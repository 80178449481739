import { Box } from '@mui/material';
function EyeIcon(props: { isHover?: boolean; isSelected?: boolean }) {
    const { isHover, isSelected } = props;
    if (isHover || isSelected) {
        return (
            <>
                <Box display={{ xs: 'none', lg: 'block' }}>
                    <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M21.25 9.14969C18.94 5.51969 15.56 3.42969 12 3.42969C10.22 3.42969 8.49 3.94969 6.91 4.91969C5.33 5.89969 3.91 7.32969 2.75 9.14969C1.75 10.7197 1.75 13.2697 2.75 14.8397C5.06 18.4797 8.44 20.5597 12 20.5597C13.78 20.5597 15.51 20.0397 17.09 19.0697C18.67 18.0897 20.09 16.6597 21.25 14.8397C22.25 13.2797 22.25 10.7197 21.25 9.14969ZM12 16.0397C9.76 16.0397 7.96 14.2297 7.96 11.9997C7.96 9.76969 9.76 7.95969 12 7.95969C14.24 7.95969 16.04 9.76969 16.04 11.9997C16.04 14.2297 14.24 16.0397 12 16.0397Z"
                            fill="#05668D"
                        />
                        <path
                            d="M12.0004 9.14062C10.4304 9.14062 9.15039 10.4206 9.15039 12.0006C9.15039 13.5706 10.4304 14.8506 12.0004 14.8506C13.5704 14.8506 14.8604 13.5706 14.8604 12.0006C14.8604 10.4306 13.5704 9.14062 12.0004 9.14062Z"
                            fill="#05668D"
                        />
                    </svg>
                </Box>
                <Box display={{ xs: 'block', lg: 'none' }}>
                    <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M35.4166 15.2499C31.5666 9.19988 25.9333 5.71655 19.9999 5.71655C17.0333 5.71655 14.1499 6.58322 11.5166 8.19989C8.88325 9.83322 6.51659 12.2166 4.58325 15.2499C2.91659 17.8666 2.91659 22.1166 4.58325 24.7332C8.43325 30.7999 14.0666 34.2666 19.9999 34.2666C22.9666 34.2666 25.8499 33.3999 28.4833 31.7832C31.1166 30.1499 33.4833 27.7666 35.4166 24.7332C37.0833 22.1332 37.0833 17.8666 35.4166 15.2499ZM19.9999 26.7332C16.2666 26.7332 13.2666 23.7166 13.2666 19.9999C13.2666 16.2832 16.2666 13.2666 19.9999 13.2666C23.7333 13.2666 26.7333 16.2832 26.7333 19.9999C26.7333 23.7166 23.7333 26.7332 19.9999 26.7332Z"
                            fill="#05668D"
                        />
                        <path
                            d="M20 15.2334C17.3833 15.2334 15.25 17.3667 15.25 20.0001C15.25 22.6167 17.3833 24.7501 20 24.7501C22.6167 24.7501 24.7667 22.6167 24.7667 20.0001C24.7667 17.3834 22.6167 15.2334 20 15.2334Z"
                            fill="#05668D"
                        />
                    </svg>
                </Box>
            </>
        );
    }
    return (
        <>
            <Box display={{ xs: 'none', lg: 'block' }}>
                <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M9.99994 13.6073C8.00827 13.6073 6.3916 11.9906 6.3916 9.99896C6.3916 8.00729 8.00827 6.39062 9.99994 6.39062C11.9916 6.39062 13.6083 8.00729 13.6083 9.99896C13.6083 11.9906 11.9916 13.6073 9.99994 13.6073ZM9.99994 7.64063C8.69993 7.64063 7.6416 8.69896 7.6416 9.99896C7.6416 11.299 8.69993 12.3573 9.99994 12.3573C11.2999 12.3573 12.3583 11.299 12.3583 9.99896C12.3583 8.69896 11.2999 7.64063 9.99994 7.64063Z"
                        fill="#808080"
                    />
                    <path
                        d="M10.0004 17.5177C6.86706 17.5177 3.90872 15.6844 1.87539 12.501C0.992057 11.126 0.992057 8.88438 1.87539 7.50104C3.91706 4.31771 6.87539 2.48438 10.0004 2.48438C13.1254 2.48438 16.0837 4.31771 18.1171 7.50104C19.0004 8.87604 19.0004 11.1177 18.1171 12.501C16.0837 15.6844 13.1254 17.5177 10.0004 17.5177ZM10.0004 3.73438C7.30872 3.73438 4.73372 5.35104 2.93372 8.17604C2.30872 9.15104 2.30872 10.851 2.93372 11.826C4.73372 14.651 7.30872 16.2677 10.0004 16.2677C12.6921 16.2677 15.2671 14.651 17.0671 11.826C17.6921 10.851 17.6921 9.15104 17.0671 8.17604C15.2671 5.35104 12.6921 3.73438 10.0004 3.73438Z"
                        fill="#808080"
                    />
                </svg>
            </Box>
            <Box display={{ xs: 'block', lg: 'none' }}>
                <svg
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M31.875 13.725C28.41 8.28002 23.34 5.14502 18 5.14502C15.33 5.14502 12.735 5.92502 10.365 7.38002C7.995 8.85002 5.865 10.995 4.125 13.725C2.625 16.08 2.625 19.905 4.125 22.26C7.59 27.72 12.66 30.84 18 30.84C20.67 30.84 23.265 30.06 25.635 28.605C28.005 27.135 30.135 24.99 31.875 22.26C33.375 19.92 33.375 16.08 31.875 13.725ZM18 24.06C14.64 24.06 11.94 21.345 11.94 18C11.94 14.655 14.64 11.94 18 11.94C21.36 11.94 24.06 14.655 24.06 18C24.06 21.345 21.36 24.06 18 24.06Z"
                        fill="#AAAAAA"
                    />
                    <path
                        d="M18.0001 13.71C15.6451 13.71 13.7251 15.63 13.7251 18C13.7251 20.355 15.6451 22.275 18.0001 22.275C20.3551 22.275 22.2901 20.355 22.2901 18C22.2901 15.645 20.3551 13.71 18.0001 13.71Z"
                        fill="#AAAAAA"
                    />
                </svg>
            </Box>
        </>
    );
}
export default EyeIcon;
