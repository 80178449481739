import { useQuery } from 'react-query';
import { QueryKeys } from '../QueryKey';
import axiosInstance from '../axiosConfig';

export function useGetUser(userId: string) {
    const getUser = async (userId: string) => {
        const response = await axiosInstance.get(
            `/api/users/${userId}`
        );
        const data = await response.data;
        return data;
    };
    return useQuery(QueryKeys.RetrieveUser, () => getUser(userId));
}
