const ModalCheckmark = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
        >
            <path
                opacity="0.4"
                d="M45.666 8.58473L22.7494 17.1681C18.3743 18.8347 14.791 24.0014 14.791 28.7097V62.4597C14.791 65.8347 16.9993 70.2931 19.7077 72.2931L42.6243 89.4181C46.666 92.4597 53.291 92.4597 57.3327 89.4181L80.2494 72.2931C82.9577 70.2514 85.166 65.8347 85.166 62.4597V28.7097C85.166 24.0431 81.5827 18.8347 77.2077 17.2097L54.291 8.62639C51.9577 7.70973 48.041 7.70973 45.666 8.58473Z"
                fill="#05668D"
            />
            <path
                d="M44.4167 59.293C43.625 59.293 42.8333 59.0013 42.2083 58.3763L35.5 51.668C34.2917 50.4596 34.2917 48.4596 35.5 47.2513C36.7083 46.043 38.7083 46.043 39.9167 47.2513L44.4167 51.7513L60.125 36.043C61.3333 34.8346 63.3333 34.8346 64.5417 36.043C65.75 37.2513 65.75 39.2513 64.5417 40.4596L46.625 58.3763C46 59.0013 45.2083 59.293 44.4167 59.293Z"
                fill="#05668D"
            />
        </svg>
    );
};

export default ModalCheckmark;
