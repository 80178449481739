import * as React from 'react';
import { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import CalendarFormIcon from '../../../assets/icons/CalendarFormIcon';
import { Responsiveness } from '../../../theme/overrides';
import Empty from '../../../assets/icons/Empty';

const Calendar = ({
    label = '',
    defaultValue,
    required = true,
    handleChange = (date: Dayjs | null | string) => {},
    disabled = false,
    error = false,
    tab=false
}: any) => {
    const { isMobile } = Responsiveness()
    const [value, setValue] = React.useState<Dayjs | null | string>(null);
    React.useEffect(() => {
        if (defaultValue) {
            setValue(defaultValue);
        }
    }, [defaultValue]);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box
                component={'span'}
                sx={{
                    position: 'relative',
                    '.MuiInputBase-root': {
                        flexDirection: 'row-reverse'
                    }
                }}
            >
                <Box
                    width="100%"
                    display="flex"
                    gap="12px"
                    alignItems="center"
                    bgcolor='#f2f2f2'
                    sx={{ borderBottom: '2px solid #C5C5C5' }}
                    pl={{xs: "16px", sm: tab ? '0px' : 0}}
                >
                    <Box display={{ xs: 'block', sm: tab ? 'none' : 'none' }}>
                        <CalendarFormIcon />
                    </Box>

                    <Box
                        display={{ xs: 'block', sm: 'none' }}
                        width="2px"
                        height="15px"
                        bgcolor="#D9D9D9"
                    ></Box>
                    <Box width="100%" bgcolor="#f2f2f2">
                        <InputLabel
                            sx={{
                                color: error ? 'red' : '#ADAAAA',
                                fontSize: '14px',
                                position: 'absolute',
                                top: '8px',
                                left: '72px',
                                zIndex: 1,
                                fontFamily: 'Open Sans'
                            }}
                            // required
                        >
                            {label}
                        </InputLabel>
                        <DatePicker
                            disabled={disabled}
                            value={value}
                            onChange={(newValue) => {
                                setValue(newValue);
                                handleChange(newValue);
                            }}
                            components={{
                                OpenPickerIcon: isMobile ? Empty :CalendarFormIcon
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    variant="filled"
                                    sx={{
                                        '& 	.MuiInputBase-input': {
                                            mt: '8px',
                                            position: 'relative',
                                            ml: tab ? '0px' : 0
                                        },
                                        '& .MuiInputBase-root::before': {
                                            borderBottom: 0
                                        },
                                        '& .MuiInputBase-root::after': {
                                            borderBottom: 0
                                        },
                                        '& .MuiInputBase-root': {
                                            backgroundColor: '#f2f2f2',
                                            width: '100%',
                                            height: { sm: tab ? '60px' : '70px', xs: '60px' },
                                            fontSize: {
                                                sm: '16px',
                                                xs: '14px'
                                            },
                                            fontFamily: 'open sans',
                                            color: '#23282B'
                                            // borderBottom: '1px solid #D9D9D9'
                                        },
                                        '& .MuiPickersDay-root.Mui-selected': {
                                            backgroundColor: '#494949'
                                        },
                                        '& .MuiPickersDay-root.Mui-selected:hover':
                                            {
                                                backgroundColor: '#494949'
                                            }
                                    }}
                                />
                            )}
                        />
                    </Box>
                </Box>
            </Box>
        </LocalizationProvider>
    );
};

export default Calendar;
