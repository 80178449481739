import { Box, Typography, Skeleton, Button } from '@mui/material';
import React from 'react';

const ConnectionLoader = () => {
    return (
        <>
            {[1, 2, 3, 4].map((i) => (
                <>
                    <Box px={{ lg: "40px", sm: '20px', xs: '16px' }} py='12px'  display={{ sm: 'block', xs: 'none' }} width="100%">
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'inset',
                                gap: { md: '20px', xs: '10px' }
                            }}
                        >
                            <>
                                <Skeleton
                                    variant="circular"
                                    sx={{
                                        minWidth: 56,
                                        minHeight: 56,
                                    }}
                                />
                                <Box sx={{ width: { sm: '100%', xs: 'fit-content' } }}>
                                    <Skeleton
                                        variant="text"
                                        sx={{
                                            fontSize: '20px'
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: { md: '20px', xs: '10px' }
                                        }}
                                    >
                                        <>
                                            <Skeleton
                                                variant="text"
                                                sx={{
                                                    width: { sm: '90px', xs: '60px' },
                                                    height: { sm: '20px', xs: '15px' }
                                                }}
                                            />
                                            <Skeleton
                                                variant="circular"
                                                sx={{
                                                    width: '7px',
                                                    height: '7px'
                                                }}
                                            />

                                            <Skeleton
                                                variant="text"
                                                sx={{
                                                    width: { sm: '90px', xs: '60px' },
                                                    height: { sm: '20px', xs: '15px' }
                                                }}
                                            />
                                            <Skeleton
                                                variant="circular"
                                                sx={{
                                                    width: '7px',
                                                    height: '7px'
                                                }}
                                            />
                                        </>
                                        <Skeleton
                                            variant="text"
                                            sx={{
                                                width: { sm: '90px', xs: '60px' },
                                                height: { sm: '20px', xs: '15px' }
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </>
                            <>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        // alignItems: 'flex-end',
                                        //   WebkitJustifyContent: 'center',
                                        alignItems: 'center',
                                        gap: { sm: '20px', xs: '10px' },
                                        width: { sm: '100%', xs: 'fit-content' },
                                        justifyContent: 'flex-end'
                                    }}
                                >
                                    <Skeleton
                                        variant="rounded"
                                        sx={{
                                            width: { md: '150px', xs: '90px' },
                                            minWidth: { md: '120px', xs: 'auto' },
                                            height: { md: '50px', xs: '35px' }
                                        }}
                                    />
                                    <>
                                        <Skeleton
                                            variant="rounded"
                                            sx={{
                                                display: { md: 'block', xs: 'none' },
                                                width: '150px',
                                                minWidth: '120px',
                                                height: '50px'
                                            }}
                                        />
                                    </>
                                </Box>
                            </>
                        </Box>
                        <>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                    gap: '8px'
                                }}
                            >
                                {[1, 2, 3].map((i) => (
                                    <Skeleton
                                        variant="text"
                                        sx={{ width: { md: '150px', xs: '90px' }, height: { md: '60px', xs: '30px' } }}
                                    />
                                ))}
                            </Box>
                        </>
                    </Box>
                    <Box p={'15px 10px'} display={{ sm: 'none', xs: 'flex', justifyContent: 'space-between', gap: '10px' }} width="100%">
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px'
                            }}
                        >
                            <Skeleton
                                variant="circular"
                                sx={{
                                    width: 50,
                                    height: 50
                                }}
                            />
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: '10px',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Skeleton
                                        variant="text"
                                        sx={{
                                            width: 80,
                                            height: 20
                                        }}
                                    />
                                    <Skeleton
                                        variant="text"
                                        sx={{
                                            width: 60,
                                            height: 40,
                                        }}
                                    />
                                </Box>

                                <Skeleton
                                    variant="text"
                                    sx={{
                                        width: 70,
                                        height: 20
                                    }}
                                />
                                <Skeleton
                                    variant="text"
                                    sx={{
                                        width: 70,
                                        height: 20
                                    }}
                                />
                            </Box>
                        </Box>
                        <Skeleton
                            variant="text"
                            sx={{
                                width: 70,
                                height: 50
                            }}
                        />
                    </Box>
                </>
            ))}
        </>
    );
};

export default ConnectionLoader;
