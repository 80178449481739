import { UseMutationResult, useMutation } from 'react-query';
import axiosInstance from '../axiosConfig';
import { PatchCompanyAccountRequest } from './types';

async function patchCompanyProfile(
    patchRequest: PatchCompanyAccountRequest
): Promise<any> {
    const response = await axiosInstance.patch(
        `/api/company-profiles/${patchRequest.companyProfileId}`,
        patchRequest
    );
    const data = await response.data;
    return data;
}

export function usePatchCompanyProfile(): UseMutationResult<
    unknown,
    unknown,
    PatchCompanyAccountRequest
> {
    return useMutation(patchCompanyProfile);
}

export default usePatchCompanyProfile;
