import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CommonRadioList from '../../../../common/CommonRadioList';
import BriefcaseFormIcon from '../../../../../assets/icons/BriefcaseFormIcon';
import { Qualification } from '../../../types/Qualification';

interface IAddQualificationDialogProps {
    handleContinue: (selectedType: Qualification) => void;
}

const SelectQualificationType = ({
    handleContinue
}: IAddQualificationDialogProps) => {
    const [type, setType] = useState<Qualification | null>(null);

    return (
        <>
            <Box
                sx={{
                    px: {
                        xs: '16px',
                        sm: '20px',
                        lg: '32px'
                    }
                }}
                mt={2}
            >
                <CommonRadioList
                    label="Qualifications"
                    description="Please select"
                    startAdornment={<BriefcaseFormIcon />}
                    options={[
                        { label: 'Education', value: Qualification.Education },
                        {
                            label: 'Work experience',
                            value: Qualification.WorkExperience
                        },
                        {
                            label: 'Volunteer service',
                            value: Qualification.VolunteerService,
                            disabled: true
                        },
                        {
                            label: 'License and certifications',
                            value: Qualification.LicenseAndCertifications,
                            disabled: true
                        },
                        {
                            label: 'Tests',
                            value: Qualification.Tests,
                            disabled: true
                        },
                        {
                            label: 'Awards',
                            value: Qualification.Awards,
                            disabled: true
                        }
                    ]}
                    onSelect={(selectedType: Qualification) =>
                        setType(selectedType)
                    }
                />
            </Box>
                <Box bgcolor='#f2f2f2' p={{xs: '8px 16px', sm: '20px', lg: '20px 32px'}}>
                    <Button
                        variant="contained"
                        onClick={() => type && handleContinue(type)}
                        disabled={!type}
                    >
                        Continue
                    </Button>
                </Box>
        </>
    );
};

export default SelectQualificationType;
