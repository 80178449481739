import React from 'react';

interface LinkedinProps {
    color?: string;
    width?: string;
    height?: string;
}

const Linkedin: React.FC<LinkedinProps> = ({
    color = '#05668D',
    width,
    height
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 41 40"
            fill="none"
        >
            <path
                d="M20.4995 0C9.45452 0 0.5 8.95459 0.5 19.9997C0.5 31.0451 9.45417 40 20.4995 40C31.5451 40 40.5 31.0451 40.5 19.9997C40.4997 8.95459 31.5451 0 20.4995 0ZM12.2006 9.0203C13.5611 9.0203 14.6646 10.0266 14.6646 11.2681C14.6646 12.5096 13.5611 13.5159 12.2006 13.5159C10.8395 13.5159 9.7367 12.5096 9.7367 11.2681C9.7367 10.0266 10.8395 9.0203 12.2006 9.0203ZM14.6832 28.246H9.81485V15.5424H14.6832V28.246ZM31.2263 28.246H26.3344V21.2605C26.3344 20.463 26.1864 19.8987 25.8914 19.5674C25.5961 19.2368 25.1815 19.0708 24.6479 19.0708C24.0576 19.0708 23.5786 19.2942 23.212 19.741C22.8451 20.1878 22.6622 20.989 22.6622 22.1455V28.246H17.7935V15.5424H22.3268V17.6114C23.0042 16.7666 23.6903 16.1625 24.384 15.7994C25.0777 15.4366 25.9225 15.2554 26.9195 15.2554C28.2675 15.2554 29.3219 15.6558 30.0837 16.4574C30.8449 17.2587 31.2259 18.4967 31.2259 20.1715L31.2263 28.246Z"
                fill={color}
            />
        </svg>
    );
};

export default Linkedin;
