import * as React from 'react';
import { SvgIcon as MuiSvgIcon, SvgIconProps, styled } from '@mui/material';

const SvgIcon = styled(MuiSvgIcon, {
    name: 'CameraIcon',
    shouldForwardProp: (prop) => prop !== 'fill'
})<SvgIconProps>(() => ({
    fill: 'currentColor'
}));

SvgIcon.defaultProps = {
    viewBox: '0 0 24 24',
    focusable: 'false',
    'aria-hidden': 'true'
};

const Camera: React.FunctionComponent<SvgIconProps> = (props) => {
    return (
        <SvgIcon {...props}>
            <path d="M17.2416 22.75H6.76164C3.96164 22.75 2.18164 21.08 2.02164 18.29L1.50164 10.04C1.42164 8.79 1.85164 7.59 2.71164 6.68C3.56164 5.77 4.76164 5.25 6.00164 5.25C6.32164 5.25 6.63164 5.06 6.78164 4.76L7.50164 3.33C8.09164 2.16 9.57164 1.25 10.8616 1.25H13.1516C14.4416 1.25 15.9116 2.16 16.5016 3.32L17.2216 4.78C17.3716 5.06 17.6716 5.25 18.0016 5.25C19.2416 5.25 20.4416 5.77 21.2916 6.68C22.1516 7.6 22.5816 8.79 22.5016 10.04L21.9816 18.3C21.8016 21.13 20.0716 22.75 17.2416 22.75ZM10.8616 2.75C10.1216 2.75 9.18164 3.33 8.84164 4L8.12164 5.44C7.70164 6.25 6.89164 6.75 6.00164 6.75C5.16164 6.75 4.38164 7.09 3.80164 7.7C3.23164 8.31 2.94164 9.11 3.00164 9.94L3.52164 18.2C3.64164 20.22 4.73164 21.25 6.76164 21.25H17.2416C19.2616 21.25 20.3516 20.22 20.4816 18.2L21.0016 9.94C21.0516 9.11 20.7716 8.31 20.2016 7.7C19.6216 7.09 18.8416 6.75 18.0016 6.75C17.1116 6.75 16.3016 6.25 15.8816 5.46L15.1516 4C14.8216 3.34 13.8816 2.76 13.1416 2.76H10.8616V2.75Z" />
            <path d="M13.5 8.75H10.5C10.09 8.75 9.75 8.41 9.75 8C9.75 7.59 10.09 7.25 10.5 7.25H13.5C13.91 7.25 14.25 7.59 14.25 8C14.25 8.41 13.91 8.75 13.5 8.75Z" />
            <path d="M12 18.75C9.79 18.75 8 16.96 8 14.75C8 12.54 9.79 10.75 12 10.75C14.21 10.75 16 12.54 16 14.75C16 16.96 14.21 18.75 12 18.75ZM12 12.25C10.62 12.25 9.5 13.37 9.5 14.75C9.5 16.13 10.62 17.25 12 17.25C13.38 17.25 14.5 16.13 14.5 14.75C14.5 13.37 13.38 12.25 12 12.25Z" />
        </SvgIcon>
    );
};

export default Camera;
