import React, {useEffect} from 'react';
import Tabs from './components/Tabs';
import Header from '../Connections/components/Header';
import { Box, Grid } from '@mui/material';
import TabPanel from './components/TabPanel';
import { useGetConnectionRequestSent } from '../../utils/hooks/api/connections/useGetConnectionRequestSent';
import { useGetConnectionRequestReceived } from '../../utils/hooks/api/connections/useGetConnectionRequestReceived';
import { useAuth } from '../../utils/context/AuthContext';
import { logger } from '../../utils/Helper/logger';
import ConnectionLoader from '../../components/LoadingState/ConnectionLoader';
import axiosInstance from '../../utils/hooks/api/axiosConfig';
import { generateHtml, generateSubject } from '../../utils/emails/emailtemplates';
// import { PendingFeedBack } from '../../utils/emails/email';
import { Responsiveness } from '../../theme/overrides';
import MobileNav from '../../components/MobileNav/MobileNav';


function PendingConnection() {
    const { isMobile, isTablet } = Responsiveness();
    const { user } = useAuth();
    const [tab, setTab] = React.useState<string>('sent');
    const { data: sentRequests, isFetching } = useGetConnectionRequestSent(
        user?.id ?? ''
    );
    const { data: receivedRequests, isFetching: isFetchingReceived } =
        useGetConnectionRequestReceived(user?.id ?? '');

    logger.log('useGetConnectionRequestReceived', receivedRequests?.length)
    logger.log('useGetConnectionRequestSent', sentRequests )

    const pendingConnectionEmailNotification = async() =>{
        // if(receivedRequests?.length === 0){
        if(receivedRequests?.length > 0){
            try {
            const numberOfReq = receivedRequests.length;
            await axiosInstance.post("/api/email/send-pendingconnectionreq-notification", {
                email: user?.professionalProfile?.emailAddress,
                firstName: user?.professionalProfile?.firstName,
                lastName: user?.professionalProfile?.lastName,
                count: numberOfReq.toString(),
                link: "pending-connections",
            })
            } catch (error) {
                console.error('Error sending notification:', error);
            }   
        }
    }

    useEffect(()=>{
        pendingConnectionEmailNotification()
    }, [user, receivedRequests])

    const changeTab = (type: string) => {
        setTab(type);
    };
        const routes = [
            {
                label: 'My Community',
                route: '/connections'
            },
            {
                label: 'Pending',
                route: '/pending-connections'
            },
            {
                label: 'Suggestions',
                route: '#',
                disable: true
            }
        ];

  
    return (
        <>
            <Box pr={{xl: '40px'}} mt={{xs: '15px', sm: 0}}>
                {isMobile ? <MobileNav list={routes} /> : null}
                <Header title={'PendingConnections'} />
                {isMobile ? null : isTablet ? (
                    <MobileNav list={routes} />
                ) : null}
                <Grid container>
                    <Tabs changeTab={changeTab} tab={tab} />
                    {isFetching || isFetchingReceived ? (
                        <ConnectionLoader />
                    ) : (
                        <>
                            <TabPanel
                                activeTab={tab}
                                tab={'sent'}
                                data={sentRequests}
                            />
                            <TabPanel
                                activeTab={tab}
                                tab={'received'}
                                data={receivedRequests}
                            />
                        </>
                    )}
                </Grid>
            </Box>
        </>
    );
}

export default PendingConnection;