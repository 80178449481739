import axiosInstance from '../axiosConfig';
import { IUserPostResponse } from './types';
import { UseMutationResult, useMutation } from 'react-query';

async function userPost(postRequest: FormData) {
    const response = await axiosInstance.post(`/api/user-posts`, postRequest);
    const data = await response.data;
    return data;
}
export function useUserPost(): UseMutationResult<
    IUserPostResponse,
    unknown,
    FormData
> {
    return useMutation(userPost);
}
