import React from 'react'

const AchievementPostOptionIcon = () => {
  return (
      <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
          <path
              d="M14.2988 13.4517L14.2992 13.4514C14.5218 13.3052 14.8164 13.4607 14.8164 13.7335V14.8085C14.8164 15.2126 14.6561 15.6603 14.3746 16.0501C14.0932 16.4399 13.7194 16.7319 13.3413 16.8593C13.341 16.8593 13.3407 16.8594 13.3405 16.8595L10.6838 17.7423L10.6838 17.7423L10.6808 17.7433C10.515 17.7996 10.2668 17.8355 9.99665 17.8355C9.726 17.8355 9.48128 17.7994 9.32137 17.7442L9.32138 17.7442L9.31577 17.7423L6.65789 16.8591C6.65781 16.8591 6.65773 16.8591 6.65765 16.859C6.2757 16.7317 5.90231 16.4398 5.62224 16.0507C5.34217 15.6616 5.18311 15.2138 5.18311 14.8085V13.7251C5.18311 13.46 5.47922 13.3045 5.6935 13.4441L5.6938 13.4443L7.4076 14.5591C8.16176 15.064 9.09508 15.3001 10.0081 15.3001C10.9214 15.3001 11.855 15.0638 12.6093 14.5586L14.2988 13.4517Z"
              fill="#808080"
              stroke="#808080"
          />
          <path
              d="M11.3834 2.52659L11.3838 2.52684L16.3747 5.80134C16.3748 5.80142 16.375 5.80149 16.3751 5.80157C17.2206 6.35878 17.5046 7.36841 17.2305 8.25237L17.2081 8.32471V8.40045V12.5005C17.2081 12.5306 17.1958 12.5619 17.1702 12.5875C17.1445 12.6132 17.1133 12.6255 17.0831 12.6255C17.053 12.6255 17.0217 12.6132 16.996 12.5875C16.9704 12.5619 16.9581 12.5306 16.9581 12.5005V9.95045V9.02375L16.1836 9.53256L15.0422 10.2824L15.0419 10.2826L11.3838 12.6824L11.3834 12.6827C11.0328 12.9132 10.5383 13.0442 10.0164 13.0442C9.49456 13.0442 9.00012 12.9132 8.64944 12.6826L8.64773 12.6815L4.9644 10.2815L4.96393 10.2812L3.63144 9.41511C3.63132 9.41503 3.63119 9.41495 3.63107 9.41487C2.32412 8.56127 2.32717 6.6472 3.62991 5.80346L3.63143 5.80247L8.6481 2.52747L8.64944 2.52659C9.00012 2.29605 9.49456 2.16504 10.0164 2.16504C10.5383 2.16504 11.0328 2.29605 11.3834 2.52659Z"
              fill="#808080"
              stroke="#808080"
          />
      </svg>
  );
}

export default AchievementPostOptionIcon
