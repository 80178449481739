import { Avatar, Box, Button, Typography } from '@mui/material';
import moment from 'moment';
import { useGetUserPostById } from '../../../utils/hooks/api/posts/useGetUserPostById';
import DotIcon from '../../../assets/icons/DotIcon';
import { Responsiveness } from '../../../theme/overrides';
import { logger } from '../../../utils/Helper/logger';
import { useEffect, useState } from 'react';
import PostCards from '../../Home/components/PostCards';
export interface IReviewType {
    professionalRating: {
        professionalProfile: {
            firstName: string;
            lastName: string;
            photoUrl: string;
        };
    };
    post: {
        id: string;
        message: string;
        createdAt: number;
    };

    activity?: string;
}
interface IPropType {
    review: IReviewType;
    hidePost: (data: IReviewType) => void;
    page?: string;
    draft?: boolean;
    postDrafts?: any[];
    setPostDrafts?: any;
}
const AchievementBox = ({
    review,
    hidePost,
    page = '',
    draft = false,
    postDrafts,
    setPostDrafts
}: IPropType) => {
    const { isMobile, isTablet } = Responsiveness();
    const maxLength = 250;
    const { data : post } = useGetUserPostById(review?.post?.id ?? undefined)
    const wrap = (word: string, maxLength: number) => {
        return word.length > maxLength
            ? word.slice(0, maxLength) + '...'
            : word;
    };
    const [openModal, setOpenModal] = useState(false);
    const [selectedDraft, setSelectedDraft] = useState('');
    useEffect(() => {
        setSelectedDraft(review?.post?.message);
    }, []);
    return (
        <>
            <Box
                sx={{
                    // p: '16px 40px',
                    pt: 0
                }}
            >
                {isMobile ? null : (
                    <Typography variant="titleSmallSemiBold" color={'#5B5B5B'}>
                        {`
                            ${review?.professionalRating?.professionalProfile?.firstName} ${review?.professionalRating?.professionalProfile?.lastName}`}
                        <Typography
                            variant="bodyMediumRegular"
                            color={' #808080'}
                        >
                            {/* {' '} */}
                            &nbsp; posted this
                            <Box
                                sx={{
                                    backgroundColor: '#808080',
                                    height: '5px',
                                    width: '5px',
                                    display: 'inline-block',
                                    mx: '10px',
                                    borderRadius: '50%',
                                    mb: '2px'
                                }}
                                component={'div'}
                            />
                            {moment(review?.post?.createdAt).fromNow()}
                        </Typography>
                    </Typography>
                )}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: isTablet ? 'flex-start' : 'center',
                        mt: '12px',
                        gap: '20px'
                    }}
                >
                    <Avatar
                        alt="Remy Sharp"
                        src={
                            review?.professionalRating?.professionalProfile
                                ?.photoUrl
                        }
                        sx={{
                            width: isMobile
                                ? '48px'
                                : isTablet
                                ? '56px'
                                : '80px',
                            height: isMobile
                                ? '48px'
                                : isTablet
                                ? '56px'
                                : '80px',
                            borderRadius: isMobile ? '100%' : '20px'
                        }}
                    />
                    <Box
                        display="flex"
                        flexDirection={'column'}
                        gap={isMobile ? '8px' : '12px'}
                        width="100%"
                    >
                        {isMobile ? (
                            <Typography
                                fontSize="16px"
                                fontWeight={600}
                                color={'#232b2b'}
                            >
                                {`
                            ${review?.professionalRating?.professionalProfile?.firstName} ${review?.professionalRating?.professionalProfile?.lastName}`}
                                <Typography
                                    variant="bodyMediumRegular"
                                    color={' #808080'}
                                    fontSize="12px"
                                >
                                    {' '}
                                    <Box
                                        sx={{
                                            backgroundColor: '#808080',
                                            height: '5px',
                                            width: '5px',
                                            display: 'inline-block',
                                            mx: '8px',
                                            borderRadius: '50%'
                                        }}
                                        component={'div'}
                                    />
                                    {moment(review?.post?.createdAt).fromNow()}
                                </Typography>
                            </Typography>
                        ) : null}
                        <Typography
                            variant="titleMediumSemiBold"
                            color={'#000'}
                            component={'div'}
                        >
                            Just completed a course
                        </Typography>
                        {review?.post ? (
                            <Typography
                                color={'#23282B'}
                                variant="titleSmallRegular"
                            >
                                {wrap(review?.post?.message, maxLength)}
                            </Typography>
                        ) : null}
                        {draft ? (
                            <Box display={{ xs: 'flex', lg: 'none' }} gap="8px">
                                <Button
                                    variant="contained"
                                    sx={{
                                        width: { xs: '100%', sm: '86px' },
                                        height: '36px',
                                        background: '#05668D',
                                        borderRadius: '8px',
                                        fontFamily: 'Open Sans',
                                        fontWeight: 600,
                                        fontSize: { xs: '14px', sm: '12px' },
                                        color: '#fff'
                                        // minWidth: 'auto'
                                    }}
                                    onClick={() => {
                                        setOpenModal(true);
                                        localStorage.setItem(
                                            'editorText',
                                            JSON.stringify(
                                                review?.post?.message
                                            )
                                        );
                                        localStorage.setItem(
                                            'activity',
                                            JSON.stringify(
                                                review?.activity ?? ''
                                            )
                                        );
                                    }}
                                >
                                    Continue
                                </Button>
                                <Button
                                    variant="outlined"
                                    sx={{
                                        width: { xs: '100%', sm: '77px' },
                                        height: '36px',
                                        background: '#FAFAFA',
                                        border: '1px solid #05668D',
                                        borderRadius: '8px',
                                        fontFamily: 'Open Sans',
                                        fontWeight: 600,
                                        fontSize: { xs: '14px', sm: '12px' },
                                        color: '#05668D'
                                        // minWidth: 'auto'
                                    }}
                                    onClick={() => {
                                        setSelectedDraft(review?.post?.message);
                                        let updatedPost = postDrafts?.filter(
                                            (post, i) =>
                                                post.post.message !==
                                                selectedDraft
                                        );
                                        logger.log(updatedPost);
                                        setPostDrafts(updatedPost);
                                        localStorage.setItem(
                                            'drafts',
                                            JSON.stringify(postDrafts)
                                        );
                                    }}
                                >
                                    Discard
                                </Button>
                            </Box>
                        ) : page !== 'received' ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    // mt: '8px',
                                    width: '100%'
                                }}
                            >
                                <Button
                                    variant="outlined"
                                    sx={{
                                        p: '14px 16px',
                                        background: '#FAFAFA',
                                        border: '1px solid #05668D',
                                        borderRadius: '8px',
                                        fontFamily: 'Open Sans',
                                        fontWeight: 600,
                                        fontSize: '14px',
                                        lineHeight: '16px',
                                        letterSpacing: '0.005em',
                                        color: '#05668D',
                                        maxWidth: isMobile ? undefined : '200px'
                                        // minWidth: 'auto'
                                    }}
                                    onClick={() => hidePost(review)}
                                >
                                    View review
                                </Button>
                                {/* <Box
                        sx={{
                            mt: '12px',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px'
                        }}
                    >
                        <Typography
                            variant="labelMediumRegular"
                            color={'#808080'}
                        >
                            {post?.commentsCount + ' Comments'}
                        </Typography>
                        <DotIcon color="#808080" width="4px" height="4px" />
                        <Typography
                            variant="labelMediumRegular"
                            color={'#808080'}
                        >
                            {post?.repost ?? 0 + ' Reposts'}
                        </Typography>
                        <DotIcon color="#808080" width="4px" height="4px" />
                        <Typography
                            variant="labelMediumRegular"
                            color={'#808080'}
                        >
                            {post?.likes ?? 0 + ' Likes'}{' '}
                        </Typography>
                    </Box> */}
                                {isMobile ? null : (
                                    <Box
                                        sx={{
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => hidePost(review)}
                                    >
                                        <Typography
                                            variant="labelMediumRegular"
                                            color={'#808080'}
                                            mb={'12px'}
                                        >
                                            ...Show more{' '}
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        ) : null}
                    </Box>
                    {draft ? (
                        <Box display={{ xs: 'none', lg: 'flex' }} gap="8px">
                            <Button
                                variant="contained"
                                sx={{
                                    width: '124px',
                                    height: '44px',
                                    background: '#05668D',
                                    borderRadius: '8px',
                                    fontFamily: 'Open Sans',
                                    fontWeight: 600,
                                    fontSize: '14px',
                                    color: '#fff'
                                    // minWidth: 'auto'
                                }}
                                onClick={() => {
                                    setOpenModal(true);
                                    localStorage.setItem(
                                        'editorText',
                                        JSON.stringify(review?.post?.message)
                                    );
                                    localStorage.setItem(
                                        'activity',
                                        JSON.stringify(review?.activity ?? '')
                                    );
                                }}
                            >
                                Continue
                            </Button>
                            <Button
                                variant="outlined"
                                sx={{
                                    width: '77px',
                                    height: '44px',
                                    background: '#FAFAFA',
                                    border: '1px solid #05668D',
                                    borderRadius: '8px',
                                    fontFamily: 'Open Sans',
                                    fontWeight: 600,
                                    fontSize: '14px',
                                    color: '#05668D'
                                    // minWidth: 'auto'
                                }}
                                onClick={() => {
                                    let updatedPost = postDrafts?.filter(
                                        (post, i) =>
                                            post.post.message !== selectedDraft
                                    );
                                    logger.log(updatedPost);
                                    logger.log(selectedDraft);
                                    localStorage.setItem(
                                        'drafts',
                                        JSON.stringify(updatedPost)
                                    );
                                }}
                            >
                                Discard
                            </Button>
                        </Box>
                    ) : null}
                </Box>
                {page === 'received' ? (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: isTablet
                                ? 'flex-end'
                                : 'space-between',
                            // mt: '8px',
                            width: '100%'
                        }}
                    >
                        {isTablet ? null : (
                            <Box
                                sx={{
                                    mt: '12px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '8px'
                                }}
                            >
                                <Typography
                                    variant="labelMediumRegular"
                                    color={'#808080'}
                                >
                                    {(post?.commentsCount ?? 0) +
                                        (post?.commentsCount === 1
                                            ? ' Comment'
                                            : ' Comments')}
                                </Typography>
                                <DotIcon
                                    color="#808080"
                                    width="4px"
                                    height="4px"
                                />
                                <Typography
                                    variant="labelMediumRegular"
                                    color={'#808080'}
                                >
                                    {(post?.repostCount ?? 0) +
                                        (post?.comments && post?.comments[0]?.repostCount === 1
                                            ? ' Repost'
                                            : ' Reposts')}
                                </Typography>
                                <DotIcon
                                    color="#808080"
                                    width="4px"
                                    height="4px"
                                />
                                <Typography
                                    variant="labelMediumRegular"
                                    color={'#808080'}
                                >
                                    {(post?.comments[0]?.likes ?? 0) +
                                        (post?.comments && post?.comments[0]?.likes === 1
                                            ? ' Like'
                                            : ' Likes')}{' '}
                                </Typography>
                            </Box>
                        )}
                        {isMobile ? null : (
                            <Box
                                sx={{
                                    cursor: 'pointer'
                                }}
                                onClick={() => hidePost(review)}
                            >
                                <Typography
                                    variant="labelMediumRegular"
                                    color={'#808080'}
                                    mb={'12px'}
                                >
                                    ...Show more{' '}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                ) : null}
            </Box>
            <PostCards
                showModal={openModal}
                hideModal={() => setOpenModal(false)}
                data={{
                    text: review?.post?.message,
                    activity: review?.activity
                }}
                achievementModal
                draft
            />
        </>
    );
};

export default AchievementBox;
