import { Modal, Box, Menu } from '@mui/material';
import React, { useState } from 'react';
import Camera from '../../../assets/icons/CameraIconMui';
import AchievementsIcon from '../../../assets/icons/AchievementsIcon';
import { ArticleIcon } from '../../../assets/icons/ArticleIcon';
import CameraPostOptionIcon from '../../../assets/icons/CameraPostOptionIcon';
import AchievementPostOptionIcon from '../../../assets/icons/AchievementPostOptionIcon';
import ArticlePostOptionIcon from '../../../assets/icons/ArticlePostOptionIcon';

interface PostOptionsProps {
    setShowModal: any;
    handleClose: () => void;
}

const PostOptions = ({ setShowModal, handleClose }: PostOptionsProps) => {
    const postItems = [
        {
            icon: <CameraPostOptionIcon />,
            name: 'Create post',
            action: () => setShowModal('open')
        },
        {
            icon: <AchievementPostOptionIcon />,
            name: 'Post an achievement',
            action: () => setShowModal('achievement')
        },
        {
            icon: <ArticlePostOptionIcon />,
            name: 'Write an article',
            action: () => {}
        }
    ];
    const handleScroll = () => {
        console.log('scroll');
    }
    const element = document.getElementById('overlay');
    element?.removeEventListener('scroll', handleScroll);
    return (
        <Box display={{ xs: 'block', lg: 'none' }}>
            <Box id='overlay'
                position="fixed"
                left={0}
                right={0}
                top={0}
                bottom={0}
                // width="100%"
                // height="100%"
                bgcolor="rgba(0,0,0,.2)"
                zIndex={1000}
                sx={{ pointerEvents: 'none' }}
            ></Box>
            <Box
                zIndex={1701}
                position={'fixed'}
                sx={{ right: { xs: '61px', sm: '85px' } }}
                bottom={{ xs: '153px', sm: '103px' }}
                width="fit-content"
                height="fit-content"
                bgcolor="transparent"
            >
                {postItems.map((item) => (
                    <Box
                        key={item.name}
                        sx={{
                            display: 'flex',
                            gap: '10px',
                            borderRadius: '24px 24px 0 24px',
                            bgcolor: '#fff',
                            p: '8px 12px',
                            mb: '8px'
                        }}
                        onClick={() => {
                            item.action();
                            handleClose()
                        }}
                    >
                        {item.icon}
                        {item.name}
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default PostOptions;
