import React from 'react'

const CameraPostOptionIcon = () => {
  return (
      <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
          <path
              d="M14.9998 5.00033C14.4915 5.00033 14.0248 4.70866 13.7915 4.25866L13.1915 3.05033C12.8081 2.29199 11.8081 1.66699 10.9581 1.66699H9.04981C8.19148 1.66699 7.19148 2.29199 6.80815 3.05033L6.20815 4.25866C5.97481 4.70866 5.50815 5.00033 4.99981 5.00033C3.19148 5.00033 1.75815 6.52533 1.87481 8.32533L2.30815 15.2087C2.40815 16.9253 3.33315 18.3337 5.63315 18.3337H14.3665C16.6665 18.3337 17.5831 16.9253 17.6915 15.2087L18.1248 8.32533C18.2415 6.52533 16.8081 5.00033 14.9998 5.00033ZM8.74981 6.04199H11.2498C11.5915 6.04199 11.8748 6.32533 11.8748 6.66699C11.8748 7.00866 11.5915 7.29199 11.2498 7.29199H8.74981C8.40815 7.29199 8.12481 7.00866 8.12481 6.66699C8.12481 6.32533 8.40815 6.04199 8.74981 6.04199ZM9.99981 15.1003C8.44981 15.1003 7.18315 13.842 7.18315 12.2837C7.18315 10.7253 8.44148 9.46699 9.99981 9.46699C11.5581 9.46699 12.8165 10.7253 12.8165 12.2837C12.8165 13.842 11.5498 15.1003 9.99981 15.1003Z"
              fill="#808080"
          />
      </svg>
  );
}

export default CameraPostOptionIcon
