import axiosInstance from '../axiosConfig';
import { SendReviewRequest } from './types';
import { UseMutationResult, useMutation } from 'react-query';

async function sendReviewRequest(postRequest: SendReviewRequest) {
    const response = await axiosInstance.post(
        `/api/professional-reviews-requests`,
        postRequest
    );
    const data = await response.data;
    return data;
}
export function useSendReviewRequest(): UseMutationResult<
    unknown,
    unknown,
    SendReviewRequest
> {
    return useMutation(sendReviewRequest);
}
