import { Avatar, Box, Typography } from '@mui/material';
import { useGetProfessionalProfileScore } from '../../../utils/hooks/api/professional-profile/useGetProfessionalProfileScore';
import { Responsiveness } from '../../../theme/overrides';
import { useNavigate } from 'react-router-dom';
import { CompanyProfile } from '../../../utils/hooks/api/company-profile/types';

const CompanyDetails = (props: IPropTypes) => {
    const { company, id } = props;
    const { data: score } = useGetProfessionalProfileScore(id ?? '');
    const { isMobile } = Responsiveness()
    const navigate = useNavigate()

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: isMobile ? '12px' : '20px'
            }}
        >
            <Box sx={{ position: 'relative' }}>
                <Avatar
                    onClick={() =>
                        navigate(`/company-profile/${id}`)
                    }
                    alt="Remy Sharp"
                    src={company?.photo}
                    sx={{
                        width: isMobile ? 44 : 56,
                        height: isMobile ? 44 : 56
                    }}
                />
            </Box>
            <Box display="flex" flexDirection="column" gap="2px">
                <Box display="flex" alignItems={'center'} gap="12px">
                    <Typography
                        onClick={() =>
                            navigate(`/company-profile/${id}`)
                        }
                        component={'h1'}
                        variant="titleLargeSemiBold"
                        sx={{
                            color: '#23282B',
                            fontSize: isMobile ? '16px' : undefined
                        }}
                    >
                        {company?.companyName}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '20px'
                    }}
                >
                    {company?.industry ? (
                        <Typography
                            sx={{
                                color: '#808080',
                                letterSpacing: '0.0015em'
                            }}
                        >
                            {company?.industry}
                        </Typography>
                    ) : null}
                    {score ? (
                        <>
                            <Typography
                                component={'div'}
                                sx={{
                                    width: '7px',
                                    height: '7px',
                                    borderRadius: '100px',
                                    background: '#494949',
                                    border: '2px solid #494949'
                                }}
                            />
                            <Typography
                                variant="titleLargeSemiBold"
                                sx={{
                                    color: '#FFFFFF',
                                    background: '#49B6FF',
                                    borderRadius: '12px',
                                    padding: '2px 12px'
                                }}
                            >
                                {score}
                            </Typography>
                        </>
                    ) : null}
                </Box>
            </Box>
        </Box>
    );
};
interface IPropTypes {
    company: CompanyProfile;
    showActive?: boolean;
    id?: string;
}

export default CompanyDetails;
