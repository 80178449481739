import { useEffect, useRef, useState } from 'react';
import { MessageDto } from '../../utils/types';
import { Box, Button, IconButton, TextField } from '@mui/material';
import { Close, Image, Send } from '@mui/icons-material';
import { useMessages } from '../../utils/MessagesContext';

export default function MessageInput() {
    const { userId, socket, activeConversation, setAlert } = useMessages();
    const [message, setMessage] = useState('');
    const [file, setFile] = useState<File | null>(null);
    const [isSending, setIsSending] = useState(false);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const UPLOAD_LIMIT_MB = 15;

    const sendMessage = async () => {
        if (!file && message.length === 0) return;
        setIsSending(true);
        const payload: MessageDto = {
            senderId: userId,
            recipientId: activeConversation!.userId,
            content: message
        };
        if (file) {
            payload.content = await file.arrayBuffer();
        }
        try {
            const response = await socket!
                .timeout(10000)
                .emitWithAck('createMessage', payload);
            if (response) {
                setMessage('');
                setFile(null);
                setIsSending(false);
                setTimeout(() => inputRef.current?.focus(), 100);
            } else {
                throw new Error();
            }
        } catch (err) {
            setAlert('Error sending message. Please try again.', 'error');
            setIsSending(false);
        }
    };

    const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files![0];
        if (selectedFile && selectedFile.size > UPLOAD_LIMIT_MB * 1024 * 1024) {
            alert(
                `File size exceeds the limit of ${UPLOAD_LIMIT_MB} megabytes.`
            );
            return;
        }
        if (!/jpg|jpeg|png/.test(selectedFile.type)) {
            alert('Only JPG, JPEG, and PNG files are allowed.');
            return;
        }
        setFile(selectedFile);
        inputRef?.current?.blur();
    };

        useEffect(() => {
            const textarea = inputRef.current;
            if (textarea) {
                textarea.scrollTop = textarea.scrollHeight;
            }
        }, [message]);

    return (
        <Box>
            <TextField
                inputRef={inputRef}
                type="text"
                value={file?.name ?? message}
                onChange={(e) => (file ? '' : setMessage(e.target.value))}
                sx={{ width: '100%', height: '80px' }}
                disabled={isSending}
                multiline
                // rows={1}
                maxRows={3}
                onKeyDown={(e) => {
                    if (e.ctrlKey && e.key === 'Enter') {
                        console.log('object')
                        const textarea = inputRef?.current;
                        e.preventDefault(); // Prevent default behavior (submitting form)
                        if (textarea) {
                            let cursorPos: any = textarea.selectionStart;
                            let textBefore = message.substring(0, cursorPos);
                            let textAfter = message.substring(cursorPos);
                            setMessage(textBefore + '\n' + textAfter);
                            textarea.scrollTo(0, textarea.scrollHeight);
                        }

                        
                    }
                    if (!(e.ctrlKey) && e.key === 'Enter')
                       sendMessage();
                        
                }}
                placeholder="Write a message..."
                InputProps={{
                    startAdornment: file ? (
                        <IconButton onClick={() => setFile(null)}>
                            <Close sx={{ color: '#AAA' }} />
                        </IconButton>
                    ) : (
                        <IconButton
                            onClick={() => fileInputRef?.current?.click()}
                        >
                            <input
                                ref={fileInputRef}
                                type="file"
                                hidden
                                accept="image/x-png,image/jpeg"
                                onChange={handleFileUpload}
                            />
                            <Image sx={{ color: '#AAA' }} />
                        </IconButton>
                    ),
                    endAdornment: (
                        <IconButton onClick={sendMessage}>
                            <Send sx={{ color: '#AAA' }} />
                        </IconButton>
                    ),
                    sx: {
                        bgcolor: '#FFF',
                        '& .MuiInputBase-input': { pl: '12px' }
                    }
                }}
            />
        </Box>
    );
}
