import { useQuery } from 'react-query';
import axiosInstance from '../axiosConfig';
import { QueryKeys } from '../QueryKey';

export function useGetUserPosts(
    limit: number,
    requestType: string,
    userId?: string,
    profileType?: string
) {
    const getUserPosts = async (limit: number) => {
        const response = await axiosInstance.get(
            requestType === 'userCommentsById'
                ? `/api/post-comments/${userId}?limit=${limit}&profileType=${profileType}`
                : `/api/user-posts${
                      requestType === 'userPostsById' ? '/' + userId : ''
                  }?limit=${limit}&profileType=${profileType}`
        );
        const data = await response.data;
        return data;
    };
    return useQuery(
        [QueryKeys.RetrieveUserPosts, requestType, limit],
        () => (!!requestType ? getUserPosts(limit) : null),
        { enabled: !!requestType }
    );
}
