import { StateMachineProvider, createStore } from 'little-state-machine';
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { CreateAccountType } from '../../utils/hooks/api/account/types';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import NewPassword from './NewPassword';
import { Box, Grid } from '@mui/material';
import OnBoardingSidePanel from '../OnBoarding/OnBoardingSidePanel';

createStore({
    data: {
        accountType: CreateAccountType.NotSelected,
        firstName: '',
        lastName: '',
        emailAddress: '',
        companyName: '',
        step: 1,
        password: '',
        verifyEmail: false,
        resetPassword: ''
    }
});

function ForgotPasswordPage() {
    return (
        <>
            <Box
                sx={{
                    mx: { md: '40px', xs: 0 },
                }}
            >
                <Grid
                    sx={{
                        mt: { md: '40px', xs: 0 },
                        minHeight: { md: 'calc(100vh - 80px)', sm: '100vh', xs: '100%' },
                        backgroundColor: { md: '#FCFBF8', sm: '#FFF', xs: '#FCFBF8' },
                        width: '100%',
                    }}
                    container
                    columnSpacing={{ md: 5, xs: 0 }}
                >
                    <StateMachineProvider>
                        {/* left column */}
                        <Grid item md={6} xs={12}>
                            <OnBoardingSidePanel totalSteps={3} steps={['Forgot password','Verify code','Enter new password']}/>
                        </Grid>
                        {/* right column */}
                        <Grid item md={6} xs={12}>
                            <Routes>
                                <Route path="/" element={<ForgotPassword />} />
                                <Route path="/reset/:email" element={<ResetPassword />} />
                                <Route path="/new/:email/:code" element={<NewPassword />} />
                            </Routes>
                        </Grid>
                    </StateMachineProvider>
                </Grid>
            </Box>
        </>
    );
}

export default ForgotPasswordPage;
