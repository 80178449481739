/* eslint-disable react-hooks/rules-of-hooks */
import CircleIcon from '@mui/icons-material/Circle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItem';
import cambridge from '../../../assets/images/cambridge.jpg';
import InfoChip from '../components/InfoChip';
import Section from '../components/Section';
import SectionHeading from '../components/SectionHeading';
import Image from '../../../components/Image';
import MedalIcon from '../../../assets/icons/MedalIcon';
import EducationSummary from '../components/EducationSummary';
import WorkSummary from '../components/WorkSummary';
import AnalyticsProfile from '../../../assets/icons/AnalyticsProfile';
import AnalyticsSearch from '../../../assets/icons/AnalyticsSearch';
import AnalyticsGraph from '../../../assets/icons/AnalyticsGraph';
import { Ranking } from '../types/Ranking';
// import EditContactInfoDialog from '../components/Modals/EditContactInfoDialog';
import ScoreCard from '../../../components/ScoreCard.tsx';
import { useEffect, useRef, useState } from 'react';
import EditProfileAboutMeDialog from '../components/Modals/EditProfileAboutMeDialog';
import EditIcon from '../../../assets/icons/EditIcon';
import EditProfileInfoDialog from '../components/Modals/EditProfileInfoDialog';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../utils/context/AuthContext';
import ProfileWizard from '../ProfileWizard/ProfileWizard';
import SeeMoreIcon from '../components/SeeMoreIcon';
import ArrowRight from '../../../assets/icons/ArrowRight';
import { useGetConnections } from '../../../utils/hooks/api/connections/useGetConnections';
import { PrimaryProfileType } from '../../../utils/hooks/api/account/types';
import { useTheme, useMediaQuery, Stack, CircularProgress } from '@mui/material';
import ProfileBanner from '../../ProfileBanner';
import { useGetCompanyProfileScore } from '../../../utils/hooks/api/company-profile/useGetCompanyProfileScore';
import { useGetProfessionalProfileScore } from '../../../utils/hooks/api/professional-profile/useGetProfessionalProfileScore';
import { useGetCompanyProfileById } from '../../../utils/hooks/api/company-profile/useGetCompanyProfile';
import { useGetProfessionalProfileById } from '../../../utils/hooks/api/professional-profile/useGetProfessionalProfile';
import { GenericCompanyLogo } from '../../../assets/images';
import cover from '../../../assets/images/cover.jpg';
import ProfilePhotoIcon from '../../../assets/icons/ProfilePhotoIcon';
import { Responsiveness } from '../../../theme/overrides';
import PageHeader from '../../../components/PageHeader/PageHeader';
import MobileNav from '../../../components/MobileNav/MobileNav';
import Edit from '../../../assets/icons/EditIcon';
import EditBtn from '../../../assets/icons/EditBtn';
import { usePost } from '../../../utils/context/PostContext';
import { FeedItem } from '../../Home/components/FeedItem';
import Alert from '../../../assets/icons/Alert';
import ArrowIconRight from '../../../assets/icons/ArrowIconRight';
import SkeletonLoader from '../../Home/components/SkeletonLoader';
import { logger } from '../../../utils/Helper/logger';
import VerifiedIcon from '../../../assets/icons/VerifiedIcon';
import MoreIcon from '../../../assets/icons/MoreIcon';
import TickCircle from '../../../assets/icons/TickCircle';
import ProfessionalProfile from '../../ProfessionalProfile/index';

function UsersPage() {
    const { isTablet, isMobile, isXMobile } = Responsiveness();
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const { user, account } = useAuth();
    const navigate = useNavigate();
    const { data: connections } = useGetConnections(user?.id ?? '');
    // const [openContactInfoModal, setOpenContactInfoModal] = useState(false);
    const [openAboutMeModal, setOpenAboutMeModal] = useState(false);
    const [openProfileInfoModal, setOpenProfileInfoModal] = useState(false);
    const [haveProfile, setHaveProfile] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const { data: score } =
        account?.primaryProfile === PrimaryProfileType.Company
            ? useGetCompanyProfileScore(user?.primaryCompanyProfile?.id!)
            : useGetProfessionalProfileScore(user?.professionalProfile?.id!);
    const {
        data: profile,
        refetch,
        isFetching
    } = account?.primaryProfile === PrimaryProfileType.Company
        ? useGetCompanyProfileById(user?.primaryCompanyProfile?.id!)
        : useGetProfessionalProfileById(user?.professionalProfile?.id!);
    const handleOnEditAboutMe = () => {
        setOpenAboutMeModal(true);
    };
    const { posts, totalPosts, isLoaded, setGetAfter } = usePost();
    const [activity, setActivity] = useState('Posts');
    const currentActivity = ['Posts', 'Achievements', 'Articles', 'Jobs'];

    const routes = [
        {
            label: 'About me',
            route: '/users'
        },
        {
            label: 'Activities',
            route: '/activities'
        },
        {
            label: 'Jobfactor Score',
            route: '/jobfactor-score'
        },
        {
            label: 'Skills',
            route: '/skills'
        }
    ];
      const [isTruncated, setIsTruncated] = useState(false);
      const containerRef = useRef(null);

      useEffect(() => {
          const container: any = containerRef.current;
          if (container !== null) {
              if (container.scrollHeight > container.clientHeight) {
                  setIsTruncated(true);
              } else {
                  setIsTruncated(false);
              }
          }
      }, [profile?.bio]);

    // const handleOnEditContactInfo = () => {
    //     setOpenContactInfoModal(true);
    // };

    const handleOnEditProfileInfo = () => {
        setOpenProfileInfoModal(true);
    };

    useEffect(() => {
        setHaveProfile(user?.professionalProfile?.photoUrl ? true : false);
    }, []);

    if (!haveProfile) {
        return <ProfileWizard />;
    }

    if (!profile) {
        return <Box width='100%' height='100vh' display='flex' alignItems='center' justifyContent='center'><CircularProgress /></Box>
    }

    return (
        <Container
            disableGutters
            style={{
                // marginTop: 63,
                maxWidth: '100%'
            }}
            sx={{
                pr: { xs: 0, sm: '20px', lg: '40px' },
                mt: isMobile ? '68px' : isTablet ? '63px' : '-15px',
                position: 'relative'
            }}
        >
            {isMobile ? null : isTablet ? (
                <PageHeader header="Profile" />
            ) : null}
            {isTablet ? <MobileNav list={routes} /> : null}
            <Grid
                mt="8px"
                container
                direction="column"
                gap={{ xs: '8px', sm: '8px' }}
            >
                <Grid item position="relative">
                    <Box
                        bgcolor="#fff"
                        borderRadius={1}
                        overflow="hidden"
                        pb={isMobile ? 0 : '20px'}
                    >
                        <Box position="relative" pb="109px">
                            <Box
                                position="relative"
                                overflow="hidden"
                                display={'flex'}
                                borderRadius={[0, 0, 1, 1]}
                            >
                                <img
                                    style={{
                                        height: isMobile
                                            ? '100px'
                                            : isTablet
                                            ? '220px'
                                            : '259px',
                                        width: '100%',
                                        objectFit: 'cover',
                                        display: 'block'
                                    }}
                                    alt="Cover"
                                    src={cover}
                                />
                                {isTablet ? null : (
                                    <ProfileBanner
                                        firstName={
                                            profile?.firstName ??
                                            profile?.companyName ??
                                            ''
                                        }
                                        lastName={profile?.lastName ?? ''}
                                        score={score}
                                    />
                                )}
                            </Box>
                            <Grid
                                container
                                gap={isMobile ? undefined : 3}
                                position={'absolute'}
                                direction={isMobile ? 'column' : undefined}
                                bottom={isMobile ? 8 : isTablet ? 30 : 0}
                            >
                                <Box
                                    ml={isTablet ? '20px' : 5}
                                    borderRadius="50%"
                                    overflow="hidden"
                                    width="fit-content"
                                    boxShadow="-8px 4px 20px rgba(0, 0, 0, 0.07), 8px 8px 20px rgba(0, 0, 0, 0.07)"
                                    position="relative"
                                    mb={isTablet && !profile?.photoUrl ? 2 : 0}
                                >
                                    {!profile?.photoUrl ? (
                                        <ProfilePhotoIcon />
                                    ) : (
                                        <Image
                                            src={profile?.photoUrl ?? ''}
                                            sx={{
                                                width: isMobile
                                                    ? '130px'
                                                    : isTablet
                                                    ? '150px'
                                                    : '200px',
                                                height: isMobile
                                                    ? '130px'
                                                    : isTablet
                                                    ? '150px'
                                                    : '200px',
                                                objectFit: 'cover'
                                            }}
                                            alt={
                                                profile?.firstName +
                                                ' ' +
                                                profile?.lastName
                                            }
                                            border="3px #fff solid"
                                            borderRadius="50%"
                                            display="block"
                                        />
                                    )}
                                </Box>
                                {isMobile ? (
                                    <Box mt="4px" ml="40px">
                                        <Typography
                                            component="span"
                                            color="#05668D"
                                            fontFamily="open sans"
                                            fontSize={16}
                                            fontWeight={700}
                                        >
                                            {connections?.length ?? 0}
                                        </Typography>
                                        &nbsp;
                                        <Typography
                                            component={'span'}
                                            color="#05668D"
                                            fontFamily="open sans"
                                            fontSize={12}
                                        >
                                            {'Connection' +
                                                (connections?.length === 1
                                                    ? ''
                                                    : 's')}
                                        </Typography>
                                    </Box>
                                ) : null}
                            </Grid>
                        </Box>
                        {isMobile ? (
                            <Box
                                display="flex"
                                alignItems="flex-end"
                                flexDirection="column"
                                mt={-11.5}
                                mr="20px"
                                gap="16px"
                            >
                                <ScoreCard
                                    score={score}
                                    width="80px"
                                    height="80px"
                                />
                                {/* {isXMobile ? (
                                    <Box
                                        zIndex={1}
                                        onClick={handleOnEditProfileInfo}
                                    >
                                        <EditBtn />
                                    </Box>
                                ) : (
                                    <Button
                                        sx={{
                                            height: '40px',
                                            width: '150px',
                                            py: '8px',
                                            px: '8px',
                                            maxHeight: '40px',
                                            color: '#05668D',
                                            backgroundColor: '#F2F2F2',
                                            borderRadius: '8px',
                                            textTransform: 'none',
                                            textDecoration: 'none',
                                            gap: 1
                                        }}
                                        onClick={handleOnEditProfileInfo}
                                    >
                                        <EditBtn />
                                        Edit profile
                                    </Button>
                                )} */}
                            </Box>
                        ) : null}

                        {isMobile ? (
                            <Box
                                width="100%"
                                height="4px"
                                bgcolor="#f2f2f2"
                                my="8px"
                            ></Box>
                        ) : null}
                        <Grid
                            container
                            px={isMobile ? '16px' : isTablet ? '20px' : 5}
                            marginTop={{ xs: 0, sm: -1.5, lg: -5.5 }}
                            pb={{ xs: '16px', sm: 0 }}
                        >
                            <Grid item flexGrow={1} marginTop="auto">
                                <Grid container direction="column" gap={0.5}>
                                    <Grid
                                        item
                                        direction={
                                            isMobile ? 'column' : undefined
                                        }
                                    >
                                        <Grid
                                            container
                                            gap={'12px'}
                                            display="flex"
                                            alignItems="center"
                                        >
                                            <Grid
                                                item
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '4px'
                                                }}
                                            >
                                                <Typography
                                                    component="h2"
                                                    color="#23282B"
                                                    fontFamily="open sans"
                                                    // lineHeight="1.125"
                                                    fontSize={
                                                        isMobile
                                                            ? 22
                                                            : isTablet
                                                            ? 24
                                                            : 28
                                                    }
                                                    fontWeight={600}
                                                >
                                                    {profile?.firstName}{' '}
                                                    {profile?.lastName}
                                                </Typography>
                                                {/* <Box> */}
                                                <TickCircle />
                                                {/* <VerifiedIcon /> */}
                                                {/* </Box> */}
                                            </Grid>
                                            <CircleIcon
                                                sx={{
                                                    display: {
                                                        xs: 'none',
                                                        sm: 'block'
                                                    },
                                                    fontSize: '6px'
                                                }}
                                                htmlColor="#C5C5C5"
                                            />
                                            <Grid
                                                item
                                                sx={{ display: 'flex' }}
                                                alignItems="center"
                                            >
                                                <Grid
                                                    sx={{
                                                        display: {
                                                            xs: 'none',
                                                            sm: 'flex'
                                                        }
                                                    }}
                                                    container
                                                    wrap="nowrap"
                                                    width="auto"
                                                    // marginTop="auto"
                                                    flexGrow={1}
                                                    alignItems="center"
                                                    gap="12px"
                                                    // marginLeft={
                                                    //     isTablet ? -2 : -1
                                                    // }
                                                >
                                                    <Grid
                                                        item
                                                        flexGrow={1}
                                                        // mt={
                                                        //     isTablet
                                                        //         ? '30px'
                                                        //         : undefined
                                                        // }
                                                    >
                                                        <Typography
                                                            component="span"
                                                            color="#05668D"
                                                            fontFamily="open sans"
                                                            fontSize={20}
                                                            fontWeight={700}
                                                        >
                                                            {connections?.length ??
                                                                0}
                                                        </Typography>
                                                        &nbsp;
                                                        <Typography
                                                            component={'span'}
                                                            color="#05668D"
                                                            fontFamily="open sans"
                                                            fontSize={20}
                                                        >
                                                            {'Connection' +
                                                                (connections?.length ===
                                                                1
                                                                    ? ''
                                                                    : 's')}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid
                                                        item
                                                        // paddingRight="32px"
                                                    >
                                                        <Box
                                                            display="flex"
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            sx={{
                                                                bgcolor:
                                                                    '#f2f2f2',
                                                                borderRadius:
                                                                    '8px',
                                                                p: '4px'
                                                            }}
                                                        >
                                                            <MoreIcon />
                                                            {/* <MoreVertIcon fontSize="medium" htmlColor='#808080' sx={{ bgcolor:"#f2f2f2",
                                                            borderRadius:"8px", p:'4px'}}  /> */}
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            {/* <Grid item alignItems="center">
                                                <Grid
                                                    container
                                                    gap={isTablet ? 1 : 2}
                                                    alignItems="center"
                                                >
                                                    {isMobile ? (
                                                        <CheckCircleIcon
                                                            htmlColor="#49B6FF"
                                                            style={{
                                                                marginLeft:
                                                                    '8px'
                                                            }}
                                                        />
                                                    ) : (
                                                        <>
                                                            <CircleIcon
                                                                sx={{
                                                                    fontSize:
                                                                        '7.25px'
                                                                }}
                                                                htmlColor="#494949"
                                                            />
                                                            {profile
                                                                ?.currentEmployment
                                                                ?.jobTitle ? (
                                                                <>
                                                                    <Typography
                                                                        component="span"
                                                                        color="#808080"
                                                                        fontFamily="open sans"
                                                                        fontSize={
                                                                            16
                                                                        }
                                                                        fontWeight={
                                                                            400
                                                                        }
                                                                    >
                                                                        {
                                                                            profile
                                                                                ?.currentEmployment
                                                                                ?.jobTitle
                                                                        }
                                                                    </Typography>
                                                                    <CheckCircleIcon
                                                                        htmlColor="#49B6FF"
                                                                        style={{
                                                                            marginLeft:
                                                                                isTablet
                                                                                    ? '8px'
                                                                                    : '-8px'
                                                                        }}
                                                                    />
                                                                </>
                                                            ) : null}
                                                            <Grid item ml={1}>
                                                                <Button
                                                                    variant="contained"
                                                                    style={{
                                                                        width: 'auto',
                                                                        minWidth:
                                                                            'auto',
                                                                        color: '#808080',
                                                                        backgroundColor:
                                                                            '#F2F2F2'
                                                                    }}
                                                                    sx={{
                                                                        px: 1,
                                                                        py: 1.25
                                                                    }}
                                                                    onClick={
                                                                        handleOnEditProfileInfo
                                                                    }
                                                                >
                                                                    <EditIcon fontSize="small" />
                                                                </Button>
                                                            </Grid>
                                                        </>
                                                    )}
                                                </Grid>
                                            </Grid> */}
                                        </Grid>
                                        {/* {isMobile &&
                                        profile?.currentEmployment?.jobTitle ? (
                                            <Typography
                                                // mt="12px"
                                                component="span"
                                                color="#808080"
                                                fontFamily="open sans"
                                                fontSize={14}
                                                fontWeight={400}
                                            >
                                                {
                                                    profile?.currentEmployment
                                                        ?.jobTitle
                                                }
                                            </Typography>
                                        ) : null} */}
                                    </Grid>
                                    <Grid
                                        item
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '12px'
                                        }}
                                    >
                                        <Typography
                                            component={'p'}
                                            color="#23282B"
                                            fontFamily="open sans"
                                            fontSize={isMobile ? 14 : 16}
                                            fontWeight={400}
                                        >
                                            {
                                                profile?.currentEmployment
                                                    ?.jobTitle
                                            }
                                        </Typography>
                                        {/* <CircleIcon
                                            sx={{
                                                fontSize: '6px'
                                            }}
                                            htmlColor="#C5C5C5"
                                        />
                                        {profile?.currentEmployment
                                            ?.jobTitle ? (
                                            <Typography
                                                // mt="12px"
                                                component="span"
                                                color="#23282B"
                                                fontFamily="open sans"
                                                fontSize={14}
                                                fontWeight={400}
                                            >
                                                {
                                                    profile?.currentEmployment
                                                        ?.jobTitle
                                                }
                                            </Typography>
                                        ) : null} */}
                                    </Grid>
                                    <Grid item>
                                        <Grid
                                            container
                                            gap={'4px'}
                                            alignItems="center"
                                        >
                                            {profile?.address ? (
                                                <>
                                                    <Grid item>
                                                        <InfoChip
                                                            type="location"
                                                            label={`${
                                                                profile?.address
                                                                    ?.city ?? ''
                                                            }, ${
                                                                profile?.address
                                                                    ?.country ??
                                                                ''
                                                            }`}
                                                        />
                                                    </Grid>
                                                    {/* {isMobile ? null : (
                                                        <CircleIcon
                                                            sx={{
                                                                fontSize:
                                                                    '7.25px'
                                                            }}
                                                            htmlColor="#494949"
                                                        />
                                                    )} */}
                                                </>
                                            ) : null}
                                            {profile?.phoneNumber ? (
                                                <>
                                                    <Grid item>
                                                        <InfoChip
                                                            type="phone"
                                                            label={
                                                                profile?.phoneNumber ??
                                                                ''
                                                            }
                                                        />
                                                    </Grid>
                                                    {/* {isMobile ? null : (
                                                        <CircleIcon
                                                            sx={{
                                                                fontSize:
                                                                    '7.25px'
                                                            }}
                                                            htmlColor="#494949"
                                                        />
                                                    )} */}
                                                </>
                                            ) : null}
                                            {profile?.emailAddress ? (
                                                <>
                                                    <Grid item>
                                                        <InfoChip
                                                            type="email"
                                                            label={
                                                                profile?.emailAddress ??
                                                                ''
                                                            }
                                                        />
                                                    </Grid>
                                                </>
                                            ) : null}
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    style={{
                                                        width: 'auto',
                                                        minWidth: 'auto',
                                                        color: '#808080',
                                                        backgroundColor:
                                                            '#F2F2F2',
                                                        zIndex: 5
                                                    }}
                                                    sx={{
                                                        p: '6px'
                                                    }}
                                                    onClick={
                                                        handleOnEditProfileInfo
                                                    }
                                                >
                                                    <EditIcon fontSize="small" />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {isMobile ? null : (
                                <Grid
                                    item
                                    flexShrink={1}
                                    position={{ sm: 'absolute', lg: 'static' }}
                                    right={'21px'}
                                    bottom={'120px'}
                                >
                                    <Box py={1}>
                                        <ScoreCard score={score} />
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </Grid>
                {/* {isMobile ? (
                    <Box width="100%" height="2px" bgcolor="#f2f2f2"></Box>
                ) : null} */}
                <Grid item>
                    <Grid
                        container
                        wrap={isTablet ? undefined : 'nowrap'}
                        //
                        spacing={{ xs: 1, lg: 1.5 }}
                    >
                        <Grid item width="100%" xs={isTablet ? 12 : 8}>
                            <Section px={0}>
                                <Box
                                    px={{ xs: '16px', sm: '20px', lg: '40px' }}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'flex-start'
                                    }}
                                >
                                    <Box>
                                        <Typography
                                            component="h3"
                                            color="#23282B"
                                            fontFamily="open sans"
                                            fontSize={20}
                                            fontWeight={600}
                                            mb={1}
                                        >
                                            Bio
                                        </Typography>
                                        <Typography
                                            component="h4"
                                            color="#808080"
                                            fontFamily="open sans"
                                            fontSize={16}
                                            fontWeight={400}
                                            mb={1.5}
                                        >
                                            Tell the world about yourself.
                                        </Typography>
                                    </Box>
                                    <Button
                                        sx={{
                                            mt: 1,
                                            height: {xs: '25px', sm: '36px'},
                                            width: '87px',
                                            maxHeight: '40px',
                                            color: '#05668D',
                                            backgroundColor: '#F2F2F2',
                                            borderRadius: '8px',
                                            textTransform: 'none',
                                            textDecoration: 'none',
                                        }}
                                        onClick={handleOnEditAboutMe}
                                        startIcon={<EditBtn />}
                                    >
                                        Edit
                                    </Button>
                                </Box>
                                <Divider />
                                <Box
                                    pt={{ xs: '12px', sm: '20px' }}
                                    px={{ xs: '16px', sm: '20px', lg: '40px' }}
                                    pb="8px"
                                >
                                    <Typography
                                        ref={containerRef}
                                        component="p"
                                        color="#23282B"
                                        fontFamily="open sans"
                                        fontSize={isTablet ? 14 : 16}
                                        fontWeight={400}
                                        // mt={4}
                                        mb={'12px'}
                                        style={{
                                            display: '-webkit-box',
                                            WebkitLineClamp: showMore
                                                ? undefined
                                                : 4,
                                            WebkitBoxOrient: 'vertical',
                                            overflow: 'hidden'
                                        }}
                                    >
                                        {profile?.bio}
                                    </Typography>
                                    {isTruncated ? (
                                        <Box sx={{ width: '100%' }}>
                                            <Button
                                                disableRipple
                                                variant="contained"
                                                sx={{
                                                    width: '118px',
                                                    backgroundColor: '#f2f2f2',
                                                    borderRadius: '8px',
                                                    padding: '0 8px',
                                                    '&:hover': {
                                                        bgcolor: '#f2f2f2'
                                                    }
                                                }}
                                                endIcon={<SeeMoreIcon />}
                                                onClick={() => {
                                                    setShowMore(!showMore);
                                                }}
                                            >
                                                <Typography
                                                    color={'#05668D'}
                                                    fontFamily="open sans"
                                                    fontWeight={700}
                                                    fontSize={14}
                                                    sx={{
                                                        textTransform: 'none',
                                                        py: 1
                                                    }}
                                                >
                                                    {showMore
                                                        ? 'See less'
                                                        : 'See more'}
                                                </Typography>
                                            </Button>
                                        </Box>
                                    ) : null}
                                </Box>
                            </Section>
                            <Box
                                bgcolor="#fff"
                                py="8px"
                                px={{ xs: '16px', sm: '20px', lg: '40px' }}
                                mb="4px"
                            >
                                <Typography
                                    fontSize="16px"
                                    fontWeight={600}
                                    color="#23282B"
                                >
                                    Improve your Job matches
                                </Typography>
                                <Typography fontSize="14px" color="#808080">
                                    Add qualifications and documents that
                                    improve job matches
                                </Typography>
                            </Box>
                            <Box
                                bgcolor="#fff"
                                py="8px"
                                px={{ xs: '16px', sm: '20px', lg: '40px' }}
                                // heading={
                                //     <SectionHeading px='40px'>
                                //         Improve your Job matches
                                //     </SectionHeading>
                                // }
                                // py={2.5}
                                borderBottom="1px solid #D8D8D8"
                            >
                                <ListItemButton
                                    component={Link}
                                    to={`/user/qualifications/${user?.professionalProfile?.id}`}
                                    style={{ textDecoration: 'none' }}
                                    sx={{ padding: 0, m: 0 }}
                                >
                                    <Grid
                                        container
                                        wrap="nowrap"
                                        alignItems="center"
                                    >
                                        <Grid flexGrow={1} item>
                                            <Typography
                                                component="h4"
                                                color="#23282B"
                                                fontFamily="open sans"
                                                fontSize={16}
                                                // fontWeight={600}
                                            >
                                                Qualifications
                                            </Typography>
                                            <Typography
                                                component="p"
                                                color="#808080"
                                                fontFamily="open sans"
                                                fontSize={16}
                                                fontWeight={400}
                                            >
                                                Highlight your skills, education
                                                and experience
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <ArrowRightIcon
                                                fontSize="large"
                                                htmlColor="#808080"
                                                sx={{
                                                    backgroundColor: '#f2f2f2',
                                                    borderRadius: '8px'
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </ListItemButton>
                            </Box>
                            <Box
                                bgcolor="#fff"
                                py="8px"
                                px={{ xs: '16px', sm: '20px', lg: '40px' }}
                                // heading={
                                //     <SectionHeading px='40px'>
                                //         Improve your Job matches
                                //     </SectionHeading>
                                // }
                                // py={2.5}
                                borderBottom="1px solid #D8D8D8"
                            >
                                <ListItemButton
                                    component={Link}
                                    to={`/resume/${user?.professionalProfile?.id}`}
                                    style={{ textDecoration: 'none' }}
                                    sx={{ padding: 0, m: 0 }}
                                >
                                    <Grid
                                        container
                                        wrap="nowrap"
                                        alignItems="center"
                                    >
                                        <Grid flexGrow={1} item>
                                            <Typography
                                                component="h4"
                                                color="#23282B"
                                                fontFamily="open sans"
                                                fontSize={16}
                                                // fontWeight={600}
                                            >
                                                Resume
                                            </Typography>
                                            <Typography
                                                component="p"
                                                color="#808080"
                                                fontFamily="open sans"
                                                fontSize={16}
                                                fontWeight={400}
                                            >
                                                View and share your online
                                                resume page
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <ArrowRightIcon
                                                fontSize="large"
                                                htmlColor="#808080"
                                                sx={{
                                                    backgroundColor: '#f2f2f2',
                                                    borderRadius: '8px'
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </ListItemButton>
                            </Box>
                            <Box
                                bgcolor="#fff"
                                py="8px"
                                px={{ xs: '16px', sm: '20px', lg: '40px' }}
                            >
                                {/* <Box py={4.5}>
                                        <Divider />
                                    </Box> */}

                                <ListItemButton
                                    style={{ textDecoration: 'none' }}
                                    sx={{ padding: 0, m: 0 }}
                                >
                                    <Grid
                                        container
                                        wrap="nowrap"
                                        alignItems="center"
                                    >
                                        <Grid flexGrow={1} item>
                                            <Typography
                                                component="h4"
                                                color="#23282B"
                                                fontFamily="open sans"
                                                fontSize={16}
                                            >
                                                Job Preference
                                            </Typography>
                                            <Typography
                                                component="p"
                                                color="#808080"
                                                fontFamily="open sans"
                                                fontSize={16}
                                                fontWeight={400}
                                            >
                                                Save specific details about your
                                                desired job
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <ArrowRightIcon
                                                fontSize="large"
                                                htmlColor="#808080"
                                                sx={{
                                                    backgroundColor: '#f2f2f2',
                                                    borderRadius: '8px'
                                                }}
                                                style={{
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() =>
                                                    navigate('/job-preference')
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </ListItemButton>
                            </Box>

                            {/* <Container sx={{display: "flex",
                                            width: "733px",
                                            padding: "12px 40px",
                                            flexDirection: "column",
                                            alignItems: "flex-start",
                                            gap: "20px",
                                            background:"#FFF",
                                            height: "auto" }}>
                                                <Box sx={{width:"653px", height:"112px", display: "flex",
                                                            flexDirection: "column",
                                                            alignItems: "flex-start",
                                                            gap: "5px",
                                    
                                                            alignSelf: "stretch",}}>
                                                                <Box sx={{height:"56px", width: "100%", display: "flex", justifyContent: "space-between",
                                                                            alignItems: "flex-start",
                                                                            alignSelf: "stretch",}}>
                                                                    <Box sx={{width:"133px", height:"56px",}}>
                                                                        <Typography variant='h3' sx={{color: "#23282B",
                                                                                    fontFamily: "Open Sans",
                                                                                    fontSize: "18px",
                                                                                    fontStyle: "normal",
                                                                                    fontWeight: "600",
                                                                                    lineHeight: "28px",}}>Activities</Typography>
                                                                        <Typography variant='h5' sx={{color: "#808080",
                                                                                        fontFamily: "Open Sans",
                                                                                        fontSize: "15px",
                                                                                        fontStyle: "normal",
                                                                                        fontWeight: "400",
                                                                                        lineHeight: "24px", 
                                                                                        letterSpacing: "0.08px",}}>400+ connections</Typography>

                                                                    </Box>
                                                                    <Box>
                                                                        <Button sx={{display: "flex",
                                                                                    height: "40px",
                                                                                    padding: "0px 8px",
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center",
                                                                                    borderRadius: "8px",
                                                                                    border: "1px solid #999",
                                                                                    textDecoration:"none"
                                                                                    }}><AddIcon/>Follow</Button>
                                                                    </Box>
                                                                </Box>
                                                                <Box sx={{width:"517px", height: "65px",display:"flex", alignItems:"flex-start", gap:"16px"}}>
                                                                    <Button sx={{textDecoration:"none",
                                                                                height:"40px",
                                                                                width:"74px", 
                                                                                display: "flex",
                                                                                padding: "10px 16px",
                                                                                alignItems: "flex-start",
                                                                                gap: "10px",
                                                                                borderRadius: "100px",
                                                                                background: "#21682B", color: "#FFF",
                                                                                fontFamily: "Open Sans",
                                                                                fontSize: "16px",
                                                                                fontStyle: "normal",
                                                                                fontWeight: "600",
                                                                                lineHeight: "24px" ,
                                                                                letterSpacing: "0.024px",
                                                                                borderBottom:"none",
                                                                                '&:hover': {
                                                                                textDecoration: 'none', 
                                                                                borderBottom: 'none', 
                                                                                },}}>Posts</Button>
                                                                    <Button sx={{textDecoration:"none", width:"117px", 
                                                                                height:"40px", 
                                                                                display: "flex",
                                                                                padding: "10px 16px",
                                                                                alignItems: "flex-start",
                                                                                gap: "10px",
                                                                                borderRadius: "100px",
                                                                                border: "1px solid #EDEDED", 
                                                                                color: "#5B5B5B",
                                                                                fontFamily: "Open Sans",
                                                                                fontSize: "16px",
                                                                                fontStyle: "normal",
                                                                                fontWeight: "600",
                                                                                lineHeight: "24px", 
                                                                                letterSpacing: "0.024px",
                                                                                //  borderBottom:"none",
                                                                                '&:hover': {
                                                                                textDecoration: 'none', 
                                                                                borderBottom: 'none', 
                                                                                }, }}>Comments</Button>
                                                                    <Button sx={{textDecoration:"none", height:"40px", width:"88px",
                                                                                display: "flex",
                                                                                padding: "10px 16px",
                                                                                alignItems: "flex-start",
                                                                                gap: "10px",
                                                                                borderRadius: "100px",
                                                                                border: "1px solid #EDEDED", 
                                                                                color: "#5B5B5B",
                                                                                fontFamily: "Open Sans",
                                                                                fontSize: "16px",
                                                                                fontStyle: "normal",
                                                                                fontWeight: "600",
                                                                                lineHeight: "24px", 
                                                                                letterSpacing: "0.024px",
                                                                                //  borderBottom:"none",
                                                                                '&:hover': {
                                                                                textDecoration: 'none', 
                                                                                borderBottom: 'none', 
                                                                                },}}>Images</Button>
                                                                    <Button sx={{textDecoration:"none", height:"40px", width:"88px",
                                                                                display: "flex",
                                                                                padding: "10px 16px",
                                                                                alignItems: "flex-start",
                                                                                gap: "10px",
                                                                                borderRadius: "100px",
                                                                                border: "1px solid #EDEDED", 
                                                                                color: "#5B5B5B",
                                                                                fontFamily: "Open Sans",
                                                                                fontSize: "16px",
                                                                                fontStyle: "normal",
                                                                                fontWeight: "600",
                                                                                lineHeight: "24px", 
                                                                                letterSpacing: "0.024px",
                                                                                //  borderBottom:"none",
                                                                                '&:hover': {
                                                                                textDecoration: 'none', 
                                                                                borderBottom: 'none',
                                                                                },}}>Videos</Button>
                                                                    <Button sx={{textDecoration:"none", height:"40px", width:"90px",
                                                                                display: "flex",
                                                                                padding: "10px 16px",
                                                                                alignItems: "flex-start",
                                                                                gap: "10px",
                                                                                borderRadius: "100px",
                                                                                border: "1px solid #EDEDED", 
                                                                                color: "#5B5B5B",
                                                                                fontFamily: "Open Sans",
                                                                                fontSize: "16px",
                                                                                fontStyle: "normal",
                                                                                fontWeight: "600",
                                                                                lineHeight: "24px", 
                                                                                letterSpacing: "0.024px",
                                                                                //  borderBottom:"none",
                                                                                '&:hover': {
                                                                                textDecoration: 'none', 
                                                                                borderBottom: 'none', 
                                                                                },}}>Articles</Button>
                                                    

                                                                </Box>

                                                </Box>

                                                <PostFeed
                                                    username="Ronald Richard"
                                                    hoursAgo="3"
                                                    image={cambridge}
                                                    altImg="alt image"
                                                    postDetail="Sorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos"
                                                    numberOfLikes={74}
                                                    numberOfComments={124}
                                                    numberOfReposts={23}
                                                />
                                                
                                                <PostFeed
                                                    username="Ronald Richard"
                                                    hoursAgo="3"
                                                    image={cambridge}
                                                    altImg="alt image"
                                                    postDetail="Sorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos"
                                                    numberOfLikes={74}
                                                    numberOfComments={124}
                                                    numberOfReposts={23}
                                                />
                                                
                                                <PostFeed
                                                    username="Ronald Richard"
                                                    hoursAgo="3"
                                                    image={cambridge}
                                                    altImg="alt image"
                                                    postDetail="Sorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos"
                                                    numberOfLikes={74}
                                                    numberOfComments={124}
                                                    numberOfReposts={23}
                                                />

                                                <Box>
                                                    <Box>
                                                        <Button sx={{display: "flex",
                                                            width: "653px",
                                                            height: "32px",
                                                            padding: "0px 8px",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            alignSelf: "stretch",
                                                            borderRadius: "8px",
                                                            background: "#F2F2F2",
                                                            textDecoration:"none"
                                                            }}>show all posts
                                                            <Box sx={{display:"flex", alignItems:"center", marginLeft:"4px"}}>
                                                                <ArrowRightIcon/>
                                                            </Box>
                                                        </Button>
                                                    </Box>
                                                    
                                                </Box>                                      
                                                

                                        

                            </Container> */}

                            {/* <Section
                                py={2.5}
                                heading={
                                    <SectionHeading>Resumes</SectionHeading>
                                }
                            >
                                <Grid
                                    container
                                    gap={2.5}
                                    alignItems="center"
                                    wrap="nowrap"
                                >
                                    <Grid item>
                                        <Image
                                            src={cambridge}
                                            alt="University of Birmingham"
                                            sx={{
                                                width: '66px',
                                                height: '100px',
                                                objectFit: 'cover'
                                            }}
                                            border="3px #fff solid"
                                            borderRadius={2}
                                            display="block"
                                        />
                                    </Grid>
                                    <Grid item flexGrow={1}>
                                        <Typography
                                            component="h4"
                                            color="#23282B"
                                            fontFamily="open sans"
                                            fontSize={16}
                                            fontWeight={600}
                                            mb={1}
                                        >
                                            Jobfactor Resume
                                        </Typography>
                                        <Typography
                                            component="p"
                                            color="#808080"
                                            fontFamily="open sans"
                                            fontSize={16}
                                            fontWeight={400}
                                            mb={1}
                                        >
                                            Updated Jun 24, 2023
                                        </Typography>
                                        <Grid
                                            container
                                            alignItems="center"
                                            gap={0.5}
                                        >
                                            <VisibilityOffIcon />
                                            <Typography
                                                component="p"
                                                color="#494949"
                                                fontFamily="open sans"
                                                fontSize={14}
                                                fontWeight={600}
                                            >
                                                Private
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <ArrowRightIcon
                                            fontSize="large"
                                            htmlColor="#055C7F"
                                        />
                                    </Grid>
                                </Grid>
                            </Section> */}
                            <Box
                                mt={{ xs: '8px' }}
                                px={{ xs: '16px', sm: '20px', lg: '40px' }}
                                py="8px"
                                bgcolor="#fff"
                            >
                                <Box
                                    mb={'8px'}
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Typography
                                        // component="h2"
                                        color="#23282B"
                                        fontFamily="open sans"
                                        // lineHeight="1.125"
                                        fontSize="16px"
                                        fontWeight={600}
                                    >
                                        Activities
                                    </Typography>

                                    <Typography
                                        component="h2"
                                        color="#808080"
                                        fontFamily="open sans"
                                        lineHeight="24px"
                                        fontSize={16}
                                        fontWeight={400}
                                    >
                                        {totalPosts + ' posts'}
                                    </Typography>
                                </Box>
                                <Box
                                    display="flex"
                                    flexWrap="wrap"
                                    height="fit-content"
                                    gap={{ xs: '4px', sm: 0 }}
                                    bgcolor={{
                                        xs: 'transparent',
                                        sm: '#f2f2f2'
                                    }}
                                >
                                    {currentActivity.map((item) => (
                                        <Box
                                            height="36px"
                                            bgcolor="#f2f2f2"
                                            width={{
                                                xs: '49.3%',
                                                sm: `${100 / 4}%`
                                            }}
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',

                                                borderBottomColor:
                                                    activity === item
                                                        ? '#05668D'
                                                        : 'transparent',
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => setActivity(item)}
                                            borderBottom="2px solid"
                                        >
                                            <Typography
                                                fontFamily="open sans"
                                                fontSize={14}
                                                fontWeight={600}
                                                lineHeight="20px"
                                                color={
                                                    activity === item
                                                        ? '#05668D'
                                                        : '#808080'
                                                }
                                            >
                                                {item}
                                            </Typography>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                            <Box py={'4px'} bgcolor="#f2f2f2">
                                {activity === 'Posts' ? (
                                    !isLoaded ? (
                                        [1, 2].map((_) => <SkeletonLoader />)
                                    ) : posts?.length === 0 ? (
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            gap="12px"
                                            pl={isMobile ? '16px' : undefined}
                                        >
                                            <Alert />
                                            <Typography> No Posts</Typography>
                                        </Box>
                                    ) : (
                                        (posts ?? [])
                                            ?.slice(0, 3)
                                            ?.map((feed: any, index: any) => (
                                                <FeedItem
                                                    profile
                                                    key={index}
                                                    feed={feed}
                                                />
                                            ))
                                    )
                                ) : activity === 'Achievements' ? (
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        gap="12px"
                                        pl={isMobile ? '16px' : undefined}
                                    >
                                        <Alert />
                                        <Typography>
                                            {' '}
                                            No Achievements
                                        </Typography>
                                    </Box>
                                ) : activity === 'Articles' ? (
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        gap="12px"
                                        p={isMobile ? '16px' : undefined}
                                    >
                                        <Alert />
                                        <Typography>No Articles</Typography>
                                    </Box>
                                ) : (
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        gap="12px"
                                        p={isMobile ? '16px' : undefined}
                                    >
                                        <Alert />
                                        <Typography>No Jobs</Typography>
                                    </Box>
                                )}
                                <Box
                                    py="8px"
                                    px={{ xs: '16px', sm: '20px', lg: '40px' }}
                                    bgcolor="#fff"
                                    mb="4px"
                                >
                                    {posts?.length > 0 && (
                                        <Button
                                            variant="contained"
                                            sx={{
                                                backgroundColor: '#f2f2f2',
                                                borderRadius: '8px',
                                                width: '100%',
                                                '&:hover': {
                                                    bgcolor: '#f2f2f2'
                                                }
                                                // mt: '16px',
                                            }}
                                            onClick={() =>
                                                navigate('/activities')
                                            }
                                        >
                                            <Typography
                                                color={'#05668D'}
                                                fontFamily="open sans"
                                                fontWeight={600}
                                                fontSize={16}
                                                sx={{
                                                    textTransform: 'none',
                                                    py: 0.5,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '12px'
                                                }}
                                            >
                                                Show all posts{' '}
                                                <ArrowIconRight />
                                            </Typography>
                                        </Button>
                                    )}
                                </Box>
                            </Box>
                            <Box
                                bgcolor="#fff"
                                py="8px"
                                px={{ xs: '16px', sm: '20px', lg: '40px' }}
                                mb="4px"
                            >
                                <Typography
                                    fontSize="16px"
                                    fontWeight={600}
                                    color="#23282b"
                                >
                                    Help other Job Seekers
                                </Typography>
                            </Box>
                            <Box
                                bgcolor="#fff"
                                py="8px"
                                px={{ xs: '16px', sm: '20px', lg: '40px' }}
                                // mb="4px"
                                // heading={
                                //     <SectionHeading>
                                //         Help other Job Seekers
                                //     </SectionHeading>
                                // }
                            >
                                {isMobile ? (
                                    <Grid
                                        container
                                        gap={1}
                                        alignItems="center"
                                        // wrap="nowrap"
                                        direction="column"
                                    >
                                        <Box
                                            py={1}
                                            pr={1}
                                            width="100%"
                                            display="flex"
                                            alignItems="center"
                                            gap="8px"
                                        >
                                            <MedalIcon
                                                width="32px"
                                                height="32px"
                                            />
                                            <Typography
                                                component="h4"
                                                color="#23282B"
                                                fontFamily="open sans"
                                                fontSize={16}
                                                fontWeight={600}
                                                mb={1}
                                                flex={1}
                                            >
                                                Review companies
                                            </Typography>
                                            <ArrowRightIcon
                                                fontSize="large"
                                                htmlColor="#055C7F"
                                            />
                                        </Box>
                                        <Grid container flexGrow={1}>
                                            <Typography
                                                component="p"
                                                color="#808080"
                                                fontFamily="open sans"
                                                fontSize={14}
                                                fontWeight={400}
                                            >
                                                Review companies you have worked
                                                for, this contributes to your
                                                Jobfactor score
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <Grid
                                        container
                                        gap={2}
                                        alignItems="center"
                                        wrap="nowrap"
                                    >
                                        <Grid item>
                                            <MedalIcon
                                                width="32px"
                                                height="32px"
                                            />
                                        </Grid>
                                        <Grid item flexGrow={1}>
                                            <Typography
                                                color="#23282B"
                                                fontFamily="open sans"
                                                fontSize={16}
                                                fontWeight={600}
                                            >
                                                Review companies
                                            </Typography>
                                            <Typography
                                                component="p"
                                                color="#808080"
                                                fontFamily="open sans"
                                                fontSize={16}
                                                fontWeight={400}
                                            >
                                                Review companies you have worked
                                                for, this contributes to your
                                                Jobfactor score
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <ArrowRightIcon
                                                fontSize="large"
                                                htmlColor="#055C7F"
                                                sx={{
                                                    bgcolor: '#f2f2f2',
                                                    borderRadius: '8px'
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                            </Box>
                        </Grid>
                        <Grid
                            item
                            width="100%"
                            xs={isTablet ? 12 : 4}
                            mb="20px"
                        >
                            {isTablet ? null : (
                                <>
                                    <Box
                                        width="100%"
                                        // heading={
                                        //     <SectionHeading
                                        //         disable={true}
                                        //         ml={0}
                                        //     >
                                        //         Top Professionals in your industry
                                        //     </SectionHeading>
                                        // }
                                        onClick={() => {}}
                                        mb="12px"
                                        // py={'16px'}
                                    >
                                        <Box
                                            bgcolor="#fff"
                                            p="8px 16px"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="space-between"
                                            onClick={() => {
                                                navigate(
                                                    `/user/qualifications/${user?.professionalProfile?.id}`
                                                );
                                            }}
                                        >
                                            <Typography
                                                fontFamily="Open Sans"
                                                fontSize="20px"
                                                fontWeight={600}
                                                lineHeight="28px"
                                                sx={{ cursor: 'pointer' }}
                                            >
                                                Education
                                            </Typography>
                                            <IconButton
                                                onClick={() => {}}
                                                sx={{
                                                    // background: '#DFEBF0',
                                                    borderRadius: '8px',
                                                    padding: '8px'
                                                }}
                                            >
                                                <ArrowRight />
                                            </IconButton>
                                        </Box>
                                        {profile?.qualifications?.length !==
                                            0 && (
                                            <Box
                                                mt="4px"
                                                bgcolor="#fff"
                                                px={isMobile ? 0 : '12px'}
                                                py="20px"
                                                gap="20px"
                                                display="flex"
                                                flexDirection="column"
                                            >
                                                {profile?.qualifications?.map(
                                                    (x: any) => (
                                                        <EducationSummary
                                                            key={x.id}
                                                            data={{
                                                                university: {
                                                                    name: x.school,
                                                                    image: cambridge
                                                                },
                                                                major: x.degree,
                                                                yearStarted:
                                                                    x.startDate,
                                                                yearEnded:
                                                                    x.endDate
                                                            }}
                                                        />
                                                    )
                                                )}
                                            </Box>
                                        )}
                                    </Box>
                                    <Box
                                        width="100%"
                                        // heading={
                                        //     <SectionHeading
                                        //         disable={true}
                                        //         ml={0}
                                        //     >
                                        //         Top Professionals in your industry
                                        //     </SectionHeading>
                                        // }
                                        onClick={() => {}}
                                        mb="12px"
                                        // py={'16px'}
                                    >
                                        <Box
                                            bgcolor="#fff"
                                            p="8px 16px"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="space-between"
                                            onClick={() => {
                                                navigate(
                                                    `/user/qualifications/${user?.professionalProfile?.id}`
                                                );
                                            }}
                                        >
                                            <Typography
                                                fontFamily="Open Sans"
                                                fontSize="20px"
                                                fontWeight={600}
                                                lineHeight="28px"
                                                sx={{ cursor: 'pointer' }}
                                            >
                                                Work Experience
                                            </Typography>
                                            <IconButton
                                                onClick={() => {}}
                                                sx={{
                                                    // background: '#DFEBF0',
                                                    borderRadius: '8px',
                                                    padding: '8px'
                                                }}
                                            >
                                                <ArrowRight />
                                            </IconButton>
                                        </Box>
                                        {!profile?.employments
                                            ?.length ? null : (
                                            <Box
                                                mt="4px"
                                                bgcolor="#fff"
                                                px={isMobile ? 0 : '12px'}
                                                py="20px"
                                                gap="20px"
                                                display="flex"
                                                flexDirection="column"
                                            >
                                                {profile?.employments?.map(
                                                    (x: any) => (
                                                        <WorkSummary
                                                            key={x.id}
                                                            data={{
                                                                employer: {
                                                                    name: x.companyName,
                                                                    image: GenericCompanyLogo
                                                                },
                                                                title: x.jobTitle,
                                                                yearStarted:
                                                                    x.startDate,
                                                                yearEnded:
                                                                    x.endDate,
                                                                isCurrentPosition:
                                                                    x.isCurrentPosition,
                                                                rankings: [
                                                                    Ranking.Leader,
                                                                    Ranking.Expert,
                                                                    Ranking.Advanced,
                                                                    Ranking.Experienced
                                                                ]
                                                            }}
                                                        />
                                                    )
                                                )}
                                            </Box>
                                        )}
                                    </Box>
                                </>
                            )}

                            <Box
                                // heading={
                                // <SectionHeading ml={0} fontSize={106}>
                                //     Analytics
                                // </SectionHeading>
                                // }
                                onClick={() => {}}
                                mb="4px"
                                bgcolor="transparent"
                                // pl={{xs: '16px', lg: 0}}
                            >
                                <Box
                                    bgcolor="#fff"
                                    p="8px 16px"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    mb="4px"
                                >
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize="20px"
                                        fontWeight={600}
                                        lineHeight="28px"
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        Analytics
                                    </Typography>
                                    {/* <IconButton
                                        onClick={() => {}}
                                        sx={{
                                            // background: '#DFEBF0',
                                            borderRadius: '8px',
                                            padding: '8px'
                                        }}
                                    >
                                        <ArrowRight />
                                    </IconButton> */}
                                </Box>
                                <Grid
                                    container
                                    gap={1}
                                    bgcolor="#fff"
                                    overflow="hidden"
                                    borderRadius={1.5}
                                    wrap="nowrap"
                                    height="140px"
                                    p={{
                                        xs: '8px 16px',
                                        sm: '8px 20px',
                                        lg: 1
                                    }}
                                >
                                    <Grid
                                        item
                                        bgcolor="#f2f2f2"
                                        flexGrow={1}
                                        width="100%"
                                        // maxWidth="33%"
                                    >
                                        <Grid
                                            container
                                            flexDirection="column"
                                            alignItems="center"
                                            justifyContent="center"
                                            height="100%"
                                        >
                                            <AnalyticsProfile />
                                            <Typography
                                                component="p"
                                                color="#23282B"
                                                fontFamily="open sans"
                                                fontSize={{ xs: 11, sm: 14 }}
                                                fontWeight={600}
                                                textAlign="center"
                                            >
                                                PROFILE
                                                <br />
                                                VIEWS
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        bgcolor="#f2f2f2"
                                        flexGrow={1}
                                        width="100%"
                                        // maxWidth="33%"
                                    >
                                        <Grid
                                            container
                                            flexDirection="column"
                                            alignItems="center"
                                            justifyContent="center"
                                            height="100%"
                                        >
                                            <AnalyticsSearch />
                                            <Typography
                                                component="p"
                                                color="#23282B"
                                                fontFamily="open sans"
                                                fontSize={{ xs: 11, sm: 14 }}
                                                fontWeight={600}
                                                textAlign="center"
                                            >
                                                SEARCH
                                                <br />
                                                APPEARANCES
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        bgcolor="#f2f2f2"
                                        flexGrow={1}
                                        width="100%"
                                        // maxWidth="33%"
                                    >
                                        <Grid
                                            container
                                            flexDirection="column"
                                            alignItems="center"
                                            justifyContent="center"
                                            height="100%"
                                        >
                                            <AnalyticsGraph />
                                            <Typography
                                                component="p"
                                                color="#23282B"
                                                fontFamily="open sans"
                                                fontSize={{ xs: 11, sm: 14 }}
                                                fontWeight={600}
                                                textAlign="center"
                                            >
                                                POSTS
                                                <br />
                                                ENGAGEMENTS
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box
                                py={1}
                                bgcolor="#fff"
                                px={isMobile ? '16px' : 1}
                            >
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: '#f2f2f2',
                                        borderRadius: '8px',
                                        width: '100%'
                                        // ml: isMobile ? '16px' : undefined
                                    }}
                                >
                                    <Typography
                                        color={'primary'}
                                        fontFamily="open sans"
                                        fontWeight={600}
                                        fontSize={14}
                                        sx={{ textTransform: 'none', py: 0.5 }}
                                    >
                                        Upgrade to Premium plan
                                    </Typography>
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/* <EditContactInfoDialog
                open={openContactInfoModal}
                setOpen={setOpenContactInfoModal}
            /> */}
            <EditProfileAboutMeDialog
                open={openAboutMeModal}
                setOpen={setOpenAboutMeModal}
                refetch={refetch}
            />
            <EditProfileInfoDialog
                open={openProfileInfoModal}
                setOpen={setOpenProfileInfoModal}
            />
        </Container>
    );
}

export default UsersPage;
