const AnalyticsSearch = () => (
    <svg
        width="37"
        height="36"
        viewBox="0 0 37 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            opacity="0.4"
            d="M17.183 30.03C24.6471 30.03 30.698 23.9791 30.698 16.515C30.698 9.05087 24.6471 3 17.183 3C9.71884 3 3.66797 9.05087 3.66797 16.515C3.66797 23.9791 9.71884 30.03 17.183 30.03Z"
            fill="#49B6FF"
        />
        <path
            d="M33.6521 28.425C33.1571 27.51 32.1071 27 30.6971 27C29.6321 27 28.7171 27.435 28.1771 28.185C27.6371 28.935 27.5171 29.94 27.8471 30.945C28.4921 32.895 29.6171 33.33 30.2321 33.405C30.3221 33.42 30.4121 33.42 30.5171 33.42C31.1771 33.42 32.1971 33.135 33.1871 31.65C33.9821 30.495 34.1321 29.34 33.6521 28.425Z"
            fill="#49B6FF"
        />
    </svg>
);

export default AnalyticsSearch;
