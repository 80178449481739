import React from 'react';

function EmailFormIcon() {
    return (
        <>
            <svg
                width="45"
                height="24"
                viewBox="0 0 45 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M17 21.25H7C3.35 21.25 1.25 19.15 1.25 15.5V8.5C1.25 4.85 3.35 2.75 7 2.75H17C20.65 2.75 22.75 4.85 22.75 8.5V15.5C22.75 19.15 20.65 21.25 17 21.25ZM7 4.25C4.14 4.25 2.75 5.64 2.75 8.5V15.5C2.75 18.36 4.14 19.75 7 19.75H17C19.86 19.75 21.25 18.36 21.25 15.5V8.5C21.25 5.64 19.86 4.25 17 4.25H7Z"
                    fill="#808080"
                />
                <path
                    d="M11.9998 12.868C11.1598 12.868 10.3098 12.608 9.65978 12.078L6.52978 9.57802C6.20978 9.31802 6.14978 8.84802 6.40978 8.52802C6.66978 8.20802 7.13978 8.14802 7.45978 8.40802L10.5898 10.908C11.3498 11.518 12.6398 11.518 13.3998 10.908L16.5298 8.40802C16.8498 8.14802 17.3298 8.19802 17.5798 8.52802C17.8398 8.84802 17.7898 9.32802 17.4598 9.57802L14.3298 12.078C13.6898 12.608 12.8398 12.868 11.9998 12.868Z"
                    fill="#808080"
                />
                <line x1="44.5" y1="4.5" x2="44.5" y2="19.5" stroke="#D9D9D9" />
            </svg>
        </>
    );
}

export default EmailFormIcon;
