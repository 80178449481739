import { useQuery } from 'react-query';
import { QueryKeys } from '../QueryKey';
import axiosInstance from '../axiosConfig';

export function useGetEmploymentById(employmentId: string, enabled: boolean) {
    const getEmploymentById = async (employmentId: string) => {
        const response = await axiosInstance.get(
            `/api/employments/${employmentId}`
        );
        const data = await response.data;
        return data;
    };
    return useQuery(
        QueryKeys.RetrieveEmploymentById,
        () => getEmploymentById(employmentId),
        {
            enabled: employmentId && enabled ? true : false
        }
    );
}
