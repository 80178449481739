import { Inbox, Mail } from '@mui/icons-material';
import { Avatar, Box, Collapse, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useAuth } from '../../utils/context/AuthContext';
import { PrimaryProfileType } from '../../utils/hooks/api/account/types';
import UserIcon3 from '../../assets/icons/UserIcon3';
import EyeIcon from '../../assets/icons/EyeIcon';
import Settings from '../../assets/icons/Settings';
import InviteDialog, { inviteDialogSignal } from '../InviteDialog';
import { useLocation, useNavigate } from 'react-router-dom';
import EyeMenuIcon from '../../assets/icons/EyeMenuIcon';
import MenuSettings from '../../assets/icons/MenuSettings';
import ExpandIcon from '../../assets/icons/ExpandIcon';
import ExpandLess from '../../assets/icons/ExpandLess';
import ExpandMore from '../../assets/icons/ExpandMore';

interface IPropType {
    open: HTMLElement | null;
    handleClose: () => void;
    showAccountTypes: boolean;
    setShowAccountTypes: (showAccountTypes : boolean) => void;
}

const pages = [
    {
        title: "Profile",
        route: '/users',
        icon: <UserIcon3 />
    },
    {
        title: "Reviews",
        route: '/reviews',
        icon: <EyeIcon />
    },
    {
        title: "Settings",
        route: '/settings',
        icon: <Settings />
    }
]
const items = [
    // {
    //     title: "Create company account",
    //     bottomDivider: true,
    //     handleClick: () => { }
    // },
    {
        title: "Invite",
        bottomDivider: false,
        handleClick: () => inviteDialogSignal.value = true
    },
    {
        title: "Help and support",
        bottomDivider: true,
        handleClick: () => { }
    },
]
// const accounts = ['test@gmail.com', 'ronaldrichie@outlook.com', 'rrichie@gmail.com']
const MobileDrawer = ({
    open = null,
    handleClose = () => {},
    showAccountTypes,
    setShowAccountTypes
}: IPropType) => {
    const navigate = useNavigate();
    const location = useLocation()
    const { user, signOut } = useAuth();
    const [show, setShow] = useState(false);
    
    const pages = [
        {
            title: 'Profile',
            route:
                user?.primaryProfile === 'COMPANY'
                    ? '/about-company'
                    : '/users',
            icon: UserIcon3
        },
        {
            title: 'Reviews',
            route: '/reviews',
            icon: EyeMenuIcon
        },
        {
            title: 'Settings',
            route: '/settings',
            icon: MenuSettings
        }
    ];
    const items = [
        // {
        //     title: 'Create account',
        //     bottomDivider: true,
        //     handleClick: () => {}
        // },
        {
            title: 'Invite',
            bottomDivider: false,
            handleClick: () => (inviteDialogSignal.value = true)
        },
        {
            title: 'Help and support',
            bottomDivider: true,
            handleClick: () => {}
        }
    ];
    return (
        <>
            <Drawer
                anchor={'right'}
                open={Boolean(open)}
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        zIndex: 9999,
                        top: '56px',
                        background: '#F2F2F2',
                        boxShadow:
                            '8px 8px 20px 0px rgba(0, 0, 0, 0.07), -8px 4px 20px 0px rgba(0, 0, 0, 0.07)'
                    }
                }}
                // sx={{zIndex: 9999}}
            >
                <Box sx={{ width: 285 }} key={user?.professionalProfile?.id}>
                    <List key={Math.random()}>
                        <ListItemButton
                            key={Math.random()}
                            onClick={() => setShow((prev) => !prev)}
                            sx={{
                                m: 0,
                                p: '12px 16px'
                            }}
                        >
                            <Box
                                key={Math.random()}
                                sx={{
                                    // display: 'flex',
                                    // gap: '12px',
                                    width: '100%'
                                }}
                            >
                                {/* <Avatar
                                    sx={{
                                        height: 24,
                                        width: 24
                                    }}
                                    src={
                                        user?.primaryProfile ===
                                        PrimaryProfileType.Professional
                                            ? user?.professionalProfile
                                                  ?.photoUrl
                                            : user?.primaryCompanyProfile
                                                  ?.photoUrl
                                    }
                                    alt="profile"
                                /> */}
                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'block'
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: '#23282B'
                                        }}
                                        variant="titleSmallBold"
                                        component={'p'}
                                    >
                                        {user?.primaryProfile ===
                                        PrimaryProfileType.Professional
                                            ? `${user?.professionalProfile?.firstName} ${user?.professionalProfile?.lastName}`
                                            : user?.primaryCompanyProfile
                                                  ?.companyName}
                                    </Typography>
                                    <Typography
                                        variant="titleSmallRegular"
                                        sx={{
                                            color: '#808080',
                                            mt: '2px'
                                        }}
                                        component={'p'}
                                    >
                                        {user?.primaryProfile ===
                                        PrimaryProfileType.Professional
                                            ? user?.professionalProfile
                                                  ?.emailAddress
                                            : user?.primaryCompanyProfile
                                                  ?.emailAddress}
                                    </Typography>
                                </Box>
                            </Box>
                            {show ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={show} timeout="auto" unmountOnExit>
                            {/* <List
                                component="div"
                                sx={{
                                    py: '8px'
                                }}
                                disablePadding
                            >
                                {accounts.map(x => (
                                    <ListItemButton
                                        sx={{
                                            p: 0
                                        }}
                                    >
                                        <ListItemText
                                            primary={x}
                                            sx={{
                                                ml: '52px',
                                                '& span': {
                                                    fontSize: 14,
                                                    color: (x === (user?.primaryProfile === PrimaryProfileType.Professional ? user?.professionalProfile?.emailAddress : user?.primaryCompanyProfile?.emailAddress)) ? '#05668D' : '#23282B'
                                                }
                                            }}
                                        />
                                    </ListItemButton>
                                ))}
                            </List> */}
                        </Collapse>
                        <Divider />
                        <Box
                            sx={{
                                p: '10px 16px',
                                pb: 0
                            }}
                        >
                            {pages.map((x) => (
                                <ListItem
                                    key={x.title}
                                    disablePadding
                                    sx={{
                                        mb: '10px'
                                    }}
                                    onClick={() => {
                                        navigate(x.route);
                                        handleClose();
                                    }}
                                >
                                    <ListItemButton
                                        sx={{
                                            '& .MuiListItemIcon-root': {
                                                minWidth: 'auto',
                                                mr: '20px'
                                            },
                                            p: 0
                                        }}
                                    >
                                        <ListItemIcon><x.icon isActive={location.pathname === x.route ? true : false} /></ListItemIcon>
                                        <ListItemText
                                            key={x.title}
                                            primary={x.title}
                                            sx={{
                                                '& span': {
                                                    fontSize: 14,
                                                    color: location.pathname === x.route ? '#05668D' : '#23282B'
                                                }
                                            }}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </Box>
                        <Divider />
                        <ListItem
                            sx={{
                                pr: 0
                            }}
                        >
                            <ListItemButton
                                sx={{
                                    py: 0
                                }}
                                onClick={() =>
                                    setShowAccountTypes(!showAccountTypes)
                                }
                            >
                                <ListItemText
                                    primary={'Create account'}
                                    sx={{
                                        ml: '24px',
                                        '& span': {
                                            fontSize: 14,
                                            color: '#23282B'
                                        }
                                    }}
                                />
                                {showAccountTypes ? (
                                    <ExpandLess />
                                ) : (
                                    <ExpandMore />
                                )}
                            </ListItemButton>
                        </ListItem>
                        {showAccountTypes && (
                            <>
                                {' '}
                                <ListItem
                                    key={Math.random()}
                                    sx={{
                                        pb: 0,
                                        pr: 0
                                    }}
                                >
                                    <ListItemButton
                                        key={Math.random()}                                        
                                        sx={{
                                            py: 0
                                        }}
                                        onClick={() =>
                                            navigate(
                                                '/create-account/professional'
                                            )
                                        }
                                    >
                                        <ListItemText
                                            key={Math.random()}                                            
                                            primary={'Professional'}
                                            sx={{
                                                ml: '24px',
                                                '& span': {
                                                    fontSize: 14,
                                                    color: '#23282B'
                                                }
                                            }}
                                        />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem
                                    key={Math.random()}                                    
                                    sx={{
                                        pr: 0
                                    }}
                                >
                                    <ListItemButton
                                        sx={{
                                            py: 0
                                        }}
                                        onClick={() =>
                                            navigate('/create-account/company')
                                        }
                                    >
                                        <ListItemText
                                            primary={'Comapny'}
                                            sx={{
                                                ml: '24px',
                                                '& span': {
                                                    fontSize: 14,
                                                    color: '#23282B'
                                                }
                                            }}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            </>
                        )}
                        <Divider />
                        {items.map((x) => (
                            <>
                                <ListItem
                                    key={x.title}
                                    sx={{
                                        pb: 0
                                    }}
                                >
                                    <ListItemButton
                                        key={x.title}
                                        sx={{
                                            py: 0
                                        }}
                                        onClick={x?.handleClick ?? undefined}
                                    >
                                        <ListItemText
                                            primary={x.title}
                                            sx={{
                                                ml: '24px',
                                                '& span': {
                                                    fontSize: 14,
                                                    color: '#23282B'
                                                }
                                            }}
                                        />
                                    </ListItemButton>
                                </ListItem>
                                <Divider
                                    sx={{
                                        pt: '8px',
                                        display: x.bottomDivider
                                            ? 'block'
                                            : 'none'
                                    }}
                                />
                            </>
                        ))}
                        <ListItem
                            key={Math.random()}
                            disablePadding
                            onClick={() => {
                                signOut();
                                navigate('/login');
                            }}
                        >
                            <ListItemText
                                key={Math.random()}
                                primary={'Sign out'}
                                sx={{
                                    mt: '8px',
                                    ml: '16px',
                                    '& span': {
                                        color: '#E75541'
                                    }
                                }}
                            />
                        </ListItem>
                    </List>
                </Box>
            </Drawer>
            <InviteDialog />
        </>
    );
};

export default MobileDrawer