import { generateHtml } from "./emailtemplates"


export const newJobAlert = {
    subject:"New Job Alert-Matched with your skill",
    html:generateHtml("Great news! An employer has just posted a job that aligns perfectly with your skills and experience. Don't miss out on this opportunity."),
}

export const connectionRequest = {
    subject:"Connect and Expand your network on JOBFACTOR",
    html: generateHtml("Congratulations!, Another professional on JOBFACTOR has sent you a connection request. Accepting this connection request will help you expand your network and unlock numerous opportunities")

}

export const provideFeedBack = {
    subject: 'Boost Your JOBFACTOR Score - Provide Feedback Now!',
    html:generateHtml("A connection is eager to get your feedback on their recent work achievement. Providing feedback not only helps them grow but also boosts your JOBFACTOR score.")
}
