const AchievementMedal = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
        >
            <path
                opacity="0.4"
                d="M54.3036 65.7995L54.2961 65.8006L54.2888 65.8019C52.902 66.0466 51.4728 66.1693 50.0007 66.1693C48.5285 66.1693 47.0993 66.0466 45.7125 65.8019L45.7052 65.8006L45.6978 65.7995C37.5517 64.6124 30.5489 59.9868 26.1243 53.4326C23.0948 48.888 21.334 43.4427 21.334 37.5026C21.334 21.6537 34.1518 8.83594 50.0007 8.83594C65.8495 8.83594 78.6673 21.6537 78.6673 37.5026C78.6673 43.4428 76.9064 48.8881 73.8769 53.4328C69.4523 59.9869 62.4496 64.6124 54.3036 65.7995Z"
                fill="#49B6FF"
                stroke="#49B6FF"
            />
            <path
                d="M70.2526 89.1718L51.115 67.1855C52.2514 67.1385 53.3648 67.0191 54.455 66.8271C62.6803 65.6263 69.7741 61.0405 74.363 54.5279L90.1886 70.3535L90.1919 70.3568C92.1959 72.3244 91.2201 75.8103 88.4269 76.4718L81.5521 78.0967L81.549 78.0975C79.8366 78.514 78.4746 79.8161 78.0963 81.5558C78.0959 81.5576 78.0955 81.5593 78.0951 81.5611L76.6391 87.6759L76.639 87.6762C75.9429 90.6072 72.2037 91.4909 70.2581 89.1782L70.2582 89.1781L70.2526 89.1718ZM45.546 66.8271C46.6366 67.0192 47.7504 67.1386 48.8873 67.1856L29.7481 89.2137L29.748 89.2137L29.7429 89.2198C27.7973 91.5325 24.0581 90.6488 23.362 87.7178L23.3619 87.7175L21.9036 81.5925L21.903 81.5901C21.4851 79.8721 20.1376 78.515 18.4456 78.1376L11.5741 76.5135C11.574 76.5135 11.5739 76.5135 11.5738 76.5135C8.7864 75.8531 7.79887 72.367 9.81238 70.3535L25.638 54.5279C30.2269 61.0405 37.3206 65.6263 45.546 66.8271Z"
                fill="#49B6FF"
                stroke="#49B6FF"
            />
            <path
                d="M52.4162 24.9141L54.8746 29.8307C55.2079 30.4974 56.0829 31.1641 56.8746 31.2891L61.3329 32.039C64.1662 32.4974 64.8329 34.5807 62.7912 36.6224L59.3329 40.0807C58.7496 40.664 58.4162 41.7891 58.6246 42.6224L59.6246 46.9141C60.4162 50.2891 58.6246 51.6223 55.6246 49.8306L51.4579 47.3723C50.7079 46.914 49.4579 46.914 48.7079 47.3723L44.5412 49.8306C41.5412 51.5806 39.7496 50.2891 40.5412 46.9141L41.5412 42.6224C41.7079 41.8307 41.4162 40.664 40.8329 40.0807L37.3746 36.6224C35.3329 34.5807 35.9996 32.539 38.8329 32.039L43.2912 31.2891C44.0412 31.1641 44.9162 30.4974 45.2496 29.8307L47.7079 24.9141C48.9162 22.2474 51.0829 22.2474 52.4162 24.9141Z"
                fill="#49B6FF"
            />
        </svg>
    );
};

export default AchievementMedal;
