import React from 'react'

const FilledMessageIcon = () => {
  return (
      <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
          <path
              d="M16 2.46875H8C4 2.46875 2 4.46875 2 8.46875V21.4688C2 22.0187 2.45 22.4688 3 22.4688H16C20 22.4688 22 20.4688 22 16.4688V8.46875C22 4.46875 20 2.46875 16 2.46875Z"
              fill="#49B6FF"
          />
          <path
              d="M7 9.96875H17"
              stroke="white"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
          />
          <path
              d="M7 14.9688H14"
              stroke="white"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
          />
      </svg>
  );
}

export default FilledMessageIcon
