import { Box, Button, Grid } from "@mui/material"
import PageHeader from "../common/PageHeader"
import MoreIcon from "../../assets/icons/MoreIcon"
import { useState } from "react"
import IndividualRatingCard from "./components/IndividualRatingCard"
import RatingBox from "./components/RatingBox"
import { useNavigate } from "react-router"
import { useGetReviewsByCompanyProfId } from "../../utils/hooks/api/company-review-requests/useGetReviewsByCompanyProfId"
import { useAuth } from "../../utils/context/AuthContext"
import { IReviewType } from "./types"
import CompanyRatingsLoader from "../../components/LoadingState/CompanyRatingsLoader"

const CompanyReviews = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [selectedTab, setSelectedTab] = useState('individuals');

    const { data: reviews = { data: [], companyAvgRating: 0 }, isFetching } = useGetReviewsByCompanyProfId(user?.primaryCompanyProfile?.id!);

    return (
        <Box>
            <PageHeader
                handleBack={() => navigate(-1)}
                pageTitle="Company Ratings"
                actions={
                    <Box
                        sx={{
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <MoreIcon />
                    </Box>
                }
            />
            <Grid
                sx={{
                    px: '40px',
                    background: '#FFF',
                    my: '20px'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Button
                        disableRipple
                        onClick={() => setSelectedTab('individuals')}
                        variant="contained"
                        sx={{
                            borderRadius: 0,
                            padding: '12px 8px',
                            background:
                                selectedTab === 'individuals'
                                    ? '#044864'
                                    : '#EDEDED',
                            color:
                                selectedTab === 'individuals'
                                    ? '#FFF'
                                    : '#23282B',
                            fontWeight:
                                selectedTab === 'individuals' ? 600 : 400,
                        }}
                    >
                        Individuals rating
                    </Button>
                    <Button
                        disableRipple
                        onClick={() => setSelectedTab('attributes')}
                        variant="contained"
                        sx={{
                            borderRadius: 0,
                            padding: '12px 8px',
                            background:
                                selectedTab === 'attributes'
                                    ? '#044864'
                                    : '#EDEDED',
                            color:
                                selectedTab === 'attributes'
                                    ? '#FFF'
                                    : '#23282B',
                            fontWeight:
                                selectedTab === 'attributes' ? 600 : 400,
                        }}
                    >
                        Company attribute breakdown
                    </Button>
                </Box>
            </Grid>
            {isFetching ? <CompanyRatingsLoader /> :
                <>
                    <IndividualRatingCard avgRating={reviews?.companyAvgRating} />
                    <Box
                        sx={{
                            py: '24px',
                            display: 'flex',
                            gap: '20px',
                            flexDirection: 'column',
                            bgcolor: '#fff'
                        }}
                    >
                        {reviews?.data?.map((review: IReviewType, i: number) => <RatingBox review={review} index={i} />)}
                    </Box>
                    <Button
                        sx={{
                            py: '8px',
                            bgcolor: '#fff',
                            borderRadius: '8px',
                            textDecoration: 'none',
                            border: '1px solid #05668D',
                            '&:hover': {
                                textDecoration: 'none'
                            }
                        }}
                    >
                        View all
                    </Button>
                </>
            }
        </Box>
    )
}

export default CompanyReviews