import React, { useEffect, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { ArrowLeftIcon } from '../assets/icons/ArrowLeftIcon';
import { useNavigate } from 'react-router-dom';
type ProfileBannerProps = {
    firstName?: string;
    lastName?: string;
    companyName?: string;
    score?: number;
};
const ProfileBanner = ({
    firstName,
    lastName,
    score = 0,
    companyName
}: ProfileBannerProps) => {
    const navigate = useNavigate();
    const [isScrolling, setIsScrolling] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 10) {
                setIsScrolling(true);
            } else {
                setIsScrolling(false);
            }
        });
    }, []);
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                position: 'fixed',
                top: isScrolling ? '83px' : 'auto',
                background: 'rgba(0, 0, 0, 0.4)',
                backdropFilter: 'blur(2.5px)',
                padding: '15px 40px',
                width: '1133px',
                zIndex: 1000
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '25px'
                }}
            >
                <IconButton onClick={() => navigate(-1)}>
                    <ArrowLeftIcon color="white" />
                </IconButton>
                <Typography
                    sx={{
                        fontSize: '24px',
                        fontWeight: '600',
                        fontFamily: 'Open Sans',
                        color: 'white',
                        lineHeight: '32px'
                    }}
                >
                    {firstName || lastName ? `${firstName} ${lastName}` : ''}
                </Typography>
                <Typography
                    sx={{
                        fontSize: '24px',
                        fontWeight: '600',
                        fontFamily: 'Open Sans',
                        color: 'white',
                        lineHeight: '32px'
                    }}
                >
                    {companyName ? companyName : ''}
                </Typography>
            </Box>
            <Box
                sx={{
                    background: '#49B6FF',
                    borderRadius: '6px',
                    padding: '8px 12px',
                    fontSize: '20px',
                    fontWeight: '600',
                    fontFamily: 'Open Sans',
                    color: 'white',
                    lineHeight: '100%'
                }}
            >
                {score}
            </Box>
        </Box>
    );
};

export default ProfileBanner;
