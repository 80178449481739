import {
    Box,
    Button,
    Checkbox,
    Grid,
    TextField,
    Typography
} from '@mui/material';
import React from 'react';
import axiosInstance from '../../utils/hooks/api/axiosConfig';
import { useAuth } from '../../utils/context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { logger } from '../../utils/Helper/logger';

function Security() {
    type DeleteAccountErrorType = 0 | 1 | 2 | 3;
    const deleteAccountErrors = [
        '',
        'Server error occurred',
        'Incorrect email',
        'You must accept the terms'
    ];
    const [emailAddress, setEmailAddress] = React.useState('');
    const [termAgreement, setTermAgreement] = React.useState(false);
    const [error, setError] = React.useState<DeleteAccountErrorType>(0);
    const { user, signOut } = useAuth();
    const navigate = useNavigate();
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (error) setError(0);
        setEmailAddress(e.target.value);
    };
    const handleDeleteAccount = async () => {
        if (
            emailAddress.toLocaleLowerCase() !==
            (
                user?.professionalProfile?.emailAddress ??
                user?.primaryCompanyProfile?.emailAddress
            )?.toLowerCase()
        ) {
            setError(2);
            return;
        } else if (!termAgreement) {
            setError(3);
            return;
        }
        try {
            await axiosInstance.delete(
                '/authentication/delete-account?emailAddress=' +
                    emailAddress.toLocaleLowerCase()
            );
            signOut();
            navigate('/login');
        } catch (err) {
            logger.error(err);
            setError(1);
        }
    };

    return (
        <Box textAlign={'center'}>
            <h1>Security Settings</h1>
            <Grid
                container
                rowGap={3}
                sx={{
                    maxWidth: '300px',
                    flexDirection: 'column',
                    mx: 'auto'
                }}
            >
                <Typography
                    sx={{
                        fontSize: '14px',
                        fontFamily: 'Open Sans',
                        color: '#23282B',
                        mb: '-12px'
                    }}
                >
                    Please enter the email associated with your account login.
                </Typography>
                <TextField
                    required
                    label="Email Address"
                    value={emailAddress}
                    onChange={handleInputChange}
                    error={!!error}
                    helperText={deleteAccountErrors[error]}
                />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        textAlign: 'left'
                    }}
                >
                    <Checkbox
                        required
                        onChange={(e) => setTermAgreement(e.target.checked)}
                        name="termAgreement"
                        sx={{
                            color: '#D1D1D1',
                            padding: 0
                        }}
                    />
                    <Typography
                        sx={{
                            fontSize: '14px',
                            fontFamily: 'Open Sans',
                            color: '#23282B',
                            ml: '12px'
                        }}
                    >
                        I understand this will delete my account and cannot be
                        undone.
                    </Typography>
                </Box>
                <Button
                    variant="contained"
                    color="error"
                    onClick={handleDeleteAccount}
                >
                    Delete Account
                </Button>
            </Grid>
        </Box>
    );
}

export default Security;
