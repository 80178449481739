import { Box, Typography } from '@mui/material';
import { useGetProfessionalRatingByProfileId } from '../../../utils/hooks/api/reviews/useGetProfessionalRatingByProfileId';
import ExperienceLevel from './ExperienceLevel';
import { experienceLevels } from '../../Reviews/constants';
import { Responsiveness } from '../../../theme/overrides';

interface ConnectionSkillsProps {
    id?: string;
    count?: number;
}

interface ISkillType {
    level: string;
    name: string;
    rating: number;
    type: string;
    witness: boolean;
}

const ConnectionSkills = ({ id, count }: ConnectionSkillsProps) => {
    const { data: skills } = useGetProfessionalRatingByProfileId(id ?? '');

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                gap: '8px'
            }}
        >
            {skills
                ?.filter((x: ISkillType) => x?.witness === true)
                ?.sort((a: ISkillType, b: ISkillType) => a?.rating < b?.rating ? 1 : -1)
                .slice(0, count ?? skills?.length)
                ?.map((x: ISkillType) => {
                    let level = experienceLevels?.find(
                        (y) => y.key === x.rating
                    );
                    return (
                        <ExperienceLevel
                            background={level?.color ?? '#E75541'}
                            shortForm={level?.shortForm ?? 'B'}
                            title={x?.name}
                        />
                    );
                })}
            {(skills?.length && count) ? (
                <>
                    <Typography
                        component={'div'}
                        sx={{
                            width: '7px',
                            height: '7px',
                            borderRadius: '100px',
                            background: '#494949',
                            border: '2px solid #494949'
                        }}
                    />{' '}
                    <Typography
                        component={'div'}
                        sx={{
                            ml: '8px',
                            width: '7px',
                            height: '7px',
                            borderRadius: '100px',
                            background: '#494949',
                            border: '2px solid #494949'
                        }}
                    />
                </>
            ) : null}
        </Box>
    );
};

export default ConnectionSkills;
