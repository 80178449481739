import { useState } from 'react';
import { Avatar, Box, Button, IconButton, Typography } from '@mui/material';
import { JobApplicationItem } from '../../types/JobApplicationItem';
import ChipList from '../Chips/ChipList';
import JobBookmarkIcon from '../../../../assets/icons/JobBookmarkIcon';
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSaveApplicant } from '../../../../utils/hooks/api/saved-applicants/useSaveApplicant';
import { useAuth } from '../../../../utils/context/AuthContext';
import SnackAlert, { SnackAlertType } from '../../../../components/Snackbar';
import { useDeleteSavedApplicant } from '../../../../utils/hooks/api/saved-applicants/useDeleteSavedApplicant';
import ExperienceChip from '../ExperienceChip';
import { getJobFitColor } from '../../../../utils/Helper/helper';
import { useGetProfessionalProfileScore } from '../../../../utils/hooks/api/professional-profile/useGetProfessionalProfileScore';

const ApplicationItem = (props: {
    ApplicantInfo: JobApplicationItem;
    showJobTitle?: boolean;
    updateData?: (applicantId: string) => void;
}) => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const {
        ApplicantInfo,
        showJobTitle = false,
        updateData = () => {}
    } = props;
    const saveApplicant = useSaveApplicant();
    const removeApplicant = useDeleteSavedApplicant();
    const [message, setMessage] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [type, setType] = useState<SnackAlertType>('info');
    const { data: score } = useGetProfessionalProfileScore(
        ApplicantInfo?.professionalProfile?.id!
    );

    const toggleSaveApplicant = () => {
        if (ApplicantInfo.isSaved) {
            removeApplicant.mutate(ApplicantInfo.savedApplicantId, {
                onSuccess: (res) => {
                    if (res) {
                        console.log('saveApplicant', res);
                        updateData(ApplicantInfo.id);
                        ApplicantInfo.isSaved = false;
                        ApplicantInfo.savedApplicantId = '';
                        setType('success');
                        setMessage('Applicant removed successfully.');
                        setShowAlert(true);
                    } else {
                        setType('error');
                        setMessage('Error occured please try again!');
                        setShowAlert(true);
                    }
                },
                onError: (err) => {
                    console.log('Err', err);
                    setType('error');
                    setMessage('Error occured please try again!');
                    setShowAlert(true);
                }
            });
        } else {
            let data = {
                companyProfileId: user?.primaryCompanyProfile?.id ?? '',
                applicantId: ApplicantInfo.id
            };
            saveApplicant.mutate(data, {
                onSuccess: (res) => {
                    if (res?.id) {
                        console.log('saveApplicant', res);
                        ApplicantInfo.isSaved = true;
                        ApplicantInfo.savedApplicantId = res?.id;
                        setType('success');
                        setMessage('Applicant saved successfully.');
                        setShowAlert(true);
                    } else {
                        setType('error');
                        setMessage('Error occured please try again!');
                        setShowAlert(true);
                    }
                },
                onError: (err) => {
                    console.log('Err', err);
                    setType('error');
                    setMessage('Error occured please try again!');
                    setShowAlert(true);
                }
            });
        }
    };
    console.log(ApplicantInfo, 'daved');
    return (
        <Box
            sx={{
                backgroundColor: '#FFFFFF',
                borderBottom: '1px solid #CCC',
                px: { xs: '16px', sm: '20px', lg: '40px' },
                py: '12px'
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: { xs: 'center', sm: 'flex-start' },
                    gap: { xs: '8px', sm: '16px' },
                    justifyContent: 'space-between'
                }}
            >
                <Box
                    onClick={() =>
                        navigate(`/applicant-details/${ApplicantInfo?.id}`)
                    }
                    sx={{
                        textAlign: 'center',
                        cursor: 'pointer'
                    }}
                >
                    <Avatar
                        sx={{
                            width: { xs: 40, sm: 70 },
                            height: { xs: 40, sm: 70 }
                        }}
                        src={ApplicantInfo.professionalProfile.photoUrl}
                    />
                </Box>
                <Box
                    sx={{
                        // width: '100%',
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2px'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '14px',
                            alignItems: 'center'
                        }}
                    >
                        <Typography
                            sx={{
                                ...styles.title,
                                cursor: 'pointer'
                            }}
                            onClick={() =>
                                navigate(
                                    `/applicant-details/${ApplicantInfo?.id}`
                                )
                            }
                        >
                            {ApplicantInfo.professionalProfile.firstName}{' '}
                            {ApplicantInfo?.professionalProfile?.lastName}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: { xs: '8px', sm: '14px' },
                            alignItems: 'center'
                        }}
                    >
                        {ApplicantInfo.professionalProfile?.employment ? (
                            <>
                                <Typography sx={styles.applicantDetails}>
                                    {
                                        ApplicantInfo.professionalProfile
                                            ?.employment?.jobTitle
                                    }
                                </Typography>
                                <Typography
                                    display={{ xs: 'none', sm: 'block' }}
                                    sx={styles.applicantDetailsBreak}
                                >
                                    •
                                </Typography>
                                <Typography
                                    display={{ xs: 'none', sm: 'block' }}
                                    sx={styles.applicantDetails}
                                >
                                    {
                                        ApplicantInfo?.professionalProfile
                                            ?.employment?.companyName
                                    }
                                </Typography>
                                <Typography
                                    display={{ xs: 'none', sm: 'block' }}
                                    sx={styles.applicantDetailsBreak}
                                >
                                    •
                                </Typography>
                            </>
                        ) : (
                            <></>
                        )}
                        {location.pathname !== '/job-applications' && (
                            <>
                                <Typography
                                    // display={{ xs: 'block', sm: 'none' }}
                                    sx={{
                                        // ...styles.title,
                                        fontSize: '14px',
                                        color: '#808080'
                                    }}
                                >
                                    {ApplicantInfo.jobPosting?.title as string}
                                </Typography>
                                {location.pathname !==
                                    '/save-applications'
                                 ? null : (
                                    <>
                                        <Typography
                                            display={{
                                                xs: 'none',
                                                sm: 'block'
                                            }}
                                            sx={styles.applicantDetailsBreak}
                                        >
                                            •
                                        </Typography>
                                        <Typography
                                            display={{
                                                xs: 'none',
                                                sm: 'block'
                                            }}
                                            sx={styles.applicantDetails}
                                        >
                                            {
                                                ApplicantInfo?.jobPosting
                                                    ?.company as string
                                            }
                                        </Typography>
                                        <Typography
                                            display={{
                                                xs: 'none',
                                                sm: 'block'
                                            }}
                                            sx={styles.applicantDetailsBreak}
                                        >
                                            •
                                        </Typography>
                                    </>
                                )}
                            </>
                        )}
                        <Typography
                            sx={{
                                ...styles.applicantDetails,
                                color: '#fff',
                                backgroundColor: '#49B6FF',
                                padding: { xs: '5px 8px', sm: '5px 10px' },
                                borderRadius: '5px'
                            }}
                        >
                            {score}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: { xs: 'none', sm: 'flex' },
                            gap: '15px',
                            alignItems: 'center'
                        }}
                    >
                        <Box
                            sx={{
                                mt: '10px',
                                gap: '12px',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <Box display={{ sm: 'none', lg: 'block' }}>
                                {ApplicantInfo?.skills
                                    ?.sort((a: any, b: any) =>
                                        a?.rating < b?.rating ? 1 : -1
                                    )
                                    ?.slice(0, 4)
                                    .map((item) => (
                                        <ExperienceChip
                                            key={item.name}
                                            item={item}
                                        />
                                    ))}
                            </Box>
                            <Box display={{ sm: 'block', lg: 'none' }}>
                                {ApplicantInfo?.skills
                                    ?.sort((a: any, b: any) =>
                                        a?.rating < b?.rating ? 1 : -1
                                    )
                                    ?.slice(0, 2)
                                    .map((item) => (
                                        <ExperienceChip
                                            key={item.name}
                                            item={item}
                                        />
                                    ))}
                            </Box>
                            {(ApplicantInfo?.skills?.length ?? 0) >= 2 && (
                                <>
                                    <Box
                                        sx={{
                                            width: '6px',
                                            height: '6px',
                                            backgroundColor: '#494949',
                                            borderRadius: '100px'
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            width: '6px',
                                            height: '6px',
                                            backgroundColor: '#494949',
                                            borderRadius: '100px'
                                        }}
                                    />
                                </>
                            )}
                        </Box>
                    </Box>
                </Box>

                <Box alignSelf={'center'}>
                    <Box
                        display="flex"
                        sx={{ flexDirection: 'column', gap: '8px' }}
                        alignItems="flex-end"
                    >
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                gap: { xs: '8px', sm: '15px' },
                                alignItems: 'center',
                                marginY: 'auto',
                                justifyContent: 'end'
                            }}
                        >
                            <Typography
                                display={{ xs: 'none', sm: 'block' }}
                                sx={{
                                    fontSize: { xs: '12px', sm: '16px' },
                                    color: '#808080',
                                    fontWeight: '600'
                                }}
                            >
                                {moment(
                                    new Date(ApplicantInfo.appliedAt)
                                ).fromNow()}
                            </Typography>
                            <Typography
                                sx={{
                                    width: { xs: '24px', sm: '30px' },
                                    height: { xs: '24px', sm: '30px' },
                                    borderRadius: 100,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontSize: { xs: '14px', sm: '15px' },
                                    color: '#fff',
                                    fontWeight: '400',
                                    backgroundColor: getJobFitColor(
                                        ApplicantInfo.jobFitMetrics
                                    )
                                }}
                            >
                                {ApplicantInfo.jobFitMetrics}
                            </Typography>
                            <Button
                                variant="outlined"
                                onClick={() =>
                                    navigate(
                                        `/applicant-details/${ApplicantInfo?.id}`
                                    )
                                }
                                sx={{
                                    display: { xs: 'none', lg: 'block' },
                                    padding: '15px 20px',
                                    width: 'fit-content',
                                    minWidth: '200px'
                                }}
                            >
                                View Application
                            </Button>
                            {ApplicantInfo.isSaved ? (
                                <IconButton
                                    sx={{
                                        backgroundColor: '#FCFBF8',
                                        width: '40px',
                                        height: '40px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRadius: '8px',
                                        cursor: 'pointer'
                                    }}
                                    onClick={toggleSaveApplicant}
                                >
                                    <TurnedInIcon
                                        style={{ color: '#F6C70E' }}
                                    />
                                </IconButton>
                            ) : (
                                <IconButton
                                    sx={{
                                        borderRadius: '8px',
                                        p: 0
                                    }}
                                    onClick={toggleSaveApplicant}
                                >
                                    <JobBookmarkIcon />
                                </IconButton>
                            )}
                        </Box>
                        <Button
                            variant="outlined"
                            onClick={() =>
                                navigate(
                                    `/applicant-details/${ApplicantInfo?.id}`
                                )
                            }
                            sx={{
                                padding: '15px 20px',
                                display: {
                                    xs: 'none',
                                    sm: 'block',
                                    lg: 'none'
                                },
                                width: 'fit-content',
                                minWidth: '200px'
                            }}
                        >
                            View Application
                        </Button>
                    </Box>
                </Box>
            </Box>
            <Box>
                <Box
                    sx={{
                        display: { xs: 'flex', sm: 'none' },
                        gap: '12px',
                        alignItems: 'center'
                    }}
                >
                    <Box
                        sx={{
                            mt: '10px',
                            gap: '8px',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <Box>
                            {ApplicantInfo?.skills
                                ?.sort((a: any, b: any) =>
                                    a?.rating < b?.rating ? 1 : -1
                                )
                                ?.slice(0, 3)
                                .map((item) => (
                                    <ExperienceChip
                                        key={item.name}
                                        item={item}
                                    />
                                ))}
                        </Box>

                        {(ApplicantInfo?.skills?.length ?? 0) > 3 && (
                            <>
                                <Box
                                    sx={{
                                        width: '6px',
                                        height: '6px',
                                        backgroundColor: '#494949',
                                        borderRadius: '100px'
                                    }}
                                />
                                <Box
                                    sx={{
                                        width: '6px',
                                        height: '6px',
                                        backgroundColor: '#494949',
                                        borderRadius: '100px'
                                    }}
                                />
                            </>
                        )}
                    </Box>
                </Box>
                <Button
                    variant="outlined"
                    onClick={() =>
                        navigate(`/applicant-details/${ApplicantInfo?.id}`)
                    }
                    sx={{
                        padding: '4px 20px',
                        display: { xs: 'block', sm: 'none' },
                        width: '100%'
                    }}
                >
                    View Application
                </Button>
            </Box>
            <SnackAlert
                open={showAlert}
                handleClose={() => setShowAlert(false)}
                message={message}
                type={type}
            />
        </Box>
    );
};

const styles = {
    title: {
        fontSize: '16px',
        color: '#494949',
        fontWeight: '600'
    },
    applicantDetails: {
        fontSize: '14px',
        color: '#808080',
        fontWeight: '400'
    },
    applicantDetailsBreak: {
        fontSize: '28px',
        lineHeight: '14px',
        color: '#494949',
        fontWeight: '400'
    }
};

export default ApplicationItem;
