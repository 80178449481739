import { useLinkedIn } from 'react-linkedin-login-oauth2';
import { useLinkedInType } from 'react-linkedin-login-oauth2/build/types';
import { AxiosError } from 'axios';
import axiosInstance from '../../api/axiosConfig';
import { UserInfoOAuth } from '../types/userInfo';
import { signal } from '@preact/signals-react';
import { logger } from '../../../Helper/logger';

interface useLinkedInAuthType {
    onSuccess: (userInfo: UserInfoOAuth) => void;
    onError?: useLinkedInType['onError'];
}

export const linkedinRequestSignal = signal(false);

export const useLinkedInAuth = ({
    onSuccess,
    onError
}: useLinkedInAuthType) => {
    const setDuplicateRequestTimeout = () => {
        linkedinRequestSignal.value = true;
        setTimeout(() => {
            linkedinRequestSignal.value = false;
        }, 5000);
    };

    const redirectUri = `${window.location.origin}/linkedin`;
    // Ref: https://www.npmjs.com/package/react-linkedin-login-oauth2
    return useLinkedIn({
        clientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID as string,
        redirectUri: redirectUri,
        scope: 'openid,profile,email',
        onSuccess: async (code: string) => {
            if (linkedinRequestSignal.value) {
                logger.warn('Filtered duplicate request from linkedin auth');
                return;
            }
            try {
                setDuplicateRequestTimeout();
                const { data: userInfo } = await axiosInstance.get(
                    `/api/linkedin/user?code=${code}&redirectUri=${redirectUri}`
                );
                logger.log('userInfo =>', userInfo);
                if (!userInfo) {
                    throw new Error('User info returned empty');
                }
                return onSuccess(userInfo as UserInfoOAuth);
            } catch (err) {
                const error = err as AxiosError;
                console.error(err);
                onError?.({
                    error: error.name,
                    errorMessage: error.message
                });
            }
        },
        onError: (error) => {
            if (error.error === 'user_closed_popup') {
                return;
            } else {
                onError?.(error);
            }
        }
    });
};
