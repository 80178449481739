import { Alert, Snackbar, SnackbarProps } from '@mui/material';

export type SnackAlertType = 'success' | 'info' | 'warning' | 'error';

interface IPropTypes {
    type: SnackAlertType;
    open: boolean;
    message: string;
    handleClose: () => void;
    anchorOrigin?: SnackbarProps['anchorOrigin'];
}
const SnackAlert = ({
    open = false,
    handleClose = () => {},
    message = '',
    type = 'info',
    anchorOrigin
}: IPropTypes) => {
    return (
        <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={
                anchorOrigin ?? { vertical: 'top', horizontal: 'right' }
            }
        >
            <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    );
};

export default SnackAlert;
