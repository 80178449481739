import { Box, Typography } from '@mui/material'
import { useEffect, useState } from 'react';
import { getAvgRatingLevel } from './functions';

const RatingBadge = ({ level = 40 }: { level: number }) => {
    const [title, setTitle] = useState('Poor');
    const [color, setColor] = useState('#E75541');
    const [shortForm, setShortForm] = useState('P');

    useEffect(() => {
        const { color, title, shortForm } = getAvgRatingLevel(level);
        setTitle(title);
        setColor(color);
        setShortForm(shortForm);
    }, [level])

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '5px'
            }}
        >
            <Typography variant='titleMediumSemiBold' color={color}>
                {title}
            </Typography>
            <Box
                sx={{
                    background: color,
                    borderRadius: '4px',
                    height: '18px',
                    width: '17px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Typography variant='titleSmallBold' color={'#fff'}>{shortForm}</Typography>
            </Box>
        </Box>
    )
}

export default RatingBadge