import React from 'react'

const CancelImageIcon = () => {
  return (
      <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
      >
          <path
              d="M18 33C26.2843 33 33 26.2843 33 18C33 9.71573 26.2843 3 18 3C9.71573 3 3 9.71573 3 18C3 26.2843 9.71573 33 18 33Z"
              fill="white"
          />
          <path
              d="M19.59 18L23.04 14.55C23.475 14.115 23.475 13.395 23.04 12.96C22.605 12.525 21.885 12.525 21.45 12.96L18 16.41L14.55 12.96C14.115 12.525 13.395 12.525 12.96 12.96C12.525 13.395 12.525 14.115 12.96 14.55L16.41 18L12.96 21.45C12.525 21.885 12.525 22.605 12.96 23.04C13.185 23.265 13.47 23.37 13.755 23.37C14.04 23.37 14.325 23.265 14.55 23.04L18 19.59L21.45 23.04C21.675 23.265 21.96 23.37 22.245 23.37C22.53 23.37 22.815 23.265 23.04 23.04C23.475 22.605 23.475 21.885 23.04 21.45L19.59 18Z"
              fill="#292D32"
          />
      </svg>
  );
}

export default CancelImageIcon;
