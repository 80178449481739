import { Box, Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Responsiveness } from '../../theme/overrides';

interface MobileNavProps {
    list: {
        label: string;
        route: string;
        disable?: boolean;
    }[];
}

const MobileNav = ({ list = [] }: MobileNavProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { isXMobile, isMobile } = Responsiveness();
   const containerRef = useRef<HTMLDivElement | null>(null);

   const containerElement = containerRef.current;
    useEffect(() => {
        
    });

    return (
        <Box
            ref={containerRef}
            bgcolor={{xs: '#fff', sm: "#F2F2F2"}}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gap={isXMobile ? '10px' : '16px'}
            minWidth={isMobile ? '100%' : undefined}
            sx={{
                overflowX: 'auto',
                '&::-webkit-scrollbar': {
                    display: 'none'
                }
            }}
            borderBottom={{xs: '1px solid #808080', sm: "1px solid #C5C5C5"}}
        >
            {list.map((item, i) => (
                <Typography
                    key={i}
                    py="12px"
                    px="10px"
                    borderBottom="2px solid"
                    minWidth={isMobile ? '150px' : undefined}
                    color={
                        location.pathname === item.route ? '#05668D' : '#808080'
                    }
                    sx={{
                        borderBottomColor:
                            location.pathname === item.route
                                ? '#05668D'
                                : 'transparent',
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                    fontFamily="Open Sans"
                    fontSize={'14px'}
                    fontWeight={400}
                    lineHeight="20px"
                    letterSpacing="0.014px"
                    onClick={() => {
                        if (item.disable === true) return;
                        else navigate(item.route, {
                            state: {
                            index: i
                            }
                        }
                        );
                        if (containerElement) {
                            let total =
                                containerElement.scrollWidth / list?.length;
                            containerElement.scrollTo({
                                left: total * location?.state?.index,
                                behavior: 'smooth'
                            });
                        }
                    }}
                >
                    {item.label}
                </Typography>
            ))}
        </Box>
    );
};

export default MobileNav;
