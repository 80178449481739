function MoreIcon() {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.3335 15.8333C8.3335 16.75 9.0835 17.5 10.0002 17.5C10.9168 17.5 11.6668 16.75 11.6668 15.8333C11.6668 14.9167 10.9168 14.1667 10.0002 14.1667C9.0835 14.1667 8.3335 14.9167 8.3335 15.8333Z"
                fill="#808080"
                stroke="#808080"
                stroke-width="1.5"
            />
            <path
                d="M8.3335 4.16683C8.3335 5.0835 9.0835 5.8335 10.0002 5.8335C10.9168 5.8335 11.6668 5.0835 11.6668 4.16683C11.6668 3.25016 10.9168 2.50016 10.0002 2.50016C9.0835 2.50016 8.3335 3.25016 8.3335 4.16683Z"
                fill="#808080"
                stroke="#808080"
                stroke-width="1.5"
            />
            <path
                d="M8.3335 9.99984C8.3335 10.9165 9.0835 11.6665 10.0002 11.6665C10.9168 11.6665 11.6668 10.9165 11.6668 9.99984C11.6668 9.08317 10.9168 8.33317 10.0002 8.33317C9.0835 8.33317 8.3335 9.08317 8.3335 9.99984Z"
                fill="#808080"
                stroke="#808080"
                stroke-width="1.5"
            />
        </svg>
    );
}
export default MoreIcon;
