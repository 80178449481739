import CommonDialog from '../../../../common/CommonDialog';

import { useEffect, useState } from 'react';

import { Qualification } from '../../../types/Qualification';
import SelectQualificationType from './SelectQualificationType';
import EducationForm from './EducationForm';
import WorkExperienceForm from './WorkExperienceForm';
import VolunteerServiceForm from './VolunteerServiceForm';
import LicenseAndCertificationsForm from './LicenseAndCertificationsForm';
import TestsForm from './TestsForm';
import AwardsForm from './AwardsForm';
import { Modal, IconButton, CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useAddEducation } from '../../../../../utils/hooks/api/education/useAddEducation';
import { useAuth } from '../../../../../utils/context/AuthContext';
import SnackAlert from '../../../../../components/Snackbar';
import { ArrowLeftIcon } from '../../../../../assets/icons/ArrowLeftIcon';
import axios from 'axios';
import {
    AddEducationPostRequest,
    UpdateEducationRequest
} from '../../../../../utils/hooks/api/education/types';
import { IEmploymentRequest } from '../../../../../utils/hooks/api/employments/types';
import { useAddEmployment } from '../../../../../utils/hooks/api/employments/useAddEmployment';
import { useUpdateEducation } from '../../../../../utils/hooks/api/education/useUpdateEducation';
import { useGetEducationById } from '../../../../../utils/hooks/api/education/useGetEducationById';
import { useUpdateEmployment } from '../../../../../utils/hooks/api/employments/useUpdateEmployment';
import { useGetEmploymentById } from '../../../../../utils/hooks/api/employments/useGetEmploymentById';
import { QueryKeys } from '../../../../../utils/hooks/api/QueryKey';
import { useQueryClient } from 'react-query';
import { Responsiveness } from '../../../../../theme/overrides';
import { useCreateCompanyReviewRequest } from '../../../../../utils/hooks/api/company-review-requests/useCreateCompanyReviewRequest';

interface IAddQualificationDialogProps {
    open: boolean;
    id?: string;
    qualificationType?: string;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddQualificationDialog = (props: IAddQualificationDialogProps) => {
    const { isMobile } = Responsiveness();
    const [education, setEducation] = useState({
        school: '',
        degree: '',
        fieldOfStudy: '',
        startDate: '',
        endDate: '',
        educationLevel: '',
        agencies: '',
        isChecked: false
    });
    const [workExperience, setWorkExperience] = useState({
        jobTitle: '',
        employmentType: '',
        companyName: '',
        companyEmail: '',
        location: '',
        locationType: '',
        startDate: '',
        endDate: '',
        description: '',
        employmentLevel: '',
        jobPosition: '',
        companySize: '',
        skills: [],
        isChecked: false
    });
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [loading, setLoading] = useState(false);
    const addEducation = useAddEducation();
    const addEmployment = useAddEmployment();
    const { user, setUser } = useAuth();
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState('');
    const [snackType, setSnackType] = useState<
        'success' | 'info' | 'warning' | 'error'
    >('info');
    const { open, setOpen, id, qualificationType } = props;
    const [type, setType] = useState(qualificationType ?? null);
    const updateEducation = useUpdateEducation();
    const updateEmployment = useUpdateEmployment();
    const queryClient = useQueryClient();
    const createCompanyReviewRequest = useCreateCompanyReviewRequest();

    const { data, isFetching, refetch } = useGetEducationById(
        id ?? '',
        type === Qualification.Education
    );
    const {
        data: employmentData,
        isFetching: isEmploymentFetching,
        refetch: fetchEmployment
    } = useGetEmploymentById(id ?? '', type === Qualification.WorkExperience);

    const handleChange = (e: any) => {
        if (type === Qualification.Education) {
            setEducation({
                ...education,
                [e.target.name]:
                    e.target.name === 'isChecked'
                        ? e.target?.checked
                        : e.target?.value
            });
            return;
        }
        if (type === Qualification.WorkExperience) {
            setWorkExperience((prev) =>
                JSON.parse(
                    JSON.stringify({
                        ...prev,
                        [e.target.name]:
                            e.target.name === 'isChecked'
                                ? e.target?.checked
                                : e.target?.value
                    })
                )
            );
            return;
        }
    };

    const handleSubmit = () => {
        setLoading(true);
        if (type === Qualification.Education) {
            let data = {
                professionalProfileId: user?.professionalProfile?.id ?? '',
                ...education
            };
            addEducation.mutate(data, {
                onSuccess: (res) => {
                    queryClient.invalidateQueries(
                        QueryKeys.RetrieveProfessionalProfileById
                    );
                    let tempData = {
                        ...user,
                        professionalProfile: {
                            ...user?.professionalProfile,
                            qualifications: [
                                res,
                                ...(user?.professionalProfile?.qualifications ??
                                    [])
                            ]
                        }
                    };
                    setUser(tempData);
                    setMessage('Education added successfully.');
                    setSnackType('success');
                    setShowAlert(true);
                    handleOnClose();
                    setLoading(false);
                },
                onError: (err) => {
                    if (axios.isAxiosError(err)) {
                        setMessage(err.response?.data?.message.join(', '));
                        setSnackType('error');
                        setShowAlert(true);
                    }
                    setLoading(false);
                }
            });
            return;
        }
        if (type === Qualification.WorkExperience) {
            let data: IEmploymentRequest = {
                professionalProfileId: user?.professionalProfile?.id ?? '',
                jobTitle: workExperience.jobTitle,
                jobDescription: workExperience.description,
                startDate: workExperience.startDate,
                endDate: workExperience.endDate,
                isCurrentPosition: workExperience.isChecked,
                companyId: workExperience.companyName,
                employmentType: workExperience.employmentType,
                employmentLevel: workExperience.employmentLevel,
                location: workExperience.location,
                locationType: [workExperience.locationType]
            };
            addEmployment.mutate(data, {
                onSuccess: (res) => {
                    queryClient.invalidateQueries(
                        QueryKeys.RetrieveProfessionalProfileById
                    );
                    let tempData = {
                        ...user,
                        professionalProfile: {
                            ...user?.professionalProfile,
                            employments: [
                                res,
                                ...(user?.professionalProfile?.employments ??
                                    [])
                            ],
                            currentEmployment: res?.isCurrentPosition
                                ? res
                                : user?.professionalProfile?.currentEmployment
                        }
                    };
                    const companyPayload = {
                        userId: user?.professionalProfile?.userId,
                        companyProfileId: res?.companyProfile?.id,
                        rating: 0,
                        ratings: [
                            {
                                rating: 0,
                                type: 'rate',
                                name: 'rate'
                            }
                        ]
                    };
                    if (res?.companyProfile?.id) {
                        createCompanyReviewRequest.mutate(companyPayload, {
                            onSuccess: (res) => {
                                console.log('RES', res);
                            },
                            onError: (err) => {
                                console.log('TEST', err);
                            }
                        });
                    }
                    setUser(tempData);
                    setMessage('Employment added successfully.');
                    setSnackType('success');
                    setShowAlert(true);
                    handleOnClose();
                    setLoading(false);
                },
                onError: (err) => {
                    if (axios.isAxiosError(err)) {
                        setMessage(err.response?.data?.message);
                        setSnackType('error');
                        setShowAlert(true);
                    }
                    setLoading(false);
                }
            });
            return;
        }
    };
    const handleEdit = () => {
        setLoading(true);
        if (type === Qualification.Education) {
            let data: UpdateEducationRequest = {
                id: id ?? '',
                ...education
            };
            updateEducation.mutate(data, {
                onSuccess: (res) => {
                    queryClient.invalidateQueries(
                        QueryKeys.RetrieveProfessionalProfileById
                    );
                    let tempData = {
                        ...user,
                        professionalProfile: {
                            ...user?.professionalProfile,
                            qualifications: [
                                res,
                                ...(user?.professionalProfile?.qualifications?.filter(
                                    (x) => x.id !== id
                                ) ?? [])
                            ]
                        }
                    };
                    setUser(tempData);
                    setMessage('Education updated successfully');
                    setSnackType('success');
                    setShowAlert(true);
                    handleOnClose();
                    setLoading(false);
                },
                onError: (err) => {
                    setMessage('Error occured please try again!');
                    setSnackType('error');
                    setShowAlert(true);
                    setLoading(false);
                }
            });
            return;
        }
        if (type === Qualification.WorkExperience) {
            let data: IEmploymentRequest = {
                id,
                professionalProfileId: user?.professionalProfile?.id ?? '',
                jobTitle: workExperience.jobTitle,
                jobDescription: workExperience.description,
                startDate: workExperience.startDate,
                endDate: workExperience.endDate,
                isCurrentPosition: workExperience.isChecked,
                companyId: workExperience.companyName,
                employmentType: workExperience.employmentType,
                employmentLevel: workExperience.employmentLevel,
                location: workExperience.location,
                locationType: [workExperience.locationType]
            };
            updateEmployment.mutate(data, {
                onSuccess: (res: IEmploymentRequest) => {
                    queryClient.invalidateQueries(
                        QueryKeys.RetrieveProfessionalProfileById
                    );
                    let tempData = {
                        ...user,
                        professionalProfile: {
                            ...user?.professionalProfile,
                            employments: [
                                res,
                                ...(user?.professionalProfile?.employments?.filter(
                                    (x) => x.id !== id
                                ) ?? [])
                            ],
                            currentEmployment: res?.isCurrentPosition
                                ? res
                                : user?.professionalProfile?.currentEmployment
                        }
                    };
                    setUser(tempData);
                    setMessage('Employment updated successfully');
                    setSnackType('success');
                    setShowAlert(true);
                    handleOnClose();
                    setLoading(false);
                },
                onError: () => {
                    setMessage('Error occured please try again!');
                    setSnackType('error');
                    setShowAlert(true);
                    setLoading(false);
                }
            });
            return;
        }
    };
    const handleOnClose = () => {
        setEducation({
            school: '',
            degree: '',
            fieldOfStudy: '',
            startDate: '',
            endDate: '',
            educationLevel: '',
            agencies: '',
            isChecked: false
        });
        setWorkExperience({
            jobTitle: '',
            employmentType: '',
            companyName: '',
            companyEmail: '',
            location: '',
            locationType: '',
            startDate: '',
            endDate: '',
            description: '',
            employmentLevel: '',
            jobPosition: '',
            companySize: '',
            skills: [],
            isChecked: false
        });
        setSelectedCompany(null);
        setType(null);
        setOpen(false);
    };

    const isValid = () => {
        if (type === Qualification.Education) {
            if (
                education.school &&
                education.degree &&
                education.fieldOfStudy &&
                education.startDate &&
                (education.isChecked || education.endDate) && education.agencies
            ) {
                return false;
            }
            return true;
        }
        if (type === Qualification.WorkExperience) {
            if (
                workExperience.jobTitle &&
                workExperience.locationType &&
                workExperience.employmentLevel &&
                workExperience.companyName &&
                workExperience.startDate &&
                (workExperience.isChecked || workExperience.endDate) &&
                workExperience.employmentType &&
                workExperience.jobPosition &&
                workExperience.companySize
            ) {
                return false;
            }
            return true;
        }
    };
    const getModalTitle = () => {
        let title = '';
        switch (type) {
            case Qualification.Education:
                title = 'Education';
                break;
            case Qualification.WorkExperience:
                title = 'Work Experience';
                break;
            case Qualification.Awards:
                title = 'Awards';
                break;
            case Qualification.VolunteerService:
                title = 'Volunteer Service';
                break;
            case Qualification.LicenseAndCertifications:
                title = 'License and Certifications';
                break;
            default:
                break;
        }
        return title;
    };
    const getCurrentView = () => {
        if (!type) {
            return (
                <SelectQualificationType
                    handleContinue={(type) => setType(type)}
                />
            );
        }

        const scrollingView = () => {
            switch (type) {
                case Qualification.Education:
                    return (
                        <EducationForm
                            handleChange={handleChange}
                            education={education}
                        />
                    );
                case Qualification.WorkExperience:
                    return (
                        <WorkExperienceForm
                            selectedCompany={selectedCompany}
                            workExperience={workExperience}
                            handleChange={handleChange}
                        />
                    );
                case Qualification.VolunteerService:
                    return <VolunteerServiceForm />;
                case Qualification.LicenseAndCertifications:
                    return <LicenseAndCertificationsForm />;
                case Qualification.Tests:
                    return <TestsForm />;
                case Qualification.Awards:
                    return <AwardsForm />;
                default:
                    return null;
            }
        };

        return (
            <Box py={isMobile ? '8px' : undefined} px={{xs: '16px', sm: "32px"}}>
                <Typography
                    display={{xs: 'none', sm: 'block'}}
                    mt={2}
                    mb={2}
                    component="p"
                    fontSize={14}
                    fontWeight={600}
                    color="#494949"
                >
                    * Indicates required
                </Typography>
                {scrollingView()}
            </Box>
        );
    };

    useEffect(() => {
        if (id) {
            if (type === Qualification.Education && !isFetching && data) {
                setEducation({
                    school: data.school,
                    degree: data.degree,
                    fieldOfStudy: data.fieldOfStudy,
                    startDate: data.startDate,
                    endDate: data.endDate,
                    educationLevel: data.educationLevel,
                    agencies: data?.agencies, // check back later
                    isChecked: data?.isCurrentSchool // check back later
                });
            }
            if (
                type === Qualification.WorkExperience &&
                !isEmploymentFetching &&
                employmentData
            ) {
                setSelectedCompany(employmentData?.companyProfile ?? null);
                setWorkExperience({
                    jobTitle: employmentData.jobTitle,
                    employmentType: employmentData.employmentType,
                    companyName:
                        employmentData?.companyProfile?.primaryCompanyId ?? '',
                    companyEmail: employmentData.companyEmail,
                    location: employmentData.location,
                    locationType: employmentData.locationType[0],
                    startDate: employmentData.startDate,
                    endDate: employmentData.endDate,
                    description: employmentData.jobDescription,
                    employmentLevel: employmentData.employmentLevel,
                    isChecked: employmentData.isCurrentPosition,
                    jobPosition: employmentData?.jobPosition,
                    companySize: employmentData?.companySize,
                    skills: employmentData?.skills
                });
            }
        }
    }, [id, employmentData, isFetching, type, isEmploymentFetching, data]);

    useEffect(() => {
        if (id) {
            if (type === Qualification.Education && !data) {
                refetch();
            }
            if (type === Qualification.WorkExperience && !employmentData) {
                fetchEmployment();
            }
        }
    }, [open, type]);

    useEffect(() => {
        setType(qualificationType ?? null);
    }, [qualificationType]);

    return (
        <>
            {isMobile ? (
                <Modal open={open} onClose={handleOnClose}>
                    <Box
                        width="100%"
                        height="100%"
                        bgcolor="#f2f2f2"
                        overflow="auto"
                    >
                        <Box mb='65px'>
                            <Box
                                position={'fixed'}
                                left={0}
                                right={0}
                                top={0}
                                bgcolor="#fff"
                                display="flex"
                                p="16px 16px 16px 8px"
                                gap="12px"
                                alignItems="center"
                                borderBottom="2px solid #f2f2f2"
                                zIndex={3}
                            >
                                <IconButton
                                    aria-label="close"
                                    // size="small"
                                    onClick={handleOnClose}
                                    sx={{
                                        '&:hover': {
                                            bgcolor: '#FFFFFF'
                                        }
                                        //     bgcolor: '#808080',
                                        //     color: '#FFFFFF',
                                        //     p: 0.625
                                    }}
                                >
                                    <ArrowLeftIcon />
                                </IconButton>
                                <Box flexGrow={1}>
                                    <Typography
                                        component="h6"
                                        fontSize={20}
                                        fontWeight={600}
                                        fontFamily="open sans"
                                    >
                                        {type
                                            ? 'Add ' + getModalTitle()
                                            : 'Add a qualification'}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box bgcolor='#fff' mt="83px">{getCurrentView()}</Box>
                        </Box>
                        <Box px="16px" py="8px" bgcolor='#fff' position='fixed' zIndex={3} bottom={0} right={0} left={0}>
                            <Button
                                variant="contained"
                                disabled={isValid()}
                                onClick={() =>
                                    id ? handleEdit() : handleSubmit()
                                }
                                style={{ height: '40px', width: '100%' }}
                            >
                                {id ? 'Update' : 'Save'}
                            </Button>
                        </Box>
                    </Box>
                </Modal>
            ) : (
                <CommonDialog
                    open={open}
                    onClose={handleOnClose}
                    setType={setType}
                    title={type ? getModalTitle() : 'Add a qualification'}
                    actions={
                        type && (
                            <Button
                                variant="contained"
                                disabled={isValid() || loading}
                                onClick={() =>
                                    id ? handleEdit() : handleSubmit()
                                }
                                style={{ height: '48px' }}
                            >
                                {loading ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={25}
                                    />
                                ) : id ? (
                                    'Update'
                                ) : (
                                    'Save'
                                )}
                            </Button>
                        )
                    }
                >
                    {getCurrentView()}
                </CommonDialog>
            )}
            <SnackAlert
                open={showAlert}
                handleClose={() => setShowAlert(false)}
                message={message}
                type={snackType}
            />
        </>
    );
};

export default AddQualificationDialog;
