function DotIcon({ width='6px', height='6px', color = '#494949' }) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 6 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="3" cy="3" r="3" fill={color} />
        </svg>
    );
}

export default DotIcon;
