import axiosInstance from '../axiosConfig';
import { UseMutationResult, useMutation } from 'react-query';
import { IPostResponseType } from './types';

async function createRating(postRequest: unknown) {
    const response = await axiosInstance.post(`/api/ratings`, postRequest);
    const data = await response.data;
    return data;
}
export function useCreateRating(): UseMutationResult<
    IPostResponseType,
    unknown,
    unknown
> {
    return useMutation(createRating);
}
