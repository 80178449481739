import { Avatar, Stack, Tooltip, Typography } from '@mui/material';
import { useMessages } from '../../utils/MessagesContext';
import { useNavigate } from 'react-router-dom';
import { replaceWithNbsp } from '../../../../utils/Helper/helper';
import { logger } from '../../../../utils/Helper/logger';

export default function ConversationHeader() {
    const { activeConversation } = useMessages();
    const navigate = useNavigate();
    logger.log(activeConversation);
    // console.log({ activeConversation });
    // socket times out and the activeConversation returns as null, causing errors in header

    return (
        <Tooltip title="Click to view profile">
            <Stack
                gap={2}
                sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    cursor: 'pointer'
                }}
                onClick={() => {
                    navigate(activeConversation!.profileSlug);
                }}
            >
                <Avatar
                    src={activeConversation!.imageUrl}
                    sx={{ width: '48px', height: '48px' }}
                />
                <Typography sx={{ fontSize: '24px', fontWeight: '600' }}>
                    {/* {replaceWithNbsp(activeConversation!.name)} */}
                    {activeConversation!.name ? activeConversation!.name : null}
                </Typography>
            </Stack>
        </Tooltip>
    );
}
