import { Grid, IconButton, Typography, Button, SxProps } from '@mui/material';
import React from 'react';
import WestIcon from '@mui/icons-material/West';
import { useNavigate } from 'react-router-dom';
import { Responsiveness } from '../../theme/overrides';

interface HeaderActionProps {
    children?: React.ReactNode;
}

interface PageHeaderProps {
    pageTitle: string | React.ReactNode;
    actions?: React.ReactNode;
    onBackNavigate?: string;
    hideAction?: boolean;
    handleBack?: () => void;
    sx?: SxProps;
}

const HeaderActions = ({ children }: HeaderActionProps) => {
    return <>{children}</>;
};

const PageHeader = ({
    actions,
    handleBack,
    pageTitle,
    onBackNavigate,
    hideAction = false,
    sx
}: PageHeaderProps) => {
    const navigate = useNavigate();
    const { isMobile, isTablet } = Responsiveness();
    return (
        <>
            <Grid
                container
                sx={{
                    backgroundColor: '#FFFFFF',
                    alignItems: 'center',
                    padding: isMobile
                        ? '16px 16px 16px 0px'
                        : isTablet
                        ? '20px'
                        : '32px 40px',
                    // marginBottom: isMobile ? undefined : '20px',
                    borderBottom: isMobile ? undefined : '1px solid #D9D9D9',
                    ...sx
                }}
            >
                <Grid
                    item
                    xs={hideAction ? 12 : 7}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: isMobile ? '12px' : '25px'
                    }}
                >
                    <IconButton
                        onClick={() => {
                            handleBack
                                ? handleBack()
                                : navigate(onBackNavigate || '/');
                        }}
                    >
                        <WestIcon />
                    </IconButton>
                    {typeof pageTitle === 'string' ? (
                        <Typography
                            component={'h1'}
                            sx={{
                                fontSize: isMobile ? '20px' : '28px',
                                fontWeight: '600',
                                fontFamily: 'Open Sans',
                                color: '#23282B'
                            }}
                        >
                            {pageTitle}
                        </Typography>
                    ) : (
                        pageTitle
                    )}
                </Grid>
                {!hideAction ? (
                    <Grid
                        item
                        xs={5}
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            gap: '12px'
                        }}
                    >
                        <HeaderActions>
                            {actions !== undefined ? (
                                actions
                            ) : (
                                <>
                                    <Button variant="outlined">
                                        Replace All
                                    </Button>
                                    <Button variant="contained">
                                        Request Review
                                    </Button>
                                </>
                            )}
                        </HeaderActions>
                    </Grid>
                ) : null}
            </Grid>
        </>
    );
};

export default PageHeader;
