import React from 'react';
import { Box, Typography } from '@mui/material';



interface HashTagProps {
  // id: string;
  display: string;
}

const HashTag = ({ display }: HashTagProps) => {
  return (
    <Box sx={{
      border: '3px solid red',
      width: '400px',
      height: '44px',
      display: 'flex',
      padding: '12px',
      alignItems: 'center',
      gap: '8px',
      alignSelf: 'stretch',
      borderBottom: '1px solid #D8D8D8',
      background: '#FFF',
    }}
    >
      <Box sx={{ width: '105px', height: '20px' }}>
        <Typography sx={{
          color: '#23282B',
          fontFamily: 'Open Sans',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '20px',
          letterSpacing: '0.049px',
        }}>{display}</Typography>
      </Box>
    </Box>
  )
}

export default HashTag

