import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Grid,
    InputBase,
    InputLabel,
    Typography
} from '@mui/material';
import OnboardingSteps from '../../OnboardingSteps/OnboardingSteps';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { GlobalState, useStateMachine } from 'little-state-machine';
import { clearAction, updateAction, updateStep } from './updateAction';
import UserFormIcon from '../../../../assets/icons/UserFormIcon';
import EmailFormIcon from '../../../../assets/icons/EmailFormIcon';
import PasswordFormIcon from '../../../../assets/icons/PasswordFormIcon';
import ErrorFormIcon from '../../../../assets/icons/ErrorFormIcon';
import GoogleIcon from '../../../../assets/icons/GoogleIcon';
import OnboardingLineIcon from '../../../../assets/icons/OnboardingLineIcon';
import React, { useState } from 'react';
import {
    CreateAccountType,
    CreateProAccountRequest
} from '../../../../utils/hooks/api/account/types';
import { useCreateProAccount } from '../../../../utils/hooks/api/account/useCreateProAccount';
import axios, { AxiosError } from 'axios';
import SnackAlert from '../../../../components/Snackbar';
import axiosInstance from '../../../../utils/hooks/api/axiosConfig';
import { logger } from '../../../../utils/Helper/logger';
import { isEmailValid } from '../../../../utils/Helper/validators';
import useGetVerificationCode from '../../../../utils/hooks/api/authentication/useGetVerificationCode';
import useVerifyAccount from '../../../../utils/hooks/api/authentication/userVerifyAccount';
import { LinkedIn } from '@mui/icons-material';
import { useLinkedInAuth } from '../../../../utils/hooks/oauth/linkedin/useLinkedInAuth';
import { on } from 'events';
import { UserInfoOAuth } from '../../../../utils/hooks/oauth/types/userInfo';
import { useGoogleAuth } from '../../../../utils/hooks/oauth/google/useGoogleAuth';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useAuth } from '../../../../utils/context/AuthContext';
interface IUserInfo {
    firstName: string;
    lastName: string;
    emailAddress: string;
    password: string;
    picture?: string;
    cpassword: string;
    termAgreement: boolean;
}

function UserCreate() {
    let navigate = useNavigate();
    const [showPass, setShowPass] = useState(false);
    const [showCPass, setShowCPass] = useState(false);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const {
        control,
        handleSubmit,
        setError,
        formState,
        register,
        clearErrors
    } = useForm<IUserInfo>();
    const { isDirty, isValid, errors } = formState;
    const { actions, state, getState } = useStateMachine({
        updateAction,
        updateStep,
        clearAction
    });
    const { signIn } = useAuth();
    const [type, setType] = useState('');
    const [title, setTitle] = useState('Verify email');
    const getCode = useGetVerificationCode();
    const { email } = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const verificationSentMessage =
        'A verification email has been sent to your email address. Click the link in the email to verify your account and continue your signup process.';
    //
    const createAccountMutation = useCreateProAccount();

    const createVerification = () => {
        if (email) {
            logger.log(
                'connection =>',
                localStorage.getItem('referralConnection')
            );
            setLoading(true);
            getCode.mutate(
                { emailAddress: email, verificationType: 'HASH' },
                {
                    onSuccess: (res) => {
                        logger.log('object', res);
                        setTitle('Verify email');
                        setType('');
                        setMessage(verificationSentMessage);
                        setLoading(false);
                    },
                    onError: () => {
                        setTitle('Account Verification Email Failed');
                        setType('error');
                        setMessage(
                            'There was a problem sending the email, please contact support.'
                        );
                        setLoading(false);
                        return;
                    }
                }
            );
        }
    };

    const handleCreateAccount = async (data: GlobalState) => {
        if (document.location.href.includes('professional')) {
            setLoading(true);
            const request: CreateProAccountRequest = {
                emailAddress: data.data.emailAddress,
                firstName: data.data.firstName,
                lastName: data.data.lastName,
                password: data.data.password,
                picture: data.data.picture,
                externalSignIn: !!data.data.externalSignIn
            };
            if (request !== undefined) {
                createAccountMutation.mutate(request, {
                    onSuccess: async (res: any) => {
                        actions.clearAction();
                        setLoading(false);
                        logger.log('create account =>', res);
                        try {
                            const referrerId = localStorage.getItem('referrer');
                            if (referrerId) {
                                localStorage.setItem(
                                    'referralUser',
                                    referrerId
                                );
                                const userId = res as string;
                                localStorage.setItem('signUpUser', userId);
                                const {
                                    data: {
                                        id: connectionRequestId,
                                        connectionLinkId
                                    }
                                } = await axiosInstance.post(
                                    `/api/connection-requests/send-connection-request`,
                                    {
                                        sourceUserId: referrerId,
                                        destinationUserId: userId
                                    }
                                );
                                localStorage.setItem(
                                    'referralConnection',
                                    JSON.stringify({
                                        connectionRequestId,
                                        connectionLinkId
                                    })
                                );
                            }
                        } catch (err) {
                            logger.error(err);
                        }
                        if (data.data.externalSignIn) {
                            const res: any = await signIn(
                                request.emailAddress,
                                request.password
                            );
                            if (res?.message) navigate('/login');
                            else navigate('/users');
                            return;
                        }
                        if (isEmailValid(email) && !queryParams.get('hash')) {
                            createVerification();
                        }

                        navigate(
                            `/create-account/verify-email/${data.data.emailAddress}`
                        );
                    },
                    onError: (error: AxiosError) => {
                        setLoading(false);
                        if (axios.isAxiosError(error)) {
                            console.error(error?.response?.status);
                            if (error?.response?.status === 409) {
                                setMessage(
                                    'Account already exists with this email address'
                                );
                            } else {
                                setMessage('Incorrect credentials!');
                            }
                            setShowAlert(true);
                        }
                    }
                });
            }
        }
    };

    const onSubmit: SubmitHandler<IUserInfo> = async (data) => {
        actions.updateAction(data);
        handleCreateAccount(getState());
    };

    const checkTermAgreement = () => {
        const agreement = !!control._formValues.termAgreement;
        if (!agreement) {
            setMessage('Please agree to the terms and conditions');
            setShowAlert(true);
        }
        return agreement;
    };
    /** OAuth */
    const handleOAuthLogin = (info: UserInfoOAuth) => {
        const userInfo = {
            firstName: info.given_name,
            lastName: info.family_name ?? '',
            emailAddress: info.email,
            password: info.sub,
            cpassword: info.sub,
            picture: info.picture,
            termAgreement: true,
            externalSignIn: true
        };
        logger.log('userInfo =>', userInfo);
        if (!userInfo.emailAddress || !checkTermAgreement()) return;
        actions.updateAction(userInfo);
        handleCreateAccount(getState());
    };
    const handleOAuthError = (error: any) => {
        logger.error(error);
        setMessage('OAuth login failed, please try again');
        setShowAlert(true);
    };
    /** LinkedIn OAuth */
    const { linkedInLogin } = useLinkedInAuth({
        onSuccess: handleOAuthLogin,
        onError: handleOAuthError
    });
    /** Google OAuth */
    const googleLogin = useGoogleAuth({
        onSuccess: handleOAuthLogin,
        onError: handleOAuthError
    });

    const navigateTo = (url: string) => {
        window.location.href = url
    }

    React.useEffect(() => {
        actions.updateStep(2);
    }, []);

    return (
        <>
            <Box
                sx={{
                    backgroundColor: {
                        md: 'transparent',
                        sm: '#FCFBF8',
                        xs: 'transparent'
                    },
                    borderRadius: '12px',
                    p: { md: 0, sm: '40px', xs: 0 },
                    mt: { md: '50px', sm: '-132px', xs: 0 },
                    mx: { lg: '50px', md: '20px', sm: '50px', xs: '20px' },
                    mb: { md: 0, sm: '50px', xs: 0 }
                }}
            >
                <OnboardingSteps />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        ml: { xl: '30px' },
                        width: { xl: '70%' }
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            mt: { sm: '40px', xs: '20px' },
                            mb: '26px'
                        }}
                    >
                        <Typography
                            fontSize={{ sm: '28px', xs: '18px' }}
                            fontWeight={600}
                            lineHeight={'40px'}
                            sx={{
                                color: '#23282B',
                                display: { sm: 'flex', xs: 'none' },
                                alignSelf: 'start',
                                mb: '32px'
                            }}
                        >
                            Create Account
                        </Typography>
                        <Grid container spacing={'18px'}>
                            <Grid item lg={6} md={12} sm={6} xs={12}>
                                <Box
                                    sx={{ width: '100%', position: 'relative' }}
                                >
                                    <InputLabel
                                        sx={{
                                            color: '#23282B',
                                            fontSize: {
                                                sm: '14px',
                                                xs: '12px'
                                            },
                                            position: 'absolute',
                                            top: '8px',
                                            left: '72px',
                                            zIndex: 1,
                                            fontFamily: 'Open Sans'
                                        }}
                                        htmlFor="firstName"
                                    >
                                        First name
                                    </InputLabel>

                                    {/* first name */}
                                    <Controller
                                        {...register('firstName', {
                                            required: 'Required field'
                                        })}
                                        name="firstName"
                                        control={control}
                                        render={({
                                            field: {
                                                onChange,
                                                value,
                                                ref,
                                                onBlur
                                            },
                                            fieldState: { error },
                                            formState
                                        }) => (
                                            <InputBase
                                                required
                                                onChange={(e) => {
                                                    onChange(e);
                                                    if (
                                                        e.target.value.length >
                                                        40
                                                    ) {
                                                        setError('firstName', {
                                                            type: 'maxLength',
                                                            message:
                                                                'Name must not exceed 40 characters'
                                                        });
                                                    } else {
                                                        clearErrors(
                                                            'firstName'
                                                        );
                                                    }
                                                }}
                                                onBlur={(e) => {
                                                    if (!e.target.value) {
                                                        setError('firstName', {
                                                            type: 'required',
                                                            message:
                                                                'Please provide your first name'
                                                        });
                                                    } else {
                                                        clearErrors(
                                                            'firstName'
                                                        );
                                                    }
                                                }}
                                                error={!!errors?.firstName}
                                                inputProps={{
                                                    autoComplete: '',
                                                    form: {
                                                        autoComplete: 'off'
                                                    },
                                                    inputMode: 'text'
                                                }}
                                                inputRef={ref}
                                                id="firstName"
                                                placeholder="Enter your first name"
                                                startAdornment={
                                                    <UserFormIcon />
                                                }
                                                rows={1}
                                                sx={{
                                                    backgroundColor: '#FFFFFF',
                                                    width: '100%',
                                                    height: {
                                                        sm: '70px',
                                                        xs: '55px'
                                                    },
                                                    fontSize: {
                                                        sm: '16px',
                                                        xs: '14px'
                                                    },
                                                    padding: '0px 16px',
                                                    fontFamily: 'open sans',
                                                    color: '#23282B',
                                                    borderBottom:
                                                        '1px solid #D9D9D9',
                                                    mb: '2px',
                                                    '& 	.MuiInputBase-input': {
                                                        ml: '20px',
                                                        position: 'relative',
                                                        top: '8px'
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                    <Typography
                                        sx={{
                                            color: 'red',
                                            fontSize: '12px',
                                            fontFamily: 'Open Sans'
                                        }}
                                    >
                                        {errors.firstName?.message}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item lg={6} md={12} sm={6} xs={12}>
                                <Box
                                    sx={{ width: '100%', position: 'relative' }}
                                >
                                    <InputLabel
                                        sx={{
                                            color: '#23282B',
                                            fontSize: {
                                                sm: '14px',
                                                xs: '12px'
                                            },
                                            position: 'absolute',
                                            top: '8px',
                                            left: '72px',
                                            zIndex: 1,
                                            fontFamily: 'Open Sans'
                                        }}
                                        htmlFor="lastName"
                                    >
                                        Last name
                                    </InputLabel>

                                    {/* last name */}
                                    <Controller
                                        {...register('lastName', {
                                            required: 'Required field'
                                        })}
                                        name="lastName"
                                        control={control}
                                        render={({
                                            field: {
                                                onChange,
                                                value,
                                                ref,
                                                onBlur
                                            },
                                            fieldState: { error },
                                            formState
                                        }) => (
                                            <InputBase
                                                required
                                                onChange={(e) => {
                                                    onChange(e);
                                                    if (
                                                        e.target.value.length >
                                                        40
                                                    ) {
                                                        setError('lastName', {
                                                            type: 'maxLength',
                                                            message:
                                                                'Name must not exceed 40 characters'
                                                        });
                                                    } else {
                                                        clearErrors('lastName');
                                                    }
                                                }}
                                                onBlur={(e) => {
                                                    if (!e.target.value) {
                                                        setError('lastName', {
                                                            type: 'required',
                                                            message:
                                                                'Please provide your last name'
                                                        });
                                                    } else {
                                                        clearErrors('lastName');
                                                    }
                                                }}
                                                error={!!errors?.lastName}
                                                inputProps={{
                                                    autoComplete: '',
                                                    form: {
                                                        autoComplete: 'off'
                                                    },
                                                    inputMode: 'text'
                                                }}
                                                inputRef={ref}
                                                id="lastName"
                                                placeholder="Enter your last name"
                                                startAdornment={
                                                    <UserFormIcon />
                                                }
                                                rows={1}
                                                sx={{
                                                    backgroundColor: '#FFFFFF',
                                                    width: '100%',
                                                    height: {
                                                        sm: '70px',
                                                        xs: '55px'
                                                    },
                                                    padding: '0px 16px',
                                                    fontFamily: 'open sans',
                                                    fontSize: {
                                                        sm: '16px',
                                                        xs: '14px'
                                                    },
                                                    color: '#23282B',
                                                    borderBottom:
                                                        '1px solid #D9D9D9',
                                                    mb: '2px',
                                                    '& 	.MuiInputBase-input': {
                                                        ml: '20px',
                                                        position: 'relative',
                                                        top: '8px'
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                    <Typography
                                        sx={{
                                            color: 'red',
                                            fontSize: '12px',
                                            fontFamily: 'Open Sans'
                                        }}
                                    >
                                        {errors.lastName?.message}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box
                                    sx={{ width: '100%', position: 'relative' }}
                                >
                                    <InputLabel
                                        sx={{
                                            color: '#23282B',
                                            fontSize: {
                                                sm: '14px',
                                                xs: '12px'
                                            },
                                            position: 'absolute',
                                            top: '8px',
                                            left: '72px',
                                            zIndex: 1,
                                            fontFamily: 'Open Sans'
                                        }}
                                        htmlFor="emailAddress"
                                    >
                                        Email
                                    </InputLabel>
                                    {/* email */}
                                    <Controller
                                        {...register('emailAddress', {
                                            required: 'Required field',
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: 'Invalid email address'
                                            }
                                        })}
                                        name="emailAddress"
                                        control={control}
                                        render={({
                                            field: {
                                                onChange,
                                                onBlur,
                                                value,
                                                ref
                                            },
                                            fieldState: { error },
                                            formState
                                        }) => (
                                            <InputBase
                                                required
                                                onChange={(e) => {
                                                    onChange(e);
                                                    if (
                                                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                                                            e.target.value
                                                        )
                                                    ) {
                                                        setError(
                                                            'emailAddress',
                                                            {
                                                                type: 'pattern',
                                                                message:
                                                                    'Invalid email address'
                                                            }
                                                        );
                                                    } else {
                                                        clearErrors(
                                                            'emailAddress'
                                                        );
                                                    }
                                                }}
                                                onBlur={(e) => {
                                                    if (!e.target.value) {
                                                        setError(
                                                            'emailAddress',
                                                            {
                                                                type: 'required',
                                                                message:
                                                                    'Please provide a valid email address'
                                                            }
                                                        );
                                                    } else {
                                                        clearErrors(
                                                            'emailAddress'
                                                        );
                                                    }
                                                }}
                                                placeholder="Enter your email address"
                                                error={!!errors?.emailAddress}
                                                inputProps={{
                                                    autoComplete: '',
                                                    form: {
                                                        autoComplete: 'off'
                                                    },
                                                    inputMode: 'email'
                                                }}
                                                inputRef={ref}
                                                id="emailAddress"
                                                startAdornment={
                                                    <EmailFormIcon />
                                                }
                                                rows={1}
                                                sx={{
                                                    backgroundColor: '#FFFFFF',
                                                    width: '100%',
                                                    height: {
                                                        sm: '70px',
                                                        xs: '55px'
                                                    },
                                                    padding: '0px 16px',
                                                    fontFamily: 'open sans',
                                                    fontSize: {
                                                        sm: '16px',
                                                        xs: '14px'
                                                    },
                                                    color: '#23282B',
                                                    mb: '2px',
                                                    borderBottom:
                                                        '1px solid #D9D9D9',
                                                    '& 	.MuiInputBase-input': {
                                                        ml: '20px',
                                                        position: 'relative',
                                                        top: '8px'
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                    <Typography
                                        sx={{
                                            color: 'red',
                                            fontSize: '12px',
                                            fontFamily: 'Open Sans'
                                        }}
                                    >
                                        {errors.emailAddress?.message}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box
                                    sx={{ width: '100%', position: 'relative' }}
                                >
                                    <InputLabel
                                        sx={{
                                            color: '#23282B',
                                            position: 'absolute',
                                            top: '8px',
                                            left: '72px',
                                            zIndex: 1,
                                            fontSize: {
                                                sm: '14px',
                                                xs: '12px'
                                            },
                                            fontFamily: 'Open Sans'
                                        }}
                                        htmlFor="password"
                                    >
                                        Password
                                    </InputLabel>
                                    {/* password */}
                                    <Controller
                                        name="password"
                                        control={control}
                                        render={({
                                            field: {
                                                onChange,
                                                onBlur,
                                                value,
                                                ref
                                            },
                                            fieldState: { error },
                                            formState
                                        }) => (
                                            <InputBase
                                                {...register('password', {
                                                    required: 'Required field',
                                                    minLength: {
                                                        value: 4,
                                                        message:
                                                            'Passwords must be a minimum of 4 characters'
                                                    },
                                                    pattern: {
                                                        value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]).{8,32}$/gm,
                                                        message:
                                                            'Invalid Password!'
                                                    }
                                                })}
                                                required
                                                onChange={(e) => {
                                                    onChange(e);
                                                    if (
                                                        e.target.value.length <
                                                        4
                                                    ) {
                                                        setError('password', {
                                                            type: 'minLength',
                                                            message:
                                                                'Passwords must be a minimum of 4 characters'
                                                        });
                                                    } else {
                                                        clearErrors('password');
                                                    }
                                                    if (
                                                        !/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]).{8,32}$/gm.test(
                                                            e.target.value
                                                        )
                                                    ) {
                                                        setError('password', {
                                                            type: 'pattern',
                                                            message:
                                                                'Invalid password'
                                                        });
                                                    } else {
                                                        clearErrors('password');
                                                    }
                                                }}
                                                // onBlur={(e) => {
                                                //     if (!e.target.value) {
                                                //         setError('password', {
                                                //             type: 'required',
                                                //             message:
                                                //                 'Please provide a valid password'
                                                //         });
                                                //     } else {
                                                //         clearErrors('password');
                                                //     }
                                                // }}
                                                error={!!error}
                                                inputProps={{
                                                    autoComplete:
                                                        'new-password',
                                                    form: {
                                                        autoComplete: 'off'
                                                    }
                                                }}
                                                inputRef={ref}
                                                name="password"
                                                id="password"
                                                startAdornment={
                                                    <PasswordFormIcon />
                                                }
                                                rows={1}
                                                placeholder={'********'}
                                                endAdornment={
                                                    showPass ? (
                                                        <RemoveRedEyeIcon
                                                            sx={{
                                                                color: '#808080',
                                                                cursor: 'pointer'
                                                            }}
                                                            onClick={() =>
                                                                setShowPass(
                                                                    (prev) =>
                                                                        !prev
                                                                )
                                                            }
                                                        />
                                                    ) : (
                                                        <VisibilityOffIcon
                                                            sx={{
                                                                color: '#808080',
                                                                cursor: 'pointer'
                                                            }}
                                                            onClick={() =>
                                                                setShowPass(
                                                                    (prev) =>
                                                                        !prev
                                                                )
                                                            }
                                                        />
                                                    )
                                                }
                                                type={
                                                    showPass
                                                        ? 'text'
                                                        : 'password'
                                                }
                                                sx={{
                                                    backgroundColor: '#FFFFFF',
                                                    width: '100%',
                                                    height: {
                                                        sm: '70px',
                                                        xs: '55px'
                                                    },
                                                    padding: '0px 16px',
                                                    mb: '2px',
                                                    fontFamily: 'open sans',
                                                    fontSize: {
                                                        sm: '16px',
                                                        xs: '14px'
                                                    },
                                                    color: '#23282B',
                                                    borderBottom:
                                                        '1px solid #D9D9D9',
                                                    '& 	.MuiInputBase-input': {
                                                        ml: '20px',
                                                        position: 'relative',
                                                        top: '8px'
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                    <Typography
                                        sx={{
                                            color: 'red',
                                            fontSize: '12px',
                                            fontFamily: 'Open Sans'
                                        }}
                                    >
                                        {errors.password?.message}
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: '5px',
                                            mt: '10px',
                                            alignContent: 'start'
                                        }}
                                    >
                                        <ErrorFormIcon />
                                        <Typography
                                            sx={{
                                                color: '#808080',
                                                fontSize: '12px',
                                                fontFamily: 'Open Sans'
                                            }}
                                        >
                                            Password should contain uppercase
                                            letter(s), numbers(s) and special
                                            character(s)
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box
                                    sx={{ width: '100%', position: 'relative' }}
                                >
                                    <InputLabel
                                        sx={{
                                            color: '#23282B',
                                            fontSize: {
                                                sm: '14px',
                                                xs: '12px'
                                            },
                                            position: 'absolute',
                                            top: '8px',
                                            left: '72px',
                                            zIndex: 1,
                                            fontFamily: 'Open Sans'
                                        }}
                                        htmlFor="cpassword"
                                    >
                                        Confirm Password
                                    </InputLabel>
                                    {/* password */}
                                    <Controller
                                        name="cpassword"
                                        control={control}
                                        render={({
                                            field: {
                                                onChange,
                                                onBlur,
                                                value,
                                                ref
                                            },
                                            fieldState: { error },
                                            formState
                                        }) => (
                                            <InputBase
                                                {...register('cpassword', {
                                                    required: 'Required field',
                                                    minLength: {
                                                        value: 4,
                                                        message:
                                                            'Passwords must be a minimum of 4 characters'
                                                    },
                                                    pattern: {
                                                        value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]).{8,32}$/gm,
                                                        message:
                                                            'Invalid Password!'
                                                    }
                                                    // validate: {
                                                    //     value: control._formValues.cpassword !== control._formValues.password,
                                                    //     message: 'Password and Confirm Password does not match!'
                                                    // }
                                                })}
                                                required
                                                onChange={(e) => {
                                                    onChange(e);
                                                    if (
                                                        e.target.value.length <
                                                        4
                                                    ) {
                                                        setError('cpassword', {
                                                            type: 'minLength',
                                                            message:
                                                                'Passwords must be a minimum of 4 characters'
                                                        });
                                                    } else if (
                                                        !/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]).{8,32}$/gm.test(
                                                            e.target.value
                                                        )
                                                    ) {
                                                        setError('cpassword', {
                                                            type: 'pattern',
                                                            message:
                                                                'Invalid password'
                                                        });
                                                    } else if (
                                                        e.target.value !==
                                                        control._formValues
                                                            .password
                                                    ) {
                                                        setError('cpassword', {
                                                            type: 'validate',
                                                            message:
                                                                'Password and Confirm Password does not match!'
                                                        });
                                                    } else {
                                                        clearErrors(
                                                            'cpassword'
                                                        );
                                                    }
                                                }}
                                                // onBlur={(e) => {
                                                //     if (!e.target.value) {
                                                //         setError('cpassword', {
                                                //             type: 'required',
                                                //             message:
                                                //                 'Please provide a valid password'
                                                //         });
                                                //     } else if (e.target.value !== control._formValues.password) {
                                                //         console.log('e.target.value',e.target.value)
                                                //         console.log('state.data.password',control._formValues.password)
                                                //         setError('cpassword', {
                                                //             type: 'required',
                                                //             message:
                                                //                 'Password and Confirm Password does not match!'
                                                //         });
                                                //     } else {
                                                //         clearErrors('cpassword');
                                                //     }
                                                // }}
                                                error={!!error}
                                                inputProps={{
                                                    autoComplete:
                                                        'new-password',
                                                    form: {
                                                        autoComplete: 'off'
                                                    }
                                                }}
                                                inputRef={ref}
                                                name="cpassword"
                                                id="cpassword"
                                                startAdornment={
                                                    <PasswordFormIcon />
                                                }
                                                endAdornment={
                                                    showCPass ? (
                                                        <RemoveRedEyeIcon
                                                            sx={{
                                                                color: '#808080',
                                                                cursor: 'pointer'
                                                            }}
                                                            onClick={() =>
                                                                setShowCPass(
                                                                    (prev) =>
                                                                        !prev
                                                                )
                                                            }
                                                        />
                                                    ) : (
                                                        <VisibilityOffIcon
                                                            sx={{
                                                                color: '#808080',
                                                                cursor: 'pointer'
                                                            }}
                                                            onClick={() =>
                                                                setShowCPass(
                                                                    (prev) =>
                                                                        !prev
                                                                )
                                                            }
                                                        />
                                                    )
                                                }
                                                rows={1}
                                                placeholder={'********'}
                                                type={
                                                    showCPass
                                                        ? 'text'
                                                        : 'password'
                                                }
                                                sx={{
                                                    backgroundColor: '#FFFFFF',
                                                    width: '100%',
                                                    height: {
                                                        sm: '70px',
                                                        xs: '55px'
                                                    },
                                                    padding: '0px 16px',
                                                    mb: '2px',
                                                    fontFamily: 'open sans',
                                                    fontSize: {
                                                        sm: '16px',
                                                        xs: '14px'
                                                    },
                                                    color: '#23282B',
                                                    borderBottom:
                                                        '1px solid #D9D9D9',
                                                    '& 	.MuiInputBase-input': {
                                                        ml: '20px',
                                                        position: 'relative',
                                                        top: '8px'
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                    <Typography
                                        sx={{
                                            color: 'red',
                                            fontSize: '12px',
                                            fontFamily: 'Open Sans'
                                        }}
                                    >
                                        {errors.cpassword?.message}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            mb: '20px',
                            width: '100%'
                        }}
                    >
                        <Controller
                            name="termAgreement"
                            control={control}
                            rules={{ required: true }}
                            render={({
                                field: { onChange, onBlur, ref },
                                fieldState: {
                                    invalid,
                                    isTouched,
                                    isDirty,
                                    error
                                },
                                formState
                            }) => {
                                return (
                                    <Checkbox
                                        required
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        inputRef={ref}
                                        name="termAgreement"
                                        inputProps={{
                                            'aria-label':
                                                'I agree to all the Terms and Privacy policy'
                                        }}
                                        sx={{
                                            color: '#D1D1D1',
                                            padding: 0
                                        }}
                                    />
                                );
                            }}
                        />
                        <Typography
                            sx={{
                                fontSize: '14px',
                                fontFamily: 'Open Sans',
                                color: '#23282B',
                                ml: '12px'
                            }}
                        >
                            I agree to all the&nbsp;
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    fontFamily: 'Open Sans',
                                    color: '#055C7F',
                                    fontWeight: 600,
                                    cursor: 'pointer',
                                    '&:hover': {
                                        textDecoration: 'underline'
                                    }
                                }}
                                component={'span'}
                                onClick={() =>
                                    navigateTo(
                                        'https://www.jobfactor.com/policy'
                                    )
                                }
                            >
                                Terms
                            </Typography>{' '}
                            and&nbsp;
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    fontFamily: 'Open Sans',
                                    color: '#055C7F',
                                    fontWeight: 600,
                                    cursor: 'pointer',
                                    '&:hover': {
                                        textDecoration: 'underline'
                                    }
                                }}
                                component={'span'}
                                onClick={() =>
                                    navigateTo(
                                        'https://www.jobfactor.com/policy'
                                    )
                                }
                            >
                                Privacy policy
                            </Typography>
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        {loading ? (
                            <CircularProgress sx={{ color: '#05668D' }} />
                        ) : (
                            <Button
                                sx={{
                                    height: { sm: '52px', xs: '45px' }
                                }}
                                variant="contained"
                                disabled={
                                    !isDirty ||
                                    !isValid ||
                                    Object.keys(errors).length !== 0
                                }
                                onClick={handleSubmit(onSubmit)}
                            >
                                <Typography
                                    variant="bodyLargeBold"
                                    textTransform="capitalize"
                                    fontSize={{ sm: '16px', xs: '14px' }}
                                >
                                    Create Account
                                </Typography>
                            </Button>
                        )}

                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                position: 'relative',
                                justifyContent: 'center',
                                width: '100%',
                                '& svg': {
                                    width: '100%'
                                }
                            }}
                        >
                            <OnboardingLineIcon />
                            {/* <Box
                            sx={{
                                height: '1px',
                                width: '100%',
                                alignSelf: 'center',
                                backgroundColor: '#D9D9D9',
                                position: 'absolute'
                            }}
                        /> */}
                            <Typography
                                variant="bodyLargeRegular"
                                sx={{
                                    width: '100%',
                                    py: '12px',
                                    backgroundColor: '#fcfbf8',
                                    zIndex: 1,
                                    fontSize: { sm: '16px', xs: '14px' },
                                    textAlign: 'center',
                                    whiteSpace: 'nowrap'
                                }}
                            >
                                OR
                            </Typography>
                            <OnboardingLineIcon />
                        </Box>
                        <Grid
                            container
                            sx={{
                                width: '100%',
                                justifyContent: 'space-evenly'
                            }}
                            spacing={1.5}
                        >
                            <Grid item lg={6} md={12} sm={6} xs={12}>
                                <Button
                                    onClick={() => {
                                        if (checkTermAgreement()) googleLogin();
                                    }}
                                    sx={{
                                        height: { sm: '52px', xs: '45px' }
                                    }}
                                    variant="outlined"
                                    startIcon={<GoogleIcon />}
                                >
                                    <Typography
                                        variant="bodyLargeSemiBold"
                                        fontSize={{ sm: '16px', xs: '14px' }}
                                    >
                                        Sign up with Google
                                    </Typography>
                                </Button>
                            </Grid>
                            <Grid item lg={6} md={12} sm={6} xs={12}>
                                <Button
                                    onClick={() => {
                                        if (checkTermAgreement())
                                            linkedInLogin();
                                    }}
                                    sx={{
                                        height: { sm: '52px', xs: '45px' }
                                    }}
                                    variant="outlined"
                                    startIcon={<LinkedIn />}
                                >
                                    <Typography
                                        variant="bodyLargeSemiBold"
                                        fontSize={{ sm: '16px', xs: '14px' }}
                                    >
                                        Sign up with LinkedIn
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>

                        <Box
                            sx={{
                                display: 'flex',
                                mt: '24px'
                            }}
                        >
                            <Typography
                                sx={{
                                    color: '#808080',
                                    mr: '10px',
                                    fontSize: { sm: '14px', xs: '12px' }
                                }}
                            >
                                Already a Member?
                            </Typography>
                            {/* Text link */}
                            <Typography
                                fontWeight={600}
                                fontSize={{ sm: '16px', xs: '14px' }}
                                fontStyle={'semibold'}
                            >
                                <Link
                                    to={'/login'}
                                    style={{
                                        color: '#05668D',
                                        textDecoration: 'none'
                                    }}
                                >
                                    Sign in
                                </Link>
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <SnackAlert
                open={showAlert}
                handleClose={() => setShowAlert(false)}
                message={message}
                type={'error'}
            />
        </>
    );
}

export default UserCreate;
