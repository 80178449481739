import React, { FC } from 'react';
import BellIcon from './BellIcon';
import { Badge } from '@mui/material';

interface NotificationBellProps {
    notificationCount: number;
}

const NotificationBell: FC<NotificationBellProps> = ({ notificationCount }) => {
    return (
        <Badge
            color="primary"
            badgeContent={notificationCount}
            overlap="circular"
            variant="dot"
        >
            <BellIcon />
        </Badge>
    );
};

export default NotificationBell;
