import { Box, Button, Typography } from '@mui/material';
import React from 'react'
import Increase from '../../../../assets/icons/Increase';
import ArrowForward from '../../../../assets/icons/ArrowForward';

const ScoringCard = ({
    value,
    title,
    subTitle
}: {
    value: any;
    title: any;
        subTitle: any;
}) => {
    return (
        <Box
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent='space-between'
            maxWidth={{ xs: '100%', sm: '260px' }}
            borderRadius="8px"
            boxShadow="0px 0px 20px 4px rgba(0, 0, 0, 0.07)"
        >
            <Box>
                <Box
                    py="16px"
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    bgcolor="#DFEBF0"
                    borderRadius="8px 8px 24px 24px"
                    mb="20px"
                >
                    <Typography
                        fontSize="16px"
                        fontWeight={600}
                        color="#23282B"
                    >
                        {title}
                    </Typography>
                    <Typography
                        fontSize="20px"
                        fontWeight={700}
                        color="#23282B"
                    >
                        {value}
                    </Typography>
                </Box>
                <Box
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    gap="20px"
                    alignItems="flex-end"
                    mb="16px"
                    height="fit-content"
                >
                    <Box
                        pt="10px"
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                    >
                        <Typography
                            fontSize="14px"
                            fontWeight={600}
                            color="#23282B"
                            mb="4px"
                        >
                            60%
                        </Typography>
                        <Typography fontSize="12px" color="#808080">
                            Percentage increase
                        </Typography>
                    </Box>
                    <Box width="1px" height="44px" bgcolor="#D9D9D9"></Box>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                    >
                        <Increase />
                        <Typography fontSize="12px" color="#808080" mt="4px">
                            Increase
                        </Typography>
                    </Box>
                </Box>
                <Box display="flex" justifyContent="center" alignItems="center">
                    <Box mb="20px" width="100%" maxWidth="225px">
                        <Typography
                            fontSize="14px"
                            color="#23282B"
                            textAlign="center"
                        >
                            {subTitle}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box
                px="16px"
                pb="16px"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="flex-end"
            >
                <Button
                    variant="contained"
                    sx={{
                        bgcolor: '#05668D',
                        width: '227px',
                        height: '44px',
                        fontSiz: '14px',
                        fontWeight: 700,
                        color: 'white',
                        borderRadius: '8px',
                        textTransform: 'none'
                    }}
                    endIcon={<ArrowForward />}
                >
                    View details
                </Button>
            </Box>
        </Box>
    );
};

export default ScoringCard
