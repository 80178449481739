import { Box } from '@mui/system';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useHover } from 'usehooks-ts';
import { IMenu } from './SideNav';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { JSX } from 'react/jsx-runtime';

export default function SideNavItem(props: {
    nav: IMenu;
    hideMenu: boolean;
    index: number;
    currentItem: IMenu;
    subMenuIndex: number;
    handleActiveMenu: (e: any) => void;
}): JSX.Element {
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
    const hoverRef = useRef(null);
    const [isSelected, setIsSelected] = useState(false);
    const isHover = useHover(hoverRef);
    const {
        nav,
        index,
        handleActiveMenu,
        currentItem,
        hideMenu,
        subMenuIndex
    } = props;
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (nav.route === location.pathname) {
            setIsSelected(true);
        } else if (currentItem.submenu) {
            if (
                location.pathname ===
                currentItem.submenu[subMenuIndex]?.route &&
                currentItem.route === nav.route
            ) {
                setIsSelected(true);
            } else {
                setIsSelected(false);
            }
        } else {
            setIsSelected(false);
        }
    }, [currentItem, location.pathname, nav.route, nav.submenu, subMenuIndex]);

    const handleClick = (e: any) => {
        handleActiveMenu(e);
        if ((isTablet && (!nav.submenu || nav.submenu?.length === 0)) || !isTablet) {
            navigate(nav.route);
        }
        setIsSelected(true);
    };

    return (
        <>
            <Box
                ref={hoverRef}
                onClick={handleClick}
                key={index}
                sx={{
                    overflowY: 'auto', // add scroll on y-axis
                    overflow: hideMenu ? 'hidden' : 'unset',
                    height: 60,
                    display: { lg: 'flex', xs: 'none' },
                    alignItems: 'center',
                    gap: '10px',
                    paddingLeft: '12px',
                    position: 'relative',
                    '&:hover, &:focus': {
                        backgroundColor: '#F2F2F2',
                        cursor: 'pointer',
                        fontWeight: 700,
                        color: '#05668D',
                        textTransform: 'uppercase',
                        '& .bar': {
                            opacity: 1
                        }
                    },
                    backgroundColor: isSelected ? '#fff' : 'transparent',
                    fontWeight: isSelected ? 700 : 'normal',
                    color: isSelected ? '#05668D' : '#808080',
                    textTransform: 'uppercase',
                    '& svg': {
                        minWidth: '32px'
                    }
                }}
            >
                <Box
                    sx={{
                        height: 60,
                        width: 5,
                        backgroundColor: isSelected ? '#05668D' : 'transparent',
                        opacity: isSelected || isHover ? 1 : 0,
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        transition: 'background-color 0.3s ease-in-out'
                    }}
                    className="bar"
                ></Box>
                {<nav.icon isHover={isHover} isSelected={isSelected} />}
                {nav.label}
            </Box>
            <Box
                ref={hoverRef}
                onClick={() => navigate(nav.route)}
                key={`nested-${index}`}
                sx={{
                    mb: '40px',
                    display: { lg: 'none', xs: 'flex' },
                    alignItems: 'center',
                    justifyContent: { lg: 'start', xs: 'center' },
                    gap: '1px',
                    paddingLeft: { lg: '12px', xs: 0 },
                    position: 'relative',
                    '&:hover, &:focus': {
                        backgroundColor: '#F2F2F2',
                        cursor: 'pointer',
                        fontWeight: 700,
                        color: '#05668D',
                        textTransform: 'uppercase',
                        '& .bar': {
                            opacity: 1
                        }
                    },
                    fontWeight: isSelected ? 700 : 'normal',
                    color: isSelected ? '#05668D' : '#808080',
                    textTransform: 'uppercase',
                    flexDirection: 'column',
                    '& svg': {
                        minWidth: '30px',
                        minHeight: '30px'
                    }
                }}
            >
                {<nav.icon isHover={isHover} isSelected={isSelected} />}
                <Typography variant='labelMediumSemiBold' component={'span'} sx={{textTransform: 'capitalize'}}>
                    {nav.label}
                </Typography>
            </Box>
        </>
    );
}
