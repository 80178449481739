const TeamIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.5291 7.77C17.4591 7.76 17.3891 7.76 17.3191 7.77C15.7691 7.72 14.5391 6.45 14.5391 4.89C14.5391 3.3 15.8291 2 17.4291 2C19.0191 2 20.3191 3.29 20.3191 4.89C20.3091 6.45 19.0791 7.72 17.5291 7.77Z"
                fill="#3785A4"
            />
            <path
                d="M20.7896 14.7004C19.6696 15.4504 18.0996 15.7304 16.6496 15.5404C17.0296 14.7204 17.2296 13.8104 17.2396 12.8504C17.2396 11.8504 17.0196 10.9004 16.5996 10.0704C18.0796 9.8704 19.6496 10.1504 20.7796 10.9004C22.3596 11.9404 22.3596 13.6504 20.7896 14.7004Z"
                fill="#3785A4"
            />
            <path
                d="M6.44016 7.77C6.51016 7.76 6.58016 7.76 6.65016 7.77C8.20016 7.72 9.43016 6.45 9.43016 4.89C9.43016 3.29 8.14016 2 6.54016 2C4.95016 2 3.66016 3.29 3.66016 4.89C3.66016 6.45 4.89016 7.72 6.44016 7.77Z"
                fill="#3785A4"
            />
            <path
                d="M6.54914 12.8496C6.54914 13.8196 6.75914 14.7396 7.13914 15.5696C5.72914 15.7196 4.25914 15.4196 3.17914 14.7096C1.59914 13.6596 1.59914 11.9496 3.17914 10.8996C4.24914 10.1796 5.75914 9.88962 7.17914 10.0496C6.76914 10.8896 6.54914 11.8396 6.54914 12.8496Z"
                fill="#3785A4"
            />
            <path
                d="M12.1208 15.87C12.0408 15.86 11.9508 15.86 11.8608 15.87C10.0208 15.81 8.55078 14.3 8.55078 12.44C8.56078 10.54 10.0908 9 12.0008 9C13.9008 9 15.4408 10.54 15.4408 12.44C15.4308 14.3 13.9708 15.81 12.1208 15.87Z"
                fill="#3785A4"
            />
            <path
                d="M8.87078 17.9396C7.36078 18.9496 7.36078 20.6096 8.87078 21.6096C10.5908 22.7596 13.4108 22.7596 15.1308 21.6096C16.6408 20.5996 16.6408 18.9396 15.1308 17.9396C13.4208 16.7896 10.6008 16.7896 8.87078 17.9396Z"
                fill="#3785A4"
            />
        </svg>
    );
};

export default TeamIcon;
