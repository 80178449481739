import { useQuery } from 'react-query';
import { QueryKeys } from '../QueryKey';
import axiosInstance from '../axiosConfig';

export function useGetUniversity(name: string) {
    const getUniversity = async (name: string) => {
        if (name.length < 3) return;
        const response = await axiosInstance.get(
            `/api/universities/search?name=${name}`
        );
        const data = await response.data;
        return data;
    };
    return useQuery(QueryKeys.RetrieveUniversity, () => getUniversity(name));
}
