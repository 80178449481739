import React from 'react';

interface FacebookProps {
    color?: string;
    width?: string;
    height?: string;
}

const Facebook: React.FC<FacebookProps> = ({
    color = '#05668D',
    width,
    height
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 41 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1208_645)">
                <path
                    d="M20.5 40C31.5457 40 40.5 31.0457 40.5 20C40.5 8.9543 31.5457 0 20.5 0C9.4543 0 0.5 8.9543 0.5 20C0.5 31.0457 9.4543 40 20.5 40Z"
                    fill={color}
                />
                <path
                    d="M30.7889 7.01562H25.7255C22.7207 7.01562 19.3785 8.2794 19.3785 12.635C19.3932 14.1526 19.3785 15.6061 19.3785 17.2419H15.9023V22.7735H19.4861V38.698H26.0713V22.6684H30.4178L30.8111 17.2264H25.9579C25.9579 17.2264 25.9687 14.8055 25.9579 14.1025C25.9579 12.3813 27.7489 12.4798 27.8566 12.4798C28.7089 12.4798 30.366 12.4823 30.7914 12.4798V7.01562H30.7889Z"
                    fill="#FAFAFA"
                />
            </g>
            <defs>
                <clipPath id="clip0_1208_645">
                    <rect
                        width="40"
                        height="40"
                        fill="white"
                        transform="translate(0.5)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Facebook;
