import { Avatar, Box, Checkbox, Stack, Typography } from '@mui/material';
import { IConversation } from '../../utils/types';
import { isImageUrl, toPreviewTimestamp } from '../../utils/helpers';
import { useMessages } from '../../utils/MessagesContext';
import UnreadCountBadge from './UnreadCountBadge';
import React, { useEffect } from 'react';
import { Image } from '@mui/icons-material';

export default function ConversationPreview({
    conversation
}: {
    conversation: IConversation;
}) {
    const {
        userId,
        socket,
        setActiveConversation,
        viewMode,
        setViewMode,
        managedConversationIds,
        setManagedConversationIds,
        setAlert
    } = useMessages();

    const [isSelected, setIsSelected] = React.useState(false);

    const updateActiveConversation = () => {
        if (!socket) return;
        setViewMode(null);
        socket.emit(
            'openConversation',
            {
                senderId: userId,
                recipientId: conversation.userId
            },
            (res: any) => {
                if (typeof res === 'boolean' && res) {
                    setActiveConversation(conversation);
                } else {
                    setAlert('Failed to open conversation', 'error');
                }
            }
        );
    };

    const updateSelected = () => {
        setManagedConversationIds((prev) => {
            if (!prev.includes(conversation.userId)) {
                return [...prev, conversation.userId];
            } else {
                return prev.filter((id) => id !== conversation.userId);
            }
        });
    };

    useEffect(() => {
        setIsSelected(managedConversationIds.includes(conversation.userId));
    }, [managedConversationIds]);

    return (
        <Box
            onClick={() =>
                viewMode === 'manage'
                    ? updateSelected()
                    : updateActiveConversation()
            }
            sx={{
                width: '100%',
                height: '84px',
                mt: '12px',
                display: 'flex',
                alignItems: 'center',
                p: '16px 16px 16px 32px',
                cursor: 'pointer',
                borderBottom: '0.5px solid #D5D8DC',
                transition: 'background-color 0.3s ease',
                '&:hover': {
                    backgroundColor: '#F7F7F7'
                }
            }}
        >
            <Stack
                direction={'row'}
                spacing={1.5}
                sx={{ width: '100%', justifyContent: 'space-evenly' }}
            >
                {viewMode === 'manage' && <Checkbox checked={isSelected} />}
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar src={conversation.imageUrl} />
                </Box>
                <Stack>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '322px'
                        }}
                    >
                        <Typography sx={{ fontSize: '20px' }}>
                            {conversation.name}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: '12px',
                                fontWeight: '300',
                                whiteSpace: 'nowrap'
                            }}
                        >
                            {toPreviewTimestamp(conversation.timestamp)}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Typography
                            sx={{
                                width: '290px',
                                fontSize: '14px',
                                color: '#808080',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}
                        >
                            {isImageUrl(conversation.preview) ? (
                                <Stack direction="row">
                                    <Image
                                        sx={{ fontSize: '20px', mr: '4px', opacity: '80%' }}
                                    />
                                    Image
                                </Stack>
                            ) : (
                                conversation.preview
                            )}
                        </Typography>
                        <UnreadCountBadge count={conversation.unreadCount} />
                    </Box>
                </Stack>
            </Stack>
        </Box>
    );
}
