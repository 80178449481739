import { Autocomplete, Button, Stack, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useMessages } from '../../utils/MessagesContext';
import axiosInstance from '../../../../utils/hooks/api/axiosConfig';
import { IUserSearchResult } from '../../utils/types';
import SelectedUserCard from './SelectedUserCard';
import { logger } from '../../../../utils/Helper/logger';

export const SearchBar = () => {
    const { userId, setViewMode, setActiveConversation, setAlert } =
        useMessages();
    const [fallbackResults, setFallbackResults] = useState<IUserSearchResult[]>(
        []
    );
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [isSearching, setIsSearching] = useState(false);
    const [searchResults, setSearchResults] = useState<IUserSearchResult[]>([]);
    const [selectedUser, setSelectedUser] = useState<IUserSearchResult | null>(
        null
    );
        

    const fetchSearchResults = async () => {
        try {
            const searchUrl =
                '/api/messages/search-users/' +
                userId +
                (searchTerm.length ? `?search=${searchTerm}` : '');
            const { data } = await axiosInstance.get<IUserSearchResult[]>(
                searchUrl
            );
            return data;
        } catch (err) {
            logger.error('failed to fetch', err);
            setAlert('Failed to fetch search results', 'error');
            return [];
        }
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(async () => {
            setIsSearching(true);
            const results = await fetchSearchResults();
            setSearchResults(results);
            setIsSearching(false);
        }, 600);

        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm]);

    const checkIfUsingFallback = (): boolean => {
        if (searchResults.length !== fallbackResults.length) return false;
        return searchResults.every((result) =>
            fallbackResults.some((r) => r.userId === result.userId)
        );
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newTerm = e.target.value;
        setSearchTerm(newTerm);
        if (newTerm.length < 3) {
            if (checkIfUsingFallback()) {
                return;
            } else {
                return setSearchResults(fallbackResults);
            }
        }
    };

    const handleSelectChange = (
        e: unknown,
        value: IUserSearchResult | null
    ) => {
        setSelectedUser(value);
    };

    const handleStartConversation = () => {
        if (!selectedUser) return;
        const { userId, name, imageUrl, profileSlug } = selectedUser;
        setActiveConversation({
            userId,
            name,
            imageUrl,
            profileSlug
        });
        setViewMode(null);
    };

    // Get fallback results on mount
    useEffect(() => {
        const getFallbackResults = async () => {
            const results = await fetchSearchResults();
            setFallbackResults(results);
            setSearchResults(results);
        };
        getFallbackResults();
    }, []);

    return (
        <Stack
            spacing={2}
            direction={{ xs: 'column', sm: 'row' }}
            sx={{
                width: '100%',
                alignItems: 'center'
            }}
        >
            <Autocomplete
                openOnFocus
                disablePortal
                loading={isSearching}
                options={searchResults}
                filterOptions={(x) => x}
                getOptionLabel={(option) => option.name}
                sx={{
                    minWidth: '50%',
                    width: { xs: '200px', sm: '300px', md: '60%' },
                    display: {
                        xs: selectedUser ? 'none' : 'block',
                        md: 'block'
                    },
                    py: 0
                }}
                value={selectedUser}
                onChange={handleSelectChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder="Search for a user"
                        onChange={handleInputChange}
                        inputProps={{
                            ...params.inputProps,
                            sx: { py: '9.5px' }
                        }}
                        sx={{
                            width: '100%',
                            py: 0
                        }}
                    />
                )}
            />
            <SelectedUserCard user={selectedUser} />
            {selectedUser && (
                <Stack direction="row" spacing={2}>
                    <Button
                        variant="contained"
                        onClick={handleStartConversation}
                        sx={{
                            minWidth: '80px',
                            maxWidth: '80px',
                            height: '40%'
                        }}
                    >
                        Message
                    </Button>
                    <Button
                        variant="outlined"
                        sx={{ display: { md: 'none' } }}
                        onClick={() => setSelectedUser(null)}
                    >
                        Cancel
                    </Button>
                </Stack>
            )}
        </Stack>
    );
};
