import { UseMutationResult, useMutation } from 'react-query';
import axiosInstance from '../axiosConfig';
import { PatchUserAccountRequest, PatchUserAccountResponse } from './types';

async function patchUserInfo(
    patchRequest: PatchUserAccountRequest
): Promise<PatchUserAccountResponse> {
    const response = await axiosInstance.patch(
        `/api/professional-profiles/${patchRequest.professionalProfileId}`,
        patchRequest
    );
    const data = await response.data;
    return data;
}

export function usePatchUserInfo(): UseMutationResult<
    PatchUserAccountResponse,
    unknown,
    PatchUserAccountRequest
> {
    return useMutation(patchUserInfo);
}

export default usePatchUserInfo;
