import JobFactorIcon from '../assets/icons/JobFactorIcon';
import ArrowDown from '../assets/icons/ArrowDown';
import SearchIcon from '../assets/icons/SearchIcon';
import React, { Fragment, useEffect, useState } from 'react';
import { useAuth } from '../utils/context/AuthContext';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import {
    Autocomplete,
    AppBar,
    Avatar,
    Box,
    Button,
    Container,
    IconButton,
    InputAdornment,
    Menu,
    MenuItem,
    TextField,
    useTheme,
    useMediaQuery,
    Typography,
    MenuProps,
    Stack
} from '@mui/material';
import { useGetSearchTerm } from '../utils/hooks/api/search/useSearchTerm';
import { styled, lighten, darken } from '@mui/system';
import { IUserType } from '../pages/Reviews/types';
import { PrimaryProfileType } from '../utils/hooks/api/account/types';
import { useSendConnectionRequest } from '../utils/hooks/api/connections/useSendConnectionRequest';
import { QueryKeys } from '../utils/hooks/api/QueryKey';
import { useQueryClient } from 'react-query';
import SnackAlert, { SnackAlertType } from './Snackbar';
import axios, { AxiosError } from 'axios';
import { useGetConnections } from '../utils/hooks/api/connections/useGetConnections';
import NotificationBell from '../assets/icons/NotificationBell';
import InviteDialog, { inviteDialogSignal } from './InviteDialog';
import ConnectionSkills from '../pages/Connections/components/ConnectionSkillsTab';
import { logger } from '../utils/Helper/logger';
import { useGetUserInfo } from '../utils/hooks/api/users/useGetUserInfo';
import { useSendEmailNotification } from '../utils/hooks/api/email/useSendEmailNotification';
import { useGetUser } from '../utils/hooks/api/users/useGetUser';
import MobileDrawer from './MobileDrawer';
import { useGetProfessionalProfileScore } from '../utils/hooks/api/professional-profile/useGetProfessionalProfileScore';
import { generateHtml, generateSubject } from '../utils/emails/emailtemplates';
import { connectionRequest } from '../utils/emails/email';
import { ArrowLeftIcon } from '../assets/icons/ArrowLeftIcon';
import axiosInstance from '../utils/hooks/api/axiosConfig';
import UserDetails from '../pages/Connections/components/UserDetails';
import image from '../assets/images/galleryImage1.png';
import { useGetAllProfessionalProfile } from '../utils/hooks/api/professional-profile/useGetAllProfessionals';
import { useGetProfessionalProfileById } from '../utils/hooks/api/professional-profile/useGetProfessionalProfile';
import { useGetAllCompaniesBySearchTerm } from '../utils/hooks/api/company-profile/useGetAllCompaniesBySearchTerm';
import SearchOutlined from '../assets/icons/SearchOutlined';

const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: '#23282B',
    fontSize: 14,
    fontWeight: 700,
    zIndex: 1,
    backgroundColor:
        theme.palette.mode === 'light'
            ? lighten(theme.palette.primary.light, 0.85)
            : darken(theme.palette.primary.main, 0.8)
}));

const GroupItems = styled('ul')({
    padding: 0
});

interface PropTypes {
    handleAdd?: (user: IUserType) => void;
    showConnections?: boolean;
}
interface IOptionType {
    id: string;
    term: string;
    entityType: string;
}
function JobfactorAppBar({ handleAdd, showConnections = false }: PropTypes) {
    const theme = useTheme();
    // const md = useMediaQuery(theme.breakpoints.down('md'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
    const [type, setType] = useState<SnackAlertType>('info');
    const [subAnchorEl, setSubAnchorEl] = React.useState<null | HTMLElement>(
        null
    );
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [showGrouping, setShowGrouping] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState('');
    const [showSearch, setShowSearch] = useState(false);
    const [focus, setFocus] = useState(false);
    const [options, setOptions] = useState([]);
    const [search, setSearch] = useState('');
    const pathname = useLocation()?.pathname;
    const navigate = useNavigate();
    const { signOut, user } = useAuth();
    const [showAccountTypes, setShowAccountTypes] = useState(false);
    const [showSideBar, setShowSideBar] = React.useState<null | HTMLElement>(
        null
    );
    const { data: allProfessionals } = useGetAllProfessionalProfile();
    const { data: allCompanies } = useGetAllCompaniesBySearchTerm(search ?? '');
    const mySearch =
        search.length <= 0
            ? []
            : allProfessionals?.filter(
                  (user: any) =>
                      user.firstName
                          .toLowerCase()
                          .concat(' ', user.lastName.toLowerCase())
                          .includes(search.toLowerCase()) ||
                      user.firstName
                          .toLowerCase()
                          .concat(' ', user.lastName.toLowerCase())
                          .includes(
                              search
                                  .toLowerCase()
                                  .split(' ')
                                  .reverse()
                                  .join(' ')
                          )
              );

    const totalResult = mySearch?.concat(allCompanies ?? []);
    // console.log(allCompanies)
    // console.log('total', totalResult)

    const handleClose = () => {
        setAnchorEl(null);
        setSubAnchorEl(null);
    };

    const openSideBar = (event: any) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        if (showSideBar) {
            setShowSideBar(null);
            handleClose();
            setShowAccountTypes(false);
        } else setShowSideBar(event.currentTarget);
    };

    const [allSearch, setAllSearch] = useState<any | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const getSearch = async () => {
        if (search.length > 0) {
            setLoading(true);
            setAllSearch([]);
            try {
                const data: any = await axios.get(
                    process.env.REACT_APP_BASE_URL +
                        `/api/search?term=${search}&userId=${user?.professionalProfile?.userId}`
                );
                // console.log(search)
                const res = data.data;
                setLoading(false);
                setAllSearch(res);
                // console.log('res', allSearch)
            } catch (error: any) {
                setError(error.message);
                console.log('error', error);
                setLoading(false);
                setAllSearch([]);
            }
        }
    };

    // console.log(allSearch, search)

    // const { data: searchedData, refetch } = useGetSearchTerm(
    //     showConnections ? '' : search,
    //     user?.professionalProfile?.userId ?? ''
    // );

    // console.log(
    //     'Searched Data',
    //     searchedData,
    //     user?.professionalProfile?.userId
    // );

    const { data: connections, refetch: refetchConnections } =
        useGetConnections(user?.id ?? '');

    // const handleData = () => {
    //     setOptions([]);
    //     if (search.length > 1 && showConnections) {
    //         setOptions(connections);
    //         return;
    //     }
    //     if (search.length > 1 && searchedData && !showConnections) {
    //         if (pathname.includes('job'))
    //             return setOptions(
    //                 searchedData?.filter(
    //                     (x: IOptionType) => x.entityType === 'JOBPOST'
    //                 )
    //             );
    //         if (pathname.includes('connections'))
    //             return setOptions(
    //                 searchedData?.filter(
    //                     (x: IOptionType) => x.entityType === 'PROFESSIONAL'
    //                 )
    //             );
    //         return setOptions(
    //             JSON.parse(
    //                 JSON.stringify(
    //                     searchedData?.sort(
    //                         (a: IOptionType, b: IOptionType) =>
    //                             -b.entityType.localeCompare(a.entityType)
    //                     )
    //                 )
    //             )
    //         );
    //     }
    // };

    const mockSearchResults = [
        {
            firstName: 'John',
            lastName: 'Doe',
            currentEmployment: {
                companyName: 'Jobfactor',
                employmentLevel: 'Senior'
            },
            score: 40,
            imageUrl: image
        },
        {
            firstName: 'John',
            lastName: 'Doe',
            currentEmployment: {
                companyName: 'Jobfactor',
                employmentLevel: 'Senior'
            },
            score: 40,
            imageUrl: image
        },
        {
            firstName: 'John',
            lastName: 'Doe',
            currentEmployment: {
                companyName: 'Jobfactor',
                employmentLevel: 'Senior'
            },
            score: 40,
            imageUrl: image
        },
        {
            firstName: 'John',
            lastName: 'Doe',
            currentEmployment: {
                companyName: 'Jobfactor',
                employmentLevel: 'Senior'
            },
            score: 40,
            imageUrl: image
        },
        {
            firstName: 'John',
            lastName: 'Doe',
            currentEmployment: {
                companyName: 'Jobfactor',
                employmentLevel: 'Senior'
            },
            score: 40,
            imageUrl: image
        },
        {
            firstName: 'John',
            lastName: 'Doe',
            currentEmployment: {
                companyName: 'Jobfactor',
                employmentLevel: 'Senior'
            },
            score: 40,
            imageUrl: image
        },
        {
            firstName: 'John',
            lastName: 'Doe',
            currentEmployment: {
                companyName: 'Jobfactor',
                employmentLevel: 'Senior'
            },
            score: 40,
            imageUrl: image
        },
        {
            firstName: 'John',
            lastName: 'Doe',
            currentEmployment: {
                companyName: 'Jobfactor',
                employmentLevel: 'Senior'
            },
            score: 40,
            imageUrl: image
        }
    ];

    const handleNavigate = (option: any) => {
        if (!showConnections) {
            if (option.entityType === 'JOBPOST')
                return navigate(
                    user?.primaryProfile === PrimaryProfileType.Professional
                        ? `/my-jobs/${option.id}`
                        : `/job-postdetail/${option.id}`
                );
            if (
                option.entityType === 'PROFESSIONAL' &&
                option.id === user?.professionalProfile?.id
            )
                return navigate(`/users`);
            if (
                option.entityType === 'PROFESSIONAL' &&
                !pathname.includes('connections') &&
                !handleAdd
            )
                return navigate(`/professional-profile/${option.id}`);
            if (
                option.entityType === 'COMPANY' &&
                option.id === user?.primaryCompanyProfile?.companyId
            )
                return navigate(`/about-company`);
            if (option.entityType === 'COMPANY')
                return navigate(`/company-profile/${option.id}`);
        }
    };

    const data = [
        {
            title: 'Switch account',
            disable: true,
            visible: true,
            separatorBottom: false,
            // handleClick: (event: any) => setSubAnchorEl(event.currentTarget),
            submenu: [
                {
                    title: 'ronaldrichie@outlook.com',
                    disable: true,
                    visible: true,
                    separatorBottom: false,
                    submenu: [],
                    handleClick: () => {}
                },
                {
                    title: 'ronaldrichie@outlook.com',
                    disable: true,
                    visible: true,
                    separatorBottom: false,
                    submenu: [],
                    handleClick: () => {}
                },
                {
                    title: 'ronaldrichie@outlook.com',
                    disable: true,
                    visible: true,
                    separatorBottom: false,
                    submenu: [],
                    handleClick: () => {}
                }
            ]
        },
        {
            title: 'Create account',
            disable: true,
            visible: true,
            separatorBottom: true,
            handleClick: (event: any) => setSubAnchorEl(event.currentTarget),
            submenu: [
                {
                    title: 'Professional',
                    disable: true,
                    visible: true,
                    separatorBottom: false,
                    submenu: [],
                    handleClick: () => navigate('/create-account/professional')
                },
                {
                    title: 'Company',
                    disable: true,
                    visible: true,
                    separatorBottom: false,
                    submenu: [],
                    handleClick: () => navigate('/create-account/company')
                }
            ]
        },
        {
            title: 'Invite',
            disable: false,
            visible: true,
            separatorBottom: false,
            submenu: [],
            handleClick: () => (inviteDialogSignal.value = true)
        },
        {
            title: 'Subscription plans',
            disable: true,
            visible: true,
            separatorBottom: false,
            submenu: []
        },
        {
            title: 'Language',
            disable: false,
            visible: false,
            separatorBottom: false,
            submenu: []
        },
        {
            title: 'Theme',
            disable: false,
            visible: false,
            separatorBottom: false,
            handleClick: (event: any) => setSubAnchorEl(event.currentTarget),
            submenu: [
                {
                    title: 'Light',
                    disable: false,
                    visible: true,
                    separatorBottom: false,
                    submenu: [],
                    handleClick: () => {}
                },
                {
                    title: 'Dark',
                    disable: false,
                    visible: true,
                    separatorBottom: false,
                    submenu: [],
                    handleClick: () => {}
                },
                {
                    title: 'System',
                    disable: false,
                    visible: true,
                    separatorBottom: false,
                    submenu: [],
                    handleClick: () => {}
                }
            ]
        },
        {
            title: 'Help and support',
            disable: false,
            visible: true,
            separatorBottom: true,
            submenu: []
        }
    ];
    useEffect(() => {
        getSearch();
        if (search.length > 1) {
            if (showConnections) {
                refetchConnections();
            }
            // else {
            //     refetch();
            // }
        }

        // handleData();
    }, [search]);

    useEffect(() => {
        // handleData();
        if (
            pathname.includes('job') ||
            pathname.includes('connections') ||
            pathname.includes('reviews')
        ) {
            setShowGrouping(false);
        } else {
            setShowGrouping(true);
        }
    }, [/*searchedData,*/ pathname]);

    useEffect(() => {
        if (isMobile) {
            handleClose();
        } else {
            setShowSideBar(null);
        }
    }, [isMobile]);

    return (
        <>
            <AppBar
                position="fixed"
                sx={{
                    zIndex: 1201,
                    backgroundColor: '#f2f2f2',
                    pb: { sm: 1, xs: '0px' },
                    pt: { sm: 0.5, xs: '5px' },
                    pr: { xs: '0 !important' },
                    height: { sm: '82px' }
                }}
                elevation={0}
            >
                <Box
                    // maxWidth="xl"

                    sx={{
                        height: '100%',
                        bgcolor: '#fff',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'relative'
                    }}
                >
                    <Box sx={{ width: '100%', maxWidth: '1542px' }}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                height: '100%',
                                px: { xs: '16px', sm: '40px' },
                                // pb: { sm: 0.5, xs: '5px' },
                                alignItems: 'center',
                                bgcolor: '#fff',
                                gap: '24px'
                            }}
                        >
                            <Box
                                onClick={() => navigate('/')}
                                sx={{
                                    '& svg': {
                                        width: { sm: '175px', xs: '120px' }
                                    }
                                }}
                            >
                                <JobFactorIcon />
                            </Box>
                            <Box
                                width="100%"
                                maxWidth="866px"
                                sx={{
                                    flex: 1,
                                    display: { sm: 'flex', xs: 'none' },
                                    flexDirection: 'column'
                                }}
                            >
                                <Autocomplete
                                    freeSolo={search.length === 0}
                                    disableClearable
                                    filterSelectedOptions={
                                        !pathname.includes('connections')
                                    }
                                    disableCloseOnSelect={pathname.includes(
                                        'connections'
                                    )}
                                    options={allSearch ?? []}
                                    getOptionLabel={(option: any) =>
                                        showConnections
                                            ? `${option?.destinationUser?.firstName} ${option?.destinationUser?.lastName}`
                                            : option?.term ??
                                              option?.primaryCompanyName ??
                                              `${option?.firstName} ${option?.lastName}` ??
                                              ''
                                    }
                                    groupBy={
                                        showGrouping
                                            ? (option) => option.entityType
                                            : undefined
                                    }
                                    onChange={(e, val) => handleNavigate(val)}
                                    sx={{
                                        // ml: 'auto',
                                        width: '100%',
                                        backgroundColor: '#f2f2f2',
                                        border: `1px solid ${
                                            focus ? '#49B6FF' : '#C5C5C5'
                                        }`,
                                        borderRadius: '8px',
                                        maxWidth: { xs: '100%' },
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '8px',
                                            padding: '0px 20px'
                                        },
                                        '& .MuiOutlinedInput-input': {
                                            padding: '0 !important',
                                            color: '#23282B'
                                        },
                                        '& fieldset,.Mui-focused fieldset': {
                                            border: '0 !important'
                                        },
                                        '& .MuiAutocomplete-popupIndicator': {
                                            display: 'none'
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Search by title, skill or company"
                                            onChange={(e) => {
                                                setSearch(e.target.value);
                                            }}
                                            onFocus={() => setFocus(true)}
                                            onBlur={() => setFocus(false)}
                                            sx={{ height: '40px', pt: '8px' }}
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: (
                                                    <>
                                                        <InputAdornment
                                                            sx={{
                                                                display: focus
                                                                    ? 'none'
                                                                    : 'flex'
                                                            }}
                                                            position="start"
                                                        >
                                                            <SearchOutlined />
                                                        </InputAdornment>
                                                        {
                                                            params.InputProps
                                                                .startAdornment
                                                        }
                                                    </>
                                                ),
                                                type: 'search'
                                            }}
                                        />
                                    )}
                                    renderGroup={(params) => (
                                        <li key={params.key}>
                                            <GroupHeader>
                                                {params.group}
                                            </GroupHeader>
                                            <GroupItems>
                                                {params.children}
                                            </GroupItems>
                                        </li>
                                    )}
                                    noOptionsText={
                                        <>
                                            {!loading && search.length > 0 && (
                                                <Box>
                                                    <Typography
                                                        color="#808080"
                                                        fontSize="14px"
                                                        mb="5px"
                                                    >
                                                        No result found for{' '}
                                                        <Typography
                                                            display="inline"
                                                            fontWeight={700}
                                                        >
                                                            {search}{' '}
                                                        </Typography>
                                                    </Typography>
                                                    <Box
                                                        display="flex"
                                                        justifyContent="space-between"
                                                        alignItems="flex-end"
                                                    >
                                                        <Typography
                                                            color="#808080"
                                                            fontSize="14px"
                                                            width="60%"
                                                        >
                                                            Is{' '}
                                                            <Typography
                                                                display="inline"
                                                                fontWeight={700}
                                                            >
                                                                {search}{' '}
                                                            </Typography>
                                                            a professional?
                                                            Invite them to join
                                                            your Jobfactor
                                                            community.
                                                        </Typography>
                                                        <Button
                                                            variant="contained"
                                                            sx={{
                                                                width: '85px',
                                                                height: '40px'
                                                            }}
                                                            onClick={() =>
                                                                (inviteDialogSignal.value =
                                                                    true)
                                                            }
                                                        >
                                                            Invite
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            )}
                                        </>
                                    }
                                    renderOption={(props, option) =>
                                        search.length === 0 ? (
                                            <Typography color="#000">
                                                No Search Input...
                                            </Typography>
                                        ) : loading ? (
                                            <Box
                                                sx={{
                                                    borderBottom:
                                                        '1px solid #D8D8D8',
                                                    padding:
                                                        '12px 48px !important',
                                                    color: '#808080'
                                                }}
                                            >
                                                <Typography color="#000">
                                                    Loading...
                                                </Typography>
                                            </Box>
                                        ) : (
                                            <Box
                                                component="li"
                                                sx={{
                                                    borderBottom:
                                                        '1px solid #D8D8D8',
                                                    padding:
                                                        '12px 48px !important',
                                                    color: '#808080'
                                                }}
                                                key={option.id}
                                                {...props}
                                            >
                                                {user?.professionalProfile
                                                    ?.id !== option.id &&
                                                    (pathname.includes(
                                                        'connections'
                                                    ) || showConnections ? (
                                                        <ConnectionOptions
                                                            option={
                                                                showConnections
                                                                    ? option?.destinationUser
                                                                    : option
                                                            }
                                                            setShowAlert={
                                                                setShowAlert
                                                            }
                                                            setMessage={
                                                                setMessage
                                                            }
                                                            handleAdd={
                                                                handleAdd
                                                            }
                                                            navigate={navigate}
                                                            setType={setType}
                                                            user={user}
                                                        />
                                                    ) : option.entityType ===
                                                      'JOBPOST' ? (
                                                        `${option.term} (${option.companyName})`
                                                    ) : (
                                                        <Box
                                                            key={option.id}
                                                            display="flex"
                                                            alignItems="center"
                                                            onClick={() => {
                                                                if (
                                                                    option?.primaryCompanyName
                                                                )
                                                                    navigate(
                                                                        `/company-profile/${option.id}`
                                                                    );
                                                                else
                                                                    navigate(
                                                                        option.id ===
                                                                            user
                                                                                ?.professionalProfile
                                                                                ?.id
                                                                            ? 'users'
                                                                            : `/professional-profile/${option.id}`
                                                                    );
                                                                setFocus(false);
                                                            }}
                                                        >
                                                            <Avatar
                                                                src={
                                                                    option?.imageUrl ??
                                                                    option?.photoUrl ??
                                                                    option?.logo
                                                                }
                                                            />
                                                            &nbsp;
                                                            {option?.term ??
                                                                option?.primaryCompanyName ??
                                                                `${option?.firstName} ${option?.lastName}`}
                                                        </Box>
                                                    ))}
                                            </Box>
                                        )
                                    }
                                />
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <IconButton
                                    color="inherit"
                                    aria-label="bell"
                                    sx={{
                                        display: { lg: 'block', xs: 'none' }
                                    }}
                                >
                                    {/* <BellIcon /> */}
                                    <NotificationBell notificationCount={5} />
                                </IconButton>
                                <IconButton
                                    sx={{
                                        display: { sm: 'none', xs: 'flex' },
                                        '& svg': {
                                            width: '28px',
                                            height: '28px'
                                        }
                                    }}
                                    color="inherit"
                                    aria-label="arrow down"
                                    onClick={() => {
                                        setShowSearch(true);
                                        setFocus(true);
                                    }}
                                >
                                    <SearchIcon />
                                </IconButton>
                                <IconButton
                                    sx={{ p: 0 }}
                                    onClick={(e) =>
                                        isMobile
                                            ? openSideBar(e)
                                            : isTablet
                                            ? setAnchorEl(e.currentTarget)
                                            : {}
                                    }
                                    color="inherit"
                                    aria-label="profile"
                                >
                                    <Avatar
                                        sx={{
                                            height: { sm: 48, xs: 36 },
                                            width: { sm: 48, xs: 36 }
                                        }}
                                        src={
                                            user?.primaryProfile ===
                                            PrimaryProfileType.Professional
                                                ? user?.professionalProfile
                                                      ?.photoUrl
                                                : user?.primaryCompanyProfile
                                                      ?.photoUrl
                                        }
                                        alt="profile"
                                    />
                                </IconButton>

                                <IconButton
                                    onClick={(e) =>
                                        setAnchorEl(e.currentTarget)
                                    }
                                    sx={{
                                        display: { lg: 'block', xs: 'none' }
                                    }}
                                    color="inherit"
                                    aria-label="arrow down"
                                >
                                    <ArrowDown />
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                {showSearch && isMobile ? (
                    <Box
                        position="absolute"
                        top={'100%'}
                        zIndex={9999}
                        width="100%"
                        height="100vh"
                        bgcolor="#f2f2f2"
                        overflow="auto"
                    >
                        <Box
                            mt="8px"
                            display="flex"
                            alignItems="center"
                            gap="8px"
                            px="16px"
                            py="5px"
                            bgcolor="#fff"
                        >
                            <IconButton onClick={() => setShowSearch(false)}>
                                <ArrowLeftIcon />
                            </IconButton>
                            <Box
                                sx={{
                                    flex: 1
                                }}
                            >
                                <form
                                    action="submit"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        setFocus(false);
                                    }}
                                >
                                    <TextField
                                        // {...params}
                                        placeholder="search"
                                        fullWidth
                                        autoFocus={focus}
                                        onChange={(e) => {
                                            setSearch(e.target.value);
                                        }}
                                        sx={{
                                            ml: 'auto',
                                            backgroundColor: '#f2f2f2',
                                            maxWidth: {
                                                xs: '100%'
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                width: '100%',
                                                borderRadius: '4px',
                                                padding: '8px 8px',
                                                border: '1px solid #49B6FF'
                                            },
                                            '& .MuiOutlinedInput-input': {
                                                padding: '0 !important',
                                                color: '#23282B'
                                            },
                                            '& fieldset,.Mui-focused fieldset':
                                                {
                                                    border: '0 !important'
                                                }
                                        }}
                                        // InputProps={{
                                        //     ...params.InputProps,
                                        //     // startAdornment: (
                                        //     //     <>
                                        //     //         <InputAdornment position="start">
                                        //     //             <SearchIcon />
                                        //     //         </InputAdornment>
                                        //     //         {
                                        //     //             params.InputProps
                                        //     //                 .startAdornment
                                        //     //         }
                                        //     //     </>
                                        //     // ),
                                        //     type: 'search'
                                        // }}
                                    />
                                </form>
                                {/* <Autocomplete
                                    freeSolo
                                    disableClearable
                                    filterSelectedOptions={
                                        !pathname.includes('connections')
                                    }
                                    disableCloseOnSelect={pathname.includes(
                                        'connections'
                                    )}
                                    options={options ?? []}
                                    // getOptionLabel={(option: any) =>
                                    //     showConnections
                                    //         ? `${option?.destinationUser?.firstName} ${option?.destinationUser?.lastName}`
                                    //         : option?.term ?? ''
                                    // }
                                    // groupBy={
                                    //     showGrouping
                                    //         ? (option) => option.entityType
                                    //         : undefined
                                    // }
                                    onChange={(e, val) => handleNavigate(val)}
                                    sx={{
                                        ml: 'auto',
                                        backgroundColor: '#f2f2f2',
                                        maxWidth: { md: '90%', xs: '100%' },
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '4px',
                                            padding: '8px 8px',
                                            border: '1px solid #49B6FF'
                                        },
                                        '& .MuiOutlinedInput-input': {
                                            padding: '0 !important',
                                            color: '#23282B'
                                        },
                                        '& fieldset,.Mui-focused fieldset': {
                                            border: '0 !important'
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="search"
                                            autoFocus={showSearch}
                                            onChange={(e) =>
                                                setSearch(e.target.value)
                                            }
                                            sx={{
                                                ml: 'auto',
                                                backgroundColor: '#f2f2f2',
                                                maxWidth: {
                                                    md: '90%',
                                                    xs: '100%'
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius: '4px',
                                                    padding: '8px 8px',
                                                    border: '1px solid #49B6FF'
                                                },
                                                '& .MuiOutlinedInput-input': {
                                                    padding: '0 !important',
                                                    color: '#23282B'
                                                },
                                                '& fieldset,.Mui-focused fieldset':
                                                    {
                                                        border: '0 !important'
                                                    }
                                            }}
                                            InputProps={{
                                                ...params.InputProps,
                                                // startAdornment: (
                                                //     <>
                                                //         <InputAdornment position="start">
                                                //             <SearchIcon />
                                                //         </InputAdornment>
                                                //         {
                                                //             params.InputProps
                                                //                 .startAdornment
                                                //         }
                                                //     </>
                                                // ),
                                                type: 'search'
                                            }}
                                        />
                                    )}
                                    renderGroup={(params) => (
                                        <li key={params.key}>
                                            <GroupHeader>
                                                {params.group}
                                            </GroupHeader>
                                            <GroupItems>
                                                {params.children}
                                            </GroupItems>
                                        </li>
                                    )}
                                    renderOption={(props, option) => (
                                        <Box
                                            component="li"
                                            sx={{
                                                borderBottom:
                                                    '1px solid #D8D8D8',
                                                padding: '12px 48px !important',
                                                color: '#808080'
                                            }}
                                            key={option.id}
                                            {...props}
                                        >
                                            {user?.professionalProfile?.id !==
                                                option.id &&
                                                (pathname.includes(
                                                    'connections'
                                                ) || showConnections ? (
                                                    <ConnectionOptions
                                                        option={
                                                            showConnections
                                                                ? option?.destinationUser
                                                                : option
                                                        }
                                                        setShowAlert={
                                                            setShowAlert
                                                        }
                                                        setShowSearch={
                                                            setShowSearch
                                                        }
                                                        setMessage={setMessage}
                                                        handleAdd={handleAdd}
                                                        navigate={navigate}
                                                        setType={setType}
                                                        user={user}
                                                    />
                                                ) : option.entityType ===
                                                  'JOBPOST' ? (
                                                    `${option.term} (${option.companyName})`
                                                ) : (
                                                    <>
                                                        <Avatar
                                                            src={
                                                                option.imageUrl
                                                            }
                                                        />
                                                        &nbsp;
                                                        {option.term}
                                                    </>
                                                ))}
                                        </Box>
                                    )}
                                /> */}
                            </Box>
                        </Box>
                        {search?.length > 0 && (
                            <Box p="8px 16px" bgcolor="#fff" mt="8px">
                                <Typography
                                    color="#000"
                                    fontSize={16}
                                    fontWeight={600}
                                >
                                    Search results
                                </Typography>
                            </Box>
                        )}
                        {search?.length > 0 ? (
                            <Box
                                p="8px 12px"
                                display="flex"
                                flexDirection="column"
                                gap="12px"
                                mt="8px"
                                bgcolor="#fff"
                            >
                                <>
                                    {loading ? (
                                        <Typography color="#000">
                                            Loading...
                                        </Typography>
                                    ) : allSearch?.length === 0 ? (
                                        search.length > 0 && (
                                            <Box>
                                                <Typography
                                                    color="#808080"
                                                    fontSize="14px"
                                                    mb="5px"
                                                >
                                                    No result found for{' '}
                                                    <Typography
                                                        display="inline"
                                                        fontWeight={700}
                                                    >
                                                        {search}{' '}
                                                    </Typography>
                                                </Typography>
                                                <Box
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    alignItems="flex-end"
                                                >
                                                    <Typography
                                                        color="#808080"
                                                        fontSize="14px"
                                                        width="60%"
                                                    >
                                                        Is{' '}
                                                        <Typography
                                                            display="inline"
                                                            fontWeight={700}
                                                        >
                                                            {search}{' '}
                                                        </Typography>
                                                        a professional? Invite
                                                        them to join your
                                                        Jobfactor community.
                                                    </Typography>
                                                    <Button
                                                        variant="contained"
                                                        sx={{
                                                            width: '85px',
                                                            height: '40px'
                                                        }}
                                                        onClick={() =>
                                                            (inviteDialogSignal.value =
                                                                true)
                                                        }
                                                    >
                                                        Invite
                                                    </Button>
                                                </Box>
                                            </Box>
                                        )
                                    ) : (
                                        allSearch?.map(
                                            (item: any, i: number) => (
                                                <Box
                                                    key={i}
                                                    onClick={() => {
                                                        handleNavigate(item);
                                                        setShowSearch(false);
                                                    }}
                                                >
                                                    {/* <Typography color='#232b2b'>
                                                {item.entityType}
                                            </Typography> */}
                                                    {user?.professionalProfile
                                                        ?.id !== item.id &&
                                                        (item.entityType !==
                                                        'JOBPOST' ? (
                                                            <ConnectionOptions
                                                                option={
                                                                    showConnections
                                                                        ? item?.destinationUser
                                                                        : item
                                                                }
                                                                setShowAlert={
                                                                    setShowAlert
                                                                }
                                                                setMessage={
                                                                    setMessage
                                                                }
                                                                handleAdd={
                                                                    handleAdd
                                                                }
                                                                navigate={
                                                                    navigate
                                                                }
                                                                setType={
                                                                    setType
                                                                }
                                                                user={user}
                                                            />
                                                        ) : item.entityType ===
                                                          'JOBPOST' ? (
                                                            <Typography color="#000">
                                                                {`${item?.term} (${item?.companyName})`}
                                                            </Typography>
                                                        ) : (
                                                            <Box
                                                                display="flex"
                                                                gap="8px"
                                                                alignItems="center"
                                                            >
                                                                <Avatar
                                                                    src={
                                                                        item?.imageUrl
                                                                    }
                                                                />
                                                                &nbsp;
                                                                <Typography color="#000">
                                                                    {item?.term}
                                                                </Typography>
                                                            </Box>
                                                        ))}
                                                </Box>
                                            )
                                        )
                                    )}
                                </>
                            </Box>
                        ) : null}
                    </Box>
                ) : null}
            </AppBar>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                disableScrollLock
                sx={{
                    '& .MuiMenu-paper': {
                        p: 0,
                        width: '300px',
                        top: '50px !important'
                    }
                }}
            >
                <Box
                    sx={{
                        width: '100%'
                    }}
                >
                    <MenuItem
                        sx={{
                            display: 'flex',
                            gap: '16px'
                        }}
                    >
                        <Avatar
                            sx={{
                                height: 24,
                                width: 24
                            }}
                            src={
                                user?.primaryProfile ===
                                PrimaryProfileType.Professional
                                    ? user?.professionalProfile?.photoUrl
                                    : user?.primaryCompanyProfile?.photoUrl
                            }
                            alt="profile"
                        />
                        <Box
                            sx={{
                                width: '100%'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    fontWeight: '700',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                    letterSpacing: '0.001em',
                                    color: '#23282B'
                                }}
                            >
                                {user?.primaryProfile ===
                                PrimaryProfileType.Professional
                                    ? `${user?.professionalProfile?.firstName} ${user?.professionalProfile?.lastName}`
                                    : user?.primaryCompanyProfile?.companyName}
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                    letterSpacing: '0.001em',
                                    color: '#808080'
                                }}
                            >
                                {user?.primaryProfile ===
                                PrimaryProfileType.Professional
                                    ? user?.professionalProfile?.emailAddress
                                    : user?.primaryCompanyProfile?.emailAddress}
                            </Typography>
                        </Box>
                    </MenuItem>
                    {data.map((item) =>
                        item.visible ? (
                            <Fragment key={item.title}>
                                <MenuItem
                                    onClick={item?.handleClick ?? handleClose}
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                        letterSpacing: '0.001em',
                                        color: '#23282B',
                                        pb: '12px',
                                        pt: '12px',
                                        pl: '57px',
                                        borderBottom: item.separatorBottom
                                            ? '1px solid #EDEDED'
                                            : 0
                                    }}
                                    aria-label={item.title}
                                    // disabled={item.disable}
                                >
                                    {item.title}
                                </MenuItem>
                                {item?.submenu &&
                                item?.submenu?.length > 0 &&
                                subAnchorEl?.ariaLabel === item?.title ? (
                                    <StyledMenu
                                        anchorEl={subAnchorEl}
                                        open={Boolean(subAnchorEl)}
                                        onClose={handleClose}
                                    >
                                        {item?.submenu?.map((x) => (
                                            <MenuItem
                                                onClick={() => {
                                                    x?.handleClick();
                                                    handleClose();
                                                }}
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    fontSize: '14px',
                                                    zIndex: 99
                                                }}
                                            >
                                                {x.title}
                                            </MenuItem>
                                        ))}
                                    </StyledMenu>
                                ) : null}
                            </Fragment>
                        ) : null
                    )}
                </Box>
                <MenuItem
                    sx={{
                        fontFamily: 'Open Sans',
                        letterSpacing: '0.0015em',
                        color: '#E75541',
                        m: 0,
                        mt: '4px'
                    }}
                    onClick={() => {
                        signOut();
                        navigate('/login');
                    }}
                >
                    Sign out
                </MenuItem>
            </Menu>

            <MobileDrawer
                key={Math.random()}
                open={showSideBar}
                showAccountTypes={showAccountTypes}
                setShowAccountTypes={setShowAccountTypes}
                handleClose={() => setShowSideBar(null)}
            />

            <SnackAlert
                open={showAlert}
                handleClose={() => setShowAlert(false)}
                message={message}
                type={type}
            />
            <InviteDialog />
        </>
    );
}

export default JobfactorAppBar;
interface IConnectionType {
    navigate: NavigateFunction;
    setType: React.Dispatch<
        React.SetStateAction<'success' | 'info' | 'warning' | 'error'>
    >;
    setMessage: React.Dispatch<React.SetStateAction<string>>;
    setShowAlert: React.Dispatch<React.SetStateAction<boolean>>;
    user: any;
    handleAdd?: (user: IUserType) => void;
    option: {
        id: string;
        term: string;
        tagline?: string;
        primaryCompanyName?: string;
        logo?: string;
        score?: number;
        userId: string;
        isAdded: boolean;
        imageUrl: string;
        lastName?: string;
        firstName?: string;
        photoUrl?: string;
        currentEmployment: {
            employmentLevel: string;
            companyName: string;
        };
    };
}

interface IUserInfo {
    id: string;
    isEnabled: boolean;
    createdAt: number;
    lastUpdatedAt: number;
    tagline?: string;
    primaryProfile: 'PROFESSIONAL'; // Assuming primaryProfile can only be "PROFESSIONAL"
    accountId: string;
    primaryCompanyProfile: null; // Assuming primaryCompanyProfile can be null
    companyProfiles: null; // Assuming companyProfiles can be null
    professionalProfile: {
        id: string;
        emailAddress: string;
        firstName: string;
        lastName: string;
        tagline: string;
        currentEmployment: null; // Assuming currentEmployment can be null
        employments: []; // Assuming employments is an empty array
        qualifications: []; // Assuming qualifications is an empty array
        dateOfBirth: string;
        gender: 'Male' | 'Female'; // Assuming gender can only be "Male" or "Female"
        bio: string;
        phoneNumber: string;
        score: number;
    };
}

const ConnectionOptions = ({
    option,
    user,
    navigate,
    setType,
    setMessage,
    setShowAlert,
    handleAdd
}: IConnectionType) => {
    const sendConnectRequest = useSendConnectionRequest();
    const userInfoQuery = useGetUser(option?.userId);
    const sendEmailNotificationMutation = useSendEmailNotification();
    const [isAdded, setIsAdded] = useState(option.isAdded ?? false);
    const queryClient = useQueryClient();
    const { data: score } = useGetProfessionalProfileScore(option?.id);
    const { data: profile } = useGetProfessionalProfileById(option?.id ?? '');

    const handleClick = () => {
        navigate(
            user?.primaryProfile === PrimaryProfileType.Professional
                ? option.id === user?.professionalProfile?.id
                    ? 'users'
                    : `/professional-profile/${option.id}`
                : `/job-postdetail/${option.id}`
        );
    };

    const handleConnectionEmail = async (userInfo: any) => {
        try {
            logger.log(
                `send connection email to ${userInfo.professionalProfile.emailAddress} names ${userInfo.professionalProfile.firstName} ${userInfo.professionalProfile.lastName}`
            );
            // await axiosInstance.post('/api/email/send-notification', {
            //     email: userInfo.professionalProfile.emailAddress,
            //     subject: generateSubject(connectionRequest.subject),
            //     firstName: userInfo.professionalProfile.firstName,
            //     lastName: userInfo.professionalProfile.lastName,
            //     message: generateHtml(connectionRequest.html),
            //     link: 'connections'
            // });
        } catch (error) {
            console.error('Error sending connection email:', error);
        }
    };

    // const sendConnectionRequest = () => {
    //     const data = {
    //         sourceUserId:
    //             user?.primaryProfile === PrimaryProfileType.Professional
    //                 ? user?.professionalProfile?.userId
    //                 : user?.id,
    //         destinationUserId: option.userId
    //     };

    //     logger.log('data', data)
    //     sendConnectRequest.mutate(data, {
    //         onSuccess: (res) => {
    //             setIsAdded(true);
    //             queryClient.invalidateQueries(QueryKeys.RetrieveConnections);
    //             queryClient.invalidateQueries(
    //                 QueryKeys.RetrieveConnectionRequestSent
    //             );
    //             queryClient.invalidateQueries(
    //                 QueryKeys.RetrieveConnectionRequestReceived
    //             );
    //             setType('success');
    //             setMessage('Request sent successfully.');
    //             setShowAlert(true);
    //         },
    //         onError: (err: AxiosError) => {
    //             if (axios.isAxiosError(err)) {
    //                 setType('error');
    //                 setMessage(
    //                     err.message ?? 'Error occured please try again!'
    //                 );
    //                 setShowAlert(true);
    //             }
    //         }
    //     });
    // };

    const sendConnectionRequest = async () => {
        try {
            const userInfoMutation = await userInfoQuery.refetch();
            if (userInfoMutation.data) {
                const userInfo = userInfoMutation.data;

                const data = {
                    sourceUserId:
                        user?.primaryProfile === PrimaryProfileType.Professional
                            ? user?.professionalProfile?.userId
                            : user?.id,
                    destinationUserId: option?.userId
                };

                logger.log('data', data);

                sendConnectRequest.mutate(data, {
                    onSuccess: () => {
                        setIsAdded(true);
                        queryClient.invalidateQueries(
                            QueryKeys.RetrieveConnections
                        );
                        queryClient.invalidateQueries(
                            QueryKeys.RetrieveConnectionRequestSent
                        );
                        queryClient.invalidateQueries(
                            QueryKeys.RetrieveConnectionRequestReceived
                        );
                        setType('success');
                        setMessage('Request and Email sent successfully.');
                        setShowAlert(true);
                        handleConnectionEmail(userInfo);
                        // const sendmaildata = {
                        //     email: userInfo.professionalProfile.emailAddress,
                        //     subject: generateSubject(connectionRequest.subject),
                        //     html: generateHtml(connectionRequest.html, userInfo.professionalProfile.firstName),
                        // }
                        // logger.log("sendmailData=>", sendmaildata)
                        //  sendEmailNotificationMutation.mutate(sendmaildata,{
                        //     onSuccess:(res) =>{
                        //         console.log('sendmail', res)
                        //         setType('success');
                        //         setMessage('email notification sent successfully.');
                        //         setShowAlert(true);
                        //     },
                        //     onError: (err)=>{
                        //         console.log('err', err)
                        //     }
                        // });
                    },
                    onError: (err: AxiosError) => {
                        if (axios.isAxiosError(err)) {
                            setType('error');
                            setMessage(
                                err.message ??
                                    'Error occurred, please try again!'
                            );
                            setShowAlert(true);
                        }
                    }
                });
            } else {
                console.error(
                    'Error fetching user info:',
                    userInfoMutation.error
                );
            }
        } catch (error) {
            console.error('Unexpected error:', error);
        }
    };

    return (
        <Box
            py={{ xs: '8px', sm: 'undefined' }}
            sx={{
                width: '100%'
            }}
            // borderBottom={'1px solid #D8D8D8'}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'inset',
                    gap: { xs: '8px', sm: '20px' }
                }}
            >
                <Avatar
                    alt=""
                    src={option?.imageUrl ?? option?.photoUrl ?? option?.logo}
                    sx={{
                        width: { xs: 48, sm: 56 },
                        height: { xs: 48, sm: 56 }
                    }}
                    onClick={handleClick}
                />
                <Box sx={{ width: '100%' }}>
                    <Box display="flex" alignItems="center" gap="12px">
                        <Typography
                            component={'h1'}
                            sx={{
                                fontSize: '16px',
                                fontWeight: '600',
                                fontFamily: 'Open Sans',
                                color: '#494949',
                                '&:hover': {
                                    textDecoration: 'underline'
                                }
                            }}
                            onClick={handleClick}
                        >
                            {option?.term ??
                                option?.primaryCompanyName ??
                                `${option?.firstName} ${option?.lastName}`}
                        </Typography>
                        <Typography
                            display={{ xs: 'block', sm: 'none' }}
                            sx={{
                                fontSize: '14px',
                                fontWeight: '600',
                                fontFamily: 'Open Sans',
                                color: '#FFFFFF',
                                background: '#49B6FF',
                                borderRadius: '6px',
                                padding: '0px 8px'
                            }}
                        >
                            {option?.score ?? 0}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '20px',
                            mt: '4px'
                            // width: '400px',
                        }}
                    >
                        {option?.currentEmployment ||
                        profile?.currentEmployment ? (
                            <>
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        fontFamily: 'Open Sans',
                                        color: '#808080',
                                        letterSpacing: '0.0015em',
                                        textTransform: 'capitalize'
                                    }}
                                >
                                    {option?.currentEmployment?.employmentLevel?.toLowerCase() ??
                                        profile?.currentEmployment?.employmentLevel?.toLowerCase()}
                                </Typography>
                                <Typography
                                    display={{ xs: 'none', sm: 'block' }}
                                    component={'div'}
                                    sx={{
                                        width: '7px',
                                        height: '7px',
                                        borderRadius: '100px',
                                        background: '#494949',
                                        border: '2px solid #494949'
                                    }}
                                />
                                <Typography
                                    display={{ xs: 'none', sm: 'block' }}
                                    sx={{
                                        fontSize: '14px',
                                        fontFamily: 'Open Sans',
                                        color: '#808080',
                                        textTransform: 'capitalize'
                                    }}
                                >
                                    {option?.currentEmployment?.companyName &&
                                    option?.currentEmployment?.companyName
                                        .length > 25
                                        ? option?.currentEmployment?.companyName
                                              ?.toLowerCase()
                                              ?.slice(0, 25) + '...'
                                        : option?.currentEmployment
                                              ?.companyName}
                                </Typography>
                                <Typography
                                    display={{ xs: 'none', sm: 'block' }}
                                    component={'div'}
                                    sx={{
                                        width: '7px',
                                        height: '7px',
                                        borderRadius: '100px',
                                        background: '#494949',
                                        border: '2px solid #494949'
                                    }}
                                />
                            </>
                        ) : null}
                        <Typography
                            display={{ xs: 'none', sm: 'block' }}
                            sx={{
                                fontSize: '14px',
                                fontWeight: '600',
                                fontFamily: 'Open Sans',
                                color: '#FFFFFF',
                                background: '#49B6FF',
                                borderRadius: '6px',
                                padding: '0px 12px'
                            }}
                        >
                            {option?.score ?? 0}
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: { xs: 'none', sm: 'flex' },
                        alignItems: 'flex-end',
                        gap: '20px',
                        width: '100%',
                        justifyContent: 'flex-end'
                    }}
                >
                    <Button
                        sx={{
                            borderRadius: '8px',
                            padding: '12px 16px',
                            border: '1px solid #05668D',
                            background: '#05668D',
                            fontSize: '14px',
                            fontWeight: '600',
                            textTransform: 'capitalize',
                            boxShadow: 'none',
                            width: 'auto',
                            whiteSpace: 'nowrap',
                            color: '#FFFFFF',
                            textDecoration: 'none',
                            ':hover': {
                                color: '#FFFFFF',
                                textDecoration: 'none',
                                background: '#05668D'
                            },
                            ':disabled': {
                                color: 'grey',
                                borderColor: '#ccc',
                                background: '#ccc'
                            }
                        }}
                        onClick={() =>
                            handleAdd
                                ? handleAdd(option)
                                : sendConnectionRequest()
                        }
                        disabled={isAdded}
                    >
                        {isAdded ? 'Added' : 'Add'}
                    </Button>
                </Box>
            </Box>
            <Box display={{ xs: 'none', sm: 'block' }} mt="5px">
                <ConnectionSkills count={3} id={option?.id} />
            </Box>
        </Box>
    );
};

const StyledMenu = styled((props: MenuProps) => <Menu {...props} />)(() => ({
    '& .MuiPaper-root': {
        marginTop: 0,
        width: 'auto',
        minWidth: '100px',
        marginLeft: '60px',
        zIndex: 5
    }
}));
