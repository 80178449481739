import React from 'react';

function ArrowUpWhite() {
    return (
        <svg
            width="60"
            height="60"
            viewBox="0 0 60 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="30" cy="30" r="30" fill="#EDEDED" />
            <g clipPath="url(#clip0_1699_325437)">
                <path
                    d="M31.0007 25.828V38H29.0007V25.828L23.6367 31.192L22.2227 29.778L30.0007 22L37.7787 29.778L36.3647 31.192L31.0007 25.828Z"
                    fill="#05668D"
                />
            </g>
            <defs>
                <clipPath id="clip0_1699_325437">
                    <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(18 18)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}

export default ArrowUpWhite;
