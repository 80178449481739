import { Box } from '@mui/material';
import React from 'react';

function CalendarFormIcon() {
    return (
        <>
            <Box display={{ xs: 'block', sm: 'none' }}>
                <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M6.66797 4.78906C6.3263 4.78906 6.04297 4.50573 6.04297 4.16406V1.66406C6.04297 1.3224 6.3263 1.03906 6.66797 1.03906C7.00964 1.03906 7.29297 1.3224 7.29297 1.66406V4.16406C7.29297 4.50573 7.00964 4.78906 6.66797 4.78906Z"
                        fill="#494949"
                    />
                    <path
                        d="M13.332 4.78906C12.9904 4.78906 12.707 4.50573 12.707 4.16406V1.66406C12.707 1.3224 12.9904 1.03906 13.332 1.03906C13.6737 1.03906 13.957 1.3224 13.957 1.66406V4.16406C13.957 4.50573 13.6737 4.78906 13.332 4.78906Z"
                        fill="#494949"
                    />
                    <path
                        d="M12.5 18.9557H7.5C2.81667 18.9557 1.875 16.7474 1.875 13.1807V8.03906C1.875 4.08906 3.20833 2.48073 6.63333 2.28906H13.3333C13.3417 2.28906 13.3583 2.28906 13.3667 2.28906C16.7917 2.48073 18.125 4.08906 18.125 8.03906V13.1807C18.125 16.7474 17.1833 18.9557 12.5 18.9557ZM6.66667 3.53906C4.33333 3.6724 3.125 4.40573 3.125 8.03906V13.1807C3.125 16.3724 3.73333 17.7057 7.5 17.7057H12.5C16.2667 17.7057 16.875 16.3724 16.875 13.1807V8.03906C16.875 4.41406 15.675 3.6724 13.3167 3.53906H6.66667Z"
                        fill="#494949"
                    />
                    <path
                        d="M17.2904 15.2891H2.70703C2.36536 15.2891 2.08203 15.0057 2.08203 14.6641C2.08203 14.3224 2.36536 14.0391 2.70703 14.0391H17.2904C17.632 14.0391 17.9154 14.3224 17.9154 14.6641C17.9154 15.0057 17.632 15.2891 17.2904 15.2891Z"
                        fill="#494949"
                    />
                    <path
                        d="M10.0013 6.875C8.9763 6.875 8.10964 7.43333 8.10964 8.51667C8.10964 9.03333 8.3513 9.425 8.71797 9.675C8.20964 9.975 7.91797 10.4583 7.91797 11.025C7.91797 12.0583 8.70964 12.7 10.0013 12.7C11.2846 12.7 12.0846 12.0583 12.0846 11.025C12.0846 10.4583 11.793 9.96667 11.2763 9.675C11.6513 9.41667 11.8846 9.03333 11.8846 8.51667C11.8846 7.43333 11.0263 6.875 10.0013 6.875ZM10.0013 9.24167C9.56797 9.24167 9.2513 8.98333 9.2513 8.575C9.2513 8.15833 9.56797 7.91667 10.0013 7.91667C10.4346 7.91667 10.7513 8.15833 10.7513 8.575C10.7513 8.98333 10.4346 9.24167 10.0013 9.24167ZM10.0013 11.6667C9.4513 11.6667 9.0513 11.3917 9.0513 10.8917C9.0513 10.3917 9.4513 10.125 10.0013 10.125C10.5513 10.125 10.9513 10.4 10.9513 10.8917C10.9513 11.3917 10.5513 11.6667 10.0013 11.6667Z"
                        fill="#494949"
                    />
                    {/* <line
                        x1="40.5"
                        y1="2.5"
                        x2="40.5"
                        y2="17.5"
                        stroke="#D9D9D9"
                    /> */}
                </svg>
            </Box>
            <Box display={{ xs: 'none', sm: 'block' }}>
                <svg
                    width="41"
                    height="20"
                    viewBox="0 0 41 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M6.66797 4.78906C6.3263 4.78906 6.04297 4.50573 6.04297 4.16406V1.66406C6.04297 1.3224 6.3263 1.03906 6.66797 1.03906C7.00964 1.03906 7.29297 1.3224 7.29297 1.66406V4.16406C7.29297 4.50573 7.00964 4.78906 6.66797 4.78906Z"
                        fill="#494949"
                    />
                    <path
                        d="M13.332 4.78906C12.9904 4.78906 12.707 4.50573 12.707 4.16406V1.66406C12.707 1.3224 12.9904 1.03906 13.332 1.03906C13.6737 1.03906 13.957 1.3224 13.957 1.66406V4.16406C13.957 4.50573 13.6737 4.78906 13.332 4.78906Z"
                        fill="#494949"
                    />
                    <path
                        d="M12.5 18.9557H7.5C2.81667 18.9557 1.875 16.7474 1.875 13.1807V8.03906C1.875 4.08906 3.20833 2.48073 6.63333 2.28906H13.3333C13.3417 2.28906 13.3583 2.28906 13.3667 2.28906C16.7917 2.48073 18.125 4.08906 18.125 8.03906V13.1807C18.125 16.7474 17.1833 18.9557 12.5 18.9557ZM6.66667 3.53906C4.33333 3.6724 3.125 4.40573 3.125 8.03906V13.1807C3.125 16.3724 3.73333 17.7057 7.5 17.7057H12.5C16.2667 17.7057 16.875 16.3724 16.875 13.1807V8.03906C16.875 4.41406 15.675 3.6724 13.3167 3.53906H6.66667Z"
                        fill="#494949"
                    />
                    <path
                        d="M17.2904 15.2891H2.70703C2.36536 15.2891 2.08203 15.0057 2.08203 14.6641C2.08203 14.3224 2.36536 14.0391 2.70703 14.0391H17.2904C17.632 14.0391 17.9154 14.3224 17.9154 14.6641C17.9154 15.0057 17.632 15.2891 17.2904 15.2891Z"
                        fill="#494949"
                    />
                    <path
                        d="M10.0013 6.875C8.9763 6.875 8.10964 7.43333 8.10964 8.51667C8.10964 9.03333 8.3513 9.425 8.71797 9.675C8.20964 9.975 7.91797 10.4583 7.91797 11.025C7.91797 12.0583 8.70964 12.7 10.0013 12.7C11.2846 12.7 12.0846 12.0583 12.0846 11.025C12.0846 10.4583 11.793 9.96667 11.2763 9.675C11.6513 9.41667 11.8846 9.03333 11.8846 8.51667C11.8846 7.43333 11.0263 6.875 10.0013 6.875ZM10.0013 9.24167C9.56797 9.24167 9.2513 8.98333 9.2513 8.575C9.2513 8.15833 9.56797 7.91667 10.0013 7.91667C10.4346 7.91667 10.7513 8.15833 10.7513 8.575C10.7513 8.98333 10.4346 9.24167 10.0013 9.24167ZM10.0013 11.6667C9.4513 11.6667 9.0513 11.3917 9.0513 10.8917C9.0513 10.3917 9.4513 10.125 10.0013 10.125C10.5513 10.125 10.9513 10.4 10.9513 10.8917C10.9513 11.3917 10.5513 11.6667 10.0013 11.6667Z"
                        fill="#494949"
                    />
                    <line
                        x1="32.5"
                        y1="2.5"
                        x2="32.5"
                        y2="17.5"
                        stroke="#D9D9D9"
                    />
                </svg>
            </Box>
        </>
    );
}

export default CalendarFormIcon;
