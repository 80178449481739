import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import BriefcaseIcon from '../../../assets/icons/BriefcaseIconMui';
import AddQualificationDialog from '../components/Modals/AddQualificationDialog';
import EducationSummary from '../components/EducationSummary';
import cambridge from '../../../assets/images/cambridge.jpg';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { Box } from '@mui/system';
import WorkSummary from '../components/WorkSummary';
import Grid from '@mui/material/Grid';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import {
    Employment,
    Qualification,
    useAuth
} from '../../../utils/context/AuthContext';
import { Qualification as getQualificationType } from '../types/Qualification';
import Trash from '../../../assets/icons/TrashIconMui';
import { IconButton } from '@mui/material';
import { useRemoveEducation } from '../../../utils/hooks/api/education/useRemoveEducation';
import SnackAlert, { SnackAlertType } from '../../../components/Snackbar';
import { useRemoveEmployment } from '../../../utils/hooks/api/employments/useRemoveEmployment';
import DeletePrompt from '../components/Modals/DeletePrompt';
import { useGetProfessionalProfileById } from '../../../utils/hooks/api/professional-profile/useGetProfessionalProfile';
import { useParams } from 'react-router';
import { useNavigate, useLocation } from 'react-router-dom';
import { QueryKeys } from '../../../utils/hooks/api/QueryKey';
import { useQueryClient } from 'react-query';
import { Responsiveness } from '../../../theme/overrides';
import { ArrowLeftIcon } from '../../../assets/icons/ArrowLeftIcon';
import MobileNav from '../../../components/MobileNav/MobileNav';
// import EditBtn from '../../../assets/icons/EditIcon';
import EditBtn from '../../../assets/icons/EditBtn';
import { logger } from '../../../utils/Helper/logger';

function UserQualifications() {
    const { user, setUser } = useAuth();
    const [id, setId] = useState('');
    const [message, setMessage] = useState('');
    const [deleteKey, setDeleteKey] = useState('');
    const [open, setOpen] = useState<boolean>(false);
    const [showAlert, setShowAlert] = useState(false);
    const [warningMessage, setWarningMessage] = useState('');
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [qualificationType, setQualificationType] = useState('');
    const [type, setType] = useState<SnackAlertType>('info');
    const removeEducation = useRemoveEducation();
    const removeEmployment = useRemoveEmployment();
    const { profileId } = useParams();
    const { data: profile } = useGetProfessionalProfileById(profileId!);
    const queryClient = useQueryClient();
    const { isMobile, isTablet } = Responsiveness()
    const navigate = useNavigate()
    const location = useLocation()
    const otherUsers = location?.state?.others ?? false

    const handleAddQualification = (key: string) => {
        setQualificationType(key);
        setOpen(true);
    };


    const handleRemove = () => {
        if (deleteKey === getQualificationType.Education) {
            removeEducation.mutate(id, {
                onSuccess: () => {
                    queryClient.invalidateQueries(
                        QueryKeys.RetrieveProfessionalProfileById
                    );
                    let tempData = {
                        ...user,
                        professionalProfile: {
                            ...user?.professionalProfile,
                            qualifications:
                                user?.professionalProfile?.qualifications?.filter(
                                    (x) => x.id !== id
                                )
                        }
                    };
                    setId('');
                    setUser(tempData);
                    setMessage('Education removed successfully');
                    setType('success');
                    setShowAlert(true);
                    setShowWarning(false);
                },
                onError: (err) => {
                    setMessage('Error occured please try again!');
                    setType('error');
                    setShowAlert(true);
                }
            });
            return;
        }
        if (deleteKey === getQualificationType.WorkExperience) {
            removeEmployment.mutate(id, {
                onSuccess: () => {
                    queryClient.invalidateQueries(
                        QueryKeys.RetrieveProfessionalProfileById
                    );
                    let tempData = {
                        ...user,
                        professionalProfile: {
                            ...user?.professionalProfile,
                            employments:
                                user?.professionalProfile?.employments?.filter(
                                    (x) => x.id !== id
                                ),
                            currentEmployment:
                                user?.professionalProfile?.currentEmployment
                                    ?.id === id
                                    ? null
                                    : user?.professionalProfile
                                          ?.currentEmployment
                        }
                    };
                    setId('');
                    setUser(tempData);
                    setMessage('Employment removed successfully');
                    setType('success');
                    setShowAlert(true);
                    setShowWarning(false);
                },
                onError: (err) => {
                    setMessage('Error occured please try again!');
                    setType('error');
                    setShowAlert(true);
                    setShowWarning(false);
                }
            });
            return;
        }
    };

    const handleDelete = (id: string, type: string) => {
        setId(id);
        setWarningMessage(
            `Are you sure you want to delete this ${
                type === getQualificationType.Education
                    ? 'education'
                    : 'work experience'
            }?`
        );
        setDeleteKey(type);
        setShowWarning(true);
    };

    return (
        <Box pr={{ xs: '0px', sm: '20px', lg: '40px' }}>
            <Box
                mb="8px"
                sx={{
                    display: 'flex',
                    gap: {xs: '12px', sm: '20px', lg: '24px'},
                    // mt: '16px',
                    bgcolor: '#fff',
                    width: '100%',
                    alignItems: 'center',
                    borderBottom: '1px solid #D9D9D9',
                    py: { xs: '16px', sm: '24px', lg: '30px' },
                    px: { xs: '16px', sm: '20px', lg: '40px' }
                }}
            >
                <IconButton onClick={() => navigate(-1)}>
                    <ArrowLeftIcon />
                </IconButton>
                <Typography
                    component={'h2'}
                    sx={{
                        fontSize: { xs: '16px', sm: '24px' },
                        fontWeight: '600',
                        fontFamily: 'Open Sans',
                        color: '#23282B',
                        flex: 1
                    }}
                >
                    Qualifications
                </Typography>
                <Button
                    onClick={() => handleAddQualification('')}
                    variant="contained"
                    sx={{
                        display: {
                            xs: 'none',
                            sm:
                                user?.professionalProfile?.id !== profileId
                                    ? 'none'
                                    : 'block'
                        },
                        width: '197px',
                        height: '52px',
                        borderRadius: '8px',
                        bgcolor: '#05668D',
                        fontSize: '16px',
                        fontWeight: 700,
                        color: '#fff'
                    }}
                >
                    Add Qualifications
                </Button>
            </Box>
            <Box>
                {user?.professionalProfile?.id === profileId ? (
                    <Box
                        p={{ xs: '16px 20px', lg: '16px 32px' }}
                        bgcolor="#fff"
                        mb="8px"
                        style={{
                            borderEndStartRadius: 0,
                            borderEndEndRadius: 0
                        }}
                    >
                        <Typography
                            fontSize={isMobile ? '14px' : '16px'}
                            component="p"
                            color="#808080"
                        >
                            We use these details to improve your jobfactor score
                            and also show you jobs that match your unique skills
                            and experience
                        </Typography>
                    </Box>
                ) : null}
                {profile?.qualifications?.length > 0 ||
                profile?.employments?.length > 0 ? (
                    <>
                        <Box>
                            {profile?.qualifications?.length > 0 ? (
                                <Box
                                    style={{
                                        borderEndStartRadius: 0,
                                        borderEndEndRadius: 0
                                    }}
                                >
                                    <Box
                                        bgcolor="#fff"
                                        mb='4px'
                                        p={{
                                            xs: '12px 16px',
                                            sm: '12px 20px',
                                            lg: '12px 40px'
                                        }}
                                    >
                                        <Typography
                                            component="h3"
                                            fontSize={{
                                                xs: '16px',
                                                sm: '20px'
                                            }}
                                            fontWeight={600}
                                        >
                                            Education
                                        </Typography>
                                    </Box>
                                    <Box>
                                        {profile?.qualifications
                                            ?.sort(
                                                (
                                                    a: Qualification,
                                                    b: Qualification
                                                ) =>
                                                    a.endDate < b.endDate
                                                        ? 1
                                                        : -1
                                            )
                                            ?.map((x: Qualification) => (
                                                <ListItemButton
                                                    key={x.id}
                                                    sx={{
                                                        p: {
                                                            xs: '12px 16px',
                                                            sm: '12px 20px',
                                                            lg: '12px 40px'
                                                        },
                                                        textDecoration: 'none',
                                                        bgcolor: '#fff',
                                                        mb: '4px'
                                                    }}
                                                >
                                                    <Grid
                                                        p={0}
                                                        m={0}
                                                        container
                                                        wrap="nowrap"
                                                        alignItems="center"
                                                    >
                                                        <Grid
                                                            p={0}
                                                            m={0}
                                                            flexGrow={1}
                                                            item
                                                        >
                                                            <EducationSummary
                                                                data={{
                                                                    university:
                                                                        {
                                                                            name: x.school,
                                                                            image: cambridge
                                                                        },
                                                                    major: x.degree,
                                                                    yearStarted:
                                                                        x.startDate,
                                                                    yearEnded:
                                                                        x.endDate
                                                                }}
                                                            />
                                                        </Grid>
                                                        {user
                                                            ?.professionalProfile
                                                            ?.id ===
                                                        profileId ? (
                                                            <Grid
                                                                item
                                                                display={'flex'}
                                                                alignItems={
                                                                    'center'
                                                                }
                                                                gap={2}
                                                            >
                                                                {isMobile ? (
                                                                    <IconButton
                                                                        sx={{
                                                                            alignSelf:
                                                                                'flex-start'
                                                                        }}
                                                                        onClick={() => {
                                                                            setId(
                                                                                x.id
                                                                            );
                                                                            handleAddQualification(
                                                                                getQualificationType.Education
                                                                            );
                                                                        }}
                                                                    >
                                                                        <EditBtn color="#000" />
                                                                    </IconButton>
                                                                ) : (
                                                                    <>
                                                                        {/* <IconButton
                                                                            sx={{
                                                                                p: 1,
                                                                                '& svg':
                                                                                    {
                                                                                        color: '#05668D'
                                                                                    }
                                                                            }}
                                                                            onClick={() =>
                                                                                handleDelete(
                                                                                    x.id,
                                                                                    getQualificationType.Education
                                                                                )
                                                                            }
                                                                        >
                                                                            <Trash />
                                                                        </IconButton> */}
                                                                        <Box
                                                                            bgcolor="#f2f2f2"
                                                                            borderRadius="8px"
                                                                            display="flex"
                                                                            alignItems="center"
                                                                            justifyContent="center"
                                                                            sx={{
                                                                                p: 0
                                                                            }}
                                                                        >
                                                                            <ArrowRightIcon
                                                                                fontSize="large"
                                                                                htmlColor="#808080"
                                                                                onClick={() => {
                                                                                    setId(
                                                                                        x.id
                                                                                    );
                                                                                    handleAddQualification(
                                                                                        getQualificationType.Education
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </Box>
                                                                    </>
                                                                )}
                                                            </Grid>
                                                        ) : null}
                                                    </Grid>
                                                </ListItemButton>
                                            ))}
                                    </Box>
                                </Box>
                            ) : null}
                            {user?.professionalProfile?.id === profileId ? (
                                <Box
                                    sx={{
                                        py: '8px',
                                        px: {
                                            xs: '16px',
                                            sm: '20px',
                                            lg: '40px'
                                        },
                                        bgcolor: '#fff'
                                    }}
                                >
                                    <Button
                                        sx={{
                                            height: '44px',
                                            bgcolor: '#f2f2f2',
                                            borderRadius: '8px',
                                            '&:hover': {
                                                bgcolor: '#f2f2f2'
                                            }
                                        }}
                                        variant="contained"
                                        endIcon={
                                            <AddBoxIcon htmlColor="#05668D" />
                                        }
                                        onClick={() =>
                                            handleAddQualification(
                                                getQualificationType.Education
                                            )
                                        }
                                    >
                                        <Typography
                                            color="#05668D"
                                            component="span"
                                            fontSize={16}
                                            fontWeight={600}
                                        >
                                            Add Education
                                        </Typography>
                                    </Button>
                                </Box>
                            ) : null}
                        </Box>

                        <Box mt="8px">
                            {profile?.employments?.length > 0 ? (
                                <Box
                                    style={{
                                        borderEndStartRadius: 0,
                                        borderEndEndRadius: 0
                                    }}
                                >
                                    <Box
                                        bgcolor="#fff"
                                        mb='4px'
                                        p={{
                                            xs: '12px 16px',
                                            sm: '12px 20px',
                                            lg: '12px 40px'
                                        }}
                                    >
                                        <Typography
                                            component="h3"
                                            fontSize={{
                                                xs: '16px',
                                                sm: '20px'
                                            }}
                                            fontWeight={600}
                                        >
                                            Work Experience
                                        </Typography>
                                    </Box>
                                    <Box>
                                        {profile?.employments
                                            ?.sort(
                                                (
                                                    a: Employment,
                                                    b: Employment
                                                ) =>
                                                    a.endDate < b.endDate
                                                        ? 1
                                                        : -1
                                            )
                                            ?.map((x: Employment) => (
                                                <ListItemButton
                                                    key={x.id}
                                                    sx={{
                                                        p: {
                                                            xs: '12px 16px',
                                                            sm: '12px 20px',
                                                            lg: '12px 40px'
                                                        },
                                                        textDecoration: 'none',
                                                        bgcolor: '#fff',
                                                        mb: '4px'
                                                    }}
                                                >
                                                    <Grid
                                                        container
                                                        wrap="nowrap"
                                                        alignItems="center"
                                                    >
                                                        <Grid flexGrow={1} item>
                                                            <WorkSummary
                                                                data={{
                                                                    employer: {
                                                                        name: x.companyName,
                                                                        image: cambridge
                                                                    },
                                                                    title: x.jobTitle,
                                                                    yearStarted:
                                                                        x.startDate,
                                                                    yearEnded:
                                                                        x.endDate,
                                                                    isCurrentPosition:
                                                                        x.isCurrentPosition
                                                                }}
                                                            />
                                                        </Grid>
                                                        {user
                                                            ?.professionalProfile
                                                            ?.id ===
                                                        profileId ? (
                                                            <Grid
                                                                item
                                                                display={'flex'}
                                                                alignItems={
                                                                    'center'
                                                                }
                                                                gap={2}
                                                            >
                                                                {isMobile ? (
                                                                    <IconButton
                                                                        sx={{
                                                                            alignSelf:
                                                                                'flex-start'
                                                                        }}
                                                                        onClick={() => {
                                                                            setId(
                                                                                x.id
                                                                            );
                                                                            handleAddQualification(
                                                                                getQualificationType.WorkExperience
                                                                            );
                                                                        }}
                                                                    >
                                                                        <EditBtn color="#000" />
                                                                    </IconButton>
                                                                ) : (
                                                                    <>
                                                                        {/* <IconButton
                                                                            sx={{
                                                                                p: 1,
                                                                                '& svg':
                                                                                    {
                                                                                        color: '#05668D'
                                                                                    }
                                                                            }}
                                                                            onClick={() =>
                                                                                handleDelete(
                                                                                    x.id,
                                                                                    getQualificationType.WorkExperience
                                                                                )
                                                                            }
                                                                        >
                                                                            <Trash />
                                                                        </IconButton> */}
                                                                        <Box
                                                                            bgcolor="#f2f2f2"
                                                                            borderRadius="8px"
                                                                            display="flex"
                                                                            alignItems="center"
                                                                            justifyContent="center"
                                                                            sx={{
                                                                                p: 0
                                                                            }}
                                                                        >
                                                                            <ArrowRightIcon
                                                                                fontSize="large"
                                                                                htmlColor="#808080"
                                                                                onClick={() => {
                                                                                    setId(
                                                                                        x.id
                                                                                    );
                                                                                    handleAddQualification(
                                                                                        getQualificationType.WorkExperience
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </Box>
                                                                    </>
                                                                )}
                                                            </Grid>
                                                        ) : null}
                                                    </Grid>
                                                </ListItemButton>
                                            ))}
                                    </Box>
                                </Box>
                            ) : null}
                            {user?.professionalProfile?.id === profileId ? (
                                <Box
                                    sx={{
                                        py: '8px',
                                        px: {
                                            xs: '16px',
                                            sm: '20px',
                                            lg: '40px'
                                        },
                                        bgcolor: '#fff'
                                    }}
                                >
                                    <Button
                                        sx={{
                                            height: '44px',
                                            bgcolor: '#f2f2f2',
                                            borderRadius: '8px',
                                            '&:hover': {
                                                bgcolor: '#f2f2f2'
                                            }
                                        }}
                                        variant="contained"
                                        endIcon={
                                            <AddBoxIcon htmlColor="#05668D" />
                                        }
                                        onClick={() =>
                                            handleAddQualification(
                                                getQualificationType.WorkExperience
                                            )
                                        }
                                    >
                                        <Typography
                                            color="#05668D"
                                            component="span"
                                            fontSize={16}
                                            fontWeight={600}
                                        >
                                            Add Work Experience
                                        </Typography>
                                    </Button>
                                </Box>
                            ) : null}
                        </Box>
                        {/* <Box mb={3}>
                        <Paper
                            sx={{
                                px: 4,
                                py: 1.5,
                                mb: 2
                            }}
                            style={{
                                borderEndStartRadius: 0,
                                borderEndEndRadius: 0
                            }}
                        >
                            <Typography
                                component="h3"
                                fontSize={20}
                                fontWeight={600}
                                mb={1.5}
                            >
                                Volunteer Service
                            </Typography>
                            <WorkSummary
                                data={{
                                    employer: {
                                        name: 'GoPro INC.',
                                        image: cambridge
                                    },
                                    title: 'Graphics Designer',
                                    yearStarted: '2020',
                                    yearEnded: '2022'
                                }}
                            />
                        </Paper>
                        {user?.professionalProfile?.id === profileId ?
                            <Button
                                sx={{
                                    py: 1.5
                                }}
                                variant="contained"
                                endIcon={<AddBoxIcon />}
                                onClick={() =>
                                    handleAddQualification(
                                        getQualificationType.VolunteerService
                                    )
                                }
                            >
                                <Typography
                                    component="span"
                                    fontSize={14}
                                    fontWeight={600}
                                >
                                    Add Volunteer Service
                                </Typography>
                            </Button>
                            : null
                        }
                    </Box> */}
                        {/* <Box mb={3}>
                        <Paper
                            sx={{
                                px: 4,
                                py: 1.5,
                                mb: 2
                            }}
                            style={{
                                borderEndStartRadius: 0,
                                borderEndEndRadius: 0
                            }}
                        >
                            <Typography
                                component="h3"
                                fontSize={20}
                                fontWeight={600}
                                mb={1.5}
                            >
                                Licenses and Certification
                            </Typography>
                            <List>
                                <ListItemButton
                                    style={{ textDecoration: 'none' }}
                                    sx={{ padding: 0 }}
                                >
                                    <Grid
                                        container
                                        wrap="nowrap"
                                        alignItems="center"
                                    >
                                        <Grid flexGrow={1} item>
                                            <WorkSummary
                                                data={{
                                                    employer: {
                                                        name: 'Google',
                                                        image: cambridge
                                                    },
                                                    title: 'Google Certified Product Designer',
                                                    yearStarted: '2020',
                                                    yearEnded: '2022'
                                                }}
                                            />
                                        </Grid>
                                        {user?.professionalProfile?.id === profileId ?
                                            <Grid item>
                                                <ArrowRightIcon
                                                    fontSize="large"
                                                    htmlColor="#055C7F"
                                                />
                                            </Grid>
                                            : null
                                        }
                                    </Grid>
                                </ListItemButton>

                                <Box py={2.5}>
                                    <Divider />
                                </Box>

                                <ListItemButton
                                    style={{ textDecoration: 'none' }}
                                    sx={{ padding: 0 }}
                                >
                                    <Grid
                                        container
                                        wrap="nowrap"
                                        alignItems="center"
                                    >
                                        <Grid flexGrow={1} item>
                                            <WorkSummary
                                                data={{
                                                    employer: {
                                                        name: 'Google',
                                                        image: cambridge
                                                    },
                                                    title: 'Google Certified Product Designer',
                                                    yearStarted: '2020',
                                                    yearEnded: '2022'
                                                }}
                                            />
                                        </Grid>
                                        {user?.professionalProfile?.id === profileId ?
                                            <Grid item>
                                                <ArrowRightIcon
                                                    fontSize="large"
                                                    htmlColor="#055C7F"
                                                />
                                            </Grid>
                                            : null
                                        }
                                    </Grid>
                                </ListItemButton>
                            </List>
                        </Paper>
                        {user?.professionalProfile?.id === profileId ?
                            <Button
                                sx={{
                                    py: 1.5
                                }}
                                variant="contained"
                                endIcon={<AddBoxIcon />}
                                onClick={() =>
                                    handleAddQualification(
                                        getQualificationType.LicenseAndCertifications
                                    )
                                }
                            >
                                <Typography
                                    component="span"
                                    fontSize={14}
                                    fontWeight={600}
                                >
                                    Add Licenses & Certification
                                </Typography>
                            </Button>
                            :
                            null
                        }
                    </Box> */}
                        {/* <Box mb={3}>
                        <Paper
                            sx={{
                                px: 4,
                                py: 1.5,
                                mb: 2
                            }}
                            style={{
                                borderEndStartRadius: 0,
                                borderEndEndRadius: 0
                            }}
                        >
                            <Typography
                                component="h3"
                                fontSize={20}
                                fontWeight={600}
                                mb={1.5}
                            >
                                Tests
                            </Typography>
                            <List>
                                <ListItemButton
                                    style={{ textDecoration: 'none' }}
                                    sx={{ padding: 0 }}
                                >
                                    <Grid
                                        container
                                        wrap="nowrap"
                                        alignItems="center"
                                    >
                                        <Grid flexGrow={1} item>
                                            <WorkSummary
                                                data={{
                                                    employer: {
                                                        name: 'College Board'
                                                    },
                                                    title: 'SAT',
                                                    yearStarted: '2020',
                                                    yearEnded: '2022'
                                                }}
                                            />
                                        </Grid>
                                        {user?.professionalProfile?.id === profileId ?
                                            <Grid item>
                                                <ArrowRightIcon
                                                    fontSize="large"
                                                    htmlColor="#055C7F"
                                                />
                                            </Grid>
                                            : null
                                        }
                                    </Grid>
                                </ListItemButton>
                            </List>
                        </Paper>
                        {user?.professionalProfile?.id === profileId ?
                            <Button
                                sx={{
                                    py: 1.5
                                }}
                                variant="contained"
                                endIcon={<AddBoxIcon />}
                                onClick={() =>
                                    handleAddQualification(
                                        getQualificationType.Tests
                                    )
                                }
                            >
                                <Typography
                                    component="span"
                                    fontSize={14}
                                    fontWeight={600}
                                >
                                    Add Test
                                </Typography>
                            </Button>
                            : null
                        }
                    </Box> */}
                        {/* <Box mb={3}>
                        <Paper
                            sx={{
                                px: 4,
                                py: 1.5,
                                mb: 2
                            }}
                            style={{
                                borderEndStartRadius: 0,
                                borderEndEndRadius: 0
                            }}
                        >
                            <Typography
                                component="h3"
                                fontSize={20}
                                fontWeight={600}
                                mb={1.5}
                            >
                                Awards
                            </Typography>
                            <List>
                                <ListItemButton
                                    style={{ textDecoration: 'none' }}
                                    sx={{ padding: 0 }}
                                >
                                    <Grid
                                        container
                                        wrap="nowrap"
                                        alignItems="center"
                                    >
                                        <Grid flexGrow={1} item>
                                            <WorkSummary
                                                data={{
                                                    employer: {
                                                        name: 'Jobfactor Inc.'
                                                    },
                                                    title: 'Employee of the year',
                                                    yearStarted: '2020',
                                                    yearEnded: '2022'
                                                }}
                                            />
                                        </Grid>
                                        {user?.professionalProfile?.id === profileId ?
                                            <Grid item>
                                                <ArrowRightIcon
                                                    fontSize="large"
                                                    htmlColor="#055C7F"
                                                />
                                            </Grid>
                                            :
                                            null
                                        }
                                    </Grid>
                                </ListItemButton>
                            </List>
                        </Paper>
                        {user?.professionalProfile?.id === profileId ?
                            <Button
                                sx={{
                                    py: 1.5
                                }}
                                variant="contained"
                                endIcon={<AddBoxIcon />}
                                onClick={() =>
                                    handleAddQualification(
                                        getQualificationType.Awards
                                    )
                                }
                            >
                                <Typography
                                    component="span"
                                    fontSize={14}
                                    fontWeight={600}
                                >
                                    Add Award
                                </Typography>
                            </Button>
                            :
                            null
                        }
                    </Box> */}
                    </>
                ) : (
                    <Box
                        sx={
                            {
                                // px: isMobile ? '16px' : isTablet ? '20px' : '40px',
                                // pr: isMobile ? 0 : undefined
                            }
                        }
                    >
                        <Button
                            sx={{
                                py: 1.5
                            }}
                            variant="contained"
                            endIcon={<BriefcaseIcon />}
                            onClick={() => handleAddQualification('')}
                        >
                            <Typography
                                component="span"
                                fontSize={14}
                                fontWeight={600}
                            >
                                Add a Qualification
                            </Typography>
                        </Button>
                    </Box>
                )}

                <AddQualificationDialog
                    id={open ? id : ''}
                    open={open}
                    qualificationType={qualificationType}
                    setOpen={(val) => {
                        setOpen(val);
                        setId('');
                        setQualificationType('');
                    }}
                />
                <SnackAlert
                    open={showAlert}
                    handleClose={() => setShowAlert(false)}
                    message={message}
                    type={type}
                />
                <DeletePrompt
                    show={showWarning}
                    message={warningMessage}
                    handleYes={handleRemove}
                    handleClose={() => setShowWarning(false)}
                />
            </Box>
        </Box>
    );
}

export default UserQualifications;
