import { UseMutationResult, useMutation } from 'react-query';
import axiosInstance from '../axiosConfig';

async function updateCompanyReviewRequest(patchRequest: unknown) {
    const response = await axiosInstance.patch(`/api/company-reviews-requests`, patchRequest);
    const data = await response.data;
    return data;
}
export function useUpdateCompanyReviewRequest(): UseMutationResult<
    unknown,
    unknown,
    unknown
> {
    return useMutation(updateCompanyReviewRequest);
}