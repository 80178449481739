import { MessagesProvider, useMessages } from './utils/MessagesContext';
import ActiveConversation from './Components/ActiveConversation/ActiveConversation';
import { Stack } from '@mui/material';
import PageHeader from '../common/PageHeader';
import ConversationList from './Components/ConversationsSidebar/ConversationList';
import ConversationHeader from './Components/Header/ConversationHeader';
import { ConversationPlaceholder } from './Components/ActiveConversation/ConversationPlaceholder';
import HeaderActions from './Components/Header/HeaderActions';
import { SearchBar } from './Components/Header/SearchBar';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

function MessagesPage() {
    const { activeConversation, setActiveConversation, viewMode, setViewMode } =
        useMessages();

const location = useLocation();
    const user = location?.state?.user;
    const [thisUser, setThisUser] = useState<any>(
        {
            name:
                user?.destinationUser?.firstName ?? user?.firstName + ' ' +
                    user?.lastName ?? user?.destinationUser?.lastName,
            // score: user?.destinationUser?.score,
            userId: user?.destinationUser?.userId ?? user?.userId,
            // profileType: 'PROFESSIONAL',
            profileSlug:
                `/professional-profile/${user?.destinationUser?.profileId ?? user?.id}`,
            imageUrl: user?.destinationUser?.photoUrl ?? user?.photoUrl
        }
    );


    useEffect(() => {
        if (user) {
            setActiveConversation(thisUser)
        }
    }, [])

    const handleBack = () => {
        setActiveConversation(null);
        setViewMode(null);
        setThisUser(null)
    };

    return (
        <Stack
            gap={1}
            sx={{
                pr: { xs: 0, sm: '20px', lg: '40px' },
                pl: { xs: 0, sm: '8px', lg: 0 },
                mt: { sm: '0px' }
            }}
        >
            <PageHeader
                pageTitle={
                    viewMode === 'search' ? (
                        <SearchBar />
                    ) : activeConversation ? (
                        <ConversationHeader />
                    ) : (
                        'Messages'
                    )
                }
                handleBack={handleBack}
                actions={<HeaderActions />}
                sx={{ p: `${activeConversation ? '21px' : '24px'} 40px` }}
            />
            <Stack
                direction="row"
                spacing={{ xs: 0, lg: 2 }}
                sx={{ height: '698px' }}
            >
                <ConversationList />
                {activeConversation ? (
                    <ActiveConversation />
                ) : (
                    <ConversationPlaceholder />
                )}
            </Stack>
        </Stack>
    );
}

export default function Messages() {
    return (
        <MessagesProvider>
            <MessagesPage />
        </MessagesProvider>
    );
}
