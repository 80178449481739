const JobDetailItemFlagIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.14844 22.75C4.73844 22.75 4.39844 22.41 4.39844 22V2C4.39844 1.59 4.73844 1.25 5.14844 1.25C5.55844 1.25 5.89844 1.59 5.89844 2V22C5.89844 22.41 5.55844 22.75 5.14844 22.75Z"
                fill="#494949"
            />
            <path
                d="M16.3484 16.75H5.14844C4.73844 16.75 4.39844 16.41 4.39844 16C4.39844 15.59 4.73844 15.25 5.14844 15.25H16.3484C17.4384 15.25 17.9484 14.96 18.0484 14.71C18.1484 14.46 17.9984 13.9 17.2184 13.13L16.0184 11.93C15.5284 11.5 15.2284 10.85 15.1984 10.13C15.1684 9.37 15.4684 8.62 16.0184 8.07L17.2184 6.87C17.9584 6.13 18.1884 5.53 18.0784 5.27C17.9684 5.01 17.3984 4.75 16.3484 4.75H5.14844C4.72844 4.75 4.39844 4.41 4.39844 4C4.39844 3.59 4.73844 3.25 5.14844 3.25H16.3484C18.5384 3.25 19.2384 4.16 19.4684 4.7C19.6884 5.24 19.8384 6.38 18.2784 7.94L17.0784 9.14C16.8284 9.39 16.6884 9.74 16.6984 10.09C16.7084 10.39 16.8284 10.66 17.0384 10.85L18.2784 12.08C19.8084 13.61 19.6584 14.75 19.4384 15.3C19.2084 15.83 18.4984 16.75 16.3484 16.75Z"
                fill="#494949"
            />
        </svg>
    );
};

export default JobDetailItemFlagIcon;
