import axiosInstance from '../axiosConfig';
import { UseMutationResult, useMutation } from 'react-query';

async function removeEducation(educationId: any) {
    const response = await axiosInstance.delete(
        `/api/educations/${educationId}`
    );
    const data = await response.data;
    return data;
}
export function useRemoveEducation(): UseMutationResult<
    unknown,
    unknown,
    unknown
> {
    return useMutation(removeEducation);
}
