import { Box, Button, Typography } from "@mui/material"
import UserPlaceholderIcon from "../../../assets/icons/UserPlaceholderIcon"
import StarRating from "./StarRating"
import { useNavigate } from "react-router"
import { IReviewType } from "../types"
import moment from "moment"


const RatingBox = ({ review, index }: { review: IReviewType, index: number }) => {
    const navigate = useNavigate();
    const maxLength = 180;
    const description = review?.message ?? '';
    return (
        <Box
            sx={{
                gap: '32px',
                p: '20px 32px',
                display: 'flex',
                alignItems: 'center',
                borderBottom: '1px solid #EDEDED'
            }}
        >
            <Box maxWidth={'10%'}>
                <UserPlaceholderIcon />
            </Box>
            <Box maxWidth={'70%'} width={'100%'}>
                <Typography variant="titleLargeSemiBold">
                    Anonymous {index + 1}
                </Typography>
                <Typography color={'#808080'} my={'12px'}>
                    {description.slice(0, maxLength)}{description?.length > maxLength ? ' ......' : ""}
                </Typography>
                <Typography variant="titleSmallRegular">
                    {moment(review.lastUpdatedAt).fromNow()}
                </Typography>
                <Button
                    onClick={() => navigate('breakdown', { state: review })}
                    variant="contained"
                    sx={{
                        mt: '12px',
                        display: 'block',
                        width: 'auto',
                        fontSize: 14,
                        fontWeight: 700
                    }}
                >
                    See ratings breakdown
                </Button>
            </Box>
            <Box maxWidth={'20%'} width={'100%'} display={'flex'} justifyContent={'end'} flexDirection={'column'}>
                <Typography color={'#808080'} mb={'12px'} variant="titleSmallSemiBold">
                    Average rating:
                </Typography>
                <StarRating level={review?.averageRating} readOnly={true} />
            </Box>
        </Box>
    )
}

export default RatingBox