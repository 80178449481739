import {
    Typography,
    Checkbox,
    Button,
    InputBase,
    InputLabel,
    Grid,
    CircularProgress
} from '@mui/material';
import Box from '@mui/material/Box';
import EllipsisIcon from '../../assets/icons/EllipsisIcon';
import EmailFormIcon from '../../assets/icons/EmailFormIcon';
import GoogleIcon from '../../assets/icons/GoogleIcon';
import JobFactorIcon from '../../assets/icons/JobFactorIcon';
import PasswordFormIcon from '../../assets/icons/PasswordFormIcon';
import profileReview from './../../assets/images/profileReview.png';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../utils/context/AuthContext';
import { useEffect, useState } from 'react';
import OnboardingLineIcon from '../../assets/icons/OnboardingLineIcon';
import SnackAlert from '../../components/Snackbar';
import { LinkedIn, TroubleshootOutlined } from '@mui/icons-material';
import { useLinkedInAuth } from '../../utils/hooks/oauth/linkedin/useLinkedInAuth';
import { logger } from '../../utils/Helper/logger';
import ErrorFormIcon from '../../assets/icons/ErrorFormIcon';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import OnBoardingSidePanel from '../OnBoarding/OnBoardingSidePanel';
import { useGoogleAuth } from '../../utils/hooks/oauth/google/useGoogleAuth';
import { UserInfoOAuth } from '../../utils/hooks/oauth/types/userInfo';

interface ILoginForm {
    emailAddress: string;
    password: string;
    rememberMe?: boolean;
}

const loginTitle = 'Professionals meet companies';
const loginSubHeading =
    'Start your journey with us, discover the world’s best platform that connects professionals and companies';
const cardReiew =
    'Absolutely amazing, the vetting algorithym is the best at proving ones level of experience';

function Login() {
    let navigate = useNavigate();
    const location = useLocation();
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const queryParams = new URLSearchParams(location.search);
    const { signIn, isAuthenticated, accessToken, account } = useAuth();
    const {
        control,
        handleSubmit,
        setError,
        register,
        clearErrors,
        formState
    } = useForm<ILoginForm>();
    const { isDirty, isValid, errors } = formState;
    useEffect(() => {
        if (
            queryParams.get('newAccount') &&
            queryParams.get('newAccount') === 'true'
        ) {
            navigate(
                account?.primaryProfile === 'PROFESSIONAL'
                    ? '/users'
                    : '/about-company'
            );
        }
        if (isAuthenticated && !!accessToken) {
            navigate('/');
        }
    }, [isAuthenticated, accessToken]);

    const handleSignIn = async (data: ILoginForm) => {
        setLoading(true)
        const response: any = await signIn(
            data.emailAddress.toLowerCase(),
            data.password
        );
        if (response?.message) {
            setMessage(response?.message);
            setShowAlert(true);
            setLoading(false)
        }
    };

    const onSubmit: SubmitHandler<ILoginForm> = async (data) => {
        handleSignIn(data);
    };

    /** OAuth */
    const handleOAuthLogin = (info: UserInfoOAuth) => {
        if (!info.email) return;
        const loginForm: ILoginForm = {
            emailAddress: info.email,
            password: info.sub,
            rememberMe: !!control._formValues.rememberMe
        };
        handleSignIn(loginForm);
    };
    const handleOAuthError = (error: any) => {
        logger.error(error);
        setMessage('OAuth login failed, please try again');
        setShowAlert(true);
    };
    /** LinkedIn */
    const { linkedInLogin } = useLinkedInAuth({
        onSuccess: handleOAuthLogin,
        onError: handleOAuthError
    });
    /** Google */
    const googleLogin = useGoogleAuth({
        onSuccess: handleOAuthLogin,
        onError: handleOAuthError
    });

    return (
        <>
            <Box
                sx={{
                    mx: { md: '40px', xs: 0 }
                }}
            >
                <Grid
                    sx={{
                        mt: { md: '40px', xs: 0 },
                        minHeight: {
                            md: 'calc(100vh - 80px)',
                            sm: '100vh',
                            xs: '100%'
                        },
                        backgroundColor: {
                            md: '#FCFBF8',
                            sm: '#FFF',
                            xs: '#FCFBF8'
                        },
                        width: '100%'
                    }}
                    container
                    columnSpacing={{ lg: 5, md: 2, xs: 0 }}
                >
                    <Grid item md={6} xs={12}>
                        <Box
                            sx={{
                                height: {
                                    md: '100%',
                                    sm: '350px',
                                    xs: 'fit-content'
                                },
                                backgroundColor: '#FFE3AD',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                gap: '40px',
                                maxWidth: { md: '700px', xs: '100%' }
                            }}
                        >
                            {/* Column1 */}
                            {/* Logo */}
                            <Box
                                sx={{
                                    mt: '51px',
                                    width: { sm: '80%', xs: '90%' },
                                    alignSelf: 'center',
                                    mb: 4
                                }}
                            >
                                <Link to={'/'}>
                                    <JobFactorIcon />
                                </Link>
                                <Typography
                                    color={'#23282B'}
                                    variant="titleLargeSemiBold"
                                    display={{ sm: 'none', xs: 'block' }}
                                    mt={'32px'}
                                >
                                    Welcome back,
                                </Typography>
                                <Typography
                                    color={'#808080'}
                                    variant="titleSmallRegular"
                                    display={{ sm: 'none', xs: 'block' }}
                                    mt={'8px'}
                                >
                                    Login to your account
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: { md: 'block', xs: 'none' }
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: { lg: '45px', xs: '35px' },
                                        fontFamily: 'open sans',
                                        fontStyle: 'semibold',
                                        fontWeight: '600',
                                        textAlign: 'left',
                                        mb: '28px',
                                        mt: '10px',
                                        px: '53px',
                                        color: '#23282B',
                                        lineHeight: '52px',
                                        opacity: '0.85'
                                    }}
                                >
                                    Professionals meet companies
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: { lg: '20px', xs: 16 },
                                        fontFamily: 'open sans',
                                        lineHeight: '28px',
                                        color: '#23282B',
                                        textAlign: 'left',
                                        px: '53px',
                                        opacity: '0.65'
                                    }}
                                >
                                    Start your journey with us, discover the
                                    world’s best platform that connects
                                    professionals and companies
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    width: '80%',
                                    alignSelf: 'center',
                                    display: { md: 'block', xs: 'none' }
                                }}
                            >
                                <Box
                                    sx={{
                                        borderRadius: '8px',
                                        padding: '24px 20px',
                                        backgroundColor: '#FFFFFF',
                                        mb: '24px'
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: { lg: '20px', xs: 16 },
                                            fontFamily: 'open sans',
                                            fontWeight: '600',
                                            textAlign: 'left',
                                            lineHeight: '28px',
                                            mb: '28px',
                                            color: '#23282B',
                                            opacity: '0.75'
                                        }}
                                    >
                                        Absolutely amazing, the vetting
                                        algorithym is the best at proving ones
                                        level of experience
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <img
                                            src={profileReview}
                                            alt="Reviewer Avatar"
                                            height="64"
                                            width="64"
                                        ></img>
                                        <Box sx={{ ml: '16px' }}>
                                            <Typography
                                                sx={{
                                                    fontSize: '16px',
                                                    fontFamily: 'open sans',
                                                    fontWeight: '700',
                                                    opacity: ' 0.9',
                                                    color: '#23282B'
                                                }}
                                            >
                                                Mark Fisher
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: '14px',
                                                    fontFamily: 'open sans',
                                                    opacity: ' 0.75',
                                                    color: '#23282B'
                                                }}
                                            >
                                                Professional
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        mt: '24px',
                                        mb: '80px'
                                    }}
                                >
                                    <EllipsisIcon />
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                    {/* right column */}
                    <Grid
                        item
                        md={6}
                        xs={12}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: { md: 'start', xs: 'center' }
                        }}
                    >
                        <Box
                            sx={{
                                width: { xl: '60%' },
                                backgroundColor: {
                                    md: 'transparent',
                                    sm: '#FCFBF8',
                                    xs: 'transparent'
                                },
                                borderRadius: '12px',
                                p: { md: 0, sm: '40px', xs: 0 },
                                mt: { md: 0, sm: '-320px', xs: 0 },
                                mx: { lg: 0, sm: '50px', xs: '20px' },
                                mb: 0
                            }}
                        >
                            {/* login form */}
                            <Box
                                sx={{
                                    margin: '0 auto'
                                }}
                            >
                                <Box>
                                    <Typography
                                        variant="headlineLargeSemiBold"
                                        color="#23282B"
                                        display={{ sm: 'block', xs: 'none' }}
                                    >
                                        Welcome back,
                                    </Typography>

                                    <Box sx={{ mt: '32px' }}>
                                        <Box sx={{ position: 'relative' }}>
                                            <InputLabel
                                                sx={{
                                                    color: '#23282B',
                                                    fontSize: '14px',
                                                    position: 'absolute',
                                                    top: '8px',
                                                    left: '72px',
                                                    zIndex: 1,
                                                    fontFamily: 'Open Sans',
                                                    ml: '4px'
                                                }}
                                                htmlFor="email"
                                            >
                                                Email
                                            </InputLabel>
                                            {/* Email Address Input */}
                                            <Controller
                                                name="emailAddress"
                                                control={control}
                                                render={({
                                                    field: { onChange, value },
                                                    fieldState: { error },
                                                    formState
                                                }) => (
                                                    <InputBase
                                                        required
                                                        onChange={onChange}
                                                        error={
                                                            !!errors?.emailAddress
                                                        }
                                                        inputProps={{
                                                            autoComplete: '',
                                                            form: {
                                                                autoComplete:
                                                                    'off'
                                                            },
                                                            inputMode: 'email'
                                                        }}
                                                        id="email"
                                                        placeholder="Enter your email address"
                                                        startAdornment={
                                                            <EmailFormIcon />
                                                        }
                                                        rows={1}
                                                        sx={{
                                                            backgroundColor:
                                                                '#FFFFFF',
                                                            width: '100%',
                                                            height: '70px',
                                                            padding: '0px 16px',
                                                            fontFamily:
                                                                'open sans',
                                                            color: '#23282B',
                                                            borderBottom:
                                                                '1px solid #D9D9D9',
                                                            mb: '20px',
                                                            '& 	.MuiInputBase-input':
                                                                {
                                                                    ml: '20px',
                                                                    position:
                                                                        'relative',
                                                                    top: '8px'
                                                                }
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Box>

                                        {/* password */}
                                        <Box
                                            sx={{
                                                width: '100%',
                                                position: 'relative'
                                            }}
                                        >
                                            <InputLabel
                                                sx={{
                                                    color: '#23282B',
                                                    fontSize: '14px',
                                                    position: 'absolute',
                                                    top: '8px',
                                                    left: '72px',
                                                    zIndex: 1,
                                                    fontFamily: 'Open Sans',
                                                    ml: '4px'
                                                }}
                                                htmlFor="password"
                                            >
                                                Password
                                            </InputLabel>
                                            <Controller
                                                name="password"
                                                control={control}
                                                render={({
                                                    field: {
                                                        onChange,
                                                        onBlur,
                                                        value,
                                                        ref
                                                    },
                                                    fieldState: { error },
                                                    formState
                                                }) => (
                                                    <InputBase
                                                        {...register(
                                                            'password',
                                                            {
                                                                required:
                                                                    'Required field'
                                                            }
                                                        )}
                                                        required
                                                        inputProps={{
                                                            autoComplete:
                                                                'new-password',
                                                            form: {
                                                                autoComplete:
                                                                    'off'
                                                            }
                                                        }}
                                                        inputRef={ref}
                                                        name="password"
                                                        id="password"
                                                        startAdornment={
                                                            <PasswordFormIcon />
                                                        }
                                                        rows={1}
                                                        placeholder={'********'}
                                                        type="password"
                                                        sx={{
                                                            backgroundColor:
                                                                '#FFFFFF',
                                                            width: '100%',
                                                            height: '70px',
                                                            padding: '0px 16px',
                                                            mb: '2px',
                                                            fontFamily:
                                                                'open sans',
                                                            color: '#23282B',
                                                            borderBottom:
                                                                '1px solid #D9D9D9',
                                                            '& 	.MuiInputBase-input':
                                                                {
                                                                    ml: '20px',
                                                                    position:
                                                                        'relative',
                                                                    top: '8px'
                                                                }
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Box>

                                        {/* removed error message */}
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                gap: '5px',
                                                mt: '10px',
                                                alignContent: 'start'
                                            }}
                                        >
                                            <ErrorFormIcon />
                                            <Typography
                                                sx={{
                                                    color: '#808080',
                                                    fontSize: '12px',
                                                    fontFamily: 'Open Sans'
                                                }}
                                            >
                                                Password should contain
                                                uppercase letter(s), numbers(s)
                                                and special character(s)
                                            </Typography>
                                        </Box>

                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                mt: '32px',
                                                mb: { sm: '56px', xs: '40px' },
                                                mr: '16px'
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: {
                                                        sm: '10px',
                                                        xs: '5px'
                                                    }
                                                }}
                                            >
                                                <Controller
                                                    name="rememberMe"
                                                    control={control}
                                                    rules={{ required: false }}
                                                    render={({
                                                        field: {
                                                            onChange,
                                                            ref
                                                        },
                                                        fieldState: {
                                                            isTouched,
                                                            isDirty
                                                        },
                                                        formState
                                                    }) => {
                                                        return (
                                                            <Checkbox
                                                                onChange={
                                                                    onChange
                                                                }
                                                                inputRef={ref}
                                                                name="rememberMe"
                                                                inputProps={{
                                                                    'aria-label':
                                                                        'Remember me?'
                                                                }}
                                                                sx={{
                                                                    color: '#D1D1D1',
                                                                    padding: 0
                                                                }}
                                                            />
                                                        );
                                                    }}
                                                />
                                                <Typography
                                                    variant="labelLargeRegular"
                                                    sx={{
                                                        color: '#23282B',
                                                        fontSize: {
                                                            sm: 16,
                                                            xs: 14
                                                        }
                                                    }}
                                                >
                                                    Remember me?
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    color: '#05668D',
                                                    fontSize: 14,
                                                    fontWeight: 600
                                                }}
                                                onClick={() =>
                                                    navigate('/forgot-password')
                                                }
                                            >
                                                Forgot Password?
                                            </Box>
                                        </Box>
                                    </Box>

                                    {/* Buttons */}
                                    <Box
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <Button
                                            disabled={loading}
                                            sx={{
                                                height: {
                                                    sm: '52px',
                                                    xs: '42px'
                                                },
                                                fontSize: { sm: 16, xs: 14 },
                                                fontWeight: 600,
                                                bgcolor: '#05668D'
                                            }}
                                            variant="contained"
                                            onClick={handleSubmit(onSubmit)}
                                        >
                                            {loading ? (
                                                <CircularProgress
                                                    sx={{ color:'#fff' }}
                                                />
                                            ) : (
                                                'Login'
                                            )}
                                        </Button>

                                        {/* TODO: Google OAuth */}
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                position: 'relative',
                                                justifyContent: 'center',
                                                width: '100%',
                                                '& svg': {
                                                    width: '100%'
                                                }
                                            }}
                                        >
                                            <OnboardingLineIcon />
                                            <Typography
                                                variant="bodyLargeRegular"
                                                sx={{
                                                    width: '100%',
                                                    py: '12px',
                                                    backgroundColor: '#fcfbf8',
                                                    zIndex: 1,
                                                    fontSize: {
                                                        sm: '16px',
                                                        xs: '14px'
                                                    },
                                                    textAlign: 'center',
                                                    whiteSpace: 'nowrap'
                                                }}
                                            >
                                                Or login with
                                            </Typography>
                                            <OnboardingLineIcon />
                                        </Box>
                                        <Grid
                                            container
                                            sx={{
                                                width: '100%',
                                                justifyContent: 'space-evenly'
                                            }}
                                            spacing={1.5}
                                        >
                                            <Grid
                                                item
                                                lg={6}
                                                md={12}
                                                sm={6}
                                                xs={12}
                                            >
                                                <Button
                                                    onClick={() =>
                                                        googleLogin()
                                                    }
                                                    sx={{
                                                        height: {
                                                            sm: '52px',
                                                            xs: '42px'
                                                        },
                                                        fontSize: {
                                                            sm: 16,
                                                            xs: 14
                                                        }
                                                    }}
                                                    variant="outlined"
                                                    startIcon={<GoogleIcon />}
                                                >
                                                    Sign in with Google
                                                </Button>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={12}
                                                sm={6}
                                                xs={12}
                                            >
                                                <Button
                                                    onClick={linkedInLogin}
                                                    sx={{
                                                        height: {
                                                            sm: '52px',
                                                            xs: '42px'
                                                        },
                                                        fontSize: {
                                                            sm: 16,
                                                            xs: 14
                                                        }
                                                    }}
                                                    variant="outlined"
                                                    startIcon={<LinkedIn />}
                                                >
                                                    Sign in with LinkedIn
                                                </Button>
                                            </Grid>
                                        </Grid>

                                        <Box
                                            sx={{
                                                display: 'flex',
                                                mt: { sm: '48px', xs: '30px' }
                                            }}
                                        >
                                            <Typography
                                                variant="bodyLargeRegular"
                                                sx={{
                                                    color: '#808080',
                                                    mr: '10px',
                                                    fontSize: { sm: 16, xs: 14 }
                                                }}
                                            >
                                                Don’t have an account?
                                            </Typography>
                                            <Typography
                                                variant="bodyLargeSemiBold"
                                                sx={{
                                                    color: '#05668D',
                                                    fontSize: { sm: 16, xs: 14 }
                                                }}
                                            >
                                                <Link
                                                    to={'/create-account'}
                                                    style={{
                                                        color: '#05668D'
                                                    }}
                                                >
                                                    Create one
                                                </Link>
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <SnackAlert
                open={showAlert}
                handleClose={() => setShowAlert(false)}
                message={message}
                type={'error'}
            />
        </>
    );
}

export default Login;
