const UserIcon3 = ({isActive = false}) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill={isActive ? '#05668D' : 'none'}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.1302 9.68464C10.1052 9.68464 10.0885 9.68464 10.0635 9.68464C10.0219 9.6763 9.96354 9.6763 9.91354 9.68464C7.49687 9.60964 5.67188 7.70964 5.67188 5.36797C5.67188 2.98464 7.61354 1.04297 9.99688 1.04297C12.3802 1.04297 14.3219 2.98464 14.3219 5.36797C14.3135 7.70964 12.4802 9.60964 10.1552 9.68464C10.1469 9.68464 10.1385 9.68464 10.1302 9.68464ZM9.99688 2.29297C8.30521 2.29297 6.92188 3.6763 6.92188 5.36797C6.92188 7.03464 8.22187 8.3763 9.88021 8.43464C9.92187 8.4263 10.0385 8.4263 10.1469 8.43464C11.7802 8.35964 13.0635 7.01797 13.0719 5.36797C13.0719 3.6763 11.6885 2.29297 9.99688 2.29297Z"
                fill={isActive ? '#05668D' : '#494949'}
            />
            <path
                d="M10.1443 18.7904C8.51094 18.7904 6.86927 18.3737 5.6276 17.5404C4.46927 16.7737 3.83594 15.7237 3.83594 14.582C3.83594 13.4404 4.46927 12.382 5.6276 11.607C8.1276 9.9487 12.1776 9.9487 14.6609 11.607C15.8109 12.3737 16.4526 13.4237 16.4526 14.5654C16.4526 15.707 15.8193 16.7654 14.6609 17.5404C13.4109 18.3737 11.7776 18.7904 10.1443 18.7904ZM6.31927 12.657C5.51927 13.1904 5.08594 13.8737 5.08594 14.5904C5.08594 15.2987 5.5276 15.982 6.31927 16.507C8.39427 17.8987 11.8943 17.8987 13.9693 16.507C14.7693 15.9737 15.2026 15.2904 15.2026 14.5737C15.2026 13.8654 14.7609 13.182 13.9693 12.657C11.8943 11.2737 8.39427 11.2737 6.31927 12.657Z"
                fill={isActive ? '#05668D' : '#494949'}
            />
        </svg>
    );
};

export default UserIcon3;
