import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    IconButton,
    Modal,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    Typography,
    Radio,
    FormControlLabel,
    Avatar,
    Dialog,
    useMediaQuery,
    useTheme,
    Menu,
    MenuItem,
    ListItemText
} from '@mui/material';
import UploadImage from '../../../assets/icons/UploadImage';
import UserGroup from '../../../assets/icons/UserGroup';
import Global from '../../../assets/icons/Global';
import CancelIcon from '@mui/icons-material/Cancel';
import { FeedItem } from './FeedItem';
import Camera from '../../../assets/icons/CameraIconMui';
import AchievementMedal from '../../../assets/icons/AchievementMedal';
import AchievementSkillsPopup, {
    ISkillType
} from '../../../components/AchievementSkillsPopup/AchievementSkillsPopup';
import { PrimaryProfileType } from '../../../utils/hooks/api/account/types';
import { useAuth } from '../../../utils/context/AuthContext';
import { useUserPost } from '../../../utils/hooks/api/posts/useCreatePost';
import SnackAlert from '../../../components/Snackbar';
import { usePost } from '../../../utils/context/PostContext';
import { IFeedItem } from '../types/IFeedItem';
// import { ICommentType } from './CommentsPopup';
import ImageGallery from '../../../assets/icons/ImageGallery';
import Folder from '../../../assets/icons/Folder';
import { useGetSearchTerm } from '../../../utils/hooks/api/search/useSearchTerm';
import { logger } from '../../../utils/Helper/logger';
import MentionsDetails from './MentionsDetails';
// @ts-ignore
import { MentionsInput, Mention } from 'react-mentions';
import HashTag from './HashTag';
import Picker from 'emoji-picker-react';
import ArrowDropIcon from '../../../assets/icons/ArrowDropIcon';
import EmojiIcon from '../../../assets/icons/EmojiIcon';
import CancelImageIcon from '../../../assets/icons/CancelIcon';
import JobfactorAppBar from '../../../components/JobfactorAppBar';
import CloseXIcon from '../../../assets/icons/CloseXIcon';
import ClearIcon from '../../../assets/icons/ClearIcon';
import ArrowBack from '../../../assets/icons/ArrowBack';
import dayjs from 'dayjs';

const modalstyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    border: 0,
    boxShadow: 24,
    p: 3,
    outline: 'none',
    maxHeight: 800,
    overflowY: 'auto'
};

const childmodalstyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    border: 0,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 3,
    outline: 'none',
    zIndex: 0,
    overflow: 'hidden'
};

const width = '300px';
const height = '400px';

interface ICommentType {
    id: string;
    createdAt: number;
    comment: string;
    postId: string;
    likes: number;
    likedByProfessionalIds: any[];
    user: {
        photoUrl?: string;
        firstName: string;
        lastName: string;
        id: string;
    };
    isAccountVerified?: boolean;
    replies: Array<ICommentType>;
}
interface PostCardProps {
    showModal: boolean;
    data: any;
    hideModal: (e?: any) => void;
    repostPopup?: boolean;
    achievementModal?: boolean;
    draft?: boolean;
}

const PostCards = ({
    showModal,
    data,
    hideModal,
    achievementModal = false,
    repostPopup = false,
    draft
}: PostCardProps) => {
    const theme = useTheme();
    const [editorText, setEditorText] = useState<string>('');
    const [activity, setActivity] = useState<string>('');
    const [assetArea, setAssetArea] = useState<File[]>([]);
    const [isPosted, setIsposted] = useState(false);
    const [open, setOpen] = React.useState<boolean>(false);
    const [step, setStep] = React.useState<number>(0);
    const [type, setType] = useState<string>('Connections');
    const [disable, setDisable] = useState<boolean>(false);
    const [btnTitle, setBtnTitle] = useState<string>('Post');
    const [postId, setPostId] = useState('');
    const [selectedSkills, setSelectedSkills] = useState<Array<ISkillType>>([]);
    const { user } = useAuth();
    const createPost = useUserPost();
    const TypeData = [{ name: 'Connections' }, { name: 'Anyone on Jobfactor' }];
    const [showAlert, setShowAlert] = useState(false);
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState('');
    const { posts, setPosts } = usePost();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
    const showMenu = Boolean(anchorEl);
    const showMenu2 = Boolean(anchorEl2);
    const [showMentionsSuggestion, setShowMentionsSuggestion] = useState(false);
    const [selectedMentions, setSelectedMentions] = useState<string[]>([]);
    const [mention, setMention] = useState('');
    const [showEmoji, setShowEmoji] = useState(false);
    const [chosenEmoji, setChosenEmoji] = React.useState(null);
    const [showBorder, setShowBorder] = useState(false);
    const [drafts, setDrafts] = useState<any[]>([]);
    const [hashtags, setHashtags] = useState<{ id: string; display: string }[]>(
        [
            { id: '1', display: '#mentalhealth' },
            { id: '2', display: '#focus' },
            { id: '3', display: '#frontend' },
            { id: '4', display: '#backend' }
        ]
    );
    useEffect(() => {
        setTimeout(() => setShow(!show), 100);
    }, []);

    const [savedText, setSavedText] = useState<any>('');
    const [savedActivity, setSavedActivity] = useState<any>('');
    const [savedAssets, setSavedAssets] = useState<any>(false);
    const [savedDrafts, setSavedDrafts] = useState<any[]>([]);

    useEffect(() => {
        if (achievementModal) {
            const storedText = localStorage.getItem('editorText');
            const storedActivity = localStorage.getItem('activity');
            const storedAssets = localStorage.getItem('images');
            const storedDrafts = localStorage.getItem('drafts');

            if (storedText) {
                setSavedText(JSON.parse(storedText) ?? '');
                setEditorText(savedText!);
            }
            if (storedDrafts) {
                setSavedDrafts(JSON.parse(storedDrafts) ?? []);
                setDrafts(savedDrafts);
            }
            if (storedActivity) {
                setSavedActivity(JSON.parse(storedActivity) ?? '');
                setActivity(savedActivity!);
            }
            if (savedText && savedActivity && !draft && step !== 2) setStep(1);
            else {
            }
        }
    }, [show, step]);

    const saveAsDraft = () => {
        const formData = {
            userId:
                user?.primaryProfile === PrimaryProfileType.Professional
                    ? user?.professionalProfile?.userId ?? ''
                    : user?.id ?? '',
            senderId:
                user?.primaryProfile === PrimaryProfileType.Professional
                    ? user?.professionalProfile?.userId ?? ''
                    : user?.id ?? '',
            profile: user?.primaryProfile ?? '',
            id: data?.id ?? null,
            images: assetArea,
            activity: activity,
            post: {
                id: '',
                message: editorText,
                createdAt: dayjs().format()
            },
            professionalRating: {
                professionalProfile: {
                    firstName:
                        user?.professionalProfile?.firstName ??
                        user?.primaryCompanyProfile?.companyName,
                    lastName: user?.professionalProfile?.lastName ?? '',
                    photoUrl: user?.professionalProfile?.photoUrl
                }
            }
        };
        setDrafts((prevDrafts) => [...prevDrafts, formData]);
    };

    useEffect(() => {
        if (isPosted) saveAsDraft();
    }, [isPosted]);
    // useEffect(() => {
    //     setEditor
    // }, [isPosted]);

    useEffect(() => {
        if (editorText.length !== 0 && activity.length !== 0) {
            localStorage.setItem('editorText', JSON.stringify(editorText));
            localStorage.setItem('activity', JSON.stringify(activity));

            // const filesData = assetArea.map((file) => ({
            //     name: file.name,
            //     type: file.type,
            //     size: file.size,
            //     lastModified: file.lastModified,
            //     webkitRelativePath: file.webkitRelativePath,
            //     content: ''
            // }));
            // localStorage.setItem('images', JSON.stringify(filesData));
        }
    }, [editorText, activity, assetArea]);

    interface MentionData {
        id: string;
        display: string;
        imageUrl: string;
        score: number;
        currentEmployment: {
            companyName: string;
            employmentLevel: string;
        };
        profileId: string;
    }

    const { data: mentionsDetailData, refetch } = useGetSearchTerm(
        mention,
        user?.professionalProfile?.userId ?? ''
    );

    const MentionData = mentionsDetailData?.map((user?: any) => ({
        id: user?.id,
        display: user?.term,
        imageUrl: user?.imageURl,
        score: user?.score,
        currentEmployment: user?.currentEmployment
    }));

    const handleShowEmoji = () => {
        setShowEmoji(!showEmoji);
    };

    const onEmojiClick = (emojiObject: any) => {
        setChosenEmoji(emojiObject);
        // setEditorText((chosenEmoji as any).emoji);
        setEditorText((prev: any) => prev + emojiObject.emoji);
        // setShowEmoji(false);
        // logger.log("chosen emoji", chosenEmoji);
        // logger.log("emoji", (chosenEmoji as any).emoji);
    };

    // logger.log('mention=>', mention)
    // logger.log('real data=>', mentionsDetailData? mentionsDetailData : '' )
    // logger.log('user', user?.professionalProfile?.userId ?? '')
    // logger.log('all mentions=>', allMentions)
    // logger.log('all data display and id=>', MentionData );

    const handleChipDelete = (mention: string) => {
        setSelectedMentions((prevMentions) =>
            prevMentions.filter((m) => m !== mention)
        );
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickMenu = (event: any) => {
        setAnchorEl2(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl2(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const reset = () => {
        setEditorText('');
        setAssetArea([]);
        setStep(0);
        setActivity('');
    };

    // useEffect(() =>{
    //     getAllMentions()
    // }, [])

    useEffect(() => {
        if (mention) {
            refetch();
        }
    }, [mention]);

    const inputref = React.useRef<HTMLInputElement>(null);

    const onChangeImage = (e: any) => {
        let temp: any = [];
        for (let i = 0; i < e.target.files?.length; i++) {
            temp.push(e.target.files[i]);
        }
        setAssetArea([...assetArea, ...temp]);
    };

    const onPostData = (step: number) => {
        let formData = new FormData();
        formData.append(
            'userId',
            user?.primaryProfile === PrimaryProfileType.Professional
                ? user?.professionalProfile?.userId ?? ''
                : user?.id ?? ''
        );
        formData.append(
            'senderId',
            user?.primaryProfile === PrimaryProfileType.Professional
                ? user?.professionalProfile?.userId ?? ''
                : user?.id ?? ''
        );
        formData.append('primaryProfile', user?.primaryProfile ?? '');
        formData.append('message', editorText);
        formData.append('repostId', data?.id ?? null);
        assetArea?.map((file: File) => formData.append('files', file));
        createPost.mutate(formData, {
            onSuccess: (res) => {
                console.log('res', res);
                setPostId(res?.post?.id);
                if (
                    window.location.pathname === '/' ||
                    (window.location.pathname === '/activities' &&
                        res?.post?.user?.id === user?.professionalProfile?.id)
                ) {
                    const post = populateData(res?.post, res?.comment);
                    setPosts([post, ...posts]);
                }
                if (step === 2) {
                    setIsposted(true);
                    setStep(3);
                } else {
                    hideModal('success');
                    reset();
                }
            },
            onError: (err) => {
                console.log('err', err);
                setMessage('Error occured please try again!');
                setShowAlert(true);
            }
        });
    };

    const populateData = (x: any, comment: ICommentType): IFeedItem => {
        let feed: IFeedItem = {
            id: x?.id,
            profileId: x?.user?.id,
            profileImage: x?.user?.photoUrl,
            fullName: x?.user?.firstName ?? '' + x?.user?.lastName ?? '',
            jobTitle: x?.user?.currentEmployment?.jobTitle ?? '',
            description: x?.message ?? '',
            views: 0,
            likes: 0,
            createdAt: x?.createdAt,
            commentsCount: 0,
            comments: [comment],
            shares: x?.repostCount,
            images: x?.photos ?? [],
            isAccountVerified: true
        };
        if (x?.repost) {
            feed.repost = populateData(x.repost as IFeedItem, comment);
        }
        return feed;
    };
    const onDeletImage = async (item: any) => {
        const filterdata = assetArea?.filter((x: any) => x !== item);
        setAssetArea(filterdata);
    };

    const getAchievement = (key: string) => {
        let achievement = '';
        switch (key) {
            case 'Completed a course':
                achievement = 'course';
                break;
            case 'Learnt a new skill':
                achievement = 'new skill';
                break;
            case 'Worked on a new project':
                achievement = 'new project';
                break;
            case 'Received an award':
                achievement = 'award';
                break;
            default:
                achievement = '';
                break;
        }
        return achievement;
    };
    const ChildModal = () => {
        return (
            <React.Fragment>
                <Modal
                    open={open}
                    onClose={() => {
                        setOpen(false);
                    }}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <Box sx={{ ...childmodalstyle }}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                // border: 0,
                                borderBottomWidth: 1,
                                borderBottomColor: '#CCC',
                                borderStyle: 'solid',
                                marginBottom: 2,
                                paddingBottom: 1
                            }}
                        >
                            <Box>
                                <h4 style={{ marginTop: 0, marginBottom: 0 }}>
                                    Choose audience
                                </h4>
                            </Box>
                            <Box>
                                <div
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        setOpen(false);
                                    }}
                                >
                                    <CancelIcon style={{ color: '#808080' }} />
                                </div>
                            </Box>
                        </Box>
                        {TypeData?.map((item, index) => {
                            return (
                                <Box
                                    key={index + 2}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        border: 0,
                                        borderBottomWidth: 1,
                                        borderBottomColor: '#CCC',
                                        borderStyle: 'solid',
                                        marginBottom: 2,
                                        paddingBottom: 1,
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                        setType(item?.name);
                                        setOpen(false);
                                    }}
                                >
                                    <Box>
                                        {index === 0 ? (
                                            <UserGroup />
                                        ) : (
                                            <Global />
                                        )}
                                    </Box>
                                    <Box>
                                        <h4
                                            style={{
                                                marginTop: 0,
                                                marginBottom: '7px',
                                                paddingLeft: 10,
                                                fontWeight: '400'
                                            }}
                                        >
                                            {item?.name}
                                        </h4>
                                    </Box>
                                </Box>
                            );
                        })}
                    </Box>
                </Modal>
            </React.Fragment>
        );
    };

    useEffect(() => {
        if (step === 1) {
            setBtnTitle('Continue');
            if (!activity) {
                setDisable(true);
            } else {
                setDisable(false);
            }
            return;
        }
        if (!editorText) {
            setDisable(true);
        } else {
            setDisable(false);
        }
        setBtnTitle('Post');
    }, [step, activity, editorText, achievementModal]);

    useEffect(() => {
        if (achievementModal) {
            if (draft && editorText && activity) setStep(4);
            else setStep(1);
        } else {
            setStep(0);
        }
    }, [achievementModal, showModal]);

    return (
        <Dialog
            open={showModal}
            onClose={() => {
                reset();
                hideModal();
            }}
            fullScreen={isMobile}
            sx={{
                '& .MuiPaper-root': {
                    width: {
                        lg: 800,
                        sm: 600,
                        xs: step === 3 ? '343px' : '100%'
                    },
                    background:
                        step === 4 ? { sm: '#FFF', xs: '#FCFBF8' } : '#f2f2f2',
                    height: {
                        xs: step === 3 ? 'fit-content' : undefined,
                        sm: step === 3 || step === 2 ? 'fit-content' : '600px'
                    },
                    borderRadius: step === 3 ? '8px' : 0
                },
                '& ::-webkit-scrollbar': {
                    display: 'none'
                }
            }}
            maxWidth="lg"
        >
            <Box
                width="100%"
                display={{ xs: step === 3 ? 'none' : 'block', sm: 'none' }}
                bgcolor="#fff"
            >
                <JobfactorAppBar />
            </Box>
            <Box
                sx={{
                    height: '100%',
                    pt: { xs: step === 3 ? 0 : '72px', sm: 0 }
                    // bgcolor: '#fff'
                }}
            >
                {step === 3 ? (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            padding: {
                                lg: '32px 136px',
                                sm: '32px 60px',
                                xs: '12px'
                            },
                            gap: '32px',
                            height: '100%',
                            justifyContent: 'center',
                            bgcolor: '#fff',
                            width: '100%'
                        }}
                    >
                        <AchievementMedal />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: '8px',
                                maxWidth: '405px'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    fontWeight: 600,
                                    fontSize: '20px',
                                    lineHeight: '28px',
                                    textAlign: 'center',
                                    color: '#23282B'
                                }}
                            >
                                Achievement Posted
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    color: '#808080',
                                    letterSpacing: '0.005em',
                                    textAlign: 'center'
                                }}
                            >
                                You can now request up to 5 reviews from your
                                connections who witnessed this achievement.
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px',
                                width: '100%',
                                flexDirection: { sm: 'row', xs: 'column' }
                            }}
                        >
                            <Button
                                variant="contained"
                                sx={{
                                    p: '14px 16px',
                                    fontSize: 14,
                                    minWidth: '227px'
                                }}
                                onClick={() => {
                                    setStep(4);
                                }}
                            >
                                Request review
                            </Button>
                            <Button
                                variant="contained"
                                sx={{
                                    p: '14px 36px',
                                    background: '#FCFBF8',
                                    border: '1px solid #05668D',
                                    fontSize: 14,
                                    minWidth: '227px',
                                    borderRadius: '8px',
                                    color: '#05668D',
                                    '&:hover': {
                                        background: '#05668D',
                                        color: 'white'
                                    }
                                }}
                                onClick={() => {
                                    hideModal();
                                    localStorage.setItem(
                                        'drafts',
                                        JSON.stringify(drafts)
                                    );
                                }}
                            >
                                Skip for now
                            </Button>
                        </Box>
                    </Box>
                ) : step === 4 ? (
                    <>
                        <AchievementSkillsPopup
                            handleClose={() => {
                                reset();
                                hideModal();
                            }}
                            postId={postId}
                            selectedSkills={selectedSkills}
                            setSelectedSkills={setSelectedSkills}
                            achievementModal={achievementModal}
                        />
                    </>
                ) : (
                    <>
                        <Box
                            sx={{
                                p: { sm: step > 2 ? 0 : 3, xs: '16px' },
                                backgroundColor: '#FFF',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                border: 0,
                                borderBottomWidth: 1,
                                borderBottomColor: '#CCC',
                                borderStyle: 'solid',
                                marginBottom: { xs: 1, sm: 0 },
                                gap: { xs: '12px', sm: '20px' }
                            }}
                        >
                            {step === 2 && (
                                <Box
                                    sx={{
                                        display: { sm: 'flex', xs: 'none' },
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => setStep((prev) => prev - 1)}
                                >
                                    <ArrowBack />
                                </Box>
                            )}
                            <Box
                                sx={{
                                    display: { sm: 'none', xs: 'flex' },
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    if (step === 0 || step === 1) {
                                        reset();
                                        hideModal();
                                    } else setStep((prev) => prev - 1);
                                }}
                            >
                                <ClearIcon />
                            </Box>
                            <Typography
                                flex={1}
                                sx={{
                                    display: 'flex',
                                    justifyContent: { xs: 'flex-start' }
                                }}
                                variant="titleLargeSemiBold"
                            >
                                {repostPopup
                                    ? 'Repost'
                                    : step === 2 && !achievementModal
                                    ? 'Create a Post'
                                    : achievementModal
                                    ? 'Post an achievement'
                                    : 'Create a Post'}
                            </Typography>
                            <Box
                                sx={{
                                    display: { sm: 'block', xs: 'none' },
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    reset();
                                    hideModal();
                                    if (achievementModal) {
                                        if (draft) {
                                            localStorage.removeItem(
                                                'editorText'
                                            );
                                            localStorage.removeItem('activity');
                                        } else {
                                            localStorage.setItem(
                                                'editorText',
                                                JSON.stringify(editorText)
                                            );
                                            localStorage.setItem(
                                                'activity',
                                                JSON.stringify(activity)
                                            );
                                        }
                                    }
                                }}
                            >
                                <CloseXIcon />
                            </Box>
                            {step !== 1 && (
                                <Button
                                    variant="contained"
                                    sx={{
                                        display: { sm: 'none', xs: 'block' },
                                        width: { xs: '74px', sm: 'auto' },
                                        fontSize: 14,
                                        py: 1
                                    }}
                                    onClick={() => {
                                        if (step === 0 || step === 2) {
                                            onPostData(step);
                                        } else {
                                            setStep((prev) => prev + 1);
                                        }
                                    }}
                                    disabled={disable}
                                >
                                    {btnTitle}
                                </Button>
                            )}
                        </Box>
                        <Box
                            sx={{
                                p: step > 2 ? 0 : { sm: 3, xs: 0 },
                                pt: '8px !important',
                                bgcolor: '#fff'
                            }}
                        >
                            <Box
                                sx={{
                                    background: repostPopup ? '#FCFBF8' : '#fff'
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'flex-start',
                                        p:
                                            step > 2
                                                ? 0
                                                : {
                                                      sm: 0,
                                                      xs: achievementModal
                                                          ? '16px'
                                                          : 3
                                                  },
                                        pt: { xs: 0 }
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: '16px',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Avatar
                                            sx={{
                                                height: { sm: 65, xs: 56 },
                                                width: { sm: 65, xs: 56 }
                                            }}
                                            src={
                                                user?.primaryProfile ===
                                                PrimaryProfileType.Professional
                                                    ? user?.professionalProfile
                                                          ?.photoUrl
                                                    : user
                                                          ?.primaryCompanyProfile
                                                          ?.photoUrl
                                            }
                                            alt="profile"
                                        />
                                        <Box
                                            sx={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                setOpen(true);
                                            }}
                                        >
                                            <h4 style={{ margin: 0 }}>
                                                {user?.primaryProfile ===
                                                PrimaryProfileType.Professional
                                                    ? `${user?.professionalProfile?.firstName} ${user?.professionalProfile?.lastName}`
                                                    : user
                                                          ?.primaryCompanyProfile
                                                          ?.companyName}
                                            </h4>
                                            <Box
                                                onClick={handleClickMenu}
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '4px',
                                                    bgcolor: '#F2F2F2',
                                                    borderWidth: 1,
                                                    borderColor: '#d8d8d8',
                                                    borderStyle: 'solid',
                                                    padding: '6px 8px',
                                                    borderRadius: 0.5,
                                                    marginTop: '5px'
                                                }}
                                            >
                                                {type === 'Connections' ? (
                                                    <UserGroup />
                                                ) : (
                                                    <Global />
                                                )}
                                                <Typography
                                                    sx={{
                                                        margin: 0,
                                                        fontSize: '12px',
                                                        color: '#23282B'
                                                    }}
                                                >
                                                    {type}
                                                </Typography>
                                                <ArrowDropIcon />
                                            </Box>
                                        </Box>
                                    </Box>
                                    {/* {step === 2 && (
                                        <Box
                                            sx={{
                                                alignItems: 'center',
                                                display: {
                                                    sm: 'flex',
                                                    xs: 'none'
                                                },
                                                color: '#05668D',
                                                height: 'fit-content',
                                                borderRadius: '5px',
                                                background: '#F2F2F2',
                                                padding: '10px 20px',
                                                fontSize: '14px',
                                                gap: '8px',
                                                fontWeight: '600'
                                            }}
                                        >
                                            <IconButton
                                                edge="start"
                                                color="inherit"
                                                aria-label="photo"
                                                sx={{ padding: 0 }}
                                                disabled={true}
                                            >
                                                <Schedule />
                                            </IconButton>
                                            Schedule
                                        </Box>
                                    )} */}
                                    {/* <Box
                                        sx={{
                                            alignItems: 'center',
                                            display: { sm: 'flex', xs: 'none' },
                                            color: '#05668D',
                                            height: 'fit-content',
                                            borderRadius: '5px',
                                            background: '#F2F2F2',
                                            padding: '10px 20px',
                                            fontSize: '14px',
                                            gap: '8px',
                                            fontWeight: '600'
                                        }}
                                    >
                                        <IconButton
                                            edge="start"
                                            color="inherit"
                                            aria-label="photo"
                                            sx={{ padding: 0 }}
                                            disabled={true}
                                        >
                                            <Schedule />
                                        </IconButton>
                                        Schedule
                                    </Box> */}
                                </Box>
                                {step === 1 ? (
                                    <Box
                                        sx={{
                                            marginTop: {
                                                sm: '40px',
                                                xs: '10px'
                                            },
                                            px: {
                                                sm: 0,
                                                xs: achievementModal
                                                    ? '16px'
                                                    : 0
                                            }
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                color: '#23282B',
                                                fontWeight: '600',
                                                fontSize: {
                                                    sm: '20px',
                                                    xs: '16px'
                                                },
                                                lineHeight: '28px'
                                            }}
                                        >
                                            What is the achievement?
                                        </Typography>
                                        <Box
                                            sx={{
                                                mt: '20px',
                                                p: '6px 16px',
                                                background:
                                                    activity ===
                                                    'Completed a course'
                                                        ? '#D8D8D8'
                                                        : '#f2f2f2',
                                                borderBottom: `1px solid ${
                                                    activity ===
                                                    'Completed a course'
                                                        ? '#EDEDED'
                                                        : '#D8D8D8'
                                                }`
                                            }}
                                        >
                                            <FormControlLabel
                                                sx={{
                                                    '& svg': {
                                                        width: '20px',
                                                        height: '20px'
                                                    },
                                                    '& .MuiFormControlLabel-label':
                                                        {
                                                            fontFamily:
                                                                'Open Sans',
                                                            fontWeight:
                                                                activity ===
                                                                'Completed a course'
                                                                    ? 600
                                                                    : 400,
                                                            letterSpacing:
                                                                '0.005em',
                                                            color:
                                                                activity ===
                                                                'Completed a course'
                                                                    ? '#23282B'
                                                                    : '#808080'
                                                        }
                                                }}
                                                value={activity}
                                                control={
                                                    <Radio
                                                        sx={{
                                                            color:
                                                                activity ===
                                                                'Completed a course'
                                                                    ? '#05668D !important'
                                                                    : '#AAAAAA !important'
                                                        }}
                                                        checked={
                                                            activity ===
                                                            'Completed a course'
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={(e) => {
                                                            if (
                                                                e.target.checked
                                                            ) {
                                                                setActivity(
                                                                    'Completed a course'
                                                                );
                                                            }
                                                        }}
                                                    />
                                                }
                                                label="Completed a course"
                                            />
                                        </Box>
                                        <Box
                                            sx={{
                                                p: '6px 16px',
                                                background:
                                                    activity ===
                                                    'Learnt a new skill'
                                                        ? '#D8D8D8'
                                                        : '#f2f2f2',
                                                borderBottom: `1px solid ${
                                                    activity ===
                                                    'Learnt a new skill'
                                                        ? '#EDEDED'
                                                        : '#D8D8D8'
                                                }`
                                            }}
                                        >
                                            <FormControlLabel
                                                sx={{
                                                    '& svg': {
                                                        width: '20px',
                                                        height: '20px'
                                                    },
                                                    '& .MuiFormControlLabel-label':
                                                        {
                                                            fontFamily:
                                                                'Open Sans',
                                                            fontWeight:
                                                                activity ===
                                                                'Learnt a new skill'
                                                                    ? 600
                                                                    : 400,
                                                            letterSpacing:
                                                                '0.005em',
                                                            color:
                                                                activity ===
                                                                'Learnt a new skill'
                                                                    ? '#23282B'
                                                                    : '#808080'
                                                        }
                                                }}
                                                value={activity}
                                                control={
                                                    <Radio
                                                        sx={{
                                                            color:
                                                                activity ===
                                                                'Learnt a new skill'
                                                                    ? '#05668D !important'
                                                                    : '#AAAAAA !important'
                                                        }}
                                                        checked={
                                                            activity ===
                                                            'Learnt a new skill'
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={(e) => {
                                                            if (
                                                                e.target.checked
                                                            ) {
                                                                setActivity(
                                                                    'Learnt a new skill'
                                                                );
                                                            }
                                                        }}
                                                    />
                                                }
                                                label="Learnt a new skill"
                                            />
                                        </Box>
                                        <Box
                                            sx={{
                                                p: '6px 16px',
                                                background:
                                                    activity ===
                                                    'Worked on a new project'
                                                        ? '#D8D8D8'
                                                        : '#f2f2f2',
                                                borderBottom: `1px solid ${
                                                    activity ===
                                                    'Worked on a new project'
                                                        ? '#EDEDED'
                                                        : '#D8D8D8'
                                                }`
                                            }}
                                        >
                                            <FormControlLabel
                                                sx={{
                                                    '& svg': {
                                                        width: '20px',
                                                        height: '20px'
                                                    },
                                                    '& .MuiFormControlLabel-label':
                                                        {
                                                            fontFamily:
                                                                'Open Sans',
                                                            fontWeight:
                                                                activity ===
                                                                'Worked on a new project'
                                                                    ? 600
                                                                    : 400,
                                                            letterSpacing:
                                                                '0.005em',
                                                            color:
                                                                activity ===
                                                                'Worked on a new project'
                                                                    ? '#23282B'
                                                                    : '#808080'
                                                        }
                                                }}
                                                value={activity}
                                                control={
                                                    <Radio
                                                        sx={{
                                                            color:
                                                                activity ===
                                                                'Worked on a new project'
                                                                    ? '#05668D !important'
                                                                    : '#AAAAAA !important'
                                                        }}
                                                        checked={
                                                            activity ===
                                                            'Worked on a new project'
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={(e) => {
                                                            if (
                                                                e.target.checked
                                                            ) {
                                                                setActivity(
                                                                    'Worked on a new project'
                                                                );
                                                            }
                                                        }}
                                                    />
                                                }
                                                label="Worked on a new project"
                                            />
                                        </Box>
                                        <Box
                                            sx={{
                                                p: '6px 16px',
                                                background:
                                                    activity ===
                                                    'Received an award'
                                                        ? '#D8D8D8'
                                                        : '#f2f2f2',
                                                borderBottom: `1px solid ${
                                                    activity ===
                                                    'Received an award'
                                                        ? '#EDEDED'
                                                        : '#D8D8D8'
                                                }`
                                            }}
                                        >
                                            <FormControlLabel
                                                sx={{
                                                    '& svg': {
                                                        width: '20px',
                                                        height: '20px'
                                                    },
                                                    '& .MuiFormControlLabel-label':
                                                        {
                                                            fontFamily:
                                                                'Open Sans',
                                                            fontWeight:
                                                                activity ===
                                                                'Received an award'
                                                                    ? 600
                                                                    : 400,
                                                            letterSpacing:
                                                                '0.005em',
                                                            color:
                                                                activity ===
                                                                'Received an award'
                                                                    ? '#23282B'
                                                                    : '#808080'
                                                        }
                                                }}
                                                value={activity}
                                                control={
                                                    <Radio
                                                        sx={{
                                                            color:
                                                                activity ===
                                                                'Received an award'
                                                                    ? '#05668D !important'
                                                                    : '#AAAAAA !important'
                                                        }}
                                                        checked={
                                                            activity ===
                                                            'Received an award'
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={(e) => {
                                                            if (
                                                                e.target.checked
                                                            ) {
                                                                setActivity(
                                                                    'Received an award'
                                                                );
                                                            }
                                                        }}
                                                    />
                                                }
                                                label="Received an award"
                                            />
                                        </Box>
                                    </Box>
                                ) : step === 2 ? (
                                    <Box
                                        sx={{
                                            // border: '1px solid #D9D9DA',
                                            pt: '16px',
                                            px: { xs: '16px', sm: 0 }
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                gap: '24px'
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#23282B',
                                                    fontWeight: '600',
                                                    letterSpacing: '0.005em'
                                                }}
                                            >
                                                Tell us about your{' '}
                                                {getAchievement(activity)}
                                            </Typography>
                                            {/* <Button
                                                variant="outlined"
                                                sx={{
                                                    display: {
                                                        sm: 'block',
                                                        xs: 'none'
                                                    },
                                                    fontFamily: 'Open Sans',
                                                    color: '#05668D',
                                                    fontSize: '14px',
                                                    fontWeight: '600',
                                                    letterSpacing: '0.001em',
                                                    lineHeight: '20px',
                                                    p: '14px 16px',
                                                    background: '#F2F2F2',
                                                    border: '1px solid #05668D',
                                                    borderRadius: '8px',
                                                    maxWidth: '212px',
                                                    '&:hover': {
                                                        background: '#05668D',
                                                        color: 'white'
                                                    }
                                                }}
                                                onClick={() => setStep(1)}
                                            >
                                                Change your achievement
                                            </Button> */}
                                        </Box>
                                        <Box
                                            sx={{
                                                mt: '12px'
                                            }}
                                        >
                                            <Box
                                                mb="16px"
                                                width="100%"
                                                height={{
                                                    xs: '200px',
                                                    sm:
                                                        assetArea?.length === 0
                                                            ? '300px'
                                                            : '169px'
                                                }}
                                            >
                                                {/* <InputBase
                                                    rows={5}
                                                    placeholder="What’s happening?"
                                                    sx={{
                                                        backgroundColor:
                                                            '#FFFAF1',
                                                        width: '100%',
                                                        overflowY: 'auto',
                                                        p: '12px 16px',
                                                        fontFamily: 'open sans',
                                                        color: '#23282B',
                                                        border:'2px solid yellow',
                                                        '& textarea': {
                                                            height: `${
                                                                repostPopup
                                                                    ? 150
                                                                    : 200
                                                            }px !important`
                                                        }
                                                    }}
                                                    
                                                    multiline
                                                    
                                                /> */}
                                                <MentionsInput
                                                    style={{
                                                        backgroundColor:
                                                            '#f2f2f2',
                                                        width: '100%',
                                                        p: '12px 16px',
                                                        height: '100%',
                                                        overflow: 'hidden',
                                                        input: {
                                                            overflow: 'auto',
                                                            // height: '100%',

                                                            padding: '12px',
                                                            border: 'none',
                                                            margin: 0
                                                        },
                                                        '&:focus': {
                                                            border: '3px solid #49B6FF'
                                                        },
                                                        fontFamily: 'open sans',
                                                        color: '#23282B',
                                                        border: showBorder
                                                            ? '3px solid #49B6FF'
                                                            : 'none',
                                                        borderRadius: '8px',
                                                        '& textarea': {
                                                            height: `${
                                                                repostPopup
                                                                    ? 150
                                                                    : 200
                                                            }px !important`
                                                        }
                                                    }}
                                                    onFocus={() =>
                                                        setShowBorder(true)
                                                    }
                                                    onBlur={() =>
                                                        setShowBorder(false)
                                                    }
                                                    spellCheck={false}
                                                    value={editorText}
                                                    onChange={(e: any) => {
                                                        const newValue =
                                                            e.target.value;
                                                        setEditorText(newValue);

                                                        const mentions =
                                                            newValue.match(
                                                                /@([^\s@]+)/g
                                                            );

                                                        if (mentions) {
                                                            for (const mention of mentions) {
                                                                const mentionKeyword =
                                                                    mention
                                                                        .substring(
                                                                            1
                                                                        )
                                                                        .toLowerCase(); // Remove "@" and convert to lowercase
                                                                // logger.log(
                                                                //     'mention key =>',
                                                                //     mentionKeyword
                                                                // );
                                                                setMention(
                                                                    mentionKeyword
                                                                );
                                                            }
                                                        }
                                                    }}
                                                >
                                                    <Mention
                                                        style={{
                                                            borderRadius: '4px',
                                                            background:
                                                                '#B2D0DC',
                                                            Width: '100px',
                                                            height: '24px',
                                                            justifyContent:
                                                                'center',
                                                            alignItems:
                                                                'center',
                                                            fontFamily:
                                                                'Open Sans',
                                                            fontSize: '16px',
                                                            fontStyle: 'normal',
                                                            fontWeight: '600',
                                                            lineHeight: '24px',
                                                            letterSpacing:
                                                                '0.08px'
                                                        }}
                                                        trigger="@"
                                                        data={MentionData}
                                                        // onSearchChange={(e: any, trigger: any, value: any) => provideData(value, (data) => {logger.log('so=>', a, trigger, value)})}
                                                        // renderSuggestion={(s:any)=>logger.log("render", s)}
                                                        // displayTransform={(url) => `@${url}`}
                                                        renderSuggestion={(
                                                            MentionData?: any
                                                        ) => (
                                                            <MentionsDetails
                                                                key={
                                                                    MentionData?.id
                                                                }
                                                                // user={MentionData}
                                                                user={{
                                                                    imageUrl:
                                                                        MentionData?.imageUrl,
                                                                    term: MentionData?.display,
                                                                    score: MentionData?.score,
                                                                    currentEmployment:
                                                                        MentionData?.currentEmployment
                                                                }}
                                                            />
                                                        )}
                                                    />

                                                    <Mention
                                                        trigger="#"
                                                        style={{
                                                            borderRadius: '4px',
                                                            background:
                                                                '#FFC0CB',
                                                            width: '100px',
                                                            height: '24px',
                                                            padding: '2px 8px',
                                                            fontFamily:
                                                                'Open Sans',
                                                            fontSize: '16px',
                                                            fontStyle: 'normal',
                                                            fontWeight: '600',
                                                            lineHeight: '24px',
                                                            letterSpacing:
                                                                '0.08px'
                                                        }}
                                                        data={hashtags}
                                                        renderSuggestion={(
                                                            hashtags?: any
                                                        ) => (
                                                            <HashTag
                                                                key={
                                                                    hashtags?.id
                                                                }
                                                                display={
                                                                    hashtags?.display
                                                                }
                                                            />
                                                        )}
                                                    />
                                                </MentionsInput>
                                            </Box>
                                            {assetArea?.length > 0 && (
                                                <Box
                                                    sx={{
                                                        height: '200px',
                                                        // pt: '12px',
                                                        // background: '#fff',
                                                        width: '100%'
                                                    }}
                                                >
                                                    <ImageList
                                                        sx={{
                                                            width: '100%',
                                                            p: 0,
                                                            m: 0,
                                                            maxHeight: '100%'

                                                            // transform:
                                                            //     'translateZ(0)'
                                                        }}
                                                        // rowHeight={200}
                                                        gap={12}
                                                    >
                                                        {assetArea?.map(
                                                            (
                                                                item: any,
                                                                index: number
                                                            ) => {
                                                                const cols =
                                                                    item?.featured
                                                                        ? 2
                                                                        : 1;
                                                                const rows =
                                                                    item?.featured
                                                                        ? 2
                                                                        : 1;
                                                                return (
                                                                    <ImageListItem
                                                                        sx={{
                                                                            objectFit:
                                                                                'cover',
                                                                            overflow:
                                                                                'hidden'
                                                                        }}
                                                                        key={
                                                                            index +
                                                                            2
                                                                        }
                                                                        cols={
                                                                            cols
                                                                        }
                                                                        rows={
                                                                            rows
                                                                        }
                                                                    >
                                                                        <img
                                                                            src={URL.createObjectURL(
                                                                                item
                                                                            )}
                                                                            alt={
                                                                                'images'
                                                                            }
                                                                            loading="lazy"
                                                                            style={{
                                                                                objectFit:
                                                                                    'cover',
                                                                                width: '100%',
                                                                                height: '200px'
                                                                                // maxWidth:
                                                                                //     '200px'
                                                                            }}
                                                                        />
                                                                        <ImageListItemBar
                                                                            sx={{
                                                                                background:
                                                                                    '#ffffff0d'
                                                                            }}
                                                                            position="top"
                                                                            actionIcon={
                                                                                <div
                                                                                    style={{
                                                                                        cursor: 'pointer',
                                                                                        padding: 10
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        onDeletImage(
                                                                                            item
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <CancelImageIcon />
                                                                                </div>
                                                                            }
                                                                            actionPosition="left"
                                                                        />
                                                                    </ImageListItem>
                                                                );
                                                            }
                                                        )}
                                                    </ImageList>
                                                </Box>
                                            )}
                                            <Box
                                                sx={{
                                                    background: '#f2f2f2',
                                                    border: '2px dashed #05668D',
                                                    borderRadius: '16px',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    p: '12px 20px',
                                                    mt: '10px'
                                                }}
                                            >
                                                <Button
                                                    variant="contained"
                                                    startIcon={<Camera />}
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#05668D',
                                                        fontSize: '14px',
                                                        fontWeight: '600',
                                                        letterSpacing:
                                                            '0.0035em',
                                                        lineHeight: '20px',
                                                        p: '10px 16px',
                                                        background: '#fff',
                                                        borderRadius: '8px',
                                                        width: 'auto',
                                                        m: 'auto'
                                                    }}
                                                    onClick={() => {
                                                        inputref.current?.click();
                                                    }}
                                                >
                                                    Add Photo
                                                </Button>
                                                <input
                                                    type="file"
                                                    ref={inputref}
                                                    multiple
                                                    accept="image/jpg, image/jpeg, image/png"
                                                    name="image"
                                                    id="imageFile"
                                                    onChange={(e) => {
                                                        onChangeImage(e);
                                                    }}
                                                    style={{ display: 'none' }}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                ) : (
                                    <Box
                                        px={{
                                            xs: repostPopup ? '16px' : 0,
                                            sm: 0
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                backgroundColor: repostPopup
                                                    ? '#f2f2f2'
                                                    : '#fff',
                                                gap: '12px',
                                                marginTop: {
                                                    sm: '16px',
                                                    xs: '12px'
                                                },
                                                border: repostPopup
                                                    ? '3px solid #49B6FF'
                                                    : 'none',
                                                height: {
                                                    xs: 'fit-content',
                                                    sm: '350px'
                                                },
                                                borderRadius: repostPopup
                                                    ? '8px'
                                                    : 0,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-between',
                                                overflow: 'auto',
                                                px: repostPopup
                                                    ? '12px'
                                                    : { xs: '16px', sm: 0 },
                                                py: { xs: '12px' }
                                            }}
                                        >
                                            <Box width="100%">
                                                {/* <InputBase
                                                    rows={5}
                                                    placeholder="What’s happening?"
                                                    sx={{
                                                        backgroundColor:
                                                            '#FFFAF1',
                                                        width: '100%',
                                                        overflowY: 'auto',
                                                        p: '12px 16px',
                                                        fontFamily: 'open sans',
                                                        color: '#23282B',
                                                        border:'2px solid yellow',
                                                        '& textarea': {
                                                            height: `${
                                                                repostPopup
                                                                    ? 150
                                                                    : 200
                                                            }px !important`
                                                        }
                                                    }}
                                                    
                                                    multiline
                                                    
                                                /> */}
                                                <MentionsInput
                                                    style={{
                                                        backgroundColor:
                                                            '#f2f2f2',
                                                        width: '100%',
                                                        p: repostPopup
                                                            ? 0
                                                            : '12px 16px',
                                                        height:
                                                            assetArea?.length ===
                                                                0 && repostPopup
                                                                ? '169px'
                                                                : assetArea?.length ===
                                                                  0
                                                                ? '300px'
                                                                : '169px',
                                                        overflow: 'hidden',
                                                        input: {
                                                            overflow: 'auto',
                                                            height: '100%',

                                                            padding: '12px',
                                                            borderColor:
                                                                'transparent',
                                                            margin: 0,
                                                            '&:focus': {
                                                                border: '3px solid transparent'
                                                            }
                                                        },
                                                        fontFamily: 'open sans',
                                                        color: '#23282B',
                                                        border: repostPopup
                                                            ? '3px solid transparent'
                                                            : showBorder
                                                            ? '3px solid #49B6FF'
                                                            : 'none',
                                                        // borderBottom:
                                                        //     repostPopup
                                                        //         ? '3px solid transparent'
                                                        //         : '3px solid #49B6FF',
                                                        borderRadius: '8px',
                                                        '& textarea': {
                                                            height: `${
                                                                repostPopup
                                                                    ? 150
                                                                    : 200
                                                            }px !important`
                                                        }
                                                    }}
                                                    onFocus={() =>
                                                        setShowBorder(true)
                                                    }
                                                    onBlur={() =>
                                                        setShowBorder(false)
                                                    }
                                                    placeholder="What’s happening?"
                                                    spellCheck={false}
                                                    value={editorText}
                                                    onChange={(e: any) => {
                                                        const newValue =
                                                            e.target.value;
                                                        setEditorText(newValue);

                                                        const mentions =
                                                            newValue.match(
                                                                /@([^\s@]+)/g
                                                            );

                                                        if (mentions) {
                                                            for (const mention of mentions) {
                                                                const mentionKeyword =
                                                                    mention
                                                                        .substring(
                                                                            1
                                                                        )
                                                                        .toLowerCase(); // Remove "@" and convert to lowercase
                                                                // logger.log(
                                                                //     'mention key =>',
                                                                //     mentionKeyword
                                                                // );
                                                                setMention(
                                                                    mentionKeyword
                                                                );
                                                            }
                                                        }
                                                    }}
                                                >
                                                    <Mention
                                                        style={{
                                                            borderRadius: '4px',
                                                            background:
                                                                '#B2D0DC',
                                                            Width: '100px',
                                                            height: '24px',
                                                            justifyContent:
                                                                'center',
                                                            alignItems:
                                                                'center',
                                                            fontFamily:
                                                                'Open Sans',
                                                            fontSize: '16px',
                                                            fontStyle: 'normal',
                                                            fontWeight: '600',
                                                            lineHeight: '24px',
                                                            letterSpacing:
                                                                '0.08px'
                                                        }}
                                                        trigger="@"
                                                        data={MentionData}
                                                        // onSearchChange={(e: any, trigger: any, value: any) => provideData(value, (data) => {logger.log('so=>', a, trigger, value)})}
                                                        // renderSuggestion={(s:any)=>logger.log("render", s)}
                                                        // displayTransform={(url) => `@${url}`}
                                                        renderSuggestion={(
                                                            MentionData?: any
                                                        ) => (
                                                            <MentionsDetails
                                                                key={
                                                                    MentionData?.id
                                                                }
                                                                // user={MentionData}
                                                                user={{
                                                                    imageUrl:
                                                                        MentionData?.imageUrl,
                                                                    term: MentionData?.display,
                                                                    score: MentionData?.score,
                                                                    currentEmployment:
                                                                        MentionData?.currentEmployment
                                                                }}
                                                            />
                                                        )}
                                                    />

                                                    <Mention
                                                        trigger="#"
                                                        style={{
                                                            borderRadius: '4px',
                                                            background:
                                                                '#FFC0CB',
                                                            width: '100px',
                                                            height: '24px',
                                                            padding: '2px 8px',
                                                            fontFamily:
                                                                'Open Sans',
                                                            fontSize: '16px',
                                                            fontStyle: 'normal',
                                                            fontWeight: '600',
                                                            lineHeight: '24px',
                                                            letterSpacing:
                                                                '0.08px'
                                                        }}
                                                        data={hashtags}
                                                        renderSuggestion={(
                                                            hashtags?: any
                                                        ) => (
                                                            <HashTag
                                                                key={
                                                                    hashtags?.id
                                                                }
                                                                display={
                                                                    hashtags?.display
                                                                }
                                                            />
                                                        )}
                                                    />
                                                </MentionsInput>
                                            </Box>
                                            {repostPopup ? (
                                                <Box
                                                    sx={
                                                        {
                                                            // border: '1px solid #C5C5C5',
                                                            // borderRadius: '12px',
                                                            // ml: {
                                                            //     lg: '32px',
                                                            //     sm: '20px',
                                                            //     xs: '16px'
                                                            // },
                                                            // mr: {
                                                            //     lg: '32px',
                                                            //     sm: '20px',
                                                            //     xs: '16px'
                                                            // },
                                                            // '& .feedItem': {
                                                            //     mb:{xs: '12px', sm: 0}
                                                            // }
                                                        }
                                                    }
                                                >
                                                    <FeedItem
                                                        feed={data}
                                                        repostPopup={true}
                                                        reposted={true}
                                                        postUp={true}
                                                    />
                                                </Box>
                                            ) : null}
                                            {assetArea?.length > 0 && (
                                                <Box
                                                    sx={{
                                                        height: '200px',
                                                        // pt: '12px',
                                                        // background: '#fff',
                                                        width: '100%'
                                                    }}
                                                >
                                                    <ImageList
                                                        sx={{
                                                            width: '100%',
                                                            p: 0,
                                                            m: 0,
                                                            maxHeight: '100%'

                                                            // transform:
                                                            //     'translateZ(0)'
                                                        }}
                                                        // rowHeight={200}
                                                        gap={12}
                                                    >
                                                        {assetArea?.map(
                                                            (
                                                                item: any,
                                                                index: number
                                                            ) => {
                                                                const cols =
                                                                    item?.featured
                                                                        ? 2
                                                                        : 1;
                                                                const rows =
                                                                    item?.featured
                                                                        ? 2
                                                                        : 1;
                                                                return (
                                                                    <ImageListItem
                                                                        sx={{
                                                                            objectFit:
                                                                                'cover',
                                                                            overflow:
                                                                                'hidden'
                                                                        }}
                                                                        key={
                                                                            index +
                                                                            2
                                                                        }
                                                                        cols={
                                                                            cols
                                                                        }
                                                                        rows={
                                                                            rows
                                                                        }
                                                                    >
                                                                        <img
                                                                            src={URL.createObjectURL(
                                                                                item
                                                                            )}
                                                                            alt={
                                                                                'images'
                                                                            }
                                                                            loading="lazy"
                                                                            style={{
                                                                                objectFit:
                                                                                    'cover',
                                                                                width: '100%',
                                                                                height: '200px'
                                                                                // maxWidth:
                                                                                //     '200px'
                                                                            }}
                                                                        />
                                                                        <ImageListItemBar
                                                                            sx={{
                                                                                background:
                                                                                    '#ffffff0d'
                                                                            }}
                                                                            position="top"
                                                                            actionIcon={
                                                                                <div
                                                                                    style={{
                                                                                        cursor: 'pointer',
                                                                                        padding: 10
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        onDeletImage(
                                                                                            item
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <CancelImageIcon />
                                                                                </div>
                                                                            }
                                                                            actionPosition="left"
                                                                        />
                                                                    </ImageListItem>
                                                                );
                                                            }
                                                        )}
                                                    </ImageList>
                                                </Box>
                                            )}
                                            {/* {repostPopup ? (
                                                <Box
                                                    sx={{
                                                        border: '1px solid #C5C5C5',
                                                        borderRadius: '12px',
                                                        ml: {
                                                            lg: '32px',
                                                            sm: '20px',
                                                            xs: '16px'
                                                        },
                                                        mr: {
                                                            lg: '32px',
                                                            sm: '20px',
                                                            xs: '16px'
                                                        },
                                                        '& .feedItem': {
                                                            mb: 0
                                                        }
                                                    }}
                                                >
                                                    <FeedItem
                                                        feed={data}
                                                                        repostPopup={true}
                                                                        reposted={true}
                                                    />
                                                </Box>
                                            ) : null} */}
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    paddingLeft: '15px',
                                    p:
                                        step > 2
                                            ? 0
                                            : { sm: 0, xs: step === 1 ? 0 : 3 },
                                    marginTop: '16px',
                                    pt: { xs: 0 }
                                }}
                            >
                                {step > 0 ? null : (
                                    // <Button
                                    //     startIcon={<HatIcon color="#747474" />}
                                    //     variant="contained"
                                    //     sx={{
                                    //         width: 'auto',
                                    //         fontFamily: 'Open Sans',
                                    //         fontWeight: 600,
                                    //         fontSize: '14px',
                                    //         lineHeight: '20px',
                                    //         letterSpacing: '0.001em',
                                    //         color: '#808080',
                                    //         display: { sm: 'flex', xs: 'none' }
                                    //     }}
                                    //     disabled={true}
                                    // >
                                    //     Achievements
                                    // </Button>
                                    <Box sx={{ display: 'flex', gap: '8px' }}>
                                        <IconButton
                                            edge="start"
                                            color="inherit"
                                            aria-label="photo"
                                            onClick={handleShowEmoji}
                                            sx={{
                                                p: '6px 8px',
                                                bgcolor: '#f2f2f2',
                                                m: 0,
                                                borderRadius: '8px',
                                                cursor: 'pointer',
                                                position: 'relative'
                                            }}
                                        >
                                            <EmojiIcon />
                                            {showEmoji ? (
                                                <Box
                                                    sx={{
                                                        position: 'absolute',
                                                        // mt:'1rem',
                                                        bottom: '100%',
                                                        left: 0,
                                                        zIndex: 2
                                                    }}
                                                >
                                                    <Picker
                                                        width={width}
                                                        height={height}
                                                        onEmojiClick={
                                                            onEmojiClick
                                                        }
                                                    />
                                                </Box>
                                            ) : (
                                                <></>
                                            )}
                                        </IconButton>
                                        <IconButton
                                            edge="start"
                                            color="inherit"
                                            aria-label="photo"
                                            sx={{
                                                p: '6px 8px',
                                                bgcolor: '#f2f2f2',
                                                borderRadius: '5px',
                                                m: 0
                                            }}
                                            onClick={() => {
                                                inputref.current?.click();
                                            }}
                                        >
                                            <UploadImage />
                                            <input
                                                type="file"
                                                ref={inputref}
                                                multiple
                                                accept="image/jpg, image/jpeg, image/png"
                                                name="image"
                                                id="imageFile"
                                                onChange={(e) => {
                                                    onChangeImage(e);
                                                }}
                                                style={{ display: 'none' }}
                                            />
                                        </IconButton>
                                        {/* <IconButton
                                            edge="start"
                                            color="inherit"
                                            aria-label="photo"
                                            sx={{
                                                p: '6px 8px',
                                                bgcolor: '#f2f2f2',
                                                m: 0,
                                                borderRadius: '8px',
                                                cursor: 'pointer'
                                            }}
                                        >
                                            <UploadVideo />
                                        </IconButton> */}
                                        {/* <IconButton
                                            edge="start"
                                            color="inherit"
                                            aria-label="photo"
                                            sx={{
                                                p: 0,
                                                m: 0,
                                                borderRadius: '5px',
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => setStep(1)}
                                        >
                                            <HatIcon />
                                        </IconButton> */}
                                        {/* <IconButton
                                            edge="start"
                                            color="inherit"
                                            aria-label="photo"
                                            onClick={handleShowEmoji}
                                            sx={{
                                                p: 0,
                                                m: 0,
                                                borderRadius: '5px',
                                                cursor: 'pointer',
                                                color: '#05668D',
                                                position: 'relative'
                                            }}
                                        >
                                            <EmojiEmotionsIcon />
                                            <Box sx={{
                                                position: 'absolute',
                                                top: '100%', // Position below the emoji icon
                                                left: 0,
                                                zIndex: 2, // Ensure the picker is above other content  
                                            }}>
                                                {showEmoji 
                                                    ? <Picker 
                                                    onEmojiClick={onEmojiClick} /> 
                                                    : <></>}
                                            </Box>
                                            {showEmoji ? (
                                                <Box
                                                    sx={{
                                                        position: 'absolute',
                                                        // mt:'1rem',
                                                        bottom: '100%',
                                                        left: 0,
                                                        zIndex: 2
                                                    }}
                                                >
                                                    <Picker
                                                        width={width}
                                                        height={height}
                                                        onEmojiClick={
                                                            onEmojiClick
                                                        }
                                                    />
                                                </Box>
                                            ) : (
                                                <></>
                                            )}
                                        </IconButton> */}
                                        {/* <IconButton
                                            edge="start"
                                            color="inherit"
                                            aria-label="photo"
                                            onClick={handleClick}
                                            sx={{
                                                p: 0,
                                                m: 0,
                                                borderRadius: '5px',
                                                cursor: 'pointer'
                                            }}
                                        >
                                            <UploadMore />
                                        </IconButton> */}
                                    </Box>
                                )}
                                <Button
                                    variant="contained"
                                    sx={{
                                        display: {
                                            xs: step === 1 ? 'block' : 'none',
                                            sm: 'block'
                                        },
                                        width:
                                            step === 1 || step === 2
                                                ? '100%'
                                                : {
                                                      sm: '123px',
                                                      xs: '100%'
                                                  },
                                        py: 1,
                                        mx: { xs: '16px', sm: 0 },
                                        mb: '8px'
                                    }}
                                    onClick={() => {
                                        if (
                                            (step === 0 || step === 2) &&
                                            editorText
                                        ) {
                                            onPostData(step);

                                            localStorage.removeItem(
                                                'editorText'
                                            );
                                            localStorage.removeItem('activity');
                                            localStorage.removeItem('isPosted');
                                        } else {
                                            setStep((prev) => prev + 1);
                                        }
                                    }}
                                    disabled={disable}
                                >
                                    {btnTitle}
                                </Button>
                                {/* {step === 2 ? (
                                    <Button
                                        variant="outlined"
                                        sx={{
                                            display: {
                                                sm: 'none',
                                                xs: 'block'
                                            },
                                            fontFamily: 'Open Sans',
                                            color: '#05668D',
                                            fontSize: '14px',
                                            fontWeight: '600',
                                            letterSpacing: '0.001em',
                                            lineHeight: '20px',
                                            p: '14px 16px',
                                            background: '#F2F2F2',
                                            border: '1px solid #05668D',
                                            borderRadius: '8px',
                                            '&:hover': {
                                                background: '#05668D',
                                                color: 'white'
                                            }
                                        }}
                                        onClick={() => setStep(1)}
                                    >
                                        Change your achievement
                                    </Button>
                                ) : null} */}
                            </Box>
                        </Box>
                    </>
                )}
                <SnackAlert
                    open={showAlert}
                    handleClose={() => setShowAlert(false)}
                    message={message}
                    type={'error'}
                />

                <Menu
                    anchorEl={anchorEl}
                    open={showMenu}
                    onClose={handleClose}
                    sx={{
                        '& .MuiMenu-paper': {
                            width: 220,
                            bordeRadius: '8px !important',
                            boxShadow:
                                '8px 8px 20px 0px rgba(0, 0, 0, 0.07), -8px 4px 20px 0px rgba(0, 0, 0, 0.07)'
                        },
                        '& ul': {
                            p: 0
                        },
                        '& li': {
                            p: '8px 16px'
                        }
                    }}
                >
                    <MenuItem>
                        <ListItemText
                            sx={{
                                '& span': {
                                    fontSize: 14,
                                    fontWeight: 600
                                }
                            }}
                        >
                            Photo Library
                        </ListItemText>
                        <ImageGallery />
                    </MenuItem>
                    <MenuItem>
                        <ListItemText
                            sx={{
                                '& span': {
                                    fontSize: 14,
                                    fontWeight: 600
                                }
                            }}
                        >
                            Take Photo
                        </ListItemText>
                        <UploadImage />
                    </MenuItem>
                    <MenuItem>
                        <ListItemText
                            sx={{
                                '& span': {
                                    fontSize: 14,
                                    fontWeight: 600
                                }
                            }}
                        >
                            Choose file
                        </ListItemText>
                        <Folder />
                    </MenuItem>
                </Menu>
                <Menu
                    anchorEl={anchorEl2}
                    open={showMenu2}
                    onClose={handleCloseMenu}
                    sx={{
                        '& .MuiMenu-paper': {
                            width: 400,
                            bordeRadius: '8px !important',
                            boxShadow:
                                '8px 8px 20px 0px rgba(0, 0, 0, 0.07), -8px 4px 20px 0px rgba(0, 0, 0, 0.07)'
                        },
                        '& ul': {
                            p: 0
                        },
                        '& li': {
                            p: '8px 16px'
                        }
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            py: '12px',
                            px: '16px'
                        }}
                    >
                        <Box>
                            <h4 style={{ marginTop: 0, marginBottom: 0 }}>
                                Choose audience
                            </h4>
                        </Box>
                        <Box>
                            <div
                                style={{ cursor: 'pointer' }}
                                onClick={handleCloseMenu}
                            >
                                <CancelIcon style={{ color: '#808080' }} />
                            </div>
                        </Box>
                    </Box>
                    {TypeData?.map((item, index) => {
                        return (
                            <MenuItem
                                key={index + 2}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    border: 0,
                                    borderBottomWidth: 1,
                                    borderBottomColor: '#CCC',
                                    borderStyle: 'solid',
                                    marginBottom: 2,
                                    paddingBottom: 1,
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    setType(item?.name);
                                    handleCloseMenu();
                                }}
                            >
                                <Box>
                                    {index === 0 ? <UserGroup /> : <Global />}
                                </Box>
                                <Box>
                                    <h4
                                        style={{
                                            marginTop: 0,
                                            marginBottom: '7px',
                                            paddingLeft: 10,
                                            fontWeight: '400'
                                        }}
                                    >
                                        {item?.name}
                                    </h4>
                                </Box>
                            </MenuItem>
                        );
                    })}
                </Menu>
            </Box>
        </Dialog>
    );
};

export default PostCards;

const mentionStyle = {
    control: {
        fontSize: 16,
        fontWeight: 'normal',
        backgroundColor: '#FFFAF1',
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        color: '#23282B'
    },

    '&multiLine': {
        control: {
            fontFamily: 'open sans',
            minHeight: '300px'
        },
        highlighter: {
            padding: '12px 16px',
            border: '1px solid transparent'
        },
        input: {
            padding: '12px 16px',
            border: 0
        }
    },

    '&singleLine': {
        display: 'inline-block',
        width: 180,

        highlighter: {
            padding: 1,
            border: '2px inset transparent'
        },
        input: {
            padding: 1,
            border: '2px inset'
        }
    }

    // suggestions: {
    //     list: {
    //         backgroundColor: 'white',
    //         border: '1px solid rgba(0,0,0,0.15)',
    //         fontSize: 14,
    //     },
    //     item: {
    //         padding: '5px 15px',
    //         borderBottom: '1px solid rgba(0,0,0,0.15)',
    //         '&focused': {
    //             backgroundColor: '#cee4e5',
    //         },
    //     },
    // },
};
