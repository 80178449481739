import { Box, Stack, Typography } from '@mui/material';
import { MessagingIcon } from '../icons/MessagingIcon';

export const ConversationPlaceholder = () => {
    return (
        <Stack
            display={{ xs: 'none', lg: 'flex' }}
            sx={{
                justifyContent: 'center',
                alignItems: 'center',
                bgcolor: '#FFF',
                width: '100%',
                height: '100%'
            }}
        >
            <MessagingIcon />
            <Typography sx={{ fontSize: '20px' }}>
                Send and receive messages
            </Typography>
        </Stack>
    );
};
