import React from 'react'

const ExpandLess = () => {
  return (
      <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
          <path
              d="M14.9331 6.81641H9.74146H5.06646C4.26646 6.81641 3.86646 7.78307 4.43312 8.34974L8.74979 12.6664C9.44146 13.3581 10.5665 13.3581 11.2581 12.6664L12.8998 11.0247L15.5748 8.34974C16.1331 7.78307 15.7331 6.81641 14.9331 6.81641Z"
              fill="#808080"
          />
      </svg>
  );
}

export default ExpandLess
