import React from 'react';

function PasswordFormIcon() {
    return (
        <>
            <svg
                width="45"
                height="24"
                viewBox="0 0 45 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M12.0004 22.7575C10.9104 22.7575 9.83039 22.4375 8.98039 21.8075L4.68039 18.5975C3.54039 17.7475 2.65039 15.9675 2.65039 14.5575V7.1175C2.65039 5.5775 3.78039 3.9375 5.23039 3.3975L10.2204 1.5275C11.2104 1.1575 12.7704 1.1575 13.7604 1.5275L18.7504 3.3975C20.2004 3.9375 21.3304 5.5775 21.3304 7.1175V14.5475C21.3304 15.9675 20.4404 17.7375 19.3004 18.5875L15.0004 21.7975C14.1704 22.4375 13.0904 22.7575 12.0004 22.7575ZM10.7504 2.9375L5.76039 4.8075C4.91039 5.1275 4.16039 6.2075 4.16039 7.1275V14.5575C4.16039 15.5075 4.83039 16.8375 5.58039 17.3975L9.88039 20.6075C11.0304 21.4675 12.9704 21.4675 14.1304 20.6075L18.4304 17.3975C19.1904 16.8275 19.8504 15.5075 19.8504 14.5575V7.1175C19.8504 6.2075 19.1004 5.1275 18.2504 4.7975L13.2604 2.9275C12.5804 2.6875 11.4204 2.6875 10.7504 2.9375Z"
                    fill="#808080"
                />
                <path
                    d="M14.1498 14.1928C13.9598 14.1928 13.7698 14.1228 13.6198 13.9728L9.36984 9.72281C9.07984 9.43281 9.07984 8.95281 9.36984 8.66281C9.65984 8.37281 10.1398 8.37281 10.4298 8.66281L14.6798 12.9128C14.9698 13.2028 14.9698 13.6828 14.6798 13.9728C14.5298 14.1128 14.3398 14.1928 14.1498 14.1928Z"
                    fill="#808080"
                />
                <path
                    d="M9.85004 14.2319C9.66004 14.2319 9.47004 14.1619 9.32004 14.0119C9.03004 13.7219 9.03004 13.2419 9.32004 12.9519L13.57 8.70188C13.86 8.41188 14.34 8.41188 14.63 8.70188C14.92 8.99187 14.92 9.47188 14.63 9.76188L10.38 14.0119C10.24 14.1619 10.04 14.2319 9.85004 14.2319Z"
                    fill="#808080"
                />
                <line x1="44.5" y1="4.5" x2="44.5" y2="19.5" stroke="#D9D9D9" />
            </svg>
        </>
    );
}

export default PasswordFormIcon;
