import {
    Box,
    Button,
    IconButton,
    Menu,
    MenuItem,
    Typography
} from '@mui/material';
import moment from 'moment';
import { useQueryClient } from 'react-query';
import { useRespondConnectionRequest } from '../../../utils/hooks/api/connections/useRespondConnectionRequest';
import { QueryKeys } from '../../../utils/hooks/api/QueryKey';
import { useAuth } from '../../../utils/context/AuthContext';
import { Responsiveness } from '../../../theme/overrides';
import { useState } from 'react';
import MoreIcon from '../../../assets/icons/MoreIcon';
import { useNavigate } from 'react-router-dom';

const UserActions = (props: PropTypes) => {
    const { isMobile, isTablet } = Responsiveness();
    const { user, tab, title } = props;
    const { user: loggedInUser } = useAuth();
    const navigate = useNavigate()
    const queryClient = useQueryClient();
    const respondRequest = useRespondConnectionRequest();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleResponse = (respondStatus: string) => {
        const data = {
            sourceUserId: user?.destinationUser?.userId
                ? user?.destinationUser?.userId
                : user?.sourceUser?.userId
                ? user?.sourceUser?.userId
                : loggedInUser?.professionalProfile?.id ?? '',
            destinationUserId: user?.userId ?? user?.destinationUser?.userId,
            connectionLinkId: user.connectionLinkId,
            connectionRequestId: user.connectionRequestId ?? user?.id,
            respondStatus
        };
        respondRequest.mutate(data, {
            onSuccess: (res) => {
                queryClient.invalidateQueries(QueryKeys.RetrieveConnections);
                queryClient.invalidateQueries(
                    QueryKeys.RetrieveConnectionRequestSent
                );
                queryClient.invalidateQueries(
                    QueryKeys.RetrieveConnectionRequestReceived
                );
                console.log('onSuccess', res);
            },
            onError: (err) => {
                console.log('onError', err);
            }
        });
    };
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'flex-end',
                flexDirection: 'column',
                // WebkitJustifyContent: 'center',
                gap: '16px',
                width: 'fit-content',
            }}
        >
            {isTablet ? null : (
                <Typography
                    sx={{
                        fontSize: '16px',
                        fontFamily: 'Open Sans',
                        fontWeight: '600',
                        color: '#808080',
                        mb: '10px'
                    }}
                >
                    {moment(user.createdAt).fromNow()}
                </Typography>
            )}
            <Box display='flex' alignItems='center' gap='16px'>
            {isTablet ? null : (
                <Button
                    variant="outlined"
                    sx={{
                        borderRadius: '8px',
                        padding: '10px 36px',
                        fontSize: '14px',
                        fontWeight: '700',
                        textTransform: 'capitalize',
                        boxShadow: 'none',
                        color: '#05668D',
                        width: '120px',
                        minWidth: '120px'
                    }}
                    onClick={() => handleResponse('REJECTED')}
                >
                    {title === 'ConnectionPage'
                        ? 'Unfollow'
                        : tab === 'sent'
                        ? 'Withdraw'
                        : 'Ignore'}
                </Button>
            )}
            {title === 'PendingConnectionPage' ? (
                <>
                    {isMobile ? null : !isTablet ? null : (
                        <Button
                            variant="outlined"
                            sx={{
                                borderRadius: '8px',
                                padding: '10px 36px',
                                fontSize: '14px',
                                fontWeight: '700',
                                textTransform: 'capitalize',
                                boxShadow: 'none',
                                color: '#05668D',
                                width: '120px',
                                minWidth: '120px'
                            }}
                            onClick={() => handleResponse('REJECTED')}
                        >
                            {tab === 'sent' ? 'Withdraw' : 'Ignore'}
                        </Button>
                    )}
                    {tab === 'received' && !isMobile ? (
                        <Button
                            sx={{
                                borderRadius: '8px',
                                background: '#05668D',
                                padding: '10px 36px',
                                border: '1px solid #05668D',
                                color: '#FFFFFF',
                                fontSize: '14px',
                                fontWeight: '700',
                                textTransform: 'capitalize',
                                width: '120px',
                                minWidth: '120px',
                                boxShadow: 'none',
                                textDecoration: 'none'
                            }}
                            onClick={() => handleResponse('ACCEPTED')}
                        >
                            Accept
                        </Button>
                    ) : null}

                    {isMobile ? (
                        <IconButton onClick={handleClick}>
                            <MoreIcon />
                        </IconButton>
                    ) : null}
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                            style: {
                                maxHeight: 150,
                                width: '173px'
                            }
                        }}
                    >
                        {tab === 'received' ? (
                            <MenuItem
                                onClick={() => {
                                    handleResponse('ACCEPTED');
                                    handleClose();
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        textTransform: 'capitalize',
                                        textDecoration: 'none',
                                        width: '100%',
                                        color: '#808080'
                                    }}
                                >
                                    Accept
                                </Typography>
                            </MenuItem>
                        ) : null}
                        <MenuItem
                            onClick={() => {
                                handleResponse('REJECTED');
                                handleClose();
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    fontWeight: '400',
                                    textTransform: 'capitalize',
                                    textDecoration: 'none',
                                    width: '100%',
                                    color: '#808080'
                                }}
                            >
                                {tab === 'sent' ? 'Withdraw' : 'Ignore'}
                            </Typography>
                        </MenuItem>
                    </Menu>
                </>
            ) : (
                <>
                    {isMobile ? null : (
                        <Button
                            sx={{
                                borderRadius: '8px',
                                padding: '10px 36px',
                                border: '1px solid #05668D',
                                background: '#05668D',
                                fontSize: '14px',
                                fontWeight: '700',
                                textTransform: 'capitalize',
                                boxShadow: 'none',
                                width: '120px',
                                minWidth: '120px',
                                color: '#FFFFFF'
                                }}
                                onClick={() => navigate('/messages', {
                                    state: {
                                        user: user
                                    }
                                })}
                        >
                            Message
                        </Button>
                    )}
                    {isTablet ? (
                        <IconButton onClick={handleClick}>
                            <MoreIcon />
                        </IconButton>
                    ) : null}
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                            style: {
                                maxHeight: 150,
                                width: '173px'
                            }
                        }}
                    >
                        {isMobile && (
                            <MenuItem onClick={handleClose}>
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        textTransform: 'capitalize',
                                        textDecoration: 'none',
                                        width: '100%',
                                        color: '#808080'
                                    }}
                                >
                                    Message
                                </Typography>
                            </MenuItem>
                        )}
                        <MenuItem
                            onClick={() => {
                                handleResponse('REJECTED');
                                handleClose();
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    fontWeight: '400',
                                    textTransform: 'capitalize',
                                    textDecoration: 'none',
                                    width: '100%',
                                    color: '#808080'
                                }}
                            >
                                {title === 'ConnectionPage'
                                    ? 'Unfollow'
                                    : tab === 'sent'
                                    ? 'Withdraw'
                                    : 'Ignore'}
                            </Typography>
                        </MenuItem>
                    </Menu>
                </>
            )}
        </Box>
        </Box>
    );
};
interface PropTypes {
    user: User;
    tab?: string;
    title: string;
}
type User = {
    createdAt: number;
    receiver: {
        userId: string;
    };
    sender: {
        userId: string;
    };
    destinationUser: {
        userId: string;
    };
    sourceUser: {
        userId: string;
    };
    connectionLinkId: string;
    connectionRequestId: string;
    userId: string;
    id: string;
};
export default UserActions;
