import {
    Typography,
    Button,
    InputBase,
    InputLabel,
    InputAdornment,
    CircularProgress
} from '@mui/material';
import Box from '@mui/material/Box';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import EmailFormIcon from '../../assets/icons/EmailFormIcon';
import OnBoardingSidePanel from '../OnBoarding/OnBoardingSidePanel';
import ForgotPasswordSteps from './ForgotPasswordSteps/ForgotPasswordSteps';
import React, { useState } from 'react';
import {
    updateAction,
    updateStep,
    clearAction
} from '../Accounts/Professional/CreationSteps/updateAction';
import { useStateMachine } from 'little-state-machine';
import SecretCodeIcon from '../../assets/icons/SecretCodeIcon';
import { Numbers } from '@mui/icons-material';
import useValidateCode from '../../utils/hooks/api/authentication/useValidateCode';
import SnackAlert, { SnackAlertType } from '../../components/Snackbar';
import axios, { AxiosError } from 'axios';

interface IResetPasswordForm {
    code: Number;
}

function ResetPassword() {
    let navigate = useNavigate();
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState<SnackAlertType>('info');
    const { email } = useParams();
    const { actions, state } = useStateMachine({
        updateAction,
        updateStep,
        clearAction
    });

    const {
        control,
        handleSubmit,
        formState,
        register,
        clearErrors,
        setError
    } = useForm<IResetPasswordForm>();
    const { isDirty, isValid, errors } = formState;
    const verifyCode = useValidateCode();
    const onSubmit: SubmitHandler<IResetPasswordForm> = async (data) => {
        setLoading(true);
        actions.updateAction(data);
        if (data.code !== undefined) {
            verifyCode.mutate(
                {
                    emailAddress: email ?? '',
                    verificationCode: String(data.code)
                },
                {
                    onSuccess: (res) => {
                        console.log('object', res);
                        if (res.isSuccess) {
                            navigate(
                                `/forgot-password/new/${email}/${data.code}`
                            );
                            return;
                        }
                        setType('error');
                        setMessage('Error occured please try again!');
                        setShowAlert(true);
                        setLoading(false);
                    },
                    onError: (error: AxiosError) => {
                        setLoading(false);
                        if (axios.isAxiosError(error)) {
                            setType('error');
                            setMessage(
                                error?.response?.data?.message ??
                                'Error occured please try again!'
                            );
                            setShowAlert(true);
                            return;
                        }
                        setType('error');
                        setMessage('Error occured please try again!');
                        setShowAlert(true);
                        return;
                    }
                }
            );
        }
    };

    const navigateBack: React.MouseEventHandler<HTMLButtonElement> = () => {
        navigate(-1);
    };

    React.useEffect(() => {
        actions.updateStep(2);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Box
                sx={{
                    backgroundColor: { md: 'transparent', sm: '#FCFBF8', xs: 'transparent' },
                    borderRadius: '12px',
                    p: { md: 0, sm: '64px 40px', xs: 0 },
                    mt: { md: '50px', sm: '-250px', xs: 0 },
                    mx: { lg: '50px', md: '20px', sm: '77px', xs: '20px' },
                    mb: { md: 0, sm: '50px', xs: 0 }
                }}
            >
                {/* ellipsis steps */}
                <ForgotPasswordSteps />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: { xl: '70%' }
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                mt: { sm: '64px', xs: '20px' },
                                display: { sm: 'block', xs: "none" }
                            }}
                        >
                            <Typography
                                variant="headlineLargeSemiBold"
                                color="#23282B"
                                sx={{ mb: '8px' }}
                                component={'p'}
                            >
                                Verify code
                            </Typography>
                            <Typography
                                variant="titleMediumRegular"
                                color="#808080"
                                component={'p'}
                            >
                                A 6 digit verification number (OTP) has
                                been sent to your registered email
                                address. Kindly enter the OTP sent to
                                reset password
                            </Typography>
                        </Box>
                        <Box sx={{ mt: { sm: '20px', xs: '28px' }, width: '100%' }}>
                            <Box
                                sx={{
                                    position: 'relative',
                                    mb: '32px'
                                }}
                            >
                                <InputLabel
                                    sx={{
                                        color: '#808080',
                                        fontSize: { sm: '14px', xs: '12px' },
                                        position: 'absolute',
                                        top: '8px',
                                        left: '72px',
                                        zIndex: 1,
                                        fontFamily: 'Open Sans'
                                    }}
                                    htmlFor="code"
                                >
                                    Verification code
                                </InputLabel>

                                <Controller
                                    name="code"
                                    control={control}
                                    rules={{
                                        required: 'Required field',
                                        minLength: {
                                            value: 6,
                                            message:
                                                'Please enter the 6 digit code that was sent to your email'
                                        },
                                        pattern: {
                                            value: /^[0-9]{6}$/,
                                            message:
                                                'Invalid code, please make sure you enter 6 digits'
                                        }
                                    }}
                                    render={({ field }) => (
                                        <InputBase
                                            {...field}
                                            required
                                            error={!!errors.code}
                                            inputProps={{
                                                autoComplete: '',
                                                form: {
                                                    autoComplete: 'off'
                                                },
                                                inputMode: 'numeric'
                                            }}
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <SecretCodeIcon />
                                                </InputAdornment>
                                            }
                                            placeholder="******"
                                            type="text"
                                            sx={{
                                                backgroundColor:
                                                    '#FFFFFF',
                                                width: '100%',
                                                height: { sm: '70px', xs: '60px' },
                                                padding: '0px 16px',
                                                fontFamily: 'open sans',
                                                fontSize: { sm: 16, xs: 14 },
                                                color: '#23282B',
                                                borderBottom:
                                                    '1px solid',
                                                borderColor: errors.code
                                                    ? 'red'
                                                    : '#D9D9D9',
                                                mb: '2px',
                                                '& .MuiInputBase-input':
                                                {
                                                    ml: '7.5px',
                                                    position:
                                                        'relative',
                                                    top: '8px'
                                                }
                                            }}
                                        />
                                    )}
                                />
                                <Typography
                                    sx={{
                                        color: 'red',
                                        fontSize: '12px',
                                        fontFamily: 'Open Sans'
                                    }}
                                >
                                    {errors.code?.message}
                                </Typography>
                            </Box>
                        </Box>

                        {/* buttons */}
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            {loading ? (
                                <CircularProgress
                                    sx={{ color: '#05668D' }}
                                />
                            ) : (
                                <Button
                                    sx={{
                                        backgroundColor: '#05668D',
                                        color: '#FFFFFF',
                                        borderRadius: '8px',
                                        width: '100%',
                                        padding: '16px, 36px, 16px, 36px',
                                        height: { sm: '52px', xs: '42px' },
                                    }}
                                    variant="contained"
                                    disabled={!isValid}
                                    type="submit"
                                >
                                    <Typography
                                        fontSize={{ sm: 16, xs: 14 }}
                                        fontWeight={700}
                                        fontStyle={'bold'}
                                        textTransform="capitalize"
                                    >
                                        Reset Password
                                    </Typography>
                                </Button>
                            )}
                            <Button
                                variant="outlined"
                                sx={{
                                    color: '#05668D',
                                    borderRadius: '8px',
                                    width: '100%',
                                    padding: '16px, 36px, 16px, 36px',
                                    fontWeight: 600,
                                    height: { sm: '52px', xs: '42px' },
                                    fontSize: { sm: 16, xs: 14 },
                                    textTransform: 'capitalize',
                                    mt: '16px'
                                }}
                                onClick={navigateBack}
                            >
                                Back
                            </Button>
                        </Box>
                    </Box>
                </form >
            </Box>
            <SnackAlert
                open={showAlert}
                handleClose={() => setShowAlert(false)}
                message={message}
                type={type}
            />
        </>
    );
}

export default ResetPassword;
