import { useQuery } from 'react-query';
import axiosInstance from '../axiosConfig';

export interface JobItemExternalProps {
    title: string;
    company: string;
    description: string;
    location: string;
    jobType: string;
    salary: string;
    link: string;
    lastUpdated: string;
    source: string;
}

export function useGetExternalJobs(professionalProfileId: string) {
    const getExternalJobs = async (
        professionalProfileId: string
    ): Promise<JobItemExternalProps[]> => {
        const { data } = await axiosInstance.get(
            `/api/jooble/${professionalProfileId}`
        );
        if (data.type === 'error') {
            console.error(data.error);
            return [];
        }
        if (Array.isArray(data) && data.length === 0) {
            console.error('Data returned empty');
            return [];
        }
        return data;
    };
    return useQuery(['external-jobs', professionalProfileId], () =>
        getExternalJobs(professionalProfileId)
    );
}
