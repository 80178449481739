import { Box } from '@mui/material';
import { IJobItem } from './types/IJobItem';
import { useEffect, useState } from 'react';
import JobsHeader from './components/JobsHeader';
import AspiringJobs from './components/AspiringJobs';
import JobsList from './components/JobsList/JobsList';
import { useGetJobs } from '../../utils/hooks/api/jobs/useGetJobs';
import { useAuth } from '../../utils/context/AuthContext';
import { logger } from '../../utils/Helper/logger';
import { useSendEmailNotification} from '../../utils/hooks/api/email/useSendEmailNotification';
import { IEmailNotificationRequest } from '../../utils/hooks/api/email/types';
import { newJobAlert } from '../../utils/emails/email';
import { generateSubject, generateHtml } from '../../utils/emails/emailtemplates';
import axiosInstance from '../../utils/hooks/api/axiosConfig';



import { useGetExternalJobs } from '../../utils/hooks/api/jobs/useGetExternalJobs';
import MobileNav from '../../components/MobileNav/MobileNav';


function MyJobsPage() {
    const { user } = useAuth();
    const minJobFitMetric = 6;
    const { data: jobs, isFetching } = useGetJobs(
        user?.professionalProfile?.id ?? ''
    );
    const sendEmailMutation = useSendEmailNotification();
    const [filteredJobs, setFilteredJobs] = useState<IJobItem[]>([]);
    const subject = generateSubject(newJobAlert.subject)
    const html = generateHtml(newJobAlert.html, user?.professionalProfile?.firstName, user?.professionalProfile?.lastName);

    logger.log('user', user)

    const route = [
        {
            label: 'All Jobs',
            route: '/my-jobs'
        },
        {
            label: 'Saved Jobs',
            route: '/save-job'
        },
        {
            label: 'Job Preferences',
            route: '/job-preference'
        },
        {
            label: 'Applied Jobs',
            route: '/job-applied'
        },
        {
            label: 'Career Planning',
            route: '#',
            disable: true
        }
    ];

    // const handleJobAlertEmail = async () => {
    // try {
    //         await axiosInstance.post("/api/email/send-notification", {
    //             email: user?.professionalProfile?.emailAddress,
    //             subject: generateSubject(newJobAlert.subject), 
    //             firstName: user?.professionalProfile?.firstName,
    //             lastName: user?.professionalProfile?.lastName,
    //             message: generateHtml(newJobAlert.html),
    //             link: "my-jobs",
    //         });
    //     } catch (error) {
    //         console.error('Error sending connection email:', error);
    //     }
    // };

//     const sendEmailNotification = (
//         email: string,
//         subject: string,
//         html: string
//         ): (() => Promise<void>) => {
        

//         const sendNotification = async (): Promise<void> => {
//             try {
//             await sendEmailMutation.mutateAsync({
//                 email,
//                 subject,
//                 html,
//             } as IEmailNotificationRequest);
//             console.log('Email notification sent successfully.');
//             } catch (error) {
//             console.error('Error sending email notification:', error);
//             }
//         };

//         return sendNotification;
//     };

//     const sendNotification = sendEmailNotification(
//     user?.professionalProfile?.emailAddress || '',
//     subject,
//     html
//   );
//   logger.log('email =>', user?.professionalProfile?.emailAddress)
//   logger.log('subject =>', subject)
//   logger.log('html =>', html)

    // let previousJobs: (typeof jobs)[] = [];
    
    // useEffect(() => {
    //     if (jobs) {
    //         setFilteredJobs(jobs);
    //         // sendNotification();
    //         handleJobAlertEmail();
    //     } else {
    //         setFilteredJobs([]);
    //     }
    // }, [jobs]);

    // useEffect(() => {
    //     if (jobs) {
    //         setFilteredJobs(jobs);
    //         const hasNewJobs = jobs.some((job: any) => !previousJobs.includes(job));
    //         const hasEnoughNewJobs = jobs.filter((job: any) => !previousJobs.includes(job)).length >= 2;
    //         if (hasNewJobs && hasEnoughNewJobs) {
    //         handleJobAlertEmail();
    //         }
    //         previousJobs = jobs; 
    //     } else {
    //         setFilteredJobs([]);
    //     }
    // }, [jobs]);
    
    const { data: externalJobs } = useGetExternalJobs(
        user?.professionalProfile?.id ?? ''
    );

    return (
        <Box
            sx={{
                marginTop: { xs: '21px', sm: '0px' },
                pr: { sm: '20px', lg: '40px' }
            }}
        >
            <Box display={{ xs: 'block', sm: 'none' }}>
                <MobileNav list={route} />
            </Box>
            <JobsHeader title="Jobs" jobs={jobs} setJobs={setFilteredJobs} />
            <Box display={{ xs: 'none', sm: 'block', lg: 'none' }}>
                <MobileNav list={route} />
            </Box>
            <>
                <JobsList
                    title={'Recommended for you'}
                    description={'Based on your profile and search history '}
                    data={filteredJobs
                        .sort((a, b) => b.jobFitMetrics - a.jobFitMetrics)
                        ?.filter(
                            (item) => item.jobFitMetrics >= minJobFitMetric
                        )}
                    showMetrics={true}
                    showheader={true}
                    isFetching={isFetching}
                    externalJobs={externalJobs ?? []}
                />
                {/* <AspiringJobs />
                    <JobsList
                        title={'Remote opportunities'}
                        description={
                            'Because you expressed interest in remote work'
                        }
                        data={filteredJobs}
                        showMetrics={false}
                        showheader={true}
                    />
                    <JobsList
                        title={'More jobs for you'}
                        description={
                            'Based on your search history, profile and suggestions'
                        }
                        data={filteredJobs}
                        showMetrics={false}
                        showheader={true}
                    /> */}
            </>
        </Box>
    );
}

export default MyJobsPage;
