import {
    Stack,
    Box,
    Typography,
    CircularProgress,
    Avatar,
} from '@mui/material';
import SideNav from '../../components/Navigation/SideNav';
import JobfactorAppBar from '../../components/JobfactorAppBar';
import { styled } from '@mui/material/styles';
import { useAuth } from '../../utils/context/AuthContext';
import { PrimaryProfileType } from '../../utils/hooks/api/account/types';
import Footer from '../../components/Footer/index';
import { useLocation } from 'react-router-dom';
import BottomTabs from '../../components/BottomTabs';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    // marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0
    })
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
}));

const drawerWidth = 240;

function MainLayout(props: { children: React.ReactNode }) {
    const { children } = props;
    const { account, user } = useAuth();
    const location = useLocation();
    const details = {
        photoUrl: '',
        name: '',
        accountType: ''
    };

    if (account?.primaryProfile === PrimaryProfileType.Professional) {
        details.photoUrl = user?.professionalProfile?.photoUrl ?? '';
        details.name = account.professionalProfile.fullName;
        details.accountType = 'Personal Account';
    }

    if (account?.primaryProfile === PrimaryProfileType.Company) {
        details.photoUrl = user?.primaryCompanyProfile?.photoUrl ?? '';
        details.name = account.companyProfile.companyName;
        details.accountType = 'Company Account';
    }

    const getEnvironment = () => {
        switch (process.env.REACT_APP_DEPLOYMENT_ENV) {
            case 'dev':
                return 'Development Environment'
            case 'staging':
                return 'Staging Environment'
            default:
                return null
        }
    }

    return (
        // <Container
        //     maxWidth="xl"
        //     disableGutters
        //     sx={{ px: { md: undefined, xs: '40px' } }}
        // >
        <Box
            sx={{
                display: 'flex',
                gap: { lg: 0, xl: '35px' },
                px: { sm: '20px', lg: 0 },
                position: 'relative',
                ml: { lg: '40px', sm: '20px', xs: 0 },
                justifyContent: 'center',
                '&::-webkit-scrollbar': {
                    display: 'none'
                }
            }}
        >
            <JobfactorAppBar />
            <Stack
                minWidth={{ lg: '248px', xs: '120px' }}
                width={{ lg: '248px', xs: '120px' }}
                height={{ lg: '815px', xs: '100%' }}
                mt={{ lg: '84px', xs: '84px' }}
                position="relative"
                alignSelf={'start'}
                display={{ sm: 'block', xs: 'none' }}
            >
                <Box
                    position="fixed"
                    width={{ lg: '248px', xs: '120px' }}
                    display="flex"
                    flexDirection="column"
                    sx={{ gap: '12px' }}
                >
                    <Box
                        sx={{
                            overflow: 'auto',
                            minHeight: '158px',
                            width: '100%',
                            backgroundColor: '#FFFFFF',
                            display: { lg: 'flex', xs: 'none' },
                            flexDirection: 'column',
                            py: '16px',
                            alignItems: 'center'
                        }}
                    >
                        {account?.sub ? (
                            <>
                                <Box>
                                    <Avatar
                                        sx={{
                                            height: 64,
                                            width: 64
                                        }}
                                        src={details.photoUrl}
                                        alt="profile"
                                    />
                                </Box>

                                <Typography
                                    sx={{ mt: '12px' }}
                                    color={'#23282B'}
                                    fontSize={16}
                                    fontFamily={'open sans'}
                                    fontWeight={700}
                                >
                                    {details.name}
                                </Typography>
                                <Typography
                                    color={'#808080'}
                                    fontSize={14}
                                    fontFamily={'open sans'}
                                    fontWeight={400}
                                >
                                    {details.accountType}
                                </Typography>

                                {
                                    <Typography
                                        color={'#000'}
                                        fontSize={14}
                                        fontFamily={'open sans'}
                                        fontWeight={700}
                                        sx={{
                                            textTransform: 'capitalize'
                                        }}
                                    >
                                        {getEnvironment()}
                                    </Typography>
                                }
                            </>
                        ) : (
                            <CircularProgress sx={{ color: '#05668D' }} />
                        )}
                    </Box>
                    <Box
                        sx={{
                            overflow: 'auto',
                            height: { xs: '100vh', lg: '57vh', xl: '' },
                            padding: { lg: '12px', xs: 0 },
                            bgcolor: { xs: '#fff' },
                            pb: { xs: '35px', lg: '12px' },
                            '&::-webkit-scrollbar': {
                                display: 'none'
                            }
                        }}
                    >
                        <SideNav />
                    </Box>
                </Box>
            </Stack>

            <Main
                sx={{
                    overflow: { sm: 'unset', xs: 'hidden' },
                    mt: {
                        lg: location.pathname === '/' ? '53px' : '63px',
                        xs: location.pathname === '/users' ? 0 : '63px'
                    },
                    ml: { xs: '0px', sm: '8px', lg: '35px', xl: 0 },
                    pt: { sm: '24px', xs: 0 },
                    maxWidth: '1230px',
                    mb: { sm: 0, xs: '30px' }
                    // position: 'relative'
                }}
            >
                {children}
                {location.pathname === '/' ? null : <Footer />}
            </Main>
            <BottomTabs />
        </Box>
        // </Container>
    );
}

export default MainLayout;
