export const QueryKeys = {
    RetrievePosts: 'retrieve-posts',
    RetrieveAccount: 'retrieve-account',
    RetrieveConnections: 'retrieve-connections',
    RetrieveConnectionRequestById: 'retrieve-connection-request',
    RetrieveConnectionRequests: 'retrieve-connection-requests',
    RetrieveConnectionRequestSent: 'retrieve-connection-requests-sent',
    RetrieveConnectionRequestReceived: 'retrieve-connection-requests-received',
    RetrieveJobs: 'retrieve-jobs',
    RetrieveJobById: 'retrieve-job',
    RetrieveJobsByCompanyId: 'retrieve-jobs-by-companyid',
    RetrieveAllApplicants: 'retrieve-applicants',
    RetrieveAllApplicantsByCompanyId: 'retrieve-applicants-by-companyid',
    RetrieveApplicantById: 'retrieve-applicant',
    RetrieveUserInfo: 'retrieve-user-account',
    RetrieveUser: 'retrieve-user',
    RetrieveAppliedJobs: 'retrieve-applied-jobs',
    RetrieveCitiesByState: 'retrieve-cities-by-state',
    RetrieveStatesByCountry: 'retrieve-states-by-country',
    RetrieveCountries: 'retrieve-countries',
    RetrieveSavedJobsByProfessionalId: 'retrieve-saved-jobs-by-id',
    RetrieveSavedApplicantByCompanyId: 'retrieve-saved-applicants-by-id',
    RetrieveSearchTerm: 'retrieve-search',
    RetrieveProfessionalProfileById: 'retrieve-professional-profile',
    RetrieveCompanyProfileById: 'retrieve-company-profile',
    RetrieveCompleteCompanyProfile: 'retrieve-complete-company-profile',
    RetrieveSkills: 'retrieve-skills',
    ProfessionalRating: 'retrieve-professional-rating',
    ProfessionalReviewRequests: 'retrieve-professional-review-requests',
    RetrieveEducationById: 'retrieve-education-by-id',
    RetrieveEmploymentById: 'retrieve-employment-by-id',
    RetrieveRaingById: 'retrieve-Rating-by-id',
    RetrieveUserPosts: 'retrieve-user-posts',
    RetrieveRaingByProfileId: 'retrieve-rating-by-profile-id',
    RetrieveUniversity: 'retrieve-university-by-name',
    RetrieveProfessionalProfileScore: 'retrieve-professional-profile-score',
    RetrieveCompanyProfileScore: 'retrieve-company-profile-score',
    RetrieveCommentsByProfileId: 'retrieve-comments-by-profile-id',
    RetrieveCompanyById: 'retrieve-company-by-id',
    RetrieveUserPostById: 'retrieve-user-post-by-id',
    RetrieveEmploymentsByProfessionalProfileId:
        'retrieve-employments-by-professional-id',
    RetrieveAllCompaniesBySearchTerm: 'retrieve-all-companies-profiles',
    RetrieveCompanyRatingById: 'retrieve-company-rating-by-id',
    RetrieveRatedCompaniesByProfId: 'retrieve-rated-companies-by-profile-id',
    RetrieveCompanyPendingReviewRequest:
        'retrieve-company-pending-review-requests',
    RetrieveCompanyReceivedReviewRequest:
        'retrieve-company-received-review-requests',
    RetrieveCompanyReviews: 'retrieve-reviews-by-company-profId',
    RetrieveAllProfessionalProfile: 'retrieve-all-professional-profile'
};
