function Edit() {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.53803 19.5235C4.92803 19.5235 4.35803 19.3135 3.94803 18.9235C3.42803 18.4335 3.17803 17.6935 3.26803 16.8935L3.63803 13.6535C3.70803 13.0435 4.07803 12.2335 4.50803 11.7935L12.718 3.10347C14.768 0.933467 16.908 0.873467 19.078 2.92347C21.248 4.97347 21.308 7.11347 19.258 9.28347L11.048 17.9735C10.628 18.4235 9.84803 18.8435 9.23803 18.9435L6.01803 19.4935C5.84803 19.5035 5.69803 19.5235 5.53803 19.5235ZM15.928 2.91347C15.158 2.91347 14.488 3.39347 13.808 4.11347L5.59803 12.8135C5.39803 13.0235 5.16803 13.5235 5.12803 13.8135L4.75803 17.0535C4.71803 17.3835 4.79803 17.6535 4.97803 17.8235C5.15803 17.9935 5.42803 18.0535 5.75803 18.0035L8.97803 17.4535C9.26803 17.4035 9.74803 17.1435 9.94803 16.9335L18.158 8.24347C19.398 6.92347 19.848 5.70347 18.038 4.00347C17.238 3.23347 16.548 2.91347 15.928 2.91347Z"
                fill="#05668D"
            />
            <path
                d="M17.3365 10.9528C17.3165 10.9528 17.2865 10.9528 17.2665 10.9528C14.1465 10.6428 11.6365 8.27278 11.1565 5.17278C11.0965 4.76278 11.3765 4.38278 11.7865 4.31278C12.1965 4.25278 12.5765 4.53278 12.6465 4.94278C13.0265 7.36278 14.9865 9.22278 17.4265 9.46278C17.8365 9.50278 18.1365 9.87278 18.0965 10.2828C18.0465 10.6628 17.7165 10.9528 17.3365 10.9528Z"
                fill="#05668D"
            />
            <path
                d="M21 22.75H3C2.59 22.75 2.25 22.41 2.25 22C2.25 21.59 2.59 21.25 3 21.25H21C21.41 21.25 21.75 21.59 21.75 22C21.75 22.41 21.41 22.75 21 22.75Z"
                fill="#05668D"
            />
        </svg>
    );
}

export default Edit;
