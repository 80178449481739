import React from 'react';

interface IconProps {
    size?: number;
    color?: string;
}

const LinkIcon: React.FC<IconProps> = ({ size = 24, color = '#222' }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                d="M8.70656 7.29688C10.2066 8.79688 10.2066 11.2235 8.70656 12.7169C7.20656 14.2102 4.7799 14.2169 3.28656 12.7169C1.79323 11.2169 1.78656 8.79021 3.28656 7.29688"
                stroke="#23282B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M7.06062 8.94188C5.50062 7.38188 5.50062 4.84854 7.06062 3.28188C8.62062 1.71521 11.154 1.72188 12.7206 3.28188C14.2873 4.84188 14.2806 7.37521 12.7206 8.94188"
                stroke="#23282B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

export default LinkIcon;
