import { Box } from '@mui/material';
import React from 'react';

function UserIcon2(props: { isHover: boolean; isSelected: boolean; tab?: boolean }) {
    const { isHover, isSelected, tab } = props;
    return (
        <>
            <Box display={{ xs: 'none', lg: 'block' }}>
                <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill={isHover || isSelected ? '#05668D' : 'none'}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M7.63411 9.05768C7.55078 9.04935 7.45078 9.04935 7.35911 9.05768C5.37578 8.99102 3.80078 7.36602 3.80078 5.36602C3.80078 3.32435 5.45078 1.66602 7.50078 1.66602C9.54245 1.66602 11.2008 3.32435 11.2008 5.36602C11.1924 7.36602 9.61745 8.99102 7.63411 9.05768Z"
                        stroke={isHover || isSelected ? '#05668D' : '#808080'}
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M13.6747 3.33398C15.2914 3.33398 16.5914 4.64232 16.5914 6.25065C16.5914 7.82565 15.3414 9.10899 13.7831 9.16732C13.7164 9.15899 13.6414 9.15899 13.5664 9.16732"
                        stroke={isHover || isSelected ? '#05668D' : '#808080'}
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M3.46563 12.134C1.44896 13.484 1.44896 15.684 3.46563 17.0257C5.75729 18.559 9.51563 18.559 11.8073 17.0257C13.824 15.6757 13.824 13.4757 11.8073 12.134C9.52396 10.609 5.76562 10.609 3.46563 12.134Z"
                        stroke={isHover || isSelected ? '#05668D' : '#808080'}
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M15.2852 16.666C15.8852 16.541 16.4518 16.2993 16.9185 15.941C18.2185 14.966 18.2185 13.3577 16.9185 12.3827C16.4602 12.0327 15.9018 11.7993 15.3102 11.666"
                        stroke={isHover || isSelected ? '#05668D' : '#808080'}
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </Box>
            <Box display={{ xs: 'block', lg: 'none' }}>
                {(isHover || isSelected) && !tab ? (
                    <svg
                        width={tab ? '20' : '40'}
                        height={tab ? '20' : '40'}
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M15 3.33325C10.6333 3.33325 7.08334 6.88325 7.08334 11.2499C7.08334 15.5333 10.4333 18.9999 14.8 19.1499C14.9333 19.1333 15.0667 19.1333 15.1667 19.1499C15.2 19.1499 15.2167 19.1499 15.25 19.1499C15.2667 19.1499 15.2667 19.1499 15.2833 19.1499C19.55 18.9999 22.9 15.5333 22.9167 11.2499C22.9167 6.88325 19.3667 3.33325 15 3.33325Z"
                            fill="#05668D"
                        />
                        <path
                            d="M23.4667 23.5833C18.8167 20.4833 11.2333 20.4833 6.55 23.5833C4.43333 25 3.26666 26.9166 3.26666 28.9666C3.26666 31.0166 4.43333 32.9166 6.53333 34.3166C8.86666 35.8833 11.9333 36.6666 15 36.6666C18.0667 36.6666 21.1333 35.8833 23.4667 34.3166C25.5667 32.9 26.7333 31 26.7333 28.9333C26.7167 26.8833 25.5667 24.9833 23.4667 23.5833Z"
                            fill="#05668D"
                        />
                        <path
                            d="M33.3167 12.2334C33.5833 15.4668 31.2833 18.3001 28.1 18.6834C28.0833 18.6834 28.0833 18.6834 28.0667 18.6834H28.0167C27.9167 18.6834 27.8167 18.6834 27.7333 18.7168C26.1167 18.8001 24.6333 18.2834 23.5167 17.3334C25.2333 15.8001 26.2167 13.5001 26.0167 11.0001C25.9 9.65008 25.4333 8.41675 24.7333 7.36675C25.3667 7.05008 26.1 6.85009 26.85 6.78342C30.1167 6.50009 33.0333 8.93342 33.3167 12.2334Z"
                            fill="#05668D"
                        />
                        <path
                            d="M36.65 27.6501C36.5166 29.2668 35.4833 30.6668 33.75 31.6168C32.0833 32.5334 29.9833 32.9668 27.9 32.9168C29.1 31.8334 29.8 30.4834 29.9333 29.0501C30.1 26.9834 29.1167 25.0001 27.15 23.4168C26.0333 22.5334 24.7333 21.8334 23.3167 21.3168C27 20.2501 31.6333 20.9668 34.4833 23.2668C36.0167 24.5001 36.8 26.0501 36.65 27.6501Z"
                            fill="#05668D"
                        />
                    </svg>
                ) : tab ? (
                    <svg
                        width={isHover || isSelected ? '24px' : '20px'}
                        height={isHover || isSelected ? '24px' : '20px'}
                        viewBox="0 0 20 20"
                        fill={isHover || isSelected ? '#05668D' : 'none'}
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M7.63411 9.05768C7.55078 9.04935 7.45078 9.04935 7.35911 9.05768C5.37578 8.99102 3.80078 7.36602 3.80078 5.36602C3.80078 3.32435 5.45078 1.66602 7.50078 1.66602C9.54245 1.66602 11.2008 3.32435 11.2008 5.36602C11.1924 7.36602 9.61745 8.99102 7.63411 9.05768Z"
                            stroke={
                                isHover || isSelected ? '#05668D' : '#808080'
                            }
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M13.6747 3.33398C15.2914 3.33398 16.5914 4.64232 16.5914 6.25065C16.5914 7.82565 15.3414 9.10899 13.7831 9.16732C13.7164 9.15899 13.6414 9.15899 13.5664 9.16732"
                            stroke={
                                isHover || isSelected ? '#05668D' : '#808080'
                            }
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M3.46563 12.134C1.44896 13.484 1.44896 15.684 3.46563 17.0257C5.75729 18.559 9.51563 18.559 11.8073 17.0257C13.824 15.6757 13.824 13.4757 11.8073 12.134C9.52396 10.609 5.76562 10.609 3.46563 12.134Z"
                            stroke={
                                isHover || isSelected ? '#05668D' : '#808080'
                            }
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M15.2852 16.666C15.8852 16.541 16.4518 16.2993 16.9185 15.941C18.2185 14.966 18.2185 13.3577 16.9185 12.3827C16.4602 12.0327 15.9018 11.7993 15.3102 11.666"
                            stroke={
                                isHover || isSelected ? '#05668D' : '#808080'
                            }
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                ) : (
                    <svg
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M13.5 3C9.57 3 6.375 6.195 6.375 10.125C6.375 13.98 9.39 17.1 13.32 17.235C13.44 17.22 13.56 17.22 13.65 17.235C13.68 17.235 13.695 17.235 13.725 17.235C13.74 17.235 13.74 17.235 13.755 17.235C17.595 17.1 20.61 13.98 20.625 10.125C20.625 6.195 17.43 3 13.5 3Z"
                            fill="#AAAAAA"
                        />
                        <path
                            d="M21.1199 21.2248C16.9349 18.4348 10.1099 18.4348 5.89494 21.2248C3.98994 22.4998 2.93994 24.2248 2.93994 26.0698C2.93994 27.9148 3.98994 29.6248 5.87994 30.8848C7.97994 32.2948 10.7399 32.9998 13.4999 32.9998C16.2599 32.9998 19.0199 32.2948 21.1199 30.8848C23.0099 29.6098 24.0599 27.8998 24.0599 26.0398C24.0449 24.1948 23.0099 22.4848 21.1199 21.2248Z"
                            fill="#AAAAAA"
                        />
                        <path
                            d="M29.985 11.0101C30.225 13.9202 28.155 16.4701 25.29 16.8152C25.275 16.8152 25.275 16.8152 25.26 16.8152H25.215C25.125 16.8152 25.035 16.8151 24.96 16.8451C23.505 16.9201 22.17 16.4551 21.165 15.6002C22.71 14.2201 23.595 12.1501 23.415 9.90015C23.31 8.68515 22.89 7.57515 22.26 6.63015C22.83 6.34515 23.49 6.16515 24.165 6.10515C27.105 5.85015 29.73 8.04015 29.985 11.0101Z"
                            fill="#AAAAAA"
                        />
                        <path
                            d="M32.9851 24.8851C32.8651 26.3401 31.9351 27.6001 30.3751 28.4551C28.8751 29.2801 26.9851 29.6701 25.1101 29.6251C26.1901 28.6501 26.8201 27.4351 26.9401 26.1451C27.0901 24.2851 26.2051 22.5001 24.4351 21.0751C23.4301 20.2801 22.2601 19.6501 20.9851 19.1851C24.3001 18.2251 28.4701 18.8701 31.0351 20.9401C32.4151 22.0501 33.1201 23.4451 32.9851 24.8851Z"
                            fill="#AAAAAA"
                        />
                    </svg>
                )}
            </Box>
        </>
    );
}

export default UserIcon2;
