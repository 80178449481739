import { Box, Button, Typography } from '@mui/material';
import { useState } from 'react';
import { data } from '../constants';
import PostCards from './PostCards';

function AchievementsCard() {
    const [showModal, setShowModal] = useState(false);
    return (
        <>
            <Box
                sx={{
                    backgroundColor: 'white',
                    // p: 2,
                    height: '100%',
                    padding: { xs: '8px 20px', lg: '24px 20px' },
                    // gap: '24px',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <Typography
                    color={'#494949'}
                    fontFamily="open sans"
                    fontWeight={700}
                    fontSize={16}
                    mb="16px"
                >
                    Have any recent achievements?
                </Typography>

                <Typography
                    sx={{ flex: 1 }}
                    color={'#808080'}
                    fontFamily="open sans"
                    fontWeight={400}
                    fontSize={14}
                    maxWidth={{ xs: '100%', lg: '350px' }}
                >
                    Tell the world about your recent achievements and get
                    reviews from people who witnessed your achievements{' '}
                </Typography>
                <Button
                    sx={{
                        mt: 3,
                        py: 1.5,
                        width: '100%',
                        flexShrink: '0',
                        bgcolor: '#fafafa'
                    }}
                    variant="outlined"
                    onClick={() => setShowModal(true)}
                >
                    Post an achievement
                </Button>
            </Box>
            {showModal ? (
                <PostCards
                    data={data}
                    achievementModal={true}
                    showModal={showModal}
                    hideModal={() => setShowModal(false)}
                />
            ) : null}
        </>
    );
}

export default AchievementsCard;
