import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    IconButton,
    Stack,
    Tooltip,
    Typography
} from '@mui/material';
import { useMessages } from '../../utils/MessagesContext';
import {
    Archive,
    CheckBox,
    CheckBoxOutlineBlank,
    Close,
    MarkChatRead,
    Unarchive,
    Delete
} from '@mui/icons-material';
import { useState } from 'react';
import { logger } from '../../../../utils/Helper/logger';

export default function ConversationToolbar() {
    const {
        userId,
        socket,
        conversations,
        setConversations,
        managedConversationIds,
        setManagedConversationIds,
        showArchived,
        setViewMode,
        setAlert
    } = useMessages();

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const selectAll = () => {
        setManagedConversationIds(
            conversations.map((conversation) => conversation.userId)
        );
    };
    const unselectAll = () => {
        setManagedConversationIds([]);
    };
    /** Mark selected conversations as read */
    const markAsRead = async () => {
        if (!socket || !managedConversationIds.length) return;
        const dto = {
            userId: userId,
            conversationIds: managedConversationIds
        };
        try {
            const ids: string[] = await socket.emitWithAck(
                'markConversationsAsRead',
                dto
            );
            setConversations((prevConversations) =>
                prevConversations.map((conversation) => {
                    if (ids.includes(conversation.userId)) {
                        return {
                            ...conversation,
                            unreadCount: 0
                        };
                    } else {
                        return conversation;
                    }
                })
            );
        } catch (err) {
            logger.error(err);
            setAlert('Failed to mark conversations as read', 'error')
        }
    };
    /** Archive selected conversations */
    const archiveConversations = async () => {
        if (!socket || !managedConversationIds.length) return;
        const dto = {
            userId: userId,
            conversationIds: managedConversationIds,
            archive: !showArchived
        };
        try {
            const ids: string[] = await socket.emitWithAck(
                'archiveConversations',
                dto
            );
            setConversations((prevConversations) =>
                prevConversations.map((conversation) => {
                    if (ids.includes(conversation.userId)) {
                        return {
                            ...conversation,
                            archived: !showArchived
                        };
                    } else {
                        return conversation;
                    }
                })
            );
            setManagedConversationIds([]);
        } catch (err) {
            logger.error(err);
            setAlert('Failed to archive conversations', 'error')
        }
    };
    const promptDeleteConfirmation = () => {
        if (!socket || !managedConversationIds.length) return;
        setShowDeleteConfirmation(true);
    };
    const handleConfirmationClose = () => {
        setShowDeleteConfirmation(false);
    };
    /** Delete selected conversations */
    const deleteConversations = async () => {
        if (!socket || !managedConversationIds.length) return;
        const dto = {
            userId: userId,
            conversationIds: managedConversationIds
        };
        try {
            const ids: string[] = await socket.emitWithAck(
                'deleteConversations',
                dto
            );
            setConversations((prevConversations) =>
                prevConversations.filter(
                    (conversation) => !ids.includes(conversation.userId)
                )
            );
            setManagedConversationIds([]);
            setShowDeleteConfirmation(false);
        } catch (err) {
            logger.error(err);
            setAlert('Failed to delete conversations', 'error')
        }
    };

    return (
        <>
            <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" alignItems="center">
                    <IconButton onClick={() => setViewMode(null)}>
                        <Close />
                    </IconButton>
                    <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
                        {managedConversationIds.length + ' Selected'}
                    </Typography>
                </Stack>
                <Stack direction="row" alignItems="center">
                    <Tooltip title="Select all">
                        <IconButton onClick={selectAll}>
                            <CheckBox />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Unselect all">
                        <IconButton onClick={unselectAll}>
                            <CheckBoxOutlineBlank />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Mark as read">
                        <IconButton onClick={markAsRead}>
                            <MarkChatRead sx={{ fontSize: '22px' }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={showArchived ? 'Unarchive' : 'Archive'}>
                        <IconButton onClick={archiveConversations}>
                            {showArchived ? <Unarchive /> : <Archive />}
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <IconButton onClick={promptDeleteConfirmation}>
                            <Delete />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </Stack>
            <Dialog
                open={showDeleteConfirmation}
                onClose={handleConfirmationClose}
                fullWidth
                maxWidth="sm"
            >
                <DialogContent>
                    <DialogContentText sx={{ textAlign: 'center', pb: '12px' }}>
                        Are you sure you want to delete these conversations?
                    </DialogContentText>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={handleConfirmationClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            onClick={deleteConversations}
                        >
                            Delete
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </>
    );
}
