import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircleIcon from '@mui/icons-material/Circle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import cambridge from '../../../assets/images/cambridge.jpg';
import Grid from '@mui/material/Grid';
import InfoChip from '../../Users/components/InfoChip';
import ScoreCard from '../../../components/ScoreCard.tsx';
import Section from '../../Users/components/Section';
import SectionHeading from '../../Users/components/SectionHeading';
import EducationSummary from '../../Users/components/EducationSummary';
import { ArrowLeftIcon } from '../../../assets/icons/ArrowLeftIcon';
import GlobalSearch from '../../../assets/icons/GlobalSearch';
import ArrowUpRight from '../../../assets/icons/ArrowUpRight';
import EmailSolid from '../../../assets/icons/EmailSolid';
import ArrowForward from '../../../assets/icons/ArrowForward';
import ImageListItem from '@mui/material/ImageListItem';
import ImageList from '@mui/material/ImageList';
import { Avatar } from '@mui/material';
import ExperienceLevel from '../../Users/components/ExperienceLevel';
import { experienceLevels } from '../../Reviews/constants';
import { useNavigate } from 'react-router-dom';
import image1 from '../../../assets/images/galleryImage1.png';
import image2 from '../../../assets/images/galleryImage2.png';
import image3 from '../../../assets/images/galleryImage3.png';
import image4 from '../../../assets/images/galleryImage4.png';
import image5 from '../../../assets/images/galleryImage5.png';
import image6 from '../../../assets/images/galleryImage6.png';
import { useGetProfessionalProfileById } from '../../../utils/hooks/api/professional-profile/useGetProfessionalProfile';
import { useGetConnections } from '../../../utils/hooks/api/connections/useGetConnections';
// import { useGetProfessionalRatingByProfileId } from '../../../utils/hooks/api/reviews/useGetProfessionalRatingByProfileId';
import { useGetProfessionalProfileEducationEmploymentById } from '../../../utils/hooks/api/professional-profile/useGetProfessionalProfileEducationEmploymentById';
import { useGetProfessionalProfileScore } from '../../../utils/hooks/api/professional-profile/useGetProfessionalProfileScore';
import WorkSummary from '../../Users/components/WorkSummary';
import { GenericCompanyLogo } from '../../../assets/images';
import { Ranking } from '../../Users/types/Ranking';

interface ApplicantDetailsPageProps {
    applicant: {
        professionalProfile: {
            firstName: string;
            lastName: string;
            score: number;
            photoUrl: string;
            emailAddress: string;
        };
    };
    id: string;
}
interface ISkillType {
    level: string;
    name: string;
    rating: number;
    type: string;
    witness: boolean;
}

const ApplicantDetailsPage = ({ applicant, id }: ApplicantDetailsPageProps) => {
    const navigate = useNavigate();
    const images = [image1, image2, image3, image4, image5, image6];
    const { data: applicantProfile, isFetching: profileIsFetching } =
        useGetProfessionalProfileById(id!);
    const { data: connections } = useGetConnections(applicantProfile?.userId!);
    const { data: score } = useGetProfessionalProfileScore(id!);

    // const { data: skills } = useGetProfessionalRatingByProfileId(
    //     applicantProfile?.id ?? ''
    // );
    const { data: qualifications } =
        useGetProfessionalProfileEducationEmploymentById(id!);
    return (
        <Grid container direction="column" gap={4}>
            <Grid item>
                <Box bgcolor="#fff" borderRadius={1} overflow="hidden" pb={5}>
                    <Box position="relative" pb="109px">
                        <Box overflow="hidden" borderRadius={[0, 0, 1, 1]}>
                            <img
                                style={{
                                    height: '259px',
                                    width: '100%',
                                    objectFit: 'cover',
                                    display: 'block'
                                }}
                                alt="Cover"
                                src={applicant?.professionalProfile?.photoUrl}
                            />
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    position: 'absolute',
                                    left: 0,
                                    right: 0,
                                    top: 0,
                                    background: 'rgba(0, 0, 0, 0.4)',
                                    backdropFilter: 'blur(2.5px)',
                                    padding: '15px 40px'
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '25px'
                                    }}
                                >
                                    <IconButton onClick={() => navigate(-1)}>
                                        <ArrowLeftIcon color="white" />
                                    </IconButton>
                                    <Typography
                                        variant="headlineSmallSemiBold"
                                        sx={{
                                            color: 'white',
                                            textTransform: 'capitalize'
                                        }}
                                    >
                                        {
                                            applicant?.professionalProfile
                                                ?.firstName
                                        }{' '}
                                        {
                                            applicant?.professionalProfile
                                                ?.lastName
                                        }
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        background: '#49B6FF',
                                        borderRadius: '6px',
                                        padding: '8px 12px',
                                        fontSize: '20px',
                                        fontWeight: '600',
                                        fontFamily: 'Open Sans',
                                        color: 'white',
                                        lineHeight: '100%'
                                    }}
                                >
                                    {applicant?.professionalProfile?.score}
                                </Box>
                            </Box>
                        </Box>
                        <Grid
                            container
                            gap={3}
                            position={'absolute'}
                            bottom={0}
                        >
                            <Box
                                ml={5}
                                borderRadius="50%"
                                overflow="hidden"
                                width="fit-content"
                                boxShadow="-8px 4px 20px rgba(0, 0, 0, 0.07), 8px 8px 20px rgba(0, 0, 0, 0.07)"
                                position="relative"
                            >
                                <Avatar
                                    sx={{
                                        width: '200px',
                                        height: '200px',
                                        border: '4px solid #FFF',
                                        objectFit: 'cover'
                                    }}
                                    src={
                                        applicant?.professionalProfile?.photoUrl
                                    }
                                />
                            </Box>
                            <Grid
                                container
                                wrap="nowrap"
                                width="auto"
                                marginTop="auto"
                                height="109px"
                                flexGrow={1}
                                alignItems="center"
                                pb={2}
                                marginLeft={-1}
                            >
                                <Grid item flexGrow={1}>
                                    <Typography
                                        component="span"
                                        color="#23282B"
                                        variant="titleLargeBold"
                                    >
                                        {connections?.length ?? 0}
                                    </Typography>
                                    &nbsp;
                                    <Typography
                                        component={'span'}
                                        color="#808080"
                                        variant="titleMediumRegular"
                                    >
                                        {'Connection' +
                                            (connections?.length === 1
                                                ? ''
                                                : 's')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                    <Grid container px={5} marginTop={-3.5}>
                        <Grid item flexGrow={1} marginTop="auto">
                            <Grid container direction="column" gap={2}>
                                <Grid item>
                                    <Grid
                                        container
                                        gap={2}
                                        alignItems="center"
                                        mt={1}
                                        marginBottom={'-30px'}
                                    >
                                        <Grid item>
                                            <Typography
                                                component="h2"
                                                color="#23282B"
                                                textTransform={'capitalize'}
                                                variant="headlineMediumSemiBold"
                                            >
                                                {
                                                    applicant
                                                        ?.professionalProfile
                                                        ?.firstName
                                                }{' '}
                                                {
                                                    applicant
                                                        ?.professionalProfile
                                                        ?.lastName
                                                }
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Grid
                                                container
                                                gap={2}
                                                alignItems="center"
                                            >
                                                <CircleIcon
                                                    sx={{
                                                        fontSize: '7.25px'
                                                    }}
                                                    htmlColor="#494949"
                                                />
                                                {profileIsFetching ? null : (
                                                    <Typography
                                                        component="span"
                                                        color="#808080"
                                                        variant="titleMediumRegular"
                                                    >
                                                        {
                                                            applicantProfile?.tagline
                                                        }
                                                    </Typography>
                                                )}
                                                <CheckCircleIcon
                                                    htmlColor="#49B6FF"
                                                    style={{
                                                        marginLeft: '-8px'
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            marginLeft={'auto'}
                                            marginTop="-55px"
                                        >
                                            <ScoreCard score={score} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        component={'p'}
                                        color="#23282B"
                                        variant="titleMediumRegular"
                                    >
                                        {applicantProfile?.tagline}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container alignItems={'center'}>
                                        {profileIsFetching ? null : (
                                            <Grid item xs={10}>
                                                <Grid
                                                    container
                                                    gap={1}
                                                    alignItems="center"
                                                >
                                                    <Grid item>
                                                        <InfoChip
                                                            type="location"
                                                            label={
                                                                applicantProfile
                                                                    ?.address
                                                                    ?.city +
                                                                ', ' +
                                                                applicantProfile
                                                                    ?.address
                                                                    ?.country
                                                            }
                                                        />
                                                    </Grid>
                                                    <CircleIcon
                                                        sx={{
                                                            fontSize: '7.25px'
                                                        }}
                                                        htmlColor="#494949"
                                                    />
                                                    <Grid item>
                                                        <InfoChip
                                                            type="phone"
                                                            label={
                                                                applicantProfile?.phoneNumber
                                                            }
                                                        />
                                                    </Grid>
                                                    <CircleIcon
                                                        sx={{
                                                            fontSize: '7.25px'
                                                        }}
                                                        htmlColor="#494949"
                                                    />
                                                    <Grid item>
                                                        <InfoChip
                                                            type="email"
                                                            label={
                                                                applicant
                                                                    ?.professionalProfile
                                                                    ?.emailAddress
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                        <Grid item xs={2} textAlign="right">
                                            <IconButton>
                                                <MoreVertIcon fontSize="medium" />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item>
                <Grid container wrap="nowrap" spacing={2}>
                    <Grid item xs={8}>
                        <Section px={0} mb={'20px'}>
                            <Box
                                px={5}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <Box>
                                    <Typography
                                        component="h3"
                                        color="#23282B"
                                        variant="titleLargeSemiBold"
                                        mb={1}
                                    >
                                        About me
                                    </Typography>
                                    <Typography
                                        component="h4"
                                        color="#808080"
                                        variant="titleMediumRegular"
                                        mb={1.5}
                                    >
                                        Tell the world about yourself.
                                    </Typography>
                                </Box>
                            </Box>
                            <Divider />
                            <Box px={5}>
                                <Typography
                                    component="p"
                                    color="#808080"
                                    variant="titleMediumRegular"
                                    mt={4}
                                    mb={2.5}
                                >
                                    {applicantProfile?.bio}
                                </Typography>
                                <Button
                                    disableRipple
                                    sx={{
                                        backgroundColor: '#FCFBF8',
                                        borderRadius: '8px',
                                        padding: '0 16px'
                                    }}
                                    endIcon={<ExpandMoreRoundedIcon />}
                                >
                                    <Typography
                                        color={'#05668D'}
                                        variant="titleSmallBold"
                                        sx={{
                                            textTransform: 'none',
                                            py: 1
                                        }}
                                    >
                                        See more
                                    </Typography>
                                </Button>
                            </Box>
                        </Section>
                        {qualifications?.qualifications?.length ? (
                            <>
                                {' '}
                                <Box
                                    sx={{
                                        backgroundColor: '#FFFFFF',
                                        borderTopRightRadius: '12px',
                                        borderTopLeftRadius: '12px',
                                        padding: '12px 40px',
                                        borderBottom: '1px solid #EDEDED'
                                    }}
                                >
                                    <Typography variant="titleLargeSemiBold">
                                        Education
                                    </Typography>
                                </Box>
                                {/* <Section
                                    px={2}
                                    py={0}
                                    mb={'20px'}
                                    sx={{
                                        borderTopRightRadius: 0,
                                        borderTopLeftRadius: 0,
                                        borderBottom: '1px solid #D8D8D8'
                                    }}
                                >
                                    {qualifications?.qualifications?.map(
                                        (education: any) => (
                                            <EducationSummary
                                                key={education.id}
                                                data={{
                                                    university: {
                                                        name: education.school,
                                                        image: cambridge
                                                    },
                                                    major: education.degree,
                                                    yearStarted:
                                                        education.startDate,
                                                    yearEnded: education.endDate
                                                }}
                                            />
                                        )
                                    )}
                                </Section> */}
                            </>
                        ) : null}
                        {qualifications?.employments?.length ? (
                            <>
                                <Box
                                    sx={{
                                        backgroundColor: '#FFFFFF',
                                        borderTopRightRadius: '12px',
                                        borderTopLeftRadius: '12px',
                                        padding: '12px 40px',
                                        borderBottom: '1px solid #EDEDED'
                                    }}
                                >
                                    <Typography variant="titleLargeSemiBold">
                                        Work Experience
                                    </Typography>
                                </Box>
                                <Section
                                    px={2}
                                    py={0}
                                    pb={2}
                                    mb={'20px'}
                                    sx={{
                                        borderTopRightRadius: 0,
                                        borderTopLeftRadius: 0,
                                        borderBottom: '1px solid #D8D8D8'
                                    }}
                                >
                                    {qualifications?.employments?.map(
                                        (employment: any) => (
                                            <WorkSummary
                                                key={employment.id}
                                                data={{
                                                    employer: {
                                                        name: employment.companyName,
                                                        image: GenericCompanyLogo
                                                    },
                                                    title: employment.jobTitle,
                                                    yearStarted:
                                                        employment.startDate,
                                                    yearEnded:
                                                        employment.endDate,
                                                    isCurrentPosition:
                                                        employment.isCurrentPosition,
                                                    rankings: [
                                                        Ranking.Leader,
                                                        Ranking.Expert,
                                                        Ranking.Advanced,
                                                        Ranking.Experienced
                                                    ]
                                                }}
                                            />
                                        )
                                    )}
                                    <Box>
                                        <Typography
                                            variant="titleMediumBold"
                                            color="#494949"
                                        >
                                            Skills:&nbsp;
                                            <Typography
                                                component="span"
                                                variant="titleMediumRegular"
                                            >
                                                Customer Experience Design · Ux
                                                writing · Mobile Interface
                                                Design · Web Interface Design ·
                                                Product Strategy · User Research
                                                · Interaction
                                            </Typography>
                                        </Typography>
                                    </Box>
                                </Section>
                            </>
                        ) : null}
                        <Box
                            sx={{
                                backgroundColor: '#FFFFFF',
                                borderTopRightRadius: '12px',
                                borderTopLeftRadius: '12px',
                                padding: '12px 40px',
                                borderBottom: '1px solid #EDEDED'
                            }}
                        >
                            <Typography variant="titleLargeSemiBold">
                                Volunteer Service
                            </Typography>
                        </Box>
                        <Section
                            px={2}
                            py={0}
                            mb={'20px'}
                            sx={{
                                borderTopRightRadius: 0,
                                borderTopLeftRadius: 0,
                                borderBottom: '1px solid #D8D8D8'
                            }}
                        >
                            <EducationSummary
                                data={{
                                    university: {
                                        name: 'Graphics Designer',
                                        image: cambridge
                                    },
                                    major: 'GoPro INC.',
                                    yearStarted: '2016',
                                    yearEnded: '2018'
                                }}
                            />
                        </Section>
                        <Box
                            sx={{
                                backgroundColor: '#FFFFFF',
                                borderTopRightRadius: '12px',
                                borderTopLeftRadius: '12px',
                                padding: '12px 40px',
                                borderBottom: '1px solid #EDEDED'
                            }}
                        >
                            <Typography variant="titleLargeSemiBold">
                                Licenses and Certification
                            </Typography>
                        </Box>
                        <Section
                            px={2}
                            py={0}
                            mb={0}
                            sx={{
                                borderTopRightRadius: 0,
                                borderTopLeftRadius: 0,
                                borderBottom: '1px solid #D8D8D8'
                            }}
                        >
                            <EducationSummary
                                data={{
                                    university: {
                                        name: 'Google Certified Product Designer',
                                        image: cambridge
                                    },
                                    major: 'Google',
                                    yearStarted: '2016',
                                    yearEnded: '2018'
                                }}
                            />
                        </Section>
                        <Section
                            px={2}
                            py={0}
                            mb={'20px'}
                            sx={{
                                borderTopRightRadius: 0,
                                borderTopLeftRadius: 0,
                                borderBottom: '1px solid #D8D8D8'
                            }}
                        >
                            <EducationSummary
                                data={{
                                    university: {
                                        name: 'Google Certified Product Designer',
                                        image: cambridge
                                    },
                                    major: 'Google',
                                    yearStarted: '2016',
                                    yearEnded: '2018'
                                }}
                            />
                        </Section>
                        <Box
                            sx={{
                                backgroundColor: '#FFFFFF',
                                borderTopRightRadius: '12px',
                                borderTopLeftRadius: '12px',
                                padding: '12px 40px',
                                borderBottom: '1px solid #EDEDED'
                            }}
                        >
                            <Typography variant="titleLargeSemiBold">
                                Tests
                            </Typography>
                        </Box>
                        <Section
                            px={2}
                            pl={4}
                            py={0}
                            mb={'20px'}
                            sx={{
                                borderTopRightRadius: 0,
                                borderTopLeftRadius: 0,
                                borderBottom: '1px solid #D8D8D8'
                            }}
                        >
                            <EducationSummary
                                data={{
                                    university: {
                                        name: 'SAT'
                                    },
                                    major: 'College Board',
                                    yearStarted: '2016',
                                    yearEnded: '2018'
                                }}
                            />
                        </Section>
                        <Box
                            sx={{
                                backgroundColor: '#FFFFFF',
                                borderTopRightRadius: '12px',
                                borderTopLeftRadius: '12px',
                                padding: '12px 40px',
                                borderBottom: '1px solid #EDEDED'
                            }}
                        >
                            <Typography variant="titleLargeSemiBold">
                                Awards
                            </Typography>
                        </Box>
                        <Section
                            px={2}
                            pl={4}
                            py={0}
                            mb={'20px'}
                            sx={{
                                borderTopRightRadius: 0,
                                borderTopLeftRadius: 0,
                                borderBottom: '1px solid #D8D8D8'
                            }}
                        >
                            <EducationSummary
                                data={{
                                    university: {
                                        name: 'Employee of the year'
                                    },
                                    major: 'Jobfactor Inc.',
                                    yearStarted: '2016',
                                    yearEnded: '2018'
                                }}
                            />
                        </Section>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="titleLargeSemiBold">
                            Skills
                        </Typography>
                        <Box
                            sx={{
                                mt: '16px',
                                mb: '20px',
                                gap: '12px',
                                display: 'flex',
                                flexWrap: 'wrap',
                                alignItems: 'center'
                            }}
                        >
                            {qualifications?.skills
                                ?.filter((x: ISkillType) => x?.witness === true)
                                ?.map((x: ISkillType) => {
                                    let level = experienceLevels?.find(
                                        (y) => y.key === x.rating
                                    );
                                    return (
                                        <ExperienceLevel
                                            background={
                                                level?.color ?? '#E75541'
                                            }
                                            shortForm={level?.shortForm ?? 'B'}
                                            title={x?.name}
                                        />
                                    );
                                })}
                        </Box>

                        <Typography variant="titleMediumRegular">
                            Website
                        </Typography>
                        <Box
                            sx={{
                                mb: '16px',
                                gap: '8px',
                                display: 'flex',
                                cursor: 'pointer',
                                alignItems: 'center'
                            }}
                        >
                            <GlobalSearch />
                            <Typography
                                variant="titleMediumSemiBold"
                                sx={{
                                    color: '#05668D'
                                }}
                            >
                                Samsonjob.com
                            </Typography>
                            <ArrowUpRight />
                        </Box>
                        <Typography variant="titleMediumRegular">
                            Email
                        </Typography>
                        <Box
                            sx={{
                                gap: '8px',
                                display: 'flex',
                                cursor: 'pointer',
                                alignItems: 'center'
                            }}
                        >
                            <EmailSolid />
                            <Typography
                                variant="titleMediumSemiBold"
                                sx={{
                                    color: '#05668D',
                                    textDecoration: 'none'
                                }}
                                component="a"
                                href={`mailto:${applicant?.professionalProfile?.emailAddress}`}
                            >
                                {applicant?.professionalProfile?.emailAddress}
                            </Typography>
                            <ArrowUpRight />
                        </Box>
                        <Button
                            variant="contained"
                            sx={{
                                mt: '20px',
                                mb: '40px',
                                fontSize: '14px'
                            }}
                            endIcon={<ArrowForward />}
                        >
                            Contact Information
                        </Button>

                        <Section
                            heading={
                                <SectionHeading
                                    ml={0}
                                    color="#494949"
                                    fontSize={'16px'}
                                >
                                    Activities
                                </SectionHeading>
                            }
                            onClick={() => {}}
                            px={0}
                            py={0}
                        >
                            <ImageList
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    borderRadius: '12px'
                                }}
                                cols={3}
                            >
                                {images?.map((item, index) => (
                                    <ImageListItem key={index}>
                                        <img
                                            src={item}
                                            alt={'Gallery'}
                                            style={{ width: '100%' }}
                                        />
                                    </ImageListItem>
                                ))}
                            </ImageList>
                        </Section>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ApplicantDetailsPage;
