import React from 'react';

const JobTypeIcon = () => {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="#305AEF"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.5758 4.81823C15.8675 4.0349 14.6842 3.64323 12.9675 3.64323H12.7675V3.6099C12.7675 2.2099 12.7675 0.476562 9.63418 0.476562H8.36751C5.23418 0.476562 5.23418 2.21823 5.23418 3.6099V3.65156H5.03418C3.30918 3.65156 2.13418 4.04323 1.42585 4.82656C0.600845 5.74323 0.625845 6.97656 0.709178 7.81823L0.717512 7.87656L0.7783 8.51484C0.792565 8.66462 0.873317 8.8 0.999315 8.88222C1.20137 9.01407 1.51111 9.21282 1.70085 9.31823C1.81751 9.39323 1.94251 9.4599 2.06751 9.52656C3.49251 10.3099 5.05918 10.8349 6.65085 11.0932C6.72584 11.8766 7.06751 12.7932 8.89251 12.7932C10.7175 12.7932 11.0758 11.8849 11.1342 11.0766C12.8342 10.8016 14.4758 10.2099 15.9592 9.34323C16.0092 9.31823 16.0425 9.29323 16.0842 9.26823C16.4044 9.08724 16.7362 8.86556 17.0408 8.64689C17.154 8.56561 17.2263 8.43948 17.2417 8.30097L17.2508 8.21823L17.2925 7.82656C17.3008 7.77656 17.3008 7.7349 17.3092 7.67656C17.3758 6.8349 17.3592 5.6849 16.5758 4.81823ZM9.90918 10.5266C9.90918 11.4099 9.90918 11.5432 8.88418 11.5432C7.85918 11.5432 7.85918 11.3849 7.85918 10.5349V9.48489H9.90918V10.5266ZM6.42584 3.64323V3.6099C6.42584 2.19323 6.42584 1.66823 8.36751 1.66823H9.63418C11.5758 1.66823 11.5758 2.20156 11.5758 3.6099V3.65156H6.42584V3.64323Z"
                fill="#305AEF"
            />
            <path
                d="M16.2589 10.511C16.613 10.3437 17.0202 10.6242 16.9848 11.0142L16.6992 14.1602C16.5242 15.8269 15.8409 17.5269 12.1742 17.5269H5.82422C2.15755 17.5269 1.47422 15.8269 1.29922 14.1686L1.02851 11.1907C0.993465 10.8053 1.39179 10.5252 1.74482 10.6839C2.70402 11.115 4.31603 11.8083 5.35201 12.0873C5.51602 12.1315 5.64917 12.2495 5.72357 12.4022C6.23777 13.4575 7.32696 14.0186 8.89088 14.0186C10.4394 14.0186 11.5419 13.4359 12.058 12.3774C12.1326 12.2246 12.2656 12.1067 12.4297 12.0622C13.5315 11.7637 15.2528 10.9865 16.2589 10.511Z"
                fill="#305AEF"
            />
        </svg>
    );
};

export default JobTypeIcon;
