import Box from '@mui/material/Box';
import HomeIcon from '../../assets/icons/HomeIcon';
import UserIcon from '../../assets/icons/UserIcon';
import UserIcon2 from '../../assets/icons/UserIcon2';
import BriefCaseIcon from '../../assets/icons/BriefCaseIcon';
import MessagesIcon from '../../assets/icons/MessagesIcon';
import Settings from '../../assets/icons/Settings';
import SideNavItem from './SideNavItem';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Submenu from '../Submenu';
import { useAuth } from '../../utils/context/AuthContext';
import EyeIcon from '../../assets/icons/EyeIcon';
import DashboardIcon from '../../assets/icons/DashboardIcon';
import Menu, { MenuProps } from '@mui/material/Menu';
import { styled, useMediaQuery, useTheme } from '@mui/material';
import NotificationBell from '../../assets/icons/NotificationBell';
import NotificationIcon from '../../assets/icons/NotificationIcon';

// const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
//     open?: boolean;
// }>(({ theme, open }) => ({
//     flexGrow: 1,
//     padding: theme.spacing(3),
//     transition: theme.transitions.create('margin', {
//         easing: theme.transitions.easing.sharp,
//         duration: theme.transitions.duration.leavingScreen
//     }),
//     marginLeft: `-${300}px`,
//     ...(open && {
//         transition: theme.transitions.create('margin', {
//             easing: theme.transitions.easing.easeOut,
//             duration: theme.transitions.duration.enteringScreen
//         }),
//         marginLeft: 0
//     })
// }));
const companyRoutes: IMenu[] = [
    {
        icon: HomeIcon,
        label: 'Home',
        route: '/'
    },
    {
        icon: UserIcon,
        label: 'Profile',
        route: '/about-company',
        submenu: [
            {
                label: 'Profile',
                route: ''
            },
            {
                label: 'About Us',
                route: '/about-company'
            },
            {
                label: 'Activities',
                route: '/company-activities'
            },
            {
                label: 'Jobfactor Score',
                route: '/jobfactor-score',
                disable: true
            },
            {
                label: 'Employees',
                route: '/employees',
                disable: true
            },
            {
                label: 'Company Ratings',
                route: '/company-ratings',
                disable: true
            }
        ]
    },
    {
        icon: UserIcon2,
        label: 'Community',
        route: '/connections',
        submenu: [
            {
                label: 'Community',
                route: ''
            },
            {
                label: 'My Community',
                route: '/connections'
            },
            {
                label: 'Pending',
                route: '/pending-connections'
            },
            {
                label: 'Suggestions',
                route: '/suggestions',
                disable: true
            }
        ]
    },
    {
        icon: BriefCaseIcon,
        label: 'Jobs',
        route: '/job-posting',
        submenu: [
            {
                label: 'Jobs',
                route: ''
            },
            {
                label: 'Job Postings',
                route: '/job-posting'
            },
            {
                label: 'Applications',
                route: '/job-applications'
            },
            {
                label: 'Saved Applications',
                route: '/save-applications'
            }
        ]
    },
    {
        icon: MessagesIcon,
        label: 'Messages',
        route: '/messages',
        disable: true
    },
    {
        icon: EyeIcon,
        label: 'Reviews',
        route: '/review-ratings',
        disable: true
    },
    {
        icon: DashboardIcon,
        label: 'DASHBOARD',
        route: '/dashboard',
        disable: true,
        submenu: [
            {
                label: 'Dashboard',
                route: ''
            },
            {
                label: 'Analytics',
                route: '#'
            },
            {
                label: 'Employees',
                route: '#'
            },
            {
                label: 'Applicant Tracking',
                route: '#'
            }
        ]
    },
    {
        icon: Settings,
        label: 'Settings',
        route: '/settings',
        submenu: [
            {
                label: 'Settings',
                route: ''
            },
            {
                label: 'General Preferences',
                route: '/settings'
            },
            {
                label: 'Communications',
                route: '#'
            },
            {
                label: 'Privacy & Visibility',
                route: '#'
            },
            {
                label: 'Subscriptions',
                route: '#'
            },
            {
                label: 'Security',
                route: '/security',
                disable: false
            }
        ]
    }
];
const professionalRoutes: IMenu[] = [
    {
        icon: HomeIcon,
        label: 'Home',
        route: '/'
    },
    {
        icon: UserIcon,
        label: 'Profile',
        route: '/users',
        submenu: [
            {
                label: 'Profile',
                route: ''
            },
            {
                label: 'About me',
                route: '/users'
            },
            {
                label: 'Activities',
                route: '/activities'
            },
            {
                label: 'Jobfactor Score',
                route: '/jobfactor-score'
            },
            {
                label: 'Skills',
                route: '/skills'
            }
        ]
    },
    {
        icon: UserIcon2,
        label: 'Community',
        route: '/connections',
        submenu: [
            {
                label: 'Community',
                route: ''
            },
            {
                label: 'My Community',
                route: '/connections'
            },
            {
                label: 'Pending',
                route: '/pending-connections'
            },
            {
                label: 'Suggestions',
                route: '#',
                disable: true
            }
        ]
    },
    {
        icon: BriefCaseIcon,
        label: 'Jobs',
        route: '/my-jobs',
        submenu: [
            {
                label: 'Jobs',
                route: ''
            },
            {
                label: 'All Jobs',
                route: '/my-jobs'
            },
            {
                label: 'Saved Jobs',
                route: '/save-job'
            },
            {
                label: 'Job Preferences',
                route: '/job-preference'
            },
            {
                label: 'Applied Jobs',
                route: '/job-applied'
            },
            {
                label: 'Career Planning',
                route: '#',
                disable: true
            }
        ]
    },
    {
        icon: MessagesIcon,
        label: 'Messages',
        route: '/messages',
        disable: true
    },
    {
        icon: EyeIcon,
        label: 'Reviews',
        route: '/reviews',
        disable: true,
        submenu: [
            {
                label: 'Reviews',
                route: ''
            },
            {
                label: 'Suggested Reviews',
                route: '/reviews'
            },
            {
                label: 'Sent Requests',
                route: '/reviews/sent-requests'
            },
            {
                label: 'Received Requests',
                route: '/reviews/received-requests'
            },
            {
                label: 'Company Ratings',
                route: '/reviews/company-ratings'
            }
        ]
    },
    {
        icon: NotificationIcon,
        label: 'Notification',
        route: '/notification',
    },
    // {
    //     icon: BookIcon,
    //     label: 'Courses',
    //     route: '/courses',
    //     submenu: [
    //         {
    //             label: 'Courses',
    //             route: ''
    //         },
    //         {
    //             label: 'All courses',
    //             route: '/#',
    //             disable: true
    //         },
    //         {
    //             label: 'My learning',
    //             route: '#',
    //             disable: true
    //         },
    //         {
    //             label: 'Career planning',
    //             route: '#',
    //             disable: true
    //         }
    //     ]
    // },
    {
        icon: Settings,
        label: 'Settings',
        route: '/settings',
        submenu: [
            {
                label: 'Settings',
                route: ''
            },
            {
                label: 'General preferences',
                route: '/settings',
                disable: true
            },
            {
                label: 'Communications',
                route: '#',
                disable: true
            },
            {
                label: 'Privacy & Visibility',
                route: '#',
                disable: true
            },
            {
                label: 'Subscriptions',
                route: '#',
                disable: true
            },
            {
                label: 'Security',
                route: '/security',
                disable: false
            }
        ]
    }
];
function SideNav() {
    const location = useLocation();
    const [hideMenu, setHideMenu] = useState(false);
    const [menuIndex, setMenuIndex] = useState(0);
    const [subMenuIndex, setSubMenuIndex] = useState(0);
    const [openedSubMenuIndex, setOpenedSubMenuIndex] = useState(0);
    const { account } = useAuth();
    const [anchorEl, setAnchorEl] = useState<any>(null);
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleActiveMenu = (e?: any) => {
        let subMenuActive = false;
        (account?.companyProfile.companyName
            ? companyRoutes
            : professionalRoutes
        ).forEach((x: IMenu, i: number) => {
            if (isTablet && e?.currentTarget) {
                const label = e?.currentTarget?.innerText;
                if (label?.toLowerCase() === x.label?.toLowerCase()) {
                    setOpenedSubMenuIndex(i);
                    if (x.submenu) {
                        subMenuActive = true;
                        if (e?.currentTarget) {
                            setAnchorEl(e.currentTarget)
                        }
                    }
                }
            } else {
                if (location.pathname === x.route) {
                    setMenuIndex(i);
                    if (x.submenu) {
                        subMenuActive = true;
                        if (e?.currentTarget) {
                            setAnchorEl(e.currentTarget)
                        }
                    }
                } else if (x.submenu) {
                    x.submenu.forEach((y, j: number) => {
                        if (location.pathname === y.route) {
                            setMenuIndex(i);
                            setSubMenuIndex(j);
                            subMenuActive = true;
                            if (e?.currentTarget) {
                                setAnchorEl(e.currentTarget)
                            }
                        }
                    });
                }
            }
        });

        setHideMenu(subMenuActive);
    };
    useEffect(() => {
        handleActiveMenu();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    useEffect(() => {
        if (isMobile) {
            setAnchorEl(null);
        }
    }, [isMobile])

    return (
        <>
            <Box
                sx={{
                    backgroundColor: { xs: '#fff' },
                    overflowY: 'auto', // add scroll on y-axis
                    display: 'flex',
                }}
            >
                <Box
                    sx={{
                        width: { lg: hideMenu ? '63px' : '100%', xs: '100%' },
                        overflow: { lg: hideMenu ? 'hidden' : 'auto', xs: 'hidden' },
                        p: { lg: 0, xs: '20px 40px' }
                    }}
                >
                    {(account?.companyProfile.companyName
                        ? companyRoutes
                        : professionalRoutes
                    ).map((nav: IMenu, index: number) => (
                        nav.label === 'Notification' && !isTablet ? null : <SideNavItem
                            hideMenu={hideMenu}
                            currentItem={
                                (account?.companyProfile.companyName
                                    ? companyRoutes
                                    : professionalRoutes)[menuIndex]
                            }
                            subMenuIndex={subMenuIndex}
                            handleActiveMenu={handleActiveMenu}
                            nav={nav}
                            index={index}
                            key={index}
                        />
                    ))}
                </Box>
                {account != null && account !== undefined && (
                    isTablet ? null
                    //     <StyledMenu
                    //         anchorEl={anchorEl}
                    //         open={Boolean(anchorEl)}
                    //         anchorOrigin={{
                    //             vertical: 'top',
                    //             horizontal: 'right',
                    //         }}
                    //         onClose={() => { setAnchorEl(null); handleActiveMenu() }}
                    //     >
                    //         <Submenu
                    //             setHideMenu={setHideMenu}
                    //             hideMenu={hideMenu}
                    //             handleNavigate={() => { setSubMenuIndex(openedSubMenuIndex); setAnchorEl(null); }}
                    //             options={
                    //                 (account?.companyProfile.companyName
                    //                     ? companyRoutes
                    //                     : professionalRoutes)[openedSubMenuIndex].submenu ?? []
                    //             }
                    //         />
                    //     </StyledMenu>
                        :
                        <Submenu
                            setHideMenu={setHideMenu}
                            hideMenu={hideMenu}
                            options={
                                (account?.companyProfile.companyName
                                    ? companyRoutes
                                    : professionalRoutes)[menuIndex].submenu ?? []
                            }
                        />
                )}
            </Box>
        </>
    );
}
export interface IMenu {
    label: string;
    route: string;
    hidden?: boolean;
    disable?: boolean;
    icon: (props: { isHover: boolean; isSelected: boolean, tab?: boolean }) => JSX.Element;
    submenu?: item[];
}
export type item = {
    route: string;
    label: string;
    disable?: boolean;
    hidden?: boolean;
};
export default SideNav;

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        {...props}
    />
))(() => ({
    '& .MuiPaper-root': {
        marginTop: 0,
        left: '140px !important',
        minWidth: '170px',
        maxWidth: '200px',
    },
    '& ul': {
        padding: 0
    }
}));