import { useEffect, useState } from 'react';
import { useAuth } from '../../utils/context/AuthContext';
import AboutCompany from './About/AboutCompany';
import ProfileWizard from './ProfileWizard/ProfileWizard';
import { useGetCompleteCompanyProfileById } from '../../utils/hooks/api/company-profile/useGetCompleteCompanyProfile';
import { logger } from '../../utils/Helper/logger';

const CompanyDetails = () => {
    const { user } = useAuth();
    const [companyProfile, setCompanyProfile] = useState(false);
    const { data } = useGetCompleteCompanyProfileById(
        user?.primaryCompanyProfile?.id!
    );

    useEffect(() => {
        setCompanyProfile(
            user?.primaryCompanyProfile?.companyName &&
                user?.primaryCompanyProfile?.emailAddress
                ? true
                : false
        );
    }, []);
    // useEffect(() => {
    //     setCompanyProfile(data?.createdAt && data?.logo ? true : false);
    // }, [data]);

    logger.log(user)
    logger.log(data)

    if (companyProfile === false) {
        return <ProfileWizard />;
    } else {
        return <AboutCompany />;
    }
};

export default CompanyDetails;
