import React from 'react';

const AchievementsIcon = ({ color = '#08BD04' }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
        >
            <path
                d="M14.2993 13.4517L14.2997 13.4514C14.5223 13.3052 14.8169 13.4607 14.8169 13.7335V14.8085C14.8169 15.2126 14.6566 15.6603 14.3751 16.0501C14.0937 16.4399 13.7199 16.7319 13.3417 16.8593C13.3415 16.8593 13.3412 16.8594 13.341 16.8595L10.6843 17.7423L10.6843 17.7423L10.6813 17.7433C10.5155 17.7996 10.2673 17.8355 9.99714 17.8355C9.72649 17.8355 9.48177 17.7994 9.32185 17.7442L9.32187 17.7442L9.31626 17.7423L6.65837 16.8591C6.6583 16.8591 6.65822 16.8591 6.65814 16.859C6.27618 16.7317 5.9028 16.4398 5.62273 16.0507C5.34266 15.6616 5.18359 15.2138 5.18359 14.8085V13.7251C5.18359 13.46 5.47971 13.3045 5.69399 13.4441L5.69429 13.4443L7.40809 14.5591C8.16225 15.064 9.09556 15.3001 10.0086 15.3001C10.9219 15.3001 11.8555 15.0638 12.6098 14.5586L14.2993 13.4517Z"
                fill="#08BD04"
                stroke="#08BD04"
            />
            <path
                d="M11.3839 2.52561L11.3843 2.52587L16.3752 5.80036C16.3753 5.80044 16.3754 5.80052 16.3756 5.80059C17.2211 6.35781 17.5051 7.36743 17.231 8.2514L17.2086 8.32374V8.39948V12.4995C17.2086 12.5296 17.1963 12.5609 17.1707 12.5865C17.145 12.6122 17.1137 12.6245 17.0836 12.6245C17.0534 12.6245 17.0222 12.6122 16.9965 12.5865C16.9709 12.5609 16.9586 12.5296 16.9586 12.4995V9.94948V9.02277L16.1841 9.53159L15.0427 10.2814L15.0424 10.2816L11.3843 12.6814L11.3839 12.6817C11.0332 12.9122 10.5388 13.0432 10.0169 13.0432C9.49505 13.0432 9.00061 12.9122 8.64993 12.6817L8.64822 12.6806L4.96489 10.2806L4.96442 10.2803L3.63193 9.41414C3.6318 9.41406 3.63168 9.41398 3.63156 9.4139C2.32461 8.5603 2.32766 6.64623 3.6304 5.80248L3.63192 5.80149L8.64859 2.5265L8.64993 2.52561C9.00061 2.29507 9.49505 2.16406 10.0169 2.16406C10.5388 2.16406 11.0332 2.29507 11.3839 2.52561Z"
                fill={color}
                stroke="#08BD04"
            />
        </svg>
    );
};

export default AchievementsIcon;
