import 'react-responsive-carousel/lib/styles/carousel.min.css';
import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { Modal } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ViewIcon from '../../../assets/icons/ViewIcon';
import VerifiedIcon from '../../../assets/icons/VerifiedIcon';
import DotIcon from '../../../assets/icons/DotIcon';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MoreIcon from '../../../assets/icons/MoreIcon';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import UploadIcon from '../../../assets/icons/UploadIcon';
import JobBookmarkIcon from '../../../assets/icons/JobBookmarkIcon';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import TextField from '@mui/material/TextField';
import { Carousel } from 'react-responsive-carousel';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { IFeedItem } from '../types/IFeedItem';
import { usePostComment } from '../../../utils/hooks/api/comments/useCreateComment';
import { useAuth } from '../../../utils/context/AuthContext';
import moment from 'moment';
import { Alert } from '@mui/material';
import { useQueryClient } from 'react-query';
import { QueryKeys } from '../../../utils/hooks/api/QueryKey';
import { usePost } from '../../../utils/context/PostContext';
import { useAddCommentLike } from '../../../utils/hooks/api/like-comments/useCreateCommentLike';
import LikesIcon from '../../../assets/icons/LikesIcon';
import { useRemoveCommentLike } from '../../../utils/hooks/api/like-comments/useRemoveCommentLike';
import { useNavigate } from 'react-router-dom';
import { PrimaryProfileType } from '../../../utils/hooks/api/account/types';
import { Responsiveness } from '../../../theme/overrides';
import { FeedItem } from './FeedItem';
import { ArrowLeftIcon } from '../../../assets/icons/ArrowLeftIcon';
import ShareIcon from '../../../assets/icons/ShareIcon';
import MessageIcon from '../../../assets/icons/MessageIcon';
import JobfactorAppBar from '../../../components/JobfactorAppBar';
import SideNav from '../../../components/Navigation/SideNav';
import CommentBox, { ICommentType } from './CommentBox';
import CloseXIcon from '../../../assets/icons/CloseXIcon';
import { useGetConnections } from '../../../utils/hooks/api/connections/useGetConnections';
import { useGetConnectionRequestSent } from '../../../utils/hooks/api/connections/useGetConnectionRequestSent';
import { logger } from '../../../utils/Helper/logger';
import { useSendConnectionRequest } from '../../../utils/hooks/api/connections/useSendConnectionRequest';
import { useGetUser } from '../../../utils/hooks/api/users/useGetUser';
import SnackAlert, { SnackAlertType } from '../../../components/Snackbar';
import axios, { AxiosError } from 'axios';

type PropTypes = {
    open: boolean;
    reposted: boolean;
    repostPopup: boolean;
    handleClose: () => void;
    handleCloseRepost: () => void;
    handleRepost: () => void;
    feed: IFeedItem;
    fullName?: string;
};
interface IUserType {
    firstName?: string;
    lastName?: string;
    commentId: string;
}
const CommentsPopup = ({
    open,
    handleClose,
    feed,
    reposted,
    handleRepost,
    handleCloseRepost,
    repostPopup
}: // fullName
PropTypes) => {
    const { isMobile, isTablet } = Responsiveness();
    const { user } = useAuth();
    const navigate = useNavigate();
    const { posts, setPosts } = usePost();
    const queryClient = useQueryClient();
    const postComment = usePostComment();
    const [comment, setComment] = useState('');
    const [onFocus, setOnFocus] = useState(false);
    const [replyingTo, setReplyingTo] = useState<IUserType>();
    const addLike = useAddCommentLike();
    const removeLike = useRemoveCommentLike();
    const isProfessional =
        user?.primaryProfile === PrimaryProfileType.Professional;
    const sendConnectRequest = useSendConnectionRequest();
    const userInfoQuery = useGetUser(feed?.profileId ?? '');
    // const [isAdded, setIsAdded] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState('');
    const [type, setType] = useState<SnackAlertType>('info');

    const handlePost = () => {
        const request: any = {
            userId: user?.id ?? '',
            postId: feed?.id ?? '',
            comment,
            commentId: replyingTo?.commentId ?? ''
        };
        postComment.mutate(request, {
            onSuccess: (res) => {
                let tempPosts = JSON.parse(JSON.stringify(posts));
                const getComment = (comments: ICommentType[]) => {
                    comments?.filter((y) => {
                        if (y.id === replyingTo?.commentId) {
                            y.replies.push({
                                ...res,
                                postId: feed?.id,
                                likedByProfessionalIds: [],
                                likes: 0
                            });
                        } else {
                            getComment(y.replies);
                        }
                        return y;
                    });
                    return comments;
                };
                tempPosts.filter((post: any) => {
                    if (post?.id === feed?.id) {
                        if (replyingTo?.commentId) {
                            getComment(post?.comments ?? []);
                        } else {
                            post?.comments.push({
                                ...res,
                                postId: post?.id,
                                likedByProfessionalIds: [],
                                likes: 0
                            });
                            post.commentsCount += 1;
                        }
                    }
                    return post;
                });
                setPosts(tempPosts);
                setComment('');
                setReplyingTo(undefined);
                queryClient.invalidateQueries(QueryKeys.RetrieveUserPosts);
            },
            onError: (err) => {
                console.log('ERR', err);
            }
        });
    };

    const handleLike = (key: string) => {
        if (key === 'add') {
            const request: any = {
                userId: user?.id ?? '',
                postId: feed?.id ?? '',
                commentId: feed?.comments ? feed?.comments[0]?.id : ''
            };
            addLike.mutate(request, {
                onSuccess: (res) => {
                    console.log('COMMENT RES', res);
                    let tempPosts = JSON.parse(JSON.stringify(posts));
                    tempPosts.filter((post: any) => {
                        console.log('post', post);
                        if (post?.id === feed?.id) {
                            updateLikes(post?.comments ?? [], 'add');
                        }
                        return post;
                    });
                    setPosts(tempPosts);
                },
                onError: (err) => {
                    console.log('ERR', err);
                }
            });
        } else {
            removeLike.mutate(
                {
                    userId: user?.id ?? '',
                    commentId: feed?.comments ? feed?.comments[0]?.id : ''
                },
                {
                    onSuccess: (res) => {
                        console.log('REMOVE COMMENT RES', res);
                        let tempPosts = JSON.parse(JSON.stringify(posts));
                        tempPosts.filter((post: any) => {
                            if (post?.id === feed?.id) {
                                updateLikes(post?.comments ?? [], 'subtract');
                            }
                            return post;
                        });
                        setPosts(tempPosts);
                    },
                    onError: (err) => {
                        console.log('ERR', err);
                    }
                }
            );
        }
    };

    const updateLikes = (comments: ICommentType[], key: string) => {
        if (key === 'add') {
            comments[0].likes += 1;
            comments[0].likedByProfessionalIds.push({
                id: isProfessional
                    ? user?.professionalProfile?.id
                    : user?.primaryCompanyProfile?.companyId,
                firstName: isProfessional
                    ? user?.professionalProfile?.firstName
                    : user?.primaryCompanyProfile?.companyName,
                lastName: isProfessional
                    ? user?.professionalProfile?.lastName
                    : null,
                photoUrl: isProfessional
                    ? user?.professionalProfile?.photoUrl
                    : user?.primaryCompanyProfile?.photoUrl
            });
        } else {
            comments[0].likes -= 1;
            comments[0].likedByProfessionalIds =
                comments[0]?.likedByProfessionalIds?.filter(
                    (x) =>
                        x.id !==
                        (isProfessional
                            ? user?.professionalProfile?.id ?? ''
                            : user?.primaryCompanyProfile?.companyId ?? '')
                );
        }
        return comments;
    };

    const sendConnectionRequest = async () => {
        try {
            const userInfoMutation = await userInfoQuery.refetch();
            if (userInfoMutation.data) {
                const userInfo = userInfoMutation.data;

                const data = {
                    sourceUserId:
                        user?.primaryProfile === PrimaryProfileType.Professional
                            ? user?.professionalProfile?.userId ?? ''
                            : user?.id ?? '',
                    destinationUserId: feed?.profileId ?? ''
                };

                //  logger.log('data', data);

                sendConnectRequest.mutate(data, {
                    onSuccess: () => {
                        // setIsAdded(true);
                        queryClient.invalidateQueries(
                            QueryKeys.RetrieveConnections
                        );
                        queryClient.invalidateQueries(
                            QueryKeys.RetrieveConnectionRequestSent
                        );
                        queryClient.invalidateQueries(
                            QueryKeys.RetrieveConnectionRequestReceived
                        );
                        setType('success');
                        setMessage('Request and Email sent successfully.');
                        setShowAlert(true);
                        //  handleConnectionEmail(userInfo);
                        // const sendmaildata = {
                        //     email: userInfo.professionalProfile.emailAddress,
                        //     subject: generateSubject(connectionRequest.subject),
                        //     html: generateHtml(connectionRequest.html, userInfo.professionalProfile.firstName),
                        // }
                        // logger.log("sendmailData=>", sendmaildata)
                        //  sendEmailNotificationMutation.mutate(sendmaildata,{
                        //     onSuccess:(res) =>{
                        //         console.log('sendmail', res)
                        //         setType('success');
                        //         setMessage('email notification sent successfully.');
                        //         setShowAlert(true);
                        //     },
                        //     onError: (err)=>{
                        //         console.log('err', err)
                        //     }
                        // });
                    },
                    onError: (err: AxiosError) => {
                        if (axios.isAxiosError(err)) {
                            setType('error');
                            setMessage(
                                err.message ??
                                    'Error occurred, please try again!'
                            );
                            setShowAlert(true);
                        }
                    }
                });
            } else {
                console.error(
                    'Error fetching user info:',
                    userInfoMutation.error
                );
            }
        } catch (error) {
            console.error('Unexpected error:', error);
        }
    };

    const { data: connections, isFetching } = useGetConnections(user?.id ?? '');
    const isAdded = connections?.filter(
        (connection: any) =>
            connection.destinationUser.profileId === feed?.profileId
    );
    const isConnected = isAdded?.length === 0 ? false : true;
    const { data: sentRequests } = useGetConnectionRequestSent(user?.id ?? '');
    const requestSent = sentRequests?.filter(
        (connection: any) =>
            connection.destinationUser.profileId === feed?.profileId
    );
    const isRequested = requestSent?.length === 0 ? false : true;

    return (
        <>
            {isTablet && open ? (
                <Modal open={open} onClose={handleClose}>
                    <Box
                        // pt="80px"
                        zIndex={9999}
                        position="absolute"
                        // width="100%"
                        height="100%"
                        bgcolor="#fff"
                        top={0}
                        right={0}
                        left={0}
                        pb="30px"
                        // bottom={'-100%'}
                        overflow="auto"
                        // display="flex"
                        // sx={{}}
                    >
                        <JobfactorAppBar />
                        <Box display="flex" pt="80px">
                            {isMobile ? null : (
                                <Box pl={'16px'} width="170px">
                                    <Box position="fixed">
                                        <Box width="120px">
                                            <SideNav />
                                        </Box>
                                    </Box>
                                </Box>
                            )}
                            <Box width="100%" position="relative">
                                <Box
                                    position="fixed"
                                    top={70}
                                    width="100%"
                                    p={isMobile ? '16px' : '20px'}
                                    // width="100%"
                                    bgcolor="#fff"
                                    borderBottom="1px solid #D9D9D9"
                                    display="flex"
                                    alignItems="center"
                                    gap="20px"
                                    zIndex={50}
                                >
                                    <Box
                                        onClick={() => handleClose()}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        <ArrowLeftIcon />
                                    </Box>
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize={isMobile ? '16px' : '24px'}
                                        fontWeight={600}
                                        lineHeight="32px"
                                        color="#23282b"
                                    >
                                        Post
                                    </Typography>
                                </Box>
                                <Box
                                    position="relative"
                                    sx={{
                                        // display: 'none',
                                        mt: reposted ? '8px' : '70px',
                                        ml: reposted ? '8px' : 0,
                                        p: reposted ? '8px' : '20px',
                                        mb: reposted ? 0 : '80px',
                                        // background: reposted ? '#F2F2F2' : '#fff',
                                        border:
                                            reposted && !repostPopup
                                                ? '1px solid #C5C5C5'
                                                : 0
                                        // width: '100%'
                                    }}
                                    className="feedItem"
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: '16px',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                gap: '16px'
                                                // width: '100%'
                                            }}
                                        >
                                            <Avatar
                                                onClick={() =>
                                                    navigate(
                                                        feed?.primaryProfile ===
                                                            PrimaryProfileType.Professional
                                                            ? `/professional-profile/${feed?.profileId}`
                                                            : `/company-profile/${feed?.profileId}`
                                                    )
                                                }
                                                src={feed.profileImage}
                                                alt="feed"
                                                sx={{
                                                    width: 48,
                                                    height: 48,
                                                    borderRadius: 100,
                                                    cursor: 'pointer'
                                                }}
                                            />
                                            <Box>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        gap: '4px',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: 14,
                                                            fontWeight: 600,
                                                            cursor: 'pointer',
                                                            '&:hover': {
                                                                textDecoration:
                                                                    'underline'
                                                            }
                                                        }}
                                                        color="#23282B"
                                                        variant="titleMediumSemiBold"
                                                        onClick={() =>
                                                            navigate(
                                                                feed?.primaryProfile ===
                                                                    PrimaryProfileType.Professional
                                                                    ? `/professional-profile/${feed?.profileId}`
                                                                    : `/company-profile/${feed?.profileId}`
                                                            )
                                                        }
                                                    >
                                                        {feed.fullName}
                                                    </Typography>
                                                    <Box
                                                        component={'span'}
                                                        display={'flex'}
                                                        sx={{
                                                            '& svg': {
                                                                width: 16,
                                                                height: 16
                                                            }
                                                        }}
                                                    >
                                                        <VerifiedIcon />
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            mx: '4px',
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'center',
                                                            '& svg': {
                                                                width: 4,
                                                                height: 4
                                                            }
                                                        }}
                                                    >
                                                        <DotIcon color="#808080" />
                                                    </Box>
                                                    <Typography
                                                        color="#808080"
                                                        variant="titleSmallRegular"
                                                        fontSize={12}
                                                    >
                                                        {moment(
                                                            feed.createdAt
                                                        ).fromNow()}
                                                    </Typography>
                                                </Box>
                                                <Typography
                                                    color="#808080"
                                                    variant="titleSmallRegular"
                                                >
                                                    {feed.jobTitle}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <MoreIcon />
                                    </Box>
                                    <Box>
                                        <Box sx={{ mt: '12px' }}>
                                            <Typography
                                                component="div"
                                                sx={{ whiteSpace: 'pre-line' }}
                                            >
                                                <Typography
                                                    color={'#808080'}
                                                    variant="titleSmallRegular"
                                                    // ref={DescriptionRef}
                                                    sx={{
                                                        whiteSpace: 'pre-line',
                                                        mb: 1
                                                    }}
                                                >
                                                    {feed?.description}
                                                </Typography>
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                width: '100%'
                                            }}
                                        >
                                            {feed?.images?.length > 0 && (
                                                <Box
                                                    sx={{
                                                        mt: '15px',
                                                        position: 'relative',
                                                        display: 'flex',
                                                        gap: '12px',
                                                        overflow: 'hidden',
                                                        height: '100%',
                                                        width: '100%',
                                                        boxSizing: 'border-box'
                                                    }}
                                                >
                                                    {feed.images.map(
                                                        (image: any, index) => (
                                                            <img
                                                                key={index}
                                                                src={image}
                                                                alt="feed"
                                                                style={{
                                                                    height: 'fit-content',
                                                                    width: `${
                                                                        100 /
                                                                        (feed
                                                                            ?.images
                                                                            ?.length >
                                                                        1
                                                                            ? 2
                                                                            : 1)
                                                                    }%`,
                                                                    objectFit:
                                                                        feed
                                                                            ?.images
                                                                            ?.length ===
                                                                        1
                                                                            ? 'contain'
                                                                            : 'fill'
                                                                }}
                                                            />
                                                        )
                                                    )}
                                                    {feed?.images?.length >
                                                        2 && (
                                                        <Box
                                                            sx={{
                                                                position:
                                                                    'absolute',
                                                                bottom: '10px',
                                                                right: '10px',
                                                                color: '#FAFAFA',
                                                                fontFamily:
                                                                    'Open Sans',
                                                                fontSize:
                                                                    '36px',
                                                                fontStyle:
                                                                    'normal',
                                                                fontWeight:
                                                                    '600',
                                                                lineHeight:
                                                                    '44px'
                                                            }}
                                                        >
                                                            +
                                                            {feed?.images
                                                                ?.length - 2}
                                                        </Box>
                                                    )}
                                                </Box>
                                            )}
                                        </Box>
                                    </Box>
                                    {feed?.repost ? (
                                        <FeedItem
                                            feed={feed?.repost}
                                            reposted={true}
                                        />
                                    ) : null}
                                    {!reposted ? (
                                        <Box>
                                            <Box
                                                sx={{
                                                    mt: '16px',
                                                    mb: isMobile
                                                        ? '12px'
                                                        : '40px',
                                                    display: 'flex',
                                                    gap: '24px',
                                                    alignItems: 'center',
                                                    justifyContent:
                                                        'space-between',
                                                    '& svg': {
                                                        width: '22px',
                                                        height: '22px'
                                                    }
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        gap: '12px',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    {/* <Box
                                sx={{
                                    display: 'flex',
                                    gap: '12px',
                                    alignItems: 'center',
                                    height: '36px',
                                    bgcolor: '#f2f2f2',
                                    borderRadius: '8px',
                                    px: '12px'
                                }}
                            >
                                <ViewIcon color="#999999" />
                                <Typography
                                    variant="titleMediumSemiBold"
                                    color={'#808080'}
                                >
                                    {feed?.views}
                                </Typography>
                            </Box> */}
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            gap: '12px',
                                                            alignItems:
                                                                'center',
                                                            px: '12px',
                                                            height: '36px',
                                                            bgcolor: '#f2f2f2',
                                                            borderRadius: '8px'
                                                        }}
                                                    >
                                                        <UploadIcon color="#464646" />
                                                        <Typography
                                                            variant="titleSmallSemiBold"
                                                            color={'#808080'}
                                                        >
                                                            Send
                                                        </Typography>
                                                    </Box>

                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            gap: '12px',
                                                            alignItems:
                                                                'center',
                                                            height: '36px',
                                                            px: '12px',
                                                            bgcolor: '#f2f2f2',
                                                            borderRadius: '8px'
                                                        }}
                                                    >
                                                        {feed?.comments &&
                                                        feed?.comments[0]?.likedByProfessionalIds?.find(
                                                            (x) =>
                                                                x.id ===
                                                                (isProfessional
                                                                    ? user
                                                                          ?.professionalProfile
                                                                          ?.id ??
                                                                      ''
                                                                    : user
                                                                          ?.primaryCompanyProfile
                                                                          ?.companyId ??
                                                                      '')
                                                        ) ? (
                                                            <IconButton
                                                                onClick={() =>
                                                                    handleLike(
                                                                        'remove'
                                                                    )
                                                                }
                                                                sx={{
                                                                    m: 0,
                                                                    p: 0
                                                                }}
                                                            >
                                                                <LikesIcon />
                                                            </IconButton>
                                                        ) : (
                                                            <IconButton
                                                                onClick={() =>
                                                                    handleLike(
                                                                        'add'
                                                                    )
                                                                }
                                                                sx={{
                                                                    maxHeight:
                                                                        '20px',
                                                                    maxWidth:
                                                                        '20px',
                                                                    m: 0,
                                                                    p: 0
                                                                }}
                                                            >
                                                                <FavoriteBorderIcon
                                                                    sx={{
                                                                        color: '#464646'
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        )}
                                                        <Typography
                                                            variant="titleSmallSemiBold"
                                                            color={'#808080'}
                                                        >
                                                            {/* {(feed?.comments
                                                                ? feed
                                                                      ?.comments[0]
                                                                      ?.likes
                                                                : 0) ??
                                                                feed?.likes} */}
                                                            Like
                                                        </Typography>
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            gap: '12px',
                                                            alignItems:
                                                                'center',
                                                            height: '36px',
                                                            px: '12px',
                                                            bgcolor: '#f2f2f2',
                                                            borderRadius: '8px'
                                                        }}
                                                        onClick={() =>
                                                            setReplyingTo({
                                                                commentId:
                                                                    feed?.id
                                                            })
                                                        }
                                                    >
                                                        <IconButton
                                                            sx={{ m: 0, p: 0 }}
                                                        >
                                                            <MessageIcon />
                                                        </IconButton>
                                                        <Typography
                                                            variant="titleSmallSemiBold"
                                                            color={'#808080'}
                                                        >
                                                            Comment
                                                        </Typography>
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            gap: '12px',
                                                            alignItems:
                                                                'center',
                                                            height: '36px',
                                                            px: '12px',
                                                            bgcolor: '#f2f2f2',
                                                            borderRadius: '8px'
                                                        }}
                                                    >
                                                        <IconButton
                                                            onClick={
                                                                handleRepost
                                                            }
                                                            sx={{ m: 0, p: 0 }}
                                                        >
                                                            <ShareIcon color="#464646" />
                                                        </IconButton>
                                                        <Typography
                                                            variant="titleSmallSemiBold"
                                                            color={'#808080'}
                                                        >
                                                            Repost
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            {isMobile ? (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        gap: '12px',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'space-between'
                                                    }}
                                                    mb="40px"
                                                >
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            gap: '12px',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'space-between'
                                                        }}
                                                    >
                                                        <ViewIcon color="#999999" />
                                                        <Typography
                                                            variant="titleMediumSemiBold"
                                                            color={'#808080'}
                                                        >
                                                            {feed?.views}
                                                        </Typography>
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            gap: '12px',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <Box>
                                                            {/* <Typography
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                letterSpacing:
                                                                    '0.0015em',
                                                                color: '#808080',
                                                                fontSize: '16px'
                                                            }}
                                                        >
                                                            {item?.likes === 1
                                                                ? item?.likes +
                                                                  ' Like'
                                                                : item?.likes +
                                                                  ' Likes'}
                                                        </Typography> */}
                                                        </Box>
                                                        {/* <DotIcon /> */}
                                                        <Box>
                                                            <Typography
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    letterSpacing:
                                                                        '0.0015em',
                                                                    color: '#808080',
                                                                    fontSize:
                                                                        '16px'
                                                                }}
                                                            >
                                                                {feed?.comments
                                                                    ?.length ===
                                                                1
                                                                    ? feed
                                                                          ?.comments
                                                                          ?.length +
                                                                      ' Comment'
                                                                    : feed
                                                                          ?.comments
                                                                          ?.length +
                                                                      ' Comments'}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            ) : null}
                                        </Box>
                                    ) : null}
                                    {feed?.comments?.length ? (
                                        <Box>
                                            {feed?.comments?.map(
                                                (item: any, i) => (
                                                    <CommentBox
                                                        isFirstComment={i === 0}
                                                        key={item.id}
                                                        item={item}
                                                        setReplyingTo={
                                                            setReplyingTo
                                                        }
                                                    />
                                                )
                                            )}
                                        </Box>
                                    ) : (
                                        <Typography pl="20px">
                                            No comments
                                        </Typography>
                                    )}
                                </Box>
                                <Box
                                    position="fixed"
                                    bottom={0}
                                    right={0}
                                    left={0}
                                    // width={isMobile ? undefined : '634px'}
                                    sx={{
                                        background: '#FCFBF8',
                                        borderTop: '0.5px solid #D9D9D9',
                                        padding: '16px 20px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '16px',
                                        // width: '100%',
                                        ml: '0 !important'
                                    }}
                                >
                                    {replyingTo ? (
                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                letterSpacing: '0.0015em',
                                                color: '#808080',
                                                fontSize: '16px'
                                            }}
                                        >
                                            Replying to {replyingTo?.firstName}{' '}
                                            {replyingTo?.lastName}
                                        </Typography>
                                    ) : null}
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '20px'
                                        }}
                                    >
                                        <TextField
                                            fullWidth
                                            value={comment}
                                            onChange={(e) =>
                                                setComment(e.target.value)
                                            }
                                            sx={{
                                                '& .MuiOutlinedInput-notchedOutline':
                                                    {
                                                        border: '0'
                                                    },
                                                flex: 1
                                            }}
                                            placeholder="Add a comment..."
                                        />
                                        <Button
                                            onClick={handlePost}
                                            disabled={!comment}
                                            sx={{
                                                padding: '12px',
                                                width: '100px',
                                                height: '40px',
                                                background:
                                                    comment.length > 0
                                                        ? '#05668D'
                                                        : '#EDEDED',
                                                borderRadius: '8px',
                                                textTransform: 'capitalize',
                                                textDecoration: 'none',
                                                fontFamily: 'Open Sans',
                                                fontWeight: '600',
                                                fontSize: '14px',
                                                letterSpacing: '0.001em',
                                                color:
                                                    comment.length > 0
                                                        ? '#fff'
                                                        : '#808080'
                                            }}
                                        >
                                            Post
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
            ) : (
                <Dialog
                    open={open}
                    onClose={handleClose}
                    maxWidth="lg"
                    sx={{
                        '& .MuiDialog-paper': {
                            height: '800px',
                            width: '1200px'
                        }
                        // width: isMobile ? '100vw' : '60vw'
                    }}
                    scroll="paper"
                >
                    <Grid
                        container
                        wrap="wrap"
                        sx={{
                            width: '100%',
                            maxWidth: '1200px',
                            height: '100%'
                        }}
                    >
                        <Grid
                            item
                            xs={12}
                            lg={6}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                background: '#23282B',
                                '& .carousel-root, .carousel-slider, .slider-wrapper, ul':
                                    {
                                        width: '100%',
                                        height: '100%'
                                    }
                            }}
                        >
                            <Carousel
                                showStatus={false}
                                showIndicators={false}
                                showThumbs={false}
                                renderArrowNext={(clickHandler: () => void) =>
                                    feed?.images?.length > 1 ? (
                                        <IconButton
                                            sx={{
                                                background:
                                                    'rgba(255, 255, 255, 0.7)',
                                                width: '44px',
                                                height: '44px',
                                                filter: 'drop-shadow(4px 4px 15px rgba(0, 0, 0, 0.15))',
                                                backdropFilter: 'blur(1px)',
                                                position: 'absolute',
                                                top: 0,
                                                zIndex: '99',
                                                bottom: 0,
                                                margin: 'auto',
                                                right: '35px'
                                            }}
                                            onClick={clickHandler}
                                        >
                                            <ArrowForwardIcon />
                                        </IconButton>
                                    ) : null
                                }
                                renderArrowPrev={(clickHandler: () => void) =>
                                    feed?.images?.length > 1 ? (
                                        <IconButton
                                            sx={{
                                                background:
                                                    'rgba(255, 255, 255, 0.7)',
                                                width: '44px',
                                                height: '44px',
                                                filter: 'drop-shadow(4px 4px 15px rgba(0, 0, 0, 0.15))',
                                                backdropFilter: 'blur(1px)',
                                                position: 'absolute',
                                                top: 0,
                                                zIndex: '99',
                                                bottom: 0,
                                                margin: 'auto',
                                                left: '35px'
                                            }}
                                            onClick={clickHandler}
                                        >
                                            <ArrowBack />
                                        </IconButton>
                                    ) : null
                                }
                            >
                                {feed?.images?.map((x) => (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            height: '100%',
                                            width: '100%',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <img
                                            src={x}
                                            style={{
                                                maxHeight: '481px',
                                                width: 'auto',
                                                height: 'auto',
                                                maxWidth: '600px'
                                            }}
                                            alt="slider_1"
                                        />
                                    </Box>
                                ))}
                            </Carousel>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            lg={6}
                            sx={{
                                display: 'flex',
                                maxHeight: '100%',
                                overflow: 'hidden',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '100%',
                                    pt: '12px',
                                    pl: '20px',
                                    pr: '30px',
                                    pb: '18px',
                                        gap: '16px',
                                }}
                            >
                                <Avatar
                                    // onClick={() =>
                                    //     navigate(
                                    //         isProfessional ? `/professional-profile/${user?.professionalProfile?.id}` : `/company-profile/${user?.primaryCompanyProfile?.companyId}`
                                    //     )
                                    // }
                                    src={feed?.profileImage}
                                    sx={{
                                        // cursor: 'pointer',
                                        width: '56px',
                                        height: '56px'
                                    }}
                                />

                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        width: '100%'
                                    }}
                                >
                                    <Box flex={1}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '4px'
                                                // width: '100%'
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    fontWeight: '600',
                                                    fontSize: '16px',
                                                    letterSpacing: '0.0015em',
                                                    color: '#23282B',
                                                    mr: '5px'
                                                    // cursor: 'pointer',
                                                    // '&:hover': {
                                                    //     textDecoration: 'underline'
                                                    // }
                                                }}
                                                // onClick={() =>
                                                //     navigate(
                                                //         isProfessional ? `/professional-profile/${user?.professionalProfile?.id}` : `/company-profile/${user?.primaryCompanyProfile?.companyId}`
                                                //     )
                                                // }
                                            >
                                                {feed?.fullName}
                                            </Typography>

                                            <VerifiedIcon />
                                            <Box
                                                sx={{
                                                    px: '8px'
                                                }}
                                            >
                                                {/* <DotIcon /> */}
                                                <Box
                                                    width="6px"
                                                    height="6px"
                                                    borderRadius="50%"
                                                    bgcolor="#D9D9D9"
                                                ></Box>
                                            </Box>
                                            {isConnected &&
                                            !isRequested ? null : feed?.profileId ===
                                              user?.professionalProfile
                                                  ?.id ? null : (
                                                <Button
                                                    disabled={isRequested}
                                                    sx={{
                                                        width: isRequested
                                                            ? '140px'
                                                            : '89px',
                                                        height: '40px',
                                                        // padding: '10px 8px',
                                                        background: '#f2f2f2',
                                                        borderRadius: '8px',
                                                        textTransform:
                                                            'capitalize',
                                                        fontFamily: 'Open Sans',
                                                        fontWeight: '600',
                                                        fontSize: '14px',
                                                        letterSpacing:
                                                            '0.0035em',
                                                        color: '#05668D',
                                                        textDecoration: 'none'
                                                    }}
                                                    onClick={() => {
                                                        if (isRequested) {
                                                        } else
                                                            sendConnectionRequest();
                                                    }}
                                                >
                                                    {isRequested
                                                        ? 'Request sent'
                                                        : feed?.primaryProfile !==
                                                          PrimaryProfileType.Professional
                                                        ? 'Follow'
                                                        : 'Connect'}
                                                </Button>
                                            )}
                                        </Box>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                fontSize: '14px',
                                                letterSpacing: '0.0035em',
                                                color: '#808080',
                                                mr: '5px'
                                            }}
                                        >
                                            {feed?.jobTitle}
                                        </Typography>
                                    </Box>
                                    <Box
                                        onClick={handleClose}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        <CloseXIcon />
                                    </Box>
                                </Box>
                            </Box>
                            {feed?.comments?.length ? (
                                <DialogContent dividers>
                                    {feed?.comments?.map((item: any, i) => (
                                        <CommentBox
                                            isFirstComment={i === 0}
                                            key={item.id}
                                            item={item}
                                            setReplyingTo={setReplyingTo}
                                        />
                                    ))}
                                </DialogContent>
                            ) : (
                                <Typography pl="20px">No comments</Typography>
                                )}
                            </Box>
                                
                            <DialogActions
                                sx={{
                                    display: 'block',
                                    pl: 0,
                                    pr: 0,
                                    '& .MuiAlert-root': {
                                        ml: '0 !important'
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        width: '100%',
                                        pl: '20px',
                                        pr: '20px'
                                    }}
                                >
                                    <Box>
                                        <IconButton sx={{ p: 0, m: 0 }}>
                                            <UploadIcon color="#494949" />
                                        </IconButton>
                                        {feed?.comments &&
                                        feed?.comments[0]?.likedByProfessionalIds?.find(
                                            (x) =>
                                                x.id ===
                                                (isProfessional
                                                    ? user?.professionalProfile
                                                          ?.id ?? ''
                                                    : user
                                                          ?.primaryCompanyProfile
                                                          ?.companyId ?? '')
                                        ) ? (
                                            <IconButton
                                                onClick={() =>
                                                    handleLike('remove')
                                                }
                                                sx={{
                                                    m: 0,
                                                    p: 0,
                                                    mr: '11px',
                                                    ml: '22px !important'
                                                }}
                                            >
                                                <LikesIcon />
                                            </IconButton>
                                        ) : (
                                            <IconButton
                                                onClick={() =>
                                                    handleLike('add')
                                                }
                                                sx={{
                                                    maxHeight: '20px',
                                                    maxWidth: '20px',
                                                    m: 0,
                                                    p: 0,
                                                    mr: '11px',
                                                    ml: '22px !important'
                                                }}
                                            >
                                                <FavoriteBorderIcon
                                                    sx={{
                                                        color: '#808080'
                                                    }}
                                                />
                                            </IconButton>
                                        )}
                                        {feed?.comments ? (
                                            <Typography variant="titleSmallBold">
                                                {feed?.comments[0]?.likes}
                                            </Typography>
                                        ) : null}
                                        <IconButton
                                            sx={{ p: 0, m: 0, ml: '10px' }}
                                        >
                                            <JobBookmarkIcon color="#494949" />
                                        </IconButton>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            mt: '18px',
                                            mb: '26px'
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        >
                                            {feed?.comments &&
                                            feed?.comments[0]
                                                ?.likedByProfessionalIds
                                                ?.length > 0 ? (
                                                <>
                                                    {feed?.comments[0]?.likedByProfessionalIds?.map(
                                                        (x, i) =>
                                                            i < 2 ? (
                                                                <Avatar
                                                                    key={feed?.id}
                                                                    sx={{
                                                                        width: '24px',
                                                                        height: '24px'
                                                                        // cursor: 'pointer'
                                                                    }}
                                                                    src={
                                                                        x?.photoUrl
                                                                    }
                                                                    // onClick={() =>
                                                                    //     feed?.comments
                                                                    //         ? navigate(
                                                                    //             `/professional-profile/${feed?.comments[0]?.likedByProfessionalIds[0]?.id}`
                                                                    //         )
                                                                    //         : null
                                                                    // }
                                                                />
                                                            ) : null
                                                    )}
                                                    <Typography
                                                        sx={{
                                                            fontFamily:
                                                                'Open Sans',
                                                            letterSpacing:
                                                                '0.0015em',
                                                            color: '#23282B',
                                                            ml: '12px'
                                                        }}
                                                    >
                                                        Liked by
                                                        <Typography
                                                            sx={{
                                                                fontWeight:
                                                                    '600',
                                                                // cursor: 'pointer',
                                                                '&:hover': {
                                                                    textDecoration:
                                                                        'underline'
                                                                }
                                                            }}
                                                            component="span"
                                                            // onClick={() =>
                                                            //     feed?.comments
                                                            //         ? navigate(
                                                            //             `/professional-profile/${feed?.comments[0]?.likedByProfessionalIds[0]?.id}`
                                                            //         )
                                                            //         : null
                                                            // }
                                                        >
                                                            {' '}
                                                            {
                                                                feed
                                                                    ?.comments[0]
                                                                    ?.likedByProfessionalIds[0]
                                                                    ?.firstName
                                                            }{' '}
                                                        </Typography>
                                                        {feed?.comments &&
                                                        feed?.comments[0]
                                                            ?.likedByProfessionalIds
                                                            ?.length > 1 ? (
                                                            <>
                                                                and{' '}
                                                                <Typography
                                                                    sx={{
                                                                        fontWeight:
                                                                            '600'
                                                                    }}
                                                                    component="span"
                                                                >
                                                                    {' '}
                                                                    others
                                                                </Typography>
                                                            </>
                                                        ) : null}
                                                    </Typography>
                                                </>
                                            ) : null}
                                        </Box>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                letterSpacing: '0.0015em',
                                                color: '#808080',
                                                fontSize: '14px',
                                                lineHeight: '20px'
                                            }}
                                        >
                                            {moment(feed?.createdAt).fromNow()}
                                        </Typography>
                                    </Box>
                                </Box>
                                {replyingTo ? (
                                    <Alert
                                        variant="filled"
                                        severity="info"
                                        onClose={() => setReplyingTo(undefined)}
                                    >
                                        Replying to {replyingTo?.firstName}{' '}
                                        {replyingTo?.lastName}
                                    </Alert>
                                ) : null}
                                <Box
                                    sx={{
                                        background: '#f2f2f2',
                                        border: onFocus
                                            ? '2px solid #49B6FF'
                                            : 'none',
                                        borderTop: onFocus
                                            ? '2px solid #49B6FF'
                                            : '0.5px solid #D9D9D9',
                                        borderRadius: '8px',
                                        padding: '14px 20px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '20px',
                                        width: '100%',
                                        ml: '0 !important'
                                    }}
                                >
                                    <TextField
                                        fullWidth
                                        value={comment}
                                        onChange={(e) =>
                                            setComment(e.target.value)
                                        }
                                        onFocus={() => setOnFocus(true)}
                                        onBlur={() => setOnFocus(false)}
                                        multiline
                                        maxRows={2}
                                        sx={{
                                            '& .MuiOutlinedInput-notchedOutline':
                                                {
                                                    border: '0'
                                                },
                                            input: {
                                                fontSize: '16px',
                                                color: '#23282B'
                                            }
                                        }}
                                        placeholder="Add a comment..."
                                    />
                                    <Button
                                        onClick={handlePost}
                                        variant="contained"
                                        // disabled={!comment}
                                        sx={{
                                            width: '100px',
                                            height: '44px',
                                            background: !comment
                                                ? '#EDEDED'
                                                : '#05668D',
                                            borderRadius: '8px',
                                            textTransform: 'capitalize',
                                            textDecoration: 'none',
                                            fontFamily: 'Open Sans',
                                            fontWeight: '600',
                                            fontSize: '14px',
                                            letterSpacing: '0.001em',
                                            color: !comment ? '#808080' : '#fff'
                                        }}
                                    >
                                        Post
                                    </Button>
                                </Box>
                            </DialogActions>
                        </Grid>
                    </Grid>
                </Dialog>
            )}
            <SnackAlert
                open={showAlert}
                handleClose={() => setShowAlert(false)}
                message={message}
                type={type}
            />
        </>
    );
};
export default CommentsPopup;
