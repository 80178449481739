const LightBriefcase = () => {
    return (
        <svg
            width="20"
            height="22"
            viewBox="0 0 20 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.09 5.98002C18.24 5.04002 16.82 4.57002 14.76 4.57002H14.52V4.53002C14.52 2.85002 14.52 0.77002 10.76 0.77002H9.24004C5.48004 0.77002 5.48004 2.86002 5.48004 4.53002V4.58002H5.24004C3.17004 4.58002 1.76004 5.05002 0.910038 5.99002C-0.0799625 7.09002 -0.0499626 8.57002 0.0500374 9.58002L0.0600374 9.65002L0.137493 10.4633C0.151757 10.6131 0.232421 10.7484 0.358313 10.8307C0.598117 10.9877 0.999462 11.2464 1.24004 11.38C1.38004 11.47 1.53004 11.55 1.68004 11.63C3.39004 12.57 5.27004 13.2 7.18004 13.51C7.27004 14.45 7.68004 15.55 9.87004 15.55C12.06 15.55 12.49 14.46 12.56 13.49C14.6 13.16 16.57 12.45 18.35 11.41C18.41 11.38 18.45 11.35 18.5 11.32C18.8968 11.0958 19.3083 10.8195 19.6835 10.5489C19.7965 10.4673 19.8688 10.3413 19.8842 10.2028L19.9 10.06L19.95 9.59002C19.96 9.53002 19.96 9.48002 19.97 9.41002C20.05 8.40002 20.03 7.02002 19.09 5.98002ZM11.09 12.83C11.09 13.89 11.09 14.05 9.86004 14.05C8.63004 14.05 8.63004 13.86 8.63004 12.84V11.58H11.09V12.83ZM6.91004 4.57002V4.53002C6.91004 2.83002 6.91004 2.20002 9.24004 2.20002H10.76C13.09 2.20002 13.09 2.84002 13.09 4.53002V4.58002H6.91004V4.57002Z"
                fill="#49B6FF"
            />
            <path
                d="M18.8733 12.7344C19.2269 12.5661 19.6342 12.8464 19.5988 13.2364L19.2398 17.1902C19.0298 19.1902 18.2098 21.2302 13.8098 21.2302H6.18984C1.78984 21.2302 0.969844 19.1902 0.759844 17.2002L0.419132 13.4524C0.38409 13.0669 0.782046 12.787 1.13468 12.9465C2.2741 13.462 4.37724 14.3766 5.67641 14.7169C5.84072 14.7599 5.97361 14.8775 6.04556 15.0314C6.65253 16.3295 7.96896 17.0202 9.86984 17.0202C11.752 17.0202 13.085 16.3029 13.694 15.0016C13.766 14.8476 13.8991 14.7301 14.0635 14.6868C15.443 14.3238 17.6816 13.3015 18.8733 12.7344Z"
                fill="#49B6FF"
            />
        </svg>
    );
};

export default LightBriefcase;
