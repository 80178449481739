import { Grid, Link, Typography } from '@mui/material';
import { useState } from 'react';
import { Box } from '@mui/system';
//import Facebook from '../../assets/icons/Facebook';
//import Instagram from '../../assets/icons/Instagram';
import JobFactorIcon from '../../assets/icons/JobFactorIcon';
//import Linkedin from '../../assets/icons/Linkedin';
//import Tiktok from '../../assets/icons/Tiktok';
//import Twitter from '../../assets/icons/Twitter';
//import Youtube from '../../assets/icons/Youtube';
import TwitterIcon from '../../assets/icons/TwitterIcon';
import LinkedinIcon from '../../assets/icons/LinkedinIcon';
import FacebookIcon from '../../assets/icons/FacebookIcon';
import InstaIcon from '../../assets/icons/InstaIcon';
import YoutubeIcon from '../../assets/icons/YoutubeIcon';
import TiktokIcon from '../../assets/icons/TiktokIcon';
import ArrowRightBlue from '../../assets/icons/ArrowRightBlue';
import ArrowDownBlue from '../../assets/icons/ArrowDownBlue';
import ArrowUpWhite from '../../assets/icons/ArrowUpWhite';

const Footer = () => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    const toggleExpansion = () => {
        setIsCollapsed(!isCollapsed);
    };

    const footerStyle = {
        height: isCollapsed ? '0' : 'auto',
        overflow: isCollapsed ? 'hidden' : 'visible'
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const linksHeadings = {
        letterSpacing: '0.00938em',
        color: '#23282B',
        fontWeight: '600',
        fontSize: '20px',
        lineHeight: '28px',
        paddingBottom: '18px',
        fontFamily: 'Open Sans',
        maxWidth: '1512px',
        maxHeight: '492px',
        flexShrink: 0
        // marginRight:'40px'
    };
    const Links = {
        color: '#808080',
        fontSize: '16px',
        lineHeight: '24px',
        cursor: 'pointer',
        paddingBottom: '8px',
        fontFamily: 'Open Sans'
    };
    return (
        <Box
            position="relative"
            component={'div'}
            display={{ lg: 'block', xs: 'none' }}
        >
            <Grid
                container
                spacing={5}
                mt={5}
                mb={1}
                justifySelf={'baseline'}
                alignSelf={'baseline'}
            >
                <Grid item xs={2}>
                    <JobFactorIcon />
                </Grid>
                <Grid item xs={2}>
                    <Typography component={'p'} sx={linksHeadings}>
                        How Jobfactor Works
                    </Typography>
                </Grid>
                <Grid item xs={1.5}>
                    <Typography component={'p'} sx={linksHeadings}>
                        jobfactor
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography component={'p'} sx={linksHeadings}>
                        Professionals
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography component={'p'} sx={linksHeadings}>
                        Company
                    </Typography>
                </Grid>
                <Grid item xs={1.5}>
                    <Typography component={'p'} sx={linksHeadings}>
                        Community
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={1}
                    className="expand-button"
                    onClick={toggleExpansion}
                    width={'24px'}
                    height={'24px'}
                    // position={'absolute'}
                    // right={'20px'}
                    // top={'70px'}
                >
                    {isCollapsed ? <ArrowRightBlue /> : <ArrowDownBlue />}
                </Grid>
            </Grid>
            <Grid container spacing={5} className="footer" style={footerStyle}>
                <Grid item xs={2}></Grid>
                <Grid item xs={2}>
                    <Link underline="hover" component={'p'} sx={Links}>
                        Jobfactor score
                    </Link>
                    <Link underline="hover" component={'p'} sx={Links}>
                        Company ratings
                    </Link>
                    <Link underline="hover" component={'p'} sx={Links}>
                        Reviews and feedbacks
                    </Link>
                </Grid>
                <Grid item xs={1.5}>
                    <Link underline="hover" component={'p'} sx={Links}>
                        About us
                    </Link>
                    <Link underline="hover" component={'p'} sx={Links}>
                        Blog
                    </Link>
                    <Link underline="hover" component={'p'} sx={Links}>
                        Analytics
                    </Link>
                    <Link underline="hover" component={'p'} sx={Links}>
                        News and update
                    </Link>
                </Grid>
                <Grid item xs={2}>
                    <Link underline="hover" component={'p'} sx={Links}>
                        My Jobfactor score
                    </Link>
                    <Link underline="hover" component={'p'} sx={Links}>
                        Browse jobs
                    </Link>
                    <Link underline="hover" component={'p'} sx={Links}>
                        My factor
                    </Link>
                    <Link underline="hover" component={'p'} sx={Links}>
                        Business factor
                    </Link>
                </Grid>
                <Grid item xs={2}>
                    <Link underline="hover" component={'p'} sx={Links}>
                        Contact sales team
                    </Link>
                    <Link underline="hover" component={'p'} sx={Links}>
                        Build your brand
                    </Link>
                    <Link underline="hover" component={'p'} sx={Links}>
                        Post a job
                    </Link>
                    <Link underline="hover" component={'p'} sx={Links}>
                        Business factor
                    </Link>
                    <Link underline="hover" component={'p'} sx={Links}>
                        Enterprise subscription
                    </Link>
                    <Link underline="hover" component={'p'} sx={Links}>
                        Dynamic Recruiting
                    </Link>
                </Grid>
                <Grid item xs={1.5}>
                    <Link underline="hover" component={'p'} sx={Links}>
                        Frequently Asked Questions
                    </Link>
                    <Link underline="hover" component={'p'} sx={Links}>
                        Help and Support
                    </Link>
                    <Link underline="hover" component={'p'} sx={Links}>
                        Privacy and Cookies
                    </Link>
                    <Link underline="hover" component={'p'} sx={Links}>
                        Business factor
                    </Link>
                    <Link underline="hover" component={'p'} sx={Links}>
                        Terms of Service
                    </Link>
                </Grid>
            </Grid>
            <Box
                component="div"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '40px',
                    marginTop: '20px'
                }}
            >
                <Link
                    href="https://www.linkedin.com/company/job-factor/"
                    color="inherit"
                    target="_blank"
                    sx={{
                        transition: 'all .4s ease',
                        cursor: 'pointer',
                        '&:hover': {
                            opacity: 0.8
                        }
                    }}
                >
                    <LinkedinIcon />
                </Link>
                <Link
                    href="https://twitter.com/_jobfactor?t=9xNuozxqPZaQ9ilYibL0iQ&s=09"
                    target="_blank"
                    sx={{
                        transition: 'all .4s ease',
                        cursor: 'pointer',
                        '&:hover': {
                            opacity: 0.8
                        }
                    }}
                >
                    <TwitterIcon />
                </Link>
                <Link
                    href="https://www.facebook.com/profile.php?id=100089853477839&mibextid=ZbWKwL"
                    color="inherit"
                    target="_blank"
                    sx={{
                        transition: 'all .4s ease',
                        cursor: 'pointer',
                        '&:hover': {
                            opacity: 0.8
                        }
                    }}
                >
                    <FacebookIcon />
                </Link>
                <Link
                    href="http://www.instagram.com/officialjobfactor"
                    color="primary"
                    target="_blank"
                    sx={{
                        transition: 'all .4s ease',
                        cursor: 'pointer',
                        '&:hover': {
                            opacity: 0.8
                        }
                    }}
                >
                    <InstaIcon />
                </Link>
                <Link
                    sx={{
                        transition: 'all .4s ease',
                        cursor: 'pointer',
                        '&:hover': {
                            opacity: 0.8
                        }
                    }}
                >
                    <YoutubeIcon />
                </Link>
                <Link
                    href="https://www.tiktok.com/@officialjobfactor?_t=8aFd3ZWiKDF&_r=1"
                    color="rgba(255,255,255,1)"
                    target="_blank"
                    sx={{
                        transition: 'all .4s ease',
                        cursor: 'pointer',
                        '&:hover': {
                            opacity: 0.8
                        }
                    }}
                >
                    <TiktokIcon />
                </Link>
            </Box>
            <Typography
                sx={{
                    color: '#808080',
                    fontSize: '16px',
                    letterSpacing: '0.0015em',
                    textAlign: 'center',
                    marginTop: '30px',
                    fontFamily: 'Open Sans'
                }}
            >
                {' '}
                &#169; 2023, Jobfactor Inc. All rights reserved.
            </Typography>

            <Box
                width={'60px'}
                height={'60px'}
                justifySelf={'end'}
                className="scroll-to-top-button"
                onClick={scrollToTop}
                position={'absolute'}
                right={'20px'}
                bottom={'50px'}
                sx={{
                    transition: 'all .4s ease',
                    cursor: 'pointer',
                    '&:hover': {
                        opacity: 0.8
                    }
                }}
            >
                <ArrowUpWhite />
            </Box>
        </Box>
    );
};

export default Footer;
