import { Box, Typography } from '@mui/material'
import HomeIcon from '../../assets/icons/HomeIcon';
import UserIcon2 from '../../assets/icons/UserIcon2';
import BriefCaseIcon from '../../assets/icons/BriefCaseIcon';
import MessagesIcon from '../../assets/icons/MessagesIcon';
import { IMenu } from '../Navigation/SideNav';
import TransparentBellIcon from '../../assets/icons/TransparentBellIcon';
import { useLocation, useNavigate } from 'react-router-dom';
import NotificationIcon from '../../assets/icons/NotificationIcon';

const BottomTabs = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const professionalRoutes: IMenu[] = [
        {
            icon: HomeIcon,
            label: 'Home',
            route: '/'
        },
        {
            icon: UserIcon2,
            label: 'Community',
            route: '/connections',
        },
        {
            icon: BriefCaseIcon,
            label: 'Jobs',
            route: '/my-jobs',
        },

        {
            icon: NotificationIcon,
            label: 'Notification',
            route: '/notification',
        },
        {
            icon: MessagesIcon,
            label: 'Messages',
            route: '/messages',
        },
    ];

    return (
        <Box
            sx={{
                display: { sm: 'none', xs: 'flex' },
                padding: '10px 16px',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '10px',
                position: 'fixed',
                bottom: 0,
                backgroundColor: '#F2F2F2',
                left: 0,
                right: 0,
                zIndex: 1201,
                boxShadow: ' 0 -5px 25px rgba(0, 0, 0, 0.1)'
            }}
        >
            {professionalRoutes.map(x => {
                const isSelected = x.route === location.pathname;
                return (
                    <Box
                    key={x.label}
                        sx={{
                            gap: '4px',
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            width: '100%'
                        }}
                        onClick={() => navigate(x.route)}
                    >
                        {<x.icon tab={true} isHover={false} isSelected={isSelected} />}
                        <Typography variant='labelMediumRegular' color={isSelected ? '#05668D' : '#23282B'} component={'span'}>
                            {x.label}
                        </Typography>
                    </Box>
                )
            })}
        </Box >
    )
}

export default BottomTabs