
const Folder = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M9.20908 3.1339L9.21015 3.13532L10.3768 4.68532L10.3775 4.68619C10.3856 4.69691 10.3939 4.70806 10.4025 4.71957C10.5039 4.85495 10.6421 5.03938 10.8467 5.14699C11.0745 5.26672 11.3304 5.26797 11.5513 5.26797H13.8763C15.1352 5.26797 16.2575 5.85779 16.9808 6.77951C17.0259 6.83698 17.036 6.88179 17.0371 6.91215C17.0382 6.94527 17.0293 6.98558 17.0018 7.02956C16.9449 7.12063 16.8167 7.20964 16.6379 7.20964H2.66797C2.39183 7.20964 2.16797 6.98578 2.16797 6.70964V5.3513C2.16797 3.59411 3.59411 2.16797 5.3513 2.16797H7.28464C7.91093 2.16797 8.25973 2.26995 8.50096 2.41103C8.75003 2.5567 8.94043 2.77431 9.20908 3.1339Z" fill="#05668D" stroke="#05668D" />
            <path d="M17.3213 8.95704C17.8723 8.95704 18.3195 9.40269 18.3213 9.95365L18.3346 13.8739C18.3346 16.3323 16.3346 18.3323 13.8763 18.3323H6.1263C3.66797 18.3323 1.66797 16.3323 1.66797 13.8739V9.95724C1.66797 9.40496 2.11568 8.95725 2.66796 8.95724L17.3213 8.95704Z" fill="#05668D" />
        </svg>
    )
}

export default Folder