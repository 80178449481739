import { Box } from '@mui/material';
import React from 'react'

const CustomRadio = ({checked, onClick}: {checked: boolean, onClick: () => void}) => {
  return (
      <Box p="8px" bgcolor="#f2f2f2" borderRadius="8px" sx={{cursor: 'pointer'}}>
          <Box
              width="20px"
              height="20px"
              borderRadius="50%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              border={
                  checked
                      ? '2px solid #05668D'
                      : '2px solid #aaa'
              }
          >
              <Box
                  width="16px"
                  height="16px"
                  borderRadius="50%"
                  bgcolor={checked ? '#05668D' : '#fff'}
                  border="1px solid #fff"
                  onClick={onClick}
              ></Box>
          </Box>
      </Box>
  );
}

export default CustomRadio
