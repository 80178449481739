import { Box } from '@mui/material';
import Header from '../components/JobApplication/Header';
import { useAuth } from '../../../utils/context/AuthContext';
import ApplicationList from '../components/JobApplication/ApplicationList';
import { useGetSavedApplicantByCompanyId } from '../../../utils/hooks/api/saved-applicants/useGetSavedApplicantByCompanyId';
import { useEffect, useState } from 'react';
import { JobApplicationItem } from '../types/JobApplicationItem';
import MobileNav from '../../../components/MobileNav/MobileNav';

const SavedApplications = () => {
    const { user } = useAuth();
    const [applicants, setApplicants] = useState<JobApplicationItem[]>([]);
    const companyProfileId = user?.primaryCompanyProfile?.id ?? '';
    const { data, isFetching } =
        useGetSavedApplicantByCompanyId(companyProfileId);

    const updateData = (applicantId: string) => {
        setApplicants(
            data?.filter(
                (x: JobApplicationItem) =>
                    x?.id !== applicantId && x?.isSaved === true
            )
        );
    };

    const route = [
        {
            label: 'Job Postings',
            route: '/job-posting'
        },
        {
            label: 'Applications',
            route: '/job-applications'
        },
        {
            label: 'Saved Applications',
            route: '/save-applications'
        }
    ];

    useEffect(() => {
        if (data) {
            setApplicants(data);
        } else {
            setApplicants([]);
        }
    }, [data]);

    return (
        <Box
            sx={{
                mt: { xs: '21px', sm: 0 },
                pr: { xs: 0, sm: '20px', lg: '40px' }
            }}
        >
            <Box display={{ xs: 'block', sm: 'none' }}>
                <MobileNav list={route} />
            </Box>
            <Header />
            <Box display={{ xs: 'none', sm: 'block', lg: 'none' }}>
                <MobileNav list={route} />
            </Box>
            {isFetching ? null : (
                <ApplicationList
                    data={
                        Array.isArray(applicants) && applicants.length
                            ? applicants
                            : []
                    }
                    updateData={updateData}
                    showMetrics
                    showJobTitle
                />
            )}
        </Box>
    );
};

export default SavedApplications;
