import React from 'react';

function GoogleIcon() {
    return (
        <>
            <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M21.9892 11.07C21.9392 10.56 21.5092 10.18 20.9992 10.18H13.4492C12.8992 10.18 12.4492 10.63 12.4492 11.18V12.89C12.4492 13.44 12.8992 13.89 13.4492 13.89H17.9592C17.8492 14.81 17.2492 16.2 15.9192 17.13C15.0692 17.72 13.9392 18.13 12.4492 18.13C12.3792 18.13 12.3192 18.13 12.2492 18.12C9.69922 18.04 7.53922 16.33 6.75922 13.98C6.54922 13.35 6.42922 12.69 6.42922 12C6.42922 11.31 6.54922 10.64 6.74922 10.02C6.80922 9.83999 6.87922 9.66001 6.95922 9.48001C7.87922 7.41001 9.88922 5.95 12.2492 5.88C12.3092 5.87 12.3792 5.87 12.4492 5.87C13.8792 5.87 14.9492 6.33999 15.6992 6.85999C16.0892 7.12999 16.6092 7.06999 16.9492 6.73999L18.3392 5.38C18.7792 4.95 18.7392 4.21999 18.2392 3.85999C16.6492 2.68999 14.7092 2 12.4492 2C12.3792 2 12.3192 2.00001 12.2492 2.01001C8.41922 2.08001 5.12922 4.30001 3.51922 7.51001C2.83922 8.87001 2.44922 10.39 2.44922 12C2.44922 13.61 2.83922 15.13 3.51922 16.49H3.52922C5.13922 19.7 8.42922 21.92 12.2492 21.99C12.3192 22 12.3792 22 12.4492 22C15.1492 22 17.4192 21.11 19.0692 19.58C20.9592 17.83 22.0492 15.27 22.0492 12.22C22.0492 11.79 22.0292 11.42 21.9892 11.07Z"
                    fill="#05668D"
                />
            </svg>
        </>
    );
}

export default GoogleIcon;
