import React from 'react'

const MenuSettings = ({isActive=false}) => {
  return (
      <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
          <path
              d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z"
              stroke={isActive ? '#05668D' : '#494949'}
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
          />
          <path
              d="M1.66797 10.7339V9.26718C1.66797 8.40052 2.3763 7.68385 3.2513 7.68385C4.75964 7.68385 5.3763 6.61718 4.61797 5.30885C4.18464 4.55885 4.44297 3.58385 5.2013 3.15052L6.64297 2.32552C7.3013 1.93385 8.1513 2.16718 8.54297 2.82552L8.63464 2.98385C9.38464 4.29218 10.618 4.29218 11.3763 2.98385L11.468 2.82552C11.8596 2.16718 12.7096 1.93385 13.368 2.32552L14.8096 3.15052C15.568 3.58385 15.8263 4.55885 15.393 5.30885C14.6346 6.61718 15.2513 7.68385 16.7596 7.68385C17.6263 7.68385 18.343 8.39218 18.343 9.26718V10.7339C18.343 11.6005 17.6346 12.3172 16.7596 12.3172C15.2513 12.3172 14.6346 13.3838 15.393 14.6922C15.8263 15.4505 15.568 16.4172 14.8096 16.8505L13.368 17.6755C12.7096 18.0672 11.8596 17.8339 11.468 17.1755L11.3763 17.0172C10.6263 15.7089 9.39297 15.7089 8.63464 17.0172L8.54297 17.1755C8.1513 17.8339 7.3013 18.0672 6.64297 17.6755L5.2013 16.8505C4.44297 16.4172 4.18464 15.4422 4.61797 14.6922C5.3763 13.3838 4.75964 12.3172 3.2513 12.3172C2.3763 12.3172 1.66797 11.6005 1.66797 10.7339Z"
              stroke={isActive ? '#05668D' : '#494949'}
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
          />
      </svg>
  );
}

export default MenuSettings
