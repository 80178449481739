import { Box, Button, Modal, Radio, Typography } from '@mui/material';
import React, { useState } from 'react';
import ArrowBack from '../../assets/icons/ArrowBack';
import CloseXIcon from '../../assets/icons/CloseXIcon';
import EducationSummary from '../Users/components/EducationSummary';
import WorkSummary from '../Users/components/WorkSummary';
import cambridge from '../../assets/images/cambridge.jpg';
import { Ranking } from '../Users/types/Ranking';
import GenericCompanyLogo from '../../assets/images/generic-company-logo.png';
import { logger } from '../../utils/Helper/logger';
import CustomRadio from './CustomRadio';
import SnackAlert, { SnackAlertType } from '../../components/Snackbar';
import { experienceLevels } from '../Reviews/constants';
import { ISkillType } from './Resume';

const ExperienceModal = ({
    open,
    onClose,
    user,
    setEducation,
    education,
    workExperiences,
    setWorkExperiences,
    skills,
    setSkills
}: {
    open: string;
    onClose: () => void;
    user: any;
    setEducation: any;
    education: any;
    workExperiences: any;
    setWorkExperiences: any;
    skills: any;
    setSkills: any;
}) => {
    const [selectedRadio, setSelectedRadio] = useState<any | []>([]);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [openSucessSnackbar, setOpenSuccessSnackbar] = useState(false);
    const [message, setMessage] = useState('');
    const [type, setType] = useState<SnackAlertType>('error');
    return (
        <Modal open={Boolean(open)} onClose={onClose}>
            <Box
                top="50%"
                left="50%"
                width={{ xs: '100%', sm: '600px', lg: '800px' }}
                height={{ xs: '100%', sm: '500px' }}
                bgcolor="#fff"
                sx={{ transform: 'translate(-50%, -50%)' }}
                px={{ xs: '16px', sm: '20px', lg: '32px' }}
                position="absolute"
                pb="90px"
            >
                <Box
                    overflow="auto"
                    width="100%"
                    height="100%"
                    sx={{ '&::-webkit-scrollbar': { display: 'none' } }}
                >
                    <Box
                        pt={{ xs: '16px', sm: '30px' }}
                        pb={{ xs: '16px', sm: '22px' }}
                        display="flex"
                        alignItems="center"
                        gap="16px"
                        borderBottom="2px solid #D9D9D9"
                        mb={{ xs: '8px', sm: '12px' }}
                    >
                        <Box
                            display={{ xs: 'block', sm: 'none' }}
                            onClick={() => {
                                onClose();
                                // setSelectedRadio([]);
                            }}
                        >
                            <ArrowBack />
                        </Box>
                        <Typography
                            color="#23282B"
                            fontSize="20px"
                            fontWeight={600}
                            flex={1}
                        >
                            {open}
                        </Typography>
                        <Box
                            display={{ xs: 'none', sm: 'block' }}
                            onClick={() => {
                                onClose();
                                setSelectedRadio([]);
                            }}
                        >
                            <CloseXIcon />
                        </Box>
                        <Button
                            variant="contained"
                            sx={{
                                display: { xs: 'block', sm: 'none' },
                                bgcolor: '#05668D',
                                width: '71px',
                                height: '40px',
                                borderRadius: '8px',
                                textTransform: 'none',
                                '&:hover': { bgcolor: '#05668D' },
                                fontSize: '16px',
                                fontWeight: 600,
                                color: '#fff'
                            }}
                        >
                            Add
                        </Button>
                    </Box>
                    <Box>
                        {open === 'Education'
                            ? user?.qualifications?.map((item: any, i: any) => (
                                  <Box
                                      display="flex"
                                      alignItems="center"
                                      gap="16px"
                                  >
                                      <Box flex={1}>
                                          <EducationSummary
                                              key={item.id}
                                              data={{
                                                  university: {
                                                      name: item.school,
                                                      image: cambridge
                                                  },
                                                  major: item.degree,
                                                  yearStarted: item.startDate,
                                                  yearEnded: item.endDate
                                              }}
                                          />
                                      </Box>
                                      <CustomRadio
                                          checked={selectedRadio.find(
                                              (x: any) => x.id === item.id
                                          )}
                                          onClick={() => {
                                              if (
                                                  selectedRadio.find(
                                                      (x: any) =>
                                                          x.id === item.id
                                                  )
                                              ) {
                                                  setSelectedRadio(
                                                      selectedRadio.filter(
                                                          (y: any) =>
                                                              y.id !== item.id
                                                      )
                                                  );
                                              } else {
                                                  if (
                                                      education.find(
                                                          (x: any) =>
                                                              x.id === item.id
                                                      )
                                                  ) {
                                                      setOpenSnackbar(true);
                                                      setMessage(
                                                          'This item has previously been added to Resume'
                                                      );
                                                      setType('error');
                                                  } else {
                                                      setSelectedRadio([
                                                          ...selectedRadio,
                                                          item
                                                      ]);
                                                  }
                                              }
                                          }}
                                      />
                                  </Box>
                              ))
                            : open === 'Work Experience'
                            ? user?.employments?.map((item: any) => (
                                  <Box
                                      display="flex"
                                      alignItems="center"
                                      gap="16px"
                                  >
                                      <Box flex={1}>
                                          <WorkSummary
                                              key={item.id}
                                              data={{
                                                  employer: {
                                                      name: item.companyName,
                                                      image: GenericCompanyLogo
                                                  },
                                                  title: item.jobTitle,
                                                  yearStarted: item.startDate,
                                                  yearEnded: item.endDate,
                                                  isCurrentPosition:
                                                      item.isCurrentPosition,
                                                  rankings: [
                                                      Ranking.Leader,
                                                      Ranking.Expert,
                                                      Ranking.Advanced,
                                                      Ranking.Experienced
                                                  ]
                                              }}
                                          />
                                      </Box>
                                      <CustomRadio
                                          checked={selectedRadio.find(
                                              (x: any) => x.id === item.id
                                          )}
                                          onClick={() => {
                                              if (
                                                  selectedRadio.find(
                                                      (x: any) =>
                                                          x.id === item.id
                                                  )
                                              ) {
                                                  setSelectedRadio(
                                                      selectedRadio.filter(
                                                          (y: any) =>
                                                              y.id !== item.id
                                                      )
                                                  );
                                              } else {
                                                  if (
                                                      workExperiences.find(
                                                          (x: any) =>
                                                              x.id === item.id
                                                      )
                                                  ) {
                                                      setOpenSnackbar(true);
                                                      setMessage(
                                                          'This item has previously been added to Resume'
                                                      );
                                                      setType('error');
                                                  } else {
                                                      setSelectedRadio([
                                                          ...selectedRadio,
                                                          item
                                                      ]);
                                                  }
                                              }
                                          }}
                                      />
                                  </Box>
                              ))
                            : open === 'Skills'
                            ? user?.skills
                                  ?.sort((a: ISkillType, b: ISkillType) =>
                                      a?.rating < b?.rating ? 1 : -1
                                  )
                                  ?.map((item: ISkillType, i: number) => {
                                      let level = experienceLevels?.find(
                                          (y) => y.key === item.rating
                                      );
                                      return (
                                          <Box
                                              display="flex"
                                              alignItems="center"
                                              gap="16px"
                                              mb="16px"
                                          >
                                              <Box flex={1}>
                                                  <Typography>
                                                      {item.name}
                                                  </Typography>
                                              </Box>
                                              <CustomRadio
                                                  checked={selectedRadio.find(
                                                      (x: any) =>
                                                          x.name === item.name
                                                  )}
                                                  onClick={() => {
                                                      if (
                                                          selectedRadio.find(
                                                              (x: any) =>
                                                                  x.name ===
                                                                  item.name
                                                          )
                                                      ) {
                                                          setSelectedRadio(
                                                              selectedRadio.filter(
                                                                  (y: any) =>
                                                                      y.name !==
                                                                      item.name
                                                              )
                                                          );
                                                      } else {
                                                          if (
                                                              skills.find(
                                                                  (x: any) =>
                                                                      x.name ===
                                                                      item.name
                                                              )
                                                          ) {
                                                              setOpenSnackbar(
                                                                  true
                                                              );
                                                              setMessage(
                                                                  'This item has previously been added to Resume'
                                                              );
                                                              setType('error');
                                                          } else {
                                                              setSelectedRadio([
                                                                  ...selectedRadio,
                                                                  item
                                                              ]);
                                                          }
                                                      }
                                                  }}
                                              />
                                          </Box>
                                      );
                                  })
                            : null}
                    </Box>
                </Box>
                <Box
                    position="fixed"
                    bottom={0}
                    right={0}
                    left={0}
                    p={{ sm: '20px', lg: '20px 32px' }}
                    bgcolor="#f2f2f2"
                    display={{ xs: 'none', sm: 'block' }}
                >
                    <Button
                        variant="contained"
                        sx={{
                            bgcolor: '#05668D',
                            height: '48px',
                            borderRadius: '8px',
                            textTransform: 'none',
                            '&:hover': { bgcolor: '#05668D' },
                            fontSize: '16px',
                            fontWeight: 600,
                            color: '#fff'
                        }}
                        onClick={() => {
                            if (open === 'Education') {
                                setEducation([...education, ...selectedRadio]);
                            }
                            if (open === 'Work Experience') {
                                setWorkExperiences([
                                    ...workExperiences,
                                    ...selectedRadio
                                ]);
                            }
                            if (open === 'Skills') {
                                setSkills([...skills, ...selectedRadio]);
                            }
                            if (selectedRadio.length > 0) {
                                setOpenSuccessSnackbar(true);
                            } else {
                                setOpenSnackbar(true);
                                setMessage('Select an item to add');
                                setType('warning');
                            }
                            setSelectedRadio([]);
                        }}
                    >
                        Add
                    </Button>
                </Box>
                <SnackAlert
                    open={openSnackbar}
                    handleClose={() => setOpenSnackbar(false)}
                    message={message}
                    type={type}
                />
                <SnackAlert
                    open={openSucessSnackbar}
                    handleClose={() => setOpenSuccessSnackbar(false)}
                    message="Item(s) has been successfully added to Resume"
                    type="success"
                />
            </Box>
        </Modal>
    );
};

export default ExperienceModal;
