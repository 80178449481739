import 'react-phone-number-input/style.css';
import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import InputBase from '@mui/material/InputBase';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { List, MenuItem, Radio, Select } from '@mui/material';
import dayjs from 'dayjs';
import {
    getCountryCallingCode,
    isPossiblePhoneNumber
} from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import PhoneInput from 'react-phone-number-input';
import CommonDialog from '../../common/CommonDialog';
import Image from '../../../components/Image';
import { Address } from '../../../utils/hooks/api/users/types';
import Camera from '../../../assets/icons/CameraIconMui';
import Trash from '../../../assets/icons/TrashIconMui';
import { useAuth } from '../../../utils/context/AuthContext';
import ProfileInfoListItem from '../../Users/components/ProfileInfoListItem';
import ProfileFormIcon from '../../../assets/icons/ProfileFormIcon';
import Dropdown from '../../../assets/icons/Dropdown';
import Flag from '../../../assets/icons/Flag';
import { useQuery, useQueryClient } from 'react-query';
import { useGetCountries } from '../../../utils/hooks/api/cityState/useGetCountries';
import SnackAlert from '../../../components/Snackbar';
import { companySizes } from '../../../utils/Helper/helper';
import BuildingFormIcon from '../../../assets/icons/BuildingFormIcon';
import UserFormGroup from '../../../assets/icons/UserFormGroup';
import SummaryForm from '../../../assets/icons/SummaryForm';
import { useGetCompanyProfileById } from '../../../utils/hooks/api/company-profile/useGetCompanyProfile';
import externalAxiosInstance from '../../../utils/hooks/api/externalAxiosConfig';
import usePatchCompanyProfile from '../../../utils/hooks/api/company-profile/usePatchCompanyProfile';
import { QueryKeys } from '../../../utils/hooks/api/QueryKey';
import { PatchCompanyAccountRequest } from '../../../utils/hooks/api/company-profile/types';
import Calendar from './Calendar';
import { useUploadCompanyProfilePhoto } from '../../../utils/hooks/api/users/useUploadCompanyProfilePhoto';

interface EditProfileModalProps {
    open: boolean;
    handleClose: () => void;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ICompanyInfo {
    companyName: string;
    industry: string;
    address: Address;
    phoneNumber: string;
    companySize: string;
    yearFounded: string;
    tagline: string;
}

const EditProfileModal = (props: EditProfileModalProps) => {
    const { open, setOpen, handleClose } = props;
    const imageRef = useRef<HTMLInputElement>(null);
    const { user, setUser } = useAuth();
    const [message, setMessage] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [type, setType] = useState<'success' | 'info' | 'warning' | 'error'>(
        'info'
    );
    const [country, setCountry] = useState({ name: '', iso2: '' });
    const [selectedState, setSelectedState] = useState({
        value: '',
        iso2: ''
    });
    const [disableStateDropDown, setDisableDropdown] = useState(false);
    const [selectedCity, setSelectedCity] = useState('');
    const [statesList, setStatesList] = useState([]);
    const [citiesList, setCitiesList] = useState([]);
    const [disableCityDropDown, setDisableCityDropdown] = useState(false);
    const queryClient = useQueryClient();
    const { data: countryList } = useGetCountries();
    const { data: company } = useGetCompanyProfileById(
        user?.primaryCompanyProfile?.companyId ?? ''
    );
    const updateInfo = usePatchCompanyProfile();
    const uploadImage = useUploadCompanyProfilePhoto();

    const {
        control,
        handleSubmit,
        register,
        formState: { errors },
        setValue,
        clearErrors,
        resetField,
        setError
    } = useForm<ICompanyInfo>();

    const handleOnClose = () => {
        setOpen(false);
    };

    const handleChangeFile = (e: any) => {
        const file = e.target.files[0];
        console.log(file);
        let data = {
            profileId: user?.primaryCompanyProfile?.id ?? '',
            file: file
        };
        uploadImage.mutate(data, {
            onSuccess: (res) => {
                let tempData = {
                    ...user,
                    primaryCompanyProfile: {
                        ...user?.primaryCompanyProfile,
                        photoUrl: res
                    }
                };
                queryClient.invalidateQueries(
                    QueryKeys.RetrieveCompanyProfileById
                );
                setUser(tempData);
                setMessage('Profile Image Updated Successfully.');
                setType('success');
                setShowAlert(true);
            },
            onError: (res) => {
                setMessage('Error occured please try again!');
                setType('error');
                setShowAlert(true);
            }
        });
    };

    const { data: states } = useQuery(
        ['country', country],
        async () => {
            const result = await externalAxiosInstance.get(
                `/countries/${country?.iso2}/states`
            );
            const sortedResult = result.data.sort(
                (a: { name: string }, b: { name: string }) =>
                    a.name > b.name ? 1 : -1
            );
            setStatesList(sortedResult);
            if (result.data.length === 0) {
                setDisableDropdown(true);
                resetField('address.stateProvince');
            } else {
                setDisableDropdown(false);
            }
        },
        {
            refetchOnWindowFocus: false,
            enabled: country?.iso2 ? true : false //disable the query:
            //this is how we keep it from running on component mount.
        }
    );

    const { data: cities } = useQuery(
        ['state', selectedState.iso2],
        async () => {
            const result = await externalAxiosInstance.get(
                `/countries/${country?.iso2}/states/${selectedState.iso2}/cities`
            );
            const sortedResult = result.data.sort(
                (a: { name: string }, b: { name: string }) =>
                    a.name > b.name ? 1 : -1
            );
            setCitiesList(sortedResult);
            if (result.data.length === 0) {
                setDisableCityDropdown(true);
                resetField('address.city');
            } else {
                setDisableCityDropdown(false);
            }
        },
        {
            refetchOnWindowFocus: false,
            enabled: selectedState?.iso2 ? true : false //disable the query:
            //this is how we keep it from running on component mount.
        }
    );

    const onSubmit: SubmitHandler<ICompanyInfo> = async (data) => {
        console.log(data);
        if (!isPossiblePhoneNumber(data?.phoneNumber)) {
            //
            setError('phoneNumber', {
                type: 'required',
                message: 'Invalid Phone number'
            });
            return;
        }

        const temp: PatchCompanyAccountRequest = {
            tagLine: data?.tagline ?? company?.tagLine,
            bio: company.bio,
            companyName: data?.companyName ?? company?.primaryCompany?.name,
            industry: data?.industry ?? company.industry,
            emailAddress:
                user?.primaryCompanyProfile?.emailAddress ??
                company.emailAddress,
            companySize:
                companySizes.find((x) => x.key === data?.companySize)?.value ??
                company.companySize,
            yearFounded: data.yearFounded ?? company.yearFounded,
            address: {
                street1: data.address.street1 ?? company.address.street1,
                street2: company?.address?.street2 ?? '',
                stateProvince:
                    data.address.stateProvince ?? company.address.stateProvince,
                zipPostCode:
                    data.address.zipPostCode ?? company.address.zipPostCode,
                country: country?.name ?? company.address.country,
                city: data.address.city ?? company.address.city
            },
            companyProfileId: user?.primaryCompanyProfile?.id ?? '',
            phoneNumber: data?.phoneNumber
        };
        setUser({
            ...user,
            primaryCompanyProfile: {
                ...user?.primaryCompanyProfile,
                ...temp
            }
        });
        updateInfo.mutate(temp, {
            onSuccess: (res) => {
                queryClient.invalidateQueries(
                    QueryKeys.RetrieveCompanyProfileById
                );
                console.log('onSuccess', res);
                handleClose();
            },
            onError: (error) => {
                console.log('onError', error);
                setType('error');
                setMessage('Error occured please try again!');
                setShowAlert(true);
            }
        });
    };

    useEffect(() => {
        if (open) {
            // setImage(user?.professionalProfile?.photoUrl ?? '');
            setValue('companyName', company?.primaryCompany?.name ?? '');
            setValue('industry', company?.industry ?? '');
            setValue('yearFounded', company?.yearFounded ?? '');
            setValue(
                'companySize',
                companySizes.find((x) => x.value === company?.companySize)
                    ?.key ?? ''
            );
            setValue('tagline', company?.tagLine ?? '');
            setValue('phoneNumber', company?.phoneNumber ?? '');
            setValue('address.city', company?.address?.city ?? '');
            setValue('address.country', company?.address?.country ?? '');
            setValue('address.zipPostCode', company?.address?.zipPostCode);
            setValue('address.stateProvince', company?.address?.stateProvince);
            setValue('address.street1', company?.address?.street1 ?? '');
            setSelectedState({
                value: company?.address?.stateProvince ?? '',
                iso2: ''
            });
            setSelectedCity(company?.address?.city ?? '');
            setCountry({
                iso2: company?.address?.country ?? '',
                name: company?.address?.country ?? ''
            });
        }
    }, [open, company, setValue]);

    return (
        <CommonDialog
            open={open}
            onClose={handleOnClose}
            title="Edit profile info"
            contentPaddingX={false}
            actions={
                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Button
                        variant="outlined"
                        sx={{
                            backgroundColor: 'transparent',
                            width: 'auto',
                            height: '36px',
                            borderRadius: '20px',
                            lineHeight: 1
                        }}
                        startIcon={<PeopleAltIcon htmlColor="#49B6FF" />}
                        endIcon={<ArrowDropDownIcon />}
                    >
                        <Typography
                            fontWeight={400}
                            fontSize={14}
                            sx={{
                                textTransform: 'none'
                            }}
                            lineHeight={1}
                        >
                            Connections
                        </Typography>
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ color: '#FFFFFF', width: '150px' }}
                        onClick={handleSubmit(onSubmit)}
                    >
                        <Typography
                            fontWeight={600}
                            fontSize={14}
                            sx={{
                                textTransform: 'none',
                                py: 0.75
                            }}
                        >
                            Save
                        </Typography>
                    </Button>
                </Grid>
            }
        >
            <Box bgcolor="#fff" overflow="hidden" sx={{ pt: '16px' }}>
                <Box position="relative" pb={6} mt={2}>
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-around"
                        position="relative"
                        height="200px"
                    >
                        <img
                            style={{
                                height: '200px',
                                width: '100%',
                                objectFit: 'cover',
                                display: 'block',
                                position: 'absolute'
                            }}
                            alt="Cover"
                            src={user?.primaryCompanyProfile?.photoUrl}
                        />

                        <Grid container gap={2.5} width="auto">
                            <IconButton
                                style={{
                                    backgroundColor: '#0000003F',
                                    color: '#FFFFFF',
                                    width: '44px',
                                    height: '44px'
                                }}
                            >
                                <Camera />
                            </IconButton>
                            <IconButton
                                style={{
                                    backgroundColor: '#0000003F',
                                    color: '#FFFFFF',
                                    width: '44px',
                                    height: '44px'
                                }}
                            >
                                <Trash />
                            </IconButton>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        position={'absolute'}
                        bottom={0}
                        justifyContent="center"
                        alignItems="center"
                        width="150px"
                        height="150px"
                        ml={4}
                    >
                        <Box
                            borderRadius="50%"
                            overflow="hidden"
                            width="fit-content"
                            position="absolute"
                            bottom={0}
                        >
                            <Image
                                src={
                                    user?.primaryCompanyProfile?.photoUrl ?? ''
                                }
                                sx={{
                                    width: '150px',
                                    height: '150px',
                                    objectFit: 'cover'
                                }}
                                alt="Ronald Richard"
                                border="3px #fff solid"
                                borderRadius="50%"
                                display="block"
                            />
                        </Box>
                        <Box mt={9}>
                            <IconButton
                                style={{
                                    backgroundColor: '#0000003F',
                                    color: '#FFFFFF',
                                    width: '44px',
                                    height: '44px'
                                }}
                                onClick={() => imageRef?.current?.click()}
                            >
                                <Camera />
                                <input
                                    type="file"
                                    onChange={handleChangeFile}
                                    ref={imageRef}
                                    style={{ display: 'none' }}
                                    accept="image/png, image/gif, image/jpeg"
                                />
                            </IconButton>
                        </Box>
                    </Grid>
                </Box>
            </Box>
            <Box
                sx={{
                    width: '90%',
                    mx: 'auto'
                }}
            >
                <List
                    sx={{
                        width: '100%'
                    }}
                    component="nav"
                >
                    <ProfileInfoListItem
                        title="Profile URL"
                        subtitle={`${process.env.REACT_APP_BASE_URL}/about-company`}
                        onClick={() => {}}
                    />
                    <ProfileInfoListItem
                        title="Email"
                        subtitle={user?.primaryCompanyProfile?.emailAddress}
                        onClick={() => {
                            /* handle navigate email link */
                        }}
                    />
                </List>
                <Grid container gap="20px" wrap="nowrap">
                    <Grid item xs={6}>
                        <Box sx={{ position: 'relative' }}>
                            <InputLabel
                                sx={{
                                    color: errors.companyName?.message
                                        ? 'red'
                                        : '#ADAAAA',
                                    fontSize: '14px',
                                    position: 'absolute',
                                    top: '8px',
                                    left: '72px',
                                    zIndex: 1,
                                    fontFamily: 'Open Sans'
                                }}
                                htmlFor="companyName"
                            >
                                Company Name *
                            </InputLabel>
                            <Controller
                                {...register('companyName', {
                                    required: 'Required field'
                                })}
                                name="companyName"
                                control={control}
                                render={({
                                    field: { onChange, value },
                                    fieldState: { error },
                                    formState
                                }) => (
                                    <InputBase
                                        required
                                        value={value}
                                        onChange={onChange}
                                        inputProps={{
                                            autoComplete: '',
                                            form: {
                                                autoComplete: 'off'
                                            },
                                            inputMode: 'text'
                                        }}
                                        id="companyName"
                                        startAdornment={<ProfileFormIcon />}
                                        rows={1}
                                        sx={{
                                            backgroundColor: '#FCFBF8',
                                            width: '100%',
                                            height: '70px',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                            padding: '0px 16px',
                                            fontFamily: 'open sans',
                                            color: '#23282B',
                                            borderBottom: '1px solid #C5C5C5',
                                            mb: '20px',
                                            '& 	.MuiInputBase-input': {
                                                ml: '20px',
                                                position: 'relative',
                                                top: '15px'
                                            }
                                        }}
                                    />
                                )}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{ position: 'relative' }}>
                            <InputLabel
                                sx={{
                                    color: errors.industry?.message
                                        ? 'red'
                                        : '#ADAAAA',
                                    fontSize: '14px',
                                    position: 'absolute',
                                    top: '8px',
                                    left: '72px',
                                    zIndex: 1,
                                    fontFamily: 'Open Sans'
                                }}
                                htmlFor="industry"
                            >
                                Industry *
                            </InputLabel>
                            <Controller
                                {...register('industry', {
                                    required: 'Required field'
                                })}
                                name="industry"
                                control={control}
                                render={({
                                    field: { onChange, value },
                                    fieldState: { error },
                                    formState
                                }) => (
                                    <InputBase
                                        required
                                        value={value}
                                        onChange={onChange}
                                        inputProps={{
                                            autoComplete: '',
                                            form: {
                                                autoComplete: 'off'
                                            },
                                            inputMode: 'text'
                                        }}
                                        id="industry"
                                        startAdornment={<BuildingFormIcon />}
                                        rows={1}
                                        sx={{
                                            backgroundColor: '#FCFBF8',
                                            width: '100%',
                                            height: '70px',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                            padding: '0px 16px',
                                            fontFamily: 'open sans',
                                            color: '#23282B',
                                            borderBottom: '1px solid #C5C5C5',
                                            mb: '20px',
                                            '& 	.MuiInputBase-input': {
                                                ml: '20px',
                                                position: 'relative',
                                                top: '15px'
                                            }
                                        }}
                                    />
                                )}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Grid container gap="20px" wrap="nowrap" mb={'20px'}>
                    <Grid
                        item
                        xs={6}
                        sx={{
                            '& .MuiInputBase-root': {
                                height: '70px !important'
                            }
                        }}
                    >
                        <Box sx={{ position: 'relative' }}>
                            <Controller
                                {...register('yearFounded')}
                                name="yearFounded"
                                control={control}
                                render={({
                                    field: { onChange, value },
                                    fieldState: { error },
                                    formState
                                }) => (
                                    <Calendar
                                        error={
                                            errors.yearFounded?.message
                                                ? true
                                                : false
                                        }
                                        label="Year Founded"
                                        defaultValue={value}
                                        handleChange={(
                                            e: dayjs.Dayjs | null
                                        ) => {
                                            onChange(dayjs(e).format('YYYY'));
                                            if (!e) {
                                                setError('yearFounded', {
                                                    type: 'required',
                                                    message: '*'
                                                });
                                            } else {
                                                clearErrors('yearFounded');
                                            }
                                        }}
                                    />
                                )}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{ position: 'relative' }}>
                            <InputLabel
                                sx={{
                                    color: errors.companySize?.message
                                        ? 'red'
                                        : '#ADAAAA',
                                    fontSize: '14px',
                                    position: 'absolute',
                                    top: '8px',
                                    left: '72px',
                                    zIndex: 1,
                                    fontFamily: 'Open Sans'
                                }}
                            >
                                Company Size *
                            </InputLabel>

                            <Controller
                                {...register('companySize')}
                                name="companySize"
                                control={control}
                                render={({
                                    field: { onChange, value },
                                    fieldState: { error, isTouched },
                                    formState
                                }) => (
                                    <Select
                                        value={value}
                                        onChange={onChange}
                                        fullWidth
                                        variant="filled"
                                        IconComponent={() => <Dropdown />}
                                        sx={{
                                            backgroundColor: '#FCFBF8',
                                            width: '100%',
                                            height: '70px',
                                            padding: '0px 16px',
                                            fontSize: 14,
                                            color: '#23282B',
                                            '& 	.MuiInputBase-input': {
                                                ml: '20px',
                                                top: '8px',
                                                position: 'relative'
                                            },
                                            '& 	.MuiInputBase-input:focus': {
                                                backgroundColor: 'transparent'
                                            }
                                        }}
                                        renderValue={(e) => (
                                            <p>{value || ''}</p>
                                        )}
                                        startAdornment={<UserFormGroup />}
                                        defaultValue={''}
                                    >
                                        {companySizes?.map((item: any) => (
                                            <MenuItem
                                                key={item.key}
                                                value={item.key}
                                            >
                                                <Radio
                                                    checked={value === item.key}
                                                />{' '}
                                                {item.key}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Grid container gap="20px" wrap="nowrap">
                    <Grid item xs={12}>
                        <Box sx={{ position: 'relative' }}>
                            <InputLabel
                                sx={{
                                    color: errors.tagline?.message
                                        ? 'red'
                                        : '#ADAAAA',
                                    fontSize: '14px',
                                    position: 'absolute',
                                    top: '8px',
                                    left: '72px',
                                    zIndex: 1,
                                    fontFamily: 'Open Sans'
                                }}
                                htmlFor="tagline"
                            >
                                Company tagline or summary *
                            </InputLabel>
                            <Controller
                                {...register('tagline', {
                                    required: 'Required field'
                                })}
                                name="tagline"
                                control={control}
                                render={({
                                    field: { onChange, value },
                                    fieldState: { error },
                                    formState
                                }) => (
                                    <InputBase
                                        required
                                        value={value}
                                        onChange={onChange}
                                        inputProps={{
                                            autoComplete: '',
                                            form: {
                                                autoComplete: 'off'
                                            },
                                            inputMode: 'text'
                                        }}
                                        id="tagline"
                                        startAdornment={<SummaryForm />}
                                        rows={1}
                                        sx={{
                                            backgroundColor: '#FCFBF8',
                                            width: '100%',
                                            height: '70px',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                            padding: '0px 16px',
                                            fontFamily: 'open sans',
                                            color: '#23282B',
                                            borderBottom: '1px solid #C5C5C5',
                                            mb: '20px',
                                            '& 	.MuiInputBase-input': {
                                                ml: '20px',
                                                position: 'relative',
                                                top: '15px'
                                            }
                                        }}
                                    />
                                )}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Grid container gap="20px" wrap="nowrap" mb={'20px'}>
                    <Grid item xs={6}>
                        <Box sx={{ position: 'relative' }}>
                            <InputLabel
                                sx={{
                                    color: errors.address?.country?.message
                                        ? 'red'
                                        : '#ADAAAA',
                                    fontSize: '14px',
                                    position: 'absolute',
                                    top: '8px',
                                    left: '72px',
                                    zIndex: 1,
                                    fontFamily: 'Open Sans'
                                }}
                            >
                                Country *
                            </InputLabel>

                            <Controller
                                {...register('address.country', {
                                    required: '*'
                                })}
                                name="address.country"
                                control={control}
                                render={({
                                    field: { onChange, value },
                                    fieldState: { error, isTouched },
                                    formState
                                }) => (
                                    <Select
                                        value={value}
                                        onChange={(e) => {
                                            onChange(e);
                                            console.log(e);
                                            setSelectedState({
                                                value: '',
                                                iso2: ''
                                            });
                                            setSelectedCity('');
                                            resetField('address.stateProvince');
                                            resetField('address.city');
                                            if (statesList.length === 0) {
                                                clearErrors(
                                                    'address.stateProvince'
                                                );
                                            }
                                            if (citiesList.length === 0) {
                                                clearErrors('address.city');
                                            }
                                        }}
                                        fullWidth
                                        variant="filled"
                                        IconComponent={() => <Dropdown />}
                                        sx={{
                                            backgroundColor: '#FCFBF8',
                                            width: '100%',
                                            height: '70px',
                                            padding: '0px 16px',
                                            fontSize: 14,
                                            color: '#23282B',
                                            '& 	.MuiInputBase-input': {
                                                ml: '20px',
                                                top: '8px',
                                                position: 'relative'
                                            },
                                            '& 	.MuiInputBase-input:focus': {
                                                backgroundColor: 'transparent'
                                            }
                                        }}
                                        renderValue={(e) => (
                                            <p>{country.name ?? ''}</p>
                                        )}
                                        startAdornment={<Flag />}
                                        defaultValue={''}
                                    >
                                        {countryList
                                            ?.sort(
                                                (
                                                    a: { name: string },
                                                    b: { name: string }
                                                ) => (a.name > b.name ? 1 : -1)
                                            )
                                            ?.map((country: any) => (
                                                <MenuItem
                                                    key={country.id}
                                                    value={country.iso2}
                                                    onClick={() =>
                                                        setCountry({
                                                            name: country.name,
                                                            iso2: country.iso2
                                                        })
                                                    }
                                                >
                                                    <Radio
                                                        checked={
                                                            value ===
                                                            country.iso2
                                                        }
                                                    />{' '}
                                                    {country.name} (
                                                    {country.iso2})
                                                </MenuItem>
                                            ))}
                                    </Select>
                                )}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{ position: 'relative' }}>
                            <InputLabel
                                sx={{
                                    color: errors.phoneNumber?.message
                                        ? 'red'
                                        : '#ADAAAA',
                                    fontSize: '14px',
                                    position: 'absolute',
                                    top: '8px',
                                    left: '16px',
                                    zIndex: 1,
                                    fontFamily: 'Open Sans'
                                }}
                                htmlFor="phoneNumber"
                            >
                                Phone Number *
                            </InputLabel>
                            <Controller
                                {...register('phoneNumber', {
                                    required: ' '
                                })}
                                name="phoneNumber"
                                control={control}
                                render={({
                                    field: { onChange, value },
                                    fieldState: { error },
                                    formState
                                }) => (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            backgroundColor: '#FCFBF8',
                                            width: '100%',
                                            height: '70px',
                                            borderBottom: '1px solid #D9D9D9',
                                            padding: '0px 16px',
                                            fontFamily: 'open sans',
                                            color: '#23282B',
                                            gap: '5px',
                                            paddingTop: '12px',
                                            '& input, input:focus': {
                                                border: 0,
                                                outline: 0,
                                                background: 'transparent'
                                            },
                                            '& .PhoneInput': {
                                                mt: '8px'
                                            },
                                            '& .PhoneInputCountryIcon--border, .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border':
                                                {
                                                    boxShadow: 0
                                                },
                                            '& .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow':
                                                {
                                                    color: '#C5C5C5'
                                                }
                                        }}
                                    >
                                        <PhoneInput
                                            placeholder="Enter phone number"
                                            flags={flags}
                                            value={value}
                                            limitMaxLength={true}
                                            onCountryChange={(e) => {
                                                if (e) {
                                                    var code =
                                                        getCountryCallingCode(
                                                            e
                                                        );
                                                    console.log(code);
                                                    onChange('+' + code);
                                                }
                                            }}
                                            rules={{
                                                required: true,
                                                validate: isPossiblePhoneNumber
                                            }}
                                            onChange={onChange}
                                        />
                                    </Box>
                                )}
                            />
                            <Typography
                                color="red"
                                variant="titleSmallSemiBold"
                            >
                                {errors.phoneNumber?.message}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container gap="20px" wrap="nowrap" mb={'20px'}>
                    <Grid item xs={6}>
                        <Box sx={{ position: 'relative' }}>
                            <InputLabel
                                sx={{
                                    color: errors.address?.stateProvince
                                        ?.message
                                        ? 'red'
                                        : '#ADAAAA',
                                    fontSize: '14px',
                                    position: 'absolute',
                                    top: '8px',
                                    left: '16px',
                                    zIndex: 1,
                                    fontFamily: 'Open Sans'
                                }}
                            >
                                State *{errors.address?.stateProvince?.message}
                            </InputLabel>

                            <Controller
                                {...register('address.stateProvince')}
                                name="address.stateProvince"
                                control={control}
                                render={({
                                    field: { onChange, value },
                                    fieldState: { error },
                                    formState
                                }) => (
                                    <Select
                                        value={value}
                                        onChange={(e) => {
                                            onChange(e);
                                            setSelectedCity('');
                                            resetField('address.city');
                                        }}
                                        fullWidth
                                        variant="filled"
                                        IconComponent={() => <Dropdown />}
                                        sx={{
                                            backgroundColor: '#FCFBF8',
                                            width: '100%',
                                            height: '70px',
                                            padding: '0px 16px',
                                            pl: '5px',
                                            fontSize: 14,
                                            color: '#23282B',
                                            '& 	.MuiInputBase-input': {
                                                top: '8px',
                                                position: 'relative'
                                            },
                                            '& 	.MuiInputBase-input:focus': {
                                                backgroundColor: 'transparent'
                                            }
                                        }}
                                        renderValue={(e) => (
                                            <p>{selectedState.value || ''}</p>
                                        )}
                                        defaultValue={''}
                                        disabled={disableStateDropDown}
                                    >
                                        {statesList?.map((state: any) => (
                                            <MenuItem
                                                key={state.id}
                                                value={state.name}
                                                onClick={() =>
                                                    setSelectedState({
                                                        value: state.name,
                                                        iso2: state.iso2
                                                    })
                                                }
                                            >
                                                <Radio
                                                    checked={
                                                        value === state.name
                                                    }
                                                />{' '}
                                                {state.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{ position: 'relative' }}>
                            <InputLabel
                                sx={{
                                    color: errors.address?.city?.message
                                        ? 'red'
                                        : '#ADAAAA',
                                    fontSize: '14px',
                                    position: 'absolute',
                                    top: '8px',
                                    left: '16px',
                                    zIndex: 1,
                                    fontFamily: 'Open Sans'
                                }}
                            >
                                City *
                            </InputLabel>

                            <Controller
                                {...register('address.city', {
                                    required: '*'
                                })}
                                name="address.city"
                                control={control}
                                render={({
                                    field: { onChange, value },
                                    fieldState: { error, isTouched },
                                    formState
                                }) => (
                                    <Select
                                        value={value}
                                        onChange={(e) => {
                                            onChange(e);
                                            setSelectedCity(e.target.value);
                                            if (citiesList?.length === 0) {
                                                clearErrors('address.city');
                                            }
                                        }}
                                        fullWidth
                                        variant="filled"
                                        IconComponent={() => <Dropdown />}
                                        sx={{
                                            backgroundColor: '#FCFBF8',
                                            width: '100%',
                                            height: '70px',
                                            padding: '0px 16px',
                                            fontSize: 14,
                                            color: '#23282B',
                                            pl: '5px',
                                            '& 	.MuiInputBase-input': {
                                                top: '8px',
                                                position: 'relative'
                                            },
                                            '& 	.MuiInputBase-input:focus': {
                                                backgroundColor: 'transparent'
                                            }
                                        }}
                                        renderValue={(e) => (
                                            <p>{selectedCity || ''}</p>
                                        )}
                                        defaultValue={''}
                                        disabled={disableCityDropDown}
                                    >
                                        {citiesList?.map((city: any) => (
                                            <MenuItem
                                                key={city.id}
                                                value={city.name}
                                            >
                                                <Radio
                                                    checked={
                                                        value === city.name
                                                    }
                                                />{' '}
                                                {city.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Grid container gap="20px" wrap="nowrap">
                    <Grid item xs={8}>
                        <Box sx={{ position: 'relative' }}>
                            <InputLabel
                                sx={{
                                    color: errors.address?.street1?.message
                                        ? 'red'
                                        : '#ADAAAA',
                                    fontSize: '14px',
                                    position: 'absolute',
                                    top: '8px',
                                    left: '16px',
                                    zIndex: 1,
                                    fontFamily: 'Open Sans'
                                }}
                            >
                                Street address 1 *
                            </InputLabel>
                            <Controller
                                {...register('address.street1', {
                                    required: '*'
                                })}
                                name="address.street1"
                                control={control}
                                render={({
                                    field: { onChange, value, ref, onBlur },
                                    fieldState: { error },
                                    formState
                                }) => (
                                    <InputBase
                                        required
                                        value={value}
                                        onChange={(e) => {
                                            onChange(e);
                                            if (!e.target.value) {
                                                setError('address.street1', {
                                                    type: 'required',
                                                    message: '*'
                                                });
                                            } else {
                                                clearErrors('address.street1');
                                            }
                                        }}
                                        placeholder="Please enter your street address"
                                        rows={1}
                                        sx={{
                                            backgroundColor: '#FCFBF8',
                                            width: '100%',
                                            height: '70px',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                            padding: '0px 16px',
                                            fontFamily: 'open sans',
                                            color: '#23282B',
                                            borderBottom: '1px solid #C5C5C5',
                                            mb: '20px',
                                            '& 	.MuiInputBase-input': {
                                                position: 'relative',
                                                top: '15px'
                                            }
                                        }}
                                    />
                                )}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Box sx={{ position: 'relative' }}>
                            <InputLabel
                                sx={{
                                    color: errors.address?.zipPostCode?.message
                                        ? 'red'
                                        : '#ADAAAA',
                                    fontSize: '14px',
                                    position: 'absolute',
                                    top: '8px',
                                    left: '16px',
                                    zIndex: 1,
                                    fontFamily: 'Open Sans'
                                }}
                            >
                                Zipcode *
                            </InputLabel>
                            <Controller
                                {...register('address.zipPostCode', {
                                    required: '*'
                                })}
                                name="address.zipPostCode"
                                control={control}
                                render={({
                                    field: { onChange, value, ref, onBlur },
                                    fieldState: { error },
                                    formState
                                }) => (
                                    <InputBase
                                        required
                                        value={value}
                                        onChange={(e) => {
                                            onChange(e);
                                        }}
                                        id="address.zipPostCode"
                                        fullWidth
                                        sx={{
                                            backgroundColor: '#FCFBF8',
                                            width: '100%',
                                            height: '70px',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                            padding: '0px 16px',
                                            fontFamily: 'open sans',
                                            color: '#23282B',
                                            borderBottom: '1px solid #C5C5C5',
                                            mb: '20px',
                                            '& 	.MuiInputBase-input': {
                                                position: 'relative',
                                                top: '15px'
                                            }
                                        }}
                                        placeholder="Zip code"
                                        rows={1}
                                    />
                                )}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <ProfileInfoListItem title="Website">
                    <Button
                        disableRipple
                        variant="contained"
                        sx={{
                            backgroundColor: '#F2F2F2',
                            width: 'auto'
                        }}
                        startIcon={<AddIcon htmlColor="#05668D" />}
                    >
                        <Typography
                            color={'#05668D'}
                            fontFamily="open sans"
                            fontWeight={600}
                            fontSize={14}
                            sx={{ textTransform: 'none' }}
                        >
                            Add Website
                        </Typography>
                    </Button>
                </ProfileInfoListItem>
            </Box>
            <SnackAlert
                open={showAlert}
                handleClose={() => setShowAlert(false)}
                message={message}
                type={type}
            />
        </CommonDialog>
    );
};

export default EditProfileModal;
