import { Typography, Box } from '@mui/material';
import { IExperienceLevelTypes } from '../../Reviews/types';
import { Responsiveness } from '../../../theme/overrides';

const ExperienceLevelSkills = (props: IExperienceLevelTypes) => {
    const {isMobile} =Responsiveness()
    return (
        <Box
            sx={{
                display: 'flex',
                // padding: '4px 0px',
                // py: isMobile ? '4px' : '6px',
                // px: isMobile ? '8px' : '16px',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '5px',
                // bgcolor: props.color,
                borderRadius: '4px'
            }}
        >
            <Typography
                variant="titleSmallSemiBold"
                sx={{
                    color: props.color,
                    // background: props.color,
                    fontFamily: 'Open Sans',
                    fontSize: { xs: '12px', sm: '14px' },
                    fontStyle: 'normal',
                    fontWeight: '600',
                    lineHeight: '20px',
                    letterSpacing: '0.049px'
                    // color: '#fff'
                }}
            >
                {props.title}
            </Typography>
            <Box
                p="1px"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <Typography
                    variant="titleSmallBold"
                    sx={{
                        background: props.color,
                        // background: 'rgba(0, 0, 0, .3)',
                        padding: { xs: '0px 4px' },
                        borderRadius: '4px',
                        color: '#fff'
                    }}
                >
                    {props.shortForm}
                </Typography>
            </Box>
        </Box>
    );
};

export default ExperienceLevelSkills;
