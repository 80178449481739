import React, { useEffect, useState, useRef } from 'react';
import { IFeedItem } from '../types/IFeedItem';
import {
    Box,
    Typography,
    Button,
    IconButton,
    Avatar,
    TextField
} from '@mui/material';
import DotIcon from '../../../assets/icons/DotIcon';
import UploadIcon from '../../../assets/icons/UploadIcon';
import ShareIcon from '../../../assets/icons/ShareIcon';
import CommentIcon from '../../../assets/icons/CommentIcon';
import LikesIcon from '../../../assets/icons/LikesIcon';
import ViewIcon from '../../../assets/icons/ViewIcon';
import MoreIcon from '../../../assets/icons/MoreIcon';
import VerifiedIcon from '../../../assets/icons/VerifiedIcon';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import PostCards from './PostCards';
import CommentsPopup from './CommentsPopup';
import CommentBox, { ICommentType } from './CommentBox';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import { useAuth } from '../../../utils/context/AuthContext';
import { useAddCommentLike } from '../../../utils/hooks/api/like-comments/useCreateCommentLike';
import { useRemoveCommentLike } from '../../../utils/hooks/api/like-comments/useRemoveCommentLike';
import { usePost } from '../../../utils/context/PostContext';
import MessageIcon from '../../../assets/icons/MessageIcon';
import { PrimaryProfileType } from '../../../utils/hooks/api/account/types';
import { useIncrementViews } from '../../../utils/hooks/api/views/useIncrementViews';
import { usePostComment } from '../../../utils/hooks/api/comments/useCreateComment';
import { useQueryClient } from 'react-query';
import { QueryKeys } from '../../../utils/hooks/api/QueryKey';
import { useGetUserPostById } from '../../../utils/hooks/api/posts/useGetUserPostById';
import { logger } from '../../../utils/Helper/logger';
import { Responsiveness } from '../../../theme/overrides';
import { useGetProfessionalProfileById } from '../../../utils/hooks/api/professional-profile/useGetProfessionalProfile';
import FilledMessageIcon from '../../../assets/icons/FilledMessageIcon';
interface IUserType {
    firstName: string;
    lastName: string;
    commentId: string;
}
export const FeedItem = (props: {
    feed: IFeedItem;
    reposted?: boolean;
    repostPopup?: boolean;
    postUp?: boolean;
    profile?: boolean;
}) => {
    const { feed, reposted = false, postUp = false, profile = false } = props;
    const { user } = useAuth();
    const DescriptionRef = React.useRef<HTMLDivElement>(null);
    const { posts, setPosts } = usePost();
    const queryClient = useQueryClient();
    const postComment = usePostComment();
    const [show, setShow] = useState(false);
    const [showFullDescription, setShowFullDescription] = useState(false);
    const [repostPopup, setRepostPopup] = useState(false);
    const [commentsPopup, setCommentsPopup] = useState(false);
    const [comment, setComment] = useState('');
    const [posted, setPosted] = useState(false);
    const [onFocus, setOnFocus] = useState(false);
    const [replyPopup, setReplyPopup] = useState(false);
    const [replyingTo, setReplyingTo] = useState<IUserType>();
    const location = useLocation();
    const navigate = useNavigate();
    const addLike = useAddCommentLike();
    const removeLike = useRemoveCommentLike();
    const isProfessional =
        user?.primaryProfile === PrimaryProfileType.Professional;
    const { isMobile } = Responsiveness();
    const maxLength = isMobile ? 100 : 200;
    // const { data: userProfile } = useGetProfessionalProfileById(feed?.profileId ?? '')
    const onShowHidden = () => {
        // if (DescriptionRef.current != null) {
        //     DescriptionRef.current.style.height = '100%';
        // }
        // setShow(true);
        setShowFullDescription(true);
    };

    const onHide = () => {
        // if (DescriptionRef.current != null) {
        //     DescriptionRef.current.style.height = '70px';
        // }
        // setShow(false);
        setShowFullDescription(false);
    };

    const [hasCommented, setHasCommented] = useState<ICommentType | undefined>(undefined)

     
    const getHasCommented = () => {
       const commented = feed?.comments?.find(
            (comment: any, i) =>
                comment.user.userId === user?.professionalProfile?.userId &&
                i > 0
       );
        setHasCommented(commented)
    }

    useEffect(() => {
        getHasCommented()
    }, [posted])

    logger.log(posted)

    const handlePost = () => {
        const request: any = {
            userId: user?.id ?? feed?.userId ?? '',
            postId: feed?.id ?? '',
            comment,
            commentId: replyingTo?.commentId ?? ''
        };
        postComment.mutate(request, {
            onSuccess: (res) => {
                let tempPosts = JSON.parse(JSON.stringify(posts));
                const getComment = (comments: ICommentType[]) => {
                    comments?.filter((y) => {
                        if (y.id === replyingTo?.commentId) {
                            y.replies.push({
                                ...res,
                                postId: feed?.id,
                                likedByProfessionalIds: [],
                                likes: 0
                            });
                        } else {
                            getComment(y.replies);
                        }
                        return y;
                    });
                    return comments;
                };
                tempPosts.filter((post: any) => {
                    if (post?.id === feed?.id) {
                        if (replyingTo?.commentId) {
                            getComment(post?.comments ?? []);
                        } else {
                            post?.comments.push({
                                ...res,
                                postId: post?.id,
                                likedByProfessionalIds: [],
                                likes: 0
                            });
                            post.commentsCount += 1;
                        }
                    }
                    return post;
                });
                setPosts(tempPosts);
                setComment('');
                setPosted(!posted)
                setReplyingTo(undefined);
                queryClient.invalidateQueries(QueryKeys.RetrieveUserPosts);
            },
            onError: (err) => {
                console.log('ERR', err);
            }
        });
    };

    const handleLike = (key: string) => {
        if (key === 'add') {
            const request: any = {
                userId: user?.id ?? '',
                postId: feed?.id ?? '',
                commentId: feed?.comments
                    ? feed?.comments[0]?.id ??
                      feed?.comments[feed?.comments?.length - 1]?.id
                    : ''
            };
            addLike.mutate(request, {
                onSuccess: (res) => {
                    console.log('COMMENT RES', res);
                    if (
                        res?.postId === feed?.id &&
                        location.pathname.includes('review')
                    ) {
                        updateLikes(feed?.comments ?? [], 'add');
                    }
                    let tempPosts = JSON.parse(JSON.stringify(posts));
                    tempPosts.filter((post: any) => {
                        console.log('post', post);
                        if (post?.id === feed?.id) {
                            updateLikes(post?.comments ?? [], 'add');
                        }
                        return post;
                    });
                    setPosts(tempPosts);
                },
                onError: (err) => {
                    console.log('ERR', err);
                }
            });
        } else {
            removeLike.mutate(
                {
                    userId: user?.id ?? '',
                    commentId: feed?.comments
                        ? feed?.comments[0]?.id ??
                          feed?.comments[feed?.comments?.length - 1]?.id
                        : ''
                },
                {
                    onSuccess: (res) => {
                        console.log('REMOVE COMMENT RES', res);
                        if (location.pathname.includes('review')) {
                            updateLikes(feed?.comments ?? [], 'subtract');
                        }
                        let tempPosts = JSON.parse(JSON.stringify(posts));
                        tempPosts.filter((post: any) => {
                            if (post?.id === feed?.id) {
                                updateLikes(post?.comments ?? [], 'subtract');
                            }
                            return post;
                        });
                        setPosts(tempPosts);
                    },
                    onError: (err) => {
                        console.log('ERR', err);
                    }
                }
            );
        }
    };

    const updateLikes = (comments: ICommentType[], key: string) => {
        if (key === 'add') {
            comments[0].likes += 1;
            comments[0].likedByProfessionalIds.push({
                id: isProfessional
                    ? user?.professionalProfile?.id
                    : user?.primaryCompanyProfile?.companyId,
                firstName: isProfessional
                    ? user?.professionalProfile?.firstName
                    : user?.primaryCompanyProfile?.companyName,
                lastName: isProfessional
                    ? user?.professionalProfile?.lastName
                    : null,
                photoUrl: isProfessional
                    ? user?.professionalProfile?.photoUrl
                    : user?.primaryCompanyProfile?.photoUrl
            });
        } else {
            comments[0].likes -= 1;
            comments[0].likedByProfessionalIds =
                comments[0]?.likedByProfessionalIds?.filter(
                    (x) =>
                        x.id !==
                        (isProfessional
                            ? user?.professionalProfile?.id ?? ''
                            : user?.primaryCompanyProfile?.companyId ?? '')
                );
        }
        return comments;
    };

    const parseText = (
        text: string,
        // showAll: boolean,
        // maxLength: number
    ): JSX.Element[] => {
        let result: JSX.Element[] = [];

        text?.split(/(@\[[^\]]+\]\([^\)]+\))|(#\w+)/).every((part, index) => {
            if (part) {
                if (index % 3 === 1 && part.startsWith('@[')) {
                    const [, display, id] =
                        /@\[(.*?)\]\((.*?)\)/.exec(part) || [];
                    if (display && id) {
                        result.push(
                            <Typography
                                component="span"
                                sx={{
                                    borderRadius: '4px',
                                    color: '#05668D',
                                    Width: '100px',
                                    height: '24px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontFamily: 'Open Sans',
                                    fontSize: '16px',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    lineHeight: '24px',
                                    letterSpacing: '0.08px'
                                }}
                                key={id}
                            >
                                {display}
                            </Typography>
                        );
                    }
                } else if (index % 3 === 2 && part.startsWith('#')) {
                    const hashtag = part.slice(1);
                    result.push(
                        <Typography
                            component="span"
                            sx={{
                                borderRadius: '4px',
                                // background: '#FFC0CB',
                                color: '#05668D',
                                width: '100px',
                                height: '24px',
                                padding: '2px 8px',
                                fontFamily: 'Open Sans',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                lineHeight: '24px',
                                letterSpacing: '0.08px'
                            }}
                            key={hashtag}
                        >
                            #{hashtag}
                        </Typography>
                    );
                } else {
                    result.push(
                        <Typography component="span" key={part}>
                            {part}
                        </Typography>
                    );
                }
            }
            // It stops executing as soon as the condition is not met
            // logger.log(result.join('').length < maxLength);
            // return showAll || result.join('').length < maxLength;
        });
    

        return result;
    };

    const parsedDescription = feed?.description; // Replace 'feed?.description' with your actual description.
    const formattedText = parseText(parsedDescription);

    const [isTruncated, setIsTruncated] = useState(false);
    const containerRef = useRef(null);

    useEffect(() => {
        const container: any = containerRef.current;
        if (container !== null) {
            if (container.scrollHeight > container.clientHeight) {
                setIsTruncated(true);
            } else {
                setIsTruncated(false);
            }
        }
    }, [formattedText, feed?.description]);


        



    const postRef = useRef(null);
    const incrementViews = useIncrementViews();
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    // When the post is in view, update the view count in the backend
                    const incrementViewCount = () => {
                        let id = feed?.id ?? '';
                        incrementViews.mutate(id);
                    };

                    incrementViewCount();
                    observer.disconnect(); // Stop observing once it's in view
                }
            },
            {
                root: null, // Use the viewport as the root
                rootMargin: '0px', // No margin
                threshold: 1.0 // Fully in view triggers the callback
            }
        );

        if (postRef.current) {
            observer.observe(postRef.current);
        }

        return () => {
            if (postRef.current) {
                observer.unobserve(postRef.current);
            }
        };
    }, [feed?.id]);

    return (
        <>
            <>
                <Box
                    ref={postRef}
                    onClick={() => setCommentsPopup(true)}
                    sx={{
                        display: { sm: 'block', xs: 'none' },
                        p:
                            reposted || repostPopup
                                ? '15px'
                                : {
                                      lg: profile
                                          ? '8px 40px 8px 40px'
                                          : '20px',
                                      xs: '10px'
                                  },
                        mb: profile ? '4px' : '8px',
                        background:
                            postUp && reposted
                                ? '#fff'
                                : !postUp && reposted
                                ? '#f2f2f2'
                                : '#fff',

                        borderRadius: { sm: '0px', lg: profile ? 0 : '12px' },
                        border:
                            reposted || repostPopup ? '1px solid #C5C5C5' : 0,
                        mt: reposted && !postUp ? '28px' : 0
                    }}
                    className="feedItem"
                >
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '16px'
                        }}
                    >
                        {profile ? null : (
                            <Box>
                                <Avatar
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        navigate(
                                            feed?.primaryProfile ===
                                                PrimaryProfileType.Professional
                                                ? feed?.profileId ===
                                                  user?.professionalProfile?.id
                                                    ? 'users'
                                                    : `/professional-profile/${feed?.profileId}`
                                                : `/company-profile/${feed?.profileId}`
                                        );
                                    }}
                                    src={feed.profileImage}
                                    alt="feed"
                                    sx={{
                                        width: 64,
                                        height: 64,
                                        borderRadius: 100,
                                        cursor: 'pointer'
                                    }}
                                />
                            </Box>
                        )}
                        <Box
                            sx={{
                                width: '100%'
                            }}
                        >
                            <Box sx={{ cursor: 'pointer' }}>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    gap="12px"
                                >
                                    {profile ? (
                                        <Box>
                                            <Avatar
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    navigate(
                                                        feed?.primaryProfile ===
                                                            PrimaryProfileType.Professional
                                                            ? feed?.profileId ===
                                                              user
                                                                  ?.professionalProfile
                                                                  ?.id
                                                                ? 'users'
                                                                : `/professional-profile/${feed?.profileId}`
                                                            : `/company-profile/${feed?.profileId}`
                                                    );
                                                }}
                                                src={feed.profileImage}
                                                alt="feed"
                                                sx={{
                                                    width: 56,
                                                    height: 56,
                                                    borderRadius: 100,
                                                    cursor: 'pointer'
                                                }}
                                            />
                                        </Box>
                                    ) : null}
                                    <Box flex={1}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                width: '100%'
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    gap: '4px',
                                                    alignItems: 'center'
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        cursor: 'pointer',
                                                        '&:hover': {
                                                            textDecoration:
                                                                'underline'
                                                        }
                                                    }}
                                                    color="#23282B"
                                                    variant="titleMediumSemiBold"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        navigate(
                                                            feed?.primaryProfile ===
                                                                PrimaryProfileType.Professional
                                                                ? feed?.profileId ===
                                                                  user
                                                                      ?.professionalProfile
                                                                      ?.id
                                                                    ? 'users'
                                                                    : `/professional-profile/${feed?.profileId}`
                                                                : `/company-profile/${feed?.profileId}`
                                                        );
                                                    }}
                                                >
                                                    {feed.fullName}
                                                </Typography>
                                                <VerifiedIcon />
                                                <Box
                                                    sx={{
                                                        mx: '4px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}
                                                >
                                                    <DotIcon color="#808080" />
                                                </Box>
                                                <Typography
                                                    color="#808080"
                                                    variant="titleSmallRegular"
                                                >
                                                    {moment(
                                                        feed.createdAt
                                                    ).fromNow()}
                                                </Typography>
                                            </Box>
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                                sx={{
                                                    bgcolor: '#f2f2f2',
                                                    borderRadius: '8px',
                                                    p: '8px'
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                }}
                                            >
                                                <MoreIcon />
                                            </Box>
                                        </Box>
                                        <Typography
                                            sx={{ mt: '0px' }}
                                            color="#808080"
                                            variant="titleSmallRegular"
                                        >
                                            {feed.jobTitle}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ mt: '8px' }}>
                                    {/* <Typography
                                        component="div"
                                        sx={{ whiteSpace: 'pre-line' }}
                                    > */}
                                    <Typography
                                        color={'#23282B'}
                                        variant="titleSmallRegular"
                                        ref={containerRef}
                                        sx={{
                                            whiteSpace: 'pre-line'
                                            // mb: 1
                                        }}
                                        style={{
                                            display: '-webkit-box',
                                            WebkitLineClamp: showFullDescription
                                                ? undefined
                                                : 4,
                                            WebkitBoxOrient: 'vertical',
                                            overflow: 'hidden'
                                        }}
                                    >
                                        {formattedText}
                                    </Typography>
                                    {isTruncated ? (
                                        <Box
                                            component="span"
                                            sx={{
                                                display: 'inline',
                                                color: '#808080',
                                                fontSize: '12px',
                                                fontWeight: '700',
                                                zIndex: 25,
                                                // px: 0,
                                                '&:hover': {
                                                    textDecoration: 'underline'
                                                }
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setShowFullDescription(true);
                                                setShow(true);
                                            }}
                                        >
                                            see more
                                        </Box>
                                    ) : null}
                                    {show && (
                                        <Box
                                            component="span"
                                            sx={{
                                                display: 'inline',
                                                color: '#808080',
                                                fontSize: '12px',
                                                fontWeight: '700',
                                                zIndex: 25,
                                                // px: 0,
                                                '&:hover': {
                                                    textDecoration: 'underline'
                                                }
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setShowFullDescription(false);
                                                setShow(false);
                                            }}
                                        >
                                            see less
                                        </Box>
                                    )}
                                    {/* </Typography> */}
                                </Box>

                                {feed?.images?.length > 0 && (
                                    <Box
                                        sx={{
                                            mt: '8px',
                                            position: 'relative',
                                            display: 'flex',
                                            // flexWrap: 'wrap',
                                            // alignItems:'center',
                                            gap: '8px',
                                            borderRadius: '12px',
                                            overflow: 'hidden',
                                            height: 'auto'
                                            // boxSizing: 'border-box'
                                        }}
                                    >
                                        {feed.images.map(
                                            (image: any, index) => (
                                                <img
                                                    key={index}
                                                    src={image}
                                                    alt="feed"
                                                    style={{
                                                        borderRadius: '12px',
                                                        height: '350px',
                                                        width: `${
                                                            100 /
                                                            (feed?.images
                                                                ?.length > 1
                                                                ? 2
                                                                : 1)
                                                        }%`,
                                                        objectFit:
                                                            feed?.images
                                                                ?.length === 1
                                                                ? 'cover'
                                                                : 'cover'
                                                        // border:'3px solid black'
                                                    }}
                                                />
                                            )
                                        )}

                                        {feed?.images?.length > 2 && (
                                            <Box
                                                sx={{
                                                    position: 'absolute',
                                                    bottom: '10px',
                                                    right: '10px',
                                                    color: '#FAFAFA',
                                                    fontFamily: 'Open Sans',
                                                    fontSize: '36px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '600',
                                                    lineHeight: '44px'
                                                }}
                                            >
                                                +{feed?.images?.length - 2}
                                            </Box>
                                        )}
                                    </Box>
                                )}
                            </Box>

                            <Box
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            >
                                {feed?.repost ? (
                                    <FeedItem
                                        feed={feed?.repost}
                                        reposted={true}
                                    />
                                ) : null}
                            </Box>
                            {!reposted ? (
                                <Box>
                                    <Box
                                        sx={{
                                            mt: '8px',
                                            display: 'flex',
                                            gap: '20px',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            position: 'relative',
                                            mb:
                                                replyPopup &&
                                                feed?.comments?.length === 1
                                                    ? '90px'
                                                    : replyPopup &&
                                                      feed?.comments?.length !==
                                                          1
                                                    ? '130px'
                                                    : undefined
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                gap: '8px',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Box
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                }}
                                                sx={{
                                                    display: 'flex',
                                                    gap: '12px',
                                                    alignItems: 'center',
                                                    height: '36px',
                                                    bgcolor: '#f2f2f2',
                                                    borderRadius: '8px',
                                                    p: '6px 16px'
                                                }}
                                            >
                                                <ViewIcon color="#999999" />
                                                <Typography
                                                    variant="titleMediumSemiBold"
                                                    color={'#808080'}
                                                >
                                                    {feed?.views}
                                                </Typography>
                                            </Box>
                                            {hasCommented ? (
                                                <Box
                                                    onClick={() => {
                                                        setReplyPopup(
                                                            !replyPopup
                                                        );
                                                    }}
                                                    sx={{
                                                        display: 'flex',
                                                        gap: '12px',
                                                        alignItems: 'center',
                                                        height: '36px',
                                                        px: {
                                                            xs: '8px',
                                                            sm: '12px'
                                                        },
                                                        bgcolor: '#E6F0F4',
                                                        borderRadius: '8px'
                                                    }}
                                                >
                                                    <IconButton
                                                        sx={{ m: 0, p: 0 }}
                                                    >
                                                        <FilledMessageIcon />
                                                    </IconButton>
                                                    <Typography
                                                        variant="titleSmallSemiBold"
                                                        color={'#05668D'}
                                                    >
                                                        {feed?.commentsCount}
                                                    </Typography>
                                                </Box>
                                            ) : (
                                                <Box
                                                    onClick={() => {
                                                        setReplyPopup(
                                                            !replyPopup
                                                        );
                                                    }}
                                                    sx={{
                                                        display: 'flex',
                                                        gap: '12px',
                                                        alignItems: 'center',
                                                        height: '36px',
                                                        px: {
                                                            xs: '8px',
                                                            sm: '12px'
                                                        },
                                                        bgcolor: '#f2f2f2',
                                                        borderRadius: '8px'
                                                    }}
                                                >
                                                    <IconButton
                                                        sx={{ m: 0, p: 0 }}
                                                    >
                                                        <MessageIcon />
                                                    </IconButton>
                                                    <Typography
                                                        variant="titleSmallSemiBold"
                                                        color={'#808080'}
                                                    >
                                                        {feed?.commentsCount}
                                                    </Typography>
                                                </Box>
                                            )}
                                            <Box>
                                                {feed?.comments &&
                                                (
                                                    feed?.comments[0] ??
                                                    feed?.comments[
                                                        feed?.comments?.length -
                                                            1
                                                    ]
                                                )?.likedByProfessionalIds?.find(
                                                    (x) =>
                                                        x.id ===
                                                        (isProfessional
                                                            ? user
                                                                  ?.professionalProfile
                                                                  ?.id ?? ''
                                                            : user
                                                                  ?.primaryCompanyProfile
                                                                  ?.companyId ??
                                                              '')
                                                ) ? (
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            gap: '12px',
                                                            alignItems:
                                                                'center',
                                                            height: '36px',
                                                            p: '6px 16px',
                                                            bgcolor: '#FCECEB',
                                                            borderRadius: '8px'
                                                        }}
                                                    >
                                                        <IconButton
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleLike(
                                                                    'remove'
                                                                );
                                                            }}
                                                            sx={{ m: 0, p: 0 }}
                                                        >
                                                            <LikesIcon />
                                                        </IconButton>
                                                        <Typography
                                                            variant="titleSmallSemiBold"
                                                            color={'#E36966'}
                                                        >
                                                            {(feed?.comments
                                                                ? feed
                                                                      ?.comments?.[0]
                                                                      .likes ??
                                                                  (feed?.comments ??
                                                                      [])[
                                                                      (
                                                                          feed?.comments ??
                                                                          []
                                                                      ).length -
                                                                          1
                                                                  ].likes
                                                                : 0) ??
                                                                feed?.likes}
                                                        </Typography>
                                                    </Box>
                                                ) : (
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            gap: '12px',
                                                            alignItems:
                                                                'center',
                                                            height: '36px',
                                                            p: '6px 16px',
                                                            bgcolor: '#f2f2f2',
                                                            borderRadius: '8px'
                                                        }}
                                                    >
                                                        <IconButton
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleLike(
                                                                    'add'
                                                                );
                                                            }}
                                                            sx={{
                                                                maxHeight:
                                                                    '20px',
                                                                maxWidth:
                                                                    '20px',
                                                                m: 0,
                                                                p: 0
                                                            }}
                                                        >
                                                            <FavoriteBorderIcon
                                                                sx={{
                                                                    color: '#464646'
                                                                }}
                                                            />
                                                        </IconButton>
                                                        <Typography
                                                            variant="titleSmallSemiBold"
                                                            color={'#808080'}
                                                        >
                                                            {(feed?.comments
                                                                ? feed
                                                                      ?.comments?.[0]
                                                                      .likes ??
                                                                  (feed?.comments ??
                                                                      [])[
                                                                      (
                                                                          feed?.comments ??
                                                                          []
                                                                      ).length -
                                                                          1
                                                                  ].likes
                                                                : 0) ??
                                                                feed?.likes}
                                                        </Typography>
                                                    </Box>
                                                )}
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    gap: '12px',
                                                    alignItems: 'center',
                                                    height: '36px',
                                                    p: '6px 16px',
                                                    bgcolor: '#f2f2f2',
                                                    borderRadius: '8px'
                                                }}
                                            >
                                                <IconButton
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setRepostPopup(true);
                                                    }}
                                                    sx={{ m: 0, p: 0 }}
                                                >
                                                    <ShareIcon color="#464646" />
                                                </IconButton>
                                                <Typography
                                                    variant="titleSmallSemiBold"
                                                    color={'#808080'}
                                                >
                                                    {feed.shares}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                gap: '12px',
                                                alignItems: 'center',
                                                p: '6px 16px',
                                                height: '36px',
                                                bgcolor: '#f2f2f2',
                                                borderRadius: '8px'
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                        >
                                            <UploadIcon color="#464646" />
                                        </Box>
                                        {replyPopup && (
                                            <Box
                                                onClick={(e) =>
                                                    e.stopPropagation()
                                                }
                                                position="absolute"
                                                right={0}
                                                left={0}
                                                top="150%"
                                                display="flex"
                                                alignItems={'flex-start'}
                                                bgcolor="#FFF"
                                                gap="16px"
                                            >
                                                <TextField
                                                    fullWidth
                                                    multiline
                                                    maxRows="3"
                                                    value={comment}
                                                    onChange={(e) =>
                                                        setComment(
                                                            e.target.value
                                                        )
                                                    }
                                                    sx={{
                                                        '& .MuiOutlinedInput-notchedOutline':
                                                            {
                                                                border: '1px solid #D9D9D9',
                                                                borderRadius:
                                                                    '16px',
                                                                px: '20px',
                                                                py: '12px'
                                                            },
                                                        bgcolor: '#f2f2f2'
                                                    }}
                                                    placeholder="Add a comment..."
                                                />
                                                <Button
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handlePost();
                                                        setReplyPopup(false);
                                                    }}
                                                    disabled={!comment}
                                                    sx={{
                                                        padding: '12px',
                                                        width: '100px',
                                                        height: '40px',
                                                        background:
                                                            comment?.length > 0
                                                                ? '#05668D'
                                                                : '#EDEDED',
                                                        borderRadius: '8px',
                                                        textTransform:
                                                            'capitalize',
                                                        textDecoration: 'none',
                                                        fontFamily: 'Open Sans',
                                                        fontWeight: '600',
                                                        fontSize: '14px',
                                                        letterSpacing:
                                                            '0.001em',
                                                        color:
                                                            comment?.length > 0
                                                                ? '#fff'
                                                                : '#808080'
                                                    }}
                                                >
                                                    Post
                                                </Button>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            ) : null}
                        </Box>
                    </Box>
                </Box>
                <Box
                    ref={postRef}
                    sx={{
                        display: { sm: 'none', xs: 'block' },
                        mt: reposted ? '8px' : 0,
                        ml: reposted && !postUp ? '0px' : 0,
                        pt: reposted ? '8px' : { xs: '8px' },
                        pb: reposted ? '8px' : { xs: '8px' },
                        mb: reposted ? 0 : { xs: profile ? '4px' : '8px' },
                        borderRadius: reposted ? '12px' : 0,
                        background:
                            reposted && postUp
                                ? '#fff'
                                : reposted && !postUp
                                ? '#F2F2F2'
                                : '#fff',
                        border:
                            reposted && !repostPopup ? '1px solid #C5C5C5' : 0,
                        width: '100%'
                    }}
                    className="feedItem"
                >
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '16px',
                            justifyContent: 'space-between',
                            px: reposted ? '8px' : '16px'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '16px',
                                width: '100%'
                            }}
                        >
                            <Avatar
                                onClick={() =>
                                    navigate(
                                        feed?.primaryProfile ===
                                            PrimaryProfileType.Professional
                                            ? feed?.profileId ===
                                              user?.professionalProfile?.id
                                                ? 'users'
                                                : `/professional-profile/${feed?.profileId}`
                                            : `/company-profile/${feed?.profileId}`
                                    )
                                }
                                src={feed.profileImage}
                                alt="feed"
                                sx={{
                                    width: 48,
                                    height: 48,
                                    borderRadius: 100,
                                    cursor: 'pointer'
                                }}
                            />
                            <Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: '4px',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            fontWeight: 600,
                                            cursor: 'pointer',
                                            '&:hover': {
                                                textDecoration: 'underline'
                                            }
                                        }}
                                        color="#23282B"
                                        variant="titleMediumSemiBold"
                                        onClick={() =>
                                            navigate(
                                                feed?.primaryProfile ===
                                                    PrimaryProfileType.Professional
                                                    ? feed?.profileId ===
                                                      user?.professionalProfile
                                                          ?.id
                                                        ? 'users'
                                                        : `/professional-profile/${feed?.profileId}`
                                                    : `/company-profile/${feed?.profileId}`
                                            )
                                        }
                                    >
                                        {feed.fullName}
                                    </Typography>
                                    <Box
                                        component={'span'}
                                        display={'flex'}
                                        sx={{
                                            '& svg': {
                                                width: 16,
                                                height: 16
                                            }
                                        }}
                                    >
                                        <VerifiedIcon />
                                    </Box>
                                    <Box
                                        sx={{
                                            mx: '4px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            '& svg': {
                                                width: 4,
                                                height: 4
                                            }
                                        }}
                                    >
                                        <DotIcon color="#808080" />
                                    </Box>
                                    <Typography
                                        color="#808080"
                                        variant="titleSmallRegular"
                                        fontSize={12}
                                    >
                                        {moment(feed.createdAt).fromNow()}
                                    </Typography>
                                </Box>
                                <Typography
                                    color="#808080"
                                    variant="titleSmallRegular"
                                >
                                    {feed.jobTitle}
                                </Typography>
                            </Box>
                        </Box>
                        <MoreIcon />
                    </Box>
                    <Box
                        sx={{ px: reposted ? '8px' : '16px' }}
                        onClick={() => {
                            // setCommentsPopup(true);
                            navigate(`/post/${feed?.id}`, {
                                state: {
                                    feed: feed,
                                    repostPopup: repostPopup,
                                    reposted: reposted
                                }
                            });
                        }}
                    >
                        <Box sx={{ mt: '12px' }}>
                            <Typography
                                component="div"
                                sx={{ whiteSpace: 'pre-line' }}
                            >
                                <Typography
                                    color={'#23282B'}
                                    variant="titleSmallRegular"
                                    ref={containerRef}
                                    sx={{ whiteSpace: 'pre-line', mb: 1 }}
                                    style={{
                                        display: '-webkit-box',
                                        WebkitLineClamp: showFullDescription
                                            ? undefined
                                            : 3,
                                        WebkitBoxOrient: 'vertical',
                                        overflow: 'hidden'
                                    }}
                                >
                                    {formattedText}
                                    {/* {feed.description?.slice(
                                        0,
                                        show
                                            ? feed?.description?.length
                                            : maxLength
                                    )}{' '}
                                    {feed?.description?.length > maxLength &&
                                    !show
                                        ? '...'
                                        : ''} */}
                                    {/* {formattedText?.slice(
                                        0,
                                        showFullDescription
                                            ? formattedText?.length
                                            : maxLength
                                    )}{' '}
                                    {formattedText?.length > maxLength &&
                                    !showFullDescription
                                        ? '...'
                                        : ''} */}
                                </Typography>
                            </Typography>
                            {isTruncated ? (
                                <>
                                    {!showFullDescription ? (
                                        <Button
                                            variant="contained"
                                            sx={{
                                                width: '100px',
                                                height: '40px',
                                                color: '#808080',
                                                background: 'transparent',
                                                fontSize: '12px',
                                                fontWeight: '700',
                                                px: 0,
                                                '&:hover': {
                                                    textDecoration: 'underline',
                                                    background: 'transparent',
                                                    color: '#808080'
                                                }
                                            }}
                                            onClick={() => {
                                                onShowHidden();
                                            }}
                                        >
                                            See More
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="contained"
                                            sx={{
                                                width: '100px',
                                                height: '40px',
                                                color: '#808080',
                                                background: 'transparent',
                                                fontSize: '12px',
                                                fontWeight: '700',
                                                px: 0,
                                                '&:hover': {
                                                    textDecoration: 'underline',
                                                    background: 'transparent',
                                                    color: '#808080'
                                                }
                                            }}
                                            onClick={() => {
                                                onHide();
                                            }}
                                        >
                                            See Less
                                        </Button>
                                    )}
                                </>
                            ) : null}
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%'
                            }}
                        >
                            {feed?.images?.length > 0 && (
                                <Box
                                    sx={{
                                        mt: '8px',
                                        position: 'relative',
                                        display: 'flex',
                                        gap: '4px',
                                        overflow: 'hidden',
                                        height: '100%',
                                        width: '100%',
                                        boxSizing: 'border-box',
                                        borderRadius: '8px'
                                    }}
                                >
                                    {feed.images.map((image: any, index) => (
                                        <img
                                            key={index}
                                            src={image}
                                            alt="feed"
                                            style={{
                                                height: '168px',
                                                width: `${
                                                    100 /
                                                    (feed?.images?.length > 1
                                                        ? 2
                                                        : 1)
                                                }%`,
                                                objectFit:
                                                    feed?.images?.length === 1
                                                        ? 'cover'
                                                        : 'cover'
                                            }}
                                        />
                                    ))}
                                    {feed?.images?.length > 2 && (
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                bottom: '10px',
                                                right: '10px',
                                                color: '#FAFAFA',
                                                fontFamily: 'Open Sans',
                                                fontSize: '36px',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                                lineHeight: '44px'
                                            }}
                                        >
                                            +{feed?.images?.length - 2}
                                        </Box>
                                    )}
                                </Box>
                            )}
                        </Box>
                    </Box>
                    <Box sx={{ px: reposted ? '8px' : '16px' }}>
                        {feed?.repost ? (
                            <FeedItem feed={feed?.repost} reposted={true} />
                        ) : null}
                    </Box>

                    {!reposted ? (
                        <Box>
                            <Box
                                sx={{
                                    mt: '8px',
                                    display: 'flex',
                                    gap: { xs: '8px', sm: '24px' },
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    '& svg': {
                                        width: '16px',
                                        height: '16px'
                                    },
                                    px: reposted ? '8px' : '16px',
                                    position: 'relative',
                                    mb:
                                        replyPopup &&
                                        feed?.comments?.length === 1
                                            ? '90px'
                                            : replyPopup &&
                                              feed?.comments?.length !== 1
                                            ? '130px'
                                            : undefined
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: { xs: '8px', sm: '12px' },
                                        alignItems: 'center'
                                    }}
                                >
                                    {hasCommented ? (
                                        <Box
                                            onClick={() => {
                                                setReplyPopup(!replyPopup);
                                            }}
                                            sx={{
                                                display: 'flex',
                                                gap: '12px',
                                                alignItems: 'center',
                                                height: '36px',
                                                px: {
                                                    xs: '8px',
                                                    sm: '12px'
                                                },
                                                bgcolor: '#E6F0F4',
                                                borderRadius: '8px'
                                            }}
                                        >
                                            <IconButton sx={{ m: 0, p: 0 }}>
                                                <FilledMessageIcon />
                                            </IconButton>
                                            <Typography
                                                variant="titleSmallSemiBold"
                                                color={'#05668D'}
                                            >
                                                {feed?.commentsCount}
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <Box
                                            onClick={() => {
                                                setReplyPopup(!replyPopup);
                                            }}
                                            sx={{
                                                display: 'flex',
                                                gap: '12px',
                                                alignItems: 'center',
                                                height: '36px',
                                                px: {
                                                    xs: '8px',
                                                    sm: '12px'
                                                },
                                                bgcolor: '#f2f2f2',
                                                borderRadius: '8px'
                                            }}
                                        >
                                            <IconButton sx={{ m: 0, p: 0 }}>
                                                <MessageIcon />
                                            </IconButton>
                                            <Typography
                                                variant="titleSmallSemiBold"
                                                color={'#808080'}
                                            >
                                                {feed?.commentsCount}
                                            </Typography>
                                        </Box>
                                    )}
                                    <Box>
                                        {feed?.comments &&
                                        (
                                            feed?.comments[0] ??
                                            feed?.comments[
                                                feed?.comments?.length - 1
                                            ]
                                        )?.likedByProfessionalIds?.find(
                                            (x) =>
                                                x.id ===
                                                (isProfessional
                                                    ? user?.professionalProfile
                                                          ?.id ?? ''
                                                    : user
                                                          ?.primaryCompanyProfile
                                                          ?.companyId ?? '')
                                        ) ? (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    gap: '12px',
                                                    alignItems: 'center',
                                                    height: '36px',
                                                    px: {
                                                        xs: '8px',
                                                        sm: '12px'
                                                    },
                                                    bgcolor: '#FCECEB',
                                                    borderRadius: '8px'
                                                }}
                                            >
                                                <IconButton
                                                    onClick={() =>
                                                        handleLike('remove')
                                                    }
                                                    sx={{ m: 0, p: 0 }}
                                                >
                                                    <LikesIcon />
                                                </IconButton>
                                                <Typography
                                                    variant="titleSmallSemiBold"
                                                    color={'#E36966'}
                                                >
                                                    {(feed?.comments
                                                        ? feed?.comments?.[0]
                                                              .likes ??
                                                          (feed?.comments ??
                                                              [])[
                                                              (
                                                                  feed?.comments ??
                                                                  []
                                                              ).length - 1
                                                          ].likes
                                                        : 0) ?? feed?.likes}
                                                </Typography>
                                            </Box>
                                        ) : (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    gap: '12px',
                                                    alignItems: 'center',
                                                    height: '36px',
                                                    px: {
                                                        xs: '8px',
                                                        sm: '12px'
                                                    },
                                                    bgcolor: '#f2f2f2',
                                                    borderRadius: '8px'
                                                }}
                                            >
                                                <IconButton
                                                    onClick={() =>
                                                        handleLike('add')
                                                    }
                                                    sx={{
                                                        maxHeight: '20px',
                                                        maxWidth: '20px',
                                                        m: 0,
                                                        p: 0
                                                    }}
                                                >
                                                    <FavoriteBorderIcon
                                                        sx={{
                                                            color: '#464646'
                                                        }}
                                                    />
                                                </IconButton>
                                                {(feed?.comments ?? [])[0] && (
                                                    <Typography
                                                        variant="titleSmallSemiBold"
                                                        color={'#808080'}
                                                    >
                                                        {feed?.comments
                                                            ? (feed?.comments ??
                                                                  [])[0]
                                                                  .likes ??
                                                              (feed?.comments ??
                                                                  [])[
                                                                  (
                                                                      feed?.comments ??
                                                                      []
                                                                  ).length - 1
                                                              ].likes
                                                            : 0}
                                                    </Typography>
                                                )}
                                            </Box>
                                        )}
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: '12px',
                                            alignItems: 'center',
                                            height: '36px',
                                            px: { xs: '8px', sm: '12px' },
                                            bgcolor: '#f2f2f2',
                                            borderRadius: '8px'
                                        }}
                                    >
                                        <IconButton
                                            onClick={() => {
                                                setRepostPopup(true);
                                            }}
                                            sx={{ m: 0, p: 0 }}
                                        >
                                            <ShareIcon color="#464646" />
                                        </IconButton>
                                        <Typography
                                            variant="titleSmallSemiBold"
                                            color={'#808080'}
                                        >
                                            {feed.shares}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: '12px',
                                        alignItems: 'center',
                                        px: { xs: '8px', sm: '12px' },
                                        height: '36px',
                                        bgcolor: '#f2f2f2',
                                        borderRadius: '8px'
                                    }}
                                >
                                    <UploadIcon color="#464646" />
                                </Box>
                                {replyPopup && (
                                    <Box
                                        position="absolute"
                                        right={0}
                                        left={0}
                                        top="150%"
                                        display="flex"
                                        alignItems={'flex-start'}
                                        bgcolor="#f2f2f2"
                                        gap="8px"
                                        sx={{
                                            borderTop: onFocus
                                                ? '2px solid #49B6FF'
                                                : '1px solid #C5C5C5',
                                            borderBottom: onFocus
                                                ? '2px solid #49B6FF'
                                                : '1px solid #C5C5C5'
                                        }}
                                    >
                                        <TextField
                                            fullWidth
                                            multiline
                                            maxRows="3"
                                            onFocus={() => setOnFocus(true)}
                                            onBlur={() => setOnFocus(false)}
                                            value={comment}
                                            onChange={(e) =>
                                                setComment(e.target.value)
                                            }
                                            sx={{
                                                '& .MuiOutlinedInput-notchedOutline':
                                                    {
                                                        border: 'none',
                                                        // px: '12px',
                                                        py: '12px'
                                                    },
                                                '& .MuiOutlinedInput-root': {
                                                    '&.Mui-focused fieldset': {
                                                        border: 'none'
                                                    }
                                                }
                                            }}
                                            placeholder="Add a comment..."
                                        />
                                        <Button
                                            onClick={() => {
                                                handlePost();
                                                setReplyPopup(false);
                                            }}
                                            disabled={!comment}
                                            sx={{
                                                padding: '12px',
                                                width: '100px',
                                                height: '40px',
                                                background: 'transparent',
                                                borderRadius: '8px',
                                                textTransform: 'capitalize',
                                                textDecoration: 'none',
                                                fontFamily: 'Open Sans',
                                                fontWeight: '600',
                                                fontSize: '14px',
                                                letterSpacing: '0.001em',
                                                color:
                                                    comment?.length > 0
                                                        ? '#05668D'
                                                        : '#808080'
                                            }}
                                        >
                                            Reply
                                        </Button>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    ) : null}
                </Box>
                {location.pathname.includes('reviews') ? null : (
                    <Box sx={{ px: reposted ? '8px' : '16px' }} mt="16px">
                        {feed?.comments
                            ?.filter((x) => x.comment !== feed?.description)
                            ?.reverse()
                            .slice(0, 1)
                            .map((item: any, i) => (
                                <CommentBox
                                    feedId={feed?.id}
                                    key={item.id}
                                    item={item}
                                    setReplyingTo={setReplyingTo}
                                    replyingTo={replyingTo}
                                    handlePopup={() => {
                                        setCommentsPopup(true);
                                    }}
                                />
                            ))}
                    </Box>
                )}
            </>

            <PostCards
                repostPopup={repostPopup}
                showModal={repostPopup}
                data={feed?.repost ?? feed}
                hideModal={() => {
                    setRepostPopup(false);
                }}
            />
            <CommentsPopup
                open={commentsPopup}
                feed={feed}
                reposted={reposted}
                repostPopup={repostPopup}
                handleRepost={() => setRepostPopup(true)}
                handleCloseRepost={() => setRepostPopup(false)}
                handleClose={() => setCommentsPopup(false)}
            />
        </>
    );
};
