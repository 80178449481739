import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { PrimaryProfileType } from '../../utils/hooks/api/account/types';
import axiosInstance from '../../utils/hooks/api/axiosConfig';
import { useEffectOnce } from 'usehooks-ts';
import { Responsiveness } from '../../theme/overrides';

interface ScoreCardProps {
    score: number;
    height?: string;
    width?: string;
    background?: string;
    noText?: boolean;
}

const ScoreCard = ({ score, height, width, background, noText }: ScoreCardProps) => {
    const { isMobile } = Responsiveness()
    const [color, setColor] = useState('transparent');
    const divider = 850;
    useEffect(() => {
        if(score === undefined || score === null){
            setColor('transparent');
        }
        else if (score <= 250 && score != 0) {
            setColor('#808080');
        } else if (score <= 400) {
            setColor('#E75541');
        } else if (score <= 500) {
            setColor('#F6C70E');
        } else if (score <= 600) {
            setColor('#49B6FF');
        } else if (score <= 700) {
            setColor('#7ABE57');
        } else {
            setColor('#07AF22');
        }
    }, [score, divider]);
    const radius = 50 - 12 / 2;
    const pathDescription = `
      M 50,50 m 0,-${radius}
      a ${radius},${radius} 0 1 1 0,${2 * radius}
      a ${radius},${radius} 0 1 1 0,-${2 * radius}
    `;
    const diameter = Math.PI * 2 * radius;
    const percentage = (score / divider) * 100;

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                width: `${width}`,
                gap: '8px',
                height: `${height}`,
                background: `${background}`,
                zIndex: '999',
                position: 'sticky',
                bottom: 0
            }}
            component="span"
        >
            <svg
                className={'CircularProgressbar'}
                viewBox="0 0 100 100"
                width={122}
                height={122}
            >
                <defs>
                    <linearGradient
                        id="shadowGrad"
                        x1="0"
                        y1="0"
                        x2="100"
                        y2="100"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#fafafa" offset={0} />
                        <stop stopColor="#efefef" offset={1} />
                    </linearGradient>
                    <filter id="blur" x="0" y="0">
                        <feGaussianBlur in="SourceGraphic" stdDeviation="1" />
                    </filter>
                </defs>
                <circle
                    cx="54"
                    cy="54"
                    r="44"
                    fill="url(#shadowGrad)"
                    filter="url(#blur)"
                />
                <path
                    className="CircularProgressbar-trail"
                    style={{
                        stroke: '#d9d9d9',
                        strokeWidth: '1px',
                        fill: '#ffffff'
                    }}
                    d="m 49.999617,0.49987251 c -27.26776,0 -49.49890951,22.23277249 -49.49890951,49.50051649 0,27.26775 22.23114951,49.50051 49.49890951,49.50051 27.26778,0 49.50051,-22.23276 49.50051,-49.50051 0,-27.267744 -22.23273,-49.50051649 -49.50051,-49.50051649 z m 0,11.00313849 a 38.497875,38.497875 0 0 1 38.49898,38.497378 38.497875,38.497875 0 0 1 -38.49898,38.49737 38.497875,38.497875 0 0 1 -38.49737,-38.49737 38.497875,38.497875 0 0 1 38.49737,-38.497378 z"
                />

                <path
                    className="CircularProgressbar-path"
                    d={pathDescription}
                    strokeWidth={12}
                    fillOpacity={0}
                    style={{
                        stroke: color,
                        strokeLinecap: 'round',
                        strokeDasharray: `${diameter}px ${diameter}px`,
                        strokeDashoffset: `${
                            ((100 - percentage) / 100) * diameter
                        }px`,
                        transform: 'rotate(0.5turn)',
                        transformOrigin: 'center center',
                        transition: 'stroke-dashoffset 0.5s ease 0s'
                    }}
                />
                <circle cx="50" cy="50" r={'31'} fill="#FFC24C"></circle>
                <text
                    x={50}
                    y={50}
                    style={{
                        backgroundColor: '#FFC24C',
                        borderRadius: 100,
                        padding: 5,
                        fontWeight: '700',
                        dominantBaseline: 'central',
                        textAnchor: 'middle',
                        fill: 'white',
                        fontSize: '20px',
                        textShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)'
                    }}
                >
                    {score === undefined || score === null ? 0 : score}
                </text>
            </svg>
            {isMobile || noText ? null : <Typography
                sx={{
                    color: '#23282B',
                    fontWeight: '600',
                    fontSize: '16px',
                    fontFamily: 'open sans'
                }}
            >
                Jobfactor Score
            </Typography>}
        </Box>
    );
};

export default ScoreCard;
