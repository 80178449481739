import { useQuery } from 'react-query';
import { QueryKeys } from '../QueryKey';
import axiosInstance from '../axiosConfig';

export function useGetCompanyProfileScore(id: string) {
    const getCompanyProfileScore = async (id: string) => {
        const response = await axiosInstance.get(
            `/api/company-profiles/${id}/score`
        );
        return response.data;
    };
    return useQuery(
        [QueryKeys.RetrieveCompanyProfileScore, id],
        () => getCompanyProfileScore(id),
        {
            refetchOnMount: 'always'
        }
    );
}
