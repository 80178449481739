import React from 'react';

const Dashboard = () => {
    return (
        <div style={{ marginTop: '-48px' }}>
            <h1>Dashboard</h1>

            <>Page is unavailable</>
        </div>
    );
};

export default Dashboard;
