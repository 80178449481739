import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { isImageUrl, toDatestamp, toTimestamp } from '../../utils/helpers';
import { CheckBox } from '@mui/icons-material';

const dropShadow = '0px 2px 4px rgba(0, 0, 0, 0.1)';

const variantStyles = {
    sent: {
        backgroundColor: '#FFF7E8',
        alignSelf: 'flex-end',
        boxShadow: dropShadow
    },
    received: {
        backgroundColor: '#FFFFFF',
        alignSelf: 'flex-start',
        boxShadow: dropShadow
    }
};

interface MessageBubbleProps {
    variant: 'sent' | 'received';
    content: string;
    timestamp: Date;
    isRead: boolean;
}

export default function MessageBubble({
    variant,
    timestamp,
    content,
    isRead
}: MessageBubbleProps) {
    const [isImageLoaded, setIsImageLoaded] = React.useState(false);

    return (
        <Box
            sx={{
                ...variantStyles[variant],
                minWidth: '128px',
                maxWidth: '80%',
                p: '4px 12px',
                borderRadius: '12px'
            }}
        >
            {isImageUrl(content) ? (
                <Box
                    sx={{
                        p: '8px',
                        borderRadius: 0,
                        width: '100%'
                    }}
                >
                    <img
                        alt="Media message"
                        src={content}
                        height="auto"
                        style={{
                            maxWidth: '100%',
                            borderRadius: '8px',
                            transition: 'opacity 0.5s ease',
                            opacity: isImageLoaded ? 1 : 0
                        }}
                        onLoad={() => setIsImageLoaded(true)}
                    />
                </Box>
            ) : (
                <Typography
                    sx={{
                        fontSize: '16px',
                        color: '#23282B',
                        wordBreak: 'break-word'
                    }}
                >
                    {content}
                </Typography>
            )}
            <Stack
                direction="row"
                sx={{ justifyContent: 'end', alignItems: 'center' }}
            >
                <Typography
                    sx={{
                        fontSize: '12px',
                        color: '#76818E',
                        textAlign: 'right'
                    }}
                >
                    {toDatestamp(timestamp) + ', ' + toTimestamp(timestamp)}
                    {isRead && variant === 'sent' && (
                        <CheckBox
                            sx={{
                                fontSize: '14px',
                                mb: '-2px',
                                ml: '3px',
                                color: 'limegreen',
                                textAlign: 'right'
                            }}
                        />
                    )}
                </Typography>
            </Stack>
        </Box>
    );
}
