import { useQuery } from 'react-query';
import { QueryKeys } from '../QueryKey';
import axiosInstance from '../axiosConfig';

export function useGetAllProfessionalProfile() {
    const getAllProfessionalProfile = async () => {
        const response = await axiosInstance.get(
            `/api/professional-profiles`
        );
        const data = await response.data;
        return data;
    };
    return useQuery(
        QueryKeys.RetrieveAllProfessionalProfile,
        () => getAllProfessionalProfile(),
        {
            refetchOnMount: 'always'
        }
    );
}
