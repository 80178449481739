import { Avatar, Box, Button, IconButton, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { ArrowLeftIcon } from '../../assets/icons/ArrowLeftIcon';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Responsiveness } from '../../theme/overrides';
import { useAuth } from '../../utils/context/AuthContext';
import { PrimaryProfileType } from '../../utils/hooks/api/account/types';
import VerifiedIcon from '../../assets/icons/VerifiedIcon';
import DotIcon from '../../assets/icons/DotIcon';
import moment from 'moment';
import MoreIcon from '../../assets/icons/MoreIcon';
import { FeedItem } from '../Home/components/FeedItem';
import MessageIcon from '../../assets/icons/MessageIcon';
import { useAddCommentLike } from '../../utils/hooks/api/like-comments/useCreateCommentLike';
import { useRemoveCommentLike } from '../../utils/hooks/api/like-comments/useRemoveCommentLike';
import { usePost } from '../../utils/context/PostContext';
import CommentBox, { ICommentType } from '../Home/components/CommentBox';
import LikesIcon from '../../assets/icons/LikesIcon';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ShareIcon from '../../assets/icons/ShareIcon';
import UploadIcon from '../../assets/icons/UploadIcon';
import ViewIcon from '../../assets/icons/ViewIcon';
import { usePostComment } from '../../utils/hooks/api/comments/useCreateComment';
import { useQueryClient } from 'react-query';
import { QueryKeys } from '../../utils/hooks/api/QueryKey';
import PostCards from '../Home/components/PostCards';
import MobileEyeIcon from '../../assets/icons/MobileEyeIcon';
import { logger } from '../../utils/Helper/logger';
import { useGetUserPostById } from '../../utils/hooks/api/posts/useGetUserPostById';

interface IUserType {
    firstName?: string;
    lastName?: string;
    commentId: string;
}

const Post = () => {
    const { user } = useAuth()
    const location = useLocation()
    const feed = location?.state?.feed
    const replyTo = location?.state?.replyTo
    // const repostPopup = location?.state?.repostPopup
    const reposted = location?.state?.reposted
    const navigate = useNavigate()
    const { posts, setPosts } = usePost();
    const [comment, setComment] = useState('')
    const [onFocus, setOnFocus] = useState(false)
    const [repostPopup, setRepostPopup] = useState(false)
    const [replyingTo, setReplyingTo] = useState<IUserType>();
    const { isMobile, isTablet } = Responsiveness()
    const { postId } = useParams()
    const { data: post, refetch } = useGetUserPostById(postId ?? '')
        const queryClient = useQueryClient();
        const postComment = usePostComment();
        const addLike = useAddCommentLike();
        const removeLike = useRemoveCommentLike();
        const isProfessional =
        user?.primaryProfile === PrimaryProfileType.Professional;
    logger.log(post);
    const [postSuccess, setPostSuccess] = useState(false)
    useEffect(() => {
        refetch()
    }, [postSuccess])
   
    const updateLikes = (comments: ICommentType[], key: string) => {
        if (key === 'add') {
            comments[0].likes += 1;
            comments[0].likedByProfessionalIds.push({
                id: isProfessional
                    ? user?.professionalProfile?.id
                    : user?.primaryCompanyProfile?.companyId,
                firstName: isProfessional
                    ? user?.professionalProfile?.firstName
                    : user?.primaryCompanyProfile?.companyName,
                lastName: isProfessional
                    ? user?.professionalProfile?.lastName
                    : null,
                photoUrl: isProfessional
                    ? user?.professionalProfile?.photoUrl
                    : user?.primaryCompanyProfile?.photoUrl
            });
        } else {
            comments[0].likes -= 1;
            comments[0].likedByProfessionalIds =
                comments[0]?.likedByProfessionalIds?.filter(
                    (x: any) =>
                        x.id !==
                        (isProfessional
                            ? user?.professionalProfile?.id ?? ''
                            : user?.primaryCompanyProfile?.companyId ?? '')
                );
        }
        return comments;
    };
    

    const handlePost = () => {
        const request: any = {
            userId: user?.id ?? '',
            postId: feed?.id ?? '',
            comment,
            commentId: (replyingTo ?? replyTo)?.commentId ?? ''
        };
        postComment.mutate(request, {
            onSuccess: (res: any) => {
                setPostSuccess(true)
                let tempPosts = JSON.parse(JSON.stringify(posts));
                const getComment = (comments: ICommentType[]) => {
                    comments?.filter((y) => {
                        if (y.id === replyingTo?.commentId) {
                            y.replies.push({
                                ...res,
                                postId: feed?.id,
                                likedByProfessionalIds: [],
                                likes: 0
                            });
                        } else {
                            getComment(y.replies);
                        }
                        return y;
                    });
                    return comments;
                };
                tempPosts.filter((post: any) => {
                    if (post?.id === feed?.id) {
                        if (replyingTo?.commentId) {
                            getComment(post?.comments ?? []);
                        } else {
                            post?.comments.push({
                                ...res,
                                postId: post?.id,
                                likedByProfessionalIds: [],
                                likes: 0
                            });
                            post.commentsCount += 1;
                        }
                    }
                    return post;
                });
                setPosts(tempPosts);
                setComment('');
                setReplyingTo(undefined);
                queryClient.invalidateQueries(QueryKeys.RetrieveUserPosts);
            },
            onError: (err: any) => {
                console.log('ERR', err);
            }
        });
    };

     const handleLike = (key: string) => {
         if (key === 'add') {
             const request: any = {
                 userId: user?.id ?? '',
                 postId: feed?.id ?? '',
                 commentId: feed?.comments ? feed?.comments[0]?.id : ''
             };
             addLike.mutate(request, {
                 onSuccess: (res) => {
                     console.log('COMMENT RES', res);
                     let tempPosts = JSON.parse(JSON.stringify(posts));
                     tempPosts.filter((post: any) => {
                         console.log('post', post);
                         if (post?.id === feed?.id) {
                             updateLikes(post?.comments ?? [], 'add');
                         }
                         return post;
                     });
                     setPosts(tempPosts);
                 },
                 onError: (err) => {
                     console.log('ERR', err);
                 }
             });
         } else {
             removeLike.mutate(
                 {
                     userId: user?.id ?? '',
                     commentId: feed?.comments ? feed?.comments[0]?.id : ''
                 },
                 {
                     onSuccess: (res) => {
                         console.log('REMOVE COMMENT RES', res);
                         let tempPosts = JSON.parse(JSON.stringify(posts));
                         tempPosts.filter((post: any) => {
                             if (post?.id === feed?.id) {
                                 updateLikes(post?.comments ?? [], 'subtract');
                             }
                             return post;
                         });
                         setPosts(tempPosts);
                     },
                     onError: (err) => {
                         console.log('ERR', err);
                     }
                 }
             );
         }
     };
  return (
      <Box position="relative">
          <Box position="relative">
              <Box
                  width="100%"
                  position="relative"
                  pb={{ xs: '120px', sm: '70px' }}
              >
                  <Box
                      width="100%"
                      p={isMobile ? '16px' : '20px'}
                      // width="100%"
                      bgcolor="#fff"
                      mt={{ xs: '6px', sm: 0 }}
                      mb="8px"
                      //   borderBottom="1px solid #D9D9D9"
                      display="flex"
                      alignItems="center"
                      gap="20px"
                  >
                      <Box
                          onClick={() => navigate(-1)}
                          sx={{ cursor: 'pointer' }}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                      >
                          <ArrowLeftIcon />
                      </Box>
                      <Typography
                          fontFamily="Open Sans"
                          fontSize={isMobile ? '16px' : '24px'}
                          fontWeight={600}
                          lineHeight="32px"
                          color="#23282b"
                      >
                          Post
                      </Typography>
                  </Box>
                  <Box
                      position="relative"
                      bgcolor="#fff"
                      sx={{
                          // display: 'none',
                          ml: reposted ? '8px' : 0,
                          p: reposted ? '8px' : { xs: '16px', sm: '20px' },
                          mb: reposted ? 0 : '8px',
                          // background: reposted ? '#F2F2F2' : '#fff',
                          border:
                              reposted && !repostPopup ? '1px solid #C5C5C5' : 0
                          // width: '100%'
                      }}
                      className="feedItem"
                  >
                      <Box
                          sx={{
                              display: 'flex',
                              gap: '16px',
                              justifyContent: 'space-between'
                          }}
                      >
                          <Box
                              sx={{
                                  display: 'flex',
                                  gap: '16px'
                                  // width: '100%'
                              }}
                          >
                              <Avatar
                                  onClick={() =>
                                      navigate(
                                          feed?.primaryProfile ===
                                              PrimaryProfileType.Professional
                                              ? `/professional-profile/${feed?.profileId}`
                                              : `/company-profile/${feed?.profileId}`
                                      )
                                  }
                                  src={feed.profileImage}
                                  alt="feed"
                                  sx={{
                                      width: 48,
                                      height: 48,
                                      borderRadius: 100,
                                      cursor: 'pointer'
                                  }}
                              />
                              <Box>
                                  <Box
                                      sx={{
                                          display: 'flex',
                                          gap: '4px',
                                          alignItems: 'center'
                                      }}
                                  >
                                      <Typography
                                          sx={{
                                              fontSize: 14,
                                              fontWeight: 600,
                                              cursor: 'pointer',
                                              '&:hover': {
                                                  textDecoration: 'underline'
                                              }
                                          }}
                                          color="#23282B"
                                          variant="titleMediumSemiBold"
                                          onClick={() =>
                                              navigate(
                                                  feed?.primaryProfile ===
                                                      PrimaryProfileType.Professional
                                                      ? `/professional-profile/${feed?.profileId}`
                                                      : `/company-profile/${feed?.profileId}`
                                              )
                                          }
                                      >
                                          {feed.fullName}
                                      </Typography>
                                      <Box
                                          component={'span'}
                                          display={'flex'}
                                          sx={{
                                              '& svg': {
                                                  width: 16,
                                                  height: 16
                                              }
                                          }}
                                      >
                                          <VerifiedIcon />
                                      </Box>
                                      <Box
                                          sx={{
                                              mx: '4px',
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'center',
                                              '& svg': {
                                                  width: 4,
                                                  height: 4
                                              }
                                          }}
                                      >
                                          <DotIcon color="#808080" />
                                      </Box>
                                      <Typography
                                          color="#808080"
                                          variant="titleSmallRegular"
                                          fontSize={12}
                                      >
                                          {moment(feed.createdAt).fromNow()}
                                      </Typography>
                                  </Box>
                                  <Typography
                                      color="#808080"
                                      variant="titleSmallRegular"
                                  >
                                      {feed.jobTitle}
                                  </Typography>
                              </Box>
                          </Box>
                          <MoreIcon />
                      </Box>
                      <Box>
                          <Box sx={{ mt: '12px' }}>
                              <Typography
                                  component="div"
                                  sx={{ whiteSpace: 'pre-line' }}
                              >
                                  <Typography
                                      color={''}
                                      variant="titleSmallRegular"
                                      // ref={DescriptionRef}
                                      sx={{
                                          whiteSpace: 'pre-line',
                                          mb: 1
                                      }}
                                  >
                                      {feed?.description}
                                  </Typography>
                              </Typography>
                          </Box>
                          <Box
                              sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  width: '100%'
                              }}
                          >
                              {feed?.images?.length > 0 && (
                                  <Box
                                      sx={{
                                          mt: '15px',
                                          position: 'relative',
                                          display: 'flex',
                                          gap: '12px',
                                          overflow: 'hidden',
                                          height: '100%',
                                          width: '100%',
                                          boxSizing: 'border-box'
                                      }}
                                  >
                                      {feed.images.map(
                                          (image: any, index: any) => (
                                              <img
                                                  key={index}
                                                  src={image}
                                                  alt="feed"
                                                  style={{
                                                      height: 'fit-content',
                                                      width: `${
                                                          100 /
                                                          (feed?.images
                                                              ?.length > 1
                                                              ? 2
                                                              : 1)
                                                      }%`,
                                                      objectFit:
                                                          feed?.images
                                                              ?.length === 1
                                                              ? 'contain'
                                                              : 'fill'
                                                  }}
                                              />
                                          )
                                      )}
                                      {feed?.images?.length > 2 && (
                                          <Box
                                              sx={{
                                                  position: 'absolute',
                                                  bottom: '10px',
                                                  right: '10px',
                                                  color: '#FAFAFA',
                                                  fontFamily: 'Open Sans',
                                                  fontSize: '36px',
                                                  fontStyle: 'normal',
                                                  fontWeight: '600',
                                                  lineHeight: '44px'
                                              }}
                                          >
                                              +{feed?.images?.length - 2}
                                          </Box>
                                      )}
                                  </Box>
                              )}
                          </Box>
                      </Box>
                      {feed?.repost ? (
                          <FeedItem feed={feed?.repost} reposted={true} />
                      ) : null}
                      {!reposted ? (
                          <Box>
                              <Box
                                  sx={{
                                      mt: '16px',
                                      mb: isMobile ? '12px' : '40px',
                                      display: { xs: 'flex', sm: 'none' },
                                      gap: '10px',
                                      alignItems: 'center',
                                      '& svg': {
                                          width: '22px',
                                          height: '22px'
                                      }
                                  }}
                              >
                                  {/* <Box
                                sx={{
                                    display: 'flex',
                                    gap: '12px',
                                    alignItems: 'center',
                                    height: '36px',
                                    bgcolor: '#f2f2f2',
                                    borderRadius: '8px',
                                    px: '12px'
                                }}
                            >
                                <ViewIcon color="#999999" />
                                <Typography
                                    variant="titleMediumSemiBold"
                                    color={'#808080'}
                                >
                                    {feed?.views}
                                </Typography>
                            </Box> */}
                                  <Box
                                      sx={{
                                          width: '100%',
                                          display: 'flex',
                                          gap: '8px',
                                          alignItems: 'center',
                                          px: '8px',
                                          height: '36px',
                                          bgcolor: '#f2f2f2',
                                          borderRadius: '8px'
                                      }}
                                  >
                                      <IconButton
                                          sx={{
                                              m: 0,
                                              p: 0,
                                              display: {
                                                  xs: 'block',
                                                  sm: 'none'
                                              }
                                          }}
                                      >
                                          <UploadIcon
                                              color="#464646"
                                              width={'16px'}
                                              height={'16px'}
                                          />
                                      </IconButton>
                                      <IconButton
                                          sx={{
                                              m: 0,
                                              p: 0,
                                              display: {
                                                  xs: 'none',
                                                  sm: 'block'
                                              }
                                          }}
                                      >
                                          <UploadIcon color="#464646" />
                                      </IconButton>
                                      <Typography
                                          variant="titleSmallSemiBold"
                                          color={'#808080'}
                                      >
                                          Send
                                      </Typography>
                                  </Box>

                                  <Box
                                      sx={{
                                          width: '100%',
                                          display: 'flex',
                                          gap: '8px',
                                          alignItems: 'center',
                                          height: '36px',
                                          px: '8px',
                                          bgcolor: '#f2f2f2',
                                          borderRadius: '8px'
                                      }}
                                  >
                                      {post?.comments &&
                                      post?.comments[0]?.likedByProfessionalIds?.find(
                                          (x: any) =>
                                              x.id ===
                                              (isProfessional
                                                  ? user?.professionalProfile
                                                        ?.id ?? ''
                                                  : user?.primaryCompanyProfile
                                                        ?.companyId ?? '')
                                      ) ? (
                                          <>
                                              <IconButton
                                                  onClick={() =>
                                                      handleLike('remove')
                                                  }
                                                  sx={{
                                                      m: 0,
                                                      p: 0,
                                                      display: {
                                                          xs: 'block',
                                                          sm: 'none'
                                                      }
                                                  }}
                                              >
                                                  <LikesIcon
                                                      width={'16px'}
                                                      height={'16px'}
                                                  />
                                              </IconButton>
                                              <IconButton
                                                  onClick={() =>
                                                      handleLike('remove')
                                                  }
                                                  sx={{
                                                      m: 0,
                                                      p: 0,
                                                      display: {
                                                          xs: 'none',
                                                          sm: 'block'
                                                      }
                                                  }}
                                              >
                                                  <LikesIcon />
                                              </IconButton>
                                          </>
                                      ) : (
                                          <IconButton
                                              onClick={() => handleLike('add')}
                                              sx={{
                                                  maxHeight: '20px',
                                                  maxWidth: '20px',
                                                  m: 0,
                                                  p: 0
                                              }}
                                          >
                                              <FavoriteBorderIcon
                                                  sx={{
                                                      width: {
                                                          xs: '16px',
                                                          sm: '20px'
                                                      },
                                                      height: {
                                                          xs: '16px',
                                                          sm: '20px'
                                                      },
                                                      color: '#464646'
                                                  }}
                                              />
                                          </IconButton>
                                      )}
                                      <Typography
                                          variant="titleSmallSemiBold"
                                          color={'#808080'}
                                      >
                                          {/* {(feed?.comments
                                                                ? feed
                                                                      ?.comments[0]
                                                                      ?.likes
                                                                : 0) ??
                                                                feed?.likes} */}
                                          Like
                                      </Typography>
                                  </Box>
                                  <Box
                                      sx={{
                                          width: '100%',
                                          display: 'flex',
                                          gap: '8px',
                                          alignItems: 'center',
                                          height: '36px',
                                          px: '8px',
                                          bgcolor: '#f2f2f2',
                                          borderRadius: '8px'
                                      }}
                                      onClick={() =>
                                          setReplyingTo({
                                              commentId: post?.id
                                          })
                                      }
                                  >
                                      <IconButton
                                          sx={{
                                              m: 0,
                                              p: 0,
                                              display: {
                                                  xs: 'block',
                                                  sm: 'none'
                                              }
                                          }}
                                      >
                                          <MessageIcon
                                              width={'16px'}
                                              height={'16px'}
                                          />
                                      </IconButton>
                                      <IconButton
                                          sx={{
                                              m: 0,
                                              p: 0,
                                              display: {
                                                  xs: 'none',
                                                  sm: 'block'
                                              }
                                          }}
                                      >
                                          <MessageIcon />
                                      </IconButton>
                                      <Typography
                                          variant="titleSmallSemiBold"
                                          color={'#808080'}
                                      >
                                          Comment
                                      </Typography>
                                  </Box>
                                  <Box
                                      sx={{
                                          width: '100%',
                                          display: 'flex',
                                          gap: '8px',
                                          alignItems: 'center',
                                          height: '36px',
                                          px: '8px',
                                          bgcolor: '#f2f2f2',
                                          borderRadius: '8px'
                                      }}
                                  >
                                      <IconButton
                                          onClick={() => setRepostPopup(true)}
                                          sx={{
                                              m: 0,
                                              p: 0,
                                              display: {
                                                  xs: 'block',
                                                  sm: 'none'
                                              }
                                          }}
                                      >
                                          <ShareIcon
                                              color="#464646"
                                              width={'16px'}
                                              height={'16px'}
                                          />
                                      </IconButton>
                                      <IconButton
                                          onClick={() => setRepostPopup(true)}
                                          sx={{
                                              m: 0,
                                              p: 0,
                                              display: {
                                                  xs: 'none',
                                                  sm: 'block'
                                              }
                                          }}
                                      >
                                          <ShareIcon color="#464646" />
                                      </IconButton>
                                      <Typography
                                          variant="titleSmallSemiBold"
                                          color={'#808080'}
                                      >
                                          Repost
                                      </Typography>
                                  </Box>
                                  {/* <Box
                                          sx={{
                                              display: 'flex',
                                              gap: '12px',
                                              alignItems: 'center',
                                              height: '36px',
                                              px: '12px',
                                              bgcolor: '#f2f2f2',
                                              borderRadius: '8px'
                                          }}
                                      >
                                          <IconButton sx={{ m: 0, p: 0 }}>
                                              <MessageIcon />
                                          </IconButton>
                                          <Typography
                                              variant="titleSmallSemiBold"
                                              color={'#808080'}
                                          >
                                              {feed?.commentsCount}
                                          </Typography>
                                      </Box>
                                      <Box
                                          sx={{
                                              display: 'flex',
                                              gap: '12px',
                                              alignItems: 'center',
                                              height: '36px',
                                              px: '12px',
                                              bgcolor: '#f2f2f2',
                                              borderRadius: '8px'
                                          }}
                                      >
                                          {post?.comments &&
                                          post?.comments[0]?.likedByProfessionalIds?.find(
                                              (x: any) =>
                                                  x.id ===
                                                  (isProfessional
                                                      ? user
                                                            ?.professionalProfile
                                                            ?.id ?? ''
                                                      : user
                                                            ?.primaryCompanyProfile
                                                            ?.companyId ?? '')
                                          ) ? (
                                              <IconButton
                                                  onClick={() =>
                                                      handleLike('remove')
                                                  }
                                                  sx={{
                                                      m: 0,
                                                      p: 0
                                                  }}
                                              >
                                                  <LikesIcon />
                                              </IconButton>
                                          ) : (
                                              <IconButton
                                                  onClick={() =>
                                                      handleLike('add')
                                                  }
                                                  sx={{
                                                      maxHeight: '20px',
                                                      maxWidth: '20px',
                                                      m: 0,
                                                      p: 0
                                                  }}
                                              >
                                                  <FavoriteBorderIcon
                                                      sx={{
                                                          color: '#464646'
                                                      }}
                                                  />
                                              </IconButton>
                                          )}
                                          <Typography
                                              variant="titleSmallSemiBold"
                                              color={'#808080'}
                                          >
                                              {(post?.comments
                                                  ? post?.comments[0]?.likes
                                                  : 0) ?? feed?.likes}
                                          </Typography>
                                      </Box>
                                      <Box
                                          sx={{
                                              display: 'flex',
                                              gap: '12px',
                                              alignItems: 'center',
                                              height: '36px',
                                              px: '12px',
                                              bgcolor: '#f2f2f2',
                                              borderRadius: '8px'
                                          }}
                                      >
                                          <IconButton
                                              onClick={() =>
                                                  setRepostPopup(true)
                                              }
                                              sx={{ m: 0, p: 0 }}
                                          >
                                              <ShareIcon color="#464646" />
                                          </IconButton>
                                          <Typography
                                              variant="titleSmallSemiBold"
                                              color={'#808080'}
                                          >
                                              {feed.shares}
                                          </Typography>
                                      </Box> */}
                              </Box>
                              <Box
                                  sx={{
                                      mt: '16px',
                                      mb: isMobile ? '12px' : '40px',
                                      display: { xs: 'none', sm: 'flex' },
                                      gap: '24px',
                                      alignItems: 'center',
                                      justifyContent: 'space-between',
                                      '& svg': {
                                          width: '22px',
                                          height: '22px'
                                      }
                                  }}
                              >
                                  <Box
                                      sx={{
                                          display: 'flex',
                                          gap: '12px',
                                          alignItems: 'center'
                                      }}
                                  >
                                      {/* <Box
                                sx={{
                                    display: 'flex',
                                    gap: '12px',
                                    alignItems: 'center',
                                    height: '36px',
                                    bgcolor: '#f2f2f2',
                                    borderRadius: '8px',
                                    px: '12px'
                                }}
                            >
                                <ViewIcon color="#999999" />
                                <Typography
                                    variant="titleMediumSemiBold"
                                    color={'#808080'}
                                >
                                    {feed?.views}
                                </Typography>
                            </Box> */}
                                      <Box
                                          sx={{
                                              display: 'flex',
                                              gap: '12px',
                                              alignItems: 'center',
                                              height: '36px',
                                              px: '12px',
                                              bgcolor: '#f2f2f2',
                                              borderRadius: '8px'
                                          }}
                                      >
                                          <IconButton sx={{ m: 0, p: 0 }}>
                                              <MessageIcon />
                                          </IconButton>
                                          <Typography
                                              variant="titleSmallSemiBold"
                                              color={'#808080'}
                                          >
                                              {feed?.commentsCount}
                                          </Typography>
                                      </Box>
                                      <Box
                                          sx={{
                                              display: 'flex',
                                              gap: '12px',
                                              alignItems: 'center',
                                              height: '36px',
                                              px: '12px',
                                              bgcolor: '#f2f2f2',
                                              borderRadius: '8px'
                                          }}
                                      >
                                          {post?.comments &&
                                          post?.comments[0]?.likedByProfessionalIds?.find(
                                              (x: any) =>
                                                  x.id ===
                                                  (isProfessional
                                                      ? user
                                                            ?.professionalProfile
                                                            ?.id ?? ''
                                                      : user
                                                            ?.primaryCompanyProfile
                                                            ?.companyId ?? '')
                                          ) ? (
                                              <IconButton
                                                  onClick={() =>
                                                      handleLike('remove')
                                                  }
                                                  sx={{
                                                      m: 0,
                                                      p: 0
                                                  }}
                                              >
                                                  <LikesIcon />
                                              </IconButton>
                                          ) : (
                                              <IconButton
                                                  onClick={() =>
                                                      handleLike('add')
                                                  }
                                                  sx={{
                                                      maxHeight: '20px',
                                                      maxWidth: '20px',
                                                      m: 0,
                                                      p: 0
                                                  }}
                                              >
                                                  <FavoriteBorderIcon
                                                      sx={{
                                                          color: '#464646'
                                                      }}
                                                  />
                                              </IconButton>
                                          )}
                                          <Typography
                                              variant="titleSmallSemiBold"
                                              color={'#808080'}
                                          >
                                              {(post?.comments
                                                  ? post?.comments[0]?.likes
                                                  : 0) ?? feed?.likes}
                                          </Typography>
                                      </Box>
                                      <Box
                                          sx={{
                                              display: 'flex',
                                              gap: '12px',
                                              alignItems: 'center',
                                              height: '36px',
                                              px: '12px',
                                              bgcolor: '#f2f2f2',
                                              borderRadius: '8px'
                                          }}
                                      >
                                          <IconButton
                                              onClick={() =>
                                                  setRepostPopup(true)
                                              }
                                              sx={{ m: 0, p: 0 }}
                                          >
                                              <ShareIcon color="#464646" />
                                          </IconButton>
                                          <Typography
                                              variant="titleSmallSemiBold"
                                              color={'#808080'}
                                          >
                                              {feed.shares}
                                          </Typography>
                                      </Box>
                                  </Box>
                                  <Box
                                      sx={{
                                          display: 'flex',
                                          gap: '12px',
                                          alignItems: 'center',
                                          px: '12px',
                                          height: '36px',
                                          bgcolor: '#f2f2f2',
                                          borderRadius: '8px'
                                      }}
                                  >
                                      <UploadIcon color="#464646" />
                                  </Box>
                              </Box>
                              {isMobile ? (
                                  <Box
                                      sx={{
                                          display: 'flex',
                                          gap: '12px',
                                          alignItems: 'center',
                                          justifyContent: 'space-between'
                                      }}
                                  >
                                      <Box
                                          sx={{
                                              display: 'flex',
                                              gap: '4px',
                                              alignItems: 'center',
                                              justifyContent: 'space-between'
                                          }}
                                      >
                                          <MobileEyeIcon />
                                          <Typography
                                              fontSize="12px"
                                              color={'#808080'}
                                          >
                                              {feed?.views}
                                          </Typography>
                                      </Box>
                                      <Box
                                          sx={{
                                              display: 'flex',
                                              gap: '12px',
                                              alignItems: 'center'
                                          }}
                                      >
                                          <Box>
                                              {/* <Typography
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                letterSpacing:
                                                                    '0.0015em',
                                                                color: '#808080',
                                                                fontSize: '16px'
                                                            }}
                                                        >
                                                            {item?.likes === 1
                                                                ? item?.likes +
                                                                  ' Like'
                                                                : item?.likes +
                                                                  ' Likes'}
                                                        </Typography> */}
                                          </Box>
                                          {/* <DotIcon /> */}
                                          <Box>
                                              <Typography
                                                  sx={{
                                                      fontFamily: 'Open Sans',
                                                      letterSpacing: '0.0015em',
                                                      color: '#808080',
                                                      fontSize: '12px'
                                                  }}
                                              >
                                                  {post?.comments?.length === 2
                                                      ? post?.comments?.length -
                                                        1 +
                                                        ' Comment'
                                                      : post?.comments?.length -
                                                        1 +
                                                        ' Comments'}
                                              </Typography>
                                          </Box>
                                      </Box>
                                  </Box>
                              ) : null}
                          </Box>
                      ) : null}
                  </Box>
                  <Box px={{ xs: '16px', sm: '20px' }}>
                      {post?.comments?.length ? (
                          <Box>
                              {post?.comments
                                  ?.slice(1, post?.comments?.length)
                                  ?.map((item: any, i: any) => (
                                      <CommentBox
                                          isFirstComment={i === 0}
                                          key={item.id}
                                          item={item}
                                          setReplyingTo={setReplyingTo}
                                      />
                                  ))}
                          </Box>
                      ) : (
                          <Typography pl="20px">No comments</Typography>
                      )}
                  </Box>
                  <Box
                      position="fixed"
                      bottom={{ xs: '68px', sm: 0 }}
                      right={0}
                      left={{ xs: 0, sm: 0 }}
                      // width={isMobile ? undefined : '634px'}
                      sx={{
                          bgcolor: '#fff',
                          py: '8px'
                      }}
                  >
                      <Box
                          // width={isMobile ? undefined : '634px'}
                          sx={{
                              background: '#f2f2f2',
                              border: onFocus
                                  ? '1px solid #49B6FF'
                                  : '1px solid #D9D9D9',
                              padding: { xs: '10px 16px', sm: '16px 20px' },
                              display: 'flex',
                              flexDirection: 'column',
                              gap: '16px',
                              // width: '100%',
                              ml: '0 !important'
                          }}
                      >
                          {replyingTo ?? replyTo ? (
                              <Typography
                                  sx={{
                                      fontFamily: 'Open Sans',
                                      letterSpacing: '0.0015em',
                                      color: '#808080',
                                      fontSize: '16px'
                                  }}
                              >
                                  Replying to{' '}
                                  {(replyingTo ?? replyTo)?.firstName}{' '}
                                  {(replyingTo ?? replyTo)?.lastName}
                              </Typography>
                          ) : null}
                          <Box
                              sx={{
                                  display: 'flex',
                                  alignItems:
                                      comment.length > 0
                                          ? 'flex-start'
                                          : 'center',
                                  gap: '0px'
                              }}
                          >
                              <Avatar
                                  // onClick={() =>
                                  //     navigate(isProfessional ? `/professional-profile/${item?.user?.id}` : `/company-profile/${item?.user?.id}`)
                                  // }
                                  src={user?.professionalProfile?.photoUrl}
                                  sx={{
                                      cursor: 'pointer',
                                      width: '32px',
                                      height: '32px'
                                  }}
                              />
                              <TextField
                                  fullWidth
                                  value={comment}
                                  onChange={(e) => setComment(e.target.value)}
                                  onFocus={() => setOnFocus(true)}
                                  onBlur={() => setOnFocus(false)}
                                  multiline
                                  maxRows={3}
                                  sx={{
                                      m: 0,
                                      p: 0,
                                      ml: '12px',
                                      '& .MuiOutlinedInput-notchedOutline': {
                                          border: '0'
                                      },
                                      '& .MuiOutlinedInput-root': {
                                          padding: 0,
                                          margin: 0
                                      },
                                      flex: 1,
                                      input: {
                                          m: 0,
                                          p: 0
                                      }
                                  }}
                                  placeholder="Add a comment..."
                              />
                              <Button
                                  onClick={handlePost}
                                  variant="text"
                                  //   disabled={!comment}
                                  sx={{
                                      p: 0,
                                      m: 0,
                                      //   padding: '12px',
                                      width: '23px',
                                      //   height: '40px',
                                      //   background:
                                      //       comment.length > 0
                                      //           ? '#05668D'
                                      //           : '#EDEDED',
                                      //   borderRadius: '8px',
                                      textTransform: 'capitalize',
                                      textDecoration: 'none',
                                      fontFamily: 'Open Sans',
                                      fontWeight: '600',
                                      fontSize: '14px',
                                      //   letterSpacing: '0.001em',
                                      color:
                                          comment.length > 0
                                              ? '#05668D'
                                              : '#808080'
                                  }}
                              >
                                  Reply
                              </Button>
                          </Box>
                      </Box>
                  </Box>
              </Box>
          </Box>
          <PostCards
              repostPopup={repostPopup}
              showModal={repostPopup}
              data={feed?.repost ?? feed}
              hideModal={() => {
                  setRepostPopup(false);
              }}
          />
      </Box>
  );
}

export default Post
