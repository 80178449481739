/* eslint-disable react-hooks/rules-of-hooks */
import CircleIcon from '@mui/icons-material/Circle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import cambridge from '../../assets/images/cambridge.jpg';
import Image from '../../components/Image';
import ScoreCard from '../../components/ScoreCard.tsx';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    useTheme,
    useMediaQuery,
    Tabs,
    Tab,
    ImageListItem,
    Link
} from '@mui/material';
import { useGetProfessionalProfileScore } from '../../utils/hooks/api/professional-profile/useGetProfessionalProfileScore';
import { useGetProfessionalProfileById } from '../../utils/hooks/api/professional-profile/useGetProfessionalProfile';
import { GenericCompanyLogo } from '../../assets/images';
import ProfileBanner from '../ProfileBanner';
import InfoChip from '../Users/components/InfoChip';
import Section from './components/Section';
import SeeMoreIcon from '../Users/components/SeeMoreIcon';
import EducationSummary from '../Users/components/EducationSummary';
import { Ranking } from '../Users/types/Ranking';
import WorkSummary from '../Users/components/WorkSummary';
import ExperienceLevel from '../Users/components/ExperienceLevel';
import { experienceLevels } from '../Reviews/constants';
import ArrowUpRight from '../../assets/icons/ArrowUpRight';
import EmailSolid from '../../assets/icons/EmailSolid';
import ArrowForward from '../../assets/icons/ArrowForward';
import { usePost } from '../../utils/context/PostContext';
import InfiniteScroll from 'react-infinite-scroll-component';
import TabWrapper from './components/TabWrapper';
import PostCard from './components/PostCard';
// import SkeletonLoader from './components/SkeletonLoader';
import cover from '../../assets/images/cover.jpg';
import ProfilePhotoIcon from '../../assets/icons/ProfilePhotoIcon';
import { Responsiveness } from '../../theme/overrides';
import PageHeader from '../../components/PageHeader/PageHeader';
import MobileNav from '../../components/MobileNav/MobileNav';
import ArrowIconRight from '../../assets/icons/ArrowIconRight';
import Alert from '../../assets/icons/Alert';
import { FeedItem } from '../Home/components/FeedItem';
import AnalyticsSearch from '../../assets/icons/AnalyticsSearch';
import AnalyticsGraph from '../../assets/icons/AnalyticsGraph';
import AnalyticsProfile from '../../assets/icons/AnalyticsProfile';
import ArrowRight from '../../assets/icons/ArrowRight';
import ProfileList from '../Activities/components/ProfileList';
import ImageList from '@mui/material/ImageList';
import profImg1 from '../../assets/images/profileReview.png';
import profImg2 from '../../assets/images/feed2.png';
import profImg3 from '../../assets/images/SideNavProfile.png';
import SkeletonLoader from '../Home/components/SkeletonLoader';
import { ArrowLeftIcon } from '../../assets/icons/ArrowLeftIcon';
import { useAuth } from '../../utils/context/AuthContext';
import MoreIcon from '../../assets/icons/MoreIcon';
import TickCircle from '../../assets/icons/TickCircle';
import ArrowRightIcon from '../Users/components/ArrowRightIcon';
import MedalIcon from '../../assets/icons/MedalIcon';
import { PrimaryProfileType } from '../../utils/hooks/api/account/types';
import { useSendConnectionRequest } from '../../utils/hooks/api/connections/useSendConnectionRequest';
import { useGetUser } from '../../utils/hooks/api/users/useGetUser';
import { QueryKeys } from '../../utils/hooks/api/QueryKey';
import { useQueryClient } from 'react-query';
import axios, { AxiosError } from 'axios';
import SnackAlert, { SnackAlertType } from '../../components/Snackbar';
import { logger } from '../../utils/Helper/logger';
import { useGetConnections } from '../../utils/hooks/api/connections/useGetConnections';
import { useGetConnectionRequestSent } from '../../utils/hooks/api/connections/useGetConnectionRequestSent';

interface ISkillType {
    level: string;
    name: string;
    rating: number;
    type: string;
    witness: boolean;
}

function ProfessionalProfile() {
    const theme = useTheme();
    const maxCount = 5;
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const { isMobile, isTablet } = Responsiveness();
    const { id } = useParams();
    const [showMore, setShowMore] = useState(false);
    const [showMoreSkills, setShowMoreSkills] = useState(false);
    const { data: score, refetch: refetchScore } =
        useGetProfessionalProfileScore(id!);
    const { data: profile, refetch } = useGetProfessionalProfileById(id!);
    const [value, setValue] = useState(0);
    const [showAllPosts, setShowAllPosts] = useState(false);
    const [activity, setActivity] = useState('Posts');
    const currentActivity = ['Posts', 'Achievements', 'Articles', 'Jobs'];
    const { posts, totalPosts, isLoaded, setGetAfter } = usePost();
    const navigate = useNavigate();
    const { user, account } = useAuth();
    const [privacy, setPrivacy] = useState(true);
    const sendConnectRequest = useSendConnectionRequest();
    const userInfoQuery = useGetUser(profile?.userId);
    // const [isAdded, setIsAdded] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState('');
    const [type, setType] = useState<SnackAlertType>('info');
    const queryClient = useQueryClient();
    const { data: connections, isFetching } = useGetConnections(user?.id ?? '');
    const isAdded = connections?.filter(
        (connection: any) =>
            connection.destinationUser.userId === profile?.userId
    );
    const isConnected = isAdded?.length === 0 ? false : true;
    const { data: sentRequests } = useGetConnectionRequestSent(user?.id ?? '');
    logger.log('rofile', profile);
    const requestSent = sentRequests?.filter(
        (connection: any) =>
            connection.destinationUser.userId === profile?.userId
    );
    const isRequested = requestSent?.length === 0 ? false : true;

    const professionals = [
        {
            profileImage: profImg1,
            fullName: 'Jane Cooper',
            jobTitle: 'Product Designer',
            score: 550
        },
        {
            profileImage: profImg2,
            fullName: 'Esther Howard',
            jobTitle: 'Accounting leader',
            score: 450
        },
        {
            profileImage: profImg3,
            fullName: 'Kathryn Murphy',
            jobTitle: 'Human Resource',
            score: 750
        }
    ];

    let Images: any[] = [];
    posts?.map((postImages) =>
        postImages?.images?.map((image) => Images.push(image))
    );

    const [isTruncated, setIsTruncated] = useState(false);
    const containerRef = useRef(null);

    useEffect(() => {
        const container: any = containerRef.current;
        if (container !== null) {
            if (container.scrollHeight > container.clientHeight) {
                setIsTruncated(true);
            } else {
                setIsTruncated(false);
            }
        }
    }, [profile?.bio]);

    const allyProps = (index: number) => {
        return {
            id: `tab-${index}`,
            'aria-controls': `tabpanel-${index}`
        };
    };

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        setShowAllPosts(false);
    };

    const privacyFilter = () => {
        if (user?.professionalProfile?.id === profile?.id) {
            return setPrivacy(false);
        }
    };

    const sendConnectionRequest = async () => {
        try {
            const userInfoMutation = await userInfoQuery.refetch();
            if (userInfoMutation.data) {
                const userInfo = userInfoMutation.data;

                const data = {
                    sourceUserId:
                        user?.primaryProfile === PrimaryProfileType.Professional
                            ? user?.professionalProfile?.userId ?? ''
                            : user?.id ?? '',
                    destinationUserId: profile?.userId
                };

                //  logger.log('data', data);

                sendConnectRequest.mutate(data, {
                    onSuccess: () => {
                        // setIsAdded(true);
                        queryClient.invalidateQueries(
                            QueryKeys.RetrieveConnections
                        );
                        queryClient.invalidateQueries(
                            QueryKeys.RetrieveConnectionRequestSent
                        );
                        queryClient.invalidateQueries(
                            QueryKeys.RetrieveConnectionRequestReceived
                        );
                        setType('success');
                        setMessage('Request and Email sent successfully.');
                        setShowAlert(true);
                        //  handleConnectionEmail(userInfo);
                        // const sendmaildata = {
                        //     email: userInfo.professionalProfile.emailAddress,
                        //     subject: generateSubject(connectionRequest.subject),
                        //     html: generateHtml(connectionRequest.html, userInfo.professionalProfile.firstName),
                        // }
                        // logger.log("sendmailData=>", sendmaildata)
                        //  sendEmailNotificationMutation.mutate(sendmaildata,{
                        //     onSuccess:(res) =>{
                        //         console.log('sendmail', res)
                        //         setType('success');
                        //         setMessage('email notification sent successfully.');
                        //         setShowAlert(true);
                        //     },
                        //     onError: (err)=>{
                        //         console.log('err', err)
                        //     }
                        // });
                    },
                    onError: (err: AxiosError) => {
                        if (axios.isAxiosError(err)) {
                            setType('error');
                            setMessage(
                                err.message ??
                                    'Error occurred, please try again!'
                            );
                            setShowAlert(true);
                        }
                    }
                });
            } else {
                console.error(
                    'Error fetching user info:',
                    userInfoMutation.error
                );
            }
        } catch (error) {
            console.error('Unexpected error:', error);
        }
    };

    useEffect(() => {
        if (id) {
            refetch();
            refetchScore();
        }
    }, [id]);
    // console.log(posts);

    return (
        <Container
            disableGutters
            sx={{
                pr: isMobile ? 0 : isTablet ? '20px' : '40px',
                mt: isMobile ? 0 : isTablet ? '-16px' : 0,
                pb: '20px'
            }}
        >
            {isTablet ? (
                showAllPosts ? (
                    <Box
                        p={'20px'}
                        width="100%"
                        bgcolor="#fff"
                        borderBottom="1px solid #D9D9D9"
                        display="flex"
                        alignItems="center"
                        gap="20px"
                    >
                        <Box
                            onClick={() => setShowAllPosts(false)}
                            sx={{ cursor: 'pointer' }}
                        >
                            <ArrowLeftIcon />
                        </Box>
                        <Typography
                            fontFamily="Open Sans"
                            fontSize={isMobile ? '16px' : '24px'}
                            fontWeight={600}
                            lineHeight="32px"
                            color="#23282b"
                        >
                            {`${profile?.firstName}'s Activities`}
                        </Typography>
                    </Box>
                ) : null
            ) : null}
            <Grid
                container
                direction="column"
                mt={isMobile ? 0 : isTablet && showAllPosts ? '16px' : 0}
                gap={{ xs: '8px', lg: '12px' }}
            >
                <Grid item position="relative">
                    <Box
                        bgcolor="#fff"
                        borderRadius={1}
                        overflow="hidden"
                        pb={{ xs: '8px', lg: '20px' }}
                    >
                        <Box position="relative" pb="109px">
                            <Box
                                position="relative"
                                overflow="hidden"
                                display={'flex'}
                                borderRadius={[0, 0, 1, 1]}
                            >
                                <img
                                    style={{
                                        height: isMobile
                                            ? '100px'
                                            : isTablet
                                            ? '220px'
                                            : '259px',
                                        width: '100%',
                                        objectFit: 'cover',
                                        display: 'block'
                                    }}
                                    alt="Cover"
                                    src={cover}
                                />
                                {isTablet ? null : (
                                    <ProfileBanner
                                        firstName={
                                            profile?.firstName ??
                                            ''
                                        }
                                        lastName={profile?.lastName ?? ''}
                                        score={score}
                                    />
                                )}
                            </Box>
                            <Grid
                                container
                                gap={isMobile ? undefined : 3}
                                position={'absolute'}
                                direction={isMobile ? 'column' : undefined}
                                bottom={isMobile ? 8 : isTablet ? 30 : 0}
                            >
                                <Box
                                    ml={isTablet ? '20px' : 5}
                                    borderRadius="50%"
                                    overflow="hidden"
                                    width="fit-content"
                                    boxShadow="-8px 4px 20px rgba(0, 0, 0, 0.07), 8px 8px 20px rgba(0, 0, 0, 0.07)"
                                    position="relative"
                                    mb={isTablet && !profile?.photoUrl ? 2 : 0}
                                >
                                    {!profile?.photoUrl ? (
                                        <ProfilePhotoIcon />
                                    ) : (
                                        <Image
                                            src={profile?.photoUrl ?? ''}
                                            sx={{
                                                width: isMobile
                                                    ? '130px'
                                                    : isTablet
                                                    ? '150px'
                                                    : '200px',
                                                height: isMobile
                                                    ? '130px'
                                                    : isTablet
                                                    ? '150px'
                                                    : '200px',
                                                objectFit: 'cover'
                                            }}
                                            alt={
                                                profile?.firstName +
                                                ' ' +
                                                profile?.lastName
                                            }
                                            border="3px #fff solid"
                                            borderRadius="50%"
                                            display="block"
                                        />
                                    )}
                                </Box>
                                {isMobile ? (
                                    <Box mt="4px" ml="40px">
                                        <Typography
                                            component="span"
                                            color="#05668D"
                                            fontFamily="open sans"
                                            fontSize={16}
                                            fontWeight={700}
                                        >
                                            {profile?.connections ?? 0}
                                        </Typography>
                                        &nbsp;
                                        <Typography
                                            component={'span'}
                                            color="#05668D"
                                            fontFamily="open sans"
                                            fontSize={12}
                                        >
                                            {'Connection' +
                                                (profile?.connections === 1
                                                    ? ''
                                                    : 's')}
                                        </Typography>
                                    </Box>
                                ) : null}
                            </Grid>
                        </Box>
                        <Box
                            display={{
                                xs: 'flex',
                                sm: 'none'
                            }}
                            alignItems="center"
                            gap="10px"
                            pl="16px"
                        >
                            {isConnected && !isRequested ? null : (
                                <Button
                                    variant="contained"
                                    disabled={isRequested}
                                    sx={{
                                        width: isRequested ? '140px' : '120px',
                                        height: '40px',
                                        borderRadius: '8px',
                                        textTransform: 'none',
                                        bgcolor: '#05668D',
                                        color: '#fff',
                                        fontSize: '16px',
                                        fontWeight: 600,
                                        mr: '6px'
                                    }}
                                    onClick={() => {
                                        if (isRequested) {
                                        } else sendConnectionRequest();
                                    }}
                                >
                                    {isRequested ? 'Request sent' : 'Connect'}
                                </Button>
                            )}
                            <Button
                                variant="outlined"
                                sx={{
                                    width: '120px',
                                    height: '40px',
                                    borderRadius: '8px',
                                    textTransform: 'none',
                                    border: '1px solid #05668D',
                                    color: '#05668D',
                                    fontSize: '16px',
                                    fontWeight: 600
                                }}
                                onClick={() =>
                                    navigate('/messages', {
                                        state: {
                                            user: profile
                                        }
                                    })
                                }
                            >
                                Message
                            </Button>
                            <IconButton>
                                <MoreIcon />
                            </IconButton>
                        </Box>
                        {isMobile ? (
                            <Box
                                position="absolute"
                                right="16px"
                                top="120px"
                                // display="flex"
                                // alignItems="flex-end"
                                // justifyContent='center'
                                // flexDirection="column"
                                // mt={-10}
                                // mr="20px"
                                gap="16px"
                            >
                                <ScoreCard
                                    score={score}
                                    width="80px"
                                    height="80px"
                                />
                            </Box>
                        ) : null}

                        {isMobile ? (
                            <Box
                                width="100%"
                                height="4px"
                                bgcolor="#f2f2f2"
                                my="8px"
                            ></Box>
                        ) : null}
                        <Grid
                            // pt={{ xs: 0, lg: '65px' }}
                            container
                            px={isMobile ? '16px' : isTablet ? '20px' : 5}
                            marginTop={{ xs: 0, sm: -1.5, lg: '20px' }}
                            pb={{ xs: '16px', sm: 0 }}
                            wrap="nowrap"
                        >
                            <Grid item xs={12} flexGrow={1} marginTop="auto">
                                <Grid container direction="column" gap={0.5}>
                                    <Grid
                                        item
                                        direction={
                                            isMobile ? 'column' : undefined
                                        }
                                    >
                                        <Box
                                            display={{
                                                xs: 'none',
                                                sm: 'flex',
                                                lg: 'none'
                                            }}
                                            alignItems="center"
                                            gap="10px"
                                            mb="20px"
                                        >
                                            {isConnected ? null : (
                                                <Button
                                                    variant="contained"
                                                    disabled={isRequested}
                                                    sx={{
                                                        width: '160px',
                                                        height: '44px',
                                                        borderRadius: '8px',
                                                        textTransform: 'none',
                                                        bgcolor: '#05668D',
                                                        color: '#fff',
                                                        fontSize: '16px',
                                                        fontWeight: 600,
                                                        mr: '6px'
                                                    }}
                                                    onClick={() => {
                                                        if (isRequested) {
                                                        } else
                                                            sendConnectionRequest();
                                                    }}
                                                >
                                                    {isRequested
                                                        ? 'Request sent'
                                                        : 'Connect'}
                                                </Button>
                                            )}
                                            <Button
                                                variant="outlined"
                                                sx={{
                                                    width: '133px',
                                                    height: '44px',
                                                    borderRadius: '8px',
                                                    textTransform: 'none',
                                                    border: '1px solid #05668D',
                                                    color: '#05668D',
                                                    fontSize: '16px',
                                                    fontWeight: 600
                                                }}
                                                onClick={() =>
                                                    navigate('/messages', {
                                                        state: {
                                                            user: profile
                                                        }
                                                    })
                                                }
                                            >
                                                Message
                                            </Button>
                                            <IconButton>
                                                <MoreIcon />
                                            </IconButton>
                                        </Box>
                                        <Grid
                                            container
                                            gap={'12px'}
                                            display="flex"
                                            alignItems="center"
                                        >
                                            <Grid
                                                item
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '4px'
                                                }}
                                            >
                                                <Typography
                                                    component="h2"
                                                    color="#23282B"
                                                    fontFamily="open sans"
                                                    // lineHeight="1.125"
                                                    fontSize={
                                                        isMobile
                                                            ? 22
                                                            : isTablet
                                                            ? 24
                                                            : 28
                                                    }
                                                    fontWeight={600}
                                                >
                                                    {profile?.firstName}{' '}
                                                    {profile?.lastName}
                                                </Typography>
                                                {/* <Box> */}
                                                <TickCircle />
                                                {/* <VerifiedIcon /> */}
                                                {/* </Box> */}
                                            </Grid>
                                            <CircleIcon
                                                sx={{
                                                    display: {
                                                        xs: 'none',
                                                        sm: 'block'
                                                    },
                                                    fontSize: '6px'
                                                }}
                                                htmlColor="#C5C5C5"
                                            />
                                            <Grid
                                                item
                                                sx={{ display: 'flex' }}
                                                alignItems="center"
                                            >
                                                <Grid
                                                    sx={{
                                                        display: {
                                                            xs: 'none',
                                                            sm: 'flex'
                                                        }
                                                    }}
                                                    container
                                                    wrap="nowrap"
                                                    width="auto"
                                                    // marginTop="auto"
                                                    flexGrow={1}
                                                    alignItems="center"
                                                    gap="12px"
                                                    // marginLeft={
                                                    //     isTablet ? -2 : -1
                                                    // }
                                                >
                                                    <Grid
                                                        item
                                                        flexGrow={1}
                                                        // mt={
                                                        //     isTablet
                                                        //         ? '30px'
                                                        //         : undefined
                                                        // }
                                                    >
                                                        <Typography
                                                            component="span"
                                                            color="#05668D"
                                                            fontFamily="open sans"
                                                            fontSize={20}
                                                            fontWeight={700}
                                                        >
                                                            {profile?.connections ??
                                                                0}
                                                        </Typography>
                                                        &nbsp;
                                                        <Typography
                                                            component={'span'}
                                                            color="#05668D"
                                                            fontFamily="open sans"
                                                            fontSize={20}
                                                        >
                                                            {'Connection' +
                                                                (profile?.connections ===
                                                                1
                                                                    ? ''
                                                                    : 's')}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid
                                                        item
                                                        // paddingRight="32px"
                                                    >
                                                        <Box
                                                            display="flex"
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            sx={{
                                                                bgcolor:
                                                                    '#f2f2f2',
                                                                borderRadius:
                                                                    '8px',
                                                                p: '4px'
                                                            }}
                                                        >
                                                            <MoreIcon />
                                                            {/* <MoreVertIcon fontSize="medium" htmlColor='#808080' sx={{ bgcolor:"#f2f2f2",
                                                            borderRadius:"8px", p:'4px'}}  /> */}
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '12px'
                                        }}
                                    >
                                        <Typography
                                            component={'p'}
                                            color="#23282B"
                                            fontFamily="open sans"
                                            fontSize={isMobile ? 14 : 16}
                                            fontWeight={400}
                                        >
                                            {profile?.currentEmployment
                                                ?.jobTitle ?? ''}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Grid
                                            container
                                            gap={'4px'}
                                            alignItems="center"
                                        >
                                            {profile?.address?.city ||
                                            profile?.address?.country ? (
                                                <>
                                                    <Grid item>
                                                        <InfoChip
                                                            type="location"
                                                            label={`${
                                                                profile?.address
                                                                    ?.city ?? ''
                                                            }, ${
                                                                profile?.address
                                                                    ?.country ??
                                                                ''
                                                            }`}
                                                        />
                                                    </Grid>
                                                    {/* {isMobile ? null : (
                                                        <CircleIcon
                                                            sx={{
                                                                fontSize:
                                                                    '7.25px'
                                                            }}
                                                            htmlColor="#494949"
                                                        />
                                                    )} */}
                                                </>
                                            ) : null}
                                            {profile?.phoneNumber ? (
                                                <>
                                                    <Grid item>
                                                        <InfoChip
                                                            type="phone"
                                                            label={
                                                                profile?.phoneNumber ??
                                                                ''
                                                            }
                                                        />
                                                    </Grid>
                                                    {/* {isMobile ? null : (
                                                        <CircleIcon
                                                            sx={{
                                                                fontSize:
                                                                    '7.25px'
                                                            }}
                                                            htmlColor="#494949"
                                                        />
                                                    )} */}
                                                </>
                                            ) : null}
                                            {profile?.emailAddress ? (
                                                <>
                                                    <Grid item>
                                                        <InfoChip
                                                            type="email"
                                                            label={
                                                                profile?.emailAddress ??
                                                                ''
                                                            }
                                                        />
                                                    </Grid>
                                                </>
                                            ) : null}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {isMobile ? null : (
                                <Grid
                                    item
                                    flexShrink={1}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-end'
                                    }}
                                >
                                    <Box
                                        py={1}
                                        position={{
                                            sm: 'absolute'
                                            // lg: 'static'
                                        }}
                                        right={{ sm: '21px', lg: '62px' }}
                                        bottom={{ sm: '210px', lg: '86px' }}
                                    >
                                        <ScoreCard score={score} />
                                    </Box>
                                    <Box
                                        position="absolute"
                                        bottom="20px"
                                        display={{ xs: 'none', lg: 'flex' }}
                                        alignItems="center"
                                        gap="10px"
                                    >
                                        {isConnected ? null : (
                                            <Button
                                                variant="contained"
                                                disabled={isRequested}
                                                sx={{
                                                    width: '160px',
                                                    height: '48px',
                                                    borderRadius: '8px',
                                                    textTransform: 'none',
                                                    bgcolor: '#05668D',
                                                    color: '#fff',
                                                    fontSize: '16px',
                                                    fontWeight: 600,
                                                    mr: '6px'
                                                }}
                                                onClick={() => {
                                                    if (isRequested) {
                                                    } else
                                                        sendConnectionRequest();
                                                }}
                                            >
                                                {isRequested
                                                    ? 'Request sent'
                                                    : 'Connect'}
                                            </Button>
                                        )}
                                        <Button
                                            variant="outlined"
                                            sx={{
                                                width: '133px',
                                                height: '48px',
                                                borderRadius: '8px',
                                                textTransform: 'none',
                                                border: '1px solid #05668D',
                                                color: '#05668D',
                                                fontSize: '16px',
                                                fontWeight: 600
                                            }}
                                            onClick={() =>
                                                navigate('/messages', {
                                                    state: {
                                                        user: profile
                                                    }
                                                })
                                            }
                                        >
                                            Message
                                        </Button>
                                        <IconButton>
                                            <MoreIcon />
                                        </IconButton>
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </Grid>

                {!showAllPosts ? (
                    <Grid item>
                        <Grid container wrap="nowrap" spacing={2}>
                            <Grid item width="100%" xs={isTablet ? 12 : 8}>
                                <Section px={0}>
                                    <Box
                                        px={{
                                            xs: '16px',
                                            sm: '20px',
                                            lg: '40px'
                                        }}
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'flex-start'
                                        }}
                                    >
                                        <Box>
                                            <Typography
                                                component="h3"
                                                color="#23282B"
                                                fontFamily="open sans"
                                                fontSize={20}
                                                fontWeight={600}
                                                mb={1}
                                            >
                                                Bio
                                            </Typography>
                                            <Typography
                                                component="h4"
                                                color="#808080"
                                                fontFamily="open sans"
                                                fontSize={16}
                                                fontWeight={400}
                                                mb={1.5}
                                            >
                                                Tell the world about yourself.
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Box
                                        pt={{ xs: '12px', sm: '20px' }}
                                        px={{
                                            xs: '16px',
                                            sm: '20px',
                                            lg: '40px'
                                        }}
                                        pb="8px"
                                    >
                                        <Typography
                                            ref={containerRef}
                                            component="p"
                                            color="#23282B"
                                            fontFamily="open sans"
                                            fontSize={isTablet ? 14 : 16}
                                            fontWeight={400}
                                            // mt={4}
                                            mb={'12px'}
                                            style={{
                                                display: '-webkit-box',
                                                WebkitLineClamp: showMore
                                                    ? undefined
                                                    : 4,
                                                WebkitBoxOrient: 'vertical',
                                                overflow: 'hidden'
                                            }}
                                        >
                                            {profile?.bio}
                                        </Typography>

                                        {isTruncated ? (
                                            <Box sx={{ width: '100%' }}>
                                                <Button
                                                    disableRipple
                                                    variant="contained"
                                                    sx={{
                                                        width: '118px',
                                                        backgroundColor:
                                                            '#f2f2f2',
                                                        borderRadius: '8px',
                                                        padding: '0 8px',
                                                        '&:hover': {
                                                            bgcolor: '#f2f2f2'
                                                        }
                                                    }}
                                                    endIcon={<SeeMoreIcon />}
                                                    onClick={() => {
                                                        setShowMore(!showMore);
                                                    }}
                                                >
                                                    <Typography
                                                        color={'#05668D'}
                                                        fontFamily="open sans"
                                                        fontWeight={700}
                                                        fontSize={14}
                                                        sx={{
                                                            textTransform:
                                                                'none',
                                                            py: 1
                                                        }}
                                                    >
                                                        {showMore
                                                            ? 'See less'
                                                            : 'See more'}
                                                    </Typography>
                                                </Button>
                                            </Box>
                                        ) : null}
                                    </Box>
                                </Section>
                                <Box
                                    bgcolor="#fff"
                                    py="16px"
                                    px={{ xs: '16px', sm: '20px', lg: '40px' }}
                                    // heading={
                                    //     <SectionHeading px='40px'>
                                    //         Improve your Job matches
                                    //     </SectionHeading>
                                    // }
                                    // py={2.5}
                                    borderBottom="1px solid #D8D8D8"
                                    mb={{ xs: 0.5, lg: 1 }}
                                >
                                    <Typography
                                        component="h4"
                                        color="#23282B"
                                        fontFamily="open sans"
                                        fontSize={{ xs: 16, sm: 20 }}
                                        fontWeight={600}
                                    >
                                        Qualifications
                                    </Typography>
                                </Box>
                                {/* <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    px={isMobile ? 2 : isTablet ? 2.5 : 5}
                                    pb="16px"
                                    borderBottom="1px solid"
                                    sx={{
                                        borderBottomColor: isMobile
                                            ? '#f2f2f2'
                                            : 'transparent'
                                    }}
                                >
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize={isMobile ? '16px' : '20px'}
                                        fontWeight={600}
                                        lineHeight="28px"
                                    >
                                        Qualifications
                                    </Typography>
                                </Box> */}
                                {profile?.qualifications?.length !== 0 && (
                                    <Box>
                                        <Box
                                            mb={{ xs: '2px', sm: '4px' }}
                                            bgcolor="#fff"
                                            py="12px"
                                            px={{
                                                xs: '16px',
                                                sm: '20px',
                                                lg: '40px'
                                            }}
                                        >
                                            <Typography
                                                fontFamily="Open Sans"
                                                fontSize={16}
                                                fontWeight={600}
                                                lineHeight="28px"
                                            >
                                                Education
                                            </Typography>
                                        </Box>

                                        <Box
                                            mb={{ xs: '4px', sm: '8px' }}
                                            bgcolor="#fff"
                                        >
                                            {profile?.qualifications?.map(
                                                (x: any) => (
                                                    <EducationSummary
                                                        containerStyle={{
                                                            borderBottom:
                                                                '1px solid #D8D8D8',
                                                            px: isMobile
                                                                ? '16px'
                                                                : isTablet
                                                                ? '20px'
                                                                : '40px',
                                                            py: {
                                                                xs: '12px',
                                                                sm: '20px'
                                                            }
                                                        }}
                                                        key={x.id}
                                                        data={{
                                                            university: {
                                                                name: x.school,
                                                                image: cambridge
                                                            },
                                                            major: x.degree,
                                                            yearStarted:
                                                                x.startDate,
                                                            yearEnded: x.endDate
                                                        }}
                                                    />
                                                )
                                            )}
                                        </Box>
                                    </Box>
                                )}
                                {!profile?.employments?.length ? null : (
                                    <Box width="100%">
                                        <Box
                                            mb={{ xs: '2px', sm: '4px' }}
                                            bgcolor="#fff"
                                            py="12px"
                                            px={{
                                                xs: '16px',
                                                sm: '20px',
                                                lg: '40px'
                                            }}
                                        >
                                            <Typography
                                                fontFamily="Open Sans"
                                                fontSize={16}
                                                fontWeight={600}
                                                lineHeight="28px"
                                            >
                                                Work Experience
                                            </Typography>
                                        </Box>

                                        <Box
                                            mb={{ xs: '4px', sm: '8px' }}
                                            bgcolor="#fff"
                                        >
                                            {profile?.employments?.map(
                                                (x: any) => (
                                                    <WorkSummary
                                                        containerStyle={{
                                                            borderBottom:
                                                                '1px solid #D8D8D8',
                                                            px: isMobile
                                                                ? '16px'
                                                                : isTablet
                                                                ? '20px'
                                                                : '40px',
                                                            py: {
                                                                xs: '12px',
                                                                sm: '20px'
                                                            }
                                                        }}
                                                        key={x.id}
                                                        data={{
                                                            employer: {
                                                                name: x.companyName,
                                                                image: GenericCompanyLogo
                                                            },
                                                            title: x.jobTitle,
                                                            yearStarted:
                                                                x.startDate,
                                                            yearEnded:
                                                                x.endDate,
                                                            isCurrentPosition:
                                                                x.isCurrentPosition,
                                                            rankings: [
                                                                Ranking.Leader,
                                                                Ranking.Expert,
                                                                Ranking.Advanced,
                                                                Ranking.Experienced
                                                            ]
                                                        }}
                                                    />
                                                )
                                            )}
                                        </Box>
                                    </Box>
                                )}
                                {profile?.employments?.length ||
                                profile?.qualifications?.length ? (
                                    <Box
                                        px={{
                                            xs: '16px',
                                            sm: '20px',
                                            lg: '40px'
                                        }}
                                        py="8px"
                                        bgcolor="#fff"
                                        mb={{ xs: '8px', lg: '12px' }}
                                    >
                                        <Button
                                            variant="contained"
                                            sx={{
                                                backgroundColor: '#f2f2f2',
                                                borderRadius: '8px',
                                                width: '100%',
                                                "&:hover": {
                                                    bgcolor: '#f2f2f2'
                                                }
                                            }}
                                            onClick={() =>
                                                navigate(
                                                    `/user/qualifications/${id}`,
                                                    {
                                                        state: {
                                                            others: true
                                                        }
                                                    }
                                                )
                                            }
                                        >
                                            <Typography
                                                color={'#05668D'}
                                                fontFamily="open sans"
                                                fontWeight={600}
                                                fontSize={16}
                                                sx={{
                                                    textTransform: 'none',
                                                    py: 0.5,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '12px'
                                                }}
                                            >
                                                Show all qualifications{' '}
                                                <ArrowIconRight />
                                            </Typography>
                                        </Button>
                                    </Box>
                                ) : null}
                                <Box
                                    mt={{ xs: 0, sm: '12px' }}
                                    px={{ xs: '16px', sm: '20px', lg: '40px' }}
                                    py="8px"
                                    bgcolor="#fff"
                                >
                                    <Box
                                        mb={'8px'}
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Typography
                                            component="h2"
                                            color="#23282B"
                                            fontFamily="open sans"
                                            lineHeight="1.125"
                                            fontSize={
                                                isMobile
                                                    ? 20
                                                    : isTablet
                                                    ? 24
                                                    : 28
                                            }
                                            fontWeight={600}
                                        >
                                            Activities
                                        </Typography>

                                        <Typography
                                            component="h2"
                                            color="#808080"
                                            fontFamily="open sans"
                                            lineHeight="24px"
                                            fontSize={16}
                                            fontWeight={400}
                                        >
                                            {totalPosts + ' posts'}
                                        </Typography>
                                    </Box>
                                    <Box
                                        display="flex"
                                        flexWrap="wrap"
                                        height="fit-content"
                                        gap={{ xs: '4px', sm: 0 }}
                                        bgcolor={{
                                            xs: 'transparent',
                                            sm: '#f2f2f2'
                                        }}
                                    >
                                        {currentActivity.map((item) => (
                                            <Box
                                                height="36px"
                                                bgcolor="#f2f2f2"
                                                width={{
                                                    xs: '49.3%',
                                                    sm: `${100 / 4}%`
                                                }}
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',

                                                    borderBottomColor:
                                                        activity === item
                                                            ? '#05668D'
                                                            : 'transparent',
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() =>
                                                    setActivity(item)
                                                }
                                                borderBottom="2px solid"
                                            >
                                                <Typography
                                                    fontFamily="open sans"
                                                    fontSize={14}
                                                    fontWeight={600}
                                                    lineHeight="20px"
                                                    color={
                                                        activity === item
                                                            ? '#05668D'
                                                            : '#808080'
                                                    }
                                                >
                                                    {item}
                                                </Typography>
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>
                                <Box py={'4px'} bgcolor="#f2f2f2">
                                    {activity === 'Posts' ? (
                                        !isLoaded ? (
                                            [1, 2].map((_) => (
                                                <SkeletonLoader />
                                            ))
                                        ) : posts?.length === 0 ? (
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                gap="12px"
                                                pl={
                                                    isMobile
                                                        ? '16px'
                                                        : undefined
                                                }
                                            >
                                                <Alert />
                                                <Typography>
                                                    {' '}
                                                    No Posts
                                                </Typography>
                                            </Box>
                                        ) : (
                                            (posts ?? [])
                                                ?.slice(0, 3)
                                                ?.map(
                                                    (feed: any, index: any) => (
                                                        <FeedItem
                                                            profile
                                                            key={index}
                                                            feed={feed}
                                                        />
                                                    )
                                                )
                                        )
                                    ) : activity === 'Achievements' ? (
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            gap="12px"
                                            pl={isMobile ? '16px' : undefined}
                                        >
                                            <Alert />
                                            <Typography>
                                                {' '}
                                                No Achievements
                                            </Typography>
                                        </Box>
                                    ) : activity === 'Articles' ? (
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            gap="12px"
                                            p={isMobile ? '16px' : undefined}
                                        >
                                            <Alert />
                                            <Typography>No Articles</Typography>
                                        </Box>
                                    ) : (
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            gap="12px"
                                            p={isMobile ? '16px' : undefined}
                                        >
                                            <Alert />
                                            <Typography>No Jobs</Typography>
                                        </Box>
                                    )}
                                    <Box
                                        py="8px"
                                        px={{
                                            xs: '16px',
                                            sm: '20px',
                                            lg: '40px'
                                        }}
                                        bgcolor="#fff"
                                        // mb="8px"
                                    >
                                        {posts?.length > 0 && (
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    backgroundColor: '#f2f2f2',
                                                    borderRadius: '8px',
                                                    width: '100%',
                                                    '&:hover': {
                                                        bgcolor: '#f2f2f2'
                                                    }
                                                    // mt: '16px',
                                                }}
                                                onClick={() => {
                                                    setShowAllPosts(true);
                                                    window.scroll(0, 0);
                                                }}
                                            >
                                                <Typography
                                                    color={'#05668D'}
                                                    fontFamily="open sans"
                                                    fontWeight={600}
                                                    fontSize={16}
                                                    sx={{
                                                        textTransform: 'none',
                                                        py: 0.5,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '12px'
                                                    }}
                                                >
                                                    Show all posts{' '}
                                                    <ArrowIconRight />
                                                </Typography>
                                            </Button>
                                        )}
                                    </Box>
                                </Box>
                                <Box
                                    bgcolor="#fff"
                                    py="8px"
                                    px={{ xs: '16px', sm: '20px', lg: '40px' }}
                                    mb="4px"
                                >
                                    <Typography
                                        fontSize="16px"
                                        fontWeight={600}
                                        color="#23282b"
                                    >
                                        Help other Job Seekers
                                    </Typography>
                                </Box>
                                <Box
                                    bgcolor="#fff"
                                    py="8px"
                                    px={{ xs: '16px', sm: '20px', lg: '40px' }}
                                    // mb="4px"
                                    // heading={
                                    //     <SectionHeading>
                                    //         Help other Job Seekers
                                    //     </SectionHeading>
                                    // }
                                >
                                    {isMobile ? (
                                        <Grid
                                            container
                                            gap={1}
                                            alignItems="center"
                                            // wrap="nowrap"
                                            direction="column"
                                        >
                                            <Box
                                                py={1}
                                                pr={1}
                                                width="100%"
                                                display="flex"
                                                alignItems="center"
                                                gap="8px"
                                            >
                                                <MedalIcon
                                                    width="32px"
                                                    height="32px"
                                                />
                                                <Typography
                                                    component="h4"
                                                    color="#23282B"
                                                    fontFamily="open sans"
                                                    fontSize={16}
                                                    fontWeight={600}
                                                    mb={1}
                                                    flex={1}
                                                >
                                                    Review companies
                                                </Typography>
                                                <ArrowRightIcon color="#055C7F" />
                                            </Box>
                                            <Grid container flexGrow={1}>
                                                <Typography
                                                    component="p"
                                                    color="#808080"
                                                    fontFamily="open sans"
                                                    fontSize={14}
                                                    fontWeight={400}
                                                >
                                                    Review companies you have
                                                    worked for, this contributes
                                                    to your Jobfactor score
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <Grid
                                            container
                                            gap={2}
                                            alignItems="center"
                                            wrap="nowrap"
                                        >
                                            <Grid item>
                                                <MedalIcon
                                                    width="32px"
                                                    height="32px"
                                                />
                                            </Grid>
                                            <Grid item flexGrow={1}>
                                                <Typography
                                                    color="#23282B"
                                                    fontFamily="open sans"
                                                    fontSize={16}
                                                    fontWeight={600}
                                                >
                                                    Review companies
                                                </Typography>
                                                <Typography
                                                    component="p"
                                                    color="#808080"
                                                    fontFamily="open sans"
                                                    fontSize={16}
                                                    fontWeight={400}
                                                >
                                                    Review companies you have
                                                    worked for, this contributes
                                                    to your Jobfactor score
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <ArrowRightIcon color="#055C7F" />
                                            </Grid>
                                        </Grid>
                                    )}
                                </Box>
                                {/* <Section px={'40px'}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <Box>
                                        <Typography
                                            component="h3"
                                            color="#23282B"
                                            fontFamily="open sans"
                                            fontSize={20}
                                            fontWeight={600}
                                            mb={1}
                                        >
                                            Activities
                                        </Typography>
                                        <Typography
                                            component="h4"
                                            color="#808080"
                                            fontFamily="open sans"
                                            fontSize={16}
                                            fontWeight={400}
                                            mb={1.5}
                                        >
                                            {profile?.connections} connections
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ width: '100%' }}>
                                    <Box>
                                        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            sx={{
                                                '& .MuiTabs-flexContainer': {
                                                    gap: '16px'
                                                },
                                                '& .Mui-selected': {
                                                    backgroundColor: '#21682B',
                                                    border: 0,
                                                    color: '#FFF !important'
                                                },
                                                '& .MuiTabs-indicator': {
                                                    display: 'none'
                                                }
                                            }}
                                        >
                                            <Tab
                                                label="Posts"
                                                sx={{
                                                    borderRadius: '100px',
                                                    border: '1px solid #EDEDED',
                                                    fontSize: '16px',
                                                    fontWeight: 600,
                                                    color: '#5B5B5B',
                                                    textTransform: 'capitalize'
                                                }}
                                                {...allyProps(0)}
                                            />
                                            <Tab
                                                label="Comments"
                                                sx={{
                                                    borderRadius: '100px',
                                                    border: '1px solid #EDEDED',
                                                    fontSize: '16px',
                                                    fontWeight: 600,
                                                    color: '#5B5B5B',
                                                    textTransform: 'capitalize'
                                                }}
                                                {...allyProps(1)}
                                            />
                                        </Tabs>
                                    </Box>
                                    <TabWrapper value={value} index={0}>
                                        <ActivityList
                                            setShowAllPosts={() => {
                                                setShowAllPosts(true);
                                            }}
                                            showAllPosts={showAllPosts}
                                            value={value}
                                        />
                                    </TabWrapper>
                                    <TabWrapper value={value} index={1}>
                                        <ActivityList
                                            setShowAllPosts={() => {
                                                setShowAllPosts(true);
                                            }}
                                            showAllPosts={showAllPosts}
                                            value={value}
                                        />
                                    </TabWrapper>
                                </Box>
                            </Section> */}
                            </Grid>
                            {isTablet ? null : (
                                <Grid item width="100%" xs={isTablet ? 12 : 4}>
                                    <Box
                                        bgcolor="#fff"
                                        p="16px 16px 8px 16px"
                                        mb="12px"
                                    >
                                        <Typography variant="titleLargeSemiBold">
                                            Skills
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                alignItems: 'flex-start',
                                                gap: '10px',
                                                mb: '12px',
                                                mt: '16px'
                                            }}
                                        >
                                            {profile?.skills
                                                ?.sort(
                                                    (
                                                        a: ISkillType,
                                                        b: ISkillType
                                                    ) =>
                                                        a?.rating < b?.rating
                                                            ? 1
                                                            : -1
                                                )
                                                ?.map(
                                                    (
                                                        x: ISkillType,
                                                        i: number
                                                    ) => {
                                                        let level =
                                                            experienceLevels?.find(
                                                                (y) =>
                                                                    y.key ===
                                                                    x.rating
                                                            );
                                                        return (
                                                            <>
                                                                {maxCount > i ||
                                                                showMoreSkills ? (
                                                                    <ExperienceLevel
                                                                        background={
                                                                            level?.color ??
                                                                            '#E75541'
                                                                        }
                                                                        shortForm={
                                                                            level?.shortForm ??
                                                                            'B'
                                                                        }
                                                                        title={
                                                                            x?.name
                                                                        }
                                                                    />
                                                                ) : null}
                                                                {
                                                                    <>
                                                                        {i +
                                                                            1 ===
                                                                            profile
                                                                                ?.skills
                                                                                .length &&
                                                                        profile
                                                                            ?.skills
                                                                            .length >
                                                                            maxCount ? (
                                                                            <Button
                                                                                disableRipple
                                                                                sx={{
                                                                                    width: 'auto',
                                                                                    backgroundColor:
                                                                                        '#FCFBF8',
                                                                                    borderRadius:
                                                                                        '8px',
                                                                                    padding:
                                                                                        '0 16px',
                                                                                    color: '#05668D',
                                                                                    fontWeight: 700,
                                                                                    fontSize: 14
                                                                                }}
                                                                                onClick={() => {
                                                                                    setShowMoreSkills(
                                                                                        !showMoreSkills
                                                                                    );
                                                                                }}
                                                                            >
                                                                                {showMoreSkills
                                                                                    ? 'See less'
                                                                                    : 'See more'}
                                                                            </Button>
                                                                        ) : null}
                                                                    </>
                                                                }
                                                            </>
                                                        );
                                                    }
                                                )}
                                        </Box>
                                        <Box>
                                            <Typography
                                                fontSize="16px"
                                                color="#23282B"
                                            >
                                                Email
                                            </Typography>
                                            <Box
                                                sx={{
                                                    gap: '8px',
                                                    display: 'flex',

                                                    cursor: 'pointer',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <EmailSolid />
                                                <Typography
                                                    fontSize="16px"
                                                    fontWeight={600}
                                                    sx={{
                                                        color: '#05668D',
                                                        textDecoration: 'none'
                                                    }}
                                                    component="a"
                                                    href={`mailto:${profile?.emailAddress}`}
                                                >
                                                    {profile?.emailAddress}
                                                </Typography>
                                                <ArrowUpRight />
                                            </Box>
                                        </Box>
                                        <Button
                                            variant="outlined"
                                            sx={{
                                                mt: '20px',
                                                py: '10px',
                                                fontSize: '14px'
                                            }}
                                            endIcon={
                                                <ArrowRightIcon color="#055C7F" />
                                            }
                                        >
                                            Contact Information
                                        </Button>
                                    </Box>
                                    <Box
                                        width="100%"
                                        // heading={
                                        //     <SectionHeading
                                        //         disable={true}
                                        //         ml={0}
                                        //     >
                                        //         Top Professionals in your industry
                                        //     </SectionHeading>
                                        // }
                                        onClick={() => {}}
                                        mb="12px"
                                        // py={'16px'}
                                    >
                                        <Box
                                            bgcolor="#fff"
                                            p="8px 16px"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="space-between"
                                        >
                                            <Typography
                                                fontFamily="Open Sans"
                                                fontSize="16px"
                                                fontWeight={600}
                                                lineHeight="24px"
                                                letterSpacing="0.024px"
                                            >
                                                Top Professionals in your
                                                industry
                                            </Typography>
                                            <IconButton
                                                onClick={() => {}}
                                                sx={{
                                                    background: '#DFEBF0',
                                                    borderRadius: '8px',
                                                    padding: '8px'
                                                }}
                                            >
                                                <ArrowRight />
                                            </IconButton>
                                        </Box>
                                        <Box mt="4px" bgcolor="#fff">
                                            {professionals.map((person) => (
                                                <ProfileList data={person} />
                                            ))}
                                        </Box>
                                    </Box>
                                    <Box
                                        // heading={
                                        // <SectionHeading ml={0} fontSize={106}>
                                        //     Analytics
                                        // </SectionHeading>
                                        // }
                                        onClick={() => {}}
                                        mb="4px"
                                        bgcolor="transparent"
                                        // pl={{xs: '16px', lg: 0}}
                                    >
                                        <Box
                                            bgcolor="#fff"
                                            p="8px 16px"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="space-between"
                                            mb="4px"
                                        >
                                            <Typography
                                                fontFamily="Open Sans"
                                                fontSize="20px"
                                                fontWeight={600}
                                                lineHeight="28px"
                                                sx={{ cursor: 'pointer' }}
                                            >
                                                Analytics
                                            </Typography>
                                            {/* <IconButton
                                        onClick={() => {}}
                                        sx={{
                                            // background: '#DFEBF0',
                                            borderRadius: '8px',
                                            padding: '8px'
                                        }}
                                    >
                                        <ArrowRight />
                                    </IconButton> */}
                                        </Box>
                                        <Grid
                                            container
                                            gap={1}
                                            bgcolor="#fff"
                                            overflow="hidden"
                                            borderRadius={1.5}
                                            wrap="nowrap"
                                            height="140px"
                                            p={{
                                                xs: '8px 16px',
                                                sm: '8px 20px',
                                                lg: 1
                                            }}
                                        >
                                            <Grid
                                                item
                                                bgcolor="#f2f2f2"
                                                flexGrow={1}
                                                maxWidth="33%"
                                            >
                                                <Grid
                                                    container
                                                    flexDirection="column"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    height="100%"
                                                >
                                                    <AnalyticsProfile />
                                                    <Typography
                                                        component="p"
                                                        color="#23282B"
                                                        fontFamily="open sans"
                                                        fontSize={{
                                                            lg: 12,
                                                            xl: 14
                                                        }}
                                                        fontWeight={600}
                                                        textAlign="center"
                                                    >
                                                        PROFILE
                                                        <br />
                                                        VIEWS
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                item
                                                bgcolor="#f2f2f2"
                                                flexGrow={1}
                                                maxWidth="33%"
                                            >
                                                <Grid
                                                    container
                                                    flexDirection="column"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    height="100%"
                                                >
                                                    <AnalyticsSearch />
                                                    <Typography
                                                        component="p"
                                                        color="#23282B"
                                                        fontFamily="open sans"
                                                        fontSize={{
                                                            lg: 12,
                                                            xl: 14
                                                        }}
                                                        fontWeight={600}
                                                        textAlign="center"
                                                    >
                                                        SEARCH
                                                        <br />
                                                        APPEARANCES
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                item
                                                bgcolor="#f2f2f2"
                                                flexGrow={1}
                                                maxWidth="33%"
                                            >
                                                <Grid
                                                    container
                                                    flexDirection="column"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    height="100%"
                                                >
                                                    <AnalyticsGraph />
                                                    <Typography
                                                        component="p"
                                                        color="#23282B"
                                                        fontFamily="open sans"
                                                        fontSize={{
                                                            lg: 12,
                                                            xl: 14
                                                        }}
                                                        fontWeight={600}
                                                        textAlign="center"
                                                    >
                                                        POSTS
                                                        <br />
                                                        ENGAGEMENTS
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    <Box
                                        py={1}
                                        bgcolor="#fff"
                                        px={isMobile ? '16px' : 1}
                                    >
                                        <Button
                                            variant="contained"
                                            sx={{
                                                backgroundColor: '#f2f2f2',
                                                borderRadius: '8px',
                                                width: '100%'
                                                // ml: isMobile ? '16px' : undefined
                                            }}
                                        >
                                            <Typography
                                                color={'primary'}
                                                fontFamily="open sans"
                                                fontWeight={600}
                                                fontSize={14}
                                                sx={{
                                                    textTransform: 'none',
                                                    py: 0.5
                                                }}
                                            >
                                                Upgrade to Premium plan
                                            </Typography>
                                        </Button>
                                    </Box>
                                    {/* <Typography variant="titleMediumRegular">
                                Website
                            </Typography>
                            <Box
                                sx={{
                                    mb: '16px',
                                    gap: '8px',
                                    display: 'flex',
                                    cursor: 'pointer',
                                    alignItems: 'center'
                                }}
                            >
                                <GlobalSearch />
                                <Typography
                                    variant="titleMediumSemiBold"
                                    sx={{
                                        color: '#05668D'
                                    }}
                                >
                                    Samsonjob.com
                                </Typography>
                                <ArrowUpRight />
                            </Box> */}
                                    {/* Privacy filter for contact information */}
                                    {!privacy ? (
                                        <>
                                            <Typography variant="titleMediumRegular">
                                                Email
                                            </Typography>
                                            <Box
                                                sx={{
                                                    gap: '8px',
                                                    display: 'flex',

                                                    cursor: 'pointer',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <EmailSolid />
                                                <Typography
                                                    variant="titleMediumSemiBold"
                                                    sx={{
                                                        color: '#05668D',
                                                        textDecoration: 'none'
                                                    }}
                                                    component="a"
                                                    href={`mailto:${profile?.emailAddress}`}
                                                >
                                                    {profile?.emailAddress}
                                                </Typography>
                                                <ArrowUpRight />
                                            </Box>
                                        </>
                                    ) : null}
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                ) : (
                    <Grid item>
                        <Grid
                            pl={isMobile ? 0 : isTablet ? '12px' : undefined}
                            container
                            wrap="nowrap"
                            spacing={2}
                        >
                            <Grid width="100%" item xs={isTablet ? 12 : 8}>
                                <Box
                                    px={
                                        isMobile
                                            ? '16px'
                                            : isTablet
                                            ? '20px'
                                            : '40px'
                                    }
                                    bgcolor="#fff"
                                    py="16px"
                                >
                                    <Box
                                        mb={'16px'}
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Typography
                                            component="h2"
                                            color="#23282B"
                                            fontFamily="open sans"
                                            lineHeight="1.125"
                                            fontSize={
                                                isMobile
                                                    ? 20
                                                    : isTablet
                                                    ? 24
                                                    : 28
                                            }
                                            fontWeight={600}
                                        >
                                            Activities
                                        </Typography>

                                        <Typography
                                            component="h2"
                                            color="#808080"
                                            fontFamily="open sans"
                                            lineHeight="24px"
                                            fontSize={16}
                                            fontWeight={400}
                                        >
                                            {totalPosts + ' posts'}
                                        </Typography>
                                    </Box>
                                    <Box
                                        display="flex"
                                        height="44px"
                                        bgcolor="#f2f2f2"
                                    >
                                        {currentActivity.map((item) => (
                                            <Box
                                                width={`${100 / 3}%`}
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',

                                                    borderBottomColor:
                                                        activity === item
                                                            ? '#05668D'
                                                            : 'transparent'
                                                }}
                                                onClick={() =>
                                                    setActivity(item)
                                                }
                                                borderBottom="2px solid"
                                            >
                                                <Typography
                                                    fontFamily="open sans"
                                                    fontSize={14}
                                                    fontWeight={600}
                                                    lineHeight="20px"
                                                    color={
                                                        activity === item
                                                            ? '#05668D'
                                                            : '#808080'
                                                    }
                                                >
                                                    {item}
                                                </Typography>
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>
                                <Box
                                    mt={isMobile ? '8px' : '12px'}
                                    px={isTablet ? 0 : '32px'}
                                    bgcolor="#fff"
                                >
                                    {activity === 'Posts' ? (
                                        !isLoaded || posts?.length === 0 ? (
                                            [1, 2, 3].map((_) => (
                                                <SkeletonLoader />
                                            ))
                                        ) : (
                                            <InfiniteScroll
                                                dataLength={posts.length}
                                                next={() =>
                                                    setGetAfter(
                                                        (prev) => prev + 5
                                                    )
                                                }
                                                hasMore={
                                                    posts.length <
                                                    (totalPosts ?? 0)
                                                }
                                                scrollThreshold={0.9}
                                                loader={
                                                    <>
                                                        {[1, 2, 3].map((_) => (
                                                            <SkeletonLoader />
                                                        ))}
                                                    </>
                                                }
                                            >
                                                {posts?.map((feed, index) => (
                                                    <FeedItem
                                                        key={index}
                                                        feed={feed}
                                                    />
                                                ))}
                                            </InfiniteScroll>
                                        )
                                    ) : activity === 'Achievements' ? (
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            gap="12px"
                                            pl={isMobile ? '16px' : undefined}
                                        >
                                            <Alert />
                                            <Typography>
                                                {' '}
                                                No Achievements
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            gap="12px"
                                            pl={isMobile ? '16px' : undefined}
                                        >
                                            <Alert />
                                            <Typography>No Articles</Typography>
                                        </Box>
                                    )}
                                </Box>
                            </Grid>
                            {isTablet ? null : (
                                <Grid item width="100%" xs={isTablet ? 12 : 4}>
                                    {Images.length !== 0 && (
                                        <Box
                                            width="100%"
                                            // heading={
                                            //     <SectionHeading
                                            //         disable={true}
                                            //         ml={0}
                                            //     >
                                            //         Top Professionals in your industry
                                            //     </SectionHeading>
                                            // }
                                            onClick={() => {}}
                                            p="16px 16px 8px 16px"
                                            mb="12px"
                                            bgcolor="#fff"
                                            // py={'16px'}
                                        >
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="space-between"
                                            >
                                                <Typography
                                                    fontFamily="Open Sans"
                                                    fontSize="16px"
                                                    fontWeight={600}
                                                    lineHeight="24px"
                                                    letterSpacing="0.024px"
                                                >
                                                    Gallery
                                                </Typography>
                                                <IconButton
                                                    onClick={() => {}}
                                                    sx={{
                                                        background: '#DFEBF0',
                                                        borderRadius: '8px',
                                                        padding: '8px'
                                                    }}
                                                >
                                                    <ArrowRight />
                                                </IconButton>
                                            </Box>
                                            <ImageList
                                                sx={{
                                                    width: '100%',
                                                    height: '100%',
                                                    borderRadius: '12px'
                                                }}
                                                cols={3}
                                            >
                                                {/* <ImageListItem>
                                            <img
                                                src={company?.logo}
                                                alt={'Gallery'}
                                                style={{ width: '100%' }}
                                            />
                                        </ImageListItem> */}
                                                {Images?.map((image, index) => (
                                                    <ImageListItem key={index}>
                                                        <img
                                                            src={image}
                                                            alt={'Gallery'}
                                                            style={{
                                                                width: '100%'
                                                            }}
                                                        />
                                                    </ImageListItem>
                                                ))}
                                            </ImageList>
                                        </Box>
                                    )}
                                    <Box
                                        width="100%"
                                        // heading={
                                        //     <SectionHeading
                                        //         disable={true}
                                        //         ml={0}
                                        //     >
                                        //         Top Professionals in your industry
                                        //     </SectionHeading>
                                        // }
                                        onClick={() => {}}
                                        mb="12px"
                                        // py={'16px'}
                                    >
                                        <Box
                                            bgcolor="#fff"
                                            p="8px 16px"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="space-between"
                                        >
                                            <Typography
                                                fontFamily="Open Sans"
                                                fontSize="16px"
                                                fontWeight={600}
                                                lineHeight="24px"
                                                letterSpacing="0.024px"
                                            >
                                                Top Professionals in your
                                                industry
                                            </Typography>
                                            <IconButton
                                                onClick={() => {}}
                                                sx={{
                                                    background: '#DFEBF0',
                                                    borderRadius: '8px',
                                                    padding: '8px'
                                                }}
                                            >
                                                <ArrowRight />
                                            </IconButton>
                                        </Box>
                                        <Box mt="4px" bgcolor="#fff">
                                            {professionals.map((person) => (
                                                <ProfileList data={person} />
                                            ))}
                                        </Box>
                                    </Box>
                                    <Box
                                        // heading={
                                        // <SectionHeading ml={0} fontSize={106}>
                                        //     Analytics
                                        // </SectionHeading>
                                        // }
                                        onClick={() => {}}
                                        mb="4px"
                                        bgcolor="transparent"
                                        // pl={{xs: '16px', lg: 0}}
                                    >
                                        <Box
                                            bgcolor="#fff"
                                            p="8px 16px"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="space-between"
                                            mb="4px"
                                        >
                                            <Typography
                                                fontFamily="Open Sans"
                                                fontSize="20px"
                                                fontWeight={600}
                                                lineHeight="28px"
                                                sx={{ cursor: 'pointer' }}
                                            >
                                                Analytics
                                            </Typography>
                                            {/* <IconButton
                                        onClick={() => {}}
                                        sx={{
                                            // background: '#DFEBF0',
                                            borderRadius: '8px',
                                            padding: '8px'
                                        }}
                                    >
                                        <ArrowRight />
                                    </IconButton> */}
                                        </Box>
                                        <Grid
                                            container
                                            gap={1}
                                            bgcolor="#fff"
                                            overflow="hidden"
                                            borderRadius={1.5}
                                            wrap="nowrap"
                                            height="140px"
                                            p={{
                                                xs: '8px 16px',
                                                sm: '8px 20px',
                                                lg: 1
                                            }}
                                        >
                                            <Grid
                                                item
                                                bgcolor="#f2f2f2"
                                                flexGrow={1}
                                                maxWidth="33%"
                                            >
                                                <Grid
                                                    container
                                                    flexDirection="column"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    height="100%"
                                                >
                                                    <AnalyticsProfile />
                                                    <Typography
                                                        component="p"
                                                        color="#23282B"
                                                        fontFamily="open sans"
                                                        fontSize={{
                                                            lg: 12,
                                                            xl: 14
                                                        }}
                                                        fontWeight={600}
                                                        textAlign="center"
                                                    >
                                                        PROFILE
                                                        <br />
                                                        VIEWS
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                item
                                                bgcolor="#f2f2f2"
                                                flexGrow={1}
                                                maxWidth="33%"
                                            >
                                                <Grid
                                                    container
                                                    flexDirection="column"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    height="100%"
                                                >
                                                    <AnalyticsSearch />
                                                    <Typography
                                                        component="p"
                                                        color="#23282B"
                                                        fontFamily="open sans"
                                                        fontSize={{
                                                            lg: 12,
                                                            xl: 14
                                                        }}
                                                        fontWeight={600}
                                                        textAlign="center"
                                                    >
                                                        SEARCH
                                                        <br />
                                                        APPEARANCES
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                item
                                                bgcolor="#f2f2f2"
                                                flexGrow={1}
                                                maxWidth="33%"
                                            >
                                                <Grid
                                                    container
                                                    flexDirection="column"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    height="100%"
                                                >
                                                    <AnalyticsGraph />
                                                    <Typography
                                                        component="p"
                                                        color="#23282B"
                                                        fontFamily="open sans"
                                                        fontSize={{
                                                            lg: 12,
                                                            xl: 14
                                                        }}
                                                        fontWeight={600}
                                                        textAlign="center"
                                                    >
                                                        POSTS
                                                        <br />
                                                        ENGAGEMENTS
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    <Box
                                        py={1}
                                        bgcolor="#fff"
                                        px={isMobile ? '16px' : 1}
                                    >
                                        <Button
                                            variant="contained"
                                            sx={{
                                                backgroundColor: '#f2f2f2',
                                                borderRadius: '8px',
                                                width: '100%'
                                                // ml: isMobile ? '16px' : undefined
                                            }}
                                        >
                                            <Typography
                                                color={'primary'}
                                                fontFamily="open sans"
                                                fontWeight={600}
                                                fontSize={14}
                                                sx={{
                                                    textTransform: 'none',
                                                    py: 0.5
                                                }}
                                            >
                                                Upgrade to Premium plan
                                            </Typography>
                                        </Button>
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <SnackAlert
                open={showAlert}
                handleClose={() => setShowAlert(false)}
                message={message}
                type={type}
            />
        </Container>
    );
}

export default ProfessionalProfile;

interface IPropTypes {
    setShowAllPosts: () => void;
    value: number;
    showAllPosts: boolean;
}
const ActivityList = ({ value, showAllPosts, setShowAllPosts }: IPropTypes) => {
    const { posts, totalPosts, isLoaded, setGetAfter } = usePost();
    useEffect(() => {
        if (showAllPosts) {
            setGetAfter((prev) => prev + 5);
        }
    }, [showAllPosts]);

    return (
        <>
            {!isLoaded && posts?.length === 0 ? (
                [1, 2, 3, 4, 5].map((_) => <SkeletonLoader />)
            ) : (
                <InfiniteScroll
                    dataLength={posts.length}
                    next={() => setGetAfter((prev) => prev + 5)}
                    hasMore={
                        !showAllPosts ? false : posts.length < (totalPosts ?? 0)
                    }
                    scrollThreshold={0.9}
                    loader={
                        <>
                            {[1, 2, 3].map((_) => (
                                <SkeletonLoader />
                            ))}
                        </>
                    }
                >
                    {posts?.map((feed, index) => (
                        <PostCard
                            handleShowAllPosts={setShowAllPosts}
                            value={value}
                            key={index}
                            feed={feed}
                            showBtn={
                                index + 1 === posts.length &&
                                posts.length < totalPosts &&
                                !showAllPosts
                                    ? true
                                    : false
                            }
                        />
                    ))}
                </InfiniteScroll>
            )}
        </>
    );
};
