import React from 'react'
import { Box, Typography } from '@mui/material';
import { ArrowLeftIcon } from '../../assets/icons/ArrowLeftIcon';
import { useNavigate } from 'react-router-dom';
import { Responsiveness } from '../../theme/overrides';

const PageHeader = ({ header = '' }) => {
    const { isMobile } = Responsiveness()
    const navigate = useNavigate() 
    return (
        <Box
            p={'20px'}
            width="100%"
            bgcolor="#fff"
            borderBottom="1px solid #D9D9D9"
            display="flex"
            alignItems="center"
            gap="20px"
        >
            <Box onClick={() => navigate(-1)} sx={{cursor: 'pointer'}}>
                <ArrowLeftIcon />
            </Box>
            <Typography
                fontFamily="Open Sans"
                fontSize={isMobile ? '16px' : "24px"}
                fontWeight={600}
                lineHeight="32px"
                color="#23282b"
            >
                {header}
            </Typography>
        </Box>
    );
}

export default PageHeader