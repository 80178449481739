export interface SaveProfessionalProfileRequest {
    professionalProfileId: string;
    firstName: string;
    lastName: string;
    tagline: string;
}

export interface SaveProfessionalProfileResponse {
    id: string;
}

export interface PatchProfessionalAccount {
    professionalProfileId: string;
    firstName: string;
    lastName: string;
    emailAddress: string;
    dateOfBirth: string;
    bio: string;
    tagline: string;
    gender: any;
    address: PatchAddress;
    phoneNumber: string;
}

export interface PatchAddress {
    id: string;
    street1?: string;
    street2?: string;
    stateProvince?: string;
    zipPostCode?: string;
    country: string;
    city: string;
    createdAt?: any;
    lastUpdatedAt?: any;
}

export enum Gender {
    Male = 'MALE',
    Female = 'FEMALE',
    Other = 'OTHER',
    DeclineToSay = 'DECLINE_TO_SAY'
}
export const genders = [
    { key: 'Male', value: Gender.Male },
    { key: 'Female', value: Gender.Female },
    { key: 'Other', value: Gender.Other }
];
