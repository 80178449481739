import React from 'react'

const CopyIcon = () => {
  return (
      <svg
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
          <rect width="36" height="36" rx="8" fill="#F2F2F2" />
          <path
              d="M21.3337 18.7498V22.2498C21.3337 25.1665 20.167 26.3332 17.2503 26.3332H13.7503C10.8337 26.3332 9.66699 25.1665 9.66699 22.2498V18.7498C9.66699 15.8332 10.8337 14.6665 13.7503 14.6665H17.2503C20.167 14.6665 21.3337 15.8332 21.3337 18.7498Z"
              fill="#808080"
          />
          <path
              d="M22.2498 9.6665H18.7498C16.2464 9.6665 15.0382 10.5322 14.7458 12.6156C14.6683 13.1671 15.1298 13.6248 15.6867 13.6248H17.2498C20.7498 13.6248 22.3748 15.2498 22.3748 18.7498V20.3129C22.3748 20.8698 22.8326 21.3313 23.3841 21.2539C25.4675 20.9614 26.3331 19.7533 26.3331 17.2498V13.7498C26.3331 10.8332 25.1665 9.6665 22.2498 9.6665Z"
              fill="#808080"
          />
      </svg>
  );
}

export default CopyIcon
