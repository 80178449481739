import React from 'react';

function Global() {
    return (
        <>
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M7.65055 20.9137C7.62055 20.9137 7.58055 20.9338 7.55055 20.9338C5.61055 19.9738 4.03055 18.3838 3.06055 16.4438C3.06055 16.4138 3.08055 16.3737 3.08055 16.3438C4.30055 16.7038 5.56055 16.9738 6.81055 17.1838C7.03055 18.4438 7.29055 19.6937 7.65055 20.9137Z"
                    fill="#08BD04"
                />
                <path
                    d="M20.9391 16.4538C19.9491 18.4438 18.2991 20.0538 16.2891 21.0238C16.6691 19.7538 16.9891 18.4738 17.1991 17.1838C18.4591 16.9738 19.6991 16.7038 20.9191 16.3438C20.9091 16.3837 20.9391 16.4238 20.9391 16.4538Z"
                    fill="#08BD04"
                />
                <path
                    d="M21.0191 7.70656C19.7591 7.32656 18.4891 7.01656 17.1991 6.79656C16.9891 5.50656 16.6791 4.22656 16.2891 2.97656C18.3591 3.96656 20.0291 5.63656 21.0191 7.70656Z"
                    fill="#08BD04"
                />
                <path
                    d="M7.65047 3.0925C7.29047 4.3125 7.03047 5.5525 6.82047 6.8125C5.53047 7.0125 4.25047 7.3325 2.98047 7.7125C3.95047 5.7025 5.56047 4.0525 7.55047 3.0625C7.58047 3.0625 7.62047 3.0925 7.65047 3.0925Z"
                    fill="#08BD04"
                />
                <path
                    d="M15.4898 6.59C13.1698 6.33 10.8298 6.33 8.50977 6.59C8.75977 5.22 9.07977 3.85 9.52977 2.53C9.54977 2.45 9.53977 2.39 9.54977 2.31C10.3398 2.12 11.1498 2 11.9998 2C12.8398 2 13.6598 2.12 14.4398 2.31C14.4498 2.39 14.4498 2.45 14.4698 2.53C14.9198 3.86 15.2398 5.22 15.4898 6.59Z"
                    fill="#08BD04"
                />
                <path
                    d="M6.59 15.4878C5.21 15.2378 3.85 14.9178 2.53 14.4678C2.45 14.4478 2.39 14.4578 2.31 14.4478C2.12 13.6578 2 12.8478 2 11.9978C2 11.1578 2.12 10.3378 2.31 9.55781C2.39 9.54781 2.45 9.54781 2.53 9.52781C3.86 9.08781 5.21 8.75781 6.59 8.50781C6.34 10.8278 6.34 13.1678 6.59 15.4878Z"
                    fill="#08BD04"
                />
                <path
                    d="M22.0002 11.9978C22.0002 12.8478 21.8802 13.6578 21.6902 14.4478C21.6102 14.4578 21.5502 14.4478 21.4702 14.4678C20.1402 14.9078 18.7802 15.2378 17.4102 15.4878C17.6702 13.1678 17.6702 10.8278 17.4102 8.50781C18.7802 8.75781 20.1502 9.07781 21.4702 9.52781C21.5502 9.54781 21.6102 9.55781 21.6902 9.55781C21.8802 10.3478 22.0002 11.1578 22.0002 11.9978Z"
                    fill="#08BD04"
                />
                <path
                    d="M15.4898 17.4062C15.2398 18.7862 14.9198 20.1462 14.4698 21.4662C14.4498 21.5462 14.4498 21.6063 14.4398 21.6863C13.6598 21.8763 12.8398 21.9963 11.9998 21.9963C11.1498 21.9963 10.3398 21.8763 9.54977 21.6863C9.53977 21.6063 9.54977 21.5462 9.52977 21.4662C9.08977 20.1362 8.75977 18.7862 8.50977 17.4062C9.66977 17.5362 10.8298 17.6262 11.9998 17.6262C13.1698 17.6262 14.3398 17.5362 15.4898 17.4062Z"
                    fill="#08BD04"
                />
                <path
                    d="M15.7633 15.7633C13.2622 16.0789 10.7378 16.0789 8.23667 15.7633C7.92111 13.2622 7.92111 10.7378 8.23667 8.23667C10.7378 7.92111 13.2622 7.92111 15.7633 8.23667C16.0789 10.7378 16.0789 13.2622 15.7633 15.7633Z"
                    fill="#08BD04"
                />
            </svg>
        </>
    );
}

export default Global;
