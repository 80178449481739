const UserPlaceholderIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
            <rect width="100" height="100" rx="50" fill="#FFECE5" />
            <path d="M49.9993 47.9167C54.6017 47.9167 58.3327 44.1857 58.3327 39.5833C58.3327 34.981 54.6017 31.25 49.9993 31.25C45.397 31.25 41.666 34.981 41.666 39.5833C41.666 44.1857 45.397 47.9167 49.9993 47.9167Z" fill="black" />
            <path d="M36.0528 62.5C32.6015 68.0627 44.0259 70.8333 49.9993 70.8333C55.9728 70.8333 67.3972 68.0627 63.9459 62.5C61.3505 58.3168 56.1009 54.1667 49.9993 54.1667C43.8977 54.1667 38.6482 58.3168 36.0528 62.5Z" fill="black" />
        </svg>
    )
}

export default UserPlaceholderIcon