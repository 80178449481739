import React from 'react';

function JobFactorIcon() {
    return (
        <svg
            width="175"
            height="50"
            viewBox="0 0 175 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_4602_63845)">
                <path
                    d="M41.9223 10.9648H45.3123C45.8823 10.9648 46.1673 11.2383 46.1673 11.7852V30.6687C46.1673 32.8031 45.5837 34.4518 44.4166 35.6148C43.2495 36.7777 41.6073 37.3579 39.4901 37.3553H35.4224C33.2903 37.3553 31.6441 36.7821 30.4836 35.6356C29.3232 34.4892 28.7427 32.8382 28.7422 30.6826V26.8401C28.7422 26.6117 28.8322 26.3926 28.9924 26.231C29.1526 26.0695 29.3699 25.9788 29.5964 25.9788H33.0294C33.1419 25.9782 33.2534 26.0002 33.3574 26.0432C33.4615 26.0863 33.5562 26.1496 33.6359 26.2297C33.7156 26.3097 33.7789 26.4048 33.822 26.5096C33.8652 26.6144 33.8874 26.7267 33.8874 26.8401V30.4419C33.8874 31.319 34.0709 31.9423 34.4377 32.3117C34.8046 32.6811 35.4096 32.8661 36.2528 32.8666H38.6597C39.5295 32.8666 40.1412 32.6816 40.4947 32.3117C40.8483 31.9418 41.0251 31.3185 41.0251 30.4419V11.827C41.0251 11.2522 41.3241 10.9648 41.9223 10.9648Z"
                    fill="white"
                />
                <path
                    d="M58.3367 37.3585H54.7172C52.5754 37.3585 50.9217 36.7848 49.7562 35.6374C48.5906 34.4899 48.0078 32.8389 48.0078 30.6843V23.141C48.0078 20.9838 48.5906 19.3313 49.7562 18.1833C50.9217 17.0353 52.5754 16.4619 54.7172 16.4629H58.3367C60.4514 16.4629 62.0981 17.0433 63.277 18.2042C64.4559 19.3651 65.0443 21.0107 65.0422 23.141V30.6805C65.0422 32.8108 64.4538 34.4566 63.277 35.618C62.1002 36.7794 60.4534 37.3596 58.3367 37.3585ZM55.5085 32.9472H57.5807C58.4505 32.9472 59.0671 32.7692 59.4303 32.4132C59.7936 32.0572 59.9768 31.441 59.9799 30.5644V23.3345C59.9799 22.4574 59.7967 21.8413 59.4303 21.4864C59.064 21.1314 58.4475 20.9531 57.5807 20.9516H55.5085C54.6387 20.9516 54.0288 21.1298 53.6788 21.4864C53.3288 21.8429 53.1525 22.4589 53.15 23.3345V30.5644C53.15 31.4415 53.3263 32.0578 53.6788 32.4132C54.0313 32.7687 54.6412 32.9467 55.5085 32.9472Z"
                    fill="white"
                />
                <path
                    d="M66.6562 36.4966V9.42973C66.6562 9.20128 66.7462 8.98219 66.9064 8.82065C67.0666 8.65911 67.2839 8.56836 67.5105 8.56836H70.9442C71.1708 8.56836 71.388 8.65911 71.5482 8.82065C71.7084 8.98219 71.7984 9.20128 71.7984 9.42973V18.6292H71.9604C72.2576 17.8945 72.7839 17.3368 73.5391 16.956C74.2794 16.5791 75.0974 16.3834 75.9268 16.3849H77.2829C79.4273 16.3849 81.0832 16.9586 82.2509 18.1061C83.4185 19.2535 84.002 20.9045 84.0015 23.0591V30.6783C84.0015 32.836 83.4179 34.487 82.2509 35.6313C81.0838 36.7757 79.4278 37.3492 77.2829 37.3517H67.5105C67.285 37.3517 67.0686 37.2618 66.9086 37.1016C66.7486 36.9414 66.6579 36.7239 66.6562 36.4966ZM71.8752 32.418C71.8752 32.5377 71.9223 32.6525 72.0062 32.7371C72.0901 32.8217 72.2039 32.8692 72.3226 32.8692H76.4671C77.3328 32.8692 77.9483 32.6845 78.3136 32.3151C78.679 31.9457 78.8619 31.3227 78.8624 30.4461V23.2975C78.8624 22.4204 78.6795 21.7974 78.3136 21.4285C77.9478 21.0596 77.3323 20.8746 76.4671 20.8736H74.6804C72.8102 20.8736 71.8752 21.9153 71.8752 23.9986V32.418Z"
                    fill="white"
                />
                <path
                    d="M93.1984 16.4631H96.4296C96.7534 16.4631 96.9752 16.5242 97.0965 16.6465C97.2178 16.7688 97.2792 16.9801 97.2792 17.2788V20.0904C97.2792 20.3189 97.1892 20.538 97.029 20.6995C96.8688 20.861 96.6515 20.9518 96.4249 20.9518H93.1194C92.8216 20.9518 92.6742 21.1027 92.6742 21.403V36.4981C92.6742 37.0719 92.3772 37.3587 91.7832 37.3587H88.3824C87.8155 37.3587 87.532 37.0719 87.532 36.4981V21.4007C87.532 21.1019 87.3688 20.9523 87.0424 20.9518H85.1628C85.0506 20.9518 84.9396 20.9295 84.8359 20.8862C84.7323 20.8429 84.6381 20.7795 84.5588 20.6995C84.4795 20.6195 84.4165 20.5246 84.3736 20.42C84.3307 20.3155 84.3086 20.2035 84.3086 20.0904V17.2788C84.3086 16.953 84.3692 16.7371 84.492 16.6264C84.6148 16.5157 84.8389 16.4631 85.1651 16.4631H87.0432C87.3686 16.4631 87.5315 16.3132 87.532 16.0134V15.2395C87.532 13.086 88.0949 11.4363 89.2205 10.2904C90.3462 9.14444 91.9835 8.57175 94.1325 8.57227H96.4579C96.7841 8.57227 97.0006 8.63418 97.1095 8.75646C97.2185 8.87874 97.273 9.09079 97.273 9.39107V12.1996C97.273 12.7738 97.0036 13.061 96.4649 13.061H94.9767C94.114 13.061 93.5279 13.2457 93.2184 13.6151C92.9088 13.9845 92.753 14.6057 92.751 15.4787V16.0119C92.751 16.0711 92.7625 16.1298 92.785 16.1846C92.8075 16.2393 92.8405 16.289 92.882 16.3309C92.9236 16.3728 92.9729 16.4061 93.0272 16.4287C93.0815 16.4514 93.1397 16.4631 93.1984 16.4631Z"
                    fill="white"
                />
                <path
                    d="M104.892 37.4359H103.899C101.76 37.4359 100.11 36.8601 98.9476 35.7086C97.7851 34.557 97.2036 32.8982 97.2031 30.7323V23.1658C97.2031 20.9988 97.7846 19.3403 98.9476 18.1903C100.111 17.0402 101.761 16.4644 103.899 16.4629H113.272C113.867 16.4629 114.165 16.7508 114.165 17.3266V36.5637C114.165 37.1447 113.868 37.4354 113.274 37.4359H109.877C109.65 37.4359 109.433 37.3452 109.273 37.1837C109.112 37.0221 109.022 36.803 109.022 36.5746V35.1916H108.861C108.563 35.9397 108.036 36.5011 107.281 36.8756C106.538 37.2457 105.72 37.4375 104.892 37.4359ZM109.022 29.7471V21.4036C109.022 21.1033 108.86 20.9516 108.534 20.9516H104.706C103.836 20.9516 103.226 21.1366 102.875 21.5065C102.524 21.8764 102.347 22.501 102.345 23.3801V30.4491C102.345 31.3262 102.522 31.9497 102.875 32.3196C103.228 32.6895 103.838 32.8742 104.706 32.8737H106.213C108.086 32.8711 109.022 31.8289 109.022 29.7471Z"
                    fill="white"
                />
                <path
                    d="M129.513 33.732V36.5367C129.513 36.8377 129.451 37.049 129.329 37.172C129.207 37.2951 128.983 37.3562 128.656 37.3562H122.58C120.431 37.3562 118.773 36.7825 117.605 35.635C116.436 34.4876 115.852 32.8366 115.852 30.682V23.141C115.852 20.9838 116.436 19.3313 117.605 18.1833C118.773 17.0353 120.432 16.4619 122.58 16.4629H128.658C128.885 16.4629 129.102 16.5537 129.262 16.7154C129.423 16.8771 129.513 17.0964 129.513 17.325V20.1297C129.513 20.4315 129.451 20.6438 129.329 20.7666C129.206 20.8894 128.98 20.9511 128.653 20.9516H123.373C122.527 20.9516 121.919 21.1366 121.551 21.5065C121.183 21.8764 120.997 22.501 120.994 23.3801V30.4491C120.994 31.3262 121.178 31.9497 121.547 32.3196C121.916 32.6895 122.523 32.8742 123.369 32.8737H128.658C128.884 32.8737 129.101 32.964 129.261 33.1249C129.421 33.2857 129.512 33.504 129.513 33.732Z"
                    fill="white"
                />
                <path
                    d="M132.676 20.9513H131.057C130.733 20.9513 130.51 20.8968 130.389 20.788C130.268 20.6796 130.207 20.4614 130.207 20.1348V17.3239C130.207 17.0955 130.297 16.8764 130.457 16.7149C130.617 16.5533 130.835 16.4626 131.061 16.4626H132.673C132.792 16.4626 132.905 16.415 132.989 16.3304C133.073 16.2458 133.12 16.131 133.12 16.0114V12.1356C133.12 11.564 133.417 11.2779 134.011 11.2773H137.408C137.52 11.2769 137.632 11.2989 137.736 11.3421C137.84 11.3852 137.934 11.4487 138.014 11.5288C138.094 11.6089 138.157 11.704 138.2 11.8088C138.243 11.9136 138.266 12.026 138.266 12.1395V16.0137C138.266 16.3135 138.429 16.4631 138.755 16.4626H141.94C142.166 16.4626 142.383 16.5533 142.544 16.7149C142.704 16.8764 142.794 17.0955 142.794 17.3239V20.1348C142.794 20.4351 142.733 20.6456 142.61 20.7678C142.488 20.8901 142.264 20.9513 141.937 20.9513H138.752C138.428 20.9513 138.266 21.106 138.266 21.4032V30.4859C138.266 31.3356 138.455 31.9455 138.834 32.3154C139.212 32.6853 139.835 32.87 140.701 32.8695H142.323C142.549 32.8695 142.767 32.9604 142.927 33.122C143.088 33.2837 143.178 33.503 143.178 33.7317V36.5363C143.178 36.8382 143.116 37.0494 142.995 37.1725C142.874 37.2955 142.65 37.3567 142.326 37.3567H139.853C137.718 37.3567 136.062 36.7824 134.886 35.6339C133.711 34.4855 133.122 32.8448 133.121 30.7119V21.4025C133.121 21.2832 133.074 21.1688 132.991 21.0842C132.908 20.9997 132.794 20.9519 132.676 20.9513Z"
                    fill="white"
                />
                <path
                    d="M154.044 37.3585H150.424C148.282 37.3585 146.629 36.7848 145.463 35.6374C144.298 34.4899 143.715 32.8389 143.715 30.6843V23.141C143.715 20.9838 144.298 19.3313 145.463 18.1833C146.629 17.0353 148.282 16.4619 150.424 16.4629H154.044C156.158 16.4629 157.805 17.0433 158.984 18.2042C160.163 19.3651 160.753 21.0107 160.753 23.141V30.6805C160.753 32.8108 160.163 34.4566 158.984 35.618C157.805 36.7794 156.158 37.3596 154.044 37.3585ZM151.216 32.9472H153.288C154.158 32.9472 154.774 32.7692 155.137 32.4132C155.501 32.0572 155.684 31.441 155.687 30.5644V23.3345C155.687 22.4574 155.504 21.8413 155.137 21.4864C154.771 21.1314 154.154 20.9531 153.288 20.9516H151.216C150.346 20.9516 149.736 21.1298 149.386 21.4864C149.036 21.8429 148.86 22.4589 148.857 23.3345V30.5644C148.857 31.4415 149.033 32.0578 149.386 32.4132C149.738 32.7687 150.348 32.9467 151.216 32.9472Z"
                    fill="white"
                />
                <path
                    d="M166.729 37.3586H163.296C163.183 37.3586 163.072 37.3363 162.969 37.293C162.865 37.2497 162.771 37.1863 162.692 37.1063C162.612 37.0263 162.549 36.9313 162.506 36.8268C162.463 36.7223 162.441 36.6103 162.441 36.4972V17.3243C162.441 17.2112 162.463 17.0991 162.506 16.9946C162.549 16.8901 162.612 16.7952 162.692 16.7152C162.771 16.6352 162.865 16.5718 162.969 16.5285C163.072 16.4852 163.183 16.4629 163.296 16.4629H166.729C166.956 16.4629 167.173 16.5537 167.333 16.7152C167.494 16.8767 167.584 17.0958 167.584 17.3243V18.7073H167.747C168.046 17.9726 168.577 17.4148 169.338 17.0341C170.085 16.6567 170.91 16.461 171.746 16.4629H173.214C173.758 16.4629 174.03 16.7485 174.03 17.3196V20.1297C174.03 20.4578 173.969 20.6836 173.846 20.8069C173.724 20.9302 173.513 20.9779 173.214 20.9501H170.399C168.522 20.9511 167.584 22.0191 167.584 24.1541V36.4972C167.584 36.7256 167.494 36.9447 167.333 37.1063C167.173 37.2678 166.956 37.3586 166.729 37.3586Z"
                    fill="white"
                />
                <path
                    d="M20.9195 0C17.7095 0 15.1075 2.58638 15.1075 5.77622V13.7048H2.99296C1.39292 13.7048 0.0976562 14.9984 0.0976562 16.5929V42.4644C0.0976562 45.655 2.68819 48.2422 5.88826 48.2422V37.3791H17.996C19.596 37.3791 20.8981 36.0855 20.8981 34.4894V0.0108313M15.1182 31.8226H5.89893V19.676H15.1182V31.8226Z"
                    fill="white"
                />
                <path
                    d="M42.4403 11.8301H45.8303C46.4003 11.8301 46.6853 12.1035 46.6853 12.6504V31.5339C46.6853 33.6673 46.1017 35.316 44.9346 36.48C43.7675 37.644 42.1259 38.2252 40.0096 38.2236H35.9419C33.8099 38.2236 32.1636 37.6507 31.0032 36.5048C29.8427 35.3588 29.2622 33.7078 29.2617 31.5517V27.7054C29.2617 27.4769 29.3517 27.2578 29.5119 27.0963C29.6721 26.9347 29.8894 26.844 30.1159 26.844H33.5489C33.7757 26.844 33.9931 26.9348 34.1535 27.0965C34.3138 27.2582 34.4039 27.4775 34.4039 27.7061V31.3072C34.4039 32.1843 34.5873 32.8078 34.9542 33.1777C35.321 33.5476 35.9261 33.7323 36.7693 33.7318H39.1815C40.0513 33.7318 40.663 33.5471 41.0166 33.1777C41.3701 32.8083 41.5469 32.1848 41.5469 31.3072V12.693C41.5444 12.1177 41.8421 11.8301 42.4403 11.8301Z"
                    fill="#05668D"
                />
                <path
                    d="M58.8562 38.2257H55.2367C53.0944 38.2257 51.4407 37.652 50.2757 36.5046C49.1106 35.3571 48.5279 33.7045 48.5273 31.5469V24.0082C48.5273 21.8505 49.1101 20.1979 50.2757 19.0505C51.4412 17.903 53.0949 17.3296 55.2367 17.3301H58.8562C60.9709 17.3301 62.6177 17.9105 63.7965 19.0714C64.9754 20.2323 65.5638 21.8778 65.5618 24.0082V31.5469C65.5618 33.6772 64.9733 35.323 63.7965 36.4844C62.6197 37.6458 60.9729 38.2262 58.8562 38.2257ZM56.028 33.8144H58.1002C58.9701 33.8144 59.5866 33.6364 59.9499 33.2804C60.3132 32.9244 60.4963 32.3081 60.4994 31.4316V24.2009C60.4994 23.3238 60.3162 22.7077 59.9499 22.3528C59.5835 21.9978 58.967 21.8198 58.1002 21.8188H56.028C55.1582 21.8188 54.5483 21.9968 54.1983 22.3528C53.8483 22.7088 53.6721 23.3248 53.6695 24.2009V31.4292C53.6695 32.3063 53.8458 32.9226 54.1983 33.2781C54.5508 33.6336 55.1607 33.8124 56.028 33.8144Z"
                    fill="#05668D"
                />
                <path
                    d="M67.1758 37.3638V10.3008C67.1758 10.0724 67.2658 9.85328 67.426 9.69174C67.5862 9.53021 67.8034 9.43945 68.03 9.43945H71.4637C71.6903 9.43945 71.9076 9.53021 72.0678 9.69174C72.228 9.85328 72.318 10.0724 72.318 10.3008V19.4965H72.4799C72.7767 18.7618 73.3029 18.204 74.0586 17.8233C74.7989 17.4463 75.6169 17.2507 76.4463 17.2521H77.8024C79.9468 17.2521 81.6028 17.8256 82.7704 18.9725C83.938 20.1195 84.5215 21.7718 84.521 23.9294V31.5478C84.521 33.7065 83.9375 35.3588 82.7704 36.5048C81.6033 37.6507 79.9473 38.2241 77.8024 38.2252H68.03C67.8034 38.2252 67.5862 38.1344 67.426 37.9729C67.2658 37.8113 67.1758 37.5922 67.1758 37.3638ZM72.3947 33.2853C72.3947 33.3445 72.4063 33.4032 72.4288 33.4579C72.4512 33.5127 72.4842 33.5624 72.5258 33.6043C72.5673 33.6462 72.6166 33.6794 72.6709 33.7021C72.7252 33.7248 72.7834 33.7365 72.8421 33.7365H76.9866C77.8523 33.7365 78.4681 33.5518 78.8339 33.1823C79.1998 32.8129 79.3827 32.1897 79.3827 31.3126V24.1647C79.3827 23.2876 79.1998 22.6646 78.8339 22.2957C78.4681 21.9268 77.8523 21.7418 76.9866 21.7408H75.2006C73.3326 21.7408 72.3985 22.7817 72.3985 24.8635L72.3947 33.2853Z"
                    fill="#05668D"
                />
                <path
                    d="M93.7179 17.3303H96.9491C97.273 17.3303 97.4948 17.3914 97.616 17.5137C97.7373 17.636 97.7987 17.8472 97.7987 18.146V20.9576C97.7987 21.0707 97.7766 21.1827 97.7337 21.2872C97.6907 21.3917 97.6278 21.4867 97.5485 21.5667C97.4692 21.6467 97.375 21.7101 97.2714 21.7534C97.1677 21.7967 97.0566 21.819 96.9445 21.819H93.6389C93.3411 21.819 93.1938 21.9699 93.1938 22.2702V37.3615C93.1938 37.9357 92.8967 38.2228 92.3027 38.2228H88.902C88.335 38.2228 88.0516 37.9357 88.0516 37.3615V22.2678C88.0516 21.9691 87.8884 21.8195 87.5619 21.819H85.6823C85.4558 21.819 85.2385 21.7282 85.0783 21.5667C84.9181 21.4051 84.8281 21.1861 84.8281 20.9576V18.146C84.8281 17.8194 84.8888 17.6042 85.0116 17.4936C85.1344 17.3829 85.3585 17.3303 85.6846 17.3303H87.5627C87.8881 17.3303 88.0511 17.1804 88.0516 16.8806V16.1059C88.0516 13.9529 88.6144 12.3034 89.7401 11.1575C90.8657 10.0116 92.503 9.43894 94.652 9.43945H96.9767C97.3037 9.43945 97.5201 9.50137 97.6291 9.62365C97.7381 9.74592 97.7925 9.9572 97.7925 10.2575V13.0668C97.7925 13.641 97.5232 13.9282 96.9844 13.9282H95.4878C94.6251 13.9282 94.039 14.1129 93.7295 14.4823C93.4199 14.8517 93.2649 15.4737 93.2644 16.3482V16.8799C93.2645 16.9396 93.2763 16.9987 93.2992 17.0537C93.3221 17.1088 93.3557 17.1587 93.3979 17.2006C93.44 17.2425 93.4901 17.2755 93.545 17.2978C93.6 17.3201 93.6587 17.3311 93.7179 17.3303Z"
                    fill="#05668D"
                />
                <path
                    d="M105.416 38.3031H104.418C102.28 38.3031 100.63 37.7273 99.4672 36.5758C98.3047 35.4242 97.7232 33.7654 97.7227 31.5995V24.0329C97.7227 21.866 98.3042 20.2072 99.4672 19.0567C100.63 17.9061 102.281 17.3306 104.418 17.3301H113.792C114.387 17.3301 114.684 17.618 114.684 18.1938V37.4301C114.684 38.0121 114.387 38.3031 113.793 38.3031H110.397C110.17 38.3031 109.953 38.2124 109.793 38.0508C109.633 37.8893 109.543 37.6702 109.543 37.4418V36.0588H109.381C109.083 36.8069 108.557 37.368 107.801 37.742C107.06 38.1122 106.243 38.3043 105.416 38.3031ZM109.547 30.6135V22.2707C109.547 21.9694 109.384 21.8188 109.058 21.8188H105.23C104.36 21.8188 103.75 22.0037 103.399 22.3737C103.048 22.7436 102.871 23.3669 102.869 24.2434V31.3124C102.869 32.1895 103.046 32.8127 103.399 33.1821C103.752 33.5516 104.362 33.7365 105.23 33.737H106.737C108.608 33.737 109.543 32.6959 109.543 30.6135H109.547Z"
                    fill="#05668D"
                />
                <path
                    d="M130.036 34.6023V37.4093C130.036 37.7103 129.975 37.9216 129.853 38.0446C129.731 38.1677 129.506 38.2288 129.18 38.2288H123.104C120.955 38.2288 119.296 37.6551 118.128 36.5076C116.96 35.3602 116.376 33.7076 116.375 31.55V24.0082C116.375 21.8505 116.959 20.1979 118.128 19.0505C119.297 17.903 120.955 17.3296 123.104 17.3301H129.181C129.294 17.3301 129.405 17.3525 129.509 17.396C129.613 17.4395 129.708 17.5033 129.787 17.5837C129.866 17.6641 129.929 17.7595 129.972 17.8644C130.015 17.9694 130.037 18.0818 130.036 18.1953V20.9969C130.036 21.2987 129.975 21.511 129.852 21.6338C129.729 21.7566 129.504 21.8183 129.176 21.8188H123.893C123.046 21.8188 122.439 22.0037 122.071 22.3737C121.702 22.7436 121.518 23.3669 121.517 24.2434V31.3124C121.517 32.1895 121.702 32.8127 122.071 33.1821C122.439 33.5516 123.047 33.7365 123.893 33.737H129.181C129.294 33.737 129.405 33.7594 129.509 33.803C129.613 33.8465 129.708 33.9103 129.787 33.9906C129.866 34.071 129.929 34.1664 129.972 34.2714C130.015 34.3764 130.037 34.4888 130.036 34.6023Z"
                    fill="#05668D"
                />
                <path
                    d="M133.196 21.8185H131.576C131.252 21.8185 131.03 21.764 130.908 21.6552C130.787 21.5468 130.727 21.3286 130.727 21.002V18.195C130.727 17.9665 130.817 17.7475 130.977 17.5859C131.137 17.4244 131.354 17.3336 131.581 17.3336H133.193C133.311 17.3336 133.425 17.2861 133.509 17.2015C133.593 17.1169 133.64 17.0021 133.64 16.8824V13.002C133.64 12.4304 133.937 12.1445 134.53 12.1445H137.927C138.04 12.1441 138.152 12.1662 138.256 12.2096C138.36 12.2529 138.455 12.3166 138.535 12.397C138.615 12.4775 138.678 12.573 138.721 12.6782C138.764 12.7833 138.786 12.896 138.785 13.0098V16.8793C138.785 17.1791 138.948 17.3287 139.274 17.3282H142.459C142.572 17.3282 142.683 17.3507 142.787 17.3943C142.891 17.4379 142.986 17.5018 143.065 17.5824C143.144 17.663 143.207 17.7586 143.25 17.8637C143.293 17.9688 143.314 18.0814 143.313 18.195V21.0058C143.313 21.3061 143.253 21.5159 143.13 21.6389C143.007 21.762 142.783 21.8223 142.457 21.8223H139.272C138.947 21.8223 138.785 21.973 138.785 22.2743V31.3515C138.785 32.2013 138.975 32.8109 139.353 33.1803C139.732 33.5497 140.354 33.7347 141.22 33.7352H142.842C142.955 33.7352 143.066 33.7576 143.17 33.8012C143.274 33.8449 143.369 33.9088 143.448 33.9893C143.528 34.0699 143.591 34.1655 143.633 34.2706C143.676 34.3757 143.698 34.4883 143.697 34.6019V37.4082C143.697 37.7092 143.636 37.9205 143.514 38.0435C143.393 38.1666 143.17 38.2285 142.845 38.2285H140.372C138.237 38.2285 136.582 37.6543 135.406 36.5058C134.23 35.3573 133.642 33.7166 133.641 31.5837V22.2696C133.641 22.1504 133.594 22.036 133.51 21.9514C133.427 21.8669 133.314 21.8191 133.196 21.8185Z"
                    fill="#05668D"
                />
                <path
                    d="M154.563 38.2257H150.944C148.802 38.2257 147.148 37.652 145.983 36.5046C144.817 35.3571 144.234 33.7045 144.234 31.5469V24.0082C144.234 21.8505 144.817 20.1979 145.983 19.0505C147.148 17.903 148.802 17.3296 150.944 17.3301H154.563C156.678 17.3301 158.325 17.9105 159.504 19.0714C160.682 20.2323 161.272 21.8778 161.273 24.0082V31.5469C161.273 33.6772 160.683 35.323 159.504 36.4844C158.324 37.6458 156.677 38.2262 154.563 38.2257ZM151.735 33.8144H153.807C154.675 33.8144 155.291 33.6364 155.657 33.2804C156.023 32.9244 156.206 32.3081 156.206 31.4316V24.2009C156.206 23.3238 156.023 22.7077 155.657 22.3528C155.291 21.9978 154.674 21.8198 153.807 21.8188H151.735C150.865 21.8188 150.255 21.9968 149.905 22.3528C149.555 22.7088 149.379 23.3248 149.377 24.2009V31.4292C149.377 32.3063 149.553 32.9226 149.905 33.2781C150.258 33.6336 150.868 33.8124 151.735 33.8144Z"
                    fill="#05668D"
                />
                <path
                    d="M167.249 38.2258H163.815C163.703 38.2258 163.592 38.2035 163.488 38.1602C163.385 38.1169 163.29 38.0535 163.211 37.9735C163.132 37.8935 163.069 37.7986 163.026 37.6941C162.983 37.5895 162.961 37.4775 162.961 37.3644V18.1954C162.961 18.0822 162.983 17.9702 163.026 17.8657C163.069 17.7612 163.132 17.6663 163.211 17.5863C163.29 17.5063 163.385 17.4429 163.488 17.3996C163.592 17.3563 163.703 17.334 163.815 17.334H167.249C167.475 17.334 167.693 17.4247 167.853 17.5863C168.013 17.7478 168.103 17.9669 168.103 18.1954V19.5783H168.267C168.566 18.8436 169.096 18.2859 169.858 17.9051C170.605 17.5278 171.429 17.3321 172.265 17.334H173.733C174.277 17.334 174.55 17.6196 174.55 18.1907V21.0023C174.55 21.3305 174.489 21.5565 174.366 21.6787C174.243 21.801 174.033 21.8498 173.733 21.8227H170.918C169.042 21.8227 168.104 22.8891 168.103 25.0221V37.3644C168.103 37.4775 168.081 37.5895 168.038 37.6941C167.995 37.7986 167.932 37.8935 167.853 37.9735C167.774 38.0535 167.679 38.1169 167.576 38.1602C167.472 38.2035 167.361 38.2258 167.249 38.2258Z"
                    fill="#05668D"
                />
                <path
                    d="M21.5208 0.898438C18.2874 0.898438 15.6664 3.48486 15.6664 6.6757V14.6075H3.46333C1.85161 14.6075 0.546875 15.9015 0.546875 17.4958V43.3754C0.546875 46.5678 3.15634 49.155 6.37979 49.155V38.2893H18.576C20.1877 38.2893 21.4993 36.9945 21.4993 35.3979V0.909272M15.6772 32.731H6.39053V20.5806H15.6772V32.731Z"
                    fill="#05668D"
                />
            </g>
            <defs>
                <clipPath id="clip0_4602_63845">
                    <rect width="175" height="50" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default JobFactorIcon;
