import './style.css';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import SteppedProgress from '../components/SteppedProgress';
import SteppedSectionHeader from '../components/SteppedSectionHeader';
import DocumentUploadIcon from '../../../assets/icons/DocumentUploadMui';
import Button from '@mui/material/Button';
import {
    InputBase,
    InputLabel,
    MenuItem,
    Radio,
    Select,
    TableCell,
    TableRow,
    TextField
} from '@mui/material';
import UserFormIcon from '../../../assets/icons/UserFormIcon';
import Building from '../../../assets/icons/Building';
import UserCircle from '../../../assets/icons/UserCircle';
import Calendar from '../components/Calendar';
import SummaryForm from '../../../assets/icons/SummaryForm';
import Dropdown from '../../../assets/icons/Dropdown';
import Flag from '../../../assets/icons/Flag';
import UserDetails from '../components/UserDetails';
import SearchIcon from '../../../assets/icons/SearchIcon';
import dayjs, { Dayjs } from 'dayjs';
import TableWrapper from '../components/TableWrapper';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
// import useGetCities from '../../../utils/hooks/api/cityState/useGetCities';
// import { IStateRequest } from '../../../utils/hooks/api/cityState/type';
// import { useGetStates } from '../../../utils/hooks/api/cityState/useGetStates';
import { useGetCountries } from '../../../utils/hooks/api/cityState/useGetCountries';
import { IStateRequest } from '../../../utils/hooks/api/cityState/type';
import { useQuery, useQueryClient } from 'react-query';
import externalAxiosInstance from '../../../utils/hooks/api/externalAxiosConfig';
import usePatchUserInfo from '../../../utils/hooks/api/users/usePatchUserInfo';
import { Address } from '../../../utils/hooks/api/users/types';
import { v4 as uuidv4 } from 'uuid';
import { useDropzone } from 'react-dropzone';
import { useUploadProfessionalProfilePhoto } from '../../../utils/hooks/api/users/useUploadProfessionalProfilePhoto';
import { useAuth } from '../../../utils/context/AuthContext';
import SnackAlert, { SnackAlertType } from '../../../components/Snackbar';
import { useGetSearchTerm } from '../../../utils/hooks/api/search/useSearchTerm';
import { useSendConnectionRequest } from '../../../utils/hooks/api/connections/useSendConnectionRequest';
import axios, { AxiosError } from 'axios';
import { useGetConnectionRequestSent } from '../../../utils/hooks/api/connections/useGetConnectionRequestSent';
import { QueryKeys } from '../../../utils/hooks/api/QueryKey';
import { Link, useNavigate } from 'react-router-dom';
import { inviteDialogSignal } from '../../../components/InviteDialog';
import ConnectionLoader from '../../../components/LoadingState/ConnectionLoader';
import ConnectionSkills from '../../Connections/components/ConnectionSkillsTab';
import JobFactorIcon from '../../../assets/icons/JobFactorIcon';

interface IOptionType {
    id: string;
    term: string;
    profileId?: string;
    userId: string;
    isAdded: boolean;
    imageUrl?: string;
    photoUrl?: string;
    entityType: string;
    currentEmployment: {
        employmentLevel: string;
        companyName: string;
    };
    firstName?: string;
    lastName?: string;
    score?: number;
}

interface IAddedUsers {
    destinationUser: IOptionType;
}
interface IPersonalDetailForm {
    firstName: string;
    lastName: string;
    gender: string;
    dateOfBirth: (date: Dayjs | null) => {};
    tagline: string;
    address: Address;
    bio: string;
}

function ProfileWizard() {
    const navigate = useNavigate();
    const videoRef = useRef<HTMLInputElement>(null);
    const imageRef = useRef<HTMLInputElement>(null);
    const [step, setStep] = useState(1);
    const [jobTitle, setJobTitle] = useState('');
    const [company, setCompany] = useState('');
    const [companySize, setCompanySize] = useState(
        'Fortune 500, Ivy league companies'
    );
    const [industry, setIndustry] = useState('Please Select');
    const [search, setSearch] = useState('');
    const [users, setUsers] = useState<IOptionType[]>([]);
    const [videoSrc, setVideoSrc] = useState('');
    const [image, setImage] = useState('');
    const [startDate, setStartDate] = useState<Dayjs | null>(null);
    const [endDate, setEndDate] = useState<Dayjs | null>(null);
    const [page, setPage] = useState(0);
    const rowsPerPage = 10;
    const updateDetails = usePatchUserInfo();
    const [message, setMessage] = useState('');
    const [type, setType] = useState<SnackAlertType>('info');
    const [showAlert, setShowAlert] = useState(false);
    const { user, setUser } = useAuth();
    const {
        control,
        handleSubmit,
        setError,
        register,
        clearErrors,
        formState,
        watch,
        resetField
    } = useForm<IPersonalDetailForm>({
        defaultValues: {
            firstName: user?.professionalProfile?.firstName,
            lastName: user?.professionalProfile?.lastName,
            tagline: user?.professionalProfile?.tagline
        }
    });
    const { isDirty, isValid, errors, isSubmitting } = formState;
    const { data: countries } = useGetCountries();
    const countryList = countries;
    const [country, setCountry] = useState<IStateRequest>({ iso2: '' });
    const [disableStateDropDown, setDisableDropdown] = useState(false);
    const [disableCityDropDown, setDisableCityDropdown] = useState(false);
    const [statesList, setStatesList] = useState([]); // state list
    const [citiesList, setCitiesList] = useState([]); // city list
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedState, setSelectedState] = useState({
        value: '',
        iso2: ''
    });

    /*
     * call the useQuery hook, but leave the
     * 'enabled' flag to false. Doing so prevents the query
     * from running immediately on component mount.
     */
    const { data: states } = useQuery(
        ['country', country],
        async () => {
            console.log('on refetch ', country);
            const result = await externalAxiosInstance.get(
                `/countries/${country?.iso2}/states`
            );
            const sortedResult = result.data.sort(
                (a: { name: string }, b: { name: string }) =>
                    a.name > b.name ? 1 : -1
            );
            setStatesList(sortedResult);
            if (result.data?.length === 0) {
                setDisableDropdown(true);
                resetField('address.stateProvince');
            } else {
                setDisableDropdown(false);
            }
        },
        {
            refetchOnWindowFocus: false,
            enabled: country?.iso2 ? true : false //disable the query:
            //this is how we keep it from running on component mount.
        }
    );

    const { data: cities } = useQuery(
        ['state', selectedState.iso2],
        async () => {
            const result = await externalAxiosInstance.get(
                `/countries/${country?.iso2}/states/${selectedState.iso2}/cities`
            );
            const sortedResult = result.data.sort(
                (a: { name: string }, b: { name: string }) =>
                    a.name > b.name ? 1 : -1
            );
            setCitiesList(sortedResult);
            if (result.data?.length === 0) {
                setDisableCityDropdown(true);
                resetField('address.city');
            } else {
                setDisableCityDropdown(false);
            }
        },
        {
            refetchOnWindowFocus: false,
            enabled: selectedState?.iso2 ? true : false //disable the query:
            //this is how we keep it from running on component mount.
        }
    );

    useEffect(() => {
        console.log(user);
        const subscription = watch((value, { name, type }) =>
            console.log(value, name, type)
        );
        return () => subscription.unsubscribe();
    }, [watch]);

    const onSubmit: SubmitHandler<IPersonalDetailForm> = async (data: any) => {
        if (step === 1) {
            console.log('Data submitted');

            let temp = {
                ...data,
                dateOfBirth: data.dateOfBirth ?? '',
                address: {
                    id: uuidv4(),
                    street1: data.address.streetAddress ?? '',
                    stateProvince: data.address.stateProvince ?? '',
                    zipPostCode: data.address.zipPostCode ?? '',
                    country: data.address.country ?? '',
                    city: data.address.city ?? '',
                    createdAt: data.address.createdAt ?? Date.now(),
                    lastUpdatedAt: Date.now()
                },
                currentEmployment: {
                    jobTitle,
                    companyName: company,
                    startYear: startDate,
                    endYear: endDate,
                    companySize,
                    industry
                },
                professionalProfileId: user?.professionalProfile?.id,
                emailAddress: user?.professionalProfile?.emailAddress
            };
            console.log({ submittedData: temp });
            updateDetails.mutate(temp, {
                onSuccess: (res) => {
                    console.log('onSuccess', res);
                    setType('success');
                    setMessage('Profile updated successfully.');
                    setShowAlert(true);
                    setStep((prev) => prev + 1);
                },
                onError: (error) => {
                    console.log('onError', error);
                    setType('error');
                    setMessage('Error occured please try again!');
                    setShowAlert(true);
                }
            });
        }
        if (step === 2) {
            navigate('/');
        }
    };
    const uploadImage = useUploadProfessionalProfilePhoto();

    const {
        data: searchedData = [],
        isFetching,
        refetch
    } = useGetSearchTerm(search, user?.professionalProfile?.userId ?? '');
    const { data: sentRequests } = useGetConnectionRequestSent(user?.id ?? '');

    const onDrop = useCallback(<T extends File>(acceptedFiles: T[]) => {
        let data = {
            profileId: user?.professionalProfile?.id ?? '',
            file: acceptedFiles[0]
        };
        uploadImage.mutate(data, {
            onSuccess: (res) => {
                setImage(String(res));
                let tempData = {
                    ...user,
                    professionalProfile: {
                        ...user?.professionalProfile,
                        photoUrl: res
                    }
                };
                setUser(tempData);
                setMessage('Profile Image Updated Successfully.');
                setType('success');
                setShowAlert(true);
            },
            onError: (res) => {
                setMessage('Error occured please try again!');
                setType('error');
                setShowAlert(true);
            }
        });
    }, []);
    const { getRootProps, getInputProps, open } = useDropzone({
        onDrop,
        noClick: true
    });

    // Image Input
    const handleChangeFile = (
        files: any,
        setState: React.Dispatch<React.SetStateAction<string>>
    ) => {
        const file = files[0];
        const url = URL.createObjectURL(file);
        setState(url);
    };

    const handleChangePage = (page: number) => {
        setPage(page - 1);
    };

    const handleData = () => {
        setUsers([]);
        if (search?.length > 1) {
            return setUsers(
                searchedData?.filter(
                    (x: IOptionType) => x.entityType === 'PROFESSIONAL'
                )
            );
        }
    };

    useEffect(() => {
        if (user?.professionalProfile) {
            setImage(user?.professionalProfile?.photoUrl ?? null);
        }
    }, [user?.professionalProfile]);

    useEffect(() => {
        if (search?.length > 1) {
            refetch();
        }
        handleData();
    }, [search]);

    useEffect(() => {
        if (search?.length > 1 && !isFetching) {
            handleData();
        }
    }, [isFetching, search]);

    useEffect(() => {
        if (!isValid && isSubmitting) {
            setMessage('Please fill all the required fields!');
            setType('error');
            setShowAlert(true);
        }
    }, [isSubmitting]);

    return (
        <>
            <Box
                sx={{
                    height: 'fit-content',
                    backgroundColor: '#FFE3AD',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    gap: '40px',
                    p: '20px',
                    display: { sm: 'none', xs: 'flex' },
                    mb: 2,
                    mt: '70px'
                }}
            >
                <Box>
                    <Link to={'/'}>
                        <JobFactorIcon />
                    </Link>
                    <Typography color={'#23282B'} component={'p'} mt={'32px'} variant='titleSmallSemiBold'>
                        Step <Typography color={'#07AF22'} fontSize={'14px'} component={'span'} fontWeight={600}>&nbsp;
                            4
                        </Typography>&nbsp;
                        of <b>4</b>
                    </Typography>
                    <Typography color={'#23282B'} variant='titleLargeSemiBold' mt={1} mb={1} component={'p'}>
                        Setup profile
                    </Typography>
                    <SteppedSectionHeader
                        activeStep={1}
                        steps={[
                            { title: 'Profile Wizard', completed: true },
                            {
                                title: 'Connections',
                                completed: sentRequests?.length >= 10 ? true : false
                            }
                        ]}
                    />
                </Box>
            </Box>
            <Container
                sx={{
                    pr: { xl: '40px', sm: '0px', xs: 0 },
                    pl: { xl: '24px', sm: '0px', xs: 0 },
                    marginTop: { lg: 0, sm: '70px', xs: 0 },
                    mb: { sm: 0, xs: '100px' },
                    maxWidth: '100%'
                }}
            >
                <Box px={5} mb={3.5} display={{ sm: 'block', xs: 'none' }}>
                    <SteppedProgress
                        mb={2.5}
                        completedSteps={3}
                        steps={[
                            'Account type',
                            'Create account',
                            'Verify email',
                            'Set-up profile'
                        ]}
                    />

                    <SteppedSectionHeader
                        mb={3.5}
                        activeStep={1}
                        steps={[
                            { title: 'Profile Wizard', completed: true },
                            {
                                title: 'Connections',
                                completed: sentRequests?.length >= 10 ? true : false
                            }
                        ]}
                    />

                    <Grid container alignItems={'center'}>
                        <Grid item flexGrow={1}>
                            <Typography
                                component="h1"
                                variant="headlineMediumBold"
                            >
                                Set up profile
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'end',
                                gap: '16px'
                            }}
                        >
                            <Button
                                sx={{
                                    background: '#F2F2F2',
                                    borderRadius: '8px',
                                    padding: '10px 8px',
                                    maxWidth: '61px',
                                    fontWeight: 600,
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                    letterSpacing: '0.0035em',
                                    color: '#05668D'
                                }}
                                variant="contained"
                                onClick={() =>
                                    step === 2
                                        ? navigate('/')
                                        : setStep((prev) => prev + 1)
                                }
                            >
                                Skip
                            </Button>
                            <Button
                                sx={{
                                    borderRadius: '8px',
                                    padding: '16px 20px',
                                    fontWeight: 600,
                                    fontSize: '16px',
                                    letterSpacing: '0.0015em',
                                    maxWidth: '200px'
                                }}
                                variant="contained"
                                onClick={handleSubmit(onSubmit)}
                            >
                                Next
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                {
                    step === 1 ? (
                        <>
                            <Paper
                                elevation={0}
                                sx={{
                                    borderRadius: 0,
                                    mb: 3.5,
                                }}
                            >
                                <Box px={{ sm: 5, xs: '10px' }} py={1.5}>
                                    <Typography
                                        component="h2"
                                        variant="titleLargeSemiBold"
                                        mb={1}
                                    >
                                        Add a profile picture and video intro.
                                    </Typography>
                                    <Typography
                                        component="p"
                                        fontWeight={400}
                                        fontSize={{ sm: 16, xs: 14 }}
                                        color="#808080"
                                    >
                                        This will be displayed on your profile.
                                    </Typography>
                                </Box>
                                <Divider sx={{ borderColor: '#EDEDED' }} />
                                <Box px={{ sm: 5, xs: '10px' }} py={{ sm: 2.5, xs: '10px' }}>
                                    <Grid container spacing={{ sm: '20px', xs: '10px' }}>
                                        <Grid
                                            item
                                            sm={3}
                                            xs={6}
                                            {...getRootProps()}>
                                            <Box
                                                sx={{
                                                    margin: { sm: 'auto', xs: 'auto' },
                                                    width: 100,
                                                    height: 100,
                                                    borderRadius: '50%',
                                                    bgcolor: '#F2F2F2',
                                                    cursor: 'pointer',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    mb: 2.5
                                                }}
                                                onClick={open}
                                            >
                                                <IconButton
                                                    sx={{
                                                        height: 48,
                                                        width: 48,
                                                        backgroundColor:
                                                            '#FFF'
                                                    }}
                                                >
                                                    <DocumentUploadIcon htmlColor="#05668D" />
                                                </IconButton>
                                            </Box>
                                            <Typography
                                                component="span"
                                                variant="titleMediumSemiBold"
                                                sx={{ cursor: 'pointer', display: { lg: 'block', xs: 'none' } }}
                                                onClick={open}
                                                textAlign={'center'}
                                            >
                                                Click to upload
                                                <input
                                                    type="file"
                                                    ref={imageRef}
                                                    accept="image/png, image/gif, image/jpeg"
                                                    name="image"
                                                    style={{ display: 'none' }}
                                                    {...getInputProps()}
                                                />
                                                &nbsp;
                                                <Typography
                                                    component="span"
                                                    variant="titleSmallSemiBold"
                                                    color="#808080"
                                                >
                                                    or drag and drop
                                                </Typography>&nbsp;
                                                <Typography
                                                    component="span"
                                                    variant="titleSmallSemiBold"
                                                    color="#808080"
                                                >
                                                    SVG, PNG, or JPG ( max 800 &times; 400 )
                                                </Typography>
                                            </Typography>
                                            <Typography
                                                component="span"
                                                variant="titleMediumSemiBold"
                                                sx={{ cursor: 'pointer', display: { lg: 'none', xs: 'block' } }}
                                                fontSize={{ sm: 16, xs: 12 }}
                                                textAlign={'center'}
                                                onClick={open}
                                            >
                                                Click to upload
                                                <input
                                                    type="file"
                                                    ref={imageRef}
                                                    accept="image/png, image/gif, image/jpeg"
                                                    name="image"
                                                    style={{ display: 'none' }}
                                                    {...getInputProps()}
                                                />
                                                &nbsp;
                                                <Typography
                                                    component="span"
                                                    variant="titleSmallSemiBold"
                                                    color="#808080"
                                                    fontSize={{ sm: 16, xs: 12 }}
                                                >
                                                    ( max 800 &times; 400 )
                                                </Typography>
                                            </Typography>
                                        </Grid>

                                        {/* Intro Video */}
                                        <Grid item sm={9} xs={6}>
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    height: '100%',
                                                    bgcolor: '#F2F2F2',
                                                    borderRadius: '8px',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: 2,
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}
                                            >
                                                {videoSrc ? (
                                                    <video
                                                        controls
                                                        src={videoSrc}
                                                        width="100%"
                                                        style={{ aspectRatio: '16/9' }}
                                                    ></video>
                                                ) : (
                                                    <>
                                                        <IconButton
                                                            sx={{
                                                                height: 48,
                                                                width: 48,
                                                                backgroundColor:
                                                                    '#FFF'
                                                            }}
                                                            onClick={() =>
                                                                videoRef.current?.click()
                                                            }
                                                        >
                                                            <DocumentUploadIcon htmlColor="#05668D" />
                                                            <input
                                                                type="file"
                                                                ref={videoRef}
                                                                accept="video/mp4,video/x-m4v,video/*"
                                                                name="video"
                                                                onChange={(e) =>
                                                                    handleChangeFile(
                                                                        e.target.files,
                                                                        setVideoSrc
                                                                    )
                                                                }
                                                                style={{
                                                                    display: 'none'
                                                                }}
                                                            />
                                                        </IconButton>
                                                        <Typography
                                                            component="p"
                                                            fontWeight={600}
                                                            color="#808080"
                                                            fontSize={{ sm: 14, xs: 12 }}
                                                            width="100%"
                                                            textAlign="center"
                                                        >
                                                            Upload an intro video
                                                        </Typography>
                                                    </>
                                                )}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>

                            {/* Personal Details */}
                            <Paper
                                elevation={0}
                                sx={{
                                    borderRadius: 0,
                                    px: { md: 5, xs: '10px' },
                                    py: 1.5,
                                    mb: 3.5
                                }}
                            >
                                <Box mb={1.5}>
                                    <Typography
                                        component="h2"
                                        fontWeight={600}
                                        fontSize={20}
                                        mb={1}
                                    >
                                        Personal Details
                                    </Typography>
                                    <Typography
                                        component="p"
                                        fontWeight={400}
                                        fontSize={16}
                                        color="#808080"
                                    >
                                        Fields with * are required fields.
                                    </Typography>
                                </Box>
                                <Divider sx={{ borderColor: '#EDEDED', mb: { sm: '32px', xs: '20px' } }} />
                                <Grid container spacing={{ sm: '32px', xs: '20px' }}>
                                    {/* First Name */}
                                    <Grid item sm={6} xs={12}>
                                        <Box
                                            sx={{ position: 'relative' }}
                                        >
                                            <InputLabel
                                                sx={{
                                                    color: '#747474',
                                                    fontSize: { sm: '14px', xs: '12px' },
                                                    position: 'absolute',
                                                    top: '7px',
                                                    left: '75px',
                                                    zIndex: 1,
                                                    fontFamily: 'Open Sans'
                                                }}
                                                required
                                            >
                                                First name
                                            </InputLabel>
                                            <Controller
                                                {...register('firstName', {
                                                    required: 'Required field'
                                                })}
                                                name="firstName"
                                                control={control}
                                                render={({
                                                    field: { onChange, value, ref, onBlur },
                                                    fieldState: { error },
                                                    formState
                                                }) => (
                                                    <InputBase
                                                        required
                                                        value={value}
                                                        onChange={(e) => {
                                                            onChange(e);
                                                            if (!e.target.value) {
                                                                setError('firstName', {
                                                                    type: 'required',
                                                                    message:
                                                                        'Please provide your first name'
                                                                });
                                                            } else {
                                                                clearErrors('firstName');
                                                            }
                                                        }}
                                                        id="firstName"
                                                        placeholder="Please enter your first name"
                                                        startAdornment={<UserFormIcon />}
                                                        rows={1}
                                                        sx={{
                                                            backgroundColor: '#FCFBF8',
                                                            width: '100%',
                                                            height: { sm: '70px', xs: '60px' },
                                                            padding: '0px 16px',
                                                            fontFamily: 'open sans',
                                                            fontSize: { sm: '16px', xs: '14px' },
                                                            color: '#23282B',
                                                            borderBottom:
                                                                '1px solid #D9D9D9',
                                                            '& 	.MuiInputBase-input': {
                                                                ml: '20px',
                                                                position: 'relative',
                                                                top: '8px'
                                                            }
                                                        }}
                                                    />
                                                )}
                                            />
                                            <Typography
                                                sx={{
                                                    color: 'red',
                                                    fontSize: '12px',
                                                    fontFamily: 'Open Sans'
                                                }}
                                            >
                                                {errors.firstName?.message}
                                            </Typography>
                                        </Box>
                                    </Grid>

                                    {/* Last Name */}
                                    <Grid item sm={6} xs={12}>
                                        <Box
                                            sx={{ position: 'relative' }}
                                        >
                                            <InputLabel
                                                sx={{
                                                    color: '#747474',
                                                    fontSize: { sm: '14px', xs: '12px' },
                                                    position: 'absolute',
                                                    top: '7px',
                                                    left: '75px',
                                                    zIndex: 1,
                                                    fontFamily: 'Open Sans'
                                                }}
                                                required
                                            >
                                                Last name
                                            </InputLabel>
                                            <Controller
                                                {...register('lastName', {
                                                    required: 'Required field'
                                                })}
                                                name="lastName"
                                                control={control}
                                                render={({
                                                    field: { onChange, value, ref, onBlur },
                                                    fieldState: { error },
                                                    formState
                                                }) => (
                                                    <InputBase
                                                        required
                                                        value={value}
                                                        onChange={(e) => {
                                                            onChange(e);
                                                            if (!e.target.value) {
                                                                setError('lastName', {
                                                                    type: 'required',
                                                                    message:
                                                                        'Please provide your last name'
                                                                });
                                                            } else {
                                                                clearErrors('lastName');
                                                            }
                                                        }}
                                                        id="lastName"
                                                        placeholder="Please enter your last name"
                                                        startAdornment={<Building />}
                                                        rows={1}
                                                        sx={{
                                                            backgroundColor: '#FCFBF8',
                                                            width: '100%',
                                                            height: { sm: '70px', xs: '60px' },
                                                            padding: '0px 16px',
                                                            fontFamily: 'open sans',
                                                            color: '#23282B',
                                                            fontSize: { sm: '16px', xs: '14px' },
                                                            borderBottom:
                                                                '1px solid #D9D9D9',
                                                            '& 	.MuiInputBase-input': {
                                                                ml: '20px',
                                                                position: 'relative',
                                                                top: '8px'
                                                            }
                                                        }}
                                                    />
                                                )}
                                            />
                                            <Typography
                                                sx={{
                                                    color: 'red',
                                                    fontSize: '12px',
                                                    fontFamily: 'Open Sans'
                                                }}
                                            >
                                                {errors.lastName?.message}
                                            </Typography>
                                        </Box>
                                    </Grid>

                                    {/* Gender */}
                                    <Grid
                                        item
                                        sm={6} xs={12}
                                    >
                                        <Box
                                            sx={{
                                                position: 'relative',
                                                '& .MuiInputBase-root::before': {
                                                    borderColor: '#D9D9D9'
                                                },
                                                '& .MuiInputBase-root::after': {
                                                    borderBottom: '1px solid #D9D9D9'
                                                },
                                            }}
                                        >
                                            <InputLabel
                                                sx={{
                                                    color: '#747474',
                                                    fontSize: { sm: '14px', xs: '12px' },
                                                    position: 'absolute',
                                                    top: '7px',
                                                    left: '75px',
                                                    zIndex: 1,
                                                    fontFamily: 'Open Sans'
                                                }}
                                            >
                                                Gender
                                            </InputLabel>

                                            <Controller
                                                {...register('gender')}
                                                name="gender"
                                                control={control}
                                                render={({
                                                    field: { onChange, value },
                                                    fieldState: { error },
                                                    formState
                                                }) => (
                                                    <Select
                                                        label={value}
                                                        onChange={(e) => {
                                                            onChange(e);
                                                            if (!e.target.value) {
                                                                setError('gender', {
                                                                    type: 'required',
                                                                    message:
                                                                        'Please choose an option'
                                                                });
                                                            } else {
                                                                clearErrors('gender');
                                                            }
                                                        }}
                                                        fullWidth
                                                        variant="filled"
                                                        IconComponent={() => <Dropdown />}
                                                        sx={{
                                                            backgroundColor: '#FCFBF8',
                                                            width: '100%',
                                                            height: { sm: '70px', xs: '60px' },
                                                            padding: '0px 16px',
                                                            fontSize: { sm: '16px', xs: '14px' },
                                                            color: '#23282B',
                                                            '& 	.MuiInputBase-input': {
                                                                ml: '20px',
                                                                position: 'relative'
                                                            },
                                                            '& 	.MuiInputBase-input:focus': {
                                                                backgroundColor:
                                                                    'transparent'
                                                            }
                                                        }}
                                                        renderValue={(e) => <p>{value}</p>}
                                                        startAdornment={<UserCircle />}
                                                        placeholder="Select One"
                                                        defaultValue={''}
                                                    >
                                                        <MenuItem key="male" value="Male">
                                                            <Radio
                                                                checked={value === 'Male'}
                                                            />{' '}
                                                            Male
                                                        </MenuItem>
                                                        <MenuItem
                                                            key="female"
                                                            value="Female"
                                                        >
                                                            <Radio
                                                                checked={value === 'Female'}
                                                            />{' '}
                                                            Female
                                                        </MenuItem>
                                                        <MenuItem
                                                            key="other"
                                                            value="Other/Decline to share"
                                                        >
                                                            <Radio
                                                                checked={value === 'Other'}
                                                            />{' '}
                                                            Other
                                                        </MenuItem>
                                                    </Select>
                                                )}
                                            />
                                            <Typography
                                                sx={{
                                                    color: 'red',
                                                    fontSize: '12px',
                                                    fontFamily: 'Open Sans'
                                                }}
                                            >
                                                {errors.gender?.message}
                                            </Typography>
                                        </Box>
                                    </Grid>

                                    {/* Date Of Birth */}
                                    <Grid
                                        item
                                        sm={6} xs={12}
                                    >
                                        <Controller
                                            {...register('dateOfBirth')}
                                            name="dateOfBirth"
                                            control={control}
                                            render={({
                                                field: { onChange, value, ref, onBlur },
                                                fieldState: { error },
                                                formState
                                            }) => (
                                                <Calendar
                                                    label="Date of birth"
                                                    handleChange={(
                                                        e: dayjs.Dayjs | null
                                                    ) => {
                                                        onChange(
                                                            dayjs(e).format(
                                                                'MM/DD/YYYY'
                                                            )
                                                        );
                                                        console.log(e);
                                                        if (!e) {
                                                            setError('dateOfBirth', {
                                                                type: 'required',
                                                                message:
                                                                    'Please select your date of birth'
                                                            });
                                                        } else {
                                                            clearErrors('dateOfBirth');
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                        <Typography
                                            sx={{
                                                color: 'red',
                                                fontSize: '12px',
                                                fontFamily: 'Open Sans'
                                            }}
                                        >
                                            {errors.dateOfBirth?.message}
                                        </Typography>
                                    </Grid>

                                    {/* Summary */}
                                    <Grid item xs={12}>
                                        <Box
                                            sx={{ position: 'relative' }}
                                        >
                                            <InputLabel
                                                sx={{
                                                    color: '#747474',
                                                    fontSize: { sm: '14px', xs: '12px' },
                                                    position: 'absolute',
                                                    top: '7px',
                                                    left: '72px',
                                                    width: '-webkit-fill-available',
                                                    zIndex: 1,
                                                    fontFamily: 'Open Sans',
                                                    mr: { sm: 0, xs: '20px' }
                                                }}
                                            >
                                                Professional tagline or summary
                                            </InputLabel>
                                            <Controller
                                                {...register('tagline')}
                                                name="tagline"
                                                control={control}
                                                render={({
                                                    field: { onChange, value, ref, onBlur },
                                                    fieldState: { error },
                                                    formState
                                                }) => (
                                                    <InputBase
                                                        value={value}
                                                        onChange={(e) => {
                                                            onChange(e);
                                                        }}
                                                        id="tagline"
                                                        placeholder="Please enter your professional tagline or summary "
                                                        startAdornment={<SummaryForm />}
                                                        rows={1}
                                                        sx={{
                                                            backgroundColor: '#FCFBF8',
                                                            width: '100%',
                                                            height: { sm: '70px', xs: '60px' },
                                                            padding: '0px 16px',
                                                            fontFamily: 'open sans',
                                                            fontSize: { sm: '16px', xs: '14px' },
                                                            color: '#23282B',
                                                            borderBottom:
                                                                '1px solid #D9D9D9',
                                                            '& 	.MuiInputBase-input': {
                                                                ml: '20px',
                                                                position: 'relative',
                                                                top: '8px'
                                                            }
                                                        }}
                                                    />
                                                )}
                                            />
                                            <Typography
                                                sx={{
                                                    color: 'red',
                                                    fontSize: '12px',
                                                    fontFamily: 'Open Sans'
                                                }}
                                            >
                                                {errors.tagline?.message}
                                            </Typography>
                                        </Box>
                                    </Grid>

                                    {/* Country */}
                                    <Grid
                                        item
                                        sm={4} xs={12}
                                    >
                                        <Box
                                            sx={{
                                                position: 'relative',
                                                '& .MuiInputBase-root::before': {
                                                    borderColor: '#D9D9D9'
                                                },
                                                '& .MuiInputBase-root::after': {
                                                    borderBottom: '1px solid #D9D9D9'
                                                }
                                            }}
                                        >
                                            <InputLabel
                                                sx={{
                                                    color: '#747474',
                                                    fontSize: { sm: '14px', xs: '12px' },
                                                    position: 'absolute',
                                                    top: '7px',
                                                    left: '75px',
                                                    zIndex: 1,
                                                    fontFamily: 'Open Sans'
                                                }}
                                            >
                                                Country
                                            </InputLabel>

                                            <Controller
                                                {...register('address.country')}
                                                name="address.country"
                                                control={control}
                                                render={({
                                                    field: { onChange, value },
                                                    fieldState: { error },
                                                    formState
                                                }) => (
                                                    <Select
                                                        label={value}
                                                        onChange={(e) => {
                                                            onChange(e);
                                                            setCountry({
                                                                iso2: e.target.value
                                                            });
                                                            setSelectedState({
                                                                value: '',
                                                                iso2: ''
                                                            });
                                                            setSelectedCity('');
                                                            resetField(
                                                                'address.stateProvince'
                                                            );
                                                            resetField('address.city');
                                                            if (statesList?.length === 0) {
                                                                clearErrors(
                                                                    'address.stateProvince'
                                                                );
                                                            }
                                                        }}
                                                        fullWidth
                                                        variant="filled"
                                                        IconComponent={() => <Dropdown />}
                                                        sx={{
                                                            backgroundColor: '#FCFBF8',
                                                            width: '100%',
                                                            height: { sm: '70px', xs: '60px' },
                                                            fontSize: { sm: '16px', xs: '14px' },
                                                            padding: '0px 16px',
                                                            color: '#23282B',
                                                            '& 	.MuiInputBase-input': {
                                                                ml: '20px',
                                                                position: 'relative'
                                                            },
                                                            '& 	.MuiInputBase-input:focus': {
                                                                backgroundColor:
                                                                    'transparent'
                                                            }
                                                        }}
                                                        renderValue={(e) => (
                                                            <p>{value || ''}</p>
                                                        )}
                                                        startAdornment={<Flag />}
                                                        defaultValue={''}
                                                    >
                                                        {countryList?.map(
                                                            (country: any) => (
                                                                <MenuItem
                                                                    key={country.id}
                                                                    value={country.iso2}
                                                                >
                                                                    <Radio
                                                                        checked={
                                                                            value ===
                                                                            country.iso2 ||
                                                                            false
                                                                        }
                                                                    />{' '}
                                                                    {country.name} (
                                                                    {country.iso2})
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                )}
                                            />
                                            <Typography
                                                sx={{
                                                    color: 'red',
                                                    fontSize: '12px',
                                                    fontFamily: 'Open Sans'
                                                }}
                                            >
                                                {errors.address?.country?.message}
                                            </Typography>
                                        </Box>
                                    </Grid>

                                    {/* State */}
                                    <Grid
                                        item
                                        sm={4} xs={12}
                                    >
                                        <Box
                                            sx={{
                                                position: 'relative',
                                                '& .MuiInputBase-root::before': {
                                                    borderColor: '#D9D9D9'
                                                },
                                                '& .MuiInputBase-root::after': {
                                                    borderBottom: '1px solid #D9D9D9'
                                                },
                                                '.MuiSelect-select': {
                                                    pl: '2px'
                                                }
                                            }}
                                        >
                                            <InputLabel
                                                sx={{
                                                    color: '#747474',
                                                    fontSize: { sm: '14px', xs: '12px' },
                                                    position: 'absolute',
                                                    top: '7px',
                                                    left: '20px',
                                                    zIndex: 1,
                                                    fontFamily: 'Open Sans'
                                                }}
                                            >
                                                State
                                            </InputLabel>

                                            <Controller
                                                {...register('address.stateProvince')}
                                                name="address.stateProvince"
                                                control={control}
                                                render={({
                                                    field: { onChange, value },
                                                    fieldState: { error },
                                                    formState
                                                }) => (
                                                    <Select
                                                        label={value}
                                                        onChange={(e) => {
                                                            onChange(e);
                                                            setSelectedCity('');
                                                            resetField('address.city');
                                                        }}
                                                        fullWidth
                                                        variant="filled"
                                                        IconComponent={() => <Dropdown />}
                                                        sx={{
                                                            backgroundColor: '#FCFBF8',
                                                            width: '100%',
                                                            height: { sm: '70px', xs: '60px' },
                                                            padding: '0px 16px',
                                                            fontSize: { sm: '16px', xs: '14px' },
                                                            color: '#23282B',
                                                            '& 	.MuiInputBase-input:focus': {
                                                                backgroundColor:
                                                                    'transparent'
                                                            }
                                                        }}
                                                        renderValue={(e) => (
                                                            <p>
                                                                {selectedState.value || ''}
                                                            </p>
                                                        )}
                                                        defaultValue={''}
                                                        disabled={disableStateDropDown}
                                                    >
                                                        {statesList?.map((state: any) => (
                                                            <MenuItem
                                                                key={state.id}
                                                                value={state.name}
                                                                onClick={() =>
                                                                    setSelectedState({
                                                                        value: state.name,
                                                                        iso2: state.iso2
                                                                    })
                                                                }
                                                            >
                                                                <Radio
                                                                    checked={
                                                                        value === state.name
                                                                    }
                                                                />{' '}
                                                                {state.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                )}
                                            />
                                            <Typography
                                                sx={{
                                                    color: 'red',
                                                    fontSize: '12px',
                                                    fontFamily: 'Open Sans'
                                                }}
                                            >
                                                {errors.address?.stateProvince?.message}
                                            </Typography>
                                        </Box>
                                    </Grid>

                                    {/* City */}
                                    <Grid
                                        item
                                        sm={4} xs={12}
                                    >
                                        <Box
                                            sx={{
                                                position: 'relative',
                                                '& .MuiInputBase-root::before': {
                                                    borderColor: '#D9D9D9'
                                                },
                                                '& .MuiInputBase-root::after': {
                                                    borderBottom: '1px solid #D9D9D9'
                                                },
                                                '.MuiInputBase-root': {
                                                    height: '70px'
                                                },
                                            }}
                                        >
                                            <InputLabel
                                                sx={{
                                                    color: '#747474',
                                                    fontSize: { sm: '14px', xs: '12px' },
                                                    position: 'absolute',
                                                    top: '7px',
                                                    left: '17px',
                                                    zIndex: 1,
                                                    fontFamily: 'Open Sans'
                                                }}
                                            >
                                                City
                                            </InputLabel>
                                            <Controller
                                                {...register('address.city')}
                                                name="address.city"
                                                control={control}
                                                render={({
                                                    field: { onChange, value, ref, onBlur },
                                                    fieldState: { error },
                                                    formState
                                                }) => (
                                                    <Select
                                                        value={value}
                                                        onChange={(e) => {
                                                            onChange(e);
                                                            console.log(e.target);
                                                            setSelectedCity(e.target.value);
                                                            if (citiesList?.length === 0) {
                                                                clearErrors('address.city');
                                                            }
                                                        }}
                                                        fullWidth
                                                        variant="filled"
                                                        IconComponent={() => <Dropdown />}
                                                        sx={{
                                                            backgroundColor: '#FCFBF8',
                                                            width: '100%',
                                                            height: { sm: '70px', xs: '60px' },
                                                            fontSize: { sm: '16px', xs: '14px' },
                                                            padding: '0px 16px',
                                                            color: '#23282B',
                                                            pl: '5px',
                                                            '& 	.MuiInputBase-input': {
                                                                // top: '8px',
                                                                position: 'relative'
                                                            },
                                                            '& 	.MuiInputBase-input:focus': {
                                                                backgroundColor:
                                                                    'transparent'
                                                            }
                                                        }}
                                                        renderValue={(e) => (
                                                            <p>{selectedCity || ''}</p>
                                                        )}
                                                        defaultValue={''}
                                                        disabled={disableCityDropDown}
                                                    >
                                                        {citiesList?.map((city: any) => (
                                                            <MenuItem
                                                                key={city.id}
                                                                value={city.name}
                                                            >
                                                                <Radio
                                                                    checked={
                                                                        value === city.name
                                                                    }
                                                                />{' '}
                                                                {city.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                )}
                                            />
                                            <Typography
                                                sx={{
                                                    color: 'red',
                                                    fontSize: '12px',
                                                    fontFamily: 'Open Sans'
                                                }}
                                            >
                                                {errors.address?.city?.message}
                                            </Typography>
                                        </Box>
                                    </Grid>

                                    {/* Street Address 1 */}
                                    <Grid
                                        item
                                        sm={9}
                                        xs={12}
                                    >
                                        <Box
                                            sx={{
                                                '.MuiInputBase-root': {
                                                    height: '70px'
                                                },
                                                position: 'relative'
                                            }}
                                        >
                                            <InputLabel
                                                sx={{
                                                    color: '#747474',
                                                    fontSize: { sm: '14px', xs: '12px' },
                                                    position: 'absolute',
                                                    top: '7px',
                                                    left: '14px',
                                                    zIndex: 1,
                                                    fontFamily: 'Open Sans'
                                                }}
                                            >
                                                Street address 1
                                            </InputLabel>
                                            <Controller
                                                {...register('address.streetAddress')}
                                                name="address.streetAddress"
                                                control={control}
                                                render={({
                                                    field: { onChange, value, ref, onBlur },
                                                    fieldState: { error },
                                                    formState
                                                }) => (
                                                    <InputBase
                                                        required
                                                        onChange={(e) => onChange(e)}
                                                        placeholder="Please enter your street address"
                                                        rows={1}
                                                        sx={{
                                                            backgroundColor: '#FCFBF8',
                                                            width: '100%',
                                                            height: { sm: '70px', xs: '60px' },
                                                            padding: '0px 16px',
                                                            fontFamily: 'open sans',
                                                            fontSize: { sm: '16px', xs: '14px' },
                                                            color: '#23282B',
                                                            borderBottom:
                                                                '1px solid #D9D9D9',
                                                            '& 	.MuiInputBase-input': {
                                                                position: 'relative',
                                                                top: '10px'
                                                            }
                                                        }}
                                                    />
                                                )}
                                            />
                                            <Typography
                                                sx={{
                                                    color: 'red',
                                                    fontSize: '12px',
                                                    fontFamily: 'Open Sans'
                                                }}
                                            >
                                                {errors.address?.streetAddress?.message}
                                            </Typography>
                                        </Box>
                                    </Grid>

                                    {/* Zip Code */}
                                    <Grid
                                        item
                                        sm={3} xs={12}
                                    >
                                        <Box
                                            sx={{
                                                '.MuiInputBase-root': {
                                                    height: '70px'
                                                },
                                                position: 'relative'
                                            }}
                                        >
                                            <InputLabel
                                                sx={{
                                                    color: '#747474',
                                                    fontSize: { sm: '14px', xs: '12px' },
                                                    position: 'absolute',
                                                    top: '7px',
                                                    left: '14px',
                                                    zIndex: 1,
                                                    fontFamily: 'Open Sans'
                                                }}
                                                required
                                            >
                                                Zipcode
                                            </InputLabel>
                                            <Controller
                                                {...register('address.zipPostCode', {
                                                    required: '*'
                                                })}
                                                name="address.zipPostCode"
                                                control={control}
                                                render={({
                                                    field: { onChange, value, ref, onBlur },
                                                    fieldState: { error },
                                                    formState
                                                }) => (
                                                    <InputBase
                                                        required
                                                        value={value}
                                                        onChange={(e) => {
                                                            onChange(e);
                                                        }}
                                                        id="address.zipPostCode"
                                                        fullWidth
                                                        sx={{
                                                            backgroundColor: '#FCFBF8',
                                                            width: '100%',
                                                            height: { sm: '70px', xs: '60px' },
                                                            fontSize: { sm: '16px', xs: '14px' },
                                                            fontWeight: 400,
                                                            padding: '0px 16px',
                                                            fontFamily: 'open sans',
                                                            color: '#23282B',
                                                            borderBottom:
                                                                '1px solid #C5C5C5',
                                                            // mb: '20px',
                                                            '& 	.MuiInputBase-input': {
                                                                position: 'relative',
                                                                top: '15px'
                                                            }
                                                        }}
                                                        rows={1}
                                                    />
                                                )}
                                            />
                                        </Box>
                                    </Grid>

                                    {/* Bio */}
                                    <Grid item xs={12}>
                                        <Box
                                            sx={{ position: 'relative' }}
                                        >
                                            <InputLabel
                                                sx={{
                                                    color: '#747474',
                                                    fontSize: { sm: '14px', xs: '12px' },
                                                    position: 'absolute',
                                                    top: '7px',
                                                    left: '20px',
                                                    zIndex: 1,
                                                    fontFamily: 'Open Sans',
                                                    display: 'inline-block',
                                                    maxWidth: '-webkit-fill-available',
                                                    mr: { sm: 0, xs: '20px' },
                                                    '& svg': {
                                                        minWidth: '40px',
                                                        mb: '-5px',
                                                        mr: '20px'
                                                    }
                                                }}
                                            >
                                                <SummaryForm /> Short Bio: tell us a little about yourself
                                            </InputLabel>
                                            <Controller
                                                {...register('bio')}
                                                name="bio"
                                                control={control}
                                                render={({
                                                    field: { onChange, value, ref, onBlur },
                                                    fieldState: { error },
                                                    formState
                                                }) => (
                                                    <InputBase
                                                        onChange={(e) => {
                                                            onChange(e);
                                                        }}
                                                        multiline
                                                        minRows={3}
                                                        sx={{
                                                            backgroundColor: '#FCFBF8',
                                                            width: '100%',
                                                            padding: '0px 16px',
                                                            fontFamily: 'open sans',
                                                            fontSize: { sm: '16px', xs: '14px' },
                                                            color: '#23282B',
                                                            borderBottom:
                                                                '1px solid #D9D9D9',
                                                            '& 	.MuiInputBase-input': {
                                                                pl: '70px',
                                                                position: 'relative',
                                                                top: '28px',
                                                                pb: '40px'
                                                            },
                                                            mb: '20px',
                                                            '& textarea': {
                                                                width: { sm: '100%', xs: '80%' }
                                                            }
                                                        }}
                                                    />
                                                )}
                                            />
                                            <Typography
                                                sx={{
                                                    color: 'red',
                                                    fontSize: '12px',
                                                    fontFamily: 'Open Sans'
                                                }}
                                            >
                                                {errors.bio?.message}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </>
                    ) : step === 2 ? (
                        <>
                            <Paper
                                elevation={0}
                                sx={{
                                    borderRadius: 0,
                                    mb: { sm: 0, xs: '11px' },
                                }}
                            >
                                <Box px={{ sm: 5, xs: '20px' }} py={{ sm: 1.5, xs: '10px' }}>
                                    <Typography
                                        component="h2"
                                        color={'#23282B'}
                                        variant="titleLargeRegular"
                                        fontSize={{ sm: 20, xs: 16 }}
                                    >
                                        Add at least 10 connections
                                    </Typography>
                                </Box>
                                <Divider sx={{ borderColor: '#EDEDED', display: { sm: 'block', xs: 'none' } }} />
                                <Box
                                    sx={{
                                        p: { sm: '28px 40px', xs: '6px 10px 10px' },
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: { lg: '50px', sm: '20px', xs: '15px' }
                                    }}
                                >
                                    <TextField
                                        variant="filled"
                                        onChange={(e) => setSearch(e.target.value)}
                                        value={search}
                                        fullWidth
                                        placeholder="Search by title, skill or company"
                                        InputProps={{
                                            startAdornment: <SearchIcon />
                                        }}
                                        sx={{
                                            '& .MuiInputBase-root': {
                                                backgroundColor: '#FCFBF8',
                                                borderRadius: '8px',
                                                fontFamily: 'open sans',
                                                color: '#23282B',
                                                fontSize: { sm: 16, xs: 14 },
                                                borderBottom: 0,
                                                pl: { sm: '40px', xs: 0 },
                                                pr: { sm: '30px', xs: 0 },
                                            },
                                            '& .MuiInputBase-root::before, .MuiInputBase-root::after': {
                                                borderBottom: '0 !important'
                                            },
                                            '& .MuiInputBase-root svg': {
                                                minWidth: '20px',
                                                minHeight: '20px',
                                                ml: { sm: 0, xs: '10px' }
                                            },
                                            '& input': {
                                                p: '12px 10px'
                                            }
                                        }}
                                    />
                                    <Button
                                        variant="contained"
                                        onClick={() =>
                                            (inviteDialogSignal.value = true)
                                        }
                                        sx={{
                                            width: { md: '150px', sm: '91px', xs: '70px' },
                                            height: 'auto',
                                            fontSize: { sm: 16, xs: 14 },
                                            color: '#05668D',
                                            borderRadius: '8px',
                                            border: '1px solid #05668D',
                                            background: '#FCFBF8',
                                        }}
                                    >
                                        Invite
                                    </Button>
                                </Box>
                            </Paper>
                            {isFetching ? (
                                <ConnectionLoader />
                            ) : search?.length > 1 && !isFetching ? (
                                <Box
                                    component={'div'}
                                    mx={{ sm: 0, xs: '10px' }}
                                >
                                    <TableWrapper
                                        handleChangePage={handleChangePage}
                                        rowsPerPage={rowsPerPage}
                                        data={users}
                                    >
                                        {users
                                            ?.filter(
                                                (connection: any) =>
                                                    connection?.id !==
                                                    user?.professionalProfile?.id
                                            )
                                            ?.slice(
                                                page * rowsPerPage,
                                                page * rowsPerPage + rowsPerPage
                                            )
                                            ?.map((user: IOptionType) => (
                                                <UsersRow
                                                    user={user}
                                                    setType={setType}
                                                    key={`user_${user?.id}`}
                                                    setMessage={setMessage}
                                                    setShowAlert={setShowAlert}
                                                />
                                            ))}
                                    </TableWrapper>
                                </Box>
                            ) : null}
                            {search?.length === 0 ? (
                                <TableWrapper
                                    handleChangePage={handleChangePage}
                                    rowsPerPage={rowsPerPage}
                                    data={sentRequests}
                                    containerStyle={{
                                        boxShadow: 'none',
                                    }}
                                >
                                    {sentRequests
                                        ?.slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        ?.map(
                                            ({ destinationUser }: IAddedUsers) => (
                                                <UsersRow
                                                    user={{
                                                        id: destinationUser?.profileId!,
                                                        term: `${destinationUser?.firstName} ${destinationUser?.lastName}`,
                                                        userId: destinationUser?.userId,
                                                        isAdded: true,
                                                        imageUrl:
                                                            destinationUser?.photoUrl,
                                                        entityType: 'PROFESSIONAL',
                                                        currentEmployment:
                                                            destinationUser?.currentEmployment,
                                                        score: destinationUser?.score
                                                    }}
                                                    setType={setType}
                                                    key={`user_${user?.id}`}
                                                    setMessage={setMessage}
                                                    setShowAlert={setShowAlert}
                                                />
                                            )
                                        )}
                                </TableWrapper>
                            ) : null}
                        </>
                    ) : null
                }
            </Container>
            <Box
                component={'div'}
                sx={{
                    backgroundColor: '#F2F2F2',
                    p: '16px 20px',
                    position: 'fixed',
                    left: 0,
                    right: 0,
                    bottom: 50,
                    display: { sm: 'none', xs: 'flex' },
                    zIndex: 999
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Button
                            sx={{
                                background: '#FAFAFA',
                                borderRadius: '8px',
                                padding: '14px 16px',
                                fontWeight: 600,
                                height: '100%',
                                fontSize: '14px',
                                color: '#05668D'
                            }}
                            variant="contained"
                            onClick={() =>
                                step === 2
                                    ? navigate('/')
                                    : setStep((prev) => prev + 1)
                            }
                        >
                            Skip
                        </Button>
                    </Grid>
                    <Grid
                        item
                        xs={8}
                    >
                        <Button
                            sx={{
                                border: 0,
                                borderRadius: '8px',
                                padding: '14px 16px',
                                fontWeight: 600,
                                fontSize: { sm: '16px', xs: 14 },
                                height: '100%'
                            }}
                            variant="contained"
                            onClick={handleSubmit(onSubmit)}
                        >
                            Next
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <SnackAlert
                open={showAlert}
                handleClose={() => setShowAlert(false)}
                message={message}
                type={type}
            />
        </>
    );
}

export default ProfileWizard;

interface IUserType {
    user: IOptionType;
    setType: React.Dispatch<
        React.SetStateAction<'success' | 'info' | 'warning' | 'error'>
    >;
    setMessage: React.Dispatch<React.SetStateAction<string>>;
    setShowAlert: React.Dispatch<React.SetStateAction<boolean>>;
}

const UsersRow = ({ user, setType, setMessage, setShowAlert }: IUserType) => {
    const queryClient = useQueryClient();
    const { user: currentUser } = useAuth();
    const sendConnectRequest = useSendConnectionRequest();
    const [isAdded, setIsAdded] = useState(user.isAdded ?? false);

    const sendConnectionRequest = () => {
        const data = {
            sourceUserId: currentUser?.professionalProfile?.userId ?? '',
            destinationUserId: user.userId
        };
        sendConnectRequest.mutate(data, {
            onSuccess: (res) => {
                setIsAdded(true);
                setType('success');
                setMessage('Request sent successfully.');
                setShowAlert(true);
                queryClient.invalidateQueries(
                    QueryKeys.RetrieveConnectionRequestSent
                );
            },
            onError: (err: AxiosError) => {
                if (axios.isAxiosError(err)) {
                    setType('error');
                    setMessage(
                        err.message ?? 'Error occured please try again!'
                    );
                    setShowAlert(true);
                }
            }
        });
    };

    return (
        <TableRow>
            <TableCell
                sx={{
                    padding: { sm: '40px 32px', xs: '15px 10px' },
                    paddingBottom: '25px'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'inset',
                        gap: { sm: '20px', xs: '12px' }
                    }}
                >
                    <UserDetails user={user} />
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: { sm: 'flex-end', xs: 'flex-start' },
                            gap: '20px',
                            width: { sm: '100%', xs: 'auto' },
                            justifyContent: 'flex-end',
                            '& .Mui-disabled': {
                                background: '#EDEDED',
                                color: '#808080',
                                borderColor: '#EDEDED'
                            }
                        }}
                    >
                        <Button
                            sx={{
                                borderRadius: '8px',
                                background: '#05668D',
                                padding: { sm: '10px 36px', xs: '5px 15px' },
                                border: '1px solid #05668D',
                                color: '#FFFFFF',
                                fontSize: { sm: '14px', xs: '12px' },
                                fontWeight: '700',
                                textTransform: 'capitalize',
                                width: { sm: '120px', xs: 'auto' },
                                minWidth: { sm: '120px', xs: 'auto' },
                                boxShadow: 'none',
                                textDecoration: 'none',
                                ':hover': {
                                    background: '#05668D',
                                    color: '#FFFF'
                                }
                            }}
                            onClick={sendConnectionRequest}
                            disabled={isAdded}
                        >
                            {isAdded ? 'Added' : 'Add'}
                        </Button>
                    </Box>
                </Box>
                <Box
                    sx={{
                        ml: { sm: '77px', xs: '50px' },
                        mt: '12px',
                        display: { sm: 'block', xs: 'none' }
                    }}
                >
                    <ConnectionSkills id={user?.id} count={3} />
                </Box>

            </TableCell>
        </TableRow>
    );
};
// const experienceLevels = [
//     { background: '#E75541', title: 'Beginner', shortForm: 'B' },
//     { background: '#F6C70E', title: 'Mobile Int', shortForm: 'E' },
//     {
//         background: '#49B6FF',
//         title: 'Customer Experience Design',
//         shortForm: 'A'
//     },
//     { background: '#95C97A', title: 'Expert', shortForm: 'X' }
// ];
