import { Box, Skeleton } from '@mui/material';
import React from 'react';

const JobLoader = () => {
    return (
        <>
            {[1, 2, 3, 4].map((i) => (
                <Box
                    width="400px"
                    height="300px"
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                >
                    <Skeleton variant="text" height="156px" width="100%" />
                    <Box>
                        <Skeleton variant="text" height="30px" width="300px" />
                        <Box display="flex" gap="10px">
                            {[1, 2, 3].map((i) => (
                                <Skeleton
                                    variant="text"
                                    height="20px"
                                    width="50px"
                                />
                            ))}
                        </Box>
                        <Skeleton variant="text" height="10px" width="100px" />
                    </Box>
                    <Box display="flex" justifyContent="space-around">
                        <Skeleton variant="text" height="30px" width="30%" />
                        <Skeleton variant="text" height="30px" width="60%" />
                    </Box>
                </Box>
            ))}
        </>
    );
};

export default JobLoader;
