import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { IJobItem } from '../types/IJobItem';
import JobsHeader from '../components/JobsHeader';
import JobsList from '../components/JobsList/JobsList';
import { useAuth } from '../../../utils/context/AuthContext';
import { useGetAppliedJobs } from '../../../utils/hooks/api/jobs/useGetAppliedJobs';
import MobileNav from '../../../components/MobileNav/MobileNav';

function AppliedJobs() {
    const { user } = useAuth();
    const [filteredJobs, setFilteredJobs] = useState<IJobItem[]>([]);
    const professionalProfileId = user?.professionalProfile?.id ?? '';

    const { data: jobs, isFetching } = useGetAppliedJobs(professionalProfileId);

    useEffect(() => {
        if (jobs) {
            setFilteredJobs(jobs);
        } else {
            setFilteredJobs([]);
        }
    }, [jobs]);

    const route = [
        {
            label: 'All Jobs',
            route: '/my-jobs'
        },
        {
            label: 'Saved Jobs',
            route: '/save-job'
        },
        {
            label: 'Job Preferences',
            route: '/job-preference'
        },
        {
            label: 'Applied Jobs',
            route: '/job-applied'
        },
        {
            label: 'Career Planning',
            route: '#',
            disable: true
        }
    ];

    return (
        <Box
            sx={{
                marginTop: { xs: '21px', sm: 0 }
            }}
        >
            <Box display={{ xs: 'block', sm: 'none' }}>
                <MobileNav list={route} />
            </Box>
            <JobsHeader
                title="Applied Jobs"
                jobs={jobs}
                setJobs={setFilteredJobs}
            />
            <Box display={{ xs: 'none', sm: 'block', lg: 'none' }}>
                <MobileNav list={route} />
            </Box>
            <JobsList
                title={'Applied Jobs'}
                description={'Based on your profile'}
                data={filteredJobs}
                showMetrics={true}
                isFetching={isFetching}
            />
        </Box>
    );
}

export default AppliedJobs;
