const JobDetailItemBookmarkIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.9214 4.4198C13.3283 3.98507 10.6662 3.98507 8.07305 4.4198C7.58505 4.50161 7.19746 4.87654 7.10018 5.35947C6.2151 9.75345 6.16681 14.275 6.95784 18.6868L7.12139 19.599L10.7919 16.112C11.4674 15.4703 12.5271 15.4703 13.2025 16.112L16.8731 19.599L17.0366 18.6868C17.8276 14.275 17.7793 9.75345 16.8943 5.35947C16.797 4.87654 16.4094 4.50161 15.9214 4.4198ZM7.82504 2.94044C10.5824 2.47819 13.4121 2.47819 16.1694 2.94044C17.2686 3.12472 18.1442 3.96854 18.3647 5.06328C19.2871 9.64219 19.3374 14.354 18.5131 18.9516L18.2916 20.1869C18.1057 21.2237 16.8416 21.6381 16.0779 20.9126L12.1694 17.1995C12.0729 17.1078 11.9215 17.1078 11.825 17.1995L7.91651 20.9126C7.15281 21.6381 5.88877 21.2237 5.70287 20.1869L5.48138 18.9516C4.65706 14.354 4.70739 9.64219 5.62972 5.06328C5.85023 3.96854 6.72582 3.12472 7.82504 2.94044Z"
                fill="#494949"
            />
        </svg>
    );
};

export default JobDetailItemBookmarkIcon;
