import React, { MouseEventHandler } from 'react';
import { InfoOutlined } from '@mui/icons-material';
import { Avatar, Button, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';

type employee = [{
    name: string,
    img: string
}]
interface company {
    companyName: string;
}
interface list {
    name: string;
    id: string;
}

type ICardBoxProps = {
    title: string;
    icon?: React.ReactNode;
    list: employee | company[] | list[] | undefined;
    listLength?: number;
    btnText: string;
    btnOnClick?: MouseEventHandler<HTMLButtonElement> | undefined;
    tooltipText?: string;
};

const CardBox = ({
    title,
    icon,
    list = [],
    listLength = 2,
    btnText,
    btnOnClick = () => { },
    tooltipText
}: ICardBoxProps) => {
    return (
        <Box
            sx={{
                backgroundColor: '#fff',
                // padding: '8px 16px',
                // pr: '40px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '32px',
                width: '100%'
                // maxWidth: '324px'
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '8px 16px',
                    background: '#FFFFFF',
                    borderRadius: '8px',
                    width: '100%'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        gap: '10px',
                        borderBottom: '1px solid #ccc',
                        padding: '10px',
                        marginBottom: '16px'
                    }}
                >
                    {icon}
                    <Typography
                        sx={{
                            py: '16px',
                            color: '#23282B',
                            fontSize: '16px',
                            fontFamily: 'Open Sans',
                            fontWeight: '600'
                        }}
                    >
                        {title}
                    </Typography>
                    {tooltipText && (
                        <Tooltip
                            title={tooltipText}
                            placement="top"
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        padding: '16px 32px',
                                        background: '#FCFBF8',
                                        boxShadow:
                                            '-8px 4px 20px rgba(0, 0, 0, 0.07), 8px 8px 20px rgba(0, 0, 0, 0.07)',
                                        borderRadius: '8px',
                                        color: '#23282B',
                                        lineHeight: '24px',
                                        fontSize: '16px',
                                        fontFamily: 'Open Sans'
                                    }
                                }
                            }}
                        >
                            <InfoOutlined sx={{ color: '#808080' }} />
                        </Tooltip>
                    )}
                </Box>
                {list?.length > 0 &&
                    list?.slice(0, listLength).map((item: any, index: any) => (
                        <Box
                            key={index}
                            sx={{
                                width: '100%',
                                background: '#fafafa',
                                borderBottom: '1px solid #D8D8D8',
                                padding: '12px',
                                gap: '10px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            {item?.img && (
                                <Avatar
                                    sx={{ width: 24, height: 24 }}
                                    alt={item?.title}
                                    src={item?.companyProfile?.photo ?? item?.img}
                                />
                            )}
                            <Typography
                                sx={{
                                    color: '#23282B',
                                    fontSize: '14px',
                                    fontFamily: 'Open Sans',
                                    textAlign: 'center'
                                }}
                            >
                                {item?.companyProfile?.companyName ?? item?.name}
                            </Typography>
                        </Box>
                    ))}
                <Button
                    onClick={(e) => {
                        if (title === 'Improve your skills') {
                            btnOnClick(e)
                            localStorage.setItem(
                                'beginnerSkills',
                                JSON.stringify(list)
                            );
                        }
                        else {
                            btnOnClick(e)
                        }
                        
                     }}
                    variant="outlined"
                    sx={{ py: '14px', mt: 2, bgcolor: '#fafafa' }}
                >
                    {btnText}
                </Button>
            </Box>
        </Box>
    );
};

export default CardBox;
