import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Radio from '@mui/material/Radio';
import { useEffect, useState } from 'react';
import PageHeader from '../../../common/PageHeader';
import ArchieveBook from '../../../../assets/icons/ArchieveBook';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import {
    CompanyScoringAttributes,
} from '../../constants';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DialogBox from '../../components/DialogBox';
import { useGetCompanyProfileById } from '../../../../utils/hooks/api/company-profile/useGetCompanyProfile';
import SnackAlert from '../../../../components/Snackbar';
import { useUpdateCompanyReviewRequest } from '../../../../utils/hooks/api/company-review-requests/useUpdateCompanyReviewRequest';

interface ISkillType {
    name: string;
    rating: number;
    witness?: boolean;
    scoringLevel?: any;
}

const GeneralRatingCompany = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [message, setMessage] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [loading, setLoading] = useState(false);
    const [ratings, setRatings] = useState<ISkillType[]>([]);
    const [comment, setComment] = useState('');
    const queryParams = new URLSearchParams(location.search);
    const requestId = queryParams.get('requestId');
    const [showModal, setShowModal] = useState(false);
    const { id } = useParams();
    const data = location.state;
    const { data: companyProfile, isFetching, } = useGetCompanyProfileById(id!);
    const updateCompanyReviewRequest = useUpdateCompanyReviewRequest();

    const handleSkillChange = (
        name: string,
        rating: number,
        witness: boolean = true
    ) => {
        if (!data) {
            if (ratings.find((x) => x.name === name)) {
                setRatings((prev) => prev.filter((x) => x.name !== name));
            }
            if (witness) {
                setRatings((prev) => [
                    ...prev,
                    {
                        name,
                        rating,
                        witness
                    }
                ]);
            } else {
                setRatings((prev) => [
                    ...prev,
                    {
                        name,
                        rating,
                        witness
                    }
                ]);
            }
        }
    };

    const handleOnSubmit = () => {
        setLoading(true);
        let total = 0;
        const avgRatings = ratings.map((x, i) => {
            const rating = (x.rating / CompanyScoringAttributes[i].scoringLevel.best_in_class) * 100;
            total += rating;
            delete x.witness;
            return x;
        })
        const avg = Math.ceil(total / (CompanyScoringAttributes.length));
        updateCompanyReviewRequest.mutate(
            { ratings: avgRatings, rating: avg, message: comment, requestId, status: 'Submitted' },
            {
                onSuccess: (res) => {
                    setShowModal(true);
                    setLoading(false);
                },
                onError: (err: any) => {
                    setMessage(err?.response?.data?.message ?? 'Error occured please try again!');
                    setShowAlert(true);
                    setLoading(false);
                }
            }
        );
    };

    useEffect(() => {
        if (data?.ratings?.length > 0) {
            setRatings(data?.ratings ?? []);
            setComment(data?.message ?? "");
        }
    }, [data?.ratings, data?.message])

    return (
        <>
            <Paper sx={{ ml: '35px' }}>
                <PageHeader
                    onBackNavigate={'/reviews/company-ratings'}
                    pageTitle={'Company ratings'}
                    hideAction={true}
                />
                {isFetching ? null :
                    <Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: '28px 45px 28px 20px',
                                gap: '42px',
                                border: '1px solid #D9D9D9',
                                ml: '28px',
                                mr: '28px',
                                mt: '32px'
                            }}
                        >
                            <Avatar
                                sx={{ width: '200px', height: '200px' }}
                                src={companyProfile?.logo}
                            />
                            <Box
                                sx={{
                                    alignSelf: 'flex-start',
                                    mr: 'auto'
                                }}
                            >
                                <Typography
                                    variant="headlineLargeBold"
                                    sx={{
                                        mt: '15px',
                                        display: 'block'
                                    }}
                                >
                                    {companyProfile?.companyName ?? ''}
                                </Typography>
                                <Typography
                                    variant="titleLargeSemiBold"
                                    sx={{
                                        mt: '22px',
                                        display: 'block'
                                    }}
                                >
                                    {companyProfile?.industry ?? ''}
                                </Typography>
                                <Typography
                                    sx={{
                                        color: '#808080',
                                        mt: '8px'
                                    }}
                                >
                                    {companyProfile?.address?.country ? `${companyProfile?.address?.city ?? ''}, ${companyProfile?.address?.country ?? ''} ` : companyProfile?.address?.city}.
                                </Typography>
                                <Typography
                                    variant="titleMediumBold"
                                    sx={{
                                        display: 'block',
                                        mt: '35px'
                                    }}
                                >
                                    400+
                                    <Typography
                                        component="span"
                                        sx={{
                                            color: '#808080'
                                        }}
                                    >
                                        &nbsp;Followers
                                    </Typography>
                                </Typography>
                            </Box>
                            {/* <ScoreCard score={score?.length > 0 ? score : 0} /> */}
                        </Box>
                        <Box
                            sx={{
                                ml: '28px',
                                mr: '28px',
                                pb: '27px'
                            }}
                        >
                            <Box
                                sx={{
                                    borderTop: '1px solid #D9D9D9',
                                    borderBottom: '1px solid #D9D9D9',
                                    mt: '20px',
                                    pb: '20px'
                                }}
                            >
                                <Typography
                                    variant="headlineSmallSemiBold"
                                    sx={{
                                        mt: '70px',
                                        display: 'block',
                                        textAlign: 'center'
                                    }}
                                >
                                    Company Rating Attributes
                                </Typography>
                                <Typography
                                    sx={{
                                        mt: '32px'
                                    }}
                                >
                                    Please rate your perception of {companyProfile?.companyName ?? ''} with regards to each statement below. You can rate {companyProfile?.companyName ?? ''} as having “Poor” through “Best in Class” of demonstrating what is described in each statement. For example, if you do not feel {companyProfile?.companyName ?? ''} fully satisfies what is described in the statement, then select “Poor”. Respond to all survey items below, then click “Submit Review”.
                                </Typography>
                                <Typography
                                    variant="titleSmallRegular"
                                    sx={{
                                        color: '#808080',
                                        mt: '4px'
                                    }}
                                >
                                    Note that rating this company will impact your Jobfactor score, so ensure to be objective.
                                </Typography>
                            </Box>
                            <Box mt={5}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        pt: '12px'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: '40%'
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '70px'
                                        }}
                                    >
                                        <Box width={'70px'}>
                                            <Typography>
                                                Poor
                                            </Typography>
                                        </Box>
                                        <Box width={'70px'}>
                                            <Typography>
                                                Fair
                                            </Typography>
                                        </Box>
                                        <Box width={'70px'}>
                                            <Typography>
                                                Good
                                            </Typography>
                                        </Box>
                                        <Box width={'70px'}>
                                            <Typography>
                                                Very Good
                                            </Typography>
                                        </Box>
                                        <Box width={'70px'}>
                                            <Typography>
                                                Best in Class
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                {CompanyScoringAttributes?.map(
                                    (item: ISkillType, index: number) => (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                p: '1px 16px',
                                                borderRadius: '16px',
                                                background: '#D9D9D9',
                                                mt: '12px'
                                            }}
                                            key={`item_${index}`}
                                        >
                                            <Box
                                                sx={{
                                                    width: '40%'
                                                }}
                                            >
                                                <Typography>
                                                    {item?.name}
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '70px'
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        width: '70px'
                                                    }}
                                                >
                                                    <Radio
                                                        onChange={() =>
                                                            handleSkillChange(
                                                                item?.name,
                                                                item.scoringLevel.poor,
                                                            )
                                                        }
                                                        checked={
                                                            ratings.find(
                                                                (x) =>
                                                                    x.rating ===
                                                                    item.scoringLevel.poor &&
                                                                    x.name ===
                                                                    item?.name
                                                            )
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </Box>
                                                <Box
                                                    sx={{
                                                        width: '70px'
                                                    }}
                                                >
                                                    <Radio
                                                        onChange={() =>
                                                            handleSkillChange(
                                                                item?.name,
                                                                item.scoringLevel.fair
                                                            )
                                                        }
                                                        checked={
                                                            ratings.find(
                                                                (x) =>
                                                                    x.rating ===
                                                                    item.scoringLevel.fair &&
                                                                    x.name ===
                                                                    item?.name
                                                            )
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </Box>
                                                <Box
                                                    sx={{
                                                        width: '70px'
                                                    }}
                                                >
                                                    <Radio
                                                        onChange={() =>
                                                            handleSkillChange(
                                                                item?.name,
                                                                item.scoringLevel.good
                                                            )
                                                        }
                                                        checked={
                                                            ratings.find(
                                                                (x) =>
                                                                    x.rating ===
                                                                    item.scoringLevel.good &&
                                                                    x.name ===
                                                                    item?.name
                                                            )
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </Box>
                                                <Box
                                                    sx={{
                                                        width: '70px'
                                                    }}
                                                >
                                                    <Radio
                                                        onChange={() =>
                                                            handleSkillChange(
                                                                item?.name,
                                                                item.scoringLevel.very_good
                                                            )
                                                        }
                                                        checked={
                                                            ratings.find(
                                                                (x) =>
                                                                    x.rating ===
                                                                    item.scoringLevel.very_good &&
                                                                    x.name ===
                                                                    item?.name
                                                            )
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </Box>
                                                <Box
                                                    sx={{
                                                        width: '70px'
                                                    }}
                                                >
                                                    <Radio
                                                        onChange={() =>
                                                            handleSkillChange(
                                                                item?.name,
                                                                item.scoringLevel.best_in_class
                                                            )
                                                        }
                                                        checked={
                                                            ratings.find(
                                                                (x) =>
                                                                    x.rating ===
                                                                    item.scoringLevel.best_in_class &&
                                                                    x.name ===
                                                                    item?.name
                                                            )
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </Box>
                                            </Box>
                                        </Box>
                                    )
                                )}
                            </Box>
                            <Box
                                sx={{
                                    padding: '20px',
                                    background: '#FFFAF1',
                                    borderBottom: '1px solid #D9D9D9',
                                    mt: '50px'
                                }}
                            >
                                <TextField
                                    disabled={data}
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                    sx={{
                                        '& .MuiInput-underline': {
                                            fontSize: '14px'
                                        },
                                        '& .MuiInput-underline::before': {
                                            border: '0px !important'
                                        },
                                        '& .MuiInput-underline::after': {
                                            border: '0px !important'
                                        }
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <ArchieveBook />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="standard"
                                    placeholder="Write a short comment: "
                                    fullWidth
                                />
                                <FormHelperText
                                    sx={{
                                        color: '#808080',
                                        ml: '28px'
                                    }}
                                >
                                    Jobfactor is very diligent in their work of bridging the gap between companies and top talents
                                </FormHelperText>
                            </Box>
                            {!data &&
                                <Box
                                    sx={{
                                        mt: '32px',
                                        textAlign: 'center'
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        sx={{
                                            p: '12px 20px',
                                            width: 'auto',
                                            fontFamily: 'Open Sans',
                                            fontWeight: 600,
                                            fontSize: '16px'
                                        }}
                                        onClick={() => {
                                            handleOnSubmit();
                                        }}
                                        disabled={loading || ratings.length === 0}
                                    >
                                        Submit Review
                                    </Button>
                                </Box>
                            }
                        </Box>
                    </Box>
                }
            </Paper>
            <DialogBox
                title="Review submitted"
                description=""
                open={showModal}
                showClose={false}
                handleClose={() => { }}
                handleContinue={() => {
                    navigate('/reviews/company-ratings');
                }}
            />
            <SnackAlert
                open={showAlert}
                handleClose={() => setShowAlert(false)}
                message={message}
                type={'error'}
            />
        </>
    );
};

export default GeneralRatingCompany;
