import { useQuery } from 'react-query';
import { QueryKeys } from '../QueryKey';
import axiosInstance from '../axiosConfig';

export function useGetProfessionalProfileEducationEmploymentById(id: string) {
    const getProfessionalProfileEducationEmploymentById = async (
        id: string
    ) => {
        const response = await axiosInstance.get(
            `/api/professional-profiles/${id}/educations-employments`
        );
        const data = await response.data;
        return data;
    };
    return useQuery(
        QueryKeys.RetrieveProfessionalProfileById,
        () => getProfessionalProfileEducationEmploymentById(id),
        {
            refetchOnMount: 'always'
        }
    );
}
