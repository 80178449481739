import axiosInstance from '../axiosConfig';
import { IEmailNotificationRequest } from './types';
import { UseMutationResult, useMutation } from 'react-query';

async function sendEmailNotification(postRequest: IEmailNotificationRequest) {
    const response = await axiosInstance.post(`/api/email/send-notification`, postRequest);
    const data = await response.data;
    return data;
}
export function useSendEmailNotification(): UseMutationResult<
    IEmailNotificationRequest,
    unknown,
    IEmailNotificationRequest
> {
    return useMutation(sendEmailNotification);
}
