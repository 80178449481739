import { Alert, AlertColor, Snackbar } from '@mui/material';
import { useEffect, useState } from 'react';

export interface MessagesAlertProps {
    message: string;
    type: AlertColor;
}

export default function MessagesAlert({ message, type }: MessagesAlertProps) {
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        setOpen(!!message.length)
    }, [message]);

    const handleClose = (
        event: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    return (
        <Snackbar
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}
            message={message}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    );
}
