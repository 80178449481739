import React from 'react'

const ClearIcon = () => {
  return (
      <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
          <path
              d="M14.7138 14.7136L5.28574 5.28554"
              stroke="#808080"
              stroke-width="2"
              stroke-linecap="round"
          />
          <path
              d="M14.7143 5.28554L5.28617 14.7136"
              stroke="#808080"
              stroke-width="2"
              stroke-linecap="round"
          />
      </svg>
  );
}

export default ClearIcon
