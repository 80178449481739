import { useQuery } from 'react-query';
import { QueryKeys } from '../QueryKey';
import axiosInstance from '../axiosConfig';

export function useGetCompleteCompanyProfileById(id: string) {
    const getCompleteCompanyProfileById = async (id: string) => {
        const response = await axiosInstance.get(`/api/company-profiles/${id}`);
        const data = await response.data;
        return data;
    };
    return useQuery(QueryKeys.RetrieveCompleteCompanyProfile, () =>
        getCompleteCompanyProfileById(id)
    );
}
