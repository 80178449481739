import { CompanyHighScoringLevels, CompanyLowScoringLevels } from "../../Reviews/constants";
import { IRatingType } from "../types";

interface ILevelType {
    poor: number;
    fair: number;
    good: number;
    very_good: number;
    best_in_class: number;
}
export const getRatingLevel = (item: IRatingType) => {
    let scoringLevel: ILevelType = CompanyLowScoringLevels;
    const highScoreSkills = ['Compensation and Benefits', 'Training and Skill development', 'Career growth opportunity', 'Reward and Recognition', 'Work / Life balance', 'Job security', 'Diversity and Inclusion'];
    if (highScoreSkills.includes(item.name)) {
        scoringLevel = CompanyHighScoringLevels;
    }
    let color = '#E75541';
    let title = 'Poor';
    let shortForm = 'P';
    let stars = 1;
    switch (item.rating) {
        case scoringLevel.fair:
            stars = 2;
            color = '#F6C70E';
            title = 'Fair';
            shortForm = 'F';
            break;
        case scoringLevel.good:
            stars = 3;
            color = '#49B6FF';
            title = 'Good';
            shortForm = 'G';
            break;
        case scoringLevel.very_good:
            stars = 4;
            color = '#7ABE57';
            title = 'Very Good';
            shortForm = 'V';
            break;
        case scoringLevel.best_in_class:
            stars = 5;
            color = '#07AF22';
            title = 'Best in Class';
            shortForm = 'B';
            break;
        default:
            stars = 1;
            color = '#E75541';
            title = 'Poor';
            shortForm = 'P';
            break;
    }
    return { color, title, shortForm, stars };
}

export const getAvgRatingLevel = (level: number) => {
    let color = '#E75541';
    let title = 'Poor';
    let shortForm = 'P';
    let stars = 1;
    level = formatRating(level);
    switch (level) {
        case 40:
            stars = 2;
            color = '#F6C70E';
            title = 'Fair';
            shortForm = 'F';
            break;
        case 60:
            stars = 3;
            color = '#49B6FF';
            title = 'Good';
            shortForm = 'G';
            break;
        case 80:
            stars = 4;
            color = '#7ABE57';
            title = 'Very Good';
            shortForm = 'V';
            break;
        case 100:
            stars = 5;
            color = '#07AF22';
            title = 'Best in Class';
            shortForm = 'B';
            break;
        default:
            stars = 1;
            color = '#E75541';
            title = 'Poor';
            shortForm = 'P';
            break;
    }
    return { color, title, shortForm, stars };
}

export const formatRating = (rating: number) => {
    if (rating < 26) {
        return 20;
    }
    if (rating >= 26 && rating < 46) {
        return 40;
    }
    if (rating >= 46 && rating < 66) {
        return 60;
    }
    if (rating >= 66 && rating < 86) {
        return 80;
    }
    if (rating >= 86) {
        return 100;
    }
    return 0;
}