import axiosInstance from '../axiosConfig';
import { IEmploymentRequest } from './types';
import { UseMutationResult, useMutation } from 'react-query';

async function updateEmployment(patchtRequest: IEmploymentRequest) {
    const response = await axiosInstance.patch(
        `/api/employments/${patchtRequest?.id}`,
        patchtRequest
    );
    const data = await response.data;
    return data;
}
export function useUpdateEmployment(): UseMutationResult<
    IEmploymentRequest,
    unknown,
    IEmploymentRequest
> {
    return useMutation(updateEmployment);
}
