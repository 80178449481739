const ArrowUpRight = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
        >
            <path
                d="M14.3984 10.9609L22.9827 10.9609L22.9827 19.5452"
                stroke="#05668D"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.9609 22.9805L22.8615 11.0799"
                stroke="#05668D"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default ArrowUpRight;
