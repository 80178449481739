import { Box, Typography } from '@mui/material'
import RatingBadge from './RatingBadge'
import StarRating from './StarRating'

const IndividualRatingCard = ({ avgRating = 0 }) => {
    return (
        <Box
            sx={{
                p: "32px 40px",
                bgcolor: '#FFF',
                borderBottom: '1px solid #D9D9D9'
            }}
        >
            <Typography variant='titleLargeSemiBold'>
                Average individual rating:
            </Typography>
            <Box
                sx={{
                    mt: '20px',
                    mb: '16px'
                }}
            >
                <Typography color={'#808080'}>
                    Your average rating per individual is the aggregate of all the ratings provided by an individual, calculated by sum of ratings divided by total number of ratings
                </Typography>
                <Typography color={'#808080'} component={'li'} mt={'10px'}>
                    All reviews are anonymous and come from past and present employees, customers, partners and others
                </Typography>
                <Typography color={'#808080'} component={'li'}>
                    Reviews are updated regularly as your company get reviewed.
                </Typography>
            </Box>
            <Box
                sx={{
                    mb: '8px',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Typography variant='titleMediumSemiBold'>
                    Overall Individual rating&nbsp;&nbsp;&nbsp;
                </Typography>
                <RatingBadge level={avgRating} />
            </Box>
            <StarRating level={avgRating} readOnly={true} />
        </Box>
    )
}

export default IndividualRatingCard