import React from 'react';

export const ArrowLeftIcon = ({ color = '#494949' }) => {
    return (
        <svg
            width="28"
            height="28"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.3512 28.2297C14.0662 28.2297 13.7812 28.1247 13.5562 27.8997L4.45125 18.7947C4.01625 18.3597 4.01625 17.6397 4.45125 17.2047L13.5562 8.09969C13.9913 7.66469 14.7113 7.66469 15.1463 8.09969C15.5813 8.53469 15.5813 9.25469 15.1463 9.68969L6.83625 17.9997L15.1463 26.3097C15.5813 26.7447 15.5813 27.4647 15.1463 27.8997C14.9363 28.1247 14.6362 28.2297 14.3512 28.2297Z"
                fill={color}
            />
            <path
                d="M30.7528 19.125H5.50781C4.89281 19.125 4.38281 18.615 4.38281 18C4.38281 17.385 4.89281 16.875 5.50781 16.875H30.7528C31.3678 16.875 31.8778 17.385 31.8778 18C31.8778 18.615 31.3678 19.125 30.7528 19.125Z"
                fill={color}
            />
        </svg>
    );
};
