import React, { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import JobItem from '../JobItem/JobItem';
import JobMetrics from './JobMetrics';
import { IJobItem } from '../../types/IJobItem';
import JobListHeader from './JobListHeader';
import Pagination from '../../../../components/Pagination';
import JobLoader from '../../../../components/LoadingState/JobLoader';
import { JobItemExternalProps } from '../../../../utils/hooks/api/jobs/useGetExternalJobs';
import JobItemExternal from '../JobItem/JobItemExternal';

const JobsList = (props: {
    data: IJobItem[];
    title: string;
    description: string;
    showMetrics?: boolean;
    showheader?: boolean;
    updateData?: (jobId: string) => void;
    isFetching?: boolean;
    externalJobs?: JobItemExternalProps[];
}) => {
    const {
        data = [],
        title,
        description,
        showMetrics,
        showheader,
        updateData = () => {},
        isFetching,
        externalJobs = []
    } = props;
    const [page, setPage] = React.useState(0);
    const rowsPerPage = 8;
    const [pageCount, setPageCount] = React.useState(0);
    const handleChangePage = (page: number) => {
        setPage(page - 1);
    };
    const [allJobs, setAllJobs] = React.useState<
        Array<IJobItem | JobItemExternalProps>
    >([]);
    useEffect(() => {
        const totalJobs = [...data, ...externalJobs];
        setAllJobs(totalJobs);
        setPageCount(Math.ceil(totalJobs.length / rowsPerPage));
    }, [data, externalJobs]);
    return (
        <>
            {showMetrics ? (
                <Box sx={{ mt: { xs: '8px', lg: '16px' } }}>
                    <JobMetrics />
                </Box>
            ) : null}
            <Box
                sx={{
                    backgroundColor: '#FFFFFF',
                    mt: { xs: '0px', lg: '16px' },
                    pb: '20px'
                }}
            >
                <Box>
                    {showheader ? (
                        <JobListHeader
                            title={title}
                            description={description}
                        />
                    ) : null}
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: { xs: 0, sm: '32px' },
                            pt: { xs: 0, sm: '24px' },
                            justifyContent: { xs: 'flex-start', sm: 'center' }
                        }}
                    >
                        {isFetching ? (
                            <JobLoader />
                        ) : (
                            <React.Fragment>
                                {allJobs
                                    ?.slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    ?.map((item) => {
                                        if (!item.hasOwnProperty('source')) {
                                            const jobItem = item as IJobItem;
                                            return (
                                                <JobItem
                                                    key={jobItem.id}
                                                    jobInfo={jobItem}
                                                    updateData={updateData}
                                                />
                                            );
                                        } else {
                                            const jobItem =
                                                item as JobItemExternalProps;
                                            return (
                                                <JobItemExternal
                                                    key={
                                                        jobItem.company +
                                                        '-' +
                                                        jobItem.title
                                                    }
                                                    jobInfo={jobItem}
                                                />
                                            );
                                        }
                                    })}
                            </React.Fragment>
                        )}
                        <Box width={{xs: '100%', sm: undefined}} mt={{xs: '16px', sm: undefined}} px={{xs: '16px', sm: undefined}}>
                            {pageCount > 0 ? (
                                <Pagination
                                    count={pageCount}
                                    handleChangePage={handleChangePage}
                                />
                            ) : null}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default JobsList;
