import * as React from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { ViewModeType, useMessages } from '../../utils/MessagesContext';

export default function HeaderActions() {
    const {
        viewMode,
        setViewMode,
        setActiveConversation,
        showArchived,
        setShowArchived,
        setManagedConversationIds
    } = useMessages();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleModeChange = (mode: ViewModeType) => {
        setViewMode((prev) => (prev === mode ? null : mode));
        if (mode === 'manage') setActiveConversation(null);
        handleClose();
    };
    const handleToggleArchived = () => {
        if (viewMode !== 'manage') setViewMode(null);
        setShowArchived((prev) => !prev);
        setManagedConversationIds([]);
        handleClose();
    };

    return (
        <div>
            <IconButton
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <MoreVert />
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem onClick={() => handleModeChange('search')}>
                    New conversation
                </MenuItem>
                <MenuItem onClick={() => handleModeChange('manage')}>
                    Manage conversations
                </MenuItem>
                <MenuItem onClick={handleToggleArchived}>
                    View {showArchived && 'un'}archived
                </MenuItem>
            </Menu>
        </div>
    );
}
