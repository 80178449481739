import { Box } from '@mui/material';
import React from 'react';

function UserIcon(props: { isHover?: boolean; isSelected?: boolean }) {
    const { isHover, isSelected } = props;
    return (
        <>
            <Box display={{ xs: 'none', lg: 'block' }}>
                <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M9.9987 9.99935C12.2999 9.99935 14.1654 8.13387 14.1654 5.83268C14.1654 3.5315 12.2999 1.66602 9.9987 1.66602C7.69751 1.66602 5.83203 3.5315 5.83203 5.83268C5.83203 8.13387 7.69751 9.99935 9.9987 9.99935Z"
                        stroke={isHover || isSelected ? '#05668D' : '#808080'}
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M17.1565 18.3333C17.1565 15.1083 13.9482 12.5 9.99817 12.5C6.04817 12.5 2.83984 15.1083 2.83984 18.3333"
                        stroke={isHover || isSelected ? '#05668D' : '#808080'}
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </Box>
            <Box display={{ xs: 'block', lg: 'none' }}>
                {isHover || isSelected ? (
                    <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M19.9997 3.33337C15.633 3.33337 12.083 6.88337 12.083 11.25C12.083 15.5334 15.433 19 19.7997 19.15C19.933 19.1334 20.0663 19.1334 20.1663 19.15C20.1997 19.15 20.2163 19.15 20.2497 19.15C20.2663 19.15 20.2663 19.15 20.283 19.15C24.5497 19 27.8997 15.5334 27.9163 11.25C27.9163 6.88337 24.3663 3.33337 19.9997 3.33337Z"
                            fill="#05668D"
                        />
                        <path
                            d="M28.4666 23.5833C23.8166 20.4833 16.2333 20.4833 11.5499 23.5833C9.43327 25 8.2666 26.9166 8.2666 28.9666C8.2666 31.0166 9.43327 32.9166 11.5333 34.3166C13.8666 35.8833 16.9333 36.6666 19.9999 36.6666C23.0666 36.6666 26.1333 35.8833 28.4666 34.3166C30.5666 32.9 31.7333 31 31.7333 28.9333C31.7166 26.8833 30.5666 24.9833 28.4666 23.5833Z"
                            fill="#05668D"
                        />
                    </svg>
                ) : (
                    <svg
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M18 3C14.07 3 10.875 6.195 10.875 10.125C10.875 13.98 13.89 17.1 17.82 17.235C17.94 17.22 18.06 17.22 18.15 17.235C18.18 17.235 18.195 17.235 18.225 17.235C18.24 17.235 18.24 17.235 18.255 17.235C22.095 17.1 25.11 13.98 25.125 10.125C25.125 6.195 21.93 3 18 3Z"
                            fill="#AAAAAA"
                        />
                        <path
                            d="M25.6199 21.2248C21.4349 18.4348 14.6099 18.4348 10.3949 21.2248C8.48994 22.4998 7.43994 24.2248 7.43994 26.0698C7.43994 27.9148 8.48994 29.6248 10.3799 30.8848C12.4799 32.2948 15.2399 32.9998 17.9999 32.9998C20.7599 32.9998 23.5199 32.2948 25.6199 30.8848C27.5099 29.6098 28.5599 27.8998 28.5599 26.0398C28.5449 24.1948 27.5099 22.4848 25.6199 21.2248Z"
                            fill="#AAAAAA"
                        />
                    </svg>
                )}
            </Box>
        </>
    );
}

export default UserIcon;
