import React from 'react';
import { Box, Grid } from '@mui/material';
import JobMetrics from '../JobsList/JobMetrics';
import { JobApplicationItem } from '../../types/JobApplicationItem';
import ApplicationItem from './ApplicationItem';
import Pagination from '../../../../components/Pagination';

const ApplicationList = (props: {
    data: JobApplicationItem[];
    showMetrics?: boolean;
    showJobTitle?: boolean;
    updateData?: (applicantId: string) => void;
}) => {
    const { data, showMetrics, showJobTitle, updateData = () => {} } = props;
    const [page, setPage] = React.useState(0);
    const rowsPerPage = 8;

    const handleChangePage = (page: number) => {
        setPage(page - 1);
    };

    return (
        <Box sx={{ mt: {xs: '8px', sm: 0, lg: '28px'} }}>
            <Box>
                <Box>
                    {showMetrics ? <JobMetrics /> : null}
                </Box>
                <Grid
                    container
                    sx={{
                        flexGrow: 1,
                        marginTop: {xs: '8px', lg: '20px'},
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    {data
                        ?.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                        )
                        ?.map((item, index) => (
                            <Grid
                                item
                                key={index}
                                xs={12}
                                lg={12}
                                xl={12}
                                sx={{
                                    width: 'full',
                                    // margin: '10px 0'
                                }}
                            >
                                <ApplicationItem
                                    ApplicantInfo={item}
                                    showJobTitle={showJobTitle ?? false}
                                    updateData={updateData}
                                />
                            </Grid>
                        ))}
                    {data?.length > rowsPerPage ? (
                        <Pagination
                            count={Math.ceil(data.length / rowsPerPage)}
                            handleChangePage={handleChangePage}
                        />
                    ) : null}
                </Grid>
            </Box>
        </Box>
    );
};

export default ApplicationList;
