import React from 'react';

function ProfileFormIcon() {
    return (
        <>
            <svg
                width="41"
                height="20"
                viewBox="0 0 41 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M10.1341 9.68073C10.1091 9.68073 10.0924 9.68073 10.0674 9.68073C10.0258 9.6724 9.96745 9.6724 9.91745 9.68073C7.50078 9.60573 5.67578 7.70573 5.67578 5.36406C5.67578 2.98073 7.61745 1.03906 10.0008 1.03906C12.3841 1.03906 14.3258 2.98073 14.3258 5.36406C14.3174 7.70573 12.4841 9.60573 10.1591 9.68073C10.1508 9.68073 10.1424 9.68073 10.1341 9.68073ZM10.0008 2.28906C8.30911 2.28906 6.92578 3.6724 6.92578 5.36406C6.92578 7.03073 8.22578 8.3724 9.88411 8.43073C9.92578 8.4224 10.0424 8.4224 10.1508 8.43073C11.7841 8.35573 13.0674 7.01406 13.0758 5.36406C13.0758 3.6724 11.6924 2.28906 10.0008 2.28906Z"
                    fill="#494949"
                />
                <path
                    d="M10.1404 18.7943C8.50703 18.7943 6.86536 18.3776 5.6237 17.5443C4.46536 16.7776 3.83203 15.7276 3.83203 14.5859C3.83203 13.4443 4.46536 12.3859 5.6237 11.6109C8.1237 9.9526 12.1737 9.9526 14.657 11.6109C15.807 12.3776 16.4487 13.4276 16.4487 14.5693C16.4487 15.7109 15.8154 16.7693 14.657 17.5443C13.407 18.3776 11.7737 18.7943 10.1404 18.7943ZM6.31536 12.6609C5.51536 13.1943 5.08203 13.8776 5.08203 14.5943C5.08203 15.3026 5.5237 15.9859 6.31536 16.5109C8.39036 17.9026 11.8904 17.9026 13.9654 16.5109C14.7654 15.9776 15.1987 15.2943 15.1987 14.5776C15.1987 13.8693 14.757 13.1859 13.9654 12.6609C11.8904 11.2776 8.39036 11.2776 6.31536 12.6609Z"
                    fill="#494949"
                />
                <line x1="40.5" y1="2.5" x2="40.5" y2="17.5" stroke="#D9D9D9" />
            </svg>
        </>
    );
}

export default ProfileFormIcon;
