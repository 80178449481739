import React from 'react';
import { Box, Typography } from '@mui/material';

const LineIcon = () => {
    return (
        <>
            <Box display={{ xs: 'none', sm: 'flex' }}>
                <svg
                    width="16"
                    height="1"
                    viewBox="0 0 16 1"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <line y1="0.5" x2="16" y2="0.5" stroke="#808080" />
                </svg>
            </Box>
            <Box display={{ xs: 'flex', sm: 'none' }}>
                <svg
                    width="10"
                    height="1"
                    viewBox="0 0 16 1"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <line y1="0.5" x2="16" y2="0.5" stroke="#808080" />
                </svg>
            </Box>
        </>
    );
};

function JobMetricCircle(props: { label: string; bgColor: string }) {
    const { label, bgColor } = props;
    return (
        <Box
            sx={{
                backgroundColor: bgColor,
                height: { xs: 20, sm: 32 },
                width: { xs: 20, sm: 32 },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '100px',
                color: 'white',
                fontSize: {xs: 12, sm: 14}
            }}
        >
            {label}
        </Box>
    );
}

function MetricLabel(props: { label: string }) {
    const { label } = props;
    return (
        <Box
            sx={{
                display: {xs: 'none', sm: 'block'},
                borderRadius: '0px 100px 100px 0px',
                backgroundColor: '#F2F2F2',
                p: '2px 12px'
            }}
        >
            <Typography variant="titleSmallSemiBold" color={'#23282B'}>
                {label}
            </Typography>
        </Box>
    );
}

const NotAFit = () => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <JobMetricCircle label={'1'} bgColor={'#E75541'} />
            <Box sx={{ ml: 0.5 }} />
            <LineIcon />
            <Box sx={{ ml: 0.5 }} />
            <JobMetricCircle label={'3'} bgColor={'#E75541'} />
            <Box sx={{ ml: 1 }} />
            <MetricLabel label={'Not a fit'} />
        </Box>
    );
};

const LowFit = () => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <JobMetricCircle label={'4'} bgColor={'#F6C70E'} />
            <Box sx={{ ml: 0.5 }} />
            <LineIcon />
            <Box sx={{ ml: 0.5 }} />
            <JobMetricCircle label={'5'} bgColor={'#F6C70E'} />
            <Box sx={{ ml: 1 }} />
            <MetricLabel label={'Low fit'} />
        </Box>
    );
};

const PossibleMatch = () => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <JobMetricCircle label={'6'} bgColor={'#49B6FF'} />
            <Box sx={{ ml: 0.5 }} />
            <LineIcon />
            <Box sx={{ ml: 0.5 }} />
            <JobMetricCircle label={'7'} bgColor={'#49B6FF'} />
            <Box sx={{ ml: 1 }} />
            <MetricLabel label={'Possible Match'} />
        </Box>
    );
};

const GoodMatch = () => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <JobMetricCircle label={'8'} bgColor={'#95C97A'} />
            <Box sx={{ ml: 1 }} />
            <MetricLabel label={'Good Match'} />
        </Box>
    );
};

const BestFit = () => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <JobMetricCircle label={'9'} bgColor={'#07AF22'} />
            <Box sx={{ ml: 0.5 }} />
            <LineIcon />
            <Box sx={{ ml: 0.5 }} />
            <JobMetricCircle label={'10'} bgColor={'#07AF22'} />
            <Box sx={{ ml: 1 }} />
            <MetricLabel label={'Best fit'} />
        </Box>
    );
};
const JobMetrics = () => {
    return (
        <Box
            sx={{
                backgroundColor: '#FFFFFF',
                mt: 1,
                padding: {
                    xs: '12px 16px',
                    sm: '20px',
                    lg: '10px 10px 10px 40px'
                }
            }}
        >
            <Typography
                variant={'titleSmallSemiBold'}
                sx={{ textTransform: 'uppercase', fontSize: {xs: '12px', sm: '14px'}, color: '#23282B' }}
            >
                Job Fit Metrics
            </Typography>
            <Box sx={{ display: 'flex', mt: 1.5, gap: {xs: 1, sm: 2}, flexWrap: 'wrap', justifyContent: {xs: 'space-between', sm: 'flex-start'} }}>
                <NotAFit />
                <LowFit />
                <PossibleMatch />
                <GoodMatch />
                <BestFit />
            </Box>
        </Box>
    );
};

export default JobMetrics;
