const UserFormGroup = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            style={{ minWidth: '41px', minHeight: '20px' }}
            width="41"
            height="20"
            viewBox="0 0 41 20"
            fill="none"
        >
            <path
                d="M14.9999 5.96407C14.9499 5.95573 14.8916 5.95573 14.8416 5.96407C13.6916 5.9224 12.7749 4.98073 12.7749 3.81406C12.7749 2.6224 13.7332 1.66406 14.9249 1.66406C16.1166 1.66406 17.0749 2.63073 17.0749 3.81406C17.0666 4.98073 16.1499 5.9224 14.9999 5.96407Z"
                stroke="#747474"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.1417 12.0309C15.2834 12.2225 16.5417 12.0225 17.425 11.4309C18.6 10.6475 18.6 9.3642 17.425 8.58086C16.5334 7.9892 15.2584 7.78919 14.1167 7.98919"
                stroke="#747474"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.97539 5.96407C5.02539 5.95573 5.08372 5.95573 5.13372 5.96407C6.28372 5.9224 7.20039 4.98073 7.20039 3.81406C7.20039 2.6224 6.24206 1.66406 5.05039 1.66406C3.85873 1.66406 2.90039 2.63073 2.90039 3.81406C2.90872 4.98073 3.82539 5.9224 4.97539 5.96407Z"
                stroke="#747474"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.83353 12.0309C4.69186 12.2225 3.43353 12.0225 2.5502 11.4309C1.3752 10.6475 1.3752 9.3642 2.5502 8.58086C3.44186 7.9892 4.71686 7.78919 5.85853 7.98919"
                stroke="#747474"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.9999 12.1906C9.9499 12.1823 9.89157 12.1823 9.84157 12.1906C8.69157 12.149 7.7749 11.2073 7.7749 10.0406C7.7749 8.84896 8.73323 7.89062 9.9249 7.89062C11.1166 7.89062 12.0749 8.85729 12.0749 10.0406C12.0666 11.2073 11.1499 12.1573 9.9999 12.1906Z"
                stroke="#747474"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.5751 14.8187C6.4001 15.6021 6.4001 16.8854 7.5751 17.6687C8.90843 18.5604 11.0918 18.5604 12.4251 17.6687C13.6001 16.8854 13.6001 15.6021 12.4251 14.8187C11.1001 13.9354 8.90843 13.9354 7.5751 14.8187Z"
                stroke="#747474"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <line x1="40.5" y1="2.5" x2="40.5" y2="17.5" stroke="#D9D9D9" />
        </svg>
    );
};

export default UserFormGroup;
