const SummaryForm = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="41"
            height="20"
            viewBox="0 0 41 20"
            fill="none"
        >
            <path
                d="M13.3333 18.9557H6.66667C3.625 18.9557 1.875 17.2057 1.875 14.1641V5.83073C1.875 2.78906 3.625 1.03906 6.66667 1.03906H13.3333C16.375 1.03906 18.125 2.78906 18.125 5.83073V14.1641C18.125 17.2057 16.375 18.9557 13.3333 18.9557ZM6.66667 2.28906C4.28333 2.28906 3.125 3.4474 3.125 5.83073V14.1641C3.125 16.5474 4.28333 17.7057 6.66667 17.7057H13.3333C15.7167 17.7057 16.875 16.5474 16.875 14.1641V5.83073C16.875 3.4474 15.7167 2.28906 13.3333 2.28906H6.66667Z"
                fill="#494949"
            />
            <path
                d="M7.50016 9.25572C7.3585 9.25572 7.21683 9.23074 7.0835 9.1724C6.70016 9.00574 6.4585 8.63072 6.4585 8.22239V1.66406C6.4585 1.3224 6.74183 1.03906 7.0835 1.03906H12.9168C13.2585 1.03906 13.5418 1.3224 13.5418 1.66406V8.21405C13.5418 8.63072 13.3002 9.00573 12.9168 9.16406C12.5418 9.33073 12.1002 9.25574 11.7919 8.97241L10.0002 7.33072L8.20848 8.98075C8.00848 9.16409 7.7585 9.25572 7.50016 9.25572ZM10.0002 6.00575C10.2502 6.00575 10.5085 6.09738 10.7085 6.28071L12.2918 7.73904V2.28906H7.7085V7.73904L9.29185 6.28071C9.49185 6.09738 9.75016 6.00575 10.0002 6.00575Z"
                fill="#494949"
            />
            <path
                d="M14.5832 12.2891H11.0415C10.6998 12.2891 10.4165 12.0057 10.4165 11.6641C10.4165 11.3224 10.6998 11.0391 11.0415 11.0391H14.5832C14.9248 11.0391 15.2082 11.3224 15.2082 11.6641C15.2082 12.0057 14.9248 12.2891 14.5832 12.2891Z"
                fill="#494949"
            />
            <path
                d="M14.5833 15.625H7.5C7.15833 15.625 6.875 15.3417 6.875 15C6.875 14.6583 7.15833 14.375 7.5 14.375H14.5833C14.925 14.375 15.2083 14.6583 15.2083 15C15.2083 15.3417 14.925 15.625 14.5833 15.625Z"
                fill="#494949"
            />
            <line x1="40.5" y1="2.5" x2="40.5" y2="17.5" stroke="#D9D9D9" />
        </svg>
    );
};

export default SummaryForm;
