import { IUserType } from '../types';
import { Backdrop } from '@mui/material';
import JobfactorAppBar from '../../../components/JobfactorAppBar';
interface IPropTypes {
    handleClose: () => void;
    open: boolean;
    handleOnAddClick: (user: IUserType) => void;
}

const SearchBox = ({ open, handleClose, handleOnAddClick }: IPropTypes) => {
    return (
        <>
            <JobfactorAppBar
                showConnections={open}
                handleAdd={handleOnAddClick}
            />
            <Backdrop
                open={open}
                onClick={handleClose}
                sx={{ zIndex: 1200 }}
            ></Backdrop>
        </>
    );
};

export default SearchBox;
