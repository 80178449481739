import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { useEffect, useState } from 'react';
import PageHeader from '../../../common/PageHeader';
import { IEvidenceType } from '../../types';
import ScoreCard from '../../../../components/ScoreCard.tsx';
import ArchieveBook from '../../../../assets/icons/ArchieveBook';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import {
    creativityData,
    criticalData,
    ExperienceLevelRatings,
    experienceLevels,
    ExperienceLevelTypes,
    leadershipData,
    manageabilityData,
    personalData,
    persuasiveData
} from '../../constants';
import { useGetProfessionalRatingById } from '../../../../utils/hooks/api/professional-ratings/useGetProfessionalRatingById';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DialogBox from '../../components/DialogBox';
import { useGetProfessionalProfileById } from '../../../../utils/hooks/api/professional-profile/useGetProfessionalProfile';
import { useUpdateRating } from '../../../../utils/hooks/api/ratings/usePatchRatings';
import { useUpdateReviewRequest } from '../../../../utils/hooks/api/reviews/useUpdateReviewRequest';
import { useGetProfessionalProfileScore } from '../../../../utils/hooks/api/professional-profile/useGetProfessionalProfileScore';
import { useAuth } from '../../../../utils/context/AuthContext';
import { usePostComment } from '../../../../utils/hooks/api/comments/useCreateComment';
import { QueryKeys } from '../../../../utils/hooks/api/QueryKey';
import { useQueryClient } from 'react-query';
import { logger } from '../../../../utils/Helper/logger';
import { generateHtml, generateSubject } from '../../../../utils/emails/emailtemplates';
import { provideFeedBack } from '../../../../utils/emails/email';
import axiosInstance from '../../../../utils/hooks/api/axiosConfig';
import { Responsiveness } from '../../../../theme/overrides';
import { useGetUserPostById } from '../../../../utils/hooks/api/posts/useGetUserPostById';
import ReviewPost from '../../components/ReviewPost';
import SkillsChips from '../../components/SkillsChips';
import { FeedItem } from '../../../Home/components/FeedItem';
import { Chip } from '@mui/material';


interface ISkillType {
    name: string;
    type: string;
    rating: number;
    level: string;
    witness: boolean;
}
const GeneralRatingProfessional = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const queryClient = useQueryClient();
    const postComment = usePostComment();
    const [loading, setLoading] = useState(false);
    const [ratings, setRatings] = useState<ISkillType[]>([]);
    const [comment, setComment] = useState('');
    const queryParams = new URLSearchParams(location.search);
    const profileId = queryParams.get('profileId');
    const requestId = queryParams.get('requestId');
    const [showModal, setShowModal] = useState(false);
    const { id } = useParams();
    const { data: score } = useGetProfessionalProfileScore(profileId ?? '');
    const { data: post } = useGetUserPostById(location?.state?.postId ?? '')
    const { isMobile, isTablet } = Responsiveness()
    const [criticalThinking, setCriticalThinking] =
        useState<IEvidenceType[]>(criticalData);
    const [persuasiveCommunication, setPersuasiveCommunication] =
        useState<IEvidenceType[]>(persuasiveData);
    const [personalOrganization, setPersonalOrganization] =
        useState<IEvidenceType[]>(personalData);
    const [manageabilitySkills, setManageabilitySkills] =
        useState<IEvidenceType[]>(manageabilityData);
    const [leadershipSkills, setLeadershipSkills] =
        useState<IEvidenceType[]>(leadershipData);
    const [creativitySkills, setCreativitySkills] =
        useState<IEvidenceType[]>(creativityData);

    const { data, isFetching } = useGetProfessionalRatingById(id ?? '');

    const feed = {
        profileImage:
            location.state.user?.professionalRating?.professionalProfile
                ?.photoUrl,
        fullName: `${location.state.user?.professionalRating?.professionalProfile?.firstName} ${location.state.user?.professionalRating?.professionalProfile?.lastName}`,
        jobTitle:
            location.state.user?.professionalRating?.professionalProfile
                ?.currentEmployment?.jobTitle,
        description: location?.state?.user?.post?.message,
        views: location?.state?.user?.post?.views,
        likes:
            (post?.comments && post?.comments[0]?.likes) ??
            (post?.comments && post?.comments[post?.comments?.length - 1]?.likes) ?? 0,
        commentsCount: post?.commentsCount,
        comments: post?.comments,
        shares: post?.repostCount,
        id: post?.id,
        images: location.state.user?.post?.photos ?? [],
        isAccountVerified: true
    };

    logger.log('GetProfessionalRatingById=>', data)
    
    const {
        data: professionalProfile,
        isFetching: isProfessionalRatingFetching
    } = useGetProfessionalProfileById(profileId ?? '');

    
    const updateRequest = useUpdateReviewRequest();
    const handleSkillChange = (
        rating: number,
        type: string,
        name: string,
        level: string,
        witness: boolean = true
    ) => {
        if (ratings.find((x) => x.name === name)) {
            setRatings((prev) => prev.filter((x) => x.name !== name));
        }
        if (witness) {
            setRatings((prev) => [
                ...prev,
                {
                    name,
                    rating,
                    type,
                    level,
                    witness
                }
            ]);
        } else {
            setRatings((prev) => [
                ...prev,
                {
                    name,
                    rating,
                    type,
                    level,
                    witness
                }
            ]);
        }
    };
    const patchRating = useUpdateRating();

    const handleRatingChange = (
        rating: number,
        state: IEvidenceType[],
        setState: React.Dispatch<React.SetStateAction<IEvidenceType[]>>,
        i: number
    ) => {
        let temp: IEvidenceType[] = JSON.parse(JSON.stringify(state));
        temp[i].rating = rating;
        setState(temp);
    };




    
    const handleOnSubmit = () => {
        setLoading(true);
        const t1 = ratings.reduce((a, b) => a + b.rating, 0);
        const t2 = criticalThinking.reduce((a, b) => a + b.rating, 0);
        const t3 = persuasiveCommunication.reduce((a, b) => a + b.rating, 0);
        const t4 = personalOrganization.reduce((a, b) => a + b.rating, 0);
        const t5 = manageabilitySkills.reduce((a, b) => a + b.rating, 0);
        const t6 = leadershipSkills.reduce((a, b) => a + b.rating, 0);
        const t7 = creativitySkills.reduce((a, b) => a + b.rating, 0);
        const totalExtras = t2 + t3 + t4 + t5 + t6 + t7;
        const noOfSkills =
            criticalThinking.length +
            persuasiveCommunication.length +
            personalOrganization.length +
            manageabilitySkills.length +
            leadershipSkills.length +
            creativitySkills.length;
        let temp: ISkillType[] = ratings;
        const avg = Math.ceil((t1 + totalExtras) / (noOfSkills + temp.length));
        patchRating.mutate(
            { ratings: ratings, rating: avg, id, profileId },
            {
                onSuccess: (res) => {
                    console.log(res);
                    updateRequest.mutate(
                        { status: 'SUBMITTED', id: requestId ?? '' },
                        {
                            onSuccess: (res) => {
                                console.log('first', res);
                                setShowModal(true);
                                setLoading(false);
                            },
                            onError: (err) => {
                                console.log(err);
                                setLoading(false);
                            }
                        }
                    );
                },
                onError: (err) => {
                    console.log(err);
                    setLoading(false);
                }
            }
        );
    };

    const handlePostComment = () => {
        const request: any = {
            userId: user?.id ?? '',
            postId: location.state.postId ?? '',
            comment,
            commentId: ''
        };
        postComment.mutate(request, {
            onSuccess: (res) => {
                setComment('');
                queryClient.invalidateQueries(QueryKeys.RetrieveUserPosts);
            },
            onError: (err) => {
                console.log('ERR', err);
            }
        });
    };

    useEffect(() => {
        let skillsData: ISkillType[] = [];
        data?.data.map((skill: ISkillType) => {
            skillsData.push({
                name: skill.name,
                rating: ExperienceLevelRatings.beginner,
                type: skill.type,
                level: ExperienceLevelTypes.beginner,
                witness: true
            });
            return null;
        });
        setRatings(skillsData);
    }, [data?.data]);

    const revieweeName =
        professionalProfile?.firstName + ' ' + professionalProfile?.lastName;
    return (
        <>
            <Paper sx={{ ml: isTablet ? undefined : '35px' }}>
                <PageHeader
                    onBackNavigate={'/reviews/received-requests'}
                    pageTitle={'Received Requests'}
                    hideAction={true}
                />
                {isFetching || isProfessionalRatingFetching ? null : (
                    <Box>
                        {isMobile ? (
                            <Box>
                                <Box
                                    sx={{
                                        backgroundColor: 'white'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            backgroundColor: 'white',
                                            borderBottom: '1px solid  #D9D9D9',
                                            display: 'flex',
                                            padding: '16px',
                                            alignItems: 'center',
                                            gap: '20px',
                                            marginTop: '20px'
                                        }}
                                    >
                                        <Typography variant="bodyLargeSemiBold">
                                            {professionalProfile?.firstName}{' '}
                                            {professionalProfile?.lastName}{' '}
                                            <Typography variant="bodyLargeRegular">
                                                Completed a course
                                            </Typography>
                                        </Typography>
                                    </Box>
                                    <FeedItem feed={feed} />
                                    <Box px='16px' display='flex' flexDirection='column' gap='16px'>
                                        <Typography
                                            display="block"
                                            variant="titleLargeSemiBold"
                                        >
                                            Skills
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                gap: '12px'
                                            }}
                                        >
                                            {location.state.user.skills?.data?.map(
                                                (skill: any) => (
                                                    <Chip
                                                        sx={{
                                                            backgroundColor:
                                                                '#f2f2f2',
                                                            fontSize: '14px',
                                                            color: '#808080',
                                                            border: '1px solid #C5C5C5',
                                                            p: '4px 10px'
                                                        }}
                                                        label={skill?.name}
                                                        variant="outlined"
                                                    />
                                                )
                                            )}
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        ) : null}
                        {isMobile ? null : (
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: isTablet ? '20px' : '28px 40px',
                                    gap: isTablet ? '20px' : '42px',
                                    border: isTablet
                                        ? undefined
                                        : '1px solid #D9D9D9',
                                    pl: isTablet ? undefined : '28px',
                                    pr: isTablet ? undefined : '28px',
                                    mt: '32px'
                                }}
                            >
                                <Avatar
                                    sx={{
                                        width: isTablet ? '100px' : '200px',
                                        height: isTablet ? '100px' : '200px'
                                    }}
                                    src={professionalProfile?.photoUrl}
                                />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: isTablet ? '8px' : undefined,
                                        alignSelf: 'flex-start',
                                        mr: 'auto'
                                    }}
                                >
                                    <Typography
                                        variant="headlineLargeBold"
                                        sx={{
                                            mt: isTablet ? undefined : '15px',
                                            display: 'block'
                                        }}
                                        fontSize={isTablet ? '24px' : undefined}
                                    >
                                        {`${
                                            professionalProfile?.firstName ?? ''
                                        } ${
                                            professionalProfile?.lastName ?? ''
                                        }`}
                                    </Typography>
                                    <Typography
                                        variant="titleLargeSemiBold"
                                        sx={{
                                            mt: isTablet ? undefined : '22px',
                                            display: 'block'
                                        }}
                                        fontSize={isTablet ? '16px' : undefined}
                                    >
                                        {professionalProfile?.tagline ?? ''}
                                    </Typography>
                                    <Typography
                                        fontSize={isTablet ? '16px' : undefined}
                                        sx={{
                                            color: '#808080',
                                            mt: isTablet ? undefined : '8px'
                                        }}
                                    >
                                        {`${
                                            professionalProfile?.address
                                                ?.city ?? ''
                                        } ${
                                            professionalProfile?.address
                                                ?.country ?? ''
                                        } `}
                                    </Typography>
                                    <Typography
                                        variant="titleMediumBold"
                                        sx={{
                                            display: 'block',
                                            mt: isTablet ? '12px' : '35px'
                                        }}
                                    >
                                        {professionalProfile?.connections &&
                                        professionalProfile?.connections > 400
                                            ? '400+'
                                            : professionalProfile?.connections}
                                        &nbsp;
                                        <Typography
                                            component="span"
                                            sx={{
                                                color: '#808080'
                                            }}
                                        >
                                            Connections
                                        </Typography>
                                    </Typography>
                                </Box>
                                <ScoreCard score={score} />
                            </Box>
                        )}
                        <Box
                            sx={{
                                pl: isMobile
                                    ? '16px'
                                    : isTablet
                                    ? '20px'
                                    : '28px',
                                pr: isMobile
                                    ? '16px'
                                    : isTablet
                                    ? '20px'
                                    : '28px',
                                pb: '27px'
                            }}
                        >
                            <Box
                                sx={{
                                    borderTop: '1px solid #D9D9D9',
                                    borderBottom: '1px solid #D9D9D9',
                                    mt: '20px',
                                    pb: '20px'
                                }}
                            >
                                <Typography
                                    variant="headlineSmallSemiBold"
                                    sx={{
                                        mt: isMobile ? '16px' : '70px',
                                        display: 'block',
                                        textAlign: isTablet
                                            ? 'flex-start'
                                            : 'center'
                                    }}
                                    fontSize={
                                        isMobile
                                            ? '16px'
                                            : isTablet
                                            ? '20px'
                                            : undefined
                                    }
                                >
                                    Skill Based Rating
                                </Typography>
                                <Typography
                                    sx={{
                                        mt: isMobile ? '16px' : '32px',
                                        mb: '8px'
                                    }}
                                >
                                    {revieweeName} said you witnessed his use of
                                    the following skills. Please share your
                                    thoughts on their competency level for
                                    each skills below.
                                </Typography>
                                {isMobile ? null : (
                                    <Typography
                                        variant="titleSmallRegular"
                                        sx={{
                                            color: '#808080',
                                            mt: '4px'
                                        }}
                                    >
                                        Note that rating this individual will
                                        impact your Jobfactor score, so ensure
                                        to be objective.
                                    </Typography>
                                )}
                                {isMobile ? (
                                    <Typography
                                        variant="titleSmallRegular"
                                        sx={{
                                            color: '#232b2b'
                                        }}
                                    >
                                        <Typography
                                            display="inline"
                                            fontWeight={600}
                                        >
                                            Note
                                        </Typography>{' '}
                                        that rating this individual will impact
                                        your Jobfactor score, so ensure
                                        to be objective.
                                    </Typography>
                                ) : null}
                            </Box>
                            <Box>
                                <Typography
                                    variant="titleMediumSemiBold"
                                    sx={{
                                        mt: '44px',
                                        display: 'block',
                                        mb: '8px'
                                    }}
                                >
                                    Did you witness the use of these skills ?
                                </Typography>
                                {data.data?.map(
                                    (item: ISkillType, index: number) => (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: isTablet
                                                    ? 'flex-start'
                                                    : 'center',
                                                flexDirection: isMobile
                                                    ? 'column'
                                                    : 'row',
                                                justifyContent: 'space-between',
                                                pt: '12px'
                                            }}
                                            key={`item_${index}`}
                                        >
                                            <Box
                                                sx={
                                                    {
                                                        // width: '40%'
                                                    }
                                                }
                                            >
                                                <Typography>
                                                    {item?.name}
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '70px'
                                                }}
                                            >
                                                <FormControlLabel
                                                    control={
                                                        <Radio
                                                            onChange={(e) =>
                                                                handleSkillChange(
                                                                    ExperienceLevelRatings.beginner,
                                                                    item?.type,
                                                                    item?.name,
                                                                    ExperienceLevelTypes.beginner,
                                                                    true
                                                                )
                                                            }
                                                            checked={
                                                                ratings.find(
                                                                    (x) =>
                                                                        x.witness ===
                                                                            true &&
                                                                        x.name ===
                                                                            item?.name
                                                                )
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    }
                                                    label="Yes"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Radio
                                                            onChange={(e) =>
                                                                handleSkillChange(
                                                                    0,
                                                                    item?.type,
                                                                    item?.name,
                                                                    '',
                                                                    false
                                                                )
                                                            }
                                                            checked={
                                                                ratings.find(
                                                                    (x) =>
                                                                        x.witness ===
                                                                            false &&
                                                                        x.name ===
                                                                            item?.name
                                                                )
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    }
                                                    label="No"
                                                />
                                            </Box>
                                        </Box>
                                    )
                                )}
                                <Typography
                                    variant="titleMediumSemiBold"
                                    sx={{
                                        display: 'block',
                                        mt: '70px',
                                        mb: '12px'
                                    }}
                                >
                                    Select your view of their competency level
                                    for the various skills
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '25px',
                                        flexWrap: 'wrap',
                                        mb: '8px'
                                    }}
                                >
                                    {experienceLevels.map((x) => (
                                        <Box key={`item_${x.shortForm}`}>
                                            <Typography
                                                variant="titleSmallSemiBold"
                                                sx={{
                                                    color: x.color ?? '#FFFFFF',
                                                    background: 'transparent',
                                                    borderRadius: '6px',
                                                    display: 'flex',
                                                    gap: '8px',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                {x.title}
                                                <Typography
                                                    variant="titleSmallBold"
                                                    sx={{
                                                        background: x.color,
                                                        padding: '0px 4px',
                                                        borderRadius: '4px',
                                                        color: '#FFFFFF'
                                                    }}
                                                >
                                                    {x.shortForm}
                                                </Typography>
                                            </Typography>
                                        </Box>
                                    ))}
                                </Box>
                                {data.data?.map(
                                    (item: ISkillType, index: number) => (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: isTablet
                                                    ? 'flex-start'
                                                    : 'center',
                                                flexWrap: 'wrap',
                                                justifyContent: 'space-between',
                                                pt: '12px'
                                            }}
                                            key={`item_${index}`}
                                        >
                                            <Box
                                                sx={
                                                    {
                                                        // width: '40%'
                                                    }
                                                }
                                            >
                                                <Typography>
                                                    {item?.name}
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: isMobile
                                                        ? undefined
                                                        : isTablet
                                                        ? '32px'
                                                        : '70px',
                                                    justifyContent:
                                                        'space-between',
                                                    width: isMobile
                                                        ? '100%'
                                                        : undefined
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <Radio
                                                        disabled={
                                                            ratings.find(
                                                                (x) =>
                                                                    !x.witness &&
                                                                    x.name ===
                                                                        item?.name
                                                            )
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={() =>
                                                            handleSkillChange(
                                                                ExperienceLevelRatings.beginner,
                                                                item?.type,
                                                                item?.name,
                                                                ExperienceLevelTypes.beginner
                                                            )
                                                        }
                                                        checked={
                                                            ratings.find(
                                                                (x) =>
                                                                    x.level ===
                                                                        ExperienceLevelTypes.beginner &&
                                                                    x.name ===
                                                                        item?.name
                                                            )
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    <Typography
                                                        variant="labelLargeBold"
                                                        sx={{
                                                            background:
                                                                '#E75541',
                                                            padding: '0px 4px',
                                                            borderRadius: '4px',
                                                            color: '#FFFFFF'
                                                        }}
                                                    >
                                                        B
                                                    </Typography>
                                                </Box>

                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <Radio
                                                        disabled={
                                                            ratings.find(
                                                                (x) =>
                                                                    !x.witness &&
                                                                    x.name ===
                                                                        item?.name
                                                            )
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={() =>
                                                            handleSkillChange(
                                                                ExperienceLevelRatings.experienced,
                                                                item?.type,
                                                                item?.name,
                                                                ExperienceLevelTypes.experienced
                                                            )
                                                        }
                                                        checked={
                                                            ratings.find(
                                                                (x) =>
                                                                    x.level ===
                                                                        ExperienceLevelTypes.experienced &&
                                                                    x.name ===
                                                                        item?.name
                                                            )
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    <Typography
                                                        variant="labelLargeBold"
                                                        sx={{
                                                            background:
                                                                '#F6C70E',
                                                            padding: '0px 4px',
                                                            borderRadius: '4px',
                                                            color: '#FFFFFF'
                                                        }}
                                                    >
                                                        E
                                                    </Typography>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <Radio
                                                        disabled={
                                                            ratings.find(
                                                                (x) =>
                                                                    !x.witness &&
                                                                    x.name ===
                                                                        item?.name
                                                            )
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={() =>
                                                            handleSkillChange(
                                                                ExperienceLevelRatings.advanced,
                                                                item?.type,
                                                                item?.name,
                                                                ExperienceLevelTypes.advanced
                                                            )
                                                        }
                                                        checked={
                                                            ratings.find(
                                                                (x) =>
                                                                    x.level ===
                                                                        ExperienceLevelTypes.advanced &&
                                                                    x.name ===
                                                                        item?.name
                                                            )
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    <Typography
                                                        variant="labelLargeBold"
                                                        sx={{
                                                            background:
                                                                '#49B6FF',
                                                            padding: '0px 4px',
                                                            borderRadius: '4px',
                                                            color: '#FFFFFF'
                                                        }}
                                                    >
                                                        A
                                                    </Typography>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <Radio
                                                        disabled={
                                                            ratings.find(
                                                                (x) =>
                                                                    !x.witness &&
                                                                    x.name ===
                                                                        item?.name
                                                            )
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={() =>
                                                            handleSkillChange(
                                                                ExperienceLevelRatings.expert,
                                                                item?.type,
                                                                item?.name,
                                                                ExperienceLevelTypes.expert
                                                            )
                                                        }
                                                        checked={
                                                            ratings.find(
                                                                (x) =>
                                                                    x.level ===
                                                                        ExperienceLevelTypes.expert &&
                                                                    x.name ===
                                                                        item?.name
                                                            )
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    <Typography
                                                        variant="labelLargeBold"
                                                        sx={{
                                                            background:
                                                                '#7ABE57',
                                                            padding: '0px 4px',
                                                            borderRadius: '4px',
                                                            color: '#FFFFFF'
                                                        }}
                                                    >
                                                        X
                                                    </Typography>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <Radio
                                                        disabled={
                                                            ratings.find(
                                                                (x) =>
                                                                    !x.witness &&
                                                                    x.name ===
                                                                        item?.name
                                                            )
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={() =>
                                                            handleSkillChange(
                                                                ExperienceLevelRatings.thought_leader,
                                                                item?.type,
                                                                item?.name,
                                                                ExperienceLevelTypes.thought_leader
                                                            )
                                                        }
                                                        checked={
                                                            ratings.find(
                                                                (x) =>
                                                                    x.level ===
                                                                        ExperienceLevelTypes.thought_leader &&
                                                                    x.name ===
                                                                        item?.name
                                                            )
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    <Typography
                                                        variant="labelLargeBold"
                                                        sx={{
                                                            background:
                                                                '#07AF22',
                                                            padding: '0px 4px',
                                                            borderRadius: '4px',
                                                            color: '#FFFFFF'
                                                        }}
                                                    >
                                                        T
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )
                                )}
                            </Box>
                            {/* <Box
                                sx={{
                                    borderBottom: '1px solid #D9D9D9',
                                    mt: '20px',
                                    pb: '20px'
                                }}
                            >
                                <Typography
                                    variant="headlineSmallSemiBold"
                                    sx={{
                                        mt: '117px',
                                        display: 'block',
                                        textAlign: 'center'
                                    }}
                                >
                                    General Rating
                                </Typography>
                                <Typography
                                    sx={{
                                        mt: '32px'
                                    }}
                                >
                                    Please rate your perception of{' '}
                                    {revieweeName} with regards to each
                                    statement below. You can rate {revieweeName}{' '}
                                    as having “No Evidence” through “Strong
                                    Evidence” of demonstrating what is described
                                    in each statement. For example, if you do
                                    not feel {revieweeName} demonstrates what is
                                    described in the statement, then select “No
                                    Evidence”. Respond to all survey items
                                    below, then click “Submit Review”.
                                </Typography>
                                <Typography
                                    variant="titleSmallRegular"
                                    sx={{
                                        color: '#808080',
                                        display: 'block',
                                        mt: '4px'
                                    }}
                                >
                                    Note that rating this individual will impact
                                    your Jobfactor score, so ensure
                                    to be objective.
                                </Typography>
                            </Box>
                            {generalSkills.map((item) => (
                                <RatingSection
                                    key={item.title}
                                    {...item}
                                    handleChange={handleRatingChange}
                                />
                            ))}*/}
                            {isMobile ? null : (
                                <>
                                    <Box
                                        sx={{
                                            padding: '20px',
                                            background: '#FFFAF1',
                                            borderBottom: '1px solid #D9D9D9',
                                            mt: '50px'
                                        }}
                                    >
                                        <TextField
                                            value={comment}
                                            onChange={(e) =>
                                                setComment(e.target.value)
                                            }
                                            sx={{
                                                '& .MuiInput-underline': {
                                                    fontSize: '14px'
                                                },
                                                '& .MuiInput-underline::before':
                                                    {
                                                        border: '0px !important'
                                                    },
                                                '& .MuiInput-underline::after':
                                                    {
                                                        border: '0px !important'
                                                    }
                                            }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <ArchieveBook />
                                                    </InputAdornment>
                                                )
                                            }}
                                            variant="standard"
                                            placeholder="Write a short comment: "
                                            fullWidth
                                        />
                                        <FormHelperText>
                                            {/* {professionalProfile?.firstName} is a very
                                    diligent individual. He put his heart into
                                    his jobs. And he is worth every review. */}
                                        </FormHelperText>
                                    </Box>
                                    <Box
                                        sx={{
                                            mt: '32px',
                                            textAlign: 'center'
                                        }}
                                    >
                                        <Button
                                            variant="contained"
                                            sx={{
                                                p: '12px 20px',
                                                width: 'auto',
                                                fontFamily: 'Open Sans',
                                                fontWeight: 600,
                                                fontSize: '16px'
                                            }}
                                            onClick={() => {
                                                handleOnSubmit();
                                                if (comment) {
                                                    handlePostComment();
                                                }
                                            }}
                                            disabled={loading}
                                        >
                                            Submit Review
                                        </Button>
                                    </Box>
                                </>
                            )}
                        </Box>
                        {isMobile ? (
                            <>
                                <Box
                                    sx={{
                                        padding: '20px',
                                        background: '#FFFAF1',
                                        borderBottom: '1px solid #D9D9D9',
                                        mt: '50px'
                                    }}
                                >
                                    <TextField
                                        value={comment}
                                        onChange={(e) =>
                                            setComment(e.target.value)
                                        }
                                        sx={{
                                            '& .MuiInput-underline': {
                                                fontSize: '14px'
                                            },
                                            '& .MuiInput-underline::before': {
                                                border: '0px !important'
                                            },
                                            '& .MuiInput-underline::after': {
                                                border: '0px !important'
                                            }
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <ArchieveBook />
                                                </InputAdornment>
                                            )
                                        }}
                                        variant="standard"
                                        placeholder="Write a short comment: "
                                        fullWidth
                                    />
                                    <FormHelperText>
                                        {/* {professionalProfile?.firstName} is a very
                                    diligent individual. He put his heart into
                                    his jobs. And he is worth every review. */}
                                    </FormHelperText>
                                </Box>
                                <Box
                                    sx={{
                                        mt: '32px',
                                        textAlign: 'center',
                                        px: '16px'
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        sx={{
                                            p: '12px 20px',
                                            width: '100%',
                                            fontFamily: 'Open Sans',
                                            fontWeight: 600,
                                            fontSize: '16px'
                                        }}
                                        onClick={() => {
                                            handleOnSubmit();
                                            if (comment) {
                                                handlePostComment();
                                            }
                                        }}
                                        disabled={loading}
                                    >
                                        Submit Review
                                    </Button>
                                </Box>
                            </>
                        ) : null}
                    </Box>
                )}
            </Paper>
            <DialogBox
                title="Review submitted"
                description=''
                open={showModal}
                handleClose={() => setShowModal(false)}
                handleContinue={() => {
                    navigate('/reviews/received-requests');
                }}
            />
        </>
    );
};

export default GeneralRatingProfessional;
