const AnalyticsGraph = () => (
    <svg
        width="37"
        height="36"
        viewBox="0 0 37 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M32.8353 10.4241C31.8753 7.16912 29.1603 4.45412 25.9053 3.49412C23.4303 2.77412 21.7203 2.83412 20.5353 3.71912C19.1103 4.78412 18.9453 6.70412 18.9453 8.06912V11.8041C18.9453 15.4941 20.6253 17.3691 23.9253 17.3691H28.2303C29.5803 17.3691 31.5153 17.2041 32.5803 15.7791C33.4953 14.6091 33.5703 12.8991 32.8353 10.4241Z"
            fill="#49B6FF"
        />
        <path
            opacity="0.4"
            d="M28.6971 20.0417C28.3071 19.5917 27.7371 19.3367 27.1521 19.3367H21.7821C19.1421 19.3367 16.9971 17.1917 16.9971 14.5517V9.18169C16.9971 8.59669 16.7421 8.02669 16.2921 7.63669C15.8571 7.24669 15.2571 7.06669 14.6871 7.14169C11.1621 7.59169 7.92212 9.52669 5.80712 12.4367C3.67712 15.3617 2.89712 18.9317 3.57212 22.5017C4.54712 27.6617 8.67212 31.7867 13.8471 32.7617C14.6721 32.9267 15.4971 33.0017 16.3221 33.0017C19.0371 33.0017 21.6621 32.1617 23.8971 30.5267C26.8071 28.4117 28.7421 25.1717 29.1921 21.6467C29.2671 21.0617 29.0871 20.4767 28.6971 20.0417Z"
            fill="#49B6FF"
        />
    </svg>
);

export default AnalyticsGraph;
