import {
    Box,
    Button,
    CircularProgress,
    Grid,
    IconButton,
    TextField,
    Typography
} from '@mui/material';
import { ArrowLeftIcon } from '../../../assets/icons/ArrowLeftIcon';
import { useNavigate } from 'react-router-dom';
import ScoreCard from '../../../components/ScoreCard.tsx';
import { useAuth } from '../../../utils/context/AuthContext';
import { IScoreProperties } from './types/ScoreProperties';
import { Fragment, ReactNode, useState } from 'react';
import { useGetProfessionalProfileScore } from '../../../utils/hooks/api/professional-profile/useGetProfessionalProfileScore';
import { useGetScoreProperties } from '../../../utils/hooks/api/professional-profile/useGetScoreProperties';
import { Responsiveness } from '../../../theme/overrides';
import MobileNav from '../../../components/MobileNav/MobileNav';
import Impact from '../../../assets/icons/Impact';
import { Radio } from '@mui/material';
import ArrowForwardC from '../../Connections/components/ArrowForwardC';
import Increase from '../../../assets/icons/Increase';
import ArrowForward from '../../../assets/icons/ArrowForward';
import ScoringCard from './components/ScoringCard';
import { logger } from '../../../utils/Helper/logger';

const JobFactorScore = () => {
    const navigate = useNavigate();
    const { user } = useAuth();
    const [currentTab, setCurrentTab] = useState('Skills');
    const { isTablet, isMobile } = Responsiveness();
    const nullValueLabel = 'N/A';
    const { data: score } = useGetProfessionalProfileScore(
        user?.professionalProfile?.id!
    );
    const {
        data: scoreProperties,
        isFetching,
        isRefetching,
        isError
    } = useGetScoreProperties(user?.professionalProfile?.id!);
    logger.log(scoreProperties);

    const routes = [
        {
            label: 'About me',
            route: '/users'
        },
        {
            label: 'Activities',
            route: '/activities'
        },
        {
            label: 'Jobfactor Score',
            route: '/jobfactor-score'
        },
        {
            label: 'Skills',
            route: '/skills'
        }
    ];

    const tabs = ['Skills', 'Education', 'Work History', 'Feedback'];

    const TabHeader = ({
        header,
        rating,
        impact
    }: {
        header: string;
        rating: number;
        impact: string;
    }) => {
        return (
            <Box p={{ xs: '16px', sm: '20px', lg: '32px 40px' }}>
                <Typography
                    color="#23282B"
                    fontSize={{ xs: '16px', sm: '20px' }}
                    fontWeight={600}
                    mb={{ xs: '4px', sm: '16px', lg: '20px' }}
                >
                    {header}
                </Typography>
                <Typography
                    color="#808080"
                    fontSize={{ xs: '14px', sm: '16px' }}
                    mb={{ xs: '4px', sm: '16px' }}
                >
                    Take a look at your {header.toLowerCase()} to see where you
                    could improve. This score is derived from ratings, test
                    score, and valid information from your profile.
                </Typography>
                <Box display="flex" alignItems="center" gap="8px">
                    <Typography
                        color="#23282B"
                        fontSize={{ xs: '16px', sm: '20px' }}
                        fontWeight={600}
                    >
                        {impact} IMPACT
                    </Typography>
                    <Box display="flex" alignItems="center" gap="4px">
                       <Radio checked={rating >= 1} sx={{p: 0, m: 0}} />
                       <Radio checked={rating >= 2} sx={{p: 0, m: 0}} />
                       <Radio checked={rating >= 3} sx={{p: 0, m: 0}} />
                    </Box>
                </Box>
            </Box>
        );
    };

    const getHeader = () => {
        switch (currentTab) {
            case 'Skills':
                return (
                    <TabHeader
                        header="Skill scoring"
                        impact="HIGH"
                        rating={3}
                    />
                );
            case 'Education':
                return (
                    <TabHeader
                        header="Educational qualification scoring  "
                        impact="LOW"
                        rating={1}
                    />
                );
            case 'Work History':
                return (
                    <TabHeader
                        header="Work history scoring  "
                        impact="MEDIUM"
                        rating={2}
                    />
                );
            case 'Feedback':
                return (
                    <TabHeader
                        header="Feedback activities scoring  "
                        impact="MEDIUM"
                        rating={2}
                    />
                );
            default:
                return (
                    <TabHeader
                        header="Skill scoring"
                        impact="HIGH"
                        rating={3}
                    />
                );
        }
    };

    return (
        <Box mt={{ xs: '6px', sm: 0 }} pr={{ xs: 0, sm: '20px', lg: '40px' }}>
            {isMobile ? <MobileNav list={routes} /> : null}
            <Box
                sx={{
                    // background: '#FFFFFF',
                    height: '100%',
                    // pl: isMobile ? '16px' : '40px',
                    // pr: isMobile ? '16px' : '40px',
                    pb: '32px'
                    // marginTop: '-38px'
                }}
            >
                <Box
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        borderRadius: '12px 12px 0  0',
                        pr: { xs: 0, lg: '40px' }
                    }}
                    bgcolor="#fff"
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Box
                            p={{ xs: '16px', sm: '24px 20px', lg: '30px 40px' }}
                            borderBottom="1px solid #C5C5C5"
                            sx={{
                                display: 'flex',
                                gap: '24px',
                                alignItems: 'flex-start',
                                alignSelf: 'flex-start',
                                width: '100%',
                                maxWidth: '866px'
                            }}
                        >
                            <IconButton onClick={() => navigate(-1)}>
                                <ArrowLeftIcon />
                            </IconButton>
                            <Typography
                                // component={'h2'}
                                mt="5px"
                                sx={{
                                    fontSize: '24px',
                                    fontWeight: '600',
                                    fontFamily: 'Open Sans',
                                    color: '#23282B'
                                }}
                            >
                                Jobfactor Score Metrics
                            </Typography>
                        </Box>
                        <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
                            <ScoreCard
                                width="122px"
                                height="158px"
                                score={score}
                            />
                        </Box>
                    </Box>
                </Box>
                {isMobile ? null : isTablet ? (
                    <MobileNav list={routes} />
                ) : null}
                <Box
                    p={{ xs: '8px 16px', sm: '10px 20px', lg: '20px 40px' }}
                    bgcolor="white"
                    mb={{ xs: '5px', sm: '8px', lg: '12px' }}
                >
                    <Box
                        p={{ xs: '4px', sm: '8px', lg: '12px' }}
                        borderRadius={{ xs: '10px', sm: '12px' }}
                        border="1px solid #C5C5C5"
                    >
                        <Grid
                            container
                            wrap="nowrap"
                            sx={{ borderRadius: { xs: '8px', lg: '12px' } }}
                        >
                            {tabs.map((tab, i) => (
                                <Grid
                                    key={tab}
                                    item
                                    xs={3}
                                    sx={{
                                        pt: {
                                            xs: '11px',
                                            sm: '8px',
                                            lg: '12px'
                                        },
                                        pb: {
                                            xs: '11px',
                                            sm: '8px',
                                            lg: '8px'
                                        },
                                        bgcolor:
                                            currentTab === tab
                                                ? '#05668D'
                                                : '#EDEDED',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius:
                                            i === 0
                                                ? {
                                                      xs: '8px 0 0 8px',
                                                      lg: '12px 0 0 12px'
                                                  }
                                                : i + 1 === tabs.length
                                                ? {
                                                      xs: '0 8px 8px 0',
                                                      lg: '0 12px 12px 0'
                                                  }
                                                : 0,
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => setCurrentTab(tab)}
                                >
                                    <Typography
                                        fontSize={{ xs: '12px', sm: '14px' }}
                                        color={
                                            currentTab === tab
                                                ? '#fff'
                                                : '#808080'
                                        }
                                        fontWeight={
                                            currentTab === tab ? 600 : 400
                                        }
                                    >
                                        {tab}
                                    </Typography>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Box>
                {isFetching || isRefetching ? (
                    <LoadingErrorLayout>
                        <CircularProgress size={200} color="primary" />
                    </LoadingErrorLayout>
                ) : !scoreProperties || isError ? (
                    <LoadingErrorLayout>
                        <Typography variant="h3">No data found.</Typography>
                        <Typography variant="h6">
                            This may be because you have not finished setting up
                            your profile or because of a server error.
                        </Typography>
                    </LoadingErrorLayout>
                ) : (
                    <Box bgcolor="#fff">
                        <Box borderBottom="1px solid #C5C5C5">
                            {getHeader()}
                        </Box>
                        <Box
                            p={{ xs: '16px', sm: '20px', lg: '32px 134.5px' }}
                                    width="100%"
                                    display='flex'
                                    gap='50px' 
                                    flexWrap='wrap'
                        >
                            {currentTab === 'Skills' && (
                                <>
                                    <ScoringCard
                                        title="Functional / Technical Skill"
                                        value={'Avg: 90'}
                                        subTitle="Average score of functional skill derived from ratings and test"
                                    />
                                    <ScoringCard
                                        title="Behavioral Skill"
                                        value={'Avg: 74'}
                                        subTitle="Average score of behavioral skill as rated by peer"
                                    />
                                   
                                </>
                            )}
                            {currentTab === 'Education' && (
                                <>
                                    <ScoringCard
                                        title="Level of education"
                                        value={
                                            scoreProperties.educationLevel ??
                                            nullValueLabel
                                        }
                                        subTitle="Highest level of education attained"
                                    />
                                </>
                            )}
                            {currentTab === 'Work History' && (
                                <>
                                    <ScoringCard
                                        title="Years of experience "
                                        value={
                                            scoreProperties.totalExperienceYear?.toString() ??
                                            nullValueLabel
                                        }
                                        subTitle="Total years of experience"
                                    />
                                    <ScoringCard
                                        title="Level of responsibility"
                                        value={
                                            scoreProperties.employmentLevel ??
                                            nullValueLabel
                                        }
                                        subTitle="Current level of responsibility"
                                    />
                                    <ScoringCard
                                        title="Consistency"
                                        value={
                                            scoreProperties.yearsAtCurrentEmployment?.toString() ??
                                            nullValueLabel
                                        }
                                        subTitle="Length of service at current employer"
                                    />
                                    <ScoringCard
                                        title="Company size"
                                        value={nullValueLabel}
                                        subTitle="Company size of current workplace"
                                    />
                                </>
                            )}
                            {currentTab === 'Feedback' && (
                                <>
                                    <ScoringCard
                                        title="Feedback completed"
                                        value={
                                            scoreProperties.feedbackCompletedPercent +
                                                '%' ?? nullValueLabel
                                        }
                                        subTitle="This is measured in percentage and on rolling basis"
                                    />
                                    <ScoringCard
                                        title="Biased Feedback"
                                        value={
                                            scoreProperties.feedbackGivenDeemedBiasedPercent +
                                                '%' ?? nullValueLabel
                                        }
                                        subTitle="Feedback that is skewed towards bias and deemed as outlier"
                                    />
                                </>
                            )}
                        </Box>
                    </Box>
                    // <Fragment>
                    //     <Box
                    //         sx={{
                    //             display: 'flex',
                    //             justifyContent: 'space-between',
                    //             mt: '56px',
                    //             mb: '28px'
                    //         }}
                    //     >
                    //         <Typography
                    //             component={'h1'}
                    //             sx={{
                    //                 fontSize: '20px',
                    //                 fontWeight: '600',
                    //                 fontFamily: 'Open Sans',
                    //                 color: '#494949'
                    //             }}
                    //         >
                    //             Education
                    //         </Typography>
                    //         <Button
                    //             variant="contained"
                    //             sx={{
                    //                 maxWidth: '110px',
                    //                 fontSize: '14px',
                    //                 background: '#E75541',
                    //                 padding: '6px 16px',
                    //                 fontWeight: '600',
                    //                 fontFamily: 'Open Sans',
                    //                 whiteSpace: 'noWrap',
                    //                 borderRadius: '4px'
                    //             }}
                    //         >
                    //             Low impact
                    //         </Button>
                    //     </Box>
                    //     <Box
                    //         component={'span'}
                    //         sx={{
                    //             '& .Mui-disabled.MuiFilledInput-root': {
                    //                 background: '#FCFBF8'
                    //             }
                    //         }}
                    //     >
                    //         <InputField
                    //             label="Highest Degree"
                    //             defaultValue={
                    //                 scoreProperties.educationLevel ??
                    //                 nullValueLabel
                    //             }
                    //         />
                    //     </Box>
                    //     <Box
                    //         sx={{
                    //             display: 'flex',
                    //             justifyContent: 'space-between',
                    //             mt: '88px',
                    //             mb: '4px'
                    //         }}
                    //     >
                    //         <Typography
                    //             component={'h1'}
                    //             sx={{
                    //                 fontSize: '20px',
                    //                 fontWeight: '600',
                    //                 fontFamily: 'Open Sans',
                    //                 color: '#494949'
                    //             }}
                    //         >
                    //             Work History
                    //         </Typography>
                    //         <Button
                    //             variant="contained"
                    //             sx={{
                    //                 maxWidth: '139px',
                    //                 fontSize: '14px',
                    //                 background: '#49B6FF',
                    //                 padding: '6px 16px',
                    //                 fontWeight: '600',
                    //                 fontFamily: 'Open Sans',
                    //                 whiteSpace: 'noWrap',
                    //                 borderRadius: '4px'
                    //             }}
                    //         >
                    //             Medium impact
                    //         </Button>
                    //     </Box>
                    //     <InputField
                    //         label="Total years of experience"
                    //         defaultValue={
                    //             scoreProperties.totalExperienceYear?.toString() ??
                    //             nullValueLabel
                    //         }
                    //     />
                    //     <InputField
                    //         label="Current level of responsibility"
                    //         defaultValue={
                    //             scoreProperties.employmentLevel ??
                    //             nullValueLabel
                    //         }
                    //     />
                    //     <InputField
                    //         label="Length of service at current employment (Consistency)"
                    //         defaultValue={
                    //             scoreProperties.yearsAtCurrentEmployment?.toString() ??
                    //             nullValueLabel
                    //         }
                    //     />
                    //     <InputField
                    //         label="Company size"
                    //         defaultValue={
                    //             scoreProperties.currentCompanySize ??
                    //             nullValueLabel
                    //         }
                    //     />
                    //     <Box
                    //         sx={{
                    //             display: 'flex',
                    //             justifyContent: 'space-between',
                    //             mt: '88px',
                    //             mb: '4px'
                    //         }}
                    //     >
                    //         <Typography
                    //             component={'h1'}
                    //             sx={{
                    //                 fontSize: '20px',
                    //                 fontWeight: '600',
                    //                 fontFamily: 'Open Sans',
                    //                 color: '#494949'
                    //             }}
                    //         >
                    //             Feedback Activities
                    //         </Typography>
                    //         <Button
                    //             variant="contained"
                    //             sx={{
                    //                 maxWidth: '139px',
                    //                 fontSize: '14px',
                    //                 background: '#49B6FF',
                    //                 padding: '6px 16px',
                    //                 fontWeight: '600',
                    //                 fontFamily: 'Open Sans',
                    //                 whiteSpace: 'noWrap',
                    //                 borderRadius: '4px'
                    //             }}
                    //         >
                    //             Medium impact
                    //         </Button>
                    //     </Box>
                    //     <InputField
                    //         label="Feedback completed"
                    //         defaultValue={
                    //             scoreProperties.feedbackCompletedPercent
                    //                 ? scoreProperties.feedbackCompletedPercent.toString() +
                    //                   '%'
                    //                 : nullValueLabel
                    //         }
                    //     />
                    //     <InputField
                    //         label="Invalid feedback"
                    //         defaultValue={'N/A'}
                    //     />
                    // </Fragment>
                )}
            </Box>
        </Box>
    );
};

export default JobFactorScore;

const InputField = ({
    label,
    defaultValue
}: {
    label: string;
    defaultValue: string;
}) => {
    return (
        <Box
            component={'span'}
            sx={{
                '& .Mui-disabled.MuiFilledInput-root': {
                    background: '#FCFBF8'
                },
                '& .MuiFilledInput-root': {
                    height: '70px'
                }
            }}
        >
            <TextField
                fullWidth
                disabled
                variant="filled"
                label={label}
                defaultValue={defaultValue}
                sx={{ mt: '24px' }}
            />
        </Box>
    );
};

const LoadingErrorLayout = ({ children }: { children: ReactNode }) => {
    return (
        <Box width="90%" height="40vh" display="flex">
            <Box margin="auto" textAlign="center">
                {children}
            </Box>
        </Box>
    );
};
