import { Box, Menu, Typography, MenuItem, Modal, Dialog, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react'
import PostAddIcon from '../../../assets/icons/PostAddIcon';
import PostCards from './PostCards';
import SnackAlert from '../../../components/Snackbar';
import { Responsiveness } from '../../../theme/overrides';
import MobilePlusIcon from '../../../assets/icons/MobilePlusIcon';
import PostOptions from './PostOptions';
import Camera from '../../../assets/icons/CameraIconMui';
import AchievementsIcon from '../../../assets/icons/AchievementsIcon';
import { ArticleIcon } from '../../../assets/icons/ArticleIcon';
import CloseXIcon from '../../../assets/icons/CloseXIcon';
import CameraPostOptionIcon from '../../../assets/icons/CameraPostOptionIcon';
import AchievementPostOptionIcon from '../../../assets/icons/AchievementPostOptionIcon';
import ArticlePostOptionIcon from '../../../assets/icons/ArticlePostOptionIcon';


const PostButton = () => {
    const {isMobile} = Responsiveness()
    const [showmodal, setShowModal] = useState<string>('');
    const [isScrolling, setIsScrolling] = useState(false);
        const [message, setMessage] = useState('');
        const [showAlert, setShowAlert] = useState(false);
    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 50) {
                setIsScrolling(true);
            } else {
                setIsScrolling(false);
            }
        });
    }, []);
    const [openMenu, setOpenMenu] = useState(false);
    const [open, setOpen] = useState(false);
    const anchor = Boolean(openMenu);
    const handleOpen = () => {
        setOpenMenu(!openMenu);
    };
    const handleClose = () => {
        setOpenMenu(false);
    };

    return (
        <Box position={'relative'}>
            <Box
                display={{ xs: isScrolling ? 'flex' : 'none', lg: 'none' }}
                onClick={handleOpen}
                sx={{
                    bgcolor: '#05668D',
                    height: '52px',
                    width: '52px',
                    borderRadius: '100%',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'fixed',
                    bottom: { xs: '90px', sm: '40px' },
                    right: { xs: '16px', sm: '40px' },
                    cursor: 'pointer',
                    zIndex: 1101,
                    transform: openMenu ? 'rotate(45deg)' : 'rotate(0)'
                }}
            >
                {/* <Box sx={{transform: openMenu ? 'rotate(45deg)' : 'rotate(0)'}}> */}
                <MobilePlusIcon />
                {/* </Box> */}
            </Box>
            <Box
                width="100%"
                maxWidth="340px"
                height="64px"
                display={{ xs: 'none', lg: isScrolling ? 'flex' : 'none' }}
                onClick={() => setOpen(true)}
                sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: '#05668D',
                    position: 'fixed',
                    bottom: '10%',
                    // ml: '16px',
                    right: '5%',
                    gap: '24px',
                    borderRadius: '80px',
                    cursor: 'pointer'
                    // right: '50%',
                    // transform: 'translate(-50%, -50%)'
                }}
            >
                <MobilePlusIcon />
                <Typography
                    color="#fff"
                    fontSize="16px"
                    fontWeight={600}
                    lineHeight="24px"
                    letterSpacing="0.08px"
                >
                    Post
                </Typography>
            </Box>
                <Dialog
                    open={open}
                    onClose={() => {
                        setOpen(false)
                    }}
                    maxWidth="lg"
                >
                    <Box
                        // position="absolute"
                        // top="50%"
                        // left="50%"
                        // sx={{ transform: 'translate(-50%, -50%)' }}
                        bgcolor="#fff"
                        width="800px"
                        zIndex={99}
                    >
                        <Box sx={{ px: '32px', pb: '62px' }}>
                            <Box pb="16px" mb="28px">
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    pt="24px"
                                    pb="16px"
                                    borderBottom="2px solid #D9D9D9"
                                    justifyContent="space-between"
                                    gap="16px"
                                >
                                    <Typography
                                        fontSize="20px"
                                        fontWeight={600}
                                        color="#23282B"
                                    >
                                        What is the post?
                                    </Typography>
                                    <Box
                                        sx={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            setOpen(false)
                                        }}
                                    >
                                        <CloseXIcon />
                                    </Box>
                                </Box>
                            </Box>
                            <MenuItem
                                sx={{
                                    p: '12px 32px',
                                    borderBottom: '1px solid #D8D8D8',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '16px',
                                    '&:hover': {
                                        bgcolor: '#EDEDED'
                                    }
                                }}
                                onClick={() => {
                                    setShowModal('open');
                                    setOpen(false);
                                }}
                            >
                                <CameraPostOptionIcon />
                                <Typography
                                    fontSize="16px"
                                    color="#808080"
                                    sx={{
                                        '&:hover': {
                                            color: '#23282B',
                                            fontWeight: 600
                                        }
                                    }}
                                >
                                    Create post
                                </Typography>
                            </MenuItem>
                            <MenuItem
                            onClick={() => {
                                setShowModal('achievement');
                                setOpen(false)
                            }}
                                sx={{
                                    p: '12px 32px',
                                    borderBottom: '1px solid #D8D8D8',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '16px',
                                    '&:hover': {
                                        bgcolor: '#EDEDED'
                                    }
                                }}
                            >
                                <AchievementPostOptionIcon />
                                <Typography
                                    fontSize="16px"
                                    color="#808080"
                                    sx={{
                                        '&:hover': {
                                            color: '#23282B',
                                            fontWeight: 600
                                        }
                                    }}
                                >
                                    Post an achievement
                                </Typography>
                            </MenuItem>
                            <MenuItem
                                onClick={() => setOpen(false)}
                                sx={{
                                    p: '12px 32px',
                                    borderBottom: '1px solid #D8D8D8',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '16px',
                                    '&:hover': {
                                        bgcolor: '#EDEDED'
                                    }
                                }}
                            >
                                <ArticlePostOptionIcon />
                                <Typography
                                    fontSize="16px"
                                    color="#808080"
                                    sx={{
                                        '&:hover': {
                                            color: '#23282B',
                                            fontWeight: 600
                                        }
                                    }}
                                >
                                    Write an article
                                </Typography>
                            </MenuItem>
                        </Box>
                    </Box>
                </Dialog>
            <SnackAlert
                open={showAlert}
                handleClose={() => setShowAlert(false)}
                message={message}
                type={'success'}
            />
            {showmodal && (
                <PostCards
                    showModal={showmodal ? true : false}
                    achievementModal={showmodal === 'achievement'}
                    data={null}
                    hideModal={(res) => {
                        setShowModal('');
                        if (res === 'success') {
                            setMessage('Post created successfully');
                            setShowAlert(true);
                        }
                    }}
                />
            )}
            {openMenu && isScrolling ? (
                <PostOptions
                    handleClose={handleClose}
                    setShowModal={setShowModal}
                />
            ) : null}
        </Box>
    );
}

export default PostButton
