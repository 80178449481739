import { useQuery } from 'react-query';
import { QueryKeys } from '../QueryKey';
import axiosInstance from '../axiosConfig';

export function useGetUserPostById(id: string) {
    const getUserPostById = async (id: string) => {
        const response = await axiosInstance.get(`/api/user-posts/post/${id}`);
        return response.data;
    };
    return useQuery(
        [QueryKeys.RetrieveUserPostById, id],
        () => getUserPostById(id),
        {
            refetchOnMount: 'always'
        }
    );
}
