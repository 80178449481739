import CircleIcon from '@mui/icons-material/Circle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import EastIcon from '@mui/icons-material/East';
import {
    Box,
    Typography,
    IconButton,
    Grid,
    Container,
    Divider,
    Button,
    Rating,
    Avatar,
    ImageListItem,
    CircularProgress
} from '@mui/material';
import cover from '../../assets/images/companyCover.png';
import profile from '../../assets/images/companyProfile.png';
import cambridge from '../../assets/images/cambridge.jpg';
import InfoChip from '../Users/components/InfoChip';
import Section from '../Users/components/Section';
import SectionHeading from '../Users/components/SectionHeading';
import Image from '../../components/Image';
import MedalIcon from '../../assets/icons/MedalIcon';
import AnalyticsProfile from '../../assets/icons/AnalyticsProfile';
import AnalyticsSearch from '../../assets/icons/AnalyticsSearch';
import AnalyticsGraph from '../../assets/icons/AnalyticsGraph';
import ScoreCard from '../../components/ScoreCard.tsx';
import { ArrowLeftIcon } from '../../assets/icons/ArrowLeftIcon';
import { useNavigate, useParams } from 'react-router-dom';
import DotIcon from '../../assets/icons/DotIcon';
import VerifiedIcon from '../../assets/icons/VerifiedIcon';
import ArrowRight from '../../assets/icons/ArrowRight';
import { useGetCompanyProfileById } from '../../utils/hooks/api/company-profile/useGetCompanyProfile';
import { useEffect, useRef, useState } from 'react';
import { PrimaryProfileType } from '../../utils/hooks/api/account/types';
import { useGetCompanyProfileScore } from '../../utils/hooks/api/company-profile/useGetCompanyProfileScore';
import { Responsiveness } from '../../theme/overrides';
import { useGetConnections } from '../../utils/hooks/api/connections/useGetConnections';
import ProfilePhotoIcon from '../../assets/icons/ProfilePhotoIcon';
import ProfileBanner from '../ProfileBanner';
import ArrowIconRight from '../../assets/icons/ArrowIconRight';
import Alert from '../../assets/icons/Alert';
import { usePost } from '../../utils/context/PostContext';
import { FeedItem } from '../Home/components/FeedItem';
import InfiniteScroll from 'react-infinite-scroll-component';
import SkeletonLoader from '../Home/components/SkeletonLoader';
import ImageList from '@mui/material/ImageList';
import ProfileList from '../Activities/components/ProfileList';
import profImg1 from '../../assets/images/profileReview.png';
import profImg2 from '../../assets/images/feed2.png';
import profImg3 from '../../assets/images/SideNavProfile.png';
import ExpandIcon from '../../assets/icons/ExpandIcon';
import TickCircle from '../../assets/icons/TickCircle';
import MoreIcon from '../../assets/icons/MoreIcon';
import SeeMoreIcon from '../Users/components/SeeMoreIcon';

const data = [
    { review: 'Salary, Compensation,Benefits', rating: 4 },
    { review: 'Work/Life balance, flexible work schedule', rating: 4 },
    {
        review: 'Carrer growth opportunities, reward and recoginition',
        rating: 4
    }
];
function CompanyProfile() {
    const { id } = useParams();
    // const navigate = useNavigate();
    const { data: company, refetch } = useGetCompanyProfileById(id ?? '');
    const { data: score } = useGetCompanyProfileScore(company?.id ?? '');
    const { data: connections } = useGetConnections(company?.id!);
    const { isMobile, isTablet } = Responsiveness();
    const [showAllPosts, setShowAllPosts] = useState(false);
    const [showAllBio, setShowAllBio] = useState(false);
    const maxBioLength = 2600;
            const [activity, setActivity] = useState('Posts');
            const currentActivity = ['Posts', 'Achievements', 'Articles', 'Jobs'];
            const { posts, totalPosts, isLoaded, setGetAfter } = usePost();
            let Images: any[] = [];
            posts?.map((postImages) =>
                postImages?.images?.map((image) => Images.push(image))
            );

        // const isAdded = connections?.filter(
        //     (connection: any) =>
        //         connection.destinationUser.userId === profile?.userId
        // );
        // const isConnected = isAdded?.length === 0 ? false : true;
        // const { data: sentRequests } = useGetConnectionRequestSent(
        //     user?.id ?? ''
        // );
        // logger.log('rofile', profile);
        // const requestSent = sentRequests?.filter(
        //     (connection: any) =>
        //         connection.destinationUser.userId === profile?.userId
        // );
        // const isRequested = requestSent?.length === 0 ? false : true;
    
            const professionals = [
        {
            profileImage: profImg1,
            fullName: 'Jane Cooper',
            jobTitle: 'Product Designer',
            score: 550
        },
        {
            profileImage: profImg2,
            fullName: 'Esther Howard',
            jobTitle: 'Accounting leader',
            score: 450
        },
        {
            profileImage: profImg3,
            fullName: 'Kathryn Murphy',
            jobTitle: 'Human Resource',
            score: 750
        }
    ];

    useEffect(() => {
        refetch();
    }, [id]);

              const [isTruncated, setIsTruncated] = useState(false);
              const containerRef = useRef(null);
              const [showMore, setShowMore] = useState(false);

              useEffect(() => {
                  const container: any = containerRef.current;
                  if (container !== null) {
                      if (container.scrollHeight > container.clientHeight) {
                          setIsTruncated(true);
                      } else {
                          setIsTruncated(false);
                      }
                  }
              }, [company?.bio]);

    if (!company) {
        return <Box width='100%' height='100vh' display='flex' alignItems='center' justifyContent='center'><CircularProgress /></Box>
    }

    return (
        <Container
            disableGutters
            style={{
                maxWidth: '100%'
            }}
            sx={{ pr: isMobile ? 0 : isTablet ? '20px' : '40px' }}
        >
            <Grid container direction="column" gap={1}>
                <Grid item position="relative">
                    <Box
                        bgcolor="#fff"
                        borderRadius={1}
                        overflow="hidden"
                        pb={isMobile ? 0 : '20px'}
                    >
                        <Box position="relative" pb="109px">
                            <Box
                                position="relative"
                                overflow="hidden"
                                display={'flex'}
                                borderRadius={[0, 0, 1, 1]}
                            >
                                <img
                                    style={{
                                        height: isMobile
                                            ? '100px'
                                            : isTablet
                                            ? '220px'
                                            : '259px',
                                        width: '100%',
                                        objectFit: 'cover',
                                        display: 'block'
                                    }}
                                    alt="Cover"
                                    src={cover}
                                />
                                {isTablet ? null : (
                                    <ProfileBanner
                                        companyName={
                                            company?.primaryCompanyProfileName ??
                                            ''
                                        }
                                        score={score}
                                    />
                                )}
                            </Box>
                            <Grid
                                container
                                gap={isMobile ? undefined : 3}
                                position={'absolute'}
                                direction={isMobile ? 'column' : undefined}
                                bottom={isMobile ? 8 : isTablet ? 30 : 0}
                            >
                                <Box
                                    ml={isTablet ? '20px' : 5}
                                    borderRadius="50%"
                                    overflow="hidden"
                                    width="fit-content"
                                    boxShadow="-8px 4px 20px rgba(0, 0, 0, 0.07), 8px 8px 20px rgba(0, 0, 0, 0.07)"
                                    position="relative"
                                    mb={isTablet && !company?.logo ? 2 : 0}
                                >
                                    {!company?.logo ? (
                                        <ProfilePhotoIcon />
                                    ) : (
                                        <Image
                                            src={company?.logo ?? ''}
                                            sx={{
                                                width: isTablet
                                                    ? '150px'
                                                    : '200px',
                                                height: isTablet
                                                    ? '150px'
                                                    : '200px',
                                                objectFit: 'cover'
                                            }}
                                            alt={
                                                company?.primaryCompanyProfileName
                                            }
                                            border="3px #fff solid"
                                            borderRadius="50%"
                                            display="block"
                                        />
                                    )}
                                </Box>
                                {isMobile ? (
                                    <Box mt="4px" ml="40px">
                                        <Typography
                                            component="span"
                                            color="#05668D"
                                            fontFamily="open sans"
                                            fontSize={16}
                                            fontWeight={700}
                                        >
                                            {connections?.length ?? 0}
                                        </Typography>
                                        &nbsp;
                                        <Typography
                                            component={'span'}
                                            color="#05668D"
                                            fontFamily="open sans"
                                            fontSize={12}
                                        >
                                            {'Connection' +
                                                (connections?.length === 1
                                                    ? ''
                                                    : 's')}
                                        </Typography>
                                    </Box>
                                ) : null}
                            </Grid>
                        </Box>
                        <Box
                            display={{
                                xs: 'flex',
                                sm: 'none'
                            }}
                            alignItems="center"
                            gap="10px"
                            pl="16px"
                        >
                            {/* {isConnected && !isRequested ? null : ( */}
                            <Button
                                variant="contained"
                                // disabled={isRequested}
                                sx={{
                                    // width: isRequested ? '140px' : '120px',
                                    width: '120px',
                                    height: '40px',
                                    borderRadius: '8px',
                                    textTransform: 'none',
                                    bgcolor: '#05668D',
                                    color: '#fff',
                                    fontSize: '16px',
                                    fontWeight: 600,
                                    mr: '6px'
                                }}
                                // onClick={() => {
                                //     if (isRequested) {
                                //     } else sendConnectionRequest();
                                // }}
                            >
                                Follow
                            </Button>
                            {/* )} */}
                            {/* <Button
                                variant="outlined"
                                sx={{
                                    width: '120px',
                                    height: '40px',
                                    borderRadius: '8px',
                                    textTransform: 'none',
                                    border: '1px solid #05668D',
                                    color: '#05668D',
                                    fontSize: '16px',
                                    fontWeight: 600
                                }}
                                onClick={() =>
                                    navigate('/messages', {
                                        state: {
                                            user: profile
                                        }
                                    })
                                }
                            >
                                Message
                            </Button> */}
                            <IconButton>
                                <MoreIcon />
                            </IconButton>
                        </Box>
                        {isMobile ? (
                            <Box
                                display="flex"
                                alignItems="flex-end"
                                flexDirection="column"
                                mt={-9.5}
                                mr="20px"
                                gap="16px"
                            >
                                <ScoreCard
                                    score={score}
                                    width="80px"
                                    height="80px"
                                />
                            </Box>
                        ) : null}

                        {isMobile ? (
                            <Box
                                width="100%"
                                height="4px"
                                bgcolor="#f2f2f2"
                                my="8px"
                            ></Box>
                        ) : null}
                        <Grid
                            container
                            px={isMobile ? '16px' : isTablet ? '20px' : 5}
                            marginTop={{ xs: 0, sm: -1.5, lg: '20px' }}
                            pb={{ xs: '16px', sm: 0 }}
                        >
                            <Grid item flexGrow={1} marginTop="auto">
                                <Grid container direction="column" gap={0.5}>
                                    <Grid
                                        item
                                        direction={
                                            isMobile ? 'column' : undefined
                                        }
                                    >
                                        <Box
                                            display={{
                                                xs: 'none',
                                                sm: 'flex',
                                                lg: 'none'
                                            }}
                                            alignItems="center"
                                            gap="10px"
                                            mb="20px"
                                        >
                                            {/* {isConnected ? null : ( */}
                                            <Button
                                                variant="contained"
                                                // disabled={isRequested}
                                                sx={{
                                                    width: '160px',
                                                    height: '44px',
                                                    borderRadius: '8px',
                                                    textTransform: 'none',
                                                    bgcolor: '#05668D',
                                                    color: '#fff',
                                                    fontSize: '16px',
                                                    fontWeight: 600,
                                                    mr: '6px'
                                                }}
                                                // onClick={() => {
                                                //     if (isRequested) {
                                                //     } else
                                                //         sendConnectionRequest();
                                                // }}
                                            >
                                                Follow
                                            </Button>
                                            {/* )} */}
                                            {/* <Button
                                                variant="outlined"
                                                sx={{
                                                    width: '133px',
                                                    height: '44px',
                                                    borderRadius: '8px',
                                                    textTransform: 'none',
                                                    border: '1px solid #05668D',
                                                    color: '#05668D',
                                                    fontSize: '16px',
                                                    fontWeight: 600
                                                }}
                                                onClick={() =>
                                                    navigate('/messages', {
                                                        state: {
                                                            user: profile
                                                        }
                                                    })
                                                }
                                            >
                                                Message
                                            </Button> */}
                                            <IconButton>
                                                <MoreIcon />
                                            </IconButton>
                                        </Box>
                                        <Grid
                                            container
                                            gap={'12px'}
                                            display="flex"
                                            alignItems="center"
                                        >
                                            <Grid
                                                item
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '4px'
                                                }}
                                            >
                                                <Typography
                                                    component="h2"
                                                    color="#23282B"
                                                    fontFamily="open sans"
                                                    // lineHeight="1.125"
                                                    fontSize={
                                                        isMobile
                                                            ? 22
                                                            : isTablet
                                                            ? 24
                                                            : 28
                                                    }
                                                    fontWeight={600}
                                                >
                                                    {
                                                        company?.primaryCompanyProfileName
                                                    }
                                                </Typography>
                                                {/* <Box> */}
                                                <TickCircle />
                                                {/* <VerifiedIcon /> */}
                                                {/* </Box> */}
                                            </Grid>
                                            <CircleIcon
                                                sx={{
                                                    display: {
                                                        xs: 'none',
                                                        sm: 'block'
                                                    },
                                                    fontSize: '6px'
                                                }}
                                                htmlColor="#C5C5C5"
                                            />
                                            {company?.yearFounded ? (
                                                <>
                                                    <Typography
                                                        component="span"
                                                        color="#808080"
                                                        fontFamily="open sans"
                                                        fontSize={16}
                                                        fontWeight={400}
                                                    >
                                                        Founded in{' '}
                                                        {company?.yearFounded}
                                                    </Typography>
                                                    <CircleIcon
                                                        sx={{
                                                            display: {
                                                                xs: 'none',
                                                                sm: 'block'
                                                            },
                                                            fontSize: '6px'
                                                        }}
                                                        htmlColor="#C5C5C5"
                                                    />
                                                </>
                                            ) : null}
                                            <Grid
                                                item
                                                sx={{ display: 'flex' }}
                                                alignItems="center"
                                            >
                                                <Grid
                                                    sx={{
                                                        display: {
                                                            xs: 'none',
                                                            sm: 'flex'
                                                        }
                                                    }}
                                                    container
                                                    wrap="nowrap"
                                                    width="auto"
                                                    // marginTop="auto"
                                                    flexGrow={1}
                                                    alignItems="center"
                                                    gap="12px"
                                                    // marginLeft={
                                                    //     isTablet ? -2 : -1
                                                    // }
                                                >
                                                    <Grid
                                                        item
                                                        flexGrow={1}
                                                        // mt={
                                                        //     isTablet
                                                        //         ? '30px'
                                                        //         : undefined
                                                        // }
                                                    >
                                                        <Typography
                                                            component="span"
                                                            color="#05668D"
                                                            fontFamily="open sans"
                                                            fontSize={20}
                                                            fontWeight={700}
                                                        >
                                                            {connections?.length ??
                                                                0}
                                                        </Typography>
                                                        &nbsp;
                                                        <Typography
                                                            component={'span'}
                                                            color="#05668D"
                                                            fontFamily="open sans"
                                                            fontSize={20}
                                                        >
                                                            {'Connection' +
                                                                (connections?.length ===
                                                                1
                                                                    ? ''
                                                                    : 's')}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid
                                                        item
                                                        // paddingRight="32px"
                                                    >
                                                        <Box
                                                            display="flex"
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            sx={{
                                                                bgcolor:
                                                                    '#f2f2f2',
                                                                borderRadius:
                                                                    '8px',
                                                                p: '4px'
                                                            }}
                                                        >
                                                            <MoreIcon />
                                                            {/* <MoreVertIcon fontSize="medium" htmlColor='#808080' sx={{ bgcolor:"#f2f2f2",
                                                            borderRadius:"8px", p:'4px'}}  /> */}
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {company?.tagLine ? (
                                        <Grid
                                            item
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '12px'
                                            }}
                                        >
                                            <Typography
                                                component={'p'}
                                                color="#23282B"
                                                fontFamily="open sans"
                                                fontSize={isMobile ? 14 : 16}
                                                fontWeight={400}
                                            >
                                                {company?.tagLine}
                                            </Typography>
                                        </Grid>
                                    ) : null}

                                    <Grid item>
                                        <Grid
                                            container
                                            gap={'4px'}
                                            alignItems="center"
                                        >
                                            {company?.address ? (
                                                <>
                                                    <Grid item>
                                                        <InfoChip
                                                            type="location"
                                                            label={`${
                                                                company?.address
                                                                    ?.city ?? ''
                                                            }, ${
                                                                company?.address
                                                                    ?.country ??
                                                                ''
                                                            }`}
                                                        />
                                                    </Grid>
                                                    {/* {isMobile ? null : (
                                                        <CircleIcon
                                                            sx={{
                                                                fontSize:
                                                                    '7.25px'
                                                            }}
                                                            htmlColor="#494949"
                                                        />
                                                    )} */}
                                                </>
                                            ) : null}
                                            {company?.phoneNumber ? (
                                                <>
                                                    <Grid item>
                                                        <InfoChip
                                                            type="phone"
                                                            label={
                                                                company?.phoneNumber ??
                                                                ''
                                                            }
                                                        />
                                                    </Grid>
                                                    {/* {isMobile ? null : (
                                                        <CircleIcon
                                                            sx={{
                                                                fontSize:
                                                                    '7.25px'
                                                            }}
                                                            htmlColor="#494949"
                                                        />
                                                    )} */}
                                                </>
                                            ) : null}
                                            {company?.emailAddress ? (
                                                <>
                                                    <Grid item>
                                                        <InfoChip
                                                            type="email"
                                                            label={
                                                                company?.emailAddress ??
                                                                ''
                                                            }
                                                        />
                                                    </Grid>
                                                </>
                                            ) : null}
                                            {/* <Grid item>
                                                <Button
                                                    variant="contained"
                                                    style={{
                                                        width: 'auto',
                                                        minWidth: 'auto',
                                                        color: '#808080',
                                                        backgroundColor:
                                                            '#F2F2F2',
                                                        zIndex: 5
                                                    }}
                                                    sx={{
                                                        p: '6px'
                                                    }}
                                                    onClick={
                                                        handleOnEditProfileInfo
                                                    }
                                                >
                                                    <EditIcon fontSize="small" />
                                                </Button>
                                            </Grid> */}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {isMobile ? null : (
                                <Grid
                                    item
                                    flexShrink={1}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-end'
                                    }}
                                    // position={{ sm: 'absolute', lg: 'static' }}
                                    // right={'21px'}
                                    // bottom={'120px'}
                                >
                                    <Box
                                        py={1}
                                        position={{
                                            sm: 'absolute'
                                            // lg: 'static'
                                        }}
                                        right={{ sm: '21px', lg: '62px' }}
                                        bottom={{ sm: '185px', lg: '86px' }}
                                    >
                                        <ScoreCard score={score} />
                                    </Box>
                                    <Box
                                        position="absolute"
                                        bottom="20px"
                                        display={{ xs: 'none', lg: 'flex' }}
                                        alignItems="center"
                                        gap="10px"
                                    >
                                        {/* {isConnected ? null : ( */}
                                        <Button
                                            variant="contained"
                                            // disabled={isRequested}
                                            sx={{
                                                width: '160px',
                                                height: '48px',
                                                borderRadius: '8px',
                                                textTransform: 'none',
                                                bgcolor: '#05668D',
                                                color: '#fff',
                                                fontSize: '16px',
                                                fontWeight: 600,
                                                mr: '6px'
                                            }}
                                            // onClick={() => {
                                            //     if (isRequested) {
                                            //     } else
                                            //         sendConnectionRequest();
                                            // }}
                                        >
                                            Follow
                                        </Button>
                                        {/* )} */}
                                        {/* <Button
                                            variant="outlined"
                                            sx={{
                                                width: '133px',
                                                height: '48px',
                                                borderRadius: '8px',
                                                textTransform: 'none',
                                                border: '1px solid #05668D',
                                                color: '#05668D',
                                                fontSize: '16px',
                                                fontWeight: 600
                                            }}
                                            onClick={() =>
                                                navigate('/messages', {
                                                    state: {
                                                        user: profile
                                                    }
                                                })
                                            }
                                        >
                                            Message
                                        </Button> */}
                                        <IconButton>
                                            <MoreIcon />
                                        </IconButton>
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </Grid>
                {/* <Grid item>
                    <Box
                        bgcolor="#fff"
                        borderRadius={1}
                        overflow="hidden"
                        pb={isMobile ? 0 : 5}
                    >
                        <Box
                            position="relative"
                            pb={isMobile ? '169px' : '109px'}
                        >
                            <Box
                                position="relative"
                                overflow="hidden"
                                display={'flex'}
                                borderRadius={[0, 0, 1, 1]}
                            >
                                <img
                                    style={{
                                        height: isMobile
                                            ? '100px'
                                            : isTablet
                                            ? '220px'
                                            : '259px',
                                        width: '100%',
                                        objectFit: 'cover',
                                        display: 'block'
                                    }}
                                    alt="Cover"
                                    src={cover}
                                />
                                {isTablet ? null : (
                                    <ProfileBanner
                                        companyName={
                                            company?.primaryCompanyProfileName
                                        }
                                        score={score}
                                    />
                                )}
                            </Box>
                            <Grid
                                container
                                gap={isMobile ? undefined : 3}
                                position={'absolute'}
                                direction={isMobile ? 'column' : undefined}
                                bottom={isMobile ? 40 : isTablet ? 30 : 0}
                            >
                                <Box
                                    ml={isTablet ? '20px' : 5}
                                    borderRadius="50%"
                                    overflow="hidden"
                                    width="fit-content"
                                    boxShadow="-8px 4px 20px rgba(0, 0, 0, 0.07), 8px 8px 20px rgba(0, 0, 0, 0.07)"
                                    position="relative"
                                    mb={isTablet && !company?.logo ? 2 : 0}
                                >
                                    {!company?.logo ? (
                                        <ProfilePhotoIcon />
                                    ) : (
                                        <Image
                                            src={company?.logo ?? ''}
                                            sx={{
                                                width: isTablet
                                                    ? '150px'
                                                    : '200px',
                                                height: isTablet
                                                    ? '150px'
                                                    : '200px',
                                                objectFit: 'cover'
                                            }}
                                            alt={
                                                company?.primaryCompanyProfileName
                                            }
                                            border="3px #fff solid"
                                            borderRadius="50%"
                                            display="block"
                                        />
                                    )}
                                </Box>
                                {isMobile ? (
                                    <Box mt="4px" ml="40px">
                                        <Typography
                                            component="span"
                                            color="#23282B"
                                            fontFamily="open sans"
                                            fontSize={16}
                                            fontWeight={700}
                                        >
                                            {connections?.length ?? 0}
                                        </Typography>
                                        &nbsp;
                                        <Typography
                                            component={'span'}
                                            color="#808080"
                                            fontFamily="open sans"
                                            fontSize={12}
                                        >
                                            {'Connection' +
                                                (connections?.length === 1
                                                    ? ''
                                                    : 's')}
                                        </Typography>
                                    </Box>
                                ) : null}
                                {isMobile ? null : (
                                    <Grid
                                        container
                                        wrap="nowrap"
                                        width="auto"
                                        marginTop="auto"
                                        height="109px"
                                        flexGrow={1}
                                        alignItems="center"
                                        pb={2}
                                        marginLeft={isTablet ? -2 : -1}
                                    >
                                        <Grid
                                            item
                                            flexGrow={1}
                                            mt={isTablet ? '30px' : undefined}
                                        >
                                            <Typography
                                                component="span"
                                                color="#23282B"
                                                fontFamily="open sans"
                                                fontSize={20}
                                                fontWeight={700}
                                            >
                                                {connections?.length ?? 0}
                                            </Typography>
                                            &nbsp;
                                            <Typography
                                                component={'span'}
                                                color="#808080"
                                                fontFamily="open sans"
                                                fontSize={16}
                                            >
                                                {'Connection' +
                                                    (connections?.length === 1
                                                        ? ''
                                                        : 's')}
                                            </Typography>
                                        </Grid>

                                        {isTablet ? null : (
                                            <Grid
                                                item
                                                flexShrink="1"
                                                paddingRight="32px"
                                            >
                                                <IconButton>
                                                    <MoreVertIcon fontSize="medium" />
                                                </IconButton>
                                            </Grid>
                                        )}
                                    </Grid>
                                )}
                            </Grid>
                        </Box>
                        {isMobile ? (
                            <Box
                                display="flex"
                                alignItems="flex-end"
                                flexDirection="column"
                                mt={-13}
                                mr="20px"
                                gap="16px"
                            >
                                <ScoreCard
                                    score={score}
                                    width="50px"
                                    height="50px"
                                />
                            </Box>
                        ) : null}
                        {isMobile ? (
                            <Box
                                width="100%"
                                height="2px"
                                bgcolor="#f2f2f2"
                                my="16px"
                            ></Box>
                        ) : null}
                        <Grid
                            container
                            px={isMobile ? '16px' : isTablet ? '20px' : 5}
                            marginTop={isMobile ? 0 : -3.5}
                        >
                            <Grid item flexGrow={1} marginTop="auto">
                                <Grid
                                    container
                                    direction="column"
                                    gap={isTablet ? '12px' : 2}
                                >
                                    <Grid
                                        item
                                        direction={
                                            isMobile ? 'column' : undefined
                                        }
                                    >
                                        <Grid
                                            container
                                            gap={isTablet ? 1 : 2}
                                            alignItems="center"
                                        >
                                            <Grid item>
                                                <Typography
                                                    component="h2"
                                                    color="#23282B"
                                                    fontFamily="open sans"
                                                    lineHeight="1.125"
                                                    fontSize={
                                                        isMobile
                                                            ? 22
                                                            : isTablet
                                                            ? 24
                                                            : 28
                                                    }
                                                    fontWeight={600}
                                                >
                                                    {
                                                        company?.primaryCompanyProfileName
                                                    }
                                                </Typography>
                                            </Grid>

                                            <Grid item alignItems="center">
                                                <Grid
                                                    container
                                                    gap={isTablet ? 1 : 2}
                                                    alignItems="center"
                                                >
                                                    {isMobile ? (
                                                        <CheckCircleIcon
                                                            htmlColor="#49B6FF"
                                                            style={{
                                                                marginLeft:
                                                                    '8px'
                                                            }}
                                                        />
                                                    ) : (
                                                        <>
                                                            <CircleIcon
                                                                sx={{
                                                                    fontSize:
                                                                        '7.25px'
                                                                }}
                                                                htmlColor="#494949"
                                                            />
                                                            {company?.yearFounded ? (
                                                                <>
                                                                    <Typography
                                                                        component="span"
                                                                        color="#808080"
                                                                        fontFamily="open sans"
                                                                        fontSize={
                                                                            16
                                                                        }
                                                                        fontWeight={
                                                                            400
                                                                        }
                                                                    >
                                                                        Founded
                                                                        in{' '}
                                                                        {
                                                                            company?.yearFounded
                                                                        }
                                                                    </Typography>
                                                                    <CheckCircleIcon
                                                                        htmlColor="#49B6FF"
                                                                        style={{
                                                                            marginLeft:
                                                                                isTablet
                                                                                    ? '8px'
                                                                                    : '-8px'
                                                                        }}
                                                                    />
                                                                </>
                                                            ) : null}
                                                        </>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {isMobile && company?.yearFounded ? (
                                            <Typography
                                                mt="12px"
                                                component="span"
                                                color="#808080"
                                                fontFamily="open sans"
                                                fontSize={14}
                                                fontWeight={400}
                                            >
                                                Founded in{' '}
                                                {company?.yearFounded}
                                            </Typography>
                                        ) : null}
                                    </Grid>
                                    {company?.tagLine ? (
                                        <Grid item>
                                            <Typography
                                                component={'p'}
                                                color="#23282B"
                                                fontFamily="open sans"
                                                fontSize={isMobile ? 14 : 16}
                                                fontWeight={400}
                                            >
                                                {company?.tagLine}
                                            </Typography>
                                        </Grid>
                                    ) : null}
                                    <Grid item>
                                        <Grid
                                            container
                                            gap={1}
                                            alignItems="center"
                                        >
                                            {company?.address ? (
                                                <>
                                                    <Grid item>
                                                        <InfoChip
                                                            type="location"
                                                            label={`${company?.address?.city}, ${company?.address?.country}`}
                                                        />
                                                    </Grid>
                                                    {isMobile ? null : (
                                                        <CircleIcon
                                                            sx={{
                                                                fontSize:
                                                                    '7.25px'
                                                            }}
                                                            htmlColor="#494949"
                                                        />
                                                    )}
                                                </>
                                            ) : null}
                                            {company?.phoneNumber ? (
                                                <>
                                                    <Grid item>
                                                        <InfoChip
                                                            type="phone"
                                                            label={
                                                                company?.phoneNumber ??
                                                                ''
                                                            }
                                                        />
                                                    </Grid>
                                                    {isMobile ? null : (
                                                        <CircleIcon
                                                            sx={{
                                                                fontSize:
                                                                    '7.25px'
                                                            }}
                                                            htmlColor="#494949"
                                                        />
                                                    )}
                                                </>
                                            ) : null}
                                            {company?.emailAddress ? (
                                                <>
                                                    <Grid item>
                                                        <InfoChip
                                                            type="email"
                                                            label={
                                                                company?.emailAddress ??
                                                                ''
                                                            }
                                                        />
                                                    </Grid>
                                                </>
                                            ) : null}
                                            
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {isTablet ? null : (
                                <Grid item flexShrink={1}>
                                    <Box py={1}>
                                        <ScoreCard score={score} />
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </Grid> */}
                {!showAllPosts ? (
                    <Grid item>
                        <Grid
                            container
                            wrap={isTablet ? 'wrap' : 'nowrap'}
                            spacing={1}
                        >
                            <Grid item xs={isTablet ? 12 : 8}>
                                <Section px={0}>
                                    <Box
                                        px={{
                                            xs: '16px',
                                            sm: '20px',
                                            lg: '40px'
                                        }}
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'flex-start'
                                        }}
                                    >
                                        <Box>
                                            <Typography
                                                component="h3"
                                                color="#23282B"
                                                fontFamily="open sans"
                                                fontSize={20}
                                                fontWeight={600}
                                                mb={1}
                                            >
                                                About us
                                            </Typography>
                                            <Typography
                                                component="h4"
                                                color="#808080"
                                                fontFamily="open sans"
                                                fontSize={16}
                                                fontWeight={400}
                                                mb={1.5}
                                            >
                                                Tell the world about your
                                                company.
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Box
                                        pt={{ xs: '12px', sm: '20px' }}
                                        px={{
                                            xs: '16px',
                                            sm: '20px',
                                            lg: '40px'
                                        }}
                                        pb="8px"
                                    >
                                        <Typography
                                            ref={containerRef}
                                            component="p"
                                            color="#23282B"
                                            fontFamily="open sans"
                                            fontSize={isTablet ? 14 : 16}
                                            fontWeight={400}
                                            // mt={4}
                                            mb={'12px'}
                                            style={{
                                                display: '-webkit-box',
                                                WebkitLineClamp: showMore
                                                    ? undefined
                                                    : 4,
                                                WebkitBoxOrient: 'vertical',
                                                overflow: 'hidden'
                                            }}
                                        >
                                            {company?.bio}
                                        </Typography>
                                        {isTruncated ? (
                                            <Box sx={{ width: '100%' }}>
                                                <Button
                                                    disableRipple
                                                    variant="contained"
                                                    sx={{
                                                        width: '118px',
                                                        backgroundColor:
                                                            '#f2f2f2',
                                                        borderRadius: '8px',
                                                        padding: '0 8px',
                                                        '&:hover': {
                                                            bgcolor: '#f2f2f2'
                                                        }
                                                    }}
                                                    endIcon={<SeeMoreIcon />}
                                                    onClick={() => {
                                                        setShowMore(!showMore);
                                                    }}
                                                >
                                                    <Typography
                                                        color={'#05668D'}
                                                        fontFamily="open sans"
                                                        fontWeight={700}
                                                        fontSize={14}
                                                        sx={{
                                                            textTransform:
                                                                'none',
                                                            py: 1
                                                        }}
                                                    >
                                                        {showMore
                                                            ? 'See less'
                                                            : 'See more'}
                                                    </Typography>
                                                </Button>
                                            </Box>
                                        ) : null}
                                    </Box>
                                </Section>
                                <Section
                                    sx={{ borderRadius: 0, mb: '8px' }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Box>
                                            <Typography
                                                component="h3"
                                                color="#23282B"
                                                fontFamily="open sans"
                                                fontSize={20}
                                                fontWeight={600}
                                            >
                                                Company News
                                            </Typography>
                                        </Box>
                                        <Button
                                            sx={{
                                                p: '8px',
                                                color: '#05668D',
                                                backgroundColor: '#F2F2F2',
                                                borderRadius: '8px',
                                                width: 'auto',
                                                textTransform: 'capitalize',
                                                gap: 1
                                            }}
                                        >
                                            View all
                                            <EastIcon />
                                        </Button>
                                    </Box>
                                </Section>
                                <Section
                                    sx={{
                                        borderBottom: '1px solid #ccc',
                                        borderRadius: 0,
                                        mb: 0
                                    }}
                                >
                                    <Grid container gap={2.5} wrap="nowrap">
                                        <Grid item>
                                            <Image
                                                src={cambridge}
                                                alt="University of Birmingham"
                                                sx={{
                                                    width: '70px',
                                                    height: '70px',
                                                    objectFit: 'cover'
                                                }}
                                                border="3px #fff solid"
                                                borderRadius={2}
                                                display="block"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '10px',
                                                    mb: '5px'
                                                }}
                                            >
                                                <Typography
                                                    color="#808080"
                                                    fontFamily="open sans"
                                                    fontSize={12}
                                                >
                                                    30 January, 2023
                                                </Typography>
                                                <DotIcon />
                                                <Typography
                                                    color="#808080"
                                                    fontFamily="open sans"
                                                    fontSize={12}
                                                >
                                                    3 minutes read
                                                </Typography>
                                            </Box>

                                            <Typography
                                                component="h4"
                                                color="#23282B"
                                                fontFamily="open sans"
                                                fontSize={16}
                                                fontWeight={600}
                                                mb={2}
                                            >
                                                lorem ipsum lorem ipsum lorem
                                                ipsumlorem ipsumlorem ipsumlorem
                                                ipsumlorem ipsumlorem ipsumlorem
                                                ipsumlorem ipsum
                                            </Typography>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    color: '#05668D',
                                                    backgroundColor: '#F2F2F2',
                                                    borderRadius: '8px',
                                                    textTransform: 'none',
                                                    width: 'auto',
                                                    fontSize: '14px',
                                                    fontWeight: '600',
                                                    gap: 1
                                                }}
                                            >
                                                Continue reading <EastIcon />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Section>
                                <Section
                                    sx={{
                                        borderBottom: '1px solid #ccc',
                                        borderRadius: 0,
                                        mb: 0
                                    }}
                                >
                                    <Grid container gap={2.5} wrap="nowrap">
                                        <Grid item>
                                            <Image
                                                src={cambridge}
                                                alt="University of Birmingham"
                                                sx={{
                                                    width: '70px',
                                                    height: '70px',
                                                    objectFit: 'cover'
                                                }}
                                                border="3px #fff solid"
                                                borderRadius={2}
                                                display="block"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '10px',
                                                    mb: '5px'
                                                }}
                                            >
                                                <Typography
                                                    color="#808080"
                                                    fontFamily="open sans"
                                                    fontSize={12}
                                                >
                                                    30 January, 2023
                                                </Typography>
                                                <DotIcon />
                                                <Typography
                                                    color="#808080"
                                                    fontFamily="open sans"
                                                    fontSize={12}
                                                >
                                                    3 minutes read
                                                </Typography>
                                            </Box>

                                            <Typography
                                                component="h4"
                                                color="#23282B"
                                                fontFamily="open sans"
                                                fontSize={16}
                                                fontWeight={600}
                                                mb={2}
                                            >
                                                lorem ipsum lorem ipsum lorem
                                                ipsumlorem ipsumlorem ipsumlorem
                                                ipsumlorem ipsumlorem ipsumlorem
                                                ipsumlorem ipsum
                                            </Typography>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    color: '#05668D',
                                                    backgroundColor: '#F2F2F2',
                                                    borderRadius: '8px',
                                                    textTransform: 'none',
                                                    width: 'auto',
                                                    fontSize: '14px',
                                                    fontWeight: '600',
                                                    gap: 1
                                                }}
                                            >
                                                Continue reading <EastIcon />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Section>
                                <Section
                                    sx={{
                                        borderBottom: '1px solid #ccc',
                                        borderRadius: 0,
                                        mb: 0
                                    }}
                                >
                                    <Grid container gap={2.5} wrap="nowrap">
                                        <Grid item>
                                            <Image
                                                src={cambridge}
                                                alt="University of Birmingham"
                                                sx={{
                                                    width: '70px',
                                                    height: '70px',
                                                    objectFit: 'cover'
                                                }}
                                                border="3px #fff solid"
                                                borderRadius={2}
                                                display="block"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '10px',
                                                    mb: '5px'
                                                }}
                                            >
                                                <Typography
                                                    color="#808080"
                                                    fontFamily="open sans"
                                                    fontSize={12}
                                                >
                                                    30 January, 2023
                                                </Typography>
                                                <DotIcon />
                                                <Typography
                                                    color="#808080"
                                                    fontFamily="open sans"
                                                    fontSize={12}
                                                >
                                                    3 minutes read
                                                </Typography>
                                            </Box>

                                            <Typography
                                                component="h4"
                                                color="#23282B"
                                                fontFamily="open sans"
                                                fontSize={16}
                                                fontWeight={600}
                                                mb={2}
                                            >
                                                lorem ipsum lorem ipsum lorem
                                                ipsumlorem ipsumlorem ipsumlorem
                                                ipsumlorem ipsumlorem ipsumlorem
                                                ipsumlorem ipsum
                                            </Typography>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    color: '#05668D',
                                                    backgroundColor: '#F2F2F2',
                                                    borderRadius: '8px',
                                                    textTransform: 'none',
                                                    width: 'auto',
                                                    fontSize: '14px',
                                                    fontWeight: '600',
                                                    gap: 1
                                                }}
                                            >
                                                Continue reading <EastIcon />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Section>
                                <Section
                                    sx={{
                                        borderRadius: 0,
                                        mt: '8px',
                                        mb: '8px'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Box>
                                            <Typography
                                                component="h3"
                                                color="#23282B"
                                                fontFamily="open sans"
                                                fontSize={20}
                                                fontWeight={600}
                                            >
                                                Employees
                                            </Typography>
                                        </Box>
                                        <Button
                                            sx={{
                                                p: '8px',
                                                width: 'auto',
                                                color: '#05668D',
                                                backgroundColor: '#F2F2F2',
                                                borderRadius: '8px',
                                                textTransform: 'capitalize',
                                                gap: 1
                                            }}
                                        >
                                            View all
                                            <EastIcon />
                                        </Button>
                                    </Box>
                                </Section>
                                <Section
                                    sx={{
                                        borderBottom: '1px solid #ccc',
                                        borderRadius: 0,
                                        pt: '20px',
                                        mb: 0
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '20px'
                                        }}
                                    >
                                        <Avatar
                                            src={profile}
                                            sx={{
                                                width: '60px',
                                                height: '60px'
                                            }}
                                        />
                                        <Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '10px'
                                                }}
                                            >
                                                <Typography
                                                    color="#23282B"
                                                    fontFamily="open sans"
                                                    fontWeight={600}
                                                    fontSize={14}
                                                >
                                                    Jenny Wilson
                                                </Typography>
                                                <VerifiedIcon />
                                                <DotIcon />
                                                <Box
                                                    sx={{
                                                        background: '#808080',
                                                        color: 'white',
                                                        borderRadius: '5px',
                                                        p: '5px 10px',
                                                        fontFamily: 'Open sans',
                                                        fontSize: '12px',
                                                        fontWeight: '600'
                                                    }}
                                                >
                                                    Admin
                                                </Box>
                                            </Box>
                                            <Typography
                                                color="#808080"
                                                fontFamily="open sans"
                                                fontSize={12}
                                            >
                                                Lead Marketer
                                            </Typography>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '10px',
                                                    mt: '5px'
                                                }}
                                            >
                                                <Typography
                                                    color="#808080"
                                                    fontFamily="open sans"
                                                    fontSize={12}
                                                >
                                                    March 2020 - Present
                                                </Typography>
                                                <DotIcon />
                                                <Typography
                                                    color="#808080"
                                                    fontFamily="open sans"
                                                    fontSize={12}
                                                >
                                                    2 years, 7 months
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Section>
                                <Section
                                    sx={{
                                        borderBottom: '1px solid #ccc',
                                        borderRadius: 0,
                                        pt: '20px',
                                        mb: 0
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '20px'
                                        }}
                                    >
                                        <Avatar
                                            src={profile}
                                            sx={{
                                                width: '60px',
                                                height: '60px'
                                            }}
                                        />
                                        <Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '10px'
                                                }}
                                            >
                                                <Typography
                                                    color="#23282B"
                                                    fontFamily="open sans"
                                                    fontWeight={600}
                                                    fontSize={14}
                                                >
                                                    Jenny Wilson
                                                </Typography>
                                                <VerifiedIcon />
                                                <DotIcon />
                                                <Box
                                                    sx={{
                                                        background: '#808080',
                                                        color: 'white',
                                                        borderRadius: '5px',
                                                        p: '5px 10px',
                                                        fontFamily: 'Open sans',
                                                        fontSize: '12px',
                                                        fontWeight: '600'
                                                    }}
                                                >
                                                    Admin
                                                </Box>
                                            </Box>
                                            <Typography
                                                color="#808080"
                                                fontFamily="open sans"
                                                fontSize={12}
                                            >
                                                Lead Marketer
                                            </Typography>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '10px',
                                                    mt: '5px'
                                                }}
                                            >
                                                <Typography
                                                    color="#808080"
                                                    fontFamily="open sans"
                                                    fontSize={12}
                                                >
                                                    March 2020 - Present
                                                </Typography>
                                                <DotIcon />
                                                <Typography
                                                    color="#808080"
                                                    fontFamily="open sans"
                                                    fontSize={12}
                                                >
                                                    2 years, 7 months
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Section>
                                <Section
                                    sx={{
                                        borderBottom: '1px solid #ccc',
                                        borderRadius: 0,
                                        pt: '20px'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '20px'
                                        }}
                                    >
                                        <Avatar
                                            src={profile}
                                            sx={{
                                                width: '60px',
                                                height: '60px'
                                            }}
                                        />
                                        <Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '10px'
                                                }}
                                            >
                                                <Typography
                                                    color="#23282B"
                                                    fontFamily="open sans"
                                                    fontWeight={600}
                                                    fontSize={14}
                                                >
                                                    Jenny Wilson
                                                </Typography>
                                                <VerifiedIcon />
                                                <DotIcon />
                                                <Box
                                                    sx={{
                                                        background: '#808080',
                                                        color: 'white',
                                                        borderRadius: '5px',
                                                        p: '5px 10px',
                                                        fontFamily: 'Open sans',
                                                        fontSize: '12px',
                                                        fontWeight: '600'
                                                    }}
                                                >
                                                    Admin
                                                </Box>
                                            </Box>
                                            <Typography
                                                color="#808080"
                                                fontFamily="open sans"
                                                fontSize={12}
                                            >
                                                Lead Marketer
                                            </Typography>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '10px',
                                                    mt: '5px'
                                                }}
                                            >
                                                <Typography
                                                    color="#808080"
                                                    fontFamily="open sans"
                                                    fontSize={12}
                                                >
                                                    March 2020 - Present
                                                </Typography>
                                                <DotIcon />
                                                <Typography
                                                    color="#808080"
                                                    fontFamily="open sans"
                                                    fontSize={12}
                                                >
                                                    2 years, 7 months
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Section>
                                <Box
                                    mt={{ xs: '8px' }}
                                    px={{ xs: '16px', sm: '20px', lg: '40px' }}
                                    py="8px"
                                    bgcolor="#fff"
                                >
                                    <Box
                                        mb={'8px'}
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Typography
                                            // component="h2"
                                            color="#23282B"
                                            fontFamily="open sans"
                                            // lineHeight="1.125"
                                            fontSize="16px"
                                            fontWeight={600}
                                        >
                                            Activities
                                        </Typography>

                                        <Typography
                                            component="h2"
                                            color="#808080"
                                            fontFamily="open sans"
                                            lineHeight="24px"
                                            fontSize={16}
                                            fontWeight={400}
                                        >
                                            {totalPosts + ' posts'}
                                        </Typography>
                                    </Box>
                                    <Box
                                        display="flex"
                                        flexWrap="wrap"
                                        height="fit-content"
                                        gap={{ xs: '4px', sm: 0 }}
                                        bgcolor={{
                                            xs: 'transparent',
                                            sm: '#f2f2f2'
                                        }}
                                    >
                                        {currentActivity.map((item) => (
                                            <Box
                                                height="36px"
                                                bgcolor="#f2f2f2"
                                                width={{
                                                    xs: '49.3%',
                                                    sm: `${100 / 4}%`
                                                }}
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',

                                                    borderBottomColor:
                                                        activity === item
                                                            ? '#05668D'
                                                            : 'transparent',
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() =>
                                                    setActivity(item)
                                                }
                                                borderBottom="2px solid"
                                            >
                                                <Typography
                                                    fontFamily="open sans"
                                                    fontSize={14}
                                                    fontWeight={600}
                                                    lineHeight="20px"
                                                    color={
                                                        activity === item
                                                            ? '#05668D'
                                                            : '#808080'
                                                    }
                                                >
                                                    {item}
                                                </Typography>
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>
                                <Box py={'4px'} bgcolor="#f2f2f2">
                                    {activity === 'Posts' ? (
                                        !isLoaded ? (
                                            [1, 2].map((_) => (
                                                <SkeletonLoader />
                                            ))
                                        ) : posts?.length === 0 ? (
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                gap="12px"
                                                pl={
                                                    isMobile
                                                        ? '16px'
                                                        : undefined
                                                }
                                            >
                                                <Alert />
                                                <Typography>
                                                    {' '}
                                                    No Posts
                                                </Typography>
                                            </Box>
                                        ) : (
                                            (posts ?? [])
                                                ?.slice(0, 3)
                                                ?.map(
                                                    (feed: any, index: any) => (
                                                        <FeedItem
                                                            profile
                                                            key={index}
                                                            feed={feed}
                                                        />
                                                    )
                                                )
                                        )
                                    ) : activity === 'Achievements' ? (
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            gap="12px"
                                            pl={isMobile ? '16px' : undefined}
                                        >
                                            <Alert />
                                            <Typography>
                                                {' '}
                                                No Achievements
                                            </Typography>
                                        </Box>
                                    ) : activity === 'Articles' ? (
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            gap="12px"
                                            p={isMobile ? '16px' : undefined}
                                        >
                                            <Alert />
                                            <Typography>No Articles</Typography>
                                        </Box>
                                    ) : (
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            gap="12px"
                                            p={isMobile ? '16px' : undefined}
                                        >
                                            <Alert />
                                            <Typography>No Jobs</Typography>
                                        </Box>
                                    )}
                                    <Box
                                        py="8px"
                                        px={{
                                            xs: '16px',
                                            sm: '20px',
                                            lg: '40px'
                                        }}
                                        bgcolor="#fff"
                                        mb="4px"
                                    >
                                        {posts?.length > 0 && (
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    backgroundColor: '#f2f2f2',
                                                    borderRadius: '8px',
                                                    width: '100%',
                                                    '&:hover': {
                                                        bgcolor: '#f2f2f2'
                                                    }
                                                    // mt: '16px',
                                                }}
                                                onClick={() => {
                                                    setShowAllPosts(true);
                                                    window.scroll(0, 0);
                                                }}
                                            >
                                                <Typography
                                                    color={'#05668D'}
                                                    fontFamily="open sans"
                                                    fontWeight={600}
                                                    fontSize={16}
                                                    sx={{
                                                        textTransform: 'none',
                                                        py: 0.5,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '12px'
                                                    }}
                                                >
                                                    Show all posts{' '}
                                                    <ArrowIconRight />
                                                </Typography>
                                            </Button>
                                        )}
                                    </Box>
                                </Box>
                                <Section py={2.5}>
                                    <Grid
                                        container
                                        gap={isMobile ? 1.5 : 2.5}
                                        alignItems={
                                            isMobile ? undefined : 'center'
                                        }
                                        wrap="nowrap"
                                    >
                                        <Grid item py={1} pr={1}>
                                            <MedalIcon
                                                color={'rgb(72 181 254)'}
                                            />
                                        </Grid>
                                        <Grid item flexGrow={1}>
                                            <Typography
                                                component="h4"
                                                color="#23282B"
                                                fontFamily="open sans"
                                                fontSize={16}
                                                fontWeight={600}
                                                mb={1}
                                            >
                                                RATE AND REVIEW EMPLOYEE
                                            </Typography>
                                            <Typography
                                                component="p"
                                                color="#808080"
                                                fontFamily="open sans"
                                                fontSize={16}
                                                fontWeight={400}
                                                // pr={8}
                                            >
                                                Rate and review past and present
                                                employees
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <IconButton
                                                sx={{
                                                    background: '#F2F2F2',
                                                    borderRadius: '5px'
                                                }}
                                            >
                                                <ArrowRight />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Section>
                            </Grid>
                            <Grid item xs={isTablet ? 12 : 4}>
                                {isTablet ? null : (
                                    <>
                                        <Box
                                            width="100%"
                                            // heading={
                                            //     <SectionHeading
                                            //         disable={true}
                                            //         ml={0}
                                            //     >
                                            //         Top Professionals in your industry
                                            //     </SectionHeading>
                                            // }
                                            onClick={() => {}}
                                            mb="12px"
                                            // py={'16px'}
                                        >
                                            <Box
                                                bgcolor="#fff"
                                                p="8px 16px"
                                                borderBottom="1px solid #D8D8D8"
                                            >
                                                <Typography
                                                    fontFamily="Open Sans"
                                                    fontSize="20px"
                                                    fontWeight={600}
                                                    lineHeight="28px"
                                                >
                                                    Company Reviews
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        color: '#808080',
                                                        fontSize: '16px',
                                                        mt: '8px'
                                                    }}
                                                >
                                                    All reviews shown are
                                                    anonymous
                                                </Typography>
                                            </Box>
                                            <Box bgcolor="#fff">
                                                <Box p="8px 16px">
                                                    {data.map((item, index) => (
                                                        <Box
                                                            key={index}
                                                            sx={{
                                                                padding:
                                                                    '20px 0',
                                                                // m: 'auto 20px',
                                                                borderBottom:
                                                                    '1px solid #D9D9D9'
                                                            }}
                                                        >
                                                            <Typography
                                                                fontWeight={
                                                                    '600'
                                                                }
                                                                mb={'5px'}
                                                                color="#808080"
                                                                textTransform={
                                                                    'uppercase'
                                                                }
                                                            >
                                                                {item.review}
                                                            </Typography>
                                                            <Rating
                                                                name="read-only"
                                                                value={
                                                                    item.rating
                                                                }
                                                                readOnly
                                                            />
                                                        </Box>
                                                    ))}
                                                </Box>
                                                <Button
                                                    variant="outlined"
                                                    sx={{
                                                        borderColor: '#05668D',
                                                        height: '44px',
                                                        mt: '20px'
                                                    }}
                                                >
                                                    View reviews
                                                </Button>
                                            </Box>
                                        </Box>
                                    </>
                                )}
                                <Box
                                    // heading={
                                    // <SectionHeading ml={0} fontSize={106}>
                                    //     Analytics
                                    // </SectionHeading>
                                    // }
                                    onClick={() => {}}
                                    mb="4px"
                                    bgcolor="transparent"
                                    // pl={{xs: '16px', lg: 0}}
                                >
                                    <Box
                                        bgcolor="#fff"
                                        p="8px 16px"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        mb="4px"
                                    >
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize="20px"
                                            fontWeight={600}
                                            lineHeight="28px"
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            Analytics
                                        </Typography>
                                        {/* <IconButton
                                        onClick={() => {}}
                                        sx={{
                                            // background: '#DFEBF0',
                                            borderRadius: '8px',
                                            padding: '8px'
                                        }}
                                    >
                                        <ArrowRight />
                                    </IconButton> */}
                                    </Box>
                                    <Grid
                                        container
                                        gap={1}
                                        bgcolor="#fff"
                                        overflow="hidden"
                                        borderRadius={1.5}
                                        wrap="nowrap"
                                        height="140px"
                                        p={{
                                            xs: '8px 16px',
                                            sm: '8px 20px',
                                            lg: 1
                                        }}
                                    >
                                        <Grid
                                            item
                                            bgcolor="#f2f2f2"
                                            flexGrow={1}
                                            width="100%"
                                            // maxWidth="33%"
                                        >
                                            <Grid
                                                container
                                                flexDirection="column"
                                                alignItems="center"
                                                justifyContent="center"
                                                height="100%"
                                            >
                                                <AnalyticsProfile />
                                                <Typography
                                                    component="p"
                                                    color="#23282B"
                                                    fontFamily="open sans"
                                                    fontSize={{
                                                        xs: 11,
                                                        sm: 14
                                                    }}
                                                    fontWeight={600}
                                                    textAlign="center"
                                                >
                                                    PROFILE
                                                    <br />
                                                    VIEWS
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            bgcolor="#f2f2f2"
                                            flexGrow={1}
                                            width="100%"
                                            // maxWidth="33%"
                                        >
                                            <Grid
                                                container
                                                flexDirection="column"
                                                alignItems="center"
                                                justifyContent="center"
                                                height="100%"
                                            >
                                                <AnalyticsSearch />
                                                <Typography
                                                    component="p"
                                                    color="#23282B"
                                                    fontFamily="open sans"
                                                    fontSize={{
                                                        xs: 11,
                                                        sm: 14
                                                    }}
                                                    fontWeight={600}
                                                    textAlign="center"
                                                >
                                                    SEARCH
                                                    <br />
                                                    APPEARANCES
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            bgcolor="#f2f2f2"
                                            flexGrow={1}
                                            width="100%"
                                            // maxWidth="33%"
                                        >
                                            <Grid
                                                container
                                                flexDirection="column"
                                                alignItems="center"
                                                justifyContent="center"
                                                height="100%"
                                            >
                                                <AnalyticsGraph />
                                                <Typography
                                                    component="p"
                                                    color="#23282B"
                                                    fontFamily="open sans"
                                                    fontSize={{
                                                        xs: 11,
                                                        sm: 14
                                                    }}
                                                    fontWeight={600}
                                                    textAlign="center"
                                                >
                                                    POSTS
                                                    <br />
                                                    ENGAGEMENTS
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Box
                                    py={1}
                                    bgcolor="#fff"
                                    px={isMobile ? '16px' : 1}
                                >
                                    <Button
                                        variant="contained"
                                        sx={{
                                            backgroundColor: '#f2f2f2',
                                            borderRadius: '8px',
                                            width: '100%'
                                            // ml: isMobile ? '16px' : undefined
                                        }}
                                    >
                                        <Typography
                                            color={'primary'}
                                            fontFamily="open sans"
                                            fontWeight={600}
                                            fontSize={14}
                                            sx={{
                                                textTransform: 'none',
                                                py: 0.5
                                            }}
                                        >
                                            Upgrade to Premium plan
                                        </Typography>
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid item>
                        <Grid
                            pl={isMobile ? 0 : isTablet ? '12px' : undefined}
                            container
                            wrap="nowrap"
                        >
                            <Grid width="100%" item xs={isTablet ? 12 : 8}>
                                <Box
                                    px={
                                        isMobile
                                            ? '16px'
                                            : isTablet
                                            ? '20px'
                                            : '40px'
                                    }
                                    bgcolor="#fff"
                                    py="16px"
                                >
                                    <Box
                                        mb={'16px'}
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Typography
                                            component="h2"
                                            color="#23282B"
                                            fontFamily="open sans"
                                            lineHeight="1.125"
                                            fontSize={
                                                isMobile
                                                    ? 20
                                                    : isTablet
                                                    ? 24
                                                    : 28
                                            }
                                            fontWeight={600}
                                        >
                                            Activities
                                        </Typography>

                                        <Typography
                                            component="h2"
                                            color="#808080"
                                            fontFamily="open sans"
                                            lineHeight="24px"
                                            fontSize={16}
                                            fontWeight={400}
                                        >
                                            {totalPosts + ' posts'}
                                        </Typography>
                                    </Box>
                                    <Box
                                        display="flex"
                                        height="44px"
                                        bgcolor="#f2f2f2"
                                    >
                                        {currentActivity.map((item) => (
                                            <Box
                                                width={`${100 / 3}%`}
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',

                                                    borderBottomColor:
                                                        activity === item
                                                            ? '#05668D'
                                                            : 'transparent'
                                                }}
                                                onClick={() =>
                                                    setActivity(item)
                                                }
                                                borderBottom="2px solid"
                                            >
                                                <Typography
                                                    fontFamily="open sans"
                                                    fontSize={14}
                                                    fontWeight={600}
                                                    lineHeight="20px"
                                                    color={
                                                        activity === item
                                                            ? '#05668D'
                                                            : '#808080'
                                                    }
                                                >
                                                    {item}
                                                </Typography>
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>
                                <Box
                                    mt={isMobile ? '16px' : '32px'}
                                    px={isTablet ? 0 : '32px'}
                                    bgcolor="#fff"
                                >
                                    {activity === 'Posts' ? (
                                        !isLoaded || posts?.length === 0 ? (
                                            [1, 2, 3].map((_) => (
                                                <SkeletonLoader />
                                            ))
                                        ) : (
                                            <InfiniteScroll
                                                dataLength={posts?.length}
                                                next={() =>
                                                    setGetAfter(
                                                        (prev) => prev + 5
                                                    )
                                                }
                                                hasMore={
                                                    posts?.length <
                                                    (totalPosts ?? 0)
                                                }
                                                scrollThreshold={0.9}
                                                loader={
                                                    <>
                                                        {[1, 2, 3].map((_) => (
                                                            <SkeletonLoader />
                                                        ))}
                                                    </>
                                                }
                                            >
                                                {posts?.map((feed, index) => (
                                                    <FeedItem
                                                        key={index}
                                                        feed={feed}
                                                    />
                                                ))}
                                            </InfiniteScroll>
                                        )
                                    ) : activity === 'Achievements' ? (
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            gap="12px"
                                            pl={isMobile ? '16px' : undefined}
                                        >
                                            <Alert />
                                            <Typography>
                                                {' '}
                                                No Achievements
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            gap="12px"
                                            pl={isMobile ? '16px' : undefined}
                                        >
                                            <Alert />
                                            <Typography>No Articles</Typography>
                                        </Box>
                                    )}
                                </Box>
                            </Grid>
                            {isTablet ? null : (
                                <Grid item width="100%" xs={isTablet ? 12 : 4}>
                                    {Images?.length !== 0 && (
                                        <Box
                                            width="100%"
                                            // heading={
                                            //     <SectionHeading
                                            //         disable={true}
                                            //         ml={0}
                                            //     >
                                            //         Top Professionals in your industry
                                            //     </SectionHeading>
                                            // }
                                            onClick={() => {}}
                                            pl={'20px'}
                                            mb="24px"
                                            // py={'16px'}
                                        >
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="space-between"
                                            >
                                                <Typography
                                                    fontFamily="Open Sans"
                                                    fontSize="16px"
                                                    fontWeight={600}
                                                    lineHeight="24px"
                                                    letterSpacing="0.024px"
                                                >
                                                    Gallery
                                                </Typography>
                                                <IconButton
                                                    onClick={() => {}}
                                                    sx={{
                                                        background: '#DFEBF0',
                                                        borderRadius: '8px',
                                                        padding: '8px'
                                                    }}
                                                >
                                                    <ArrowRight />
                                                </IconButton>
                                            </Box>
                                            <ImageList
                                                sx={{
                                                    width: '100%',
                                                    height: '100%',
                                                    borderRadius: '12px'
                                                }}
                                                cols={3}
                                            >
                                                {/* <ImageListItem>
                                            <img
                                                src={company?.logo}
                                                alt={'Gallery'}
                                                style={{ width: '100%' }}
                                            />
                                        </ImageListItem> */}
                                                {Images?.map((image, index) => (
                                                    <ImageListItem key={index}>
                                                        <img
                                                            src={image}
                                                            alt={'Gallery'}
                                                            style={{
                                                                width: '100%'
                                                            }}
                                                        />
                                                    </ImageListItem>
                                                ))}
                                            </ImageList>
                                        </Box>
                                    )}
                                    <Box
                                        width="100%"
                                        // heading={
                                        //     <SectionHeading
                                        //         disable={true}
                                        //         ml={0}
                                        //     >
                                        //         Top Professionals in your industry
                                        //     </SectionHeading>
                                        // }
                                        onClick={() => {}}
                                        pl={'20px'}
                                        mb="24px"
                                        // py={'16px'}
                                    >
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="space-between"
                                        >
                                            <Typography
                                                fontFamily="Open Sans"
                                                fontSize="16px"
                                                fontWeight={600}
                                                lineHeight="24px"
                                                letterSpacing="0.024px"
                                            >
                                                Top Professionals in your
                                                industry
                                            </Typography>
                                            <IconButton
                                                onClick={() => {}}
                                                sx={{
                                                    background: '#DFEBF0',
                                                    borderRadius: '8px',
                                                    padding: '8px'
                                                }}
                                            >
                                                <ArrowRight />
                                            </IconButton>
                                        </Box>
                                        <Box mt="8px" bgcolor="#fff">
                                            {professionals.map((person) => (
                                                <ProfileList data={person} />
                                            ))}
                                        </Box>
                                    </Box>

                                    <Box
                                        // heading={
                                        // <SectionHeading ml={0} fontSize={106}>
                                        //     Analytics
                                        // </SectionHeading>
                                        // }
                                        onClick={() => {}}
                                        mb="4px"
                                        bgcolor="transparent"
                                        // pl={{xs: '16px', lg: 0}}
                                    >
                                        <Box
                                            bgcolor="#fff"
                                            p="8px 16px"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="space-between"
                                            mb="4px"
                                        >
                                            <Typography
                                                fontFamily="Open Sans"
                                                fontSize="20px"
                                                fontWeight={600}
                                                lineHeight="28px"
                                                sx={{ cursor: 'pointer' }}
                                            >
                                                Analytics
                                            </Typography>
                                            {/* <IconButton
                                        onClick={() => {}}
                                        sx={{
                                            // background: '#DFEBF0',
                                            borderRadius: '8px',
                                            padding: '8px'
                                        }}
                                    >
                                        <ArrowRight />
                                    </IconButton> */}
                                        </Box>
                                        <Grid
                                            container
                                            gap={1}
                                            bgcolor="#fff"
                                            overflow="hidden"
                                            borderRadius={1.5}
                                            wrap="nowrap"
                                            height="140px"
                                            p={{
                                                xs: '8px 16px',
                                                sm: '8px 20px',
                                                lg: 1
                                            }}
                                        >
                                            <Grid
                                                item
                                                bgcolor="#f2f2f2"
                                                flexGrow={1}
                                                width="100%"
                                                // maxWidth="33%"
                                            >
                                                <Grid
                                                    container
                                                    flexDirection="column"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    height="100%"
                                                >
                                                    <AnalyticsProfile />
                                                    <Typography
                                                        component="p"
                                                        color="#23282B"
                                                        fontFamily="open sans"
                                                        fontSize={{
                                                            xs: 11,
                                                            sm: 14
                                                        }}
                                                        fontWeight={600}
                                                        textAlign="center"
                                                    >
                                                        PROFILE
                                                        <br />
                                                        VIEWS
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                item
                                                bgcolor="#f2f2f2"
                                                flexGrow={1}
                                                width="100%"
                                                // maxWidth="33%"
                                            >
                                                <Grid
                                                    container
                                                    flexDirection="column"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    height="100%"
                                                >
                                                    <AnalyticsSearch />
                                                    <Typography
                                                        component="p"
                                                        color="#23282B"
                                                        fontFamily="open sans"
                                                        fontSize={{
                                                            xs: 11,
                                                            sm: 14
                                                        }}
                                                        fontWeight={600}
                                                        textAlign="center"
                                                    >
                                                        SEARCH
                                                        <br />
                                                        APPEARANCES
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                item
                                                bgcolor="#f2f2f2"
                                                flexGrow={1}
                                                width="100%"
                                                // maxWidth="33%"
                                            >
                                                <Grid
                                                    container
                                                    flexDirection="column"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    height="100%"
                                                >
                                                    <AnalyticsGraph />
                                                    <Typography
                                                        component="p"
                                                        color="#23282B"
                                                        fontFamily="open sans"
                                                        fontSize={{
                                                            xs: 11,
                                                            sm: 14
                                                        }}
                                                        fontWeight={600}
                                                        textAlign="center"
                                                    >
                                                        POSTS
                                                        <br />
                                                        ENGAGEMENTS
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    <Box
                                        py={1}
                                        bgcolor="#fff"
                                        px={isMobile ? '16px' : 1}
                                    >
                                        <Button
                                            variant="contained"
                                            sx={{
                                                backgroundColor: '#f2f2f2',
                                                borderRadius: '8px',
                                                width: '100%'
                                                // ml: isMobile ? '16px' : undefined
                                            }}
                                        >
                                            <Typography
                                                color={'primary'}
                                                fontFamily="open sans"
                                                fontWeight={600}
                                                fontSize={14}
                                                sx={{
                                                    textTransform: 'none',
                                                    py: 0.5
                                                }}
                                            >
                                                Upgrade to Premium plan
                                            </Typography>
                                        </Button>
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Container>
    );
}

export default CompanyProfile;
