const Checked = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
        >
            <path
                d="M19.5841 0.664062H8.41073C3.5574 0.664062 0.664062 3.5574 0.664062 8.41073V19.5707C0.664062 24.4374 3.5574 27.3307 8.41073 27.3307H19.5707C24.4241 27.3307 27.3174 24.4374 27.3174 19.5841V8.41073C27.3307 3.5574 24.4374 0.664062 19.5841 0.664062ZM20.3707 10.9307L12.8107 18.4907C12.6241 18.6774 12.3707 18.7841 12.1041 18.7841C11.8374 18.7841 11.5841 18.6774 11.3974 18.4907L7.62406 14.7174C7.2374 14.3307 7.2374 13.6907 7.62406 13.3041C8.01073 12.9174 8.65073 12.9174 9.0374 13.3041L12.1041 16.3707L18.9574 9.5174C19.3441 9.13073 19.9841 9.13073 20.3707 9.5174C20.7574 9.90406 20.7574 10.5307 20.3707 10.9307Z"
                fill="#05668D"
            />
        </svg>
    );
};

export default Checked;
