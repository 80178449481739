const JobDetailItemShareIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12 22.25C6.34614 22.25 1.75 17.6539 1.75 12C1.75 6.34614 6.34614 1.75 12 1.75C12.1339 1.75 12.25 1.86614 12.25 2C12.25 2.13386 12.1339 2.25 12 2.25C6.62386 2.25 2.25 6.62386 2.25 12C2.25 17.3761 6.62386 21.75 12 21.75C17.3761 21.75 21.75 17.3761 21.75 12C21.75 11.8661 21.8661 11.75 22 11.75C22.1339 11.75 22.25 11.8661 22.25 12C22.25 17.6539 17.6539 22.25 12 22.25Z"
                fill="#494949"
                stroke="#494949"
            />
            <path
                d="M12.9975 11.2522C12.933 11.2522 12.8723 11.2299 12.8211 11.1786C12.7263 11.0839 12.7263 10.9205 12.8211 10.8257L21.0211 2.62574C21.1158 2.531 21.2792 2.531 21.3739 2.62574C21.4687 2.72048 21.4687 2.8839 21.3739 2.97863L13.1739 11.1786C13.1227 11.2299 13.062 11.2522 12.9975 11.2522Z"
                fill="#494949"
                stroke="#494949"
            />
            <path
                d="M21.7519 2.75V2.25H21.2519H17.1719C17.038 2.25 16.9219 2.13386 16.9219 2C16.9219 1.86614 17.038 1.75 17.1719 1.75H22.0019C22.1357 1.75 22.2519 1.86614 22.2519 2V6.83C22.2519 6.96386 22.1357 7.08 22.0019 7.08C21.868 7.08 21.7519 6.96386 21.7519 6.83V2.75Z"
                fill="#494949"
                stroke="#494949"
            />
        </svg>
    );
};

export default JobDetailItemShareIcon;
