export interface IJobPreferences {
    id: string;
    professionalProfileId: string;
    jobTitle?: string;
    jobType?: string[];
    minimumSalary?: string;
    payDuration?: string;
    locationTypes?: string;
}

export interface ICreateJobPreferences extends Omit<IJobPreferences, 'id'> {}

export interface IUpdateJobPreferences
    extends Omit<ICreateJobPreferences, 'professionalProfileId'> {}

export const employmentTypeList: Record<string, string> = {
    FULL_TIME: 'Full-time',
    PART_TIME: 'Part-time',
    TEMPORARY: 'Temporary',
    PERMANENT: 'Permanent',
    CONTRACT: 'Contract',
    INTERNSHIP: 'Internship',
    NEW_GRADE: 'New Graduate'
};

export const payDurationList: Record<string, string> = {
    PER_HOUR: 'Per hour',
    PER_MONTH: 'Per month',
    PER_YEAR: 'Per year'
};
export const locationTypeList: Record<string, string> = {
    ONSITE: 'Onsite',
    REMOTE: 'Remote',
    HYBRID: 'Hybrid'
};
