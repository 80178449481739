import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ArrowBack from '../../assets/icons/ArrowBack';
import EducationSummary from '../Users/components/EducationSummary';
import CustomRadio from './CustomRadio';
import WorkSummary from '../Users/components/WorkSummary';
import { useGetProfessionalProfileById } from '../../utils/hooks/api/professional-profile/useGetProfessionalProfile';
import cambridge from '../../assets/images/cambridge.jpg';
import GenericCompanyLogo from '../../assets/images/generic-company-logo.png';
import { Ranking } from '../Users/types/Ranking';
import { logger } from '../../utils/Helper/logger';
import SnackAlert, { SnackAlertType } from '../../components/Snackbar';
import { ISkillType } from './Resume';
import { experienceLevels } from '../Reviews/constants';

const MobileExperienceModal = ({
    setEducation,
    education,
    workExperiences,
    setWorkExperiences,
    skills,
    setSkills
}: {
    setEducation: any;
    education: any;
    workExperiences: any;
    setWorkExperiences: any;
    skills: any;
    setSkills: any;
}) => {
    const navigate = useNavigate();
    const { id, header } = useParams();
    const { data: user } = useGetProfessionalProfileById(id ?? '');
    const location = useLocation();
    const [selectedRadio, setSelectedRadio] = useState<any | []>([]);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [openSucessSnackbar, setOpenSuccessSnackbar] = useState(false);
    const qualification = location?.pathname;
    const [message, setMessage] = useState('');
    const [type, setType] = useState<SnackAlertType>('error');
    useEffect(() => {
        if (education.length > 0)
            localStorage.setItem('educations', JSON.stringify(education));
        if (skills.length > 0)
            localStorage.setItem('skill', JSON.stringify(skills));
        if (workExperiences.length > 0)
            localStorage.setItem('work', JSON.stringify(workExperiences));
    }, [education, skills, workExperiences]);

    logger.log(education);

    useEffect(() => {
        const educations = localStorage.getItem('educations');
        const work = localStorage.getItem('work');
        const skill = localStorage.getItem('skill');
        if (
            educations !== null &&
            educations !== 'undefined' &&
            educations !== undefined
        ) {
            logger.log('werty');
            setEducation(JSON.parse(educations));
        } else
            localStorage.setItem(
                'educations',
                JSON.stringify(user?.qualifications)
            );
        if (skill !== null && skill !== 'undefined' && skill !== undefined)
            setSkills(JSON.parse(skill));
        else localStorage.setItem('skill', JSON.stringify(user?.skills));

        if (work !== null && work !== 'undefined' && work !== undefined)
            setWorkExperiences(JSON.parse(work));
        else localStorage.setItem('work', JSON.stringify(user?.employments));

        logger.log(education);
        logger.log(skills);
        logger.log(workExperiences);
    }, []);
    logger.log(user?.employments);
    return (
        <Box>
            <Box overflow="auto">
                <Box
                    pt={{ xs: '16px', sm: '30px' }}
                    pb={{ xs: '16px', sm: '22px' }}
                    px="16px"
                    display="flex"
                    alignItems="center"
                    gap="16px"
                    borderBottom="2px solid #D9D9D9"
                    mb={{ xs: '8px' }}
                    bgcolor="#fff"
                >
                    <Box
                        onClick={() => navigate(-1)}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <ArrowBack />
                    </Box>
                    <Typography
                        color="#23282B"
                        fontSize="20px"
                        fontWeight={600}
                        flex={1}
                    >
                        {header}
                    </Typography>
                    <Button
                        variant="contained"
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            bgcolor: '#05668D',
                            width: '71px',
                            height: '40px',
                            borderRadius: '8px',
                            textTransform: 'none',
                            '&:hover': { bgcolor: '#05668D' },
                            fontSize: '16px',
                            fontWeight: 600,
                            color: '#fff'
                        }}
                        onClick={() => {
                            if (header === 'Education') {
                                setEducation([...education, ...selectedRadio]);
                            }
                            if (header === 'Work Experience') {
                                setWorkExperiences([
                                    ...workExperiences,
                                    ...selectedRadio
                                ]);
                            }
                            if (header === 'Skills') {
                                setSkills([...skills, ...selectedRadio]);
                            }
                            if (selectedRadio.length > 0) {
                                setOpenSuccessSnackbar(true);
                            } else {
                                setOpenSnackbar(true);
                                setMessage('Select an item to add');
                                setType('warning');
                            }
                            setSelectedRadio([]);
                        }}
                    >
                        Add
                    </Button>
                </Box>
                <Box px="16px" bgcolor="#fff">
                    {header === 'Education'
                        ? user?.qualifications?.map((item: any, i: any) => (
                              <Box
                                  py="12px"
                                  display="flex"
                                  alignItems="center"
                                  gap="16px"
                              >
                                  <Box flex={1}>
                                      <EducationSummary
                                          key={item.id}
                                          data={{
                                              university: {
                                                  name: item.school,
                                                  image: cambridge
                                              },
                                              major: item.degree,
                                              yearStarted: item.startDate,
                                              yearEnded: item.endDate
                                          }}
                                      />
                                  </Box>
                                  <CustomRadio
                                      checked={selectedRadio.find(
                                          (x: any) => x.id === item.id
                                      )}
                                      onClick={() => {
                                          if (
                                              selectedRadio.find(
                                                  (x: any) => x.id === item.id
                                              )
                                          ) {
                                              setSelectedRadio(
                                                  selectedRadio.filter(
                                                      (y: any) =>
                                                          y.id !== item.id
                                                  )
                                              );
                                          } else {
                                              if (
                                                  education.find(
                                                      (x: any) =>
                                                          x.id === item.id
                                                  )
                                              ) {
                                                  setOpenSnackbar(true);
                                                  setMessage(
                                                      'This item has previously been added to Resume'
                                                  );
                                                  setType('error');
                                              } else {
                                                  setSelectedRadio([
                                                      ...selectedRadio,
                                                      item
                                                  ]);
                                              }
                                          }
                                      }}
                                  />
                              </Box>
                          ))
                        : header === 'Work Experience'
                        ? user?.employments?.map((item: any) => (
                              <Box
                                  py="12px"
                                  display="flex"
                                  alignItems="center"
                                  gap="16px"
                              >
                                  <Box flex={1}>
                                      <WorkSummary
                                          key={item.id}
                                          data={{
                                              employer: {
                                                  name: item.companyName,
                                                  image: GenericCompanyLogo
                                              },
                                              title: item.jobTitle,
                                              yearStarted: item.startDate,
                                              yearEnded: item.endDate,
                                              isCurrentPosition:
                                                  item.isCurrentPosition,
                                              rankings: [
                                                  Ranking.Leader,
                                                  Ranking.Expert,
                                                  Ranking.Advanced,
                                                  Ranking.Experienced
                                              ]
                                          }}
                                      />
                                  </Box>
                                  <CustomRadio
                                      checked={selectedRadio.find(
                                          (x: any) => x.id === item.id
                                      )}
                                      onClick={() => {
                                          if (
                                              selectedRadio.find(
                                                  (x: any) => x.id === item.id
                                              )
                                          ) {
                                              setSelectedRadio(
                                                  selectedRadio.filter(
                                                      (y: any) =>
                                                          y.id !== item.id
                                                  )
                                              );
                                          } else {
                                              if (
                                                  workExperiences.find(
                                                      (x: any) =>
                                                          x.id === item.id
                                                  )
                                              ) {
                                                  setOpenSnackbar(true);
                                                  setMessage(
                                                      'This item has previously been added to Resume'
                                                  );
                                                  setType('error');
                                              } else {
                                                  setSelectedRadio([
                                                      ...selectedRadio,
                                                      item
                                                  ]);
                                              }
                                          }
                                      }}
                                  />
                              </Box>
                          ))
                        : header === 'Skills'
                        ? user?.skills
                              ?.sort((a: ISkillType, b: ISkillType) =>
                                  a?.rating < b?.rating ? 1 : -1
                              )
                              ?.map((item: ISkillType, i: number) => {
                                  let level = experienceLevels?.find(
                                      (y) => y.key === item.rating
                                  );
                                  return (
                                      <Box
                                          display="flex"
                                          alignItems="center"
                                          gap="16px"
                                          mb="16px"
                                      >
                                          <Box flex={1}>
                                              <Typography>
                                                  {item.name}
                                              </Typography>
                                          </Box>
                                          <CustomRadio
                                              checked={selectedRadio.find(
                                                  (x: any) =>
                                                      x.name === item.name
                                              )}
                                              onClick={() => {
                                                  if (
                                                      selectedRadio.find(
                                                          (x: any) =>
                                                              x.name ===
                                                              item.name
                                                      )
                                                  ) {
                                                      setSelectedRadio(
                                                          selectedRadio.filter(
                                                              (y: any) =>
                                                                  y.name !==
                                                                  item.name
                                                          )
                                                      );
                                                  } else {
                                                      if (
                                                          skills.find(
                                                              (x: any) =>
                                                                  x.name ===
                                                                  item.name
                                                          )
                                                      ) {
                                                          setOpenSnackbar(true);
                                                          setMessage(
                                                              'This item has previously been added to Resume'
                                                          );
                                                          setType('error');
                                                      } else {
                                                          setSelectedRadio([
                                                              ...selectedRadio,
                                                              item
                                                          ]);
                                                      }
                                                  }
                                              }}
                                          />
                                      </Box>
                                  );
                              })
                        : null}
                </Box>
            </Box>
            <SnackAlert
                open={openSnackbar}
                handleClose={() => setOpenSnackbar(false)}
                message={message}
                type={type}
            />
            <SnackAlert
                open={openSucessSnackbar}
                handleClose={() => setOpenSuccessSnackbar(false)}
                message="Item(s) has been successfully added to Resume"
                type="success"
            />
        </Box>
    );
};

export default MobileExperienceModal;
