import { Box } from '@mui/material';
import Header from '../components/JobPost/Header';
import JobPostList from '../components/JobPost/JobPostList';
import MobileNav from '../../../components/MobileNav/MobileNav';

const JobPostings = () => {
    const route = [
        {
            label: 'Job Postings',
            route: '/job-posting'
        },
        {
            label: 'Applications',
            route: '/job-applications'
        },
        {
            label: 'Saved Applications',
            route: '/save-applications'
        }
    ];
    return (
        <Box
            sx={{
                mt: { xs: '21px', sm: 0 },
                pr: {xs: 0, sm: '20px', lg:'40px'}
            }}
        >
            <Box display={{ xs: 'block', sm: 'none' }}>
                <MobileNav list={route} />
            </Box>
            <Header />
            <Box display={{ xs: 'none', sm: 'block', lg: 'none' }}>
                <MobileNav list={route} />
            </Box>
            <JobPostList />
        </Box>
    );
};

export default JobPostings;
