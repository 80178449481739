import React from 'react'

const EyeMenuIcon = ({isActive=false}) => {
  return (
      <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
          <path
              d="M9.99993 13.6092C8.00827 13.6092 6.3916 11.9926 6.3916 10.0009C6.3916 8.00924 8.00827 6.39258 9.99993 6.39258C11.9916 6.39258 13.6083 8.00924 13.6083 10.0009C13.6083 11.9926 11.9916 13.6092 9.99993 13.6092ZM9.99993 7.64258C8.69993 7.64258 7.6416 8.70091 7.6416 10.0009C7.6416 11.3009 8.69993 12.3592 9.99993 12.3592C11.2999 12.3592 12.3583 11.3009 12.3583 10.0009C12.3583 8.70091 11.2999 7.64258 9.99993 7.64258Z"
              fill={isActive ? '#05668D' : '#494949'}
          />
          <path
              d="M9.9999 17.5158C6.86657 17.5158 3.90824 15.6824 1.8749 12.4991C0.991569 11.1241 0.991569 8.88242 1.8749 7.49909C3.91657 4.31576 6.8749 2.48242 9.9999 2.48242C13.1249 2.48242 16.0832 4.31576 18.1166 7.49909C18.9999 8.87409 18.9999 11.1158 18.1166 12.4991C16.0832 15.6824 13.1249 17.5158 9.9999 17.5158ZM9.9999 3.73242C7.30824 3.73242 4.73324 5.34909 2.93324 8.17409C2.30824 9.14909 2.30824 10.8491 2.93324 11.8241C4.73324 14.6491 7.30824 16.2658 9.9999 16.2658C12.6916 16.2658 15.2666 14.6491 17.0666 11.8241C17.6916 10.8491 17.6916 9.14909 17.0666 8.17409C15.2666 5.34909 12.6916 3.73242 9.9999 3.73242Z"
              fill={isActive ? '#05668D' : '#494949'}
          />
      </svg>
  );
}

export default EyeMenuIcon
