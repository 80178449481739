import React, { useState } from 'react';
import Header from './components/Header';
import UserActions from './components/UserActions';
import UserDetails from './components/UserDetails';
import TableWrapper from './components/TableWrapper';
import ExperienceLevel from './components/ExperienceLevel';
import {
    Box,
    Button,
    Grid,
    TableCell,
    TableRow,
    Typography
} from '@mui/material';
import { useGetConnections } from '../../utils/hooks/api/connections/useGetConnections';
import { useAuth } from '../../utils/context/AuthContext';
import { useGetProfessionalRatingByProfileId } from '../../utils/hooks/api/reviews/useGetProfessionalRatingByProfileId';
import { experienceLevels } from '../Reviews/constants';
import ProfessionalProfile from '../ProfessionalProfile/index';
import ConnectionSkills from './components/ConnectionSkillsTab';
import { logger } from '../../utils/Helper/logger';
import ConnectionLoader from '../../components/LoadingState/ConnectionLoader';
import EmptyState from '../../components/EmptyState/EmptyState';
import { Responsiveness } from '../../theme/overrides';
import MobileNav from '../../components/MobileNav/MobileNav';
import CustomPageTabs from '../../components/CustomPageTabs/CustomPageTabs';

interface IConnectionRequestType {
    name: string;
}
// const experienceLevels = [
//     { background: '#E75541', title: 'Beginner', shortForm: 'B' },
//     { background: '#F6C70E', title: 'Mobile Int', shortForm: 'E' },
//     {
//         background: '#49B6FF',
//         title: 'Customer Experience Design',
//         shortForm: 'A'
//     },
//     { background: '#95C97A', title: 'Expert', shortForm: 'X' }
// ];

function ConnectionsPage() {
    const [users, setUsers] = React.useState<Array<IConnectionRequestType>>([]);
    const [page, setPage] = React.useState(0);
    const rowsPerPage = 9;
    const { user } = useAuth();
    const { isMobile, isTablet } = Responsiveness();

    const { data: connections, isFetching } = useGetConnections(user?.id ?? '');

    const handleChangePage = (page: number) => {
        setPage(page - 1);
    };
    const tabs = ['Connections', 'Following']
    const routes = [
        {
            label: 'My Community',
            route: '/connections'
        },
        {
            label: 'Pending',
            route: '/pending-connections'
        },
        {
            label: 'Suggestions',
            route: '#',
            disable: true
        }
    ];

    React.useEffect(() => {
        setUsers(connections);
    }, [connections]);

    const [selectedButton, setSelectedButton] = useState('Connections');
    // const handleButtonClick = (button: 'community' | 'following') => {
    //     setSelectedButton(button);
    // };

    return (
        <Box
            sx={{
                pr: { lg: '40px' },
                marginTop: isMobile ? '8px' : '0px',
                position: 'relative'
            }}
        >
            {isMobile ? <MobileNav list={routes} /> : null}
            <Header
                setUsers={setUsers}
                data={connections}
                title={'ConnectionPage'}
            />
            {isMobile ? null : isTablet ? <MobileNav list={routes} /> : null}
            <Box
                sx={{
                    // width: '1149px',
                    px: { xs: '16px', sm: '20px', lg: '40px' },
                    py: '16px',
                    //gap: '32px',
                    background: '#fff',
                    mt: {xs: 0, lg: '12px'}
                }}
            >
                <CustomPageTabs
                    tabs={tabs}
                    currentTab={selectedButton}
                    setCurrentTab={setSelectedButton}
                />
            </Box>

            {/* Render different content based on the selected button */}
            {selectedButton === 'Connections' ? (
                // Content for the Community page
                <Grid container mt={{xs: '8px', lg: '12px'}}>
                    {isFetching ? (
                        <ConnectionLoader />
                    ) : users?.length > 0 ? (
                        <TableWrapper
                            handleChangePage={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            data={users}
                        >
                            {!Array.isArray(users)
                                ? null
                                : users
                                      ?.slice(
                                          page * rowsPerPage,
                                          page * rowsPerPage + rowsPerPage
                                      )
                                      ?.map((user: any, index: number) => (
                                          <TableRow key={`user_${index}`}>
                                              <TableCell
                                                  sx={{
                                                      padding: isMobile
                                                          ? '16px'
                                                          : isTablet
                                                          ? '12px 20px 20px 20px'
                                                              : '12px 40px 20px 40px',
                                                      mb: {xs: 0, sm: index === users.length + 1 ? 0 : '4px', lg: 0}
                                                  }}
                                              >
                                                  <Box
                                                      sx={{
                                                          display: 'flex',
                                                          //   alignItems: 'inset',
                                                          gap: '16px',
                                                          alignItems: 'center'
                                                      }}
                                                  >
                                                          <UserDetails
                                                              user={
                                                                  user?.destinationUser
                                                              }
                                                              id={
                                                                  user
                                                                      ?.destinationUser
                                                                      ?.profileId
                                                              }
                                                          />
                                                      <UserActions
                                                          user={user}
                                                          title={
                                                              'ConnectionPage'
                                                          }
                                                      />
                                                  </Box>
                                              </TableCell>
                                          </TableRow>
                                      ))}
                        </TableWrapper>
                    ) : (
                        <EmptyState message="You do not have any connections yet. Add or invite others to your network." />
                    )}
                </Grid>
            ) : (
                // Content for the Following page
                <EmptyState message="You do not have any followers yet. Add or invite others to your network." />
            )}
        </Box>
    );
}

export default ConnectionsPage;
