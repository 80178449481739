import { EducationLevel } from './types/Education';
import { CompanySize, Employment, EmploymentLevel } from './types/Employment';
import { LocationType } from './types/Location';

export const EMPLOYMENT_TYPE_OPTIONS = [
    { label: 'Full Time', value: Employment.FullTime },
    { label: 'Part Time', value: Employment.PartTime },
    { label: 'Contract', value: Employment.Contract },
    { label: 'New Grad', value: Employment.newGrad },
    { label: 'Internship', value: Employment.Internship },
    { label: 'Permanent', value: Employment.permanent },
    { label: 'Temporary', value: Employment.temporary }
];

export const COMPANY_SIZE_OPTIONS = [
    { label: 'Family-owned, family ran companies', value: CompanySize.family },
    { label: 'Small to medium sized private companies', value: CompanySize.small },
    { label: 'Medium sized public companies', value: CompanySize.medium },
    { label: 'Large corporation, not in Fortune 500 list   ', value: CompanySize.large },
    { label: 'Fortune 500, ivy league companies ', value: CompanySize.fortune500 }
];

export const LOCATION_TYPE_OPTIONS = [
    { label: 'Onsite', value: LocationType.Onsite },
    { label: 'Hybrid', value: LocationType.Hybrid },
    { label: 'Remote', value: LocationType.Remote }
];

export const EDUCATION_LEVEL_OPTIONS = [
    {
        label: 'High-school / GED / Artisanal apprenticeship ',
        value: EducationLevel.GED
    },
    {
        label: 'Diploma / Trade school / Military training  ',
        value: EducationLevel.DIPLOMA
    },
    { label: 'BACHELORS', value: EducationLevel.BACHELORS },
    { label: 'MASTERS', value: EducationLevel.MASTERS },
    { label: 'PHD', value: EducationLevel.PHD }
];

export const EMPLOYMENT_LEVEL_OPTIONS = [
    { label: 'Intern', value: EmploymentLevel.INTERN },
    { label: 'Junior', value: EmploymentLevel.JUNIOR },
    { label: 'Mid', value: EmploymentLevel.MID },
    { label: 'Senior', value: EmploymentLevel.SENIOR },
    { label: 'Manager', value: EmploymentLevel.MANAGER },
    { label: 'Director', value: EmploymentLevel.DIRECTOR },
    { label: 'C_Level', value: EmploymentLevel.C_LEVEL },
    { label: 'Owner', value: EmploymentLevel.OWNER }
];
