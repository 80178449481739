import { Box, Button, Typography } from '@mui/material';
import JobSalaryIcon from '../../../../assets/icons/JobSalaryIcon';
import JobTypeIcon from '../../../../assets/icons/JobTypeIcon';
import VerifiedIcon from '../../../../assets/icons/VerifiedIcon';
import moment from 'moment';
import GenericCompanyLogo from './GenericCompanyLogo';
import { JobItemExternalProps } from '../../../../utils/hooks/api/jobs/useGetExternalJobs';

const JobItemExternal = ({ jobInfo }: { jobInfo: JobItemExternalProps }) => {
    const maxLength = 30
    const maxCompanyLength = 25
    const CompanyInfo = () => {
        return (
            <Box
                sx={{
                    borderTopRightRadius: { xs: 0, sm: '8px' },
                    borderTopLeftRadius: { xs: 0, sm: '8px' },
                    borderBottomWidth: 0,
                    // border: '2px solid red'
                }}
            >
                <Box
                    sx={{
                        backgroundColor: { xs: '#fff', sm: '#FFECC8' },
                        borderRadius: { xs: 0, sm: '8px 8px 25px 25px' },
                        minHeight: { xs: 0, sm: '156px' },
                        p: { xs: '12px 16px', sm: '20px' }
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '16px',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                gap: { xs: '12px', sm: '16px' }
                            }}
                        >
                            <Box>
                                <GenericCompanyLogo />
                            </Box>
                            <Box>
                                <Typography
                                    variant="titleMediumSemiBold"
                                    sx={{
                                        display: { xs: 'none', sm: 'block' },
                                        color: '#23282B',
                                        fontSize: { xs: '16px', lg: '20px' }
                                    }}
                                >
                                    {jobInfo.title}
                                </Typography>
                                <Typography
                                    variant="titleMediumSemiBold"
                                    sx={{
                                        display: { xs: 'block', sm: 'none' },
                                        color: '#23282B',
                                        fontSize: { xs: '16px', lg: '20px' }
                                    }}
                                >
                                    {jobInfo.title.length > maxLength
                                        ? `${jobInfo.title.slice(
                                              0,
                                              maxLength
                                          )}...`
                                        : jobInfo.title}
                                </Typography>
                                {jobInfo?.company && <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '8px'
                                    }}
                                >
                                    <Typography
                                        variant="titleSmallSemiBold"
                                        sx={{
                                            display: {
                                                xs: 'block',
                                                sm: 'none'
                                            },
                                            color: '#808080',
                                            textTransform: 'uppercase'
                                            // fontSize: { xs: '14px', lg: '16px' }
                                        }}
                                    >
                                        {jobInfo?.company?.length >
                                        maxCompanyLength
                                            ? `${jobInfo?.company.slice(
                                                  0,
                                                  maxCompanyLength
                                              )}...`
                                            : jobInfo?.company}
                                    </Typography>
                                    <Typography
                                        variant="titleSmallSemiBold"
                                        sx={{
                                            display: {
                                                xs: 'none',
                                                sm: 'block'
                                            },
                                            color: '#808080',
                                            textTransform: 'uppercase'
                                            // fontSize: { xs: '14px', lg: '16px' }
                                        }}
                                    >
                                        {jobInfo?.company}
                                    </Typography>
                                    <VerifiedIcon />
                                </Box>}
                                <Typography
                                    variant="titleSmallRegular"
                                    sx={{
                                        color: '#808080'
                                    }}
                                >
                                    {jobInfo.location}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        );
    };

    const JobPostingRequirements = () => {
        return (
            <Box display={{xs: 'none', sm: 'block'}}
                sx={{
                    backgroundColor: '#FCFBF8',
                    padding: '20px',
                    // border: '1px solid #D8D8D8',
                    borderTopWidth: 0,
                    borderBottomRightRadius: '8px',
                    borderBottomLeftRadius: '8px',
                    pt: '16px'
                }}
            >
                <Typography
                    variant="titleMediumSemiBold"
                    sx={{
                        color: '#23282B',
                        textTransform: 'uppercase'
                    }}
                >
                    Requirements
                </Typography>
                <Box
                    sx={{
                        mt: '8px',
                        display: 'flex',
                        gap: '4px',
                        fontFamily: 'Open Sans',
                        fontWeight: 600,
                        fontSize: '14px',
                        color: '#808080'
                    }}
                >
                    {jobInfo?.jobType ? (
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '4px',
                                alignItems: 'center',
                                backgroundColor: '#F2F2F2',
                                borderRadius: '4px',
                                p: '4px 12px'
                            }}
                        >
                            <JobTypeIcon />
                            {jobInfo.jobType}
                        </Box>
                    ) : null}
                    {jobInfo?.salary ? (
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '4px',
                                alignItems: 'center',
                                backgroundColor: '#F2F2F2',
                                borderRadius: '4px',
                                p: '4px 12px'
                            }}
                        >
                            <JobSalaryIcon />
                            {jobInfo.salary}
                        </Box>
                    ) : null}
                </Box>
                <Box sx={{ mt: '16px' }}>
                    <Typography
                        sx={{
                            color: '#23282B'
                        }}
                        variant="titleMediumSemiBold"
                    >
                        More
                    </Typography>
                    <p
                        style={{
                            color: '#5B5B5B',
                            fontSize: '14px',
                            marginTop: '12px',
                            marginBottom: 0,
                            padding: 0,
                            height: '88px',
                            width: '100%',
                            overflow: 'hidden'
                            // border:'2px solid black'
                        }}
                    >
                        {jobInfo?.description
                            .replace(/<[^>]+>/g, '')
                            .replace(/&nbsp;/g, ' ')}
                    </p>
                    <Typography
                        variant="labelMediumRegular"
                        sx={{
                            mt: '12px',
                            color: '#23282B'
                        }}
                    >
                        {jobInfo.lastUpdated
                            ? `Posted ${moment(jobInfo.lastUpdated).fromNow()}`
                            : null}
                    </Typography>
                    <JobPostingCTA />
                </Box>
            </Box>
        );
    };

    const JobPostingCTA = () => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '20px',
                    gap: '8px'
                }}
            >
                <Button
                    variant="contained"
                    sx={{
                        padding: '10px 15px',
                        width: 'fit-content',
                        flex: 2,
                        fontSize: '14px',
                        fontWeight: 600
                    }}
                    component="a"
                    href={jobInfo?.link}
                    target="_blank"
                >
                    Apply
                </Button>
            </Box>
        );
    };

    return (
        <Box
            sx={{
                maxWidth: { xs: '100%', sm: '480px', md: '380px', lg: '430px', xl: '480px' },
                // height: '566px',
                width: '100%',
                backgroundColor: '#FCFBF8',
                // mb: 4
                border: { xs: '1px solid #D8D8D8' },
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}
        >
            <CompanyInfo />
            <JobPostingRequirements />
        </Box>
    );
};

export default JobItemExternal;
