import { Avatar, Box, Typography } from '@mui/material';
import { useGetProfessionalProfileScore } from '../../../utils/hooks/api/professional-profile/useGetProfessionalProfileScore';
import { Responsiveness } from '../../../theme/overrides';
import { useNavigate } from 'react-router-dom';
const UserDetails = (props: IPropTypes) => {
    const { user, showActive = false, id } = props;
    const { data: score } = useGetProfessionalProfileScore(id ?? '');
    const { isMobile } = Responsiveness()
    const navigate = useNavigate()

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: isMobile ? '12px' : '20px'
            }}
        >
            <Box sx={{ position: 'relative' }}>
                <Avatar
                    onClick={() =>
                        navigate(`/professional-profile/${user?.id ?? id}`)
                    }
                    alt="Remy Sharp"
                    src={user?.photoUrl}
                    sx={{
                        width: isMobile ? 44 : 56,
                        height: isMobile ? 44 : 56
                    }}
                />
                {showActive ? (
                    <Box
                        sx={{
                            width: '16px',
                            height: '16px',
                            background: '#08BD04',
                            borderRadius: '100px',
                            position: 'absolute',
                            right: 0,
                            top: '43px',
                            border: '3px solid white'
                        }}
                    />
                ) : null}
            </Box>
            <Box display="flex" flexDirection="column" gap="2px">
                <Box display="flex" alignItems={'center'} gap="12px">
                    <Typography
                        onClick={() =>
                            navigate(`/professional-profile/${user?.id ?? id}`)
                        }
                        component={'h1'}
                        variant="titleLargeSemiBold"
                        sx={{
                            color: '#23282B',
                            fontSize: isMobile ? '16px' : undefined
                        }}
                    >
                        {user?.firstName} {user?.lastName}
                    </Typography>
                    {isMobile && <Typography
                        variant="titleLargeSemiBold"
                        sx={{
                            color: '#FFFFFF',
                            background: '#49B6FF',
                            borderRadius: '8px',
                            padding: '2px 8px'
                        }}
                    >
                        {score ?? 0}
                    </Typography>}
                </Box>
                {isMobile ? (
                    <Box>
                        {user?.currentEmployment?.jobTitle ? (
                            <>
                                <Typography
                                    sx={{
                                        color: '#494949',
                                        letterSpacing: '0.0015em',
                                        fontSize: isMobile ? '16px' : undefined
                                    }}
                                >
                                    {user?.currentEmployment?.jobTitle}
                                </Typography>
                            </>
                        ) : null}
                        {user?.currentEmployment?.companyName ? (
                            <>
                                <Typography
                                    sx={{
                                        color: '#808080',
                                        fontSize: isMobile ? '16px' : undefined
                                    }}
                                >
                                    {user?.currentEmployment?.companyName}
                                </Typography>
                            </>
                        ) : null}
                    </Box>
                ) : null}
                {isMobile ? null : (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '20px'
                        }}
                    >
                        {user?.currentEmployment?.employmentLevel ? (
                            <>
                                <Typography
                                    sx={{
                                        color: '#808080',
                                        letterSpacing: '0.0015em'
                                    }}
                                >
                                    {user?.currentEmployment?.employmentLevel}
                                </Typography>

                                <Typography
                                    component={'div'}
                                    sx={{
                                        width: '7px',
                                        height: '7px',
                                        borderRadius: '100px',
                                        background: '#494949',
                                        border: '2px solid #494949'
                                    }}
                                />
                            </>
                        ) : null}
                        {user?.currentEmployment?.companyName ? (
                            <>
                                <Typography
                                    sx={{
                                        color: '#808080'
                                    }}
                                >
                                    {user?.currentEmployment?.companyName}
                                </Typography>

                                <Typography
                                    component={'div'}
                                    sx={{
                                        width: '7px',
                                        height: '7px',
                                        borderRadius: '100px',
                                        background: '#494949',
                                        border: '2px solid #494949'
                                    }}
                                />
                            </>
                        ) : null}
                        {user?.score ? (
                            <Typography
                                variant="titleLargeSemiBold"
                                sx={{
                                    color: '#FFFFFF',
                                    background: '#49B6FF',
                                    borderRadius: '12px',
                                    padding: '2px 12px'
                                }}
                            >
                                {score}
                            </Typography>
                        ) : null}
                    </Box>
                )}
            </Box>
        </Box>
    );
};
interface IPropTypes {
    user: User;
    showActive?: boolean;
    id?: string;
}
type User = {
    id: string;
    score: number;
    photoUrl: string;
    firstName: string;
    lastName?: string;
    currentEmployment: {
        companyName: string;
        employmentLevel: string;
        jobTitle: string;
    };
    profileId?: string;
};
export default UserDetails;
