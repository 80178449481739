import React from 'react';

const HatIcon = ({ color = '#05668D' }) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.8291 15.6402C17.4991 15.2002 18.3791 15.6802 18.3791 16.4802V17.7702C18.3791 19.0402 17.3891 20.4002 16.1991 20.8002L13.0091 21.8602C12.4491 22.0502 11.5391 22.0502 10.9891 21.8602L7.79914 20.8002C6.59914 20.4002 5.61914 19.0402 5.61914 17.7702V16.4702C5.61914 15.6802 6.49914 15.2002 7.15914 15.6302L9.21914 16.9702C10.0091 17.5002 11.0091 17.7602 12.0091 17.7602C13.0091 17.7602 14.0091 17.5002 14.7991 16.9702L16.8291 15.6402Z"
                fill={color}
            />
            <path
                d="M19.9795 6.45957L13.9895 2.52957C12.9095 1.81957 11.1295 1.81957 10.0495 2.52957L4.02953 6.45957C2.09953 7.70957 2.09953 10.5396 4.02953 11.7996L5.62953 12.8396L10.0495 15.7196C11.1295 16.4296 12.9095 16.4296 13.9895 15.7196L18.3795 12.8396L19.7495 11.9396V14.9996C19.7495 15.4096 20.0895 15.7496 20.4995 15.7496C20.9095 15.7496 21.2495 15.4096 21.2495 14.9996V10.0796C21.6495 8.78957 21.2395 7.28957 19.9795 6.45957Z"
                fill={color}
            />
        </svg>
    );
};

export default HatIcon;
