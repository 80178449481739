import {
    Box,
    Button,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Typography
} from '@mui/material';
import { ArrowLeftIcon } from '../../../assets/icons/ArrowLeftIcon';
import MoreIcon from '../../../assets/icons/MoreIcon';
import SelectDropdown from '../../../components/Selectdropdown';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { inviteDialogSignal } from '../../../components/InviteDialog';
import { Responsiveness } from '../../../theme/overrides';
import PlusIcon from '../../../assets/icons/PlusIcon';

const Header = (props: PropTypes) => {
    const { isXMobile, isMobile, isTablet } = Responsiveness()
    const { data = [], setUsers = () => {}, title } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const navigate = useNavigate();
    const open = Boolean(anchorEl);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const sortData = (type: string) => {
        const sortFn = ({ name: nameA }: data, { name: nameB }: data) => {
            const a = nameA[0].toLowerCase();
            const b = nameB[0].toLowerCase();
            return type === 'A-Z'
                ? a.localeCompare(b)
                : type === 'Z-A'
                ? b.localeCompare(a)
                : 0;
        };
        setUsers([...data].sort(sortFn));
        setAnchorEl(null);
    };

    return (
        <>
            <Grid
                container
                sx={{
                    backgroundColor: '#FFFFFF',
                    alignItems: 'center',
                    px: isMobile ? '0px' : isTablet ? '20px' : '40px',
                    py: isMobile ? '16px' : isTablet ? '20px' : '24px',
                    borderBottom: '1px solid #D9D9D9'
                }}
            >
                <Grid
                    item
                    xs={8}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: isXMobile ? '8px' : isMobile ? '12px' : '28px'
                    }}
                >
                    <IconButton onClick={() => navigate('/')}>
                        <ArrowLeftIcon />
                    </IconButton>
                    <Typography
                        component={'h1'}
                        sx={{
                            fontSize: isMobile ? '16px' : '24px',
                            fontWeight: '600',
                            fontFamily: 'Open Sans',
                            color: '#23282B'
                        }}
                    >
                        {title === 'ConnectionPage'
                            ? 'My Community'
                            : 'Pending'}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={4}
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        gap: isXMobile ? '8px' : isMobile ? '12px' : '28px'
                    }}
                >
                    <Button
                        variant="outlined"
                        sx={{
                            width: isMobile
                                ? '107px'
                                : isTablet
                                ? '87px'
                                : '150px',
                            position: { xs: 'fixed', sm: 'static' },
                            height: isMobile ? '40px' : '44px',
                            bottom: '104px',
                            right: '16px',
                            bgcolor: { xs: '#FAFAFA', sm: 'transparent' },
                            zIndex: 1
                        }}
                        onClick={() => (inviteDialogSignal.value = true)}
                        startIcon={
                            <Box display={{ xs: 'flex', sm: 'none' }}>
                                <PlusIcon />
                            </Box>
                        }
                    >
                        Invite
                    </Button>
                    {/*{props.title === 'ConnectionPage' && (
                        <SelectDropdown
                            label="Sort"
                            halfWidth={true}
                            options={['A-Z', 'Z-A']}
                            style={{ width: '90px', height: '50px' }}
                            handleChange={(e: any) => sortData(e.target.value)}
                        />
                    )}*/}
                    <IconButton>
                        <MoreIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: 100,
                        width: '20ch'
                    }
                }}
            >
                {['test', 'test'].map((option: any) => (
                    <MenuItem key={option} onClick={handleClose}>
                        {option}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

interface PropTypes {
    data?: data[];
    setUsers?: (data: data[]) => void;
    title: string;
}
type data = {
    name: string;
};

export default Header;
