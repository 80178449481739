import { Box, Skeleton } from '@mui/material';
import React from 'react';
import { Responsiveness } from '../../../theme/overrides';

const SkeletonLoader = () => {
    const { isMobile } = Responsiveness()
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    gap: '20px',
                    marginTop: '30px',
                    px: isMobile ? '16px' : undefined
                }}
            >
                <Skeleton variant="circular" width={80} height={80} />
                <Box>
                    <Skeleton
                        variant="text"
                        sx={{
                            fontSize: '1rem',
                            height: '30px',
                            maxWidth: '250px',
                            mt: '15px'
                        }}
                    />
                    <Skeleton
                        variant="text"
                        sx={{
                            fontSize: '1rem',
                            height: '30px',
                            width: '150px',
                            mt: '8px'
                        }}
                    />
                </Box>
            </Box>
            <Box sx={{ px: isMobile ? '16px' : undefined }}><Skeleton
                variant="rounded"
                width={'100%'}
                height={300}
                sx={{ marginTop: '10px' }}
            /></Box>
            
        </>
    );
};

export default SkeletonLoader;
