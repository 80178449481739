import {
    Box,
    Grid,
    Typography,
    Link,
    Button,
    Menu,
    MenuItem
} from '@mui/material';
/* eslint-disable react-hooks/rules-of-hooks */
import AchievementsCard from './components/AchievementsCard';
import FeedbackCard from './components/FeedbackCard';
import CreateFeedCard from './components/CreateFeedCard';
import FeedList from './components/FeedList';
import ScoreCard from '../../components/ScoreCard.tsx';
import CardBox from './components/CardBox';
import TeamIcon from '../../assets/icons/TeamIcon';
import ConnectionsIcon from '../../assets/icons/ConnectionsIcon';
import HatIcon from '../../assets/icons/HatIcon';
import { useAuth } from '../../utils/context/AuthContext';
import { PrimaryProfileType } from '../../utils/hooks/api/account/types';
import ArrowDown from '../../assets/icons/ArrowDown';
// Placeholder data
import chika from '../../assets/images/chika.png';
import philips from '../../assets/images/philips.png';
import solomon from '../../assets/images/homeReviewImage.png';
import JobFactorIcon from '../../assets/icons/JobFactorIcon';
import Linkedin from '../../assets/icons/Linkedin';
import Twitter from '../../assets/icons/Twitter';
import Facebook from '../../assets/icons/Facebook';
import Instagram from '../../assets/icons/Instagram';
import Youtube from '../../assets/icons/Youtube';
import Tiktok from '../../assets/icons/Tiktok';
import { useState } from 'react';
import { useGetConnectionRequestReceived } from '../../utils/hooks/api/connections/useGetConnectionRequestReceived';
import { useNavigate } from 'react-router';
import { useGetProfessionalProfileScore } from '../../utils/hooks/api/professional-profile/useGetProfessionalProfileScore';
import { useGetCompanyProfileScore } from '../../utils/hooks/api/company-profile/useGetCompanyProfileScore';
import AddIcon from '../../assets/icons/AddIcon';
import PostCards from './components/PostCards';
import PostButton from './components/PostButton';
import { useGetPendingCompanyRequests } from '../../utils/hooks/api/company-review-requests/useGetPendingCompanyRequests';
import CameraPostOptionIcon from '../../assets/icons/CameraPostOptionIcon';
import AchievementPostOptionIcon from '../../assets/icons/AchievementPostOptionIcon';
import ArticlePostOptionIcon from '../../assets/icons/ArticlePostOptionIcon';
import CloseXIcon from '../../assets/icons/CloseXIcon';
import { logger } from '../../utils/Helper/logger';
import { useGetProfessionalRatingByProfileId } from '../../utils/hooks/api/reviews/useGetProfessionalRatingByProfileId';

function HomePage() {
    const { account, user } = useAuth();
    const [showModal, setShowModal] = useState('');
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl);
    const handleOpen = (e: any) => {
        setAnchorEl(e.currentTarget)
    }
    const handleClose = () => setAnchorEl(null);
    const isCompany = account?.primaryProfile === PrimaryProfileType.Company;
    const { data: pendingConnections } = useGetConnectionRequestReceived(
        user?.id ?? ''
    );

    const { data: skills } = useGetProfessionalRatingByProfileId(
        user?.professionalProfile?.id ?? ''
    );
    logger.log(skills)

    const navigate = useNavigate();
    const { data: pendingRequests = [] } = useGetPendingCompanyRequests(user?.professionalProfile?.userId!);
    const { data: score } =
        account?.primaryProfile === PrimaryProfileType.Company
            ? useGetCompanyProfileScore(user?.primaryCompanyProfile?.id!)
            : useGetProfessionalProfileScore(user?.professionalProfile?.id!);

    // Placeholder data   
    const employees = [
        { name: 'Chika Okoto', img: chika },
        { name: 'Jobs Philips', img: philips },
        { name: 'Solomon Odewor', img: solomon }
    ];
    const courses = [
        { name: 'UI/UX Design' },
        { name: 'Cloud Development' },
        { name: 'Machine Learning' }
    ];
    const Links = {
        color: '#23282B',
        fontSize: '12px',
        cursor: 'pointer',
        fontFamily: 'Open Sans',
        whiteSpace: 'nowrap',
        '& svg': {
            mb: '-7px'
        }
    };
    return (
        <Box
            mt={{ xs: '8px', sm: '0px', lg: '8px' }}
            ml={{ xs: '0px', xl: 0 }}
            mr={{ lg: '40px', sm: '20px', xs: 0 }}
        >
            <Grid
                container
                spacing={{ xs: '8px' }}
                display={{ sm: 'flex', xs: 'none' }}
            >
                <Grid item lg={8} xs={12}>
                    {isCompany ? null : (
                        <Grid
                            container
                            spacing={{ lg: 2 }}
                            mb={'8px'}
                            wrap="nowrap"
                            // sx={{ display: {xs: 'flex', lg: undefined}, alignItems: {xs: 'center', lg: undefined}, justifyContent: {xs: 'space-between', lg: undefined} }}
                        >
                            <Grid item xs={9} lg={6}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexGrow: 1,
                                        flexDirection: 'column',
                                        height: '100%' // Set a consistent height for both cards
                                    }}
                                >
                                    <AchievementsCard />
                                </Box>
                            </Grid>
                            <Grid
                                item
                                sm={6}
                                xs={12}
                                display={{ lg: 'block', xs: 'none' }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexGrow: 1,
                                        flexDirection: 'column',
                                        height: '100%' // Set a consistent height for both cards
                                    }}
                                >
                                    <FeedbackCard />
                                </Box>
                            </Grid>
                            <Grid
                                item
                                xs={3}
                                display={{ xs: 'flex', lg: 'none' }}
                                sx={{
                                    bgcolor: '#fff',
                                    px: '20px',
                                    ml: '8px',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <ScoreCard score={score} />
                            </Grid>
                        </Grid>
                    )}
                    <CreateFeedCard />
                    <FeedList />
                </Grid>
                <Grid
                    item
                    mb={3}
                    sm={4}
                    xs={12}
                    sx={{
                        paddingRight: 0,
                        width: '100%',
                        pl: '26px !important',
                        display: { lg: 'block', xs: 'none' }
                    }}
                >
                    <Box
                        sx={{
                            gap: '16px',
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            height: '100%',
                            position: 'relative'
                        }}
                    >
                        <Box
                            sx={{
                                zIndex: 999,
                                textAlign: 'center',
                                width: '100%',
                                position: 'sticky',
                                top: '82px',
                                left: '20px',
                                bgcolor: '#fff'
                            }}
                        >
                            <ScoreCard
                                height="228px"
                                width="100%"
                                background="#fff"
                                score={score}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '20px',
                                width: '100%'
                            }}
                        >
                            {(isCompany && pendingRequests.length > 0) ||
                            !isCompany ? (
                                <CardBox
                                    title={`Rate your ${
                                        isCompany ? 'employees' : 'companies'
                                    }`}
                                    icon={<TeamIcon />}
                                    list={
                                        isCompany ? employees : pendingRequests
                                    }
                                    btnText="Review all"
                                    btnOnClick={() =>
                                        navigate('/reviews/company-ratings')
                                    }
                                />
                            ) : null}
                            {!pendingConnections?.length ? null : (
                                <CardBox
                                    title="Pending connections"
                                    icon={<ConnectionsIcon />}
                                    list={
                                        pendingConnections?.map(
                                            (item: any) => ({
                                                name:
                                                    item?.sourceUser
                                                        ?.firstName +
                                                    ' ' +
                                                    item?.sourceUser?.lastName,
                                                img:
                                                    item?.sourceUser
                                                        ?.photoUrl ?? ''
                                            })
                                        ) ?? []
                                    }
                                    btnText="View connections"
                                    btnOnClick={() =>
                                        navigate('/pending-connections')
                                    }
                                />
                            )}
                            {isCompany ? null : (
                                <CardBox
                                    title="Improve your skills"
                                    icon={<HatIcon />}
                                    list={skills?.filter(
                                        (x: any) => x.level === 'beginner'
                                    )}
                                    btnText="Improve skills"
                                    btnOnClick={() => {
                                        setShowModal('achievement')
                                        
                                    }}
                                />
                            )}
                        </Box>
                        <Box
                            sx={{
                                bgcolor: '#fff',
                                width: '100%',
                                padding: '12px 0px',
                                position: 'sticky',
                                top: '326px'
                            }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <JobFactorIcon />
                            </Box>
                            <Grid
                                container
                                sx={{
                                    mt: '24px',
                                    justifyContent: 'center'
                                }}
                                columnGap={'12px'}
                                rowGap={'8px'}
                            >
                                <Grid>
                                    <Link
                                        underline="hover"
                                        textAlign={'right'}
                                        component={'p'}
                                        sx={Links}
                                    >
                                        How Jobfactor works
                                        <ArrowDown />
                                    </Link>
                                </Grid>
                                <Grid>
                                    <Link
                                        underline="hover"
                                        component={'p'}
                                        sx={Links}
                                        // textAlign={'right'}
                                    >
                                        Jobfactor
                                        <ArrowDown />
                                    </Link>
                                </Grid>
                                <Grid>
                                    <Link
                                        underline="hover"
                                        component={'p'}
                                        sx={Links}
                                    >
                                        Professional
                                        <ArrowDown />
                                    </Link>
                                </Grid>
                                <Grid>
                                    <Link
                                        underline="hover"
                                        component={'p'}
                                        sx={Links}
                                        textAlign={'center'}
                                    >
                                        Community
                                        <ArrowDown />
                                    </Link>
                                </Grid>
                                <Grid>
                                    <Link
                                        underline="hover"
                                        component={'p'}
                                        sx={Links}
                                        textAlign={'right'}
                                    >
                                        Partnership
                                        <ArrowDown />
                                    </Link>
                                </Grid>
                            </Grid>
                            <Box
                                sx={{
                                    mt: '20px',
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '12px'
                                }}
                            >
                                <Link
                                    href="https://www.linkedin.com/company/job-factor/"
                                    sx={{
                                        transition: 'all .4s ease',
                                        cursor: 'pointer',
                                        '&:hover': {
                                            opacity: 0.8
                                        }
                                    }}
                                >
                                    <Linkedin
                                        color="#464646"
                                        width="28"
                                        height="28"
                                    />
                                </Link>
                                <Link
                                    href="https://twitter.com/_jobfactor?t=9xNuozxqPZaQ9ilYibL0iQ&s=09"
                                    sx={{
                                        transition: 'all .4s ease',
                                        cursor: 'pointer',
                                        '&:hover': {
                                            opacity: 0.8
                                        }
                                    }}
                                >
                                    <Twitter
                                        color="#464646"
                                        width="28"
                                        height="28"
                                    />
                                </Link>
                                <Link
                                    href="https://www.facebook.com/profile.php?id=100089853477839&mibextid=ZbWKwL"
                                    sx={{
                                        transition: 'all .4s ease',
                                        cursor: 'pointer',
                                        '&:hover': {
                                            opacity: 0.8
                                        }
                                    }}
                                >
                                    <Facebook
                                        color="#464646"
                                        width="28"
                                        height="28"
                                    />
                                </Link>
                                <Link
                                    href="http://www.instagram.com/officialjobfactor"
                                    sx={{
                                        transition: 'all .4s ease',
                                        cursor: 'pointer',
                                        '&:hover': {
                                            opacity: 0.8
                                        }
                                    }}
                                >
                                    <Instagram
                                        color="#464646"
                                        width="28"
                                        height="28"
                                    />
                                </Link>
                                <Link
                                    sx={{
                                        transition: 'all .4s ease',
                                        cursor: 'pointer',
                                        '&:hover': {
                                            opacity: 0.8
                                        }
                                    }}
                                >
                                    <Youtube
                                        color="#464646"
                                        width="28"
                                        height="28"
                                    />
                                </Link>
                                <Link
                                    href="https://www.tiktok.com/@officialjobfactor?_t=8aFd3ZWiKDF&_r=1"
                                    sx={{
                                        transition: 'all .4s ease',
                                        cursor: 'pointer',
                                        '&:hover': {
                                            opacity: 0.8
                                        }
                                    }}
                                >
                                    <Tiktok
                                        color="#464646"
                                        width="28"
                                        height="28"
                                    />
                                </Link>
                            </Box>
                            <Typography
                                sx={{
                                    mt: '12px',
                                    color: '#808080',
                                    fontFamily: 'Open Sans',
                                    fontSize: '16px',
                                    textAlign: 'center'
                                }}
                            >
                                &copy; 2023, Jobfactor Inc.
                            </Typography>
                        </Box>
                        <PostButton />
                    </Box>
                </Grid>
            </Grid>
            <Box
                sx={{
                    display: { sm: 'none', xs: 'block' }
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px'
                        // backgroundColor: '#F2F2F2'
                    }}
                >
                    <Box width="100%" p="14px 16px" bgcolor="#fff">
                        <Button
                            variant="contained"
                            sx={{
                                borderRadius: '8px',
                                border: '1px solid #05668D',
                                background: '#FAFAFA',
                                color: '#05668D',
                                fontWeight: 600,
                                fontSize: 14,
                                p: '8px 12px'
                            }}
                            startIcon={<AddIcon />}
                            onClick={(e) => handleOpen(e)}
                        >
                            Create a post
                        </Button>
                    </Box>
                    <Box
                        px="16px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        py="5px"
                        bgcolor="#fff"
                    >
                        <ScoreCard width="52px" height="61px" score={score} />
                    </Box>
                </Box>
                <FeedList />
                <PostButton />
                <Menu
                    disableScrollLock
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                >
                    <Box sx={{ px: '16px', pb: '32px' }}>
                        <Box
                            py="8px"
                            borderBottom="2px solid #D9D9D9"
                            mb="20px"
                        >
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                gap="16px"
                            >
                                <Typography
                                    fontSize="16px"
                                    fontWeight={600}
                                    color="#23282B"
                                >
                                    What is the post?
                                </Typography>
                                <Box onClick={handleClose}>
                                    <CloseXIcon />
                                </Box>
                            </Box>
                        </Box>
                        <MenuItem
                            sx={{
                                p: '12px 32px',
                                borderBottom: '1px solid #D8D8D8',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '16px',
                                '&:hover': {
                                    bgcolor: '#EDEDED'
                                }
                            }}
                            onClick={() => {
                                setShowModal('true');
                                handleClose();
                            }}
                        >
                            <CameraPostOptionIcon />
                            <Typography
                                fontSize="14px"
                                color="#808080"
                                sx={{
                                    '&:hover': {
                                        color: '#23282B',
                                        fontWeight: 600
                                    }
                                }}
                            >
                                Create post
                            </Typography>
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                setShowModal('achievement');
                                handleClose();
                            }}
                            sx={{
                                p: '12px 32px',
                                borderBottom: '1px solid #D8D8D8',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '16px',
                                '&:hover': {
                                    bgcolor: '#EDEDED'
                                }
                            }}
                        >
                            <AchievementPostOptionIcon />
                            <Typography
                                fontSize="14px"
                                color="#808080"
                                sx={{
                                    '&:hover': {
                                        color: '#23282B',
                                        fontWeight: 600
                                    }
                                }}
                            >
                                Post an achievement
                            </Typography>
                        </MenuItem>
                        <MenuItem
                            onClick={handleClose}
                            sx={{
                                p: '12px 32px',
                                borderBottom: '1px solid #D8D8D8',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '16px',
                                '&:hover': {
                                    bgcolor: '#EDEDED'
                                }
                            }}
                        >
                            <ArticlePostOptionIcon />
                            <Typography
                                fontSize="14px"
                                color="#808080"
                                sx={{
                                    '&:hover': {
                                        color: '#23282B',
                                        fontWeight: 600
                                    }
                                }}
                            >
                                Write an article
                            </Typography>
                        </MenuItem>
                    </Box>
                </Menu>
            </Box>

            {/* <ScrollToTop /> */}
            {showModal ? (
                <PostCards
                    data={null}
                    achievementModal={showModal === 'achievement'}
                    showModal={showModal ? true : false}
                    hideModal={() => setShowModal('')}
                />
            ) : null}
        </Box>
    );
}

export default HomePage;
