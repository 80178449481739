import { Box, Button, FormHelperText, InputAdornment, TextField, Typography } from '@mui/material'
import IndividualRatingCard from '../components/IndividualRatingCard'
import MoreIcon from '../../../assets/icons/MoreIcon'
import PageHeader from '../../common/PageHeader'
import RatingBadge from '../components/RatingBadge'
import StarRating from '../components/StarRating'
import ArchieveBook from '../../../assets/icons/ArchieveBook'
import { useLocation, useNavigate } from 'react-router'
import { IRatingType } from '../types'

const RatingBreakdown = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const data = location.state;
    const ratings = data?.ratings;
    return (
        <Box>
            <PageHeader
                pageTitle="Individual ratings breakdown"
                handleBack={() => navigate(-1)}
                actions={
                    <Box
                        sx={{
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <MoreIcon />
                    </Box>
                }
            />
            <Box my={'16px'}>
                <IndividualRatingCard avgRating={data?.averageRating ?? 0} />
            </Box>
            <Box p={'32px 40px'} bgcolor={'#FFF'}>
                <Box display={'flex'} alignItems={'center'} gap={'25px'}>
                    {[20, 40, 60, 80, 100].map(x => <RatingBadge level={x} />)}
                </Box>
                <Box
                    my={'32px'}
                    pb={'20px'}
                    width={'70%'}
                    borderBottom={'1px solid #D9D9D9'}
                >
                    <Typography variant='titleLargeSemiBold' component={'p'}>
                        Company Rating Attributes
                    </Typography>
                    <Typography color={'#808080'} variant='titleSmallRegular' component={'p'}>
                        Your individual ratings determine your competency attribute:
                    </Typography>
                    <Box
                        mt={'24px'}
                        pb={'12px'}
                        borderBottom={'1px solid #D9D9D9'}
                    >
                        {ratings?.map((x: IRatingType) => (
                            <Box
                                mb={'12px'}
                                gap={'12px'}
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                            >
                                <Box component={'li'}>
                                    <Typography component={'span'}>
                                        {x.name}
                                    </Typography>
                                </Box>
                                <StarRating item={x} readOnly={true} />
                            </Box>
                        ))}
                    </Box>
                    <Box
                        sx={{
                            padding: '20px',
                            background: '#FFFAF1',
                            borderBottom: '1px solid #D9D9D9',
                            mt: '32px'
                        }}
                    >
                        <TextField
                            disabled
                            value={data?.message ?? ''}
                            sx={{
                                '& .MuiInput-underline': {
                                    fontSize: '14px'
                                },
                                '& .MuiInput-underline::before': {
                                    border: '0px !important'
                                },
                                '& .MuiInput-underline::after': {
                                    border: '0px !important'
                                }
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <ArchieveBook />
                                    </InputAdornment>
                                ),
                            }}
                            variant="standard"
                            placeholder="Write a short comment: "
                            fullWidth
                        />
                        <FormHelperText
                            sx={{
                                color: '#808080',
                                ml: '28px'
                            }}
                        >
                            Jobfactor is very diligent in their work of bridging the gap between companies and top talents
                        </FormHelperText>
                    </Box>
                </Box>
                <Button
                    sx={{
                        py: '8px',
                        fontSize: 14,
                        width: 'auto',
                        p: '10px 36px',
                        bgcolor: '#fff',
                        borderRadius: '8px',
                        textDecoration: 'none',
                        border: '1px solid #05668D',
                        '&:hover': {
                            textDecoration: 'none'
                        }
                    }}
                    onClick={() => navigate(-1)}
                >
                    Back to review
                </Button>
            </Box>
        </Box>
    )
}

export default RatingBreakdown