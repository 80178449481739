const AnalyticsProfile = () => (
    <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            opacity="0.4"
            d="M13.5 3C9.57 3 6.375 6.195 6.375 10.125C6.375 13.98 9.39 17.1 13.32 17.235C13.44 17.22 13.56 17.22 13.65 17.235C13.68 17.235 13.695 17.235 13.725 17.235C13.74 17.235 13.74 17.235 13.755 17.235C17.595 17.1 20.61 13.98 20.625 10.125C20.625 6.195 17.43 3 13.5 3Z"
            fill="#49B6FF"
        />
        <path
            d="M21.1214 21.2253C16.9364 18.4353 10.1114 18.4353 5.89641 21.2253C3.99141 22.5003 2.94141 24.2253 2.94141 26.0703C2.94141 27.9153 3.99141 29.6253 5.88141 30.8853C7.98141 32.2953 10.7414 33.0003 13.5014 33.0003C16.2614 33.0003 19.0214 32.2953 21.1214 30.8853C23.0114 29.6103 24.0614 27.9003 24.0614 26.0403C24.0464 24.1953 23.0114 22.4853 21.1214 21.2253Z"
            fill="#49B6FF"
        />
        <path
            opacity="0.4"
            d="M29.9841 11.0111C30.2241 13.9211 28.1541 16.4711 25.2891 16.8161C25.2741 16.8161 25.2741 16.8161 25.2591 16.8161H25.2141C25.1241 16.8161 25.0341 16.8161 24.9591 16.8461C23.5041 16.9211 22.1691 16.4561 21.1641 15.6011C22.7091 14.2211 23.5941 12.1511 23.4141 9.90113C23.3091 8.68613 22.8891 7.57613 22.2591 6.63113C22.8291 6.34613 23.4891 6.16613 24.1641 6.10613C27.1041 5.85113 29.7291 8.04113 29.9841 11.0111Z"
            fill="#49B6FF"
        />
        <path
            d="M32.9844 24.8856C32.8644 26.3406 31.9344 27.6006 30.3744 28.4556C28.8744 29.2806 26.9844 29.6706 25.1094 29.6256C26.1894 28.6506 26.8194 27.4356 26.9394 26.1456C27.0894 24.2856 26.2044 22.5006 24.4344 21.0756C23.4294 20.2806 22.2594 19.6506 20.9844 19.1856C24.2994 18.2256 28.4694 18.8706 31.0344 20.9406C32.4144 22.0506 33.1194 23.4456 32.9844 24.8856Z"
            fill="#49B6FF"
        />
    </svg>
);

export default AnalyticsProfile;
