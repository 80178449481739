import React from 'react'

const ArrowIconRight = () => {
  return (
      <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
      >
          <path
              d="M14.43 18.8211C14.24 18.8211 14.05 18.7511 13.9 18.6011C13.61 18.3111 13.61 17.8311 13.9 17.5411L19.44 12.0011L13.9 6.46109C13.61 6.17109 13.61 5.69109 13.9 5.40109C14.19 5.11109 14.67 5.11109 14.96 5.40109L21.03 11.4711C21.32 11.7611 21.32 12.2411 21.03 12.5311L14.96 18.6011C14.81 18.7511 14.62 18.8211 14.43 18.8211Z"
              fill="#05668D"
          />
          <path
              d="M20.33 12.75H3.5C3.09 12.75 2.75 12.41 2.75 12C2.75 11.59 3.09 11.25 3.5 11.25H20.33C20.74 11.25 21.08 11.59 21.08 12C21.08 12.41 20.74 12.75 20.33 12.75Z"
              fill="#05668D"
          />
      </svg>
  );
}

export default ArrowIconRight;
