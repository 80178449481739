import {useEffect} from 'react';
import { Avatar, Box, Button, Typography } from '@mui/material';
import homeReviewImage from '../../../assets/images/homeReviewImage.png';
import { Height } from '@mui/icons-material';
import { redirect, useLocation, useNavigate } from 'react-router-dom';
import { useGetProfessionalRecievedRequests } from '../../../utils/hooks/api/reviews/useGetProfessionalRecievedRequests';
import { useAuth } from '../../../utils/context/AuthContext';
import ProfessionalProfile from '../../ProfessionalProfile/index';
import { logger } from '../../../utils/Helper/logger';
import axiosInstance from '../../../utils/hooks/api/axiosConfig';
import { provideFeedBack} from '../../../utils/emails/email';
import { generateHtml, generateSubject } from '../../../utils/emails/emailtemplates';

function FeedbackCard() {
    const { user } = useAuth();
    const navigate = useNavigate();
    const { data: latestReview, isFetching } =
        useGetProfessionalRecievedRequests(user?.professionalProfile?.id ?? '');

    const sendNotification = async () => {
        if ( !isFetching && (latestReview && latestReview?.length) > 0 && (!isFetching && latestReview && latestReview?.slice(0, 1))){
        // if (!latestReview?.length){
            try {
                if (!user?.professionalProfile) {
                    return;
                }
                const { emailAddress, firstName, lastName } = user?.professionalProfile;
    
                if (!emailAddress || !firstName || !lastName) {
                    return;
                }
                // await axiosInstance.post("/api/email/send-notification", {
                //     email: emailAddress,
                //     subject: generateSubject(provideFeedBack.subject),
                //     firstName: firstName,
                //     lastName: lastName,
                //     message: generateHtml(provideFeedBack.html),
                //     link: "reviews/recieved-request",
                // });
            } catch (error) {
                console.error('Error sending notification:', error);
            }
        }
    };
    console.log({latestReview});

    //  useEffect(() => {
    //     sendNotification();
    // }, [user, latestReview]);

    // logger.log('user=>', user?.professionalProfile?.emailAddress)
    return (
        <Box
            sx={{
                backgroundColor: 'white',
                // p: 2,
                padding: '24px 20px',
                // gap: '24px',
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            <Typography
                color={'#494949'}
                fontFamily="open sans"
                fontWeight={700}
                fontSize={16}
                mb='16px'
            >
                Given feedback yet?
            </Typography>
            {(latestReview && latestReview?.length > 0) ? (
                latestReview && latestReview?.slice(0, 1)?.map((user: any) => (
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '24px',
                            pb: '8px'
                        }}
                    >
                        <Avatar
                            variant="circular"
                            src={
                                user?.professionalRating?.professionalProfile
                                    ?.photoUrl
                            }
                            alt={'profile'}
                            sx={{ height: 48, width: 48 }}
                            // loading="lazy"
                        />
                        <Typography
                            color={'#808080'}
                            fontFamily="open sans"
                            fontWeight={400}
                            fontSize={14}
                        >
                            <span
                                style={{
                                    fontWeight: 700,
                                    color: '#494949'
                                }}
                            >
                                {
                                    user?.professionalRating
                                        ?.professionalProfile?.firstName
                                }{' '}
                                {
                                    user?.professionalRating
                                        ?.professionalProfile?.lastName
                                }{' '}
                            </span>
                            is requesting feedback from you as a witness to
                            their recent achievement.
                        </Typography>
                    </Box>
                ))
            ) : (
                <Typography
                        sx={{ flex:1 }}
                    color={'#808080'}
                    fontFamily="open sans"
                    fontWeight={400}
                    fontSize={14}
                    maxWidth={'350px'}
                >
                    You're caught up, no pending requests.
                </Typography>
            )}

            <Button
                disabled={!latestReview}
                sx={{ mt: 3, mb: 0, py: 1.5, bgcolor: '#fafafa' }}
                variant="outlined"
                onClick={() => navigate('/reviews/received-requests')}
            >
                Ok, give review
            </Button>
        </Box>
    );
}

export default FeedbackCard;