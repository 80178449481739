import React from 'react'

const ArticlePostOptionIcon = () => {
  return (
      <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
          <path
              d="M17.8248 6.50729V6.50866V13.492C17.8248 14.9092 17.4043 15.9804 16.688 16.6968C15.9716 17.4131 14.9004 17.8337 13.4832 17.8337H6.50817C5.09095 17.8337 4.01992 17.4132 3.30361 16.696C2.58723 15.9787 2.1665 14.9054 2.1665 13.4837V6.50866C2.1665 5.09143 2.58702 4.02025 3.30339 3.30388C4.01977 2.5875 5.09094 2.16699 6.50817 2.16699H13.4915C14.9088 2.16699 15.9798 2.58755 16.695 3.30366C17.4101 4.01966 17.8287 5.09037 17.8248 6.50729ZM6.87484 13.6253H13.1248C13.7426 13.6253 14.2498 13.1181 14.2498 12.5003C14.2498 11.8825 13.7426 11.3753 13.1248 11.3753H6.87484C6.25703 11.3753 5.74984 11.8825 5.74984 12.5003C5.74984 13.1181 6.25703 13.6253 6.87484 13.6253ZM6.87484 8.62533H13.1248C13.7426 8.62533 14.2498 8.11813 14.2498 7.50033C14.2498 6.88252 13.7426 6.37533 13.1248 6.37533H6.87484C6.25703 6.37533 5.74984 6.88252 5.74984 7.50033C5.74984 8.11813 6.25703 8.62533 6.87484 8.62533Z"
              fill="#808080"
              stroke="#808080"
          />
      </svg>
  );
}

export default ArticlePostOptionIcon
