import {
    Button,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableRow,
    TableCell
} from '@mui/material';
import { Box } from '@mui/system';
import { Responsiveness } from '../../../theme/overrides';

const tabStyles = {
    fontWeight: '700',
    borderRadius: 0,
    padding: '12px 8px',
    width: '50%',
    boxShadow: 'none',
    ':hover': {
        boxShadow: 'none',
        color: 'white',
        background: '#05668D'
    }
};

const Tabs = (props: PropTypes) => {
    const { tab, changeTab } = props;
    const {isMobile} = Responsiveness()
    return (
        <TableContainer
            component={Paper}
            sx={{
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0
            }}
        >
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <Box
                                sx={{
                                    margin: isMobile ? '0px' : '20px',
                                    p: '3px',
                                    bgcolor: '#f2f2f2',
                                    borderRadius: '8px',
                                    border: '.5px solid #aaa'
                                }}
                            >
                                <Button
                                    disableRipple
                                    sx={{
                                        background:
                                            tab === 'sent'
                                                ? '#055C7F'
                                                : 'transparent',
                                        color:
                                            tab === 'sent'
                                                ? '#FFFFFF'
                                                : '#23282B',
                                        fontSize: '14px',
                                        textDecoration: 'none',
                                        textTransform: 'capitalize',
                                        ...tabStyles,
                                        borderRadius: '8px'
                                    }}
                                    onClick={() => changeTab('sent')}
                                >
                                    Sent
                                </Button>
                                <Button
                                    disableRipple
                                    sx={{
                                        background:
                                            tab === 'received'
                                                ? '#055C7F'
                                                : 'transparent',
                                        color:
                                            tab === 'received'
                                                ? '#FFFFFF'
                                                : '#23282B',
                                        fontSize: '14px',
                                        textTransform: 'capitalize',
                                        textDecoration: 'none',
                                        ...tabStyles,
                                        borderRadius: '8px'
                                    }}
                                    onClick={() => changeTab('received')}
                                >
                                    Received
                                </Button>
                            </Box>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};
interface PropTypes {
    tab: string;
    changeTab: (type: string) => void;
}
export default Tabs;
