import React from 'react';

function UserGroup() {
    return (
        <>
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M6.10573 7.24927C6.03906 7.2426 5.95906 7.2426 5.88573 7.24927C4.29906 7.19594 3.03906 5.89594 3.03906 4.29594C3.03906 2.6626 4.35906 1.33594 5.99906 1.33594C7.6324 1.33594 8.95906 2.6626 8.95906 4.29594C8.95239 5.89594 7.6924 7.19594 6.10573 7.24927Z"
                    fill="#49B6FF"
                    stroke="#49B6FF"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10.9402 2.66406C12.2335 2.66406 13.2735 3.71073 13.2735 4.9974C13.2735 6.2574 12.2735 7.28406 11.0268 7.33073C10.9735 7.32406 10.9135 7.32406 10.8535 7.33073"
                    fill="#49B6FF"
                />
                <path
                    d="M10.9402 2.66406C12.2335 2.66406 13.2735 3.71073 13.2735 4.9974C13.2735 6.2574 12.2735 7.28406 11.0268 7.33073C10.9735 7.32406 10.9135 7.32406 10.8535 7.33073"
                    stroke="#49B6FF"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M2.7725 9.70406C1.15917 10.7841 1.15917 12.5441 2.7725 13.6174C4.60583 14.8441 7.6125 14.8441 9.44583 13.6174C11.0592 12.5374 11.0592 10.7774 9.44583 9.70406C7.61917 8.48406 4.6125 8.48406 2.7725 9.70406Z"
                    fill="#49B6FF"
                    stroke="#49B6FF"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12.2266 13.3359C12.7066 13.2359 13.1599 13.0426 13.5332 12.7559C14.5732 11.9759 14.5732 10.6893 13.5332 9.90927C13.1666 9.62927 12.7199 9.4426 12.2466 9.33594"
                    fill="#49B6FF"
                />
                <path
                    d="M12.2266 13.3359C12.7066 13.2359 13.1599 13.0426 13.5332 12.7559C14.5732 11.9759 14.5732 10.6893 13.5332 9.90927C13.1666 9.62927 12.7199 9.4426 12.2466 9.33594"
                    stroke="#49B6FF"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </>
    );
}

export default UserGroup;
