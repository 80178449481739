import { Box, Typography } from '@mui/material';
import React from 'react';
import UserDetails from '../../Connections/components/UserDetails';
import ConnectionSkills from '../../Connections/components/ConnectionSkillsTab';
import { Responsiveness } from '../../../theme/overrides';

const Reviewer = ({ reviewers = [] }) => {
    const { isMobile, isTablet }= Responsiveness()

    return (
        <Box sx={{ backgroundColor: 'white' }}>
            <Box
                sx={{
                    backgroundColor: 'white',
                    borderBottom: '1px solid  #D9D9D9',
                    display: 'flex',
                    padding: isMobile
                        ? '16px'
                        : isTablet
                        ? '16px 20px'
                        : '16px 40px',
                    alignItems: 'center',
                    gap: '20px',
                    marginTop: '20px'
                }}
            >
                <Typography variant="headlineSmallSemiBold">
                    Reviewer{' '}
                </Typography>
            </Box>
            {reviewers?.map((user, key) => (
                <Box
                    sx={{
                        backgroundColor: 'white',
                        padding: isMobile
                            ? '16px'
                            : isTablet
                            ? '16px 20px'
                            : '16px 40px',
                        justifyContent: ' center',
                        alignItems: 'flex-end',
                        gap: '72px'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'inset',
                            gap: '20px'
                        }}
                    >
                        <UserDetails user={user} />
                    </Box>
                    {isMobile ? null : <Box
                        sx={{
                            mt: '12px'
                        }}
                    >
                        <ConnectionSkills id={user?.id} count={isTablet ? 2 :4} />
                    </Box>}
                </Box>
            ))}
        </Box>
    );
};

export default Reviewer;
