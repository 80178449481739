import React from 'react';

interface YoutubeProp {
    color?: string;
    width?: string;
    height?: string;
}

const Youtube: React.FC<YoutubeProp> = ({
    color = '#05668D',
    width,
    height
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 41 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.7871 24.0733L24.5771 19.9995L17.7871 15.9258V24.0733Z"
                fill={color}
            />
            <path
                d="M20.5 0C9.455 0 0.5 8.95375 0.5 20C0.5 31.0462 9.455 40 20.5 40C31.545 40 40.5 31.045 40.5 20C40.5 8.955 31.545 0 20.5 0ZM31.36 20.8763C31.36 22.7513 31.1425 24.6263 31.1425 24.6263C31.1425 24.6263 30.93 26.2238 30.28 26.925C29.4537 27.8475 28.5288 27.8525 28.1038 27.9075C25.0638 28.14 20.5 28.1475 20.5 28.1475C20.5 28.1475 14.8525 28.0925 13.115 27.915C12.6313 27.8188 11.5475 27.8463 10.72 26.925C10.0687 26.2225 9.8575 24.6263 9.8575 24.6263C9.8575 24.6263 9.64 22.7525 9.64 20.8763V19.1188C9.64 17.2438 9.8575 15.37 9.8575 15.37C9.8575 15.37 10.07 13.7725 10.72 13.0687C11.5462 12.145 12.4712 12.14 12.8962 12.0875C15.935 11.8525 20.495 11.8525 20.495 11.8525H20.505C20.505 11.8525 25.065 11.8525 28.1038 12.0875C28.5275 12.14 29.4537 12.145 30.28 13.0675C30.9312 13.7713 31.1425 15.3688 31.1425 15.3688C31.1425 15.3688 31.36 17.2438 31.36 19.1188V20.8763Z"
                fill={color}
            />
        </svg>
    );
};

export default Youtube;
