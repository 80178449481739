import React, { ChangeEvent } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import InputBase from '@mui/material/InputBase';
import dayjs from 'dayjs';
import Calendar from '../../Calendar';
import CommonRadioDropdown from '../../../../common/CommonRadioDropdown';
import { EDUCATION_LEVEL_OPTIONS } from '../../../constants';
import { Autocomplete, Button, Checkbox, FormControlLabel } from '@mui/material';
import { useGetUniversity } from '../../../../../utils/hooks/api/university/useGetUniversity';
import debounce from 'lodash/debounce';
import { Responsiveness } from '../../../../../theme/overrides';
import PlusIcon from '../../../../../assets/icons/PlusIcon';
import { logger } from '../../../../../utils/Helper/logger';

interface University {
    country: string;
    name: string;
    website?: string;
}

const EducationForm = ({ handleChange, education }: any) => {
    const [schoolName, setSchoolName] = React.useState<string>('');
    const [universities, setUniversities] = React.useState<any[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const universitiesQuery = useGetUniversity(schoolName);
    const { isMobile } = Responsiveness();


    const debouncedSearch = debounce((query: string) => {
        setIsLoading(true);
        universitiesQuery
            .refetch()
            .then((result) => {
                if (result.data) {
                    const data = result.data as University[];
                    setUniversities(data);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching universities:', error);
                setIsLoading(false);
            });
    }, 1000);

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const input = event.target.value.trimStart();
        setSchoolName(input);
        if (input.length < 3) return setUniversities([]);
        handleChange({
            target: { name: 'school', value: input }
        });
        return debouncedSearch(input);
    };

    return (
        <Box bgcolor="#fff">
            <Box sx={{ position: 'relative' }}>
                <InputLabel
                    sx={{
                        color: '#23282B',
                        fontSize: '14px',
                        position: 'absolute',
                        top: '8px',
                        left: '16px',
                        zIndex: 1,
                        fontFamily: 'Open Sans'
                    }}
                    htmlFor="school"
                >
                    School
                    <Typography
                        ml={0.25}
                        component="span"
                        color="#C3BCB6"
                        fontSize={14}
                        fontWeight={600}
                    >
                        *
                    </Typography>
                </InputLabel>
                <Autocomplete
                    options={universities || []}
                    getOptionLabel={(option: string | University) => {
                        if (typeof option === 'string') {
                            return option;
                        } else {
                            return option.name;
                        }
                    }}
                    inputValue={schoolName}
                    value={
                        universities?.find(
                            (option: University) => option.name === schoolName
                        ) || null
                    }
                    onChange={(_, newValue) => {
                        if (typeof newValue === 'string') {
                            handleChange({
                                target: { name: 'school', value: newValue }
                            });
                            setSchoolName(newValue);
                            setIsLoading(false);
                        } else if (newValue && newValue.name) {
                            handleChange({
                                target: { name: 'school', value: newValue.name }
                            });
                            setSchoolName(newValue.name);
                            setIsLoading(false);
                        }
                    }}
                    freeSolo={true}
                    renderInput={(params) => (
                        <InputBase
                            {...params.InputProps}
                            required
                            name="school"
                            value={schoolName ? schoolName : ''}
                            onChange={handleInputChange}
                            placeholder="Please enter school"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'off'
                            }}
                            rows={1}
                            sx={{
                                backgroundColor: '#f2f2f2',
                                width: '100%',
                                height: '60px',
                                padding: '0px 16px',
                                fontFamily: 'open sans',
                                color: '#23282B',
                                borderBottom: '1px solid #D9D9D9',
                                mb: '8px',
                                '& .MuiInputBase-input': {
                                    position: 'relative',
                                    top: '10px'
                                }
                            }}
                        />
                    )}
                />
                {isLoading && <p>Loading...</p>}
            </Box>

            <Box sx={{ position: 'relative' }}>
                <CommonRadioDropdown
                    options={EDUCATION_LEVEL_OPTIONS}
                    value={{
                        label: education.educationLevel,
                        value: education.educationLevel
                    }}
                    onSelect={(val) => {
                        let item = {
                            target: {
                                value: val,
                                name: 'degree'
                            }
                        };
                        handleChange(item);
                    }}
                    label={
                        <>
                            <Typography color="#23282B">
                                Degree
                                <Typography
                                    ml={0.25}
                                    component="span"
                                    color="#C3BCB6"
                                    fontSize={14}
                                    fontWeight={600}
                                    // display=
                                >
                                    *
                                </Typography>
                            </Typography>
                        </>
                    }
                    placeholder="Please select"
                />
            </Box>
            {/* <Box sx={{ position: 'relative' }}>
                <InputLabel
                    sx={{
                        color: '#23282B',
                        fontSize: '14px',
                        position: 'absolute',
                        top: '8px',
                        left: '16px',
                        zIndex: 1,
                        fontFamily: 'Open Sans'
                    }}
                    htmlFor="phone"
                >
                    Degree
                    <Typography
                        ml={0.25}
                        component="span"
                        color="#C3BCB6"
                        fontSize={14}
                        fontWeight={600}
                    >
                        *
                    </Typography>
                </InputLabel>
                <InputBase
                    required
                    value={education.degree}
                    name="degree"
                    placeholder="Enter degree"
                    onChange={handleChange}
                    inputProps={{
                        autoComplete: '',
                        form: {
                            autoComplete: 'off'
                        }
                    }}
                    id="degree"
                    rows={1}
                    sx={{
                        backgroundColor: '#f2f2f2',
                        width: '100%',
                        height: '60px',
                        padding: '0px 16px',
                        fontFamily: 'open sans',
                        color: '#23282B',
                        borderBottom: '1px solid #D9D9D9',
                        mb: '8px',
                        '& 	.MuiInputBase-input': {
                            position: 'relative',
                            top: '10px'
                        }
                    }}
                />
            </Box> */}

            <Box sx={{ position: 'relative' }}>
                <InputLabel
                    sx={{
                        color: '#23282B',
                        fontSize: '14px',
                        position: 'absolute',
                        top: '8px',
                        left: '16px',
                        zIndex: 1,
                        fontFamily: 'Open Sans'
                    }}
                    htmlFor="phone"
                >
                    Field of study
                    <Typography
                        ml={0.25}
                        component="span"
                        color="#C3BCB6"
                        fontSize={14}
                        fontWeight={600}
                    >
                        *
                    </Typography>
                </InputLabel>
                <InputBase
                    required
                    value={education.fieldOfStudy}
                    onChange={handleChange}
                    inputProps={{
                        autoComplete: '',
                        form: {
                            autoComplete: 'off'
                        }
                    }}
                    name="fieldOfStudy"
                    placeholder="Enter field of study"
                    rows={1}
                    sx={{
                        backgroundColor: '#f2f2f2',
                        width: '100%',
                        height: '60px',
                        padding: '0px 16px',
                        fontFamily: 'open sans',
                        color: '#23282B',
                        borderBottom: '1px solid #D9D9D9',
                        // mb: '15px',
                        '& 	.MuiInputBase-input': {
                            position: 'relative',
                            top: '10px'
                        }
                    }}
                />
            </Box>

            <Box>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={education.isChecked}
                            onChange={handleChange}
                            name="isChecked"
                            color="success"
                        />
                    }
                    label="I am currently still in school"
                />
            </Box>

            <Grid
                direction={isMobile ? 'column' : undefined}
                container
                gap={isMobile ? 1.5 : '32px'}
                alignItems={isMobile ? 'flex-start' : 'center'}
                mb="8px"
                wrap="nowrap"
            >
                <Grid
                    item
                    xs={6}
                    width="100%"
                    sx={{ position: 'relative' }}
                    flexGrow={1}
                >
                    <Calendar
                        tab
                        defaultValue={dayjs(education.startDate).format(
                            'MM/DD/YYYY'
                        )}
                        label="Start Date"
                        handleChange={(e: dayjs.Dayjs | null | string) => {
                            console.log(e);
                            let item = {
                                target: {
                                    value: dayjs(e),
                                    name: 'startDate'
                                }
                            };
                            handleChange(item);
                        }}
                    />
                </Grid>

                {/* <Box width={24} height={0} borderBottom="1px solid #D9D9D9" /> */}

                <Grid
                    item
                    xs={6}
                    width="100%"
                    sx={{ position: 'relative' }}
                    flexGrow={1}
                >
                    <Calendar
                        tab
                        label="End Date"
                        defaultValue={dayjs(education.endDate).format(
                            'MM/DD/YYYY'
                        )}
                        disabled={education.isChecked}
                        handleChange={(e: dayjs.Dayjs | null) => {
                            let item = {
                                target: {
                                    value: dayjs(e),
                                    name: 'endDate'
                                }
                            };
                            handleChange(item);
                        }}
                    />
                </Grid>
            </Grid>

            {/* <Box sx={{ position: 'relative' }}>
                <CommonRadioDropdown
                    options={EDUCATION_LEVEL_OPTIONS}
                    value={{
                        label: education.educationLevel,
                        value: education.educationLevel
                    }}
                    onSelect={(val) => {
                        let item = {
                            target: {
                                value: val,
                                name: 'educationLevel'
                            }
                        };
                        handleChange(item);
                    }}
                    label={
                        <>
                            <Typography color="#23282B">
                                Education Level
                                <Typography
                                    ml={0.25}
                                    component="span"
                                    color="#C3BCB6"
                                    fontSize={14}
                                    fontWeight={600}
                                    // display=
                                >
                                    *
                                </Typography>
                            </Typography>
                        </>
                    }
                    placeholder="Please select"
                />
            </Box> */}
            <Box
                sx={{ position: 'relative', backgroundColor: '#f2f2f2' }}
                mb="10px"
            >
                <InputLabel
                    sx={{
                        color: '#23282B',
                        fontSize: '14px',
                        // position: 'absolute',
                        top: '8px',
                        left: '16px',
                        zIndex: 1,
                        fontFamily: 'Open Sans'
                    }}
                    htmlFor="phone"
                >
                    Agencies and societies
                    <Typography
                        ml={0.25}
                        component="span"
                        color="#C3BCB6"
                        fontSize={14}
                        fontWeight={600}
                    >
                        *
                    </Typography>
                </InputLabel>
                <InputBase
                    required
                    value={education.agencies}
                    name="agencies"
                    placeholder="Please enter agencies and societies "
                    onChange={handleChange}
                    inputProps={{
                        autoComplete: '',
                        form: {
                            autoComplete: 'off'
                        }
                    }}
                    id="agencies"
                    multiline
                    rows={6}
                    sx={{
                        backgroundColor: '#f2f2f2',
                        width: '100%',
                        // height: '60px',
                        padding: '0px 16px',
                        fontFamily: 'open sans',
                        color: '#23282B',
                        borderBottom: '1px solid #D9D9D9',
                        '& 	.MuiInputBase-input': {
                            position: 'relative',
                            top: '10px'
                        }
                    }}
                />
            </Box>
            <Typography
                mb="8px"
                textAlign="right"
                fontSize="16px"
                color="#808080"
            >{`${education?.agencies?.length}/250`}</Typography>
            <Box>
                <Button
                    startIcon={<PlusIcon />}
                    variant="contained"
                    sx={{
                        width: '135px',
                        height: '36px',
                        textTransform: 'none',
                        bgcolor: '#f2f2f2',
                        borderRadius: '8px',
                        color: '#05668D',
                        fontSize: '14px',
                        fontWeight: 600,
                        mb: '12px'
                    }}
                >
                    Add Media
                </Button>
                <Typography fontSize="14px" color="#23282B">
                    Add or link to external documents, photos, sites, videos,
                    and presentations.
                </Typography>
            </Box>
        </Box>
    );
};

export default EducationForm;
