import { Box, Skeleton } from '@mui/material';
import React, { Fragment } from 'react';

const ReviewLoader = () => {
    return (
        <Fragment>
            {[1, 2, 3, 4].map((i) => (
                <Box pl="60px" py="20px" key={i}>
                    <Skeleton variant="text" width="300px" height="30px" />
                    <Box
                        key={i}
                        py="10px"
                        display="flex"
                        gap="20px"
                        alignItems="center"
                    >
                        <Skeleton
                            variant="rounded"
                            width="80px"
                            height="80px"
                        />
                        <Box>
                            <Skeleton
                                variant="text"
                                width="150px"
                                height="20px"
                            />
                            <Skeleton
                                variant="text"
                                width="100px"
                                height="20px"
                            />
                        </Box>
                    </Box>
                    <Skeleton variant="text" width="150px" height="30px" />
                </Box>
            ))}
        </Fragment>
    );
};

export default ReviewLoader;
