import { Grid, Typography, Box } from '@mui/material';
import Image from '../../../components/Image';
import { useLocation } from 'react-router-dom';

import CircleIcon from '@mui/icons-material/Circle';
import DashIcon from '../../../assets/icons/DashIcon';

import TraitChip from './TraitChip';
import { Ranking } from '../types/Ranking';
import moment from 'moment';
import { Responsiveness } from '../../../theme/overrides';

interface MockWorkData {
    employer: {
        name: string;
        image?: string;
    };
    title: string;
    yearStarted: string;
    yearEnded?: string;
    rankings?: Ranking[];
    skills?: string[];
    isCurrentPosition?: boolean;
}

interface WorkSummaryProps {
    data: MockWorkData;
    containerStyle?: any;
}

const WorkSummary = ({
    data: {
        employer,
        title,
        yearStarted,
        yearEnded,
        rankings,
        skills,
        isCurrentPosition
    },
    containerStyle
}: WorkSummaryProps) => {
    const { isMobile } = Responsiveness()
    const location = useLocation();
    return (
        <Box>
            <Grid
                container
                gap={isMobile ? '12px' : 2.5}
                alignItems="center"
                wrap="nowrap"
                sx={containerStyle}
            >
                {employer.image && (
                    <Grid item>
                        <Image
                            src={employer.image}
                            alt={employer.name}
                            sx={{
                                width: '64px',
                                height: '64px',
                                objectFit: 'cover'
                            }}
                            border="3px #fff solid"
                            borderRadius={2}
                            display="block"
                        />
                    </Grid>
                )}
                <Grid item flexGrow={1}>
                    <Typography
                        component="h4"
                        color="#23282B"
                        fontFamily="open sans"
                        fontSize={isMobile ? 14 : 16}
                        fontWeight={600}
                        mb="4px"
                    >
                        {(location.pathname === '/users' ||
                            location.pathname.includes('applicant-details')) &&
                        title?.length > 35
                            ? title.slice(0, 35) + '...'
                            : title}
                    </Typography>
                    <Typography
                        component="p"
                        color="#494949"
                        fontFamily="open sans"
                        fontSize={isMobile ? 14 : 16}
                        fontWeight={400}
                        mb="4px"
                        // flexShrink={0}
                    >
                        {(location.pathname === '/users' ||
                            location.pathname.includes('applicant-details') ||
                            location.pathname.includes('resume')) &&
                        employer.name?.length > 15
                            ? employer.name.slice(0, 15) + '...'
                            : employer.name}
                    </Typography>
                    <Box
                        alignItems="center"
                        display="flex"
                        flexDirection="row"
                        flexWrap="wrap"
                        gap={1.5}
                        mb={0.5}
                    >
                        {!!rankings?.length ? (
                            <>
                                {/* <CircleIcon
                                    sx={{ fontSize: '7.25px' }}
                                    htmlColor="#494949"
                                /> */}
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    gap={'12px'}
                                >
                                    <Typography
                                        component="span"
                                        color="#808080"
                                        fontFamily="open sans"
                                        fontSize={14}
                                        fontWeight={400}
                                    >
                                        {moment(yearStarted).format('YYYY')}
                                    </Typography>
                                    {/* <DashIcon
                                        sx={{ fontSize: '16px' }}
                                        htmlColor="#494949"
                                    /> */}
                                    <CircleIcon
                                        sx={{ fontSize: '7.25px' }}
                                        htmlColor="#494949"
                                    />
                                    <Typography
                                        component="span"
                                        color="#808080"
                                        fontFamily="open sans"
                                        fontSize={14}
                                        fontWeight={400}
                                    >
                                        {isCurrentPosition
                                            ? 'Present'
                                            : moment(yearEnded).format('YYYY')}
                                    </Typography>
                                </Box>
                            </>
                        ) : null}
                    </Box>
                    {!!rankings?.length ? //             .fill('') //         Array(Math.min(2, rankings.length - 2)) //     {rankings.length > 2 && //     ))} //         <TraitChip key={index} type={ranking} /> //     {rankings.slice(0, 2).map((ranking, index) => ( // > //     wrap="nowrap" //     gap={0.75} //     alignItems="center" //     container // <Grid
                    //             .map((_, index) => (
                    //                 <CircleIcon
                    //                     key={index}
                    //                     sx={{ fontSize: '7.25px' }}
                    //                     htmlColor="#494949"
                    //                 />
                    //             ))}
                    // </Grid>
                    null : (
                        <Grid container alignItems="center" gap={1.5}>
                            <Typography
                                component="span"
                                color="#808080"
                                fontFamily="open sans"
                                fontSize={14}
                                fontWeight={400}
                            >
                                {moment(yearStarted).format('MMM, YYYY')}
                            </Typography>
                            <CircleIcon
                                sx={{ fontSize: '7.25px' }}
                                htmlColor="#494949"
                            />
                            <Typography
                                component="span"
                                color="#808080"
                                fontFamily="open sans"
                                fontSize={14}
                                fontWeight={400}
                            >
                                {isCurrentPosition
                                    ? 'Present'
                                    : moment(yearEnded).format('MMM, YYYY')}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            {!!skills?.length ? (
                <Box mb={1}>
                    <Typography component="span" fontSize={14} fontWeight={700}>
                        Skills: &nbsp;
                    </Typography>
                    {skills.map((skill, index) => {
                        return (
                            <Typography
                                component="span"
                                fontSize={14}
                                key={index}
                            >
                                {skill}
                                {index !== skills?.length - 1 ? (
                                    <>&nbsp;&middot;&nbsp;</>
                                ) : (
                                    ''
                                )}
                            </Typography>
                        );
                    })}
                </Box>
            ) : null}
        </Box>
    );
};

export default WorkSummary;
