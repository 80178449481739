import { Box, Typography } from '@mui/material';
import ExperienceLevel from './ExperienceLevel';
import { getRatingLevel } from '../../CompanyReviews/components/functions';

interface CompanyRatingsTabProps {
    count?: number;
    ratings: IRatingType[];
}

interface IRatingType {
    name: string;
    rating: number;
    type: string;
    witness: boolean;
}

const CompanyRatingsTab = ({ ratings = [], count }: CompanyRatingsTabProps) => {

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                gap: '8px'
            }}
        >
            {ratings
                ?.sort((a: IRatingType, b: IRatingType) => a?.rating < b?.rating ? 1 : -1)
                .slice(0, count ?? ratings?.length)
                ?.map((x: IRatingType) => {
                    const { color, shortForm } = getRatingLevel(x);
                    return (
                        <ExperienceLevel
                            background={color ?? '#E75541'}
                            shortForm={shortForm ?? 'B'}
                            title={x?.name}
                        />
                    );
                })}
            {count && (ratings?.length > count) ? (
                <>
                    <Typography
                        component={'div'}
                        sx={{
                            width: '7px',
                            height: '7px',
                            borderRadius: '100px',
                            background: '#494949',
                            border: '2px solid #494949'
                        }}
                    />{' '}
                    <Typography
                        component={'div'}
                        sx={{
                            ml: '8px',
                            width: '7px',
                            height: '7px',
                            borderRadius: '100px',
                            background: '#494949',
                            border: '2px solid #494949'
                        }}
                    />
                </>
            ) : null}
        </Box>
    );
};

export default CompanyRatingsTab;
