const DashboardIcon = ({ isHover = false, isSelected = false }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
        >
            <path
                d="M14.6307 14.9748H5.36406C2.0474 14.9748 1.03906 13.9665 1.03906 10.6498V5.3665C1.03906 2.04984 2.0474 1.0415 5.36406 1.0415H14.6224C17.9391 1.0415 18.9474 2.04984 18.9474 5.3665V10.6415C18.9557 13.9665 17.9474 14.9748 14.6307 14.9748ZM5.36406 2.2915C2.7474 2.2915 2.28906 2.74984 2.28906 5.3665V10.6415C2.28906 13.2582 2.7474 13.7165 5.36406 13.7165H14.6224C17.2391 13.7165 17.6974 13.2582 17.6974 10.6415V5.3665C17.6974 2.74984 17.2391 2.2915 14.6224 2.2915H5.36406Z"
                fill={isHover || isSelected ? '#05668D' : '#808080'}
            />
            <path
                d="M10 18.9584C9.65833 18.9584 9.375 18.6751 9.375 18.3334V14.3501C9.375 14.0084 9.65833 13.7251 10 13.7251C10.3417 13.7251 10.625 14.0084 10.625 14.3501V18.3334C10.625 18.6751 10.3417 18.9584 10 18.9584Z"
                fill={isHover || isSelected ? '#05668D' : '#808080'}
            />
            <path
                d="M18.3307 11.4585H1.66406C1.3224 11.4585 1.03906 11.1752 1.03906 10.8335C1.03906 10.4918 1.3224 10.2085 1.66406 10.2085H18.3307C18.6724 10.2085 18.9557 10.4918 18.9557 10.8335C18.9557 11.1752 18.6724 11.4585 18.3307 11.4585Z"
                fill={isHover || isSelected ? '#05668D' : '#808080'}
            />
            <path
                d="M13.75 18.9585H6.25C5.90833 18.9585 5.625 18.6752 5.625 18.3335C5.625 17.9918 5.90833 17.7085 6.25 17.7085H13.75C14.0917 17.7085 14.375 17.9918 14.375 18.3335C14.375 18.6752 14.0917 18.9585 13.75 18.9585Z"
                fill={isHover || isSelected ? '#05668D' : '#808080'}
            />
        </svg>
    );
};

export default DashboardIcon;
