import React from 'react'

const MobilePlusIcon = () => {
  return (
      <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
      >
          <path
              d="M10.25 20H29.75"
              stroke="white"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
          />
          <path
              d="M20 29.75V10.25"
              stroke="white"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
          />
      </svg>
  );
}

export default MobilePlusIcon
