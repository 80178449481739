import { Box, Skeleton } from '@mui/material';
import React from 'react'

const SkillsLoader = () => {
  return (
      <Box
          sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              gap: '8px'
          }}
      >
          {[1, 2, 3, 4, 5].map((i) => (
              <Skeleton
                  variant="text"
                  sx={{ width: '150px', height: '60px' }}
              />
          ))}
      </Box>
  );
}

export default SkillsLoader
