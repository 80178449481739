const ExclaminationIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
        >
            <g id="vuesax/bulk/warning-2">
                <g id="warning-2">
                    <path
                        id="Vector"
                        opacity="0.4"
                        d="M87.8335 35.7498V64.2497C87.8335 68.9163 85.3335 73.2498 81.2918 75.6248L56.5418 89.9165C52.5001 92.2498 47.5 92.2498 43.4167 89.9165L18.6667 75.6248C14.625 73.2915 12.125 68.958 12.125 64.2497V35.7498C12.125 31.0831 14.625 26.7496 18.6667 24.3746L43.4167 10.083C47.4584 7.74967 52.4585 7.74967 56.5418 10.083L81.2918 24.3746C85.3335 26.7496 87.8335 31.0415 87.8335 35.7498Z"
                        fill="#05668D"
                    />
                    <g id="Group">
                        <path
                            id="Vector_2"
                            d="M50 57.292C48.2917 57.292 46.875 55.8753 46.875 54.167V32.292C46.875 30.5837 48.2917 29.167 50 29.167C51.7083 29.167 53.125 30.5837 53.125 32.292V54.167C53.125 55.8753 51.7083 57.292 50 57.292Z"
                            fill="#05668D"
                        />
                    </g>
                    <g id="Group_2">
                        <path
                            id="Vector_3"
                            d="M49.9997 71.8746C49.458 71.8746 48.9163 71.7495 48.4163 71.5412C47.8747 71.3328 47.4579 71.0413 47.0412 70.6663C46.6662 70.2497 46.3747 69.7913 46.1247 69.2913C45.9164 68.7913 45.833 68.2496 45.833 67.7079C45.833 66.6246 46.2496 65.5411 47.0412 64.7495C47.4579 64.3745 47.8747 64.083 48.4163 63.8746C49.958 63.208 51.7914 63.5828 52.9581 64.7495C53.3331 65.1661 53.6246 65.5829 53.8329 66.1246C54.0413 66.6246 54.1663 67.1662 54.1663 67.7079C54.1663 68.2496 54.0413 68.7913 53.8329 69.2913C53.6246 69.7913 53.3331 70.2497 52.9581 70.6663C52.1664 71.458 51.1247 71.8746 49.9997 71.8746Z"
                            fill="#05668D"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default ExclaminationIcon;
