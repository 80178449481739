import axiosInstance from '../axiosConfig';
import { UseMutationResult, useMutation } from 'react-query';
import { IAddLikeRequest } from './types';

async function addCommentLike(postRequest: IAddLikeRequest) {
    const response = await axiosInstance.post(
        `/api/comment-likes`,
        postRequest
    );
    const data = await response.data;
    return data;
}
export function useAddCommentLike(): UseMutationResult<
    any,
    unknown,
    IAddLikeRequest
> {
    return useMutation(addCommentLike);
}
