import { FeedItem } from './FeedItem';
import { Box, Grid } from '@mui/material';
import { IFeedItem } from '../types/IFeedItem';
import InfiniteScroll from 'react-infinite-scroll-component';
import SkeletonLoader from './SkeletonLoader';
import { usePost } from '../../../utils/context/PostContext';
import { useEffect } from 'react';

function FeedList() {
    const { posts, totalPosts, setGetAfter, isLoaded } = usePost();

    useEffect(() => {
        if (posts?.length === 0) {
            window.scrollTo(0, 0);
        }
    });

    return (
        <Grid container spacing={{xs: 0, lg: 2}} sx={{ mt: {xs: '8px', lg:'4px'}, bgcolor: '#f2f2f2' }}>
            <Grid item xs={12}>
                {!isLoaded && posts?.length === 0 ? (
                    [1, 2, 3].map((_) => (
                        <Box key={_}>
                            <SkeletonLoader />
                        </Box>
                    ))
                ) : (
                    <InfiniteScroll
                        dataLength={posts.length}
                        next={() => setGetAfter((prev) => prev + 5)}
                        hasMore={posts.length < (totalPosts ?? 0)}
                        scrollThreshold={0.9}
                        loader={
                            <Box
                                sx={{
                                    // ml: '20px',
                                    width: '100%'
                                }}
                            >
                                {[1, 2, 3].map((_) => (
                                    <SkeletonLoader />
                                ))}
                            </Box>
                        }
                    >
                        {posts?.map((feed: IFeedItem, index: number) => (
                            <FeedItem key={index} feed={feed} />
                        ))}
                    </InfiniteScroll>
                )}
            </Grid>
        </Grid>
    );
}

export default FeedList;
