import axiosInstance from '../axiosConfig';
import { UseMutationResult, useMutation } from 'react-query';

async function deleteReviewRequest(reviewRequestId: any) {
    const response = await axiosInstance.delete(
        `/api/professional-reviews-requests/${reviewRequestId}`
    );
    const data = await response.data;
    return data;
}
export function useDeleteReviewRequest(): UseMutationResult<
    unknown,
    unknown,
    unknown
> {
    return useMutation(deleteReviewRequest);
}
