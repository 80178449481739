import React from 'react'
import { Avatar, Box, Typography } from '@mui/material';
import VerificationTick from './VerificationTick';
import { useGetProfessionalProfileScore } from '../../../utils/hooks/api/professional-profile/useGetProfessionalProfileScore';


interface MentionsDetailsProps {
  user: User;
  id?: string;
}


const MentionsDetails = (props:MentionsDetailsProps)=>{
   
const { user, id} = props;
const { data: score } = useGetProfessionalProfileScore(id ?? '');



  return (
    <Box sx={{
        width:'400px',
        height:'76px',
        display: 'flex',
        padding: '12px',
        alignItems: 'center',
        alignSelf: 'stretch',
        borderBottom: '1px solid #D8D8D8',
        background: '#FFF',
        // border:'2px solid red',
    }}
    >

        <Box sx={{
            display:'flex',
            gap:'8px'
        }}>
            <Box>
                 <Avatar
                    alt={user?.term}
                    src={user?.imageUrl}
                    sx={{ width: { sm: 56, xs: 44 }, height: { sm: 56, xs: 44 } }}
                />
            </Box>
            <Box sx={{
                display:'flex',
                flexDirection:'column',
                gap:'10px'
            }}>
                <Box sx={{display:'flex', gap:'5px'}}>
                    <Typography variant='h3' sx={{
                        color: '#23282B',
                        fontFamily: 'Open Sans',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: '24px',
                        letterSpacing: '0.024px',
                    }}
                    
                    >{user?.term}</Typography>
                    <VerificationTick/>
                    <Box sx={{
                        width:'41px',
                        height:'22px',
                        borderRadius: '4px',
                        background: '#49B6FF',
                        color:'#FFF',
                        display: 'flex',
                        justifyContent:'center',
                        alignItems:'center'
                       
                    }}
                    >{id ? score : user?.score}</Box>
                </Box>
                <Box sx={{
                    width:'114px',
                    height:'20px'
                }}>
                    <Typography sx={{
                        color: '#808080',
                        fontFamily: 'Open Sans',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '20px',
                        letterSpacing: '0.049px',
                    }}>{user?.currentEmployment?.employmentLevel}</Typography>
                </Box>
            </Box>
        </Box>

    </Box>
  )
};

interface PropTypes {
    user: User;
    id?: string;
}

type User = {
    term?: string;
    currentEmployment: {
        companyName: string;
        employmentLevel: string;
    };
    imageUrl: string;
    score?: number;
};

export default MentionsDetails