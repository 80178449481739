import React from 'react';

const ArrowRightBlue = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6 12.0016V9.33163C6 6.02163 8.35 4.66163 11.22 6.32163L13.53 7.66163L15.84 9.00163C18.71 10.6616 18.71 13.3716 15.84 15.0316L13.53 16.3716L11.22 17.7116C8.35 19.3416 6 17.9916 6 14.6716V12.0016Z"
                fill="#055C7F"
                stroke="#055C7F"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default ArrowRightBlue;
