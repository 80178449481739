import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useAuth } from '../utils/context/AuthContext';
import MainLayout from '../pages/MainLayout/MainLayout';
import ConnectionsPage from '../pages/Connections/ConnectionsPage';
import MyJobsPage from '../pages/MyJobs/MyJobsPage';
import MessagesPage from '../pages/Messages/MessagesPage';
import ReviewsPage from '../pages/Reviews';
import CoursesPage from '../pages/Courses/CoursesPage';
import NotificationsPage from '../pages/Notifications/NotificationsPags';
import HelpSupportPage from '../pages/HelpSupport/HelpSupportPage';
import SettingsPage from '../pages/Settings/SettingsPage';
import HomePage from '../pages/Home/HomePage';
import UsersPage from '../pages/Users';
import Activities from '../pages/Activities/ActivitiesPage';
import PendingConnection from '../pages/Connections/PendingConnection';
import JobFactorThemePage from '../pages/Theme/JobFactorThemePage';
import JobItemDetail from '../pages/MyJobs/components/JobItem/JobItemDetail';
import JobFactorScore from '../pages/Users/JobFactorScore';
import JobPostings from '../pages/MyJobs/JobPostings';
import JobPostDetail from '../pages/MyJobs/components/JobPost/JobPostDetail';
import JobApplications from '../pages/MyJobs/JobApplications';
import SavedApplications from '../pages/MyJobs/SavedApplications';
import NewJobPost from '../pages/MyJobs/NewJobPost';
import JobPreference from '../pages/MyJobs/JobPreference';
import UserQualifications from '../pages/Users/UserQualifications/UserQualifications';
import SavedJobs from '../pages/MyJobs/SavedJobs';
import AppliedJobs from '../pages/MyJobs/AppliedJobs';
import CompanyActivities from '../pages/CompanyActivities/CompanyActivities';
import JobApplicants from '../pages/MyJobs/JobApplicants';
import CompanyDetails from '../pages/AboutCompany';
import ApplicantDetails from '../pages/MyJobs/ApplicantDetails';
import ProfessionalProfile from '../pages/ProfessionalProfile';
import CompanyProfile from '../pages/CompanyProfile/CompanyProfile';
//import NotFound from '../components/NotFound';
import SkillsPage from '../pages/Users/SkillsPage/SkillsPage';

import { PostProvider } from '../utils/context/PostContext';
import Dashboard from '../pages/Dashboard/Dashboard';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import Security from '../pages/Settings/Security';
import { Responsiveness } from '../theme/overrides';
import { Box, Typography } from '@mui/material';
import Alert from '../assets/icons/Alert';
import GeneralRatingProfessional from '../pages/Reviews/GeneralRating/professoinal';
import GeneralRatingCompany from '../pages/Reviews/GeneralRating/company';
import CompanyReviews from '../pages/CompanyReviews';
import RatingBreakdown from '../pages/CompanyReviews/RatingBreakdown';
import { MessagesProvider } from '../pages/Messages/utils/MessagesContext';
import Post from '../pages/Post/Post';
import Resume from '../pages/Resume/Resume';
import MobileExperienceModal from '../pages/Resume/MobileExperienceModal';

function RequireAuth({ children }: { children: React.ReactNode }) {
    const { isAuthenticated } = useAuth();
    const location = useLocation();

    return isAuthenticated ? (
        <>{children}</>
    ) : (
        <Navigate to="/login" replace state={{ path: location.pathname }} />
    );

    //   return <>{children}</>;
}

const PrivateRoutes = () => {
    const location = useLocation();
    const { isTablet, isMobile } = Responsiveness();

    const [workExperiences, setWorkExperiences] = useState<any>([]);
    const [education, setEducation] = useState<any>([]);
    const [skills, setSkills] = useState<any>([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <Routes>
            <Route path="/linkedin" element={<LinkedInCallback />} />
            <Route path="/*">
                <Route
                    path=""
                    element={
                        <RequireAuth>
                            <PostProvider requestType="all">
                                <MainLayout children={<HomePage />} />
                            </PostProvider>
                        </RequireAuth>
                    }
                />
                <Route
                    path="users/:slug?"
                    element={
                        <RequireAuth>
                            <PostProvider requestType="userPostsById">
                                <MainLayout children={<UsersPage />} />
                            </PostProvider>
                        </RequireAuth>
                    }
                />
                <Route
                    path="skills"
                    element={
                        <RequireAuth>
                            <MainLayout children={<SkillsPage />} />
                        </RequireAuth>
                    }
                />
                <Route
                    path="about-company"
                    element={
                        <RequireAuth>
                            <PostProvider requestType="userPostsById">
                                <MainLayout children={<CompanyDetails />} />
                            </PostProvider>
                        </RequireAuth>
                    }
                />
                <Route
                    path="company-activities"
                    element={
                        <RequireAuth>
                            <PostProvider requestType="userPostsById">
                                <MainLayout children={<CompanyActivities />} />
                            </PostProvider>
                        </RequireAuth>
                    }
                />
                <Route
                    path="activities"
                    element={
                        <RequireAuth>
                            <PostProvider requestType="userPostsById">
                                <MainLayout children={<Activities />} />
                            </PostProvider>
                        </RequireAuth>
                    }
                />
                <Route
                    path="professional-profile/:id"
                    element={
                        <RequireAuth>
                            <PostProvider requestType="userPostsById">
                                <MainLayout
                                    children={<ProfessionalProfile />}
                                />
                            </PostProvider>
                        </RequireAuth>
                    }
                />
                <Route
                    path="company-profile/:id"
                    element={
                        <RequireAuth>
                            <PostProvider requestType="userPostsById">
                                <MainLayout children={<CompanyProfile />} />
                            </PostProvider>
                        </RequireAuth>
                    }
                />
                <Route
                    path="connections"
                    element={
                        <RequireAuth>
                            <MainLayout children={<ConnectionsPage />} />
                        </RequireAuth>
                    }
                />
                <Route
                    path="pending-connections"
                    element={
                        <RequireAuth>
                            <MainLayout children={<PendingConnection />} />
                        </RequireAuth>
                    }
                />
                <Route
                    path="my-jobs"
                    element={
                        <RequireAuth>
                            <MainLayout children={<MyJobsPage />} />
                        </RequireAuth>
                    }
                />

                <Route
                    path="messages"
                    element={
                        <RequireAuth>
                            <MainLayout children={<MessagesPage />} />
                        </RequireAuth>
                    }
                />
                <Route
                    path="my-jobs/:id"
                    element={
                        <RequireAuth>
                            <MainLayout children={<JobItemDetail />} />
                        </RequireAuth>
                    }
                />

                <Route
                    path="resume/:id"
                    element={
                        <RequireAuth>
                            <MainLayout
                                children={
                                    <Resume
                                        setEducation={setEducation}
                                        education={education}
                                        workExperiences={workExperiences}
                                        setWorkExperiences={setWorkExperiences}
                                        skills={skills}
                                        setSkills={setSkills}
                                    />
                                }
                            />
                        </RequireAuth>
                    }
                />
                <Route
                    path="resume/:id/:header"
                    element={
                        <RequireAuth>
                            <MainLayout
                                children={
                                    <MobileExperienceModal
                                        setEducation={setEducation}
                                        education={education}
                                        workExperiences={workExperiences}
                                        setWorkExperiences={setWorkExperiences}
                                        skills={skills}
                                        setSkills={setSkills}
                                    />
                                }
                            />
                        </RequireAuth>
                    }
                />
                <Route
                    path="review-ratings"
                    element={
                        <RequireAuth>
                            <MainLayout children={<CompanyReviews />} />
                        </RequireAuth>
                    }
                />
                <Route
                    path="review-ratings/breakdown"
                    element={
                        <RequireAuth>
                            <MainLayout children={<RatingBreakdown />} />
                        </RequireAuth>
                    }
                />
                <Route
                    path="courses"
                    element={
                        <RequireAuth>
                            <MainLayout
                                children={
                                    isTablet ? (
                                        <Box
                                            mr={isMobile ? 0 : '20px'}
                                            display="flex"
                                            alignItems="center"
                                            gap="12px"
                                            bgcolor="#f2f2f2"
                                            px={
                                                isMobile
                                                    ? '16px'
                                                    : isTablet
                                                    ? '20px'
                                                    : undefined
                                            }
                                            py={
                                                isMobile
                                                    ? '8px'
                                                    : isTablet
                                                    ? '12px'
                                                    : undefined
                                            }
                                        >
                                            <Alert />
                                            <Typography
                                                color="#808080"
                                                fontSize="14px"
                                                fontWeight={600}
                                            >
                                                This feature is unavailable on
                                                this interface. Please login on
                                                your PC to use this feature.
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <CoursesPage />
                                    )
                                }
                            />
                        </RequireAuth>
                    }
                />

                <Route
                    path="notification"
                    element={
                        <RequireAuth>
                            <MainLayout children={<NotificationsPage />} />
                        </RequireAuth>
                    }
                />

                <Route
                    path="settings"
                    element={
                        <RequireAuth>
                            <MainLayout children={<SettingsPage />} />
                        </RequireAuth>
                    }
                />

                <Route
                    path="help-support"
                    element={
                        <RequireAuth>
                            <MainLayout children={<HelpSupportPage />} />
                        </RequireAuth>
                    }
                />
                <Route
                    path="jobfactor-score"
                    element={
                        <RequireAuth>
                            <MainLayout children={<JobFactorScore />} />
                        </RequireAuth>
                    }
                />
                <Route
                    path="job-posting"
                    element={
                        <RequireAuth>
                            <MainLayout children={<JobPostings />} />
                        </RequireAuth>
                    }
                />
                <Route
                    path="job-postdetail/:id"
                    element={
                        <RequireAuth>
                            <MainLayout
                                children={
                                    isTablet ? (
                                        <Box
                                            mr={isMobile ? 0 : '20px'}
                                            display="flex"
                                            alignItems="center"
                                            gap="12px"
                                            bgcolor="#f2f2f2"
                                            px={
                                                isMobile
                                                    ? '16px'
                                                    : isTablet
                                                    ? '20px'
                                                    : undefined
                                            }
                                            py={
                                                isMobile
                                                    ? '8px'
                                                    : isTablet
                                                    ? '12px'
                                                    : undefined
                                            }
                                        >
                                            <Alert />
                                            <Typography
                                                color="#808080"
                                                fontSize="14px"
                                                fontWeight={600}
                                            >
                                                This feature is unavailable on
                                                this interface. Please login on
                                                your PC to use this feature.
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <JobPostDetail />
                                    )
                                }
                            />
                        </RequireAuth>
                    }
                />
                <Route
                    path="job-postdetail/:id/applicants"
                    element={
                        <RequireAuth>
                            <MainLayout children={<JobApplicants />} />
                        </RequireAuth>
                    }
                />
                <Route
                    path="save-job"
                    element={
                        <RequireAuth>
                            <MainLayout children={<SavedJobs />} />
                        </RequireAuth>
                    }
                />
                <Route
                    path="job-applications"
                    element={
                        <RequireAuth>
                            <MainLayout children={<JobApplications />} />
                        </RequireAuth>
                    }
                />
                <Route
                    path="save-applications"
                    element={
                        <RequireAuth>
                            <MainLayout children={<SavedApplications />} />
                        </RequireAuth>
                    }
                />
                <Route
                    path="new-jobpost"
                    element={
                        <RequireAuth>
                            <MainLayout children={<NewJobPost />} />
                        </RequireAuth>
                    }
                />
                <Route
                    path="job-applied"
                    element={
                        <RequireAuth>
                            <MainLayout children={<AppliedJobs />} />
                        </RequireAuth>
                    }
                />
                <Route
                    path="applicant-details/:id"
                    element={
                        <RequireAuth>
                            <MainLayout
                                children={
                                    isTablet ? (
                                        <Box
                                            mr={isMobile ? 0 : '20px'}
                                            display="flex"
                                            alignItems="center"
                                            gap="12px"
                                            bgcolor="#f2f2f2"
                                            px={
                                                isMobile
                                                    ? '16px'
                                                    : isTablet
                                                    ? '20px'
                                                    : undefined
                                            }
                                            py={
                                                isMobile
                                                    ? '8px'
                                                    : isTablet
                                                    ? '12px'
                                                    : undefined
                                            }
                                        >
                                            <Alert />
                                            <Typography
                                                color="#808080"
                                                fontSize="14px"
                                                fontWeight={600}
                                            >
                                                This feature is unavailable on
                                                this interface. Please login on
                                                your PC to use this feature.
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <ApplicantDetails />
                                    )
                                }
                            />
                        </RequireAuth>
                    }
                />
                <Route
                    path="theme"
                    element={
                        <RequireAuth>
                            <JobFactorThemePage />
                        </RequireAuth>
                    }
                />
                <Route
                    path="reviews/:slug?"
                    element={
                        <RequireAuth>
                            <MainLayout children={<ReviewsPage />} />
                        </RequireAuth>
                    }
                />
                <Route
                    path="reviews/received-requests/:id"
                    element={
                        <RequireAuth>
                            <MainLayout
                                children={<GeneralRatingProfessional />}
                            />
                        </RequireAuth>
                    }
                />
                <Route
                    path="reviews/company-ratings/:id"
                    element={
                        <RequireAuth>
                            <MainLayout
                                children={
                                    isTablet ? (
                                        <Box
                                            mr={isMobile ? 0 : '20px'}
                                            display="flex"
                                            alignItems="center"
                                            gap="12px"
                                            bgcolor="#f2f2f2"
                                            px={
                                                isMobile
                                                    ? '16px'
                                                    : isTablet
                                                    ? '20px'
                                                    : undefined
                                            }
                                            py={
                                                isMobile
                                                    ? '8px'
                                                    : isTablet
                                                    ? '12px'
                                                    : undefined
                                            }
                                        >
                                            <Alert />
                                            <Typography
                                                color="#808080"
                                                fontSize="14px"
                                                fontWeight={600}
                                            >
                                                This feature is unavailable on
                                                this interface. Please login on
                                                your PC to use this feature.
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <GeneralRatingCompany />
                                    )
                                }
                            />
                        </RequireAuth>
                    }
                />
                <Route
                    path="user/qualifications/:profileId"
                    element={
                        <RequireAuth>
                            <MainLayout children={<UserQualifications />} />
                        </RequireAuth>
                    }
                />
                <Route
                    path="post/:postId"
                    element={
                        <RequireAuth>
                            <MainLayout children={<Post />} />
                        </RequireAuth>
                    }
                />

                <Route
                    path="job-preference"
                    element={
                        <RequireAuth>
                            <MainLayout children={<JobPreference />} />
                        </RequireAuth>
                    }
                />
                <Route
                    path="dashboard"
                    element={
                        <RequireAuth>
                            <MainLayout children={<Dashboard />} />
                        </RequireAuth>
                    }
                />
                <Route
                    path="security"
                    element={
                        <RequireAuth>
                            <MainLayout children={<Security />} />
                        </RequireAuth>
                    }
                />
                {/* <Route path="*">
                    <Route path="" element={<NotFound />} />
                </Route> */}
            </Route>
        </Routes>
    );
};

export default PrivateRoutes;
