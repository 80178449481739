function CloseXIcon() {
    return (
        <>
            <svg
                width="36"
                height="36"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect width="40" height="40" fill="white" />
                <path
                    d="M29.4277 29.4253L10.5715 10.5691"
                    stroke="#494949"
                    strokeLinecap="round"
                />
                <path
                    d="M29.4285 10.5691L10.5723 29.4253"
                    stroke="#494949"
                    strokeLinecap="round"
                />
            </svg>
        </>
    );
}

export default CloseXIcon;
