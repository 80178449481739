import { Avatar, Box, Typography } from '@mui/material';
import { useGetProfessionalProfileScore } from '../../../utils/hooks/api/professional-profile/useGetProfessionalProfileScore';
import { useNavigate } from 'react-router-dom';
import { Responsiveness } from '../../../theme/overrides';
import ConnectionSkills from './ConnectionSkillsTab';
const UserDetails = (props: PropTypes) => {
    const { isMobile,isTablet } = Responsiveness();
    const { user, id } = props;
    const { data: score } = useGetProfessionalProfileScore(id ?? '');
    const navigate = useNavigate();
    const maxLength = 13
    return (
        <Box display="flex" gap={{ xs: '12px', sm: '16px' }} flex={1}>
            <Avatar
                onClick={() =>
                    navigate(`/professional-profile/${user?.id ?? id}`)
                }
                alt="Remy Sharp"
                src={user?.photoUrl}
                sx={{
                    width: isMobile ? 44 : 56,
                    height: isMobile ? 44 : 56,
                    cursor: 'pointer'
                }}
            />
            {isMobile ? (
                <Box sx={{ width: '100%' }}>
                    <Box
                        display="flex"
                        gap="8px"
                        alignItems="center"
                        width="100%"
                    >
                        <Typography
                            onClick={() =>
                                navigate(
                                    `/professional-profile/${user?.id ?? id}`
                                )
                            }
                            component={'h1'}
                            sx={{
                                fontSize: '14px',
                                fontWeight: '600',
                                fontFamily: 'Open Sans',
                                color: '#494949',
                                cursor: 'pointer'
                            }}
                        >
                            {user.firstName} {user.lastName}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: '14px',
                                fontWeight: '600',
                                fontFamily: 'Open Sans',
                                color: '#FFFFFF',
                                background: '#49B6FF',
                                borderRadius: '6px',
                                padding: '0px 8px'
                            }}
                        >
                            {id ? score : user?.score}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: isTablet ? '8px' : '20px'
                        }}
                    >
                        {user?.currentEmployment?.employmentLevel ? (
                            <Box>
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        fontWeight: 400,
                                        fontFamily: 'Open Sans',
                                        color: '#808080',
                                        letterSpacing: '0.0015em'
                                    }}
                                >
                                    {user?.currentEmployment?.jobTitle}
                                </Typography>
                            </Box>
                        ) : null}
                    </Box>
                    
                </Box>
            ) : null}
            {isMobile ? null : (
                <Box sx={{ width: '100%' }}>
                    <Typography
                        onClick={() =>
                            navigate(`/professional-profile/${user?.id ?? id}`)
                        }
                        component={'h1'}
                        sx={{
                            fontSize: '20px',
                            fontWeight: '600',
                            fontFamily: 'Open Sans',
                            color: '#494949',
                            cursor: 'pointer'
                        }}
                    >
                        {user.firstName} {user.lastName}
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: isTablet ? '8px' : '20px'
                        }}
                    >
                            <>
                                {user?.currentEmployment?.employmentLevel && (
                                    <>
                                        {' '}
                                        <Typography
                                            sx={{
                                                fontSize: isTablet
                                                    ? '14px'
                                                    : '16px',
                                                fontFamily: 'Open Sans',
                                                color: '#808080',
                                                letterSpacing: '0.0015em'
                                            }}
                                        >
                                            {isTablet
                                                ? user?.currentEmployment?.employmentLevel?.slice(
                                                      0,
                                                      13
                                                  ) +
                                                  (user?.currentEmployment
                                                      ?.employmentLevel
                                                      ?.length > maxLength
                                                      ? '...'
                                                      : '')
                                                : user?.currentEmployment
                                                      ?.employmentLevel}
                                        </Typography>
                                        <Typography
                                            component={'div'}
                                            sx={{
                                                width: '7px',
                                                height: '7px',
                                                borderRadius: '100px',
                                                background: '#494949',
                                                border: '2px solid #494949'
                                            }}
                                        />
                                    </>
                                )}

                                {user?.currentEmployment?.companyName &&
                                    <>
                                        <Typography
                                            sx={{
                                                fontSize: isTablet
                                                    ? '14px'
                                                    : '16px',
                                                fontFamily: 'Open Sans',
                                                color: '#808080'
                                            }}
                                        >
                                            {isTablet
                                                ? user?.currentEmployment?.companyName?.slice(
                                                      0,
                                                      13
                                                  ) +
                                                  (user?.currentEmployment
                                                      ?.companyName?.length >
                                                  maxLength
                                                      ? '...'
                                                      : '')
                                                : user?.currentEmployment
                                                      ?.companyName}
                                        </Typography>
                                        <Typography
                                            component={'div'}
                                            sx={{
                                                width: '7px',
                                                height: '7px',
                                                borderRadius: '100px',
                                                background: '#494949',
                                                border: '2px solid #494949'
                                            }}
                                        />
                                    </>
                                }
                            </>
                        <Typography
                            sx={{
                                fontSize: '20px',
                                fontWeight: '600',
                                fontFamily: 'Open Sans',
                                color: '#FFFFFF',
                                background: '#49B6FF',
                                borderRadius: '6px',
                                padding: '0px 12px'
                            }}
                        >
                            {id ? score : user?.score}
                        </Typography>
                    </Box>
                    {isMobile ? null : (
                        <Box mt="12px">
                            <ConnectionSkills
                                count={2}
                                id={user?.profileId}
                            />
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    );
};
interface PropTypes {
    user: User;
    id?: string;
}
type User = {
    id: string;
    score: number;
    firstName: string;
    lastName?: string;
    currentEmployment: {
        companyName: string;
        employmentLevel: string;
        jobTitle: string;
    };
    photoUrl: string;
    profileId: string;
    term?: string;
};
export default UserDetails;
