import { Box, TableCell, TableRow, Typography } from '@mui/material';
import ExperienceLevel from '../../Connections/components/ExperienceLevel';
import TableWrapper from '../../Connections/components/TableWrapper';
import UserActions from '../../Connections/components/UserActions';
import UserDetails from '../../Connections/components//UserDetails';
import React from 'react';
import ConnectionSkills from './ConnectionSkillsTab';
import EmptyState from '../../../components/EmptyState/EmptyState';
import { Responsiveness } from '../../../theme/overrides';

const experienceLevels = [
    { background: '#E75541', title: 'Beginner', shortForm: 'B' },
    { background: '#F6C70E', title: 'Mobile Int', shortForm: 'E' },
    {
        background: '#49B6FF',
        title: 'Customer Experience Design',
        shortForm: 'A'
    },
    { background: '#95C97A', title: 'Expert', shortForm: 'X' }
];

const TabPanel = (props: TabProps) => {
    const { isMobile, isTablet } = Responsiveness()
    const { tab, data, activeTab } = props;
    const [page, setPage] = React.useState(0);
    const rowsPerPage = 9;
    const handleChangePage = (page: number) => {
        setPage(page - 1);
    };

    return (
        <TableWrapper
            activeTab={activeTab}
            tab={tab}
            data={data}
            handleChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
        >
            {data
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((user: any, index: number) => (
                    <TableRow key={`user_${index}`}>
                        <TableCell
                            sx={{
                                padding: isMobile
                                    ? '24px 16px'
                                    : isTablet
                                    ? '28px 20px'
                                    : '32px 40px',
                                paddingBottom: isMobile ? undefined : '25px'
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'inset',
                                    gap: '20px'
                                }}
                            >
                                <UserDetails
                                    user={
                                        user?.destinationUser ??
                                        user?.sourceUser
                                    }
                                    id={
                                        user?.destinationUser?.profileId ??
                                        user?.sourceUser?.profileId
                                    }
                                />
                                <UserActions
                                    user={user}
                                    tab={tab}
                                    title={'PendingConnectionPage'}
                                />
                            </Box>
                            {isMobile ? null : (<Box
                                sx={{
                                    mt: '12px'
                                }}
                            >
                                <ConnectionSkills
                                    count={isTablet ? 2 : 4}
                                    id={
                                        user?.destinationUser?.profileId ??
                                        user?.sourceUser?.profileId
                                    }
                                />
                            </Box>)}
                        </TableCell>
                    </TableRow>
                ))}
            {data.length === 0 && (
                <EmptyState message={`You have no ${tab} connection request`} />
            )}
        </TableWrapper>
    );
};
interface TabProps {
    data: Array<Object>;
    activeTab: string;
    tab: string;
}

export default TabPanel;
