import CircleIcon from '@mui/icons-material/Circle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
    IconButton,
    Grid,
    Box,
    Typography,
    Container,
    Button,
    ImageList,
    ImageListItem
} from '@mui/material';
import cover from '../../assets/images/companyCover.png';
import profile from '../../assets/images/companyProfile.png';
import InfoChip from '../Users/components/InfoChip';
import Section from '../Activities/components/Section';
import SectionHeading from '../Users/components/SectionHeading';
import Image from '../../components/Image';
import AnalyticsProfile from '../../assets/icons/AnalyticsProfile';
import AnalyticsSearch from '../../assets/icons/AnalyticsSearch';
import AnalyticsGraph from '../../assets/icons/AnalyticsGraph';
import ContactInfoModal from '../AboutCompany/components/ContactInfoModal';
import ScoreCard from '../../components/ScoreCard.tsx';
import { useState } from 'react';
import EditIcon from '../../assets/icons/EditIcon';
import EditProfileModal from '../AboutCompany/components/EditProfileModal';
import { ArrowLeftIcon } from '../../assets/icons/ArrowLeftIcon';
import image1 from '../../assets/images/galleryImage1.png';
import image2 from '../../assets/images/galleryImage2.png';
import image3 from '../../assets/images/galleryImage3.png';
import image4 from '../../assets/images/galleryImage4.png';
import image5 from '../../assets/images/galleryImage5.png';
import image6 from '../../assets/images/galleryImage6.png';
import feedImg from '../../assets/images/slider1.png';
import profImg1 from '../../assets/images/profileReview.png';
import profImg2 from '../../assets/images/feed2.png';
import profImg3 from '../../assets/images/SideNavProfile.png';
import ProfileList from '../Activities/components/ProfileList';
import { useNavigate } from 'react-router-dom';
import { FeedItem } from '../Home/components/FeedItem';
import { useAuth } from '../../utils/context/AuthContext';
import { PrimaryProfileType } from '../../utils/hooks/api/account/types';
import ArrowRight from '../../assets/icons/ArrowRight';
import { useGetCompanyProfileScore } from '../../utils/hooks/api/company-profile/useGetCompanyProfileScore';
import { useGetConnections } from '../../utils/hooks/api/connections/useGetConnections';
import { useGetCompanyProfileById } from '../../utils/hooks/api/company-profile/useGetCompanyProfile';
import { usePost } from '../../utils/context/PostContext';
import SkeletonLoader from '../Home/components/SkeletonLoader';
import InfiniteScroll from 'react-infinite-scroll-component';
import SnackAlert from '../../components/Snackbar';
import { Responsiveness } from '../../theme/overrides';
import ProfileBanner from '../ProfileBanner';
import ProfilePhotoIcon from '../../assets/icons/ProfilePhotoIcon';
import EditBtn from '../../assets/icons/EditBtn';
import PageHeader from '../../components/PageHeader/PageHeader';
import MobileNav from '../../components/MobileNav/MobileNav';
import Alert from '../../assets/icons/Alert';

const images = [image1, image2, image3, image4, image5, image6];

const data = [
    {
        id: '1',
        profileImage: profile,
        fullName: 'Jobfactor Inc.',
        jobTitle: 'Product Designer',
        description:
            'Hello everyone,If you wan to get started in brand design you can send me a private message so I can put you through all you will need to get started. #startyourtechjourneynow',
        views: 2456,
        likes: 500,
        commentsCount: 156,
        shares: 75,
        images: [feedImg],
        isAccountVerified: true
    },
    {
        id: '2',
        profileImage: profile,
        fullName: 'Jobfactor Inc.',
        jobTitle: 'Product Designer',
        description: 'test',
        views: 2456,
        likes: 500,
        commentsCount: 156,
        shares: 75,
        images: [],
        isAccountVerified: true
    }
];
const professionals = [
    {
        profileImage: profImg1,
        fullName: 'Jane Cooper',
        jobTitle: 'Product Designer',
        score: 550
    },
    {
        profileImage: profImg2,
        fullName: 'Esther Howard',
        jobTitle: 'Accounting leader',
        score: 450
    },
    {
        profileImage: profImg3,
        fullName: 'Kathryn Murphy',
        jobTitle: 'Human Resource',
        score: 750
    }
];

function CompanyActivities() {
    const { user } = useAuth();
    const [openContactInfoModal, setOpenContactInfoModal] = useState(false);
    const [openProfileInfoModal, setOpenProfileInfoModal] = useState(false);
    const { data: score } = useGetCompanyProfileScore(
        user?.primaryCompanyProfile?.id!
    );
    const { data: connections } = useGetConnections(user?.id!);
    const navigate = useNavigate();
    const [showAlert, setShowAlert] = useState(false);
    const { data: company } = useGetCompanyProfileById(
        user?.primaryCompanyProfile?.companyId ?? ''
    );
    const { posts, totalPosts, isLoaded, setGetAfter } = usePost();
    let Images: any[] = []
    posts?.map((postImages) =>
        postImages?.images?.map((image) => Images.push(image)));
    const {isMobile, isTablet, isXMobile} = Responsiveness()

    const handleOnEditContactInfo = () => {
        setOpenContactInfoModal(true);
    };
        const [activity, setActivity] = useState('Posts');
        const currentActivity = ['Posts', 'Achievements', 'Articles'];

    const routes = [
        {
            label: 'About Us',
            route: '/about-company'
        },
        {
            label: 'Activities',
            route: '/company-activities'
        },
        {
            label: 'Jobfactor Score',
            route: '/jobfactor-score',
            disable: true
        },
        {
            label: 'Employees',
            route: '/employees',
            disable: true
        },
        {
            label: 'Company Ratings',
            route: '/company-ratings',
            disable: true
        }
    ];

    const handleOnEditProfileInfo = () => {
        setOpenProfileInfoModal(true);
    };

    return (
        <Container
            disableGutters
            maxWidth="xl"
            style={{
                maxWidth: '100%'
            }}
            sx={{ pr: isMobile ? 0 : isTablet ? '20px' : '40px' }}
        >
            {isTablet ? (
                <PageHeader
                    header={`${company?.primaryCompanyProfileName}'s Activities`}
                />
            ) : null}
            {isTablet ? <MobileNav list={routes} /> : null}
            <Grid container direction="column" mt='16px' gap={4}>
                <Grid item>
                    <Box
                        bgcolor="#fff"
                        borderRadius={1}
                        overflow="hidden"
                        pb={isMobile ? 0 : 5}
                    >
                        <Box position="relative" pb="109px">
                            <Box
                                position="relative"
                                overflow="hidden"
                                display={'flex'}
                                borderRadius={[0, 0, 1, 1]}
                            >
                                <img
                                    style={{
                                        height: isMobile
                                            ? '100px'
                                            : isTablet
                                            ? '220px'
                                            : '259px',
                                        width: '100%',
                                        objectFit: 'cover',
                                        display: 'block'
                                    }}
                                    alt="Cover"
                                    src={cover}
                                />
                                {isTablet ? null : (
                                    <ProfileBanner
                                        companyName={
                                            company?.primaryCompanyProfileName
                                        }
                                        score={score}
                                    />
                                )}
                            </Box>
                            <Grid
                                container
                                gap={isMobile ? undefined : 3}
                                position={'absolute'}
                                direction={isMobile ? 'column' : undefined}
                                bottom={isMobile ? -10 : isTablet ? 30 : 0}
                            >
                                <Box
                                    ml={isTablet ? '20px' : 5}
                                    borderRadius="50%"
                                    overflow="hidden"
                                    width="fit-content"
                                    boxShadow="-8px 4px 20px rgba(0, 0, 0, 0.07), 8px 8px 20px rgba(0, 0, 0, 0.07)"
                                    position="relative"
                                    mb={isTablet && !company?.logo ? 2 : 0}
                                >
                                    {!company?.logo ? (
                                        <ProfilePhotoIcon />
                                    ) : (
                                        <Image
                                            src={company?.logo ?? ''}
                                            sx={{
                                                width: isTablet
                                                    ? '150px'
                                                    : '200px',
                                                height: isTablet
                                                    ? '150px'
                                                    : '200px',
                                                objectFit: 'cover'
                                            }}
                                            alt={
                                                company?.primaryCompanyProfileName
                                            }
                                            border="3px #fff solid"
                                            borderRadius="50%"
                                            display="block"
                                        />
                                    )}
                                </Box>
                                {isMobile ? (
                                    <Box mt="4px" ml="40px">
                                        <Typography
                                            component="span"
                                            color="#23282B"
                                            fontFamily="open sans"
                                            fontSize={16}
                                            fontWeight={700}
                                        >
                                            {connections?.length ?? 0}
                                        </Typography>
                                        &nbsp;
                                        <Typography
                                            component={'span'}
                                            color="#808080"
                                            fontFamily="open sans"
                                            fontSize={12}
                                        >
                                            {'Connection' +
                                                (connections?.length === 1
                                                    ? ''
                                                    : 's')}
                                        </Typography>
                                    </Box>
                                ) : null}
                                {isMobile ? null : (
                                    <Grid
                                        container
                                        wrap="nowrap"
                                        width="auto"
                                        marginTop="auto"
                                        height="109px"
                                        flexGrow={1}
                                        alignItems="center"
                                        pb={2}
                                        marginLeft={isTablet ? -2 : -1}
                                    >
                                        <Grid
                                            item
                                            flexGrow={1}
                                            mt={isTablet ? '30px' : undefined}
                                        >
                                            <Typography
                                                component="span"
                                                color="#23282B"
                                                fontFamily="open sans"
                                                fontSize={20}
                                                fontWeight={700}
                                            >
                                                {connections?.length ?? 0}
                                            </Typography>
                                            &nbsp;
                                            <Typography
                                                component={'span'}
                                                color="#808080"
                                                fontFamily="open sans"
                                                fontSize={16}
                                            >
                                                {'Connection' +
                                                    (connections?.length === 1
                                                        ? ''
                                                        : 's')}
                                            </Typography>
                                        </Grid>

                                        {isTablet ? null : (
                                            <Grid
                                                item
                                                flexShrink="1"
                                                paddingRight="32px"
                                            >
                                                <IconButton>
                                                    <MoreVertIcon fontSize="medium" />
                                                </IconButton>
                                            </Grid>
                                        )}
                                    </Grid>
                                )}
                            </Grid>
                        </Box>
                        {isMobile ? (
                            <Box
                                display="flex"
                                alignItems="flex-end"
                                flexDirection="column"
                                mt={-13}
                                mr="20px"
                                gap="16px"
                            >
                                <ScoreCard
                                    score={score}
                                    width="50px"
                                    height="50px"
                                />
                                {isXMobile ? (
                                    <Box onClick={handleOnEditProfileInfo}>
                                        <EditBtn />
                                    </Box>
                                ) : (
                                    <Button
                                        sx={{
                                            height: '40px',
                                            width: '150px',
                                            py: '8px',
                                            px: '8px',
                                            maxHeight: '40px',
                                            color: '#05668D',
                                            backgroundColor: '#F2F2F2',
                                            borderRadius: '8px',
                                            textTransform: 'none',
                                            textDecoration: 'none',
                                            gap: 1
                                        }}
                                        onClick={handleOnEditProfileInfo}
                                    >
                                        <EditBtn />
                                        Edit profile
                                    </Button>
                                )}
                            </Box>
                        ) : null}
                        {isMobile ? (
                            <Box
                                width="100%"
                                height="2px"
                                bgcolor="#f2f2f2"
                                my="16px"
                            ></Box>
                        ) : null}
                        <Grid
                            container
                            px={isMobile ? '16px' : isTablet ? '20px' : 5}
                            marginTop={isMobile ? 0 : -3.5}
                        >
                            <Grid item flexGrow={1} marginTop="auto">
                                <Grid
                                    container
                                    direction="column"
                                    gap={isTablet ? '12px' : 2}
                                >
                                    <Grid
                                        item
                                        direction={
                                            isMobile ? 'column' : undefined
                                        }
                                    >
                                        <Grid
                                            container
                                            gap={isTablet ? 1 : 2}
                                            alignItems="center"
                                        >
                                            <Grid item>
                                                <Typography
                                                    component="h2"
                                                    color="#23282B"
                                                    fontFamily="open sans"
                                                    lineHeight="1.125"
                                                    fontSize={
                                                        isMobile
                                                            ? 22
                                                            : isTablet
                                                            ? 24
                                                            : 28
                                                    }
                                                    fontWeight={600}
                                                >
                                                    {
                                                        company?.primaryCompanyProfileName
                                                    }
                                                </Typography>
                                            </Grid>

                                            <Grid item alignItems="center">
                                                <Grid
                                                    container
                                                    gap={isTablet ? 1 : 2}
                                                    alignItems="center"
                                                >
                                                    {isMobile ? (
                                                        <CheckCircleIcon
                                                            htmlColor="#49B6FF"
                                                            style={{
                                                                marginLeft:
                                                                    '8px'
                                                            }}
                                                        />
                                                    ) : (
                                                        <>
                                                            <CircleIcon
                                                                sx={{
                                                                    fontSize:
                                                                        '7.25px'
                                                                }}
                                                                htmlColor="#494949"
                                                            />
                                                            {company?.yearFounded ? (
                                                                <>
                                                                    <Typography
                                                                        component="span"
                                                                        color="#808080"
                                                                        fontFamily="open sans"
                                                                        fontSize={
                                                                            16
                                                                        }
                                                                        fontWeight={
                                                                            400
                                                                        }
                                                                    >
                                                                        Founded
                                                                        in{' '}
                                                                        {
                                                                            company?.yearFounded
                                                                        }
                                                                    </Typography>
                                                                    <CheckCircleIcon
                                                                        htmlColor="#49B6FF"
                                                                        style={{
                                                                            marginLeft:
                                                                                isTablet
                                                                                    ? '8px'
                                                                                    : '-8px'
                                                                        }}
                                                                    />
                                                                </>
                                                            ) : null}
                                                            <Grid item ml={1}>
                                                                <Button
                                                                    variant="contained"
                                                                    style={{
                                                                        width: 'auto',
                                                                        minWidth:
                                                                            'auto',
                                                                        color: '#808080',
                                                                        backgroundColor:
                                                                            '#F2F2F2'
                                                                    }}
                                                                    sx={{
                                                                        px: 1,
                                                                        py: 1.25
                                                                    }}
                                                                    onClick={
                                                                        handleOnEditProfileInfo
                                                                    }
                                                                >
                                                                    <EditIcon fontSize="small" />
                                                                </Button>
                                                            </Grid>
                                                        </>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {isMobile && company?.yearFounded ? (
                                            <Typography
                                                mt="12px"
                                                component="span"
                                                color="#808080"
                                                fontFamily="open sans"
                                                fontSize={14}
                                                fontWeight={400}
                                            >
                                                Founded in{' '}
                                                {company?.yearFounded}
                                            </Typography>
                                        ) : null}
                                    </Grid>
                                    {company?.tagLine ? (
                                        <Grid item>
                                            <Typography
                                                component={'p'}
                                                color="#23282B"
                                                fontFamily="open sans"
                                                fontSize={isMobile ? 14 : 16}
                                                fontWeight={400}
                                            >
                                                {company?.tagLine}
                                            </Typography>
                                        </Grid>
                                    ) : null}
                                    <Grid item>
                                        <Grid
                                            container
                                            gap={1}
                                            alignItems="center"
                                        >
                                            {company?.address ? (
                                                <>
                                                    <Grid item>
                                                        <InfoChip
                                                            type="location"
                                                            label={`${company?.address?.city}, ${company?.address?.country}`}
                                                        />
                                                    </Grid>
                                                    {isMobile ? null : (
                                                        <CircleIcon
                                                            sx={{
                                                                fontSize:
                                                                    '7.25px'
                                                            }}
                                                            htmlColor="#494949"
                                                        />
                                                    )}
                                                </>
                                            ) : null}
                                            {company?.phoneNumber ? (
                                                <>
                                                    <Grid item>
                                                        <InfoChip
                                                            type="phone"
                                                            label={
                                                                company?.phoneNumber ??
                                                                ''
                                                            }
                                                        />
                                                    </Grid>
                                                    {isMobile ? null : (
                                                        <CircleIcon
                                                            sx={{
                                                                fontSize:
                                                                    '7.25px'
                                                            }}
                                                            htmlColor="#494949"
                                                        />
                                                    )}
                                                </>
                                            ) : null}
                                            {company?.emailAddress ? (
                                                <>
                                                    <Grid item>
                                                        <InfoChip
                                                            type="email"
                                                            label={
                                                                company?.emailAddress ??
                                                                ''
                                                            }
                                                        />
                                                    </Grid>
                                                </>
                                            ) : null}
                                            {/* <Grid item ml={2}>
                                                <Button
                                                    variant="contained"
                                                    style={{
                                                        width: 'auto',
                                                        minWidth: 'auto',
                                                        color: '#808080',
                                                        backgroundColor:
                                                            '#F2F2F2'
                                                    }}
                                                    sx={{
                                                        px: 1,
                                                        py: 1.25
                                                    }}
                                                    onClick={
                                                        handleOnEditProfileInfo
                                                    }
                                                >
                                                    <EditIcon fontSize="small" />
                                                </Button>
                                            </Grid> */}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {isTablet ? null : (
                                <Grid item flexShrink={1}>
                                    <Box py={1}>
                                        <ScoreCard score={score} />
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </Grid>
                {isMobile ? (
                    <Box width="100%" height="2px" bgcolor="#f2f2f2"></Box>
                ) : null}
                <Grid item>
                    <Grid container wrap={isTablet ? undefined : 'nowrap'}>
                        <Grid width="100%" xs={isTablet ? 12 : 8}>
                            <Box
                                px={
                                    isMobile
                                        ? '16px'
                                        : isTablet
                                        ? '20px'
                                        : '40px'
                                }
                                bgcolor="#fff"
                                py="16px"
                            >
                                <Box
                                    mb={'16px'}
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Typography
                                        component="h2"
                                        color="#23282B"
                                        fontFamily="open sans"
                                        lineHeight="1.125"
                                        fontSize={
                                            isMobile ? 20 : isTablet ? 24 : 28
                                        }
                                        fontWeight={600}
                                    >
                                        Activities
                                    </Typography>

                                    <Typography
                                        component="h2"
                                        color="#808080"
                                        fontFamily="open sans"
                                        lineHeight="24px"
                                        fontSize={16}
                                        fontWeight={400}
                                    >
                                        {totalPosts + ' posts'}
                                    </Typography>
                                </Box>
                                <Box
                                    display="flex"
                                    height="44px"
                                    bgcolor="#f2f2f2"
                                >
                                    {currentActivity.map((item) => (
                                        <Box
                                            width={`${100 / 3}%`}
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',

                                                borderBottomColor:
                                                    activity === item
                                                        ? '#05668D'
                                                        : 'transparent'
                                            }}
                                            onClick={() => setActivity(item)}
                                            borderBottom="2px solid"
                                        >
                                            <Typography
                                                fontFamily="open sans"
                                                fontSize={14}
                                                fontWeight={600}
                                                lineHeight="20px"
                                                color={
                                                    activity === item
                                                        ? '#05668D'
                                                        : '#808080'
                                                }
                                            >
                                                {item}
                                            </Typography>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                            <Box
                                py={isMobile ? '16px' : '16px'}
                                px={isTablet ? 0 : '32px'}
                                bgcolor="#f2f2f2"
                            >
                                {activity === 'Posts' ? (
                                     posts?.length === 0 ? <Box
                                        display="flex"
                                        alignItems="center"
                                        gap="12px"
                                        pl={isMobile ? '16px' : undefined}
                                    >
                                        <Alert />
                                        <Typography>
                                            {' '}
                                            No Posts
                                        </Typography>
                                    </Box> : (
                                        <InfiniteScroll
                                            dataLength={posts.length}
                                            next={() =>
                                                setGetAfter((prev) => prev + 5)
                                            }
                                            hasMore={
                                                posts.length < (totalPosts ?? 0)
                                            }
                                            scrollThreshold={0.9}
                                            loader={
                                                <>
                                                    {[1, 2, 3].map((_) => (
                                                        <SkeletonLoader />
                                                    ))}
                                                </>
                                            }
                                        >
                                            {posts?.map((feed, index) => (
                                                <FeedItem
                                                    key={index}
                                                    feed={feed}
                                                />
                                            ))}
                                        </InfiniteScroll>
                                    )
                                ) : activity === 'Achievements' ? (
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        gap="12px"
                                        pl={isMobile ? '16px' : undefined}
                                    >
                                        <Alert />
                                        <Typography>
                                            {' '}
                                            No Achievements
                                        </Typography>
                                    </Box>
                                ) : (
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        gap="12px"
                                        pl={isMobile ? '16px' : undefined}
                                    >
                                        <Alert />
                                        <Typography>No Articles</Typography>
                                    </Box>
                                )}
                            </Box>
                        </Grid>
                        {isTablet ? null : (
                            <Grid width="100%" xs={isTablet ? 12 : 4} item>
                                <Box
                                    width="100%"
                                    // heading={
                                    //     <SectionHeading
                                    //         disable={true}
                                    //         ml={0}
                                    //     >
                                    //         Top Professionals in your industry
                                    //     </SectionHeading>
                                    // }
                                    onClick={() => {}}
                                    pl={'20px'}
                                    mb="24px"
                                    // py={'16px'}
                                >
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize="16px"
                                            fontWeight={600}
                                            lineHeight="24px"
                                            letterSpacing="0.024px"
                                        >
                                            Gallery
                                        </Typography>
                                        <IconButton
                                            onClick={() => {}}
                                            sx={{
                                                background: '#DFEBF0',
                                                borderRadius: '8px',
                                                padding: '8px'
                                            }}
                                        >
                                            <ArrowRight />
                                        </IconButton>
                                    </Box>
                                    <ImageList
                                        sx={{
                                            width: '100%',
                                            height: '100%',
                                            borderRadius: '12px'
                                        }}
                                        cols={3}
                                    >
                                        <ImageListItem>
                                            <img
                                                src={company?.logo}
                                                alt={'Gallery'}
                                                style={{ width: '100%' }}
                                            />
                                        </ImageListItem>
                                        {Images?.map((image, index) => (
                                            <ImageListItem key={index}>
                                                <img
                                                    src={image}
                                                    alt={'Gallery'}
                                                    style={{ width: '100%' }}
                                                />
                                            </ImageListItem>
                                        ))}
                                    </ImageList>
                                </Box>
                                <Box
                                    width="100%"
                                    // heading={
                                    //     <SectionHeading
                                    //         disable={true}
                                    //         ml={0}
                                    //     >
                                    //         Top Professionals in your industry
                                    //     </SectionHeading>
                                    // }
                                    onClick={() => {}}
                                    pl={'20px'}
                                    mb="24px"
                                    // py={'16px'}
                                >
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize="16px"
                                            fontWeight={600}
                                            lineHeight="24px"
                                            letterSpacing="0.024px"
                                        >
                                            Top Professionals in your industry
                                        </Typography>
                                        <IconButton
                                            onClick={() => {}}
                                            sx={{
                                                background: '#DFEBF0',
                                                borderRadius: '8px',
                                                padding: '8px'
                                            }}
                                        >
                                            <ArrowRight />
                                        </IconButton>
                                    </Box>
                                    <Box mt="8px" bgcolor="#fff">
                                        {professionals.map((person) => (
                                            <ProfileList data={person} />
                                        ))}
                                    </Box>
                                </Box>

                                <Box
                                    // heading={
                                    // <SectionHeading ml={0} fontSize={106}>
                                    //     Analytics
                                    // </SectionHeading>
                                    // }
                                    onClick={() => {}}
                                    mb="12px"
                                    bgcolor="transparent"
                                >
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        mb="12px"
                                        pl={'20px'}
                                    >
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize="16px"
                                            fontWeight={600}
                                            lineHeight="24px"
                                            letterSpacing="0.024px"
                                        >
                                            Analytics
                                        </Typography>
                                        <IconButton
                                            onClick={() => {}}
                                            sx={{
                                                background: '#DFEBF0',
                                                borderRadius: '8px',
                                                padding: '8px'
                                            }}
                                        >
                                            <ArrowRight />
                                        </IconButton>
                                    </Box>
                                    <Grid
                                        container
                                        gap={0.33}
                                        overflow="hidden"
                                        borderRadius={1.5}
                                        wrap="nowrap"
                                        height="140px"
                                    >
                                        <Grid
                                            item
                                            bgcolor="#E6F0F4"
                                            flexGrow={1}
                                            maxWidth="33%"
                                        >
                                            <Grid
                                                container
                                                flexDirection="column"
                                                alignItems="center"
                                                justifyContent="center"
                                                height="100%"
                                            >
                                                <AnalyticsProfile />
                                                <Typography
                                                    component="p"
                                                    color="#23282B"
                                                    fontFamily="open sans"
                                                    fontSize={14}
                                                    fontWeight={600}
                                                    textAlign="center"
                                                >
                                                    PROFILE
                                                    <br />
                                                    VIEWS
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            bgcolor="#E6F0F4"
                                            flexGrow={1}
                                            maxWidth="33%"
                                        >
                                            <Grid
                                                container
                                                flexDirection="column"
                                                alignItems="center"
                                                justifyContent="center"
                                                height="100%"
                                            >
                                                <AnalyticsSearch />
                                                <Typography
                                                    component="p"
                                                    color="#23282B"
                                                    fontFamily="open sans"
                                                    fontSize={14}
                                                    fontWeight={600}
                                                    textAlign="center"
                                                >
                                                    SEARCH
                                                    <br />
                                                    APPEARANCES
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            bgcolor="#E6F0F4"
                                            flexGrow={1}
                                            maxWidth="33%"
                                        >
                                            <Grid
                                                container
                                                flexDirection="column"
                                                alignItems="center"
                                                justifyContent="center"
                                                height="100%"
                                            >
                                                <AnalyticsGraph />
                                                <Typography
                                                    component="p"
                                                    color="#23282B"
                                                    fontFamily="open sans"
                                                    fontSize={14}
                                                    fontWeight={600}
                                                    textAlign="center"
                                                >
                                                    POSTS
                                                    <br />
                                                    ENGAGEMENTS
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: '#05668D',
                                        borderRadius: '8px',
                                        width: '100%'
                                    }}
                                >
                                    <Typography
                                        color={'#FFFFFF'}
                                        fontFamily="open sans"
                                        fontWeight={700}
                                        fontSize={14}
                                        sx={{ textTransform: 'none', py: 0.5 }}
                                    >
                                        Upgrade to Premium plan
                                    </Typography>
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <ContactInfoModal
                open={openContactInfoModal}
                setOpen={setOpenContactInfoModal}
            />
            <EditProfileModal
                handleClose={() => {
                    setOpenProfileInfoModal(false);
                    setShowAlert(true);
                }}
                open={openProfileInfoModal}
                setOpen={setOpenProfileInfoModal}
            />
            <SnackAlert
                open={showAlert}
                handleClose={() => setShowAlert(false)}
                message={'Profile updated successfully.'}
                type={'success'}
            />
        </Container>
    );
}

export default CompanyActivities;
