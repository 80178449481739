import { useQuery } from 'react-query';
import { QueryKeys } from '../QueryKey';
import axiosInstance from '../axiosConfig';

export function useGetPendingCompanyRequests(userId: string) {
    const getPendingCompanyRequests = async (userId: string) => {
        const response = await axiosInstance.get(
            `/api/company-reviews-requests/pending/${userId}`
        );
        const data = await response.data;
        return data;
    };
    return useQuery(QueryKeys.RetrieveCompanyPendingReviewRequest, () =>
        getPendingCompanyRequests(userId),
    );
}
