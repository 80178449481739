import { Box, Button, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import ArrowBack from '../../assets/icons/ArrowBack';
import MoreIcon from '../../assets/icons/MoreIcon';
import { useAuth } from '../../utils/context/AuthContext';
import ScoreCard from '../../components/ScoreCard.tsx';
import EditBtn from '../../assets/icons/EditBtn';
import Phone from '../../assets/icons/Phone';
import { Download, HdrPlus, PlusOne, Tag } from '@mui/icons-material';
import Birthday from '../../assets/icons/Birthday';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetProfessionalProfileById } from '../../utils/hooks/api/professional-profile/useGetProfessionalProfile';
import JobfactorBlue from '../../assets/icons/JobfactorBlue';
import LocationBlue from '../../assets/icons/LocationBlue';
import Sms from '../../assets/icons/Sms';
import PlusIcon from '../../assets/icons/PlusIcon';
import ExperienceModal from './ExperienceModal';
import { logger } from '../../utils/Helper/logger';
import UploadIcon from '../../assets/icons/UploadIcon';
import DownloadIcon from '../../assets/icons/DownloadIcon';
import TickCircle from '../../assets/icons/TickCircle';
import DotIcon from '../../assets/icons/DotIcon';
import CloseSquare from '../../assets/icons/CloseSquare';
import InfoChip from '../Users/components/InfoChip';
import EditIcon from '@mui/icons-material/Edit';
import CircleIcon from '@mui/icons-material/Circle';
import ExperienceLevel from '../Users/components/ExperienceLevel';
import { experienceLevels } from '../Reviews/constants';
import moment from 'moment';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export interface ISkillType {
    id: string;
    level: string;
    name: string;
    rating: number;
    type: string;
    witness: boolean;
}

const Resume = ({
    setEducation,
    education,
    workExperiences,
    setWorkExperiences,
    skills,
    setSkills
}: {
    setEducation: any;
    education: any;
    workExperiences: any;
    setWorkExperiences: any;
    skills: any;
    setSkills: any;
}) => {
    // const { user } = useAuth();
    const { id } = useParams();
    const { data: user } = useGetProfessionalProfileById(id ?? '');
    // const [workExperiences, setWorkExperiences] = useState([]);
    // const [education, setEducation] = useState([]);
    // const [skills, setSkills] = useState([]);
    const [open, setOpen] = useState('');
    const [download, setDownload] = useState(false);
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState<any>(null);
    // useEffect(() => {
    //     setWorkExperiences(user?.employments);
    //     setEducation([]);
    //     setSkills(user?.skills);
    // }, []);

    const contentRef = useRef();

    const handleDownloadPdf = async () => {
        setDownload(true);
        try {
            const canvas = await html2canvas(contentRef.current!);
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
            pdf.save('download.pdf');
        } finally {
            setDownload(false);
        }
    };

    const menuItems = [
        {
            icon: <UploadIcon color="#363636" />,
            label: 'Share',
            action: () => {}
        },
        {
            icon: <DownloadIcon />,
            label: 'Download as pdf',
            action: handleDownloadPdf
        }
    ];
    const userDetails = [
        {
            icon: <JobfactorBlue />,
            key: 'Your Profile',
            value: 'https://app.jobfactor.com/professional-profile/' + id
        },
        {
            icon: <Phone />,
            key: 'Phone',
            value: user?.phoneNumber
        },
        {
            icon: <LocationBlue />,
            key: 'Address',
            value: `${user?.address?.street1} ${user?.address?.city} ${user?.address?.stateProvince}`
        },
        {
            icon: <Sms />,
            key: 'Email',
            value: user?.emailAddress
        },
        {
            icon: <Birthday />,
            key: 'Birthday',
            value: user?.dateOfBirth ?? 'May 29'
        }
    ];

    const jobs = [
        {
            jobTitle: 'Senior Product Designer',
            startDate: 'Jun 2020',
            endDate: 'Aug 2022',
            companyName: 'NASA'
        },
        {
            jobTitle: 'Junior Product Designer',
            startDate: 'Jun 2020',
            endDate: 'Aug 2022',
            companyName: 'NASA'
        }
    ];
    useEffect(() => {
        if (education.length > 0)
            localStorage.setItem('educations', JSON.stringify(education));
        if (skills.length > 0)
            localStorage.setItem('skill', JSON.stringify(skills));
        if (workExperiences.length > 0)
            localStorage.setItem('work', JSON.stringify(workExperiences));
    }, [education, skills, workExperiences]);

    logger.log(education);

    useEffect(() => {
        const educations = localStorage.getItem('educations');
        const work = localStorage.getItem('work');
        const skill = localStorage.getItem('skill');
        if (
            educations !== null &&
            educations !== 'undefined' &&
            educations !== undefined
        ) {
            logger.log('werty');
            setEducation(JSON.parse(educations));
        } else
            localStorage.setItem(
                'educations',
                JSON.stringify(user?.qualifications)
            );
        if (skill !== null && skill !== 'undefined' && skill !== undefined)
            setSkills(JSON.parse(skill));
        else localStorage.setItem('skill', JSON.stringify(user?.skills));

        if (work !== null && work !== 'undefined' && work !== undefined)
            setWorkExperiences(JSON.parse(work));
        else localStorage.setItem('work', JSON.stringify(user?.employments));

        logger.log(education);
        logger.log(skills);
        logger.log(workExperiences);
    }, []);

    return (
        <Box pr={{ xs: 0, sm: '20px', lg: '40px' }}>
            <Box
                p={{ xs: '20px 16px', sm: '22px 20px', lg: '30px 40px' }}
                bgcolor="#fff"
                display="flex"
                alignItems="center"
                gap={{ xs: '12px', sm: '20px', lg: '28px' }}
                mb="8px"
            >
                <Box
                    sx={{ cursor: 'pointer' }}
                    onClick={() => navigate(-1)}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <ArrowBack />
                </Box>
                <Typography
                    flex={1}
                    fontSize={{ xs: '20px', sm: '24px' }}
                    color="#23282B"
                    fontWeight={600}
                >
                    Resume
                </Typography>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                        bgcolor: '#f2f2f2',
                        borderRadius: '8px',
                        p: '8px',
                        cursor: 'pointer'
                    }}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                >
                    <MoreIcon />
                </Box>
            </Box>
            <Box
                ref={contentRef}
                p={download ? '16px' : 0}
                bgcolor="#f2f2f2"
                height="fit-content"
                width="100%"
            >
                <Box
                    p={{ xs: '16px', sm: '16px 20px', lg: '16px 40px' }}
                    bgcolor="#fff"
                    display="flex"
                    alignItems="center"
                    gap="24px"
                    mb="8px"
                    width="100%"
                >
                    <Box width="100%">
                        {/* <Typography
                        fontSize={{ xs: '24px' }}
                        color="#23282B"
                        fontWeight={600}
                        mb="8px"
                    >
                        {user?.lastName} {user?.firstName}
                    </Typography>
                    <Typography
                        fontSize="16px"
                        color="#808080"
                        fontWeight={600}
                    >
                        {user?.currentEmployment?.jobTitle}
                    </Typography> */}
                        <Box flexGrow={1} marginTop="auto">
                            <Box
                                display="flex"
                                flexDirection="column"
                                gap={0.5}
                            >
                                <Box
                                    display="flex"
                                    flexDirection={{
                                        xs: 'column',
                                        sm: undefined
                                    }}
                                >
                                    <Box
                                        gap={'12px'}
                                        display="flex"
                                        alignItems="center"
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '4px'
                                            }}
                                        >
                                            <Typography
                                                component="h2"
                                                color="#23282B"
                                                fontFamily="open sans"
                                                // lineHeight="1.125"
                                                fontSize={{
                                                    xs: 22,
                                                    sm: 24,
                                                    xl: 28
                                                }}
                                                fontWeight={600}
                                            >
                                                {user?.lastName}{' '}
                                                {user?.firstName}
                                            </Typography>
                                            {/* <Box> */}
                                            <TickCircle />
                                            {/* <VerifiedIcon /> */}
                                            {/* </Box> */}
                                        </Box>
                                        <CircleIcon
                                            sx={{
                                                display: {
                                                    xs: 'none',
                                                    sm: 'block'
                                                },
                                                fontSize: '6px'
                                            }}
                                            htmlColor="#C5C5C5"
                                        />
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '12px'
                                    }}
                                >
                                    <Typography
                                        component={'p'}
                                        color="#23282B"
                                        fontFamily="open sans"
                                        fontSize={{ xs: 14, sm: 16 }}
                                        fontWeight={400}
                                    >
                                        {user?.currentEmployment?.jobTitle}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Box
                                        display="flex"
                                        gap={'4px'}
                                        alignItems="center"
                                        flexWrap="wrap"
                                        mb="4px"
                                    >
                                        {user ? (
                                            <>
                                                <Box>
                                                    <InfoChip
                                                        type="profile"
                                                        label={
                                                            `https://app.jobfactor.com/professional-profile/${id}` ??
                                                            ''
                                                        }
                                                    />
                                                </Box>
                                            </>
                                        ) : null}
                                        {user?.address ? (
                                            <>
                                                <Box>
                                                    <InfoChip
                                                        type="location"
                                                        label={`${
                                                            user?.address
                                                                ?.city ?? ''
                                                        }, ${
                                                            user?.address
                                                                ?.country ?? ''
                                                        }`}
                                                    />
                                                </Box>
                                                {/* {isMobile ? null : (
                                                        <CircleIcon
                                                            sx={{
                                                                fontSize:
                                                                    '7.25px'
                                                            }}
                                                            htmlColor="#494949"
                                                        />
                                                    )} */}
                                            </>
                                        ) : null}
                                        <Box>
                                            <Button
                                                variant="contained"
                                                style={{
                                                    width: 'auto',
                                                    minWidth: 'auto',
                                                    color: '#808080',
                                                    backgroundColor: '#F2F2F2',
                                                    zIndex: 5
                                                }}
                                                sx={{
                                                    p: '6px'
                                                }}
                                                // onClick={handleOnEditProfileInfo}
                                            >
                                                <EditIcon fontSize="small" />
                                            </Button>
                                        </Box>
                                    </Box>
                                    <Box
                                        display="flex"
                                        gap={'4px'}
                                        alignItems="center"
                                        flexWrap="wrap"
                                    >
                                        {user?.phoneNumber ? (
                                            <>
                                                <Box>
                                                    <InfoChip
                                                        type="phone"
                                                        label={
                                                            user?.phoneNumber ??
                                                            ''
                                                        }
                                                    />
                                                </Box>
                                            </>
                                        ) : null}
                                        {user?.emailAddress ? (
                                            <>
                                                <Box>
                                                    <InfoChip
                                                        type="email"
                                                        label={
                                                            user?.emailAddress ??
                                                            ''
                                                        }
                                                    />
                                                </Box>
                                            </>
                                        ) : null}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <ScoreCard
                        noText
                        score={user?.score}
                        width="80px"
                        height="80px"
                    />
                </Box>
                {/* <Box
                p={{ xs: '16px', sm: '8px 20px', lg: '8px 40px' }}
                bgcolor="#fff"
                mb="8px"
            >
                <Box
                    display="flex"
                    alignItems="center"
                    gap="8px"
                    mb={{ xs: '16px', sm: '20px' }}
                >
                    <Typography
                        fontSize="20px"
                        fontWeight={600}
                        color="#23282B"
                        flex={1}
                    >
                        Contact Info
                    </Typography>
                    <Button
                        variant="contained"
                        startIcon={<EditBtn color="#fff" />}
                        sx={{
                            bgcolor: '#05668D',
                            width: '87px',
                            height: { xs: '36px', sm: '44px' },
                            borderRadius: '8px',
                            textTransform: 'none',
                            '&:hover': { bgcolor: '#05668D' },
                            fontSize: '14px',
                            fontWeight: 600,
                            color: '#fff'
                        }}
                    >
                        Edit
                    </Button>
                </Box>
                <Box display="flex" flexDirection="column" gap="16px">
                    {userDetails.map((item, i) => (
                        <Box
                            key={i}
                            display="flex"
                            gap={{ xs: '12px', sm: '20px' }}
                            alignItems="flex-start"
                        >
                            <Box width="24px">{item.icon}</Box>
                            <Box>
                                <Typography
                                    fontSize="16px"
                                    fontWeight={600}
                                    color="#23282B"
                                    mb="8px"
                                >
                                    {item.key}
                                </Typography>
                                <Typography color="#055C7F" fontSize="14px">
                                    {item.value}
                                </Typography>
                            </Box>
                        </Box>
                    ))}
                </Box>
            </Box> */}
                <Box
                    display="flex"
                    gap="8px"
                    flexDirection={{ xs: 'column', md: 'row' }}
                    width="100%"
                >
                    <Box width="100%">
                        <Box
                            px={{ xs: '16px', sm: '20px', lg: '40px' }}
                            bgcolor="#fff"
                            py="16px"
                            mb="8px"
                        >
                            <Typography
                                color="#23282B"
                                fontSize={{ xs: '12px' }}
                            >
                                {user?.bio}
                            </Typography>
                        </Box>
                        <Box mb="8px">
                            <Box
                                bgcolor="#fff"
                                py="12px"
                                px={{ xs: '16px', sm: '20px', lg: '40px' }}
                                display="flex"
                                alignItems="center"
                                gap="16px"
                                mb="4px"
                            >
                                <Typography
                                    color="#23282B"
                                    fontSize={{ xs: '16px', sm: '20px' }}
                                    fontWeight={600}
                                    flex={1}
                                >
                                    Work Experience
                                </Typography>
                                <Button
                                    variant="contained"
                                    startIcon={<PlusIcon />}
                                    onClick={() => setOpen('Work Experience')}
                                    sx={{
                                        display: { xs: 'none', sm: 'flex' },
                                        bgcolor: '#f2f2f2',
                                        width: '67px',
                                        height: { xs: '36px' },
                                        borderRadius: '8px',
                                        textTransform: 'none',
                                        '&:hover': {
                                            bgcolor: '#f2f2f2',
                                            color: '#05668D'
                                        },
                                        fontSize: '14px',
                                        fontWeight: 600,
                                        color: '#05668D'
                                    }}
                                >
                                    Add
                                </Button>
                                <Button
                                    variant="contained"
                                    startIcon={<PlusIcon />}
                                    onClick={() => navigate('Work Experience')}
                                    sx={{
                                        display: { xs: 'flex', sm: 'none' },
                                        bgcolor: '#f2f2f2',
                                        width: '67px',
                                        height: { xs: '36px' },
                                        borderRadius: '8px',
                                        textTransform: 'none',
                                        '&:hover': {
                                            bgcolor: '#f2f2f2',
                                            color: '#05668D'
                                        },
                                        fontSize: '14px',
                                        fontWeight: 600,
                                        color: '#05668D'
                                    }}
                                >
                                    Add
                                </Button>
                            </Box>
                            <Box
                                display="flex"
                                flexDirection="column"
                                gap="4px"
                            >
                                {workExperiences?.map((job: any) => (
                                    <ResumeWorkExperience
                                        job={job}
                                        setWorkExperiences={setWorkExperiences}
                                        workExperiences={workExperiences}
                                    />
                                ))}
                            </Box>
                        </Box>
                        <Box mb="8px">
                            <Box
                                bgcolor="#fff"
                                py="12px"
                                px={{ xs: '16px', sm: '20px', lg: '40px' }}
                                display="flex"
                                alignItems="center"
                                gap="16px"
                                mb="4px"
                            >
                                <Typography
                                    color="#23282B"
                                    fontSize={{ xs: '16px', sm: '20px' }}
                                    fontWeight={600}
                                    flex={1}
                                >
                                    Education
                                </Typography>
                                <Button
                                    variant="contained"
                                    startIcon={<PlusIcon />}
                                    onClick={() => setOpen('Education')}
                                    sx={{
                                        display: { xs: 'none', sm: 'flex' },
                                        bgcolor: '#f2f2f2',
                                        width: '67px',
                                        height: { xs: '36px' },
                                        borderRadius: '8px',
                                        textTransform: 'none',
                                        '&:hover': {
                                            bgcolor: '#f2f2f2',
                                            color: '#05668D'
                                        },
                                        fontSize: '14px',
                                        fontWeight: 600,
                                        color: '#05668D'
                                    }}
                                >
                                    Add
                                </Button>
                                <Button
                                    variant="contained"
                                    startIcon={<PlusIcon />}
                                    onClick={() => navigate('Education')}
                                    sx={{
                                        display: { xs: 'flex', sm: 'none' },
                                        bgcolor: '#f2f2f2',
                                        width: '67px',
                                        height: { xs: '36px' },
                                        borderRadius: '8px',
                                        textTransform: 'none',
                                        '&:hover': {
                                            bgcolor: '#f2f2f2',
                                            color: '#05668D'
                                        },
                                        fontSize: '14px',
                                        fontWeight: 600,
                                        color: '#05668D'
                                    }}
                                >
                                    Add
                                </Button>
                            </Box>
                            <Box
                                display="flex"
                                flexDirection="column"
                                gap="4px"
                            >
                                {education.map((item: any) => (
                                    <ResumeEducation
                                        education={item}
                                        setEducation={setEducation}
                                    />
                                ))}
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        minWidth={{ xs: '100%', md: '300px' }}
                        maxWidth={{ xs: '100%', md: '300px' }}
                        height="100%"
                        flex={1}
                    >
                        <Box
                            width="100%"
                            p="12px 24px"
                            bgcolor="#fff"
                            display="flex"
                            alignItems="center"
                            mb="4px"
                        >
                            <Typography
                                color="#23282B"
                                fontSize={{ xs: '16px', sm: '20px' }}
                                fontWeight={600}
                                flex={1}
                            >
                                Skills
                            </Typography>
                            <Button
                                variant="contained"
                                startIcon={<PlusIcon />}
                                onClick={() => setOpen('Skills')}
                                sx={{
                                    display: { xs: 'none', sm: 'flex' },
                                    bgcolor: '#f2f2f2',
                                    width: '67px',
                                    height: { xs: '36px' },
                                    borderRadius: '8px',
                                    textTransform: 'none',
                                    '&:hover': {
                                        bgcolor: '#f2f2f2',
                                        color: '#05668D'
                                    },
                                    fontSize: '14px',
                                    fontWeight: 600,
                                    color: '#05668D'
                                }}
                            >
                                Add
                            </Button>
                            <Button
                                variant="contained"
                                startIcon={<PlusIcon />}
                                onClick={() => navigate('Skills')}
                                sx={{
                                    display: { xs: 'flex', sm: 'none' },
                                    bgcolor: '#f2f2f2',
                                    width: '67px',
                                    height: { xs: '36px' },
                                    borderRadius: '8px',
                                    textTransform: 'none',
                                    '&:hover': {
                                        bgcolor: '#f2f2f2',
                                        color: '#05668D'
                                    },
                                    fontSize: '14px',
                                    fontWeight: 600,
                                    color: '#05668D'
                                }}
                            >
                                Add
                            </Button>
                        </Box>
                        <Box
                            width="100%"
                            p="12px 24px 12px 16px"
                            bgcolor="#fff"
                            display="flex"
                            flexDirection="column"
                            gap="8px"
                            height="100%"
                        >
                            {skills
                                ?.sort((a: ISkillType, b: ISkillType) =>
                                    a?.rating < b?.rating ? 1 : -1
                                )
                                ?.map((x: ISkillType, i: number) => {
                                    let level = experienceLevels?.find(
                                        (y) => y.key === x.rating
                                    );
                                    return (
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="space-between"
                                            gap="10px"
                                        >
                                            <ExperienceLevel
                                                background={
                                                    level?.color ?? '#E75541'
                                                }
                                                shortForm={
                                                    level?.shortForm ?? 'B'
                                                }
                                                title={x?.name}
                                            />
                                            <Box
                                                sx={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    setSkills((prev: any) =>
                                                        prev.filter(
                                                            (item: any) =>
                                                                item.name !==
                                                                x.name
                                                        )
                                                    );
                                                }}
                                            >
                                                <CloseSquare />
                                            </Box>
                                        </Box>
                                    );
                                })}
                        </Box>
                    </Box>
                </Box>
            </Box>
            <ExperienceModal
                open={open}
                onClose={() => setOpen('')}
                user={user}
                setEducation={setEducation}
                education={education}
                workExperiences={workExperiences}
                setWorkExperiences={setWorkExperiences}
                skills={skills}
                setSkills={setSkills}
            />
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                PaperProps={{
                    sx: {
                        width: '108px'
                    },
                    style: {
                        width: 200,
                        marginLeft: '-120px'
                    }
                }}
                disableScrollLock
                sx={{
                    width: '138px'
                }}
            >
                {menuItems.map((item, i) => (
                    <MenuItem
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '16px',
                            borderBottom:
                                i + 1 !== menuItems.length
                                    ? '1px solid #D8D8D8'
                                    : 'non'
                        }}
                        onClick={() => {
                            setAnchorEl(null);
                            item.action();
                        }}
                    >
                        {item.icon}
                        <Typography fontSize="16px" color="#808080">
                            {item.label}
                        </Typography>
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
};

type ResumeProps = {
    job: {
        id: string;
        jobTitle: string;
        startDate: string;
        endDate: string;
        companyName: string;
        jobDescription: string;
        isCurrentPosition: boolean;
    };
    setWorkExperiences: any;
    workExperiences: any
};

export default Resume;
const ResumeWorkExperience = ({ job, setWorkExperiences, workExperiences }: ResumeProps) => {
    return (
        <Box
            py="20px"
            px={{ xs: '16px', sm: '20px', lg: '40px' }}
            bgcolor="#fff"
            position="relative"
        >
            <Box
                sx={{position: 'absolute', top: '5px', right: '5px', cursor: 'pointer'}}
                onClick={() => {
                    setWorkExperiences((prev: any) =>
                        prev.filter((item: any) => item.id !== job.id)
                        );
                }}
            >
                <CloseSquare />
            </Box>
            <Box display="flex" alignItems="center" mb="6px">
                <Typography
                    flex={1}
                    color="#23282B"
                    fontSize="16px"
                    fontWeight={600}
                >
                    {job.jobTitle}
                </Typography>
                <Box display="flex" alignItems="center" gap="10px">
                    <Typography color="#808080" fontSize="12px">
                        {moment(job.startDate).format('MMM DD')}
                    </Typography>
                    <Typography color="#808080" fontSize="12px">
                        <DotIcon />
                    </Typography>
                    <Typography color="#808080" fontSize="12px">
                        {job.isCurrentPosition
                            ? 'Present'
                            : moment(job.endDate).format('MMM DD')}
                    </Typography>
                </Box>
            </Box>
            <Typography color="#494949" fontSize="14px">
                {job.companyName}
            </Typography>
            <Box
                py="12px"
                pl="8px"
                display="flex"
                flexDirection="column"
                gap="8px"
            >
                {job.jobDescription.split('. ').map((item) => (
                    <Box display="flex" alignItems="center" gap="8px">
                        <DotIcon height="3px" width="3px" />
                        <Typography color="#23282B" fontSize="12px">
                            {item}.
                        </Typography>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

type EDURESPROPS = {
    education: {
        id: string;
        degree: string;
        fieldOfStudy: string;
        universityName: string;
        startDate: string;
        endDate: string;
        school: string;
    };
    setEducation: any;
};

const ResumeEducation = ({ education , setEducation}: EDURESPROPS) => {
    return (
        <Box
            py="12px"
            px={{ xs: '16px', sm: '20px', lg: '40px' }}
            bgcolor="#fff"
            position="relative"
        >
            <Box
                sx={{position: 'absolute', top: '5px', right: '5px', cursor: 'pointer'}}
                onClick={() => {
                    setEducation((prev: any) =>
                        prev.filter((item: any) => item.id !== education.id)
                        );
                }}
            >
                <CloseSquare />
            </Box>
            <Typography
                color="#23282B"
                fontSize="16px"
                fontWeight={600}
                mb="6px"
            >
                {education.school}
            </Typography>
            <Typography color="#494949" fontSize="12px" mb="4px">
                {education.degree} in {education.fieldOfStudy}
            </Typography>
            <Box display="flex" alignItems="center" gap="10px">
                <Typography color="#808080" fontSize="12px">
                    {moment(education.startDate).format('YYYY')}
                </Typography>
                <Typography color="#808080" fontSize="12px">
                    <DotIcon />
                </Typography>
                <Typography color="#808080" fontSize="12px">
                    {moment(education.endDate).format('YYYY')}
                </Typography>
            </Box>
        </Box>
    );
};
