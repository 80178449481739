import { useEffect, useState } from 'react';
import { useMessages } from '../../utils/MessagesContext';
import { Box, Stack } from '@mui/material';
import ConversationPreview from './ConversationPreview';
import { IConversation, IMessage } from '../../utils/types';
import { messagesComponentHeight } from '../../utils/helpers';
import ConversationToolbar from './ConversationToolbar';
import { logger } from '../../../../utils/Helper/logger';

export default function ConversationList() {
    const {
        userId,
        socket,
        hasConversations,
        activeConversation,
        conversations,
        setConversations,
        showArchived,
        viewMode,
    } = useMessages();
    const [placeholderMsg, setPlaceholderMsg] = useState<string>('');
    const [displayedConversations, setDisplayedConversations] = useState<
        IConversation[]
    >([]);

    // Fetch conversations & update on new message
    useEffect(() => {
        if (!socket) return;

        const fetchConversations = async () => {
            const response = await socket
                .timeout(15000)
                .emitWithAck('findAllConversations', userId);
            if (!Array.isArray(response)) throw new Error();
            setConversations(response);
        };

        const updateConversation = ({
            senderId,
            recipientId,
            ...message
        }: IMessage) => {
            setConversations((prevConversations) => {
                const updatedConversations = [...prevConversations];
                const conversationIndex = updatedConversations.findIndex(
                    (conversation) =>
                        [senderId, recipientId].includes(conversation.userId)
                );
                if (conversationIndex === -1) return prevConversations;
                const incrementUnreadCount = +(
                    recipientId === userId &&
                    ![senderId, recipientId].includes(
                        activeConversation?.userId ?? ''
                    )
                );
                const updatedConversation = {
                    ...updatedConversations[conversationIndex],
                    preview: message.content,
                    unreadCount:
                        updatedConversations[conversationIndex].unreadCount +
                        incrementUnreadCount,
                    timestamp: message.timestamp
                };
                updatedConversations[conversationIndex] = updatedConversation;
                return updatedConversations;
            });
        };

        socket.on('newMessage', updateConversation);

        socket.on('newConversation', (conversation: IConversation) => {
            setConversations((prevConversations) => [
                ...prevConversations,
                conversation
            ]);
        });

        fetchConversations().catch((err) => {
            if (hasConversations && !!conversations.length) return;
            logger.error(err);
            // setAlert('Timed out while loading conversations', 'error');
        });

        return () => {
            socket.off('newMessage', updateConversation);
            socket.off('newConversation');
        };
    }, [activeConversation?.userId, setConversations, socket, userId]);

    // Filter conversations based on view mode
    useEffect(() => {
        const displayed = conversations.filter(
            (c) => c?.archived === showArchived
        );
        setDisplayedConversations(displayed);
    }, [conversations, showArchived]);

    // Set placeholder message
    useEffect(() => {
        if (!hasConversations) return setPlaceholderMsg('No conversations yet');
        if (showArchived) {
            if (conversations.length === 0) {
                return setPlaceholderMsg('Loading conversations...');
            } else {
                return setPlaceholderMsg(
                    `No ${
                        showArchived ? 'archived' : 'unarchived'
                    } conversations`
                );
            }
        }
    }, [hasConversations, conversations, showArchived]);

    return (
        <Box
            width="100%"
            sx={{
                maxWidth: { xs: '100%', md: '426px' },
                display: {
                    xs: activeConversation ? 'none' : 'block',
                    md: 'block'
                }
            }}
        >
            {viewMode === 'manage' && <ConversationToolbar />}
            <Stack
                sx={{
                    // width: '426px',
                    height: messagesComponentHeight,
                    bgcolor: '#FFF',
                    overflowX: 'hidden',
                    overflowY: 'scroll',
                    '&::-webkit-scrollbar': {
                        width: '10px'
                    },
                    '&::-webkit-scrollbar-track-piece': {
                        backgroundColor: 'inherit'
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#CBCBCB',
                        outline: '2px solid #FFF',
                        outlineOffset: '-2px',
                        border: '.1px solid #B7B7B7',
                        borderRadius: '10px'
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: '#909090'
                    }
                }}
            >
                {displayedConversations.length === 0 ? (
                    <Box sx={{ textAlign: 'center', mt: '12px' }}>
                        {placeholderMsg}
                    </Box>
                ) : (
                    displayedConversations.map((conversation) => (
                        <ConversationPreview
                            key={conversation.userId}
                            conversation={conversation}
                        />
                    ))
                )}
            </Stack>
        </Box>
    );
}
