import { useQuery } from 'react-query';
import { QueryKeys } from '../QueryKey';
import axiosInstance from '../axiosConfig';

export function useGetProfessionalRatingById(id: string) {
    const getProfessionalRatingById = async (id: string) => {
        const response = await axiosInstance.get(`/api/ratings/${id}`);
        const data = await response.data;
        return data;
    };
    return useQuery(QueryKeys.RetrieveRaingById, () =>
        getProfessionalRatingById(id)
    );
}
