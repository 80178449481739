const EmailSolid = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
        >
            <path
                d="M17 4.41211H7C4 4.41211 2 5.91211 2 9.41211V16.4121C2 19.9121 4 21.4121 7 21.4121H17C20 21.4121 22 19.9121 22 16.4121V9.41211C22 5.91211 20 4.41211 17 4.41211ZM17.47 10.5021L14.34 13.0021C13.68 13.5321 12.84 13.7921 12 13.7921C11.16 13.7921 10.31 13.5321 9.66 13.0021L6.53 10.5021C6.21 10.2421 6.16 9.76211 6.41 9.44211C6.67 9.12211 7.14 9.06211 7.46 9.32211L10.59 11.8221C11.35 12.4321 12.64 12.4321 13.4 11.8221L16.53 9.32211C16.85 9.06211 17.33 9.11211 17.58 9.44211C17.84 9.76211 17.79 10.2421 17.47 10.5021Z"
                fill="#05668D"
            />
        </svg>
    );
};

export default EmailSolid;
