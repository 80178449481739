import React, { useEffect, useState } from 'react';
import {
    Box,
    Checkbox,
    Chip,
    FormControlLabel,
    FormGroup,
    Modal,
    TextField,
    Typography,
    Button,
    Paper
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { useGetSkills } from '../../../../utils/hooks/api/skills/useGetSkills';
import { ISkillType } from '../../../../components/AchievementSkillsPopup/AchievementSkillsPopup';
import { useCreateRating } from '../../../../utils/hooks/api/ratings/useCreateRating';
import { useCreateProfessionalRating } from '../../../../utils/hooks/api/professional-ratings/useCreateProfessionalRating';
import { useAuth } from '../../../../utils/context/AuthContext';
import { useUpdateRating } from '../../../../utils/hooks/api/ratings/usePatchUnRatings';
import { useQueryClient } from 'react-query';
import { QueryKeys } from '../../../../utils/hooks/api/QueryKey';
import { Responsiveness } from '../../../../theme/overrides';
import { ArrowLeftIcon } from '../../../../assets/icons/ArrowLeftIcon';
import JobfactorAppBar from '../../../../components/JobfactorAppBar';
import MobileNav from '../../../../components/MobileNav/MobileNav';
import { logger } from '../../../../utils/Helper/logger';

interface UpdateSkillCardProps {
    showModal: boolean;
    hideModal: (e?: any) => void;
    rating: any;
}

const UpdateSkills = ({
    showModal,
    hideModal,
    rating
}: UpdateSkillCardProps) => {
    const { user } = useAuth();
    const { isTablet, isMobile } = Responsiveness();
    const [suggestedSkills, setSuggestedSkills] = useState<ISkillType[]>([]);
    const [selectedSkills, setSelectedSkills] = useState<ISkillType[]>([]);
    const [search, setSearch] = useState<string>('');

    const queryClient = useQueryClient();
    const patchRating = useUpdateRating();
    const postSkills = useCreateRating();
    const postProfessionalRating = useCreateProfessionalRating();
    const { data: skills = [], refetch } = useGetSkills(search, 10);
    logger.log(user)

    const routes = [
        {
            label: 'About me',
            route: '/users'
        },
        {
            label: 'Activities',
            route: '/activities'
        },
        {
            label: 'Jobfactor Score',
            route: '/jobfactor-score'
        },
        {
            label: 'Skills',
            route: '/skills'
        }
    ];

    const addSkill = (skill: any) => {
        if (
            !selectedSkills.find((x: ISkillType) => x.name === skill.name) &&
            selectedSkills.length < 10
        ) {
            setSelectedSkills([...selectedSkills, skill]);
        }
    };

    const closeModal = () => {
        setSearch('');
        setSelectedSkills([]);
        hideModal();
    };

    const addSkills = () => {
        let ratings: { rating: number; name: string; type: string }[] = [];
        selectedSkills.map((x) =>
            ratings.push({
                rating: 0,
                name: x.name,
                type: x.type?.name ?? ''
            })
        );
        if (rating?.id) {
            let temp = [...rating?.data, ...ratings];
            selectedSkills.map((x) =>
                temp.push({
                    rating: 0,
                    name: x.name,
                    type: x.type?.name ?? ''
                })
            );
            patchRating.mutate(
                { ratings: temp, rating: 0, id: rating?.id },
                {
                    onSuccess: (res) => {
                        console.log('postSkills', res);
                        queryClient.invalidateQueries(
                            QueryKeys.ProfessionalRating
                        );
                        closeModal();
                    },
                    onError: (err) => {
                        console.log('err', err);
                    }
                }
            );
        } else {
            postSkills.mutate(
                { ratings },
                {
                    onSuccess: (res) => {
                        console.log('postSkills', res);
                        let data = {
                            postId: '',
                            message: 'Added new skills',
                            ratingId: res?.id,
                            professionalProfileId: user?.professionalProfile?.id
                        };
                        postProfessionalRating.mutate(data, {
                            onSuccess: (res) => {
                                closeModal();
                                queryClient.invalidateQueries(
                                    QueryKeys.ProfessionalRating
                                );
                            },
                            onError: (err) => {
                                console.log('ERR', err);
                            }
                        });
                    },
                    onError: (err) => {
                        console.log('err', err);
                    }
                }
            );
        }
    };

    useEffect(() => {
        if (suggestedSkills.length === 0 && showModal) {
            setSuggestedSkills(skills);
        }
    }, [skills, showModal]);

    useEffect(() => {
        if (search.length > 2) {
            refetch();
        }
    }, [search]);

    return (
        <Modal
            open={showModal}
            onClose={() => {
                closeModal();
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: 'fixed',
                    width: isMobile ? '100%' : '90%',
                    minHeight: isMobile ? '100vh' : '600px',
                    maxWidth: '800px',
                    // maxHeight: '600px',
                    top: '50%',
                    left: '50%',
                    transform: `translate(-50%, -50%)`,
                    zIindex: 1,
                    borderRadius: '3px',
                    backgroundColor: '#FFF',
                    overflowY: 'auto',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <Box px={{ sm: '20px', lg: '32px' }} pt={{ xs: 0, sm: '50px' }}>
                    {isMobile ? <JobfactorAppBar /> : null}
                    {isMobile ? (
                        <Box mt="60px">
                            <MobileNav list={routes} />
                        </Box>
                    ) : null}
                    <Box
                        sx={{
                            width: '100%',
                            py: '20px',
                            height: { xs: '70dvh', sm: '60dvh' },
                            overflow: 'auto'
                            // '&::-webkit-scrollbar': {
                            //     display: 'none'
                            // }
                        }}
                    >
                        <Box
                            position={{ xs: 'static', sm: 'fixed' }}
                            top={0}
                            left={0}
                            right={0}
                            bgcolor="#fff"
                            zIndex={4}
                            px={{ xs: '16px', sm: '20px', lg: '32px' }}
                            pt={{ xs: 0, sm: '20px' }}
                            sx={{
                                display: 'flex',
                                justifyContent: isMobile
                                    ? undefined
                                    : 'space-between',
                                alignItems: 'center',
                                gap: '12px',
                                width: '100%',
                                padding: '0px 0px 16px 0px',
                                borderBottom: {
                                    xs: 'none',
                                    sm: '2px solid #D9D9D9'
                                }
                            }}
                        >
                            {isMobile ? (
                                <Box onClick={() => hideModal()}>
                                    <ArrowLeftIcon />
                                </Box>
                            ) : null}
                            <Box>
                                <Typography
                                    variant="h3"
                                    sx={{
                                        color: '#23282B',
                                        fontFamily: 'Open Sans',
                                        fontSize: '20px',
                                        fontWeight: '600'
                                    }}
                                >
                                    Update Skills
                                </Typography>
                            </Box>
                            {isMobile ? null : (
                                <Box>
                                    <div
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            hideModal();
                                        }}
                                    >
                                        <ClearIcon />
                                    </div>
                                </Box>
                            )}
                        </Box>
                        <Box
                            display={{ xs: 'block', sm: 'none' }}
                            height="8px"
                            width="100%"
                            bgcolor="#f2f2f2"
                        ></Box>

                        <Box>
                            <Box
                                px={{ xs: '16px', sm: 0 }}
                                sx={{
                                    display: 'inline-flex',
                                    alignItems: 'flex-start',
                                    gap: '8px',
                                    background: '#FFF',
                                    width: '100%',
                                    mb: '10px',
                                    mt: '10px'
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    sx={{
                                        color: '#808080',
                                        fontFamily: 'Open Sans',
                                        fontSize: '14px',
                                        fontWeight: '600'
                                    }}
                                >
                                    * Be sure to add skills that others
                                    witnessed you demonstrate. Maximum of 10
                                    skills.
                                </Typography>
                            </Box>

                            <Box px={{ xs: '16px', sm: 0 }}>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        color: '#23282B',
                                        fontFamily: 'Open Sans',
                                        fontSize: '14px',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        lineHeight: '20px',
                                        letterSpacing: '0.049px',
                                        mb: '8px'
                                    }}
                                >
                                    Skills *
                                </Typography>
                                <Box>
                                    <TextField
                                        placeholder="Search Skills"
                                        value={search}
                                        onChange={(e) =>
                                            setSearch(e.target.value)
                                        }
                                        variant="outlined"
                                        fullWidth
                                        style={{ width: '100%' }}
                                        sx={{
                                            borderRadius: '8px',
                                            border: '1px solid #C5C5C5',
                                            background: '#f2f2f2',
                                            mb: '12px'
                                        }}
                                        InputProps={{
                                            sx: {
                                                height: '40px'
                                            },
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Box>

                                {skills.length > 0 && search.length > 2 ? (
                                    <Paper
                                        sx={{
                                            maxHeight: '200px',
                                            overflow: 'auto',
                                            position: 'absolute',
                                            left: 20,
                                            right: 20,
                                            zIndex: 999,
                                            backgroundColor: '#FFFAF1'
                                        }}
                                    >
                                        {skills.map((skill: ISkillType) => (
                                            <Box
                                                key={skill.name}
                                                sx={{
                                                    pl: '10px',
                                                    cursor: 'pointer',
                                                    borderRadius: '8px',
                                                    '& :hover': {
                                                        background: '#D9D9D9'
                                                    }
                                                }}
                                            >
                                                <Typography
                                                    variant="titleMediumSemiBold"
                                                    sx={{
                                                        width: '100%',
                                                        display: 'block',
                                                        p: '5px'
                                                    }}
                                                    onClick={() => {
                                                        addSkill(skill);
                                                        setSearch('');
                                                    }}
                                                >
                                                    {skill.name}
                                                </Typography>
                                            </Box>
                                        ))}
                                    </Paper>
                                ) : null}
                            </Box>
                            <Box pb="12px">
                                <Box
                                    sx={{
                                        display: 'flex',
                                        width: isMobile ? '100%' : undefined,
                                        // maxWidth: '754px',
                                        padding: {
                                            xs: '8px 12px',
                                            sm: '16px 20px'
                                        },
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        gap: '12px',
                                        borderRadius: isMobile
                                            ? undefined
                                            : '8px',
                                        border: {
                                            xs: 'none',
                                            sm: '1px solid #C5C5C5'
                                        },
                                        background: {
                                            xs: '#f2f2f2',
                                            sm: '#fafafa'
                                        },
                                        mb: { xs: 0, sm: '24px' }
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            padding: '8px 0px',
                                            alignItems: 'flex-start',
                                            gap: '10px',
                                            alignSelf: 'stretch',
                                            color: '#5B5B5B'
                                        }}
                                    >
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                color: '#5B5B5B',
                                                fontFamily: 'Open Sans',
                                                fontSize: '16px',
                                                fontWeight: '600'
                                            }}
                                        >
                                            Suggestion is based on your profile
                                        </Typography>
                                    </Box>

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            alignContent: 'center',
                                            gap: { xs: '8px', sm: '12px' },
                                            alignSelf: 'stretch',
                                            flexWrap: 'wrap'
                                        }}
                                    >
                                        {suggestedSkills.map(
                                            (item: ISkillType) => (
                                                <Chip
                                                    key={item.name}
                                                    label={item.name}
                                                    onClick={() =>
                                                        addSkill(item)
                                                    }
                                                    sx={{
                                                        color: '#808080',
                                                        fontFamily: 'Open Sans',
                                                        fontSize: '15px',
                                                        fontWeight: '500',
                                                        display: 'flex',
                                                        padding: '4px 10px',
                                                        gap: '10px',
                                                        borderRadius: '100px',
                                                        backgroundColor: {
                                                            xs: 'transparent',
                                                            sm: '#fafafa'
                                                        },
                                                        border: '1px solid #C5C5C5'
                                                    }}
                                                />
                                            )
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                            <Box
                                display={{ xs: 'block', sm: 'none' }}
                                height="8px"
                                width="100%"
                                bgcolor="#f2f2f2"
                            ></Box>
                            {selectedSkills?.length > 0 ? (
                                <>
                                    <Box pt="12px" pb="12px">
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                width: isMobile
                                                    ? '100%'
                                                    : undefined,
                                                maxWidth: '754px',
                                                padding: {
                                                    xs: '8px 16px',
                                                    sm: '8px 20px 20px 20px'
                                                },
                                                flexDirection: 'column',
                                                alignItems: 'flex-start',
                                                gap: '12px',
                                                borderRadius: isMobile
                                                    ? undefined
                                                    : '8px',
                                                border: {
                                                    xs: 'none',
                                                    sm: '1px solid #C5C5C5'
                                                },
                                                background: '#f2f2f2'
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    padding: '8px 0px',
                                                    alignItems: 'flex-start',
                                                    gap: '10px',
                                                    alignSelf: 'stretch',
                                                    color: '#363636'
                                                }}
                                            >
                                                <Typography
                                                    variant="h6"
                                                    sx={{
                                                        color: '#363636',
                                                        fontFamily: 'Open Sans',
                                                        fontSize: '16px',
                                                        fontWeight: '600'
                                                    }}
                                                >
                                                    Selected skills
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    alignContent: 'center',
                                                    gap: '12px',
                                                    alignSelf: 'stretch',
                                                    flexWrap: 'wrap'
                                                }}
                                            >
                                                {selectedSkills.map(
                                                    (
                                                        item: ISkillType,
                                                        index: number
                                                    ) => (
                                                        <Chip
                                                            key={`chip_${index}`}
                                                            label={item?.name}
                                                            onDelete={() => {
                                                                setSelectedSkills(
                                                                    selectedSkills.filter(
                                                                        (
                                                                            x: ISkillType
                                                                        ) =>
                                                                            x.id !==
                                                                            item?.id
                                                                    )
                                                                );
                                                            }}
                                                            sx={{
                                                                display: 'flex',
                                                                padding:
                                                                    '4px 10px',
                                                                alignItems:
                                                                    'center',
                                                                gap: '8px',
                                                                borderRadius:
                                                                    '100px',
                                                                border: '1px solid #AAA',
                                                                background:
                                                                    '#E6F0F4',
                                                                color: '#23282B',
                                                                fontFamily:
                                                                    'Open Sans',
                                                                fontSize:
                                                                    '16px',
                                                                fontStyle:
                                                                    'normal',
                                                                fontWeight:
                                                                    '600',
                                                                lineHeight:
                                                                    '24px',
                                                                letterSpacing:
                                                                    '0.08px'
                                                            }}
                                                        />
                                                    )
                                                )}
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box
                                        display={{ xs: 'block', sm: 'none' }}
                                        height="8px"
                                        width="100%"
                                        bgcolor="#f2f2f2"
                                    ></Box>
                                </>
                            ) : null}

                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    gap: '20px',
                                    alignSelf: 'stretch',
                                    mt: '56px',
                                    px: { xs: '20px', sm: 0 },
                                    pb: { xs: 0, sm: '50px' }
                                }}
                            >
                                <Box
                                    sx={{
                                        width: '320px',
                                        height: '52px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignitems: 'flex-start',
                                        gap: '8px'
                                    }}
                                >
                                    <Typography
                                        variant="h5"
                                        sx={{
                                            color: '#23282B',
                                            fontFamily: 'Open Sans',
                                            fontSize: '16px',
                                            fontWeight: '600'
                                        }}
                                    >
                                        Tell us where you put this skill to use
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            color: '#808080',
                                            fontFamily: 'Open Sans',
                                            fontSize: '14px',
                                            fontWeight: '400',
                                            lineHeight: '20px',
                                            letterSpacing: '0.014px'
                                        }}
                                    >
                                        Select any where this skill applies
                                    </Typography>
                                </Box>

                                <Box
                                    sx={{
                                        width: '300px',
                                        height: '100px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignitems: 'flex-start',
                                        gap: '8px'
                                    }}
                                >
                                    <Typography
                                        variant="h5"
                                        sx={{
                                            color: '#000',
                                            fontFamily: 'Open Sans',
                                            fontSize: '14px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: '20px',
                                            letterSpacing: '0.014px'
                                        }}
                                    >
                                        Work experience
                                    </Typography>
                                    <FormGroup>
                                        {user?.professionalProfile?.employments.map(
                                            (job) => (
                                                <FormControlLabel
                                                    control={<Checkbox />}
                                                    label={`${job.jobTitle} ${job.companyName && 'at ' + job.companyName} `}
                                                />
                                            )
                                        )}
                                    </FormGroup>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        py: '20px',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        gap: '8px',
                        background: '#f2f2f2',
                        mt: '2rem',
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        right: 0
                    }}
                    px={{ xs: '16px', sm: '20px', lg: '32px' }}
                >
                    <Button
                        sx={{
                            display: 'flex',
                            height: '48px',
                            alignItems: 'flex-start',
                            alignSelf: 'stretch',
                            background:
                                selectedSkills?.length > 0
                                    ? '#05668D'
                                    : '#808080',
                            color: '#FFFFFF',
                            textDecoration: 'none'
                        }}
                        disabled={selectedSkills?.length === 0}
                        onClick={addSkills}
                    >
                        Save
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default UpdateSkills;
