import {
    Box,
    InputLabel,
    InputBase,
    Typography,
    IconButton,
    Button,
    Stack
} from '@mui/material';
import Header from './Header';
import UserIcon3 from '../../../assets/icons/UserIcon3';
import BriefCaseIcon from '../../../assets/icons/BriefCaseIcon';
import MoneyIcon from '../../../assets/icons/MoneyIcon';
import CloseIcon from '@mui/icons-material/Close';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import React, { Fragment, useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useAuth } from '../../../utils/context/AuthContext';
import { useEffectOnce } from 'usehooks-ts';
import axiosInstance from '../../../utils/hooks/api/axiosConfig';
import {
    ICreateJobPreferences,
    IJobPreferences,
    IUpdateJobPreferences,
    employmentTypeList,
    payDurationList,
    locationTypeList
} from './types';
import SnackAlert, { SnackAlertType } from '../../../components/Snackbar';
import { Responsiveness } from '../../../theme/overrides';
import MobileNav from '../../../components/MobileNav/MobileNav';
import KeyboardArrowDown from '../../../assets/icons/KeyboardArrowDown';

interface IAlert {
    isOpen: boolean;
    type: SnackAlertType;
    message: string;
}

const JobPreference = () => {
    const { user } = useAuth();
    const {isTablet, isMobile} = Responsiveness()
    const [employmentTypes, setEmploymentTypes] = useState<string[]>([]);
    const [locationTypes, setLocationTypes] = useState<string>('');
    const [payDuration, setPayDuration] = useState<string>('');
    const [showtypes, setShowTypes] = useState<boolean>(false);
    const [showLocationTypes, setShowLocationTypes] = useState<boolean>(false);
    const [showduration, setShowDuration] = useState<boolean>(false);
    const [title, setTitle] = useState<string>('');
    const [salary, setSalary] = useState<string>('');
    const [jobPreferencesId, setJobPreferencesId] = useState<string | null>(
        null
    );
    const [alert, setAlert] = useState<IAlert>({
        isOpen: false,
        type: 'info',
        message: ''
    });

    const routes = [
        {
            label: 'All Jobs',
            route: '/my-jobs'
        },
        {
            label: 'Saved Jobs',
            route: '/save-job'
        },
        {
            label: 'Job Preferences',
            route: '/job-preference'
        },
        {
            label: 'Applied Jobs',
            route: '/job-applied'
        }
    ];

    useEffectOnce(() => {
        const fetchData = async () => {
            try {
                const res = await axiosInstance.get(
                    `/api/job-preference/professional-profiles/${user?.professionalProfile?.id}`
                );
                if (!res.data) return;
                const { id, jobTitle, jobType, minimumSalary, payDuration } =
                    res.data as IJobPreferences;
                setJobPreferencesId(id);
                if (jobTitle) setTitle(jobTitle);
                if (jobType) setEmploymentTypes(jobType);
                if (minimumSalary) setSalary(minimumSalary);
                if (payDuration) setPayDuration(payDuration);
            } catch (err) {}
        };
        fetchData();
    });

    const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let check = event.target.checked;
        if (check === true) {
            setEmploymentTypes([...employmentTypes, event.target.name]);
        } else {
            let filter = employmentTypes?.filter(
                (x) => x !== event.target.name
            );
            if (filter) {
                setEmploymentTypes(filter);
            }
        }
    };

    const handleDurationChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setPayDuration(event.target.value);
        setShowDuration(false);
    };
    const handleLocationChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setLocationTypes(event.target.value);
        setShowLocationTypes(false)
    };

    const handleSubmit = async () => {
        const body: IUpdateJobPreferences = {
            jobTitle: title !== '' ? title : undefined,
            jobType: employmentTypes.length ? employmentTypes : undefined,
            minimumSalary: salary !== '' ? salary : undefined,
            payDuration: payDuration !== '' ? payDuration : undefined,
            locationTypes: locationTypes !== '' ? locationTypes : undefined
        };
        try {
            console.info(body);
            if (jobPreferencesId) {
                await axiosInstance.patch(
                    `/api/job-preference/${jobPreferencesId}`,
                    body as IUpdateJobPreferences
                );
            } else {
                await axiosInstance.post(`/api/job-preference`, {
                    professionalProfileId: user?.professionalProfile?.id,
                    ...body
                } as ICreateJobPreferences);
            }
            setAlert({
                isOpen: true,
                type: 'success',
                message: 'Job preferences updated.'
            });
        } catch (err) {
            console.error(err);
            setAlert({
                isOpen: true,
                type: 'error',
                message: 'Error occurred. Please try again.'
            });
        }
    };

    return (
        <Box
            sx={{
                marginTop: isMobile ? '8px' : 0,
                // width: '80%'
                pr: { sm: '20px', lg: '40px' }
            }}
        >
            {isMobile ? <MobileNav list={routes} /> : null}
            <Box mt={{ xs: '8px', sm: 0 }}>
                <Header handleSubmit={handleSubmit} />
            </Box>
            {isMobile ? null : isTablet ? <MobileNav list={routes} /> : null}
            <Box
                sx={{
                    padding: isMobile ? '16px' : '20px',
                    backgroundColor: '#fff',
                    marginTop: isMobile ? '8px' : '20px'
                }}
            >
                <Stack spacing={2} alignItems="center">
                    {/* Job Title */}
                    <Box
                        sx={{
                            position: 'relative',
                            backgroundColor: '#f2f2f2',
                            width: '100%'
                        }}
                    >
                        <InputLabel
                            sx={{
                                color: '#808080',
                                fontSize: '14px',
                                position: 'absolute',
                                top: '8px',
                                left: '75px',
                                zIndex: 1,
                                fontFamily: 'Open Sans'
                            }}
                            htmlFor="text"
                        >
                            Job Title
                        </InputLabel>
                        <InputBase
                            key="jobtitle"
                            onChange={({ target }) => {
                                setTitle(target.value);
                            }}
                            inputMode="text"
                            id="jobTitle"
                            value={title}
                            placeholder="Preferred job title"
                            startAdornment={
                                <>
                                    <UserIcon3 />
                                    <Typography
                                        sx={{
                                            margin: '0 10px',
                                            color: '#D9D9D9'
                                        }}
                                    >
                                        |
                                    </Typography>
                                </>
                            }
                            // endAdornment={
                            //     <IconButton
                            //         onClick={() => {
                            //             setTitle('');
                            //         }}
                            //     >
                            //         <CloseIcon
                            //             style={{
                            //                 color: '#494949',
                            //                 cursor: 'pointer'
                            //             }}
                            //         />
                            //     </IconButton>
                            // }
                            rows={1}
                            sx={{
                                width: '100%',
                                height: '70px',
                                padding: '0px 16px',
                                fontFamily: 'open sans',
                                color: '#23282B',
                                borderBottom: '1px solid #D9D9D9',
                                '& 	.MuiInputBase-input': {
                                    ml: '10px',
                                    position: 'relative',
                                    top: '8px'
                                }
                            }}
                        />
                    </Box>
                    {/* Employment Types */}
                    <Box
                        sx={{
                            position: 'relative',

                            width: '100%'
                        }}
                    >
                        <Box sx={{ backgroundColor: '#f2f2f2' }}>
                            <InputLabel
                                sx={{
                                    color: '#808080',
                                    fontSize: '14px',
                                    position: 'absolute',
                                    top: '8px',
                                    left: '75px',
                                    zIndex: 1,
                                    fontFamily: 'Open Sans'
                                }}
                                htmlFor="text"
                            >
                                Employment Type
                            </InputLabel>
                            <InputBase
                                key="jobtype"
                                inputMode="text"
                                id="jobtype"
                                placeholder="Select employment type(s)"
                                value={
                                    '' +
                                    employmentTypes
                                        .map((k) => employmentTypeList[k])
                                        .join(', ')
                                }
                                disabled={true}
                                startAdornment={
                                    <>
                                        <BriefCaseIcon
                                            tab
                                            isHover={false}
                                            isSelected={false}
                                        />
                                        <Typography
                                            sx={{
                                                margin: '0 10px',
                                                color: '#D9D9D9'
                                            }}
                                        >
                                            |
                                        </Typography>
                                    </>
                                }
                                endAdornment={
                                    <IconButton
                                        onClick={() => {
                                            setShowTypes(!showtypes);
                                            setShowDuration(false);
                                        }}
                                    >
                                        {showtypes ? (
                                            <Box sx={{transform: 'rotate(90deg)'}}><KeyboardArrowDown />                                                
                                            </Box>
                                        ) : (
                                            <KeyboardArrowDown />
                                        )}
                                    </IconButton>
                                }
                                rows={1}
                                sx={{
                                    width: '100%',
                                    height: '70px',
                                    padding: '0px 16px',
                                    fontFamily: 'open sans',
                                    color: '#23282B',
                                    borderBottom: '1px solid #D9D9D9',
                                    '& 	.MuiInputBase-input': {
                                        ml: '10px',
                                        position: 'relative',
                                        top: '8px'
                                    }
                                }}
                            />
                        </Box>
                        {showtypes && (
                            <Box
                                sx={{
                                    backgroundColor: '#FCFBF8',
                                    px: '10px',
                                    width: '100%',
                                    zIndex: 500,
                                    position: isMobile ? undefined : 'absolute',
                                    border: '1px solid lightgray',
                                    borderTop: 'none'
                                }}
                            >
                                {Object.keys(employmentTypeList).map(
                                    (item: string, index: number, arr) => {
                                        return (
                                            <Box
                                                key={index}
                                                sx={{
                                                    width: '100%',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    borderBottom:
                                                        index + 1 === arr.length
                                                            ? 'none'
                                                            : '1px solid #D9D9D9',
                                                    padding: '5px 0',
                                                    paddingTop: '5px',
                                                    color: '#808080'
                                                }}
                                            >
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={employmentTypes.includes(
                                                                item
                                                            )}
                                                            onChange={(e) => {
                                                                handleTypeChange(
                                                                    e
                                                                );
                                                            }}
                                                            name={item}
                                                        />
                                                    }
                                                    label={
                                                        employmentTypeList[item]
                                                    }
                                                    sx={{
                                                        margin: '0px'
                                                    }}
                                                />
                                            </Box>
                                        );
                                    }
                                )}
                            </Box>
                        )}
                    </Box>
                    <Box
                        sx={{
                            position: 'relative',
                            width: '100%'
                        }}
                    >
                        <Box
                            sx={{
                                backgroundColor: '#f2f2f2'
                            }}
                        >
                            <InputLabel
                                sx={{
                                    color: '#808080',
                                    fontSize: '14px',
                                    position: 'absolute',
                                    top: '8px',
                                    left: '75px',
                                    zIndex: 1,
                                    fontFamily: 'Open Sans'
                                }}
                                htmlFor="text"
                            >
                                Location type
                            </InputLabel>
                            <InputBase
                                key="duration"
                                id="duration"
                                value={locationTypeList[locationTypes] ?? ''}
                                disabled={true}
                                placeholder="Please select"
                                startAdornment={
                                    <>
                                        <BriefCaseIcon
                                            tab
                                            isHover={false}
                                            isSelected={false}
                                        />
                                        <Typography
                                            sx={{
                                                margin: '0 10px',
                                                color: '#D9D9D9'
                                            }}
                                        >
                                            |
                                        </Typography>
                                    </>
                                }
                                endAdornment={
                                    <IconButton
                                        onClick={() => {
                                            setShowLocationTypes(
                                                !showLocationTypes
                                            );
                                            setShowTypes(false);
                                        }}
                                    >
                                        {showduration ? (
                                            <Box sx={{transform: 'rotate(90deg)'}}><KeyboardArrowDown />                                                
                                            </Box>
                                        ) : (
                                            <KeyboardArrowDown />
                                        )}
                                    </IconButton>
                                }
                                rows={1}
                                sx={{
                                    width: '100%',
                                    height: '70px',
                                    padding: '0px 16px',
                                    fontFamily: 'open sans',
                                    color: '#23282B',
                                    borderBottom: '1px solid #D9D9D9',
                                    '& 	.MuiInputBase-input': {
                                        ml: '10px',
                                        position: 'relative',
                                        top: '8px'
                                    }
                                }}
                            />
                        </Box>
                        {showLocationTypes && (
                            <Box
                                sx={{
                                    backgroundColor: '#FCFBF8',
                                    px: '10px',
                                    width: '100%',
                                    zIndex: 500,
                                    position: isMobile ? undefined : 'absolute',
                                    border: '1px solid lightgray',
                                    borderTop: 'none'
                                }}
                            >
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    value={locationTypes}
                                    onChange={(e) => {
                                        handleLocationChange(e);
                                    }}
                                    name="radio-buttons-group"
                                >
                                    {Object.keys(locationTypeList).map(
                                        (item: string, index: number, arr) => {
                                            return (
                                                <Box
                                                    key={index}
                                                    sx={{
                                                        width: '100%',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        borderBottom:
                                                            index + 1 ===
                                                            arr.length
                                                                ? 'none'
                                                                : '1px solid #D9D9D9',
                                                        padding: '5px 0',
                                                        paddingTop: '5px',
                                                        color: '#808080'
                                                    }}
                                                >
                                                    <FormControlLabel
                                                        control={
                                                            <Radio
                                                                value={item}
                                                                name={item}
                                                            />
                                                        }
                                                        label={''}
                                                        sx={{
                                                            margin: '0px'
                                                        }}
                                                    />
                                                    <Typography
                                                        sx={{
                                                            marginLeft: '5px',
                                                            fontSize: '16px',
                                                            color: '#D9D9D9'
                                                        }}
                                                    >
                                                        |
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            marginLeft: '15px',
                                                            fontSize: '16px',
                                                            color: '#808080'
                                                        }}
                                                    >
                                                        {locationTypeList[item]}
                                                    </Typography>
                                                </Box>
                                            );
                                        }
                                    )}
                                </RadioGroup>
                            </Box>
                        )}
                    </Box>
                    {/* Minimum Salary */}
                    <Box
                        sx={{
                            position: 'relative',
                            backgroundColor: '#f2f2f2',
                            width: '100%'
                        }}
                    >
                        <InputLabel
                            sx={{
                                color: '#808080',
                                fontSize: '14px',
                                position: 'absolute',
                                top: '8px',
                                left: '75px',
                                zIndex: 1,
                                fontFamily: 'Open Sans'
                            }}
                            htmlFor="text"
                        >
                            Minimum Salary
                        </InputLabel>
                        <InputBase
                            key="salary"
                            onChange={({ target }) => {
                                setSalary(target.value);
                            }}
                            inputMode="numeric"
                            value={salary}
                            id="salary"
                            placeholder="Preferred minimum salary"
                            startAdornment={
                                <>
                                    <MoneyIcon />
                                    <Typography
                                        sx={{
                                            margin: '0 10px',
                                            color: '#D9D9D9'
                                        }}
                                    >
                                        |
                                    </Typography>
                                </>
                            }
                            // endAdornment={
                            //     <IconButton
                            //         onClick={() => {
                            //             setSalary('');
                            //         }}
                            //     >
                            //         <CloseIcon
                            //             style={{
                            //                 color: '#494949',
                            //                 cursor: 'pointer'
                            //             }}
                            //         />
                            //     </IconButton>
                            // }
                            rows={1}
                            sx={{
                                width: '100%',
                                height: '70px',
                                padding: '0px 16px',
                                fontFamily: 'open sans',
                                color: '#23282B',
                                borderBottom: '1px solid #D9D9D9',
                                '& 	.MuiInputBase-input': {
                                    ml: '10px',
                                    position: 'relative',
                                    top: '8px'
                                }
                            }}
                        />
                    </Box>
                    {/* Pay Duration */}
                    <Box
                        sx={{
                            position: 'relative',
                            width: '100%'
                        }}
                    >
                        <Box
                            sx={{
                                backgroundColor: '#f2f2f2'
                            }}
                        >
                            <InputLabel
                                sx={{
                                    color: '#808080',
                                    fontSize: '14px',
                                    position: 'absolute',
                                    top: '8px',
                                    left: '75px',
                                    zIndex: 1,
                                    fontFamily: 'Open Sans'
                                }}
                                htmlFor="text"
                            >
                                Pay Duration
                            </InputLabel>
                            <InputBase
                                key="duration"
                                id="duration"
                                value={payDurationList[payDuration] ?? ''}
                                disabled={true}
                                placeholder="Select pay duration"
                                startAdornment={
                                    <>
                                        <BriefCaseIcon
                                            tab
                                            isHover={false}
                                            isSelected={false}
                                        />
                                        <Typography
                                            sx={{
                                                margin: '0 10px',
                                                color: '#D9D9D9'
                                            }}
                                        >
                                            |
                                        </Typography>
                                    </>
                                }
                                endAdornment={
                                    <IconButton
                                        onClick={() => {
                                            setShowDuration(!showduration);
                                            setShowTypes(false);
                                        }}
                                    >
                                        {showduration ? (
                                            <Box sx={{transform: 'rotate(90deg)'}}><KeyboardArrowDown />                                                
                                            </Box>
                                        ) : (
                                            <KeyboardArrowDown />
                                        )}
                                    </IconButton>
                                }
                                rows={1}
                                sx={{
                                    width: '100%',
                                    height: '70px',
                                    padding: '0px 16px',
                                    fontFamily: 'open sans',
                                    color: '#23282B',
                                    borderBottom: '1px solid #D9D9D9',
                                    '& 	.MuiInputBase-input': {
                                        ml: '10px',
                                        position: 'relative',
                                        top: '8px'
                                    }
                                }}
                            />
                        </Box>
                        {showduration && (
                            <Box
                                sx={{
                                    backgroundColor: '#FCFBF8',
                                    px: '10px',
                                    width: '100%',
                                    zIndex: 500,
                                    position: isMobile ? undefined : 'absolute',
                                    border: '1px solid lightgray',
                                    borderTop: 'none'
                                }}
                            >
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    value={payDuration}
                                    onChange={(e) => {
                                        handleDurationChange(e);
                                    }}
                                    name="radio-buttons-group"
                                >
                                    {Object.keys(payDurationList).map(
                                        (item: string, index: number, arr) => {
                                            return (
                                                <Box
                                                    key={index}
                                                    sx={{
                                                        width: '100%',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        borderBottom:
                                                            index + 1 ===
                                                            arr.length
                                                                ? 'none'
                                                                : '1px solid #D9D9D9',
                                                        padding: '5px 0',
                                                        paddingTop: '5px',
                                                        color: '#808080'
                                                    }}
                                                >
                                                    <FormControlLabel
                                                        control={
                                                            <Radio
                                                                value={item}
                                                                name={item}
                                                            />
                                                        }
                                                        label={''}
                                                        sx={{
                                                            margin: '0px'
                                                        }}
                                                    />
                                                    <Typography
                                                        sx={{
                                                            marginLeft: '5px',
                                                            fontSize: '16px',
                                                            color: '#D9D9D9'
                                                        }}
                                                    >
                                                        |
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            marginLeft: '15px',
                                                            fontSize: '16px',
                                                            color: '#808080'
                                                        }}
                                                    >
                                                        {payDurationList[item]}
                                                    </Typography>
                                                </Box>
                                            );
                                        }
                                    )}
                                </RadioGroup>
                            </Box>
                        )}
                    </Box>
                    <Button
                        variant="contained"
                        sx={{
                            display: { xs: 'block', lg: 'none' },
                            color: '#FFFFFF',
                            width: isTablet ? '100%' : '150px'
                        }}
                        onClick={handleSubmit}
                    >
                        <Typography
                            fontWeight={600}
                            fontSize={14}
                            sx={{
                                textTransform: 'none',
                                py: 0.75
                            }}
                        >
                            Save
                        </Typography>
                    </Button>
                </Stack>
                <SnackAlert
                    open={alert.isOpen}
                    handleClose={() =>
                        setAlert((curr) => ({ ...curr, isOpen: false }))
                    }
                    type={alert.type}
                    message={alert.message}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                />
            </Box>
        </Box>
    );
};

export default JobPreference;
