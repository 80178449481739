import { UseMutationResult, useMutation } from 'react-query';
import axiosInstance from '../axiosConfig';

async function createCompanyReviewRequest(postRequest: unknown) {
    const response = await axiosInstance.post(`/api/company-reviews-requests`, postRequest);
    const data = await response.data;
    return data;
}
export function useCreateCompanyReviewRequest(): UseMutationResult<
    unknown,
    unknown,
    unknown
> {
    return useMutation(createCompanyReviewRequest);
}