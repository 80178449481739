import React from 'react';
import { Box } from '@mui/material';

function BriefCaseIcon(props: { isHover: boolean; isSelected: boolean, tab?: boolean }) {
    const { isHover, isSelected, tab } = props;
    if (isHover || isSelected) {
        return (
            <>
                <Box display={{ xs: 'none', lg: 'block' }}>
                    <svg
                        width="20"
                        height="22"
                        viewBox="0 0 20 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M19.091 5.97953C18.241 5.03953 16.821 4.56953 14.761 4.56953H14.521V4.52953C14.521 2.84953 14.521 0.769531 10.761 0.769531H9.24101C5.48101 0.769531 5.48101 2.85953 5.48101 4.52953V4.57953H5.24101C3.17101 4.57953 1.76101 5.04953 0.911014 5.98953C-0.0789859 7.08953 -0.0489861 8.56953 0.0510139 9.57953L0.0610139 9.64953L0.138469 10.4628C0.152734 10.6126 0.233397 10.7479 0.359289 10.8303C0.599094 10.9872 1.00044 11.2459 1.24101 11.3795C1.38101 11.4695 1.53101 11.5495 1.68101 11.6295C3.39101 12.5695 5.27101 13.1995 7.18101 13.5095C7.27101 14.4495 7.68101 15.5495 9.87101 15.5495C12.061 15.5495 12.491 14.4595 12.561 13.4895C14.601 13.1595 16.571 12.4495 18.351 11.4095C18.411 11.3795 18.451 11.3495 18.501 11.3195C18.8977 11.0953 19.3093 10.819 19.6845 10.5484C19.7975 10.4668 19.8698 10.3408 19.8852 10.2023L19.901 10.0595L19.951 9.58953C19.961 9.52953 19.961 9.47953 19.971 9.40953C20.051 8.39953 20.031 7.01953 19.091 5.97953ZM11.091 12.8295C11.091 13.8895 11.091 14.0495 9.86101 14.0495C8.63101 14.0495 8.63101 13.8595 8.63101 12.8395V11.5795H11.091V12.8295ZM6.91101 4.56953V4.52953C6.91101 2.82953 6.91101 2.19953 9.24101 2.19953H10.761C13.091 2.19953 13.091 2.83953 13.091 4.52953V4.57953H6.91101V4.56953Z"
                            fill="#05668D"
                        />
                        <path
                            d="M18.8733 12.7349C19.2269 12.5666 19.6342 12.8469 19.5988 13.2369L19.2398 17.1907C19.0298 19.1907 18.2098 21.2307 13.8098 21.2307H6.18984C1.78984 21.2307 0.969844 19.1907 0.759844 17.2007L0.419132 13.4529C0.38409 13.0674 0.782046 12.7874 1.13468 12.947C2.2741 13.4625 4.37724 14.3771 5.67641 14.7174C5.84072 14.7604 5.97361 14.878 6.04556 15.0319C6.65253 16.33 7.96896 17.0207 9.86984 17.0207C11.752 17.0207 13.085 16.3034 13.694 15.0021C13.766 14.8481 13.8991 14.7305 14.0635 14.6873C15.443 14.3243 17.6816 13.3019 18.8733 12.7349Z"
                            fill="#05668D"
                        />
                    </svg>
                </Box>
                <Box display={{ xs: 'block', lg: 'none' }}>
                    <svg
                        width={tab ? '24' : '40'}
                        height={tab ? '24' : '40'}
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M35.1497 11.6333C33.7331 10.0667 31.3664 9.28335 27.9331 9.28335H27.5331V9.21668C27.5331 6.41668 27.5331 2.95001 21.2664 2.95001H18.7331C12.4664 2.95001 12.4664 6.43335 12.4664 9.21668V9.30001H12.0664C8.6164 9.30001 6.2664 10.0833 4.84974 11.65C3.19974 13.4833 3.24974 15.95 3.4164 17.6333L3.43307 17.75L3.57719 19.2633C3.59146 19.4131 3.67233 19.5484 3.79801 19.6311C4.16424 19.8722 4.95031 20.3837 5.39974 20.6333C5.63307 20.7833 5.88307 20.9167 6.13307 21.05C8.98307 22.6167 12.1164 23.6667 15.2997 24.1833C15.4497 25.75 16.1331 27.5833 19.7831 27.5833C23.4331 27.5833 24.1497 25.7667 24.2664 24.15C27.6664 23.6 30.9497 22.4167 33.9164 20.6833C34.0164 20.6333 34.0831 20.5833 34.1664 20.5333C34.8695 20.136 35.6005 19.6405 36.2568 19.1626C36.3695 19.0805 36.4418 18.9546 36.4572 18.8161L36.4997 18.4333L36.5831 17.65C36.5997 17.55 36.5997 17.4667 36.6164 17.35C36.7497 15.6667 36.7164 13.3667 35.1497 11.6333ZM21.8164 23.05C21.8164 24.8167 21.8164 25.0833 19.7664 25.0833C17.7164 25.0833 17.7164 24.7667 17.7164 23.0667V20.9667H21.8164V23.05ZM14.8497 9.28335V9.21668C14.8497 6.38335 14.8497 5.33335 18.7331 5.33335H21.2664C25.1497 5.33335 25.1497 6.40001 25.1497 9.21668V9.30001H14.8497V9.28335Z"
                            fill="#05668D"
                        />
                        <path
                            d="M35.3304 22.6311C35.683 22.4609 36.0881 22.7416 36.0527 23.1316L35.4004 30.3167C35.0504 33.65 33.6837 37.05 26.3504 37.05H13.6504C6.31706 37.05 4.95039 33.65 4.60039 30.3334L3.97968 23.5055C3.94464 23.1201 4.3417 22.8403 4.69351 23.0017C6.43117 23.7987 10.6071 25.6583 12.969 26.2387C13.1339 26.2793 13.2685 26.3968 13.3356 26.5529C14.3114 28.8233 16.5356 30.0334 19.7837 30.0334C22.9996 30.0334 25.2537 28.7768 26.2317 26.5031C26.2989 26.3469 26.4332 26.2295 26.5983 26.1889C29.1015 25.5728 33.5234 23.5032 35.3304 22.6311Z"
                            fill="#05668D"
                        />
                    </svg>
                </Box>
            </>
        );
    }
    return (
        <>
            <Box display={{ xs: 'none', lg: 'block' }}>
                <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill={'none'}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M6.66734 18.3333H13.334C16.684 18.3333 17.284 16.9917 17.459 15.3583L18.084 8.69167C18.309 6.65833 17.7257 5 14.1673 5H5.834C2.27567 5 1.69234 6.65833 1.91734 8.69167L2.54234 15.3583C2.71734 16.9917 3.31734 18.3333 6.66734 18.3333Z"
                        stroke={'#808080'}
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M6.66797 4.99935V4.33268C6.66797 2.85768 6.66797 1.66602 9.33464 1.66602H10.668C13.3346 1.66602 13.3346 2.85768 13.3346 4.33268V4.99935"
                        stroke="#808080"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M11.6654 10.8333V11.6667C11.6654 11.675 11.6654 11.675 11.6654 11.6833C11.6654 12.5917 11.657 13.3333 9.9987 13.3333C8.3487 13.3333 8.33203 12.6 8.33203 11.6917V10.8333C8.33203 10 8.33203 10 9.16536 10H10.832C11.6654 10 11.6654 10 11.6654 10.8333Z"
                        stroke="#808080"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M18.043 9.16602C16.118 10.566 13.918 11.3993 11.668 11.6827"
                        stroke="#808080"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M2.18359 9.39258C4.05859 10.6759 6.17526 11.4509 8.33359 11.6926"
                        stroke="#808080"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </Box>
            <Box display={{ xs: 'block', lg: 'none' }}>
                {tab ? (
                    <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill={'none'}
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M6.66734 18.3333H13.334C16.684 18.3333 17.284 16.9917 17.459 15.3583L18.084 8.69167C18.309 6.65833 17.7257 5 14.1673 5H5.834C2.27567 5 1.69234 6.65833 1.91734 8.69167L2.54234 15.3583C2.71734 16.9917 3.31734 18.3333 6.66734 18.3333Z"
                            stroke={'#808080'}
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M6.66797 4.99935V4.33268C6.66797 2.85768 6.66797 1.66602 9.33464 1.66602H10.668C13.3346 1.66602 13.3346 2.85768 13.3346 4.33268V4.99935"
                            stroke="#808080"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M11.6654 10.8333V11.6667C11.6654 11.675 11.6654 11.675 11.6654 11.6833C11.6654 12.5917 11.657 13.3333 9.9987 13.3333C8.3487 13.3333 8.33203 12.6 8.33203 11.6917V10.8333C8.33203 10 8.33203 10 9.16536 10H10.832C11.6654 10 11.6654 10 11.6654 10.8333Z"
                            stroke="#808080"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M18.043 9.16602C16.118 10.566 13.918 11.3993 11.668 11.6827"
                            stroke="#808080"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M2.18359 9.39258C4.05859 10.6759 6.17526 11.4509 8.33359 11.6926"
                            stroke="#808080"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                ) : (
                    <svg
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M31.6351 10.4698C30.3601 9.05979 28.2301 8.35479 25.1401 8.35479H24.7801V8.29479C24.7801 5.77479 24.7801 2.65479 19.1401 2.65479H16.8601C11.2201 2.65479 11.2201 5.78979 11.2201 8.29479V8.36979H10.8601C7.75506 8.36979 5.64006 9.07478 4.36506 10.4848C2.88006 12.1348 2.92506 14.3548 3.07506 15.8698L3.09006 15.9748L3.21751 17.3131C3.23178 17.4628 3.31224 17.5979 3.43795 17.6806C3.77524 17.9023 4.46336 18.3494 4.86006 18.5698C5.07006 18.7048 5.29506 18.8248 5.52006 18.9448C8.08506 20.3548 10.9051 21.2998 13.7701 21.7648C13.9051 23.1748 14.5201 24.8248 17.8051 24.8248C21.0901 24.8248 21.7351 23.1898 21.8401 21.7348C24.9001 21.2398 27.8551 20.1748 30.5251 18.6148C30.6151 18.5698 30.6751 18.5248 30.7501 18.4798C31.3765 18.1257 32.0276 17.6851 32.6137 17.259C32.7264 17.177 32.7988 17.051 32.8142 16.9125L32.8501 16.5898L32.9251 15.8848C32.9401 15.7948 32.9401 15.7198 32.9551 15.6148C33.0751 14.0998 33.0451 12.0298 31.6351 10.4698ZM19.6351 20.7448C19.6351 22.3348 19.6351 22.5748 17.7901 22.5748C15.9451 22.5748 15.9451 22.2898 15.9451 20.7598V18.8698H19.6351V20.7448ZM13.3651 8.35479V8.29479C13.3651 5.74479 13.3651 4.79979 16.8601 4.79979H19.1401C22.6351 4.79979 22.6351 5.75979 22.6351 8.29479V8.36979H13.3651V8.35479Z"
                            fill="#AAAAAA"
                        />
                        <path
                            d="M31.7157 20.4073C32.0685 20.2374 32.4743 20.5178 32.4389 20.9078L31.86 27.2851C31.545 30.2851 30.315 33.3451 23.715 33.3451H12.285C5.68501 33.3451 4.45501 30.2851 4.14001 27.3001L3.5893 21.2422C3.55426 20.8568 3.95032 20.5765 4.30227 20.7375C5.90334 21.4702 9.5496 23.0872 11.6449 23.6083C11.8098 23.6493 11.9447 23.7671 12.0126 23.9228C12.8964 25.95 14.8937 27.0301 17.805 27.0301C20.6875 27.0301 22.7114 25.9084 23.5973 23.878C23.6653 23.7222 23.8005 23.6044 23.9654 23.5633C26.1862 23.0098 30.0484 21.21 31.7157 20.4073Z"
                            fill="#AAAAAA"
                        />
                    </svg>
                )}
            </Box>
        </>
    );
}

export default BriefCaseIcon;
