import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';
import { useEffect, useState } from 'react';

const DeletePrompt = ({
    handleClose = () => { },
    show = false,
    message = '',
    handleYes = () => { }
}) => {
    const [isDisabled, setIsDisabled] = useState(false);
    useEffect(() => {
        setIsDisabled(false);
    }, [show])

    return (
        <Dialog open={show} onClose={handleClose}>
            <DialogTitle>Warning</DialogTitle>
            <DialogContent>
                <DialogContentText>{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    variant="outlined"
                    sx={{
                        p: '10px 12px',
                        fontWeight: '400',
                        fontSize: '14px',
                        textTransform: 'capitalize',
                        lineHeight: '20px',
                        width: 'auto'
                    }}
                >
                    Cancel
                </Button>
                <Button
                    disabled={isDisabled}
                    onClick={() => { setIsDisabled(true); handleYes(); }}
                    variant="contained"
                    sx={{
                        p: '10px 12px',
                        backgroundColor: '#cb2e2e',
                        fontWeight: '400',
                        fontSize: '14px',
                        textTransform: 'capitalize',
                        lineHeight: '20px',
                        width: 'auto'
                    }}
                >
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeletePrompt;
