export const messagesComponentHeight = '698px';

export const toTimestamp = (date: Date) => {
    return new Date(date)
        .toLocaleTimeString([], {
            hour: 'numeric',
            minute: '2-digit'
        })
        .replaceAll(' ', '\u00A0');
};

export const toDatestamp = (date: Date) => {
    date = new Date(date);
    const month = date.toLocaleString('default', { month: 'short' });
    const day = date.getDate();
    return `${month} ${day}`;
};

export const toPreviewTimestamp = (date: Date) => {
    date = new Date(date);
    const currentDate = new Date();
    const isSameDay =
        date.getDate() === currentDate.getDate() &&
        date.getMonth() === currentDate.getMonth() &&
        date.getFullYear() === currentDate.getFullYear();

    if (isSameDay) {
        return toTimestamp(date);
    } else {
        return toDatestamp(date);
    }
};

export const truncateWithEllipsesStyle = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
};

export const isImageUrl = (str: string) => {
    return /https:\/\/jobfactorstorage\.blob/.test(str);
}