import { Box } from "@mui/material";

export default function GenericCompanyLogo() {
    return (
        <>
            <Box display={{ xs: 'none', sm: 'flex' }}>
                <svg
                    width="80"
                    height="80"
                    viewBox="0 0 80 80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect width="80" height="80" fill="white" />
                    <path
                        d="M32 15L48.4545 49.5H15.5455L32 15Z"
                        fill="#05668D"
                    />
                    <circle cx="52" cy="51" r="14" fill="#FFC24C" />
                </svg>
            </Box>
            <Box display={{ xs: 'flex', sm: 'none' }}>
                <svg
                    width="52"
                    height="60"
                    viewBox="0 0 80 80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect width="80" height="80" fill="white" />
                    <path
                        d="M32 15L48.4545 49.5H15.5455L32 15Z"
                        fill="#05668D"
                    />
                    <circle cx="52" cy="51" r="14" fill="#FFC24C" />
                </svg>
            </Box>
        </>
    );
}
