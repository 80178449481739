const MedalIcon = ({ width='48px', height='48px', color = '#494949' }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M42.5005 36.9402L39.2005 37.7202C38.4605 37.9002 37.8805 38.4602 37.7205 39.2002L37.0205 42.1402C36.6405 43.7402 34.6005 44.2402 33.5405 42.9802L27.5605 36.1002C27.0805 35.5402 27.3405 34.6602 28.0605 34.4802C31.6005 33.6202 34.7805 31.6402 37.1205 28.8202C37.5005 28.3602 38.1805 28.3002 38.6005 28.7202L43.0405 33.1602C44.5605 34.6802 44.0205 36.5802 42.5005 36.9402Z"
                fill={color}
            />
            <path
                d="M5.39837 36.9402L8.69837 37.7202C9.43837 37.9002 10.0184 38.4602 10.1784 39.2002L10.8784 42.1402C11.2584 43.7402 13.2984 44.2402 14.3584 42.9802L20.3384 36.1002C20.8184 35.5402 20.5584 34.6602 19.8384 34.4802C16.2984 33.6202 13.1184 31.6402 10.7784 28.8202C10.3984 28.3602 9.71837 28.3002 9.29837 28.7202L4.85837 33.1602C3.33837 34.6802 3.87837 36.5802 5.39837 36.9402Z"
                fill={color}
            />
            <path
                d="M24 4C16.26 4 10 10.26 10 18C10 20.9 10.86 23.56 12.34 25.78C14.5 28.98 17.92 31.24 21.9 31.82C22.58 31.94 23.28 32 24 32C24.72 32 25.42 31.94 26.1 31.82C30.08 31.24 33.5 28.98 35.66 25.78C37.14 23.56 38 20.9 38 18C38 10.26 31.74 4 24 4ZM30.12 17.56L28.46 19.22C28.18 19.5 28.02 20.04 28.12 20.44L28.6 22.5C28.98 24.12 28.12 24.76 26.68 23.9L24.68 22.72C24.32 22.5 23.72 22.5 23.36 22.72L21.36 23.9C19.92 24.74 19.06 24.12 19.44 22.5L19.92 20.44C20 20.06 19.86 19.5 19.58 19.22L17.88 17.56C16.9 16.58 17.22 15.6 18.58 15.38L20.72 15.02C21.08 14.96 21.5 14.64 21.66 14.32L22.84 11.96C23.48 10.68 24.52 10.68 25.16 11.96L26.34 14.32C26.5 14.64 26.92 14.96 27.3 15.02L29.44 15.38C30.78 15.6 31.1 16.58 30.12 17.56Z"
                fill={color}
            />
        </svg>
    );
};

export default MedalIcon;
