import React from 'react';
import { IUserSearchResult } from '../../utils/types';
import { Avatar, Stack, Typography } from '@mui/material';
import ScoreChip from '../../../../components/ScoreChip';

interface SelectedUserCardProps {
    user: IUserSearchResult | null;
}

export default function SelectedUserCard({ user }: SelectedUserCardProps) {
    if (user === null) return <></>;

    const { name, imageUrl, profileType, score } = user;
    return user === null ? (
        <></>
    ) : (
        <Stack
            spacing={1}
            direction="row"
            sx={{
                alignItems: 'center',
                p: '8px',
                borderRadius: '8px',
                backgroundColor: '#F5F5F5'
            }}
        >
            <Avatar src={imageUrl} />
            <Stack>
                <Typography
                    sx={{
                        width: { xs: '100px', md: '200px' },
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}
                >
                    {name}
                </Typography>
                <Typography sx={{ fontSize: '10px', color: '#76818E' }}>
                    {profileType}
                </Typography>
            </Stack>
            <ScoreChip score={score} />
        </Stack>
    );
}
