import { Avatar, Box, Button, IconButton, Typography } from "@mui/material";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import LikesIcon from "../../../assets/icons/LikesIcon";
import moment from "moment";
import { useAuth } from "../../../utils/context/AuthContext";
import { useAddCommentLike } from "../../../utils/hooks/api/like-comments/useCreateCommentLike";
import { useRemoveCommentLike } from "../../../utils/hooks/api/like-comments/useRemoveCommentLike";
import { Responsiveness } from "../../../theme/overrides";
import { usePost } from "../../../utils/context/PostContext";
import { useNavigate } from "react-router-dom";
import { PrimaryProfileType } from "../../../utils/hooks/api/account/types";
import VerifiedIcon from "../../../assets/icons/VerifiedIcon";
import { useState } from "react";
import { logger } from "../../../utils/Helper/logger";
import ProfessionalProfile from '../../ProfessionalProfile/index';

interface CommentBoxProps {
    replyingTo?: any;
    feedId?: string;
    item: ICommentType;
    isFirstComment?: boolean;
    setReplyingTo: React.Dispatch<React.SetStateAction<any>>;
    handlePopup?: () => void;
}

export interface ICommentType {
    id: string;
    createdAt: number;
    comment: string;
    postId: string;
    likes: number;
    likedByProfessionalIds: any[];
    user: {
        photoUrl?: string;
        firstName: string;
        lastName: string;
        id: string;
        userId?: string;
    };
    isAccountVerified?: boolean;
    replies: Array<ICommentType>;
}
const CommentBox = ({
    replyingTo,
    feedId,
    item,
    isFirstComment,
    setReplyingTo = () => {},
    handlePopup = () => {}
}: CommentBoxProps) => {
    
    const [showReplies, setShowReplies] = useState(false);
    const { user } = useAuth();
    const addLike = useAddCommentLike();
    const removeLike = useRemoveCommentLike();
    const { isMobile } = Responsiveness();
    const { posts, setPosts } = usePost();
    const navigate = useNavigate();
    const isProfessional =
        user?.primaryProfile === PrimaryProfileType.Professional;

    const handleLike = (key: string) => {
        if (!isFirstComment) {
            if (key === 'add') {
                const request: any = {
                    userId: user?.id ?? '',
                    postId: item?.postId ?? '',
                    commentId: item?.id ?? ''
                };
                addLike.mutate(request, {
                    onSuccess: (res) => {
                        console.log('COMMENT RES', res);
                        let tempPosts = JSON.parse(JSON.stringify(posts));
                        tempPosts.filter((post: any) => {
                            if (post?.id === item?.postId) {
                                getComment(post?.comments ?? [], 'add');
                            }
                            return post;
                        });
                        setPosts(tempPosts);
                    },
                    onError: (err) => {
                        console.log('ERR', err);
                    }
                });
            } else {
                removeLike.mutate(
                    { userId: user?.id ?? '', commentId: item?.id },
                    {
                        onSuccess: (res) => {
                            console.log('REMOVE COMMENT RES', res);
                            let tempPosts = JSON.parse(JSON.stringify(posts));
                            tempPosts.filter((post: any) => {
                                if (post?.id === item?.postId) {
                                    getComment(
                                        post?.comments ?? [],
                                        'subtract'
                                    );
                                }
                                return post;
                            });
                            setPosts(tempPosts);
                        },
                        onError: (err) => {
                            console.log('ERR', err);
                        }
                    }
                );
            }
        }
    };

    const getComment = (comments: ICommentType[], key: string) => {
        comments?.filter((y) => {
            if (y.id === item?.id) {
                if (key === 'add') {
                    y.likes += 1;
                    y.likedByProfessionalIds.push({
                        id: isProfessional
                            ? user?.professionalProfile?.id
                            : user?.primaryCompanyProfile?.companyId,
                        firstName: isProfessional
                            ? user?.professionalProfile?.firstName
                            : user?.primaryCompanyProfile?.companyName,
                        lastName: isProfessional
                            ? user?.professionalProfile?.lastName
                            : null,
                        photoUrl: isProfessional
                            ? user?.professionalProfile?.photoUrl
                            : user?.primaryCompanyProfile?.photoUrl
                    });
                } else {
                    y.likes -= 1;
                    y.likedByProfessionalIds = y.likedByProfessionalIds.filter(
                        (x) =>
                            x.id !==
                            (isProfessional
                                ? user?.professionalProfile?.id ?? ''
                                : user?.primaryCompanyProfile?.companyId ?? '')
                    );
                }
            } else {
                getComment(y.replies, key);
            }
            return y;
        });
        return comments;
    };
    logger.log(item, 'comment')
    logger.log(user, 'user')

    return (
        <Box
            onClick={(e) => e.stopPropagation()}
            sx={{
                display: 'flex',
                width: '100%',
                gap: '16px',
                py: '8px'
            }}
        >
            <Avatar
                onClick={() =>
                    navigate(
                        isProfessional
                            ? item?.user?.id === user?.professionalProfile?.id
                                ? '/users'
                                : `/professional-profile/${item?.user?.userId}`
                            : `/company-profile/${item?.user?.id}`
                    )
                }
                src={item?.user?.photoUrl}
                sx={{
                    cursor: 'pointer',
                    width: isMobile ? '32px' : '56px',
                    height: isMobile ? '32px' : '56px'
                }}
            />
            <Box sx={{ width: '100%' }}>
                <Box
                // bgcolor={isMobile ? '#f2f2f2' : 'transparent'}
                // borderRadius={isMobile ? '0px 12px 12px 12px' : undefined}
                // p="8px"
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                                gap: '4px'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    fontWeight: '600',
                                    fontSize: isMobile ? '14px' : '16px',
                                    letterSpacing: '0.0015em',
                                    color: '#23282B',
                                    mr: '5px',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        textDecoration: 'underline'
                                    }
                                }}
                                onClick={() =>
                                    navigate(
                                        isProfessional
                                            ? item?.user?.id ===
                                              user?.professionalProfile?.id
                                                ? '/users'
                                                : `/professional-profile/${item?.user?.id}`
                                            : `/company-profile/${item?.user?.id}`
                                    )
                                }
                            >
                                {item?.user?.firstName} {item?.user?.lastName}
                            </Typography>
                            <Box flex={1}>
                                {item?.isAccountVerified ? (
                                    <VerifiedIcon />
                                ) : null}
                            </Box>
                            {isMobile ? (
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        letterSpacing: '0.0015em',
                                        color: '#808080',
                                        fontSize: '11px'
                                    }}
                                >
                                    {moment(item?.createdAt).fromNow()}
                                </Typography>
                            ) : null}
                        </Box>
                        {isFirstComment || isMobile ? null : (
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                {item?.likedByProfessionalIds?.find(
                                    (x) =>
                                        x.id ===
                                        (isProfessional
                                            ? user?.professionalProfile?.id ??
                                              ''
                                            : user?.primaryCompanyProfile
                                                  ?.companyId ?? '')
                                ) ? (
                                    <LikesIcon
                                        onClick={() => handleLike('remove')}
                                        style={{
                                            height: '16px',
                                            marginRight: '5px',
                                            cursor: 'pointer'
                                        }}
                                    />
                                ) : (
                                    <FavoriteBorderIcon
                                        onClick={() => handleLike('add')}
                                        sx={{
                                            color: '#808080',
                                            width: '20px',
                                            height: '20px',
                                            mr: '5px',
                                            cursor: 'pointer'
                                        }}
                                    />
                                )}
                            </Box>
                        )}
                    </Box>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            letterSpacing: '0.001em',
                            color: '#23282B',
                            fontSize: '14px'
                        }}
                    >
                        {item?.comment}
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2.5,
                            mt: '12px'
                        }}
                    >
                        {isMobile ? null : (
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    fontFamily: 'Open Sans',
                                    letterSpacing: '0.0015em',
                                    color: '#808080'
                                }}
                            >
                                {moment(item?.createdAt).fromNow()}
                            </Typography>
                        )}
                        {isFirstComment || isMobile ? null : (
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    letterSpacing: '0.0015em',
                                    color: '#808080',
                                    fontSize: '16px'
                                }}
                            >
                                {item?.likes === 1
                                    ? item?.likes + ' Like'
                                    : item?.likes + ' Likes'}
                            </Typography>
                        )}
                        {isFirstComment || isMobile ? null : (
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    letterSpacing: '0.0015em',
                                    color: '#808080',
                                    cursor: 'pointer',
                                    fontSize: '16px',
                                    '&:hover': {
                                        textDecoration: 'underline'
                                    }
                                }}
                                onClick={() => {
                                    setReplyingTo({
                                        ...item?.user,
                                        commentId: item?.id
                                    });
                                    handlePopup();
                                }}
                            >
                                Reply
                            </Typography>
                        )}
                    </Box>
                </Box>

                {isMobile && !isFirstComment ? (
                    <Box display="flex" alignItems="center" gap="8px" mt="8px">
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '4px',
                                alignItems: 'center',
                                height: '32px',
                                px: '8px',
                                bgcolor: '#f2f2f2',
                                borderRadius: '8px'
                            }}
                        >
                            {item?.likedByProfessionalIds?.find(
                                (x) =>
                                    x.id ===
                                    (isProfessional
                                        ? user?.professionalProfile?.id ?? ''
                                        : user?.primaryCompanyProfile
                                              ?.companyId ?? '')
                            ) ? (
                                <IconButton
                                    onClick={() => handleLike('remove')}
                                    sx={{
                                        m: 0,
                                        p: 0
                                    }}
                                >
                                    <LikesIcon
                                        style={{
                                            width: '16px',
                                            height: '16px'
                                        }}
                                    />
                                </IconButton>
                            ) : (
                                <IconButton
                                    onClick={() => handleLike('add')}
                                    sx={{
                                        maxHeight: '16px',
                                        maxWidth: '16px',
                                        m: 0,
                                        p: 0
                                    }}
                                >
                                    <FavoriteBorderIcon
                                        sx={{
                                            color: '#808080'
                                        }}
                                    />
                                </IconButton>
                            )}
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    letterSpacing: '0.0015em',
                                    color: '#808080',
                                    fontSize: '12px'
                                }}
                            >
                                Like
                            </Typography>
                        </Box>
                        <Box width="2px" height="20px" bgcolor="#C5C5C5"></Box>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '4px',
                                alignItems: 'center',
                                height: '32px',
                                px: '8px',
                                bgcolor: '#f2f2f2',
                                borderRadius: '8px'
                            }}
                            onClick={() => {
                                setReplyingTo({
                                    ...item?.user,
                                    commentId: item?.id
                                });
                                navigate(`/post/${feedId}`, {
                                    state: {
                                        replyTo: replyingTo
                                    }
                                });
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    letterSpacing: '0.0015em',
                                    color: '#808080',
                                    fontSize: '12px'
                                }}
                            >
                                Reply
                            </Typography>
                        </Box>
                    </Box>
                ) : null}
                {item?.replies?.length > 0 ? (
                    <Button
                        variant="contained"
                        sx={{
                            padding: '8px 16px',
                            background: '#F2F2F2',
                            borderRadius: '8px',
                            textTransform: 'capitalize',
                            textDecoration: 'none',
                            fontFamily: 'Open Sans',
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '20px',
                            letterSpacing: '0.0035em',
                            color: '#05668D',
                            mt: '12px',
                            mb: '12px',
                            width: 'fit-content',
                            justifyContent: 'flex-start',
                            height: '28px',
                            '&:hover': {
                                color: '#05668D',
                                background: '#F2F2F2'
                            }
                        }}
                        onClick={() => setShowReplies(!showReplies)}
                    >
                        {showReplies
                            ? `Hide repl${
                                  item?.replies?.length > 1 ? 'ies' : 'y'
                              }`
                            : `View repl${
                                  item?.replies?.length > 1 ? 'ies' : 'y'
                              } (${item?.replies?.length})`}
                    </Button>
                ) : null}
                {showReplies
                    ? item?.replies?.map((x, i) => (
                          <CommentBox
                              key={i}
                              item={x}
                              setReplyingTo={setReplyingTo}
                          />
                      ))
                    : null}
            </Box>
        </Box>
    );
};

export default CommentBox;