import React from 'react'

const ProfileCircleIcon = () => {
  return (
      <svg
          width="61"
          height="20"
          viewBox="0 0 41 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
          <path
              d="M10.1001 10.6497C10.0417 10.6414 9.96672 10.6414 9.90006 10.6497C8.43339 10.5997 7.26672 9.39974 7.26672 7.92474C7.26672 6.41641 8.48339 5.19141 10.0001 5.19141C11.5084 5.19141 12.7334 6.41641 12.7334 7.92474C12.7251 9.39974 11.5667 10.5997 10.1001 10.6497Z"
              stroke="#23282B"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
          />
          <path
              d="M15.6166 16.1498C14.1333 17.5081 12.1666 18.3331 9.99997 18.3331C7.8333 18.3331 5.86663 17.5081 4.3833 16.1498C4.46663 15.3665 4.96663 14.5998 5.8583 13.9998C8.14163 12.4831 11.875 12.4831 14.1416 13.9998C15.0333 14.5998 15.5333 15.3665 15.6166 16.1498Z"
              stroke="#23282B"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
          />
          <path
              d="M9.99996 18.3337C14.6023 18.3337 18.3333 14.6027 18.3333 10.0003C18.3333 5.39795 14.6023 1.66699 9.99996 1.66699C5.39759 1.66699 1.66663 5.39795 1.66663 10.0003C1.66663 14.6027 5.39759 18.3337 9.99996 18.3337Z"
              stroke="#23282B"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
          />
          <line x1="40.5" y1="2.5" x2="40.5" y2="17.5" stroke="#D9D9D9" />
      </svg>
  );
}

export default ProfileCircleIcon
