import { Box, Stack } from '@mui/material';
import MessageList from './MessageList';
import MessageInput from './MessageInput';
import { useMessages } from '../../utils/MessagesContext';
import { IMessage } from '../../utils/types';
import { useEffect, useState } from 'react';
import { messagesComponentHeight } from '../../utils/helpers';

export default function ActiveConversation() {
    const { userId, socket, activeConversation, setConversations } =
        useMessages();
    const [messages, setMessages] = useState<IMessage[]>([]);

    useEffect(() => {
        if (!(socket && activeConversation)) return;
        const messageListener = (message: IMessage) => {
            if (
                ![message.senderId, message.recipientId].includes(
                    activeConversation!.userId
                )
            ) {
                return;
            }

            setMessages((prevMessages) => {
                const newMessages = [...prevMessages];
                newMessages.unshift(message);
                return newMessages;
            });
        };

        socket.on('newMessage', messageListener);

        socket.emit(
            'findConversationMessages',
            { senderId: userId, recipientId: activeConversation!.userId },
            (response: IMessage[]) => {
                setMessages(response);

                setConversations((prevConversations) => {
                    const updatedConversations = [...prevConversations];
                    const conversationIndex = updatedConversations.findIndex(
                        (conversation) =>
                            conversation.userId === activeConversation.userId
                    );
                    if (conversationIndex === -1) return prevConversations;
                    const updatedConversation = {
                        ...updatedConversations[conversationIndex],
                        unreadCount: 0
                    };
                    updatedConversations[conversationIndex] =
                        updatedConversation;
                    return updatedConversations;
                });
            }
        );

        return () => {
            socket.off('newMessage', messageListener);
        };
    }, [socket, activeConversation, userId]);

    return (
        <Box sx={{ width: '100%', pr: { md: '48px' } }}>
            <Stack
                spacing={1.5}
                sx={{
                    // You might need to add some padding to this
                    height: messagesComponentHeight,
                    width: '100%',
                    justifyContent: 'space-between'
                }}
            >
                <MessageList messages={messages} />
                <MessageInput />
            </Stack>
        </Box>
    );
}
