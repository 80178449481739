import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ModalCheckmark from '../../../assets/icons/ModalCheckmark';
import CloseXIcon from '../../../assets/icons/CloseXIcon';
import { Responsiveness } from '../../../theme/overrides';

interface IPropTypes {
    handleClose: () => void;
    icon?: React.ReactNode;
    handleContinue?: () => void;
    open: boolean;
    showClose?: boolean;
    description: string;
    title: string;
}
const DialogBox = ({
    handleClose,
    open,
    handleContinue,
    icon,
    description,
    title,
    showClose = true
}: IPropTypes) => {
    const { isMobile, isTablet } = Responsiveness()
    return (
        <Dialog
            onClose={handleClose}
            open={open}
            sx={{
                '& .MuiPaper-root': {
                    minWidth: isMobile ? '90%' : isTablet ? '60%' : '750px',
                    background: '#FCFBF8',
                    py: '16px',
                    px: '32px',
                    height: '372px'
                    // pb: '40px'
                }
            }}
        >
            <Box display="flex" height="100%">
                <Box
                    height="100%"
                    flex={1}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: isMobile ? 'center' : undefined,
                        flexDirection: 'column'
                    }}
                >
                    {icon ?? <ModalCheckmark />}
                    {title ? (
                        <Typography
                            variant="titleLargeBold"
                            sx={{
                                mt: '16px'
                            }}
                        >
                            {title}
                        </Typography>
                    ) : null}
                    <Typography
                        sx={{
                            color: '#808080',
                            mt: '8px',
                            textAlign: 'center',
                            maxWidth: '507px'
                        }}
                    >
                        {description}
                    </Typography>
                    <Button
                        variant={isTablet ? 'contained' : 'outlined'}
                        sx={{
                            padding: '14px 36px',
                            background: isTablet ? '#05668D' : '#FCFBF8',
                            border: '1px solid #05668D',
                            borderRadius: '8px',
                            fontFamily: 'Open Sans',
                            fontSize: '14px',
                            lineHeight: '20px',
                            fontWeight: 600,
                            color: isTablet ? '#fff' : '#05668D',
                            mt: '32px',
                            width: 'auto',
                            minWidth: '224px'
                        }}
                        onClick={handleContinue ?? handleClose}
                    >
                        Continue
                    </Button>
                </Box>
                {showClose && (isMobile ? null : <Box sx={{ cursor: 'pointer' }} onClick={handleClose}>
                    <CloseXIcon />
                </Box>)}
            </Box>
        </Dialog>
    );
};

export default DialogBox;
