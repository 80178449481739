import React from 'react';

const ArrowRightIcon = (
    { color }: { color?: string } = { color: '#05668D' }
) => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
            >
                <path
                    d="M10.1199 12.5448C9.99325 12.5448 9.86658 12.4981 9.76658 12.3981C9.57325 12.2048 
            9.57325 11.8848 9.76658 11.6915L13.4599 7.99812L9.76658 4.30479C9.57325 4.11146 9.57325 
            3.79146 9.76658 3.59812C9.95992 3.40479 10.2799 3.40479 10.4732 3.59812L14.5199 7.64479C14.7132 
            7.83812 14.7132 8.15812 14.5199 8.35146L10.4732 12.3981C10.3732 12.4981 10.2466 12.5448 10.1199 12.5448Z"
                    fill={color}
                />
                <path
                    d="M14.0533 8.5H2.83325C2.55992 8.5 2.33325 8.27333 2.33325 8C2.33325 7.72667 2.55992 7.5 2.83325 
            7.5H14.0533C14.3266 7.5 14.5533 7.72667 14.5533 8C14.5533 8.27333 14.3266 8.5 14.0533 8.5Z"
                    fill={color}
                />
            </svg>
        </>
    );
};

export default ArrowRightIcon;
