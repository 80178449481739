import React from 'react';

export const PhotoIcon = ({ color = '#055C7F' }) => {
    return (
        <svg
            width="22"
            height="19"
            viewBox="0 0 22 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.37475 10.1667C8.37475 8.71692 9.55 7.54167 10.9997 7.54167C12.4495 7.54167 13.6247 8.71692 13.6247 10.1667C13.6247 11.6164 12.4495 12.7917 10.9997 12.7917C9.55 12.7917 8.37475 11.6164 8.37475 10.1667Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.71901 3.91619C5.71901 1.89141 7.36042 0.25 9.3852 0.25H12.6142C14.639 0.25 16.2804 1.89141 16.2804 3.91619C16.2804 3.9335 16.2937 3.94792 16.311 3.94932L18.9122 4.16057C20.0773 4.2552 21.0356 5.11683 21.2531 6.26544C21.8081 9.19646 21.8494 12.2016 21.3754 15.1468L21.2619 15.8517C21.0481 17.1804 19.9545 18.1913 18.613 18.3003L16.347 18.4843C12.788 18.7734 9.21143 18.7734 5.65243 18.4843L3.38639 18.3003C2.04497 18.1913 0.951378 17.1804 0.737509 15.8517L0.62405 15.1468C0.149998 12.2016 0.191379 9.19646 0.746346 6.26544C0.963827 5.11683 1.92209 4.2552 3.08727 4.16057L5.68847 3.94932C5.70572 3.94792 5.71901 3.9335 5.71901 3.91619ZM10.9997 5.79167C8.5835 5.79167 6.62475 7.75042 6.62475 10.1667C6.62475 12.5829 8.5835 14.5417 10.9997 14.5417C13.416 14.5417 15.3747 12.5829 15.3747 10.1667C15.3747 7.75042 13.416 5.79167 10.9997 5.79167Z"
                fill={color}
            />
        </svg>
    );
};
