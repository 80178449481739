import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { useNavigate } from 'react-router-dom';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import PopupCheckmark from '../../../../assets/icons/PopupCheckmark';
import { Modal } from '@mui/material';

interface PropTypes {
    open: boolean;
    updatePopup?: boolean;
    handleClose: () => void;
    handleOnPostAnother: () => void;
}
const DialogBox = ({
    open,
    handleClose,
    handleOnPostAnother,
    updatePopup = false
}: PropTypes) => {
    const navigate = useNavigate();
    return (
        <Modal open={open} onClose={handleClose}>
            <Box
                sx={{
                    position: 'absolute',
                    padding: {xs: '64px 16px', sm: '64px 32px'},
                    display: 'flex',
                    flexDirection: 'column',
                    bgcolor: '#fff',
                    top: '50%',
                    left: '50%',
                    alignItems: 'center',
                    transform: `translate(-50%, -50%)`,
                    gap: '32px',
                    width: { xs: '90%', sm: '404px' },
                    borderRadius: '8px',
                    border: 'none'
                }}
            >
                <Box
                    sx={{
                        padding: '0px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '8px'
                    }}
                >
                    <PopupCheckmark />
                    <DialogContentText
                        sx={{
                            fontFamily: 'Open Sans',
                            fontWeight: '400',
                            fontSize: { xs: '24px', sm: '28px' },
                            lineHeight: '36px',
                            color: '#000000'
                        }}
                    >
                        {updatePopup
                            ? 'Job Updated Successfully'
                            : 'Job Posting Successful'}
                    </DialogContentText>
                </Box>
                {/* <Button
                    sx={{
                        background: '#05668D',
                        borderRadius: '8px',
                        padding: '16px 24px',
                        fontFamily: 'Open Sans',
                        fontWeight: '600',
                        lineHeight: '19px',
                        color: '#EDEDED',
                        textTransform: 'capitalize',
                        textDecoration: 'none',
                        // minWidth: '193px',
                        '&:hover': {
                            background: '#ccc'
                        }
                    }}
                    onClick={handleOnPostAnother}
                >
                    Post another opening
                </Button> */}
                <Button
                    sx={{
                        background: '#FAFAFA',
                        border: '1px solid #05668D',
                        borderRadius: '8px',
                        padding: '16px 24px',
                        fontFamily: 'Open Sans',
                        fontWeight: '600',
                        lineHeight: '19px',
                        color: '#05668D',
                        textTransform: 'capitalize',
                        textDecoration: 'none'
                        // minWidth: '193px'
                    }}
                    onClick={() => navigate('/job-posting')}
                >
                    Continue
                </Button>
            </Box>
        </Modal>
    );
};

export default DialogBox;
