function MoneyIcon() {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.4057 15.1841H5.08072C4.64739 15.1841 4.23905 15.1424 3.87238 15.0591C3.68071 15.0341 3.44739 14.9674 3.20572 14.8758C2.09739 14.4591 0.789062 13.4341 0.789062 10.8841V6.59242C0.789062 3.86742 2.35572 2.30078 5.08072 2.30078H12.4057C14.7057 2.30078 16.1891 3.40076 16.5891 5.40076C16.6641 5.76742 16.6974 6.15908 16.6974 6.59242V10.8841C16.6974 13.6258 15.1391 15.1841 12.4057 15.1841ZM5.08904 3.56746C3.03904 3.56746 2.04738 4.5591 2.04738 6.6091V10.9008C2.04738 12.3925 2.57239 13.3091 3.64739 13.7175C3.81406 13.7758 3.9724 13.8174 4.1224 13.8424C4.43906 13.9091 4.74737 13.9424 5.08904 13.9424H12.414C14.464 13.9424 15.4557 12.9508 15.4557 10.9008V6.6091C15.4557 6.2591 15.4307 5.95076 15.3724 5.66743C15.0891 4.25076 14.1224 3.56746 12.414 3.56746H5.08904Z"
                fill="#494949"
            />
            <path
                d="M14.9112 17.6849H7.58618C6.87784 17.6849 6.2445 17.5849 5.70283 17.3766C4.47783 16.9182 3.67783 15.9516 3.40283 14.5682C3.36116 14.3599 3.42784 14.1432 3.57784 14.0016C3.72784 13.8516 3.94451 13.7932 4.15285 13.8432C4.42785 13.9016 4.73618 13.9349 5.08618 13.9349H12.4112C14.4612 13.9349 15.4528 12.9433 15.4528 10.8933V6.60158C15.4528 6.25158 15.4278 5.94324 15.3695 5.65991C15.3278 5.45157 15.3945 5.24325 15.5362 5.09325C15.6862 4.94325 15.8945 4.87657 16.1028 4.92657C18.1028 5.3349 19.2028 6.81825 19.2028 9.10158V13.3933C19.2028 16.1266 17.6445 17.6849 14.9112 17.6849ZM4.93618 15.1849C5.20285 15.6682 5.60284 16.0099 6.15284 16.2099C6.55284 16.3599 7.03616 16.4349 7.59449 16.4349H14.9195C16.9695 16.4349 17.9612 15.4433 17.9612 13.3933V9.10158C17.9612 7.78491 17.5528 6.90992 16.7112 6.45158C16.7112 6.50158 16.7112 6.55158 16.7112 6.60158V10.8933C16.7112 13.6183 15.1445 15.1849 12.4195 15.1849H5.09449C5.03616 15.1849 4.98618 15.1849 4.93618 15.1849Z"
                fill="#494949"
            />
            <path
                d="M8.74689 11.5758C7.18855 11.5758 5.92188 10.3091 5.92188 8.75079C5.92188 7.19246 7.18855 5.92578 8.74689 5.92578C10.3052 5.92578 11.5719 7.19246 11.5719 8.75079C11.5719 10.3091 10.3052 11.5758 8.74689 11.5758ZM8.74689 7.17578C7.88022 7.17578 7.17187 7.88413 7.17187 8.75079C7.17187 9.61746 7.88022 10.3258 8.74689 10.3258C9.61355 10.3258 10.3219 9.61746 10.3219 8.75079C10.3219 7.88413 9.61355 7.17578 8.74689 7.17578Z"
                fill="#494949"
            />
            <path
                d="M3.98437 11.2097C3.64271 11.2097 3.35938 10.9263 3.35938 10.5847V6.91797C3.35938 6.5763 3.64271 6.29297 3.98437 6.29297C4.32604 6.29297 4.60937 6.5763 4.60937 6.91797V10.5847C4.60937 10.9263 4.33437 11.2097 3.98437 11.2097Z"
                fill="#494949"
            />
            <path
                d="M13.5078 11.2097C13.1661 11.2097 12.8828 10.9263 12.8828 10.5847V6.91797C12.8828 6.5763 13.1661 6.29297 13.5078 6.29297C13.8495 6.29297 14.1328 6.5763 14.1328 6.91797V10.5847C14.1328 10.9263 13.8578 11.2097 13.5078 11.2097Z"
                fill="#494949"
            />
        </svg>
    );
}
export default MoneyIcon;
