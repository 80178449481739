import { Box, Button, CircularProgress, Typography } from '@mui/material';
import React, { useState } from 'react';
import EmailConfirmationIcon from '../../../../assets/icons/EmailConfirmationIcon';
import OnboardingSteps from '../../OnboardingSteps/OnboardingSteps';
import { updateStep } from './updateAction';
import { useStateMachine } from 'little-state-machine';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useGetVerificationCode from '../../../../utils/hooks/api/authentication/useGetVerificationCode';
import useVerifyAccount from '../../../../utils/hooks/api/authentication/userVerifyAccount';
import { isEmailValid } from '../../../../utils/Helper/validators';
import axiosInstance from '../../../../utils/hooks/api/axiosConfig';
import { logger } from '../../../../utils/Helper/logger';
import EmailVerificationFailedIcon from '../../../../assets/icons/EmailVerificationFailedIcon';
import EmailConfirmationSuccessIcon from '../../../../assets/icons/EmailConfirmationSuccessIcon';

function EmailConfirmation() {
    const { actions } = useStateMachine({ updateStep });
    const { email } = useParams();
    const getCode = useGetVerificationCode();
    const verifyAccount = useVerifyAccount();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [type, setType] = useState('');
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState('Verify email');
    const verificationSentMessage =
        'A verification email has been sent to your email address. Click the link in the email to verify your account and continue your signup process.';
    const [message, setMessage] = useState(verificationSentMessage);

    const verifyCode = async () => {
        const data = {
            verificationCode: queryParams.get('hash') ?? '',
            emailAddress: email ?? '',
            verificationType: 'HASH'
        }
        console.log({ hash: data });
        // try async await or wait for x time
        await verifyAccount.mutate(
            data,
            {
                onSuccess: async (res: any) => {
                    setTitle('Email verification successful');
                    setType('success');
                    setMessage('Your email is successful. Click on continue to proceed to your account.');
                    try {
                        const referralUser =
                            localStorage.getItem('referralUser');
                        if (referralUser) {
                            const signUpUser =
                                localStorage.getItem('signUpUser');
                            const { connectionRequestId, connectionLinkId } =
                                JSON.parse(
                                    localStorage.getItem('referralConnection')!
                                );
                            const res = await axiosInstance.post(
                                '/api/connection-requests/respond-connection-request',
                                {
                                    sourceUserId: referralUser,
                                    destinationUserId: signUpUser,
                                    connectionRequestId,
                                    connectionLinkId,
                                    respondStatus: 'ACCEPTED'
                                }
                            );
                            logger.log('accepted connection =>', res.data);
                            localStorage.removeItem('referrer');
                            localStorage.removeItem('referralUser');
                            localStorage.removeItem('signUpUser');
                            localStorage.removeItem('referralConnection');
                        }
                    } catch (err) {
                        logger.error(err);
                    }
                    return;
                },
                onError: () => {
                    setTitle('Email verification failed');
                    setType('error');
                    setMessage(
                        'Your account verification was unsuccessful, please try again.'
                    );
                    return;
                }
            }
        );
    };
    const createVerification = () => {
        if (email) {
            logger.log(
                'connection =>',
                localStorage.getItem('referralConnection')
            );
            setLoading(true);
            getCode.mutate(
                { emailAddress: email, verificationType: 'HASH' },
                {
                    onSuccess: (res) => {
                        logger.log('object', res);
                        setTitle('Verify email');
                        setType('');
                        setMessage(verificationSentMessage);
                        setLoading(false);
                    },
                    onError: () => {
                        setTitle('Email verification failed');
                        setType('error');
                        setMessage(
                            'There was a problem sending the email, please contact support.'
                        );
                        setLoading(false);
                        return;
                    }
                }
            );
        }
    };

    React.useEffect(() => {
        actions.updateStep(3);
        if (isEmailValid(email) && queryParams.get('hash'))
            verifyCode();
    }, []);

    return (
        <>
            <Box
                sx={{
                    backgroundColor: { md: 'transparent', sm: '#FCFBF8', xs: 'transparent' },
                    borderRadius: '12px',
                    p: { md: 0, sm: '40px', xs: 0 },
                    mt: { md: '50px', sm: '-240px', xs: 0 },
                    mx: { lg: '50px', md: '20px', sm: '50px', xs: '20px' },
                }}
            >
                <OnboardingSteps
                    isVerified={
                        type === 'success' ? true : type === 'error' ? false : null
                    }
                />
                <Box
                    sx={{
                        ml: { lg: '40px', xs: 0 },
                        mt: { sm: '50px', xs: '20px' },
                        width: { xl: '70%' },
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        '& svg': {
                            height: { md: 'fit-content', sm: '300px', xs: '250px' },
                            width: 'fit-content'
                        }
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            fontWeight: 600,
                            color: '#23282B',
                            fontSize: { sm: '28px', xs: '18px' },
                            textAlign: { md: 'left', xs: 'center' },
                            width: '100%',
                            display: { sm: 'block', xs: 'none' },
                        }}
                    >
                        {title}
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#808080',
                            fontSize: '16px',
                            lineHeight: '28px',
                            textAlign: { md: 'left', sm: 'center', xs: 'left' },
                            mt: '20px',
                            mb: '40px'
                        }}
                    >
                        {message}
                    </Typography>
                    {type === 'success' ?
                        <EmailConfirmationSuccessIcon />
                        :
                        type === 'error' ?
                            <EmailVerificationFailedIcon />
                            :
                            <EmailConfirmationIcon />
                    }
                    {type === 'success' ? (
                        <Button
                            variant="contained"
                            sx={{
                                width: '100%',
                                py: '10px',
                                mt: '30px'
                            }}
                            onClick={() => navigate('/login?newAccount=true')}
                        >
                            Sign In
                        </Button>
                    ) : null}
                    {type === 'error' ? (
                        loading ? (
                            <CircularProgress sx={{ mt: '30px', color: '#05668D' }} />
                        ) : (
                            <Button
                                variant="contained"
                                sx={{
                                    mt: '30px',
                                    width: '100%',
                                    py: '10px'
                                }}
                                onClick={() => {
                                    createVerification();
                                }}
                            >
                                Resend email
                            </Button>
                        )
                    ) : null}
                </Box>
            </Box>
        </>
    );
}

export default EmailConfirmation;
