const UserCircle = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            style={{ minWidth: '41px', minHeight: '20px' }}
            width="41"
            height="20"
            viewBox="0 0 41 20"
            fill="none"
        >
            <path
                d="M10.0999 10.6536C10.0416 10.6453 9.9666 10.6453 9.89994 10.6536C8.43327 10.6036 7.2666 9.40365 7.2666 7.92865C7.2666 6.42031 8.48327 5.19531 9.99993 5.19531C11.5083 5.19531 12.7333 6.42031 12.7333 7.92865C12.7249 9.40365 11.5666 10.6036 10.0999 10.6536Z"
                stroke="#747474"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.6166 16.1469C14.1333 17.5052 12.1666 18.3302 9.99997 18.3302C7.8333 18.3302 5.86663 17.5052 4.3833 16.1469C4.46663 15.3635 4.96663 14.5969 5.8583 13.9969C8.14163 12.4802 11.875 12.4802 14.1416 13.9969C15.0333 14.5969 15.5333 15.3635 15.6166 16.1469Z"
                stroke="#747474"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.99984 18.3307C14.6022 18.3307 18.3332 14.5998 18.3332 9.9974C18.3332 5.39502 14.6022 1.66406 9.99984 1.66406C5.39746 1.66406 1.6665 5.39502 1.6665 9.9974C1.6665 14.5998 5.39746 18.3307 9.99984 18.3307Z"
                stroke="#747474"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <line x1="40.5" y1="2.5" x2="40.5" y2="17.5" stroke="#D9D9D9" />
        </svg>
    );
};

export default UserCircle;
