import { Box } from '@mui/material';
export default function HomeIcon(props: {
    isHover: boolean;
    isSelected: boolean;
    tab?: boolean
}) {
    const { isHover, isSelected, tab } = props;
    return (
        <>
            <Box display={{ xs: 'none', lg: 'block' }}>
                <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill={isHover || isSelected ? '#05668D' : 'none'}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M7.51797 2.36713L3.0263 5.86713C2.2763 6.45046 1.66797 7.69213 1.66797 8.63379V14.8088C1.66797 16.7421 3.24297 18.3255 5.1763 18.3255H14.8263C16.7596 18.3255 18.3346 16.7421 18.3346 14.8171V8.75046C18.3346 7.74213 17.6596 6.45046 16.8346 5.87546L11.6846 2.26713C10.518 1.45046 8.64297 1.49213 7.51797 2.36713Z"
                        stroke={isHover || isSelected ? '#05668D' : '#808080'}
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M10 14.9922V12.4922"
                        stroke={isHover || isSelected ? '#FFFAF1' : '#808080'}
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </Box>
            <Box display={{ xs: 'block', lg: 'none' }}>
                {(isHover || isSelected) && !tab ? (
                    <svg
                        width={tab ? '20' : '40'}
                        height={tab ? '20' : '40'}
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M33.4 11.3665L23.8 4.64981C21.1833 2.81648 17.1667 2.91648 14.65 4.86648L6.29998 11.3831C4.63332 12.6831 3.31665 15.3498 3.31665 17.4498V28.9498C3.31665 33.1998 6.76665 36.6665 11.0167 36.6665H28.9833C33.2333 36.6665 36.6833 33.2165 36.6833 28.9665V17.6665C36.6833 15.4165 35.2333 12.6498 33.4 11.3665ZM21.25 29.9998C21.25 30.6831 20.6833 31.2498 20 31.2498C19.3167 31.2498 18.75 30.6831 18.75 29.9998V24.9998C18.75 24.3165 19.3167 23.7498 20 23.7498C20.6833 23.7498 21.25 24.3165 21.25 24.9998V29.9998Z"
                            fill="#05668D"
                        />
                    </svg>
                ) : tab ? (
                    <svg
                        width={isHover || isSelected ? '24px' : '20px'}
                        height={isHover || isSelected ? '24px' : '20px'}
                        viewBox="0 0 20 20"
                        fill={isHover || isSelected ? '#05668D' : 'none'}
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M7.51797 2.36713L3.0263 5.86713C2.2763 6.45046 1.66797 7.69213 1.66797 8.63379V14.8088C1.66797 16.7421 3.24297 18.3255 5.1763 18.3255H14.8263C16.7596 18.3255 18.3346 16.7421 18.3346 14.8171V8.75046C18.3346 7.74213 17.6596 6.45046 16.8346 5.87546L11.6846 2.26713C10.518 1.45046 8.64297 1.49213 7.51797 2.36713Z"
                            stroke={
                                isHover || isSelected ? '#05668D' : '#808080'
                            }
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M10 14.9922V12.4922"
                            stroke={
                                isHover || isSelected ? '#FFFAF1' : '#808080'
                            }
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                ) : (
                    <svg
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M30.06 10.23L21.42 4.18503C19.065 2.53503 15.45 2.62503 13.185 4.38003L5.66999 10.245C4.16999 11.415 2.98499 13.815 2.98499 15.705V26.055C2.98499 29.88 6.08998 33 9.91498 33H26.085C29.91 33 33.015 29.895 33.015 26.07V15.9C33.015 13.875 31.71 11.385 30.06 10.23ZM19.125 27C19.125 27.615 18.615 28.125 18 28.125C17.385 28.125 16.875 27.615 16.875 27V22.5C16.875 21.885 17.385 21.375 18 21.375C18.615 21.375 19.125 21.885 19.125 22.5V27Z"
                            fill="#AAAAAA"
                        />
                    </svg>
                )}
            </Box>
        </>
    );
}
