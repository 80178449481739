import React from 'react';

const SeeMoreIcon = () => {
    return (
        <>
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M9.90005 12.4906C9.74172 12.4906 9.58338 12.4323 9.45838 12.3073L7.32505 10.174C7.08338 9.93229 7.08338 9.53229 7.32505 9.29063C7.56672 9.04896 7.96672 9.04896 8.20838 9.29063L9.90005 10.9823L11.5917 9.29063C11.8334 9.04896 12.2334 9.04896 12.475 9.29063C12.7167 9.53229 12.7167 9.93229 12.475 10.174L10.3417 12.3073C10.2167 12.4323 10.0584 12.4906 9.90005 12.4906Z"
                    fill="#05668D"
                />
                <path
                    d="M9.8999 12.432C9.55824 12.432 9.2749 12.1487 9.2749 11.807V3.33203C9.2749 2.99036 9.55824 2.70703 9.8999 2.70703C10.2416 2.70703 10.5249 2.99036 10.5249 3.33203V11.807C10.5249 12.1487 10.2416 12.432 9.8999 12.432Z"
                    fill="#05668D"
                />
                <path
                    d="M9.99992 17.4401C5.70825 17.4401 2.70825 14.4401 2.70825 10.1484C2.70825 9.80677 2.99159 9.52344 3.33325 9.52344C3.67492 9.52344 3.95825 9.80677 3.95825 10.1484C3.95825 13.7068 6.44159 16.1901 9.99992 16.1901C13.5583 16.1901 16.0416 13.7068 16.0416 10.1484C16.0416 9.80677 16.3249 9.52344 16.6666 9.52344C17.0083 9.52344 17.2916 9.80677 17.2916 10.1484C17.2916 14.4401 14.2916 17.4401 9.99992 17.4401Z"
                    fill="#05668D"
                />
            </svg>
        </>
    );
};

export default SeeMoreIcon;
