import React from 'react';
import {Box} from '@mui/material';


const VerificationTick = () => {
  return (
    <Box sx={{
        width:'20px',
        height:'20px',
    }}>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.99984 1.66406C5.40817 1.66406 1.6665 5.40573 1.6665 9.9974C1.6665 14.5891 5.40817 18.3307 9.99984 18.3307C14.5915 18.3307 18.3332 14.5891 18.3332 9.9974C18.3332 5.40573 14.5915 1.66406 9.99984 1.66406ZM13.9832 8.08073L9.25817 12.8057C9.1415 12.9224 8.98317 12.9891 8.8165 12.9891C8.64984 12.9891 8.4915 12.9224 8.37484 12.8057L6.0165 10.4474C5.77484 10.2057 5.77484 9.80573 6.0165 9.56406C6.25817 9.3224 6.65817 9.3224 6.89984 9.56406L8.8165 11.4807L13.0998 7.1974C13.3415 6.95573 13.7415 6.95573 13.9832 7.1974C14.2248 7.43906 14.2248 7.83073 13.9832 8.08073Z" fill="#49B6FF"/>
        </svg>
    </Box>
  )
}

export default VerificationTick