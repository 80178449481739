import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import Alert from '../../assets/icons/Alert';
import emptyState from '../../assets/images/empyState.png'
import { Responsiveness } from '../../theme/overrides';

interface EmptyStateProps {
    message: string
}

const EmptyState = ({ message }: EmptyStateProps) => {
    const {isMobile, isTablet} = Responsiveness()
    return (
        <Stack
            height="500px"
            px={isMobile ? '16px' : isTablet ? '20px' : "40px"}
            pt={isMobile ? '16px' : "24px"}
            gap={isTablet ? '100px' : "45px"}
            mt="24px"
        >
            <Box display='flex' gap="4px" alignItems="flex-start">
                <Alert />
                <Typography
                    color="#808080"
                    fontFamily="Open Sans"
                    fontSize="14px"
                    fontStyle="normal"
                    fontWeight={400}
                    lineHeight="20px"
                    letterSpacing="0.049px"
                >
                    {message}
                </Typography>
            </Box>
            <img style={{alignSelf: 'center', width: isMobile ? '80%' : undefined}} src={emptyState} alt="empty" />
        </Stack>
    );
};

export default EmptyState
