export enum Employment {
    FullTime = 'FULL_TIME',
    permanent = 'PERMANENT',
    temporary = 'TEMPORARY',
    newGrad = 'NEW_GRAD',
    PartTime = 'PART_TIME',
    Contract = 'CONTRACT',
    Internship = 'INTERNSHIP'
}
export enum CompanySize {
    family = 'Family-owned, family ran companies',
    small = 'Small to medium sized private companies',
    medium = 'Medium sized public companies',
    large = 'Large corporation, not in Fortune 500 list',
    fortune500 = 'Fortune 500, ivy league companies'
}

export enum EmploymentLevel {
    INTERN = 'INTERN',
    JUNIOR = 'JUNIOR',
    MID = 'MID',
    SENIOR = 'SENIOR',
    MANAGER = 'MANAGER',
    DIRECTOR = 'DIRECTOR',
    C_LEVEL = 'C_LEVEL',
    OWNER = 'OWNER'
}
