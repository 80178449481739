
const ImageGallery = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M17.8897 14.2128L17.8892 14.2115L15.2808 8.11152L15.2805 8.11065C14.8393 7.07349 14.2545 6.64999 13.7232 6.6226L13.7229 6.62259C13.2006 6.59539 12.5699 6.95678 12.0195 7.94913L12.019 7.94997L10.436 10.7911L17.8897 14.2128ZM17.8897 14.2128C18.265 15.0838 18.1792 16.078 17.6435 16.8675L17.6435 16.8675L17.6401 16.8726C17.1162 17.6655 16.2314 18.1399 15.2822 18.1399H4.64889C3.66417 18.1399 2.76652 17.6383 2.2491 16.8019C1.73333 15.9682 1.68906 14.9439 2.12969 14.0547C2.12984 14.0544 2.13 14.0541 2.13016 14.0538L3.57022 11.182C3.57032 11.1818 3.57043 11.1816 3.57053 11.1814C3.94368 10.4427 4.45946 10.0739 4.99031 10.0208C5.52906 9.96691 6.10872 10.214 6.62361 10.8587L6.80553 11.0902M17.8897 14.2128L6.80553 11.0902M6.80553 11.0902C7.29737 11.7171 7.97187 12.1159 8.71771 12.0464M6.80553 11.0902L8.71771 12.0464M8.71771 12.0464C9.45136 11.9857 10.0509 11.4839 10.4357 10.7916L8.71771 12.0464Z" fill="#05668D" stroke="#05668D" />
            <path d="M8.12552 4.16823C8.12552 5.44769 7.08831 6.4849 5.80885 6.4849C4.52939 6.4849 3.49219 5.44769 3.49219 4.16823C3.49219 2.88877 4.52939 1.85156 5.80885 1.85156C7.08831 1.85156 8.12552 2.88877 8.12552 4.16823Z" fill="#05668D" stroke="#05668D" />
        </svg>
    )
}

export default ImageGallery