import axiosInstance from '../axiosConfig';
import { UseMutationResult, useMutation } from 'react-query';
import { ICommentRequest } from './types';

async function postComment(postRequest: ICommentRequest) {
    const response = await axiosInstance.post(
        `/api/post-comments`,
        postRequest
    );
    const data = await response.data;
    return data;
}
export function usePostComment(): UseMutationResult<
    any,
    unknown,
    ICommentRequest
> {
    return useMutation(postComment);
}
