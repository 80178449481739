import React from 'react';

function BellIcon() {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.8253 11.7173L15.8837 10.1507C15.6753 9.80898 15.492 9.15065 15.492 8.75065V7.19232C15.492 4.16732 13.0337 1.70898 10.017 1.70898C6.992 1.71732 4.53367 4.16732 4.53367 7.19232V8.74232C4.53367 9.14232 4.35033 9.80065 4.15033 10.1423L3.20867 11.709C2.85033 12.3173 2.767 13.009 2.992 13.609C3.217 14.2173 3.72533 14.7007 4.392 14.9173C5.292 15.2173 6.20033 15.434 7.12533 15.5923C7.217 15.609 7.30866 15.6173 7.40033 15.634C7.517 15.6507 7.642 15.6673 7.767 15.684C7.98366 15.7173 8.20033 15.7423 8.42533 15.759C8.95033 15.809 9.48367 15.834 10.017 15.834C10.542 15.834 11.067 15.809 11.5837 15.759C11.7753 15.7423 11.967 15.7257 12.1503 15.7007C12.3003 15.684 12.4503 15.6673 12.6003 15.6423C12.692 15.634 12.7837 15.6173 12.8753 15.6007C13.8087 15.4507 14.7337 15.2173 15.6337 14.9173C16.2753 14.7007 16.767 14.2173 17.0003 13.6007C17.2337 12.9757 17.167 12.2923 16.8253 11.7173ZM10.6253 8.33398C10.6253 8.68398 10.342 8.96732 9.992 8.96732C9.642 8.96732 9.35866 8.68398 9.35866 8.33398V5.75065C9.35866 5.40065 9.642 5.11732 9.992 5.11732C10.342 5.11732 10.6253 5.40065 10.6253 5.75065V8.33398Z"
                fill="#735722"
            />
            <path
                d="M12.3568 16.6743C12.0068 17.641 11.0818 18.3327 9.99844 18.3327C9.3401 18.3327 8.6901 18.066 8.23177 17.591C7.9651 17.341 7.7651 17.0077 7.64844 16.666C7.75677 16.6827 7.8651 16.691 7.98177 16.7077C8.17344 16.7327 8.37344 16.7577 8.57344 16.7743C9.04844 16.816 9.53177 16.841 10.0151 16.841C10.4901 16.841 10.9651 16.816 11.4318 16.7743C11.6068 16.7577 11.7818 16.7493 11.9484 16.7243C12.0818 16.7077 12.2151 16.691 12.3568 16.6743Z"
                fill="#735722"
            />
        </svg>
    );
}

export default BellIcon;
