import 'react-phone-number-input/style.css';
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import InputBase from '@mui/material/InputBase';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';

import cover from '../../../../assets/images/cover.jpg';
import CommonDialog from '../../../common/CommonDialog';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';

import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Image from '../../../../components/Image';
import ProfileFormIcon from '../../../../assets/icons/ProfileFormIcon';
import ProfileCircleIcon from '../../../../assets/icons/ProfileCircleIcon';

import CameraIcon from '../../../../assets/icons/CameraIconMui';
import TrashIcon from '../../../../assets/icons/TrashIconMui';
import { useQuery, useQueryClient } from 'react-query';
import { useSaveProfessionalProfile } from '../../../../utils/hooks/api/professional-profile/useSaveProfessionalProfile';
import { List, MenuItem, Radio, Select, Stack, TextField } from '@mui/material';
import ProfileInfoListItem from '../ProfileInfoListItem';
import dayjs, { Dayjs } from 'dayjs';
import { Address } from '../../../../utils/hooks/api/users/types';
import {
    getCountryCallingCode,
    isPossiblePhoneNumber
} from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import Dropdown from '../../../../assets/icons/Dropdown';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import Flag from '../../../../assets/icons/Flag';
import { useGetCountries } from '../../../../utils/hooks/api/cityState/useGetCountries';
import { IStateRequest } from '../../../../utils/hooks/api/cityState/type';
import Calendar from '../Calendar';
import {
    Gender,
    genders
} from '../../../../utils/hooks/api/professional-profile/types';
import externalAxiosInstance from '../../../../utils/hooks/api/externalAxiosConfig';
import SnackAlert from '../../../../components/Snackbar';
import { QueryKeys } from '../../../../utils/hooks/api/QueryKey';
import { useAuth } from '../../../../utils/context/AuthContext';
import { useUploadProfessionalProfilePhoto } from '../../../../utils/hooks/api/users/useUploadProfessionalProfilePhoto';
import { useGetProfessionalProfileById } from '../../../../utils/hooks/api/professional-profile/useGetProfessionalProfile';
import usePatchUserInfo from '../../../../utils/hooks/api/users/usePatchUserInfo';
import CopyIcon from '../../../../assets/icons/CopyIcon';
import axiosInstance from '../../../../utils/hooks/api/axiosConfig';
import CloseXIcon from '../../../../assets/icons/CloseXIcon';
import { Responsiveness } from '../../../../theme/overrides';

interface IEditProfileInfoDialogProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IUserInfo {
    firstName: string;
    lastName: string;
    address: Address;
    phoneNumber: string;
    gender: string;
    dateOfBirth: string;
}

const EditProfileInfoDialog = React.forwardRef(
    (props: IEditProfileInfoDialogProps, ref) => {
        const { user, setUser } = useAuth();
        const imageRef = useRef<HTMLInputElement>(null);
        const { open, setOpen } = props;
        const {
            control,
            handleSubmit,
            register,
            formState: { errors },
            setValue,
            clearErrors,
            resetField,
            setError,
            reset
        } = useForm<IUserInfo>();
        const updateProfileInfo = usePatchUserInfo();
        const { data: userProf, refetch } = useGetProfessionalProfileById(
            user?.professionalProfile?.id ?? ''
        );
        const [message, setMessage] = useState('');
        const [showAlert, setShowAlert] = useState(false);
        const [type, setType] = useState<
            'success' | 'info' | 'warning' | 'error'
        >('info');
        const [country, setCountry] = useState<IStateRequest>({ iso2: '' });
        const [selectedState, setSelectedState] = useState({
            value: '',
            iso2: ''
        });
        const [disableStateDropDown, setDisableDropdown] = useState(false);
        const [selectedCity, setSelectedCity] = useState('');
        const [statesList, setStatesList] = useState([]);
        const [citiesList, setCitiesList] = useState([]);
        const [disableCityDropDown, setDisableCityDropdown] = useState(false);
        const queryClient = useQueryClient();
        const uploadImage = useUploadProfessionalProfilePhoto();
        const { isMobile } = Responsiveness()

        const { data: countryList } = useGetCountries();
        const [copied, setCopied] = React.useState(false);
    const copyToClipboard = () => {
        navigator.clipboard.writeText(
            `${process.env.REACT_APP_BASE_URL}/users`
        );
        setShowAlert(true);
        setMessage('Url copied')
        setType('success')
    };

        const handleOnClose = () => {
            setOpen(false);
            reset();
        };
        const [aboutMe, setAboutMe] = useState(user?.professionalProfile?.bio);
        const handleSave = async () => {
            await axiosInstance.patch(
                `api/professional-profiles/${user?.professionalProfile?.id}`,
                { bio: aboutMe }
            );
        };
        const onSubmit: SubmitHandler<IUserInfo> = async (data) => {
            if (isMobile)
                handleSave()
            if (!isPossiblePhoneNumber(data?.phoneNumber)) {
                //
                setError('phoneNumber', {
                    type: 'required',
                    message: 'Invalid Phone number'
                });
                return;
            }
            const temp = {
                // tagline: userProf.tagline,
                bio: userProf.bio,
                professionalProfileId:
                    user?.professionalProfile?.id ?? userProf.id,
                firstName: data?.firstName ?? userProf.firstName,
                lastName: data?.lastName ?? userProf.lastName,
                emailAddress:
                    user?.professionalProfile?.emailAddress ??
                    userProf.emailAddress,
                gender: data?.gender ?? userProf.gender,
                dateOfBirth: data.dateOfBirth ?? userProf.dateOfBirth,
                address: {
                    street1: data.address.street1 ?? userProf.address.street1,
                    street2: '',
                    stateProvince:
                        data.address.stateProvince ??
                        userProf.address.stateProvince,
                    zipPostCode:
                        data.address.zipPostCode ??
                        userProf.address.zipPostCode,
                    country: data.address.country ?? userProf.address.country,
                    city: data.address.city ?? userProf.address.city,
                    lastUpdatedAt: Date.now()
                },
                phoneNumber: data?.phoneNumber
            };
            setUser({
                ...user,
                professionalProfile: {
                    ...user?.professionalProfile,
                    ...temp,
                    address: {
                        ...user?.professionalProfile?.address,
                        ...temp?.address
                    }
                }
            });

            updateProfileInfo.mutate(temp, {
                onSuccess: (res) => {
                    queryClient.invalidateQueries(
                        QueryKeys.RetrieveProfessionalProfileById
                    );
                    console.log('onSuccess', res);
                    setType('success');
                    setMessage('Profile updated successfully.');
                    setShowAlert(true);
                    setOpen(false);
                },
                onError: (error) => {
                    console.log('onError', error);
                    setType('error');
                    setMessage('Error occured please try again!');
                    setShowAlert(true);
                }
            });
        };

        const { data: states } = useQuery(
            ['country', country],
            async () => {
                const result = await externalAxiosInstance.get(
                    `/countries/${country?.iso2}/states`
                );
                const sortedResult = result.data.sort(
                    (a: { name: string }, b: { name: string }) =>
                        a.name > b.name ? 1 : -1
                );
                setStatesList(sortedResult);
                if (result.data.length === 0) {
                    setDisableDropdown(true);
                    resetField('address.stateProvince');
                } else {
                    setDisableDropdown(false);
                }
            },
            {
                refetchOnWindowFocus: false,
                enabled: country?.iso2 ? true : false //disable the query:
                //this is how we keep it from running on component mount.
            }
        );

        const { data: cities } = useQuery(
            ['state', selectedState.iso2],
            async () => {
                const result = await externalAxiosInstance.get(
                    `/countries/${country?.iso2}/states/${selectedState.iso2}/cities`
                );
                const sortedResult = result.data.sort(
                    (a: { name: string }, b: { name: string }) =>
                        a.name > b.name ? 1 : -1
                );
                setCitiesList(sortedResult);
                if (result.data.length === 0) {
                    setDisableCityDropdown(true);
                    resetField('address.city');
                } else {
                    setDisableCityDropdown(false);
                }
            },
            {
                refetchOnWindowFocus: false,
                enabled: selectedState?.iso2 ? true : false //disable the query:
                //this is how we keep it from running on component mount.
            }
        );

        const handleChangeFile = (e: any) => {
            const file = e.target.files[0];
            console.log(file);
            let data = {
                profileId: user?.professionalProfile?.id ?? '',
                file: file
            };
            uploadImage.mutate(data, {
                onSuccess: (res) => {
                    // setImage(String(res));
                    let tempData = {
                        ...user,
                        professionalProfile: {
                            ...user?.professionalProfile,
                            photoUrl: res
                        }
                    };
                    setUser(tempData);
                    setMessage('Profile Image Updated Successfully.');
                    setType('success');
                    setShowAlert(true);
                },
                onError: (res) => {
                    setMessage('Error occured please try again!');
                    setType('error');
                    setShowAlert(true);
                }
            });
        };

        useEffect(() => {
            if (open) {
                // setImage(user?.professionalProfile?.photoUrl ?? '');
                setValue('firstName', userProf.firstName ?? '');
                setValue('lastName', userProf.lastName ?? '');
                setValue('dateOfBirth', userProf.dateOfBirth ?? '');
                setValue('gender', userProf.gender ?? '');
                setValue('phoneNumber', userProf.phoneNumber ?? '');
                setValue('address.city', userProf.address?.city ?? '');
                setValue('address.country', userProf.address?.country ?? '');
                setValue('address.zipPostCode', userProf.address?.zipPostCode);
                setValue(
                    'address.stateProvince',
                    userProf.address?.stateProvince
                );
                setValue('address.street1', userProf.address?.street1 ?? '');
                setSelectedState({
                    value: userProf.address?.stateProvince ?? '',
                    iso2: ''
                });
                setSelectedCity(userProf.address?.city ?? '');
                setCountry({
                    iso2: userProf.address?.country ?? ''
                });
            }
        }, [open, userProf, setValue]);

        
        // const handleOnClose = () => {
        //     setOpen(false);
        // };

        const maxCount = '2500';

        const handleTextareaChange = (
            event: ChangeEvent<HTMLTextAreaElement>
        ) => {
            setAboutMe(event.target.value);
        };

        
        const [isFocused, setIsFocused] = useState(false);


        return (
            <CommonDialog
                open={open}
                onClose={handleOnClose}
                title={'Edit profile'}
                contentPaddingX={false}
                actions={
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        px={{ xs: 2, sm: 4 }}
                    >
                        {/* <Button
                            variant="outlined"
                            sx={{
                                display: { xs: 'none', sm: 'block' },
                                backgroundColor: 'transparent',
                                width: 'auto',
                                height: '36px',
                                borderRadius: '20px',
                                lineHeight: 1
                            }}
                            startIcon={<PeopleAltIcon htmlColor="#49B6FF" />}
                            endIcon={<ArrowDropDownIcon />}
                        >
                            <Typography
                                fontWeight={400}
                                fontSize={14}
                                sx={{
                                    textTransform: 'none'
                                }}
                                lineHeight={1}
                            >
                                Connections
                            </Typography>
                        </Button> */}
                        <Button
                            variant="contained"
                            sx={{
                                color: '#FFFFFF',
                                width: { xs: '77px', sm: '150px' }
                            }}
                            onClick={handleSubmit(onSubmit)}
                        >
                            <Typography
                                fontWeight={600}
                                fontSize={14}
                                sx={{
                                    textTransform: 'none',
                                    py: { xs: 0, sm: 0.75 }
                                }}
                            >
                                Save
                            </Typography>
                        </Button>
                    </Box>
                }
            >
                <Box
                    bgcolor="#fff"
                    // overflow="hidden"
                    pb={{ xs: 1, sm: 0 }}
                    mb={{ xs: 1, sm: 0 }}
                    sx={{ mt: { xs: 1, sm: 0 }, pt: { xs: 0, sm: '12px' } }}
                >
                    <Box position="relative" pb={{ xs: 10, sm: 6 }}>
                        <Grid
                            container
                            alignItems="center"
                            justifyContent="space-around"
                            position="relative"
                            height={{ xs: '100px', sm: '200px' }}
                            bgcolor="#fff"
                        >
                            <Box
                                sx={{
                                    height: '200px',
                                    width: '100%',
                                    position: 'absolute'
                                }}
                                display={{ xs: 'none', sm: 'block' }}
                            >
                                <img
                                    style={{
                                        height: '200px',
                                        width: '100%',
                                        objectFit: 'cover',
                                        display: 'block',
                                        position: 'absolute'
                                    }}
                                    alt="Cover"
                                    src={user?.professionalProfile?.photoUrl}
                                />
                            </Box>
                            <Box
                                sx={{
                                    height: '100px',
                                    width: '100%',
                                    position: 'absolute'
                                }}
                                display={{ xs: 'block', sm: 'none' }}
                            >
                                <img
                                    style={{
                                        height: '100px',
                                        width: '100%',
                                        objectFit: 'cover',
                                        display: 'block',
                                        position: 'absolute'
                                    }}
                                    alt="Cover"
                                    src={user?.professionalProfile?.photoUrl}
                                />
                            </Box>

                            <Grid container gap={2.5} width="auto">
                                <IconButton
                                    style={{
                                        backgroundColor: '#0000003F',
                                        color: '#FFFFFF',
                                        width: '44px',
                                        height: '44px'
                                    }}
                                >
                                    <CameraIcon />
                                </IconButton>
                                <IconButton
                                    style={{
                                        backgroundColor: '#0000003F',
                                        color: '#FFFFFF',
                                        width: '44px',
                                        height: '44px'
                                    }}
                                >
                                    <TrashIcon />
                                </IconButton>
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            position={'absolute'}
                            bottom={0}
                            justifyContent="center"
                            alignItems="center"
                            width={{ xs: '100px', sm: '150px' }}
                            height={{ xs: '100px', sm: '150px' }}
                            ml={4}
                        >
                            <Box
                                borderRadius="50%"
                                overflow="hidden"
                                width="fit-content"
                                position="absolute"
                                bottom={0}
                            >
                                <Image
                                    src={
                                        user?.professionalProfile?.photoUrl ??
                                        ''
                                    }
                                    sx={{
                                        width: { xs: '100px', sm: '150px' },
                                        height: { xs: '100px', sm: '150px' },
                                        objectFit: 'cover'
                                    }}
                                    alt="Ronald Richard"
                                    border="3px #fff solid"
                                    borderRadius="50%"
                                    display="block"
                                />
                            </Box>
                            <Box position={'absolute'}>
                                <IconButton
                                    style={{
                                        backgroundColor: '#0000003F',
                                        color: '#FFFFFF',
                                        width: '44px',
                                        height: '44px'
                                    }}
                                    onClick={() => imageRef?.current?.click()}
                                >
                                    <CameraIcon />
                                    <input
                                        type="file"
                                        onChange={handleChangeFile}
                                        ref={imageRef}
                                        style={{ display: 'none' }}
                                        accept="image/png, image/gif, image/jpeg"
                                    />
                                </IconButton>
                            </Box>
                        </Grid>
                    </Box>
                </Box>
                <Box display={{ xs: 'block', sm: 'none' }} p='8px 16px' bgcolor='#fff' mt='8px' mb='4px' >
                    <Typography color='#23282B' fontSize='16px' fontWeight={600}>
                        Profile Information
                    </Typography>
                </Box>
                <Box
                    sx={{
                        // width: '90%',
                        px: { xs: '16px', sm: '32px' },
                        mx: 'auto',
                        bgcolor: '#fff'
                    }}
                >
                    <List
                        sx={
                            {
                                // width: '100%'
                            }
                        }
                        component="nav"
                    >
                        <Box
                            width="fit-content"
                            display="flex"
                            alignItems="flex-end"
                            justifyContent="flex-start"
                            gap="24px"
                        >
                            <ProfileInfoListItem
                                title="Profile URL"
                                subtitle={`${process.env.REACT_APP_BASE_URL}/users`}
                                onClick={() => {}}
                            />
                            <Box onClick={copyToClipboard}>
                                <CopyIcon />
                            </Box>
                        </Box>
                        <ProfileInfoListItem
                            title="Email"
                            subtitle={user?.professionalProfile?.emailAddress}
                            onClick={() => {
                                /* handle navigate email link */
                            }}
                        />
                    </List>
                    <Grid
                        container
                        spacing={1}
                        sx={{ wrap: { xs: 'wrap', sm: 'nowrap' } }}
                    >
                        <Grid item xs={12} sm={6}>
                            <Box sx={{ position: 'relative' }}>
                                <InputLabel
                                    sx={{
                                        color: errors.firstName?.message
                                            ? 'red'
                                            : '#ADAAAA',
                                        fontSize: '14px',
                                        position: 'absolute',
                                        top: '8px',
                                        left: '72px',
                                        zIndex: 1,
                                        fontFamily: 'Open Sans'
                                    }}
                                    htmlFor="firstName"
                                >
                                    First name *
                                </InputLabel>
                                <Controller
                                    {...register('firstName', {
                                        required: 'Required field'
                                    })}
                                    name="firstName"
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                        formState
                                    }) => (
                                        <InputBase
                                            required
                                            value={value}
                                            onChange={onChange}
                                            inputProps={{
                                                autoComplete: '',
                                                form: {
                                                    autoComplete: 'off'
                                                },
                                                inputMode: 'text'
                                            }}
                                            id="firstName"
                                            startAdornment={<ProfileFormIcon />}
                                            rows={1}
                                            sx={{
                                                backgroundColor: '#f2f2f2',
                                                width: '100%',
                                                height: '70px',
                                                fontSize: '14px',
                                                fontWeight: 400,
                                                padding: '0px 16px',
                                                fontFamily: 'open sans',
                                                color: '#23282B',
                                                borderBottom:
                                                    '1px solid #C5C5C5',
                                                mb: '8px',
                                                '& 	.MuiInputBase-input': {
                                                    ml: '20px',
                                                    position: 'relative',
                                                    top: '15px'
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box sx={{ position: 'relative' }}>
                                <InputLabel
                                    sx={{
                                        color: errors.lastName?.message
                                            ? 'red'
                                            : '#ADAAAA',
                                        fontSize: '14px',
                                        position: 'absolute',
                                        top: '8px',
                                        left: '72px',
                                        zIndex: 1,
                                        fontFamily: 'Open Sans'
                                    }}
                                    htmlFor="lastName"
                                >
                                    Last name *
                                </InputLabel>
                                <Controller
                                    {...register('lastName', {
                                        required: 'Required field'
                                    })}
                                    name="lastName"
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                        formState
                                    }) => (
                                        <InputBase
                                            required
                                            value={value}
                                            onChange={onChange}
                                            inputProps={{
                                                autoComplete: '',
                                                form: {
                                                    autoComplete: 'off'
                                                },
                                                inputMode: 'text'
                                            }}
                                            id="lastName"
                                            startAdornment={<ProfileFormIcon />}
                                            rows={1}
                                            sx={{
                                                backgroundColor: '#f2f2f2',
                                                width: '100%',
                                                height: '70px',
                                                fontSize: '14px',
                                                fontWeight: 400,
                                                padding: '0px 16px',
                                                fontFamily: 'open sans',
                                                color: '#23282B',
                                                borderBottom:
                                                    '1px solid #C5C5C5',
                                                mb: '8px',
                                                '& 	.MuiInputBase-input': {
                                                    ml: '20px',
                                                    position: 'relative',
                                                    top: '15px'
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={1}
                        sx={{ wrap: { xs: 'wrap', sm: 'nowrap' } }}
                        mb={'8px'}
                    >
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{
                                '& .MuiInputBase-root': {
                                    m: 0,
                                    height: '70px !important',
                                    bgcolor: '#f2f2f2'
                                }
                            }}
                        >
                            <Box sx={{ position: 'relative' }}>
                                <Controller
                                    {...register('dateOfBirth')}
                                    name="dateOfBirth"
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                        formState
                                    }) => (
                                        <Calendar
                                            tab
                                            error={
                                                errors.dateOfBirth?.message
                                                    ? true
                                                    : false
                                            }
                                            label="Date of birth"
                                            defaultValue={value}
                                            handleChange={(
                                                e: dayjs.Dayjs | null
                                            ) => {
                                                onChange(
                                                    dayjs(e).format(
                                                        'MM/DD/YYYY'
                                                    )
                                                );
                                                console.log(e);
                                                if (!e) {
                                                    setError('dateOfBirth', {
                                                        type: 'required',
                                                        message: '*'
                                                    });
                                                } else {
                                                    clearErrors('dateOfBirth');
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box sx={{ position: 'relative' }}>
                                <InputLabel
                                    sx={{
                                        color: errors.gender?.message
                                            ? 'red'
                                            : '#ADAAAA',
                                        fontSize: '14px',
                                        position: 'absolute',
                                        top: '8px',
                                        left: '72px',
                                        zIndex: 1,
                                        fontFamily: 'Open Sans'
                                    }}
                                >
                                    Gender *
                                </InputLabel>

                                <Controller
                                    {...register('gender')}
                                    name="gender"
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error, isTouched },
                                        formState
                                    }) => (
                                        <Select
                                            value={value}
                                            onChange={onChange}
                                            fullWidth
                                            variant="filled"
                                            IconComponent={() => <Dropdown />}
                                            sx={{
                                                backgroundColor: '#f2f2f2',
                                                borderBottom:
                                                    '1px solid #C5C5C5',
                                                width: '100%',
                                                height: '70px',
                                                padding: '0px 16px',
                                                fontSize: 14,
                                                color: '#23282B',
                                                '& 	.MuiInputBase-input': {
                                                    ml: '20px',
                                                    top: '8px',
                                                    position: 'relative'
                                                },
                                                '& 	.MuiInputBase-input:focus': {
                                                    backgroundColor:
                                                        'transparent'
                                                }
                                            }}
                                            renderValue={(e) => (
                                                <p>{value || ''}</p>
                                            )}
                                            startAdornment={
                                                <ProfileCircleIcon />
                                            }
                                            defaultValue={''}
                                        >
                                            {genders?.map((item: any) => (
                                                <MenuItem
                                                    key={item.key}
                                                    value={item.value}
                                                >
                                                    <Radio
                                                        checked={
                                                            value === item.value
                                                        }
                                                    />{' '}
                                                    {item.value}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={1}
                        sx={{ wrap: { xs: 'wrap', sm: 'nowrap' } }}
                        mb={'8px'}
                    >
                        <Grid item xs={12} sm={6}>
                            <Box sx={{ position: 'relative' }}>
                                <InputLabel
                                    sx={{
                                        color: errors.address?.country?.message
                                            ? 'red'
                                            : '#ADAAAA',
                                        fontSize: '14px',
                                        position: 'absolute',
                                        top: '8px',
                                        left: '72px',
                                        zIndex: 1,
                                        fontFamily: 'Open Sans'
                                    }}
                                >
                                    Country *
                                </InputLabel>

                                <Controller
                                    {...register('address.country', {
                                        required: '*'
                                    })}
                                    name="address.country"
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error, isTouched },
                                        formState
                                    }) => (
                                        <Select
                                            value={value}
                                            onChange={(e) => {
                                                onChange(e);
                                                console.log(e.target);
                                                setCountry({
                                                    iso2: e.target.value
                                                });
                                                setSelectedState({
                                                    value: '',
                                                    iso2: ''
                                                });
                                                setSelectedCity('');
                                                resetField(
                                                    'address.stateProvince'
                                                );
                                                resetField('address.city');
                                                if (statesList.length === 0) {
                                                    clearErrors(
                                                        'address.stateProvince'
                                                    );
                                                }
                                                if (citiesList.length === 0) {
                                                    clearErrors('address.city');
                                                }
                                            }}
                                            fullWidth
                                            variant="filled"
                                            IconComponent={() => <Dropdown />}
                                            sx={{
                                                backgroundColor: '#f2f2f2',
                                                borderBottom:
                                                    '1px solid #C5C5C5',
                                                width: '100%',
                                                height: '70px',
                                                padding: '0px 16px',
                                                fontSize: 14,
                                                color: '#23282B',
                                                '& 	.MuiInputBase-input': {
                                                    ml: '20px',
                                                    top: '8px',
                                                    position: 'relative'
                                                },
                                                '& 	.MuiInputBase-input:focus': {
                                                    backgroundColor:
                                                        'transparent'
                                                }
                                            }}
                                            renderValue={(e) => (
                                                <p>{value || ''}</p>
                                            )}
                                            startAdornment={<Flag />}
                                            defaultValue={''}
                                        >
                                            {countryList
                                                ?.sort(
                                                    (
                                                        a: { name: string },
                                                        b: { name: string }
                                                    ) =>
                                                        a.name > b.name ? 1 : -1
                                                )
                                                ?.map((country: any) => (
                                                    <MenuItem
                                                        key={country.id}
                                                        value={country.iso2}
                                                    >
                                                        <Radio
                                                            checked={
                                                                value ===
                                                                    country.iso2 ||
                                                                false
                                                            }
                                                        />{' '}
                                                        {country.name} (
                                                        {country.iso2})
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    )}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box sx={{ position: 'relative' }}>
                                <InputLabel
                                    sx={{
                                        color: errors.phoneNumber?.message
                                            ? 'red'
                                            : '#ADAAAA',
                                        fontSize: '14px',
                                        position: 'absolute',
                                        top: '8px',
                                        left: '16px',
                                        zIndex: 1,
                                        fontFamily: 'Open Sans'
                                    }}
                                    htmlFor="phoneNumber"
                                >
                                    Phone Number
                                </InputLabel>
                                <Controller
                                    {...register('phoneNumber')}
                                    name="phoneNumber"
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                        formState
                                    }) => (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                backgroundColor: '#f2f2f2',
                                                width: '100%',
                                                height: '70px',
                                                borderBottom:
                                                    '1px solid #C5C5C5',
                                                padding: '0px 16px',
                                                fontFamily: 'open sans',
                                                color: '#23282B',
                                                gap: '5px',
                                                paddingTop: '12px',
                                                '& input, input:focus': {
                                                    border: 0,
                                                    outline: 0,
                                                    background: 'transparent'
                                                },
                                                '& .PhoneInput': {
                                                    mt: '8px'
                                                },
                                                '& .PhoneInputCountryIcon--border, .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border':
                                                    {
                                                        boxShadow: 0
                                                    },
                                                '& .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow':
                                                    {
                                                        color: '#C5C5C5'
                                                    }
                                            }}
                                        >
                                            <PhoneInput
                                                placeholder="Enter phone number"
                                                flags={flags}
                                                value={value}
                                                limitMaxLength={true}
                                                onCountryChange={(e) => {
                                                    if (e) {
                                                        var code =
                                                            getCountryCallingCode(
                                                                e
                                                            );
                                                        console.log(code);
                                                        onChange('+' + code);
                                                    }
                                                }}
                                                rules={{
                                                    required: true,
                                                    validate:
                                                        isPossiblePhoneNumber
                                                }}
                                                onChange={onChange}
                                            />
                                        </Box>
                                    )}
                                />
                                <Typography
                                    color="red"
                                    variant="titleSmallSemiBold"
                                >
                                    {errors.phoneNumber?.message}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={1}
                        sx={{ wrap: { xs: 'wrap', sm: 'nowrap' } }}
                        mb={'8px'}
                    >
                        <Grid item xs={6}>
                            <Box sx={{ position: 'relative' }}>
                                <InputLabel
                                    sx={{
                                        color: errors.address?.stateProvince
                                            ?.message
                                            ? 'red'
                                            : '#ADAAAA',
                                        fontSize: '14px',
                                        position: 'absolute',
                                        top: '8px',
                                        left: '16px',
                                        zIndex: 1,
                                        fontFamily: 'Open Sans'
                                    }}
                                >
                                    State *
                                    {errors.address?.stateProvince?.message}
                                </InputLabel>

                                <Controller
                                    {...register('address.stateProvince')}
                                    name="address.stateProvince"
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                        formState
                                    }) => (
                                        <Select
                                            value={value}
                                            onChange={(e) => {
                                                onChange(e);
                                                setSelectedCity('');
                                                resetField('address.city');
                                            }}
                                            fullWidth
                                            variant="filled"
                                            IconComponent={() => <Dropdown />}
                                            sx={{
                                                backgroundColor: '#f2f2f2',
                                                borderBottom:
                                                    '1px solid #C5C5C5',
                                                width: '100%',
                                                height: '70px',
                                                padding: '0px 16px',
                                                pl: '5px',
                                                fontSize: 14,
                                                color: '#23282B',
                                                '& 	.MuiInputBase-input': {
                                                    top: '8px',
                                                    position: 'relative'
                                                },
                                                '& 	.MuiInputBase-input:focus': {
                                                    backgroundColor:
                                                        'transparent'
                                                }
                                            }}
                                            renderValue={(e) => (
                                                <p>
                                                    {selectedState.value || ''}
                                                </p>
                                            )}
                                            defaultValue={''}
                                            disabled={disableStateDropDown}
                                        >
                                            {statesList?.map((state: any) => (
                                                <MenuItem
                                                    key={state.id}
                                                    value={state.name}
                                                    onClick={() =>
                                                        setSelectedState({
                                                            value: state.name,
                                                            iso2: state.iso2
                                                        })
                                                    }
                                                >
                                                    <Radio
                                                        checked={
                                                            value === state.name
                                                        }
                                                    />{' '}
                                                    {state.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                            </Box>
                        </Grid>
                        <Grid item display={{ xs: 'block', sm: 'none' }} xs={6}>
                            <Box sx={{ position: 'relative' }}>
                                <InputLabel
                                    sx={{
                                        color: errors.address?.zipPostCode
                                            ?.message
                                            ? 'red'
                                            : '#ADAAAA',
                                        fontSize: '14px',
                                        position: 'absolute',
                                        top: '8px',
                                        left: '16px',
                                        zIndex: 1,
                                        fontFamily: 'Open Sans'
                                    }}
                                >
                                    Zipcode
                                </InputLabel>
                                <Controller
                                    {...register('address.zipPostCode')}
                                    name="address.zipPostCode"
                                    control={control}
                                    render={({
                                        field: { onChange, value, ref, onBlur },
                                        fieldState: { error },
                                        formState
                                    }) => (
                                        <InputBase
                                            required
                                            value={value}
                                            onChange={(e) => {
                                                onChange(e);
                                            }}
                                            id="address.zipPostCode"
                                            fullWidth
                                            sx={{
                                                backgroundColor: '#f2f2f2',
                                                width: '100%',
                                                height: '70px',
                                                fontSize: '14px',
                                                fontWeight: 400,
                                                padding: '0px 16px',
                                                fontFamily: 'open sans',
                                                color: '#23282B',
                                                borderBottom:
                                                    '1px solid #C5C5C5',
                                                mb: '8px',
                                                '& 	.MuiInputBase-input': {
                                                    position: 'relative',
                                                    top: '15px'
                                                }
                                            }}
                                            placeholder="Zip code"
                                            rows={1}
                                        />
                                    )}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box sx={{ position: 'relative' }}>
                                <InputLabel
                                    sx={{
                                        color: errors.address?.city?.message
                                            ? 'red'
                                            : '#ADAAAA',
                                        fontSize: '14px',
                                        position: 'absolute',
                                        top: '8px',
                                        left: '16px',
                                        zIndex: 1,
                                        fontFamily: 'Open Sans'
                                    }}
                                >
                                    City *
                                </InputLabel>

                                <Controller
                                    {...register('address.city', {
                                        required: '*'
                                    })}
                                    name="address.city"
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error, isTouched },
                                        formState
                                    }) => (
                                        <Select
                                            value={value}
                                            onChange={(e) => {
                                                onChange(e);
                                                console.log(e.target);
                                                setSelectedCity(e.target.value);
                                                if (citiesList?.length === 0) {
                                                    clearErrors('address.city');
                                                }
                                            }}
                                            fullWidth
                                            variant="filled"
                                            IconComponent={() => <Dropdown />}
                                            sx={{
                                                backgroundColor: '#f2f2f2',
                                                width: '100%',
                                                height: '70px',
                                                padding: '0px 16px',
                                                borderBottom:
                                                    '1px solid #C5C5C5',
                                                fontSize: 14,
                                                color: '#23282B',
                                                pl: '5px',
                                                '& 	.MuiInputBase-input': {
                                                    top: '8px',
                                                    position: 'relative'
                                                },
                                                '& 	.MuiInputBase-input:focus': {
                                                    backgroundColor:
                                                        'transparent'
                                                }
                                            }}
                                            renderValue={(e) => (
                                                <p>{selectedCity || ''}</p>
                                            )}
                                            defaultValue={''}
                                            disabled={disableCityDropDown}
                                        >
                                            {citiesList?.map((city: any) => (
                                                <MenuItem
                                                    key={city.id}
                                                    value={city.name}
                                                >
                                                    <Radio
                                                        checked={
                                                            value === city.name
                                                        }
                                                    />{' '}
                                                    {city.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={1}
                        sx={{ wrap: { xs: 'wrap', sm: 'nowrap' } }}
                    >
                        <Grid item xs={12} sm={6}>
                            <Box sx={{ position: 'relative' }}>
                                <InputLabel
                                    sx={{
                                        color: errors.address?.street1?.message
                                            ? 'red'
                                            : '#ADAAAA',
                                        fontSize: '14px',
                                        position: 'absolute',
                                        top: '8px',
                                        left: '16px',
                                        zIndex: 1,
                                        fontFamily: 'Open Sans'
                                    }}
                                >
                                    Address
                                </InputLabel>
                                <Controller
                                    {...register('address.street1')}
                                    name="address.street1"
                                    control={control}
                                    render={({
                                        field: { onChange, value, ref, onBlur },
                                        fieldState: { error },
                                        formState
                                    }) => (
                                        <InputBase
                                            required
                                            value={value}
                                            onChange={(e) => {
                                                onChange(e);
                                                if (!e.target.value) {
                                                    setError(
                                                        'address.street1',
                                                        {
                                                            type: 'required',
                                                            message: '*'
                                                        }
                                                    );
                                                } else {
                                                    clearErrors(
                                                        'address.street1'
                                                    );
                                                }
                                            }}
                                            placeholder="Please enter your street address"
                                            rows={1}
                                            sx={{
                                                backgroundColor: '#f2f2f2',
                                                width: '100%',
                                                height: '70px',
                                                fontSize: '14px',
                                                fontWeight: 400,
                                                padding: '0px 16px',
                                                fontFamily: 'open sans',
                                                color: '#23282B',
                                                borderBottom:
                                                    '1px solid #C5C5C5',
                                                mb: '20px',
                                                '& 	.MuiInputBase-input': {
                                                    position: 'relative',
                                                    top: '15px'
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </Box>
                        </Grid>
                        <Grid item display={{ xs: 'none', sm: 'block' }} xs={6}>
                            <Box sx={{ position: 'relative' }}>
                                <InputLabel
                                    sx={{
                                        color: errors.address?.zipPostCode
                                            ?.message
                                            ? 'red'
                                            : '#ADAAAA',
                                        fontSize: '14px',
                                        position: 'absolute',
                                        top: '8px',
                                        left: '16px',
                                        zIndex: 1,
                                        fontFamily: 'Open Sans'
                                    }}
                                >
                                    Zipcode
                                </InputLabel>
                                <Controller
                                    {...register('address.zipPostCode')}
                                    name="address.zipPostCode"
                                    control={control}
                                    render={({
                                        field: { onChange, value, ref, onBlur },
                                        fieldState: { error },
                                        formState
                                    }) => (
                                        <InputBase
                                            required
                                            value={value}
                                            onChange={(e) => {
                                                onChange(e);
                                            }}
                                            id="address.zipPostCode"
                                            fullWidth
                                            sx={{
                                                backgroundColor: '#f2f2f2',
                                                width: '100%',
                                                height: '70px',
                                                fontSize: '14px',
                                                fontWeight: 400,
                                                padding: '0px 16px',
                                                fontFamily: 'open sans',
                                                color: '#23282B',
                                                borderBottom:
                                                    '1px solid #C5C5C5',
                                                mb: '20px',
                                                '& 	.MuiInputBase-input': {
                                                    position: 'relative',
                                                    top: '15px'
                                                }
                                            }}
                                            placeholder="Zip code"
                                            rows={1}
                                        />
                                    )}
                                />
                            </Box>
                        </Grid>
                    </Grid>

                    {/* <ProfileInfoListItem title="Website">
                        <Button
                            disableRipple
                            variant="contained"
                            sx={{
                                backgroundColor: '#F2F2F2',
                                width: 'auto'
                            }}
                            startIcon={<AddIcon htmlColor="#05668D" />}
                        >
                            <Typography
                                color={'#05668D'}
                                fontFamily="open sans"
                                fontWeight={600}
                                fontSize={14}
                                sx={{ textTransform: 'none' }}
                            >
                                Add Website
                            </Typography>
                        </Button>
                    </ProfileInfoListItem> */}
                </Box>
                <Box display={{xs: 'block', sm: 'none'}}>
                    <Box px='16px' py="12px" mt="8px" mb="4px" bgcolor='#fff'>
                        <Typography
                            fontSize={'20px'}
                            fontWeight={600}
                            color="#23282B"
                        >
                            Bio
                        </Typography>
                        <Typography
                            variant="bodyLargeRegular"
                            color="#494949"
                            sx={{
                                mt: '4px',
                                fontSize: { xs: '14px', sm: '16px' },
                                mb: '8px'
                            }}
                        >
                            Write about your previous and current job
                            experiences, skills, and achievements. Maximum of
                            2500 characters.
                        </Typography>
                    </Box>
                    <Stack px='16px' py="8px" sx={{ bgcolor: '#fff' }} mb='30px'>
                        <Box
                            sx={
                                {
                                    // backgroundColor: '#f2f2f2',
                                    // color: '#808080',
                                    // p: 2
                                }
                            }
                        >
                            <TextField
                                fullWidth
                                value={aboutMe}
                                onChange={handleTextareaChange}
                                placeholder="Enter your about me"
                                sx={{
                                    bgcolor: '#f2f2f2',
                                    borderRadius: '8px',
                                    border: isFocused
                                        ? '2px solid #49B6FF'
                                        : 'none',
                                    '& fieldset,.Mui-focused fieldset': {
                                        border: '0 !important'
                                    },
                                    '& .MuiOutlinedInput-input': {
                                        color: '#23282B'
                                    },
                                    input: {
                                        p: '16px'
                                    }
                                }}
                                multiline
                                rows={4}
                                maxRows={4}
                                onFocus={() => setIsFocused(true)}
                                onBlur={() => setIsFocused(false)}
                            />
                            {/* <TextareaAutosize
                            style={{
                                width: '100%',
                                backgroundColor: '#f2f2f2',
                                color: '#808080',
                                fontFamily: 'Open Sans',
                                fontWeight: 400,
                                fontSize: '16px',
                                lineHeight: '24px',
                                border: 'none',
                                outline: 'none',
                                textAlign: 'justify'
                            }}
                            placeholder="Enter your about me"
                            value={aboutMe}
                            onChange={handleTextareaChange}
                            maxRows={5}
                        /> */}
                        </Box>
                        <Box
                            sx={{
                                mt: 1,
                                display: 'flex',
                                justifyContent: 'flex-end',
                                fontSize: { xs: '12px', sm: '16px' }
                            }}
                        >
                            {aboutMe?.length}/{maxCount}
                        </Box>
                    </Stack>
                </Box>
                <SnackAlert
                    open={showAlert}
                    handleClose={() => setShowAlert(false)}
                    message={message}
                    type={type}
                />
            </CommonDialog>
        );
    }
);

export default EditProfileInfoDialog;
