import { useQuery } from 'react-query';
import { QueryKeys } from '../QueryKey';
import axiosInstance from '../axiosConfig';

export function useGetProfessionalRatingByProfileId(id: string) {
    const getProfessionalRatingByProfileId = async (id: string) => {
        const response = await axiosInstance.get(
            `/api/professional-reviews-requests/${id}`
        );
        const data = await response.data;
        return data;
    };
    return useQuery([QueryKeys.RetrieveRaingByProfileId, id], () =>
        getProfessionalRatingByProfileId(id)
    );
}
