import { Box } from '@mui/material';
function MessagesIcon(props: { isHover: boolean, isSelected?: boolean, tab?: boolean }) {
    const { isHover, isSelected, tab } = props;
    if (isHover || isSelected) {
        return (
            <>
                <Box display={{ xs: 'none', lg: 'block' }}>
                    <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M13.59 10.4V14.47C13.59 14.83 13.55 15.17 13.46 15.48C13.09 16.95 11.87 17.87 10.19 17.87H7.47L4.45 19.88C4 20.19 3.4 19.86 3.4 19.32V17.87C2.38 17.87 1.53 17.53 0.94 16.94C0.34 16.34 0 15.49 0 14.47V10.4C0 8.5 1.18 7.19 3 7.02C3.13 7.01 3.26 7 3.4 7H10.19C12.23 7 13.59 8.36 13.59 10.4Z"
                            fill="#05668D"
                        />
                        <path
                            d="M15.75 13.6C17.02 13.6 18.09 13.18 18.83 12.43C19.58 11.69 20 10.62 20 9.35V4.25C20 1.9 18.1 0 15.75 0H7.25C4.9 0 3 1.9 3 4.25V5C3 5.28 3.22 5.5 3.5 5.5H10.19C12.9 5.5 15.09 7.69 15.09 10.4V13.1C15.09 13.38 15.31 13.6 15.59 13.6H15.75Z"
                            fill="#05668D"
                        />
                    </svg>
                </Box>
                <Box display={{ xs: 'block', lg: 'none' }}>
                    <svg
                        width={tab ? '24px' : '40'}
                        height={tab ? '24px' : '40'}
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M25.2864 28.9939L25.2839 29.0026L25.2817 29.0113C24.7264 31.2175 22.9105 32.6167 20.3166 32.6167H15.7833H15.6321L15.5062 32.7004L10.4729 36.0504L10.4729 36.0504L10.4663 36.0549C10.0608 36.3342 9.49992 36.0462 9.49992 35.5333V33.1167V32.6167H8.99992C7.41292 32.6167 6.13003 32.0897 5.25347 31.2131C4.35948 30.3191 3.83325 29.0358 3.83325 27.45V20.6667C3.83325 19.1887 4.29067 17.9747 5.06902 17.1014C5.84595 16.2297 6.97261 15.6635 8.37575 15.5315C8.59295 15.5148 8.78997 15.5 8.99992 15.5H20.3166C21.903 15.5 23.1861 16.0266 24.0714 16.9119C24.9566 17.7972 25.4833 19.0803 25.4833 20.6667V27.45C25.4833 28.0142 25.4204 28.5323 25.2864 28.9939Z"
                            fill="#05668D"
                            stroke="#F2F2F2"
                        />
                        <path
                            d="M29.5833 25.9999C31.6999 25.9999 33.4833 25.2999 34.7166 24.0499C35.9666 22.8166 36.6666 21.0333 36.6666 18.9166V10.4166C36.6666 6.49992 33.4999 3.33325 29.5833 3.33325H15.4166C11.4999 3.33325 8.33325 6.49992 8.33325 10.4166V11.6666C8.33325 12.1333 8.69992 12.4999 9.16659 12.4999H20.3166C24.8333 12.4999 28.4833 16.1499 28.4833 20.6666V25.1666C28.4833 25.6333 28.8499 25.9999 29.3166 25.9999H29.5833Z"
                            fill="#05668D"
                        />
                    </svg>
                </Box>
            </>
        );
    }
    return (
        <>
            <Box display={{ xs: 'none', lg: 'block' }}>
                <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M14.0846 15.8424C13.8263 15.8424 13.568 15.7674 13.343 15.6174L12.543 15.0924C12.318 14.9424 12.2096 14.6591 12.2846 14.4008C12.343 14.2091 12.368 13.9841 12.368 13.7341V10.3424C12.368 8.98408 11.518 8.13411 10.1596 8.13411H4.5013C4.4013 8.13411 4.30963 8.14246 4.21797 8.1508C4.04297 8.15913 3.87631 8.10079 3.74297 7.98412C3.60964 7.86745 3.54297 7.7008 3.54297 7.5258V5.21745C3.54297 2.76745 5.25964 1.05078 7.70964 1.05078H14.793C17.243 1.05078 18.9596 2.76745 18.9596 5.21745V9.46743C18.9596 10.6758 18.5513 11.7424 17.8013 12.4757C17.2013 13.0841 16.368 13.4758 15.418 13.5924V14.5174C15.418 15.0174 15.143 15.4675 14.7096 15.7008C14.5096 15.7925 14.293 15.8424 14.0846 15.8424ZM13.5846 14.2758L14.1263 14.5841C14.1763 14.5591 14.1763 14.5174 14.1763 14.5091V13.0008C14.1763 12.6591 14.4596 12.3758 14.8013 12.3758C15.6763 12.3758 16.418 12.1008 16.9263 11.5841C17.4513 11.0675 17.718 10.3341 17.718 9.45909V5.20911C17.718 3.43411 16.5763 2.29244 14.8013 2.29244H7.71796C5.94296 2.29244 4.8013 3.43411 4.8013 5.20911V6.87577H10.168C12.2013 6.87577 13.6263 8.30079 13.6263 10.3341V13.7257C13.618 13.9174 13.6096 14.1008 13.5846 14.2758Z"
                        fill="#808080"
                    />
                    <path
                        d="M5.05964 18.9583C4.87631 18.9583 4.68464 18.9167 4.50964 18.825C4.11797 18.6167 3.8763 18.2167 3.8763 17.7667V17.1333C3.14296 17.0167 2.49297 16.7083 2.00964 16.225C1.37631 15.5916 1.04297 14.725 1.04297 13.725V10.3334C1.04297 8.45002 2.2763 7.06668 4.10963 6.89168C4.24296 6.88335 4.36796 6.875 4.5013 6.875H10.1596C12.193 6.875 13.618 8.30002 13.618 10.3334V13.725C13.618 14.0916 13.5763 14.4333 13.4846 14.7417C13.1096 16.2417 11.8346 17.1833 10.1596 17.1833H8.08463L5.7263 18.75C5.5263 18.8917 5.29297 18.9583 5.05964 18.9583ZM4.5013 8.125C4.4013 8.125 4.30963 8.13335 4.21797 8.14168C3.01797 8.25002 2.29297 9.07502 2.29297 10.3334V13.725C2.29297 14.3916 2.5013 14.95 2.89297 15.3416C3.2763 15.725 3.83463 15.9333 4.5013 15.9333C4.84296 15.9333 5.1263 16.2167 5.1263 16.5583V17.65L7.54297 16.0417C7.64297 15.975 7.76797 15.9333 7.89297 15.9333H10.1596C11.2596 15.9333 12.0346 15.3833 12.2763 14.4167C12.3346 14.2083 12.368 13.975 12.368 13.725V10.3334C12.368 8.97502 11.518 8.125 10.1596 8.125H4.5013Z"
                        fill="#808080"
                    />
                </svg>
            </Box>
            <Box display={{ xs: 'block', lg: 'none' }}>
                {tab ? (
                    <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M14.0846 15.8424C13.8263 15.8424 13.568 15.7674 13.343 15.6174L12.543 15.0924C12.318 14.9424 12.2096 14.6591 12.2846 14.4008C12.343 14.2091 12.368 13.9841 12.368 13.7341V10.3424C12.368 8.98408 11.518 8.13411 10.1596 8.13411H4.5013C4.4013 8.13411 4.30963 8.14246 4.21797 8.1508C4.04297 8.15913 3.87631 8.10079 3.74297 7.98412C3.60964 7.86745 3.54297 7.7008 3.54297 7.5258V5.21745C3.54297 2.76745 5.25964 1.05078 7.70964 1.05078H14.793C17.243 1.05078 18.9596 2.76745 18.9596 5.21745V9.46743C18.9596 10.6758 18.5513 11.7424 17.8013 12.4757C17.2013 13.0841 16.368 13.4758 15.418 13.5924V14.5174C15.418 15.0174 15.143 15.4675 14.7096 15.7008C14.5096 15.7925 14.293 15.8424 14.0846 15.8424ZM13.5846 14.2758L14.1263 14.5841C14.1763 14.5591 14.1763 14.5174 14.1763 14.5091V13.0008C14.1763 12.6591 14.4596 12.3758 14.8013 12.3758C15.6763 12.3758 16.418 12.1008 16.9263 11.5841C17.4513 11.0675 17.718 10.3341 17.718 9.45909V5.20911C17.718 3.43411 16.5763 2.29244 14.8013 2.29244H7.71796C5.94296 2.29244 4.8013 3.43411 4.8013 5.20911V6.87577H10.168C12.2013 6.87577 13.6263 8.30079 13.6263 10.3341V13.7257C13.618 13.9174 13.6096 14.1008 13.5846 14.2758Z"
                            fill="#808080"
                        />
                        <path
                            d="M5.05964 18.9583C4.87631 18.9583 4.68464 18.9167 4.50964 18.825C4.11797 18.6167 3.8763 18.2167 3.8763 17.7667V17.1333C3.14296 17.0167 2.49297 16.7083 2.00964 16.225C1.37631 15.5916 1.04297 14.725 1.04297 13.725V10.3334C1.04297 8.45002 2.2763 7.06668 4.10963 6.89168C4.24296 6.88335 4.36796 6.875 4.5013 6.875H10.1596C12.193 6.875 13.618 8.30002 13.618 10.3334V13.725C13.618 14.0916 13.5763 14.4333 13.4846 14.7417C13.1096 16.2417 11.8346 17.1833 10.1596 17.1833H8.08463L5.7263 18.75C5.5263 18.8917 5.29297 18.9583 5.05964 18.9583ZM4.5013 8.125C4.4013 8.125 4.30963 8.13335 4.21797 8.14168C3.01797 8.25002 2.29297 9.07502 2.29297 10.3334V13.725C2.29297 14.3916 2.5013 14.95 2.89297 15.3416C3.2763 15.725 3.83463 15.9333 4.5013 15.9333C4.84296 15.9333 5.1263 16.2167 5.1263 16.5583V17.65L7.54297 16.0417C7.64297 15.975 7.76797 15.9333 7.89297 15.9333H10.1596C11.2596 15.9333 12.0346 15.3833 12.2763 14.4167C12.3346 14.2083 12.368 13.975 12.368 13.725V10.3334C12.368 8.97502 11.518 8.125 10.1596 8.125H4.5013Z"
                            fill="#808080"
                        />
                    </svg>
                ) : (
                    <svg
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M23.385 18.6V24.705C23.385 25.245 23.325 25.755 23.19 26.22C22.635 28.425 20.805 29.805 18.285 29.805H14.205L9.675 32.82C9 33.285 8.1 32.79 8.1 31.98V29.805C6.57 29.805 5.295 29.295 4.41 28.41C3.51 27.51 3 26.235 3 24.705V18.6C3 15.75 4.77 13.785 7.5 13.53C7.695 13.515 7.89 13.5 8.1 13.5H18.285C21.345 13.5 23.385 15.54 23.385 18.6Z"
                            fill="#AAAAAA"
                        />
                        <path
                            d="M26.625 23.4C28.53 23.4 30.135 22.77 31.245 21.645C32.37 20.535 33 18.93 33 17.025V9.375C33 5.85 30.15 3 26.625 3H13.875C10.35 3 7.5 5.85 7.5 9.375V10.5C7.5 10.92 7.83 11.25 8.25 11.25H18.285C22.35 11.25 25.635 14.535 25.635 18.6V22.65C25.635 23.07 25.965 23.4 26.385 23.4H26.625Z"
                            fill="#AAAAAA"
                        />
                    </svg>
                )}
            </Box>
        </>
    );
}

export default MessagesIcon;
