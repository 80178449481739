import { useQuery } from 'react-query';
import externalAxiosInstance from '../externalAxiosConfig';
import { QueryKeys } from '../QueryKey';
import { ICountryResponse } from './type';

export function useGetCountries() {
    const getCountries = async () => {
        const response = await externalAxiosInstance.get(`/countries`);
        const data = await response.data;
        return data;
    };
    return useQuery(QueryKeys.RetrieveCountries, () => getCountries());
}
