import { useQuery } from 'react-query';
import { QueryKeys } from '../QueryKey';
import axiosInstance from '../axiosConfig';

export function useGetAllCompaniesBySearchTerm(search: string) {
    const getAllCompaniesBySearchTerm = async (search: string) => {
        const response = await axiosInstance.get(
            `/api/company-profiles/${search}`
        );
        const data = await response.data;
        return data;
    };
    return useQuery(QueryKeys.RetrieveAllCompaniesBySearchTerm, () =>
        getAllCompaniesBySearchTerm(search),
        {
            enabled: (search && search !== '' && search?.length > 3) ? true : false
        }
    );
}
