import Typography from '@mui/material/Typography';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Alert from '../../../assets/icons/Alert';
import UserActions from '../components/UserActions';
import UserDetails from '../components/UserDetails';
import TableWrapper from '../components/TableWrapper';
import SectionHeader from '../../common/SectionHeader';
import ExperienceLevel from '../components/ExperienceLevel';
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Delete from '../../../assets/icons/Delete';
import DialogBox from '../components/DialogBox';
import PageHeader from '../../common/PageHeader';
import Paper from '@mui/material/Paper';
import SearchBox from '../components/SearchBox';
import { ISkillsType, IUserType } from '../types';
import { useAuth } from '../../../utils/context/AuthContext';
import { useGetConnections } from '../../../utils/hooks/api/connections/useGetConnections';
import { useSendReviewRequest } from '../../../utils/hooks/api/reviews/useSendReviewRequest';
import { useLocation, useNavigate } from 'react-router-dom';
import ExclaminationIcon from '../../../assets/icons/ExclaminationIcon';
import ConnectionSkills from '../../Connections/components/ConnectionSkillsTab';
import { useSendEmailNotification } from '../../../utils/hooks/api/email/useSendEmailNotification';
import { useGetUser } from '../../../utils/hooks/api/users/useGetUser';
import { logger } from '../../../utils/Helper/logger';
import { Responsiveness } from '../../../theme/overrides';
import MobileNav from '../../../components/MobileNav/MobileNav';
import emptyState from '../../../assets/images/empyState.png';

import axiosInstance from '../../../utils/hooks/api/axiosConfig';
import {
    generateHtml,
    generateSubject
} from '../../../utils/emails/emailtemplates';
import { provideFeedBack } from '../../../utils/emails/email';
import AchievementBox from '../components/AchievementBox';

const SuggestedReviews = () => {
    const [selectedConnections, setSelectedConnections] = React.useState<
        IUserType[]
    >([]);
    const [replaceAll, setReplaceAll] = React.useState<boolean>(false);
    const [showSearch, setShowSearch] = React.useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const ratingId = queryParams.get('ratingId') ?? '';
    const [showModal, setShowModal] = React.useState(false);
    const [selected, setSelected] = React.useState(-1);
    const requestReview = useSendReviewRequest();
    const [page, setPage] = React.useState(0);
    const rowsPerPage = 8;
    const [successModal, setSuccessModal] = React.useState(false);
    const { user } = useAuth();
    const { data: connections = [] } = useGetConnections(user?.id ?? '');
    const { isMobile, isTablet } = Responsiveness();

    const routes = [
        {
            label: 'Suggested Reviews',
            route: '/reviews'
        },
        {
            label: 'Sent Requests',
            route: '/reviews/sent-requests'
        },
        {
            label: 'Received Requests',
            route: '/reviews/received-requests'
        },
        {
            label: 'Company Ratings',
            route: '/reviews/company-ratings'
        }
    ];

    const [postDrafts, setPostDrafts] = useState<any[]>([]);
    const [text, setText] = useState<any>('');

    useEffect(() => {
        const storedDrafts = localStorage.getItem('drafts');
        const storedText = localStorage.getItem('editorText');
        if (storedDrafts) {
            setPostDrafts(JSON.parse(storedDrafts) ?? []);
        }
        if (storedText) {
            setText(JSON.parse(storedText) ?? '');
        }
    }, [postDrafts]);

    const handleChangePage = (page: number) => {
        setPage(page - 1);
    };

    const handleRequestReview = () => {
        const uniqueIds = new Map<string | undefined, boolean>();
        for (let i = 0; i < selectedConnections.length; i++) {
            const obj = selectedConnections[i];
            if (!uniqueIds.has(obj.destinationUser?.userId)) {
                uniqueIds.set(obj.destinationUser?.userId, true);
            } else {
                selectedConnections.splice(i, 1);
                i--;
            }
        }
        if (ratingId) {
            selectedConnections.map((x, i) => {
                // const userDetailsQuery = useGetUser(x?.destinationUser?.userId ?? "");
                let data = {
                    professionalRatingId: ratingId,
                    professionalProfileId: x?.destinationUser?.profileId ?? '',
                    userId: x?.destinationUser?.userId ?? '',
                    status: 'PENDING'
                };
                requestReview.mutate(data, {
                    onSuccess: (res) => {
                        console.log('onSuccess', res);
                        console.log(
                            'selectedConnections',
                            selectedConnections?.length
                        );
                        console.log('index', i);
                        if (i + 1 === selectedConnections?.length) {
                            setSuccessModal(true);
                        }
                        let updatedPost = postDrafts?.filter(
                            (post, i) => post.post.message !== text
                        );
                        
                        localStorage.setItem(
                            'drafts',
                            JSON.stringify(updatedPost)
                        );
                        localStorage.removeItem('editorText')
                        localStorage.removeItem('activity')
                    },
                    onError: (err) => {
                        console.log('onError', err);
                    }
                });

                return null;
            });
        } else {
            setShowModal(true);
        }
    };

    React.useEffect(() => {
        setSelectedConnections(connections);
    }, [connections]);

    return (
        <Box mt={{ xs: '15px', sm: 0 }} pr={{ sm: '20px', lg: '40px' }}>
            {isMobile ? <MobileNav list={routes} /> : null}
            <PageHeader
                pageTitle={'Reviews'}
                actions={
                    <>
                        {selectedConnections?.length > 0 && ratingId ? (
                            isMobile || !isTablet ? (
                                <Button
                                    variant="outlined"
                                    sx={{
                                        p: '14px 16px',
                                        background: '#FAFAFA',
                                        border: '1px solid #05668D',
                                        borderRadius: '8px',
                                        fontFamily: 'Open Sans',
                                        fontWeight: 600,
                                        fontSize: '14px',
                                        lineHeight: '16px',
                                        letterSpacing: '0.005em',
                                        color: '#05668D',
                                        width: 'auto',
                                        minWidth: '100px'
                                    }}
                                    onClick={() => {
                                        setReplaceAll(true);
                                        setSelectedConnections([]);
                                    }}
                                >
                                    Replace All
                                </Button>
                            ) : null
                        ) : null}
                        {isTablet || !ratingId ? null : (
                            <Button
                                variant="contained"
                                sx={{
                                    p: '14px 24px',
                                    background: '#05668D',
                                    border: '1px solid #05668D',
                                    borderRadius: '8px',
                                    fontFamily: 'Open Sans',
                                    fontWeight: 600,
                                    fontSize: '14px',
                                    lineHeight: '16px',
                                    letterSpacing: '0.005em',
                                    color: '#FFFFFF',
                                    width: 'auto',
                                    minWidth: '157px',
                                    '&:disabled': {
                                        border: 0
                                    }
                                }}
                                disabled={
                                    selectedConnections?.length === 0 &&
                                    ratingId
                                        ? true
                                        : false
                                }
                                onClick={() => handleRequestReview()}
                            >
                                Request Review
                            </Button>
                        )}
                    </>
                }
            />
            {isMobile ? null : isTablet ? <MobileNav list={routes} /> : null}
            {postDrafts.length > 0 && !ratingId ? (
                <Box>
                    <TableWrapper
                        handleChangePage={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        data={postDrafts}
                    >
                        {postDrafts
                            ?.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                            )
                            ?.map((post: any, index: number) => (
                                <TableRow key={`post_${index}`}>
                                    <TableCell
                                        sx={{
                                            pt: isMobile ? '16px' : '20px'
                                        }}
                                    >
                                        <AchievementBox
                                            setPostDrafts={setPostDrafts}
                                            postDrafts={postDrafts}
                                            draft
                                            hidePost={() => {}}
                                            review={post}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableWrapper>
                </Box>
            ) : replaceAll ? (
                <>
                    <Paper sx={{ mt: '36px' }}>
                        <Box>
                            <SectionHeader
                                header={'Selected connections'}
                                subHeader={
                                    'You have a maximum of five reviewers'
                                }
                            />
                            <Grid container>
                                <TableWrapper
                                    handleChangePage={handleChangePage}
                                    rowsPerPage={rowsPerPage}
                                    data={selectedConnections}
                                >
                                    {selectedConnections
                                        ?.slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        ?.map((user: any, index: number) => (
                                            <TableRow key={`user_${index}`}>
                                                <TableCell
                                                    sx={{
                                                        padding: '40px 32px',
                                                        paddingBottom: '25px'
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'space-between',
                                                            gap: '20px'
                                                        }}
                                                    >
                                                        <UserDetails
                                                            user={
                                                                user?.destinationUser
                                                            }
                                                            id={
                                                                user
                                                                    ?.destinationUser
                                                                    ?.profileId
                                                            }
                                                        />
                                                        <UserActions>
                                                            <Button
                                                                variant="outlined"
                                                                sx={{
                                                                    borderRadius:
                                                                        '8px',
                                                                    padding:
                                                                        '12px 16px',
                                                                    fontSize:
                                                                        '14px',
                                                                    fontWeight:
                                                                        '600',
                                                                    textTransform:
                                                                        'capitalize',
                                                                    boxShadow:
                                                                        'none',
                                                                    color: '#05668D',
                                                                    width: 'auto',
                                                                    ml: '35px'
                                                                }}
                                                                onClick={() => {
                                                                    setShowSearch(
                                                                        true
                                                                    );
                                                                    setSelected(
                                                                        index
                                                                    );
                                                                }}
                                                            >
                                                                Replace
                                                            </Button>
                                                            <Button
                                                                sx={{
                                                                    borderRadius:
                                                                        '8px',
                                                                    padding:
                                                                        '12px 16px',
                                                                    border: '1px solid #F2F2F2',
                                                                    background:
                                                                        '#F2F2F2',
                                                                    fontSize:
                                                                        '14px',
                                                                    fontWeight:
                                                                        '600',
                                                                    textTransform:
                                                                        'capitalize',
                                                                    boxShadow:
                                                                        'none',
                                                                    width: 'auto',
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                    color: '#05668D',
                                                                    textDecoration:
                                                                        'none',
                                                                    ':hover': {
                                                                        color: '#05668D',
                                                                        textDecoration:
                                                                            'none',
                                                                        background:
                                                                            '#F2F2F2'
                                                                    }
                                                                }}
                                                                endIcon={
                                                                    <Delete color="#F68481" />
                                                                }
                                                                onClick={() => {
                                                                    setSelectedConnections(
                                                                        selectedConnections?.filter(
                                                                            (
                                                                                x: IUserType
                                                                            ) =>
                                                                                x?.id !==
                                                                                user.id
                                                                        )
                                                                    );
                                                                }}
                                                            >
                                                                Remove
                                                            </Button>
                                                        </UserActions>
                                                    </Box>
                                                    {isMobile ? null : (
                                                        <Box
                                                            sx={{
                                                                mt: '12px'
                                                            }}
                                                        >
                                                            <ConnectionSkills
                                                                id={
                                                                    user
                                                                        ?.destinationUser
                                                                        ?.profileId
                                                                }
                                                                count={
                                                                    isTablet
                                                                        ? 2
                                                                        : 4
                                                                }
                                                            />
                                                        </Box>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableWrapper>
                            </Grid>
                        </Box>
                    </Paper>
                    <Paper sx={{ mt: '36px' }}>
                        <Box>
                            <SectionHeader
                                header={
                                    'Request a review from your connections'
                                }
                                subHeader={
                                    'Search to add 5 connections for reviews'
                                }
                            />
                            <Grid container>
                                <TableWrapper
                                    handleChangePage={handleChangePage}
                                    rowsPerPage={rowsPerPage}
                                    data={connections?.filter((x: any) =>
                                        selectedConnections.filter(
                                            (y: IUserType) =>
                                                y?.destinationUser?.userId !==
                                                x.destinationUser?.userId
                                        )
                                    )}
                                >
                                    {connections
                                        ?.filter(
                                            (x: any) =>
                                                selectedConnections.filter(
                                                    (y: IUserType) =>
                                                        y?.destinationUser
                                                            ?.userId ===
                                                        x.destinationUser
                                                            ?.userId
                                                )?.length === 0
                                        )
                                        ?.slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        ?.map((user: any, index: number) => (
                                            <TableRow key={`user_${index}`}>
                                                <TableCell
                                                    sx={{
                                                        padding: '40px 32px',
                                                        paddingBottom: '25px'
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'space-between',
                                                            gap: '20px'
                                                        }}
                                                    >
                                                        <UserDetails
                                                            user={
                                                                user?.destinationUser
                                                            }
                                                            id={
                                                                user
                                                                    ?.destinationUser
                                                                    ?.profileId
                                                            }
                                                        />
                                                        <UserActions>
                                                            <Button
                                                                sx={{
                                                                    borderRadius:
                                                                        '8px',
                                                                    padding:
                                                                        '12px 16px',
                                                                    border: '1px solid #05668D',
                                                                    background:
                                                                        '#05668D',
                                                                    fontSize:
                                                                        '14px',
                                                                    fontWeight:
                                                                        '600',
                                                                    textTransform:
                                                                        'capitalize',
                                                                    boxShadow:
                                                                        'none',
                                                                    width: 'auto',
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                    color: '#FFFFFF',
                                                                    textDecoration:
                                                                        'none',
                                                                    ':hover': {
                                                                        color: '#FFFFFF',
                                                                        textDecoration:
                                                                            'none',
                                                                        background:
                                                                            '#05668D'
                                                                    }
                                                                }}
                                                                onClick={() =>
                                                                    selectedConnections?.length <
                                                                    5
                                                                        ? setSelectedConnections(
                                                                              (
                                                                                  prev
                                                                              ) => [
                                                                                  ...prev,
                                                                                  user
                                                                              ]
                                                                          )
                                                                        : []
                                                                }
                                                            >
                                                                Add
                                                            </Button>
                                                        </UserActions>
                                                    </Box>
                                                    {isMobile ? null : (
                                                        <Box
                                                            sx={{
                                                                mt: '12px'
                                                            }}
                                                        >
                                                            <ConnectionSkills
                                                                id={
                                                                    user
                                                                        ?.destinationUser
                                                                        ?.profileId
                                                                }
                                                                count={
                                                                    isTablet
                                                                        ? 2
                                                                        : 4
                                                                }
                                                            />
                                                        </Box>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableWrapper>
                            </Grid>
                        </Box>
                    </Paper>
                </>
            ) : (
                <Paper sx={{ mt: isMobile ? '16px' : isTablet ? 0 : '36px' }}>
                    <Box sx={{ minHeight: '559px' }}>
                        {ratingId ? (
                            <>
                                <SectionHeader
                                    header={
                                        'You can replace suggested connections with connections of your choice'
                                    }
                                    subHeader={
                                        'You have a maximum of five reviewers'
                                    }
                                />
                                {isMobile ? (
                                    <Typography
                                        sx={{
                                            fontSize: '16px',
                                            fontWeight: 600,
                                            p: '16px'
                                        }}
                                    >
                                        Suggested reviewers
                                    </Typography>
                                ) : null}
                                <Grid container>
                                    <TableWrapper
                                        handleChangePage={handleChangePage}
                                        hidePagination={true}
                                        data={selectedConnections}
                                    >
                                        {selectedConnections
                                            ?.slice(0, 5)
                                            ?.map(
                                                (user: any, index: number) => (
                                                    <TableRow
                                                        key={`user_${index}`}
                                                    >
                                                        <TableCell
                                                            sx={{
                                                                padding:
                                                                    isTablet
                                                                        ? '20px 20px'
                                                                        : '32px 40px',
                                                                paddingBottom:
                                                                    '25px'
                                                            }}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                    alignItems:
                                                                        'center',
                                                                    justifyContent:
                                                                        'space-between',
                                                                    gap: '20px'
                                                                }}
                                                            >
                                                                <Box>
                                                                    <UserDetails
                                                                        user={
                                                                            user?.destinationUser
                                                                        }
                                                                        id={
                                                                            user
                                                                                ?.destinationUser
                                                                                ?.profileId
                                                                        }
                                                                    />
                                                                </Box>
                                                                {isTablet ? null : (
                                                                    <UserActions>
                                                                        <Button
                                                                            variant="outlined"
                                                                            sx={{
                                                                                borderRadius:
                                                                                    '8px',
                                                                                padding:
                                                                                    '12px 16px',
                                                                                fontSize:
                                                                                    '14px',
                                                                                fontWeight:
                                                                                    '600',
                                                                                textTransform:
                                                                                    'capitalize',
                                                                                boxShadow:
                                                                                    'none',
                                                                                color: '#05668D',
                                                                                width: 'auto',
                                                                                ml: '35px'
                                                                            }}
                                                                            onClick={() => {
                                                                                setShowSearch(
                                                                                    true
                                                                                );
                                                                                setSelected(
                                                                                    index
                                                                                );
                                                                            }}
                                                                        >
                                                                            Replace
                                                                        </Button>
                                                                        <Button
                                                                            sx={{
                                                                                borderRadius:
                                                                                    '8px',
                                                                                padding:
                                                                                    '12px 16px',
                                                                                border: '1px solid #F2F2F2',
                                                                                background:
                                                                                    '#F2F2F2',
                                                                                fontSize:
                                                                                    '14px',
                                                                                fontWeight:
                                                                                    '600',
                                                                                textTransform:
                                                                                    'capitalize',
                                                                                boxShadow:
                                                                                    'none',
                                                                                width: 'auto',
                                                                                whiteSpace:
                                                                                    'nowrap',
                                                                                color: '#05668D',
                                                                                textDecoration:
                                                                                    'none',
                                                                                ':hover':
                                                                                    {
                                                                                        color: '#05668D',
                                                                                        textDecoration:
                                                                                            'none',
                                                                                        background:
                                                                                            '#F2F2F2'
                                                                                    }
                                                                            }}
                                                                            endIcon={
                                                                                <Delete color="#F68481" />
                                                                            }
                                                                            onClick={() =>
                                                                                setSelectedConnections(
                                                                                    selectedConnections?.filter(
                                                                                        (
                                                                                            x: IUserType
                                                                                        ) =>
                                                                                            x?.id !==
                                                                                            user.id
                                                                                    )
                                                                                )
                                                                            }
                                                                        >
                                                                            Remove
                                                                        </Button>
                                                                    </UserActions>
                                                                )}
                                                            </Box>
                                                            {isMobile ? null : (
                                                                <Box mt="12px">
                                                                    <ConnectionSkills
                                                                        id={
                                                                            user
                                                                                ?.destinationUser
                                                                                ?.profileId
                                                                        }
                                                                        count={
                                                                            isTablet
                                                                                ? 2
                                                                                : 4
                                                                        }
                                                                    />
                                                                </Box>
                                                            )}
                                                            {isTablet ? (
                                                                <Box
                                                                    sx={{
                                                                        display:
                                                                            'flex',
                                                                        alignItems:
                                                                            'center',
                                                                        gap: '8px',
                                                                        mt: '16px'
                                                                    }}
                                                                >
                                                                    <Button
                                                                        variant="outlined"
                                                                        sx={{
                                                                            borderRadius:
                                                                                '8px',
                                                                            padding:
                                                                                '12px 16px',
                                                                            fontSize:
                                                                                '14px',
                                                                            fontWeight:
                                                                                '600',
                                                                            textTransform:
                                                                                'capitalize',
                                                                            boxShadow:
                                                                                'none',
                                                                            color: '#05668D',
                                                                            width: isMobile
                                                                                ? '100%'
                                                                                : '100px',
                                                                            height: isMobile
                                                                                ? '32px'
                                                                                : undefined
                                                                        }}
                                                                        onClick={() => {
                                                                            setShowSearch(
                                                                                true
                                                                            );
                                                                            setSelected(
                                                                                index
                                                                            );
                                                                        }}
                                                                    >
                                                                        Replace
                                                                    </Button>
                                                                    <Button
                                                                        sx={{
                                                                            borderRadius:
                                                                                '8px',
                                                                            padding:
                                                                                '12px 16px',
                                                                            border: '1px solid #F2F2F2',
                                                                            background:
                                                                                '#F2F2F2',
                                                                            fontSize:
                                                                                '14px',
                                                                            fontWeight:
                                                                                '600',
                                                                            textTransform:
                                                                                'capitalize',
                                                                            boxShadow:
                                                                                'none',
                                                                            width: isMobile
                                                                                ? '100%'
                                                                                : '100px',
                                                                            height: isMobile
                                                                                ? '32px'
                                                                                : undefined,
                                                                            whiteSpace:
                                                                                'nowrap',
                                                                            color: '#05668D',
                                                                            textDecoration:
                                                                                'none',
                                                                            ':hover':
                                                                                {
                                                                                    color: '#05668D',
                                                                                    textDecoration:
                                                                                        'none',
                                                                                    background:
                                                                                        '#F2F2F2'
                                                                                }
                                                                        }}
                                                                        endIcon={
                                                                            <Delete color="#F68481" />
                                                                        }
                                                                        onClick={() =>
                                                                            setSelectedConnections(
                                                                                selectedConnections?.filter(
                                                                                    (
                                                                                        x: IUserType
                                                                                    ) =>
                                                                                        x?.id !==
                                                                                        user.id
                                                                                )
                                                                            )
                                                                        }
                                                                    >
                                                                        Remove
                                                                    </Button>
                                                                </Box>
                                                            ) : null}
                                                            {/* {user?.skills
                                                                ?.length > 0 ? (
                                                                <Box
                                                                    sx={{
                                                                        display:
                                                                            'flex',
                                                                        alignItems:
                                                                            'center',
                                                                        gap: '8px',
                                                                        ml: '77px',
                                                                        mt: '12px'
                                                                    }}
                                                                >
                                                                    {user?.skills?.map(
                                                                        (
                                                                            item: ISkillsType,
                                                                            index: number
                                                                        ) => (
                                                                            <ExperienceLevel
                                                                                background={
                                                                                    item.background
                                                                                }
                                                                                shortForm={
                                                                                    item.shortForm
                                                                                }
                                                                                title={
                                                                                    item.title
                                                                                }
                                                                                key={`skill_${index}`}
                                                                            />
                                                                        )
                                                                    )}
                                                                    <Typography
                                                                        component={
                                                                            'div'
                                                                        }
                                                                        sx={{
                                                                            ml: '4px',
                                                                            width: '7px',
                                                                            height: '7px',
                                                                            borderRadius:
                                                                                '100px',
                                                                            background:
                                                                                '#494949',
                                                                            border: '2px solid #494949'
                                                                        }}
                                                                    />{' '}
                                                                    <Typography
                                                                        component={
                                                                            'div'
                                                                        }
                                                                        sx={{
                                                                            ml: '4px',
                                                                            width: '7px',
                                                                            height: '7px',
                                                                            borderRadius:
                                                                                '100px',
                                                                            background:
                                                                                '#494949',
                                                                            border: '2px solid #494949'
                                                                        }}
                                                                    />
                                                                </Box>
                                                            ) : null} */}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            )}
                                    </TableWrapper>
                                </Grid>
                            </>
                        ) : (
                            <Box
                                display="flex"
                                flexDirection={'column'}
                                gap={isTablet ? '100px' : '45px'}
                                px={
                                    isMobile
                                        ? '16px'
                                        : isTablet
                                        ? '20px'
                                        : '40px'
                                }
                                pt={isMobile ? '16px' : '24px'}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        gap: '6px'
                                    }}
                                >
                                    <Alert />
                                    <Typography
                                        variant="titleSmallRegular"
                                        sx={{
                                            letterSpacing: '0.0035em',
                                            color: '#808080'
                                        }}
                                    >
                                        You do not have any suggestion at the
                                        moment. You only get suggestions when
                                        you post an achievement.
                                    </Typography>
                                </Box>
                                <img
                                    style={{
                                        alignSelf: 'center',
                                        width: isMobile ? '80%' : undefined
                                    }}
                                    src={emptyState}
                                    alt="empty"
                                />
                            </Box>
                        )}
                    </Box>
                </Paper>
            )}
            {!isTablet ? null : (
                <Box
                    display={postDrafts.length > 0 ? 'none' : 'flex'}
                    width="100%"
                    alignItems="center"
                    gap="8px"
                    mt="32px"
                    px={'20px'}
                >
                    {selectedConnections?.length > 0 && ratingId ? (
                        isMobile ? null : (
                            <Button
                                variant="outlined"
                                sx={{
                                    p: '14px 16px',
                                    background: '#FAFAFA',
                                    border: '1px solid #05668D',
                                    borderRadius: '8px',
                                    fontFamily: 'Open Sans',
                                    fontWeight: 600,
                                    fontSize: '14px',
                                    lineHeight: '16px',
                                    letterSpacing: '0.005em',
                                    color: '#05668D',
                                    width: '100%'
                                    // minWidth: 'auto'
                                }}
                                onClick={() => {
                                    setReplaceAll(true);
                                    setSelectedConnections([]);
                                }}
                            >
                                Replace All
                            </Button>
                        )
                    ) : null}
                    <Button
                        variant="contained"
                        sx={{
                            p: '14px 24px',
                            background: '#05668D',
                            border: '1px solid #05668D',
                            borderRadius: '8px',
                            fontFamily: 'Open Sans',
                            fontWeight: 600,
                            fontSize: '14px',
                            lineHeight: '16px',
                            letterSpacing: '0.005em',
                            color: '#FFFFFF',
                            width: '100%',
                            // minWidth: 'auto',
                            '&:disabled': {
                                border: 0
                            },
                            mb:'90px'
                        }}
                        disabled={
                            selectedConnections?.length === 0 && ratingId
                                ? true
                                : false
                        }
                        onClick={() => handleRequestReview()}
                    >
                        Request Review
                    </Button>
                </Box>
            )}
            <SearchBox
                handleClose={() => setShowSearch(false)}
                handleOnAddClick={(user) => {
                    console.log(user);
                    let temp = JSON.parse(JSON.stringify(selectedConnections));
                    temp = temp?.filter(
                        (x: any) => x?.destinationUser?.userId !== user?.userId
                    );
                    temp[selected] = { destinationUser: user };
                    setSelectedConnections(temp);
                    setSelected(-1);
                    setShowSearch(false);
                }}
                open={showSearch}
            />
            <DialogBox
                icon={<ExclaminationIcon />}
                title={''}
                open={showModal}
                description={
                    'You can only request reviews when you post an achievement. Go to home screen to post an achievement and add skills to get reviews.'
                }
                handleClose={() => {
                    setShowModal(false);
                }}
                handleContinue={() => navigate('/')}
            />
            <DialogBox
                title="Request sent"
                open={successModal}
                description={
                    'Request has been sent to your connection for review.'
                }
                handleContinue={() => navigate('/')}
                handleClose={() => setSuccessModal(false)}
            />
        </Box>
    );
};

export default SuggestedReviews;
