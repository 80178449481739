import axiosInstance from '../axiosConfig';
import { UseMutationResult, useMutation } from 'react-query';
async function usePatchRatings(patchtRequest: any) {
    const response = await axiosInstance.patch(
        `api/professional-ratings/${patchtRequest?.id}/${patchtRequest?.profileId}/updateskills`,
        patchtRequest
    );
    const data = await response.data;
    return data;
}
export function useUpdateRating(): UseMutationResult<
    unknown,
    unknown,
    unknown
> {
    return useMutation(usePatchRatings);
}
