import {
    Typography,
    Button,
    InputBase,
    InputLabel,
    InputAdornment,
    CircularProgress
} from '@mui/material';
import Box from '@mui/material/Box';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import EmailFormIcon from '../../assets/icons/EmailFormIcon';
import OnBoardingSidePanel from '../OnBoarding/OnBoardingSidePanel';
import ForgotPasswordSteps from './ForgotPasswordSteps/ForgotPasswordSteps';
import React, { useState } from 'react';
import {
    updateAction,
    updateStep,
    clearAction
} from '../Accounts/Professional/CreationSteps/updateAction';
import { useStateMachine } from 'little-state-machine';
import useGetVerificationCode from '../../utils/hooks/api/authentication/useGetVerificationCode';
import SnackAlert, { SnackAlertType } from '../../components/Snackbar';
import axios, { AxiosError } from 'axios';

interface IResetPasswordForm {
    emailAddress: string;
}

function ForgotPassword() {
    let navigate = useNavigate();
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState<SnackAlertType>('info');
    const { actions } = useStateMachine({
        updateAction,
        updateStep,
        clearAction
    });

    const {
        control,
        handleSubmit,
        formState,
        register,
        setError,
        clearErrors
    } = useForm<IResetPasswordForm>();
    const { isDirty, isValid, errors } = formState;
    const getCode = useGetVerificationCode();
    const onSubmit: SubmitHandler<IResetPasswordForm> = async (data) => {
        setLoading(true);
        actions.updateAction(data);
        if (data.emailAddress !== undefined) {
            getCode.mutate(
                { emailAddress: data.emailAddress, verificationType: 'CODE' },
                {
                    onSuccess: (res) => {
                        if (res.accountId) {
                            navigate(
                                `/forgot-password/reset/${data.emailAddress}`
                            );
                            return;
                        }
                        setType('error');
                        setMessage('Error occured please try again!');
                        setShowAlert(true);
                        setLoading(false);
                    },
                    onError: (error: AxiosError) => {
                        setLoading(false);
                        if (axios.isAxiosError(error)) {
                            setType('error');
                            setMessage('Incorrect credentials');
                            setShowAlert(true);
                            return;
                        }
                        setType('error');
                        setMessage('Error occured please try again!');
                        setShowAlert(true);
                        return;
                    }
                }
            );
        }
    };

    const navigateBack: React.MouseEventHandler<HTMLButtonElement> = () => {
        navigate(-1);
    };

    React.useEffect(() => {
        actions.clearAction();
        actions.updateStep(1);
    }, []);

    return (
        <>
            <Box
                sx={{
                    backgroundColor: { md: 'transparent', sm: '#FCFBF8', xs: 'transparent' },
                    borderRadius: '12px',
                    p: { md: 0, sm: '64px 40px', xs: 0 },
                    mt: { md: '50px', sm: '-270px', xs: 0 },
                    mx: { lg: '50px', md: '20px', sm: '77px', xs: '20px' },
                    mb: { md: 0, sm: '50px', xs: 0 }
                }}
            >
                {/* ellipsis steps */}
                <ForgotPasswordSteps />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: { xl: '70%' }
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                mt: { sm: '64px', xs: '20px' },
                                display: { sm: 'block', xs: "none" }
                            }}
                        >
                            <Typography
                                variant="headlineLargeSemiBold"
                                color="#23282B"
                                sx={{ mb: '8px', display: 'block' }}
                            >
                                Forgot Password?
                            </Typography>
                            <Typography
                                variant="titleMediumRegular"
                                color="#808080"
                                sx={{ display: 'block' }}
                            >
                                Reset password in two quick steps
                            </Typography>
                        </Box>

                        <Box sx={{ mt: { sm: '20px', xs: '28px' }, width: '100%' }}>
                            <Box sx={{ position: 'relative' }}>
                                <InputLabel
                                    sx={{
                                        color: '#808080',
                                        fontSize: { sm: '14px', xs: '12px' },
                                        position: 'absolute',
                                        top: '8px',
                                        left: '72px',
                                        zIndex: 1,
                                        fontFamily: 'Open Sans'
                                    }}
                                    htmlFor="email"
                                >
                                    Email
                                </InputLabel>
                                {/* Email Address Input */}
                                <Controller
                                    name="emailAddress"
                                    control={control}
                                    rules={{
                                        required: 'Required field',
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message:
                                                'Invalid email address'
                                        }
                                    }}
                                    render={({ field }) => (
                                        <InputBase
                                            {...field}
                                            required
                                            error={
                                                !!errors.emailAddress
                                            }
                                            inputProps={{
                                                autoComplete: 'off',
                                                form: {
                                                    autoComplete: 'off'
                                                },
                                                inputMode: 'email'
                                            }}
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <EmailFormIcon />
                                                </InputAdornment>
                                            }
                                            placeholder="Enter email address"
                                            type="email"
                                            sx={{
                                                backgroundColor:
                                                    '#FFFFFF',
                                                width: '100%',
                                                height: { sm: '70px', xs: '60px' },
                                                padding: '0px 16px',
                                                fontFamily: 'open sans',
                                                color: '#23282B',
                                                borderBottom:
                                                    '1px solid',
                                                borderColor:
                                                    errors.emailAddress
                                                        ? 'red'
                                                        : '#D9D9D9',
                                                mb: '2px',
                                                fontSize: { sm: '16px', xs: '14px' },
                                                '& .MuiInputBase-input':
                                                {
                                                    ml: '7.5px',
                                                    position:
                                                        'relative',
                                                    top: '8px'
                                                }
                                            }}
                                        />
                                    )}
                                />

                                <Typography
                                    sx={{
                                        color: 'red',
                                        fontSize: '12px',
                                        fontFamily: 'Open Sans'
                                    }}
                                >
                                    {errors.emailAddress?.message}
                                </Typography>
                            </Box>
                        </Box>
                        {/* buttons */}
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                mt: '32px'
                            }}
                        >
                            {loading ? (
                                <CircularProgress
                                    sx={{ color: '#05668D' }}
                                />
                            ) : (
                                <Button
                                    sx={{
                                        height: { sm: '52px', xs: '42px' }
                                    }}
                                    variant="contained"
                                    disabled={!isDirty || !isValid}
                                    type="submit"
                                >
                                    <Typography
                                        fontSize={{ sm: '16px', xs: '14px' }}
                                        fontWeight={700}
                                        fontStyle={'bold'}
                                        textTransform="capitalize"
                                    >
                                        Send OTP
                                    </Typography>
                                </Button>
                            )}
                            <Button
                                variant="outlined"
                                sx={{
                                    color: '#05668D',
                                    borderRadius: '8px',
                                    width: '100%',
                                    padding: '16px, 36px, 16px, 36px',
                                    height: { sm: '52px', xs: '42px' },
                                    mt: '16px',
                                    textTransform: 'capitalize',
                                    fontWeight: 600,
                                    fontSize: { sm: '16px', xs: '14px' }
                                }}
                                onClick={navigateBack}
                            >
                                Back
                            </Button>
                        </Box>
                    </Box>
                </form >
            </Box >
            <SnackAlert
                open={showAlert}
                handleClose={() => setShowAlert(false)}
                message={message}
                type={type}
            />
        </>
    );
}

export default ForgotPassword;
