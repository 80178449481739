import CircleIcon from '@mui/icons-material/Circle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
    IconButton,
    Grid,
    Box,
    Typography,
    Container,
    Button,
    useTheme,
    useMediaQuery,
    Stack,
    ImageList,
    ImageListItem
} from '@mui/material';
import cover from '../../assets/images/cover.jpg';
import InfoChip from '../Users/components/InfoChip';
import Section from './components/Section';
import SectionHeading from '../Users/components/SectionHeading';
import Image from '../../components/Image';
import AnalyticsProfile from '../../assets/icons/AnalyticsProfile';
import AnalyticsSearch from '../../assets/icons/AnalyticsSearch';
import AnalyticsGraph from '../../assets/icons/AnalyticsGraph';
import EditContactInfoDialog from '../Users/components/Modals/EditContactInfoDialog';
import ScoreCard from '../../components/ScoreCard.tsx';
import { useState } from 'react';
import EditProfileAboutMeDialog from '../Users/components/Modals/EditProfileAboutMeDialog';
import EditIcon from '../../assets/icons/EditIcon';
import EditProfileInfoDialog from '../Users/components/Modals/EditProfileInfoDialog';
import { ArrowLeftIcon } from '../../assets/icons/ArrowLeftIcon';
import profImg1 from '../../assets/images/profileReview.png';
import profImg2 from '../../assets/images/feed2.png';
import profImg3 from '../../assets/images/SideNavProfile.png';
import ProfileList from './components/ProfileList';
import { useNavigate } from 'react-router-dom';
import { FeedItem } from '../Home/components/FeedItem';
import { useAuth } from '../../utils/context/AuthContext';
import React from 'react';
import SkeletonLoader from '../Home/components/SkeletonLoader';
import InfiniteScroll from 'react-infinite-scroll-component';
import { usePost } from '../../utils/context/PostContext';
import ArrowRight from '../../assets/icons/ArrowRight';
import { PrimaryProfileType } from '../../utils/hooks/api/account/types';
import { useGetConnections } from '../../utils/hooks/api/connections/useGetConnections';
import ProfileBanner from '../ProfileBanner';
import { useGetProfessionalProfileScore } from '../../utils/hooks/api/professional-profile/useGetProfessionalProfileScore';
import ProfilePhotoIcon from '../../assets/icons/ProfilePhotoIcon';
import { Responsiveness } from '../../theme/overrides';
import PageHeader from '../../components/PageHeader/PageHeader';
import MobileNav from '../../components/MobileNav/MobileNav';
import EditBtn from '../../assets/icons/EditBtn';
import Alert from '../../assets/icons/Alert';
import TickCircle from '../../assets/icons/TickCircle';
import MoreIcon from '../../assets/icons/MoreIcon';

function Activities() {
    const { isTablet, isMobile, isXMobile } = Responsiveness()
    // const [openContactInfoModal, setOpenContactInfoModal] = useState(false);
    const [openAboutMeModal, setOpenAboutMeModal] = useState(false);
    const [openProfileInfoModal, setOpenProfileInfoModal] = useState(false);
        const [activity, setActivity] = useState('Posts');
        const currentActivity = ['Posts', 'Achievements', 'Articles'];
    const navigate = useNavigate();
    const { user } = useAuth();
    const { data: score } = useGetProfessionalProfileScore(
        user?.professionalProfile?.id!
    );
    const { posts, totalPosts, isLoaded, setGetAfter } = usePost();
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const { data: connections } = useGetConnections(user?.id ?? '');
        let Images: any[] = [];
        posts?.map((postImages) =>
            postImages?.images?.map((image) => Images.push(image))
        );
    const ref = React.createRef();

    const professionals = [
        {
            profileImage: profImg1,
            fullName: 'Jane Cooper',
            jobTitle: 'Product Designer',
            score: 550
        },
        {
            profileImage: profImg2,
            fullName: 'Esther Howard',
            jobTitle: 'Accounting leader',
            score: 450
        },
        {
            profileImage: profImg3,
            fullName: 'Kathryn Murphy',
            jobTitle: 'Human Resource',
            score: 750
        }
    ];

    const routes = [
        {
            label: 'About me',
            route: '/users'
        },
        {
            label: 'Activities',
            route: '/activities'
        },
        {
            label: 'Jobfactor Score',
            route: '/jobfactor-score'
        },
        {
            label: 'Skills',
            route: '/skills'
        }
    ];

    // const handleOnEditContactInfo = () => {
    //     setOpenContactInfoModal(true);
    // };

    const handleOnEditProfileInfo = () => {
        setOpenProfileInfoModal(true);
    };

    return (
        <Container
            disableGutters
            style={{
                // marginTop: 63,
                maxWidth: '100%'
            }}
            sx={{
                pr: isMobile ? 0 : isTablet ? '20px' : '40px',
                mt: isMobile ? '21px' : isTablet ? '0px' : '-10px'
            }}
        >
            {isMobile ? null : isTablet ? (
                <PageHeader
                    header={`${user?.professionalProfile?.firstName}'s Activities`}
                />
            ) : null}
            {isTablet ? <MobileNav list={routes} /> : null}
            {isMobile ? (
                <PageHeader
                    header={`${user?.professionalProfile?.firstName}'s Activities`}
                />
            ) : null}
            <Grid
                mt="8px"
                container
                direction="column"
                gap={{ xs: 1, lg: 1.5 }}
            >
                <Grid item position="relative">
                    <Box
                        bgcolor="#fff"
                        borderRadius={1}
                        overflow="hidden"
                        pb={isMobile ? 0 : '20px'}
                    >
                        <Box position="relative" pb="109px">
                            <Box
                                position="relative"
                                overflow="hidden"
                                display={'flex'}
                                borderRadius={[0, 0, 1, 1]}
                            >
                                <img
                                    style={{
                                        height: isMobile
                                            ? '100px'
                                            : isTablet
                                            ? '220px'
                                            : '259px',
                                        width: '100%',
                                        objectFit: 'cover',
                                        display: 'block'
                                    }}
                                    alt="Cover"
                                    src={cover}
                                />
                                {isTablet ? null : (
                                    <ProfileBanner
                                        firstName={
                                            user?.professionalProfile?.firstName  ??
                                            ''
                                        }
                                        lastName={user?.professionalProfile?.lastName ?? ''}
                                        score={score}
                                    />
                                )}
                            </Box>
                            <Grid
                                container
                                gap={isMobile ? undefined : 3}
                                position={'absolute'}
                                direction={isMobile ? 'column' : undefined}
                                bottom={isMobile ? 8 : isTablet ? 30 : 0}
                            >
                                <Box
                                    ml={isTablet ? '20px' : 5}
                                    borderRadius="50%"
                                    overflow="hidden"
                                    width="fit-content"
                                    boxShadow="-8px 4px 20px rgba(0, 0, 0, 0.07), 8px 8px 20px rgba(0, 0, 0, 0.07)"
                                    position="relative"
                                    mb={isTablet && !user?.professionalProfile?.photoUrl ? 2 : 0}
                                >
                                    {!user?.professionalProfile?.photoUrl ? (
                                        <ProfilePhotoIcon />
                                    ) : (
                                        <Image
                                            src={user?.professionalProfile?.photoUrl ?? ''}
                                            sx={{
                                                width: isMobile
                                                    ? '130px'
                                                    : isTablet
                                                    ? '150px'
                                                    : '200px',
                                                height: isMobile
                                                    ? '130px'
                                                    : isTablet
                                                    ? '150px'
                                                    : '200px',
                                                objectFit: 'cover'
                                            }}
                                            alt={
                                                user?.professionalProfile?.firstName +
                                                ' ' +
                                                user?.professionalProfile?.lastName
                                            }
                                            border="3px #fff solid"
                                            borderRadius="50%"
                                            display="block"
                                        />
                                    )}
                                </Box>
                                {isMobile ? (
                                    <Box mt="4px" ml="40px">
                                        <Typography
                                            component="span"
                                            color="#05668D"
                                            fontFamily="open sans"
                                            fontSize={16}
                                            fontWeight={700}
                                        >
                                            {connections?.length ?? 0}
                                        </Typography>
                                        &nbsp;
                                        <Typography
                                            component={'span'}
                                            color="#05668D"
                                            fontFamily="open sans"
                                            fontSize={12}
                                        >
                                            {'Connection' +
                                                (connections?.length === 1
                                                    ? ''
                                                    : 's')}
                                        </Typography>
                                    </Box>
                                ) : null}
                            </Grid>
                        </Box>
                        {isMobile ? (
                            <Box
                                display="flex"
                                alignItems="flex-end"
                                flexDirection="column"
                                mt={-10}
                                mr="20px"
                                gap="16px"
                            >
                                <ScoreCard
                                    score={score}
                                    width="80px"
                                    height="80px"
                                />
                                {/* {isXMobile ? (
                                    <Box
                                        zIndex={1}
                                        onClick={handleOnEditProfileInfo}
                                    >
                                        <EditBtn />
                                    </Box>
                                ) : (
                                    <Button
                                        sx={{
                                            height: '40px',
                                            width: '150px',
                                            py: '8px',
                                            px: '8px',
                                            maxHeight: '40px',
                                            color: '#05668D',
                                            backgroundColor: '#F2F2F2',
                                            borderRadius: '8px',
                                            textTransform: 'none',
                                            textDecoration: 'none',
                                            gap: 1
                                        }}
                                        onClick={handleOnEditProfileInfo}
                                    >
                                        <EditBtn />
                                        Edit profile
                                    </Button>
                                )} */}
                            </Box>
                        ) : null}

                        {isMobile ? (
                            <Box
                                width="100%"
                                height="4px"
                                bgcolor="#f2f2f2"
                                my="8px"
                            ></Box>
                        ) : null}
                        <Grid
                            container
                            px={isMobile ? '16px' : isTablet ? '20px' : 5}
                            marginTop={{ xs: 0, sm: -1.5, lg: -5.5 }}
                            pb={{ xs: '16px', sm: 0 }}
                        >
                            <Grid item flexGrow={1} marginTop="auto">
                                <Grid container direction="column" gap={0.5}>
                                    <Grid
                                        item
                                        direction={
                                            isMobile ? 'column' : undefined
                                        }
                                    >
                                        <Grid
                                            container
                                            gap={'12px'}
                                            display="flex"
                                            alignItems="center"
                                        >
                                            <Grid
                                                item
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '4px'
                                                }}
                                            >
                                                <Typography
                                                    component="h2"
                                                    color="#23282B"
                                                    fontFamily="open sans"
                                                    // lineHeight="1.125"
                                                    fontSize={
                                                        isMobile
                                                            ? 22
                                                            : isTablet
                                                            ? 24
                                                            : 28
                                                    }
                                                    fontWeight={600}
                                                >
                                                    {user?.professionalProfile?.firstName}{' '}
                                                    {user?.professionalProfile?.lastName}
                                                </Typography>
                                                {/* <Box> */}
                                                <TickCircle />
                                                {/* <VerifiedIcon /> */}
                                                {/* </Box> */}
                                            </Grid>
                                            <CircleIcon
                                                sx={{
                                                    display: {
                                                        xs: 'none',
                                                        sm: 'block'
                                                    },
                                                    fontSize: '6px'
                                                }}
                                                htmlColor="#C5C5C5"
                                            />
                                            <Grid
                                                item
                                                sx={{ display: 'flex' }}
                                                alignItems="center"
                                            >
                                                <Grid
                                                    sx={{
                                                        display: {
                                                            xs: 'none',
                                                            sm: 'flex'
                                                        }
                                                    }}
                                                    container
                                                    wrap="nowrap"
                                                    width="auto"
                                                    // marginTop="auto"
                                                    flexGrow={1}
                                                    alignItems="center"
                                                    gap="12px"
                                                    // marginLeft={
                                                    //     isTablet ? -2 : -1
                                                    // }
                                                >
                                                    <Grid
                                                        item
                                                        flexGrow={1}
                                                        // mt={
                                                        //     isTablet
                                                        //         ? '30px'
                                                        //         : undefined
                                                        // }
                                                    >
                                                        <Typography
                                                            component="span"
                                                            color="#05668D"
                                                            fontFamily="open sans"
                                                            fontSize={20}
                                                            fontWeight={700}
                                                        >
                                                            {connections?.length ??
                                                                0}
                                                        </Typography>
                                                        &nbsp;
                                                        <Typography
                                                            component={'span'}
                                                            color="#05668D"
                                                            fontFamily="open sans"
                                                            fontSize={20}
                                                        >
                                                            {'Connection' +
                                                                (connections?.length ===
                                                                1
                                                                    ? ''
                                                                    : 's')}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid
                                                        item
                                                        // paddingRight="32px"
                                                    >
                                                        <Box
                                                            display="flex"
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            sx={{
                                                                bgcolor:
                                                                    '#f2f2f2',
                                                                borderRadius:
                                                                    '8px',
                                                                p: '4px'
                                                            }}
                                                        >
                                                            <MoreIcon />
                                                            {/* <MoreVertIcon fontSize="medium" htmlColor='#808080' sx={{ bgcolor:"#f2f2f2",
                                                            borderRadius:"8px", p:'4px'}}  /> */}
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            {/* <Grid item alignItems="center">
                                                <Grid
                                                    container
                                                    gap={isTablet ? 1 : 2}
                                                    alignItems="center"
                                                >
                                                    {isMobile ? (
                                                        <CheckCircleIcon
                                                            htmlColor="#49B6FF"
                                                            style={{
                                                                marginLeft:
                                                                    '8px'
                                                            }}
                                                        />
                                                    ) : (
                                                        <>
                                                            <CircleIcon
                                                                sx={{
                                                                    fontSize:
                                                                        '7.25px'
                                                                }}
                                                                htmlColor="#494949"
                                                            />
                                                            {user?.professionalProfile
                                                                ?.currentEmployment
                                                                ?.jobTitle ? (
                                                                <>
                                                                    <Typography
                                                                        component="span"
                                                                        color="#808080"
                                                                        fontFamily="open sans"
                                                                        fontSize={
                                                                            16
                                                                        }
                                                                        fontWeight={
                                                                            400
                                                                        }
                                                                    >
                                                                        {
                                                                            user?.professionalProfile
                                                                                ?.currentEmployment
                                                                                ?.jobTitle
                                                                        }
                                                                    </Typography>
                                                                    <CheckCircleIcon
                                                                        htmlColor="#49B6FF"
                                                                        style={{
                                                                            marginLeft:
                                                                                isTablet
                                                                                    ? '8px'
                                                                                    : '-8px'
                                                                        }}
                                                                    />
                                                                </>
                                                            ) : null}
                                                            <Grid item ml={1}>
                                                                <Button
                                                                    variant="contained"
                                                                    style={{
                                                                        width: 'auto',
                                                                        minWidth:
                                                                            'auto',
                                                                        color: '#808080',
                                                                        backgroundColor:
                                                                            '#F2F2F2'
                                                                    }}
                                                                    sx={{
                                                                        px: 1,
                                                                        py: 1.25
                                                                    }}
                                                                    onClick={
                                                                        handleOnEdituser?.professionalProfileInfo
                                                                    }
                                                                >
                                                                    <EditIcon fontSize="small" />
                                                                </Button>
                                                            </Grid>
                                                        </>
                                                    )}
                                                </Grid>
                                            </Grid> */}
                                        </Grid>
                                        {/* {isMobile &&
                                        user?.professionalProfile?.currentEmployment?.jobTitle ? (
                                            <Typography
                                                // mt="12px"
                                                component="span"
                                                color="#808080"
                                                fontFamily="open sans"
                                                fontSize={14}
                                                fontWeight={400}
                                            >
                                                {
                                                    user?.professionalProfile?.currentEmployment
                                                        ?.jobTitle
                                                }
                                            </Typography>
                                        ) : null} */}
                                    </Grid>
                                    <Grid
                                        item
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '12px'
                                        }}
                                    >
                                        <Typography
                                            component={'p'}
                                            color="#23282B"
                                            fontFamily="open sans"
                                            fontSize={isMobile ? 14 : 16}
                                            fontWeight={400}
                                        >
                                            {user?.professionalProfile?.tagline}
                                        </Typography>
                                        {/* <CircleIcon
                                            sx={{
                                                fontSize: '6px'
                                            }}
                                            htmlColor="#C5C5C5"
                                        />
                                        {profile?.currentEmployment
                                            ?.jobTitle ? (
                                            <Typography
                                                // mt="12px"
                                                component="span"
                                                color="#23282B"
                                                fontFamily="open sans"
                                                fontSize={14}
                                                fontWeight={400}
                                            >
                                                {
                                                    profile?.currentEmployment
                                                        ?.jobTitle
                                                }
                                            </Typography>
                                        ) : null} */}
                                    </Grid>
                                    <Grid item>
                                        <Grid
                                            container
                                            gap={'4px'}
                                            alignItems="center"
                                        >
                                            {user?.professionalProfile?.address ? (
                                                <>
                                                    <Grid item>
                                                        <InfoChip
                                                            type="location"
                                                            label={`${
                                                                user?.professionalProfile?.address
                                                                    ?.city ?? ''
                                                            }, ${
                                                                user?.professionalProfile?.address
                                                                    ?.country ??
                                                                ''
                                                            }`}
                                                        />
                                                    </Grid>
                                                    {/* {isMobile ? null : (
                                                        <CircleIcon
                                                            sx={{
                                                                fontSize:
                                                                    '7.25px'
                                                            }}
                                                            htmlColor="#494949"
                                                        />
                                                    )} */}
                                                </>
                                            ) : null}
                                            {user?.professionalProfile?.phoneNumber ? (
                                                <>
                                                    <Grid item>
                                                        <InfoChip
                                                            type="phone"
                                                            label={
                                                                user?.professionalProfile?.phoneNumber ??
                                                                ''
                                                            }
                                                        />
                                                    </Grid>
                                                    {/* {isMobile ? null : (
                                                        <CircleIcon
                                                            sx={{
                                                                fontSize:
                                                                    '7.25px'
                                                            }}
                                                            htmlColor="#494949"
                                                        />
                                                    )} */}
                                                </>
                                            ) : null}
                                            {user?.professionalProfile?.emailAddress ? (
                                                <>
                                                    <Grid item>
                                                        <InfoChip
                                                            type="email"
                                                            label={
                                                                user?.professionalProfile?.emailAddress ??
                                                                ''
                                                            }
                                                        />
                                                    </Grid>
                                                </>
                                            ) : null}
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    style={{
                                                        width: 'auto',
                                                        minWidth: 'auto',
                                                        color: '#808080',
                                                        backgroundColor:
                                                            '#F2F2F2'
                                                    }}
                                                    sx={{
                                                        p: '6px'
                                                    }}
                                                    onClick={
                                                        handleOnEditProfileInfo
                                                    }
                                                >
                                                    <EditIcon fontSize="small" />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {isMobile ? null : (
                                <Grid
                                    item
                                    flexShrink={1}
                                    position={{ sm: 'absolute', lg: 'static' }}
                                    right={'21px'}
                                    bottom={'120px'}
                                >
                                    <Box py={1}>
                                        <ScoreCard score={score} />
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </Grid>
                {/* {isMobile ? (
                    <Box
                        width="100%"
                        height="2px"
                        bgcolor="#f2f2f2"
                        // my="16px"
                    ></Box>
                ) : null} */}
                <Grid item>
                    <Grid
                        pl={isMobile ? 0 : isTablet ? '0px' : undefined}
                        container
                        wrap="nowrap"
                        gap={{ xs: 1, lg: 1.5 }}
                    >
                        <Grid width="100%" item xs={isTablet ? 12 : 8}>
                            <Box
                                px={
                                    isMobile
                                        ? '16px'
                                        : isTablet
                                        ? '20px'
                                        : '40px'
                                }
                                bgcolor="#fff"
                                py="16px"
                            >
                                <Box
                                    mb={'16px'}
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Typography
                                        component="h2"
                                        color="#23282B"
                                        fontFamily="open sans"
                                        lineHeight="1.125"
                                        fontSize={
                                            isMobile ? 20 : isTablet ? 24 : 28
                                        }
                                        fontWeight={600}
                                    >
                                        Activities
                                    </Typography>

                                    <Typography
                                        component="h2"
                                        color="#808080"
                                        fontFamily="open sans"
                                        lineHeight="24px"
                                        fontSize={16}
                                        fontWeight={400}
                                    >
                                        {totalPosts + ' posts'}
                                    </Typography>
                                </Box>
                                <Box
                                    display="flex"
                                    height="44px"
                                    bgcolor="#f2f2f2"
                                >
                                    {currentActivity.map((item) => (
                                        <Box
                                            width={`${100 / 3}%`}
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',

                                                borderBottomColor:
                                                    activity === item
                                                        ? '#05668D'
                                                        : 'transparent'
                                            }}
                                            onClick={() => setActivity(item)}
                                            borderBottom="2px solid"
                                        >
                                            <Typography
                                                fontFamily="open sans"
                                                fontSize={14}
                                                fontWeight={600}
                                                lineHeight="20px"
                                                color={
                                                    activity === item
                                                        ? '#05668D'
                                                        : '#808080'
                                                }
                                            >
                                                {item}
                                            </Typography>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                            <Box
                                py={isMobile ? '16px' : '16px'}
                                px={isTablet ? 0 : '0px'}
                                bgcolor="#f2f2f2"
                            >
                                {activity === 'Posts' ? (
                                    posts?.length === 0 ? (
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            gap="12px"
                                            pl={isMobile ? '16px' : undefined}
                                        >
                                            <Alert />
                                            <Typography> No Posts</Typography>
                                        </Box>
                                    ) : (
                                        <Box width="100%">
                                            <InfiniteScroll
                                                dataLength={posts.length}
                                                next={() =>
                                                    setGetAfter(
                                                        (prev) => prev + 5
                                                    )
                                                }
                                                hasMore={
                                                    posts.length <
                                                    (totalPosts ?? 0)
                                                }
                                                scrollThreshold={0.9}
                                                loader={
                                                    <>
                                                        {[1, 2, 3].map((_) => (
                                                            <SkeletonLoader />
                                                        ))}
                                                    </>
                                                }
                                            >
                                                {posts?.map((feed, index) => (
                                                    <FeedItem
                                                        key={index}
                                                        feed={feed}
                                                    />
                                                ))}
                                            </InfiniteScroll>
                                        </Box>
                                    )
                                ) : activity === 'Achievements' ? (
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        gap="12px"
                                        pl={isMobile ? '16px' : undefined}
                                    >
                                        <Alert />
                                        <Typography>
                                            {' '}
                                            No Achievements
                                        </Typography>
                                    </Box>
                                ) : (
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        gap="12px"
                                        pl={isMobile ? '16px' : undefined}
                                    >
                                        <Alert />
                                        <Typography>No Articles</Typography>
                                    </Box>
                                )}
                            </Box>
                        </Grid>
                        {isTablet ? null : (
                            <Grid item width="100%" xs={isTablet ? 12 : 4}>
                                {Images.length !== 0 && (
                                    <Box
                                        width="100%"
                                        // heading={
                                        //     <SectionHeading
                                        //         disable={true}
                                        //         ml={0}
                                        //     >
                                        //         Top Professionals in your industry
                                        //     </SectionHeading>
                                        // }
                                        onClick={() => {}}
                                        mb="12px"
                                        // py={'16px'}
                                    >
                                        <Box
                                            bgcolor="#fff"
                                            p="8px 16px"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="space-between"
                                            mb="4px"
                                        >
                                            <Typography
                                                fontFamily="Open Sans"
                                                fontSize="16px"
                                                fontWeight={600}
                                                lineHeight="24px"
                                                letterSpacing="0.024px"
                                            >
                                                Gallery
                                            </Typography>
                                            <IconButton
                                                onClick={() => {}}
                                                sx={{
                                                    background: '#DFEBF0',
                                                    borderRadius: '8px',
                                                    padding: '8px'
                                                }}
                                            >
                                                <ArrowRight />
                                            </IconButton>
                                        </Box>
                                        <ImageList
                                            sx={{
                                                width: '100%',
                                                height: '100%',
                                                borderRadius: '12px'
                                            }}
                                            cols={3}
                                        >
                                            {/* <ImageListItem>
                                            <img
                                                src={company?.logo}
                                                alt={'Gallery'}
                                                style={{ width: '100%' }}
                                            />
                                        </ImageListItem> */}
                                            {Images?.map((image, index) => (
                                                <ImageListItem key={index}>
                                                    <img
                                                        src={image}
                                                        alt={'Gallery'}
                                                        style={{
                                                            width: '100%'
                                                        }}
                                                    />
                                                </ImageListItem>
                                            ))}
                                        </ImageList>
                                    </Box>
                                )}
                                <Box
                                    width="100%"
                                    // heading={
                                    //     <SectionHeading
                                    //         disable={true}
                                    //         ml={0}
                                    //     >
                                    //         Top Professionals in your industry
                                    //     </SectionHeading>
                                    // }
                                    onClick={() => {}}
                                    mb="12px"
                                    // py={'16px'}
                                >
                                    <Box
                                        bgcolor="#fff"
                                        p="8px 16px"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize="16px"
                                            fontWeight={600}
                                            lineHeight="24px"
                                            letterSpacing="0.024px"
                                        >
                                            Top Professionals in your industry
                                        </Typography>
                                        <IconButton
                                            onClick={() => {}}
                                            sx={{
                                                background: '#DFEBF0',
                                                borderRadius: '8px',
                                                padding: '8px'
                                            }}
                                        >
                                            <ArrowRight />
                                        </IconButton>
                                    </Box>
                                    <Box mt="4px" bgcolor="#fff">
                                        {professionals.map((person) => (
                                            <ProfileList data={person} />
                                        ))}
                                    </Box>
                                </Box>

                                <Box
                                    // heading={
                                    // <SectionHeading ml={0} fontSize={106}>
                                    //     Analytics
                                    // </SectionHeading>
                                    // }
                                    onClick={() => {}}
                                    mb="12px"
                                    bgcolor="transparent"
                                >
                                    <Box
                                        bgcolor="#fff"
                                        p="8px 16px"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        mb="4px"
                                    >
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize="16px"
                                            fontWeight={600}
                                            lineHeight="24px"
                                            letterSpacing="0.024px"
                                        >
                                            Analytics
                                        </Typography>
                                        <IconButton
                                            onClick={() => {}}
                                            sx={{
                                                background: '#DFEBF0',
                                                borderRadius: '8px',
                                                padding: '8px'
                                            }}
                                        >
                                            <ArrowRight />
                                        </IconButton>
                                    </Box>
                                    <Grid
                                        bgcolor="#fff"
                                        container
                                        gap={1}
                                        overflow="hidden"
                                        borderRadius={1.5}
                                        wrap="nowrap"
                                        height="140px"
                                        p={1}
                                    >
                                        <Grid
                                            item
                                            bgcolor="#E6F0F4"
                                            flexGrow={1}
                                            maxWidth="33%"
                                        >
                                            <Grid
                                                container
                                                flexDirection="column"
                                                alignItems="center"
                                                justifyContent="center"
                                                height="100%"
                                            >
                                                <AnalyticsProfile />
                                                <Typography
                                                    component="p"
                                                    color="#23282B"
                                                    fontFamily="open sans"
                                                    fontSize={14}
                                                    fontWeight={600}
                                                    textAlign="center"
                                                >
                                                    PROFILE
                                                    <br />
                                                    VIEWS
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            bgcolor="#E6F0F4"
                                            flexGrow={1}
                                            maxWidth="33%"
                                        >
                                            <Grid
                                                container
                                                flexDirection="column"
                                                alignItems="center"
                                                justifyContent="center"
                                                height="100%"
                                            >
                                                <AnalyticsSearch />
                                                <Typography
                                                    component="p"
                                                    color="#23282B"
                                                    fontFamily="open sans"
                                                    fontSize={14}
                                                    fontWeight={600}
                                                    textAlign="center"
                                                >
                                                    SEARCH
                                                    <br />
                                                    APPEARANCES
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            bgcolor="#E6F0F4"
                                            flexGrow={1}
                                            maxWidth="33%"
                                        >
                                            <Grid
                                                container
                                                flexDirection="column"
                                                alignItems="center"
                                                justifyContent="center"
                                                height="100%"
                                            >
                                                <AnalyticsGraph />
                                                <Typography
                                                    component="p"
                                                    color="#23282B"
                                                    fontFamily="open sans"
                                                    fontSize={14}
                                                    fontWeight={600}
                                                    textAlign="center"
                                                >
                                                    POSTS
                                                    <br />
                                                    ENGAGEMENTS
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box p={1} bgcolor="#fff">
                                    <Button
                                        variant="outlined"
                                        sx={{
                                            backgroundColor: '#f2f2f2',
                                            borderRadius: '8px',
                                            width: '100%'
                                        }}
                                    >
                                        <Typography
                                            color={'primary'}
                                            fontFamily="open sans"
                                            fontWeight={700}
                                            fontSize={14}
                                            sx={{
                                                textTransform: 'none',
                                                py: 0.5
                                            }}
                                        >
                                            Upgrade to Premium plan
                                        </Typography>
                                    </Button>
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            {/* <EditContactInfoDialog
                open={openContactInfoModal}
                setOpen={setOpenContactInfoModal}
            /> */}
            <EditProfileAboutMeDialog
                open={openAboutMeModal}
                setOpen={setOpenAboutMeModal}
            />
            <EditProfileInfoDialog
                open={openProfileInfoModal}
                setOpen={setOpenProfileInfoModal}
                ref={ref}
            />
        </Container>
    );
}

export default Activities;
