/* eslint-disable react-hooks/exhaustive-deps */
import { IFeedItem } from '../../pages/Home/types/IFeedItem';
import { useGetUserPosts } from '../hooks/api/posts/useGetUserPosts';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAuth } from './AuthContext';
import { ICommentType } from '../../pages/Home/components/CommentBox';
import { PrimaryProfileType } from '../hooks/api/account/types';

interface PostContextType {
    posts: IFeedItem[];
    setPosts: React.Dispatch<React.SetStateAction<any>>;
    refechPosts: () => void;
    totalPosts: number;
    setGetAfter: React.Dispatch<React.SetStateAction<number>>;
    isLoaded: boolean;
}

interface PostProviderProps {
    children: React.ReactNode;
    requestType: string;
}

export const PostContext = createContext<PostContextType>({
    posts: [],
    setPosts: () => {},
    totalPosts: 0,
    refechPosts: () => {},
    setGetAfter: () => {},
    isLoaded: false
});

export const PostProvider: React.FC<PostProviderProps> = ({
    children,
    requestType
}) => {
    const [getAfter, setGetAfter] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);
    const [totalPosts, setTotalPosts] = useState(0);
    const [posts, setPosts] = useState<IFeedItem[]>([]);
    const { user } = useAuth();

    const {
        data = [],
        isFetching,
        refetch: refechPosts
    } = useGetUserPosts(
        getAfter,
        requestType,
        window.location.pathname.split('/')[2] ??
            (user?.primaryProfile === PrimaryProfileType.Professional
                ? user?.professionalProfile?.id
                : user?.id),
        user?.primaryProfile
    );

    const populateData = (x: any): IFeedItem => {
        let feed: IFeedItem = {
            id: x?.id,
            profileId: x?.user?.id,
            profileImage: x?.user?.photoUrl,
            primaryProfile: x?.user?.primaryProfile,
            fullName: x?.user?.firstName ?? '' + x?.user?.lastName ?? '',
            jobTitle: x?.user?.currentEmployment?.jobTitle ?? '',
            description: x?.message ?? '',
            views: x?.views ?? 0,
            likes: x?.likes ?? 0,
            likedByProfessionalIds: x?.likedByProfessionalIds ?? [],
            commentsCount: x?.commentsCount,
            shares: x?.repostCount,
            images: x?.photos ?? [],
            isAccountVerified: true,
            createdAt: x?.createdAt,
            comments:
                x?.comments?.sort((a: ICommentType, b: ICommentType) =>
                    a.createdAt < b.createdAt ? -1 : 1
                ) ?? [],
            userComment: x?.userComment ?? ''
        };
        if (x?.repost) {
            feed.repost = populateData(x.repost as IFeedItem);
        }
        return feed;
    };

    useEffect(() => {
        setIsLoaded(false);
        try {
            if (!isFetching && data?.data) {
            const dataFeed = data?.data?.map((x: IFeedItem) => populateData(x));
            setTotalPosts(data?.total);
            setPosts((prev) => [...prev, ...dataFeed]);
            setIsLoaded(true);
        }
        } catch{}
    }, [isFetching, data?.data]);

    useEffect(() => {
        if (!!requestType) {
            setGetAfter(0);
            setTotalPosts(0);
            setPosts([]);
        }
    }, [requestType, window.location.pathname]);

    return (
        <PostContext.Provider
            value={{
                posts,
                setPosts,
                totalPosts,
                refechPosts,
                setGetAfter,
                isLoaded
            }}
        >
            {children}
        </PostContext.Provider>
    );
};

export const usePost = () => useContext(PostContext);
