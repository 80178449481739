import { Avatar, Box, Button, Grid, IconButton, ImageList, ImageListItem, ImageListItemBar, InputBase } from '@mui/material';
import { PhotoIcon } from '../../../assets/icons/PhotoIcon';
import { VideoIcon } from '../../../assets/icons/VideoIcon';
import SideNavProfile from '../../../assets/images/profile-sq.png';
import { useRef, useState } from 'react';
import PostCards from './PostCards';
import { PrimaryProfileType } from '../../../utils/hooks/api/account/types';
import { useAuth } from '../../../utils/context/AuthContext';
import TeacherIcon from '../../../assets/icons/TeacherIcon';
import AchievementsIcon from '../../../assets/icons/AchievementsIcon';
import SnackAlert from '../../../components/Snackbar';
import CancelImageIcon from '../../../assets/icons/CancelIcon';
import { useUserPost } from '../../../utils/hooks/api/posts/useCreatePost';
import { usePost } from '../../../utils/context/PostContext';
import { IFeedItem } from '../types/IFeedItem';
import { MentionsInput, Mention } from 'react-mentions';
import MentionsDetails from './MentionsDetails';
import { useGetSearchTerm } from '../../../utils/hooks/api/search/useSearchTerm';
import HashTag from './HashTag';

interface ICommentType {
    id: string;
    createdAt: number;
    comment: string;
    postId: string;
    likes: number;
    likedByProfessionalIds: any[];
    user: {
        photoUrl?: string;
        firstName: string;
        lastName: string;
        id: string;
    };
    isAccountVerified?: boolean;
    replies: Array<ICommentType>;
}

function CreateFeedCard() {
    const [showmodal, setShowModal] = useState<string>('');
    const { user, account } = useAuth();
    const [message, setMessage] = useState('');
    const [editorText, setEditorText] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [assetArea, setAssetArea] = useState<File[]>([]);
    const [mention, setMention] = useState('');
        const [hashtags, setHashtags] = useState<
            { id: string; display: string }[]
        >([
            { id: '1', display: '#mentalhealth' },
            { id: '2', display: '#focus' },
            { id: '3', display: '#frontend' },
            { id: '4', display: '#backend' }
        ]);
    const isCompany = account?.primaryProfile === PrimaryProfileType.Company;
    const createPost = useUserPost();
    const [postId, setPostId] = useState('');
    const [showBorder, setShowBorder] = useState(false);
     const { posts, setPosts } = usePost();
    let data = {
        userimage: SideNavProfile,
        username: 'Jacob Jones'
    };
    const inputref = useRef<HTMLInputElement>(null);
        const onDeletImage = async (item: any) => {
            const filterdata = assetArea?.filter((x: any) => x !== item);
            setAssetArea(filterdata);
        };
    
        const { data: mentionsDetailData, refetch } = useGetSearchTerm(
            mention,
            user?.professionalProfile?.userId ?? ''
        );

        const MentionData = mentionsDetailData?.map((user?: any) => ({
            id: user?.id,
            display: user?.term,
            imageUrl: user?.imageURl,
            score: user?.score,
            currentEmployment: user?.currentEmployment
        }));

    const onChangeImage = (e: any) => {
        let temp: any = [];
        for (let i = 0; i < e.target.files?.length; i++) {
            temp.push(e.target.files[i]);
        }
        setAssetArea([...assetArea, ...temp]);
    };

    const populateData = (x: any, comment: ICommentType): IFeedItem => {
        let feed: IFeedItem = {
            id: x?.id,
            profileId: x?.user?.id,
            profileImage: x?.user?.photoUrl,
            fullName: x?.user?.firstName ?? '' + x?.user?.lastName ?? '',
            jobTitle: x?.user?.currentEmployment?.jobTitle ?? '',
            description: x?.message ?? '',
            views: 0,
            likes: 0,
            createdAt: x?.createdAt,
            commentsCount: 0,
            comments: [comment],
            shares: x?.repostCount,
            images: x?.photos ?? [],
            isAccountVerified: true
        };
        if (x?.repost) {
            feed.repost = populateData(x.repost as IFeedItem, comment);
        }
        return feed;
    };

    const reset = () => {
        setEditorText('');
        setAssetArea([]);
    };

        const onPostData = () => {
            let formData = new FormData();
            formData.append(
                'userId',
                user?.primaryProfile === PrimaryProfileType.Professional
                    ? user?.professionalProfile?.userId ?? ''
                    : user?.id ?? ''
            );
            formData.append(
                'senderId',
                user?.primaryProfile === PrimaryProfileType.Professional
                    ? user?.professionalProfile?.userId ?? ''
                    : user?.id ?? ''
            );
            formData.append('primaryProfile', user?.primaryProfile ?? '');
            formData.append('message', editorText);
            formData.append('repostId', '' ?? null);
            assetArea.map((file: File) => formData.append('files', file));
            createPost.mutate(formData, {
                onSuccess: (res) => {
                    console.log('res', res);
                    setPostId(res?.post?.id);
                    if (
                        window.location.pathname === '/' ||
                        (window.location.pathname === '/activities' &&
                            res?.post?.user?.id ===
                                user?.professionalProfile?.id)
                    ) {
                        const post = populateData(res?.post, res?.comment);
                        setPosts([post, ...posts]);
                    }
                    
                        if (res) {
                            setMessage('Post created successfully');
                            setShowAlert(true);
                        }
                        reset();
                },
                onError: (err) => {
                    console.log('err', err);
                    setMessage('Error occured please try again!');
                    setShowAlert(true);
                }
            });
        };

    const btnStyle = {
        backgroundColor: '#F2F2F2',
        borderRadius: '8px',
        fontFamily: 'Open Sans',
        fontSize: 14,
        color: '#808080',
        textDecoration: 'none',
        fontWeight: 600,
        minWidth:'fit-content',
        width: 'fit-content',
        padding: '8px'
    };
    return (
        <>
            <Box
                sx={{
                    height: 'fit-content',
                    px: '20px',
                    py: '16px',
                    backgroundColor: 'white',
                    position: 'relative'
                }}
            >
                <Grid
                    container
                    wrap="nowrap"
                    sx={{ display: 'flex', gap: '16px', overflow: 'auto' }}
                >
                    {/* <Grid item xs={2} sx={{ maxWwidth: '72px', mr: '16px' }} bgcolor="blue"> */}
                    <Avatar
                        sx={{
                            height: 72,
                            width: 72
                        }}
                        src={
                            user?.primaryProfile ===
                            PrimaryProfileType.Professional
                                ? user?.professionalProfile?.photoUrl
                                : user?.primaryCompanyProfile?.photoUrl
                        }
                        alt="profile"
                    />
                    {/* </Grid> */}
                    <Grid item xs={12} sx={{ overflow: 'auto' }}>
                        <InputBase
                            rows={4}
                            placeholder="What’s happening?"
                            value={editorText}
                            onChange={(e) => setEditorText(e.target.value)}
                            // readOnly={true}
                            sx={{
                                backgroundColor: '#f2f2f2',
                                width: '100%',
                                overflowY: 'auto',
                                padding: 0,
                                py: '8px',
                                px: '12px',
                                fontFamily: 'open sans',
                                color: '#23282B',
                                borderRadius: '8px'
                            }}
                            multiline
                            // onClick={() => setShowModal('open')}
                        />
                        {/* <MentionsInput
                            style={{
                                backgroundColor: '#f2f2f2',
                                width: '100%',
                                height: '100px',
                                position: 'relative',
                                overflow: 'auto',
                                input: {
                                    // overflow: 'auto',
                                    // height: '100%',
                                    // width: '100%',
                                    backgroundColor: 'red',
                                    padding: '12px',
                                    borderColor: 'transparent',
                                    margin: 0,
                                    '&:focus': {
                                        border: '3px solid transparent'
                                    }
                                },
                                fontFamily: 'open sans',
                                color: '#23282B',
                                border: showBorder
                                    ? '3px solid #49B6FF'
                                    : 'none',
                                // borderBottom:
                                //     repostPopup
                                //         ? '3px solid transparent'
                                //         : '3px solid #49B6FF',
                                borderRadius: '8px'
                                // '& textarea': {
                                //     height: `${200}px !important`,
                                //     display: 'none'
                                // }
                            }}
                            onFocus={() => setShowBorder(true)}
                            onBlur={() => setShowBorder(false)}
                            placeholder="What’s happening?"
                            spellCheck={false}
                            value={editorText}
                            onChange={(e: any) => {
                                const newValue = e.target.value;
                                setEditorText(newValue);

                                const mentions = newValue.match(/@([^\s@]+)/g);

                                if (mentions) {
                                    for (const mention of mentions) {
                                        const mentionKeyword = mention
                                            .substring(1)
                                            .toLowerCase(); // Remove "@" and convert to lowercase

                                        setMention(mentionKeyword);
                                    }
                                }
                            }}
                        >
                            <Mention
                                style={{
                                    borderRadius: '4px',
                                    background: '#B2D0DC',
                                    Width: '100px',
                                    height: '24px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontFamily: 'Open Sans',
                                    fontSize: '16px',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    lineHeight: '24px',
                                    letterSpacing: '0.08px'
                                }}
                                trigger="@"
                                data={MentionData}
                                // onSearchChange={(e: any, trigger: any, value: any) => provideData(value, (data) => {logger.log('so=>', a, trigger, value)})}
                                // renderSuggestion={(s:any)=>logger.log("render", s)}
                                // displayTransform={(url) => `@${url}`}
                                renderSuggestion={(MentionData?: any) => (
                                    <MentionsDetails
                                        key={MentionData?.id}
                                        // user={MentionData}
                                        user={{
                                            imageUrl: MentionData?.imageUrl,
                                            term: MentionData?.display,
                                            score: MentionData?.score,
                                            currentEmployment:
                                                MentionData?.currentEmployment
                                        }}
                                    />
                                )}
                            />

                            <Mention
                                trigger="#"
                                style={{
                                    borderRadius: '4px',
                                    background: '#FFC0CB',
                                    width: '100px',
                                    height: '24px',
                                    padding: '2px 8px',
                                    fontFamily: 'Open Sans',
                                    fontSize: '16px',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    lineHeight: '24px',
                                    letterSpacing: '0.08px'
                                }}
                                data={hashtags}
                                renderSuggestion={(hashtags?: any) => (
                                    <HashTag
                                        key={hashtags?.id}
                                        display={hashtags?.display}
                                    />
                                )}
                            />
                        </MentionsInput> */}
                        {assetArea?.length > 0 && (
                            <Box
                                sx={{
                                    height: '200px',
                                    // pt: '12px',
                                    // background: '#fff',
                                    width: '100%'
                                }}
                            >
                                <ImageList
                                    sx={{
                                        width: '100%',
                                        p: 0,
                                        m: 0,
                                        maxHeight: '100%'

                                        // transform:
                                        //     'translateZ(0)'
                                    }}
                                    // rowHeight={200}
                                    gap={12}
                                >
                                    {assetArea.map(
                                        (item: any, index: number) => {
                                            const cols = item?.featured ? 2 : 1;
                                            const rows = item?.featured ? 2 : 1;
                                            return (
                                                <ImageListItem
                                                    sx={{
                                                        objectFit: 'cover',
                                                        overflow: 'hidden'
                                                    }}
                                                    key={index + 2}
                                                    cols={cols}
                                                    rows={rows}
                                                >
                                                    <img
                                                        src={URL.createObjectURL(
                                                            item
                                                        )}
                                                        alt={'images'}
                                                        loading="lazy"
                                                        style={{
                                                            objectFit: 'cover',
                                                            width: '100%',
                                                            height: '200px'
                                                            // maxWidth:
                                                            //     '200px'
                                                        }}
                                                    />
                                                    <ImageListItemBar
                                                        sx={{
                                                            background:
                                                                '#ffffff0d'
                                                        }}
                                                        position="top"
                                                        actionIcon={
                                                            <div
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    padding: 10
                                                                }}
                                                                onClick={() => {
                                                                    onDeletImage(
                                                                        item
                                                                    );
                                                                }}
                                                            >
                                                                <CancelImageIcon />
                                                            </div>
                                                        }
                                                        actionPosition="left"
                                                    />
                                                </ImageListItem>
                                            );
                                        }
                                    )}
                                </ImageList>
                            </Box>
                        )}
                        <Box
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'space-between',
                                mt: '8px'
                            }}
                        >
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    gap: '8px',
                                    flexWrap: 'wrap'
                                }}
                            >
                                <Button
                                    sx={btnStyle}
                                    onClick={() => {
                                        inputref.current?.click();
                                    }}
                                    startIcon={<PhotoIcon color="#FFC24C" />}
                                >
                                    Photo
                                </Button>
                                <input
                                    type="file"
                                    ref={inputref}
                                    multiple
                                    accept="image/jpg, image/jpeg, image/png"
                                    name="image"
                                    id="imageFile"
                                    onChange={(e) => {
                                        onChangeImage(e);
                                    }}
                                    style={{ display: 'none' }}
                                />
                                {/* <Button
                                sx={btnStyle}
                                onClick={() => setShowModal('open')}
                                startIcon={<VideoIcon color="#49B6FF" />}
                            >
                                Video
                            </Button> */}

                                {isCompany ? null : (
                                    <Button
                                        sx={btnStyle}
                                        onClick={() =>
                                            setShowModal('achievement')
                                        }
                                        startIcon={
                                            <AchievementsIcon color="#08BD04" />
                                        }
                                    >
                                        Achievements
                                    </Button>
                                )}
                                {/* <Button
                                sx={btnStyle}
                                onClick={() => setShowModal('open')}
                                startIcon={<TeacherIcon />}
                            >
                                Article
                            </Button> */}
                            </Box>
                            <Button
                                variant="contained"
                                sx={{
                                    display: 'block',
                                    width: '123px',
                                    py: 1,
                                    textTransform: 'none'
                                }}
                                onClick={onPostData}
                                disabled={
                                    editorText.length === 0 &&
                                    assetArea.length === 0
                                }
                            >
                                Post
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <SnackAlert
                open={showAlert}
                handleClose={() => setShowAlert(false)}
                message={message}
                type={'success'}
            />
            {showmodal && (
                <PostCards
                    showModal={showmodal ? true : false}
                    achievementModal={
                        showmodal === 'achievement' ? true : false
                    }
                    data={null}
                    hideModal={(res) => {
                        setShowModal('');
                        if (res === 'success') {
                            setMessage('Post created successfully');
                            setShowAlert(true);
                        }
                    }}
                />
            )}
        </>
    );
}

export default CreateFeedCard;
