import React from 'react'

const ArrowBack = () => {
  return (
      <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
          <path
              d="M11.1652 21.9573C10.9435 21.9573 10.7218 21.8756 10.5468 21.7006L3.46518 14.619C3.12684 14.2806 3.12684 13.7206 3.46518 13.3823L10.5468 6.30063C10.8852 5.96229 11.4452 5.96229 11.7835 6.30063C12.1218 6.63896 12.1218 7.19896 11.7835 7.53729L5.32018 14.0006L11.7835 20.464C12.1218 20.8023 12.1218 21.3623 11.7835 21.7006C11.6202 21.8756 11.3868 21.9573 11.1652 21.9573Z"
              fill="#494949"
          />
          <path
              d="M23.9167 14.875H4.28174C3.8034 14.875 3.40674 14.4783 3.40674 14C3.40674 13.5217 3.8034 13.125 4.28174 13.125H23.9167C24.3951 13.125 24.7917 13.5217 24.7917 14C24.7917 14.4783 24.3951 14.875 23.9167 14.875Z"
              fill="#494949"
          />
      </svg>
  );
}

export default ArrowBack
