import axiosInstance from '../axiosConfig';
import { AddEducationPostRequest } from './types';
import { UseMutationResult, useMutation } from 'react-query';

async function addEducation(postRequest: AddEducationPostRequest) {
    const response = await axiosInstance.post(`/api/educations`, postRequest);
    const data = await response.data;
    return data;
}
export function useAddEducation(): UseMutationResult<
    AddEducationPostRequest,
    unknown,
    AddEducationPostRequest
> {
    return useMutation(addEducation);
}
