import {
    Button,
    Divider,
    Stack,
    TextareaAutosize,
    Typography
} from '@mui/material';
import { Box } from '@mui/system';
import React, { ChangeEvent, useEffect, useState } from 'react';
import CommonDialog from '../../common/CommonDialog';
import usePatchCompanyProfile from '../../../utils/hooks/api/company-profile/usePatchCompanyProfile';
import { QueryKeys } from '../../../utils/hooks/api/QueryKey';
import { useQueryClient } from 'react-query';
import { PatchCompanyAccountRequest } from '../../../utils/hooks/api/company-profile/types';
import SnackAlert from '../../../components/Snackbar';

interface EditAboutModalProps {
    open: boolean;
    companyDetails: any;
    handleClose: () => void;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditAboutModal = (props: EditAboutModalProps) => {
    const { open, setOpen, companyDetails, handleClose } = props;
    const queryClient = useQueryClient();
    const updateInfo = usePatchCompanyProfile();
    const [message, setMessage] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [type, setType] = useState<'success' | 'info' | 'warning' | 'error'>(
        'info'
    );
    const [aboutUs, setAboutUs] = useState(
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur tempus urna at turpis condimentum lobortis. Ut commodo efficitur neque.'
    );

    const maxCount = '2,600';

    const handleTextareaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setAboutUs(event.target.value);
    };

    const handleSave = () => {
        let temp: PatchCompanyAccountRequest = {
            bio: aboutUs,
            yearFounded: companyDetails?.yearFounded ?? '',
            industry: companyDetails?.industry ?? '',
            companySize: companyDetails?.companySize ?? '',
            tagLine: companyDetails?.tagLine ?? '',
            address: companyDetails?.address ?? null,
            companyProfileId: companyDetails?.id ?? '',
            emailAddress: companyDetails?.emailAddress ?? '',
            companyName: companyDetails?.primaryCompany?.name ?? ''
        };
        updateInfo.mutate(temp, {
            onSuccess: (res) => {
                queryClient.invalidateQueries(
                    QueryKeys.RetrieveCompanyProfileById
                );
                console.log('onSuccess', res);
                handleClose();
            },
            onError: (error) => {
                console.log('onError', error);
                setType('error');
                setMessage('Error occured please try again!');
                setShowAlert(true);
            }
        });
    };

    useEffect(() => {
        if (open) {
            setAboutUs(companyDetails?.bio ?? '');
        }
    }, [open]);

    return (
        <CommonDialog
            open={open}
            onClose={() => setOpen(false)}
            actions={
                <Button
                    variant="contained"
                    sx={{
                        minWidth: '150px',
                        width: 'auto',
                        fontFamily: 'Open Sans',
                        fontWeight: 600,
                        fontSize: '14px',
                        lineHeight: '0.001em',
                        p: '12px 59px',
                        height: '44px'
                    }}
                    onClick={handleSave}
                    disabled={aboutUs.length === 0}
                >
                    Save
                </Button>
            }
            title="About us"
        >
            <Stack>
                <Typography
                    variant="bodyLargeRegular"
                    color="#494949"
                    sx={{ mt: 1 }}
                >
                    You can write about your company’s mandate, the industry, mission and vision. 
                    Some company talks about their achievement in the industry.
                </Typography>

                <Box
                    sx={{
                        backgroundColor: '#FFFAF1',
                        color: '#808080',
                        mt: 4,
                        p: 2
                    }}
                >
                    <TextareaAutosize
                        style={{
                            width: '100%',
                            backgroundColor: '#FFFAF1',
                            color: '#808080',
                            fontFamily: 'Open Sans',
                            maxWidth: '800px',
                            fontWeight: 400,
                            fontSize: '16px',
                            lineHeight: '24px',
                            border: 'none',
                            outline: 'none',
                            textAlign: 'justify'
                        }}
                        placeholder="Enter your about me"
                        value={aboutUs}
                        onChange={handleTextareaChange}
                    />
                </Box>
            </Stack>
            <Divider />
            <Box sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end' }}>
                {aboutUs?.length}/{maxCount}
            </Box>
            <SnackAlert
                open={showAlert}
                handleClose={() => setShowAlert(false)}
                message={message}
                type={type}
            />
        </CommonDialog>
    );
};

export default EditAboutModal;
