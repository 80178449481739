import axiosInstance from '../axiosConfig';
import { UseMutationResult, useMutation } from 'react-query';
import { IPostResponseType } from './types';

async function createProfessionalRating(postRequest: unknown) {
    const response = await axiosInstance.post(
        `/api/professional-ratings`,
        postRequest
    );
    const data = await response.data;
    return data;
}
export function useCreateProfessionalRating(): UseMutationResult<
    IPostResponseType,
    unknown,
    unknown
> {
    return useMutation(createProfessionalRating);
}
