const GlobalSearch = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
        >
            <g clipPath="url(#clip0_94_88755)">
                <path
                    d="M21.79 21.7307L21.07 21.0107C21.44 20.4507 21.66 19.7707 21.66 19.0507C21.66 17.0707 20.06 15.4707 18.08 15.4707C16.1 15.4707 14.5 17.0707 14.5 19.0507C14.5 21.0307 16.1 22.6307 18.08 22.6307C18.81 22.6307 19.48 22.4107 20.04 22.0407L20.76 22.7607C20.9 22.9007 21.09 22.9707 21.27 22.9707C21.46 22.9707 21.64 22.9007 21.78 22.7607C22.07 22.4707 22.07 22.0107 21.79 21.7307Z"
                    fill="#05668D"
                />
                <path
                    d="M2.02781 15.6016C2.02781 15.6316 2.00781 15.6716 2.00781 15.7016C2.92781 17.5416 4.42781 19.0516 6.26781 19.9616C6.29781 19.9616 6.33781 19.9416 6.36781 19.9416C6.02781 18.7816 5.76781 17.5916 5.57781 16.4016C4.37781 16.2016 3.18781 15.9416 2.02781 15.6016Z"
                    fill="#05668D"
                />
                <path
                    d="M19.0681 7.40016C18.1281 5.43016 16.5381 3.84016 14.5781 2.91016C14.9381 4.10016 15.2381 5.32016 15.4381 6.54016C16.6581 6.74016 17.8781 7.03016 19.0681 7.40016Z"
                    fill="#05668D"
                />
                <path
                    d="M1.92969 7.40023C3.12969 7.04023 4.34969 6.74023 5.56969 6.54023C5.76969 5.35023 6.01969 4.17023 6.35969 3.01023C6.32969 3.01023 6.28969 2.99023 6.25969 2.99023C4.37969 3.92023 2.84969 5.49023 1.92969 7.40023Z"
                    fill="#05668D"
                />
                <path
                    d="M13.8197 6.3307C13.5797 5.0307 13.2797 3.7307 12.8497 2.4707C12.8297 2.4007 12.8297 2.3407 12.8197 2.2607C12.0797 2.0807 11.2997 1.9707 10.4997 1.9707C9.68969 1.9707 8.91969 2.0807 8.16969 2.2707C8.15969 2.3407 8.16969 2.4007 8.14969 2.4807C7.72969 3.7407 7.41969 5.0307 7.17969 6.3307C9.38969 6.0907 11.6097 6.0907 13.8197 6.3307Z"
                    fill="#05668D"
                />
                <path
                    d="M5.36 8.15039C4.05 8.39039 2.77 8.70039 1.5 9.12039C1.43 9.14039 1.37 9.14039 1.3 9.15039C1.11 9.89039 1 10.6704 1 11.4704C1 12.2804 1.11 13.0504 1.3 13.8004C1.37 13.8104 1.43 13.8004 1.51 13.8204C2.77 14.2404 4.06 14.5504 5.37 14.7904C5.12 12.5804 5.12 10.3604 5.36 8.15039Z"
                    fill="#05668D"
                />
                <path
                    d="M19.7028 9.15039C19.6328 9.15039 19.5728 9.14039 19.4928 9.12039C18.2328 8.70039 16.9328 8.39039 15.6328 8.15039C15.8828 10.3604 15.8828 12.5804 15.6328 14.7804C16.9328 14.5404 18.2328 14.2404 19.4928 13.8104C19.5628 13.7904 19.6228 13.8004 19.7028 13.7904C19.8828 13.0404 20.0028 12.2704 20.0028 11.4604C20.0028 10.6704 19.8928 9.90039 19.7028 9.15039Z"
                    fill="#05668D"
                />
                <path
                    d="M7.17969 16.6116C7.41969 17.9216 7.71969 19.2116 8.14969 20.4716C8.16969 20.5416 8.15969 20.6016 8.16969 20.6816C8.91969 20.8616 9.68969 20.9716 10.4997 20.9716C11.2997 20.9716 12.0797 20.8616 12.8197 20.6716C12.8297 20.6016 12.8297 20.5416 12.8497 20.4616C13.2697 19.2016 13.5797 17.9116 13.8197 16.6016C12.7197 16.7216 11.6097 16.8116 10.4997 16.8116C9.38969 16.8116 8.27969 16.7316 7.17969 16.6116Z"
                    fill="#05668D"
                />
                <path
                    d="M6.95156 7.92031C6.65156 10.2803 6.65156 12.6603 6.95156 15.0303C9.31156 15.3303 11.6916 15.3303 14.0616 15.0303C14.3616 12.6703 14.3616 10.2903 14.0616 7.92031C11.6916 7.62031 9.31156 7.62031 6.95156 7.92031Z"
                    fill="#05668D"
                />
            </g>
            <defs>
                <clipPath id="clip0_94_88755">
                    <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(0 0.970703)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default GlobalSearch;
