import { Box, Grid, Typography } from '@mui/material';
import React from 'react'

const CustomPageTabs = ({ tabs, currentTab, setCurrentTab }: { tabs: string[]; currentTab: string; setCurrentTab: ((currentTab: string) => void)}) => {
  return (
      <Box
          p={{ xs: '4px', sm: '8px', lg: '12px' }}
          borderRadius={{ xs: '10px', sm: '12px' }}
          border="1px solid #C5C5C5"
      >
          <Grid
              container
              wrap="nowrap"
              sx={{ borderRadius: { xs: '8px', lg: '12px' } }}
          >
              {tabs.map((tab, i) => (
                  <Grid
                      key={tab}
                      item
                      xs={12 / tabs.length}
                      sx={{
                          height: {xs: '36px', lg: '44px'},
                          //   pt: {
                          //       xs: '11px',
                          //       sm: '8px',
                          //       lg: '12px'
                          //   },
                          //   pb: {
                          //       xs: '11px',
                          //       sm: '8px',
                          //       lg: '12px'
                          //   },
                          bgcolor: currentTab === tab ? '#05668D' : '#EDEDED',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius:
                              i === 0
                                  ? {
                                        xs: '8px 0 0 8px',
                                        lg: '12px 0 0 12px'
                                    }
                                  : i + 1 === tabs.length
                                  ? {
                                        xs: '0 8px 8px 0',
                                        lg: '0 12px 12px 0'
                                    }
                                  : 0,
                          cursor: 'pointer'
                      }}
                      onClick={() => setCurrentTab(tab)}
                  >
                      <Typography
                          fontSize={{
                              xs: '12px',
                              sm: '14px'
                          }}
                          color={currentTab === tab ? '#fff' : '#808080'}
                          //   fontWeight={currentTab === tab ? 600 : 400}
                      >
                          {tab}
                      </Typography>
                  </Grid>
              ))}
          </Grid>
      </Box>
  );
}

export default CustomPageTabs
