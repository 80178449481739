import {
    Box,
    Button,
    CircularProgress,
    InputAdornment,
    InputBase,
    InputLabel,
    Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import OnBoardingSidePanel from '../OnBoarding/OnBoardingSidePanel';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import PasswordFormIcon from '../../assets/icons/PasswordFormIcon';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../utils/context/AuthContext';
import { useStateMachine } from 'little-state-machine';
import {
    clearAction,
    updateAction,
    updateStep
} from '../Accounts/Professional/CreationSteps/updateAction';
import ErrorFormIcon from '../../assets/icons/ErrorFormIcon';
import useResetPassword from '../../utils/hooks/api/authentication/useResetPassword';
import SnackAlert from '../../components/Snackbar';
import axios, { AxiosError } from 'axios';
import ForgotPasswordSteps from './ForgotPasswordSteps/ForgotPasswordSteps';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
interface INewPasswordForm {
    password: string;
    confirmPassword: string;
}

function NewPassword() {
    let navigate = useNavigate();
    const [showPass, setShowPass] = useState(false);
    const [showCPass, setShowCPass] = useState(false);
    const { email, code } = useParams();
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState<'success' | 'info' | 'warning' | 'error'>(
        'info'
    );
    const { actions } = useStateMachine({
        updateAction,
        updateStep,
        clearAction
    });

    const {
        control,
        handleSubmit,
        formState,
        register,
        setError,
        clearErrors,
        getValues
    } = useForm<INewPasswordForm>();
    const { isDirty, isValid, errors } = formState;
    const resetPassword = useResetPassword();

    const onSubmit: SubmitHandler<INewPasswordForm> = async (data) => {
        actions.updateAction(data);
        setLoading(true);
        if (data.confirmPassword) {
            resetPassword.mutate(
                {
                    emailAddress: email ?? '',
                    code: code ?? '',
                    newPassword: data.confirmPassword
                },
                {
                    onSuccess: (res) => {
                        if (res.success) {
                            navigate('/login');
                            return;
                        }
                        setType('error');
                        setMessage('Error occured please try again!');
                        setShowAlert(true);
                        setLoading(false);
                    },
                    onError: (error: AxiosError) => {
                        setLoading(false);
                        if (axios.isAxiosError(error)) {
                            setType('error');
                            setMessage(
                                error?.response?.data?.message ??
                                'Error occured please try again!'
                            );
                            setShowAlert(true);
                            return;
                        }
                        setType('error');
                        setMessage('Error occured please try again!');
                        setShowAlert(true);
                        return;
                    }
                }
            );
        }
    };

    return (
        <>
            <Box
                sx={{
                    backgroundColor: { md: 'transparent', sm: '#FCFBF8', xs: 'transparent' },
                    borderRadius: '12px',
                    p: { md: 0, sm: '64px 40px', xs: 0 },
                    mt: { md: '50px', sm: '-220px', xs: 0 },
                    mx: { lg: '50px', md: '20px', sm: '77px', xs: '20px' },
                    mb: { md: 0, sm: '50px', xs: 0 }
                }}
            >
                {/* ellipsis steps */}
                <ForgotPasswordSteps />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: { xl: '70%' }
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                mt: { sm: '64px', xs: '20px' },
                                display: { sm: 'block', xs: "none" }
                            }}
                        >
                            <Typography
                                color="#494949"
                                variant="headlineLargeSemiBold"
                                sx={{ display: 'block' }}
                            >
                                Enter new password
                            </Typography>
                            <Typography
                                color="#808080"
                                variant="titleMediumRegular"
                                sx={{
                                    mt: '8px',
                                    display: 'block'
                                }}
                            >
                                Kindly enter a new password as the previous
                                one has been reset
                            </Typography>
                        </Box>
                        <Box sx={{ mt: { sm: '20px', xs: '28px' }, width: '100%' }}>
                            <Typography
                                color="#808080"
                                variant="titleSmallRegular"
                                sx={{
                                    mb: '16px',
                                    fontSize: 14,
                                    display: { sm: 'none', xs: "block" }
                                }}
                            >
                                Kindly enter a new password as the previous
                                one has been reset
                            </Typography>
                            <Box
                                sx={{
                                    width: '100%',
                                    position: 'relative',
                                }}
                            >
                                <InputLabel
                                    sx={{
                                        color: '#23282B',
                                        fontSize: { sm: '14px', xs: '12px' },
                                        position: 'absolute',
                                        top: '8px',
                                        left: '72px',
                                        zIndex: 1,
                                        fontFamily: 'Open Sans'
                                    }}
                                    htmlFor="password"
                                >
                                    Password
                                </InputLabel>
                                {/* password */}

                                <Controller
                                    name="password"
                                    control={control}
                                    rules={{
                                        required: 'Required field',
                                        minLength: {
                                            value: 4,
                                            message:
                                                'Passwords must be a minimum of 4 characters'
                                        },
                                        pattern: {
                                            value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]).{8,32}$/,
                                            message:
                                                'Passwords should contain at least one uppercase letter, one number, and one special character'
                                        }
                                    }}
                                    render={({ field }) => (
                                        <InputBase
                                            {...field}
                                            required
                                            error={!!errors.password}
                                            inputProps={{
                                                autoComplete:
                                                    'new-password',
                                                form: {
                                                    autoComplete: 'off'
                                                }
                                            }}
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <PasswordFormIcon />
                                                </InputAdornment>
                                            }
                                            placeholder="********"
                                            endAdornment={showPass ? <RemoveRedEyeIcon sx={{ color: '#808080', cursor: 'pointer' }} onClick={() => setShowPass((prev) => !prev)} /> : <VisibilityOffIcon sx={{ color: '#808080', cursor: 'pointer' }} onClick={() => setShowPass((prev) => !prev)} />}
                                            type={showPass ? 'text' : "password"}
                                            sx={{
                                                backgroundColor:
                                                    '#FFFFFF',
                                                width: '100%',
                                                height: '70px',
                                                padding: '0px 16px',
                                                mb: '2px',
                                                fontFamily: 'open sans',
                                                fontSize: { sm: '16px', xs: '14px' },
                                                color: '#23282B',
                                                borderBottom:
                                                    '1px solid',
                                                borderColor:
                                                    errors.password
                                                        ? 'red'
                                                        : '#D9D9D9',
                                                '& .MuiInputBase-input':
                                                {
                                                    ml: '7.5px',
                                                    position:
                                                        'relative',
                                                    top: '8px'
                                                }
                                            }}
                                        />
                                    )}
                                />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: '5px',
                                        mt: '10px',
                                        alignContent: 'start'
                                    }}
                                >
                                    <ErrorFormIcon />
                                    <Typography
                                        sx={{
                                            color: '#808080',
                                            fontSize: '12px',
                                            fontFamily:
                                                'Open Sans'
                                        }}
                                    >
                                        Password should contain
                                        uppercase letter(s),
                                        numbers(s) and special
                                        character(s)
                                    </Typography>
                                </Box>
                            </Box>
                            {/* repeat password */}
                            <Box
                                sx={{
                                    width: '100%',
                                    position: 'relative',
                                    mt: '18px'
                                }}
                            >
                                <InputLabel
                                    sx={{
                                        color: '#23282B',
                                        fontSize: { sm: '14px', xs: '12px' },
                                        position: 'absolute',
                                        top: '8px',
                                        left: '72px',
                                        zIndex: 1,
                                        fontFamily: 'Open Sans'
                                    }}
                                    htmlFor="confirmPassword"
                                >
                                    Confirm Password
                                </InputLabel>
                                {/* repeatPassword */}
                                <Controller
                                    name="confirmPassword"
                                    control={control}
                                    rules={{
                                        required: 'Required field',
                                        validate: (value) =>
                                            value ===
                                            getValues('password') ||
                                            'Passwords do not match'
                                    }}
                                    render={({ field }) => (
                                        <InputBase
                                            {...field}
                                            required
                                            error={
                                                !!errors.confirmPassword
                                            }
                                            inputProps={{
                                                autoComplete:
                                                    'new-password',
                                                form: {
                                                    autoComplete: 'off'
                                                }
                                            }}
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <PasswordFormIcon />
                                                </InputAdornment>
                                            }
                                            placeholder="********"
                                            endAdornment={showCPass ? <RemoveRedEyeIcon sx={{ color: '#808080', cursor: 'pointer' }} onClick={() => setShowCPass((prev) => !prev)} /> : <VisibilityOffIcon sx={{ color: '#808080', cursor: 'pointer' }} onClick={() => setShowCPass((prev) => !prev)} />}
                                            type={showCPass ? 'text' : "password"}
                                            sx={{
                                                backgroundColor:
                                                    '#FFFFFF',
                                                width: '100%',
                                                height: '70px',
                                                padding: '0px 16px',
                                                mb: '2px',
                                                fontFamily: 'open sans',
                                                fontSize: { sm: '16px', xs: '14px' },
                                                color: '#23282B',
                                                borderBottom:
                                                    '1px solid',
                                                borderColor:
                                                    errors.confirmPassword
                                                        ? 'red'
                                                        : '#D9D9D9',
                                                '& .MuiInputBase-input':
                                                {
                                                    ml: '7.5px',
                                                    position:
                                                        'relative',
                                                    top: '8px'
                                                }
                                            }}
                                        />
                                    )}
                                />
                                <Typography
                                    sx={{
                                        color: 'red',
                                        fontSize: '12px',
                                        fontFamily: 'Open Sans'
                                    }}
                                >
                                    {errors.confirmPassword?.message}
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                mt: '32px'
                            }}
                        >
                            {loading ? (
                                <CircularProgress
                                    sx={{ color: '#05668D' }}
                                />
                            ) : (
                                <Button
                                    sx={{
                                        backgroundColor: '#05668D',
                                        color: '#FFFFFF',
                                        borderRadius: '8px',
                                        width: '100%',
                                        padding: '16px, 36px, 16px, 36px',
                                        height: { sm: '52px', xs: '42px' }
                                    }}
                                    variant="contained"
                                    disabled={
                                        Object.keys(errors).length > 0 ||
                                        Object.keys(getValues()).length ===
                                        0
                                    }
                                    onClick={handleSubmit(onSubmit)}
                                >
                                    <Typography
                                        fontSize={{ sm: '16px', xs: '14px' }}
                                        fontWeight={700}
                                        fontStyle={'bold'}
                                        textTransform="capitalize"
                                    >
                                        Save
                                    </Typography>
                                </Button>
                            )}
                        </Box>
                    </Box>
                </form>
            </Box>
            <SnackAlert
                open={showAlert}
                handleClose={() => setShowAlert(false)}
                message={message}
                type={type}
            />
        </>
    );
}

export default NewPassword;
