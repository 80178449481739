import { useEffect, useRef } from 'react';
import { IMessage } from '../../utils/types';
import { Box, Stack } from '@mui/material';
import MessageBubble from './MessageBubble';
import { useMessages } from '../../utils/MessagesContext';

export default function MessageList({ messages }: { messages: IMessage[] }) {
    const { userId } = useMessages();
    const messageListRef = useRef<HTMLDivElement>(null);
    const scrollToBottom = () => {
        messageListRef.current?.scrollTo({
            top: messageListRef.current.offsetTop
        });
    };

    useEffect(() => {
        setTimeout(scrollToBottom, 100);
    }, [messages]);

    return (
        <Stack
            id="message-list"
            ref={messageListRef}
            direction="column-reverse"
            spacing={2}
            sx={{
                overflowY: 'scroll',
                px: '10px',
                pb: '4px',
                '&::-webkit-scrollbar': {
                    width: '10px'
                },

                '&::-webkit-scrollbar-track-piece': {
                    backgroundColor: 'inherit'
                },

                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#CBCBCB',
                    outline: '2px solid #FFF',
                    outlineOffset: '-2px',
                    border: '.1px solid #B7B7B7',
                    borderRadius: '10px'
                },

                '&::-webkit-scrollbar-thumb:hover': {
                    backgroundColor: '#909090'
                }
            }}
        >
            {messages.length ? (
                messages.map((message) => (
                    <MessageBubble
                        key={message.id}
                        variant={
                            message.senderId === userId ? 'sent' : 'received'
                        }
                        content={message.content}
                        timestamp={message.timestamp}
                        isRead={message.isRead}
                    />
                ))
            ) : (
                <Box sx={{ color: '#AAA', textAlign: 'center' }}>
                    Send a message
                </Box>
            )}
        </Stack>
    );
}
