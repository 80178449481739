import { Box, Grid, useMediaQuery } from '@mui/material';
import { Routes, Route } from 'react-router-dom';
import OnBoardingSidePanel from '../../OnBoarding/OnBoardingSidePanel';
import {
    StateMachineProvider,
    createStore,
    useStateMachine
} from 'little-state-machine';
import AccountType from './CreationSteps/AccountType';
import UserCreate from './CreationSteps/UserCreate';
import EmailConfirmation from './CreationSteps/EmailConfirmation';
import { CreateAccountType } from '../../../utils/hooks/api/account/types';
import CompanyCreate from '../Company/CompanyCreate';

createStore({
    data: {
        accountType: CreateAccountType.NotSelected,
        firstName: '',
        lastName: '',
        emailAddress: '',
        companyName: '',
        step: 1,
        password: '',
        verifyEmail: false,
        resetPassword: ''
    }
});

function CreateAccount() {
    const isTablet = useMediaQuery('(max-width:768px)');
    return (
        <>
            <Box
                sx={{
                    mx: { md: '40px', xs: 0 },
                }}
            >
                <Grid
                    sx={{
                        mt: { md: '40px', xs: 0 },
                        minHeight: { md: 'calc(100vh - 80px)', sm: '100vh', xs: '100%' },
                        backgroundColor: { md: '#FCFBF8', sm: '#FFF', xs: '#FCFBF8' },
                        width: '100%',
                    }}
                    container
                    columnSpacing={{ md: 5, xs: 0 }}
                >
                    <StateMachineProvider>
                        {/* left column */}
                        <Grid item md={6} xs={12}>
                            <OnBoardingSidePanel steps={['Account type','Create your account','Verify email']}/>
                        </Grid>


                        {/* right column */}
                        <Grid item md={6} xs={12}>
                            {/* <Box
                        sx={{
                            justifyContent: 'center',
                            mt: { md: '82px', xs: 0 },
                            ml: '0px',
                            mr: { md: '40px', xs: 0 },
                            mb: { md: '323px', xs: 0 },

                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}
                        > */}
                            <Routes>
                                <Route path="/" element={<AccountType />} />
                                <Route
                                    path="/professional"
                                    element={<UserCreate />}
                                />
                                <Route
                                    path="/verify-email/:email"
                                    element={<EmailConfirmation />}
                                />
                                <Route
                                    path="/company"
                                    element={<CompanyCreate />}
                                />
                            </Routes>
                            {/* </Box>
                    </Box> */}
                        </Grid>
                    </StateMachineProvider>
                </Grid>
            </Box>
        </>
    );
}

export default CreateAccount;
