import React from 'react';

export const Phone = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.05 14.95L9.2 16.8C8.81 17.19 8.19 17.19 7.79 16.81C7.68 16.7 7.57 16.6 7.46 16.49C6.43 15.45 5.5 14.36 4.67 13.22C3.85 12.08 3.19 10.94 2.71 9.81C2.24 8.67 2 7.58 2 6.54C2 5.86 2.12 5.21 2.36 4.61C2.6 4 2.98 3.44 3.51 2.94C4.15 2.31 4.85 2 5.59 2C5.87 2 6.15 2.06 6.4 2.18C6.66 2.3 6.89 2.48 7.07 2.74L9.39 6.01C9.57 6.26 9.7 6.49 9.79 6.71C9.88 6.92 9.93 7.13 9.93 7.32C9.93 7.56 9.86 7.8 9.72 8.03C9.59 8.26 9.4 8.5 9.16 8.74L8.4 9.53C8.29 9.64 8.24 9.77 8.24 9.93C8.24 10.01 8.25 10.08 8.27 10.16C8.3 10.24 8.33 10.3 8.35 10.36C8.53 10.69 8.84 11.12 9.28 11.64C9.73 12.16 10.21 12.69 10.73 13.22C10.83 13.32 10.94 13.42 11.04 13.52C11.44 13.91 11.45 14.55 11.05 14.95Z"
                fill="#05668D"
            />
            <path
                d="M21.9716 18.3291C21.9716 18.6091 21.9216 18.8991 21.8216 19.1791C21.7916 19.2591 21.7616 19.3391 21.7216 19.4191C21.5516 19.7791 21.3316 20.1191 21.0416 20.4391C20.5516 20.9791 20.0116 21.3691 19.4016 21.6191C19.3916 21.6191 19.3816 21.6291 19.3716 21.6291C18.7816 21.8691 18.1416 21.9991 17.4516 21.9991C16.4316 21.9991 15.3416 21.7591 14.1916 21.2691C13.0416 20.7791 11.8916 20.1191 10.7516 19.2891C10.3616 18.9991 9.97156 18.7091 9.60156 18.3991L12.8716 15.1291C13.1516 15.3391 13.4016 15.4991 13.6116 15.6091C13.6616 15.6291 13.7216 15.6591 13.7916 15.6891C13.8716 15.7191 13.9516 15.7291 14.0416 15.7291C14.2116 15.7291 14.3416 15.6691 14.4516 15.5591L15.2116 14.8091C15.4616 14.5591 15.7016 14.3691 15.9316 14.2491C16.1616 14.1091 16.3916 14.0391 16.6416 14.0391C16.8316 14.0391 17.0316 14.0791 17.2516 14.1691C17.4716 14.2591 17.7016 14.3891 17.9516 14.5591L21.2616 16.9091C21.5216 17.0891 21.7016 17.2991 21.8116 17.5491C21.9116 17.7991 21.9716 18.0491 21.9716 18.3291Z"
                fill="#05668D"
            />
        </svg>
    );
};

export default Phone;
