import { Chip, Typography } from '@mui/material';
import {useNavigate} from 'react-router-dom'
import PlaceIcon from '@mui/icons-material/Place';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import { Box } from '@mui/system';
import { Responsiveness } from '../../../theme/overrides';
import JobfactorBlue from '../../../assets/icons/JobfactorBlue';
import Sms from '../../../assets/icons/Sms';
import Phone from '../../../assets/icons/Phone';
import LocationBlue from '../../../assets/icons/LocationBlue';

interface Props {
    type: 'location' | 'phone' | 'email' | 'profile';
    label: string;
}

const InfoChip = ({ type, label }: Props) => {
    const {isTablet, isMobile} = Responsiveness()
    const iconStyle = { color: '#055C7F', marginLeft: '8px'};
    const navigate = useNavigate()

    let icon;

    if (type === 'location') {
        icon = (
            <Box ml="8px">
                <LocationBlue />
            </Box>
        );
    } else if (type === 'phone') {
        icon = (
            <Box ml="8px">
                <Phone />
            </Box>
        );
    } else if (type === 'email') {
        
        icon = <Box ml='8px'><Sms /> </Box>;
       
    } else if (type === 'profile') {
        icon = (
            <Box ml='8px'>
                <JobfactorBlue />
            </Box>
        );
    }

    const labelComponent =
        type === 'email' ? (
            <Typography
                component="a"
                href={`mailto:${label}`}
                sx={{
                    textDecoration: 'none',
                    fontSize: isMobile ? '12px' : '14px',
                    color: '#055C7F'
                }}
            >
                {label}
            </Typography>
        ) : type === 'profile' ? (
            <Typography
                onClick={() => navigate(label)}
                sx={{
                    textDecoration: 'none',
                    fontSize: isMobile ? '12px' : '14px',
                    color: '#055C7F',
                    cursor: 'pointer'
                }}
            >
                {label.slice(0, 25)}...
            </Typography>
        ) : (
            label
        );

    return (
        <Chip
            icon={icon}
            label={<Box pr={1}>{labelComponent}</Box>}
            sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#f2f2f2',
                fontFamily: 'open sans',
                fontWeight: 400,
                fontSize: isMobile ? '12px' : '14px',
                borderRadius: '4px',
                letterSpacing: '0.049px',
                lineHeight: '20px',
                color: '#808080'
            }}
        />
    );
};

export default InfoChip;
