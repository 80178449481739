import CircleIcon from '@mui/icons-material/Circle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import EastIcon from '@mui/icons-material/East';
import {
    Box,
    Typography,
    IconButton,
    Grid,
    Container,
    Divider,
    Button,
    Rating,
    Avatar,
    CircularProgress
} from '@mui/material';
import profile from '../../../assets/images/companyProfile.png';
import cambridge from '../../../assets/images/cambridge.jpg';
import InfoChip from '../../Users/components/InfoChip';
import Section from '../../Users/components/Section';
import SectionHeading from '../../Users/components/SectionHeading';
import Image from '../../../components/Image';
import MedalIcon from '../../../assets/icons/MedalIcon';
import AnalyticsProfile from '../../../assets/icons/AnalyticsProfile';
import AnalyticsSearch from '../../../assets/icons/AnalyticsSearch';
import AnalyticsGraph from '../../../assets/icons/AnalyticsGraph';
import ContactInfoModal from '../components/ContactInfoModal';
import ScoreCard from '../../../components/ScoreCard.tsx';
import { useEffect, useRef, useState } from 'react';
import EditAboutModal from '../components/EditAboutModal';
import EditIcon from '../../../assets/icons/EditIcon';
import EditProfileModal from '../components/EditProfileModal';
import { ArrowLeftIcon } from '../../../assets/icons/ArrowLeftIcon';
import { useNavigate } from 'react-router-dom';
import DotIcon from '../../../assets/icons/DotIcon';
import VerifiedIcon from '../../../assets/icons/VerifiedIcon';
import ArrowRight from '../../../assets/icons/ArrowRight';
import { useAuth } from '../../../utils/context/AuthContext';
import { useGetCompanyProfileScore } from '../../../utils/hooks/api/company-profile/useGetCompanyProfileScore';
import { useGetCompanyProfileById } from '../../../utils/hooks/api/company-profile/useGetCompanyProfile';
import SnackAlert from '../../../components/Snackbar';
import { useGetConnections } from '../../../utils/hooks/api/connections/useGetConnections';
import cover from '../../../assets/images/companyCover.png';
import { Responsiveness } from '../../../theme/overrides';
import ProfileBanner from '../../ProfileBanner';
import ProfilePhotoIcon from '../../../assets/icons/ProfilePhotoIcon';
import EditBtn from '../../../assets/icons/EditBtn';
import ArrowIconRight from '../../../assets/icons/ArrowIconRight';
import Alert from '../../../assets/icons/Alert';
import SkeletonLoader from '../../Home/components/SkeletonLoader';
import { FeedItem } from '../../Home/components/FeedItem';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { usePost } from '../../../utils/context/PostContext';
import ExpandIcon from '../../../assets/icons/ExpandIcon';
import PageHeader from '../../../components/PageHeader/PageHeader';
import MobileNav from '../../../components/MobileNav/MobileNav';
import InfiniteScroll from 'react-infinite-scroll-component';
import TickCircle from '../../../assets/icons/TickCircle';
import MoreIcon from '../../../assets/icons/MoreIcon';
import SeeMoreIcon from '../../Users/components/SeeMoreIcon';

const data = [
    { review: 'Salary, Compensation,Benefits', rating: 4 },
    { review: 'Work/Life balance, flexible work schedule', rating: 4 },
    {
        review: 'Carrer growth opportunities, reward and recoginition',
        rating: 4
    }
];
function AboutCompany() {
    const { user } = useAuth();
    const [openContactInfoModal, setOpenContactInfoModal] = useState(false);
    const [openAboutMeModal, setOpenAboutMeModal] = useState(false);
    const [openProfileInfoModal, setOpenProfileInfoModal] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const navigate = useNavigate();
    const { data: score } = useGetCompanyProfileScore(
        user?.primaryCompanyProfile?.id!
    );
    const { isTablet, isMobile, isXMobile } = Responsiveness();
    const { data: connections } = useGetConnections(user?.id!);
    const { data: company } = useGetCompanyProfileById(
        user?.primaryCompanyProfile?.companyId ?? ''
    );
    const [showAllBio, setShowAllBio] = useState(false);
    const maxBioLength = 2600;
    const { posts, totalPosts, isLoaded, setGetAfter } = usePost();
    const [activity, setActivity] = useState('Posts');
    const currentActivity = ['Posts', 'Achievements', 'Articles', 'Jobs'];
    const handleOnEditAboutUs = () => {
        setOpenAboutMeModal(true);
    };

    const handleOnEditContactInfo = () => {
        setOpenContactInfoModal(true);
    };

    const handleOnEditProfileInfo = () => {
        setOpenProfileInfoModal(true);
    };

    const routes = [
        {
            label: 'About Us',
            route: '/about-company'
        },
        {
            label: 'Activities',
            route: '/company-activities'
        },
        {
            label: 'Jobfactor Score',
            route: '/jobfactor-score',
            disable: true
        },
        {
            label: 'Employees',
            route: '/employees',
            disable: true
        },
        {
            label: 'Company Ratings',
            route: '/company-ratings',
            disable: true
        }
    ];

          const [isTruncated, setIsTruncated] = useState(false);
    const containerRef = useRef(null);
        const [showMore, setShowMore] = useState(false);

          useEffect(() => {
              const container: any = containerRef.current;
              if (container !== null) {
                  if (container.scrollHeight > container.clientHeight) {
                      setIsTruncated(true);
                  } else {
                      setIsTruncated(false);
                  }
              }
          }, [company?.bio]);
    
    if (!company) {
        return (
            <Box
                width="100%"
                height="100vh"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Container
            disableGutters
            style={
                {
                    // paddingRight: '0px',
                    // maxWidth: '100%',
                }
            }
            sx={{ pr: isMobile ? 0 : isTablet ? '20px' : '40px' }}
        >
            {isMobile ? null : isTablet ? (
                <PageHeader header="Profile" />
            ) : null}
            {isTablet ? <MobileNav list={routes} /> : null}
            <Grid
                mt="8px"
                container
                direction="column"
                gap={{ xs: '8px', sm: '8px' }}
            >
                <Grid item position="relative">
                    <Box
                        bgcolor="#fff"
                        borderRadius={1}
                        overflow="hidden"
                        pb={isMobile ? 0 : '20px'}
                    >
                        <Box position="relative" pb="109px">
                            <Box
                                position="relative"
                                overflow="hidden"
                                display={'flex'}
                                borderRadius={[0, 0, 1, 1]}
                            >
                                <img
                                    style={{
                                        height: isMobile
                                            ? '100px'
                                            : isTablet
                                            ? '220px'
                                            : '259px',
                                        width: '100%',
                                        objectFit: 'cover',
                                        display: 'block'
                                    }}
                                    alt="Cover"
                                    src={cover}
                                />
                                {isTablet ? null : (
                                    <ProfileBanner
                                        companyName={
                                            company?.primaryCompanyProfileName ?? ''
                                        }
                                        score={score}
                                    />
                                )}
                            </Box>
                            <Grid
                                container
                                gap={isMobile ? undefined : 3}
                                position={'absolute'}
                                direction={isMobile ? 'column' : undefined}
                                bottom={isMobile ? 8 : isTablet ? 30 : 0}
                            >
                                <Box
                                    ml={isTablet ? '20px' : 5}
                                    borderRadius="50%"
                                    overflow="hidden"
                                    width="fit-content"
                                    boxShadow="-8px 4px 20px rgba(0, 0, 0, 0.07), 8px 8px 20px rgba(0, 0, 0, 0.07)"
                                    position="relative"
                                    mb={isTablet && !company?.logo ? 2 : 0}
                                >
                                    {!company?.logo ? (
                                        <ProfilePhotoIcon />
                                    ) : (
                                        <Image
                                            src={company?.logo ?? ''}
                                            sx={{
                                                width: isTablet
                                                    ? '150px'
                                                    : '200px',
                                                height: isTablet
                                                    ? '150px'
                                                    : '200px',
                                                objectFit: 'cover'
                                            }}
                                            alt={
                                                company?.primaryCompanyProfileName
                                            }
                                            border="3px #fff solid"
                                            borderRadius="50%"
                                            display="block"
                                        />
                                    )}
                                </Box>
                                {isMobile ? (
                                    <Box mt="4px" ml="40px">
                                        <Typography
                                            component="span"
                                            color="#05668D"
                                            fontFamily="open sans"
                                            fontSize={16}
                                            fontWeight={700}
                                        >
                                            {connections?.length ?? 0}
                                        </Typography>
                                        &nbsp;
                                        <Typography
                                            component={'span'}
                                            color="#05668D"
                                            fontFamily="open sans"
                                            fontSize={12}
                                        >
                                            {'Connection' +
                                                (connections?.length === 1
                                                    ? ''
                                                    : 's')}
                                        </Typography>
                                    </Box>
                                ) : null}
                            </Grid>
                        </Box>
                        {isMobile ? (
                            <Box
                                display="flex"
                                alignItems="flex-end"
                                flexDirection="column"
                                mt={-11.5}
                                mr="20px"
                                gap="16px"
                            >
                                <ScoreCard
                                    score={score}
                                    width="80px"
                                    height="80px"
                                />
                            </Box>
                        ) : null}

                        {isMobile ? (
                            <Box
                                width="100%"
                                height="4px"
                                bgcolor="#f2f2f2"
                                my="8px"
                            ></Box>
                        ) : null}
                        <Grid
                            container
                            px={isMobile ? '16px' : isTablet ? '20px' : 5}
                            marginTop={{ xs: 0, sm: -1.5, lg: -5.5 }}
                            pb={{ xs: '16px', sm: 0 }}
                        >
                            <Grid item flexGrow={1} marginTop="auto">
                                <Grid container direction="column" gap={0.5}>
                                    <Grid
                                        item
                                        direction={
                                            isMobile ? 'column' : undefined
                                        }
                                    >
                                        <Grid
                                            container
                                            gap={'12px'}
                                            display="flex"
                                            alignItems="center"
                                        >
                                            <Grid
                                                item
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '4px'
                                                }}
                                            >
                                                <Typography
                                                    component="h2"
                                                    color="#23282B"
                                                    fontFamily="open sans"
                                                    // lineHeight="1.125"
                                                    fontSize={
                                                        isMobile
                                                            ? 22
                                                            : isTablet
                                                            ? 24
                                                            : 28
                                                    }
                                                    fontWeight={600}
                                                >
                                                    {
                                                        company?.primaryCompanyProfileName
                                                    }
                                                </Typography>
                                                {/* <Box> */}
                                                <TickCircle />
                                                {/* <VerifiedIcon /> */}
                                                {/* </Box> */}
                                            </Grid>
                                            <CircleIcon
                                                sx={{
                                                    display: {
                                                        xs: 'none',
                                                        sm: 'block'
                                                    },
                                                    fontSize: '6px'
                                                }}
                                                htmlColor="#C5C5C5"
                                            />
                                            {company?.yearFounded ? (
                                                <>
                                                    <Typography
                                                        component="span"
                                                        color="#808080"
                                                        fontFamily="open sans"
                                                        fontSize={16}
                                                        fontWeight={400}
                                                    >
                                                        Founded in{' '}
                                                        {company?.yearFounded}
                                                    </Typography>
                                                    <CircleIcon
                                                        sx={{
                                                            display: {
                                                                xs: 'none',
                                                                sm: 'block'
                                                            },
                                                            fontSize: '6px'
                                                        }}
                                                        htmlColor="#C5C5C5"
                                                    />
                                                </>
                                            ) : null}
                                            <Grid
                                                item
                                                sx={{ display: 'flex' }}
                                                alignItems="center"
                                            >
                                                <Grid
                                                    sx={{
                                                        display: {
                                                            xs: 'none',
                                                            sm: 'flex'
                                                        }
                                                    }}
                                                    container
                                                    wrap="nowrap"
                                                    width="auto"
                                                    // marginTop="auto"
                                                    flexGrow={1}
                                                    alignItems="center"
                                                    gap="12px"
                                                    // marginLeft={
                                                    //     isTablet ? -2 : -1
                                                    // }
                                                >
                                                    <Grid
                                                        item
                                                        flexGrow={1}
                                                        // mt={
                                                        //     isTablet
                                                        //         ? '30px'
                                                        //         : undefined
                                                        // }
                                                    >
                                                        <Typography
                                                            component="span"
                                                            color="#05668D"
                                                            fontFamily="open sans"
                                                            fontSize={20}
                                                            fontWeight={700}
                                                        >
                                                            {connections?.length ??
                                                                0}
                                                        </Typography>
                                                        &nbsp;
                                                        <Typography
                                                            component={'span'}
                                                            color="#05668D"
                                                            fontFamily="open sans"
                                                            fontSize={20}
                                                        >
                                                            {'Connection' +
                                                                (connections?.length ===
                                                                1
                                                                    ? ''
                                                                    : 's')}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid
                                                        item
                                                        // paddingRight="32px"
                                                    >
                                                        <Box
                                                            display="flex"
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            sx={{
                                                                bgcolor:
                                                                    '#f2f2f2',
                                                                borderRadius:
                                                                    '8px',
                                                                p: '4px'
                                                            }}
                                                        >
                                                            <MoreIcon />
                                                            {/* <MoreVertIcon fontSize="medium" htmlColor='#808080' sx={{ bgcolor:"#f2f2f2",
                                                            borderRadius:"8px", p:'4px'}}  /> */}
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {company?.tagLine ? (
                                        <Grid
                                            item
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '12px'
                                            }}
                                        >
                                            <Typography
                                                component={'p'}
                                                color="#23282B"
                                                fontFamily="open sans"
                                                fontSize={isMobile ? 14 : 16}
                                                fontWeight={400}
                                            >
                                                {company?.tagLine}
                                            </Typography>
                                        </Grid>
                                    ) : null}

                                    <Grid item>
                                        <Grid
                                            container
                                            gap={'4px'}
                                            alignItems="center"
                                        >
                                            {company?.address ? (
                                                <>
                                                    <Grid item>
                                                        <InfoChip
                                                            type="location"
                                                            label={`${
                                                                company?.address
                                                                    ?.city ?? ''
                                                            }, ${
                                                                company?.address
                                                                    ?.country ??
                                                                ''
                                                            }`}
                                                        />
                                                    </Grid>
                                                    {/* {isMobile ? null : (
                                                        <CircleIcon
                                                            sx={{
                                                                fontSize:
                                                                    '7.25px'
                                                            }}
                                                            htmlColor="#494949"
                                                        />
                                                    )} */}
                                                </>
                                            ) : null}
                                            {company?.phoneNumber ? (
                                                <>
                                                    <Grid item>
                                                        <InfoChip
                                                            type="phone"
                                                            label={
                                                                company?.phoneNumber ??
                                                                ''
                                                            }
                                                        />
                                                    </Grid>
                                                    {/* {isMobile ? null : (
                                                        <CircleIcon
                                                            sx={{
                                                                fontSize:
                                                                    '7.25px'
                                                            }}
                                                            htmlColor="#494949"
                                                        />
                                                    )} */}
                                                </>
                                            ) : null}
                                            {company?.emailAddress ? (
                                                <>
                                                    <Grid item>
                                                        <InfoChip
                                                            type="email"
                                                            label={
                                                                company?.emailAddress ??
                                                                ''
                                                            }
                                                        />
                                                    </Grid>
                                                </>
                                            ) : null}
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    style={{
                                                        width: 'auto',
                                                        minWidth: 'auto',
                                                        color: '#808080',
                                                        backgroundColor:
                                                            '#F2F2F2',
                                                        zIndex: 5
                                                    }}
                                                    sx={{
                                                        p: '6px'
                                                    }}
                                                    onClick={
                                                        handleOnEditProfileInfo
                                                    }
                                                >
                                                    <EditIcon fontSize="small" />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {isMobile ? null : (
                                <Grid
                                    item
                                    flexShrink={1}
                                    position={{ sm: 'absolute', lg: 'static' }}
                                    right={'21px'}
                                    bottom={'120px'}
                                >
                                    <Box py={1}>
                                        <ScoreCard score={score} />
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </Grid>
                {/* <Grid item>
                    <Box
                        bgcolor="#fff"
                        borderRadius={1}
                        overflow="hidden"
                        pb={isMobile ? 0 : 5}
                    >
                        <Box position="relative" pb="109px">
                            <Box
                                position="relative"
                                overflow="hidden"
                                display={'flex'}
                                borderRadius={[0, 0, 1, 1]}
                            >
                                <img
                                    style={{
                                        height: isMobile
                                            ? '100px'
                                            : isTablet
                                            ? '220px'
                                            : '259px',
                                        width: '100%',
                                        objectFit: 'cover',
                                        display: 'block'
                                    }}
                                    alt="Cover"
                                    src={cover}
                                />
                                {isTablet ? null : (
                                    <ProfileBanner
                                        companyName={
                                            company?.primaryCompanyProfileName
                                        }
                                        score={score}
                                    />
                                )}
                            </Box>
                            <Grid
                                container
                                gap={isMobile ? undefined : 3}
                                position={'absolute'}
                                direction={isMobile ? 'column' : undefined}
                                bottom={isMobile ? -10 : isTablet ? 30 : 0}
                            >
                                <Box
                                    ml={isTablet ? '20px' : 5}
                                    borderRadius="50%"
                                    overflow="hidden"
                                    width="fit-content"
                                    boxShadow="-8px 4px 20px rgba(0, 0, 0, 0.07), 8px 8px 20px rgba(0, 0, 0, 0.07)"
                                    position="relative"
                                    mb={isTablet && !company?.logo ? 2 : 0}
                                >
                                    {!company?.logo ? (
                                        <ProfilePhotoIcon />
                                    ) : (
                                        <Image
                                            src={company?.logo ?? ''}
                                            sx={{
                                                width: isTablet
                                                    ? '150px'
                                                    : '200px',
                                                height: isTablet
                                                    ? '150px'
                                                    : '200px',
                                                objectFit: 'cover'
                                            }}
                                            alt={
                                                company?.primaryCompanyProfileName
                                            }
                                            border="3px #fff solid"
                                            borderRadius="50%"
                                            display="block"
                                        />
                                    )}
                                </Box>
                                {isMobile ? (
                                    <Box mt="4px" ml="40px">
                                        <Typography
                                            component="span"
                                            color="#23282B"
                                            fontFamily="open sans"
                                            fontSize={16}
                                            fontWeight={700}
                                        >
                                            {connections?.length ?? 0}
                                        </Typography>
                                        &nbsp;
                                        <Typography
                                            component={'span'}
                                            color="#808080"
                                            fontFamily="open sans"
                                            fontSize={12}
                                        >
                                            {'Connection' +
                                                (connections?.length === 1
                                                    ? ''
                                                    : 's')}
                                        </Typography>
                                    </Box>
                                ) : null}
                                {isMobile ? null : (
                                    <Grid
                                        container
                                        wrap="nowrap"
                                        width="auto"
                                        marginTop="auto"
                                        height="109px"
                                        flexGrow={1}
                                        alignItems="center"
                                        pb={2}
                                        marginLeft={isTablet ? -2 : -1}
                                    >
                                        <Grid
                                            item
                                            flexGrow={1}
                                            mt={isTablet ? '30px' : undefined}
                                        >
                                            <Typography
                                                component="span"
                                                color="#23282B"
                                                fontFamily="open sans"
                                                fontSize={20}
                                                fontWeight={700}
                                            >
                                                {connections?.length ?? 0}
                                            </Typography>
                                            &nbsp;
                                            <Typography
                                                component={'span'}
                                                color="#808080"
                                                fontFamily="open sans"
                                                fontSize={16}
                                            >
                                                {'Connection' +
                                                    (connections?.length === 1
                                                        ? ''
                                                        : 's')}
                                            </Typography>
                                        </Grid>

                                        {isTablet ? null : (
                                            <Grid
                                                item
                                                flexShrink="1"
                                                paddingRight="32px"
                                            >
                                                <IconButton>
                                                    <MoreVertIcon fontSize="medium" />
                                                </IconButton>
                                            </Grid>
                                        )}
                                    </Grid>
                                )}
                            </Grid>
                        </Box>
                        {isMobile ? (
                            <Box
                                display="flex"
                                alignItems="flex-end"
                                flexDirection="column"
                                mt={-13}
                                mr="20px"
                                gap="16px"
                            >
                                <ScoreCard
                                    score={score}
                                    width="50px"
                                    height="50px"
                                />
                                {isXMobile ? (
                                    <Box onClick={handleOnEditProfileInfo}>
                                        <EditBtn />
                                    </Box>
                                ) : (
                                    <Button
                                        sx={{
                                            height: '40px',
                                            width: '150px',
                                            py: '8px',
                                            px: '8px',
                                            maxHeight: '40px',
                                            color: '#05668D',
                                            backgroundColor: '#F2F2F2',
                                            borderRadius: '8px',
                                            textTransform: 'none',
                                            textDecoration: 'none',
                                            gap: 1
                                        }}
                                        onClick={handleOnEditProfileInfo}
                                    >
                                        <EditBtn />
                                        Edit profile
                                    </Button>
                                )}
                            </Box>
                        ) : null}
                        {isMobile ? (
                            <Box
                                width="100%"
                                height="2px"
                                bgcolor="#f2f2f2"
                                my="16px"
                            ></Box>
                        ) : null}
                        <Grid
                            container
                            px={isMobile ? '16px' : isTablet ? '20px' : 5}
                            marginTop={isMobile ? 0 : -3.5}
                        >
                            <Grid item flexGrow={1} marginTop="auto">
                                <Grid
                                    container
                                    direction="column"
                                    gap={isTablet ? '12px' : 2}
                                >
                                    <Grid
                                        item
                                        direction={
                                            isMobile ? 'column' : undefined
                                        }
                                    >
                                        <Grid
                                            container
                                            gap={isTablet ? 1 : 2}
                                            alignItems="center"
                                        >
                                            <Grid item>
                                                <Typography
                                                    component="h2"
                                                    color="#23282B"
                                                    fontFamily="open sans"
                                                    lineHeight="1.125"
                                                    fontSize={
                                                        isMobile
                                                            ? 22
                                                            : isTablet
                                                            ? 24
                                                            : 28
                                                    }
                                                    fontWeight={600}
                                                >
                                                    {
                                                        company?.primaryCompanyProfileName
                                                    }
                                                </Typography>
                                            </Grid>

                                            <Grid item alignItems="center">
                                                <Grid
                                                    container
                                                    gap={isTablet ? 1 : 2}
                                                    alignItems="center"
                                                >
                                                    {isMobile ? (
                                                        <CheckCircleIcon
                                                            htmlColor="#49B6FF"
                                                            style={{
                                                                marginLeft:
                                                                    '8px'
                                                            }}
                                                        />
                                                    ) : (
                                                        <>
                                                            <CircleIcon
                                                                sx={{
                                                                    fontSize:
                                                                        '7.25px'
                                                                }}
                                                                htmlColor="#494949"
                                                            />
                                                            {company?.yearFounded ? (
                                                                <>
                                                                    <Typography
                                                                        component="span"
                                                                        color="#808080"
                                                                        fontFamily="open sans"
                                                                        fontSize={
                                                                            16
                                                                        }
                                                                        fontWeight={
                                                                            400
                                                                        }
                                                                    >
                                                                        Founded
                                                                        in{' '}
                                                                        {
                                                                            company?.yearFounded
                                                                        }
                                                                    </Typography>
                                                                    <CheckCircleIcon
                                                                        htmlColor="#49B6FF"
                                                                        style={{
                                                                            marginLeft:
                                                                                isTablet
                                                                                    ? '8px'
                                                                                    : '-8px'
                                                                        }}
                                                                    />
                                                                </>
                                                            ) : null}
                                                            <Grid item ml={1}>
                                                                <Button
                                                                    variant="contained"
                                                                    style={{
                                                                        width: 'auto',
                                                                        minWidth:
                                                                            'auto',
                                                                        color: '#808080',
                                                                        backgroundColor:
                                                                            '#F2F2F2'
                                                                    }}
                                                                    sx={{
                                                                        px: 1,
                                                                        py: 1.25
                                                                    }}
                                                                    onClick={
                                                                        handleOnEditProfileInfo
                                                                    }
                                                                >
                                                                    <EditIcon fontSize="small" />
                                                                </Button>
                                                            </Grid>
                                                        </>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {isMobile && company?.yearFounded ? (
                                            <Typography
                                                mt="12px"
                                                component="span"
                                                color="#808080"
                                                fontFamily="open sans"
                                                fontSize={14}
                                                fontWeight={400}
                                            >
                                                Founded in{' '}
                                                {company?.yearFounded}
                                            </Typography>
                                        ) : null}
                                    </Grid>
                                    {company?.tagLine ? (
                                        <Grid item>
                                            <Typography
                                                component={'p'}
                                                color="#23282B"
                                                fontFamily="open sans"
                                                fontSize={isMobile ? 14 : 16}
                                                fontWeight={400}
                                            >
                                                {company?.tagLine}
                                            </Typography>
                                        </Grid>
                                    ) : null}
                                    <Grid item>
                                        <Grid
                                            container
                                            gap={1}
                                            alignItems="center"
                                        >
                                            {company?.address ? (
                                                <>
                                                    <Grid item>
                                                        <InfoChip
                                                            type="location"
                                                            label={`${company?.address?.city}, ${company?.address?.country}`}
                                                        />
                                                    </Grid>
                                                    {isMobile ? null : (
                                                        <CircleIcon
                                                            sx={{
                                                                fontSize:
                                                                    '7.25px'
                                                            }}
                                                            htmlColor="#494949"
                                                        />
                                                    )}
                                                </>
                                            ) : null}
                                            {company?.phoneNumber ? (
                                                <>
                                                    <Grid item>
                                                        <InfoChip
                                                            type="phone"
                                                            label={
                                                                company?.phoneNumber ??
                                                                ''
                                                            }
                                                        />
                                                    </Grid>
                                                    {isMobile ? null : (
                                                        <CircleIcon
                                                            sx={{
                                                                fontSize:
                                                                    '7.25px'
                                                            }}
                                                            htmlColor="#494949"
                                                        />
                                                    )}
                                                </>
                                            ) : null}
                                            {company?.emailAddress ? (
                                                <>
                                                    <Grid item>
                                                        <InfoChip
                                                            type="email"
                                                            label={
                                                                company?.emailAddress ??
                                                                ''
                                                            }
                                                        />
                                                    </Grid>
                                                </>
                                            ) : null}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {isTablet ? null : (
                                <Grid item flexShrink={1}>
                                    <Box py={1}>
                                        <ScoreCard score={score} />
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </Grid> */}
                {/* {isMobile ? (
                    <Box width="100%" height="2px" bgcolor="#f2f2f2"></Box>
                ) : null} */}
                <Grid item>
                    <Grid
                        container
                        wrap={isTablet ? undefined : 'nowrap'}
                        //
                        spacing={{ xs: 1, lg: 1.5 }}
                    >
                        <Grid item width="100%" xs={isTablet ? 12 : 8}>
                            <Section px={0}>
                                <Box
                                    px={{ xs: '16px', sm: '20px', lg: '40px' }}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'flex-start'
                                    }}
                                >
                                    <Box>
                                        <Typography
                                            component="h3"
                                            color="#23282B"
                                            fontFamily="open sans"
                                            fontSize={20}
                                            fontWeight={600}
                                            mb={1}
                                        >
                                            About us
                                        </Typography>
                                        <Typography
                                            component="h4"
                                            color="#808080"
                                            fontFamily="open sans"
                                            fontSize={16}
                                            fontWeight={400}
                                            mb={1.5}
                                        >
                                            Tell the world about your company.
                                        </Typography>
                                    </Box>
                                    <Button
                                        sx={{
                                            mt: 1,
                                            height: { xs: '25px', sm: '36px' },
                                            width: '87px',
                                            maxHeight: '40px',
                                            color: '#05668D',
                                            backgroundColor: '#F2F2F2',
                                            borderRadius: '8px',
                                            textTransform: 'none',
                                            textDecoration: 'none'
                                        }}
                                        onClick={handleOnEditAboutUs}
                                        startIcon={<EditBtn />}
                                    >
                                        Edit
                                    </Button>
                                </Box>
                                <Divider />
                                <Box
                                    pt={{ xs: '12px', sm: '20px' }}
                                    px={{ xs: '16px', sm: '20px', lg: '40px' }}
                                    pb="8px"
                                >
                                    <Typography
                                        ref={containerRef}
                                        component="p"
                                        color="#23282B"
                                        fontFamily="open sans"
                                        fontSize={isTablet ? 14 : 16}
                                        fontWeight={400}
                                        // mt={4}
                                        mb={'12px'}
                                        style={{
                                            display: '-webkit-box',
                                            WebkitLineClamp: showMore
                                                ? undefined
                                                : 4,
                                            WebkitBoxOrient: 'vertical',
                                            overflow: 'hidden'
                                        }}
                                    >
                                        {company?.bio}
                                    </Typography>
                                    {isTruncated ? (
                                        <Box sx={{ width: '100%' }}>
                                            <Button
                                                disableRipple
                                                variant="contained"
                                                sx={{
                                                    width: '118px',
                                                    backgroundColor: '#f2f2f2',
                                                    borderRadius: '8px',
                                                    padding: '0 8px',
                                                    '&:hover': {
                                                        bgcolor: '#f2f2f2'
                                                    }
                                                }}
                                                endIcon={<SeeMoreIcon />}
                                                onClick={() => {
                                                    setShowMore(!showMore);
                                                }}
                                            >
                                                <Typography
                                                    color={'#05668D'}
                                                    fontFamily="open sans"
                                                    fontWeight={700}
                                                    fontSize={14}
                                                    sx={{
                                                        textTransform: 'none',
                                                        py: 1
                                                    }}
                                                >
                                                    {showMore
                                                        ? 'See less'
                                                        : 'See more'}
                                                </Typography>
                                            </Button>
                                        </Box>
                                    ) : null}
                                </Box>
                            </Section>
                            <Box
                                mt={{ xs: '8px' }}
                                px={{ xs: '16px', sm: '20px', lg: '40px' }}
                                py="8px"
                                bgcolor="#fff"
                            >
                                <Box
                                    mb={'8px'}
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Typography
                                        // component="h2"
                                        color="#23282B"
                                        fontFamily="open sans"
                                        // lineHeight="1.125"
                                        fontSize="16px"
                                        fontWeight={600}
                                    >
                                        Activities
                                    </Typography>

                                    <Typography
                                        component="h2"
                                        color="#808080"
                                        fontFamily="open sans"
                                        lineHeight="24px"
                                        fontSize={16}
                                        fontWeight={400}
                                    >
                                        {totalPosts + ' posts'}
                                    </Typography>
                                </Box>
                                <Box
                                    display="flex"
                                    flexWrap="wrap"
                                    height="fit-content"
                                    gap={{ xs: '4px', sm: 0 }}
                                    bgcolor={{
                                        xs: 'transparent',
                                        sm: '#f2f2f2'
                                    }}
                                >
                                    {currentActivity.map((item) => (
                                        <Box
                                            height="36px"
                                            bgcolor="#f2f2f2"
                                            width={{
                                                xs: '49.3%',
                                                sm: `${100 / 4}%`
                                            }}
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',

                                                borderBottomColor:
                                                    activity === item
                                                        ? '#05668D'
                                                        : 'transparent',
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => setActivity(item)}
                                            borderBottom="2px solid"
                                        >
                                            <Typography
                                                fontFamily="open sans"
                                                fontSize={14}
                                                fontWeight={600}
                                                lineHeight="20px"
                                                color={
                                                    activity === item
                                                        ? '#05668D'
                                                        : '#808080'
                                                }
                                            >
                                                {item}
                                            </Typography>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                            <Box py={'4px'} bgcolor="#f2f2f2">
                                {activity === 'Posts' ? (
                                    !isLoaded ? (
                                        [1, 2].map((_) => <SkeletonLoader />)
                                    ) : posts?.length === 0 ? (
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            gap="12px"
                                            pl={isMobile ? '16px' : undefined}
                                        >
                                            <Alert />
                                            <Typography> No Posts</Typography>
                                        </Box>
                                    ) : (
                                        (posts ?? [])
                                            ?.slice(0, 3)
                                            ?.map((feed: any, index: any) => (
                                                <FeedItem
                                                    profile
                                                    key={index}
                                                    feed={feed}
                                                />
                                            ))
                                    )
                                ) : activity === 'Achievements' ? (
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        gap="12px"
                                        pl={isMobile ? '16px' : undefined}
                                    >
                                        <Alert />
                                        <Typography>
                                            {' '}
                                            No Achievements
                                        </Typography>
                                    </Box>
                                ) : activity === 'Articles' ? (
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        gap="12px"
                                        p={isMobile ? '16px' : undefined}
                                    >
                                        <Alert />
                                        <Typography>No Articles</Typography>
                                    </Box>
                                ) : (
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        gap="12px"
                                        p={isMobile ? '16px' : undefined}
                                    >
                                        <Alert />
                                        <Typography>No Jobs</Typography>
                                    </Box>
                                )}
                                <Box
                                    py="8px"
                                    px={{ xs: '16px', sm: '20px', lg: '40px' }}
                                    bgcolor="#fff"
                                    mb="4px"
                                >
                                    {posts?.length > 0 && (
                                        <Button
                                            variant="contained"
                                            sx={{
                                                backgroundColor: '#f2f2f2',
                                                borderRadius: '8px',
                                                width: '100%',
                                                '&:hover': {
                                                    bgcolor: '#f2f2f2'
                                                }
                                                // mt: '16px',
                                            }}
                                            onClick={() =>
                                                navigate('/activities')
                                            }
                                        >
                                            <Typography
                                                color={'#05668D'}
                                                fontFamily="open sans"
                                                fontWeight={600}
                                                fontSize={16}
                                                sx={{
                                                    textTransform: 'none',
                                                    py: 0.5,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '12px'
                                                }}
                                            >
                                                Show all posts{' '}
                                                <ArrowIconRight />
                                            </Typography>
                                        </Button>
                                    )}
                                </Box>
                            </Box>
                            <Box
                                bgcolor="#fff"
                                py="8px"
                                px={{ xs: '16px', sm: '20px', lg: '40px' }}
                                mb="4px"
                            >
                                <Typography
                                    fontSize="16px"
                                    fontWeight={600}
                                    color="#23282b"
                                >
                                    Help other Job Seekers
                                </Typography>
                            </Box>
                            <Box
                                bgcolor="#fff"
                                py="8px"
                                px={{ xs: '16px', sm: '20px', lg: '40px' }}
                                // mb="4px"
                                // heading={
                                //     <SectionHeading>
                                //         Help other Job Seekers
                                //     </SectionHeading>
                                // }
                            >
                                {isMobile ? (
                                    <Grid
                                        container
                                        gap={1}
                                        alignItems="center"
                                        // wrap="nowrap"
                                        direction="column"
                                    >
                                        <Box
                                            py={1}
                                            pr={1}
                                            width="100%"
                                            display="flex"
                                            alignItems="center"
                                            gap="8px"
                                        >
                                            <MedalIcon
                                                width="32px"
                                                height="32px"
                                            />
                                            <Typography
                                                component="h4"
                                                color="#23282B"
                                                fontFamily="open sans"
                                                fontSize={16}
                                                fontWeight={600}
                                                mb={1}
                                                flex={1}
                                            >
                                                Review companies
                                            </Typography>
                                            <ArrowRightIcon
                                                fontSize="large"
                                                htmlColor="#055C7F"
                                            />
                                        </Box>
                                        <Grid container flexGrow={1}>
                                            <Typography
                                                component="p"
                                                color="#808080"
                                                fontFamily="open sans"
                                                fontSize={14}
                                                fontWeight={400}
                                            >
                                                Review companies you have worked
                                                for, this contributes to your
                                                Jobfactor score
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <Grid
                                        container
                                        gap={2}
                                        alignItems="center"
                                        wrap="nowrap"
                                    >
                                        <Grid item>
                                            <MedalIcon
                                                width="32px"
                                                height="32px"
                                            />
                                        </Grid>
                                        <Grid item flexGrow={1}>
                                            <Typography
                                                color="#23282B"
                                                fontFamily="open sans"
                                                fontSize={16}
                                                fontWeight={600}
                                            >
                                                Review companies
                                            </Typography>
                                            <Typography
                                                component="p"
                                                color="#808080"
                                                fontFamily="open sans"
                                                fontSize={16}
                                                fontWeight={400}
                                            >
                                                Review companies you have worked
                                                for, this contributes to your
                                                Jobfactor score
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <ArrowRightIcon
                                                fontSize="large"
                                                htmlColor="#055C7F"
                                                sx={{
                                                    bgcolor: '#f2f2f2',
                                                    borderRadius: '8px'
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                            </Box>
                        </Grid>
                        <Grid
                            item
                            width="100%"
                            xs={isTablet ? 12 : 4}
                            mb="20px"
                        >
                            {isTablet ? null : (
                                <>
                                    <Box
                                        width="100%"
                                        // heading={
                                        //     <SectionHeading
                                        //         disable={true}
                                        //         ml={0}
                                        //     >
                                        //         Top Professionals in your industry
                                        //     </SectionHeading>
                                        // }
                                        onClick={() => {}}
                                        mb="12px"
                                        // py={'16px'}
                                    >
                                        <Box
                                            bgcolor="#fff"
                                            p="8px 16px"
                                            borderBottom="1px solid #D8D8D8"
                                        >
                                            <Typography
                                                fontFamily="Open Sans"
                                                fontSize="20px"
                                                fontWeight={600}
                                                lineHeight="28px"
                                            >
                                                Company Reviews
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    color: '#808080',
                                                    fontSize: '16px',
                                                    mt: '8px'
                                                }}
                                            >
                                                All reviews shown are anonymous
                                            </Typography>
                                        </Box>
                                        <Box bgcolor="#fff">
                                            <Box p="8px 16px">
                                                {data.map((item, index) => (
                                                    <Box
                                                        key={index}
                                                        sx={{
                                                            padding: '20px 0',
                                                            // m: 'auto 20px',
                                                            borderBottom:
                                                                '1px solid #D9D9D9'
                                                        }}
                                                    >
                                                        <Typography
                                                            fontWeight={'600'}
                                                            mb={'5px'}
                                                            color="#808080"
                                                            textTransform={
                                                                'uppercase'
                                                            }
                                                        >
                                                            {item.review}
                                                        </Typography>
                                                        <Rating
                                                            name="read-only"
                                                            value={item.rating}
                                                            readOnly
                                                        />
                                                    </Box>
                                                ))}
                                            </Box>
                                            <Button
                                                variant="outlined"
                                                sx={{
                                                    borderColor: '#05668D',
                                                    height: '44px',
                                                    mt: '20px'
                                                }}
                                            >
                                                View reviews
                                            </Button>
                                        </Box>
                                    </Box>
                                </>
                            )}

                            <Box
                                // heading={
                                // <SectionHeading ml={0} fontSize={106}>
                                //     Analytics
                                // </SectionHeading>
                                // }
                                onClick={() => {}}
                                mb="4px"
                                bgcolor="transparent"
                                // pl={{xs: '16px', lg: 0}}
                            >
                                <Box
                                    bgcolor="#fff"
                                    p="8px 16px"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    mb="4px"
                                >
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize="20px"
                                        fontWeight={600}
                                        lineHeight="28px"
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        Analytics
                                    </Typography>
                                    {/* <IconButton
                                        onClick={() => {}}
                                        sx={{
                                            // background: '#DFEBF0',
                                            borderRadius: '8px',
                                            padding: '8px'
                                        }}
                                    >
                                        <ArrowRight />
                                    </IconButton> */}
                                </Box>
                                <Grid
                                    container
                                    gap={1}
                                    bgcolor="#fff"
                                    overflow="hidden"
                                    borderRadius={1.5}
                                    wrap="nowrap"
                                    height="140px"
                                    p={{
                                        xs: '8px 16px',
                                        sm: '8px 20px',
                                        lg: 1
                                    }}
                                >
                                    <Grid
                                        item
                                        bgcolor="#f2f2f2"
                                        flexGrow={1}
                                        width="100%"
                                        // maxWidth="33%"
                                    >
                                        <Grid
                                            container
                                            flexDirection="column"
                                            alignItems="center"
                                            justifyContent="center"
                                            height="100%"
                                        >
                                            <AnalyticsProfile />
                                            <Typography
                                                component="p"
                                                color="#23282B"
                                                fontFamily="open sans"
                                                fontSize={{ xs: 11, sm: 14 }}
                                                fontWeight={600}
                                                textAlign="center"
                                            >
                                                PROFILE
                                                <br />
                                                VIEWS
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        bgcolor="#f2f2f2"
                                        flexGrow={1}
                                        width="100%"
                                        // maxWidth="33%"
                                    >
                                        <Grid
                                            container
                                            flexDirection="column"
                                            alignItems="center"
                                            justifyContent="center"
                                            height="100%"
                                        >
                                            <AnalyticsSearch />
                                            <Typography
                                                component="p"
                                                color="#23282B"
                                                fontFamily="open sans"
                                                fontSize={{ xs: 11, sm: 14 }}
                                                fontWeight={600}
                                                textAlign="center"
                                            >
                                                SEARCH
                                                <br />
                                                APPEARANCES
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        bgcolor="#f2f2f2"
                                        flexGrow={1}
                                        width="100%"
                                        // maxWidth="33%"
                                    >
                                        <Grid
                                            container
                                            flexDirection="column"
                                            alignItems="center"
                                            justifyContent="center"
                                            height="100%"
                                        >
                                            <AnalyticsGraph />
                                            <Typography
                                                component="p"
                                                color="#23282B"
                                                fontFamily="open sans"
                                                fontSize={{ xs: 11, sm: 14 }}
                                                fontWeight={600}
                                                textAlign="center"
                                            >
                                                POSTS
                                                <br />
                                                ENGAGEMENTS
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box
                                py={1}
                                bgcolor="#fff"
                                px={isMobile ? '16px' : 1}
                            >
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: '#f2f2f2',
                                        borderRadius: '8px',
                                        width: '100%'
                                        // ml: isMobile ? '16px' : undefined
                                    }}
                                >
                                    <Typography
                                        color={'primary'}
                                        fontFamily="open sans"
                                        fontWeight={600}
                                        fontSize={14}
                                        sx={{ textTransform: 'none', py: 0.5 }}
                                    >
                                        Upgrade to Premium plan
                                    </Typography>
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                {/* <Grid item>
                    <Grid
                        container
                        wrap={isTablet ? undefined : 'nowrap'}
                        spacing={2}
                    >
                        <Grid item width="100%" xs={isTablet ? 12 : 8}>
                            <Section px={0}>
                                <Box
                                    px={isTablet ? 0 : 5}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <Box>
                                        <Typography
                                            component="h3"
                                            color="#23282B"
                                            fontFamily="open sans"
                                            fontSize={20}
                                            fontWeight={600}
                                            mb={1}
                                        >
                                            About us
                                        </Typography>
                                        <Typography
                                            component="h4"
                                            color="#808080"
                                            fontFamily="open sans"
                                            fontSize={16}
                                            fontWeight={400}
                                            mb={1.5}
                                        >
                                            Tell the world about your company.
                                        </Typography>
                                    </Box>
                                    <Button
                                        sx={{
                                            mt: 1,
                                            height: '40px',
                                            width: '80px',
                                            py: '8px',
                                            px: '8px',
                                            maxHeight: '40px',
                                            color: '#05668D',
                                            backgroundColor: '#F2F2F2',
                                            borderRadius: '8px',
                                            textTransform: 'none',
                                            textDecoration: 'none',
                                            gap: 1
                                        }}
                                        onClick={handleOnEditAboutUs}
                                    >
                                        Edit
                                        <EditBtn />
                                    </Button>
                                </Box>
                                <Divider />
                                <Box px={isTablet ? 0 : 5}>
                                    <Typography
                                        component="p"
                                        color="#808080"
                                        fontFamily="open sans"
                                        fontSize={isTablet ? 14 : 16}
                                        fontWeight={400}
                                        mt={4}
                                        mb={2.5}
                                    >
                                        {company?.bio?.slice(
                                            0,
                                            showAllBio
                                                ? company?.bio?.length
                                                : maxBioLength
                                        )}{' '}
                                        {company?.bio?.length > maxBioLength &&
                                        !showAllBio
                                            ? '...'
                                            : ''}
                                    </Typography>
                                    {company?.bio?.length > maxBioLength ? (
                                        <Box
                                            display="flex"
                                            width="118px"
                                            borderRadius="8px"
                                            height="40px"
                                            bgcolor="#f2f2f2"
                                            justifyContent="flex-start"
                                            sx={{ textDecoration: 'none' }}
                                        >
                                            <Button
                                                disableRipple
                                                sx={{
                                                    backgroundColor: '#f2f2f2',
                                                    borderRadius: '8px',
                                                    padding: '8px',
                                                    // alignSelf: 'flex-start',
                                                    width: '118px',
                                                    height: '40px'
                                                    // display: 'flex',
                                                    // alignItems: 'flex-start',
                                                    // justifyContent: 'flex-start'
                                                }}
                                                endIcon={<ExpandIcon />}
                                                onClick={() =>
                                                    setShowAllBio(!showAllBio)
                                                }
                                            >
                                                <Typography
                                                    color={'#05668D'}
                                                    fontFamily="open sans"
                                                    fontWeight={700}
                                                    fontSize={14}
                                                    sx={{
                                                        textTransform: 'none',
                                                        textDecoration: 'none',
                                                        py: 1
                                                    }}
                                                >
                                                    See{' '}
                                                    {showAllBio
                                                        ? 'less'
                                                        : 'more'}
                                                </Typography>
                                            </Button>
                                        </Box>
                                    ) : null}
                                </Box>
                            </Section>
                            <Box
                                pl={
                                    isMobile
                                        ? '16px'
                                        : isTablet
                                        ? '20px'
                                        : '40px'
                                }
                                pr={isMobile ? '16px' : isTablet ? 0 : '40px'}
                                py="16px"
                                bgcolor="#fff"
                            >
                                <Box
                                    mb={'16px'}
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Typography
                                        component="h2"
                                        color="#23282B"
                                        fontFamily="open sans"
                                        lineHeight="1.125"
                                        fontSize={
                                            isMobile ? 20 : isTablet ? 24 : 28
                                        }
                                        fontWeight={600}
                                    >
                                        Activities
                                    </Typography>

                                    <Typography
                                        component="h2"
                                        color="#808080"
                                        fontFamily="open sans"
                                        lineHeight="24px"
                                        fontSize={16}
                                        fontWeight={400}
                                    >
                                        {totalPosts + ' posts'}
                                    </Typography>
                                </Box>
                                <Box
                                    display="flex"
                                    height="44px"
                                    bgcolor="#f2f2f2"
                                >
                                    {currentActivity.map((item) => (
                                        <Box
                                            width={`${100 / 3}%`}
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',

                                                borderBottomColor:
                                                    activity === item
                                                        ? '#05668D'
                                                        : 'transparent'
                                            }}
                                            onClick={() => setActivity(item)}
                                            borderBottom="2px solid"
                                        >
                                            <Typography
                                                fontFamily="open sans"
                                                fontSize={14}
                                                fontWeight={600}
                                                lineHeight="20px"
                                                color={
                                                    activity === item
                                                        ? '#05668D'
                                                        : '#808080'
                                                }
                                            >
                                                {item}
                                            </Typography>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                            <Box
                                pl={
                                    isMobile
                                        ? '8px'
                                        : isTablet
                                        ? '12px'
                                        : '32px'
                                }
                                pr={isMobile ? '8px' : isTablet ? 0 : '32px'}
                                py={isMobile ? '16px' : '16px'}
                                bgcolor="#f2f2f2"
                            >
                                {activity === 'Posts' ? (
                                    <>
                                        {!isLoaded ? (
                                            [1, 2].map((_) => (
                                                <SkeletonLoader />
                                            ))
                                        ) : posts?.length === 0 ? (
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                gap="12px"
                                                pl={
                                                    isMobile
                                                        ? '16px'
                                                        : undefined
                                                }
                                            >
                                                <Alert />
                                                <Typography>
                                                    {' '}
                                                    No Posts
                                                </Typography>
                                            </Box>
                                        ) : (
                                            <>
                                                {posts
                                                    ?.slice(0, 2)
                                                    .map((feed, index) => (
                                                        <FeedItem
                                                            key={index}
                                                            feed={feed}
                                                        />
                                                    ))}
                                                {posts?.length > 0 && (
                                                    <Box px="8px">
                                                        <Button
                                                            variant="contained"
                                                            sx={{
                                                                backgroundColor:
                                                                    '#f2f2f2',
                                                                borderRadius:
                                                                    '8px',
                                                                width: '100%',
                                                                mt: '16px',
                                                                mb: '24px'
                                                            }}
                                                            onClick={() =>
                                                                navigate(
                                                                    '/company-activities'
                                                                )
                                                            }
                                                        >
                                                            <Typography
                                                                color={
                                                                    'primary'
                                                                }
                                                                fontFamily="open sans"
                                                                fontWeight={600}
                                                                fontSize={16}
                                                                sx={{
                                                                    textTransform:
                                                                        'none',
                                                                    py: 0.5,
                                                                    display:
                                                                        'flex',
                                                                    alignItems:
                                                                        'center',
                                                                    gap: '12px'
                                                                }}
                                                            >
                                                                Show all posts{' '}
                                                                <ArrowIconRight />
                                                            </Typography>
                                                        </Button>
                                                    </Box>
                                                )}
                                            </>
                                        )}
                                    </>
                                ) : activity === 'Achievements' ? (
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        gap="12px"
                                        pl={isMobile ? '16px' : undefined}
                                    >
                                        <Alert />
                                        <Typography>
                                            {' '}
                                            No Achievements
                                        </Typography>
                                    </Box>
                                ) : (
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        gap="12px"
                                        pl={isMobile ? '16px' : undefined}
                                    >
                                        <Alert />
                                        <Typography>No Articles</Typography>
                                    </Box>
                                )}
                            </Box>
                            <Section
                                py={2.5}
                                heading={
                                    <SectionHeading>
                                        Help other Companies
                                    </SectionHeading>
                                }
                            >
                                {isMobile ? (
                                    <Grid
                                        container
                                        gap={1}
                                        alignItems="center"
                                        // wrap="nowrap"
                                        direction="column"
                                    >
                                        <Box
                                            py={1}
                                            pr={1}
                                            width="100%"
                                            display="flex"
                                            alignItems="center"
                                            gap="8px"
                                        >
                                            <MedalIcon
                                                width="32px"
                                                height="32px"
                                            />
                                            <Typography
                                                component="h4"
                                                color="#23282B"
                                                fontFamily="open sans"
                                                fontSize={16}
                                                fontWeight={600}
                                                mb={1}
                                                flex={1}
                                            >
                                                Rate and review employees
                                            </Typography>
                                            <ArrowRightIcon
                                                fontSize="large"
                                                htmlColor="#055C7F"
                                            />
                                        </Box>
                                        <Grid container flexGrow={1}>
                                            <Typography
                                                component="p"
                                                color="#808080"
                                                fontFamily="open sans"
                                                fontSize={14}
                                                fontWeight={400}
                                            >
                                                Rate and review past and present
                                                employees. This contributes to
                                                your Jobfactor score.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <Grid
                                        container
                                        gap={2.5}
                                        alignItems="center"
                                        wrap="nowrap"
                                    >
                                        <Grid item py={1} pr={1}>
                                            <MedalIcon />
                                        </Grid>
                                        <Grid item flexGrow={1}>
                                            <Typography
                                                component="h4"
                                                color="#23282B"
                                                fontFamily="open sans"
                                                fontSize={16}
                                                fontWeight={600}
                                                mb={1}
                                            >
                                                Rate and review employees
                                            </Typography>
                                            <Typography
                                                component="p"
                                                color="#808080"
                                                fontFamily="open sans"
                                                fontSize={16}
                                                fontWeight={400}
                                                pr={8}
                                            >
                                                Rate and review past and present
                                                employees. This contributes to
                                                your Jobfactor score.
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <ArrowRightIcon
                                                fontSize="large"
                                                htmlColor="#055C7F"
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                            </Section>
                        </Grid>
                        <Grid item width="100%" xs={isTablet ? 12 : 4}>
                            {isTablet ? null : (
                                <>
                                    <Typography component={'h6'} variant="h6">
                                        Company Reviews
                                    </Typography>
                                    <Typography
                                        sx={{
                                            color: '#808080',
                                            fontSize: '16px',
                                            mt: '5px'
                                        }}
                                    >
                                        All reviews shown are anonymous
                                    </Typography>
                                    {data.map((item, index) => (
                                        <Box
                                            key={index}
                                            sx={{
                                                padding: '20px 0',
                                                // m: 'auto 20px',
                                                borderBottom:
                                                    '1px solid #808080'
                                            }}
                                        >
                                            <Typography
                                                fontWeight={'600'}
                                                mb={'5px'}
                                                color="#808080"
                                                textTransform={'uppercase'}
                                            >
                                                {item.review}
                                            </Typography>
                                            <Rating
                                                name="read-only"
                                                value={item.rating}
                                                readOnly
                                            />
                                        </Box>
                                    ))}

                                    <Button
                                        variant="outlined"
                                        sx={{
                                            borderColor: '#05668D',
                                            mt: '20px',
                                            mb: '40px'
                                        }}
                                    >
                                        View reviews
                                    </Button>
                                </>
                            )}
                            <Box
                                // heading={
                                // <SectionHeading ml={0} fontSize={106}>
                                //     Analytics
                                // </SectionHeading>
                                // }
                                onClick={() => {}}
                                mb="12px"
                                bgcolor="transparent"
                                pr={isMobile ? '16px' : undefined}
                            >
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    mb="12px"
                                    pl={isMobile ? '16px' : '20px'}
                                >
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize="20px"
                                        fontWeight={600}
                                        lineHeight="28px"
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        Analytics
                                    </Typography>
                                    <IconButton
                                        onClick={() => {}}
                                        sx={{
                                            background: '#DFEBF0',
                                            borderRadius: '8px',
                                            padding: '8px'
                                        }}
                                    >
                                        <ArrowRight />
                                    </IconButton>
                                </Box>
                                <Grid
                                    container
                                    gap={0.33}
                                    overflow="hidden"
                                    borderRadius={1.5}
                                    wrap="nowrap"
                                    height="140px"
                                    pl={isMobile ? '16px' : undefined}
                                >
                                    <Grid
                                        item
                                        bgcolor="#E6F0F4"
                                        flexGrow={1}
                                        maxWidth="33%"
                                    >
                                        <Grid
                                            container
                                            flexDirection="column"
                                            alignItems="center"
                                            justifyContent="center"
                                            height="100%"
                                        >
                                            <AnalyticsProfile />
                                            <Typography
                                                component="p"
                                                color="#23282B"
                                                fontFamily="open sans"
                                                fontSize={14}
                                                fontWeight={600}
                                                textAlign="center"
                                            >
                                                PROFILE
                                                <br />
                                                VIEWS
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        bgcolor="#E6F0F4"
                                        flexGrow={1}
                                        maxWidth="33%"
                                    >
                                        <Grid
                                            container
                                            flexDirection="column"
                                            alignItems="center"
                                            justifyContent="center"
                                            height="100%"
                                        >
                                            <AnalyticsSearch />
                                            <Typography
                                                component="p"
                                                color="#23282B"
                                                fontFamily="open sans"
                                                fontSize={14}
                                                fontWeight={600}
                                                textAlign="center"
                                            >
                                                SEARCH
                                                <br />
                                                APPEARANCES
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        bgcolor="#E6F0F4"
                                        flexGrow={1}
                                        maxWidth="33%"
                                    >
                                        <Grid
                                            container
                                            flexDirection="column"
                                            alignItems="center"
                                            justifyContent="center"
                                            height="100%"
                                        >
                                            <AnalyticsGraph />
                                            <Typography
                                                component="p"
                                                color="#23282B"
                                                fontFamily="open sans"
                                                fontSize={14}
                                                fontWeight={600}
                                                textAlign="center"
                                            >
                                                POSTS
                                                <br />
                                                ENGAGEMENTS
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box px={isMobile ? '16px' : undefined}>
                                <Button
                                    variant="outlined"
                                    sx={{
                                        // backgroundColor: '#05668D',
                                        borderRadius: '8px',
                                        width: '100%'
                                        // ml: isMobile ? '16px' : undefined
                                    }}
                                >
                                    <Typography
                                        color={'primary'}
                                        fontFamily="open sans"
                                        fontWeight={700}
                                        fontSize={14}
                                        sx={{ textTransform: 'none', py: 0.5 }}
                                    >
                                        Upgrade to Premium plan
                                    </Typography>
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid> */}
            </Grid>
            <ContactInfoModal
                open={openContactInfoModal}
                setOpen={setOpenContactInfoModal}
            />
            <EditAboutModal
                handleClose={() => {
                    setOpenProfileInfoModal(false);
                    setShowAlert(true);
                }}
                open={openAboutMeModal}
                companyDetails={company}
                setOpen={setOpenAboutMeModal}
            />
            <EditProfileModal
                handleClose={() => {
                    setOpenProfileInfoModal(false);
                    setShowAlert(true);
                }}
                open={openProfileInfoModal}
                setOpen={setOpenProfileInfoModal}
            />
            <SnackAlert
                open={showAlert}
                handleClose={() => setShowAlert(false)}
                message={'Profile updated successfully.'}
                type={'success'}
            />
        </Container>
    );
}

export default AboutCompany;
