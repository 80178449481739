import { Box, Skeleton } from '@mui/material'

const CompanyRatingsLoader = () => {
    return (
        <>
            {[1, 2, 3, 4].map((i) => (
                <>
                    <Box p={{ md: "30px", xs: '15px 10px' }} display={{ sm: 'block', xs: 'none' }} width="100%">
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                gap: { md: '20px', xs: '10px' }
                            }}
                        >
                            <Skeleton
                                variant="circular"
                                sx={{
                                    minWidth: 80,
                                    minHeight: 80,
                                }}
                            />
                            <Box sx={{ width: { sm: '100%', xs: 'fit-content' } }}>
                                <Skeleton
                                    variant="text"
                                    sx={{
                                        fontSize: '20px',
                                        width: '100px'
                                    }}
                                />
                                <Skeleton
                                    variant="text"
                                    sx={{
                                        mt: '12px',
                                        width: { sm: '300px', xs: '60px' },
                                    }}
                                />
                                <Skeleton
                                    variant="text"
                                    sx={{
                                        mt: '12px',
                                        width: { sm: '90px', xs: '60px' },
                                        height: { sm: '20px', xs: '15px' }
                                    }}
                                />
                                 <Skeleton
                                        variant="text"
                                        sx={{ width: { md: '200px', xs: '90px' }, height: { md: '40px', xs: '30px' } }}
                                    />
                            </Box>
                            <Box>
                                <Skeleton
                                    variant="rounded"
                                    sx={{
                                        width: { md: '90px', xs: '90px' },
                                        height:  '15px' 
                                    }}
                                />
                                <Skeleton
                                    variant="rounded"
                                    sx={{
                                        mt:'12px',
                                        width: { md: '200px', xs: '90px' },
                                        height: { md: '20px', xs: '15px' }
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                </>
            ))}
        </>
    )
}

export default CompanyRatingsLoader