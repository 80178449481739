import React from 'react';

function SecretCodeIcon() {
    return (
        <>
            <svg
                width="41"
                height="20"
                viewBox="0 0 41 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M5.14963 18.9599C5.06629 18.9599 4.97463 18.9516 4.89963 18.9432L3.09129 18.6932C2.22463 18.5766 1.44129 17.8016 1.30796 16.9182L1.05796 15.0932C0.974626 14.5099 1.22463 13.7516 1.64129 13.3266L5.29963 9.66823C4.70796 7.30156 5.39129 4.80156 7.13296 3.07656C9.83296 0.384897 14.2246 0.376564 16.933 3.07656C18.2413 4.3849 18.958 6.12656 18.958 7.97656C18.958 9.82656 18.2413 11.5682 16.933 12.8766C15.183 14.6099 12.6913 15.2932 10.3413 14.6932L6.67463 18.3516C6.32463 18.7182 5.69963 18.9599 5.14963 18.9599ZM12.0246 2.30156C10.5663 2.30156 9.11629 2.85156 8.00796 3.9599C6.50796 5.45156 5.96629 7.6349 6.59129 9.66823C6.65796 9.89323 6.59963 10.1266 6.43296 10.2932L2.51629 14.2099C2.37463 14.3516 2.25796 14.7182 2.28296 14.9099L2.53296 16.7349C2.58296 17.0516 2.92463 17.4099 3.24129 17.4516L5.05796 17.7016C5.25796 17.7349 5.62463 17.6182 5.76629 17.4766L9.69963 13.5516C9.86629 13.3849 10.108 13.3349 10.3246 13.4016C12.333 14.0349 14.5246 13.4932 16.0246 11.9932C17.0913 10.9266 17.683 9.49323 17.683 7.97656C17.683 6.45156 17.0913 5.02656 16.0246 3.9599C14.9413 2.8599 13.483 2.30156 12.0246 2.30156Z"
                    fill="#292D32"
                />
                <path
                    d="M7.65872 17.1177C7.50039 17.1177 7.34206 17.0594 7.21706 16.9344L5.30039 15.0177C5.05872 14.776 5.05872 14.376 5.30039 14.1344C5.54206 13.8927 5.94206 13.8927 6.18372 14.1344L8.10039 16.051C8.34206 16.2927 8.34206 16.6927 8.10039 16.9344C7.97539 17.0594 7.81706 17.1177 7.65872 17.1177Z"
                    fill="#292D32"
                />
                <path
                    d="M12.083 9.78906C11.0497 9.78906 10.208 8.9474 10.208 7.91406C10.208 6.88073 11.0497 6.03906 12.083 6.03906C13.1163 6.03906 13.958 6.88073 13.958 7.91406C13.958 8.9474 13.1163 9.78906 12.083 9.78906ZM12.083 7.28906C11.7413 7.28906 11.458 7.5724 11.458 7.91406C11.458 8.25573 11.7413 8.53906 12.083 8.53906C12.4247 8.53906 12.708 8.25573 12.708 7.91406C12.708 7.5724 12.4247 7.28906 12.083 7.28906Z"
                    fill="#292D32"
                />
                <line x1="40.5" y1="2.5" x2="40.5" y2="17.5" stroke="#D9D9D9" />
            </svg>
        </>
    );
}

export default SecretCodeIcon;
