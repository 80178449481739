import { Typography } from "@mui/material";

interface ScoreChipProps {
    score: number;
}

export default function ScoreChip({ score }: ScoreChipProps) {
    return (
        <Typography
            sx={{
                fontSize: 14,
                fontWeight: '600',
                fontFamily: 'Open Sans',
                color: '#FFFFFF',
                background: '#49B6FF',
                borderRadius: '6px',
                padding: '4px 12px'
            }}
        >
            {score}
        </Typography>
    );

}