import axiosInstance from '../axiosConfig';
import { UpdateEducationRequest } from './types';
import { UseMutationResult, useMutation } from 'react-query';

async function updateEducation(patchtRequest: UpdateEducationRequest) {
    const response = await axiosInstance.patch(
        `/api/educations/${patchtRequest.id}`,
        patchtRequest
    );
    const data = await response.data;
    return data;
}
export function useUpdateEducation(): UseMutationResult<
    UpdateEducationRequest,
    unknown,
    UpdateEducationRequest
> {
    return useMutation(updateEducation);
}
