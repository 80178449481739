import React from 'react';

function BriefcaseFormIcon() {
    return (
        <>
            <svg
                width="41"
                height="20"
                viewBox="0 0 41 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M6.66734 18.3333H13.334C16.684 18.3333 17.284 16.9917 17.459 15.3583L18.084 8.69167C18.309 6.65833 17.7257 5 14.1673 5H5.834C2.27567 5 1.69234 6.65833 1.91734 8.69167L2.54234 15.3583C2.71734 16.9917 3.31734 18.3333 6.66734 18.3333Z"
                    stroke="#808080"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6.66797 4.99935V4.33268C6.66797 2.85768 6.66797 1.66602 9.33464 1.66602H10.668C13.3346 1.66602 13.3346 2.85768 13.3346 4.33268V4.99935"
                    stroke="#494949"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M11.6654 10.8333V11.6667C11.6654 11.675 11.6654 11.675 11.6654 11.6833C11.6654 12.5917 11.657 13.3333 9.9987 13.3333C8.3487 13.3333 8.33203 12.6 8.33203 11.6917V10.8333C8.33203 10 8.33203 10 9.16536 10H10.832C11.6654 10 11.6654 10 11.6654 10.8333Z"
                    stroke="#494949"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M18.043 9.16602C16.118 10.566 13.918 11.3993 11.668 11.6827"
                    stroke="#494949"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M2.18359 9.39258C4.05859 10.6759 6.17526 11.4509 8.33359 11.6926"
                    stroke="#494949"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <line x1="40.5" y1="2.5" x2="40.5" y2="17.5" stroke="#D9D9D9" />
            </svg>
        </>
    );
}

export default BriefcaseFormIcon;
