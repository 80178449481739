import { Box, SxProps, Theme, Typography } from '@mui/material';
import {
    getCompetencyColor,
    skillLevel
} from '../../../../utils/Helper/helper';
interface IItemTypes {
    name: string;
    rating?: number;
    competencyLevel: number;
}
interface IPropTypes {
    item: IItemTypes;
    coloredBg?: boolean;
    titleStyle?: SxProps<Theme>;
    shortFormStyle?: SxProps<Theme>;
    truncate?: boolean
}
const ExperienceChip = ({
    item,
    coloredBg = true,
    titleStyle,
    shortFormStyle,
    truncate = true
}: IPropTypes) => {
    const truncatedName =
        item.name.length > 10 ? item.name.slice(0, 10) + '...' : item.name;

    return (
        <Box
            sx={{
                wordWrap: 'none',
                minWidth: '69px',
                minHeight: '16px',
                marginBottom: '.5rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '8px'
            }}
        >
            <Typography
                variant="titleSmallSemiBold"
                sx={{
                    color: '#FFFFFF',
                    background: coloredBg
                        ? getCompetencyColor(
                              item?.competencyLevel ?? item?.rating
                          )
                        : '#808080',
                    borderRadius: '6px',
                    padding: '4px 8px',
                    display: 'flex',
                    gap: '8px',
                    alignItems: 'center',
                    ...titleStyle
                }}
            >
                {/* {item.name.replaceAll(' ', '\u00A0')} */}
                {truncate
                    ? truncatedName.replaceAll(' ', '\u00A0')
                    : item.name.replaceAll(' ', '\u00A0')}
                <Typography
                    variant="labelMediumSemiBold"
                    sx={{
                        background: coloredBg
                            ? 'rgba(5, 5, 5, 0.4)'
                            : getCompetencyColor(
                                  item?.competencyLevel ?? item?.rating
                              ),
                        padding: '0px 4px',
                        borderRadius: '4px',
                        ...shortFormStyle
                    }}
                >
                    {
                        skillLevel.filter(
                            (x) =>
                                x.level ===
                                (item?.competencyLevel ?? item?.rating)
                        )[0]?.shortForm
                    }
                </Typography>
            </Typography>
        </Box>
    );
};

export default ExperienceChip;
