import { Avatar, Box, Typography } from '@mui/material';
import { useGetProfessionalProfileScore } from '../../../utils/hooks/api/professional-profile/useGetProfessionalProfileScore';

const UserDetails = (props: PropTypes) => {
    const { user } = props;
    // const { data: score } = useGetProfessionalProfileScore(user?.profileId ?? "");

    return (
        <>
            <Avatar
                alt="Remy Sharp"
                src={user?.imageUrl}
                sx={{ width: { sm: 56, xs: 44 }, height: { sm: 56, xs: 44 } }}
            />
            <Box sx={{ width: '100%' }}>
                <Typography
                    component={'h1'}
                    sx={{
                        display: { sm: 'block', xs: 'none' },
                        fontSize: '20px',
                        fontWeight: '600',
                        fontFamily: 'Open Sans',
                        color: '#494949'
                    }}
                >
                    {user.term}
                </Typography>
                <Box
                    sx={{
                        display: { sm: 'none', xs: 'flex' },
                        alignItems: 'center',
                        gap: '8px',
                    }}
                >
                    <Typography
                        component={'h1'}
                        sx={{
                            fontSize: 14,
                            fontWeight: '600',
                            fontFamily: 'Open Sans',
                            color: '#494949'
                        }}
                    >
                        {user.term}
                    </Typography>
                    <Typography
                        sx={{
                            display: { sm: 'none', xs: 'block' },
                            fontSize: 14,
                            fontWeight: '600',
                            fontFamily: 'Open Sans',
                            color: '#FFFFFF',
                            background: '#49B6FF',
                            borderRadius: '6px',
                            padding: '4px 12px'
                        }}
                    >
                        {user.score}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: { sm: 'center', xs: "start" },
                        gap: { sm: '20px', xs: '0px' },
                        flexDirection: { sm: 'row', xs: "column" }
                    }}
                >
                    {
                        user?.currentEmployment?.employmentLevel && (
                            <>
                                <Typography
                                    sx={{
                                        fontSize: { sm: '16px', xs: 14 },
                                        fontFamily: 'Open Sans',
                                        color: '#808080',
                                        letterSpacing: '0.0015em'
                                    }}
                                >
                                    {user?.currentEmployment?.employmentLevel}
                                </Typography>
                                <Typography
                                    component={'div'}
                                    sx={{
                                        display: { sm: 'block', xs: 'none' },
                                        width: '7px',
                                        height: '7px',
                                        borderRadius: '100px',
                                        background: '#494949',
                                        border: '2px solid #494949'
                                    }}
                                />
                            </>

                        )
                    }

                    {
                        user?.currentEmployment?.companyName && (
                            <>
                                <Typography
                                    sx={{
                                        fontSize: { sm: '16px', xs: 12 },
                                        fontFamily: 'Open Sans',
                                        color: '#808080'
                                    }}
                                >
                                    {user?.currentEmployment?.companyName}
                                </Typography>
                                <Typography
                                    component={'div'}
                                    sx={{
                                        display: { sm: 'block', xs: 'none' },
                                        width: '7px',
                                        height: '7px',
                                        borderRadius: '100px',
                                        background: '#494949',
                                        border: '2px solid #494949'
                                    }}
                                />
                            </>
                        )

                    }
                    <Typography
                        sx={{
                            display: { sm: 'block', xs: 'none' },
                            fontSize: '20px',
                            fontWeight: '600',
                            fontFamily: 'Open Sans',
                            color: '#FFFFFF',
                            background: '#49B6FF',
                            borderRadius: '6px',
                            padding: '0px 12px'
                        }}
                    >
                        {user.score}
                    </Typography>
                </Box>
            </Box>
        </>
    );
};
interface PropTypes {
    user: User;
}
type User = {
    term: string;
    currentEmployment: {
        companyName: string;
        employmentLevel: string;
    };
    imageUrl?: string;
    profileId?: string;
    score?: number;
};
export default UserDetails;
