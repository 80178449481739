import React from 'react'

const ExpandMore = () => {
  return (
      <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
          <path
              d="M12.6669 8.74258L11.0252 7.10092L8.35023 4.42592C7.78356 3.86758 6.81689 4.26758 6.81689 5.06758V10.2592V14.9342C6.81689 15.7342 7.78356 16.1342 8.35023 15.5676L12.6669 11.2509C13.3586 10.5676 13.3586 9.43425 12.6669 8.74258Z"
              fill="#808080"
          />
      </svg>
  );
}

export default ExpandMore
