import { useQuery } from 'react-query';
import { QueryKeys } from '../QueryKey';
import axiosInstance from '../axiosConfig';

export function useGetProfessionalRating(profileId: string) {
    const getProfessionalRating = async (profileId: string) => {
        const response = await axiosInstance.get(
            `/api/professional-ratings/${profileId}/userSkills`
        );
        const data = await response.data;
        return data;
    };
    return useQuery(QueryKeys.ProfessionalRating, () =>
        getProfessionalRating(profileId)
    );
}
