import { Box, Divider, Typography } from '@mui/material';
import React from 'react';
import { Responsiveness } from '../../theme/overrides';

interface SectionHeaderProps {
    header: React.ReactNode;
    subHeader?: React.ReactNode;
}
const SectionHeader = ({ header, subHeader }: SectionHeaderProps) => {
    const { isMobile, isTablet } = Responsiveness()
    return (
        <Box sx={{ p: isMobile ? '16px 0' : isTablet ? '20px' : '20px 40px', width: '100% ' }}>
            <Box px={isMobile ? '16px' : undefined}>
                <Typography
                    sx={{
                        mb: isMobile ? '.5rem' : '1rem',
                        fontWeight: '600',
                        fontSize: isMobile ? '14px' : undefined,
                        color: isMobile ? '#808080' : undefined
                    }}
                    variant="h5"
                >
                    {header}
                </Typography>
                <Typography
                    sx={{
                        color: '#808080',
                        fontWeight: 400,
                        fontSize: isMobile ? '14px' : undefined
                    }}
                    variant="subtitle2"
                >
                    {subHeader}
                </Typography>
            </Box>
            <Divider sx={{ width: isMobile ? '100%' : '95%', pt: '1rem' }} />
        </Box>
    );
};

export default SectionHeader;
