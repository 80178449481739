import { useQuery } from 'react-query';
import { QueryKeys } from '../QueryKey';
import axiosInstance from '../axiosConfig';

export function useGetScoreProperties(id: string) {
    const GetScoreProperties = async (id: string) => {
        const response = await axiosInstance.get(
            `/api/professional-profiles/${id}/score-properties`
        );
        return response.data;
    };
    return useQuery(['score-properties', id], () => GetScoreProperties(id), {
        refetchOnMount: 'always'
    });
}
