import React from 'react';

const ArrowBlueDown = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.9984 6H14.6684C17.9784 6 19.3384 8.35 17.6784 11.22L16.3384 13.53L14.9984 15.84C13.3384 18.71 10.6284 18.71 8.96837 15.84L7.62837 13.53L6.28837 11.22C4.65837 8.35 6.00837 6 9.32837 6L11.9984 6Z"
                fill="#055C7F"
                stroke="#055C7F"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};
export default ArrowBlueDown;
