import { Box, Typography } from '@mui/material';
import Alert from '../../assets/icons/Alert';

function NotificationsPage() {
    return (
        <>
            <Box
                mr={'20px'}
                display="flex"
                alignItems="center"
                gap="12px"
                bgcolor="#f2f2f2"
                px={'16px'}
                py={'12px'}
            >
                <Alert />
                <Typography color="#808080" fontSize="14px" fontWeight={600}>
                    This feature is unavailable on this interface.
                </Typography>
            </Box>
        </>
    );
}

export default NotificationsPage;
