import { Box, Typography, IconButton, Button } from '@mui/material';
import { ArrowLeftIcon } from '../../../assets/icons/ArrowLeftIcon';
import { useNavigate } from 'react-router';
import { Responsiveness } from '../../../theme/overrides';

const Header = ({handleSubmit = () => {}}) => {
    const navigate = useNavigate();
    const { isMobile } = Responsiveness()
    return (
        <Box
            sx={{
                display: 'flex',
                alignContent: 'center',
                gap: isMobile ? '12px' : 4,
                backgroundColor: '#fff',
                padding: isMobile ? '16px' : '24px',
                borderBottom: '2px solid #D9D9D9',
                justifyContent: 'space-between'
            }}
        >
            <Box onClick={() => navigate(-1)}>
                <ArrowLeftIcon />
            </Box>
            <Box flex={1} >
                <Typography
                fontWeight={600}
                    color="#23282B"
                    fontSize={{xs: '20px', sm: '24px'}}
                >
                    Job Preference
                </Typography>
                {/* <Typography sx={{ color: '#808080', marginTop: '20px' }}>
                    Update your job preferences to get better recommendations on
                    Jobfactor.
                    <br />
                    Employers can see these when your resume is set to public.
                </Typography> */}
            </Box>
                <Button
                    variant="contained"
                    sx={{display: {xs: 'none', lg: 'block'}, color: '#FFFFFF', width: '86px', height: '52px', borderRadius: '8px' }}
                    onClick={handleSubmit}
                >
                    <Typography
                        fontWeight={700}
                        fontSize={16}
                        sx={{
                            textTransform: 'none',
                        }}
                    >
                        Save
                    </Typography>
                </Button>
        </Box>
    );
};

export default Header;
