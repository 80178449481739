import {
    Dispatch,
    ReactNode,
    SetStateAction,
    createContext,
    useContext,
    useEffect,
    useState
} from 'react';
import { IConversation, IConversationUser } from './types';
import { useAuth } from '../../../utils/context/AuthContext';
import io, { Socket } from 'socket.io-client';
import { AlertColor, Box } from '@mui/material';
import axiosInstance from '../../../utils/hooks/api/axiosConfig';
import MessagesAlert, { MessagesAlertProps } from '../Components/Alert/MessagesAlert';

export type ViewModeType = null | 'manage' | 'search';

export interface MessagesContextType {
    userId: string;
    socket: Socket | null;
    isConnected: boolean;
    hasConversations: boolean;
    conversations: IConversation[];
    setConversations: Dispatch<SetStateAction<IConversation[]>>;
    activeConversation: IConversationUser | null;
    setActiveConversation: Dispatch<SetStateAction<IConversationUser | null>>;
    manageMode: boolean;
    setManageMode: Dispatch<SetStateAction<boolean>>;
    viewMode: ViewModeType;
    setViewMode: Dispatch<SetStateAction<ViewModeType>>;
    showArchived: boolean;
    setShowArchived: Dispatch<SetStateAction<boolean>>;
    managedConversationIds: string[];
    setManagedConversationIds: Dispatch<SetStateAction<string[]>>;
    setAlert: (message: string, type?: AlertColor) => void;
}

export const MessagesContext = createContext<MessagesContextType | null>(null);

export function MessagesProvider(props: any) {
    const { user } = useAuth();
    const [socket, setSocket] = useState<Socket | null>(null);
    const [hasConversations, setHasConversations] = useState<boolean>(false);
    const [conversations, setConversations] = useState<IConversation[]>([]);
    const [activeConversation, setActiveConversation] =
        useState<IConversationUser | null>(null);
    const [manageMode, setManageMode] = useState<boolean>(false);
    const [isConnected, setIsConnected] = useState<boolean>(false);
    const [viewMode, setViewMode] = useState<ViewModeType>(null);
    const [showArchived, setShowArchived] = useState<boolean>(false);
    const [managedConversationIds, setManagedConversationIds] = useState<
        string[]
    >([]);
    const [alertProps, setAlertProps] = useState<MessagesAlertProps>({
        message: '',
        type: 'info'
    });

    // Connect to socket
    useEffect(() => {
        const newSocket = io(process.env.REACT_APP_BASE_URL + '/messages', {
            query: { userId: user!.id, activeConversationId: null }
        });
        setSocket(newSocket);
        const checkIfLoadingConversations = async () => {
            const { data } = await axiosInstance.get<boolean>(
                '/api/messages/has-conversations/' +
                    user!.id +
                    '?archived=' +
                    showArchived
            );
            setHasConversations(data);
        };
        checkIfLoadingConversations();
        return () => {
            newSocket.disconnect();
        };
    }, [user]);

    // Set up socket event listeners
    useEffect(() => {
        if (socket === null) return;
        // Socket event listeners
        socket.on('connect', () => {
            setAlert('');
            setIsConnected(true);
        });

        socket.on('disconnect', () => {
            setIsConnected(false);
            setAlert('Connection lost to messaging server', 'warning');
        });

        // Clean up the socket connection on component unmount
        return () => {
            socket.off('connect');
            socket.off('disconnect');
            socket.disconnect();
        };
    }, [socket]);

    // Set alert
    const setAlert = (message: string, type: AlertColor = 'info') => {
        setAlertProps({ message, type });
    };

    return (
        <MessagesContext.Provider
            value={{
                userId: user!.id,
                socket,
                isConnected,
                hasConversations,
                conversations,
                setConversations,
                activeConversation,
                setActiveConversation,
                manageMode,
                setManageMode,
                viewMode,
                setViewMode,
                showArchived,
                setShowArchived,
                managedConversationIds,
                setManagedConversationIds,
                setAlert
            }}
        >
            <Box
                sx={
                    isConnected
                        ? { transition: 'opacity 0.5s ease-in-out' }
                        : {
                              pointerEvents: 'none',
                              opacity: '0.25',
                              transition: 'opacity 0.5s ease-in-out'
                          }
                }
            >
                {props.children as ReactNode}
            </Box>
            <MessagesAlert {...alertProps} />
        </MessagesContext.Provider>
    );
}

export function useMessages() {
    const context = useContext(MessagesContext);
    if (!context) {
        throw new Error('useMessages must be used within a MessagesProvider');
    }
    return context;
}
