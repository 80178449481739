import React from 'react';

function RectangleLine() {
    return (
        <>
            <svg
                width="100"
                height="4"
                viewBox="0 0 100 4"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect width="100" height="4" fill="#EDEDED" />
            </svg>
        </>
    );
}

export default RectangleLine;
