import { useState } from 'react';
import UserActions from '../components/UserActions';
import TableWrapper from '../components/TableWrapper';
import SectionHeader from '../../common/SectionHeader';
import {
    Box,
    Button,
    Grid,
    Paper,
    TableCell,
    TableRow,
    Typography,
} from '@mui/material';
import PageHeader from '../../common/PageHeader';
import { useAuth } from '../../../utils/context/AuthContext';
import { useNavigate } from 'react-router-dom';
import EmptyState from '../../../components/EmptyState/EmptyState';
import CompanyReviewLoader from '../../../components/LoadingState/CompanyReviewLoader';
import CompanyDetails from '../components/CompanyDetails';
import { useGetPendingCompanyRequests } from '../../../utils/hooks/api/company-review-requests/useGetPendingCompanyRequests';
import { useGetReviewedCompanyRequests } from '../../../utils/hooks/api/company-review-requests/useGetReviewedCompanyRequests';
import CompanyRatingsTab from '../../Connections/components/CompanyRatingsTab';
import MobileNav from '../../../components/MobileNav/MobileNav';
import { Responsiveness } from '../../../theme/overrides';

const CompanyRatings = () => {
    const { user: userData } = useAuth();
    const [selectedButton, setSelectedButton] = useState<'pending' | 'reviewed'>('pending');
    const { isMobile, isTablet } = Responsiveness()
    const [pendingPage, setPendingPage] = useState(0);
    const [reviewedPage, setReviewedPage] = useState(0);
    const navigate = useNavigate();
    const rowsPerPage = 8;

    const { data: pendingRequests=[], isFetching = false } = useGetPendingCompanyRequests(userData?.professionalProfile?.userId!);
    const { data: receivedRequests=[], isFetching: isFetchingReceived = false } = useGetReviewedCompanyRequests(userData?.professionalProfile?.userId!);

    const handlePendingChangePage = (page: number) => {
        setPendingPage(page - 1);
    };
    const handleReviewedChangePage = (page: number) => {
        setReviewedPage(page - 1);
    };

    const routes = [
        {
            label: 'Suggested Reviews',
            route: '/reviews'
        },
        {
            label: 'Sent Requests',
            route: '/reviews/sent-requests'
        },
        {
            label: 'Received Requests',
            route: '/reviews/received-requests'
        },
        {
            label: 'Company Ratings',
            route: '/reviews/company-ratings'
        }
    ];


    const handleButtonClick = (button: 'pending' | 'reviewed') => {
        setSelectedButton(button);
    };

    return (
        <Box mt={{ xs: '21px', sm: 0 }}>
            {isMobile ? <MobileNav list={routes} /> : null}
            <PageHeader
                pageTitle={'Reviews'}
                hideAction={true}
                handleBack={() => navigate('/reviews')}
            />
            {isMobile ? null : isTablet ? <MobileNav list={routes} /> : null}
            <Paper sx={{ mt: {xs: '8ps', sm: '20px', lg: '36px'} }}>
                <Box>
                    <SectionHeader
                        header={'Company Ratings'}
                        subHeader={
                            'You can only rate your previous or current company'
                        }
                    />
                    <Grid
                        sx={{
                            display: 'flex',
                            padding: '0px 40px',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'flex-end',
                            background: '#FFF',
                            mt: '20px'
                        }}
                        container
                    >
                        <Grid item xs={6}>
                            <Button
                                onClick={() => handleButtonClick('pending')}
                                variant="contained"
                                sx={{
                                    borderRadius: '0px',
                                    width: '100%',
                                    padding: '12px 8px',
                                    color:
                                        selectedButton === 'pending'
                                            ? '#FFF'
                                            : '#23282B',
                                    fontWeight:
                                        selectedButton === 'pending'
                                            ? 600
                                            : 400,
                                    background:
                                        selectedButton === 'pending'
                                            ? '#044864'
                                            : '#EDEDED',
                                    '&:hover': {
                                        fontWeight: 600
                                    }
                                }}
                            >
                                Pending
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                onClick={() => handleButtonClick('reviewed')}
                                variant="contained"
                                sx={{
                                    width: '100%',
                                    borderRadius: '0px',
                                    padding: '12px 8px',
                                    color:
                                        selectedButton === 'reviewed'
                                            ? '#FFF'
                                            : '#23282B',
                                    fontWeight:
                                        selectedButton === 'reviewed'
                                            ? 600
                                            : 400,
                                    background:
                                        selectedButton === 'reviewed'
                                            ? '#044864'
                                            : '#EDEDED',
                                    '&:hover': {
                                        fontWeight: 600
                                    }
                                }}
                            >
                                Reviewed
                            </Button>
                        </Grid>
                    </Grid>
                    {selectedButton === 'pending' ? (
                        <Grid container>
                            <TableWrapper
                                handleChangePage={handlePendingChangePage}
                                rowsPerPage={rowsPerPage}
                                data={pendingRequests}
                            >
                                {isFetching ? (
                                    <CompanyReviewLoader />
                                ) : pendingRequests.length > 0 ? (
                                    pendingRequests
                                        ?.slice(
                                            pendingPage * rowsPerPage,
                                            pendingPage * rowsPerPage +
                                                rowsPerPage
                                        )
                                        ?.map((request: any, index: number) => (
                                            <TableRow key={`user_${index}`}>
                                                <TableCell
                                                    sx={{
                                                        padding: '40px 32px',
                                                        paddingBottom: '25px'
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'space-between'
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                width: '70%'
                                                            }}
                                                        >
                                                            <CompanyDetails
                                                                company={
                                                                    request.companyProfile
                                                                }
                                                                id={
                                                                    request
                                                                        .companyProfile
                                                                        .company
                                                                        .id
                                                                }
                                                            />
                                                        </Box>

                                                        <UserActions>
                                                            <Typography
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    fontWeight: 600,
                                                                    color: '#808080'
                                                                }}
                                                            >
                                                                {request?.isCurrentPosition
                                                                    ? 'Current company'
                                                                    : 'Previous company'}
                                                            </Typography>
                                                            <Button
                                                                sx={{
                                                                    borderRadius:
                                                                        '8px',
                                                                    padding:
                                                                        '12px 16px',
                                                                    border: '1px solid #05668D',
                                                                    background:
                                                                        '#05668D',
                                                                    fontSize:
                                                                        '14px',
                                                                    fontWeight:
                                                                        '600',
                                                                    textTransform:
                                                                        'capitalize',
                                                                    boxShadow:
                                                                        'none',
                                                                    width: 'auto',
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                    color: '#FFFFFF',
                                                                    minWidth:
                                                                        'fit-content',
                                                                    textDecoration:
                                                                        'none',
                                                                    ':hover': {
                                                                        color: '#FFFFFF',
                                                                        textDecoration:
                                                                            'none',
                                                                        background:
                                                                            '#05668D'
                                                                    }
                                                                }}
                                                                onClick={() =>
                                                                    navigate(
                                                                        `/reviews/company-ratings/${request?.companyProfile?.company.id}?requestId=${request?.id}`
                                                                    )
                                                                }
                                                            >
                                                                Rate
                                                            </Button>
                                                        </UserActions>
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                ) : (
                                    <EmptyState message="You have not received any request for reviews yet." />
                                )}
                            </TableWrapper>
                        </Grid>
                    ) : (
                        <Grid container>
                            <TableWrapper
                                handleChangePage={handleReviewedChangePage}
                                rowsPerPage={rowsPerPage}
                                data={receivedRequests}
                            >
                                {isFetchingReceived ? (
                                    <CompanyReviewLoader />
                                ) : receivedRequests.length > 0 ? (
                                    receivedRequests
                                        ?.slice(
                                            reviewedPage * rowsPerPage,
                                            reviewedPage * rowsPerPage +
                                                rowsPerPage
                                        )
                                        ?.map((request: any, index: number) => (
                                            <TableRow key={`user_${index}`}>
                                                <TableCell
                                                    sx={{
                                                        padding: '40px 32px',
                                                        paddingBottom: '25px'
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'space-between'
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                width: '70%'
                                                            }}
                                                        >
                                                            <CompanyDetails
                                                                company={
                                                                    request.companyProfile
                                                                }
                                                                id={
                                                                    request
                                                                        .companyProfile
                                                                        .id
                                                                }
                                                            />
                                                        </Box>

                                                        <UserActions>
                                                            <Typography
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    fontWeight: 600,
                                                                    color: '#808080'
                                                                }}
                                                            >
                                                                {request?.isCurrentPosition
                                                                    ? 'Current company'
                                                                    : 'Previous company'}
                                                            </Typography>
                                                            <Button
                                                                sx={{
                                                                    borderRadius:
                                                                        '8px',
                                                                    padding:
                                                                        '12px 16px',
                                                                    border: '1px solid #05668D',
                                                                    background:
                                                                        '#05668D',
                                                                    fontSize:
                                                                        '14px',
                                                                    fontWeight:
                                                                        '600',
                                                                    textTransform:
                                                                        'capitalize',
                                                                    boxShadow:
                                                                        'none',
                                                                    width: 'auto',
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                    color: '#FFFFFF',
                                                                    minWidth:
                                                                        'fit-content',
                                                                    textDecoration:
                                                                        'none',
                                                                    ':hover': {
                                                                        color: '#FFFFFF',
                                                                        textDecoration:
                                                                            'none',
                                                                        background:
                                                                            '#05668D'
                                                                    }
                                                                }}
                                                                onClick={() =>
                                                                    navigate(
                                                                        `/reviews/company-ratings/${request?.companyProfile?.company.id}`,
                                                                        {
                                                                            state: {
                                                                                ratings:
                                                                                    request?.ratings,
                                                                                message:
                                                                                    request?.message
                                                                            }
                                                                        }
                                                                    )
                                                                }
                                                            >
                                                                View Rating
                                                            </Button>
                                                        </UserActions>
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            mt: '12px'
                                                        }}
                                                    >
                                                        <CompanyRatingsTab
                                                            ratings={
                                                                request?.ratings
                                                            }
                                                            count={4}
                                                        />
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                ) : (
                                    <EmptyState message="You have not reviewed any company yet." />
                                )}
                            </TableWrapper>
                        </Grid>
                    )}
                </Box>
            </Paper>
        </Box>
    );
};

export default CompanyRatings;
