import {
    Box,
    Typography,
    Button,
    InputBase,
    IconButton,
    Select,
    MenuItem,
    Paper,
    CircularProgress,
    InputLabel,
    Chip,
    InputAdornment
} from '@mui/material';
import { ArrowLeftIcon } from '../../../assets/icons/ArrowLeftIcon';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { useEffect, useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useLocation, useNavigate } from 'react-router-dom';
import DialogBox from '../components/DialogBox';
import {
    ADVANCE,
    BEGINNER,
    EXPERIENCED,
    EXPERT,
    THOUGHT_LEADER,
    convertDateStringToMilli,
    getJobType,
    getWorkPlace,
    jobTypes,
    workPlaces
} from '../../../utils/Helper/helper';
import { useCreateJobPost } from '../../../utils/hooks/api/jobs/useCreateJob';
import { useAuth } from '../../../utils/context/AuthContext';
import { useQueryClient } from 'react-query';
import SnackAlert from '../../../components/Snackbar';
import { useUpdateJobPost } from '../../../utils/hooks/api/jobs/useUpdateJob';
import { useGetJobById } from '../../../utils/hooks/api/jobs/useGetJobById';
import Dropdown from '../../../assets/icons/Dropdown';
import EditBtn from '../../../assets/icons/EditBtn';
import SearchIcon from '../../../assets/icons/SearchIcon';
import { QueryKeys } from '../../../utils/hooks/api/QueryKey';
import moment from 'moment';
import { ISelectedSkillType, ISkillType } from '../types/ISkillType';
import { useGetSkills } from '../../../utils/hooks/api/skills/useGetSkills';
import Close from '../../../assets/icons/CloseIcon';

const NewJobPost = () => {
    const navigate = useNavigate();
    const [show, setShow] = useState<boolean>(false);
    const [selectedSkills, setSelectedSkills] = useState<ISelectedSkillType[]>(
        []
    );
    const [overview, setOverview] = useState<string>('');
    const [qualifications, setQualifications] = useState<string>('');
    const [responsibilities, setResponsibilities] = useState<string>('');
    const [deadLine, setDeadLine] = useState<string>('');
    const [additional1, setAdditional1] = useState<string>('');
    const [jobTitle, setJobTitle] = useState<string>('');
    const [workPlaceType, setWorkPlaceType] = useState<string>('');
    const [currency, setCurrency] = useState<string>('NGN');
    const [jobType, setJobType] = useState<string>('');
    const [location, setLocation] = useState<string>('');
    const [yearsOfExp, setYearsOfExp] = useState<string>('');
    const [minimumScore, setMinimumScore] = useState<string>('');
    const [minSalary, setMinSalary] = useState<string>('');
    const [maxSalary, setMaxSalary] = useState<string>('');
    const [edit, setEdit] = useState<boolean>(false);
    const [step, setStep] = useState<number>(1);
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const { user } = useAuth();
    const queryClient = useQueryClient();
    const createJobPost = useCreateJobPost();
    const updateJobPost = useUpdateJobPost();
    const state = useLocation()?.state;
    const jobId = state ? state?.id : '';
    const { data: job } = useGetJobById(jobId, '');
    const [search, setSearch] = useState('');
    const { data: skills = [], refetch } = useGetSkills(search, 20);

    const handleSubmit = () => {
        if (user?.id && user?.primaryCompanyProfile?.companyId) {
            setLoading(true);
            let temp = {
                title: jobTitle,
                workplaceType: workPlaceType,
                jobType: jobType,
                location: location,
                yearsOfExperience: yearsOfExp,
                minimumScore: minimumScore,
                salaryRangeFrom: Number(minSalary),
                additionalNote: additional1,
                salaryRangeTo: Number(maxSalary),
                salaryCurrency: currency,
                overview: overview,
                qualifications: qualifications,
                responsibilities: responsibilities,
                expiredAt: convertDateStringToMilli(deadLine),
                skills: selectedSkills,
                description: 'description',
                companyId: user?.primaryCompanyProfile?.companyId
            };

            if (state?.id) {
                let data = {
                    ...temp,
                    createdAt: job?.createdAt,
                    id: state.id,
                    isActive: job?.isActive
                };
                updateJobPost.mutate(data, {
                    onSuccess: (res) => {
                        console.log('updateJobPost', res);
                        queryClient.invalidateQueries({
                            queryKey: [QueryKeys.RetrieveJobs]
                        });
                        setShowPopup(true);
                        setLoading(false);
                    },
                    onError: (error) => {
                        setShow(true);
                        setLoading(false);
                    }
                });
            } else {
                let data = {
                    ...temp,
                    isActive: true
                };
                createJobPost.mutate(data, {
                    onSuccess: (res) => {
                        console.log('createJobPost', res);
                        queryClient.invalidateQueries({
                            queryKey: [QueryKeys.RetrieveJobs]
                        });
                        setShowPopup(true);
                        setLoading(false);
                    },
                    onError: (error) => {
                        setShow(true);
                        setLoading(false);
                    }
                });
            }
        }
    };

    const handleSkillsChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        id: number
    ) => {
        if (step !== 3) {
            let temp = JSON.parse(JSON.stringify(selectedSkills));
            temp?.filter((item: ISelectedSkillType) => {
                if (item?.id === id) {
                    item['competencyLevel'] = Number(
                        (event.target as HTMLInputElement).value
                    );
                }
                return null;
            });
            setSelectedSkills(temp);
        }
    };

    const onChangeDeadLine = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDeadLine(e.target.value);
    };

    useEffect(() => {
        if (state?.id && job) {
            setJobTitle(job?.title);
            setJobType(job?.jobType);
            setLocation(job?.location);
            setYearsOfExp(job.yearsOfExperience);
            setMinimumScore(job?.minimumScore);
            setCurrency(job?.salaryCurrency);
            setMinSalary(job?.salaryRangeFrom);
            setMaxSalary(job?.salaryRangeTo);
            setWorkPlaceType(job?.workplaceType);
            setOverview(job?.overview);
            setQualifications(job?.qualifications);
            setResponsibilities(job?.responsibilities);
            setDeadLine(moment(job?.expiredAt).format('YYYY-MM-DD')); //todo: convert date number to string
            setAdditional1(
                job?.additionalNote ? job?.additionalNote?.split('\n')[0] : ''
            );
            setSelectedSkills(job?.skills ?? []);
        }
    }, [job, state]);

    useEffect(() => {
        if (search.length > 2) {
            refetch();
        }
    }, [search]);

    const Header = () => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    gap: '15px',
                    alignItems: 'center',
                    backgroundColor: '#fff',
                    borderBottom: '1px solid #D9D9D9',
                    padding: { xs: '12px 16px', sm: '20px' }
                }}
            >
                <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        gap: { xs: '12px', sm: '15px' }
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: { xs: '12px', sm: '20px', lg: '28px' }
                        }}
                    >
                        <IconButton
                            onClick={() =>
                                step === 1
                                    ? navigate(-1)
                                    : setStep((prev) => prev - 1)
                            }
                        >
                            <ArrowLeftIcon />
                        </IconButton>
                        <Typography
                            variant="headlineMediumSemiBold"
                            color="#494949"
                            fontSize={{ xs: '20px', sm: '24px' }}
                        >
                            Job Posting
                        </Typography>
                    </Box>
                </Box>
                {step === 2 ? (
                    <Button
                        variant="contained"
                        sx={{
                            width: '300px',
                            padding: '15px 0px',
                            display: { xs: 'none', sm: 'block' }
                            // maxWidth: '186px'
                            // minWidth: '1px'
                        }}
                        disabled={!selectedSkills ? true : false}
                        onClick={() => {
                            if (step === 2) {
                                setEdit(true);
                                setStep(3);
                            }
                        }}
                    >
                        Preview job posting
                    </Button>
                ) : null}
                {step === 3 && (
                    <Button
                        variant="contained"
                        sx={{
                            width: '100px',
                            padding: '15px 0px',
                            display: { xs: 'none', sm: 'block' }
                        }}
                        onClick={() => {
                            if (step === 3) {
                                handleSubmit();
                            }
                        }}
                    >
                        {step === 3
                            ? state?.id
                                ? 'Update Job'
                                : 'Post Job'
                            : 'Save and Continue'}
                    </Button>
                )}
                {edit && step === 3 ? (
                    <Button
                        sx={{
                            color: '#05668D',
                            borderRadius: '8px',
                            background: '#fcfbf8',
                            padding: '12px 16px',
                            fontSize: '14px',
                            border: '1px solid #05668D',
                            fontFamily: 'Open Sans',
                            gap: '10px',
                            width: 'auto',
                            height: '44px',
                            fontWeight: '600',
                            textDecoration: 'none',
                            textTransform: 'capitalize'
                        }}
                        onClick={() => {
                            setEdit(false);
                            setStep(1);
                        }}
                        endIcon={<EditBtn />}
                    >
                        Edit
                    </Button>
                ) : null}
            </Box>
        );
    };
    const RequiredSkills = (
        <Box
            sx={{
                marginTop: step === 3 ? {xs: '8px', lg: '20px'} : 0,
                mb: { xs: 1, lg: 2 },
                backgroundColor: '#fff',
                padding: {
                    xs: '12px 16px',
                    sm: '20px',
                    lg: '12px 40px'
                }
            }}
        >
            <Typography variant={'titleLargeSemiBold'} color={'#494949'}>
                Required skills
            </Typography>
            <Stack spacing={3} sx={{ width: '100%', mt: '20px' }}>
                <Autocomplete
                    disabled={step === 3}
                    multiple
                    options={skills.map((option: ISkillType) => option.name)}
                    freeSolo
                    filterSelectedOptions
                    defaultValue={selectedSkills.map((x) => x.name)}
                    // renderTags={(value: readonly string[], getTagProps) =>
                    //     selectedSkills.map(
                    //         (option: ISelectedSkillType, index: number) => (
                    //             <Chip
                    //                 variant="outlined"
                    //                 label={option.name}
                    //                 {...getTagProps({ index })}
                    //             />
                    //         )
                    //     )
                    // }
                    onChange={(event, values) => {
                        let data: ISelectedSkillType[] =
                            selectedSkills.length > 0
                                ? selectedSkills?.filter((x) =>
                                      values.includes(x.name)
                                  )
                                : [];
                        if (values.length > selectedSkills.length) {
                            data.push({
                                id:
                                    selectedSkills.length > 0
                                        ? selectedSkills[
                                              selectedSkills.length - 1
                                          ]?.id + 1
                                        : 1,
                                name: values[values.length - 1],
                                competencyLevel: 2
                            });
                        }
                        values = data?.map((x) => x.name);
                        setSelectedSkills(data);
                    }}
                    sx={{
                        backgroundColor: '#f2f2f2',
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '8px',
                            pt: '6px',
                            pb: '6px'
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            onChange={(e) => setSearch(e.target.value)}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <>
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                        {params.InputProps.startAdornment}
                                    </>
                                )
                            }}
                            placeholder="Search skills"
                        />
                    )}
                />
            </Stack>
            <Box>
                {selectedSkills?.length > 0 ? (
                    <Box sx={{ marginTop: '24px' }}>
                        <Typography
                            variant={'titleMediumSemiBold'}
                            color={'#494949'}
                            marginBottom={'10px'}
                            component={'div'}
                        >
                            {step === 3
                                ? 'Selected skills and competency levels expected from applicants  '
                                : 'Select the competency level for skills expected from the applicants'}
                        </Typography>
                        <Box
                            component={'div'}
                            sx={{
                                mt: '12px',
                                mb: '12px',
                                gap: '12px',
                                display: 'flex',
                                flexWrap: 'wrap',
                                alignItems: 'center'
                            }}
                        >
                            {selectedSkills?.map((item, index) => (
                                <Chip
                                    key={index}
                                    label={item?.name}
                                    onDelete={() => {
                                        setSelectedSkills(
                                            selectedSkills.filter(
                                                (x) => x.name !== item?.name
                                            )
                                        );
                                    }}
                                    deleteIcon={
                                        <Close sx={{ color: '#232b2b' }} />
                                    }
                                    sx={{
                                        p: '8px',
                                        border: '1px solid #05668D',
                                        background: '#E6F0F4',
                                        fontWeight: 600,
                                        fontSize: '16px'
                                    }}
                                />
                            ))}
                        </Box>
                        {selectedSkills?.map((item, index) => {
                            return (
                                <Box
                                    key={index + 2}
                                    sx={{
                                        borderBottom: '1px solid #D9D9D9',
                                        backgroundColor: '#f2f2f2',
                                        padding: {
                                            xs: '8px 12px',
                                            sm: '12px 12px',
                                            lg: '20px 12px'
                                        },
                                        borderRadius: '5px',
                                        marginTop: { xs: 1, lg: '15px' }
                                    }}
                                >
                                    <FormControl
                                        sx={{
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            gap: { xs: '8px', lg: '20px' },
                                            width: '100%',
                                            flexWrap: 'wrap'
                                        }}
                                    >
                                        <FormLabel
                                            id="demo-controlled-radio-buttons-group"
                                            sx={{
                                                // minWidth: '50px',
                                                color: '#23282B',
                                                fontSize: {
                                                    xs: '14px',
                                                    sm: '16px'
                                                }
                                            }}
                                        >
                                            {item?.name}
                                        </FormLabel>
                                        <RadioGroup
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name="controlled-radio-buttons-group"
                                            value={item?.competencyLevel}
                                            onChange={(e) => {
                                                handleSkillsChange(e, item?.id);
                                            }}
                                            sx={{
                                                flexDirection: 'row'
                                            }}
                                        >
                                            <FormControlLabel
                                                sx={{
                                                    color: '#F55536'
                                                }}
                                                value={BEGINNER.level}
                                                control={
                                                    <Radio
                                                        sx={{
                                                            color: '#808080'
                                                        }}
                                                    />
                                                }
                                                label={BEGINNER.name}
                                            />
                                            <FormControlLabel
                                                sx={{
                                                    color: '#49B6FF'
                                                }}
                                                value={EXPERIENCED.level}
                                                control={
                                                    <Radio
                                                        sx={{
                                                            color: '#808080'
                                                        }}
                                                    />
                                                }
                                                label={EXPERIENCED.name}
                                            />
                                            <FormControlLabel
                                                sx={{
                                                    color: '#0D00A4'
                                                }}
                                                value={ADVANCE.level}
                                                control={
                                                    <Radio
                                                        sx={{
                                                            color: '#808080'
                                                        }}
                                                    />
                                                }
                                                label={ADVANCE.name}
                                            />
                                            <FormControlLabel
                                                sx={{
                                                    color: '#00BD9D'
                                                }}
                                                value={EXPERT.level}
                                                control={
                                                    <Radio
                                                        sx={{
                                                            color: '#808080'
                                                        }}
                                                    />
                                                }
                                                label={EXPERT.name}
                                            />
                                            <FormControlLabel
                                                sx={{
                                                    color: '#15796E'
                                                }}
                                                value={THOUGHT_LEADER.level}
                                                control={
                                                    <Radio
                                                        sx={{
                                                            color: '#808080'
                                                        }}
                                                    />
                                                }
                                                label={THOUGHT_LEADER.name}
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Box>
                            );
                        })}
                    </Box>
                ) : null}
            </Box>
        </Box>
    );

    const AboutRole = (
        <Box
            sx={{
                marginTop: 0,
                backgroundColor: '#fff',
                padding: {
                    xs: '8px 16px',
                    sm: '20px',
                    lg: '12px 40px'
                },
                mb: { xs: 1, lg: 2 }
            }}
        >
            <Typography variant="titleMediumSemiBold" color="#494949">
                Overview
            </Typography>
            <Box sx={{ marginTop: '10px' }}>
                <InputBase
                    rows={5}
                    placeholder="Summary of the role "
                    value={overview}
                    disabled={step === 3}
                    sx={{
                        borderBottom: '1px solid #D9D9D9',
                        backgroundColor: '#f2f2f2',
                        width: '100%',
                        overflowY: 'auto',
                        borderRadius: '5px',
                        padding: 0,
                        paddingTop: '15px',
                        paddingLeft: '15px',
                        fontFamily: 'open sans',
                        color: '#23282B'
                    }}
                    onChange={(e) => setOverview(e.target.value)}
                    multiline
                />
            </Box>
        </Box>
    );

    const Qualifications = (
        <Box
            sx={{
                mb: { xs: 1, lg: 2 },
                backgroundColor: '#fff',
                padding: {
                    xs: '8px 16px',
                    sm: '20px',
                    lg: '12px 40px'
                }
            }}
        >
            <Typography variant="titleMediumSemiBold" color="#494949">
                Qualifications
            </Typography>
            <Box sx={{ marginTop: '10px' }}>
                <InputBase
                    value={qualifications}
                    disabled={step === 3}
                    rows={5}
                    placeholder="Applicants qualifications"
                    sx={{
                        borderBottom: '1px solid #D9D9D9',
                        backgroundColor: '#f2f2f2',
                        width: '100%',
                        overflowY: 'auto',
                        borderRadius: '5px',
                        padding: 0,
                        paddingTop: '15px',
                        paddingLeft: '15px',
                        fontFamily: 'open sans',
                        color: '#23282B'
                    }}
                    onChange={(e) => setQualifications(e.target.value)}
                    multiline
                />
            </Box>
        </Box>
    );

    const Responsibilities = (
        <Box
            sx={{
                mb: { xs: 1, lg: 2 },
                backgroundColor: '#fff',
                padding: {
                    xs: '8px 16px',
                    sm: '20px',
                    lg: '12px 40px'
                }
            }}
        >
            <Typography variant="titleMediumSemiBold" color="#494949">
                Responsibilities
            </Typography>
            <Box sx={{ marginTop: '10px' }}>
                <InputBase
                    rows={5}
                    disabled={step === 3}
                    value={responsibilities}
                    placeholder="Applicants responsibilities...."
                    sx={{
                        borderBottom: '1px solid #D9D9D9',
                        backgroundColor: '#f2f2f2',
                        width: '100%',
                        overflowY: 'auto',
                        borderRadius: '5px',
                        padding: 0,
                        paddingTop: '15px',
                        paddingLeft: '15px',
                        fontFamily: 'open sans',
                        color: '#23282B'
                    }}
                    onChange={(e) => {
                        setResponsibilities(e.target.value);
                    }}
                    multiline
                />
            </Box>
        </Box>
    );

    const DeadLine = (
        <Box
            sx={{
                mb: { xs: 1, lg: 2 },
                '& input[type="date"]': {
                    borderBottom: '1px solid #D9D9D9'
                },
                backgroundColor: '#fff',
                padding: {
                    xs: '8px 16px',
                    sm: '20px',
                    lg: '12px 40px'
                }
            }}
        >
            <Typography
                component={'p'}
                variant="titleMediumSemiBold"
                color="#494949"
            >
                Application Deadline
            </Typography>
            <input
                type="date"
                readOnly={edit}
                disabled={step === 3}
                value={deadLine}
                onChange={(e) => onChangeDeadLine(e)}
                style={{
                    height: '50px',
                    width: '100%',
                    padding: '15px',
                    borderTop: 0,
                    borderRight: 0,
                    borderLeft: 0,
                    backgroundColor: '#f2f2f2',
                    color: '#808080',
                    fontSize: '16px',
                    marginTop: '10px'
                }}
            />
        </Box>
    );

    const Additional1 = (
        <Box
            sx={{
                backgroundColor: '#fff',
                padding: {
                    xs: '8px 16px',
                    sm: '20px',
                    lg: '12px 40px'
                }
            }}
        >
            <Typography variant="titleMediumSemiBold" color="#494949">
                More info about the job
            </Typography>
            <Box sx={{ marginTop: '10px' }}>
                <InputBase
                    value={additional1}
                    disabled={step === 3}
                    rows={5}
                    placeholder="Feel free to add more information about this job"
                    sx={{
                        backgroundColor: '#f2f2f2',
                        borderBottom: '1px solid #D9D9D9',
                        width: '100%',
                        overflowY: 'auto',
                        borderRadius: '5px',
                        padding: 0,
                        paddingTop: '15px',
                        paddingLeft: '15px',
                        fontFamily: 'open sans',
                        color: '#23282B'
                    }}
                    onChange={(e) => {
                        setAdditional1(e.target.value);
                    }}
                    multiline
                />
            </Box>
        </Box>
    );

    return (
        <>
            <Box
                sx={{
                    mt: { xs: '21px', sm: 0 },
                    pr: { xs: 0, sm: '20px', lg: '40px' }
                }}
            >
                <Header />
                <Box
                    sx={{
                        mt: {
                            xs: '8px',
                            sm: 0
                        }
                    }}
                >
                    {step === 1 || step === 3 ? (
                        <Box
                            sx={{
                                backgroundColor: '#fff',
                                padding: {
                                    xs: '8px 16px',
                                    sm: '20px',
                                    lg: '12px 40px'
                                }
                            }}
                        >
                            <Box>
                                <Box>
                                    <TextField
                                        value={jobTitle}
                                        onChange={(e) =>
                                            setJobTitle(e.target.value)
                                        }
                                        placeholder="Ex: Product Designer"
                                        fullWidth
                                        disabled={step === 3}
                                        label="Job Title"
                                        required
                                        variant="filled"
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#23282B',
                                            '& .MuiInputBase-root,.Mui-disabled':
                                                {
                                                    backgroundColor: '#f2f2f2'
                                                }
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    marginTop: {
                                        xs: '12px',
                                        sm: '16px',
                                        lg: '20px'
                                    }
                                }}
                            >
                                <FormControl
                                    sx={{
                                        marginTop: '8px',
                                        '& .MuiInputLabel-shrink': {
                                            top: '16px',
                                            left: '-2px'
                                        },
                                        '& .MuiSelect-icon': {
                                            top: 'calc(50% - 1em)'
                                        },
                                        '& .Mui-disabled': {
                                            backgroundColor: '#f2f2f2'
                                        }
                                    }}
                                    fullWidth
                                >
                                    <InputLabel required>Job Type</InputLabel>
                                    <Select
                                        fullWidth
                                        disabled={step === 3}
                                        value={jobType}
                                        onChange={(e) =>
                                            setJobType(e.target.value)
                                        }
                                        label="Job Type"
                                        required
                                        variant="filled"
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            backgroundColor: '#f2f2f2',
                                            color: '#23282B'
                                        }}
                                        IconComponent={(props) => (
                                            <Dropdown {...props} />
                                        )}
                                    >
                                        {jobTypes.map((x) => (
                                            <MenuItem key={x} value={x}>
                                                {getJobType(x)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box
                                sx={{
                                    marginTop: {
                                        xs: '12px',
                                        sm: '16px',
                                        lg: '20px'
                                    }
                                }}
                            >
                                <Box sx={{ marginTop: '8px' }}>
                                    <TextField
                                        value={location}
                                        onChange={(e) =>
                                            setLocation(e.target.value)
                                        }
                                        placeholder="Ex: Lagos, Nigeria"
                                        disabled={step === 3}
                                        fullWidth
                                        label="Job Location"
                                        required
                                        variant="filled"
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#23282B',
                                            '& .MuiInputBase-root,.Mui-disabled':
                                                {
                                                    backgroundColor: '#f2f2f2'
                                                }
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    marginTop: {
                                        xs: '12px',
                                        sm: '16px',
                                        lg: '20px'
                                    }
                                }}
                            >
                                <FormControl
                                    sx={{
                                        marginTop: '8px',
                                        '& .MuiInputLabel-shrink': {
                                            top: '16px',
                                            left: '-2px'
                                        },
                                        '& .MuiSelect-icon': {
                                            top: 'calc(50% - 1em)'
                                        },
                                        '& .Mui-disabled': {
                                            backgroundColor: '#f2f2f2'
                                        }
                                    }}
                                    fullWidth
                                >
                                    <InputLabel required>
                                        Workplace type
                                    </InputLabel>
                                    <Select
                                        disabled={step === 3}
                                        value={workPlaceType}
                                        onChange={(e) =>
                                            setWorkPlaceType(e.target.value)
                                        }
                                        label="Job Title"
                                        required
                                        variant="filled"
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            backgroundColor: '#f2f2f2',
                                            color: '#23282B'
                                        }}
                                        IconComponent={(props) => (
                                            <Dropdown {...props} />
                                        )}
                                    >
                                        {workPlaces.map((x) => (
                                            <MenuItem key={x} value={x}>
                                                {getWorkPlace(x)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box
                                sx={{
                                    marginTop: {
                                        xs: '12px',
                                        sm: '16px',
                                        lg: '20px'
                                    }
                                }}
                            >
                                <Box sx={{ marginTop: '8px' }}>
                                    <TextField
                                        value={yearsOfExp}
                                        onChange={(e) =>
                                            setYearsOfExp(e.target.value)
                                        }
                                        placeholder="Ex: 2 years"
                                        disabled={step === 3}
                                        fullWidth
                                        label="Years of Experience"
                                        required
                                        variant="filled"
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#23282B',
                                            '& .MuiInputBase-root,.Mui-disabled':
                                                {
                                                    backgroundColor: '#f2f2f2'
                                                }
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    marginTop: {
                                        xs: '12px',
                                        sm: '16px',
                                        lg: '20px'
                                    }
                                }}
                            >
                                <Box sx={{ marginTop: '8px' }}>
                                    <TextField
                                        value={minimumScore}
                                        onChange={(e) =>
                                            setMinimumScore(e.target.value)
                                        }
                                        placeholder="Ex: 650"
                                        fullWidth
                                        label="Minimum Jobfactor score"
                                        disabled={step === 3}
                                        required
                                        variant="filled"
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#23282B',
                                            '& .MuiInputBase-root,.Mui-disabled':
                                                {
                                                    backgroundColor: '#f2f2f2'
                                                }
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    marginTop: {
                                        xs: '12px',
                                        sm: '16px',
                                        lg: '20px'
                                    }
                                }}
                            >
                                <Typography fontSize={'16px'} color="#494949">
                                    Salary range
                                </Typography>
                                <Box
                                    sx={{
                                        marginTop: '8px',
                                        padding: { xs: 0, sm: '16px' },
                                        borderBottom: '1px solid #D9D9D9',
                                        backgroundColor: {
                                            xs: '#fff',
                                            sm: '#f2f2f2'
                                        },
                                        borderRadius: '5px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexDirection: {
                                            xs: 'column',
                                            sm: 'row'
                                        },
                                        gap: { xs: '12px', sm: '16px' }
                                    }}
                                >
                                    <Select
                                        value={currency}
                                        disabled={step === 3}
                                        onChange={(e) =>
                                            setCurrency(e.target.value)
                                        }
                                        sx={{
                                            width: { xs: '100%', sm: '100px' },
                                            fontFamily: 'Open Sans',
                                            color: '#23282B',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            textAlign: 'center',
                                            // height: '26px',
                                            background: '#D9D9D9',
                                            borderRadius: '4px',
                                            // padding: '12px',
                                            fontSize: '12px',
                                            fontWeight: '700'
                                        }}
                                    >
                                        <MenuItem value={'NGN'}>Naira</MenuItem>
                                        <MenuItem value={'USD'}>
                                            US Dollar
                                        </MenuItem>
                                        <MenuItem value={'Rupees'}>
                                            Canadian Dollar
                                        </MenuItem>
                                        <MenuItem value={'GBP'}>
                                            British Pounds
                                        </MenuItem>
                                    </Select>
                                    <Paper
                                        component="form"
                                        sx={{
                                            p: '8px 12px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            background: '#d8d8d8',
                                            boxShadow: 'none',
                                            borderBottom: '1px solid #808080',
                                            width: '100%',
                                            maxWidth: {
                                                xs: '100%',
                                                sm: '130px'
                                            }
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                fontSize: '16px',
                                                letterSpacing: '0.005em'
                                            }}
                                        >
                                            min.
                                        </Typography>
                                        <InputBase
                                            sx={{
                                                ml: 1,
                                                width: {
                                                    xs: '100px',
                                                    sm: '100%'
                                                },
                                                input: {
                                                    color: '#232b2b',
                                                    fontWeight: 600
                                                }
                                            }}
                                            type="number"
                                            value={minSalary}
                                            disabled={step === 3}
                                            onChange={(e) =>
                                                setMinSalary(e.target.value)
                                            }
                                        />
                                    </Paper>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '16px',
                                            letterSpacing: '0.005em',
                                            color: {xs: '#232b2b', sm: '#808080'},
                                            fontWeight: { xs: 600, sm: 400 },
                                            textTransform: {xs: 'uppercase', sm: 'lowercase'}
                                        }}
                                    >
                                        to
                                    </Typography>
                                    <Paper
                                        component="form"
                                        sx={{
                                            p: '8px 12px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            background: '#d8d8d8',
                                            boxShadow: 'none',
                                            justifyContent: 'center',
                                            borderBottom: '1px solid #808080',
                                            width: '100%',
                                            maxWidth: {
                                                xs: '100%',
                                                sm: '130px'
                                            }
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                fontSize: '16px',
                                                letterSpacing: '0.005em',
                                                color: '#808080'
                                            }}
                                        >
                                            max.
                                        </Typography>
                                        <InputBase
                                            sx={{
                                                ml: 1,
                                                width: {
                                                    xs: '100px',
                                                    sm: '100%'
                                                },
                                                input: {
                                                    color: '#494949',
                                                    fontWeight: 400
                                                }
                                            }}
                                            type="number"
                                            disabled={step === 3}
                                            value={maxSalary}
                                            onChange={(e) =>
                                                setMaxSalary(e.target.value)
                                            }
                                        />
                                    </Paper>
                                </Box>
                            </Box>
                        </Box>
                    ) : null}
                    {step === 2 || step === 3 ? (
                        <>
                            {RequiredSkills}
                            {AboutRole}
                            {Qualifications}
                            {Responsibilities}
                            {DeadLine}
                            {Additional1}
                        </>
                    ) : null}

                    <Box
                        width="100%"
                        sx={{
                            marginTop: '30px',
                            px: { xs: '16px', sm: '20px' }
                        }}
                    >
                        {loading ? (
                            <CircularProgress sx={{ color: '#05668D' }} />
                        ) : (
                            <>
                                {step === 1 && (
                                    <Button
                                        variant="contained"
                                        sx={{
                                            width: {
                                                sm: '100%',
                                                lg: 'fit-content'
                                            },
                                            padding: '15px 20px',
                                            minWidth: '300px'
                                        }}
                                        disabled={
                                            (
                                                step === 1
                                                    ? !jobTitle
                                                    : selectedSkills?.length ===
                                                          0 ||
                                                      qualifications === '' ||
                                                      overview === '' ||
                                                      responsibilities === '' ||
                                                      deadLine === ''
                                            )
                                                ? true
                                                : false
                                        }
                                        onClick={() => {
                                            if (step === 1) {
                                                setStep(2);
                                            }
                                        }}
                                    >
                                        Save and Continue
                                    </Button>
                                )}
                                {step === 3 && (
                                    <Button
                                        variant="contained"
                                        sx={{
                                            padding: '15px 20px',
                                            width: '100%',
                                            display: { xs: 'block', sm: 'none' }
                                        }}
                                        onClick={() => {
                                            if (step === 3) {
                                                handleSubmit();
                                            }
                                        }}
                                    >
                                        {step === 3
                                            ? state?.id
                                                ? 'Update Job'
                                                : 'Post Job'
                                            : 'Save and Continue'}
                                    </Button>
                                )}
                                {step === 2 ? (
                                    <Button
                                        variant="contained"
                                        sx={{
                                            display: {
                                                xs: 'block',
                                                sm: 'none'
                                            },
                                            width: '100%',
                                            padding: '15px 0px'
                                            // maxWidth: '186px'
                                            // minWidth: '1px'
                                        }}
                                        disabled={
                                            !selectedSkills ? true : false
                                        }
                                        onClick={() => {
                                            if (step === 2) {
                                                setEdit(true);
                                                setStep(3);
                                            }
                                        }}
                                    >
                                        Preview job posting
                                    </Button>
                                ) : null}
                            </>
                        )}
                    </Box>
                </Box>
            </Box>
            <DialogBox
                open={showPopup}
                updatePopup={state?.id ? true : false}
                handleClose={() => setShowPopup(false)}
                handleOnPostAnother={() => {
                    setEdit(false);
                    setStep(1);
                    setJobTitle('');
                    setJobType('FULL_TIME');
                    setLocation('');
                    setYearsOfExp('');
                    setMinimumScore('');
                    setCurrency('NGN');
                    setMinSalary('');
                    setMaxSalary('');
                    setWorkPlaceType('ONSITE');
                    setOverview('');
                    setQualifications('');
                    setResponsibilities('');
                    setDeadLine('');
                    setAdditional1('');
                    setSelectedSkills([]);
                    setShowPopup(false);
                }}
            />
            <SnackAlert
                open={show}
                message={'Job post failed please try again!'}
                type={'error'}
                handleClose={() => setShow(false)}
            />
        </>
    );
};

export default NewJobPost;
