function BuildingFormIcon() {
    return (
        <>
            <svg
                width="41"
                height="20"
                viewBox="0 0 41 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M1.66797 18.3359H18.3346"
                    stroke="#747474"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M14.1667 1.66406H5.83333C3.33333 1.66406 2.5 3.15573 2.5 4.9974V18.3307H17.5V4.9974C17.5 3.15573 16.6667 1.66406 14.1667 1.66406Z"
                    stroke="#747474"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M5.83203 13.75H8.33203"
                    stroke="#747474"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M11.668 13.75H14.168"
                    stroke="#747474"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M5.83203 10H8.33203"
                    stroke="#747474"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M11.668 10H14.168"
                    stroke="#747474"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M5.83203 6.25H8.33203"
                    stroke="#747474"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M11.668 6.25H14.168"
                    stroke="#747474"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <line x1="40.5" y1="2.5" x2="40.5" y2="17.5" stroke="#D9D9D9" />
            </svg>
        </>
    );
}

export default BuildingFormIcon;
