import React from 'react';

function LocationFormIcon() {
    return (
        <>
            <svg
                width="41"
                height="20"
                viewBox="0 0 41 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M9.99844 11.8089C8.22344 11.8089 6.77344 10.3672 6.77344 8.58385C6.77344 6.80052 8.22344 5.36719 9.99844 5.36719C11.7734 5.36719 13.2234 6.80885 13.2234 8.59219C13.2234 10.3755 11.7734 11.8089 9.99844 11.8089ZM9.99844 6.61719C8.9151 6.61719 8.02344 7.50052 8.02344 8.59219C8.02344 9.68385 8.90677 10.5672 9.99844 10.5672C11.0901 10.5672 11.9734 9.68385 11.9734 8.59219C11.9734 7.50052 11.0818 6.61719 9.99844 6.61719Z"
                    fill="#808080"
                />
                <path
                    d="M10.0014 18.9641C8.76803 18.9641 7.52637 18.4974 6.5597 17.5724C4.10137 15.2057 1.3847 11.4307 2.4097 6.93906C3.3347 2.86406 6.89303 1.03906 10.0014 1.03906C10.0014 1.03906 10.0014 1.03906 10.0097 1.03906C13.118 1.03906 16.6764 2.86406 17.6014 6.9474C18.618 11.4391 15.9014 15.2057 13.443 17.5724C12.4764 18.4974 11.2347 18.9641 10.0014 18.9641ZM10.0014 2.28906C7.57637 2.28906 4.4597 3.58073 3.6347 7.21406C2.7347 11.1391 5.20137 14.5224 7.4347 16.6641C8.87637 18.0557 11.1347 18.0557 12.5764 16.6641C14.8014 14.5224 17.268 11.1391 16.3847 7.21406C15.5514 3.58073 12.4264 2.28906 10.0014 2.28906Z"
                    fill="#808080"
                />
                <line x1="40.5" y1="2.5" x2="40.5" y2="17.5" stroke="#D9D9D9" />
            </svg>
        </>
    );
}

export default LocationFormIcon;
