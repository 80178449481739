import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ModalClose from '../../assets/icons/ModalClose';
import Chip from '@mui/material/Chip';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import SearchIcon from '../../assets/icons/SearchIcon';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { useGetSkills } from '../../utils/hooks/api/skills/useGetSkills';
import { useNavigate } from 'react-router';
import { useCreateRating } from '../../utils/hooks/api/ratings/useCreateRating';
import { useAuth } from '../../utils/context/AuthContext';
import { useCreateProfessionalRating } from '../../utils/hooks/api/professional-ratings/useCreateProfessionalRating';
import Close from '../../assets/icons/CloseIcon';
import { logger } from '../../utils/Helper/logger';

interface IProptypes {
    selectedSkills: ISkillType[];
    handleClose: () => void;
    setSelectedSkills: (skill: ISkillType[]) => void;
    postId: string;
    achievementModal: boolean;
}
export interface ISkillType {
    id: string;
    name: string;
    type?: {
        id: string;
        name: string;
    };
}

const AchievementSkillsPopup = ({
    setSelectedSkills = () => {},
    handleClose = () => {},
    selectedSkills = [],
    postId = '',
    achievementModal = false
}: IProptypes) => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const postSkills = useCreateRating();
    const postProfessionalRating = useCreateProfessionalRating();
    const [search, setSearch] = useState<string>('');
    const [suggestedSkills, setSuggestedSkills] = useState<ISkillType[]>([]);
    const { data: skills = [], refetch } = useGetSkills(search, 10);
    const [savedSkills, setSavedSkills] = useState<any[]>([])

    useEffect(() => {
        if (achievementModal) {
            const storedSkills = localStorage.getItem('beginnerSkills');

            if (storedSkills) {
                setSavedSkills(JSON.parse(storedSkills) ?? []);
                // setSelectedSkills([...selectedSkills, savedSkills]);
            }
        }
    }, []);

    logger.log(selectedSkills)

    const addSkill = (skill: any) => {
        if (
            !selectedSkills.find((x: ISkillType) => x.name === skill.name) &&
            selectedSkills.length < 10
        ) {
            setSelectedSkills([...selectedSkills, skill]);
        }
    };

    const closeModal = () => {
        setSearch('');
        setSelectedSkills([]);
        handleClose();
    };

    const addSkills = () => {
        let ratings: { rating: number; name: string; type: string }[] = [];
        selectedSkills.map((x) =>
            ratings.push({
                rating: 0,
                name: x.name,
                type: x.type?.name ?? ''
            })
        );
        postSkills.mutate(
            { ratings },
            {
                onSuccess: (res) => {
                    console.log('postSkills', res);
                    let data = {
                        postId,
                        message: 'Please rate my skills',
                        ratingId: res?.id,
                        professionalProfileId: user?.professionalProfile?.id
                    };
                    postProfessionalRating.mutate(data, {
                        onSuccess: (res) => {
                            console.log('postProfessionalRating', res);
                            navigate(`/reviews?ratingId=${res?.id}`);
                            console.log('RES', res);
                        },
                        onError: (err) => {
                            console.log('ERR', err);
                        }
                    });
                },
                onError: (err) => {
                    console.log('err', err);
                }
            }
        );
    };

    useEffect(() => {
        if (suggestedSkills.length === 0) {
            setSuggestedSkills(skills);
        }
    }, [skills]);

    useEffect(() => {
        if (search.length > 2) {
            refetch();
        }
    }, [search]);

    return (
        <>
            <Box
                sx={{
                    ml: { lg: '32px', sm: '20px', xs: 0 },
                    mr: { lg: '32px', sm: '20px', xs: 0 },
                    '& .search-field .MuiInputBase-root': {
                        borderColor: '#D9D9D9',
                        borderRadius: '8px',
                        background: ' #FCFBF8'
                    },
                    '& .search-field .MuiInputBase-root svg': {
                        mr: '13px'
                    }
                }}
            >
                <Box
                    sx={{
                        display: { sm: 'flex', xs: 'none' },
                        mb: '22px',
                        pt: '22px',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        borderBottom: '2px solid #D9D9D9'
                    }}
                >
                    <Typography variant="titleLargeSemiBold">
                        Relevant skills acquired from this achievement
                    </Typography>
                    <IconButton onClick={closeModal}>
                        <ModalClose />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        backgroundColor: '#FFF',
                        display: { sm: 'none', xs: 'flex' },
                        mb: '8px',
                        p: '16px',
                        alignItems: 'center',
                        // justifyContent: 'space-between',
                        gap: '16px',
                        borderBottom: '2px solid #D9D9D9'
                    }}
                >
                    <Box onClick={closeModal}>
                        <ModalClose />
                    </Box>
                    <Typography
                        variant="titleLargeSemiBold"
                        sx={{ flex: 1 }}
                        color={'#000'}
                    >
                        Request review
                    </Typography>
                    <Button
                        variant="contained"
                        sx={{
                            width: 'auto',
                            p: '4px 8px',
                            background: '#FAFAFA',
                            border: '1px solid #05668D',
                            fontSize: '14px',
                            color: '#05668D',
                            display: { sm: 'none', xs: 'block' }
                        }}
                        onClick={closeModal}
                    >
                        Skip
                    </Button>
                </Box>
                <Box mx={{ sm: 0, xs: '16px' }}>
                    <Typography
                        variant="titleSmallSemiBold"
                        fontWeight={{ sm: 600, xs: 400 }}
                        fontSize={{ sm: 16, xs: 14 }}
                        color="#808080"
                    >
                        Be sure to add skills that others witnessed you
                        demonstrate. Maximum of 10 skills.
                    </Typography>
                    <InputLabel
                        required
                        sx={{
                            color: '#5B5B5B',
                            mb: '8px',
                            mt: '16px',
                            fontSize: { sm: 16, xs: 14 }
                        }}
                    >
                        Skills
                    </InputLabel>
                    <Box
                        sx={{
                            position: 'relative'
                        }}
                    >
                        <TextField
                            fullWidth
                            value={search}
                            variant="outlined"
                            className="search-field"
                            placeholder="Search skills"
                            InputProps={{ startAdornment: <SearchIcon /> }}
                            onChange={(e) => setSearch(e.target.value)}
                            sx={{
                                input: {
                                    p: '10px 14px',
                                    pl: 0
                                },
                                '& .MuiInputBase-root': {
                                    fontSize: { sm: 16, xs: 14 }
                                }
                            }}
                        />
                        {skills.length > 0 && search.length > 2 ? (
                            <Paper
                                sx={{
                                    maxHeight: '200px',
                                    overflow: 'auto',
                                    position: 'absolute',
                                    left: 0,
                                    right: 0,
                                    zIndex: 999
                                }}
                            >
                                {skills.map((skill: ISkillType) => (
                                    <Box
                                        key={skill.name}
                                        sx={{
                                            pl: '10px',
                                            cursor: 'pointer',
                                            borderRadius: '8px',
                                            '& :hover': {
                                                background: '#D9D9D9'
                                            }
                                        }}
                                    >
                                        <Typography
                                            variant="titleMediumSemiBold"
                                            sx={{
                                                width: '100%',
                                                display: 'block',
                                                p: '5px',
                                                fontSize: { sm: 16, xs: 14 }
                                            }}
                                            onClick={() => {
                                                addSkill(skill);
                                                setSearch('');
                                            }}
                                        >
                                            {skill.name}
                                        </Typography>
                                    </Box>
                                ))}
                            </Paper>
                        ) : null}
                    </Box>
                    <Box
                        sx={{
                            background: '#FAFAFA',
                            border: '1px solid #C5C5C5',
                            borderRadius: '8px',
                            mt: '12px',
                            p: { xs: '8px 12px 20px' }
                        }}
                    >
                        <Typography
                            variant="titleMediumSemiBold"
                            color={'#5B5B5B'}
                            fontSize={{ sm: 16, xs: 14 }}
                            fontWeight={{ sm: 600, xs: 400 }}
                        >
                            Suggested based on the achievement you posted
                        </Typography>

                        <Box
                            sx={{
                                mt: { xs: '12px' },
                                display: 'flex',
                                alignItems: 'center',
                                gap: { sm: '12px', xs: '8px' },
                                flexWrap: 'wrap'
                            }}
                        >
                            {suggestedSkills.map((item: ISkillType) => (
                                <Chip
                                    key={item.name}
                                    label={item.name}
                                    variant="outlined"
                                    sx={{
                                        fontWeight: { sm: 600, xs: 400 },
                                        fontSize: { sm: '14px', xs: 12 },
                                        color: '#808080',
                                        bgcolor: '#f2f2f2',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => addSkill(item)}
                                />
                            ))}
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            background: '#f2f2f2',
                            border: '1px solid #C5C5C5',
                            borderRadius: '8px',
                            mt: { sm: '16px', xs: '8px' },
                            p: { xs: '8px 12px 20px' }
                        }}
                    >
                        <Typography
                            variant="titleMediumSemiBold"
                            fontSize={{ sm: 16, xs: 14 }}
                            fontWeight={{ sm: 600, xs: 400 }}
                        >
                            Selected skills
                        </Typography>

                        <Box
                            sx={{
                                mt: { xs: '12px' },
                                display: 'flex',
                                alignItems: 'center',
                                gap: { sm: '12px', xs: '8px' },
                                flexWrap: 'wrap'
                            }}
                        >
                            {selectedSkills.map(
                                (item: ISkillType, index: number) => (
                                    <Chip
                                        key={`chip_${index}`}
                                        label={item?.name}
                                        sx={{
                                            fontWeight: { sm: 600, xs: 400 },
                                            fontSize: { sm: '16px', xs: 12 },
                                            color: '#05668D',
                                            background: '#E6F0F4',
                                            border: '1px solid #05668D',
                                            '& svg': {
                                                color: '#494949 !important',
                                                fontSize: {
                                                    sm: 22,
                                                    xs: '18px !important'
                                                }
                                            }
                                        }}
                                        onDelete={() =>
                                            setSelectedSkills(
                                                selectedSkills.filter(
                                                    (x: ISkillType) =>
                                                        x.id !== item?.id
                                                )
                                            )
                                        }
                                        deleteIcon={<Close />}
                                    />
                                )
                            )}
                        </Box>
                    </Box>
                    <Typography
                        variant="titleMediumRegular"
                        color={'#808080'}
                        sx={{
                            float: 'right',
                            fontSize: { sm: 16, xs: 14 }
                        }}
                    >
                        {selectedSkills?.length ?? 0}/10
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    mt: { lg: '16px', xs: '8px' },
                    p: { sm: '20px 32px', xs: '0 16px' },
                    background: { sm: '#fff', xs: 'transparent' }
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px'
                    }}
                >
                    <Button
                        variant="contained"
                        sx={{
                            p: '14px 16px',
                            background: '#f2f2f2',
                            // border: '1px solid #05668D',
                            fontSize: '12px',
                            color: '#808080',
                            display: { sm: 'block', xs: 'none' }
                        }}
                        onClick={closeModal}
                    >
                        Skip for now
                    </Button>
                    <Button
                        variant="contained"
                        sx={{
                            p: '14px 16px',
                            fontSize: '14px'
                        }}
                        onClick={() => {
                            addSkills();
                        }}
                        disabled={selectedSkills.length === 0}
                    >
                        Request review
                    </Button>
                </Box>
            </Box>
        </>
    );
};

export default AchievementSkillsPopup;
