import React from 'react';
import { Box, Typography, Button, IconButton } from '@mui/material';
import { ArrowLeftIcon } from '../../../../assets/icons/ArrowLeftIcon';
import EllipseIcon3 from '../../../../assets/icons/EllipseIcon3';
import { useNavigate } from 'react-router-dom';

const Header = () => {
    const navigate = useNavigate();

    return (
        <Box
            sx={{
                backgroundColor: '#FFFFFF',
                padding: { xs: '16px', sm: '20px' },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: { xs: '12px', sm: '20px', lg: '28px' }
                }}
            >
                <IconButton onClick={() => navigate(-1)}>
                    <ArrowLeftIcon />
                </IconButton>
                <Typography
                    variant="headlineMediumSemiBold"
                    color="#23282B"
                    fontSize={{ xs: '20px', sm: '24px' }}
                >
                    Job Postings
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2.5
                }}
            >
                <Button
                    variant="contained"
                    onClick={() => {
                        navigate('/new-jobpost');
                    }}
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        padding: '15px 20px',
                        width: 'fit-content'
                        // maxWidth: {xs: '95px', sm: '240px'}
                    }}
                >
                    Post new job opening
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        navigate('/new-jobpost');
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        padding: '15px 12px',
                        width: 'fit-content'
                        // maxWidth: {xs: '95px', sm: '240px'}
                    }}
                >
                    Post job
                </Button>
                {/* <EllipseIcon3 /> */}
            </Box>
        </Box>
    );
};

export default Header;
