import React from 'react';

export const Location = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20.6172 8.45C19.5672 3.83 15.5372 1.75 11.9972 1.75C11.9972 1.75 11.9972 1.75 11.9872 1.75C8.45717 1.75 4.41717 3.82 3.36717 8.44C2.19717 13.6 5.35717 17.97 8.21717 20.72C9.27717 21.74 10.6372 22.25 11.9972 22.25C13.3572 22.25 14.7172 21.74 15.7672 20.72C18.6272 17.97 21.7872 13.61 20.6172 8.45ZM11.9972 13.46C10.2572 13.46 8.84717 12.05 8.84717 10.31C8.84717 8.57 10.2572 7.16 11.9972 7.16C13.7372 7.16 15.1472 8.57 15.1472 10.31C15.1472 12.05 13.7372 13.46 11.9972 13.46Z"
                fill="#05668D"
            />
        </svg>
    );
};

export default Location;
