import * as React from 'react';
import { SvgIcon as MuiSvgIcon, SvgIconProps, styled } from '@mui/material';

const SvgIcon = styled(MuiSvgIcon, {
    name: 'JobfactorIcon',
    shouldForwardProp: (prop) => prop !== 'fill'
})<SvgIconProps>(() => ({
    fill: 'currentColor'
}));

SvgIcon.defaultProps = {
    viewBox: '0 0 20 20',
    focusable: 'false',
    'aria-hidden': 'true'
};

const Edit: React.FunctionComponent<SvgIconProps> = (props) => {
    return (
        <SvgIcon {...props}>
            <path d="M17.5 18.3359H2.5C2.15833 18.3359 1.875 18.0526 1.875 17.7109C1.875 17.3693 2.15833 17.0859 2.5 17.0859H17.5C17.8417 17.0859 18.125 17.3693 18.125 17.7109C18.125 18.0526 17.8417 18.3359 17.5 18.3359Z" />
            <path d="M15.8495 2.90005C14.2328 1.28338 12.6495 1.24172 10.9912 2.90005L9.98283 3.90838C9.89949 3.99172 9.86616 4.12505 9.89949 4.24172C10.5328 6.45005 12.2995 8.21672 14.5078 8.85005C14.5412 8.85838 14.5745 8.86672 14.6078 8.86672C14.6995 8.86672 14.7828 8.83338 14.8495 8.76672L15.8495 7.75838C16.6745 6.94172 17.0745 6.15005 17.0745 5.35005C17.0828 4.52505 16.6828 3.72505 15.8495 2.90005Z" />
            <path d="M13.007 9.60573C12.7653 9.48906 12.532 9.3724 12.307 9.23906C12.1236 9.13073 11.9486 9.01406 11.7736 8.88906C11.632 8.7974 11.4653 8.66406 11.307 8.53073C11.2903 8.5224 11.232 8.4724 11.1653 8.40573C10.8903 8.1724 10.582 7.8724 10.307 7.53906C10.282 7.5224 10.2403 7.46406 10.182 7.38906C10.0986 7.28906 9.95697 7.1224 9.83197 6.93073C9.73197 6.80573 9.6153 6.6224 9.50697 6.43906C9.37364 6.21406 9.25697 5.98906 9.1403 5.75573C8.98734 5.42795 8.55713 5.33057 8.30135 5.58635L3.6153 10.2724C3.50697 10.3807 3.40697 10.5891 3.38197 10.7307L2.93197 13.9224C2.84864 14.4891 3.00697 15.0224 3.35697 15.3807C3.65697 15.6724 4.07364 15.8307 4.52364 15.8307C4.62364 15.8307 4.72364 15.8224 4.82364 15.8057L8.02364 15.3557C8.17364 15.3307 8.38197 15.2307 8.48197 15.1224L13.1758 10.4286C13.4264 10.178 13.3322 9.74668 13.007 9.60573Z" />
        </SvgIcon>
    );
};

export default Edit;
