import { Box } from '@mui/material';
import React from 'react'

const NotificationIcon = (props: { isHover: boolean; isSelected: boolean, tab?: boolean }) => {
    const { isHover, isSelected=true, tab } = props;
    return (
        <Box>
            {isHover || isSelected ? (
                <svg
                    width={tab ? '24px' : '40'}
                    height={tab ? '24px' : '40'}
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M30.2851 21.0902L28.5901 18.2702C28.2151 17.6552 27.8851 16.4702 27.8851 15.7502V12.9452C27.8851 7.5002 23.4601 3.0752 18.0301 3.0752C12.5851 3.0902 8.16006 7.5002 8.16006 12.9452V15.7352C8.16006 16.4552 7.83006 17.6402 7.47006 18.2552L5.77506 21.0752C5.13006 22.1702 4.98006 23.4152 5.38506 24.4952C5.79006 25.5902 6.70506 26.4602 7.90506 26.8502C9.52506 27.3902 11.1601 27.7802 12.8251 28.0652C12.9901 28.0952 13.1551 28.1102 13.3201 28.1402C13.5301 28.1702 13.7551 28.2002 13.9801 28.2302C14.3701 28.2902 14.7601 28.3352 15.1651 28.3652C16.1101 28.4552 17.0701 28.5002 18.0301 28.5002C18.9751 28.5002 19.9201 28.4552 20.8501 28.3652C21.1951 28.3352 21.5401 28.3052 21.8701 28.2602C22.1401 28.2302 22.4101 28.2002 22.6801 28.1552C22.8451 28.1402 23.0101 28.1102 23.1751 28.0802C24.8551 27.8102 26.5201 27.3902 28.1401 26.8502C29.2951 26.4602 30.1801 25.5902 30.6001 24.4802C31.0201 23.3552 30.9001 22.1252 30.2851 21.0902ZM19.1251 15.0002C19.1251 15.6302 18.6151 16.1402 17.9851 16.1402C17.3551 16.1402 16.8451 15.6302 16.8451 15.0002V10.3502C16.8451 9.7202 17.3551 9.2102 17.9851 9.2102C18.6151 9.2102 19.1251 9.7202 19.1251 10.3502V15.0002Z"
                        fill="#05668D"
                    />
                    <path
                        d="M22.245 30.015C21.615 31.755 19.95 33 18 33C16.815 33 15.645 32.52 14.82 31.665C14.34 31.215 13.98 30.615 13.77 30C13.965 30.03 14.16 30.045 14.37 30.075C14.715 30.12 15.075 30.165 15.435 30.195C16.29 30.27 17.16 30.315 18.03 30.315C18.885 30.315 19.74 30.27 20.58 30.195C20.895 30.165 21.21 30.15 21.51 30.105C21.75 30.075 21.99 30.045 22.245 30.015Z"
                        fill="#AAAAAA"
                    />
                </svg>
            ) : !tab ? (
                <svg
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path />
                    <path
                        d="M22.245 30.015C21.615 31.755 19.95 33 18 33C16.815 33 15.645 32.52 14.82 31.665C14.34 31.215 13.98 30.615 13.77 30C13.965 30.03 14.16 30.045 14.37 30.075C14.715 30.12 15.075 30.165 15.435 30.195C16.29 30.27 17.16 30.315 18.03 30.315C18.885 30.315 19.74 30.27 20.58 30.195C20.895 30.165 21.21 30.15 21.51 30.105C21.75 30.075 21.99 30.045 22.245 30.015Z"
                        fill="#AAAAAA"
                    />
                </svg>
            ) : (
                <svg
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M10.3917 17.6086C8.45005 17.6086 6.50839 17.3003 4.66672 16.6836C3.96672 16.4419 3.43339 15.9503 3.20005 15.3086C2.95839 14.6669 3.04172 13.9586 3.42505 13.3253L4.38339 11.7336C4.58339 11.4003 4.76672 10.7336 4.76672 10.3419V7.93359C4.76672 4.83359 7.29172 2.30859 10.3917 2.30859C13.4917 2.30859 16.0167 4.83359 16.0167 7.93359V10.3419C16.0167 10.7253 16.2001 11.4003 16.4001 11.7419L17.3501 13.3253C17.7084 13.9253 17.7751 14.6503 17.5334 15.3086C17.2917 15.9669 16.7667 16.4669 16.1084 16.6836C14.2751 17.3003 12.3334 17.6086 10.3917 17.6086ZM10.3917 3.55859C7.98339 3.55859 6.01672 5.51693 6.01672 7.93359V10.3419C6.01672 10.9503 5.76672 11.8503 5.45839 12.3753L4.50005 13.9669C4.31672 14.2753 4.26672 14.6003 4.37505 14.8753C4.47505 15.1586 4.72505 15.3753 5.06672 15.4919C8.55005 16.6586 12.2417 16.6586 15.7251 15.4919C16.0251 15.3919 16.2584 15.1669 16.3667 14.8669C16.4751 14.5669 16.4501 14.2419 16.2834 13.9669L15.3251 12.3753C15.0084 11.8336 14.7667 10.9419 14.7667 10.3336V7.93359C14.7667 5.51693 12.8084 3.55859 10.3917 3.55859Z"
                        fill="#808080"
                    />
                    <path
                        d="M11.9416 3.78242C11.8833 3.78242 11.8249 3.77409 11.7666 3.75742C11.5249 3.69076 11.2916 3.64076 11.0666 3.60742C10.3583 3.51576 9.67493 3.56576 9.03327 3.75742C8.79993 3.83242 8.54993 3.75742 8.3916 3.58242C8.23327 3.40742 8.18327 3.15742 8.27493 2.93242C8.6166 2.05742 9.44993 1.48242 10.3999 1.48242C11.3499 1.48242 12.1833 2.04909 12.5249 2.93242C12.6083 3.15742 12.5666 3.40742 12.4083 3.58242C12.2833 3.71576 12.1083 3.78242 11.9416 3.78242Z"
                        fill="#808080"
                    />
                    <path
                        d="M10.3916 19.5078C9.5666 19.5078 8.7666 19.1745 8.18327 18.5911C7.59993 18.0078 7.2666 17.2078 7.2666 16.3828H8.5166C8.5166 16.8745 8.7166 17.3578 9.0666 17.7078C9.4166 18.0578 9.89994 18.2578 10.3916 18.2578C11.4249 18.2578 12.2666 17.4161 12.2666 16.3828H13.5166C13.5166 18.1078 12.1166 19.5078 10.3916 19.5078Z"
                        fill="#808080"
                    />
                </svg>
            )}
        </Box>
    );
};

export default NotificationIcon
