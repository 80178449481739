import React from 'react';

const ProfileAdd = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                d="M12.3346 13H9.66797"
                stroke="#05668D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11 14.3346V11.668"
                stroke="#05668D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.10575 7.24536C8.03909 7.2387 7.95909 7.2387 7.88575 7.24536C6.29909 7.19203 5.03909 5.89203 5.03909 4.29203C5.03242 2.6587 6.35909 1.33203 7.99242 1.33203C9.62575 1.33203 10.9524 2.6587 10.9524 4.29203C10.9524 5.89203 9.68575 7.19203 8.10575 7.24536Z"
                stroke="#05668D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.99141 14.5385C6.77807 14.5385 5.57141 14.2318 4.65141 13.6185C3.03807 12.5385 3.03807 10.7785 4.65141 9.70516C6.48474 8.47849 9.49141 8.47849 11.3247 9.70516"
                stroke="#05668D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default ProfileAdd;
