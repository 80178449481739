import React, { useEffect, useState } from 'react';
import UserActions from '../components/UserActions';
import UserDetails from '../components/UserDetails';
import TableWrapper from '../components/TableWrapper';
import SectionHeader from '../../common/SectionHeader';
import {
    Box,
    Button,
    Grid,
    Paper,
    TableCell,
    TableRow,
    Typography,
    useTheme,
    useMediaQuery,
    CircularProgress
} from '@mui/material';
import DialogBox from '../components/DialogBox';
import PageHeader from '../../common/PageHeader';
import { useGetProfessionalReviewRequests } from '../../../utils/hooks/api/reviews/useGetProfessionalReviewRequests';
import { useAuth } from '../../../utils/context/AuthContext';
import { useDeleteReviewRequest } from '../../../utils/hooks/api/reviews/useDeleteReviewRequest';
import SnackAlert, { SnackAlertType } from '../../../components/Snackbar';
import axios from 'axios';
import { useQueryClient } from 'react-query';
import { QueryKeys } from '../../../utils/hooks/api/QueryKey';
import AchievementBox, { IReviewType } from '../components/AchievementBox';
import ReviewPost from '../components/ReviewPost';
import { useNavigate } from 'react-router-dom';
import ReviewLoader from '../../../components/LoadingState/ReviewLoader';
import EmptyState from '../../../components/EmptyState/EmptyState';
import { Responsiveness } from '../../../theme/overrides';
import MobileNav from '../../../components/MobileNav/MobileNav';

export interface IRequestType {
    professionalRating: {
        professionalProfile: {
            id: string;
        };
    };
    professionalProfile: {
        id: string;
    };
}

const SentRequests = () => {
    const { user } = useAuth();
    const {isMobile, isTablet} = Responsiveness()
    const [type, setType] = useState<SnackAlertType>('info');
    const { data, isFetching } = useGetProfessionalReviewRequests(
        user?.professionalProfile?.id ?? ''
    );
    const [achievement, setAchievement] = useState<IReviewType>();
    const [showAlert, setShowAlert] = useState(false);
    const deleteRequest = useDeleteReviewRequest();
    const [requests, setRequests] = useState([]);
    const [message, setMessage] = useState('');
    const queryClient = useQueryClient();
    const [page, setPage] = useState(0);
    const rowsPerPage = 8;
    const [showPostDetails, setShowPostDetails] = useState(false);
    const navigate = useNavigate();
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    console.log(requests)

    const routes = [
        {
            label: 'Suggested Reviews',
            route: '/reviews'
        },
        {
            label: 'Sent Requests',
            route: '/reviews/sent-requests'
        },
        {
            label: 'Received Requests',
            route: '/reviews/received-requests'
        },
        {
            label: 'Company Ratings',
            route: '/reviews/company-ratings'
        }
    ];

    const hidePost = (data: IReviewType) => {
        setShowPostDetails(true);
        setAchievement(undefined);
        setAchievement(data);
    };
    const handleChangePage = (page: number) => {
        setPage(page - 1);
    };

    const withDrawRequest = (id: string) => {
        deleteRequest.mutate(id, {
            onSuccess: (res) => {
                console.log('TEST', res);
                setMessage('Success');
                setType('success');
                setShowAlert(true);
                queryClient.invalidateQueries(
                    QueryKeys.ProfessionalReviewRequests
                );
            },
            onError: (err) => {
                console.log('ER', err);
                if (axios.isAxiosError(err)) {
                    setMessage(
                        err.response?.data?.message ??
                            'Error occured please try again!'
                    );
                    setType('error');
                    setShowAlert(true);
                }
            }
        });
    };
    useEffect(() => {
        if (data && !isFetching) {
            setRequests(data);
        }
    }, [data, isFetching]);

    return (
        <Box mt={{xs: '21px', sm: 0}}>
            {isMobile ? <MobileNav list={routes} /> : null}
            <PageHeader
                pageTitle={'Sent Requests'}
                hideAction={true}
                handleBack={() =>
                    showPostDetails
                        ? setShowPostDetails(false)
                        : navigate('/reviews')
                }
            />
            {isMobile ? null : isTablet ? <MobileNav list={routes} /> : null}
            {showPostDetails ? (
                <Box
                    sx={{
                        mt: isMobile ? '8px' : '36px'
                    }}
                >
                    <ReviewPost achievement={achievement} />
                </Box>
            ) : (
                // <Paper sx={{ mt: '36px' }}>
                    <Box
                        mt={isMobile ? 0 : '16px'}
                    // px={isMobile ? '16px' : isTablet ? '20px' : '40px'}
                    sx={{ minHeight: '559px' }}
                >
                    {/* <SectionHeader
                            header={'People you requested reviews from'}
                            subHeader={
                                'See all those you requested to have them review your profile'
                            }
                        /> */}
                    <Grid container>
                        <TableWrapper
                            handleChangePage={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            data={requests}
                        >
                            {isFetching ? (
                                <ReviewLoader />
                            ) : requests?.length > 0 ? (
                                requests
                                    ?.slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    ?.map((review: any, index: number) => (
                                        <TableRow key={`user_${index}`}>
                                            <TableCell
                                                sx={{
                                                    pt: isMobile ? '16px' : '20px'
                                                }}
                                            >
                                                <AchievementBox
                                                    hidePost={hidePost}
                                                    review={review}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))
                            ) : (
                                <EmptyState message="You have not sent any request for reviews yet." />
                            )}
                        </TableWrapper>
                    </Grid>
                </Box>
                // </Paper>
            )}
            <SnackAlert
                open={showAlert}
                handleClose={() => setShowAlert(false)}
                message={message}
                type={type}
            />
        </Box>
    );
};

export default SentRequests;
