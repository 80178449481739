import { Box, Typography } from '@mui/material';
import React from 'react';

interface BadgeProps {
    count: number;
}

export default function UnreadCountBadge({ count }: BadgeProps) {
    return count > 0 ? (
        <Box
            sx={{
                width: '26px',
                height: '26px',
                p: '4px', 
                borderRadius: '50%',
                backgroundColor: '#49B6FF',
                textAlign: 'center',
            }}
        >
            <Typography sx={{ fontSize: '14px', color: '#FFF', m: 0 }}>
                {count}
            </Typography>
        </Box>
    ) : (
        <></>
    );
}
