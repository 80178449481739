import React, { useEffect, useState } from 'react';
import UserActions from '../components/UserActions';
import UserDetails from '../components/UserDetails';
import TableWrapper from '../components/TableWrapper';
import SectionHeader from '../../common/SectionHeader';
import ExperienceLevel from '../components/ExperienceLevel';
import {
    Box,
    Button,
    Grid,
    Paper,
    TableCell,
    TableRow,
    Typography
} from '@mui/material';
import DialogBox from '../components/DialogBox';
import PageHeader from '../../common/PageHeader';
import { IConstantUserType } from '../types';
import { useGetProfessionalRecievedRequests } from '../../../utils/hooks/api/reviews/useGetProfessionalRecievedRequests';
import { IRequestType } from '../SentRequests';
import moment from 'moment';
import { useAuth } from '../../../utils/context/AuthContext';
import { useUpdateReviewRequest } from '../../../utils/hooks/api/reviews/useUpdateReviewRequest';
import { useQueryClient } from 'react-query';
import axios from 'axios';
import SnackAlert, { SnackAlertType } from '../../../components/Snackbar';
import { QueryKeys } from '../../../utils/hooks/api/QueryKey';
import AchievementBox, { IReviewType } from '../components/AchievementBox';
import ReviewPost from '../components/ReviewPost';
import { useNavigate } from 'react-router-dom';
import ReviewLoader from '../../../components/LoadingState/ReviewLoader';
import EmptyState from '../../../components/EmptyState/EmptyState';
import axiosInstance from '../../../utils/hooks/api/axiosConfig';
// import { PendingFeedBack } from '../../../utils/emails/email';
// import { generateHtml, generateSubject } from '../../../utils/emails/emailtemplates';
import MobileNav from '../../../components/MobileNav/MobileNav';
import { Responsiveness } from '../../../theme/overrides';

const ReceivedRequests = () => {
    const { user } = useAuth();
    const { isMobile, isTablet } = Responsiveness()
    const [id, setId] = useState('');
    const [type, setType] = useState<SnackAlertType>('info');
    const { data, isFetching } = useGetProfessionalRecievedRequests(
        user?.professionalProfile?.id ?? ''
    );
    const [showPostDetails, setShowPostDetails] = useState(false);
    const [achievement, setAchievement] = useState<IReviewType>();
    const [showModal, setShowModal] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const updateRequest = useUpdateReviewRequest();
    const [requests, setRequests] = useState([]);
    const [message, setMessage] = useState('');
    const queryClient = useQueryClient();
    const [page, setPage] = useState(0);
    const navigate = useNavigate();
    const rowsPerPage = 8;

        const routes = [
            {
                label: 'Suggested Reviews',
                route: '/reviews'
            },
            {
                label: 'Sent Requests',
                route: '/reviews/sent-requests'
            },
            {
                label: 'Received Requests',
                route: '/reviews/received-requests'
            },
            {
                label: 'Company Ratings',
                route: '/reviews/company-ratings'
            }
        ];

    const handleChangePage = (page: number) => {
        setPage(page - 1);
    };

    const hidePost = (data: IReviewType) => {
        setShowPostDetails(true);
        setAchievement(data);
    };

    const declineRequest = (id: string) => {
        updateRequest.mutate(
            { id, status: 'DECLINED' },
            {
                onSuccess: (res) => {
                    console.log('first', res);
                    setMessage('Success');
                    setType('success');
                    setShowAlert(true);
                    queryClient.invalidateQueries(
                        QueryKeys.ProfessionalReviewRequests
                    );
                },
                onError: (err) => {
                    console.log('ERR', err);
                    if (axios.isAxiosError(err)) {
                        setMessage(
                            err.response?.data?.message ??
                                'Error occured please try again!'
                        );
                        setType('error');
                        setShowAlert(true);
                    }
                }
            }
        );
    };

    const pendingFeedbackReqNotification = async() =>{
        if(requests.length > 0){
            try {
                if (!user?.professionalProfile) {
                    return;
                }
                const { emailAddress, firstName, lastName } = user.professionalProfile;
    
                if (!emailAddress || !firstName || !lastName) {
                    return;
                }
                await axiosInstance.post("/api/email/send-pendingfeedbackreq-notification", {
                    email: emailAddress,
                    // subject: generateSubject(PendingFeedBack.subject),
                    firstName: firstName,
                    lastName: lastName,
                    // message: generateHtml(PendingFeedBack.html),
                    link: "reviews/received-requests",
                });
            } catch (error) {
                console.error('Error sending notification:', error);
            }

        }

    }

    useEffect(() => {
      pendingFeedbackReqNotification()
    }, [user, requests])

    useEffect(() => {
        if (data && !isFetching) {
            setRequests(data);
        }
    }, [data, isFetching]);

    console.log("request=>", requests);

    return (
        <Box mt={{ xs: '21px', sm: 0 }}>
            {isMobile ? <MobileNav list={routes} /> : null}
            <PageHeader
                pageTitle={'Received Requests'}
                hideAction={true}
                handleBack={() =>
                    showPostDetails
                        ? setShowPostDetails(false)
                        : navigate('/reviews')
                }
            />
            {isMobile ? null : isTablet ? <MobileNav list={routes} /> : null}
            {showPostDetails ? (
                <Box
                    sx={{
                        mt: {xs: 1, sm: '36px'}
                    }}
                >
                    <ReviewPost achievement={achievement} />
                </Box>
            ) : (
                // <Paper sx={{ mt: '36px' }}>
                <Box mt={isMobile ? 0 : '16px'}>
                    <SectionHeader
                        header={'Requested reviews from people you know'}
                        subHeader={
                            'Click the review button below to share your thoughts on their achievements'
                        }
                    />
                    {/* <Grid container> */}
                    <TableWrapper
                        handleChangePage={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        data={requests}
                    >
                        {isFetching ? (
                            <ReviewLoader />
                        ) : requests.length > 0 ? (
                            requests
                                ?.slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                ?.map((user: any, index: number) => (
                                    <TableRow key={`user_${index}`}>
                                        <TableCell
                                            sx={{
                                                padding: isMobile
                                                    ? '16px'
                                                    : isTablet
                                                    ? '25px 20px'
                                                    : '32px 40px',
                                                paddingBottom: '25px'
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent:
                                                        'space-between'
                                                    // gap: '20px',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        width: isTablet
                                                            ? '100%'
                                                            : '70%'
                                                    }}
                                                    position="relative"
                                                >
                                                    <AchievementBox
                                                        hidePost={hidePost}
                                                        review={user}
                                                        page="received"
                                                    />
                                                    {!isTablet ? null : (
                                                        <Box
                                                            display="flex"
                                                            gap="8px"
                                                            alignItems="center"
                                                            // width="100%"
                                                            ml={
                                                                isMobile
                                                                    ? '68px'
                                                                    : '76px'
                                                            }
                                                            mt="8px"
                                                        >
                                                            <Button
                                                                sx={{
                                                                    borderRadius:
                                                                        '8px',
                                                                    padding:
                                                                        '12px 16px',
                                                                    border: '1px solid #05668D',
                                                                    background:
                                                                        '#05668D',
                                                                    fontSize:
                                                                        '14px',
                                                                    fontWeight:
                                                                        '600',
                                                                    textTransform:
                                                                        'capitalize',
                                                                    boxShadow:
                                                                        'none',
                                                                    width: isMobile
                                                                        ? '100%'
                                                                        : 'auto',
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                    color: '#FFFFFF',
                                                                    minWidth:
                                                                        'fit-content',
                                                                    textDecoration:
                                                                        'none',
                                                                    ':hover': {
                                                                        color: '#FFFFFF',
                                                                        textDecoration:
                                                                            'none',
                                                                        background:
                                                                            '#05668D'
                                                                    }
                                                                }}
                                                                onClick={() =>
                                                                    navigate(
                                                                        `/reviews/received-requests/${user?.skills?.id}?profileId=${user?.professionalRating.professionalProfile.id}&requestId=${user?.id}`,
                                                                        {
                                                                            state: {
                                                                                postId: user
                                                                                    ?.post
                                                                                    ?.id,
                                                                                user: user
                                                                            }
                                                                        }
                                                                    )
                                                                }
                                                            >
                                                                Review
                                                            </Button>
                                                            <Button
                                                                variant="outlined"
                                                                sx={{
                                                                    borderRadius:
                                                                        '8px',
                                                                    padding:
                                                                        '12px 16px',
                                                                    fontSize:
                                                                        '14px',
                                                                    fontWeight:
                                                                        '600',
                                                                    textTransform:
                                                                        'capitalize',
                                                                    boxShadow:
                                                                        'none',
                                                                    color: '#05668D',
                                                                    width: isMobile
                                                                        ? '100%'
                                                                        : 'auto',
                                                                    minWidth:
                                                                        'fit-content'
                                                                }}
                                                                onClick={() =>
                                                                    declineRequest(
                                                                        user?.id
                                                                    )
                                                                }
                                                            >
                                                                Decline
                                                            </Button>
                                                        </Box>
                                                    )}
                                                </Box>

                                                {isTablet ? null : (
                                                    <UserActions>
                                                        <Button
                                                            sx={{
                                                                borderRadius:
                                                                    '8px',
                                                                padding:
                                                                    '12px 16px',
                                                                border: '1px solid #05668D',
                                                                background:
                                                                    '#05668D',
                                                                fontSize:
                                                                    '14px',
                                                                fontWeight:
                                                                    '600',
                                                                textTransform:
                                                                    'capitalize',
                                                                boxShadow:
                                                                    'none',
                                                                width: 'auto',
                                                                whiteSpace:
                                                                    'nowrap',
                                                                color: '#FFFFFF',
                                                                minWidth:
                                                                    'fit-content',
                                                                textDecoration:
                                                                    'none',
                                                                ':hover': {
                                                                    color: '#FFFFFF',
                                                                    textDecoration:
                                                                        'none',
                                                                    background:
                                                                        '#05668D'
                                                                }
                                                            }}
                                                            onClick={() =>
                                                                navigate(
                                                                    `/reviews/received-requests/${user?.skills?.id}?profileId=${user?.professionalRating.professionalProfile.id}&requestId=${user?.id}`,
                                                                    {
                                                                        state: {
                                                                            postId: user
                                                                                ?.post
                                                                                ?.id
                                                                        }
                                                                    }
                                                                )
                                                            }
                                                        >
                                                            Review
                                                        </Button>
                                                        <Button
                                                            variant="outlined"
                                                            sx={{
                                                                borderRadius:
                                                                    '8px',
                                                                padding:
                                                                    '12px 16px',
                                                                fontSize:
                                                                    '14px',
                                                                fontWeight:
                                                                    '600',
                                                                textTransform:
                                                                    'capitalize',
                                                                boxShadow:
                                                                    'none',
                                                                color: '#05668D',
                                                                width: 'auto',
                                                                minWidth:
                                                                    'fit-content'
                                                            }}
                                                            onClick={() =>
                                                                declineRequest(
                                                                    user?.id
                                                                )
                                                            }
                                                        >
                                                            Decline request
                                                        </Button>
                                                    </UserActions>
                                                )}
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))
                        ) : (
                            <EmptyState message="You have not received any request for reviews yet." />
                        )}
                    </TableWrapper>
                    {/* </Grid> */}
                </Box>
                // </Paper>
            )}
            <SnackAlert
                open={showAlert}
                handleClose={() => setShowAlert(false)}
                message={message}
                type={type}
            />
        </Box>
    );
};

export default ReceivedRequests;
