import {
    Dialog,
    DialogTitle,
    Button,
    Grid,
    Box,
    Typography,
    DialogContent,
    InputBase,
    Chip,
    TextField,
    FormHelperText
} from '@mui/material';
import { useAuth } from '../../utils/context/AuthContext';
import React, { useState } from 'react';
import LinkIcon from '../../assets/icons/LinkIcon';
import EmailFormIcon from '../../assets/icons/EmailFormIcon';
import { isEmailValid } from '../../utils/Helper/validators';
import axiosInstance from '../../utils/hooks/api/axiosConfig';
import { logger } from '../../utils/Helper/logger';
import { signal } from '@preact/signals-react';
import SnackAlert, { SnackAlertType } from '../Snackbar';
import { set } from 'lodash';

export const inviteDialogSignal = signal(false);

export default function InviteDialog() {
    /** Referral link */
    const { user } = useAuth();
    const link = `${window.location.origin}/create-account?referrer=${user?.id}`;
    const [copied, setCopied] = React.useState(false);
    const [inFocus, setInFocus] = React.useState(false);
    const copyToClipboard = () => {
        navigator.clipboard.writeText(link);
        setCopied(true);
    };
    /** Referral email */
    const MAX_EMAILS = 10;
    const referrerName =
        user?.professionalProfile?.firstName +
            ' ' +
            user?.professionalProfile?.lastName ??
        user?.primaryCompanyProfile?.companyName;
    const [emails, setEmails] = useState<string[]>([]);
    const [invalidInput, setInvalidInput] = useState<boolean>(false);
    const handleAddEmail = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key !== 'Enter') return;
        const email = event.currentTarget.value.trim().toLowerCase();
        if (!isEmailValid(email)) return setInvalidInput(true);
        if (emails.length >= MAX_EMAILS) return setInvalidInput(true);
        event.currentTarget.value = '';
        if (emails.includes(email)) return;
        setEmails([...emails, email]);
    };
    const handleRemoveEmail = (email: string) => {
        setEmails(emails.filter((e) => e !== email));
    };
    const handleSendInvite = async () => {
        logger.log(`Sending invites to ${emails} from ${referrerName}`);
        await axiosInstance.post('/api/email/send-invite', {
            referrerName: referrerName,
            referrerId: user?.id,
            emails: emails
        });
        handleClose();
        setSnackAlertProps({
            ...snackAlertProps,
            open: true,
            message: 'Invite sent successfully'
        });
    };
    const handleClose = () => {
        inviteDialogSignal.value = false;
        setTimeout(() => {
            setCopied(false);
            setEmails([]);
            setInvalidInput(false);
        }, 500);
    };
    const [snackAlertProps, setSnackAlertProps] = useState({
        type: 'success' as SnackAlertType,
        open: false,
        message: ''
    });

    return (
        <React.Fragment>
            <SnackAlert
                {...snackAlertProps}
                handleClose={() => {
                    setSnackAlertProps({ ...snackAlertProps, open: false });
                }}
            />
            <Dialog
                open={inviteDialogSignal.value}
                onClose={handleClose}
                fullWidth
                maxWidth="sm"
                
            >
                <DialogTitle sx={{ pb: '0px' }}>
                    <Typography variant="bodyLargeBold" fontSize="16px" fontWeight={700} color='#23282b'>
                        Invite others to join your network
                    </Typography>
                    <Typography sx={{ opacity: 0.8 }}>
                        Grow your community. Invite colleagues and friends to
                        join your Jobfactor network by sending them the link, or
                        have us send them an invite to their email. You may
                        enter up to 10 email addresses at a time.
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid container rowSpacing={4} sx={{ m: 'auto' }}>
                        <Grid item container wrap="wrap" columnSpacing={2}>
                            <Grid item xs={12} sm={9}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        border: '2px solid #d6d6d6',
                                        borderRadius: '6px',
                                        bgcolor: '#fcfbf8',
                                        padding: '8px 10px',
                                        my: '4px'
                                    }}
                                >
                                    <LinkIcon size={20} />
                                    <Typography
                                        sx={{
                                            userSelect: 'all',
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            fontSize: '14px',
                                            ml: '4px'
                                        }}
                                    >
                                        {link}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Button
                                    variant="contained"
                                    onClick={copyToClipboard}
                                    sx={{
                                        opacity: copied ? 0.85 : 1,
                                        fontSize: '14px',
                                        height: '100%'
                                    }}
                                >
                                    {copied ? 'Invite Copied' : 'Copy Invite'}
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            columnSpacing={2}
                            alignItems="center"
                        >
                            <Grid item xs={12} sm={9}>
                                {invalidInput ? (
                                    <FormHelperText
                                        sx={{
                                            position: 'absolute',
                                            my: '-20px',
                                            ml: '16px',
                                            color: '#ff00008f'
                                        }}
                                    >
                                        This email address is not valid
                                    </FormHelperText>
                                ) : !inFocus ? null : (
                                    <FormHelperText
                                        sx={{
                                            position: 'absolute',
                                            my: '-20px',
                                            ml: '16px'
                                        }}
                                    >
                                        Press enter to add email to invite list
                                    </FormHelperText>
                                )}
                                <InputBase
                                    onKeyDown={handleAddEmail}
                                    onChange={() => setInvalidInput(false)}
                                    onFocus={() => setInFocus(true)}
                                    onBlur={() => setInFocus(false)}
                                    placeholder={`Type an email address and press Enter`}
                                    startAdornment={<EmailFormIcon />}
                                    sx={{
                                        bgcolor: '#f7f2e683',
                                        py: '8px',
                                        px: '16px',
                                        ml: '2px',
                                        width: '100%',
                                        height: '100%',
                                        borderRadius: '4px',
                                        outline: invalidInput
                                            ? '1px solid #ff00008f'
                                            : 'none'
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3} height="100%">
                                <Button
                                    variant="contained"
                                    disabled={emails.length === 0}
                                    onClick={handleSendInvite}
                                    sx={{
                                        fontSize: '14px',
                                        // height: '100%',
                                        '&.Mui-disabled': {
                                            background: '#14658bc8',
                                            color: '#DDD'
                                        }
                                    }}
                                >
                                    {'Send Invite'}
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            direction="row"
                            columnSpacing={1}
                            rowSpacing={1}
                            alignItems="center"
                            sx={{ py: '16px !important' }}
                        >
                            {emails.map((email, index) => (
                                <Grid item key={`email_chip_${index}`}>
                                    <Chip
                                        label={email}
                                        onDelete={() =>
                                            handleRemoveEmail(email)
                                        }
                                        sx={{
                                            bgcolor: '#dff1f3',
                                            fontSize: '14px',
                                            outline: '1px solid lightgray'
                                        }}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}
