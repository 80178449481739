import React from 'react'

const TickCircle = () => {
  return (
      <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
          <path
              d="M14.0007 2.33325C7.57232 2.33325 2.33398 7.57159 2.33398 13.9999C2.33398 20.4283 7.57232 25.6666 14.0007 25.6666C20.429 25.6666 25.6673 20.4283 25.6673 13.9999C25.6673 7.57159 20.429 2.33325 14.0007 2.33325ZM19.5773 11.3166L12.9623 17.9316C12.799 18.0949 12.5773 18.1883 12.344 18.1883C12.1107 18.1883 11.889 18.0949 11.7257 17.9316L8.42398 14.6299C8.08565 14.2916 8.08565 13.7316 8.42398 13.3933C8.76232 13.0549 9.32232 13.0549 9.66065 13.3933L12.344 16.0766L18.3407 10.0799C18.679 9.74159 19.239 9.74159 19.5773 10.0799C19.9157 10.4183 19.9157 10.9666 19.5773 11.3166Z"
              fill="#49B6FF"
          />
      </svg>
  );
}

export default TickCircle
