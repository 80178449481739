import React from 'react';

export const Birthday = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20.8894 13V14H20.0194C19.2794 14 18.6794 14.6 18.6794 15.35V15.65C18.6794 16.4 18.0794 17 17.3294 17C16.5894 17 15.9894 16.4 15.9894 15.65V15.35C15.9894 14.6 15.3794 14 14.6394 14C13.8994 14 13.2994 14.6 13.2994 15.35V15.65C13.2994 16.4 12.6894 17 11.9494 17C11.2094 17 10.5994 16.4 10.5994 15.65V15.35C10.5994 14.6 9.99938 14 9.25938 14C8.51938 14 7.90938 14.6 7.90938 15.35V15.65C7.90938 16.4 7.30938 17 6.56938 17C5.81938 17 5.21937 16.4 5.21937 15.65V15.33C5.21937 14.59 4.62938 13.99 3.89938 13.98H3.10938V13C3.10938 11.62 4.14938 10.45 5.55938 10.11C5.83938 10.04 6.12938 10 6.43938 10H17.5594C17.8694 10 18.1594 10.04 18.4394 10.11C19.8494 10.45 20.8894 11.62 20.8894 13Z"
                fill="#05668D"
            />
            <path
                d="M18.4425 7.17V8.58C18.1525 8.52 17.8625 8.5 17.5625 8.5H6.4425C6.1425 8.5 5.8525 8.53 5.5625 8.59V7.17C5.5625 5.97 6.6425 5 7.9825 5H16.0225C17.3625 5 18.4425 5.97 18.4425 7.17Z"
                fill="#05668D"
            />
            <path
                d="M8.75 3.54844V5.00844H7.98C7.72 5.00844 7.48 5.03844 7.25 5.09844V3.54844C7.25 3.19844 7.59 2.89844 8 2.89844C8.41 2.89844 8.75 3.19844 8.75 3.54844Z"
                fill="#05668D"
            />
            <path
                d="M16.75 3.32812V5.09812C16.52 5.02813 16.28 4.99813 16.02 4.99813H15.25V3.32812C15.25 2.91813 15.59 2.57812 16 2.57812C16.41 2.57812 16.75 2.91813 16.75 3.32812Z"
                fill="#05668D"
            />
            <path
                d="M12.75 2.82V5H11.25V2.82C11.25 2.37 11.59 2 12 2C12.41 2 12.75 2.37 12.75 2.82Z"
                fill="#05668D"
            />
            <path
                d="M22 21.2466C22 21.6566 21.66 21.9966 21.25 21.9966H2.75C2.34 21.9966 2 21.6566 2 21.2466C2 20.8366 2.34 20.4966 2.75 20.4966H3.11V15.4766H3.72V15.5466C3.72 16.8866 4.6 18.1266 5.91 18.4166C6.93 18.6566 7.9 18.3266 8.56 17.6766C8.94 17.2966 9.56 17.2866 9.94 17.6666C10.46 18.1766 11.17 18.4966 11.95 18.4966C12.73 18.4966 13.44 18.1866 13.96 17.6666C14.34 17.2966 14.95 17.2966 15.34 17.6766C15.99 18.3266 16.96 18.6566 17.99 18.4166C19.3 18.1266 20.18 16.8866 20.18 15.5466V15.4966H20.89V20.4966H21.25C21.66 20.4966 22 20.8366 22 21.2466Z"
                fill="#05668D"
            />
        </svg>
    );
};

export default Birthday;
