function VerifiedIcon() {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.9974 1.66797C5.40573 1.66797 1.66406 5.40964 1.66406 10.0013C1.66406 14.593 5.40573 18.3346 9.9974 18.3346C14.5891 18.3346 18.3307 14.593 18.3307 10.0013C18.3307 5.40964 14.5891 1.66797 9.9974 1.66797ZM13.9807 8.08464L9.25573 12.8096C9.13906 12.9263 8.98073 12.993 8.81406 12.993C8.6474 12.993 8.48906 12.9263 8.3724 12.8096L6.01406 10.4513C5.7724 10.2096 5.7724 9.80964 6.01406 9.56797C6.25573 9.3263 6.65573 9.3263 6.8974 9.56797L8.81406 11.4846L13.0974 7.2013C13.3391 6.95964 13.7391 6.95964 13.9807 7.2013C14.2224 7.44297 14.2224 7.83464 13.9807 8.08464Z"
                fill="#49B6FF"
            />
        </svg>
    );
}

export default VerifiedIcon;
