import React, { useState } from 'react';
import {
    Box,
    Grid,
    Button,
    IconButton,
    Typography,
    ButtonGroup
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftIcon } from '../../../assets/icons/ArrowLeftIcon';
import ScoreCard from '../../../components/ScoreCard.tsx';
import ExperienceLevelSkills from '../components/ExperienceLevelSkills';
import ExperienceLevel from '../../Reviews/components/ExperienceLevel';
import UpdateSkills from '../components/Modals/UpdateSkills';
import { useAuth } from '../../../utils/context/AuthContext';
import { experienceLevels } from '../../Reviews/constants';
import { useGetProfessionalRating } from '../../../utils/hooks/api/professional-ratings/useGetProfessionalRating';
import { useGetProfessionalProfileScore } from '../../../utils/hooks/api/professional-profile/useGetProfessionalProfileScore';
import ConnectionSkills from '../../Connections/components/ConnectionSkillsTab';
import SkillsLoader from '../../../components/LoadingState/SkillsLoader';
import { Responsiveness } from '../../../theme/overrides';
import MobileNav from '../../../components/MobileNav/MobileNav';
import { logger } from '../../../utils/Helper/logger';

interface ISkillType {
    level: string;
    name: string;
    rating: number;
    type: string;
    witness: boolean;
}

const SkillsPage = () => {
    const { user } = useAuth();
    const { isTablet, isMobile } = Responsiveness()
    const [currentTab, setCurrentTab] = useState('Skills');
    const [showModal, setShowModal] = useState<boolean>(false);
    const { data: score } = useGetProfessionalProfileScore(
        user?.professionalProfile?.id!
    );
    const navigate = useNavigate();


    const { data: unratedSkills, isFetching } = useGetProfessionalRating(
        user?.professionalProfile?.id ?? ''
    );

     const tabs = ['Skills', 'Skill assessment', 'Personality test'];

    const routes = [
        {
            label: 'About me',
            route: '/users'
        },
        {
            label: 'Activities',
            route: '/activities'
        },
        {
            label: 'Jobfactor Score',
            route: '/jobfactor-score'
        },
        {
            label: 'Skills',
            route: '/skills'
        }
    ];

    return (
        <>
            <Box
                sx={{ mt: { xs: 0, sm: '-40px' }, width: '100%' }}
                pr={{ xs: 0, sm: '20px', lg: '40px' }}
                pb="40px"
            >
                {isMobile ? <MobileNav list={routes} /> : null}
                <Box
                    sx={{
                        mt: { xs: '8px', sm: '16px' },
                        // background: '#FFFFFF',
                        // pr: isMobile ? 0 : '40px',
                        pl: 0,
                        pb: { xs: 1, sm: '24px' },
                        pt: { xs: 1, sm: '24px' }
                    }}
                >
                    <Box
                        sx={{
                            display: { xs: 'none', sm: 'block' },
                            // borderRadius: '12px 12px 0  0',
                            pr: { xs: 0, lg: '40px' }
                        }}
                        bgcolor="#fff"
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Box
                                p={{
                                    xs: '16px',
                                    sm: '24px 20px',
                                    lg: '30px 40px'
                                }}
                                borderBottom="1px solid #C5C5C5"
                                sx={{
                                    display: 'flex',
                                    gap: '24px',
                                    alignItems: 'flex-start',
                                    alignSelf: 'flex-start',
                                    width: '100%',
                                    maxWidth: '866px'
                                }}
                            >
                                <IconButton onClick={() => navigate(-1)}>
                                    <ArrowLeftIcon />
                                </IconButton>
                                <Typography
                                    mt="5px"
                                    sx={{
                                        fontSize: '24px',
                                        fontWeight: '600',
                                        fontFamily: 'Open Sans',
                                        color: '#23282B'
                                    }}
                                >
                                    Skills
                                </Typography>
                            </Box>
                            <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
                                <ScoreCard
                                    width="122px"
                                    height="158px"
                                    score={score}
                                />
                            </Box>
                        </Box>
                    </Box>
                    {isMobile ? null : isTablet ? (
                        <MobileNav list={routes} />
                    ) : null}
                    <Box
                        p={{ xs: '8px 16px', sm: '10px 20px', lg: '20px 40px' }}
                        bgcolor="white"
                        mb={{ xs: '5px', sm: '8px', lg: 0 }}
                    >
                        <Box
                            p={{ xs: '4px', sm: '8px', lg: '12px' }}
                            borderRadius={{ xs: '10px', sm: '12px' }}
                            border="1px solid #C5C5C5"
                        >
                            <Grid
                                container
                                wrap="nowrap"
                                sx={{ borderRadius: { xs: '8px', lg: '12px' } }}
                            >
                                {tabs.map((tab, i) => (
                                    <Grid
                                        key={tab}
                                        item
                                        xs={4}
                                        sx={{
                                            pt: {
                                                xs: '11px',
                                                sm: '8px',
                                                lg: '12px'
                                            },
                                            pb: {
                                                xs: '11px',
                                                sm: '8px',
                                                lg: '8px'
                                            },
                                            bgcolor:
                                                currentTab === tab
                                                    ? '#05668D'
                                                    : '#F5F5F5',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            borderRadius:
                                                i === 0
                                                    ? {
                                                          xs: '8px 0 0 8px',
                                                          lg: '12px 0 0 12px'
                                                      }
                                                    : i + 1 === tabs.length
                                                    ? {
                                                          xs: '0 8px 8px 0',
                                                          lg: '0 12px 12px 0'
                                                      }
                                                    : 0,
                                            cursor:
                                                currentTab !== 'Skills'
                                                    ? 'not-allowed'
                                                    : 'pointer'
                                        }}
                                        onClick={() => setCurrentTab('Skills')}
                                    >
                                        <Typography
                                            fontSize={{
                                                xs: '12px',
                                                sm: '14px'
                                            }}
                                            color={
                                                currentTab === tab
                                                    ? '#fff'
                                                    : '#cccccc'
                                            }
                                            fontWeight={
                                                currentTab === tab ? 600 : 400
                                            }
                                        >
                                            {tab}
                                        </Typography>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </Box>
                </Box>

                <Box
                    sx={{
                        background: '#FFFFFF',
                        mb: '12px',
                        // mt: '20px',
                        pr: isMobile ? '16px' : '40px',
                        pl: isTablet ? '16px' : '40px'
                    }}
                >
                    <Box
                        sx={{
                            // mt: isMobile ? '16px' : '32px',
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            background: '#FFF',
                            gap: { xs: '12px', sm: '20px' },
                            py: { xs: '12px', sm: '20px' }
                        }}
                    >
                        <Box
                            sx={{
                                flexWrap: 'wrap',
                                gap: { xs: '12px', lg: '25px' },
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'flex-start'
                            }}
                        >
                            <Box
                                sx={{
                                    width: { xs: '65px', sm: '88px' },
                                    height: { xs: '20px', sm: '24px' },
                                    fontSize: { xs: '12px', sm: '14px' },
                                    borderRadius: '4px',
                                    background: '#808080',
                                    color: '#FFF',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                Unrated
                            </Box>
                            <>
                                {/* <Box
                                sx={{
                                    // display: 'flex',
                                    // alignItems: 'center',
                                    gap: isMobile
                                        ? '8px'
                                        : isTablet
                                        ? '12px'
                                        : '25px',
                                    flexWrap: 'wrap',
                                    py: { xs: '12px', sm: '20px' }
                                    // pb: { xs: '12px', sm: '20px' }
                                }}
                            > */}
                                {experienceLevels.map((x) => (
                                    <ExperienceLevelSkills {...x} />
                                ))}
                                {/* </Box> */}
                            </>
                        </Box>
                        <Box width={isTablet ? '100%' : undefined}>
                            <Button
                                sx={{
                                    display: 'flex',
                                    width: { xs: '100%', lg: '141px' },
                                    height: { xs: '36px', lg: '44px' },
                                    alignItems: 'center',
                                    flexShrink: 0,
                                    fontSize: {
                                        xs: '12px',
                                        sm: '14px',
                                        lg: '16px'
                                    },
                                    fontWeight: '500',
                                    fontFamily: 'Open Sans',
                                    background: '#05668D',
                                    borderRadius: '6px',
                                    color: '#FFFFFF',
                                    textDecoration: 'none',
                                    padding: '10px',
                                    '&:hover': {
                                        textDecoration: 'none',
                                        background: '#05668D',
                                        color: '#FFFFFF'
                                    }
                                }}
                                onClick={() => {
                                    setShowModal(true);
                                }}
                            >
                                Update Skills
                            </Button>
                        </Box>
                    </Box>
                </Box>

                <Box
                    sx={{
                        background: '#FFFFFF',
                        p: isMobile
                            ? '12px 16px'
                            : isTablet
                            ? '20px'
                            : '32px 40px',
                        mb: '8px'
                    }}
                >
                    <Box
                        sx={{
                            gap: '20px',
                            borderRadius: '8px',
                            background: '#FFF'
                        }}
                    >
                        <Box
                            sx={{
                                pb: '10px',
                                borderBottom: {
                                    xs: 'none',
                                    lg: '1px solid #dbdbdb'
                                },
                                mb: { xs: '12px', sm: '20px' }
                            }}
                        >
                            <Typography
                                variant="titleLargeSemiBold"
                                sx={{
                                    color: '#23282B',
                                    marginBottom: '5px'
                                }}
                                component={'div'}
                            >
                                Unrated skills
                            </Typography>
                            <Typography
                                variant="titleMediumRegular"
                                component={'div'}
                                sx={{
                                    color: '#808080',
                                    fontFamily: 'Open Sans',
                                    fontSize: '15px',
                                    fontWeight: '400'
                                }}
                            >
                                These are skills which you have not received any
                                ratings for.
                                <br />
                                Please note that unrated skills updated from
                                both Profile (work experience) and on this page
                                will appear here.
                                <br />
                                Post an achievement to receive ratings.
                            </Typography>
                        </Box>
                    </Box>
                    {isFetching ? (
                        <SkillsLoader />
                    ) : (
                        unratedSkills?.rating?.data?.length > 0 && (
                            <Box
                                sx={{
                                    py: { xs: 0, sm: '20px' },
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: isMobile ? '8px' : '16px',
                                    alignItems: 'center'
                                }}
                            >
                                {unratedSkills?.rating?.data
                                    ?.filter((x: ISkillType) => x?.witness)
                                    ?.map((x: ISkillType) => (
                                        <UnRatedSkillBox skill={x?.name} />
                                    ))}
                            </Box>
                        )
                    )}
                </Box>

                <Box
                    sx={{
                        background: '#FFF',
                        // mt: '20px',
                        padding: isMobile
                            ? '12px 16px'
                            : isTablet
                            ? '20px'
                            : '40px',
                        borderRadius: '8px'
                    }}
                >
                    {isFetching ? (
                        <SkillsLoader />
                    ) : (
                        <>
                            <Box
                                sx={{
                                    pb: '10px',
                                    borderBottom: {
                                        xs: 'none',
                                        lg: '1px solid #dbdbdb'
                                    },
                                    mb: { xs: '12px', sm: '20px' }
                                }}
                            >
                                <Typography
                                    variant="titleLargeSemiBold"
                                    sx={{
                                        color: '#23282B',
                                        marginBottom: '5px'
                                    }}
                                    component={'div'}
                                >
                                    Rated skills
                                </Typography>
                                <Typography
                                    variant="titleMediumRegular"
                                    component={'div'}
                                    sx={{
                                        color: '#808080',
                                        fontFamily: 'Open Sans',
                                        fontSize: '15px',
                                        fontWeight: '400'
                                    }}
                                >
                                    These are skills that have been reviewed
                                    through posted achievements.Each skill level
                                    is determined by the aggregate review.
                                </Typography>
                            </Box>
                            <Box py={{ xs: 0, sm: '20px' }}>
                                <ConnectionSkills
                                    id={user?.professionalProfile?.id}
                                />
                            </Box>
                        </>
                    )}
                </Box>
            </Box>

            {showModal && (
                <UpdateSkills
                    rating={unratedSkills?.rating ?? null}
                    showModal={showModal}
                    hideModal={() => {
                        setShowModal(false);
                    }}
                />
            )}
        </>
    );
};

export default SkillsPage;

const UnRatedSkillBox = ({ skill = '' }) => {
    const { isMobile } = Responsiveness();
    return (
        <Box
            sx={{
                borderRadius: '4px',
                background: '#808080',
                textDecoration: 'none',
                color: '#FFF',
                fontFamily: 'Open Sans',
                fontSize: isMobile ? '12px' : '14px',
                fontWeight: 600,
                p: {xs: '4px 8px', sm: '6px 16px'},
                whiteSpace: 'nowrap',
                '&:hover': {
                    textDecoration: 'none'
                }
            }}
        >
            {skill}
        </Box>
    );
};
