const JobDetailItemTrashIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M21.0036 6.72656C20.9836 6.72656 20.9536 6.72656 20.9236 6.72656C15.6336 6.19656 10.3536 5.99656 5.12358 6.52656L3.08358 6.72656C2.66358 6.76656 2.29358 6.46656 2.25358 6.04656C2.21358 5.62656 2.51358 5.26656 2.92358 5.22656L4.96358 5.02656C10.2836 4.48656 15.6736 4.69656 21.0736 5.22656C21.4836 5.26656 21.7836 5.63656 21.7436 6.04656C21.7136 6.43656 21.3836 6.72656 21.0036 6.72656Z"
                fill="#494949"
            />
            <path
                d="M8.50074 5.72C8.46074 5.72 8.42074 5.72 8.37074 5.71C7.97074 5.64 7.69074 5.25 7.76074 4.85L7.98074 3.54C8.14074 2.58 8.36074 1.25 10.6907 1.25H13.3107C15.6507 1.25 15.8707 2.63 16.0207 3.55L16.2407 4.85C16.3107 5.26 16.0307 5.65 15.6307 5.71C15.2207 5.78 14.8307 5.5 14.7707 5.1L14.5507 3.8C14.4107 2.93 14.3807 2.76 13.3207 2.76H10.7007C9.64074 2.76 9.62074 2.9 9.47074 3.79L9.24074 5.09C9.18074 5.46 8.86074 5.72 8.50074 5.72Z"
                fill="#494949"
            />
            <path
                d="M15.2104 22.7515H8.79039C5.30039 22.7515 5.16039 20.8215 5.05039 19.2615L4.40039 9.19154C4.37039 8.78154 4.69039 8.42154 5.10039 8.39154C5.52039 8.37154 5.87039 8.68154 5.90039 9.09154L6.55039 19.1615C6.66039 20.6815 6.70039 21.2515 8.79039 21.2515H15.2104C17.3104 21.2515 17.3504 20.6815 17.4504 19.1615L18.1004 9.09154C18.1304 8.68154 18.4904 8.37154 18.9004 8.39154C19.3104 8.42154 19.6304 8.77154 19.6004 9.19154L18.9504 19.2615C18.8404 20.8215 18.7004 22.7515 15.2104 22.7515Z"
                fill="#494949"
            />
            <path
                d="M13.6581 17.25H10.3281C9.91813 17.25 9.57812 16.91 9.57812 16.5C9.57812 16.09 9.91813 15.75 10.3281 15.75H13.6581C14.0681 15.75 14.4081 16.09 14.4081 16.5C14.4081 16.91 14.0681 17.25 13.6581 17.25Z"
                fill="#494949"
            />
            <path
                d="M14.5 13.25H9.5C9.09 13.25 8.75 12.91 8.75 12.5C8.75 12.09 9.09 11.75 9.5 11.75H14.5C14.91 11.75 15.25 12.09 15.25 12.5C15.25 12.91 14.91 13.25 14.5 13.25Z"
                fill="#494949"
            />
        </svg>
    );
};

export default JobDetailItemTrashIcon;
