const ConnectionsIcon = () => {
    return (
        <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.5 2C6.88 2 4.75 4.13 4.75 6.75C4.75 9.32 6.76 11.4 9.38 11.49C9.46 11.48 9.54 11.48 9.6 11.49C9.62 11.49 9.63 11.49 9.65 11.49C9.66 11.49 9.66 11.49 9.67 11.49C12.23 11.4 14.24 9.32 14.25 6.75C14.25 4.13 12.12 2 9.5 2Z"
                fill="#05668D"
            />
            <path
                d="M14.5809 14.1499C11.7909 12.2899 7.24094 12.2899 4.43094 14.1499C3.16094 14.9999 2.46094 16.1499 2.46094 17.3799C2.46094 18.6099 3.16094 19.7499 4.42094 20.5899C5.82094 21.5299 7.66094 21.9999 9.50094 21.9999C11.3409 21.9999 13.1809 21.5299 14.5809 20.5899C15.8409 19.7399 16.5409 18.5999 16.5409 17.3599C16.5309 16.1299 15.8409 14.9899 14.5809 14.1499Z"
                fill="#05668D"
            />
            <path
                d="M20.4894 7.3401C20.6494 9.2801 19.2694 10.9801 17.3594 11.2101C17.3494 11.2101 17.3494 11.2101 17.3394 11.2101H17.3094C17.2494 11.2101 17.1894 11.2101 17.1394 11.2301C16.1694 11.2801 15.2794 10.9701 14.6094 10.4001C15.6394 9.4801 16.2294 8.1001 16.1094 6.6001C16.0394 5.7901 15.7594 5.0501 15.3394 4.4201C15.7194 4.2301 16.1594 4.1101 16.6094 4.0701C18.5694 3.9001 20.3194 5.3601 20.4894 7.3401Z"
                fill="#05668D"
            />
            <path
                d="M22.4902 16.5904C22.4102 17.5604 21.7902 18.4004 20.7502 18.9704C19.7502 19.5204 18.4902 19.7804 17.2402 19.7504C17.9602 19.1004 18.3802 18.2904 18.4602 17.4304C18.5602 16.1904 17.9702 15.0004 16.7902 14.0504C16.1202 13.5204 15.3402 13.1004 14.4902 12.7904C16.7002 12.1504 19.4802 12.5804 21.1902 13.9604C22.1102 14.7004 22.5802 15.6304 22.4902 16.5904Z"
                fill="#05668D"
            />
        </svg>
    );
};

export default ConnectionsIcon;
