const PopupCheckmark = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="101"
            height="100"
            viewBox="0 0 101 100"
            fill="none"
        >
            <path
                opacity="0.4"
                d="M91.6673 50.0006C91.6673 72.7364 73.2364 91.1673 50.5006 91.1673C27.7649 91.1673 9.33398 72.7364 9.33398 50.0006C9.33398 27.2649 27.7649 8.83398 50.5006 8.83398C73.2364 8.83398 91.6673 27.2649 91.6673 50.0006Z"
                fill="#49B6FF"
                stroke="#49B6FF"
            />
            <path
                d="M44.584 64.9173C43.7507 64.9173 42.959 64.584 42.3757 64.0007L30.584 52.209C29.3757 51.0007 29.3757 49.0007 30.584 47.7923C31.7923 46.584 33.7923 46.584 35.0007 47.7923L44.584 57.3757L66.0007 35.959C67.209 34.7507 69.209 34.7507 70.4173 35.959C71.6257 37.1673 71.6257 39.1673 70.4173 40.3756L46.7923 64.0007C46.209 64.584 45.4173 64.9173 44.584 64.9173Z"
                fill="#49B6FF"
                stroke="#49B6FF"
                stroke-width="4"
            />
        </svg>
    );
};

export default PopupCheckmark;
