import React from 'react'

const SearchOutlined = () => {
  return (
      <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
          <path
              d="M9.1665 16.6666C13.3086 16.6666 16.6665 13.3088 16.6665 9.16663C16.6665 5.02449 13.3086 1.66663 9.1665 1.66663C5.02437 1.66663 1.6665 5.02449 1.6665 9.16663C1.6665 13.3088 5.02437 16.6666 9.1665 16.6666Z"
              stroke="#808080"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
          />
          <path
              d="M15.775 17.2415C16.2167 18.5748 17.225 18.7081 18 17.5415C18.7083 16.4748 18.2417 15.5998 16.9583 15.5998C16.0084 15.5915 15.475 16.3331 15.775 17.2415Z"
              stroke="#808080"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
          />
      </svg>
  );
}

export default SearchOutlined
