import axiosInstance from '../axiosConfig';
import { UseMutationResult, useMutation } from 'react-query';

async function incrementViews(id: string) {
    const response = await axiosInstance.post(`/api/user-posts/${id}/view`);
    const data = await response.data;
    return data;
}
export function useIncrementViews(): UseMutationResult<
    unknown,
    unknown,
    string
> {
    return useMutation(incrementViews);
}
