import { Box } from '@mui/material'
import React from 'react'

const Empty = () => {
  return (
    <Box position={'absolute'} display='none'>
      
    </Box>
  )
}

export default Empty
