import React from 'react';

function BirthdayFormIcon() {
    return (
        <>
            <svg
                width="41"
                height="20"
                viewBox="0 0 41 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M18.3346 18.9609H1.66797C1.3263 18.9609 1.04297 18.6776 1.04297 18.3359C1.04297 17.9943 1.3263 17.7109 1.66797 17.7109H18.3346C18.6763 17.7109 18.9596 17.9943 18.9596 18.3359C18.9596 18.6776 18.6763 18.9609 18.3346 18.9609Z"
                    fill="#808080"
                />
                <path
                    d="M17.4065 18.9609C17.0648 18.9609 16.7815 18.6776 16.7815 18.3359V10.8359C16.7815 9.8026 15.8148 8.96094 14.6315 8.96094H5.36484C4.18151 8.96094 3.21484 9.8026 3.21484 10.8359V18.3359C3.21484 18.6776 2.93151 18.9609 2.58984 18.9609C2.24818 18.9609 1.96484 18.6776 1.96484 18.3359V10.8359C1.96484 9.11094 3.48984 7.71094 5.36484 7.71094H14.6232C16.4982 7.71094 18.0232 9.11094 18.0232 10.8359V18.3359C18.0315 18.6776 17.7482 18.9609 17.4065 18.9609Z"
                    fill="#808080"
                />
                <path
                    d="M15.3661 8.95573C15.0245 8.95573 14.7411 8.6724 14.7411 8.33073V5.9724C14.7411 5.3224 14.1161 4.78906 13.3578 4.78906H6.64948C5.88281 4.78906 5.26615 5.3224 5.26615 5.9724V8.33073C5.26615 8.6724 4.98281 8.95573 4.64115 8.95573C4.29948 8.95573 4.00781 8.6724 4.00781 8.33073V5.9724C4.00781 4.63073 5.19115 3.53906 6.64115 3.53906H13.3495C14.7995 3.53906 15.9828 4.63073 15.9828 5.9724V8.33073C15.9911 8.6724 15.7078 8.95573 15.3661 8.95573Z"
                    fill="#808080"
                />
                <path
                    d="M14.4403 14.7906C13.4736 14.7906 12.6903 14.0073 12.6903 13.0406V12.7823C12.6903 12.5073 12.4653 12.2823 12.1903 12.2823C11.9153 12.2823 11.6903 12.5073 11.6903 12.7823V13.0406C11.6903 14.0073 10.907 14.7906 9.94028 14.7906C8.97362 14.7906 8.19028 14.0073 8.19028 13.0406V12.7823C8.19028 12.5073 7.96528 12.2823 7.69028 12.2823C7.41528 12.2823 7.19028 12.5073 7.19028 12.7823V13.0406C7.19028 14.0073 6.40695 14.7906 5.44028 14.7906C4.47362 14.7906 3.69028 14.0073 3.69028 13.0406V12.7656C3.69028 12.499 3.47362 12.274 3.19862 12.2656H2.89028C2.54862 12.2573 2.26528 11.974 2.27362 11.6323C2.28195 11.2906 2.55695 11.0156 2.89862 11.0156H2.90695H3.21528C4.16528 11.0323 4.94028 11.8156 4.94028 12.7656V13.0406C4.94028 13.3156 5.16528 13.5406 5.44028 13.5406C5.71528 13.5406 5.94028 13.3156 5.94028 13.0406V12.7823C5.94028 11.8156 6.72362 11.0323 7.69028 11.0323C8.65695 11.0323 9.44028 11.8156 9.44028 12.7823V13.0406C9.44028 13.3156 9.66528 13.5406 9.94028 13.5406C10.2153 13.5406 10.4403 13.3156 10.4403 13.0406V12.7823C10.4403 11.8156 11.2236 11.0323 12.1903 11.0323C13.157 11.0323 13.9403 11.8156 13.9403 12.7823V13.0406C13.9403 13.3156 14.1653 13.5406 14.4403 13.5406C14.7153 13.5406 14.9403 13.3156 14.9403 13.0406V12.7823C14.9403 11.8156 15.7236 11.0323 16.6903 11.0323H17.0653C17.407 11.0323 17.6903 11.3156 17.6903 11.6573C17.6903 11.999 17.407 12.2823 17.0653 12.2823H16.6903C16.4153 12.2823 16.1903 12.5073 16.1903 12.7823V13.0406C16.1903 14.0073 15.407 14.7906 14.4403 14.7906Z"
                    fill="#808080"
                />
                <path
                    d="M6.66797 4.79167C6.3263 4.79167 6.04297 4.50833 6.04297 4.16667V2.5C6.04297 2.15833 6.3263 1.875 6.66797 1.875C7.00964 1.875 7.29297 2.15833 7.29297 2.5V4.16667C7.29297 4.50833 7.00964 4.79167 6.66797 4.79167Z"
                    fill="#808080"
                />
                <path
                    d="M13.332 4.79167C12.9904 4.79167 12.707 4.50833 12.707 4.16667V2.5C12.707 2.15833 12.9904 1.875 13.332 1.875C13.6737 1.875 13.957 2.15833 13.957 2.5V4.16667C13.957 4.50833 13.6737 4.79167 13.332 4.79167Z"
                    fill="#808080"
                />
                <path
                    d="M10 4.78906C9.65833 4.78906 9.375 4.50573 9.375 4.16406V1.66406C9.375 1.3224 9.65833 1.03906 10 1.03906C10.3417 1.03906 10.625 1.3224 10.625 1.66406V4.16406C10.625 4.50573 10.3417 4.78906 10 4.78906Z"
                    fill="#808080"
                />
                <line x1="40.5" y1="2.5" x2="40.5" y2="17.5" stroke="#D9D9D9" />
            </svg>
        </>
    );
}

export default BirthdayFormIcon;
