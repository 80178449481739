import axiosInstance from '../axiosConfig';
import { UseMutationResult, useMutation } from 'react-query';
import { IRemoveLikeRequest } from './types';

async function removeCommentLike(deleteRequest: IRemoveLikeRequest) {
    const response = await axiosInstance.delete(
        `/api/comment-likes/${deleteRequest.userId}?commentId=${deleteRequest.commentId}`
    );
    const data = await response.data;
    return data;
}
export function useRemoveCommentLike(): UseMutationResult<
    unknown,
    unknown,
    IRemoveLikeRequest
> {
    return useMutation(removeCommentLike);
}
