export const getCompetencyColor = (competencyLevel: number) => {
    let color = '';
    switch (competencyLevel) {
        case 2: {
            color = '#F55536';
            break;
        }
        case 4: {
            color = '#49B6FF';
            break;
        }
        case 6: {
            color = '#49B6FF';
            break;
        }
        case 8: {
            color = '#00BD9D';
            break;
        }
        case 10: {
            color = '#07AF22';
            break;
        }
        default:
            color = '#808080';
            break;
    }
    return color;
};

export const getJobFitColor = (jobFit: number) => {
    if (jobFit > 8) return '#07AF22';
    if (jobFit > 7) return '#95C97A';
    if (jobFit > 5) return '#49B6FF';
    if (jobFit > 3) return '#F6C70E';
    else return '#E75541';
};

export const getJobType = (value: string) => {
    let jobType = '';
    switch (value) {
        case 'FULL_TIME': {
            jobType = 'Full Time';
            break;
        }
        case 'TEMPORARY': {
            jobType = 'Temporary';
            break;
        }
        case 'PERMANENT': {
            jobType = 'Permanent';
            break;
        }
        case 'NEW_GRAD': {
            jobType = 'New Grad';
            break;
        }
        case 'PART_TIME': {
            jobType = 'Part Time';
            break;
        }
        case 'CONTRACT': {
            jobType = 'Contract';
            break;
        }
        case 'INTERNSHIP': {
            jobType = 'Internship';
            break;
        }
        default:
            jobType = 'Full Time';
    }
    return jobType;
};

export const getSalaryRange = (
    salaryCurrency?: string,
    salaryRangeFrom?: number,
    salaryRangeTo?: number
) => {
    if (
        salaryCurrency === null ||
        salaryRangeFrom === null ||
        salaryRangeTo === null
    ) {
        return 'N/A';
    }
    return `${salaryCurrency} ${salaryRangeFrom} - ${salaryRangeTo} / month`;
};

export const getWorkPlace = (value: string) => {
    let workPlace = '';
    switch (value) {
        case 'ONSITE': {
            workPlace = 'On-site';
            break;
        }
        case 'HYBRID': {
            workPlace = 'Hybrid';
            break;
        }
        case 'REMOTE': {
            workPlace = 'Remote';
            break;
        }
        default:
            workPlace = 'On-site';
    }
    return workPlace;
};

export const convertDateStringToMilli = (dateString: string) => {
    return Date.parse(dateString);
};

export const convertMilliToDateString = (dateNumber: number) => {
    const date = new Date(dateNumber);
    return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });
};

export const BEGINNER = { name: 'Beginner', level: 2, shortForm: 'B' };
export const EXPERIENCED = { name: 'Experienced', level: 4, shortForm: 'E' };
export const ADVANCE = { name: 'Advance', level: 6, shortForm: 'A' };
export const EXPERT = { name: 'Expert', level: 8, shortForm: 'X' };
export const THOUGHT_LEADER = {
    name: 'Thought Leader',
    level: 10,
    shortForm: 'L'
};

export const skillLevel = [
    BEGINNER,
    EXPERIENCED,
    ADVANCE,
    EXPERT,
    THOUGHT_LEADER
];
export const workPlaces = ['ONSITE', 'HYBRID', 'REMOTE'];
export const jobTypes = [
    'FULL_TIME',
    'TEMPORARY',
    'PERMANENT',
    'NEW_GRAD',
    'PART_TIME',
    'CONTRACT',
    'INTERNSHIP'
];

export const companySizes = [
    { key: 'Fortune 500', value: 'FORTUNE_500' },
    { key: 'Large Corporation', value: 'LARGE_CORPORATION' },
    { key: 'Mid Sized Public', value: 'MID_SIZE_PUBLIC' },
    { key: 'Mid To Small Sized Private', value: 'MID_TO_SMALL_SIZED_PRIVATE' },
    {
        key: 'Single Sized Or Family Owned',
        value: 'SINGLE_SIZED_OR_FAMILY_OWNED'
    }
];

export const replaceWithNbsp = (text: string) => {
    return text.replace(/\s+/g, '\u00A0');
};
