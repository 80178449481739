import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';
import { useGetApplicantById } from '../../../utils/hooks/api/jobs/useGetApplicantById';
import ApplicantDetailsPage from './ApplicantDetailsPage';

function ApplicantDetails() {
    const { id } = useParams();
    const { data: applicant, isFetching } = useGetApplicantById(id ?? '');

    return (
        <Container
            sx={{
                pr: { xs: 0, sm: '20px', lg: '40px' }
            }}
        >
            {isFetching ? null : (
                <ApplicantDetailsPage
                    applicant={applicant}
                    id={applicant?.professionalProfile?.id}
                />
            )}
        </Container>
    );
}

interface ISkillTypes {
    background: string;
    title: string;
    shortForm?: string;
}
interface ISkillBadgeTypes {
    item: ISkillTypes;
}
const SkillBadge = ({ item }: ISkillBadgeTypes) => {
    return (
        <Box>
            <Typography
                variant="titleSmallSemiBold"
                sx={{
                    color: '#FFFFFF',
                    background: item.background,
                    borderRadius: '6px',
                    padding: '4px 8px',
                    display: 'flex',
                    gap: '8px',
                    alignItems: 'center'
                }}
            >
                {item.title}
                {item.shortForm ? (
                    <Typography
                        sx={{
                            background: 'rgba(5, 5, 5, 0.4)',
                            padding: '0px 4px',
                            borderRadius: '4px'
                        }}
                        variant="labelLargeBold"
                    >
                        {item.shortForm}
                    </Typography>
                ) : null}
            </Typography>
        </Box>
    );
};

const skills = [
    {
        title: 'Adobe XD',
        shortForm: 'T',
        background: '#07AF22'
    },
    {
        title: 'HTML',
        shortForm: 'H',
        background: '#F6C70E'
    },
    {
        title: 'Bubble',
        shortForm: 'B',
        background: '#E75541'
    },
    {
        title: 'Prototyping',
        shortForm: 'A',
        background: '#49B6FF'
    },
    {
        title: 'Figma',
        shortForm: 'F',
        background: '#07AF22'
    },
    {
        title: 'Webflow',
        shortForm: 'W',
        background: '#95C97A'
    },
    {
        title: 'Wireframing',
        background: '#808080'
    }
];
export default ApplicantDetails;
