import axiosInstance from '../axiosConfig';
import { UseMutationResult, useMutation } from 'react-query';

async function removeEmployment(employmentId: any) {
    const response = await axiosInstance.delete(
        `/api/employments/${employmentId}`
    );
    const data = await response.data;
    return data;
}
export function useRemoveEmployment(): UseMutationResult<
    unknown,
    unknown,
    unknown
> {
    return useMutation(removeEmployment);
}
