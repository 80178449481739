import React from 'react'

const Increase = () => {
  return (
      <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
          <rect width="24" height="24" fill="#58A963" />
          <path
              opacity="0.4"
              d="M7.81 21.49L7.80863 21.49C6.08838 21.4947 4.77559 20.9861 3.89333 20.105C3.01097 19.2237 2.5 17.9107 2.5 16.19V7.81C2.5 6.08944 3.01093 4.77618 3.89355 3.89355C4.77618 3.01093 6.08944 2.5 7.81 2.5H16.18C17.9059 2.5 19.2217 3.01114 20.1054 3.89377C20.9891 4.77634 21.5 6.08945 21.5 7.81V16.18C21.5 17.9006 20.9891 19.2138 20.1064 20.0964C19.2238 20.9791 17.9106 21.49 16.19 21.49L7.81 21.49Z"
              fill="#292D32"
              stroke="white"
          />
          <path
              d="M13.1035 7.45645L12.25 6.60289V7.81V18C12.25 18.1339 12.1338 18.25 12 18.25C11.8661 18.25 11.75 18.1339 11.75 18V7.81V6.60289L10.8964 7.45645L7.88641 10.4664C7.79167 10.5612 7.62825 10.5612 7.53351 10.4664C7.48222 10.4152 7.45996 10.3545 7.45996 10.29C7.45996 10.2306 7.4887 10.1584 7.53351 10.1136L7.17996 9.76L7.53351 10.1136L11.8235 5.82355L11.4739 5.47397L11.8235 5.82355C11.8708 5.77625 11.9341 5.75 12 5.75C12.0658 5.75 12.1291 5.77625 12.1764 5.82355L12.5213 5.47862L12.1764 5.82355L16.4664 10.1136C16.5611 10.2083 16.5611 10.3717 16.4664 10.4664C16.3717 10.5612 16.2083 10.5612 16.1135 10.4664L13.1035 7.45645Z"
              fill="#292D32"
              stroke="white"
          />
      </svg>
  );
}

export default Increase
