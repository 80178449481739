import axiosInstance from '../axiosConfig';
import { IEmploymentRequest } from './types';
import { UseMutationResult, useMutation } from 'react-query';

async function addEmployment(postRequest: IEmploymentRequest) {
    const response = await axiosInstance.post(`/api/employments`, postRequest);
    const data = await response.data;
    return data;
}
export function useAddEmployment(): UseMutationResult<
    IEmploymentRequest,
    unknown,
    IEmploymentRequest
> {
    return useMutation(addEmployment);
}
