import React from 'react'

const PlusIcon = () => {
  return (
      <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
          <path
              d="M5 10H15"
              stroke="#05668D"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
          />
          <path
              d="M10 15V5"
              stroke="#05668D"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
          />
      </svg>
  );
}

export default PlusIcon
