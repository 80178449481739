import React from 'react'

const CloseSquare = () => {

    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ minWidth: '18px', minHeight: '18px'}}
        >
            <path
                d="M12.4915 0.666016H5.50817C2.47484 0.666016 0.666504 2.47435 0.666504 5.50768V12.4827C0.666504 15.5244 2.47484 17.3327 5.50817 17.3327H12.4832C15.5165 17.3327 17.3248 15.5244 17.3248 12.491V5.50768C17.3332 2.47435 15.5248 0.666016 12.4915 0.666016ZM11.7998 10.916C12.0415 11.1577 12.0415 11.5577 11.7998 11.7993C11.6748 11.9243 11.5165 11.9827 11.3582 11.9827C11.1998 11.9827 11.0415 11.9243 10.9165 11.7993L8.99984 9.88268L7.08317 11.7993C6.95817 11.9243 6.79984 11.9827 6.6415 11.9827C6.48317 11.9827 6.32484 11.9243 6.19984 11.7993C5.95817 11.5577 5.95817 11.1577 6.19984 10.916L8.1165 8.99935L6.19984 7.08268C5.95817 6.84102 5.95817 6.44102 6.19984 6.19935C6.4415 5.95768 6.8415 5.95768 7.08317 6.19935L8.99984 8.11602L10.9165 6.19935C11.1582 5.95768 11.5582 5.95768 11.7998 6.19935C12.0415 6.44102 12.0415 6.84102 11.7998 7.08268L9.88317 8.99935L11.7998 10.916Z"
                fill="#808080"
            />
        </svg>
    );

}

export default CloseSquare