import {useEffect} from 'react'
import { Box, Typography } from '@mui/material';
import { FeedItem } from '../../Home/components/FeedItem';
import SkillsChips from './SkillsChips';
import Reviewer from './Reviewer';
import { useAuth } from '../../../utils/context/AuthContext';
import { useGetUserPostById } from '../../../utils/hooks/api/posts/useGetUserPostById';
import { useLocation } from 'react-router-dom';

const ReviewPost = ({ achievement }) => {
    const { user } = useAuth();
    const location = useLocation()
    const { data: post, refetch } = useGetUserPostById(achievement?.post?.id ?? '');
    const feed = {
        userId: user?.id,
        id: achievement?.post?.id,
        profileImage:
            achievement?.professionalRating?.professionalProfile?.photoUrl,
        fullName: `${achievement?.professionalRating?.professionalProfile?.firstName} ${achievement?.professionalRating?.professionalProfile?.lastName}`,
        jobTitle:
            achievement?.professionalRating?.professionalProfile
                ?.currentEmployment?.jobTitle,
        description: achievement?.post?.message,
        views: achievement?.post?.views,
        likes: (post?.comments && post?.comments[0]?.likes) ?? (post?.comments && post?.comments[post?.comments?.length - 1]?.likes) ?? 0,
        commentsCount: post?.commentsCount ?? 0,
        comments: post?.comments ?? null,
        shares: post?.repostCount ?? 0,
        images: achievement?.post?.photos ?? [],
        isAccountVerified: true 
    };
// console.log(user?.id);
    return (
        <Box>
            <Box
                sx={{
                    backgroundColor: 'white'
                }}
            >
                <Box
                    sx={{
                        backgroundColor: 'white',
                        borderBottom: '1px solid  #D9D9D9',
                        display: 'flex',
                        padding: '16px 40px',
                        alignItems: 'center',
                        gap: '20px',
                        marginTop: '20px'
                    }}
                >
                    <Typography variant="bodyLargeSemiBold">
                        {user?.professionalProfile?.firstName}{' '}
                        {user?.professionalProfile?.lastName}{' '}
                        <Typography variant="bodyLargeRegular">
                            Completed a course
                        </Typography>
                    </Typography>
                </Box>
                <FeedItem feed={feed} />
            </Box>
            <SkillsChips skills={achievement?.skills?.data ?? []} />
            <Reviewer reviewers={achievement?.reviewers} />
        </Box>
    );
};

export default ReviewPost;
