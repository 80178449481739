import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import InputBase from '@mui/material/InputBase';
import CommonRadioDropdown from '../../../../common/CommonRadioDropdown';
import { Button, Checkbox, Chip, FormControlLabel, Grid, Paper, TextField } from '@mui/material';
import { Responsiveness } from '../../../../../theme/overrides';
import {
    COMPANY_SIZE_OPTIONS,
    EMPLOYMENT_LEVEL_OPTIONS,
    EMPLOYMENT_TYPE_OPTIONS,
    LOCATION_TYPE_OPTIONS
} from '../../../constants';
import Calendar from '../../Calendar';
import dayjs from 'dayjs';
import CompaniesAutocomplete from '../../../../../components/CompaniesAutocomplete';
import { Address } from '../../../../../utils/hooks/api/users/types';
import PlusIcon from '../../../../../assets/icons/PlusIcon';
import SearchIcon from '../../../../../assets/icons/SearchIcon';
import { useEffect, useState } from 'react';
import { useGetSkills } from '../../../../../utils/hooks/api/skills/useGetSkills';
import { ISkillType } from '../../../../../components/AchievementSkillsPopup/AchievementSkillsPopup';
import Close from '../../../../../assets/icons/CloseIcon';
import { Add } from '@mui/icons-material';
import CloseXIcon from '../../../../../assets/icons/CloseXIcon';
import { logger } from '../../../../../utils/Helper/logger';

const WorkExperienceForm = ({ handleChange, workExperience, selectedCompany = null }: any) => {
    const { isMobile } = Responsiveness();
    const [search, setSearch] = useState('')
    const { data: skills = [], refetch } = useGetSkills(search, 10);
        const [suggestedSkills, setSuggestedSkills] = useState<ISkillType[]>(
            []
        );
        const [selectedSkills, setSelectedSkills] = useState<Array<ISkillType>>(
            []
        );
    
    logger.log(workExperience)
    
        const addSkill = (skill: any) => {
            if (
                !selectedSkills.find(
                    (x: ISkillType) => x.name === skill.name
                ) &&
                selectedSkills.length < 10
            ) {
                setSelectedSkills([...selectedSkills, skill])
                    // handleChange(skill);
            }
        };

            useEffect(() => {
                if (suggestedSkills.length === 0) {
                    setSuggestedSkills(skills);
                }
            }, [skills]);

            useEffect(() => {
                if (search.length > 2) {
                    refetch();
                }
            }, [search]);

    const formatAddress = (addr: Address) => {
        let address = '';
        if (addr?.streetAddress) address += `${addr?.streetAddress}, `;
        if (addr?.street1) address += `${addr?.street1}, `;
        if (addr?.city) address += `${addr?.city}, `;
        if (addr?.stateProvince) address += `${addr?.stateProvince}, `;
        if (addr?.country) address += addr?.country;
        return address;
    }

    return (
        <>
            <Box sx={{ position: 'relative' }}>
                <InputLabel
                    sx={{
                        color: '#23282B',
                        fontSize: '14px',
                        position: 'absolute',
                        top: '8px',
                        left: '16px',
                        zIndex: 1,
                        fontFamily: 'Open Sans'
                    }}
                    htmlFor="phone"
                >
                    Job title
                    <Typography
                        ml={0.25}
                        component="span"
                        color="#C3BCB6"
                        fontSize={14}
                        fontWeight={600}
                    >
                        *
                    </Typography>
                </InputLabel>
                <InputBase
                    required
                    value={workExperience.jobTitle}
                    onChange={handleChange}
                    inputProps={{
                        autoComplete: '',
                        form: {
                            autoComplete: 'off'
                        }
                    }}
                    placeholder="Enter job title"
                    name="jobTitle"
                    rows={1}
                    sx={{
                        backgroundColor: '#f2f2f2',
                        width: '100%',
                        height: '60px',
                        padding: '0px 16px',
                        fontFamily: 'open sans',
                        color: '#23282B',
                        borderBottom: '1px solid #D9D9D9',
                        mb: '8px',
                        '& 	.MuiInputBase-input': {
                            position: 'relative',
                            top: '10px'
                        }
                    }}
                />
            </Box>

            <CommonRadioDropdown
                options={EMPLOYMENT_TYPE_OPTIONS}
                onSelect={(val) => {
                    let item = {
                        target: {
                            value: val,
                            name: 'employmentType'
                        }
                    };
                    handleChange(item);
                }}
                value={{
                    value: workExperience.employmentType,
                    label: workExperience.employmentType
                }}
                label={
                    <>
                        Employment type
                        <Typography
                            ml={0.25}
                            component="span"
                            color="#C3BCB6"
                            fontSize={14}
                            fontWeight={600}
                        >
                            *
                        </Typography>
                    </>
                }
                placeholder="Please select"
            />

            <Box sx={{ position: 'relative' }}>
                <CompaniesAutocomplete
                    label="Company name*"
                    onSelect={(newValue) => {
                        let items = [
                            {
                                target: {
                                    name: 'companyEmail',
                                    value: newValue?.emailAddress
                                }
                            },
                            {
                                target: {
                                    name: 'companyName',
                                    value: newValue?.primaryCompanyId
                                }
                            },
                            newValue?.address
                                ? {
                                      target: {
                                          name: 'location',
                                          value: formatAddress(
                                              newValue?.address!
                                          )
                                      }
                                  }
                                : {
                                      target: {}
                                  }
                        ];
                        items.forEach((x) => {
                            handleChange(x);
                        });
                    }}
                    placeHolder="Enter company name"
                    defaultValue={selectedCompany}
                    inputStyle={{
                        backgroundColor: '#f2f2f2',
                        color: '#23282B',
                        height: '65px',
                        borderBottom: '1px solid #D9D9D9',
                        mb: '8px',
                        '& input': {
                            mt: '10px'
                        },
                        '& .MuiInputLabel-formControl': {
                            top: '16px',
                            fontSize: '18px',
                            fontFamily: 'Open Sans',
                            color: '#808080'
                        }
                    }}
                />
            </Box>

            <Box sx={{ position: 'relative' }}>
                <InputLabel
                    sx={{
                        color: '#23282B',
                        fontSize: '14px',
                        position: 'absolute',
                        top: '8px',
                        left: '16px',
                        zIndex: 1,
                        fontFamily: 'Open Sans'
                    }}
                    htmlFor="phone"
                >
                    Job position
                    <Typography
                        ml={0.25}
                        component="span"
                        color="#C3BCB6"
                        fontSize={14}
                        fontWeight={600}
                    >
                        *
                    </Typography>
                </InputLabel>
                <InputBase
                    required
                    value={workExperience.jobPosition}
                    onChange={handleChange}
                    inputProps={{
                        autoComplete: '',
                        form: {
                            autoComplete: 'off'
                        }
                    }}
                    placeholder="Enter job position"
                    name="jobPosition"
                    rows={1}
                    sx={{
                        backgroundColor: '#f2f2f2',
                        width: '100%',
                        height: '60px',
                        padding: '0px 16px',
                        fontFamily: 'open sans',
                        color: '#23282B',
                        borderBottom: '1px solid #D9D9D9',
                        mb: '8px',
                        '& 	.MuiInputBase-input': {
                            position: 'relative',
                            top: '10px'
                        }
                    }}
                />
            </Box>
            <Box sx={{ position: 'relative' }}>
                <InputLabel
                    sx={{
                        color: '#23282B',
                        fontSize: '14px',
                        position: 'absolute',
                        top: '8px',
                        left: '16px',
                        zIndex: 1,
                        fontFamily: 'Open Sans'
                    }}
                    htmlFor="phone"
                >
                    Company email
                    <Typography
                        ml={0.25}
                        component="span"
                        color="#C3BCB6"
                        fontSize={14}
                        fontWeight={600}
                    >
                        *
                    </Typography>
                </InputLabel>
                <InputBase
                    disabled
                    required
                    value={workExperience.companyEmail}
                    onChange={handleChange}
                    inputProps={{
                        autoComplete: '',
                        form: {
                            autoComplete: 'off'
                        }
                    }}
                    placeholder="Enter company email"
                    name="companyEmail"
                    rows={1}
                    sx={{
                        backgroundColor: '#f2f2f2',
                        width: '100%',
                        height: '60px',
                        padding: '0px 16px',
                        fontFamily: 'open sans',
                        color: '#23282B',
                        borderBottom: '1px solid #D9D9D9',
                        mb: '8px',
                        '& 	.MuiInputBase-input': {
                            position: 'relative',
                            top: '10px'
                        }
                    }}
                />
            </Box>

            <CommonRadioDropdown
                options={COMPANY_SIZE_OPTIONS}
                onSelect={(val) => {
                    let item = {
                        target: {
                            value: val,
                            name: 'companySize'
                        }
                    };
                    handleChange(item);
                }}
                value={{
                    value: workExperience.companySize,
                    label: workExperience.companySize
                }}
                label={
                    <>
                        Company size
                        <Typography
                            ml={0.25}
                            component="span"
                            color="#C3BCB6"
                            fontSize={14}
                            fontWeight={600}
                        >
                            *
                        </Typography>
                    </>
                }
                placeholder="Please select"
            />

            <Grid container spacing={isMobile ? 1.5 : 4}>
                <Grid item xs={isMobile ? 12 : 6}>
                    <Box sx={{ position: 'relative' }}>
                        <InputLabel
                            sx={{
                                color: '#808080',
                                fontSize: '14px',
                                position: 'absolute',
                                top: '8px',
                                left: '16px',
                                zIndex: 1,
                                fontFamily: 'Open Sans'
                            }}
                        >
                            Location
                            <Typography
                                ml={0.25}
                                component="span"
                                color="#C3BCB6"
                                fontSize={14}
                                fontWeight={600}
                            >
                                *
                            </Typography>
                        </InputLabel>
                        <InputBase
                            required
                            value={workExperience.location}
                            onChange={handleChange}
                            inputProps={{
                                autoComplete: '',
                                form: {
                                    autoComplete: 'off'
                                }
                            }}
                            placeholder="Enter location"
                            name="location"
                            rows={1}
                            sx={{
                                backgroundColor: '#f2f2f2',
                                width: '100%',
                                height: '60px',
                                padding: '0px 16px',
                                fontFamily: 'open sans',
                                color: '#23282B',
                                borderBottom: '1px solid #D9D9D9',
                                mb: '8px',
                                '& 	.MuiInputBase-input': {
                                    position: 'relative',
                                    top: '8px'
                                }
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={isMobile ? 12 : 6}>
                    <CommonRadioDropdown
                        value={{
                            value: workExperience.locationType,
                            label: workExperience.locationType
                        }}
                        options={LOCATION_TYPE_OPTIONS}
                        onSelect={(val) => {
                            let item = {
                                target: {
                                    value: val,
                                    name: 'locationType'
                                }
                            };
                            handleChange(item);
                        }}
                        label={
                            <>
                                Location type
                                <Typography
                                    ml={0.25}
                                    component="span"
                                    color="#C3BCB6"
                                    fontSize={14}
                                    fontWeight={600}
                                >
                                    *
                                </Typography>
                            </>
                        }
                        placeholder="Please select"
                    />
                </Grid>
            </Grid>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={workExperience.isChecked}
                        onChange={handleChange}
                        name="isChecked"
                        color="success"
                    />
                }
                label="I am currently working in this role"
            />
            <Grid
                container
                gap={isMobile ? 1.5 : '32px'}
                alignItems="center"
                mb={'8px'}
                wrap="nowrap"
            >
                <Grid item xs={6} sx={{ position: 'relative' }} flexGrow={1}>
                    <Calendar
                        tab
                        defaultValue={dayjs(workExperience.startDate)}
                        label="Start Date"
                        handleChange={(e: dayjs.Dayjs | null) => {
                            let item = {
                                target: {
                                    value: dayjs(e),
                                    name: 'startDate'
                                }
                            };
                            handleChange(item);
                        }}
                    />
                </Grid>

                {/* <Box width={24} height={0} borderBottom="1px solid #D9D9D9" /> */}

                <Grid item xs={6} sx={{ position: 'relative' }} flexGrow={1}>
                    <Calendar
                        tab
                        label="End Date"
                        defaultValue={dayjs(workExperience.endDate)}
                        disabled={workExperience.isChecked}
                        handleChange={(e: dayjs.Dayjs | null) => {
                            let item = {
                                target: {
                                    value: dayjs(e),
                                    name: 'endDate'
                                }
                            };
                            handleChange(item);
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container gap={0.5} alignItems="center">
                <Box sx={{ position: 'relative' }} flexGrow={1}>
                    <InputLabel
                        sx={{
                            color: '#808080',
                            fontSize: '14px',
                            position: 'absolute',
                            top: '8px',
                            left: '16px',
                            zIndex: 1,
                            fontFamily: 'Open Sans'
                        }}
                        htmlFor="phone"
                    >
                        Description
                        <Typography
                            ml={0.25}
                            component="span"
                            color="#C3BCB6"
                            fontSize={14}
                            fontWeight={600}
                        >
                            *
                        </Typography>
                    </InputLabel>
                    <InputBase
                        required
                        multiline
                        value={workExperience.description}
                        placeholder="description"
                        onChange={handleChange}
                        inputProps={{
                            autoComplete: '',
                            form: {
                                autoComplete: 'off'
                            }
                        }}
                        name="description"
                        rows={5}
                        sx={{
                            backgroundColor: '#f2f2f2',
                            width: '100%',
                            minHeight: '60px',
                            fontFamily: 'open sans',
                            color: '#23282B',
                            borderBottom: '1px solid #D9D9D9',
                            mb: '10px',
                            '& 	.MuiInputBase-input': {
                                ml: '20px',
                                position: 'relative',
                                mt: '25px'
                            }
                        }}
                    />
                </Box>
            </Grid>
            <Typography
                mb="8px"
                textAlign="right"
                fontSize="16px"
                color="#808080"
            >{`${workExperience.description.length}/250`}</Typography>
            {/* <Grid container spacing={isMobile ? 1.5 : 5} alignItems="center">
                <Grid item xs={isMobile ? 12 : 6}> */}
            <CommonRadioDropdown
                value={{
                    value: workExperience.employmentLevel,
                    label: workExperience.employmentLevel
                }}
                options={EMPLOYMENT_LEVEL_OPTIONS}
                onSelect={(val) => {
                    let item = {
                        target: {
                            value: val,
                            name: 'employmentLevel'
                        }
                    };
                    handleChange(item);
                }}
                label={
                    <>
                        Employment Level
                        <Typography
                            ml={0.25}
                            component="span"
                            color="#C3BCB6"
                            fontSize={14}
                            fontWeight={600}
                        >
                            *
                        </Typography>
                    </>
                }
                placeholder="Please select"
            />
            <Box mb="20px">
                <InputLabel
                    required
                    sx={{
                        color: '#5B5B5B',
                        mb: '8px',
                        mt: '16px',
                        fontSize: { sm: 16, xs: 14 }
                    }}
                >
                    Skills
                    <Typography
                        ml={0.25}
                        component="span"
                        color="#C3BCB6"
                        fontSize={14}
                        fontWeight={600}
                    >
                        *
                    </Typography>
                </InputLabel>
                <Box
                    sx={{
                        position: 'relative'
                    }}
                >
                    <TextField
                        fullWidth
                        value={search}
                        variant="outlined"
                        className="search-field"
                        placeholder="Search skills"
                        InputProps={{ startAdornment: <SearchIcon /> }}
                        onChange={(e) => setSearch(e.target.value)}
                        sx={{
                            input: {
                                p: '10px 14px',
                                pl: '8px'
                            },
                            '& .MuiInputBase-root': {
                                fontSize: { sm: 16, xs: 14 }
                            }
                        }}
                    />
                    {skills.length > 0 && search.length > 2 ? (
                        <Paper
                            sx={{
                                maxHeight: '200px',
                                overflow: 'auto',
                                position: 'absolute',
                                left: 0,
                                right: 0,
                                zIndex: 999
                            }}
                        >
                            {skills.map((skill: ISkillType) => (
                                <Box
                                    key={skill.name}
                                    sx={{
                                        pl: '10px',
                                        cursor: 'pointer',
                                        borderRadius: '8px',
                                        '& :hover': {
                                            background: '#D9D9D9'
                                        }
                                    }}
                                >
                                    <Typography
                                        variant="titleMediumSemiBold"
                                        sx={{
                                            width: '100%',
                                            display: 'block',
                                            p: '5px',
                                            fontSize: { sm: 16, xs: 14 }
                                        }}
                                        // name="skills"
                                        onClick={() => {
                                            addSkill(skill);
                                            setSearch('');
                                        }}
                                    >
                                        {skill.name}
                                    </Typography>
                                </Box>
                            ))}
                        </Paper>
                    ) : null}
                </Box>
                <Box
                    sx={{
                        background: '#FAFAFA',
                        border: '1px solid #C5C5C5',
                        borderRadius: '8px',
                        mt: '12px',
                        p: { xs: '8px 12px 20px' }
                    }}
                >
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Typography
                            variant="titleMediumSemiBold"
                            color={'#5B5B5B'}
                            fontSize={{ sm: 16, xs: 14 }}
                            fontWeight={{ sm: 600, xs: 400 }}
                        >
                            Suggested based on your profile
                        </Typography>
                        <CloseXIcon />
                    </Box>

                    <Box
                        sx={{
                            mt: { xs: '12px' },
                            display: 'flex',
                            alignItems: 'center',
                            gap: { sm: '12px', xs: '8px' },
                            flexWrap: 'wrap'
                        }}
                    >
                        {suggestedSkills.map((item: ISkillType) => (
                            <Chip
                                key={item.name}
                                label={item.name}
                                variant="outlined"
                                sx={{
                                    fontWeight: { sm: 600, xs: 400 },
                                    fontSize: { sm: '14px', xs: 12 },
                                    color: '#808080',
                                    border: '1px solid #C5C5C5',
                                    cursor: 'pointer'
                                }}
                                deleteIcon={<PlusIcon />}
                                // name="skills"
                                onClick={() => addSkill(item)}
                            />
                        ))}
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: selectedSkills.length > 0 ? 'block' : 'none',
                        background: '#f2f2f2',
                        border: '1px solid #C5C5C5',
                        borderRadius: '8px',
                        mt: { sm: '16px', xs: '8px' },
                        p: { xs: '8px 12px 20px' }
                    }}
                >
                    <Typography
                        variant="titleMediumSemiBold"
                        fontSize={{ sm: 16, xs: 14 }}
                        fontWeight={{ sm: 600, xs: 400 }}
                    >
                        Selected skills
                    </Typography>

                    <Box
                        sx={{
                            mt: { xs: '12px' },
                            display: 'flex',
                            alignItems: 'center',
                            gap: { sm: '12px', xs: '8px' },
                            flexWrap: 'wrap'
                        }}
                    >
                        {selectedSkills.map(
                            (item: ISkillType, index: number) => (
                                <Chip
                                    key={`chip_${index}`}
                                    label={item?.name}
                                    sx={{
                                        fontWeight: { sm: 600, xs: 400 },
                                        fontSize: { sm: '16px', xs: 12 },
                                        color: '#05668D',
                                        background: '#E6F0F4',
                                        border: '1px solid #05668D',
                                        '& svg': {
                                            color: '#494949 !important',
                                            fontSize: {
                                                sm: 22,
                                                xs: '18px !important'
                                            }
                                        }
                                    }}
                                    // onDelete={() =>
                                    //     setSelectedSkills(
                                    //         selectedSkills.filter(
                                    //             (x: ISkillType) =>
                                    //                 x.id !== item?.id
                                    //         )
                                    //     )
                                    // }
                                    deleteIcon={<Close />}
                                />
                            )
                        )}
                    </Box>
                </Box>
            </Box>
            <Box>
                <Button
                    startIcon={<PlusIcon />}
                    variant="contained"
                    sx={{
                        width: '135px',
                        height: '36px',
                        textTransform: 'none',
                        bgcolor: '#f2f2f2',
                        borderRadius: '8px',
                        color: '#05668D',
                        fontSize: '14px',
                        fontWeight: 600,
                        mb: '12px'
                    }}
                >
                    Add Media
                </Button>
                <Typography fontSize="14px" color="#23282B">
                    Add or link to external documents, photos, sites, videos,
                    and presentations.
                </Typography>
            </Box>
            {/* </Grid>
                <Grid item xs={isMobile ? 12 : 6}></Grid>
            </Grid> */}
        </>
    );
};

export default WorkExperienceForm;
