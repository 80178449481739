import { Typography, TypographyProps } from '@mui/material';
import React from 'react';

interface SectionHeadingProps extends TypographyProps {
    disable?: boolean;
}

const SectionHeading: React.FC<SectionHeadingProps> = ({
    disable,
    children,
    ...typographyProps
}) => (
    <Typography
        style={{
            color: '#23282B',
            fontFamily: 'open sans',
            fontSize: 20,
            fontWeight: 600,
            marginLeft: 5,
            opacity: disable ? 0.5 : 1,
            pointerEvents: disable ? 'none' : 'auto',
            ...typographyProps.style
        }}
        {...typographyProps}
    >
        {children}
    </Typography>
);

export default SectionHeading;
