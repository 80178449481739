import { Box, Chip, Typography } from '@mui/material';
import React from 'react';
import { Responsiveness } from '../../../theme/overrides';

const SkillsChips = ({ skills = [] }) => {
    const { isMobile, isTablet } = Responsiveness()
    const handleClick = () => {};
    const handleDelete = () => {};
    return (
        <Box
            style={{
                display: 'flex',
                padding: isMobile ? '16px' : isTablet ? '16px 20px' : '8px 40px 20px 40px',
                flexDirection: ' column',
                alignItems: 'flex-start',
                gap: '20px',
                marginTop: '20px',
                backgroundColor: 'white'
            }}
        >
            <Box
                sx={{
                    backgroundColor: 'white',
                    display: 'flex',
                    padding: '16px 0px',
                    alignItems: 'center',
                    gap: '20px'
                }}
            >
                <Typography variant="titleLargeSemiBold">Skills</Typography>
            </Box>

            <Box
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '10px'
                }}
            >
                {skills?.map((skill) => (
                    <Chip
                        sx={{
                            backgroundColor: '#FFF9ED',
                            fontSize: '16px',
                            fontWeight: 600
                        }}
                        key={skill?.name}
                        label={skill?.name}
                        variant="outlined"
                        onClick={handleClick}
                        onDelete={handleDelete}
                    />
                ))}
            </Box>
        </Box>
    );
};

export default SkillsChips;
