import { Autocomplete, CircularProgress, SxProps, TextField, Theme, createFilterOptions } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useGetAllCompaniesBySearchTerm } from '../../utils/hooks/api/company-profile/useGetAllCompaniesBySearchTerm';
import { Address } from '../../utils/hooks/api/users/types';

interface IPropsTypes {
  label: string;
  onSelect: (val: any) => void;
  inputStyle?: SxProps<Theme> | undefined;
  defaultValue?: any;
  startAdornment?: any
  placeHolder?: string;
  creatable?: boolean;
}
interface GetAllCompaniesBySearchTermResponse {
  id?: string;
  emailAddress: string;
  primaryCompanyName: string | null;
  isEnabled?: boolean;
  createdAt?: number;
  lastUpdatedAt?: number;
  userId?: string | null;
  primaryCompanyId?: string | null;
  logo?: string;
  address?: Address;
  score?: number;
  phoneNumber?: string;
}
const filter = createFilterOptions<GetAllCompaniesBySearchTermResponse>();

const CompaniesAutocomplete = ({ label, onSelect, inputStyle, defaultValue, startAdornment = null, placeHolder = '', creatable = false }: IPropsTypes) => {
  const [search, setSearch] = useState('');
  const [companies, setCompanies] = useState<GetAllCompaniesBySearchTermResponse[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<GetAllCompaniesBySearchTermResponse | null>(null);

  const { data = [], refetch, isFetching } = useGetAllCompaniesBySearchTerm(search);

  useEffect(() => {
    if (search.length > 2) {
      refetch();
    } else {
      setCompanies([]);
    }
    if (data && data?.length > 0) {
      setCompanies(data);
    } else {
      setCompanies([]);
    }
  }, [search, data])

  useEffect(() => {
    if (defaultValue) {
      setSelectedCompany(defaultValue);
      setCompanies([defaultValue]);
    }
  }, [defaultValue])

  if (creatable) {
    return <Autocomplete
      value={selectedCompany}
      onBlur={() => setCompanies([])}
      onChange={(event, newValue: any) => {
        if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          let temp = {
            primaryCompanyName: newValue.inputValue,
            emailAddress: '',
          }
          setSelectedCompany(temp);
          onSelect(temp);
        } else {
          setSelectedCompany(newValue);
          onSelect(newValue);
        }
        setSearch('');
        setCompanies([]);
      }}
      filterOptions={(options, params) => {
        const filtered: any = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.primaryCompanyName);
        if (inputValue !== '' && !isExisting && !isFetching) {
          filtered.push({
            inputValue,
            primaryCompanyName: `Add "${inputValue}"`,
          });
        }
        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={companies}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.primaryCompanyName;
      }}
      onInputChange={(e, newInputValue) => { setSearch(newInputValue); if (!newInputValue) { setCompanies([]) } }}
      renderOption={(props, option) => <li {...props}>{option.primaryCompanyName}</li>}
      sx={{
        width: '100%',
        fontFamily: 'open sans',
        ...inputStyle,
        '& fieldset': {
          border: 0,
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: 0,
        }
      }}
      loading={isFetching}
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          InputLabelProps={{
            shrink: true
          }}
          placeholder={placeHolder}
          InputProps={{
            ...params.InputProps,
            startAdornment: startAdornment,
            endAdornment: (
              <React.Fragment>
                {isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  }
  return (
    <Autocomplete
      sx={{
        width: '100%',
        fontFamily: 'open sans',
        ...inputStyle,
        '& fieldset': {
          border: 0,
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: 0,
        }
      }}
      onInputChange={(_, newInputValue) => setSearch(newInputValue)}
      value={selectedCompany}
      onChange={(_, newValue) => {
        setSearch('');
        setCompanies([]);
        setSelectedCompany(newValue);
        onSelect(newValue);
      }}
      isOptionEqualToValue={(option, value) => option.primaryCompanyId === value.primaryCompanyId}
      getOptionLabel={(option) => option.primaryCompanyName!}
      options={companies}
      loading={isFetching}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{
            shrink: true
          }}
          placeholder={placeHolder}
          label={label}
          InputProps={{
            ...params.InputProps,
            startAdornment: startAdornment,
            endAdornment: (
              <React.Fragment>
                {isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}

export default CompaniesAutocomplete;