import axios, { AxiosError } from 'axios';
import { UserInfoOAuth } from '../types/userInfo';
import { UseGoogleLoginOptions, useGoogleLogin } from '@react-oauth/google';

interface useGoogleAuthType {
    onSuccess: (userInfo: UserInfoOAuth) => void;
    onError?: UseGoogleLoginOptions['onError'];
}

export const useGoogleAuth = ({ onSuccess, onError }: useGoogleAuthType) => {
    // Ref: https://www.npmjs.com/package/@react-oauth/google
    return useGoogleLogin({
        flow: 'implicit',
        onSuccess: async (tokenResponse) => {
            try {
                const res = await axios.get(
                    process.env.REACT_APP_GOOGLE_OAUTH_URL as string,
                    {
                        headers: {
                            Authorization: `Bearer ${tokenResponse?.access_token}`
                        }
                    }
                );
                onSuccess(res.data as UserInfoOAuth);
            } catch (err) {
                const error = err as AxiosError;
                onError?.({
                    error_description: error.message
                });
            }
        },
        onError: (error) => {
            onError?.(error);
        }
    });
};
