import axios from 'axios';

const externalAxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_CITY_STATE_API_URL,
    headers: { 'X-CSCAPI-KEY': process.env.REACT_APP_CITY_STATE_API_KEY }
});

externalAxiosInstance.interceptors.request.use((config) => {
    document.body.classList.add('loading');
    return config;
});

externalAxiosInstance.interceptors.response.use(
    (response) => {
        document.body.classList.remove('loading');
        return response;
    },
    (error) => {
        document.body.classList.remove('loading');
        return Promise.reject(error);
    }
);
export default externalAxiosInstance;
