import { useQuery } from 'react-query';
import { QueryKeys } from '../QueryKey';
import axiosInstance from '../axiosConfig';

export function useGetEducationById(educationId: string, enabled: boolean) {
    const getEducationById = async (educationId: string) => {
        const response = await axiosInstance.get(
            `/api/educations/${educationId}`
        );
        const data = await response.data;
        return data;
    };
    return useQuery(
        QueryKeys.RetrieveEducationById,
        () => getEducationById(educationId),
        {
            enabled: educationId && enabled ? true : false
        }
    );
}
