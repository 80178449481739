import Box from '@mui/material/Box';
import Header from '../components/JobApplication/Header';
import { useAuth } from '../../../utils/context/AuthContext';
import ApplicationList from '../components/JobApplication/ApplicationList';
import { useGetAllApplicantsByCompanyId } from '../../../utils/hooks/api/jobs/useGetAllApplicantsByCompanyId';
import MobileNav from '../../../components/MobileNav/MobileNav';

const JobApplications = () => {
    const { user } = useAuth();
    const companyId = user?.primaryCompanyProfile?.companyId ?? '';
    const { data: applicants, isFetching } =
        useGetAllApplicantsByCompanyId(companyId);
    
     const route = [
         {
             label: 'Job Postings',
             route: '/job-posting'
         },
         {
             label: 'Applications',
             route: '/job-applications'
         },
         {
             label: 'Saved Applications',
             route: '/save-applications'
         }
     ];

    return (
        <Box
            sx={{
                mt: { xs: '21px', sm: 0 },
                pr: { xs: 0, sm: '20px', lg: '40px' }
            }}
        >
            <Box display={{ xs: 'block', sm: 'none' }}>
                <MobileNav list={route} />
            </Box>
            <Header />
            <Box display={{ xs: 'none', sm: 'block', lg: 'none' }}>
                <MobileNav list={route} />
            </Box>
            {isFetching ? null : (
                <ApplicationList
                    data={applicants?.length > 0 ? applicants : []}
                    showMetrics
                    showJobTitle
                />
            )}
        </Box>
    );
};

export default JobApplications;
