import { Box } from '@mui/material';
import React from 'react';

function Settings(props: { isHover?: boolean; isSelected?: boolean }) {
    const { isHover, isSelected } = props;
    if (isHover || isSelected) {
        return (
            <>
                <Box display={{ xs: 'none', lg: 'block' }}>
                    <svg
                        width="21"
                        height="20"
                        viewBox="0 0 21 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M18.1 7.21945C16.29 7.21945 15.55 5.93945 16.45 4.36945C16.97 3.45945 16.66 2.29945 15.75 1.77945L14.02 0.789448C13.23 0.319448 12.21 0.599448 11.74 1.38945L11.63 1.57945C10.73 3.14945 9.25 3.14945 8.34 1.57945L8.23 1.38945C7.78 0.599448 6.76 0.319448 5.97 0.789448L4.24 1.77945C3.33 2.29945 3.02 3.46945 3.54 4.37945C4.45 5.93945 3.71 7.21945 1.9 7.21945C0.86 7.21945 0 8.06945 0 9.11945V10.8794C0 11.9194 0.85 12.7794 1.9 12.7794C3.71 12.7794 4.45 14.0594 3.54 15.6294C3.02 16.5394 3.33 17.6994 4.24 18.2194L5.97 19.2094C6.76 19.6794 7.78 19.3995 8.25 18.6094L8.36 18.4194C9.26 16.8494 10.74 16.8494 11.65 18.4194L11.76 18.6094C12.23 19.3995 13.25 19.6794 14.04 19.2094L15.77 18.2194C16.68 17.6994 16.99 16.5294 16.47 15.6294C15.56 14.0594 16.3 12.7794 18.11 12.7794C19.15 12.7794 20.01 11.9294 20.01 10.8794V9.11945C20 8.07945 19.15 7.21945 18.1 7.21945ZM10 13.2494C8.21 13.2494 6.75 11.7894 6.75 9.99945C6.75 8.20945 8.21 6.74945 10 6.74945C11.79 6.74945 13.25 8.20945 13.25 9.99945C13.25 11.7894 11.79 13.2494 10 13.2494Z"
                            fill="#05668D"
                        />
                    </svg>
                </Box>
                <Box display={{ xs: 'block', lg: 'none' }}>
                    <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M30.15 13.8301C27.435 13.8301 26.325 11.9101 27.675 9.55515C28.455 8.19015 27.99 6.45015 26.625 5.67015L24.03 4.18515C22.845 3.48015 21.315 3.90015 20.61 5.08515L20.445 5.37015C19.095 7.72515 16.875 7.72515 15.51 5.37015L15.345 5.08515C14.67 3.90015 13.14 3.48015 11.955 4.18515L9.36 5.67015C7.995 6.45015 7.53 8.20515 8.31 9.57015C9.675 11.9101 8.565 13.8301 5.85 13.8301C4.29 13.8301 3 15.1051 3 16.6801V19.3201C3 20.8801 4.275 22.1701 5.85 22.1701C8.565 22.1701 9.675 24.0901 8.31 26.4451C7.53 27.8101 7.995 29.5501 9.36 30.3301L11.955 31.8151C13.14 32.5201 14.67 32.1002 15.375 30.9151L15.54 30.6301C16.89 28.2752 19.11 28.2752 20.475 30.6301L20.64 30.9151C21.345 32.1002 22.875 32.5201 24.06 31.8151L26.655 30.3301C28.02 29.5501 28.485 27.7951 27.705 26.4451C26.34 24.0901 27.45 22.1701 30.165 22.1701C31.725 22.1701 33.015 20.8951 33.015 19.3201V16.6801C33 15.1201 31.725 13.8301 30.15 13.8301ZM18 22.8751C15.315 22.8751 13.125 20.6851 13.125 18.0001C13.125 15.3151 15.315 13.1251 18 13.1251C20.685 13.1251 22.875 15.3151 22.875 18.0001C22.875 20.6851 20.685 22.8751 18 22.8751Z"
                            fill="#05668D"
                        />
                    </svg>
                </Box>
            </>
        );
    }
    return (
        <>
            <Box display={{ xs: 'none', lg: 'block' }}>
                <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z"
                        stroke="#808080"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M1.66797 10.7339V9.26718C1.66797 8.40052 2.3763 7.68385 3.2513 7.68385C4.75964 7.68385 5.3763 6.61718 4.61797 5.30885C4.18464 4.55885 4.44297 3.58385 5.2013 3.15052L6.64297 2.32552C7.3013 1.93385 8.1513 2.16718 8.54297 2.82552L8.63464 2.98385C9.38464 4.29218 10.618 4.29218 11.3763 2.98385L11.468 2.82552C11.8596 2.16718 12.7096 1.93385 13.368 2.32552L14.8096 3.15052C15.568 3.58385 15.8263 4.55885 15.393 5.30885C14.6346 6.61718 15.2513 7.68385 16.7596 7.68385C17.6263 7.68385 18.343 8.39218 18.343 9.26718V10.7339C18.343 11.6005 17.6346 12.3172 16.7596 12.3172C15.2513 12.3172 14.6346 13.3838 15.393 14.6922C15.8263 15.4505 15.568 16.4172 14.8096 16.8505L13.368 17.6755C12.7096 18.0672 11.8596 17.8339 11.468 17.1755L11.3763 17.0172C10.6263 15.7089 9.39297 15.7089 8.63464 17.0172L8.54297 17.1755C8.1513 17.8339 7.3013 18.0672 6.64297 17.6755L5.2013 16.8505C4.44297 16.4172 4.18464 15.4422 4.61797 14.6922C5.3763 13.3838 4.75964 12.3172 3.2513 12.3172C2.3763 12.3172 1.66797 11.6005 1.66797 10.7339Z"
                        stroke="#808080"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </Box>
            <Box display={{ xs: 'block', lg: 'none' }}>
                <svg
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M30.15 13.8301C27.435 13.8301 26.325 11.9101 27.675 9.55515C28.455 8.19015 27.99 6.45015 26.625 5.67015L24.03 4.18515C22.845 3.48015 21.315 3.90015 20.61 5.08515L20.445 5.37015C19.095 7.72515 16.875 7.72515 15.51 5.37015L15.345 5.08515C14.67 3.90015 13.14 3.48015 11.955 4.18515L9.36 5.67015C7.995 6.45015 7.53 8.20515 8.31 9.57015C9.675 11.9101 8.565 13.8301 5.85 13.8301C4.29 13.8301 3 15.1051 3 16.6801V19.3201C3 20.8801 4.275 22.1701 5.85 22.1701C8.565 22.1701 9.675 24.0901 8.31 26.4451C7.53 27.8101 7.995 29.5501 9.36 30.3301L11.955 31.8151C13.14 32.5201 14.67 32.1002 15.375 30.9151L15.54 30.6301C16.89 28.2752 19.11 28.2752 20.475 30.6301L20.64 30.9151C21.345 32.1002 22.875 32.5201 24.06 31.8151L26.655 30.3301C28.02 29.5501 28.485 27.7951 27.705 26.4451C26.34 24.0901 27.45 22.1701 30.165 22.1701C31.725 22.1701 33.015 20.8951 33.015 19.3201V16.6801C33 15.1201 31.725 13.8301 30.15 13.8301ZM18 22.8751C15.315 22.8751 13.125 20.6851 13.125 18.0001C13.125 15.3151 15.315 13.1251 18 13.1251C20.685 13.1251 22.875 15.3151 22.875 18.0001C22.875 20.6851 20.685 22.8751 18 22.8751Z"
                        fill="#AAAAAA"
                    />
                </svg>
            </Box>
        </>
    );
}

export default Settings;
