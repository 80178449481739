import { useQuery } from 'react-query';
import { QueryKeys } from '../QueryKey';
import axiosInstance from '../axiosConfig';

export function useGetProfessionalProfileScore(id: string) {
    const getProfessionalProfileScore = async (id: string) => {
        const response = await axiosInstance.get(
            `/api/professional-profiles/${id}/score`
        );
        return response.data;
    };
    return useQuery(
        [QueryKeys.RetrieveProfessionalProfileScore, id],
        () => getProfessionalProfileScore(id),
        {
            refetchOnMount: 'always'
        }
    );
}
