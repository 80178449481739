import axiosInstance from '../axiosConfig';
import { UpdateReviewRequest } from './types';
import { UseMutationResult, useMutation } from 'react-query';

async function updateReviewRequest(postRequest: UpdateReviewRequest) {
    const response = await axiosInstance.patch(
        `/api/professional-reviews-requests/${postRequest.id}`,
        postRequest
    );
    const data = await response.data;
    return data;
}
export function useUpdateReviewRequest(): UseMutationResult<
    unknown,
    unknown,
    UpdateReviewRequest
> {
    return useMutation(updateReviewRequest);
}
