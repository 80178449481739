import { Theme } from '@mui/material/styles';
//
import Button from './Button';
import { useMediaQuery, useTheme } from '@mui/material';

// ----------------------------------------------------------------------

export default function ComponentsOverrides(theme: Theme) {
    return Object.assign(Button(theme));
}

export const Responsiveness = () => {
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isXMobile = useMediaQuery('(max-width: 384px)');
    return { isTablet, isMobile, isXMobile };
};
