import React from 'react';

const JobSalaryIcon = () => {
    return (
        <svg
            width="18"
            height="16"
            viewBox="0 0 18 16"
            fill="#4DA818"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.9758 3.53359C14.6174 1.72526 13.2758 0.933594 11.4091 0.933594H4.09245C1.89245 0.933594 0.425781 2.03359 0.425781 4.60026V8.89193C0.425781 10.7419 1.18411 11.8253 2.43411 12.2919C2.61745 12.3586 2.81745 12.4169 3.02578 12.4503C3.35911 12.5253 3.71745 12.5586 4.09245 12.5586H11.4174C13.6174 12.5586 15.0841 11.4586 15.0841 8.89193V4.60026C15.0841 4.20859 15.0508 3.85859 14.9758 3.53359ZM3.60911 8.00026C3.60911 8.34193 3.32578 8.62526 2.98411 8.62526C2.64245 8.62526 2.35911 8.34193 2.35911 8.00026V5.50026C2.35911 5.15859 2.64245 4.87526 2.98411 4.87526C3.32578 4.87526 3.60911 5.15859 3.60911 5.50026V8.00026ZM7.75078 8.95026C6.53411 8.95026 5.55078 7.96693 5.55078 6.75026C5.55078 5.53359 6.53411 4.55026 7.75078 4.55026C8.96745 4.55026 9.95078 5.53359 9.95078 6.75026C9.95078 7.96693 8.96745 8.95026 7.75078 8.95026ZM13.1341 8.00026C13.1341 8.34193 12.8508 8.62526 12.5091 8.62526C12.1674 8.62526 11.8841 8.34193 11.8841 8.00026V5.50026C11.8841 5.15859 12.1674 4.87526 12.5091 4.87526C12.8508 4.87526 13.1341 5.15859 13.1341 5.50026V8.00026Z"
                fill="#4DA818"
            />
            <path
                d="M17.5841 7.10056V11.3922C17.5841 13.9589 16.1175 15.0672 13.9091 15.0672H6.59245C5.96745 15.0672 5.40912 14.9756 4.92578 14.7922C4.53412 14.6506 4.19245 14.4422 3.91745 14.1756C3.76745 14.0339 3.88412 13.8089 4.09245 13.8089H11.4091C14.4925 13.8089 16.3258 11.9756 16.3258 8.90056V4.60056C16.3258 4.40056 16.5508 4.27556 16.6925 4.42556C17.2591 5.02556 17.5841 5.90056 17.5841 7.10056Z"
                fill="#4DA818"
            />
        </svg>
    );
};

export default JobSalaryIcon;
