import { Rating, styled } from '@mui/material';
import StarIcon from '../../../assets/icons/StarIcon';
import { useEffect, useState } from 'react';
import { getAvgRatingLevel, getRatingLevel } from './functions';
import { IRatingType } from '../types';

interface IPropTypes {
    level?: number;
    readOnly?: boolean;
    item?: IRatingType;
}
const StarRating = ({ level = 40, readOnly = false, item }: IPropTypes) => {
    const [color, setColor] = useState('');
    const [stars, setStars] = useState(2);
    const StyledRating = styled(Rating)(({ theme }) => ({
        gap: '15px',
        '& .MuiRating-iconEmpty svg path': {
            fill: 'none',
            stroke: theme.palette.action.disabled,
        },
        '& .MuiRating-iconFilled svg path': {
            fill: color,
            stroke: color,
        },
        '& .MuiRating-iconHover svg path': {
            fill: color,
            stroke: color,
        },
    }));

    useEffect(() => {
        if (item) {
            const { color, stars } = getRatingLevel(item);
            setColor(color);
            setStars(stars);
            return;
        }
        if (level) {
            const { color, stars } = getAvgRatingLevel(level);
            setColor(color);
            setStars(stars);
        }
    }, [level, item])

    return (
        <StyledRating
            readOnly={readOnly}
            defaultValue={stars}
            IconContainerComponent={(props: any) => <span {...props}><StarIcon /></span>}
        />
    )
}

export default StarRating