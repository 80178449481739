import {
    IEvidenceType,
    IConstantUserType,
    ICompanyType,
    IReviewType
} from './types';
import image from '../../assets/images/feed2.png';

export enum ExperienceLevelTypes {
    beginner = 'beginner',
    experienced = 'experienced',
    advanced = 'advanced',
    expert = 'expert',
    thought_leader = 'thought_leader'
}

export enum ExperienceLevelRatings {
    beginner = 2,
    experienced = 4,
    advanced = 6,
    expert = 8,
    thought_leader = 10
}

export enum CompanyHighScoringLevels {
    poor = 20,
    fair = 40,
    good = 60,
    very_good = 80,
    best_in_class = 100
}

export enum CompanyLowScoringLevels {
    poor = 10,
    fair = 20,
    good = 30,
    very_good = 40,
    best_in_class = 50
}

export const experienceLevels = [
    { color: '#E75541', title: 'Beginner', shortForm: 'B', key: 2 },
    {
        color: '#F6C70E',
        title: 'Experienced',
        shortForm: 'E',
        key: 4
    },
    { color: '#49B6FF', title: 'Advanced', shortForm: 'A', key: 6 },
    { color: '#95C97A', title: 'Expert', shortForm: 'X', key: 8 },
    {
        color: '#07AF22',
        title: 'Thought Leader',
        shortForm: 'L',
        key: 10
    }
];

export const CompanyLevels = [
    'Poor', 'Fair', 'Good', 'Very Good', 'Best in Class'
];

export const CompanyScoringAttributes = [
    {
        name: 'Compensation and Benefits',
        rating: 0,
        witness: false,
        scoringLevel: CompanyHighScoringLevels
    },
    {
        name: 'Technology and Innovation',
        rating: 0,
        witness: false,
        scoringLevel: CompanyLowScoringLevels
    },
    {
        name: 'Office environment (Remote included)',
        rating: 0,
        witness: false,
        scoringLevel: CompanyLowScoringLevels
    },
    {
        name: 'Training and Skill development',
        rating: 0,
        witness: false,
        scoringLevel: CompanyHighScoringLevels
    },
    {
        name: 'Career growth opportunity',
        rating: 0,
        witness: false,
        scoringLevel: CompanyHighScoringLevels
    },
    {
        name: 'Reward and Recognition',
        rating: 0,
        witness: false,
        scoringLevel: CompanyHighScoringLevels
    },
    {
        name: 'Work / Life balance',
        rating: 0,
        witness: false,
        scoringLevel: CompanyHighScoringLevels
    },
    {
        name: 'Community engagement and Charitable giving',
        rating: 0,
        witness: false,
        scoringLevel: CompanyLowScoringLevels
    },
    {
        name: 'Company size',
        rating: 0,
        witness: false,
        scoringLevel: CompanyLowScoringLevels
    },
    {
        name: 'Job security',
        rating: 0,
        witness: false,
        scoringLevel: CompanyHighScoringLevels
    },
    {
        name: 'Diversity and Inclusion',
        rating: 0,
        witness: false,
        scoringLevel: CompanyHighScoringLevels
    },
    {
        name: 'Management transparency',
        rating: 0,
        witness: false,
        scoringLevel: CompanyLowScoringLevels
    },
];

export const criticalData: IEvidenceType[] = [
    { name: 'Strategic thinking', rating: 1 },
    { name: 'Quantitative analysis', rating: 1 },
    { name: 'Problem-solving skills', rating: 1 }
];

export const persuasiveData: IEvidenceType[] = [
    { name: 'Speaking', rating: 1 },
    { name: 'Listening', rating: 1 },
    { name: 'Writing', rating: 1 },
    { name: 'Fluency in digital communication', rating: 1 },
    { name: 'Presenting', rating: 1 },
    { name: 'Personal branding', rating: 1 },
    { name: 'Body language', rating: 1 },
    { name: 'Emotional intelligence', rating: 1 }
];

export const personalData: IEvidenceType[] = [
    { name: 'Time management', rating: 1 },
    { name: 'Prioritization and Organization', rating: 1 },
    { name: 'Multitasking', rating: 1 }
];

export const manageabilityData: IEvidenceType[] = [
    { name: 'Taking direction', rating: 1 },
    { name: 'Listening', rating: 1 },
    { name: 'Implementation', rating: 1 }
];

export const leadershipData: IEvidenceType[] = [
    { name: 'Leading by example', rating: 1 },
    { name: 'Applying other behaviours to job deliverables', rating: 1 }
];

export const creativityData: IEvidenceType[] = [
    { name: 'General creativity', rating: 1 }
];

export const data: IConstantUserType[] = [
    {
        image,
        name: 'Devon Lane',
        designation: 'Sales Manager',
        organization: 'Xtera Solutions',
        connections: 500,
        points: '550',
        address: 'North Carolina, U.S.A.',
        skills: [
            { background: '#95C97A', title: 'Wireframing', shortForm: 'X' },
            { background: '#49B6FF', title: 'Figma', shortForm: 'A' }
        ],
        id: 1
    },
    {
        image,
        name: 'Aevon Lane',
        designation: 'Sales Manager',
        organization: 'Xtera Solutions',
        connections: 300,
        points: '550',
        address: 'North Carolina, U.S.A.',
        id: 2,
        skills: [
            { background: '#808080', title: 'Restful API', shortForm: 'B' },
            { background: '#07AF22', title: 'Django', shortForm: 'T' }
        ]
    },
    {
        image,
        name: 'Devon Lane',
        designation: 'Sales Manager',
        organization: 'Xtera Solutions',
        address: 'North Carolina, U.S.A.',
        id: 3,
        connections: 200,
        skills: [
            { background: '#F6C70E', title: 'HTML', shortForm: 'E' },
            { background: '#E75541', title: 'Figma', shortForm: 'B' }
        ]
    }
];

export const myConnections: IConstantUserType[] = [
    {
        image,
        name: 'Annette Black',
        designation: 'Sales Manager',
        organization: 'Xtera Solutions',
        points: '550',
        skills: [
            { background: '#95C97A', title: 'Wireframing', shortForm: 'X' },
            { background: '#49B6FF', title: 'Figma', shortForm: 'A' }
        ]
    },
    {
        image,
        name: 'Arlene McCoy',
        designation: 'Sales Manager',
        organization: 'Utilitech',
        points: '550',
        skills: [
            { background: '#808080', title: 'Restful API', shortForm: 'B' },
            { background: '#07AF22', title: 'Django', shortForm: 'T' }
        ]
    },
    {
        image,
        name: 'Bessie Cooper',
        designation: 'Sales Manager',
        organization: 'Xtera Solutions',
        skills: [
            { background: '#F6C70E', title: 'HTML', shortForm: 'E' },
            { background: '#E75541', title: 'Figma', shortForm: 'B' }
        ]
    },
    {
        image,
        name: 'Cody Fisher',
        designation: 'Internet Security Assistant',
        organization: 'Bosch',
        points: '550',
        skills: [
            { background: '#95C97A', title: 'Wireframing', shortForm: 'X' },
            { background: '#49B6FF', title: 'Figma', shortForm: 'A' }
        ]
    },
    {
        image,
        name: 'Darlene Robertson',
        designation: 'Sales Manager',
        organization: 'Utilitech',
        points: '550',
        skills: [
            { background: '#808080', title: 'Restful API', shortForm: 'B' },
            { background: '#07AF22', title: 'Django', shortForm: 'T' }
        ]
    },
    {
        image,
        name: 'Esther Howard',
        designation: 'Sales Manager',
        organization: 'Xtera Solutions',
        skills: [
            { background: '#F6C70E', title: 'HTML', shortForm: 'E' },
            { background: '#E75541', title: 'Figma', shortForm: 'B' }
        ]
    },
    {
        image,
        name: 'Annette Black',
        designation: 'Sales Manager',
        organization: 'Xtera Solutions',
        points: '550',
        skills: [
            { background: '#95C97A', title: 'Wireframing', shortForm: 'X' },
            { background: '#49B6FF', title: 'Figma', shortForm: 'A' }
        ]
    },
    {
        image,
        name: 'Arlene McCoy',
        designation: 'Sales Manager',
        organization: 'Utilitech',
        points: '550',
        skills: [
            { background: '#808080', title: 'Restful API', shortForm: 'B' },
            { background: '#07AF22', title: 'Django', shortForm: 'T' }
        ]
    },
    {
        image,
        name: 'Bessie Cooper',
        designation: 'Sales Manager',
        organization: 'Xtera Solutions',
        skills: [
            { background: '#F6C70E', title: 'HTML', shortForm: 'E' },
            { background: '#E75541', title: 'Figma', shortForm: 'B' }
        ]
    },
    {
        image,
        name: 'Cody Fisher',
        designation: 'Internet Security Assistant',
        organization: 'Bosch',
        points: '550',
        skills: [
            { background: '#95C97A', title: 'Wireframing', shortForm: 'X' },
            { background: '#49B6FF', title: 'Figma', shortForm: 'A' }
        ]
    },
    {
        image,
        name: 'Darlene Robertson',
        designation: 'Sales Manager',
        organization: 'Utilitech',
        points: '550',
        skills: [
            { background: '#808080', title: 'Restful API', shortForm: 'B' },
            { background: '#07AF22', title: 'Django', shortForm: 'T' }
        ]
    },
    {
        image,
        name: 'Esther Howard',
        designation: 'Sales Manager',
        organization: 'Xtera Solutions',
        skills: [
            { background: '#F6C70E', title: 'HTML', shortForm: 'E' },
            { background: '#E75541', title: 'Figma', shortForm: 'B' }
        ]
    }
];

export const myCompany: ICompanyType[] = [
    {
        id: 'a',
        image,
        title: 'Google',
        type: 'Tech Company',
        point: '700',
        role: [
            {
                background: '#E75541',
                title: 'Office Management',
                shortForm: 'B'
            },
            { background: '#F6C70E', title: 'Mobile Int', shortForm: 'E' },
            {
                background: '#49B6FF',
                title: 'Customer Experience Design',
                shortForm: 'A'
            },
            { background: '#95C97A', title: 'Expert', shortForm: 'X' }
        ]
    },
    {
        id: 'b',
        image,
        title: 'Google',
        type: 'Tech Company',
        point: '700',
        role: [
            {
                background: '#95C97A',
                title: 'Office environment',
                shortForm: 'X'
            },
            { background: '#F6C70E', title: 'Mobile Int', shortForm: 'E' },
            {
                background: '#49B6FF',
                title: 'Customer Experience Design',
                shortForm: 'A'
            },
            { background: '#95C97A', title: 'Expert', shortForm: 'X' }
        ]
    }
];

export const myReview: IReviewType[] = [
    {
        id: 1,
        title: 'Compensation and Benefits'
    },
    {
        id: 2,
        title: 'Technology and Innovation'
    },
    {
        id: 3,
        title: 'Office environment(Remote Included)'
    },
    {
        id: 4,
        title: 'Training and Skill developement'
    },
    {
        id: 5,
        title: 'career growth opportunity'
    },
    {
        id: 6,
        title: 'Reward and Recognition'
    },
    {
        id: 7,
        title: 'Work/Life balance'
    },
    {
        id: 8,
        title: 'Community engagement and Charitable giving'
    },
    {
        id: 9,
        title: 'Company size'
    },
    {
        id: 10,
        title: 'Job security'
    },
    {
        id: 11,
        title: 'Diversity and Inclusion'
    },
    {
        id: 12,
        title: 'Management transparency'
    }
];
