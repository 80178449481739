import React from 'react';

function SearchIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
        >
            <path
                opacity="0.4"
                d="M16.1837 9.17484C16.1837 13.0454 13.0459 16.1832 9.17533 16.1832C5.30473 16.1832 2.16699 13.0454 2.16699 9.17484C2.16699 5.30424 5.30473 2.1665 9.17533 2.1665C13.0459 2.1665 16.1837 5.30424 16.1837 9.17484Z"
                fill="#292D32"
                stroke="#808080"
            />
            <path
                d="M16.5066 18.0651L16.4961 18.0633L16.4852 18.062C16.3942 18.0509 16.246 18.0114 16.0866 17.8799C15.9276 17.7487 15.7294 17.503 15.5746 17.0352C15.433 16.6036 15.4968 16.2172 15.6888 15.9505C15.881 15.6836 16.2269 15.5 16.6831 15.5C17.3533 15.5 17.7248 15.7364 17.8834 16.0268C18.0355 16.3195 18.0272 16.7584 17.6545 17.2998L17.6545 17.2998L17.6504 17.306C17.186 18.0026 16.7701 18.0667 16.5831 18.0667C16.5517 18.0667 16.535 18.0666 16.5207 18.066C16.5114 18.0657 16.5074 18.0652 16.5066 18.0651ZM16.5066 18.0651L16.5069 18.0651C16.5065 18.0651 16.5064 18.0651 16.5066 18.0651Z"
                fill="#292D32"
                stroke="#808080"
            />
        </svg>
    );
}

export default SearchIcon;
