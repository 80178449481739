import * as React from 'react';
import Dialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions, { DialogActionsProps } from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Box, Divider, Modal } from '@mui/material';
import CloseXIcon from '../../assets/icons/CloseXIcon';
import { ArrowLeftIcon } from '../../assets/icons/ArrowLeftIcon';

export interface DialogProps extends Omit<MuiDialogProps, 'title'> {
    open: boolean;
    setType?: (type: string) => void;
    title?: React.ReactNode;
    actions?: React.ReactNode;
    onClose?: () => void;
    children?: React.ReactNode;
    size?: 'default';
    contentPaddingX?: boolean;
    ActionsProps?: DialogActionsProps;
}

export interface DialogTitleProps {
    actions?: React.ReactNode;
    setType?: () => void;
    ActionsProps?: DialogActionsProps;
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

function CustomDialogTitle(props: DialogTitleProps) {
    const { children, onClose, actions, ActionsProps, setType, ...other } =
        props;

    return (
        <DialogTitle
            sx={{
                pt: { xs: '12px', sm: '24px' },
                pb: { xs: '12px', sm: '16px' },
                gap: '16px',
                m: 0,
                px: { xs: 2, sm: 4 },
                display: 'flex',
                alignItems: 'center',
                bgcolor: '#fff'
            }}
            {...other}
        >
            <Box
                aria-label="close"
                // size="small"
                onClick={setType}
                sx={{
                    cursor: 'pointer',
                    // display: { xs: 'block', sm: 'none' }
                    display: {xs: 'none', sm: 'flex'},
                    alignItems: 'center',
                    justifyContent: 'center'
                    //     color: '#FFFFFF',
                    //     p: 0.625
                }}
            >
                <ArrowLeftIcon />
            </Box>
            <Box
                aria-label="close"
                // size="small"
                onClick={onClose}
                sx={{
                    cursor: 'pointer',
                    // display: { xs: 'block', sm: 'none' }
                    display: {xs: 'flex', sm: 'none'},
                    alignItems: 'center',
                    justifyContent: 'center'
                    //     color: '#FFFFFF',
                    //     p: 0.625
                }}
            >
                <ArrowLeftIcon />
            </Box>
            <Box flex={1}>
                <Typography
                    // component="h6"
                    fontSize={20}
                    fontWeight={600}
                    fontFamily="open sans"
                >
                    {children}
                </Typography>
            </Box>
            {actions ? (
                <DialogActions
                    {...ActionsProps}
                    sx={{
                        bgcolor: '#FFFAF1',
                        display: { xs: 'block', sm: 'none' },
                        ...ActionsProps?.sx
                    }}
                >
                    {actions}
                </DialogActions>
            ) : null}
            {onClose ? (
                <Box
                    aria-label="close"
                    // size="small"
                    onClick={onClose}
                    sx={{
                        display: {xs: 'none', sm: 'flex'},
                        cursor: 'pointer',
                        alignItems: 'center',
                        justifyContent: 'center'
                        // display: { xs: 'none', sm: 'block' },
                        // '&:hover': {
                        //     bgcolor: '#FFFFFF'
                        // }
                        //     bgcolor: '#808080',
                        //     color: '#FFFFFF',
                        //     p: 0.625
                    }}
                >
                    <CloseXIcon />
                </Box>
            ) : null}
        </DialogTitle>
    );
}

export default function CommonDialog({
    actions,
    open,
    setType,
    title,
    onClose,
    contentPaddingX,
    ActionsProps,
    keepMounted,
    children,
    ...other
}: DialogProps) {
    function handleClose() {
        if (onClose) onClose();
    }

    return (
        <>
            <Modal open={open} onClose={handleClose}>
                <Box
                    bgcolor="#f2f2f2"
                    display={{ xs: 'block', sm: 'none' }}
                    overflow="auto"
                    height="100%"
                    position="absolute"
                    bottom={0}
                    top={0}
                    left={0}
                    right={0}
                >
                    {' '}
                    <CustomDialogTitle
                        actions={actions}
                        ActionsProps={ActionsProps}
                        id="customized-dialog-title"
                        onClose={handleClose}
                    >
                        {title}
                    </CustomDialogTitle>
                    <Divider
                        sx={{
                            display: { xs: 'none', sm: 'block' },
                            mx: 4,
                            mb: 0.125,
                            borderBottomWidth: 2
                        }}
                    />
                    {children}
                    {actions ? (
                        <DialogActions
                            {...ActionsProps}
                            sx={{
                                // bgcolor: '#fff',
                                display: { xs: 'none', sm: 'block' }
                            }}
                        >
                            {actions}
                        </DialogActions>
                    ) : null}
                </Box>
            </Modal>
            <Dialog
                keepMounted={keepMounted}
                // maxWidth="md"
                fullWidth
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={!!open}
                sx={{
                    display: { xs: 'none', sm: 'block' },

                    '& .MuiDialogContent-root': {
                        paddingX:
                            contentPaddingX === undefined || contentPaddingX
                                ? 0
                                : 0,
                        paddingBottom: actions ? 3 : 4,
                        bgcolor: '#fff',
                        paddingTop: 0
                    },
                    '& .MuiDialog-paper': {
                        // top: 0,
                        bottom: 0,
                        bgcolor: '#fff',
                        width: { xs: '100%', sm: '800px' },
                        height: { xs: '100%', sm: '600px' },
                        position: { xs: 'absolute', sm: 'static' }
                        // zIndex: 9999
                    },
                    '& .MuiDialogActions-root': {
                        // paddingX: 4,
                        // paddingY: 2.5
                    },
                    ...other.sx
                }}
                {...other}
            >
                <CustomDialogTitle
                    actions={actions}
                    ActionsProps={ActionsProps}
                    id="customized-dialog-title"
                    onClose={handleClose}
                    setType={() => {
                        if (setType) setType('');
                    }}
                >
                    {title}
                </CustomDialogTitle>
                <Divider
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        mx: 4,
                        mb: 0.125,
                        borderBottomWidth: 2
                    }}
                />
                <DialogContent>{children}</DialogContent>
                {actions ? (
                    <DialogActions
                        {...ActionsProps}
                        sx={{
                            bgcolor: '#f2f2f2',
                            display: { xs: 'none', sm: 'block' },
                            px: '16px'
                        }}
                    >
                        {actions}
                    </DialogActions>
                ) : null}
            </Dialog>
        </>
    );
}
