import {
    Button,
    Divider,
    Modal,
    Stack,
    TextareaAutosize,
    Typography
} from '@mui/material';
import { Box } from '@mui/system';
import React, { ChangeEvent, useState } from 'react';
import CommonDialog from '../../../common/CommonDialog';
import { useAuth } from '../../../../utils/context/AuthContext';
import axiosInstance from '../../../../utils/hooks/api/axiosConfig';
import CloseXIcon from '../../../../assets/icons/CloseXIcon';
import { TextField } from '@mui/material';

interface IEditProfileAboutMeDialogProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    refetch?: () => Promise<any>;
}

const EditProfileAboutMeDialog = (props: IEditProfileAboutMeDialogProps) => {
    const { user } = useAuth();

    const { open, setOpen, refetch } = props;

    const [aboutMe, setAboutMe] = useState(user?.professionalProfile?.bio);
    const handleOnClose = () => {
        setOpen(false);
    };

    const maxCount = '2500';

    const handleTextareaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setAboutMe(event.target.value);
    };

    const handleSave = async () => {
        await axiosInstance.patch(
            `api/professional-profiles/${user?.professionalProfile?.id}`,
            { bio: aboutMe }
        );
        refetch && (await refetch());
        handleOnClose();
    };
    const [isFocused, setIsFocused] = useState(false);

    return (
        <Modal open={open} onClose={handleOnClose}>
            <Box
                
                position="absolute"
                top={{ xs: 0, sm: '50%' }}
                left={{ xs: 0, sm: '50%' }}
                right={{ xs: 0, sm: undefined }}
                bottom={{ xs: 0, sm: undefined }}
                width={{ xs: '100%', sm: '600px', lg: '800px' }}
                height={{ sm: 'fit-content' }}
                sx={{
                    transform: { sm: 'translate(-50%, -50%)' }
                }}
                bgcolor="#fff"
                pb="28px"
            >
                <Box px={{ xs: '16px', sm: '32px' }} pt="24px">
                    <Box
                        borderBottom="2px solid #D9D9D9"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        pb="16px"
                    >
                        <Typography
                            fontSize={'20px'}
                            fontWeight={600}
                            color="#23282B"
                        >
                            Bio
                        </Typography>
                        <Box onClick={handleOnClose} sx={{ cursor: 'pointer' }}>
                            <CloseXIcon />
                        </Box>
                    </Box>
                </Box>
                <Stack py="8px" px={{ xs: 2, sm: 4 }} sx={{ bgcolor: '#fff' }}>
                    <Typography
                        variant="bodyLargeRegular"
                        color="#494949"
                        sx={{
                            mt: '4px',
                            fontSize: { xs: '14px', sm: '16px' },
                            mb: '8px'
                        }}
                    >
                        Write about your previous and current job experiences,
                        skills, and achievements. Maximum of 2500 characters.
                    </Typography>
                    <Typography
                        variant="bodyLargeRegular"
                        color="#494949"
                        sx={{
                            mt: '4px',
                            fontSize: '14px',
                            mb: '12px'
                        }}
                    >
                        Maximum of 2500 words.
                    </Typography>

                    <Box
                        sx={
                            {
                                // backgroundColor: '#f2f2f2',
                                // color: '#808080',
                                // p: 2
                            }
                        }
                    >
                        <TextField
                            fullWidth
                            value={aboutMe}
                            onChange={handleTextareaChange}
                            placeholder="Enter your about me"
                            sx={{
                                bgcolor: '#f2f2f2',
                                borderRadius: '8px',
                                border: isFocused
                                    ? '2px solid #49B6FF'
                                    : 'none',
                                '& fieldset,.Mui-focused fieldset': {
                                    border: '0 !important'
                                },
                                '& .MuiOutlinedInput-input': {
                                    color: '#23282B'
                                },
                                input: {
                                    p: '16px'
                                }
                            }}
                            multiline
                            rows={4}
                            maxRows={4}
                            onFocus={() => setIsFocused(true)}
                            onBlur={() => setIsFocused(false)}
                        />
                        {/* <TextareaAutosize
                            style={{
                                width: '100%',
                                backgroundColor: '#f2f2f2',
                                color: '#808080',
                                fontFamily: 'Open Sans',
                                fontWeight: 400,
                                fontSize: '16px',
                                lineHeight: '24px',
                                border: 'none',
                                outline: 'none',
                                textAlign: 'justify'
                            }}
                            placeholder="Enter your about me"
                            value={aboutMe}
                            onChange={handleTextareaChange}
                            maxRows={5}
                        /> */}
                    </Box>
                    <Box
                        sx={{
                            mt: 1,
                            display: 'flex',
                            justifyContent: 'flex-end',
                            fontSize: { xs: '12px', sm: '16px' }
                        }}
                    >
                        {aboutMe?.length}/{maxCount}
                    </Box>
                </Stack>

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        py: '20px',
                        px: { xs: 2, sm: 4 }
                    }}
                    bgcolor="#f2f2f2"
                >
                    <Button
                        variant="contained"
                        sx={{ width: 150, height: 44 }}
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default EditProfileAboutMeDialog;
