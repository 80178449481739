import { Box, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import JobFactorIcon from '../../assets/icons/JobFactorIcon';
import profileReview from './../../assets/images/profileReview.png';
import EllipsisIcon from '../../assets/icons/EllipsisIcon';
import { Link } from 'react-router-dom';
import { useStateMachine } from 'little-state-machine';

const loginTitle = 'Professionals meet companies';
const loginSubHeading =
    'Start your journey with us, discover the world’s best platform that connects professionals and companies';
const cardReview =
    'Absolutely amazing, the vetting algorithym is the best at proving ones level of experience';

function OnBoardingSidePanel({ totalSteps = 4, steps = [] }: { totalSteps?: number, steps: string[] }) {
    const {
        state: {
            data: { step }
        }
    } = useStateMachine();
    return (
        <>
            <Box
                sx={{
                    height: { md: '100%', sm: '280px', xs: 'fit-content' },
                    backgroundColor: '#FFE3AD',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    gap: '40px',
                    maxWidth: { md: '700px', xs: '100%' }
                }}
            >
                {/* Column1 */}
                {/* Logo */}
                <Box sx={{ mt: '51px', width: { sm: '80%', xs: '90%' }, alignSelf: 'center', mb: 4 }}>
                    <Link to={'/'}>
                        <JobFactorIcon />
                    </Link>
                    <Typography color={'#23282B'} fontSize={'16px'} component={'p'} mt={'32px'} display={{ sm: 'none', xs: 'block' }}>
                        Step<Typography color={'#07AF22'} fontSize={'16px'} component={'span'} fontWeight={600}>&nbsp;
                            {step}
                        </Typography>&nbsp;
                        of <b>{totalSteps}</b>
                    </Typography>
                    <Typography color={'#23282B'} fontSize={'20px'} fontWeight={600} display={{ sm: 'none', xs: 'block' }}>
                        {step >= 1 ? steps[step - 1] : ''}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: { md: 'block', xs: 'none' }
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: { lg: '45px', xs: '35px' },
                            fontFamily: 'open sans',
                            fontStyle: 'semibold',
                            fontWeight: '600',
                            textAlign: 'left',
                            mb: '28px',
                            mt: '10px',
                            px: '53px',
                            color: '#23282B',
                            lineHeight: '52px',
                            opacity: '0.85'
                        }}
                    >
                        {loginTitle}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: { lg: '20px', xs: 16 },
                            fontFamily: 'open sans',
                            lineHeight: '28px',
                            color: '#23282B',
                            textAlign: 'left',
                            px: '53px',
                            opacity: '0.65'
                        }}
                    >
                        {loginSubHeading}
                    </Typography>
                </Box>
                <Box sx={{ width: '80%', alignSelf: 'center', display: { md: 'block', xs: 'none' } }}>
                    <Box
                        sx={{
                            borderRadius: '8px',
                            padding: '24px 20px',
                            backgroundColor: '#FFFFFF',
                            mb: '24px'
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: { lg: '20px', xs: 16 },
                                fontFamily: 'open sans',
                                fontWeight: '600',
                                textAlign: 'left',
                                lineHeight: '28px',
                                mb: '28px',
                                color: '#23282B',
                                opacity: '0.75'
                            }}
                        >
                            {cardReview}
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <img
                                src={profileReview}
                                alt="Reviewer Avatar"
                                height="64"
                                width="64"
                            ></img>
                            <Box sx={{ ml: '16px' }}>
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                        fontFamily: 'open sans',
                                        fontWeight: '700',
                                        opacity: ' 0.9',
                                        color: '#23282B'
                                    }}
                                >
                                    Mark Fisher
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        fontFamily: 'open sans',
                                        opacity: ' 0.75',
                                        color: '#23282B'
                                    }}
                                >
                                    Professional
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            mt: '24px',
                            mb: '80px'
                        }}
                    >
                        <EllipsisIcon />
                    </Box>
                </Box>
            </Box>
        </>
    );
}

export default OnBoardingSidePanel;
